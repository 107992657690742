import * as yup from 'yup';

export const FileShape = {
    file: yup
        .object()
        .required('required')
        .when('file', {
            is: (file) => file !== undefined,
            then: yup.object({
                fileName: yup.string().max(150),
            }),
        }),
};

export const AddFileSchema = yup.object().shape({
    ...Object.assign({}, FileShape, {
        description: yup
            .string()
            .optional()
            .nullable()
            .max(250, 'Errors.must_be_under_two_hundred_fifty'),
    }),
}, [['file', 'file']]);
