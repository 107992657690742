import { Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Icon from 'Components/icons/icon';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListSectionHeader } from '../../../Components/list-section-header';
import { images, theme } from '../../../variant';
import CreateCompany from './modals/create-company';
import CompaniesTable from './companies-table';
import CreateOpportunityFromContactModal from 'Routes/authenticated/opportunities/create-opportunity-for-contact-modal';
import { useHistory } from 'react-router';

const { usersHeader } = images;

const GlobalCompanies: FunctionComponent = () => {
    const history = useHistory();
    const [addCompanyModalIsVisible, setAddCompanyModalIsVisible] = useState(false);
    const [createOpportunityState, setCreateOpportunityState] = useState<{
        visible: boolean;
        contactId?: string;
        contactName?: string;
        membershipId?: string;
        membershipName?: string;
    }>({ visible: false });

    const { t } = useTranslation();

    const ActionButton = (
        <Button
            type="primary"
            onClick={(): void => {
                setAddCompanyModalIsVisible(true);
            }}
        >
            {t('Membership.create_company')}
        </Button>
    );

    const onCompleteAddCompany = (): void => setAddCompanyModalIsVisible(false);

    return (
        <div className="GlobalCompanies">
            <ListSectionHeader
                title={t('companies')}
                subTitle={t('Membership.manage_companies')}
                defaultImg={<Icon iconName="Company" fill={theme['primary-color']} />}
                backgroundImageUrl={usersHeader}
                action={ActionButton}
            />

            <Content>
                <CompaniesTable globalList />
            </Content>

            {addCompanyModalIsVisible && (
                <CreateCompany
                    visible={addCompanyModalIsVisible}
                    onComplete={onCompleteAddCompany}
                    onCreateOpportunity={(contactId, contactName, membershipId, membershipName) =>
                        setCreateOpportunityState({
                            visible: true,
                            contactId,
                            contactName,
                            membershipId,
                            membershipName,
                        })
                    }
                />
            )}

            {createOpportunityState.visible && (
                <CreateOpportunityFromContactModal
                    visible={createOpportunityState.visible}
                    onComplete={(success: boolean, id?: string) => {
                        if (success && id) history.push(`/opportunities/${id}/dashboard`);
                        setCreateOpportunityState({ visible: false });
                    }}
                    contactId={createOpportunityState.contactId}
                    contactName={createOpportunityState.contactName}
                    membershipId={createOpportunityState.membershipId}
                    membershipName={createOpportunityState.membershipName}
                />
            )}
        </div>
    );
};

export default GlobalCompanies;
