import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const File: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.0218 3.6L10.244 0.0222222V0H1.86621V16H14.0107V3.62222L14.0218 3.6ZM12.8884 3.53333L10.2773 3.58889L10.3218 1.06667L12.8884 3.53333ZM13.344 15.3333H2.53288V0.666667H9.57732V4.28889H13.344V15.3333Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default File;
