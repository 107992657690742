import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Company: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.647 6.733c.09-.02.185-.033.287-.035h.03l1.849.003a1.384 1.384 0 01.533.107c.529.221.87.738.869 1.317l-.003 1.423-4.678-.01.003-1.423a1.435 1.435 0 011.11-1.382zm2.167-.563l-1.848-.004A1.958 1.958 0 00.004 8.114L0 10.069l5.742.012.004-1.955A1.947 1.947 0 003.814 6.17zM7.996 8.57c-.629 0-1.14-.511-1.14-1.14a1.142 1.142 0 012.282 0c0 .629-.512 1.14-1.142 1.14zm0-2.812c-.922 0-1.673.75-1.673 1.673 0 .921.75 1.671 1.673 1.671.922 0 1.672-.75 1.672-1.671 0-.923-.75-1.673-1.672-1.673zM6.753 10.381c.091-.021.185-.033.289-.035.01-.002.02-.002.03-.002H8.92a1.359 1.359 0 01.533.106c.53.22.871.736.871 1.315v1.423H5.647v-1.423c0-.654.465-1.236 1.106-1.384zm2.167-.568H7.071c-1.079 0-1.956.875-1.956 1.952v1.955h5.742v-1.955A1.946 1.946 0 008.92 9.813zM13.14 4.926c-.63 0-1.142-.512-1.142-1.14 0-.629.512-1.14 1.141-1.14.628 0 1.14.511 1.14 1.14 0 .628-.512 1.14-1.14 1.14zm0-2.813c-.924 0-1.674.75-1.674 1.673 0 .921.75 1.671 1.673 1.671.922 0 1.672-.75 1.672-1.671 0-.923-.75-1.673-1.672-1.673zM11.896 6.736c.091-.021.186-.033.288-.035.01-.002.021-.002.03-.002h1.85a1.385 1.385 0 01.533.106c.529.22.87.736.87 1.315v1.423h-4.678V8.12c0-.654.466-1.236 1.107-1.384zm2.167-.568h-1.849c-1.079 0-1.956.876-1.956 1.952v1.955H16V8.12a1.947 1.947 0 00-1.937-1.952zM2.896 4.926h-.004a1.133 1.133 0 01-.804-.336 1.132 1.132 0 01-.332-.807 1.136 1.136 0 011.14-1.138h.002a1.141 1.141 0 011.138 1.143 1.141 1.141 0 01-1.14 1.138zm1.184-2.32a1.662 1.662 0 00-1.181-.493h-.005a1.66 1.66 0 00-1.178.487 1.665 1.665 0 00-.493 1.182A1.675 1.675 0 002.89 5.457h.005c.444 0 .863-.172 1.18-.487.316-.315.491-.734.493-1.181 0-.445-.173-.866-.489-1.183z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Company;
