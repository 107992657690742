import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CrossFile: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.0223 3.6L9.24448 0.0222222V0H0.866699V16H13.0111V3.62222L13.0223 3.6ZM9.32226 1.06667L11.8889 3.53333L9.27781 3.58889L9.32226 1.06667ZM12.3445 15.3333H1.53337V0.666667H8.57781V4.28889H12.3445V15.3333Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.34441 13.3111L7.58885 12C7.27774 11.5 7.08885 11.1778 6.89996 10.8334H6.87774C6.71107 11.1778 6.54441 11.4889 6.2333 12.0111L5.5333 13.3111H4.65552L6.46663 10.3222L4.72218 7.38892H5.61107L6.38885 8.7778C6.61107 9.16669 6.77774 9.46669 6.9333 9.7778H6.95552C7.12218 9.42225 7.26663 9.15558 7.48885 8.7778L8.31107 7.38892H9.18885L7.38885 10.2667L9.2333 13.3H8.34441V13.3111Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default CrossFile;
