// GENERATED FILE - DO NOT MODIFY
import { CampusSurroundingTypeDto } from 'Api/Features/CampusSurroundings/Dtos/CampusSurroundingTypeDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class CampusSurroundingTypeProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getCampusSurroundingType(campusSurroundingTypeId: string): Promise<CampusSurroundingTypeDto | null> {
        const uri = this.buildUri(
            "/campussurroundingtypes/{campusSurroundingTypeId}",
            { campusSurroundingTypeId: campusSurroundingTypeId },
            null
        );

        const data: CampusSurroundingTypeDto | null = await this.httpClient.get<CampusSurroundingTypeDto | null>(uri);
        return data;
    }

    public async getCampusSurroundingTypes(): Promise<(CampusSurroundingTypeDto | null)[] | null> {
        const uri = this.buildUri(
            "/campussurroundingtypes",
            null,
            null
        );

        const data: (CampusSurroundingTypeDto | null)[] | null = await this.httpClient.get<(CampusSurroundingTypeDto | null)[] | null>(uri);
        return data;
    }
}