// GENERATED FILE - DO NOT MODIFY

export enum OpportunityStatusDto {
    New = "New",
    TourScheduled = "TourScheduled",
    Qualified = "Qualified",
    Proposal = "Proposal",
    Agreed = "Agreed",
    AgreementSent = "AgreementSent",
    AgreementSigned = "AgreementSigned",
    Lost = "Lost",
}