import { StoredFileDto } from 'Api/Features/General/Dtos/StoredFileDto';
import { Trash } from 'Components/icons';
import Icon from 'Components/icons/icon';
import { DISPLAY_DATETIME_12H } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'variant';
import { Scrollbars } from 'react-custom-scrollbars';
import './file-list.less';


interface FileListItemProps {
    files: StoredFileDto[];
    onFileClick?: (url?: string) => void;
    onDeleteClick?: (id?: string) => void;
    onScrolledToBottom?: () => void;
}


const FileList: FunctionComponent<FileListItemProps> = ({
    files,
    onFileClick,
    onDeleteClick,
    onScrolledToBottom
}) => {
    const { t } = useTranslation();

    const fileListItem = (file: StoredFileDto): ReactNode => {
        let iconName;
        if (file.contentType?.includes('image')) {
            iconName = 'ImageFile';
        } else if (file.contentType === 'application/pdf') {
            iconName = 'Pdf';
        } else {
            iconName = 'File';
        }

        return (
            <div className="file-list-item" key={file.id}>
                <div className="icon-container">
                    <Icon
                        fill={theme['primary-color']}
                        width={24}
                        height={24}
                        iconName={iconName}
                    />
                </div>
                <div className="content-container">
                    <div className="name">
                        <span
                            onClick={(): void =>
                                onFileClick ? onFileClick(file.url ?? undefined) : undefined
                            }
                        >
                            {file.fileName}
                        </span>
                    </div>
                    {file.description && (
                        <div className="description">
                            <span className="title">
                                {t('description')}
                                {': '}
                            </span>
                            {file.description}
                        </div>
                    )}
                    <div className="date">
                        {moment(file.dateCreated).format(DISPLAY_DATETIME_12H)}
                    </div>
                </div>
                <div
                    className="delete-container"
                    onClick={(): void => (onDeleteClick ? onDeleteClick(file.id) : undefined)}
                >
                    <Trash fill={theme['primary-color']} width={24} height={24} />
                </div>
            </div>
        );
    }

    const handleScroll = (e: any): void => {
        if(onScrolledToBottom) {
            const hasReachedBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            if(hasReachedBottom) {
                onScrolledToBottom();
            }
        }
    }

    return (
        <div className="FileList">
            <Scrollbars
                onScroll={(e): void => handleScroll(e)}
                height={170}
                renderThumbVertical={({ style }): any => (
                    <div
                        style={{
                            ...style,
                            backgroundColor: theme['primary-4'],
                            borderRadius: 6,
                        }}
                    ></div>
                )}
            >
                {files.map((file) => fileListItem(file))}
            </Scrollbars>
        </div>
    );
};

export default FileList;
