import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DISPLAY_DATE_FORMAT } from 'Models/Constants';
import moment, { Moment } from 'moment';
import StatusBar from 'Components/status-bar/status-bar';
import './subscription-detail-statusbar.less';

export enum SubscriptionStatus {
    onboarding = 'onboarding',
    current = 'current',
    renewalModification = 'renewalModification',
}

interface SubscriptionDetailStatusbarProps {
    subscriptionStatus: SubscriptionStatus;
    subscriptionStatusActivityDate?: Moment;
}

const SubscriptionDetailStatusbar: FunctionComponent<SubscriptionDetailStatusbarProps> = ({
    subscriptionStatus,
    subscriptionStatusActivityDate,
}) => {
    const { t } = useTranslation();
    let currentStep = 1;
    const stepsToDisplay = 3;

    const stepTextList = Object.keys(SubscriptionStatus)
        .filter((value, index) => {
            currentStep = subscriptionStatus === value ? index + 1 : currentStep;

            return index < stepsToDisplay && value;
        })
        .map((item) => t(`Subscription.status_bar_${item}`));

    let statusBarCurrentStepSubTitleStringKey = '';

    switch (subscriptionStatus) {
        case SubscriptionStatus.onboarding:
            statusBarCurrentStepSubTitleStringKey = 'Subscription.status_bar_signed_on';
            break;
        case SubscriptionStatus.current:
            statusBarCurrentStepSubTitleStringKey = 'Subscription.status_bar_started_on';
            break;
        case SubscriptionStatus.renewalModification:
            statusBarCurrentStepSubTitleStringKey = 'Subscription.status_bar_renewal_since';
    }

    return (
        <div className="SubscriptionDetailStatusbar">
            <StatusBar
                step={currentStep}
                stepTextList={stepTextList}
                currentStepSubTitle={t(statusBarCurrentStepSubTitleStringKey, {
                    param1: subscriptionStatusActivityDate
                        ? moment(subscriptionStatusActivityDate).format(DISPLAY_DATE_FORMAT)
                        : t('Unknown'),
                })}
            />
        </div>
    );
};

export default SubscriptionDetailStatusbar;
