// GENERATED FILE - DO NOT MODIFY
import { ConfirmEmailRequestDto } from 'Api/Features/Accounts/Dtos/ConfirmEmailRequestDto';
import { ForgottenPasswordRequestDto } from 'Api/Features/Accounts/Dtos/ForgottenPasswordRequestDto';
import { IsEmailAvailableRequestDto } from 'Api/Features/Accounts/Dtos/IsEmailAvailableRequestDto';
import { RegisterAccountRequestDto } from 'Api/Features/Accounts/Dtos/RegisterAccountRequestDto';
import { ResendConfirmationRequestDto } from 'Api/Features/Accounts/Dtos/ResendConfirmationRequestDto';
import { UnconfirmedAccountInfoDto } from 'Api/Features/Accounts/Dtos/UnconfirmedAccountInfoDto';
import { UpdateUserPasswordAnonymousRequestDto } from 'Api/Features/Accounts/Dtos/UpdateUserPasswordAnonymousRequestDto';
import { UpdateUserPasswordRequestDto } from 'Api/Features/Accounts/Dtos/UpdateUserPasswordRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class AccountProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async forgotPassword(forgottenPasswordRequest: ForgottenPasswordRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/account/forgottenpassword",
            null,
            null
        );

        await this.httpClient.post<ForgottenPasswordRequestDto | null, void>(uri, forgottenPasswordRequest);
    }

    public async updateUserPasswordAnonymous(request: UpdateUserPasswordAnonymousRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/account/resetpassword",
            null,
            null
        );

        await this.httpClient.post<UpdateUserPasswordAnonymousRequestDto | null, void>(uri, request);
    }

    public async updateCurrentUserPassword(changePasswordRequest: UpdateUserPasswordRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/account/changepassword",
            null,
            null
        );

        await this.httpClient.post<UpdateUserPasswordRequestDto | null, void>(uri, changePasswordRequest);
    }

    public async registerAccount(request: RegisterAccountRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/account/register",
            null,
            null
        );

        const data: string = await this.httpClient.post<RegisterAccountRequestDto | null, string>(uri, request);
        return data;
    }

    public async registerAccountValidate(request: RegisterAccountRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/account/registervalidate",
            null,
            null
        );

        await this.httpClient.post<RegisterAccountRequestDto | null, void>(uri, request);
    }

    public async resendEmailConfirmation(resendEmailConfirmationRequest: ResendConfirmationRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/account/resendemailconfirmation",
            null,
            null
        );

        await this.httpClient.post<ResendConfirmationRequestDto | null, void>(uri, resendEmailConfirmationRequest);
    }

    public async confirmEmail(confirmEmailRequest: ConfirmEmailRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/account/confirmemail",
            null,
            null
        );

        await this.httpClient.post<ConfirmEmailRequestDto | null, void>(uri, confirmEmailRequest);
    }

    public async getUnconfirmedAccountInfo(email: string | null): Promise<UnconfirmedAccountInfoDto | null> {
        const uri = this.buildUri(
            "/account/unconfirmedinfo",
            null,
            { email: email }
        );

        const data: UnconfirmedAccountInfoDto | null = await this.httpClient.get<UnconfirmedAccountInfoDto | null>(uri);
        return data;
    }

    public async isEmailAvailable(request: IsEmailAvailableRequestDto | null): Promise<boolean> {
        const uri = this.buildUri(
            "/accounts/email-available",
            null,
            { ...request || undefined }
        );

        const data: boolean = await this.httpClient.get<boolean>(uri);
        return data;
    }
}