import { Button, Col, Form, Input, Radio, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { CreateUpdateAddressRequestDto } from 'Api/Features/General/Dtos/CreateUpdateAddressRequestDto';
import { CreateOpportunityContactDto } from 'Api/Features/Opportunities/Dtos/CreateOpportunityContactDto';
import { CreateOpportunityRequestDto } from 'Api/Features/Opportunities/Dtos/CreateOpportunityRequestDto';
import FormStep, { StepInfo } from 'Components/form-step/form-step';
import { Plan } from 'Components/icons';
import BaseModal from 'Components/base-modal/base-modal';
import { useFormValidation, useService, useStores } from 'Hooks';
import { Lead } from 'Models/Leads/Lead';
import { Opportunity } from 'Models/Opportunities/Opportunity';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CreateOpportunitySchema,
    OpportunityCompanySchema,
    OpportunityContactSchema,
} from 'Schemas';
import { AccountService } from 'Services/AccountService';
import { OpportunityService } from 'Services/OpportunityService';
import CompanyFormSection from './company-form-section';
import ContactFormSection from './contact-form-section';
import './create-opportunity.less';
import OpportunityFormSection from './opportunity-form-section';
import { CreateOpportunityNoCompanySchema } from 'Schemas/CreateOpportunitySchema';
import { LeadSourceDto } from 'Api/Features/Leads/Dtos/LeadSourceDto';
import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';
import { OpportunityAccountDto } from 'Api/Features/Opportunities/Dtos/OpportunityAccountDto';
import { OpportunityMembershipDto } from 'Api/Features/Opportunities/Dtos/OpportunityMembershipDto';
import moment from 'moment';
import { LeadAccountDto } from 'Api/Features/Leads/Dtos/LeadAccountDto';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { dateMomentToString } from 'Utils/TimeUtils';
import { LightContactInfoDto } from 'Api/Features/General/Dtos/LightContactInfoDto';

const formGutter: [Gutter, Gutter] = [40, 0];

export interface CreateModificationRenewalOpportunity {
    parentSubscriptionId: string;
    isSubscriptionModification?: boolean;
    isSubscriptionRenewal?: boolean;
    initialOpportunity: Opportunity | null | undefined;
    opportunityOwner: OpportunityAccountDto | null | undefined;
    company: OpportunityMembershipDto | null | undefined;
    opportunityContact: OpportunityAccountDto | null | undefined;
    locationId?: string;
    product?: string;
}

export interface ExistingMembershipCreateOpportunity {
    membershipId: string;
    membershipName: string;
}

interface OpportunityModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    opportunity?: Opportunity;
    lead?: Lead;
    createModificationRenewalOpportunity?: CreateModificationRenewalOpportunity;
    existingMembership?: ExistingMembershipCreateOpportunity;
}

enum OpportunityFormStep {
    Company = 'Company',
    Contact = 'Contact',
    Opportunity = 'Opportunity',
}

enum OpportunityWithoutCompanyFormStep {
    Contact = 'Contact',
    Opportunity = 'Opportunity',
}

export enum OpportunityRadioButtonValues {
    New = 'New',
    Existing = 'Existing',
}

const CreateOpportunityModal: FunctionComponent<OpportunityModalProps> = ({
    visible,
    onComplete,
    opportunity,
    lead,
    createModificationRenewalOpportunity,
    existingMembership,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(CreateOpportunitySchema, form);
    const [errorsNoSource, validateFormNoSource, resetErrorsNoSource] = useFormValidation(
        CreateOpportunityNoCompanySchema,
        form
    );
    const [companyErrors, validateCompany] = useFormValidation(OpportunityCompanySchema, form);
    const [contactErrors, validateContact, resetContactErrors, setContactErrors] =
        useFormValidation(OpportunityContactSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore, userPermissionsStore } =
        useStores();
    const opportunityService = useService(OpportunityService);
    const accountService = useService(AccountService);
    const [activeFormStep, setactiveFormStep] = useState<
        OpportunityFormStep | OpportunityWithoutCompanyFormStep
    >(
        !createModificationRenewalOpportunity
            ? OpportunityFormStep.Company
            : OpportunityWithoutCompanyFormStep.Contact
    );
    const [companyRadioState, setCompanyRadioState] = useState<OpportunityRadioButtonValues>(
        lead ? OpportunityRadioButtonValues.New : OpportunityRadioButtonValues.Existing
    );
    const [contactRadioState, setContactRadioState] = useState<OpportunityRadioButtonValues>(
        lead ? OpportunityRadioButtonValues.New : OpportunityRadioButtonValues.Existing
    );
    const [companyName, setCompanyName] = useState<string>(lead?.companyName || '');
    const [contactName, setContactName] = useState<string>(
        lead ? `${lead.firstName} ${lead.lastName}` : ''
    );
    const [companyImageDetails, setCompanyImageDetails] = useState<ImageDetails[]>();
    const [contactImageDetails, setContactImageDetails] = useState<ImageDetails[]>();
    const [contactFirstName, setContactFirstName] = useState<string>(lead?.firstName || '');
    const [contactLastName, setContactLastName] = useState<string>(lead?.lastName || '');
    const [selectedExistingMembershipId, setSelectedExistingMembershipId] = useState<
        string | undefined
    >(undefined);
    const [selectedExistingContactId, setSelectedExistingContactId] = useState<string | undefined>(
        undefined
    );
    const [ownerAccount, setOwnerAccount] = useState<LeadAccountDto>();

    const modalRef = useRef<HTMLDivElement>(null);
    //#endregion

    const formSteps = Object.keys(
        !createModificationRenewalOpportunity ? OpportunityFormStep : OpportunityWithoutCompanyFormStep
    ).map((step: string, i: number) => {
        return {
            active: step === activeFormStep,
            stepNumber: i + 1,
            name: step,
        } as StepInfo;
    });

    const dismiss = (success = false, id?: string): void => {
        onComplete(success, id);
        form.resetFields();
        resetErrors();
        resetErrorsNoSource();
        resetContactErrors();
    };

    const getCompanyFormValues = () => {
        const formValues = form.getFieldsValue();

        return {
            name: formValues['membership.name'],
            billingName: formValues['membership.billingName'],
            billingEmail: formValues['membership.billingEmail'],
            presentation: formValues['membership.presentation'],
            numberOfEmployees: formValues['membership.numberOfEmployees'],
            industry: formValues['membership.industry'],
            image: companyImageDetails
                ? ({
                      delete: companyImageDetails[0].isDeleted,
                      uploadBase64: companyImageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null,
            baseCampusIds:
                formValues['membership.locations'] === ''
                    ? undefined
                    : formValues['membership.locations']?.map(
                          (location: { value: string; label: string }) => location.value
                      ),
            expertiseIds: formValues['membership.expertises']?.map(
                (expertise: { value: string; label: string }) => expertise.value
            ),
            address: {
                addressLine1: formValues['membership.address.addressLine1'],
                city: formValues['membership.address.city'],
                country: formValues['membership.address.country'],
                postalCode: formValues['membership.address.postalCode'],
                state: formValues['membership.address.state'],
            } as CreateUpdateAddressRequestDto,
            legalContact: {
                firstName: formValues['membership.legalContact.firstName'],
                lastName: formValues['membership.legalContact.lastName'],
                phone: formValues['membership.legalContact.phone'],
                email: formValues['membership.legalContact.email'],
                birthDate: dateMomentToString(formValues['membership.legalContact.birthDate']),
                addressLine1: formValues['membership.legalContact.addressLine1'],
                city: formValues['membership.legalContact.city'],
                country: formValues['membership.legalContact.country'],
                postalCode: formValues['membership.legalContact.postalCode'],
                state: formValues['membership.legalContact.state'],
            },
            contactInfo: {
                website: formValues['membership.contactInfo.website'],
                email: formValues['membership.contactInfo.email'],
                phone1: formValues['membership.contactInfo.phone1'],
                angelListUrl: formValues['membership.contactInfo.angelListUrl'],
                instagramUrl: formValues['membership.contactInfo.instagramUrl'],
                linkedInUrl: formValues['membership.contactInfo.linkedInUrl'],
                twitterUrl: formValues['membership.contactInfo.twitterUrl'],
                facebookUrl: formValues['membership.contactInfo.facebookUrl'],
            } as LightContactInfoDto,
        };
    };

    const getContactFormValues = (): CreateOpportunityContactDto => {
        const formValues = form.getFieldsValue();

        return {
            image: contactImageDetails
                ? ({
                      delete: contactImageDetails[0].isDeleted,
                      uploadBase64: contactImageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null,
            salutation: formValues['contact.salutation'],
            firstName: formValues['contact.firstName'],
            middleName: formValues['contact.middleName'],
            lastName: formValues['contact.lastName'],
            birthDate: dateMomentToString(formValues['contact.birthDate']),
            wifiPassword: formValues['contact.wifiPassword'],
            jobPosition: formValues['contact.jobPosition'],
            introduction: formValues['contact.introduction'],
            expertiseIds: formValues['contact.expertises']?.map(
                (expertise: { value: string; label: string }) => expertise.value
            ),
            contactInfo: {
                phone1: formValues['contact.contactInfo.phone1'],
                phone2: formValues['contact.contactInfo.phone2'],
                email: formValues['contact.contactInfo.email'],
                angelListUrl: formValues['contact.contactInfo.angelListUrl'],
                twitterUrl: formValues['contact.contactInfo.twitterUrl'],
                facebookUrl: formValues['contact.contactInfo.facebookUrl'],
                linkedInUrl: formValues['contact.contactInfo.linkedInUrl'],
                instagramUrl: formValues['contact.contactInfo.instagramUrl'],
            },
            address: {
                addressLine1: formValues['contact.address.addressLine1'],
                city: formValues['contact.address.city'],
                country: formValues['contact.address.country'],
                postalCode: formValues['contact.address.postalCode'],
                state: formValues['contact.address.state'],
            } as CreateUpdateAddressRequestDto,
            isPrivate: !formValues['contact.isPrivate'],
        } as CreateOpportunityContactDto;
    };

    //#region Event handlers

    const onNextClick = async (): Promise<void> => {
        const formValues = form.getFieldsValue();

        if (activeFormStep === OpportunityFormStep.Company) {
            const companyForm = {
                existingCompany: companyRadioState === OpportunityRadioButtonValues.Existing,
                membershipId: formValues.membershipId,
                membership: getCompanyFormValues(),
            };
            if (!(await validateCompany(companyForm))) return;
            setactiveFormStep(OpportunityFormStep.Contact);
            if (modalRef.current) modalRef.current.scrollIntoView();
        } else if (activeFormStep === OpportunityFormStep.Contact) {
            const contactForm = {
                existingContact: contactRadioState === OpportunityRadioButtonValues.Existing,
                contactId: selectedExistingContactId,
                contact: getContactFormValues(),
            };
            if (!(await validateContact(contactForm))) return;
            if (contactRadioState === OpportunityRadioButtonValues.New) {
                if (
                    await accountService.isEmailAvailable(
                        contactForm.contact.contactInfo?.email as string
                    )
                ) {
                    setactiveFormStep(OpportunityFormStep.Opportunity);
                    if (modalRef.current) modalRef.current.scrollIntoView();
                } else {
                    const errors = new Map<string, string[]>();

                    errors.set('contact.contactInfo.email', [t('Errors.email_exists')]);
                    setContactErrors(errors);
                }
            } else {
                setactiveFormStep(OpportunityFormStep.Opportunity);
                if (modalRef.current) modalRef.current.scrollIntoView();
            }
        }
    };

    const onBackClick = (): void => {
        if (activeFormStep === OpportunityFormStep.Contact)
            setactiveFormStep(OpportunityFormStep.Company);
        else if (activeFormStep === OpportunityFormStep.Opportunity)
            setactiveFormStep(OpportunityFormStep.Contact);

        if (modalRef.current) modalRef.current.scrollIntoView();
    };

    //#endregion

    //#region Effects

    useEffect(() => {
        if (lead) {
            form.setFieldsValue({
                campusId: lead.campus?.id,
                source: lead.source,
                sourceOther: lead.sourceOther ?? undefined,
                officeNumber: lead.officeNumber,
                //contact
                'contact.firstName': lead.firstName,
                'contact.lastName': lead.lastName,
                'contact.contactInfo.email': lead.email,
                'contact.contactInfo.phone1': lead.phoneNumber,
                'contact.contactInfo.phone2': lead.phoneNumber2,
                'contact.middleName': lead.middleName,
                'contact.salutation': lead.salutation,
                'contact.jobPosition': lead.jobPosition,
                'contact.address.addressLine1': lead.address?.addressLine1,
                'contact.address.city': lead.address?.city,
                'contact.address.state': lead.address?.state,
                'contact.address.country': lead.address?.country,
                'contact.address.postalCode': lead.address?.postalCode,
                //company
                'membership.name': lead.companyName,
                'membership.contactInfo.website': lead.website,
                'membership.numberOfEmployees': lead.numberOfEmployees ?? undefined,
                'membership.industry': lead.industry,
            });
        }
    }, [lead, form, userPermissionsStore.userInfo]);

    useEffect(() => {
        form.setFieldsValue({
            companyConfirm: companyName,
            name: `${companyName}`,
        });
    }, [companyName, form]);

    useEffect(() => {
        if (companyRadioState === OpportunityRadioButtonValues.New) {
            const companyName = form.getFieldValue('membership.name');
            setCompanyName(companyName);
        }
    }, [companyRadioState, form]);

    useEffect(() => {
        const name = `${contactFirstName} ${contactLastName}`;
        form.setFieldsValue({
            contactConfirm: name,
        });
    }, [contactFirstName, contactLastName, form]);

    useEffect(() => {
        form.setFieldsValue({
            contactConfirm: contactName,
        });
    }, [contactName, form]);

    //set the current user in the owner dropdown
    useEffect(() => {
        if (userPermissionsStore.userInfo) {
            const owner: LeadAccountDto = {
                firstName: userPermissionsStore.userInfo.firstName,
                lastName: userPermissionsStore.userInfo.lastName,
                id: userPermissionsStore.userInfo.id,
                imageUrl: userPermissionsStore.userInfo.imageUrl,
            };
            setOwnerAccount(owner);
            form.setFieldsValue({
                ownerAccountId: { value: userPermissionsStore.userInfo.id },
            });
        }
    }, [userPermissionsStore]);

    useEffect(() => {
        if (createModificationRenewalOpportunity) {
            form.setFieldsValue({
                name: `${createModificationRenewalOpportunity.company?.name}`,
                membershipId: createModificationRenewalOpportunity.company?.id,
                membership: createModificationRenewalOpportunity.company,
                companyConfirm: createModificationRenewalOpportunity.company?.name,
                contactId: createModificationRenewalOpportunity.opportunityContact
                    ? { value: createModificationRenewalOpportunity.opportunityContact.id }
                    : undefined,
                campusId: createModificationRenewalOpportunity.locationId,
                contactConfirm: createModificationRenewalOpportunity.opportunityContact
                    ? `${createModificationRenewalOpportunity.opportunityContact.firstName} ${createModificationRenewalOpportunity.opportunityContact.lastName}`
                    : undefined,
                probability:
                    createModificationRenewalOpportunity.initialOpportunity?.probability ?? undefined,
                closeDate: createModificationRenewalOpportunity.initialOpportunity?.closeDate
                    ? moment(createModificationRenewalOpportunity.initialOpportunity.closeDate)
                    : undefined,
                deskCount: createModificationRenewalOpportunity.initialOpportunity?.deskCount ?? undefined,
                product: createModificationRenewalOpportunity.initialOpportunity?.product ?? undefined,
                salesChannel:
                    createModificationRenewalOpportunity.initialOpportunity?.salesChannel ?? undefined,
                termLength:
                    createModificationRenewalOpportunity.initialOpportunity?.termLength ?? undefined,
                monthlyRevenue:
                    createModificationRenewalOpportunity.initialOpportunity?.monthlyRevenue ?? undefined,
                officeNumber:
                    createModificationRenewalOpportunity.initialOpportunity?.officeNumber ?? undefined,
                description:
                    createModificationRenewalOpportunity.initialOpportunity?.description ?? undefined,
            });
            setSelectedExistingMembershipId(createModificationRenewalOpportunity.company?.id ?? undefined);
            setSelectedExistingContactId(
                createModificationRenewalOpportunity.opportunityContact?.id ?? undefined
            );
        }
    }, [createModificationRenewalOpportunity, form, userPermissionsStore.userInfo]);

    //creating opportunity coming from company context
    useEffect(() => {
        if (existingMembership) {
            setactiveFormStep(OpportunityFormStep.Contact);
            setSelectedExistingMembershipId(existingMembership.membershipId);
            form.setFieldsValue({
                companyConfirm: existingMembership.membershipName,
                membershipId: { value: existingMembership.membershipId },
                name: `${existingMembership.membershipName}`,
            });
        }
    }, [existingMembership]);
    //#endregion

    //#region Submit / Exit
    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Plan />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText:
                    opportunity !== undefined
                        ? t('Opportunity.opportunity_confirm_positive_edit')
                        : t(`Opportunity.opportunity_confirm_positive`),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const companyValues = getCompanyFormValues();
        const legalContactAddress: CreateUpdateAddressRequestDto = {
            addressLine1: companyValues.legalContact?.addressLine1,
            city: companyValues.legalContact?.city,
            country: companyValues.legalContact?.country,
            postalCode: companyValues.legalContact?.postalCode,
            state: companyValues.legalContact?.state,
        };
        const membershipInfos =
            companyRadioState === OpportunityRadioButtonValues.Existing ||
            createModificationRenewalOpportunity
                ? undefined
                : {
                      ...companyValues,
                      legalContact: {
                          ...companyValues.legalContact,
                          ...legalContactAddress,
                      },
                  };
        const source = createModificationRenewalOpportunity
            ? createModificationRenewalOpportunity.isSubscriptionModification
                ? LeadSourceDto.SubscriptionModification
                : LeadSourceDto.SubscriptionRenewal
            : formValues.source;

        const data = {
            probability: formValues.probability === '' ? undefined : formValues.probability,
            campusId: formValues.campusId,
            closeDate: formValues.closeDate,
            description: formValues.description,
            deskCount: formValues.deskCount,
            leadId: lead?.id,
            officeNumber: formValues.officeNumber,
            monthlyRevenue:
                formValues.monthlyRevenue === '' ? undefined : formValues.monthlyRevenue,
            name: formValues.name,
            ownerAccountId: formValues.ownerAccountId?.value,
            product: formValues.product,
            salesChannel: formValues.salesChannel,
            source: source,
            sourceOther: formValues.sourceOther ?? undefined,
            termLength: formValues.termLength,
            existingCompany: companyRadioState === OpportunityRadioButtonValues.Existing,
            membershipId: createModificationRenewalOpportunity?.company
                ? createModificationRenewalOpportunity.company.id
                : formValues.membershipId?.value,
            membership: membershipInfos,
            existingContact: contactRadioState === OpportunityRadioButtonValues.Existing,
            contactId: selectedExistingContactId,
            contact:
                contactRadioState === OpportunityRadioButtonValues.Existing
                    ? undefined
                    : getContactFormValues(),
            parentSubscriptionId: createModificationRenewalOpportunity?.parentSubscriptionId,
            isSubscriptionModification: createModificationRenewalOpportunity?.isSubscriptionModification,
            isSubscriptionRenewal: createModificationRenewalOpportunity?.isSubscriptionRenewal,
            status: createModificationRenewalOpportunity ? OpportunityStatusDto.Qualified : null,
        };

        if (createModificationRenewalOpportunity) {
            if (!(await validateFormNoSource(data))) return;
        } else {
            if (!(await validateForm(data))) return;
        }

        try {
            globalLoadingStore.addLoading();
            let createdId = '';

            const request: CreateOpportunityRequestDto = {
                ...data,
                membership: membershipInfos
                    ? {
                          ...membershipInfos,
                          legalContact: {
                              ...companyValues.legalContact,
                              address: { ...legalContactAddress },
                          },
                      }
                    : undefined,
            };

            if (opportunity === undefined)
                createdId = await opportunityService.createOpportunity(request);

            toastStore.toast({
                type: 'success',
                messageKey:
                    opportunity !== undefined
                        ? 'Opportunity.opportunity_edit_success'
                        : `Opportunity.opportunity_create_success`,
            });
            dismiss(true, createdId);
        } catch (e) {
            if (e.response?.data?.modelState?.['contact.Email']) {
                const errors = new Map<string, string[]>();
                errors.set('contact.email', [e.response?.data?.modelState['contact.Email']]);
                setactiveFormStep(OpportunityFormStep.Contact);
                setContactErrors(errors);
            } else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    //#endregion

    const actionButtons = {
        back: {
            label:
                activeFormStep === OpportunityFormStep.Company ||
                (activeFormStep === OpportunityFormStep.Contact && createModificationRenewalOpportunity)
                    ? t('cancel')
                    : t('back'),
            onClick:
                activeFormStep === OpportunityFormStep.Company ||
                (activeFormStep === OpportunityFormStep.Contact && createModificationRenewalOpportunity)
                    ? exit
                    : onBackClick,
        },
        next: {
            label: activeFormStep === OpportunityFormStep.Opportunity ? t('submit') : t('next'),
            onClick: activeFormStep === OpportunityFormStep.Opportunity ? submit : onNextClick,
        },
    };
    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={
                opportunity !== undefined
                    ? t('Opportunity.opportunity_edit')
                    : t('Opportunity.opportunity_create')
            }
            className="FormModal"
            onCancel={exit}
        >
            <Input hidden defaultValue={opportunity?.id} value={opportunity?.id} />
            <div className="CreateOpportunity" ref={modalRef}>
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <FormStep Steps={formSteps} />
                        </Col>
                    </Row>

                    {!createModificationRenewalOpportunity &&
                        activeFormStep === OpportunityFormStep.Company && (
                            <Row className="radio-buttons" gutter={formGutter}>
                                <Col span={24}>
                                    <Radio.Group
                                        onChange={(e): void => {
                                            form.resetFields(['membershipId']);
                                            setSelectedExistingMembershipId(undefined);
                                            setCompanyRadioState(e.target.value);
                                        }}
                                        value={companyRadioState}
                                    >
                                        <Radio
                                            value={OpportunityRadioButtonValues.New}
                                        >{`New ${activeFormStep.toLowerCase()}`}</Radio>
                                        <Radio
                                            value={OpportunityRadioButtonValues.Existing}
                                        >{`Existing ${activeFormStep.toLowerCase()}`}</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                        )}

                    {activeFormStep === OpportunityFormStep.Contact && (
                        <Row className="radio-buttons" gutter={formGutter}>
                            <Col span={24}>
                                <Radio.Group
                                    onChange={(e): void => {
                                        form.resetFields(['contactId']);
                                        setContactRadioState(e.target.value);
                                    }}
                                    value={contactRadioState}
                                >
                                    <Radio
                                        value={OpportunityRadioButtonValues.New}
                                    >{`New ${activeFormStep.toLowerCase()}`}</Radio>
                                    <Radio
                                        value={OpportunityRadioButtonValues.Existing}
                                    >{`Existing ${activeFormStep.toLowerCase()}`}</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    )}

                    {!createModificationRenewalOpportunity && (
                        <div
                            className={
                                activeFormStep === OpportunityFormStep.Company ? '' : 'd-none'
                            }
                        >
                            <CompanyFormSection
                                radioButtonValue={companyRadioState}
                                errors={companyErrors}
                                handleOnCompanyChange={(value: any): void =>
                                    setSelectedExistingMembershipId(value.value)
                                }
                                handleOnCompanyChangeLabel={(value: string): void =>
                                    setCompanyName(value)
                                }
                                selectedMembershipId={selectedExistingMembershipId}
                                setImageDetails={setCompanyImageDetails}
                            />
                        </div>
                    )}

                    <div className={activeFormStep === OpportunityFormStep.Contact ? '' : 'd-none'}>
                        <ContactFormSection
                            setImageDetails={setContactImageDetails}
                            radioButtonValue={contactRadioState}
                            errors={contactErrors}
                            handleOnContactChange={(value: any): void =>
                                setSelectedExistingContactId(value.value)
                            }
                            handleOnContactLabelChange={(value: string): void =>
                                setContactName(value)
                            }
                            handleOnFirstNameChange={(value: string): void =>
                                setContactFirstName(value)
                            }
                            handleOnLastNameChange={(value: string): void =>
                                setContactLastName(value)
                            }
                            selectedExistingMembershipId={selectedExistingMembershipId}
                            selectedExistingContactId={selectedExistingContactId}
                        />
                    </div>

                    <div
                        className={
                            activeFormStep === OpportunityFormStep.Opportunity ? '' : 'd-none'
                        }
                    >
                        <OpportunityFormSection
                            errors={createModificationRenewalOpportunity ? errorsNoSource : errors}
                            leadOwner={ownerAccount}
                            leadDescription={lead?.description || undefined}
                            createModificationRenewalOpportunityOwner={
                                createModificationRenewalOpportunity?.opportunityOwner ??
                                userPermissionsStore.userInfo?.id
                            }
                            isModificationOrRenewal={createModificationRenewalOpportunity !== undefined}
                            leadSource={lead?.source}
                        />
                    </div>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={actionButtons.back.onClick}
                            disabled={
                                existingMembership !== undefined &&
                                activeFormStep === OpportunityFormStep.Contact
                            }
                        >
                            {actionButtons.back.label}
                        </Button>

                        <Button
                            type="primary"
                            className="positive"
                            htmlType="button"
                            onClick={actionButtons.next.onClick}
                        >
                            {actionButtons.next.label}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(CreateOpportunityModal);
