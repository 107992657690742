import { Layout, Menu } from 'antd';
import { BarGraph, Bell, CGTextWhite, User as UserIcon } from 'Components/icons';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { observer } from 'mobx-react';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { BaseDataService } from 'Services/BaseDataService';
import { useService, useStores } from '../../Hooks';
import { theme } from '../../variant';
import {
    Administration,
    Company,
    Lead,
    Location,
    LogOut,
    Plan,
    Units,
    User,
    CGWhite,
} from '../icons';
import './side-navigation.less';

const { Sider } = Layout;
const creditPacksEx = /^\/administration\/credit-packs\/?.*/;
const contactsEx = /^\/contacts\/?.*/;
const dayPassTypesEx = /^\/administration\/day-pass-types\/?.*/;
const leadEx = /^\/leads\/.+/;
const leadOpportunityAssignementEx = /^\/administration\/lead-opportunity-assignment\/?.*/;
const locationEx = /^\/locations\/.+/;
const managerUsersEx = /^\/administration\/manager-users\/?.*/;
const opportunitiesEx = /^\/opportunities\/.+/;
const reportsAcceptedSubscritpionsEx = /^\/reports\/closed-opportunities\/?.*/;
const reportsAccountsReceivableEx = /^\/reports\/accounts-receivable\/?.*/;
const reportsAgedReceivablesStatsEx = /^\/reports\/aged-receivables\/?.*/;
const reportsBookingsEx = /^\/reports\/bookings\/?.*/;
const reportsInventoryEx = /^\/reports\/inventory\/?.*/;
const reportsOccupancyStatsEx = /^\/reports\/occupancy-stats\/?.*/;
const reportsRentRollEx = /^\/reports\/rent-roll\/?.*/;
const reportsRevenueOccupancy = /^\/reports\/revenue-occupancy\/?.*/;
const reportsSecurityDepositsEx = /^\/reports\/security-deposits\/?.*/;
const saleActivitiesEx = /^\/reports\/sale-activities\/?.*/;
const subscriptionPlansEx = /^\/administration\/subscription-plans\/?.*/;
const subscriptionsEx = /^\/subscriptions\/.+/;
const companiesEx = /^\/companies\/?.*/;

const SideNavigation: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const { authenticationStore, confirmationModalStore, userPermissionsStore, locationStore } =
        useStores();
    const baseDataService = useService(BaseDataService);

    const onSelectItem = (param: MenuInfo): void => {
        history.push(`${param.key}`);
    };

    const logout = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <LogOut />,
                title: t('LogOut.log_out_confirm_title'),
                message: t('LogOut.log_out_confirm_message'),
                positiveText: t('LogOut.log_out_confirm_positive'),
                negativeText: t('LogOut.log_out_confirm_negative'),
            }))
        )
            return;
        authenticationStore.clearSession();
        userPermissionsStore.clearUserInfo();
        baseDataService.lastCallTime = undefined;
        history.replace('/');
    };
    const userId = authenticationStore.userId;

    const iconProps = {
        fill: theme['secondary-color'],
    };

    const selectedKeys = useCallback((): string[] => {
        const keys = [];

        if (managerUsersEx.test(location.pathname)) {
            keys.push('/administration/manager-users');
        }

        if (subscriptionsEx.test(location.pathname)) {
            keys.push('/subscriptions');
        }

        if (leadEx.test(location.pathname)) {
            keys.push('/leads');
        }

        if (opportunitiesEx.test(location.pathname)) {
            keys.push('/opportunities');
        }

        if (creditPacksEx.test(location.pathname)) {
            keys.push('/administration/credit-packs');
        }

        if (dayPassTypesEx.test(location.pathname)) {
            keys.push('/administration/day-pass-types');
        }

        if (contactsEx.test(location.pathname)) {
            keys.push('/contacts');
        }

        if (companiesEx.test(location.pathname)) {
            keys.push('/companies');
        }

        if (keys.length === 0) keys.push(location.pathname);

        return keys;
    }, [location.pathname]);

    return (
        <Sider breakpoint="xl" className="SideNavigation" width={300}>
            <Menu
                theme="dark"
                defaultSelectedKeys={['/activity-board']}
                mode="inline"
                selectedKeys={selectedKeys()}
                onSelect={onSelectItem}
                inlineIndent={42}
            >
                <Menu.Item className="logged-user" key={`/profile/${userId}`}>
                    <ImageWithPlaceholder
                        width="32"
                        height="32"
                        defaultImg={<UserIcon />}
                        imgSrc={userPermissionsStore.userInfo?.imageUrl}
                    />
                    {`${userPermissionsStore.userInfo?.firstName || ''} ${
                        userPermissionsStore.userInfo?.lastName || ''
                    }`}
                </Menu.Item>

                <Menu.Item key={'/'} className="logo-container" icon={<CGWhite {...iconProps} />}>
                    <CGTextWhite {...iconProps} />{' '}
                    <span className="brand-name-desktop">{t('brand_name')}</span>
                </Menu.Item>

                <Menu.Item key="/activity-board" icon={<Bell {...iconProps} />}>
                    {t('activity_board')}
                </Menu.Item>

                <Menu.Item key="/locations" icon={<Location {...iconProps} />}>
                    {t('Location.location_title')}
                </Menu.Item>

                {locationEx.test(location.pathname) && (
                    <Menu.Item
                        level={2}
                        key={location.pathname}
                        icon={<span className="anticon"></span>}
                    >
                        <span className="location-name">
                            {locationStore.locations
                                .filter((x) => x.id === location.pathname.split('/')[2])
                                .map((x) => x.name)}
                        </span>
                    </Menu.Item>
                )}

                <Menu.Item key="/contacts" icon={<User {...iconProps} />}>
                    <Link to="/contacts">{t('User.contacts_label')}</Link>
                </Menu.Item>

                <Menu.Item key="/companies" icon={<Company {...iconProps} />}>
                    <Link to="/companies">{t('companies')}</Link>
                </Menu.Item>

                <Menu.Item key="/subscriptions" icon={<Units {...iconProps} />}>
                    <Link to="/subscriptions">{t('subscriptions')}</Link>
                </Menu.Item>

                <Menu.Item key="/leads" icon={<Lead {...iconProps} />}>
                    <Link to="/leads">{t('Lead.lead_title_plural')}</Link>
                </Menu.Item>

                <Menu.Item key="/opportunities" icon={<Plan {...iconProps} />}>
                    <Link to="/opportunities">{t('Opportunity.opportunities')}</Link>
                </Menu.Item>

                <Menu.Item key="/reports" icon={<BarGraph {...iconProps} />}>
                    {t('reports')}
                </Menu.Item>

                {reportsAcceptedSubscritpionsEx.test(location.pathname) && (
                    <Menu.Item
                        level={2}
                        key="/reports/closed-opportunities"
                        icon={<span className="anticon" />}
                    >
                        {t('Reports.closed_opportunities')}
                    </Menu.Item>
                )}

                {reportsInventoryEx.test(location.pathname) && (
                    <Menu.Item
                        level={2}
                        key="/reports/inventory"
                        icon={<span className="anticon" />}
                    >
                        {t('Reports.unit_inventory')}
                    </Menu.Item>
                )}

                {reportsOccupancyStatsEx.test(location.pathname) && (
                    <Menu.Item
                        level={2}
                        key="/reports/occupancy-stats"
                        icon={<span className="anticon" />}
                    >
                        {t('Reports.occupancy_stats')}
                    </Menu.Item>
                )}
                {reportsRentRollEx.test(location.pathname) && (
                    <Menu.Item
                        level={2}
                        key="/reports/rent-roll"
                        icon={<span className="anticon" />}
                    >
                        {t('Reports.rent_roll')}
                    </Menu.Item>
                )}

                {reportsBookingsEx.test(location.pathname) && (
                    <Menu.Item
                        level={2}
                        key="/reports/bookings"
                        icon={<span className="anticon" />}
                    >
                        {t('Reports.report_bookings')}
                    </Menu.Item>
                )}

                {reportsSecurityDepositsEx.test(location.pathname) && (
                    <Menu.Item
                        level={2}
                        key="/reports/security-deposits"
                        icon={<span className="anticon" />}
                    >
                        {t('Reports.security_deposits')}
                    </Menu.Item>
                )}

                {reportsAccountsReceivableEx.test(location.pathname) && (
                    <Menu.Item
                        level={2}
                        key="/reports/accounts-receivable"
                        icon={<span className="anticon" />}
                    >
                        {t('Reports.accounts_receivable')}{' '}
                    </Menu.Item>
                )}

                {reportsRevenueOccupancy.test(location.pathname) && (
                    <Menu.Item
                        level={2}
                        key="/reports/revenue-occupancy"
                        icon={<span className="anticon" />}
                    >
                        {t('Reports.revenue_occupancy')}
                    </Menu.Item>
                )}

                {reportsAgedReceivablesStatsEx.test(location.pathname) && (
                    <Menu.Item
                        level={2}
                        key="/reports/aged-receivables"
                        icon={<span className="anticon" />}
                    >
                        {t('Reports.aged_receivables')}
                    </Menu.Item>
                )}

                {saleActivitiesEx.test(location.pathname) && (
                    <Menu.Item
                        level={2}
                        key="/reports/sale-activities"
                        icon={<span className="anticon" />}
                    >
                        {t('Reports.new_sale_activities')}
                    </Menu.Item>
                )}

                <Menu.Item key="/check-ins" icon={<Company {...iconProps} />}>
                    {t('CheckIn.check_ins_title')}
                </Menu.Item>

                {userPermissionsStore.userIsAdministrator && [
                    <Menu.Item key="/administration" icon={<Administration {...iconProps} />}>
                        {t('Administration.administration_title')}
                    </Menu.Item>,

                    managerUsersEx.test(location.pathname) && (
                        <Menu.Item
                            level={2}
                            key="/administration/manager-users"
                            icon={<span className="anticon" />}
                        >
                            {t('ManagerUser.managers')}
                        </Menu.Item>
                    ),

                    leadOpportunityAssignementEx.test(location.pathname) && (
                        <Menu.Item
                            level={2}
                            key="/administration/lead-opportunity-assignment"
                            icon={<span className="anticon" />}
                        >
                            {t('Administration.lead_opportunity_assignment')}
                        </Menu.Item>
                    ),

                    subscriptionPlansEx.test(location.pathname) && (
                        <Menu.Item
                            level={2}
                            key="/administration/subscription-plans"
                            icon={<span className="anticon" />}
                        >
                            {t('Administration.subscription_plans_title_template')}
                        </Menu.Item>
                    ),

                    creditPacksEx.test(location.pathname) && (
                        <Menu.Item
                            level={2}
                            key="/administration/credit-packs"
                            icon={<span className="anticon" />}
                        >
                            {t('Administration.credit_packs')}
                        </Menu.Item>
                    ),

                    dayPassTypesEx.test(location.pathname) && (
                        <Menu.Item
                            level={2}
                            key="/administration/day-pass-types"
                            icon={<span className="anticon" />}
                        >
                            {t('DayPass.day_pass_types')}
                        </Menu.Item>
                    ),
                ]}
            </Menu>

            <Menu
                className="log-out"
                theme="dark"
                mode="inline"
                onSelect={logout}
                selectedKeys={['']}
            >
                <Menu
                    className="log-out"
                    theme="dark"
                    mode="inline"
                    onSelect={logout}
                    selectedKeys={['']}
                >
                    <Menu.Item key={'logout'} icon={<LogOut {...iconProps} />}>
                        {t('LogOut.log_out')}
                    </Menu.Item>
                </Menu>
            </Menu>
        </Sider>
    );
});

export default SideNavigation;
