import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Pdf: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="16" height="20" viewBox="0 0 16 20" fill="none" {...props}>
                <path
                    d="M15.5284 4.5L10.8062 0.0277778V0H0.333984V20H15.5145V4.52778L15.5284 4.5ZM14.1118 4.41667L10.8479 4.48611L10.9034 1.33333L14.1118 4.41667ZM14.6812 19.1667H1.16732V0.833333H9.97287V5.36111H14.6812V19.1667Z"
                    fill={fill}
                />
                <path
                    d="M4.48687 16.2778C4.25076 16.2778 4.0702 16.1944 3.9452 16.1111C3.45909 15.7917 3.45909 15.3611 3.62576 15.0556C3.95909 14.4444 5.12576 13.9444 6.0702 13.6389C6.51465 12.7917 6.93132 11.7917 7.22298 10.9167C6.84798 10.0972 6.65354 9.33334 6.75076 8.83334C6.84798 8.27778 7.27854 7.98612 7.88965 8.05556C8.01465 8.06945 8.29243 8.13889 8.40354 8.55556C8.52854 9.04167 8.34798 9.95834 8.05632 10.8889C8.40354 11.5694 8.86187 12.3194 9.34798 12.9583C10.1535 12.8889 10.9869 12.9028 11.5702 13.1528C12.223 13.4306 12.4591 13.9722 12.2369 14.625C12.0285 15.2361 11.5702 15.5139 10.9869 15.3611C10.4174 15.2083 9.69521 14.5833 9.00076 13.75C8.47298 13.8194 7.87576 13.9306 7.18132 14.0972C6.97298 14.1528 6.77854 14.2083 6.58409 14.2639C6.19521 14.9583 5.72298 15.6111 5.22298 15.9722C4.95909 16.2083 4.6952 16.2778 4.48687 16.2778ZM4.30632 15.4306C4.3202 15.4444 4.33409 15.4583 4.36187 15.4722C4.40354 15.5 4.54243 15.5833 4.88965 15.2917C5.05632 15.1389 5.23687 14.9444 5.41743 14.7222C4.73687 15.0139 4.37576 15.2917 4.30632 15.4306ZM9.97298 13.7083C10.4174 14.1944 10.8619 14.5556 11.1952 14.6389C11.3202 14.6667 11.4174 14.6944 11.5146 14.4028C11.598 14.1389 11.5147 14.0556 11.4869 14.0139C11.3202 13.8472 10.8202 13.6944 9.97298 13.7083ZM7.72298 11.8889C7.66743 12.0417 7.61187 12.1806 7.55632 12.3194C7.44521 12.5833 7.29243 12.9444 7.09798 13.3472C7.13965 13.3333 7.16743 13.3333 7.19521 13.3194C7.44521 13.2639 7.91743 13.1528 8.48687 13.0556C8.20909 12.6944 7.94521 12.2917 7.72298 11.8889ZM7.68132 8.83334C7.54243 8.83334 7.52854 8.90278 7.51465 8.98611C7.48687 9.16667 7.51465 9.41667 7.58409 9.69445C7.66743 9.31945 7.70909 9 7.68132 8.83334Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Pdf;
