import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CG: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg
                width="12em"
                height="1em"
                viewBox="0 0 444 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.6299 5.02741L27.1508 5.28191V1.85324L26.9524 1.74033C25.082 0.682868 21.171 0 16.9887 0C7.46491 0 0 7.4506 0 16.9606C0 26.7824 7.41034 34.1899 17.2368 34.1899C22.3749 34.1899 24.9266 33.4031 26.9177 32.5536L27.1508 32.4532V28.9994L26.6299 29.2557C24.0567 30.5193 21.2371 31.0839 17.4848 31.0839C9.45103 31.0839 2.91717 24.7481 2.91717 16.9606C2.91717 9.16225 9.29393 3.05229 17.4336 3.05229C21.6869 3.05229 24.1857 3.8409 26.6299 5.02741Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M58.5742 20.5556C58.5742 25.8825 53.8557 30.3808 48.269 30.3808C42.5656 30.3808 37.9249 25.956 37.9249 20.518C37.9249 15.186 42.5656 10.8482 48.269 10.8482C53.8557 10.8482 58.5742 15.2936 58.5742 20.5556ZM48.269 8.04469C40.8162 8.04469 35.1955 13.4075 35.1955 20.518C35.1955 27.7396 40.8162 33.1844 48.269 33.1844C55.6002 33.1844 61.3408 27.62 61.3408 20.518C61.3408 13.5237 55.6002 8.04469 48.269 8.04469Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M97.0755 9.05028L85.5661 25.0348L74.1827 9.12286L74.1294 9.05028H71.3967V33.1844H74.1569V13.8491L84.6875 28.7657L84.7392 28.84H86.28L96.83 14.1031V33.1844H99.5531V9.05028H97.0755Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M137.299 9.05028L125.791 25.0348L114.408 9.12286L114.353 9.05028H111.62V33.1844H114.38V13.8491L124.911 28.7657L124.963 28.84H126.503L137.053 14.1031V33.1844H139.777V9.05028H137.299Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M175.221 20.5556C175.221 25.8825 170.501 30.3808 164.915 30.3808C159.213 30.3808 154.573 25.956 154.573 20.518C154.573 15.186 159.213 10.8482 164.915 10.8482C170.501 10.8482 175.221 15.2936 175.221 20.5556ZM164.915 8.04469C157.462 8.04469 151.844 13.4075 151.844 20.518C151.844 27.7396 157.462 33.1844 164.915 33.1844C172.245 33.1844 177.989 27.62 177.989 20.518C177.989 13.5237 172.245 8.04469 164.915 8.04469Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M206.28 9.05028V28.3579L188.779 9.05028H186.034V33.1844H188.953V13.4482L206.804 33.1844H209.162V9.05028H206.28Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M222.089 17.0134C222.089 9.32046 228.496 3.0632 236.37 3.0632C240.135 3.0632 242.561 3.57045 245.318 4.93447L245.834 5.18899V1.86409L245.62 1.75655C244.329 1.10053 241.354 0 235.932 0C226.562 0 219.218 7.4725 219.218 17.0134C219.218 26.8053 226.508 34.1899 236.175 34.1899C242.418 34.1899 246.584 32.4119 249.175 31.073L249.385 30.9654V19.6214H246.514V29.0637L246.43 29.1049C244.851 29.8595 241.453 31.1267 236.419 31.1267C228.384 31.1267 222.089 24.9268 222.089 17.0134Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M278.119 15.8608C278.119 18.263 276.035 20.0302 272.402 20.7093C271.363 20.8825 270.11 20.9691 268.572 20.9691C267.046 20.9691 264.238 20.8519 262.406 20.7382V10.98C264.591 10.8408 267.276 10.8306 268.062 10.8306C274.829 10.8306 278.119 12.4756 278.119 15.8608ZM275.301 22.9553C278.852 21.7398 281.047 18.9964 281.047 15.747C281.047 10.564 276.746 8.04469 267.904 8.04469C265.655 8.04469 262.123 8.16353 260.005 8.27728L259.441 8.30614V32.1788H262.406V23.6395C264.441 23.743 267.295 23.7549 268.454 23.7549C269.407 23.7549 270.349 23.7414 271.111 23.7159C271.453 23.704 271.916 23.6531 272.222 23.6157L278.076 32.1788H281.564L275.301 22.9553Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M310.975 20.5556C310.975 25.8825 306.257 30.3808 300.67 30.3808C294.966 30.3808 290.329 25.956 290.329 20.518C290.329 15.186 294.966 10.8482 300.67 10.8482C306.257 10.8482 310.975 15.2936 310.975 20.5556ZM300.67 8.04469C293.216 8.04469 287.598 13.4075 287.598 20.518C287.598 27.7396 293.216 33.1844 300.67 33.1844C308 33.1844 313.743 27.62 313.743 20.518C313.743 13.5237 308 8.04469 300.67 8.04469Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M344.035 9.05028V22.852C344.035 27.2665 340.47 30.349 335.361 30.349C330.256 30.349 326.691 27.2665 326.691 22.8503V9.05028H323.799V22.8503C323.799 28.8388 328.662 33.1844 335.361 33.1844C342.064 33.1844 346.927 28.8388 346.927 22.8503V9.05028H344.035Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M377.231 9.05028V28.3579L359.731 9.05028H356.983V33.1844H359.901V13.4482L377.752 33.1844H380.112V9.05028H377.231Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M395.028 12.1822L395.155 12.1723C396.808 12.0378 399.578 11.8136 401.788 11.8136C407.409 11.8136 413.462 14.6965 413.462 21.0252C413.462 26.668 408.922 30.4592 402.165 30.4592C400.177 30.4592 396.93 30.2782 395.028 30.1022V12.1822ZM401.788 9.05028C399.024 9.05028 394.72 9.4123 392.684 9.65807L392.179 9.71786V32.6347L392.691 32.6928C395.063 32.9602 399.419 33.1844 402.205 33.1844C410.774 33.1844 416.313 28.4117 416.313 21.0252C416.313 12.1872 408.487 9.05028 401.788 9.05028Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M436.453 19.5303L430.635 18.2251C426.583 17.3347 425.413 16.5324 425.413 14.65C425.413 12.2428 428.553 10.8615 434.029 10.8615C437.416 10.8615 439.424 11.3439 440.939 11.8145L441.738 12.0634V8.99943L441.3 8.87585C439.951 8.49159 437.193 8.04469 433.866 8.04469C423.842 8.04469 422.346 12.2564 422.346 14.7651C422.346 18.6991 425.79 20.0043 428.791 20.7034L434.968 22.122C439.717 23.2257 440.358 24.8592 440.358 26.3099C440.358 28.8897 436.94 30.3692 430.981 30.3692C428.212 30.3692 425.836 29.9647 423.501 29.098L422.669 28.7882V31.9046L423.079 32.04C424.393 32.4683 427.21 33.1844 431.34 33.1844C439.498 33.1844 443.464 30.8212 443.464 25.9646C443.464 22.904 440.842 20.4986 436.453 19.5303Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default CG;
