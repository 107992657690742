import { Button } from 'antd';
import React, { FunctionComponent } from 'react';
import './status-bar-navigation.less';

interface ButtonsListProps {
    buttonLabel: string;
    buttonOnClick: () => void;
}

export interface StatusBarNavigationProps {
    title: string;
    buttonsList?: ButtonsListProps[];
}

const StatusBarNavigation: FunctionComponent<StatusBarNavigationProps> = ({
    title,
    buttonsList,
}) => {
    return (
        <div className={`StatusBarNavigation`}>
            {title}

            {buttonsList &&
                buttonsList.map((button, i) => (
                    <Button
                        key={i}
                        type="primary"
                        className="change-status-btn"
                        onClick={button.buttonOnClick}
                    >
                        {button.buttonLabel}
                    </Button>
                ))}
        </div>
    );
};

export default StatusBarNavigation;
