import { inject } from 'aurelia-dependency-injection';
import { CampusProxy } from 'Api/Features/Campuses/CampusProxy';
import { ApiService } from './ApiService';
import { GetCampusOnboardingConfigResponseDto } from 'Api/Features/Campuses/Dtos/GetCampusOnboardingConfigResponseDto';
import { UpdateCampusOnboardingConfigRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusOnboardingConfigRequestDto';
import { LightCampusDetailDto } from 'Api/Features/Campuses/Dtos/LightCampusDetailDto';
import { GetCampusesRequestDto } from 'Api/Features/Campuses/Dtos/GetCampusesRequestDto';

@inject(CampusProxy)
export class LocationService extends ApiService {
    constructor(private readonly campusProxy: CampusProxy) {
        super();
    }

    public async getCampuses(
        request: GetCampusesRequestDto | null
    ): Promise<Array<LightCampusDetailDto>> {
        const response: Array<LightCampusDetailDto | null> | null = await this.campusProxy.getCampuses(
            request
        );

        return (response || []).filter((x): x is LightCampusDetailDto => x !== null);
    }

    public async getCampusOnboardingConfig(
        campusId: string | null
    ): Promise<GetCampusOnboardingConfigResponseDto | null> {
        const data: GetCampusOnboardingConfigResponseDto | null = await this.campusProxy.getCampusOnboardingConfig(
            campusId
        );
        return data;
    }

    public async updateCampusOnboardingConfig(
        campusId: string | null,
        request: UpdateCampusOnboardingConfigRequestDto | null
    ): Promise<void> {
        await this.campusProxy.updateCampusOnboardingConfig(campusId, request);
    }
}
