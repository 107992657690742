import React, { FunctionComponent } from 'react';
import { Select } from 'antd';

import { LocationFilter } from '../../../Models/Filters/LocationFilter';
import { LightCampusDto } from '../../../Api/Features/Campuses/Dtos/LightCampusDto';
import { ALL_LOCATIONS, UNASSIGNED_LOCATIONS } from '../../../Models/Constants';

const { Option } = Select;

interface LocationSelectProps {
    availableLocations: LightCampusDto[];
    onLocationChange: (locationId: string) => void;
    value?: string | undefined;
    includeUnassignedLocations?: boolean;
}

const mapLocations = (locationDtos: LightCampusDto[]): LocationFilter[] =>
    locationDtos.map((dto) => {
        const filter: LocationFilter = {
            id: dto.id!,
            imageUrl: dto.mainImageUrl!,
            name: dto.name!,
        };
        return filter;
    });

const LocationSelect: FunctionComponent<LocationSelectProps> = ({
    availableLocations,
    onLocationChange,
    value,
    includeUnassignedLocations
}) => {
    const filters: LocationFilter[] = [
        {
            id: ALL_LOCATIONS,
            imageUrl: '',
            name: 'All locations',
        },
    ]
        .concat(
            includeUnassignedLocations
                ? { id: UNASSIGNED_LOCATIONS, imageUrl: '', name: 'Unassigned locations' }
                : []
        )
        .concat(mapLocations(availableLocations));
    return (
        <Select
            defaultValue="all"
            onChange={onLocationChange}
            style={{ width: '100%' }}
            value={value}
        >
            {filters.map((locFilter) => (
                <Option key={locFilter.id} value={locFilter.id}>
                    {locFilter.name}
                </Option>
            ))}
        </Select>
    );
};

export default LocationSelect;
