// GENERATED FILE - DO NOT MODIFY
import { CreateLeadFileRequestDto } from 'Api/Features/Leads/Dtos/CreateLeadFileRequestDto';
import { GetLeadFilesRequestDto } from 'Api/Features/Leads/Dtos/GetLeadFilesRequestDto';
import { GetLeadFilesResponseDto } from 'Api/Features/Leads/Dtos/GetLeadFilesResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class LeadFileProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getLeadFiles(leadId: string, request: GetLeadFilesRequestDto | null): Promise<GetLeadFilesResponseDto | null> {
        const uri = this.buildUri(
            "/leads/{leadId}/files",
            { leadId: leadId },
            { ...request || undefined }
        );

        const data: GetLeadFilesResponseDto | null = await this.httpClient.get<GetLeadFilesResponseDto | null>(uri);
        return data;
    }

    public async createLeadFile(leadId: string, request: CreateLeadFileRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/leads/{leadId}/files",
            { leadId: leadId },
            null
        );

        const data: string = await this.httpClient.post<CreateLeadFileRequestDto | null, string>(uri, request);
        return data;
    }

    public async deleteLeadFile(leadId: string, fileId: string): Promise<void> {
        const uri = this.buildUri(
            "/leads/{leadId}/files/{fileId}",
            { leadId: leadId, fileId: fileId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}