import { Typography } from 'antd';
import ButtonLinkWithIcon from 'Components/button-link-with-icon/button-link-with-icon';
import { Administration as AdministrationIcon } from 'Components/icons';
import { ListSectionHeader } from 'Components/list-section-header';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { images, theme } from 'variant';
import './index.less';

const { usersHeader } = images;

const Administration: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <>
            <ListSectionHeader
                title={t('Administration.administration_title')}
                subTitle=""
                defaultImg={<AdministrationIcon fill={theme['primary-color']} />}
                backgroundImageUrl={usersHeader}
            />
            <div className="Administration">
                <div className="button-list-wrapper">
                    <div>
                        <Typography.Title level={2}>
                            {t('Administration.location_common_data')}
                        </Typography.Title>

                        <ButtonLinkWithIcon
                            linkTo="administration/subscription-plans"
                            iconName="Plan"
                            label={t('Administration.subscription_plans_title_template')}
                        />

                        <ButtonLinkWithIcon
                            linkTo="administration/day-pass-types"
                            iconName="Plan"
                            label={t('DayPass.day_pass_types')}
                        />

                        <ButtonLinkWithIcon
                            linkTo="administration/credit-packs"
                            iconName="Credit"
                            label={t('Administration.credit_packs')}
                        />
                    </div>

                    <div>
                        <Typography.Title level={2}>{t('management')}</Typography.Title>

                        <ButtonLinkWithIcon
                            linkTo="administration/lead-opportunity-assignment"
                            iconName="Plan"
                            label={t('Administration.lead_opportunity_assignment')}
                        />

                        <ButtonLinkWithIcon
                            linkTo="administration/manager-users"
                            iconName="User"
                            label={t('ManagerUser.managers')}
                        />
                    </div>

                    <div>
                        <Typography.Title level={2}>{t('settings')}</Typography.Title>

                        <ButtonLinkWithIcon
                            linkTo="administration/parameters"
                            iconName="AdministrationNut"
                            label={t('Administration.parameters')}
                        />

                        <ButtonLinkWithIcon
                            linkTo="administration/integration"
                            iconName="Monitor"
                            label={t('integrations')}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Administration;
