import { Layout } from 'antd';
import { SubscriptionProposalAnswerTriggerDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionProposalAnswerTriggerDto';
import { SubscriptionProposalStatusDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionProposalStatusDto';
import { EmptyPage } from 'Components/empty-page';
import { ItemWithButtons } from 'Components/item-with-buttons';
import { ButtonsProps, ItemWithButtonsProposalDetails, ProposalAnswerSubTitle } from 'Components/item-with-buttons/item-with-buttons';
import { getSubscriptionProposalStatusTagColor } from 'Components/tag/tag';
import { useService, useStores } from 'Hooks';
import { DISPLAY_DATE_FORMAT } from 'Models/Constants';
import { SubscriptionProposal } from 'Models/Proposals/SubscriptionProposal';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { SubscriptionProposalService } from 'Services/SubscriptionProposalService';
import { showFile } from 'Utils';
import { currencyFormatter } from 'Utils/NumberUtils';
import {  displayFormatedDateTime } from 'Utils/TimeUtils';
import { OpportunityRouteProps } from '..';
import ProposalResponseModal from '../modals/proposal-response';
import './index.less';

interface ProposalsProps {
    routeProps?: OpportunityRouteProps;
}
type IdProp = string | null | undefined;

const { Content } = Layout;

const Proposals: FunctionComponent<ProposalsProps> = ({ routeProps }) => {
    const history = useHistory();
    const {
        data,
        setBreadcrumbs,
        setCreateProposalModalOpen,
        setLostModalOpen,
        proposalList,
        fetchData,
        fetchProposalList,
    } = routeProps!;
    const subscriptionProposalService = useService(SubscriptionProposalService);
    const { globalLoadingStore, toastStore } = useStores();
    const [proposalResponseModalOpen, setProposalResponseModalOpen] = useState(false);
    const [answeringProposalId, setAnsweringProposalId] = useState<IdProp>();

    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                nameKey: 'Opportunities',
                path: 'opportunities',
            },
            {
                nameKey: data?.name ?? data?.membership?.name ?? '',
                path: `${id}/dashboard`,
            },
            {
                nameKey: `Proposals`,
                path: `proposals`,
            },
        ]);
    }, [data, id, setBreadcrumbs])
    
    const onExportInPdfClick = async (proposalId: string): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            const document = await subscriptionProposalService.exportSubscriptionProposal(
                proposalId
            );

            showFile(document, `${data?.membership?.name} - proposal`);
        } catch (e) {
            if (!e.treated) {
                toastStore.toast({
                    type: 'error',
                    messageKey: 'Proposal.proposal_pdf_error',
                });
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const onSendAgainButtonClick = async (proposalId: IdProp): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            if (proposalId)
                await subscriptionProposalService.resendSubscriptionProposal(proposalId);

            toastStore.toast({
                type: 'success',
                messageKey: 'Proposal.proposal_has_been_sent_by_email',
            });
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const getViewProposalButton = (proposalId: IdProp): ButtonsProps => {
        return {
            label: t(`Proposal.proposal_cta_label_view_proposal`),
            primary: true,
            onClick: (): void => history.push(`/opportunities/${id}/dashboard/proposals/${proposalId}`),
        };
    };

    const getExportInPdfButton = (id: IdProp): ButtonsProps => {
        return {
            label: t(`Proposal.proposal_cta_label_export`),
            onClick: (): Promise<void> => onExportInPdfClick(`${id}`),
        };
    };

    const getSendAgainButton = (id: IdProp): ButtonsProps => {
        return {
            label: t(`Proposal.proposal_cta_label_send_again`),
            onClick: (): Promise<void> => onSendAgainButtonClick(id),
        };
    };

    const getResponseButton = (id: IdProp): ButtonsProps => {
        return {
            label: t(`Proposal.proposal_answer_title`),
            onClick: (): void => {
                setAnsweringProposalId(id);
                setProposalResponseModalOpen(true);
            }
        }
    };

    const proposalAnswerSubTitle = (proposal: SubscriptionProposal): ProposalAnswerSubTitle | undefined => {
        if(!proposal.answeredByUser) return undefined;
        
        const answerSubTitle: ProposalAnswerSubTitle = {
            answeredBy: `${proposal.answeredByUser?.firstName ?? ''} ${
                proposal.answeredByUser?.lastName ?? ''
            } `,
            answerAction:
                proposal.status === SubscriptionProposalStatusDto.Accepted
                    ? `${t('Opportunity.opportunity_proposal_accepted_label')} `
                    : proposal.status === SubscriptionProposalStatusDto.Refused
                    ? `${t('Opportunity.opportunity_proposal_refused_label')} `
                    : proposal.status === SubscriptionProposalStatusDto.Cancelled
                    ? `${t('Opportunity.opportunity_proposal_cancelled_label')} `
                    : proposal.status === SubscriptionProposalStatusDto.Void
                    ? `${t('Opportunity.opportunity_proposal_voided_label')} `
                    : undefined,
            answeredOnBehalf: proposal.answeredOnBehalfOfAccount
                ? t('Opportunity.opportunity_proposal_on_behalf', {
                      param1: `${proposal.answeredOnBehalfOfAccount?.firstName} ${proposal.answeredOnBehalfOfAccount?.lastName} `,
                  })
                : undefined,
            answerTrigger:
                proposal.answerTrigger &&
                proposal.answerTrigger !== SubscriptionProposalAnswerTriggerDto.Manual
                    ? t(`Proposal.proposal_refusal_trigger_${proposal.answerTrigger}`)
                    : undefined,
        };
        return answerSubTitle;
    }

    const getDateStringForProposal = (proposal: SubscriptionProposal): string => {
        if(!proposal.endDate)
            return moment.utc(proposal.startDate).format(DISPLAY_DATE_FORMAT)
        else
            return `${moment.utc(proposal.startDate).format(DISPLAY_DATE_FORMAT)} to ${moment.utc(proposal.endDate).format(DISPLAY_DATE_FORMAT)}`
    }

    const itemsProposalList = proposalList?.map((proposal: SubscriptionProposal) => {
        return {
            title: proposal.title ?? '',
            tag: {
                label: t(`Proposal.status_${proposal.status}`),
                color: getSubscriptionProposalStatusTagColor(proposal.status),
            },
            buttonsList:
                proposal.status === SubscriptionProposalStatusDto.Pending
                    ? [
                          getSendAgainButton(proposal.id),
                          getViewProposalButton(proposal.id),
                          getExportInPdfButton(proposal.id),
                          getResponseButton(proposal.id),
                      ]
                    : [getExportInPdfButton(proposal.id), getViewProposalButton(proposal.id)],
            proposalAnswerSubTitle: proposalAnswerSubTitle(proposal),
            proposalAnswerDate: proposal.answerDate
                ? displayFormatedDateTime(proposal.answerDate)
                : undefined,
            proposalDetails: {
                planName: proposal.plan?.name,
                planPrice: currencyFormatter(proposal.periods?.[0]?.price ?? 0),
                dateString: getDateStringForProposal(proposal),
            } as ItemWithButtonsProposalDetails,
        };
    });

    useEffect(() => {
        fetchProposalList();
    }, [fetchProposalList]);

    useEffect(() => {
        fetchData();
    }, [proposalResponseModalOpen, fetchData]);

    return (
        <>
            <Content className="Proposals">
                {(itemsProposalList &&
                    itemsProposalList?.length > 0 &&
                    itemsProposalList.map((proposal, i) => (
                        <ItemWithButtons
                            key={i}
                            title={proposal.title}
                            tag={proposal.tag}
                            buttonsList={proposal.buttonsList}
                            proposalAnswerSubTitle={proposal.proposalAnswerSubTitle}
                            proposalAnswerDate={proposal.proposalAnswerDate}
                            proposalDetails={proposal.proposalDetails}
                        />
                    ))) || (
                    <EmptyPage
                        iconName={'Plan'}
                        title={t(`Proposal.no_proposals_made`)}
                        subTitle={t(`Proposal.make_proposal_in_dashboard`)}
                    />
                )}
            </Content>

            {proposalResponseModalOpen && (
                <ProposalResponseModal
                    visible={proposalResponseModalOpen}
                    onComplete={(): void => {
                        setLostModalOpen(false);
                        setProposalResponseModalOpen(false);
                        fetchProposalList();
                    }}
                    opportunity={data}
                    isAccepting={true}
                    setCreateProposalModalOpen={setCreateProposalModalOpen}
                    setLostModalOpen={setLostModalOpen}
                    proposalId={answeringProposalId}
                    fetchProposalList={fetchProposalList}
                />
            )}
        </>
    );
};

export default Proposals;
