// GENERATED FILE - DO NOT MODIFY
import { GetSalesReportRequestDto } from 'Api/Features/Reports/Dtos/Sales/GetSalesReportRequestDto';
import { SalesReportDto } from 'Api/Features/Reports/Dtos/Sales/SalesReportDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class SalesReportProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getSalesReport(request: GetSalesReportRequestDto | null): Promise<SalesReportDto | null> {
        const uri = this.buildUri(
            "/sales-report",
            null,
            { ...request || undefined }
        );

        const data: SalesReportDto | null = await this.httpClient.get<SalesReportDto | null>(uri);
        return data;
    }

    public async exportSalesReport(request: GetSalesReportRequestDto | null): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/sales-report/export",
            null,
            { ...request || undefined }
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }
}