import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Download: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 60 60" fill="none" {...props}>
                <path
                    d="M46.894 26.438l-2.529-1.555-13.264 13.85V0h-3.197v38.738L14.64 24.883l-2.534 1.555L29.5 44.605l17.394-18.167z"
                    fill={fill}
                />
                <path
                    d="M45.906 38.935v7.352h-6.264v2.286h8.55v-7.352h7.252v16.223H3.556V41.221h7.252v7.352h8.546v-2.286h-6.26v-7.352H1V60h57V38.935H45.907z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Download;
