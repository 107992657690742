import * as yup from 'yup';

export const EditSageIntacctIntegrationSchema = yup.object({
    sageLocationEntityId: yup
        .string()
        .nullable()
        .when('accountingSyncEnabled', {
            is: true,
            then: yup.string().required('Errors.required'),
        }),
});
