import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Discount: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    d="M17.6534 4.62159C17.8761 4.39885 18.1718 4.27649 18.4861 4.27649C18.8009 4.27649 19.0962 4.39885 19.3189 4.62107C19.778 5.08045 19.778 5.82743 19.3189 6.28681C19.0962 6.5092 18.8009 6.6319 18.4861 6.6319C18.1718 6.6319 17.8761 6.5092 17.6534 6.28681C17.431 6.06441 17.3083 5.76857 17.3083 5.45437C17.3083 5.13965 17.431 4.84398 17.6534 4.62159ZM18.4865 7.80963C19.1158 7.80963 19.7071 7.56458 20.1519 7.11944C21.07 6.20138 21.07 4.70707 20.1519 3.78866C19.7071 3.34387 19.1158 3.099 18.4865 3.099C17.8575 3.099 17.2659 3.34387 16.8208 3.78866C16.3764 4.23362 16.131 4.82531 16.131 5.4544C16.131 6.08332 16.3764 6.67483 16.8208 7.11944C17.2659 7.56458 17.8575 7.80963 18.4865 7.80963Z"
                    fill={fill}
                />
                <path
                    d="M22.1874 10.3584L10.2706 22.2753L1.66543 13.6707L13.5813 1.75482L22.7439 1.24894L22.1874 10.3584ZM13.0666 0.604037L0 13.6706L10.2703 23.9406L23.3352 10.8758L24 0L13.0666 0.604037Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Discount;
