import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PencilLined: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.2339 3.47697L13.892 4.81664L11.1822 2.10686L12.523 0.764971C12.5997 0.688193 12.6991 0.639749 12.795 0.63186C12.8401 0.628415 12.9237 0.630749 12.9834 0.689304L15.3096 3.01653C15.4122 3.11819 15.3761 3.33364 15.2339 3.47697ZM5.29311 13.4008L2.598 10.7057L10.7376 2.55375L13.4462 5.26142L5.29311 13.4008ZM1.01578 14.9831L2.28311 11.2835L4.713 13.7123L1.01578 14.9831ZM15.7553 2.56964L13.4292 0.243527C13.2543 0.0686377 13.0027 -0.0182512 12.7419 0.00308209C12.4959 0.0235265 12.26 0.136415 12.0761 0.319193L1.87011 10.5421L0 16L5.46578 14.1198L15.6797 3.92286C16.0736 3.52897 16.1074 2.92175 15.7553 2.56964Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default PencilLined;
