import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ExternalLink: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.9999 0L11.8978 4.6875L11.3616 4.55966L11.4382 0.980114L6.34036 6.08523L5.92334 5.66761L11.0212 0.5625L7.44675 0.639205L7.31909 0.102273L11.9999 0Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.8681 12H0V1.125H5.29362V1.63636H0.510638V11.4886H10.3574V6.5625H10.8681V12Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ExternalLink;
