import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import { ListSectionHeader } from 'Components/list-section-header';
import { theme, images } from 'variant';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { useService, useStores } from 'Hooks';
import { useTranslation } from 'react-i18next';
import { Plan } from 'Components/icons';
import { autorun } from 'mobx';
import Table, { TablePaginationConfig } from 'antd/lib/table';
import { FilterStore } from 'Stores';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { TableFilters } from 'Components/table-filters';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { useHistory } from 'react-router';
import { ColumnType } from 'antd/es/table';
import { cleanVal, currencyFormatter } from 'Utils/NumberUtils';
import ReorderModal, { ReorderModel } from 'Components/reorder-modal/reorder-modal';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';
import { DayPassTypeDto } from 'Api/Features/DayPassTypes/Dtos/DayPassTypeDto';
import { UpdateDayPassTypeOrderRequestDto } from 'Api/Features/DayPassTypes/Dtos/UpdateDayPassTypeOrderRequestDto';
import { GetDayPassTypesSortColumnDto } from 'Api/Features/DayPassTypes/Dtos/GetDayPassTypesSortColumnDto';
import { DayPassTypeService } from 'Services/DayPassTypeService';
import { GetDayPassTypesRequestDto } from 'Api/Features/DayPassTypes/Dtos/GetDayPassTypesRequestDto';
import EditDayPassTypeModal from './edit-day-pass-type-modal';
import { useParams } from 'react-router-dom';
import LocationHeader from 'Components/location-header/location-header';

const { usersHeader } = images;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 20,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const advancedFilters: AdvancedFilter[] = [];

const DayPassTypes: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const dayPassTypeService = useService(DayPassTypeService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const paginationRef = useRef(initialPaginationState);
    const [data, setData] = useState<DayPassTypeDto[]>([]);
    const history = useHistory();
    const { toastStore, globalLoadingStore } = useStores();

    const [createModalOpen, setCreateModalOpen] = useState(false);

    const [reorderModalOpen, setReorderModalOpen] = useState(false);
    const [orderingMethod, setOrderingMethod] = useState<{ isManual: boolean }>();

    const [loading, setLoading] = useState(false);

    const locationManagement = {
        isTrue: !!id,
        locationId: id,
    };

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            nameKey: locationManagement.isTrue
                ? 'management'
                : 'Administration.administration_title',
            path: locationManagement.isTrue ? `management` : 'administration',
        },
        {
            nameKey: 'DayPass.day_pass_types',
            path: 'day-pass-types',
        },
    ];

    const onRowClick = (pack: DayPassTypeDto): void => {
        history.push(`day-pass-types/${pack.id}`);
    };

    const menuOptions: ActionMenuOption[] = [
        {
            key: 'create',
            title: t('DayPass.create_day_pass_type'),
            action: (): void => setCreateModalOpen(true),
        },
        {
            key: 'ordering',
            title: t('DayPass.reorder_day_pass_type'),
            action: (): void => {
                setReorderModalOpen(true);
                fetchOrdering();
            },
        },
    ];

    const columns: ColumnType<DayPassTypeDto>[] = [
        {
            title: t('order'),
            render: (pass: DayPassTypeDto): number => cleanVal.number(pass?.order) + 1,
            sorter: true,
            key: GetDayPassTypesSortColumnDto.Order,
        },
        {
            title: t('name'),
            render: (pass: DayPassTypeDto): string => cleanVal.string(pass.name),
            sorter: true,
            key: GetDayPassTypesSortColumnDto.Name,
        },
        {
            title: t('price'),
            render: (pass: DayPassTypeDto): string =>
                currencyFormatter(cleanVal.number(pass.price)),
            sorter: true,
            key: GetDayPassTypesSortColumnDto.Price,
            align: 'right',
        },
        {
            title: t('credits_price'),
            render: (pass: DayPassTypeDto): string =>
                currencyFormatter(cleanVal.number(pass.creditsPrice)),
            sorter: true,
            key: GetDayPassTypesSortColumnDto.CreditsPrice,
            align: 'right',
        },
    ];

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: GetDayPassTypesSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);
            try {
                // call api
                const [data, totalItems] = await dayPassTypeService.getDayPassTypes({
                    campusIds: locationManagement.isTrue ? locationManagement.locationId : null,
                    multiLocation: !locationManagement.isTrue,
                    page: (params.pagination.current || 1) - 1,
                    pageSize: params.pagination.pageSize || 10,
                    searchTerm: params.searchTerm,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                } as GetDayPassTypesRequestDto);
                setData(data);
                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [dayPassTypeService, locationManagement.isTrue, locationManagement.locationId]
    );

    const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any): void => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        fetch({
            pagination,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
    };

    const fetchOrdering = useCallback(async () => {
        try {
            globalLoadingStore.addLoading();
            let order;
            if (locationManagement.isTrue) {
                order = await dayPassTypeService.getCampusDayPassTypeOrdering(
                    locationManagement.locationId
                );
            } else {
                order = await dayPassTypeService.getDayPassTypeOrdering();
            }

            setOrderingMethod({ isManual: order?.method === OrderingMethodDto.Manual });
        } catch (e) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, toastStore, dayPassTypeService, locationManagement]);

    const onReorderSubmit = async (method: OrderingMethodDto, ids: string[]): Promise<void> => {
        if (locationManagement.isTrue) {
            await dayPassTypeService.updateCampusDayPassTypeOrdering(
                locationManagement.locationId,
                {
                    ids,
                    method,
                } as UpdateDayPassTypeOrderRequestDto
            );
        } else {
            await dayPassTypeService.updateDayPassTypeOrdering({
                ids,
                method,
            } as UpdateDayPassTypeOrderRequestDto);
        }

        setReorderModalOpen(false);
        setOrderingMethod(undefined);
        const filterStore = filterStoreRef.current;

        fetch({
            pagination: {
                ...paginationRef.current,
                current: 1,
            },
            sortColumn: null,
            sortDirection: null,
            searchTerm: filterStore.searchTerm,
        });
        toastStore.toast({
            type: 'success',
            messageKey: t('model_reorder_success', { param1: t('DayPass.day_pass_types') }),
        });
    };

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            fetch({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                sortColumn: null,
                sortDirection: null,
                searchTerm: filterStore.searchTerm,
            });
        });
        return (): void => {
            disposer();
        };
    }, [fetch]);

    return (
        <div className="DayPassTypes">
            {locationManagement.isTrue ? (
                <LocationHeader
                    title={t('DayPass.day_pass_types')}
                    subTitle={t('DayPass.administration_section_day_pass_types_subtitle')}
                    defaultImg={<Plan fill={theme['primary-color']} />}
                    action={<ActionMenu options={menuOptions} type="primary" trigger="click" />}
                    routes={breadcrumbs}
                />
            ) : (
                <ListSectionHeader
                    title={t('DayPass.day_pass_types')}
                    subTitle={t('DayPass.administration_section_day_pass_types_subtitle')}
                    defaultImg={<Plan fill={theme['primary-color']} />}
                    backgroundImageUrl={usersHeader}
                    action={<ActionMenu options={menuOptions} type="primary" trigger="click" />}
                    routes={breadcrumbs}
                />
            )}

            <Content>
                <TableFilters filterStore={filterStoreRef.current} includeSearch />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(pack: DayPassTypeDto): string => cleanVal.string(pack.id)}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(row: DayPassTypeDto) => ({
                        onClick: (): void => {
                            onRowClick(row);
                        },
                    })}
                />
            </Content>

            {reorderModalOpen && orderingMethod && (
                <ReorderModal
                    visible={reorderModalOpen}
                    dataSource={data.map(
                        (amenity) =>
                            ({
                                id: amenity.id,
                                name: amenity.name,
                                order: amenity.order,
                            } as ReorderModel)
                    )}
                    onDismiss={(): void => {
                        setReorderModalOpen(false);
                        setOrderingMethod(undefined);
                    }}
                    manual={orderingMethod?.isManual}
                    onSubmit={async (
                        orderingMethod: OrderingMethodDto,
                        ids: string[]
                    ): Promise<void> => await onReorderSubmit(orderingMethod, ids)}
                    modelName={'day pass type'}
                />
            )}

            {createModalOpen && (
                <EditDayPassTypeModal
                    visible={createModalOpen}
                    onComplete={(success: boolean, id?: string): void => {
                        if (success && id) {
                            history.push(`day-pass-types/${id}`);
                        }
                        setCreateModalOpen(false);
                    }}
                    locationId={
                        locationManagement.isTrue ? locationManagement.locationId : undefined
                    }
                />
            )}
        </div>
    );
};

export default DayPassTypes;
