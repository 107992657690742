// GENERATED FILE - DO NOT MODIFY
import { CampusOccupancyStatsDto } from 'Api/Features/UnitOccupancy/Dtos/CampusOccupancyStatsDto';
import { CommunalUnitOccupancyStatusDtoObsolete } from 'Api/Features/UnitOccupancy/Dtos/CommunalUnitOccupancyStatusDtoObsolete';
import { GetUnitOccupancyStatusesRequestDto } from 'Api/Features/UnitOccupancy/Dtos/GetUnitOccupancyStatusesRequestDto';
import { UnitOccupancyStatusDto } from 'Api/Features/UnitOccupancy/Dtos/UnitOccupancyStatusDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class UnitOccupancyProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getUnitOccupancyStatuses(request: GetUnitOccupancyStatusesRequestDto | null): Promise<(UnitOccupancyStatusDto | null)[] | null> {
        const uri = this.buildUri(
            "/unitoccupancystatuses",
            null,
            { ...request || undefined }
        );

        const data: (UnitOccupancyStatusDto | null)[] | null = await this.httpClient.get<(UnitOccupancyStatusDto | null)[] | null>(uri);
        return data;
    }

    public async getCommunalUnitOccupancyStatusesForCampus(request: GetUnitOccupancyStatusesRequestDto | null): Promise<(CommunalUnitOccupancyStatusDtoObsolete | null)[] | null> {
        const uri = this.buildUri(
            "/communalunitoccupancystatuses",
            null,
            { ...request || undefined }
        );

        const data: (CommunalUnitOccupancyStatusDtoObsolete | null)[] | null = await this.httpClient.get<(CommunalUnitOccupancyStatusDtoObsolete | null)[] | null>(uri);
        return data;
    }

    public async getOccupancyStatsForCampus(campusIds?: (string | null)[] | null): Promise<CampusOccupancyStatsDto | null> {
        const uri = this.buildUri(
            "/campusoccupancystats",
            null,
            null
        );

        const data: CampusOccupancyStatsDto | null = await this.httpClient.get<CampusOccupancyStatsDto | null>(uri);
        return data;
    }
}