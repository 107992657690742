import { Button, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import { InvoiceStatusDto } from 'Api/Features/Invoices/Dtos/InvoiceStatusDto';
import { GetAccountsReceivableReportRequestDto } from 'Api/Features/Reports/Dtos/AccountsReceivable/GetAccountsReceivableReportRequestDto';
import Icon from 'Components/icons/icon';
import { ListSectionHeader } from 'Components/list-section-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import TableActionsButtons from 'Components/table-action-buttons/table-actions-buttons';
import { TableFilters } from 'Components/table-filters';
import { getInvoiceStatusTag } from 'Components/tag/tag';
import { TdWithImage } from 'Components/td-with-image';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import {
    ALL_LOCATIONS,
    MOMENT_PARSING_FORMAT,
    MOMENT_PARSING_MONTH_FORMAT,
} from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import moment from 'moment';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AccountsReceivableReportService } from 'Services/AccountsReceivableReportService';
import { FilterStore } from 'Stores';
import { showFile } from 'Utils';
import { cleanVal, currencyFormatter } from 'Utils/NumberUtils';
import { displayFormatedDate } from 'Utils/TimeUtils';
import { images, theme } from 'variant';
import './index.less';

const { usersHeader } = images;

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'statuses',
        nameKey: 'Statuses',
        items: [
            {
                key: InvoiceStatusDto.Pending,
                displayNameKey: `Reports.accounts_receivable_payment_status_${InvoiceStatusDto.Pending}`,
                checked: true,
            },
            {
                key: InvoiceStatusDto.AwaitingPayment,

                displayNameKey: `Reports.accounts_receivable_payment_status_${InvoiceStatusDto.AwaitingPayment}`,

                checked: true,
            },
            {
                key: InvoiceStatusDto.Void,

                displayNameKey: `Reports.accounts_receivable_payment_status_${InvoiceStatusDto.Void}`,

                checked: true,
            },
            {
                key: InvoiceStatusDto.Failed,

                displayNameKey: `Reports.accounts_receivable_payment_status_${InvoiceStatusDto.Failed}`,

                checked: true,
            },
            {
                key: InvoiceStatusDto.Success,

                displayNameKey: `Reports.accounts_receivable_payment_status_${InvoiceStatusDto.Success}`,

                checked: true,
            },
            {
                key: InvoiceStatusDto.Refunded,

                displayNameKey: `Reports.accounts_receivable_payment_status_${InvoiceStatusDto.Refunded}`,

                checked: true,
            },
        ],
    },
];

const renderCompany = (companyInfos: ExctractCompaniesList): ReactNode => (
    <TdWithImage defaultImg={<Icon iconName="Company" />} imgSrc={companyInfos.companyImageUrl}>
        {companyInfos.companyName}
    </TdWithImage>
);

const renderCurrency = (val?: number): string => currencyFormatter(cleanVal.number(val));

interface ExctractCompaniesList {
    key: number;
    id: string;
    companyName: string;
    companyImageUrl: string;
    totalAmount: string;
    totalAwaitingPayment: string;
    totalPending: string;
    totalFailed: string;
    totalPaid: string;
    totalRefunded: string;
}

interface ExctractInvoicesList {
    id: string;
    locationName: string;
    issuedDate: string;
    paymentDate: string;
    paymentMethod: string;
    invoiceNumber: string;
    status: ReactNode;
    amount: string;
    awaitingPayment: string;
    pending: string;
    failed: string;
    paid: string;
    refunded: string;
}
interface AccountsReceivableTotal {
    amount: string;
    awaitingPayment: string;
    pending: string;
    failed: string;
    paid: string;
    refunded: string;
}

interface AccountsReceivableReportData {
    companies: ExctractCompaniesList[];
    invoices: ExctractInvoicesList[][];
    total: AccountsReceivableTotal;
}

const AccountsReceivable: FunctionComponent = observer(() => {
    const accountsReceivableReportService = useService(AccountsReceivableReportService);
    const { locationStore, globalLoadingStore, toastStore, userPermissionsStore, requestStore } =
        useStores();
    const [accountsReceivableReportRequest, setAccountsReceivableReportRequest] =
        useState<GetAccountsReceivableReportRequestDto>();
    const [accountsReceivableReportData, setAccountsReceivableReportData] =
        useState<AccountsReceivableReportData>();
    const [rowKeysToExpand, setRowKeysToExpand] = useState<string[]>();
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const refRowKeysToExpand = useRef<string[]>([]);
    const { t } = useTranslation();

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            nameKey: 'reports',
            path: 'reports',
        },
        {
            nameKey: 'Reports.accounts_receivable',
            path: 'accounts-receivable',
        },
    ];

    const onExportClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            let document;
            if (accountsReceivableReportRequest) {
                const campusName =
                    locationStore?.locations.find(
                        (x) => x.id === filterStoreRef.current.currentLocationId
                    )?.name || ALL_LOCATIONS;

                document = await accountsReceivableReportService.exportAccountsReceivableReport(
                    accountsReceivableReportRequest
                );

                showFile(
                    document,
                    `${t('Reports.accounts_receivable')} - ${moment(
                        accountsReceivableReportRequest.startDate
                    ).format(MOMENT_PARSING_MONTH_FORMAT)} - ${campusName}`
                );
            }
        } catch (e) {
            if (!e.treated) {
                if (e.treated) {
                    toastStore.genericError();
                }
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const tableActionButtons = [
        {
            iconName: 'UnExpandAll',
            toolTip: 'unexpand_all',
            onClick: (): void => setRowKeysToExpand([]),
        },
        {
            iconName: 'ExpandAll',
            toolTip: 'expand_all',
            onClick: (): void => setRowKeysToExpand(refRowKeysToExpand.current),
        },
        {
            iconName: 'Download',
            toolTip: 'export_table',
            onClick: (): Promise<void> => onExportClick(),
        },
    ];

    const companyColumns: ColumnType<ExctractCompaniesList>[] = [
        {
            title: t('location'),
            render: (companyInfos): ReactNode => ({
                children: renderCompany(companyInfos),
                props: { colSpan: 6 },
            }),
        },
        {
            title: t('Reports.issued'),
            render: (): ReactNode => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('Reports.payment_date'),
            render: (): ReactNode => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('Reports.payment_method'),
            render: (): ReactNode => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('invoice'),
            render: (): ReactNode => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('status'),
            render: (): ReactNode => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('amount'),
            dataIndex: 'totalAmount',
        },
        {
            title: t('Reports.awaiting_payment'),
            dataIndex: 'totalAwaitingPayment',
        },
        {
            title: t('pending'),
            dataIndex: 'totalPending',
        },
        {
            title: t('failed'),
            dataIndex: 'totalFailed',
        },
        {
            title: t('closed'),
            dataIndex: 'totalPaid',
        },
        {
            title: t('refunded'),
            dataIndex: 'totalRefunded',
        },
    ];

    const expandedRowRender = (record: ExctractCompaniesList): ReactNode => {
        const columns: ColumnType<ExctractInvoicesList>[] = [
            {
                title: t('empty'),
                render: (): string => '',
                key: 'empty',
            },
            {
                dataIndex: 'locationName',
            },
            {
                dataIndex: 'issuedDate',
            },
            {
                dataIndex: 'paymentDate',
            },
            {
                dataIndex: 'paymentMethod',
            },
            {
                dataIndex: 'invoiceNumber',
            },
            {
                dataIndex: 'status',
            },
            {
                dataIndex: 'amount',
            },
            {
                dataIndex: 'awaitingPayment',
            },
            {
                dataIndex: 'pending',
            },
            {
                dataIndex: 'failed',
            },
            {
                dataIndex: 'paid',
            },
            {
                dataIndex: 'refunded',
            },
        ];

        return (
            <Table
                showHeader={false}
                columns={columns}
                dataSource={accountsReceivableReportData?.invoices[record.key]}
                rowKey={(record): string => `${record.id}`}
                pagination={false}
            />
        );
    };

    const getAdvancedFilterStatuses = (): {
        checkedStatuses: InvoiceStatusDto[];
        statusesCount: number;
    } => {
        const filterStore = filterStoreRef.current;
        const statuses = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'statuses'
        );
        const checkedStatuses = statuses?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key;
            });

        return {
            checkedStatuses:
                checkedStatuses?.map((x) => InvoiceStatusDto[x as keyof typeof InvoiceStatusDto]) ??
                [],
            statusesCount: statuses?.items.length ?? 0,
        };
    };

    const renderTableFooter = (): ReactNode => (
        <>
            <div>{t('total')}</div>
            <div>{accountsReceivableReportData?.total.amount}</div>
            <div>{accountsReceivableReportData?.total.awaitingPayment}</div>
            <div>{accountsReceivableReportData?.total.pending}</div>
            <div>{accountsReceivableReportData?.total.failed}</div>
            <div>{accountsReceivableReportData?.total.paid}</div>
            <div>{accountsReceivableReportData?.total.refunded}</div>
        </>
    );

    const fetchAccountsReceivable = useCallback(
        async (params: {
            startDate: string;
            endDate: string;
            campusIds: string[];
            advancedFilters?: AdvancedFilter[];
        }) => {
            globalLoadingStore.addLoading();
            const statuses = getAdvancedFilterStatuses();

            try {
                if (statuses.checkedStatuses.length === 0) {
                    setAccountsReceivableReportData({
                        companies: [],
                        invoices: [],
                        total: {
                            amount: renderCurrency(0),
                            awaitingPayment: renderCurrency(0),
                            pending: renderCurrency(0),
                            failed: renderCurrency(0),
                            paid: renderCurrency(0),
                            refunded: renderCurrency(0),
                        },
                    });
                } else {
                    const accountsReceivableResponse =
                        await accountsReceivableReportService.getAccountsReceivableReport({
                            startDate: params.startDate,
                            endDate: params.endDate,
                            campusIds: params.campusIds,
                            invoiceStatuses: statuses.checkedStatuses,
                        });

                    const accountsReceivableTotal: AccountsReceivableTotal = {
                        amount: renderCurrency(accountsReceivableResponse?.total),
                        awaitingPayment: renderCurrency(
                            accountsReceivableResponse?.awaitingPaymentTotal
                        ),
                        pending: renderCurrency(accountsReceivableResponse?.pendingTotal),
                        failed: renderCurrency(accountsReceivableResponse?.failedTotal),
                        paid: renderCurrency(accountsReceivableResponse?.paidTotal),
                        refunded: renderCurrency(accountsReceivableResponse?.refundedTotal),
                    };

                    const nonNullaccountsReceivable = accountsReceivableResponse?.customers
                        ? accountsReceivableResponse?.customers
                              .filter((company) => company !== null)
                              .map((company) => company!)
                        : [];

                    refRowKeysToExpand.current = [];

                    const exctractInvoicesList: ExctractInvoicesList[][] = [];
                    const exctractCompaniesList: ExctractCompaniesList[] | [] =
                        nonNullaccountsReceivable.map((company, i) => {
                            exctractInvoicesList[i] = company.invoices
                                ? company.invoices
                                      .filter((invoice) => invoice !== null)
                                      .map((invoice) => ({
                                          id: cleanVal.string(invoice?.id),
                                          locationName: cleanVal.string(invoice?.campusName),
                                          issuedDate: displayFormatedDate(
                                              invoice?.invoiceDate,
                                              MOMENT_PARSING_FORMAT
                                          ),
                                          paymentDate: invoice?.lastPaymentDate
                                              ? displayFormatedDate(
                                                    invoice?.lastPaymentDate,
                                                    MOMENT_PARSING_FORMAT
                                                )
                                              : 'N/A',
                                          paymentMethod: invoice?.paymentMethodType
                                              ? t(
                                                    `Reports.accounts_receivable_payment_method_${invoice?.paymentMethodType}`
                                                )
                                              : 'N/A',
                                          invoiceNumber: cleanVal.string(invoice?.number),
                                          status: getInvoiceStatusTag(invoice!, t),
                                          amount: renderCurrency(invoice?.amount),
                                          awaitingPayment: renderCurrency(
                                              invoice?.awaitingPaymentAmount
                                          ),
                                          pending: renderCurrency(invoice?.pendingAmount),
                                          failed: renderCurrency(invoice?.failedAmount),
                                          paid: renderCurrency(invoice?.paidAmount),
                                          refunded: renderCurrency(invoice?.refundedAmount),
                                      }))
                                : [];

                            refRowKeysToExpand.current.push(cleanVal.string(company.id));

                            return {
                                key: i,
                                id: cleanVal.string(company.id),
                                companyName: cleanVal.string(company.name),
                                companyImageUrl: cleanVal.string(company.imageUrl),
                                totalAmount: renderCurrency(company.total),
                                totalAwaitingPayment: renderCurrency(company.awaitingPaymentTotal),
                                totalPending: renderCurrency(company.pendingTotal),
                                totalFailed: renderCurrency(company.failedTotal),
                                totalPaid: renderCurrency(company.paidTotal),
                                totalRefunded: renderCurrency(company.refundedTotal),
                            };
                        });

                    setAccountsReceivableReportData({
                        companies: exctractCompaniesList,
                        invoices: exctractInvoicesList,
                        total: accountsReceivableTotal,
                    });
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [accountsReceivableReportService, globalLoadingStore]
    );

    useEffect(() => {
        const filterStore = filterStoreRef.current;
        filterStore.month = moment().startOf('month').format(MOMENT_PARSING_FORMAT);
    }, []);

    useEffect(() => {
        const filterStore = filterStoreRef.current;

        const disposer = autorun(() => {
            const request = {
                startDate: moment(filterStore.month).startOf('month').format(MOMENT_PARSING_FORMAT),
                endDate: moment(filterStore.month).endOf('month').format(MOMENT_PARSING_FORMAT),
                campusIds:
                    filterStore.currentLocationId === 'all'
                        ? requestStore.getReportRequestAvailableLocationIdsForUser()
                        : [filterStore.currentLocationId],
                invoiceStatuses: getAdvancedFilterStatuses().checkedStatuses,
            };

            fetchAccountsReceivable(request);

            setAccountsReceivableReportRequest(request);
        });

        return (): void => {
            disposer();
        };
    }, [fetchAccountsReceivable]);

    return (
        <div className="AccountsReceivable">
            <ListSectionHeader
                routes={breadcrumbs}
                backgroundImageUrl={usersHeader}
                defaultImg={<Icon iconName="Dollar" fill={theme['primary-color']} />}
                title={t('Reports.accounts_receivable')}
                subTitle={t('Reports.accounts_receivable_overview')}
            />

            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeMonth
                    includeLocations
                    includeAdvancedFilters
                    availableLocations={userPermissionsStore.availableLocationsForUser}
                />

                <TableActionsButtons buttonsList={tableActionButtons} />

                <Table
                    className="expand-table table-striped-rows table-action-rows"
                    rowClassName="company-row"
                    bordered
                    columns={companyColumns}
                    dataSource={accountsReceivableReportData?.companies}
                    expandable={{
                        expandedRowRender: expandedRowRender,
                        // eslint-disable-next-line react/display-name
                        expandIcon: ({ expanded, onExpand, record }): ReactNode =>
                            expanded ? (
                                <Button
                                    onClick={(e): void => {
                                        setRowKeysToExpand(undefined);
                                        onExpand(record, e);
                                    }}
                                >
                                    <Icon iconName="AngleTop" />
                                </Button>
                            ) : (
                                <Button
                                    onClick={(e): void => {
                                        setRowKeysToExpand(undefined);
                                        onExpand(record, e);
                                    }}
                                >
                                    <Icon iconName="AngleBottom" />
                                </Button>
                            ),
                        expandedRowKeys: rowKeysToExpand,
                    }}
                    rowKey={(record): string => record.id}
                    footer={renderTableFooter}
                    pagination={false}
                />
            </Content>
        </div>
    );
});

export default AccountsReceivable;
