// GENERATED FILE - DO NOT MODIFY
import { InvoiceSummaryDto } from 'Api/Features/Invoices/Dtos/InvoiceSummaryDto';
import { InvoiceSummaryRequestDto } from 'Api/Features/Invoices/Dtos/InvoiceSummaryRequestDto';
import { CancelSubscriptionRequestDto } from 'Api/Features/Subscriptions/Dtos/CancelSubscriptionRequestDto';
import { CreateSubscriptionCommentRequestDto } from 'Api/Features/Subscriptions/Dtos/CreateSubscriptionCommentRequestDto';
import { GetSubscriptionAncestriesRequestDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionAncestriesRequestDto';
import { GetSubscriptionAncestriesResponseDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionAncestriesResponseDto';
import { GetSubscriptionNoteResponseDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionNoteResponseDto';
import { GetSubscriptionOnboardingStatusResponseDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionOnboardingStatusResponseDto';
import { GetSubscriptionsRequestDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionsRequestDto';
import { GetSubscriptionsResponseDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionsResponseDto';
import { GetUpdateSubscriptionDiscountsPreviewResponseDto } from 'Api/Features/Subscriptions/Dtos/GetUpdateSubscriptionDiscountsPreviewResponseDto';
import { SubscriptionAncestryDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionAncestryDto';
import { SubscriptionCancellationInfoDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionCancellationInfoDto';
import { SubscriptionDetailsDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsDto';
import { UpdateSubscriptionDiscountsRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionDiscountsRequestDto';
import { UpdateSubscriptionNoteRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionNoteRequestDto';
import { UpdateSubscriptionOnboardingCategoryDocumentsRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionOnboardingCategoryDocumentsRequestDto';
import { UpdateSubscriptionOnboardingStatusRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionOnboardingStatusRequestDto';
import { UpdateSubscriptionPaymentSettingsRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionPaymentSettingsRequestDto';
import { UpdateSubscriptionReferenceNumberRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionReferenceNumberRequestDto';
import { UpdateSubscriptionSettingsRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionSettingsRequestDto';
import { UpdateSubscriptionUnitsRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionUnitsRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class SubscriptionProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getSubscriptionAncestry(subscriptionAncestryId: string | null): Promise<SubscriptionAncestryDto | null> {
        const uri = this.buildUri(
            "/subscription-ancestries/{subscriptionAncestryId}",
            { subscriptionAncestryId: subscriptionAncestryId },
            null
        );

        const data: SubscriptionAncestryDto | null = await this.httpClient.get<SubscriptionAncestryDto | null>(uri);
        return data;
    }

    public async getSubscriptionAncestries(request: GetSubscriptionAncestriesRequestDto | null): Promise<GetSubscriptionAncestriesResponseDto | null> {
        const uri = this.buildUri(
            "/subscription-ancestries",
            null,
            { ...request || undefined }
        );

        const data: GetSubscriptionAncestriesResponseDto | null = await this.httpClient.get<GetSubscriptionAncestriesResponseDto | null>(uri);
        return data;
    }

    public async getSubscriptions(request: GetSubscriptionsRequestDto | null): Promise<GetSubscriptionsResponseDto | null> {
        const uri = this.buildUri(
            "/subscriptions",
            null,
            { ...request || undefined }
        );

        const data: GetSubscriptionsResponseDto | null = await this.httpClient.get<GetSubscriptionsResponseDto | null>(uri);
        return data;
    }

    public async getSubscription(subscriptionId: string | null): Promise<SubscriptionDetailsDto | null> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}",
            { subscriptionId: subscriptionId },
            null
        );

        const data: SubscriptionDetailsDto | null = await this.httpClient.get<SubscriptionDetailsDto | null>(uri);
        return data;
    }

    public async getSubscriptionWithToken(subscriptionId: string | null): Promise<SubscriptionDetailsDto | null> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/getwithtoken",
            { subscriptionId: subscriptionId },
            null
        );

        const data: SubscriptionDetailsDto | null = await this.httpClient.get<SubscriptionDetailsDto | null>(uri);
        return data;
    }

    public async getSubscriptionOnboardingStatus(subscriptionId: string | null): Promise<GetSubscriptionOnboardingStatusResponseDto | null> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/onboarding-status",
            { subscriptionId: subscriptionId },
            null
        );

        const data: GetSubscriptionOnboardingStatusResponseDto | null = await this.httpClient.get<GetSubscriptionOnboardingStatusResponseDto | null>(uri);
        return data;
    }

    public async updateSubscriptionOnboardingStatus(subscriptionId: string | null, request: UpdateSubscriptionOnboardingStatusRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/onboarding-status",
            { subscriptionId: subscriptionId },
            null
        );

        await this.httpClient.put<UpdateSubscriptionOnboardingStatusRequestDto | null, void>(uri, request);
    }

    public async getSubscriptionInvoiceSummary(request: InvoiceSummaryRequestDto | null): Promise<InvoiceSummaryDto | null> {
        const uri = this.buildUri(
            "/subscriptions/invoicesummary",
            null,
            null
        );

        const data: InvoiceSummaryDto | null = await this.httpClient.post<InvoiceSummaryRequestDto | null, InvoiceSummaryDto | null>(uri, request);
        return data;
    }

    public async performSubscriptionMaintenance(): Promise<void> {
        const uri = this.buildUri(
            "/subscriptions/maintenance",
            null,
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async createSubscriptionComment(subscriptionId: string | null, request: CreateSubscriptionCommentRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/comments",
            { subscriptionId: subscriptionId },
            null
        );

        await this.httpClient.post<CreateSubscriptionCommentRequestDto | null, void>(uri, request);
    }

    public async updateSubscriptionOnboardingCategoryDocuments(subscriptionId: string | null, onboardingCategoryId: string, request: UpdateSubscriptionOnboardingCategoryDocumentsRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/onboarding-categories/{onboardingCategoryId}/documents",
            { subscriptionId: subscriptionId, onboardingCategoryId: onboardingCategoryId },
            null
        );

        await this.httpClient.patch<UpdateSubscriptionOnboardingCategoryDocumentsRequestDto | null, void>(uri, request);
    }

    public async updateSubscriptionReferenceNumber(subscriptionId: string | null, request: UpdateSubscriptionReferenceNumberRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/reference-number",
            { subscriptionId: subscriptionId },
            null
        );

        await this.httpClient.put<UpdateSubscriptionReferenceNumberRequestDto | null, void>(uri, request);
    }

    public async updateSubscriptionUnits(subscriptionId: string | null, request: UpdateSubscriptionUnitsRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/units",
            { subscriptionId: subscriptionId },
            null
        );

        await this.httpClient.put<UpdateSubscriptionUnitsRequestDto | null, void>(uri, request);
    }

    public async updateSubscriptionDiscounts(subscriptionId: string | null, request: UpdateSubscriptionDiscountsRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/update-discounts",
            { subscriptionId: subscriptionId },
            null
        );

        await this.httpClient.post<UpdateSubscriptionDiscountsRequestDto | null, void>(uri, request);
    }

    public async updateSubscriptionPaymentSettings(subscriptionId: string | null, request: UpdateSubscriptionPaymentSettingsRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/payment-settings",
            { subscriptionId: subscriptionId },
            null
        );

        await this.httpClient.put<UpdateSubscriptionPaymentSettingsRequestDto | null, void>(uri, request);
    }

    public async updateSubscriptionSettings(subscriptionId: string | null, request: UpdateSubscriptionSettingsRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/settings",
            { subscriptionId: subscriptionId },
            null
        );

        await this.httpClient.put<UpdateSubscriptionSettingsRequestDto | null, void>(uri, request);
    }

    public async getUpdateSubscriptionDiscountsPreview(subscriptionId: string | null, request: UpdateSubscriptionDiscountsRequestDto | null): Promise<GetUpdateSubscriptionDiscountsPreviewResponseDto | null> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/update-discounts-preview",
            { subscriptionId: subscriptionId },
            null
        );

        const data: GetUpdateSubscriptionDiscountsPreviewResponseDto | null = await this.httpClient.post<UpdateSubscriptionDiscountsRequestDto | null, GetUpdateSubscriptionDiscountsPreviewResponseDto | null>(uri, request);
        return data;
    }

    public async markSubscriptionSecurityDepositAsRefunded(subscriptionId: string | null): Promise<void> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/security-deposit-refunded",
            { subscriptionId: subscriptionId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async markSubscriptionSecurityDepositAsRetained(subscriptionId: string | null): Promise<void> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/security-deposit-retained",
            { subscriptionId: subscriptionId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async getSubscriptionCancellationInfo(subscriptionId: string): Promise<SubscriptionCancellationInfoDto | null> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/cancellation",
            { subscriptionId: subscriptionId },
            null
        );

        const data: SubscriptionCancellationInfoDto | null = await this.httpClient.get<SubscriptionCancellationInfoDto | null>(uri);
        return data;
    }

    public async cancelSubscription(subscriptionId: string, request: CancelSubscriptionRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/cancellation",
            { subscriptionId: subscriptionId },
            null
        );

        await this.httpClient.put<CancelSubscriptionRequestDto | null, void>(uri, request);
    }

    public async getSubscriptionNote(subscriptionId: string): Promise<GetSubscriptionNoteResponseDto | null> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/notes",
            { subscriptionId: subscriptionId },
            null
        );

        const data: GetSubscriptionNoteResponseDto | null = await this.httpClient.get<GetSubscriptionNoteResponseDto | null>(uri);
        return data;
    }

    public async updateSubscriptionNote(subscriptionId: string, request: UpdateSubscriptionNoteRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/notes",
            { subscriptionId: subscriptionId },
            null
        );

        await this.httpClient.put<UpdateSubscriptionNoteRequestDto | null, void>(uri, request);
    }
}