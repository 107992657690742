// GENERATED FILE - DO NOT MODIFY

export enum GetCheckInsSortColumnDto {
    None = "None",
    Date = "Date",
    FirstName = "FirstName",
    LastName = "LastName",
    Email = "Email",
    CompanyName = "CompanyName",
    CampusName = "CampusName",
}