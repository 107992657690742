import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Button, Layout, Table } from 'antd';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { autorun } from 'mobx';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useService, useStores } from 'Hooks';
import LocationHeader from 'Components/location-header/location-header';
import { theme } from 'variant';
import { RevenueOccupancyTargetsService } from 'Services/RevenueOccupancyTargetsService';
import { GetRevenueOccupancyTargetsRequestDto } from 'Api/Features/RevenueOccupancyTargets/Dtos/GetRevenueOccupancyTargetsRequestDto';
import moment from 'moment';
import { AngleLeft, AngleRight, Dollar, PencilBorder } from 'Components/icons';
import { RevenueOccupancyTargetMonthDto } from 'Api/Features/RevenueOccupancyTargets/Dtos/RevenueOccupancyTargetMonthDto';
import { DISPLAY_MONTH_FORMAT, MMMM_D_YYYY_FORMAT, YEAR_FORMAT } from 'Models/Constants';
import SetTargetsModal from './set-targets-modal';
import { CampusService } from 'Services/CampusService';
import { CampusOccupancyObjectiveDto } from 'Api/Features/Campuses/Dtos/CampusOccupancyObjectiveDto';
import './index.less';
import SetOccupancyObjectiveModal from './set-occupancy-objective-modal';
import { currencyFormatter } from 'Utils/NumberUtils';
import { ColumnType } from 'antd/es/table';
import { observer } from 'mobx-react';

const { Content } = Layout;

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'management',
        nameKey: 'Location.management_label',
    },
    {
        path: 'revenueOccupancyTargets',
        nameKey: 'RevenueOccupancy.revenue_occupancy_targets',
    },
];

const RevenueOccupancyTargets: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { userPermissionsStore } = useStores();
    const revenueOccupancyTargetsService = useService(RevenueOccupancyTargetsService);
    const campusService = useService(CampusService);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<RevenueOccupancyTargetMonthDto[]>();
    const [objective, setObjective] = useState<CampusOccupancyObjectiveDto>();
    const { toastStore } = useStores();

    const [editTargetsModalOpen, setEditTargetsModalOpen] = useState(false);
    const [editObjectiveModalOpen, setEditObjectiveModalOpen] = useState(false);
    const [selectedYear, setSelectedYear] = useState<moment.Moment>(moment());

    const columns: ColumnType<RevenueOccupancyTargetMonthDto>[] = [
        {
            title: t('Month'),
            dataIndex: 'period',
            render: (key: string): string => moment.utc(key).format(DISPLAY_MONTH_FORMAT),
        },
        {
            title: t('revenue'),
            dataIndex: 'revenue',
            render: (key: number): string => currencyFormatter(key),
            align: 'right',
        },
        {
            title: t('occupancy'),
            dataIndex: 'occupancy',
            render: (key: string): string => `${key}%`,
            align: 'right',
        },
    ];

    const fetch = useCallback(
        async (selectedYear: moment.Moment) => {
            setLoading(true);
            try {
                const response = await revenueOccupancyTargetsService.getRevenueOccupancyTargets(
                    id,
                    {
                        years: [selectedYear.year()],
                    } as GetRevenueOccupancyTargetsRequestDto
                );
                if (response) {
                    setData(
                        response?.months?.filter((month) => month !== null).map((month) => month!)
                    );
                }
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [revenueOccupancyTargetsService, id, toastStore]
    );

    useEffect(() => {
        fetch(selectedYear);
    }, [selectedYear]);

    const fetchObjective = useCallback(async () => {
        setLoading(true);
        try {
            const response = await campusService.getOccupancyObjective(id);
            if (response) {
                setObjective(response);
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            setLoading(false);
        }
    }, [revenueOccupancyTargetsService, id, campusService, toastStore]);

    useEffect(() => {
        const disposer = autorun(() => {
            fetchObjective();
        });
        return (): void => {
            disposer();
        };
    }, [fetchObjective]);

    return (
        <div className="RevenueOccupancyTargets">
            <LocationHeader
                title={t('RevenueOccupancy.revenue_occupancy_targets')}
                subTitle={t('RevenueOccupancy.set_revenue_occupancy_targets')}
                defaultImg={<Dollar fill={theme['primary-color']} />}
                routes={breadcrumbs}
            />
            <Content>
                <div className="objective-container">
                    <div className="objective-title">
                        {t('RevenueOccupancy.manager_app_objective')}
                    </div>
                    <div className="objective-subtitle">
                        {t('RevenueOccupancy.manager_app_objective_explanation')}
                    </div>

                    {userPermissionsStore.userIsAdministrator && (
                        <div className="objective-edit-container">
                            <div>{objective?.rate ?? 0}%</div>
                            <div
                                className="edit-btn"
                                onClick={(): void => setEditObjectiveModalOpen(true)}
                            >
                                <PencilBorder fill={theme['primary-color']} />
                            </div>
                        </div>
                    )}

                    {objective?.dueDate && (
                        <div className="due-date">
                            <span>{t('for')}&nbsp;</span>
                            <span>{moment(objective?.dueDate).format(MMMM_D_YYYY_FORMAT)}</span>
                        </div>
                    )}
                </div>

                <div className="table-actions-container">
                    <div className="selected-year">{selectedYear.format(YEAR_FORMAT)}</div>
                    <div className="date-change-btns-container">
                        <Button
                            className="date-change-btn"
                            onClick={(): void => {
                                setSelectedYear((old) => moment(old).subtract(1, 'year'));
                            }}
                        >
                            <AngleLeft />
                        </Button>
                        <Button
                            className="date-change-btn"
                            onClick={(): void => {
                                setSelectedYear((old) => moment(old).add(1, 'year'));
                            }}
                        >
                            <AngleRight />
                        </Button>
                    </div>

                    {userPermissionsStore.userIsAdministrator && (
                        <Button
                            type="primary"
                            className="set-target-btn"
                            onClick={(): void => {
                                setEditTargetsModalOpen(true);
                            }}
                        >
                            {t('RevenueOccupancy.edit_targets')}
                        </Button>
                    )}
                </div>

                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(record: RevenueOccupancyTargetMonthDto): string => record.period || ''}
                    dataSource={data}
                    loading={loading}
                    pagination={false}
                />
            </Content>

            {editTargetsModalOpen && (
                <SetTargetsModal
                    visible={editTargetsModalOpen}
                    onComplete={(success: boolean): void => {
                        if (success) {
                            fetch(selectedYear);
                        }
                        setEditTargetsModalOpen(false);
                    }}
                    campusId={id}
                    targets={data}
                    year={selectedYear.year()}
                />
            )}

            {editObjectiveModalOpen && (
                <SetOccupancyObjectiveModal
                    visible={editObjectiveModalOpen}
                    onComplete={(success: boolean): void => {
                        if (success) {
                            fetchObjective();
                        }
                        setEditObjectiveModalOpen(false);
                    }}
                    campusId={id}
                    objective={objective}
                />
            )}
        </div>
    );
});

export default RevenueOccupancyTargets;
