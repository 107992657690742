import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CloseLined: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.0925 0L7.93427 6.15804L1.84164 0.0655191L0 1.90748L6.09241 8L0 14.0925L1.84164 15.9332L7.9333 9.84078L14.0925 16L15.9332 14.1582L9.77504 8L15.9332 1.84077L14.0925 0ZM8.35906 6.58299L14.0925 0.849804L15.0832 1.84077L9.35013 7.57396L8.92517 7.99999L15.0832 14.1581L14.0925 15.1489L7.9342 8.99074L7.50817 9.41581L1.84157 15.0833L0.849857 14.0925L6.94227 7.99999L0.849857 1.90747L1.84157 0.916506L7.9342 7.00903L8.35906 6.58299Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default CloseLined;
