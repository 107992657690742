import { SubscriptionDetailsRelatedOpportunityDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsRelatedOpportunityDto';
import { SimpleList } from 'Components/simple-list';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubscriptionOpportunitiesListItem from './subscription-opportunities-list-item';
import './subscription-opportunities.less';

interface SubscriptionOpportunitiesProps {
    opportunities: SubscriptionDetailsRelatedOpportunityDto[] | null;
    subscriptionId?: string | null;
}

const SubscriptionOpportunities: FunctionComponent<SubscriptionOpportunitiesProps> = ({
    opportunities,
    subscriptionId
}) => {
    const { t } = useTranslation();
    const [initialOpportunities, setInitialOpportunities] = useState<SubscriptionDetailsRelatedOpportunityDto[]>();
    const [relatedOpportunities, setRelatedOpportunities] = useState<SubscriptionDetailsRelatedOpportunityDto[]>();
    
    useEffect(() => {
        if (opportunities) {
            const initial = opportunities.filter(
                (opp) => opp.parentSubscriptionId !== subscriptionId
            );
            setInitialOpportunities(initial);
            const related = opportunities.filter(
                (opp) => opp.parentSubscriptionId === subscriptionId
            );
            setRelatedOpportunities(related);
        }
    }, [opportunities, subscriptionId]);

    return (
        <div className="SubscriptionOpportunities">
            <SimpleList title={t('Subscription.subscription_opportunities')}>
                <div className="content">
                    {relatedOpportunities && relatedOpportunities.length > 0 && (
                        <div className="related-container">
                            <div className="header">{t('Subscription.related_opportunities')}</div>
                            <div className="related">
                                {relatedOpportunities.map((opportunity: any) => (
                                    <SubscriptionOpportunitiesListItem
                                        key={opportunity.id}
                                        opportunity={opportunity}
                                    />
                                ))}
                            </div>
                        </div>
                    )}

                    {initialOpportunities && initialOpportunities.length > 0 && (
                        <div>
                            <div className="header">{t('Subscription.initial_opportunities')}</div>
                            <div className="initial">
                                {initialOpportunities.map((opportunity: any) => (
                                    <SubscriptionOpportunitiesListItem
                                        key={opportunity.id}
                                        opportunity={opportunity}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </SimpleList>
        </div>
    );
};

export default SubscriptionOpportunities;
