import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { GetSubscriptionOnboardingStatusResponseDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionOnboardingStatusResponseDto';
import { SubscriptionOnboardingTaskStatusDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionOnboardingTaskStatusDto';
import { UpdateSubscriptionOnboardingStatusRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionOnboardingStatusRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Plan as PlanIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useService, useStores } from 'Hooks';
import { ONBOARDING_CATEGORY_ID_MEMBER_ACCESS } from 'Models/Constants';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionService } from 'Services/SubscriptionService';
import './member-access.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface MemberAccessModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    subscriptionId: string;
}

const MemberAccessModal: FunctionComponent<MemberAccessModalProps> = ({
    visible,
    onComplete,
    subscriptionId,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const subscriptionService = useService(SubscriptionService);
    const [tasks, setTasks] = useState<SubscriptionOnboardingTaskStatusDto[]>();
    const [onboardingStatus, setOnboardingStatus] = useState<
        GetSubscriptionOnboardingStatusResponseDto
    >();

    const [form] = Form.useForm();
    //#endregion

    const fetch = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const data: GetSubscriptionOnboardingStatusResponseDto | null = await subscriptionService.getSubscriptionOnboardingStatus(
                { subscriptionId }
            );
            if (data) {
                const memberAccessCategory = data.categories?.filter(
                    (category) =>
                        category?.onboardingCategoryId === ONBOARDING_CATEGORY_ID_MEMBER_ACCESS
                );
                setOnboardingStatus(data);
                if (memberAccessCategory) {
                    setTasks(memberAccessCategory[0]!.tasks?.map((task) => task!));
                }
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, subscriptionId, subscriptionService]);

    useEffect(() => {
        fetch();
    }, [subscriptionId, fetch]);

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <PlanIcon />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`Subscription.yes_leave_this_step`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const processSubmit = async (wontDo: boolean, isCompleted = false): Promise<void> => {
        if (isCompleted) {
            if (
                !(await confirmationModalStore.confirm({
                    icon: <PlanIcon />,
                    title: t(`Subscription.are_you_sure_complete_step`),
                    message: t(`action_cannot_be_undone`),
                    positiveText: t(`Subscription.yes_complete_this_step`),
                    negativeText: t(`Subscription.no_not_done`),
                }))
            )
                return;
        }

        try {
            globalLoadingStore.addLoading();
            const formValues = form.getFieldsValue();

            const updateModel: UpdateSubscriptionOnboardingStatusRequestDto = {
                categories: [...onboardingStatus?.categories!],
            };
            const memberAccesIndex = updateModel.categories?.findIndex(
                (category) =>
                    category?.onboardingCategoryId === ONBOARDING_CATEGORY_ID_MEMBER_ACCESS
            );

            updateModel.categories![memberAccesIndex!] = {
                ...updateModel.categories![memberAccesIndex!],
                note: formValues['note'],
                tasks: tasks,
                wontDo: wontDo,
                isCompleted: wontDo ? true : isCompleted,
            };
            await subscriptionService.updateSubscriptionOnboardingStatus(
                subscriptionId,
                updateModel
            );

            toastStore.toast({
                type: 'success',
                messageKey: `Subscription.onboarding_step_updated`,
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const submit = async (): Promise<void> => {
        await processSubmit(false, true);
    };

    const save = async (): Promise<void> => {
        await processSubmit(false);
    };

    const wontDo = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <PlanIcon />,
                title: t(`Subscription.are_you_sure_wont_do_step`),
                message: t(`action_cannot_be_undone`),
                positiveText: t(`Subscription.yes_wont_do`),
                negativeText: t(`Subscription.no_keep_step`),
            }))
        )
            return;
        await processSubmit(true);
    };
    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Subscription.review_members_access')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="MemberAccess">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={12} className="please-confirm">
                            {t('Subscription.please_confirm_the_following')}
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24} className="tasks">
                            <ValidatedFormItem errors={new Map<string, string[]>()}>
                                {tasks?.map((task) => (
                                    <Row key={task?.onboardingTaskId}>
                                        <Col span={24} className="task">
                                            <Checkbox
                                                onChange={(e): void => {
                                                    setTasks((state) => {
                                                        const index = state?.findIndex(
                                                            (task) =>
                                                                task.onboardingTaskId ===
                                                                e.target.name
                                                        );
                                                        const newState = [...state!];
                                                        newState[index!] = {
                                                            ...newState[index!],
                                                            isCompleted: e.target.checked,
                                                        };
                                                        return newState;
                                                    });
                                                }}
                                                value={task?.isCompleted}
                                                defaultChecked={task?.isCompleted}
                                                name={task?.onboardingTaskId}
                                            >
                                                {task?.onboardingTaskDescription}
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                ))}
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={new Map<string, string[]>()}
                                name="note"
                                label={t('Lead.lead_add_a_note')}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>

                        <Button
                            type="default"
                            className="secondary wont-do"
                            htmlType="button"
                            onClick={(): Promise<void> => wontDo()}
                        >
                            {t('wont_do')}
                        </Button>

                        <Button type="primary" className="positive" htmlType="submit">
                            {t('Subscription.save_and_complete')}
                        </Button>

                        <Button
                            type="default"
                            className="secondary"
                            htmlType="button"
                            onClick={(): Promise<void> => save()}
                        >
                            {t('save')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(MemberAccessModal);
