import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { LeadAssignationRuleProxy } from 'Api/Features/LeadAssignationRules/LeadAssignationRuleProxy';
import { GetLeadAssignationRulesResponseDto } from 'Api/Features/LeadAssignationRules/Dtos/GetLeadAssignationRulesResponseDto';
import { UpdateLeadAssignationRulesRequestDto } from 'Api/Features/LeadAssignationRules/Dtos/UpdateLeadAssignationRulesRequestDto';

@inject(LeadAssignationRuleProxy)
export class LeadAssignationService
 extends ApiService {
    constructor(private readonly leadAssignationProxy: LeadAssignationRuleProxy) {
        super();
    }

    public async getLeadAssignationRules(): Promise<GetLeadAssignationRulesResponseDto | null> {
        const data: GetLeadAssignationRulesResponseDto | null = await this.leadAssignationProxy.getLeadAssignationRules();
        return data;
    }

    public async updateLeadAssignationRule(request: UpdateLeadAssignationRulesRequestDto | null): Promise<void> {
        await this.leadAssignationProxy.updateLeadAssignationRule(request);
    }
}
