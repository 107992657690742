import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Building: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.30806 0.0275879V4.05775H2.44322V6.26625H0.400391V23.9336H13.0879V6.26625H11.0009V4.05775H7.13622V0.0275879H6.30806ZM3.27145 6.26629H10.1728V4.88596H3.27145V6.26629ZM1.22853 23.1055H12.2707V7.09446H1.22853V23.1055Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.49814 21.0628H3.32631V18.8545H2.49814V21.0628Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.03869 21.0628H5.86685V18.8545H5.03869V21.0628Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.57799 21.0628H8.40616V18.8545H7.57799V21.0628Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.1173 21.0628H10.9456V18.8545H10.1173V21.0628Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.49814 16.204H3.32631V13.9956H2.49814V16.204Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.03869 16.204H5.86685V13.9956H5.03869V16.204Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.57799 16.204H8.40616V13.9956H7.57799V16.204Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.1173 16.204H10.9456V13.9956H10.1173V16.204Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.49814 11.3456H3.32631V9.13725H2.49814V11.3456Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.03869 11.3456H5.86685V9.13725H5.03869V11.3456Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.57799 11.3456H8.40616V9.13725H7.57799V11.3456Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.1173 11.3456H10.9456V9.13725H10.1173V11.3456Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Building;
