import { Button, Tooltip } from 'antd';
import Icon from 'Components/icons/icon';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './table-actions-buttons.less';

interface Buttons {
    iconName: string;
    toolTip: string;
    onClick: () => void;
    disabled?: boolean;
}

interface TableActionsButtonsProps {
    buttonsList: Buttons[];
}

const TableActionsButtons: FunctionComponent<TableActionsButtonsProps> = ({ buttonsList }) => {
    const { t } = useTranslation();

    return (
        <div className='TableActionsButtons'>
            {buttonsList.map((button, i) => (
                <Tooltip key={i} title={t(button.toolTip)}>
                    <Button onClick={button.onClick} disabled={button.disabled}>
                        <Icon iconName={button.iconName} />
                    </Button>
                </Tooltip>
            ))}
        </div>
    );
};

export default TableActionsButtons;
