import { Button, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import { GetRentRollReportRequestDto } from 'Api/Features/Reports/Dtos/RentRoll/GetRentRollReportRequestDto';
import Icon from 'Components/icons/icon';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import TableActionsButtons from 'Components/table-action-buttons/table-actions-buttons';
import { TableFilters } from 'Components/table-filters';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import {
    ALL_LOCATIONS,
    MOMENT_PARSING_FORMAT,
    MOMENT_PARSING_MONTH_FORMAT,
} from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import moment from 'moment';
import React, {
    FunctionComponent,
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { RentRollReportService } from 'Services/RentRollReportService';
import { FilterStore } from 'Stores';
import { showFile } from 'Utils';
import { cleanVal, currencyFormatter } from 'Utils/NumberUtils';
import { displayFormatedDate } from 'Utils/TimeUtils';
import { ListSectionHeader } from '../../../../Components/list-section-header';
import { images, theme } from '../../../../variant';
import './index.less';

const { usersHeader } = images;

interface ExtractUnitTypeList {
    key: number;
    name: string;
    securityDeposit: string;
    market: string;
    rent: string;
    addOns: string;
    discount: string;
    total: string;
}

interface RentRollTotal {
    securityDeposit: string;
    market: string;
    rent: string;
    addOns: string;
    discount: string;
    total: string;
}

interface ExctractUnitsList {
    hasSub: boolean;
    subId: string;
    unitLocation?: string | null;
    unitName?: string;
    unitDesks?: number;
    unitSquareFeet?: number;
    subCompany?: string | null;
    subEndDate: string;
    unitSecurityDeposit?: number;
    unitMarket?: number;
    unitRent?: number;
    unitAddOns?: number;
    unitDiscount?: number;
    unitTotal?: number;
}

const advancedFilters: AdvancedFilter[] = [];

const RentRoll: FunctionComponent = observer(() => {
    const { locationStore } = useStores();
    const { globalLoadingStore, toastStore, userPermissionsStore, requestStore } = useStores();
    const rentRollReportService = useService(RentRollReportService);
    const [rentRollReportRequest, setRentRollReportRequest] = useState<
        GetRentRollReportRequestDto | undefined
    >(undefined);
    const [unitTypeList, setUnitTypeList] = useState<ExtractUnitTypeList[]>();
    const [unitList, setUnitList] = useState<((ExctractUnitsList | undefined)[] | undefined)[]>([]);
    const [rentRollTotal, setRentRollTotal] = useState<RentRollTotal>();
    const refRowKeysToExpand = useRef<string[]>([]);
    const [rowKeysToExpand, setRowKeysToExpand] = useState<string[] | undefined>(undefined);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { t } = useTranslation();

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            nameKey: 'reports',
            path: 'reports',
        },
        {
            nameKey: 'Reports.rent_roll',
            path: 'rent-roll',
        },
    ];

    const onExportClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            let document;
            if (rentRollReportRequest) {
                const campusName =
                    locationStore?.locations.find(
                        (x) => x.id === rentRollReportRequest?.campusIds?.[0]
                    )?.name || ALL_LOCATIONS;

                document = await rentRollReportService.exportRentRollReport(rentRollReportRequest);

                showFile(
                    document,
                    `${t('Reports.rent_roll')} - ${moment(rentRollReportRequest.date).format(
                        MOMENT_PARSING_MONTH_FORMAT
                    )} - ${campusName}`
                );
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const tableActionButtons = [
        {
            iconName: 'UnExpandAll',
            toolTip: 'unexpand_all',
            onClick: (): void => setRowKeysToExpand([]),
        },
        {
            iconName: 'ExpandAll',
            toolTip: 'expand_all',
            onClick: (): void => setRowKeysToExpand(refRowKeysToExpand.current),
        },
        {
            iconName: 'Download',
            toolTip: 'export_table',
            onClick: (): Promise<void> => onExportClick(),
        },
    ];

    const renderCurrency = (value: string | undefined): string =>
        value ? currencyFormatter(value) : '';

    const renderEmphasisCell = (row: any, field: string): ReactElement => (
        <div className={`emphasis-${!row.hasSub} padding-left`}>{row[field]}</div>
    );

    const renderEmphasisDurationCell = (row: any): ReactElement => (
        <>
            <div className={`emphasis-${!row.hasSub} date`}>
                {row.hasSub ? (
                    <>
                        <div>{row.subStartDate}</div>
                        <div>{row.subEndDate}</div>
                    </>
                ) : (
                    ''
                )}
            </div>
        </>
    );

    const renderEmphasisCurrencyCell = (row: any, field: string): ReactElement => (
        <div className={`emphasis-${!row.hasSub}`}>{renderCurrency(row[field])}</div>
    );

    const renderUnit = (row: any): ReactElement => (
        <div>
            <div>{row.unitName}</div>
            {row.unitDesks !== 0 && <div>{row.unitDesks}</div>}
            {row.unitSquareFeet !== 0 && <div>{row.unitSquareFeet}</div>}
        </div>
    );

    const unitTypeColumns: ColumnType<ExtractUnitTypeList>[] = [
        {
            title: t('location'),
            render: (row): ReactNode => ({
                children: row.name,
                props: { colSpan: 6 },
            }),
            className: 'unit-type-cell',
        },
        {
            title: t('unit'),
            render: (): ReactNode => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('Reports.dk'),
            dataIndex: 'openDesksTotal',
            render: (): ReactNode => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('Reports.sf'),
            dataIndex: 'officeDesksTotal',
            render: (): ReactNode => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('company'),
            dataIndex: 'desksTotal',
            render: (): ReactNode => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('duration'),
            dataIndex: 'occupiedDesksTotal',
            render: () => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('security_deposit'),
            dataIndex: 'securityDeposit',
            render: renderCurrency,
            align: 'right',
        },
        {
            title: t('Reports.market'),
            dataIndex: 'market',
            render: renderCurrency,
            align: 'right',
        },
        {
            title: t('Reports.rent'),
            dataIndex: 'rent',
            render: renderCurrency,
            align: 'right',
        },
        {
            title: t('Reports.dash_addons'),
            dataIndex: 'addOns',
            render: renderCurrency,
            align: 'right',
        },
        {
            title: t('discount'),
            dataIndex: 'discount',
            render: (value): string => renderCurrency(value),
            align: 'right',
        },
        {
            title: t('total'),
            dataIndex: 'total',
            render: renderCurrency,
            align: 'right',
        },
    ];

    const expandedRowRender = (record: any): ReactElement => {
        const columns: ColumnType<any>[] = [
            {
                render: (): string => '',
                key: 'empty',
            },
            { dataIndex: 'unitLocation' },
            {
                className: 'unit',
                render: renderUnit,
            },
            {
                className: 'emphasis',
                render: (row): ReactElement => renderEmphasisCell(row, 'subCompany'),
            },
            {
                className: 'emphasis',
                render: (row): ReactElement => renderEmphasisDurationCell(row),
            },
            {
                className: 'emphasis',
                render: (row): ReactElement => renderEmphasisCurrencyCell(row, 'unitSecurityDeposit'),
            },
            {
                className: 'emphasis',
                render: (row): ReactElement => renderEmphasisCurrencyCell(row, 'unitMarket'),
            },
            {
                className: 'emphasis',
                render: (row): ReactElement => renderEmphasisCurrencyCell(row, 'unitRent'),
            },
            {
                className: 'emphasis',
                render: (row): ReactElement => renderEmphasisCurrencyCell(row, 'unitAddOns'),
            },
            {
                className: 'emphasis',
                render: (row): ReactElement => renderEmphasisCurrencyCell(row, 'unitDiscount'),
            },
            {
                className: 'emphasis',
                render: (row): ReactElement => renderEmphasisCurrencyCell(row, 'unitTotal'),
            },
        ];

        return (
            <Table
                showHeader={false}
                columns={columns}
                dataSource={unitList[record.key]}
                rowKey={(record): string => `${record.subId}`}
                pagination={false}
            />
        );
    };

    const renderTableFooter = (): ReactElement => (
        <>
            <div>{t('total')}</div>
            <div>{renderCurrency(rentRollTotal?.securityDeposit)}</div>
            <div>{renderCurrency(rentRollTotal?.market)}</div>
            <div>{renderCurrency(rentRollTotal?.rent)}</div>
            <div>{renderCurrency(rentRollTotal?.addOns)}</div>
            <div>{renderCurrency(rentRollTotal?.discount)}</div>
            <div>{renderCurrency(rentRollTotal?.total)}</div>
        </>
    );

    const fetchRentRoll = useCallback(
        async (params: { date?: string; campusIds: string[] | null }) => {
            globalLoadingStore.addLoading();

            try {
                const response = await rentRollReportService.getRentRollReport({
                    date: params.date,
                    campusIds: params.campusIds,
                });

                const rentRollTotal: RentRollTotal = {
                    securityDeposit: `${response?.total?.securityDeposits}`,
                    market: `${response?.total?.marketPrice}`,
                    rent: `${response?.total?.rent}`,
                    addOns: `${response?.total?.addOns}`,
                    discount: `${response?.total?.discounts}`,
                    total: `${response?.total?.total}`,
                };

                refRowKeysToExpand.current = [];

                const exctractUnitsList: ((ExctractUnitsList | undefined)[] | undefined)[] = [];
                const extractUnitTypeList: ExtractUnitTypeList[] | undefined =
                    response?.unitTypes?.map((unitType, i) => {
                        exctractUnitsList[i] = unitType?.units
                            ?.filter((unit) => unit !== null && unit !== undefined)
                            .map((unit) => {
                                let key;
                                let unitWithSubscription = unit?.subscriptions?.map(
                                    (subscription, iSub) => {
                                        key = iSub;

                                        // In the report, multiple lines can appear for the same unit, in cases where the same unit is in use by multiples subscriptions in the same month.
                                        // In this case we only show the market price once on the first line.
                                        let unitMarketPrice = 0;
                                        if (iSub == 0) unitMarketPrice = unit?.marketPrice ?? 0;

                                        return {
                                            hasSub: true,
                                            subId: `${unit?.id}-${subscription?.id}`,
                                            unitLocation: unit.campus?.name,
                                            unitName: cleanVal.string(unit.name),
                                            unitDesks: unit.numberOfDesks,
                                            unitSquareFeet: unit.squareFeet,
                                            subCompany: subscription?.membership?.name,
                                            subStartDate: displayFormatedDate(
                                                subscription?.startDate,
                                                MOMENT_PARSING_FORMAT
                                            ),
                                            subEndDate: subscription?.endDate
                                                ? displayFormatedDate(
                                                      subscription?.endDate,
                                                      MOMENT_PARSING_FORMAT
                                                  )
                                                : 'MTM',
                                            unitSecurityDeposit: subscription?.securityDeposit,
                                            unitMarket: unitMarketPrice,
                                            unitRent: subscription?.rentAmount,
                                            unitAddOns: subscription?.addOnsAmount,
                                            unitDiscount: subscription?.discountsAmount,
                                            unitTotal:
                                                (subscription?.rentAmount ?? 0) +
                                                (subscription?.addOnsAmount ?? 0) +
                                                (subscription?.discountsAmount ?? 0),
                                        };
                                    }
                                );

                                // If there are no subscriptions, it means this unit is empty (vacant) for the whole month. We still show an empty line to show it is vacant.
                                if (unitWithSubscription && unitWithSubscription?.length < 1) {
                                    unitWithSubscription = [
                                        {
                                            hasSub: false,
                                            subId: `noSub-${key}-${unit?.id}`,
                                            unitLocation: unit?.campus?.name,
                                            unitName: cleanVal.string(unit?.name),
                                            unitDesks: unit?.numberOfDesks,
                                            unitSquareFeet: unit?.squareFeet,
                                            subCompany: '',
                                            subStartDate: '-',
                                            subEndDate: '-',
                                            unitSecurityDeposit: unit?.total?.securityDeposits,
                                            unitMarket: unit?.total?.marketPrice ?? 0,
                                            unitRent: unit?.total?.rent,
                                            unitAddOns: unit?.total?.addOns,
                                            unitDiscount: unit?.total?.discounts,
                                            unitTotal: unit?.total?.total ?? 0,
                                        },
                                    ];
                                }

                                return unitWithSubscription;
                            })
                            .flat();

                        refRowKeysToExpand.current.push(t(`Unit.type_${unitType?.type}`));

                        return {
                            key: i,
                            name: t(`Unit.type_${unitType?.type}`),
                            securityDeposit: `${unitType?.total?.securityDeposits}`,
                            market: `${unitType?.total?.marketPrice}`,
                            rent: `${unitType?.total?.rent}`,
                            addOns: `${unitType?.total?.addOns}`,
                            discount: `${unitType?.total?.discounts}`,
                            total: `${unitType?.total?.total}`,
                        };
                    });

                setRentRollTotal(rentRollTotal);
                setUnitTypeList(extractUnitTypeList);
                setUnitList(exctractUnitsList);
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [rentRollReportService, globalLoadingStore]
    );

    useEffect(() => {
        const filterStore = filterStoreRef.current;

        filterStore.month = moment().format(MOMENT_PARSING_FORMAT);
    }, []);

    useEffect(() => {
        const filterStore = filterStoreRef.current;

        const disposer = autorun(() => {
            const date = moment(filterStore.month).format(MOMENT_PARSING_FORMAT);
            const campusId =
                filterStore.currentLocationId === 'all'
                    ? requestStore.getReportRequestAvailableLocationIdsForUser()
                    : [filterStore.currentLocationId];

            fetchRentRoll({
                date: date,
                campusIds: campusId,
            });

            setRentRollReportRequest({
                date: date,
                campusIds: campusId,
            });
        });

        return (): void => {
            disposer();
        };
    }, [fetchRentRoll]);

    return (
        <div className="RentRoll">
            <ListSectionHeader
                routes={breadcrumbs}
                backgroundImageUrl={usersHeader}
                defaultImg={<Icon iconName="Ticket" fill={theme['primary-color']} />}
                title={t('Reports.rent_roll')}
                subTitle={t('Reports.rent_roll_overview')}
            />

            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeMonth
                    includeLocations
                    availableLocations={userPermissionsStore.availableLocationsForUser}
                />

                <TableActionsButtons buttonsList={tableActionButtons} />

                <Table
                    className="expand-table table-striped-rows table-action-rows"
                    rowClassName="unit-type-row"
                    bordered
                    columns={unitTypeColumns}
                    dataSource={unitTypeList || undefined}
                    expandable={{
                        expandedRowRender: expandedRowRender,
                        // eslint-disable-next-line react/display-name
                        expandIcon: ({ expanded, onExpand, record }): ReactElement =>
                            expanded ? (
                                <Button
                                    onClick={(e): void => {
                                        setRowKeysToExpand(undefined);
                                        onExpand(record, e);
                                    }}
                                >
                                    <Icon iconName="AngleTop" />
                                </Button>
                            ) : (
                                <Button
                                    onClick={(e): void => {
                                        setRowKeysToExpand(undefined);
                                        onExpand(record, e);
                                    }}
                                >
                                    <Icon iconName="AngleBottom" />
                                </Button>
                            ),
                        expandedRowKeys: rowKeysToExpand,
                    }}
                    rowKey={(record): string => record.name}
                    footer={renderTableFooter}
                    pagination={false}
                />
            </Content>
        </div>
    );
});

export default RentRoll;
