import { ConferenceRoomDto } from 'Api/Features/Amenities/Dtos/ConferenceRooms/ConferenceRoomDto';
import { Delete, ConferenceRoom as AmenityIcon, Tick as CheckMark, Caution, PencilBorder } from 'Components/icons';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { ACTIVTY_CALENDAR_EVENT_DATE_DISPLAY, DISPLAY_TIME_12H_FORMAT } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'variant';
import timezone from 'moment-timezone';
import './booking-conflict-item.less';

export interface BookingConflictItemProps {
    id: string;
    periodStart?: moment.Moment;
    periodEnd?: moment.Moment;
    amenity?: ConferenceRoomDto | null;
    resolved?: boolean;
    errorMessage?: string;
    onDeleteClick: (id: string) => void;
    onEditClick: (id: string) => void;
    isCanceled?: boolean;
    timeZone: string | null;
}

export const BookingConflictItem: FunctionComponent<BookingConflictItemProps> = ({
    id,
    periodStart,
    periodEnd,
    amenity,
    resolved,
    errorMessage,
    onDeleteClick,
    onEditClick,
    isCanceled,
    timeZone
}) => {
    const { t } = useTranslation();
    
    return (
        <div className={`BookingConflictItem ${isCanceled ? 'canceled' : ''}`}>
            <div className="row-container">
                <div className="booking-date-container">
                    <div className="booking-date">{periodStart?.format(ACTIVTY_CALENDAR_EVENT_DATE_DISPLAY)}</div>
                    <div className="booking-time">{`${timezone(periodStart).tz(timeZone!).format(
                        DISPLAY_TIME_12H_FORMAT
                    )} - ${timezone(periodEnd).tz(timeZone!).format(DISPLAY_TIME_12H_FORMAT)}`}</div>
                </div>
                <div className="actions-container">
                    <div onClick={(): void => onDeleteClick(id)}>
                        <Delete width={24} height={24} fill={theme['primary-color']} />
                    </div>
                    <div onClick={(): void => onEditClick(id)}>
                        <PencilBorder width={24} height={24} fill={theme['primary-color']} />
                    </div>
                </div>
            </div>

            <div className="row-container">
                <div className="amenity">
                    <ImageWithPlaceholder
                        width={32}
                        height={32}
                        imgSrc={amenity?.imageUrl}
                        defaultImg={<AmenityIcon fill={theme['white']} />}
                    />
                    <span className="amenity-name">{amenity?.name}</span>
                </div>

                <div className={`resolution ${resolved ? 'resolved' : ''}`}>
                    {resolved ? (
                        <div className="resolution-container">
                            <span className="text">{t('Booking.conflict_resolved')}</span>
                            <CheckMark width={22} height={24} fill={theme['success-green-1']} />
                        </div>
                    ) : (
                        <div className="resolution-container">
                            <span className="text">{errorMessage}</span>
                            <Caution width={24} height={20} fill={theme['error-red-1']} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};