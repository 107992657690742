import React, { FunctionComponent } from 'react';
import { Button } from 'antd';
import { images } from '../../../variant';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import './unauthorized.less';

const UnauthorizedPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const onButtonClick = (): void => {
        if (history.length > 1) {
            history.goBack();
        } else {
            history.replace('/');
        }
    };

    return (
        <div className="UnauthorizedPage">
            <img className="logo" src={images.logoWhite} alt={t('brand_name')} />
            <h1 className="unauthorized">{t('Errors.forbidden')}</h1>
            <Button type="primary" className="back-to-site" onClick={onButtonClick}>
                {t('Navigation.back_to_site')}
            </Button>
        </div>
    );
};

export default UnauthorizedPage;
