// GENERATED FILE - DO NOT MODIFY
import { CreateDayPassRequestDto } from 'Api/Features/DayPasses/Dtos/CreateDayPassRequestDto';
import { DayPassDto } from 'Api/Features/DayPasses/Dtos/DayPassDto';
import { GetDayPassesRequestDto } from 'Api/Features/DayPasses/Dtos/GetDayPassesRequestDto';
import { GetDayPassesResponseDto } from 'Api/Features/DayPasses/Dtos/GetDayPassesResponseDto';
import { GetDayPassGroupsResponseDto } from 'Api/Features/DayPasses/Dtos/GetDayPassGroupsResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class DayPassProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getDayPass(dayPassId: string): Promise<DayPassDto | null> {
        const uri = this.buildUri(
            "/day-passes/{dayPassId}",
            { dayPassId: dayPassId },
            null
        );

        const data: DayPassDto | null = await this.httpClient.get<DayPassDto | null>(uri);
        return data;
    }

    public async getDayPasses(request: GetDayPassesRequestDto | null): Promise<GetDayPassesResponseDto | null> {
        const uri = this.buildUri(
            "/day-passes",
            null,
            { ...request || undefined }
        );

        const data: GetDayPassesResponseDto | null = await this.httpClient.get<GetDayPassesResponseDto | null>(uri);
        return data;
    }

    public async getDayPassGroups(accountId?: string | null, membershipId?: string | null, available?: boolean | null): Promise<GetDayPassGroupsResponseDto | null> {
        const uri = this.buildUri(
            "/day-pass-groups",
            null,
            { accountId: accountId, membershipId: membershipId, available: (available === null || available === undefined) ? available : available.toString() }
        );

        const data: GetDayPassGroupsResponseDto | null = await this.httpClient.get<GetDayPassGroupsResponseDto | null>(uri);
        return data;
    }

    public async createDayPass(request: CreateDayPassRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/day-passes",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateDayPassRequestDto | null, string>(uri, request);
        return data;
    }
}