import { AcceptedSubscriptionsReportProxy } from 'Api/Features/Reports/AcceptedSubscriptionsReportProxy';
import { AcceptedSubscriptionsReportDto } from 'Api/Features/Reports/Dtos/AcceptedSubscriptions/AcceptedSubscriptionsReportDto';
import { GetAcceptedSubscriptionsReportRequestDto } from 'Api/Features/Reports/Dtos/AcceptedSubscriptions/GetAcceptedSubscriptionsReportRequestDto';
import { inject } from 'aurelia-dependency-injection';
import { arrayBufferToXlsx } from 'Utils/blobs';
import { ApiService } from './ApiService';

@inject(AcceptedSubscriptionsReportProxy)
export class AcceptedSubscriptionsReportService extends ApiService {
    constructor(
        private readonly acceptedSubscriptionsReportProxy: AcceptedSubscriptionsReportProxy
    ) {
        super();
    }

    public async getAcceptedSubscriptionsReport(
        request: GetAcceptedSubscriptionsReportRequestDto | null
    ): Promise<AcceptedSubscriptionsReportDto | null> {
        return await this.acceptedSubscriptionsReportProxy.getAcceptedSubscriptionsReport(request);
    }

    public async exportAcceptedSubscriptionsReport(
        request: GetAcceptedSubscriptionsReportRequestDto | null
    ): Promise<Blob> {
        const document = await this.acceptedSubscriptionsReportProxy.exportAcceptedSubscriptionsReport(
            request
        );
        return arrayBufferToXlsx(document);
    }
}
