import { Col, Layout, Row } from 'antd';
import { PlanAddOnDto } from 'Api/Features/Plans/Dtos/PlanAddOnDto';
import { PlanDto } from 'Api/Features/Plans/Dtos/PlanDto';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import Icon from 'Components/icons/icon';
import { ListSectionHeader } from 'Components/list-section-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import { useService, useStores } from 'Hooks';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import CreateSubscriptionPlanModal from 'Routes/authenticated/locations/modals/create-subscription-plan-modal';
import { PlanService } from 'Services/PlanService';
import { cleanVal, currencyFormatter } from 'Utils/NumberUtils';
import { images, theme } from 'variant';
import CreateSubscriptionPlanTemplateModal from '../create-subscription-plan-template-modal';
import './index.less';

const { Content } = Layout;
const { usersHeader } = images;

interface DataProps {
    id: string;
    name: string;
    imageUrl: string;
    isPriceFlexible: boolean;
    price: string;
}
const dataPropsDefaultValue = {
    id: '',
    name: '',
    imageUrl: '',
    isPriceFlexible: false,
    price: '',
};

interface DataDetailsProps {
    basicInformation: SimpleListItemProps[];
    designedFor: SimpleListItemProps[];
    termsOfPayment: SimpleListItemProps[];
    included: SimpleListItemProps[];
    description: SimpleListItemProps[];
}

const dataDetailsDefaultValue = {
    basicInformation: [],
    designedFor: [],
    termsOfPayment: [],
    included: [],
    description: [],
};

const SubscriptionPlanDetails: FunctionComponent = () => {
    const planService = useService(PlanService);
    const { globalLoadingStore, confirmationModalStore, toastStore } = useStores();
    const [loading, setLoading] = useState(true);
    const [rawData, setRawData] = useState<PlanDto>();
    const [data, setData] = useState<DataProps>(dataPropsDefaultValue);
    const [dataDetails, setDataDetails] = useState<DataDetailsProps>(dataDetailsDefaultValue);
    const [addOns, setAddOns] = useState<SimpleListItemProps[]>([]);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();

    const { id, locationSubscriptionPlanId } = useParams<{
        id: string;
        locationSubscriptionPlanId?: string;
    }>();

    const locationManagement = {
        isTrue: !!locationSubscriptionPlanId,
        subscriptionPlanId: cleanVal.string(locationSubscriptionPlanId),
        locationId: cleanVal.string(id),
    };

    const currentSubscriptionPlanId = locationManagement.isTrue
        ? locationManagement.subscriptionPlanId
        : id;

    const breadcrumb: BreadcrumbSegment[] = [
        {
            nameKey: locationManagement.isTrue
                ? 'management'
                : 'Administration.administration_title',
            path: locationManagement.isTrue
                ? `locations/${locationManagement.locationId}/management`
                : 'administration',
        },

        {
            nameKey: `Administration.subscription_plans_title${
                locationManagement.isTrue ? '' : '_template'
            }`,
            path: 'subscription-plans',
        },
        {
            path: data.id,
            name: data.name,
        },
    ];

    const deleteSubscriptionPlanTemplate = async (): Promise<void> => {
        try {
            if (
                !(await confirmationModalStore.confirm({
                    width: 850,
                    icon: <Icon iconName="Delete" />,
                    title: t(`Administration.subscription_plans_delete_title`),
                    message: t(`action_cannot_be_undone`),
                    positiveText: t(`model_confirm_positive_delete`, {
                        param1: t('Administration.subscription_plans_delete_label'),
                    }),
                    negativeText: t(`model_confirm_negative_delete`, {
                        param1: t('Administration.subscription_plans_delete_label'),
                    }),
                }))
            )
                return;

            await planService.deletePlan(currentSubscriptionPlanId);

            toastStore.toast({
                type: 'success',
                messageKey: t('model_delete_success', {
                    param1: t('Administration.subscription_plans_delete_label'),
                }),
            });

            history.goBack();
        } catch (e) {
            toastStore.toast({
                type: 'error',
                messageKey: e.response.data.error_description,
            });
        }
    };

    const menuOptions: ActionMenuOption[] = [
        {
            key: 'edit',
            title: t('Administration.subscription_plans_edit'),
            action: (): void => setEditModalOpen(true),
        },
        {
            key: 'delete',
            title: t('Administration.subscription_plans_delete'),
            action: (): Promise<void> => deleteSubscriptionPlanTemplate(),
        },
    ];

    const subtitleComponent = locationManagement.isTrue ? (
        <>
            {data?.isPriceFlexible && <div>Price is flexible</div>}
            <div className="price">{data.price}</div>
        </>
    ) : undefined;

    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();

        try {
            const response = await planService.getPlan(currentSubscriptionPlanId);

            if (response) {
                setRawData(response);
                setData({
                    id: cleanVal.string(response.id),
                    name: cleanVal.string(response.name),
                    imageUrl: cleanVal.string(response.imageUrl),
                    isPriceFlexible: response.isPriceFlexible || false,
                    price: currencyFormatter(cleanVal.number(response.price)),
                });

                if (locationManagement.isTrue && response.addOns) {
                    const nonNullAddOns = response.addOns.filter(
                        (addOn): addOn is PlanAddOnDto => addOn !== null
                    );
                    const addOnsList = nonNullAddOns.map((addOn) => ({
                        title: cleanVal.string(addOn.name),
                        description: cleanVal.string(addOn.description),
                    }));

                    setAddOns(addOnsList);
                } else {
                    const basicInformation: SimpleListItemProps[] = response.unitType
                        ? [
                              {
                                  title: t('Unit.type_title'),
                                  description: t(`Unit.type_${response.unitType}`),
                              },
                          ]
                        : [];
                    const designedFor: SimpleListItemProps[] = response.designedFor
                        ? [
                              {
                                  title: response.designedFor,
                              },
                          ]
                        : [];
                    const termsOfPayment: SimpleListItemProps[] = response.terms
                        ? [
                              {
                                  title: response.terms,
                              },
                          ]
                        : [];
                    const included: SimpleListItemProps[] = response.inclusion
                        ? [
                              {
                                  title: response.inclusion,
                              },
                          ]
                        : [];
                    const description: SimpleListItemProps[] = response.description
                        ? [
                              {
                                  title: response.description,
                              },
                          ]
                        : [];

                    setDataDetails({
                        basicInformation,
                        designedFor,
                        termsOfPayment,
                        included,
                        description,
                    });
                }
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [planService, globalLoadingStore, currentSubscriptionPlanId, locationManagement.isTrue, t]);

    useEffect(() => {
        if (!editModalOpen) fetch();
    }, [fetch, editModalOpen]);

    return (
        <div className="SubscriptionPlanDetails">
            <ListSectionHeader
                title={data.name}
                subTitle={subtitleComponent}
                imgSrc={data.imageUrl}
                defaultImg={<Icon iconName="Plan" fill={theme['primary-color']} />}
                backgroundImageUrl={usersHeader}
                action={<ActionMenu options={menuOptions} type="primary" trigger="click" />}
                routes={breadcrumb}
                loading={loading}
            />

            <Content>
                {locationManagement.isTrue ? (
                    addOns.length > 0 && (
                        <Row gutter={40}>
                            <Col span={12}>
                                <SimpleList title={t('add_ons')} data={addOns} />
                            </Col>
                        </Row>
                    )
                ) : (
                    <Row gutter={40}>
                        <Col span={12}>
                            <Row>
                                {dataDetails.basicInformation.length > 0 && (
                                    <Col span={24}>
                                        <SimpleList
                                            title={t('basic_information')}
                                            data={dataDetails.basicInformation}
                                            className="large-description"
                                        />
                                    </Col>
                                )}

                                {dataDetails.termsOfPayment.length > 0 && (
                                    <Col span={24}>
                                        <SimpleList
                                            title={t(
                                                'Administration.subscription_plans_terms_of_payment'
                                            )}
                                            data={dataDetails.termsOfPayment}
                                            className="editor-style"
                                        />
                                    </Col>
                                )}

                                {dataDetails.description.length > 0 && (
                                    <Col span={24}>
                                        <SimpleList
                                            title={t('description')}
                                            data={dataDetails.description}
                                            className="editor-style"
                                        />
                                    </Col>
                                )}
                            </Row>
                        </Col>

                        <Col span={12}>
                            <Row>
                                {dataDetails.designedFor.length > 0 && (
                                    <Col span={24}>
                                        <SimpleList
                                            title={t(
                                                'Administration.subscription_plans_designed_for'
                                            )}
                                            data={dataDetails.designedFor}
                                            className="editor-style"
                                        />
                                    </Col>
                                )}

                                {dataDetails.included.length > 0 && (
                                    <Col span={24}>
                                        <SimpleList
                                            title={t(
                                                'Administration.subscription_plans_what_is_included'
                                            )}
                                            data={dataDetails.included}
                                            className="editor-style"
                                        />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                )}
            </Content>

            {!locationManagement.isTrue && editModalOpen && (
                <CreateSubscriptionPlanTemplateModal
                    visible={editModalOpen}
                    onComplete={(success: boolean, id?: string): void => {
                        setEditModalOpen(false);
                        if (success && id) history.push(`subscription-plans/${id}`);
                    }}
                    subscriptionPlan={rawData}
                />
            )}

            {locationManagement.isTrue && editModalOpen && (
                <CreateSubscriptionPlanModal
                    visible={editModalOpen}
                    onComplete={(success: boolean, id?: string): void => {
                        setEditModalOpen(false);
                        if (success && id) history.push(`subscription-plans/${id}`);
                    }}
                    subscriptionPlan={rawData}
                    campusId={locationManagement.locationId}
                />
            )}
        </div>
    );
};

export default SubscriptionPlanDetails;
