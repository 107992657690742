import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { ColumnType } from 'antd/es/table';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetMembershipsRequestDto } from 'Api/Features/Memberships/Dtos/GetMembershipsRequestDto';
import { GetMembershipsSortColumnDto } from 'Api/Features/Memberships/Dtos/GetMembershipsSortColumnDto';
import { MembershipDto } from 'Api/Features/Memberships/Dtos/MembershipDto';
import Icon from 'Components/icons/icon';
import { TableFilters } from 'Components/table-filters';
import { useService, useStores } from 'Hooks';
import { debounce } from 'lodash';
import { autorun } from 'mobx';
import { ALL_LOCATIONS, TABLE_DEBOUNCE_DELAY } from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { MembershipService } from 'Services/MembershipService';
import { FilterStore } from 'Stores';
import { cleanVal } from 'Utils/NumberUtils';
import { TdWithImage } from 'Components/td-with-image';
import { GetMembershipsFlagDto } from 'Api/Features/Memberships/Dtos/GetMembershipsFlagDto';
import { Tag } from 'Components/tag';
import { TagColors } from 'Components/tag/tag';
import './companies-table.less';
import { useHistory } from 'react-router';
import { RequestType } from 'Stores/RequestStore';
import { useParams } from 'react-router-dom';
import { showFile } from 'Utils';

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 10,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'statuses',
        nameKey: 'Statuses',
        items: [
            {
                key: GetMembershipsFlagDto.NoSubscriptions,
                displayNameKey: `Membership.GetMembershipsFlagDto_${GetMembershipsFlagDto.NoSubscriptions}`,
                checked: true,
            },
            {
                key: GetMembershipsFlagDto.HasPendingOpportunity,
                displayNameKey: `Membership.GetMembershipsFlagDto_${GetMembershipsFlagDto.HasPendingOpportunity}`,
                checked: true,
            },
            {
                key: GetMembershipsFlagDto.HasPendingOnboarding,
                displayNameKey: `Membership.GetMembershipsFlagDto_${GetMembershipsFlagDto.HasPendingOnboarding}`,
                checked: true,
            },
            {
                key: GetMembershipsFlagDto.IsSubscribed,
                displayNameKey: `Membership.GetMembershipsFlagDto_${GetMembershipsFlagDto.IsSubscribed}`,
                checked: true,
            },
        ],
    },
];

const labelWithImage = (
    imageUrl?: string,
    label?: string,
    defaultImg = <Icon iconName="Company" />
): ReactNode => (
    <TdWithImage defaultImg={defaultImg} imgSrc={imageUrl}>
        {label}
    </TdWithImage>
);

interface CompanyTableProps {
    globalList?: boolean;
    campusId?: string;
}

const CompaniesTable: FunctionComponent<CompanyTableProps> = ({ globalList, campusId }) => {
    const paginationRef = useRef(initialPaginationState);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<MembershipDto[]>([]);
    const membershipService = useService(MembershipService);
    const [requestSetFromCache, setRequestSetFromCache] = useState(false);
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const { locationStore, toastStore, requestStore, userPermissionsStore, globalLoadingStore } =
        useStores();
    const [isTableEmpty, setIsTableEmpty] = useState<boolean>(true);

    const inLocation = {
        isTrue: !!campusId,
        id: campusId,
    };

    const { t } = useTranslation();

    const onRowClick = (membership: MembershipDto): void => {
        if (inLocation.isTrue) {
            history.push(`/locations/${id}/companies/${membership.id}`);
        } else history.push(`companies/${membership.id}/dashboard`);
    };

    const renderMembers = (membersNb?: number): string => {
        const memberWord = membersNb ?? 0 > 1 ? t('members') : t('member');

        return `${membersNb} ${memberWord}`;
    };

    const locationRender = (membership: MembershipDto): ReactNode => {
        return membership?.campuses && membership.campuses.length > 0 ? (
            <TdWithImage
                defaultImg={<Icon iconName="Location" />}
                imgSrc={membership.campuses[0]?.mainImageUrl}
            >
                {membership.campuses.map((campus) => campus?.name).join(', ')}
            </TdWithImage>
        ) : (
            ''
        );
    };

    const renderStatus = (membership: MembershipDto): ReactNode => {
        const statuses: GetMembershipsFlagDto[] = [];
        membership.isSubscribed
            ? statuses.push(GetMembershipsFlagDto.IsSubscribed)
            : statuses.push(GetMembershipsFlagDto.NoSubscriptions);
        if (membership.hasPendingOnboarding)
            statuses.push(GetMembershipsFlagDto.HasPendingOnboarding);
        if (membership.hasPendingOpportunity)
            statuses.push(GetMembershipsFlagDto.HasPendingOpportunity);

        return statuses.map((status) => (
            <Tag
                label={t(`Membership.GetMembershipsFlagDto_${status}`)}
                key={status}
                color={
                    status === GetMembershipsFlagDto.NoSubscriptions
                        ? TagColors.slateBlue40
                        : TagColors.default
                }
            />
        ));
    };

    const columns: ColumnType<MembershipDto>[] = [
        {
            title: t('name'),
            render: (membership: MembershipDto): ReactNode =>
                labelWithImage(membership.imageUrl ?? undefined, membership.name ?? undefined),
            sorter: true,
            key: GetMembershipsSortColumnDto.Name,
        },
        {
            title: `# ${t('members')}`,
            render: (membership: MembershipDto) =>
                renderMembers(membership.memberCount ?? undefined),
            key: GetMembershipsSortColumnDto.MemberCount,
            sorter: true,
        },
        {
            title: t('status'),
            render: renderStatus,
        },
        {
            title: t('Location.location_title'),
            render: locationRender,
        },
    ];

    const getAdvancedFilterStatuses = (): {
        checkedStatuses: GetMembershipsFlagDto[];
        statusesCount: number;
    } => {
        const filterStore = filterStoreRef.current;
        const statuses = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'statuses'
        );
        const checkedStatuses = statuses?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key;
            });

        return {
            checkedStatuses:
                checkedStatuses?.map(
                    (x) => GetMembershipsFlagDto[x as keyof typeof GetMembershipsFlagDto]
                ) ?? [],
            statusesCount: statuses?.items.length ?? 0,
        };
    };

    const fetchCompaniesList = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            currentLocationId?: string;
            advancedFilters?: AdvancedFilter[];
            sortColumn: GetMembershipsSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);

            try {
                const statuses = getAdvancedFilterStatuses();
                if (statuses.checkedStatuses.length === 0) {
                    setData([]);
                } else {
                    const campusId = inLocation.isTrue
                        ? [inLocation.id ?? null]
                        : params.currentLocationId === ALL_LOCATIONS
                        ? []
                        : [params.currentLocationId ?? null];

                    const request: GetMembershipsRequestDto = requestStore.setRequest({
                        request: {
                            campusIds: campusId,
                            flags:
                                statuses.checkedStatuses.length === statuses.statusesCount
                                    ? []
                                    : statuses.checkedStatuses,
                            page: (params.pagination.current || 1) - 1,
                            pageSize: params.pagination.pageSize || 10,
                            searchTerm: params.searchTerm,
                            sortColumn: params.sortColumn,
                            sortDirection: params.sortDirection,
                        },
                        requestType: RequestType.Company,
                    });

                    const [data, totalItems] = await membershipService.getMemberships(request);
                    setData(data);
                    setPagination({
                        ...params.pagination,
                        total: totalItems,
                    });
                    setIsTableEmpty(data.length === 0);
                }
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [t]
    );

    const debounceSearch = useRef(
        debounce(
            (params: {
                searchTerm?: string;
                currentLocationId?: string;
                advancedFilters?: AdvancedFilter[];
            }) => {
                fetchCompaniesList({
                    pagination: { ...paginationRef.current, current: 1 },
                    searchTerm: params.searchTerm,
                    currentLocationId: params.currentLocationId,
                    advancedFilters: params.advancedFilters,
                    sortColumn: null,
                    sortDirection: null,
                });
            },
            TABLE_DEBOUNCE_DELAY
        )
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;

            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                currentLocationId: filterStore.currentLocationId,
                advancedFilters: filterStore.advancedFilters,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    useEffect(() => {
        if (
            requestStore?.requestInfo?.request &&
            requestStore?.requestInfo?.requestType === RequestType.Company &&
            !requestSetFromCache
        ) {
            setRequestSetFromCache(true);
            const filterStore = filterStoreRef.current;
            const requestFromStore: GetMembershipsRequestDto = requestStore.requestInfo.request;
            const filtersList = [{ key: requestFromStore?.flags || [], parentKey: 'statuses' }];

            filterStore.updateLocation(
                requestFromStore.campusIds && requestFromStore?.campusIds.length > 0
                    ? cleanVal.string(requestFromStore.campusIds[0])
                    : ALL_LOCATIONS
            );

            filterStore.updateSearchTerm(cleanVal.string(requestFromStore.searchTerm));
            filterStore.tickMultipleAdvancedFilter(filtersList);
        }
    }, [requestStore, userPermissionsStore.userInfo]);

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        const { currentLocationId, advancedFilters, searchTerm } = filterStoreRef.current;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        await fetchCompaniesList({
            pagination,
            searchTerm,
            currentLocationId,
            advancedFilters,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
        paginationRef.current = pagination;
    };

    const onExportClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            const document = await membershipService.exportMemberships(
                requestStore.requestInfo.request
            );

            showFile(document, 'Companies');
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <div className="CompaniesTable">
            <TableFilters
                filterStore={filterStoreRef.current}
                includeAdvancedFilters
                includeSearch
                includeLocations={globalList}
                availableLocations={locationStore.locations}
                includeExport
                onExportClick={onExportClick}
                exportBtnDisabled={isTableEmpty}
            />

            <Table
                className="table-striped-rows table-action-rows"
                bordered
                dataSource={data}
                columns={columns}
                loading={loading}
                rowKey={(record: MembershipDto): string => record.id || ''}
                pagination={pagination}
                onChange={handleTableChange}
                onRow={(row: MembershipDto) => ({
                    onClick: (): void => {
                        onRowClick(row);
                    },
                })}
            />
        </div>
    );
};

export default CompaniesTable;
