import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Tick: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
                <path
                    d="M1.74253 7.23316C1.53842 7.04204 1.22075 7.05553 1.03298 7.26329C0.84522 7.47106 0.858472 7.79443 1.06258 7.98555L4.5648 11.265C4.79972 11.485 5.174 11.4289 5.33676 11.1494L11.0301 1.37188C11.1715 1.12903 11.0928 0.815467 10.8542 0.671524C10.6156 0.527581 10.3076 0.607762 10.1662 0.850613L4.78873 10.0856L1.74253 7.23316Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Tick;
