import { Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { MemberDetailsDto } from 'Api/Features/Members/Dtos/MemberDetailsDto';
import Icon from 'Components/icons/icon';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { useService, useStores } from 'Hooks';
import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ContactDetailsBody from 'Routes/authenticated/contacts/id/dashboard/contact-details-body';
import { MemberService } from 'Services/MemberService';
import { theme } from 'variant';

const LocationContactDetails: FunctionComponent = () => {
    const memberService = useService(MemberService);
    const { toastStore, globalLoadingStore } = useStores();
    const history = useHistory();
    const { contactId } = useParams<{ contactId: string }>();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [member, setMember] = useState<MemberDetailsDto>();

    const fetchMember = useCallback(async () => {
        try {
            globalLoadingStore.addLoading();
            setLoading(true);

            const memberResponse = await memberService.getMember(contactId);

            if (memberResponse)
                setMember(memberResponse);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
            setLoading(false);
        }
    }, [memberService, contactId, t]);

    useEffect(() => {
        fetchMember();
    }, [fetchMember]);

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'contacts',
            nameKey: 'User.contacts_label',
        },
        {
            path: `${member?.id}`,
            nameKey: `${member?.firstName} ${member?.lastName}`
        },
    ];

    return (
        <div className="LocationContactDetails">
            <LocationHeader
                title={`${member?.firstName} ${member?.lastName}`}
                subTitle={member?.jobPosition ?? ' '}
                imgSrc={member?.imageUrl}
                defaultImg={<Icon iconName="Company" fill={theme['primary-color']} />}
                routes={breadcrumbs}
                loading={loading}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            history.push(`/contacts/${contactId}/dashboard`)
                        }}
                    >
                        {t('Membership.manage_this_contact')}
                    </Button>
                }
            />

            <Content>
                {member && <ContactDetailsBody contact={member}/>}
            </Content>
        </div>
    );
};

export default LocationContactDetails;
