import { InvoiceStatusDto } from 'Api/Features/Invoices/Dtos/InvoiceStatusDto';
import { LightInvoiceDto } from 'Api/Features/Invoices/Dtos/LightInvoiceDto';
import { OpportunityDto } from 'Api/Features/Opportunities/Dtos/OpportunityDto';
import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';
import { SubscriptionAncestryDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionAncestryDto';
import { SubscriptionDetailsDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsDto';
import { SubscriptionProposalStatusDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionProposalStatusDto';
import { TFunction } from 'i18next';
import { SHORT_MONTH_DATE } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { ReactNode } from 'react-router/node_modules/@types/react';
import './tag.less';

export enum TagColors {
    default = 'default',
    disabled = 'disabled',
    error = 'error',
    hot = 'hot',
    warm = 'warm',
    cold = 'cold',
    dark = 'dark',
    active = 'active',
    light = 'light',
    slateBlue40 = 'slateBlue40'
}

export enum LabelColor {
    white = 'white',
    black = 'black'
}

export interface TagProps {
    label: string;
    color?: TagColors;
    deactivated?: boolean;
    rounded?: boolean;
    boldLabel?: boolean;
    labelColor?: LabelColor
}

const Tag: FunctionComponent<TagProps> = ({
    label,
    color = TagColors.default,
    deactivated = false,
    rounded = false,
    boldLabel = false,
    labelColor
}) => {
    return (
        <span
            className={`Tag ${color} isDeactivated-${deactivated} isRounded-${rounded} ${
                boldLabel ? 'boldLabel' : ''
            } ${labelColor ? `label-color-${labelColor}` : ''}`}
        >
            {label}
        </span>
    );
};

export default Tag;



export const getDetailHeaderSubscriptionTagStatuses = (
    subscription?: SubscriptionDetailsDto | SubscriptionAncestryDto
): string[] => {
    if (subscription) {
        const statuses = [];
        if (subscription.isOnboardingInProgress)
            statuses.push('Subscription.subscription_status_isOnboardingInProgress');
        if (subscription.isActive) statuses.push('Subscription.subscription_status_isActive');
        if (subscription.isModificationInProgress)
            statuses.push('Subscription.subscription_status_isModificationInProgress');
        if (subscription.isRenewalInProgress)
            statuses.push('Subscription.subscription_status_isRenewalInProgress');
        if (subscription.isCancelled) statuses.push('Subscription.subscription_status_isCancelled');
        if (subscription.isUpcoming) statuses.push('Subscription.subscription_status_isUpcoming');
        if (subscription.isExpired) statuses.push('Subscription.subscription_status_isExpired');
        if (subscription.expiresSoon) statuses.push('Subscription.subscription_status_expiresSoon');
        return statuses;
    } else return [];
};

export const getSubscriptionStatusTagColor = (status: string): TagColors => {
    switch (status) {
        case 'Subscription.subscription_status_isOnboardingInProgress':
        case 'Subscription.subscription_status_isModificationInProgress':
        case 'Subscription.subscription_status_isRenewalInProgress':
            return TagColors.default;
        case 'Subscription.subscription_status_isActive':
            return TagColors.active;
        case 'Subscription.subscription_status_isCancelled':
            return TagColors.disabled;
        case 'Subscription.subscription_status_isUpcoming':
            return TagColors.cold;
        case 'Subscription.subscription_status_isExpired':
            return TagColors.hot;
        case 'Subscription.subscription_status_expiresSoon':
            return TagColors.error;
        default:
            return TagColors.default;
    }
};

export const getSubscriptionProposalStatusTagColor = (
    status: SubscriptionProposalStatusDto | undefined
): TagColors | undefined => {
    switch (status) {
        case SubscriptionProposalStatusDto.Accepted:
            return TagColors.default;
        case SubscriptionProposalStatusDto.Pending:
            return TagColors.warm;
        case SubscriptionProposalStatusDto.Refused:
            return TagColors.error;
        case SubscriptionProposalStatusDto.Void:
            return TagColors.disabled;
        case SubscriptionProposalStatusDto.Cancelled:
            return TagColors.error;
        default:
            return undefined;
    }
};

export const getInvoiceStatusTag = (invoice: LightInvoiceDto, t: TFunction): ReactNode => {
    let statusProps = {
        color: TagColors.default,
        label: t(`Invoice.status_InvoiceStatusDto_${invoice.status}`),
    };
    switch (invoice.status) {
        case InvoiceStatusDto.AwaitingPayment:
            statusProps = {
                ...statusProps,
                label: t(`Invoice.status_InvoiceStatusDto_${invoice.status}`, {
                    param1: moment(invoice.dueDate).format(SHORT_MONTH_DATE),
                }),
            };
            break;
        case InvoiceStatusDto.Failed:
            statusProps = {
                ...statusProps,
                color: TagColors.error,
            };
            break;
        case InvoiceStatusDto.Pending:
            statusProps = { ...statusProps, color: TagColors.cold };
            break;
        case InvoiceStatusDto.Refunded:
        case InvoiceStatusDto.Void:
            statusProps = { ...statusProps, color: TagColors.disabled };
            break;
        case InvoiceStatusDto.Success:
            statusProps = { ...statusProps, color: TagColors.active };
            break;
        default:
            break;
    }
    return <Tag label={statusProps.label} color={statusProps.color} />;
}

export const getOpportunityStatusTag = (opportunity: OpportunityDto, t: TFunction): ReactNode => {
    return (
        <Tag
            label={t(`Opportunity.opportunity_status_${opportunity.status}`)}
            color={
                opportunity.status === OpportunityStatusDto.AgreementSigned
                    ? TagColors.active
                    : opportunity.status === OpportunityStatusDto.Lost
                    ? TagColors.disabled
                    : TagColors.default
            }
        />
    );
}
