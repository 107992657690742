import React, {
    FunctionComponent,
    ReactElement,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Button, List, Typography } from 'antd';
import './simple-list.less';
import Icon from 'Components/icons/icon';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

export interface SimpleListItemProps {
    title?: string;
    description?: string;
    avatar?: React.ReactNode;
    content?: ReactNode;
    badge?: ReactNode;
    className?: string;
}

interface SimpleListProps {
    className?: string;
    title: string;
    subTitle?: string | null;
    data?: SimpleListItemProps[] | undefined;
    children?: ReactNode;
    maxHeight?: boolean;
    largeDescription?: boolean;
}

const SimpleList: FunctionComponent<SimpleListProps> = ({
    className,
    title,
    subTitle,
    data,
    children,
    maxHeight = false,
    largeDescription
}) => {
    const [seeMore, setSeeMore] = useState(false);
    const [showSeeMoreButton, setShowSeeMoreButton] = useState(false);
    const divRef = useRef(null);
    const { t } = useTranslation();
    const seeMoreButtonLabel = seeMore ? t('see_less') : t('see_more');

    const handleseeMoreClick = (): void => setSeeMore((prevVal) => !prevVal);

    const listHeader = (
        <>
            {subTitle && <div className="header-subtitle">{subTitle}</div>}
            <Title level={2} className="header-title">
                {title}
            </Title>
        </>
    );

    useEffect(() => {
        if (maxHeight && divRef.current !== null) {
            const div = divRef.current! as HTMLElement;
            const maxHeightChild = div.querySelectorAll('.ant-list-item-meta-content');

            if (
                maxHeightChild.length > 0 &&
                maxHeightChild[0].scrollHeight > maxHeightChild[0].clientHeight
            )
                setShowSeeMoreButton(true);
        }
    }, [maxHeight]);

    return (
        <div ref={divRef}>
            <List
                className={`SimpleList maxHeight-${maxHeight} seeMore-${seeMore} ${
                    className || ''
                } ${largeDescription ? 'large-description' : ''}`}
                size="large"
                bordered
                header={listHeader}
                dataSource={data}
                renderItem={(item): ReactElement => (
                    <List.Item extra={item.badge}>
                        <List.Item.Meta
                            title={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: item.title || '',
                                    }}
                                />
                            }
                            description={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: item.description || '',
                                    }}
                                />
                            }
                            avatar={item.avatar}
                            className={item.className}
                        />
                    </List.Item>
                )}
            >
                {children}

                {maxHeight && showSeeMoreButton && (
                    <Button
                        className={`seeMore-button seeMore-${seeMore}`}
                        htmlType="button"
                        onClick={handleseeMoreClick}
                    >
                        {seeMoreButtonLabel} <Icon iconName="AngleBottom" />
                    </Button>
                )}
            </List>
        </div>
    );
};

export default SimpleList;
