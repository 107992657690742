// GENERATED FILE - DO NOT MODIFY
import { CreateManagerUserRequestDto } from 'Api/Features/ManagerUsers/Dtos/CreateManagerUserRequestDto';
import { GetManagerUsersRequestDto } from 'Api/Features/ManagerUsers/Dtos/GetManagerUsersRequestDto';
import { GetManagerUsersResponseDto } from 'Api/Features/ManagerUsers/Dtos/GetManagerUsersResponseDto';
import { ManagerUserDto } from 'Api/Features/ManagerUsers/Dtos/ManagerUserDto';
import { UpdateManagerUserRequestDto } from 'Api/Features/ManagerUsers/Dtos/UpdateManagerUserRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class ManagerUserProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getManagerUser(managerUserId: string | null): Promise<ManagerUserDto | null> {
        const uri = this.buildUri(
            "/manager-users/{managerUserId}",
            { managerUserId: managerUserId },
            null
        );

        const data: ManagerUserDto | null = await this.httpClient.get<ManagerUserDto | null>(uri);
        return data;
    }

    public async getManagers(): Promise<(ManagerUserDto | null)[] | null> {
        const uri = this.buildUri(
            "/managers",
            null,
            null
        );

        const data: (ManagerUserDto | null)[] | null = await this.httpClient.get<(ManagerUserDto | null)[] | null>(uri);
        return data;
    }

    public async getManagerUsers(request: GetManagerUsersRequestDto | null): Promise<GetManagerUsersResponseDto | null> {
        const uri = this.buildUri(
            "/manager-users",
            null,
            { ...request || undefined }
        );

        const data: GetManagerUsersResponseDto | null = await this.httpClient.get<GetManagerUsersResponseDto | null>(uri);
        return data;
    }

    public async createManagerUser(request: CreateManagerUserRequestDto | null): Promise<string | null> {
        const uri = this.buildUri(
            "/manager-users",
            null,
            null
        );

        const data: string | null = await this.httpClient.post<CreateManagerUserRequestDto | null, string | null>(uri, request);
        return data;
    }

    public async updateManagerUser(managerUserId: string | null, request: UpdateManagerUserRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/manager-users/{managerUserId}",
            { managerUserId: managerUserId },
            null
        );

        await this.httpClient.patch<UpdateManagerUserRequestDto | null, void>(uri, request);
    }

    public async deleteManagerUser(managerUserId: string | null): Promise<void> {
        const uri = this.buildUri(
            "/manager-users/{managerUserId}",
            { managerUserId: managerUserId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}