// GENERATED FILE - DO NOT MODIFY
import { GetManagerUserSalesActivitiesRequestDto } from 'Api/Features/SalesActivities/Dtos/GetManagerUserSalesActivitiesRequestDto';
import { GetManagerUserSalesActivitiesResponseDto } from 'Api/Features/SalesActivities/Dtos/GetManagerUserSalesActivitiesResponseDto';
import { GetSalesActivitiesRequestDto } from 'Api/Features/SalesActivities/Dtos/GetSalesActivitiesRequestDto';
import { GetSalesActivitiesResponseDto } from 'Api/Features/SalesActivities/Dtos/GetSalesActivitiesResponseDto';
import { UpdateManagerUserSalesActivityOrderingsRequest } from 'Api/Features/SalesActivities/Dtos/UpdateManagerUserSalesActivityOrderingsRequest';
import { UpdateManagerUserSalesActivityStatusRequestDto } from 'Api/Features/SalesActivities/Dtos/UpdateManagerUserSalesActivityStatusRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class SalesActivityProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getSalesActivities(request: GetSalesActivitiesRequestDto | null): Promise<GetSalesActivitiesResponseDto | null> {
        const uri = this.buildUri(
            "/sales-activities",
            null,
            { ...request || undefined }
        );

        const data: GetSalesActivitiesResponseDto | null = await this.httpClient.get<GetSalesActivitiesResponseDto | null>(uri);
        return data;
    }

    public async getManagerUserSalesActivities(managerUserId: string | null, request: GetManagerUserSalesActivitiesRequestDto | null): Promise<GetManagerUserSalesActivitiesResponseDto | null> {
        const uri = this.buildUri(
            "/manager-users/{managerUserId}/sales-activities",
            { managerUserId: managerUserId },
            { ...request || undefined }
        );

        const data: GetManagerUserSalesActivitiesResponseDto | null = await this.httpClient.get<GetManagerUserSalesActivitiesResponseDto | null>(uri);
        return data;
    }

    public async updateManagerUserSalesActivityStatus(salesActivityId: string, request: UpdateManagerUserSalesActivityStatusRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/sales-activities/{salesActivityId}/user-statuses",
            { salesActivityId: salesActivityId },
            { ...request || undefined }
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async updateManagerUserSalesActivityOrderings(managerUserId: string | null, request: UpdateManagerUserSalesActivityOrderingsRequest | null): Promise<void> {
        const uri = this.buildUri(
            "/manager-users/{managerUserId}/sales-activity-orderings",
            { managerUserId: managerUserId },
            null
        );

        await this.httpClient.post<UpdateManagerUserSalesActivityOrderingsRequest | null, void>(uri, request);
    }
}