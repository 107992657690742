import { CreateUnitRequestDto } from 'Api/Features/Units/Dtos/CreateUnitRequestDto';
import { GetUnitOrderResponseDto } from 'Api/Features/Units/Dtos/GetUnitOrderResponseDto';
import { GetUnitsRequestDto } from 'Api/Features/Units/Dtos/GetUnitsRequestDto';
import { ImportUnitsRequestDto } from 'Api/Features/Units/Dtos/ImportUnitsRequestDto';
import { ParseUnitImportationFileRequestDto } from 'Api/Features/Units/Dtos/ParseUnitImportationFileRequestDto';
import { ParseUnitImportationFileResponseDto } from 'Api/Features/Units/Dtos/ParseUnitImportationFileResponseDto';
import { UnitOccupancyDto } from 'Api/Features/Units/Dtos/UnitOccupancyDto';
import { UpdateUnitOrderRequestDto } from 'Api/Features/Units/Dtos/UpdateUnitOrderRequestDto';
import { UpdateUnitRequestDto } from 'Api/Features/Units/Dtos/UpdateUnitRequestDto';
import { UnitProxy } from 'Api/Features/Units/UnitProxy';
import { inject } from 'aurelia-dependency-injection';
import { Unit } from 'Models/Units/Unit';
import { dateMomentToString } from 'Utils/TimeUtils';
import { ApiService } from './ApiService';

@inject(UnitProxy)
export class UnitService extends ApiService {
    constructor(private readonly unitProxy: UnitProxy) {
        super();
    }

    public async getUnit(unitId: string | null): Promise<Unit | null> {
        const data: Unit | null = await this.unitProxy.getUnit(unitId);
        return data;
    }

    public async getUnits(request: GetUnitsRequestDto): Promise<[Unit[], number]> {
        const response = await this.unitProxy.getUnits(request);
        return [response?.items?.map((x) => new Unit(x!)) || [], response?.totalItemCount || 0];
    }

    public async createUnit(request: CreateUnitRequestDto): Promise<string> {
        request.activeStartDate = dateMomentToString(request?.activeStartDate);
        request.activeEndDate = dateMomentToString(request?.activeEndDate);

        const data: string = await this.unitProxy.createUnit(request);
        return data;
    }

    public async updateUnit(
        unitId: string | null,
        request: UpdateUnitRequestDto | null
    ): Promise<void> {
        await this.unitProxy.updateUnit(unitId, request);
    }

    public async deleteUnit(unitId: string | null): Promise<void> {
        await this.unitProxy.deleteUnit(unitId);
    }

    public async getUnitOrdering(campusId: string | null): Promise<GetUnitOrderResponseDto | null> {
        const data: GetUnitOrderResponseDto | null = await this.unitProxy.getUnitOrdering(campusId);
        return data;
    }

    public async updateUnitOrdering(
        campusId: string | null,
        request: UpdateUnitOrderRequestDto | null
    ): Promise<void> {
        await this.unitProxy.updateUnitOrdering(campusId, request);
    }

    public async getUnitImportationFileTemplate(): Promise<ArrayBuffer> {
        const data: ArrayBuffer = await this.unitProxy.getUnitImportationFileTemplate();
        return data;
    }

    public async parseUnitImportationFile(
        request: ParseUnitImportationFileRequestDto | null
    ): Promise<ParseUnitImportationFileResponseDto | null> {
        const data: ParseUnitImportationFileResponseDto | null = await this.unitProxy.parseUnitImportationFile(
            request
        );
        return data;
    }

    public async getUnitOccupancy(unitId: string | null): Promise<UnitOccupancyDto | null> {
        const data: UnitOccupancyDto | null = await this.unitProxy.getUnitOccupancy(unitId);
        return data;
    }

    public async importUnits(request: ImportUnitsRequestDto | null): Promise<void> {
        await this.unitProxy.importUnits(request);
    }
}
