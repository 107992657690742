import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Request: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.7486 0.862745V0H4.29758V0.862745H1.86621V16H14.1687V0.862745H11.7486ZM5.03708 0.739496H11.0091V2.71148H5.03708V0.739496ZM13.418 15.2493H2.62812V1.62465H4.29758V3.46218H11.7486V1.62465H13.418V15.2493Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.4208 5.29972H3.60291V5.91597H12.4208V5.29972Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.4211 7.35013H3.60314V7.96638H12.4211V7.35013Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.4211 9.40056H3.60314V10.0168H12.4211V9.40056Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.4211 11.4846H3.60314V12.1008H12.4211V11.4846Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Request;
