import { Button, Col, Row, Tooltip } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { Content } from 'antd/lib/layout/layout';
import { ExpertiseDto } from 'Api/Features/Expertises/Dtos/ExpertiseDto';
import { LightContactInfoDto } from 'Api/Features/General/Dtos/LightContactInfoDto';
import { MemberDetailsCampusDto } from 'Api/Features/Members/Dtos/MemberDetailsCampusDto';
import { MemberDetailsDto } from 'Api/Features/Members/Dtos/MemberDetailsDto';
import { MemberDetailsMembershipDto } from 'Api/Features/Members/Dtos/MemberDetailsMembershipDto';
import { AcceptMembershipInvitationRequestDto } from 'Api/Features/Memberships/Dtos/AcceptMembershipInvitationRequestDto';
import { GetMembershipMembersRequestDto } from 'Api/Features/Memberships/Dtos/GetMembershipMembersRequestDto';
import { GetMembershipMembersResponseItemDto } from 'Api/Features/Memberships/Dtos/GetMembershipMembersResponseItemDto';
import { MembershipRemoveMemberRequestDto } from 'Api/Features/Memberships/Dtos/MembershipRemoveMemberRequestDto';
import { MembershipRoleDto } from 'Api/Features/Memberships/Dtos/MembershipRoleDto';
import { RejectMembershipInvitationRequestDto } from 'Api/Features/Memberships/Dtos/RejectMembershipInvitationRequestDto';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import CompanyHeader, { CompanyHeaderData } from 'Components/company-header';
import { PencilBorder, Location as LocationIcon, Company } from 'Components/icons';
import Icon from 'Components/icons/icon';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import { Tag } from 'Components/tag';
import { TagColors } from 'Components/tag/tag';
import { TdWithImage } from 'Components/td-with-image';
import { useService, useStores } from 'Hooks';
import React, {
    FunctionComponent,
    ReactElement,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { MemberService } from 'Services/MemberService';
import { MembershipService } from 'Services/MembershipService';
import { cleanVal } from 'Utils/NumberUtils';
import { CompanyContext } from '../..';
import TransferCompanyOwnershipModal from '../../modals/transfer-company-ownership';
import EditRoleModal from '../edit-role-modal';
import './index.less';

const valOrUndefined = (val?: string | null): SimpleListItemProps[] | undefined =>
    val ? [{ title: val }] : undefined;

interface GetIconComponent {
    icon?: string | null;
    imgSrc?: string | null;
}

interface ContactDetails {
    introduction?: SimpleListItemProps[];
    expertises?: SimpleListItemProps[];
    contactInformation?: SimpleListItemProps[];
    personalInformation?: SimpleListItemProps[];
    company?: ReactNode | undefined;
    roles?: SimpleListItemProps[];
    locations?: SimpleListItemProps[];
}

const formGutter: [Gutter, Gutter] = [40, 0];

const CompanyTeamDetails: FunctionComponent = () => {
    const companyContext = useContext(CompanyContext);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const memberService = useService(MemberService);
    const membershipService = useService(MembershipService);
    const { id, memberId } = useParams<{ id: string; memberId: string }>();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [member, setMember] = useState<MemberDetailsDto>();
    const [membershipMember, setMembershipMember] = useState<GetMembershipMembersResponseItemDto>();

    const [editRolesModalOpen, setEditRolesModalOpen] = useState(false);
    const [transferOwnerModalOpen, setTransferOwnerModalOpen] = useState(false);

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `companies`,
            nameKey: 'companies',
        },
        {
            path: `${id}/dashboard`,
            nameKey: companyContext?.name ?? '',
        },
        {
            path: 'team',
            nameKey: 'team',
        },
        {
            path: `${memberId}`,
            nameKey: `${member?.firstName} ${member?.lastName}`,
        },
    ];

    const fetchMember = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const member = await memberService.getMember(memberId);
            if (member) {
                setMember(member);
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [memberId]);

    useEffect(() => {
        fetchMember();
    }, [fetchMember]);

    const fetchMemberRoles = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const request: GetMembershipMembersRequestDto = {
                ids: [memberId]
            };
            const [data] = await membershipService.getMembershipMembers(
                id,
                request
            );

            setMembershipMember(data[0]);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [memberId]);

    useEffect(() => {
        fetchMemberRoles();
    }, [fetchMemberRoles]);

    const onEditRolesClick = () => {
        if (membershipMember?.roles?.some((role) => role === MembershipRoleDto.Owner))
            transferOwnership();
        else setEditRolesModalOpen(true);
    }

    const memberRolesStringArray = (): string[] => {
        const roles = membershipMember?.roles;
        const pendingRoles = membershipMember?.pendingRoles;

        if (pendingRoles && pendingRoles.length > 0) {
            return pendingRoles
                ?.filter((role) => role !== MembershipRoleDto.Member)
                ?.map((role) => t(`Membership.member_role_${role}`));
        }            

        if (!roles?.some((role) => role !== MembershipRoleDto.Member))
            return [t(`Membership.member_role_${MembershipRoleDto.Member}`)];

        return roles
            ?.filter((role) => role !== MembershipRoleDto.Member)
            ?.map((role) =>  t(`Membership.member_role_${role}`),
            );
    };

    const headerData: CompanyHeaderData = {
        iconName: 'User',
        title: `${member?.salutation ?? ''} ${member?.firstName} ${member?.lastName}`,
        subTitle: member?.jobPosition ?? '',
        imageUrl: member?.imageUrl,
        tags: membershipMember?.roles?.some((role) => role === MembershipRoleDto.PendingMember) ? (
            <Tag label={t('Membership.app_invitation_sent')} color={TagColors.default} />
        ) : (
            memberRolesStringArray().map((role) => (
                <Tag key={role} label={role} color={TagColors.slateBlue40} />
            ))
        ),
    };

    const getIconComponent = (params: GetIconComponent): ReactElement => (
        <ImageWithPlaceholder
            width="32"
            height="32"
            defaultImg={<Icon iconName={params.icon || 'QuestionMarkLined'} />}
            imgSrc={params.imgSrc || undefined}
        />
    );

    const getContactInformation = (
        contact?: LightContactInfoDto | null
    ): SimpleListItemProps[] | undefined => {
        const contactInfosToDisplay: (keyof LightContactInfoDto)[] = [
            'email',
            'phone1',
            'phone2',
            'angelListUrl',
            'twitterUrl',
            'facebookUrl',
            'linkedInUrl',
            'instagramUrl',
            'website',
        ];
        const contactInfosToDisplayIcons: string[] = [
            'Email',
            'Phone',
            'Phone',
            'AngelList',
            'Twitter',
            'Facebook',
            'LinkedIn',
            'Instagram',
            'Television',
        ];

        const contactInfos: { title: string; avatar: ReactElement }[] = [];

        if (contact) {
            contactInfosToDisplay.forEach((key, i) => {
                if (contact[key]) {
                    contactInfos.push({
                        title: cleanVal.string(contact[key]),
                        avatar: getIconComponent({ icon: contactInfosToDisplayIcons[i] }),
                    });
                }
            });
        }
        return contactInfos;
    };

    const getExpertisesInfo = (
        expertises?: (ExpertiseDto | null)[] | null
    ): SimpleListItemProps[] | undefined => {
        if (expertises && expertises.length > 0) {
            return expertises.map((expertise) => ({
                title: cleanVal.string(expertise?.description),
                avatar: getIconComponent({ imgSrc: expertise?.iconUrl }),
            }));
        } else {
            return undefined;
        }
    };

    const getCompanies = (
        companies?: (MemberDetailsMembershipDto | null)[] | null
    ): ReactNode | undefined => {
        return companies && companies.length > 0
            ? companies.map((company) => (
                  <div key={company?.id} className="company-container">
                      <div className="company">
                          <div>
                              <TdWithImage
                                  width="32"
                                  height="32"
                                  imgSrc={company?.imageUrl}
                                  defaultImg={<Icon iconName="Company" />}
                              >
                                  {company?.name}
                              </TdWithImage>
                          </div>
                          <div>
                              <a
                                  className="company-button"
                                  href={`/companies/${company?.id}/dashboard`}
                              >
                                  See details
                              </a>
                          </div>
                      </div>
                  </div>
              ))
            : undefined;
    };

    const getRoles = useCallback((): SimpleListItemProps[] | undefined => {
        const roles = memberRolesStringArray();

        if (!roles?.some((role) => role !== MembershipRoleDto.Member))
            return [{ title: t(`Membership.MembershipRoleDto_${MembershipRoleDto.Member}`) }];

        return roles
            ?.filter((role) => role !== MembershipRoleDto.Member)
            ?.map((role) => ({
                title: role,
            }));
    }, [memberRolesStringArray]);

    const getLocations = (locations: (MemberDetailsCampusDto | null)[] | null | undefined): SimpleListItemProps[] | undefined => {
        return locations?.filter(location => location !== null).map(location => ({
            title: location?.name ?? '',
            avatar: (
                <ImageWithPlaceholder
                    width="32"
                    height="32"
                    imgSrc={location?.mainImageUrl}
                    defaultImg={<LocationIcon />}
                />
            )
        }))
    };
        

    const contactDetails: ContactDetails = {
        introduction: valOrUndefined(member?.introduction),
        contactInformation: getContactInformation(member?.contactInfo),
        expertises: getExpertisesInfo(member?.expertises),
        company: getCompanies(member?.memberships),
        roles: getRoles(),
        locations: getLocations(member?.campuses)
    };

    const removeFromMembership = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Company/>,
                title: t('Membership.remove_from_company_confirmation'),
                positiveText: t('yes'),
                negativeText: t('no'),
            }))
        )
            return;

        try {
            globalLoadingStore.addLoading();
            const request: MembershipRemoveMemberRequestDto = {
                accountId: memberId,
                membershipId: id
            }
            await membershipService.removeUserFromMembership(request);

            toastStore.toast({
                type: 'success',
                message: t('Membership.remove_member_success'),
            });
            history.push(`/companies/${id}/dashboard/team`)
        } catch (e) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const acceptInvitation = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Company/>,
                title: t('Membership.accept_decline_invitation_title', {param1: 'Accept'}),
                message: t('Membership.accept_invitation_subtitle'),
                positiveText: t('Membership.accept_decline_invitation_positive', {param1: 'Accept'}),
                negativeText: t('Membership.accept_decline_invitation_negative'),
            }))
        )
            return;

        try {
            globalLoadingStore.addLoading();
            const request: AcceptMembershipInvitationRequestDto = {
                accountId: memberId,
                membershipId: id
            }
            await membershipService.acceptMembershipInvitation(request);

            toastStore.toast({
                type: 'success',
                message: t('Membership.invitation_accept_decline_success', {param1: 'accepted'}),
            });
            history.push(`/companies/${id}/dashboard/team`)
        } catch (e) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const declineInvitation = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Company/>,
                title: t('Membership.accept_decline_invitation_title', {param1: 'Decline'}),
                message: t('Membership.decline_invitation_subtitle'),
                positiveText: t('Membership.accept_decline_invitation_positive', {param1: 'Decline'}),
                negativeText: t('Membership.accept_decline_invitation_negative'),
            }))
        )
            return;

        try {
            globalLoadingStore.addLoading();
            const request: RejectMembershipInvitationRequestDto = {
                accountId: memberId,
                membershipId: id
            }
            await membershipService.rejectMembershipInvitation(request);

            toastStore.toast({
                type: 'success',
                message: t('Membership.invitation_accept_decline_success', {param1: 'declined'}),
            });
            history.push(`/companies/${id}/dashboard/team`)
        } catch (e) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const transferOwnership = async (): Promise<void> => {
        if (
            (await confirmationModalStore.confirm({
                icon: <Company/>,
                title: t('Membership.need_to_transfer_ownership'),
                message: t('Membership.after_transfer_owner'),
                positiveText: t('Lead.transfer_ownership'),
                negativeText: t('Membership.keep_owner_role'),
            }))
        )
            setTransferOwnerModalOpen(true);
    };

    const menuOptions = (): ActionMenuOption[] => {
        const options: ActionMenuOption[] = [];

        if (!membershipMember?.roles?.some((role) => role === MembershipRoleDto.PendingMember)) {
            options.push({
                key: 'manage',
                title: t('Membership.manage_this_contact'),
                action: () => history.push(`/contacts/${memberId}/dashboard`),
            });

            if (!membershipMember?.roles?.some((role) => role === MembershipRoleDto.Owner)) {
                options.push({
                    key: 'remove',
                    title: t('Membership.remove_from_company'),
                    action: async () => await removeFromMembership(),
                });
            }
        } else {
            options.push({
                key: 'accept',
                title: t('Membership.accept_invitation'),
                action: () => acceptInvitation(),
            });
            options.push({
                key: 'decline',
                title: t('Membership.decline_invitation'),
                action: () => declineInvitation(),
            });
        }

        return options;
    }; 

    return (
        <div className="CompanyTeamDetails">
            <CompanyHeader
                company={companyContext}
                routes={breadcrumbs}
                headerData={headerData}
                loading={loading}
                action={<ActionMenu options={menuOptions()} type="primary" trigger="click" />}
            />
            <Content>
                <Row gutter={formGutter}>
                    <Col span={12}>
                        {contactDetails?.roles && (
                            <Row>
                                <Col span={24} className="editable">
                                    <SimpleList title={t(`roles`)} data={contactDetails.roles}>
                                        <Tooltip title={t('edit_roles')}>
                                            <Button
                                                icon={<PencilBorder />}
                                                onClick={onEditRolesClick}
                                            />
                                        </Tooltip>
                                    </SimpleList>
                                </Col>
                            </Row>
                        )}

                        {contactDetails?.introduction && (
                            <SimpleList
                                title={t(`introduction`)}
                                data={contactDetails.introduction}
                            />
                        )}

                        {contactDetails?.expertises && (
                            <SimpleList
                                className="expertises"
                                title={t(`expertises`)}
                                data={contactDetails.expertises}
                            />
                        )}

                        {contactDetails?.contactInformation && (
                            <SimpleList
                                title={t(`contact_information`)}
                                data={contactDetails.contactInformation}
                            />
                        )}
                    </Col>

                    <Col span={12}>
                        {contactDetails?.company && (
                            <SimpleList className="companies" title={t(`companies`)}>
                                {contactDetails.company}
                            </SimpleList>
                        )}

                        {contactDetails?.locations && (
                            <SimpleList title={t(`locations`)} data={contactDetails.locations} />
                        )}
                    </Col>
                </Row>

                {editRolesModalOpen && (
                    <EditRoleModal
                        memberRoles={membershipMember?.roles
                            ?.filter((role) => role !== null)
                            .map((role) => role!)
                            .concat(
                                membershipMember.pendingRoles
                                    ? membershipMember.pendingRoles
                                          ?.filter((role) => role !== null)
                                          .map((role) => role!)
                                    : []
                            )}
                        visible={editRolesModalOpen}
                        accountId={member?.id}
                        membershipId={id}
                        onComplete={(success: Boolean) => {
                            setEditRolesModalOpen(false);
                            if (success) {
                                fetchMemberRoles();
                            }
                        }}
                    />
                )}

                {transferOwnerModalOpen && (
                    <TransferCompanyOwnershipModal
                        visible={transferOwnerModalOpen}
                        onComplete={(success: boolean) => {
                            setTransferOwnerModalOpen(false);
                            if (success) {
                                fetchMemberRoles();
                            }
                        }}
                        membershipId={id}
                        currentOwnerId={memberId}
                    />
                )}
            </Content>
        </div>
    );
};

export default CompanyTeamDetails;
