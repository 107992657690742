import { inject } from 'aurelia-dependency-injection';
import { action, observable } from 'mobx';
import { UserPermissionsStore } from 'Stores';

interface Request {
    request?: any;
    requestType?: RequestType | null;
}

export enum RequestType {
    Company = 'Company',
    Invoice = 'Invoice',
    Contact = 'Contact',
    Lead = 'Lead',
    Opportunity = 'Opportunity',
    Subscription = 'Subsription',
    AddOn = 'AddOn',
    AreaOfInterest = 'AreaOfInterest',
    ConferenceRoom = 'ConferenceRoom',
    Unit = 'Unit',
    CommunityEvent = 'CommunityEvent'
}

@inject(UserPermissionsStore)
class RequestStore {
    constructor(private readonly userPermissionStore: UserPermissionsStore) {}

    @observable
    requestInfo: Request = {
        request: undefined,
        requestType: null
    };

    @action
    setRequest(fetchRequest: Request): any {
        this.requestInfo = fetchRequest;

        return fetchRequest.request;
    }

    @action
    getReportRequestAvailableLocationIdsForUser(): string[] {
        //if admin, empty array equals to all locations for api
        if (this.userPermissionStore.userIsAdministrator)
            return [];
        else {
            return this.userPermissionStore
            .availableLocationsForUser
            .map((location) => location.id!)
        }
    }
}

export default RequestStore;
