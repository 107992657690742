// GENERATED FILE - DO NOT MODIFY
import { AcceptSubscriptionProposalRequestDto } from 'Api/Features/SubscriptionProposals/Dtos/AcceptSubscriptionProposalRequestDto';
import { AcceptSubscriptionProposalWithTokenRequestDto } from 'Api/Features/SubscriptionProposals/Dtos/AcceptSubscriptionProposalWithTokenRequestDto';
import { CreateSubscriptionProposalRequestDto } from 'Api/Features/SubscriptionProposals/Dtos/CreateSubscriptionProposalRequestDto';
import { GetSubscriptionProposalsRequestDto } from 'Api/Features/SubscriptionProposals/Dtos/GetSubscriptionProposalsRequestDto';
import { GetSubscriptionProposalsResponseDto } from 'Api/Features/SubscriptionProposals/Dtos/GetSubscriptionProposalsResponseDto';
import { RejectSubscriptionProposalRequestDto } from 'Api/Features/SubscriptionProposals/Dtos/RejectSubscriptionProposalRequestDto';
import { SubscriptionProposalDetailsDto } from 'Api/Features/SubscriptionProposals/Dtos/SubscriptionProposalDetailsDto';
import { UpdateSubscriptionProposalTitleRequestDto } from 'Api/Features/SubscriptionProposals/Dtos/UpdateSubscriptionProposalTitleRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class SubscriptionProposalProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getSubscriptionProposal(subscriptionProposalId: string): Promise<SubscriptionProposalDetailsDto | null> {
        const uri = this.buildUri(
            "/subscription-proposals/{subscriptionProposalId}",
            { subscriptionProposalId: subscriptionProposalId },
            null
        );

        const data: SubscriptionProposalDetailsDto | null = await this.httpClient.get<SubscriptionProposalDetailsDto | null>(uri);
        return data;
    }

    public async getSubscriptionProposals(opportunityId: string, request: GetSubscriptionProposalsRequestDto | null): Promise<GetSubscriptionProposalsResponseDto | null> {
        const uri = this.buildUri(
            "/opportunities/{opportunityId}/subscription-proposals",
            { opportunityId: opportunityId },
            { ...request || undefined }
        );

        const data: GetSubscriptionProposalsResponseDto | null = await this.httpClient.get<GetSubscriptionProposalsResponseDto | null>(uri);
        return data;
    }

    public async createSubscriptionProposal(request: CreateSubscriptionProposalRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/subscription-proposals",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateSubscriptionProposalRequestDto | null, string>(uri, request);
        return data;
    }

    public async getSubscriptionProposalPreview(request: CreateSubscriptionProposalRequestDto | null): Promise<SubscriptionProposalDetailsDto | null> {
        const uri = this.buildUri(
            "/subscription-proposals/preview",
            null,
            null
        );

        const data: SubscriptionProposalDetailsDto | null = await this.httpClient.post<CreateSubscriptionProposalRequestDto | null, SubscriptionProposalDetailsDto | null>(uri, request);
        return data;
    }

    public async resendSubscriptionProposal(subscriptionProposalId: string | null): Promise<void> {
        const uri = this.buildUri(
            "/subscription-proposals/{subscriptionProposalId}/resend",
            { subscriptionProposalId: subscriptionProposalId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async acceptSubscriptionProposal(subscriptionProposalId: string | null, request: AcceptSubscriptionProposalRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/subscription-proposals/{subscriptionProposalId}/accept",
            { subscriptionProposalId: subscriptionProposalId },
            null
        );

        await this.httpClient.post<AcceptSubscriptionProposalRequestDto | null, void>(uri, request);
    }

    public async acceptSubscriptionProposalUsingToken(subscriptionProposalId: string, request: AcceptSubscriptionProposalWithTokenRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/subscription-proposals/{subscriptionProposalId}/acceptwithtoken",
            { subscriptionProposalId: subscriptionProposalId },
            null
        );

        await this.httpClient.post<AcceptSubscriptionProposalWithTokenRequestDto | null, void>(uri, request);
    }

    public async rejectSubscriptionProposal(subscriptionProposalId: string | null, request: RejectSubscriptionProposalRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/subscription-proposals/{subscriptionProposalId}/reject",
            { subscriptionProposalId: subscriptionProposalId },
            null
        );

        await this.httpClient.post<RejectSubscriptionProposalRequestDto | null, void>(uri, request);
    }

    public async rejectSubscriptionProposalUsingToken(subscriptionProposalId: string): Promise<void> {
        const uri = this.buildUri(
            "/subscription-proposals/{subscriptionProposalId}/rejectwithtoken",
            { subscriptionProposalId: subscriptionProposalId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async exportSubscriptionProposal(subscriptionProposalId: string): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/subscription-proposals/{subscriptionProposalId}/export",
            { subscriptionProposalId: subscriptionProposalId },
            null
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }

    public async updateSubscriptionProposalTitle(subscriptionProposalId: string | null, request: UpdateSubscriptionProposalTitleRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/subscription-proposals/{subscriptionProposalId}/title",
            { subscriptionProposalId: subscriptionProposalId },
            null
        );

        await this.httpClient.put<UpdateSubscriptionProposalTitleRequestDto | null, void>(uri, request);
    }
}