import { Menu } from 'antd';
import { observer } from 'mobx-react';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import './opportunity-top-header-navigation.less';

export interface MenuItem {
    key: string;
    label: string;
}

interface TopHeaderNavigationProps {
    menuItems: MenuItem[];
}

const OpportunityTopHeaderNavigation: FunctionComponent<TopHeaderNavigationProps> = observer(
    ({ menuItems }) => {
        const history = useHistory();

        const onSelectItem = (param: MenuInfo): void => {
            history.push(`${param.key}`);
        };

        const getSelectedKeys = (): string[] => {
            const path = history.location.pathname;
            const items = [...menuItems];

            if (path.substring(path.length - '/dashboard'.length) !== '/dashboard') {
                const index = menuItems.findIndex(
                    (urlPath) =>
                        urlPath.key.substring(urlPath.key.length - '/dashboard'.length) ===
                        '/dashboard'
                );
                items.splice(index, index + 1);
            }

            return items
                .filter((item) => {
                    return path.includes(item.key);
                })
                .map((item) => {
                    return item.key;
                });
        };

        return (
            <div className="OpportunityTopHeaderNavigation">
                <Menu
                    theme="light"
                    mode="horizontal"
                    selectedKeys={getSelectedKeys()}
                    onSelect={onSelectItem}
                >
                    {menuItems.map((x: MenuItem) => {
                        return <Menu.Item key={x.key}>{x.label}</Menu.Item>;
                    })}
                </Menu>
            </div>
        );
    }
);

export default OpportunityTopHeaderNavigation;
