import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { UploadFileStatus } from 'antd/lib/upload/interface';
import { useTranslation } from 'react-i18next';
import { Upload as UploadIcon, File } from 'Components/icons';
import './file-upload.less';
import Dragger from 'antd/lib/upload/Dragger';
import { theme } from 'variant';

interface FileUploadProps {
    setFileDetails: (file: FileDetails | undefined) => void;
    acceptedFileExtensions?: AcceptedFileExtension[];
}

export interface FileDetails {
    base64: string;
    filename: string;
}

export enum AcceptedFileExtension {
    //adding values here must also be added in the useeffect
    PDF = "PDF"
}

const FileUpload: FunctionComponent<FileUploadProps> = ({
    setFileDetails,
    acceptedFileExtensions,
}) => {
    const { t } = useTranslation();
    const [showAddFile, setShowAddFile] = useState<boolean>(true);
    const [acceptedFileString, setAcceptedFileString] = useState<string>();

    const getBase64 = async (file: any): Promise<string | undefined> => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        return src?.substring(src?.lastIndexOf(',') + 1);
    };

    const handleOnChange = async (event: any): Promise<void> => {
        if (event.file.status === ('done' as UploadFileStatus)) {
            const base = await getBase64(event.file);
            const file = { base64: base, filename: event.file.name } as FileDetails;
            setFileDetails(file);
            setShowAddFile(false);
        }
        if (event.file.status === ('removed' as UploadFileStatus)) {
            setFileDetails(undefined);
            setShowAddFile(true);
        }
    };

    //The antd control requires to post on file select. We do not want this behavior so we must make a dummy request
    const dummyRequest = (request: any) => {
        setTimeout(() => {
            request.onSuccess('ok');
        }, 0);
    };
    
    //create string to put in the input accept attribute
    useEffect(() => {
        //https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
        if(acceptedFileExtensions) {
            let acceptedFileString;
            acceptedFileExtensions.map(extension => {
                switch (extension) {
                    case AcceptedFileExtension.PDF: {
                        acceptedFileString = '.pdf'
                    }
                    break;
                }
            })
            setAcceptedFileString(acceptedFileString);
        }
    }, [acceptedFileExtensions])

    return (
        <div className="FileUpload">
            <div className={`upload-container ${showAddFile ? '' : 'file-selected'}`}>
                <Dragger
                    onChange={handleOnChange}
                    customRequest={dummyRequest}
                    iconRender={(): ReactNode => <File fill={theme['secondary-color']} />}
                    accept={acceptedFileString}
                >
                    <p className="ant-upload-drag-icon">
                        <UploadIcon fill={theme['secondary-color']} />
                    </p>
                    <p className="ant-upload-text">{t('Upload.click_or_drag')}</p>
                    {acceptedFileExtensions && acceptedFileExtensions.length > 0 && (
                        <p>
                            <small>Accepted files:</small>
                            {acceptedFileExtensions.map((format, i, array) => (<span key={i}> {format}{i < array.length - 1 ? ',' : ''}</span>))}
                        </p>
                    )}
                </Dragger>
            </div>
        </div>
    );
};

export default FileUpload;
