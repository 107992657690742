import { Button, Col, Form, Radio, Row } from 'antd';
import BaseModal from 'Components/base-modal/base-modal';
import Icon from 'Components/icons/icon';
import { useFormValidation, useService, useStores } from 'Hooks';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CompanyFormSection from 'Routes/authenticated/opportunities/company-form-section';
import './create-company.less';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { CompanyRadioButtonValues } from 'Routes/authenticated/contacts/modals/create-contact';
import ContactFormSection from 'Routes/authenticated/opportunities/contact-form-section';
import { CreateNewCompanyContactSchema } from 'Schemas/CreateContactSchema';
import { FormStep } from 'Components/form-step';
import { Gutter } from 'antd/lib/grid/row';
import { MembershipService } from 'Services/MembershipService';
import { CreateMembershipRequestDto } from 'Api/Features/Memberships/Dtos/CreateMembershipRequestDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { CreateUpdateAddressRequestDto } from 'Api/Features/General/Dtos/CreateUpdateAddressRequestDto';
import { dateMomentToString } from 'Utils/TimeUtils';
import { LightContactInfoDto } from 'Api/Features/General/Dtos/LightContactInfoDto';
import { CreateMembershipOwnerDto } from 'Api/Features/Memberships/Dtos/CreateMembershipOwnerDto';
import { useHistory } from 'react-router';
import { OpportunityRadioButtonValues } from 'Routes/authenticated/opportunities/create-opportunity';
import { OpportunityContactSchema } from 'Schemas';

interface CreateCompanyProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    onCreateOpportunity: (
        contactId: string,
        contactName: string,
        membershipId: string,
        membershipName: string
    ) => void;
}

enum CreateCompanyFormStep {
    Company = 'Company',
    Owner = 'Owner',
}

const formGutter: [Gutter, Gutter] = [40, 0];


const CreateCompany: FunctionComponent<CreateCompanyProps> = ({ visible, onComplete, onCreateOpportunity }) => {
    const membershipService = useService(MembershipService);
    const { confirmationModalStore, globalLoadingStore, toastStore } = useStores();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(
        CreateNewCompanyContactSchema,
        form
    );
    const [contactErrors, validateContactForm, resetContactErrors] = useFormValidation(
        OpportunityContactSchema,
        form
    );
    const history = useHistory();
    const { t } = useTranslation();
    const [activeFormStep, setActiveFormStep] = useState<CreateCompanyFormStep>(
        CreateCompanyFormStep.Company
    );
    const [companyImageDetails, setCompanyImageDetails] = useState<ImageDetails[]>();
    const [contactImageDetails, setContactImageDetails] = useState<ImageDetails[]>();

    const [ownerRadioState, setOwnerRadioState] = useState<OpportunityRadioButtonValues>(OpportunityRadioButtonValues.New);
    const [selectedExistingContactId, setSelectedExistingContactId] = useState<string | undefined>(
        undefined
    );
    const [selectedExistingContactName, setSelectedExistingContactName] = useState<string>();

    const modalRef = useRef<HTMLDivElement>(null);

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
        resetContactErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Company" />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t('Membership.company_leave_confirmation', { param1: 'creation' }),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const validateAndCreateMembershipRequestObject = async (): Promise<{
        isValid: boolean;
        requestObject?: CreateMembershipRequestDto;
    }> => {
        const formValues = form.getFieldsValue();
        const validateObject = {
            name: formValues['membership.name'],
            billingName: formValues['membership.billingName'],
            billingEmail: formValues['membership.billingEmail'],
            presentation: formValues['membership.presentation'],
            numberOfEmployees: formValues['membership.numberOfEmployees'],
            industry: formValues['membership.industry'],
            image: companyImageDetails
                ? ({
                      delete: companyImageDetails[0].isDeleted,
                      uploadBase64: companyImageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null,
            baseCampusIds: formValues['membership.locations']?.map(
                (location: { value: string; label: string }) => location.value
            ),
            expertiseIds: formValues['membership.expertises']?.map(
                (expertise: { value: string; label: string }) => expertise.value
            ),
            address: {
                addressLine1: formValues['membership.address.addressLine1'],
                city: formValues['membership.address.city'],
                country: formValues['membership.address.country'],
                postalCode: formValues['membership.address.postalCode'],
                state: formValues['membership.address.state'],
            } as CreateUpdateAddressRequestDto,
            legalContact: {
                firstName: formValues['membership.legalContact.firstName'],
                lastName: formValues['membership.legalContact.lastName'],
                phone: formValues['membership.legalContact.phone'],
                email: formValues['membership.legalContact.email'],
                birthDate: dateMomentToString(formValues['membership.legalContact.birthDate']),
                addressLine1: formValues['membership.legalContact.addressLine1'],
                city: formValues['membership.legalContact.city'],
                country: formValues['membership.legalContact.country'],
                postalCode: formValues['membership.legalContact.postalCode'],
                state: formValues['membership.legalContact.state'],
            },
            contactInfo: {
                website: formValues['membership.contactInfo.website'],
                email: formValues['membership.contactInfo.email'],
                phone1: formValues['membership.contactInfo.phone1'],
                angelListUrl: formValues['membership.contactInfo.angelListUrl'],
                instagramUrl: formValues['membership.contactInfo.instagramUrl'],
                linkedInUrl: formValues['membership.contactInfo.linkedInUrl'],
                twitterUrl: formValues['membership.contactInfo.twitterUrl'],
                facebookUrl: formValues['membership.contactInfo.facebookUrl'],
            } as LightContactInfoDto,
        };

        if (!(await validateForm({ membership: validateObject }))) return { isValid: false };

        const legalContactAddress: CreateUpdateAddressRequestDto = {
            addressLine1: validateObject.legalContact.addressLine1,
            city: validateObject.legalContact.city,
            country: validateObject.legalContact.country,
            postalCode: validateObject.legalContact.postalCode,
            state: validateObject.legalContact.state,
        };

        const request: CreateMembershipRequestDto = {
            ...validateObject,
            legalContact: { ...validateObject.legalContact, address: legalContactAddress },
        };

        return { isValid: true, requestObject: request };
    };

    const validateAndCreateOwnerRequestObject = async (): Promise<{
        isValid: boolean;
        requestObject?: any;
    }> => {
        const formValues = form.getFieldsValue();
        
        const request: CreateMembershipOwnerDto = {
            image: contactImageDetails
                ? ({
                      delete: contactImageDetails[0].isDeleted,
                      uploadBase64: contactImageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null,
            salutation: formValues['contact.salutation'],
            firstName: formValues['contact.firstName'],
            middleName: formValues['contact.middleName'],
            lastName: formValues['contact.lastName'],
            birthDate: dateMomentToString(formValues['contact.birthDate']),
            wifiPassword: formValues['contact.wifiPassword'],
            jobPosition: formValues['contact.jobPosition'],
            introduction: formValues['contact.introduction'],
            expertiseIds: formValues['contact.expertises']?.map(
                (expertise: { value: string; label: string }) => expertise.value
            ),
            contactInfo: {
                phone1: formValues['contact.contactInfo.phone1'],
                phone2: formValues['contact.contactInfo.phone2'],
                email: formValues['contact.contactInfo.email'],
                angelListUrl: formValues['contact.contactInfo.angelListUrl'],
                twitterUrl: formValues['contact.contactInfo.twitterUrl'],
                facebookUrl: formValues['contact.contactInfo.facebookUrl'],
                linkedInUrl: formValues['contact.contactInfo.linkedInUrl'],
                instagramUrl: formValues['contact.contactInfo.instagramUrl'],
            },
            address: {
                addressLine1: formValues['contact.address.addressLine1'],
                city: formValues['contact.address.city'],
                country: formValues['contact.address.country'],
                postalCode: formValues['contact.address.postalCode'],
                state: formValues['contact.address.state'],
            } as CreateUpdateAddressRequestDto,
            isPrivate: !formValues['contact.isPrivate'],
        };
        if (
            !(await validateContactForm({
                contact: request,
                existingContact: ownerRadioState === OpportunityRadioButtonValues.Existing,
                contactId: selectedExistingContactId,
            }))
        )
            return { isValid: false };

        return {
            isValid: true,
            requestObject: {
                contact: request,
                contactId: selectedExistingContactId,
            },
        };
    };

    const submit = async (): Promise<void> => {
        const membershipValidation = await validateAndCreateMembershipRequestObject();
        const ownerValidation = await validateAndCreateOwnerRequestObject();

        if (!membershipValidation.isValid || !ownerValidation.isValid) return;

        const request: CreateMembershipRequestDto = {
            ...membershipValidation.requestObject,
            owner:
                ownerRadioState === OpportunityRadioButtonValues.New
                    ? ownerValidation.requestObject.contact
                    : undefined,
            ownerId:
                ownerRadioState === OpportunityRadioButtonValues.Existing
                    ? ownerValidation.requestObject.contactId
                    : undefined,
        };

        try {
            globalLoadingStore.addLoading();
            const response = await membershipService.createMembership(request);

            toastStore.toast({
                type: 'success',
                messageKey: t('Membership.membership_created_successfully'),
            });

            globalLoadingStore.removeLoading();
            if (
                !(await confirmationModalStore.confirm({
                    icon: <Icon iconName="Plan" />,
                    title: t('Contact.create_an_opportunity'),
                    message: t('Contact.do_you_want_to_create_opportunity'),
                    positiveText: t('Contact.yes_create_opportunity'),
                    negativeText: t('Contact.no_create_opportunity'),
                }))
            ) {
                dismiss(true);
                history.push(`/companies/${response?.id}/dashboard`);
            } else {
                dismiss(true);
                onCreateOpportunity(
                    response?.ownerId!,
                    ownerRadioState === OpportunityRadioButtonValues.Existing
                        ? selectedExistingContactName!
                        : `${request.owner?.firstName} ${request.owner?.lastName}`,
                    response?.id!,
                    request.name ?? ''
                );
            }
        } catch (e) {
            if (e.response?.data.error === 'E004017') {
                toastStore.toast({
                    type: 'error',
                    message: e.response.data.error_description,
                });
            } else if (!e.treated) {
                toastStore.serverError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const onNextClick = async (): Promise<void> => {
        if (activeFormStep === CreateCompanyFormStep.Company) {
            if ((await validateAndCreateMembershipRequestObject()).isValid) {
                setActiveFormStep(CreateCompanyFormStep.Owner);
                if (modalRef.current) modalRef.current.scrollIntoView();
            }
        }

        if (activeFormStep === CreateCompanyFormStep.Owner) {
            submit();
        }
    };

    const actionsButtons = {
        prev: {
            label: activeFormStep === CreateCompanyFormStep.Company ? t('cancel') : t('back'),
            onClick:
                activeFormStep === CreateCompanyFormStep.Company
                    ? exit
                    : () => setActiveFormStep(CreateCompanyFormStep.Company),
        },
        next: {
            label: activeFormStep === CreateCompanyFormStep.Company ? t('next') : t('submit'),
            onClick: activeFormStep === CreateCompanyFormStep.Company ? onNextClick : submit,
        },
    };

    return (
        <BaseModal
            className="FormModal CreateCompany"
            visible={visible}
            title={t(`Membership.create_company`)}
            onCancel={exit}
        >
            <div ref={modalRef}>
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <FormStep
                                Steps={Object.keys(CreateCompanyFormStep).map(
                                    (step: string, i: number) => {
                                        return {
                                            active: step === activeFormStep,
                                            stepNumber: i + 1,
                                            name: step,
                                        };
                                    }
                                )}
                            />
                        </Col>
                    </Row>

                    <div
                        className={activeFormStep === CreateCompanyFormStep.Company ? '' : 'd-none'}
                    >
                        <CompanyFormSection
                            radioButtonValue={CompanyRadioButtonValues.New}
                            errors={errors}
                            setImageDetails={setCompanyImageDetails}
                        />
                    </div>

                    <div className={activeFormStep === CreateCompanyFormStep.Owner ? '' : 'd-none'}>
                        <Row className="radio-buttons" gutter={formGutter}>
                            <Col span={24}>
                                <Radio.Group
                                    onChange={(e): void => {
                                        form.resetFields(['contactId']);
                                        setOwnerRadioState(e.target.value);
                                    }}
                                    value={ownerRadioState}
                                >
                                    <Radio
                                        value={OpportunityRadioButtonValues.New}
                                    >{`New ${activeFormStep.toLowerCase()}`}</Radio>
                                    <Radio
                                        value={OpportunityRadioButtonValues.Existing}
                                    >{`Existing ${activeFormStep.toLowerCase()}`}</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>

                        <ContactFormSection
                            setImageDetails={setContactImageDetails}
                            imageDetails={contactImageDetails}
                            errors={contactErrors}
                            radioButtonValue={ownerRadioState}
                            handleOnContactChange={(value: any): void => {
                                setSelectedExistingContactId(value.value);
                                setSelectedExistingContactName(value.label);
                            }}
                            selectedExistingContactId={selectedExistingContactId}
                        />
                    </div>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={actionsButtons.prev.onClick}
                        >
                            {actionsButtons.prev.label}
                        </Button>

                        <Button
                            type="primary"
                            className="positive"
                            htmlType="button"
                            onClick={actionsButtons.next.onClick}
                        >
                            {actionsButtons.next.label}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
};

export default React.memo(CreateCompany);
