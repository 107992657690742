import React, { FunctionComponent, memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TdWithImage } from 'Components/td-with-image';
import './index.less';


export interface SimpleListContentWithSeeDetailsProps {
    imageUrl?: string;
    defaultIcon: ReactNode;
    title?: string;
    description?: string;
    description2?: string;
    seeDetailsUrl: string;
}

const SimpleListContentWithSeeDetails: FunctionComponent<SimpleListContentWithSeeDetailsProps> = ({
    imageUrl,
    defaultIcon,
    title,
    description,
    description2,
    seeDetailsUrl,
}) => {
    const { t } = useTranslation();
    return (
        <div className="SimpleListContentWithSeeDetails">
            <div className="inner-container">
                <div>
                    <TdWithImage width="32" height="32" imgSrc={imageUrl} defaultImg={defaultIcon}>
                        <div className='text-container'>
                            {title && <div className='title'>{title}</div>}
                            {description && <div className='description'>{description}</div>}
                            {description2 && <div className='description'>{description2}</div>}
                        </div>
                    </TdWithImage>
                </div>
                <div>
                    <a className="button" href={seeDetailsUrl}>
                        {t('see_details')}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default memo(SimpleListContentWithSeeDetails);
