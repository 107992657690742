import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Location: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.938.018v2.687H5.362v1.473H4v11.778h8.458V4.178h-1.391V2.705H8.491V.018h-.553zm-2.024 4.16h4.6v-.92h-4.6v.92zM4.552 15.404h7.362V4.73H4.552v10.674z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.399 14.042h.552V12.57h-.552v1.472zM7.092 14.042h.552V12.57h-.552v1.472zM8.785 14.042h.552V12.57h-.552v1.472zM10.478 14.042h.552V12.57h-.552v1.472zM5.399 10.803h.552V9.33h-.552v1.473zM7.092 10.803h.552V9.33h-.552v1.473zM8.785 10.803h.552V9.33h-.552v1.473zM10.478 10.803h.552V9.33h-.552v1.473zM5.399 7.564h.552V6.092h-.552v1.472zM7.092 7.564h.552V6.092h-.552v1.472zM8.785 7.564h.552V6.092h-.552v1.472zM10.478 7.564h.552V6.092h-.552v1.472z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Location;
