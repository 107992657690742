import React, { FunctionComponent, ReactNode, useLayoutEffect } from 'react';
import './App.less';
import { BrowserRouter } from 'react-router-dom';
import Routes from '../Routes';
import { ConfirmationModalContainer } from 'Components/confirmation-modal';
import { GlobalLoadingContainer } from 'Components/global-loading-container';
import { ToastContainer } from 'Components/toast-container';
import { AuthenticationService } from 'Services/AuthenticationService';
import { useService } from 'Hooks';
import { ConfigProvider } from 'antd';
import TableEmpty from 'Components/table-empty/table-empty';

const App: FunctionComponent = () => {
    const authenticationService = useService(AuthenticationService);
    useLayoutEffect(() => {
        authenticationService.installInterceptors();
    }, [authenticationService]);
    return (
        <>
            <BrowserRouter>
            <ConfigProvider renderEmpty={(): ReactNode => <TableEmpty />}>
                <Routes />
            </ConfigProvider>
            </BrowserRouter>
            <ConfirmationModalContainer />
            <ToastContainer />
            <GlobalLoadingContainer />
        </>
    );
};

export default App;
