import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import React, { FunctionComponent } from 'react';
import './number-input.less';

interface AddonsProps {
    addonBefore?: React.ReactNode;
    addonAfter?: React.ReactNode;
}

type NumberInputProps = InputNumberProps & AddonsProps;

const NumberInput: FunctionComponent<NumberInputProps> = ({ addonBefore, addonAfter, ...rest }) => {
    return (
        <div className="NumberInput">
            {addonBefore && <span className="ant-input-group-addon">{addonBefore}</span>}
            <InputNumber {...rest} />
            {addonAfter && <span className="ant-input-group-addon">{addonAfter}</span>}
        </div>
    );
};

export default NumberInput;
