// GENERATED FILE - DO NOT MODIFY

export enum LeadEventTypeDto {
    Created = "Created",
    StatusUpdated = "StatusUpdated",
    OwnerUpdated = "OwnerUpdated",
    CommentAdded = "CommentAdded",
    TaskAdded = "TaskAdded",
    TaskUpdated = "TaskUpdated",
    TaskCancelled = "TaskCancelled",
    TourScheduled = "TourScheduled",
    TourUpdated = "TourUpdated",
    TourCancelled = "TourCancelled",
    LabelUpdated = "LabelUpdated",
    MeetingScheduled = "MeetingScheduled",
    MeetingUpdated = "MeetingUpdated",
    MeetingCancelled = "MeetingCancelled",
    ReminderScheduled = "ReminderScheduled",
    ReminderUpdated = "ReminderUpdated",
    ReminderCancelled = "ReminderCancelled",
}