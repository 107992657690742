import { Button, Col, Divider, Row, Tooltip } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { Content } from 'antd/lib/layout/layout';
import { MeetingDto } from 'Api/Features/Meetings/Dtos/MeetingDto';
import { ReminderDto } from 'Api/Features/Reminders/Dtos/ReminderDto';
import { GetSalesActivitiesRequestDto } from 'Api/Features/SalesActivities/Dtos/GetSalesActivitiesRequestDto';
import { SalesActivityDto } from 'Api/Features/SalesActivities/Dtos/SalesActivityDto';
import { SalesActivityTypeDto } from 'Api/Features/SalesActivities/Dtos/SalesActivityTypeDto';
import { SubscriptionEventDto } from 'Api/Features/SubscriptionEvents/Dtos/SubscriptionEventDto';
import { GetSubscriptionOnboardingStatusResponseDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionOnboardingStatusResponseDto';
import { SubscriptionDetailsAncestrySubscriptionDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsAncestrySubscriptionDto';
import { SubscriptionDetailsDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsDto';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { DetailHeader } from 'Components/detail-header';
import {
    Bell,
    Calendar as CalendarIcon,
    Check as CheckIcon,
    Credit as CreditIcon,
    Discount as DiscountIcon,
    Dollar as DollarIcon,
    Location as LocationIcon,
    PencilBorder as PencilIcon,
    Plan as PlanIcon,
    Refresh,
    SecurityDeposit as SecurityDepositIcon,
    Tick,
    Wallet as WalletIcon,
} from 'Components/icons';
import Icon from 'Components/icons/icon';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { OnboardingStatusBar } from 'Components/onboarding-status-bar';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import SubscriptionActivityLog from 'Components/subscription-activity-log/subscription-activity-log';
import SubscriptionDetailStatusbar, {
    SubscriptionStatus,
} from 'Components/subscription-detail-statusbar/subscription-detail-statusbar';
import { SubscriptionTimelineItemProps } from 'Components/subscription-timeline-item/subscription-timeline-item';
import SubscriptionTimeline from 'Components/subscription-timeline/subscription-timeline';
import SubscriptionOpportunities from 'Components/subscription-opportunities/subscription-opportunities';
import { Tag } from 'Components/tag';
import { getDetailHeaderSubscriptionTagStatuses, TagColors } from 'Components/tag/tag';
import { useService, useStores } from 'Hooks';
import { DISPLAY_DATE_FORMAT } from 'Models/Constants';
import moment, { Moment } from 'moment';
import {
    default as React,
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EditMeetingModal from 'Routes/authenticated/leads/id/edit-meeting';
import EditReminderModal from 'Routes/authenticated/leads/id/edit-reminder';
import CreateOpportunityModal, {
    CreateModificationRenewalOpportunity,
} from 'Routes/authenticated/opportunities/create-opportunity';
import { MeetingService } from 'Services/MeetingService';
import { OpportunityService } from 'Services/OpportunityService';
import { ReminderService } from 'Services/ReminderService';
import { SalesActivityService } from 'Services/SalesActivityService';
import { SubscriptionService } from 'Services/SubscriptionService';
import { displayFormatedDate } from 'Utils/TimeUtils';
import { theme } from 'variant';
import './index.less';
import EditReferenceNumberModal from './modals/edit-reference-number-modal';
import EditUnitsModal from './modals/edit-units-modal';
import './index.less';
import InviteMemberModal, { InviteMemberContext } from './modals/invite-members';
import MemberAccessModal from './modals/member-access';
import SpacePreparationModal from './modals/space-preparation';
import CancelSubscriptionModal from './modals/cancel-subscription-modal';
import SpacePreperationFiles from './modals/space-preperation-files';
import { SubscriptionDetailsRelatedOpportunityDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsRelatedOpportunityDto';
import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';
import { Opportunity } from 'Models/Opportunities/Opportunity';
import { TaskDto } from 'Api/Features/Tasks/Dtos/TaskDto';
import EditTaskModal from '../../../../leads/id/edit-task';
import { TaskService } from 'Services/TaskService';
import EditNoteModal, { NoteEntityTypeEnum } from 'Components/edit-note-modal/edit-note-modal';
import EditDiscountsModal from './modals/edit-discounts-modal';
import EditSubscriptionSettingsModal from './modals/edit-subscription-settings-modal';
import FloorPlanModal from './modals/floor-plan-modal';
import EditPaymentSettingModal from './modals/edit-payment-setting-modal';
import SimpleListContentWithSeeDetails from 'Components/SimpleListContentWithSeeDetails';
import { ItemWithButtons } from 'Components/item-with-buttons';
import { AgreementTermService } from 'Services/AgreementTermsService';
import { AgreementTermsDto } from 'Api/Features/AgreementTerms/Dtos/AgreementTermsDto';
import { GetAgreementTermsRequestDto } from 'Api/Features/AgreementTerms/Dtos/GetAgreementTermsRequestDto';

const formGutter: [Gutter, Gutter] = [40, 0];

interface SubscriptionDetailsProp {
    userNavigatedFromLocation?: boolean;
    userNavigatedFromCompanySubsriptions?: boolean;
}

const SubscriptionDetails: FunctionComponent<SubscriptionDetailsProp> = ({
    userNavigatedFromLocation,
    userNavigatedFromCompanySubsriptions,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const subscriptionService = useService(SubscriptionService);
    const opportunityService = useService(OpportunityService);
    const meetingService = useService(MeetingService);
    const reminderService = useService(ReminderService);
    const salesActivitiesService = useService(SalesActivityService);
    const taskService = useService(TaskService);
    const agreementTermService = useService(AgreementTermService);

    const [subscription, setSubscription] = useState<SubscriptionDetailsDto>();
    const [subscriptionEvents, setSubscriptionEvents] = useState<SubscriptionEventDto[]>();

    const [onboardingStatus, setOnboardingStatus] =
        useState<GetSubscriptionOnboardingStatusResponseDto>();
    const { subscriptionId } = useParams<{ subscriptionId: string }>();

    const [inspectAndPrepareSpaceModalOpen, setInspectAndPrepareSpaceModalOpen] =
        useState<boolean>(false);
    const [createMembersAccessModalOpen, setCreateMembersAccessModalOpen] =
        useState<boolean>(false);
    const [inviteMemberModalOpen, setInviteMemberModalOpen] = useState<boolean>(false);
    const [viewSpaceFilesModalOpen, setViewSpaceFilesModalOpen] = useState(false);

    const [activities, setActivities] = useState<(SalesActivityDto | null)[]>();

    const [editMeetingModalOpen, setEditMeetingModalOpen] = useState(false);
    const [editMeetingId, setEditMeetingId] = useState<string>();
    const [editReminderModalOpen, setEditReminderModalOpen] = useState(false);
    const [createModificationOpportunityModalOpen, setCreateModificationRenewalOpportunityModalOpen] =
        useState(false);
    const [editReferenceNumberModalOpen, setEditReferenceNumberModalOpen] = useState(false);
    const [editUnitsModalOpen, setEditUnitsModalOpen] = useState(false);
    const [cancelSubscriptionModalOpen, setCancelSubscriptionModalOpen] = useState(false);
    const [editReminderId, setEditReminderId] = useState<string>();
    const [editTaskId, setEditTaskId] = useState<string>();
    const [editTaskModalOpen, setEditTaskModalOpen] = useState(false);
    const [editDiscountsModalOpen, setEditDiscountsModalOpen] = useState(false);

    const [createModificationOpportunityInfos, setCreateModificationOpportunityInfos] = useState<
        CreateModificationRenewalOpportunity | undefined
    >();

    const [editNoteModalOpen, setEditNoteModalOpen] = useState(false);
    const [subscriptionNote, setSubscriptionNote] = useState<string | null | undefined>();

    const [editSubscriptionSettingsModalOpen, setEditSubscriptionSettingsModalOpen] =
        useState(false);
    const [floorplanModalState, setFloorplanModalState] = useState<{
        visible: boolean;
        specificUnitId?: string;
    }>({ visible: false, specificUnitId: undefined });

    const [editPaymentSettingModalOpen, setEditPaymentSettingModalOpen] = useState(false);

    const [signedAgreementTerm, setSignedAgreementTerm] = useState<AgreementTermsDto>();

    const history = useHistory();
    const location = useLocation();

    //#endregion Hooks
    const getCtaOptions = (): ActionMenuOption[] => {
        const ctaOptions: ActionMenuOption[] = [];

        if (
            (subscription?.isActive || subscription?.isUpcoming) &&
            (subscription?.discounts?.length ?? 0) < 1
        ) {
            ctaOptions.push({
                key: 'editDiscounts',
                title: t('Subscription.subscription_add_discounts_title'),
                action: (): void => setEditDiscountsModalOpen(true),
            });
        }

        if (!subscription?.isSecurityDepositRefunded && !subscription?.isSecurityDepositRetained) {
            ctaOptions.push(
                {
                    key: 'markDepositRefunded',
                    title: t('Subscription.subscription_mark_deposit_refunded'),
                    action: (): Promise<void> => onMarkDepositRefundedComplete(),
                },
                {
                    key: 'markDepositRetained',
                    title: t('Subscription.subscription_mark_deposit_retained'),
                    action: (): Promise<void> => onMarkDepositRetainedComplete(),
                }
            );
        }

        if (
            !(
                subscription?.isModificationInProgress ||
                subscription?.isRenewalInProgress ||
                subscription?.ancestry?.subscriptions?.some(
                    (x: SubscriptionDetailsAncestrySubscriptionDto | null) =>
                        x?.parentSubscriptionId === subscription.id
                )
            )
        ) {
            ctaOptions.push(
                {
                    key: 'createRenewalOpportunity',
                    title: t('Subscription.create_renewal_opportunity'),
                    action: (): void => {
                        if (createModificationOpportunityInfos)
                            setCreateModificationOpportunityInfos({
                                ...createModificationOpportunityInfos,
                                isSubscriptionRenewal: true,
                                isSubscriptionModification: false,
                            });

                        setCreateModificationRenewalOpportunityModalOpen(true);
                    },
                },
                {
                    key: 'createModificationOpportunity',
                    title: t('Subscription.create_modification_opportunity'),
                    action: (): void => {
                        if (createModificationOpportunityInfos)
                            setCreateModificationOpportunityInfos({
                                ...createModificationOpportunityInfos,
                                isSubscriptionRenewal: false,
                                isSubscriptionModification: true,
                            });

                        setCreateModificationRenewalOpportunityModalOpen(true);
                    },
                }
            );
        }

        if (!subscription?.isCancelled && !subscription?.isExpired) {
            ctaOptions.push({
                key: 'cancelSubscriptions',
                title: t('Subscription.subscription_cancel_subscription'),
                action: (): void => setCancelSubscriptionModalOpen(true),
            });
        }

        return ctaOptions;
    };

    const fetchSubscriptionDetails = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            const data = await subscriptionService.getSubscription(subscriptionId);
            if (data) {
                setSubscription(data);

                let opportunityData: Opportunity | null;
                if (data.parentOpportunity?.id)
                    opportunityData = await opportunityService.getById(data.parentOpportunity.id);

                setCreateModificationOpportunityInfos((createModificationOpportunityInfos) => ({
                    ...createModificationOpportunityInfos,
                    parentSubscriptionId: subscriptionId,
                    opportunityOwner: opportunityData?.ownerAccount,
                    company: opportunityData?.membership ?? data.membership,
                    opportunityContact: opportunityData?.contact,
                    locationId: data.campusId ?? undefined,
                    product: opportunityData?.product ?? undefined,
                    initialOpportunity: opportunityData,
                }));
            }
        } catch (e) {
            toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, subscriptionId, toastStore, subscriptionService, opportunityService]);

    useEffect(() => {
        fetchSubscriptionDetails();
    }, [fetchSubscriptionDetails]);

    const fetchOnboardingStatus = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            const data = await subscriptionService.getSubscriptionOnboardingStatus({
                subscriptionId,
            });
            if (data) {
                setOnboardingStatus(data);
            }
        } catch (e) {
            toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, subscriptionId, toastStore, subscriptionService]);

    useEffect(() => {
        fetchOnboardingStatus();
    }, [
        fetchOnboardingStatus,
        inspectAndPrepareSpaceModalOpen,
        inviteMemberModalOpen,
        createMembersAccessModalOpen,
    ]);

    const fetchSubscriptionEvents = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            const data = await subscriptionService.getSubscriptionEvents(subscriptionId, {});
            if (data) {
                setSubscriptionEvents(
                    data.items?.filter((event) => event !== null).map((event) => event!)
                );
            }
        } catch (e) {
            toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, subscriptionId, toastStore, subscriptionService]);

    useEffect(() => {
        fetchSubscriptionEvents();
    }, [
        fetchSubscriptionEvents,
        inspectAndPrepareSpaceModalOpen,
        inviteMemberModalOpen,
        createMembersAccessModalOpen,
        editUnitsModalOpen,
    ]);

    const fetchSubscriptionActivites = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            const data = await salesActivitiesService.getSalesActivities({
                subscriptionIds: [subscriptionId],
            } as GetSalesActivitiesRequestDto);
            if (data?.items) {
                setActivities(data.items);
            }
        } catch (e) {
            toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, subscriptionId, toastStore, salesActivitiesService]);

    useEffect(() => {
        fetchSubscriptionActivites();
    }, [fetchSubscriptionActivites]);

    const onAddedActivityComplete = (): void => {
        fetchSubscriptionEvents();
        fetchSubscriptionDetails();
        fetchSubscriptionActivites();
    };

    const getTotalBasePrice = (): number => {
        const addOnsTotal =
            subscription?.addOns?.reduce(
                (total: any, addOn: any) => total + (addOn?.price ?? 0),
                0
            ) ?? 0;
        const price = subscription?.periods ? subscription.periods[0]?.price! : 0;

        return price + addOnsTotal;
    };

    const locationInformation =
        subscription?.campus !== null
            ? [
                  {
                      title: subscription?.campus?.name ?? '',
                      avatar: (
                          <ImageWithPlaceholder
                              width="32"
                              height="32"
                              imgSrc={subscription?.campus?.mainImageUrl}
                              defaultImg={<LocationIcon />}
                          />
                      ),
                  },
              ]
            : undefined;

    const planInformation = [
        {
            title: subscription?.plan?.name ?? '',
            avatar: (
                <ImageWithPlaceholder
                    width="32"
                    height="32"
                    imgSrc={subscription?.plan?.name}
                    defaultImg={<PlanIcon />}
                />
            ),
        },
    ];

    const unitsInformation = (): ReactNode => {
        return (
            <div className="inner-container">
                {subscription?.units
                    ?.filter((unit) => unit && unit.id !== undefined)
                    .map((unit) => (
                        <div className="unit" key={unit?.id ?? ''}>
                            <div className="name">{unit?.name}</div>
                            <div
                                className="view-floorplan"
                                onClick={(): void =>
                                    setFloorplanModalState({
                                        visible: true,
                                        specificUnitId: unit?.id!,
                                    })
                                }
                            >
                                {t('FloorPlan.view_on_floorplan')}
                            </div>
                        </div>
                    ))}
            </div>
        );
    };

    const settingsInformation = (): SimpleListItemProps[] => [
        {
            title: t('Subscription.automatic_renewal_opportunity'),
            avatar: <PlanIcon fill={theme['primary-color']} />,
            description: subscription?.isAutomaticRenewalOpportunityCreationEnabled ? 'On' : 'Off',
        },
        {
            title: t('Subscription.mtm_conversion'),
            avatar: <Refresh fill={theme['primary-color']} />,
            description: subscription?.becomesMonthToMonthOnExpiration ? 'On' : 'Off',
        },
        {
            title: t('Subscription.expiration_reminder'),
            avatar: <Bell fill={theme['primary-color']} />,
            description: subscription?.sendExpirationReminder ? 'On' : 'Off',
        },
    ];

    const durationInformation = (): SimpleListItemProps[] => {
        const duration: SimpleListItemProps[] = [
            {
                title: t('start_date'),
                avatar: <CalendarIcon fill={theme['primary-color']} />,
                description: moment.utc(subscription?.startDate).format(DISPLAY_DATE_FORMAT),
            },
            {
                title: t('end_date'),
                avatar: <CalendarIcon fill={theme['primary-color']} />,
                description: subscription?.endDate
                    ? moment.utc(subscription?.endDate).format(DISPLAY_DATE_FORMAT)
                    : t('none'),
            },
        ];
        if (subscription?.endDate && subscription.becomesMonthToMonthOnExpiration)
            duration.push({
                title: t('Proposal.month_to_month_after', {
                    param1: moment.utc(subscription?.endDate).format(DISPLAY_DATE_FORMAT),
                }),
                avatar: <PlanIcon fill={theme['primary-color']} />,
            });

        return duration;
    };

    const pricingInformation = (): SimpleListItemProps[] => {
        const pricing: SimpleListItemProps[] = [];

        const periods = subscription?.periods?.map((period) => ({
            title: t('Proposal.pricing_from', {
                param1: moment.utc(period?.startDate).format(DISPLAY_DATE_FORMAT),
            }),
            avatar: <DollarIcon fill={theme['primary-color']} />,
            description: t('us_price_format', {
                param1: period?.price?.toFixed(2) ?? '0.00',
            }),
        }));

        if (periods) pricing.push(...periods);

        if (subscription?.monthToMonthAmount) {
            pricing.push({
                title: t('mtm_price'),
                avatar: <DollarIcon fill={theme['primary-color']} />,
                description: t('us_price_format', {
                    param1: subscription?.monthToMonthAmount?.toFixed(2) ?? '0.00',
                }),
            });
        }

        return pricing;
    };

    const addOnsInformation = subscription?.addOns?.map((addOn: any) => ({
        title: addOn?.name ?? '',
        description: t('us_price_format', {
            param1: addOn?.price?.toFixed(2) ?? '0.00',
        }),
    }));

    const securityDepositBadge = (subscription: SubscriptionDetailsDto | undefined): ReactNode => {
        const label = subscription?.isSecurityDepositRefunded ? t('refunded') : t('retained');
        return (
            <Tag
                label={label}
                color={label === t('refunded') ? TagColors.default : TagColors.disabled}
            />
        );
    };

    const securityDepositInformation = [
        {
            title: t('Subscription.sec_dep_original'),
            avatar: <SecurityDepositIcon fill={theme['primary-color']} />,
            description: t('us_price_format', {
                param1: subscription?.securityDeposit?.toFixed(2) ?? '0.00',
            }),
        },
        {
            title: t('Subscription.sec_dep_held'),
            avatar: <SecurityDepositIcon fill={theme['primary-color']} />,
            description: t('us_price_format', {
                param1: subscription?.heldSecurityDeposit?.toFixed(2) ?? '0.00',
            }),
            badge:
                subscription?.isSecurityDepositRefunded || subscription?.isSecurityDepositRetained
                    ? securityDepositBadge(subscription)
                    : null,
        },
    ];

    const creditsInformation = [
        {
            title: t('Proposal.credits_included_per_month'),
            description: subscription?.creditPointsPerInterval?.toFixed(2) ?? '0.00',
            avatar: <CreditIcon fill={theme['primary-color']} />,
        },
    ];

    const paymentModalitiesInformation = (): SimpleListItemProps[] => {
        if (subscription?.isCheckPayable)
            return [
                {
                    title: t('Proposal.check_transactions'),
                    avatar: <CheckIcon fill={theme['primary-color']} />,
                },
            ];
        if (subscription?.isChargedAutomatically)
            return [
                {
                    title: t('Proposal.payment_pre_authorized'),
                    avatar: <WalletIcon fill={theme['primary-color']} />,
                },
            ];
        else
            return [
                {
                    title: t('Proposal.payment_manual'),
                    avatar: <WalletIcon fill={theme['primary-color']} />,
                },
            ];
    };

    const getBreadcrumb = (): BreadcrumbSegment[] => {
        const segments: BreadcrumbSegment[] = [];
        if (userNavigatedFromLocation) {
            segments.push(
                {
                    path: 'locations',
                    nameKey: 'Location.location_title',
                },
                {
                    path: subscription?.campusId || '',
                    name: subscription?.campus?.name || '',
                }
            );
        }

        if (userNavigatedFromCompanySubsriptions) {
            segments.push(
                {
                    path: 'companies',
                    nameKey: 'companies',
                },
                {
                    path: `${subscription?.membershipId}/dashboard`,
                    nameKey: subscription?.membership?.name ?? '',
                }
            );
        }

        segments.push(
            {
                path: 'subscriptions',
                nameKey: 'subscriptions',
            },
            {
                path: subscription?.id || '',
                name: subscription?.membershipName || '',
            }
        );

        return segments;
    };

    const onEditReferenceNumberClick = () => setEditReferenceNumberModalOpen(true);

    const onEditUnitsClick = () => setEditUnitsModalOpen(true);

    const onMarkDepositRefundedComplete = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="SecurityDeposit" />,
                title: t(`Subscription.subscription_mark_deposit_refunded_title`),
                message: t(
                    `Subscription.subscription_mark_deposit_refunded_retained_confirmation_info`
                ),
                positiveText: t(`Subscription.subscription_mark_deposit_refunded_confirmation`),
                negativeText: t(`no`),
            }))
        ) {
            return;
        } else {
            try {
                globalLoadingStore.addLoading();

                if (subscription?.id)
                    await subscriptionService.markSubscriptionSecurityDepositAsRefunded(
                        subscription.id
                    );

                fetchSubscriptionEvents();
                fetchSubscriptionDetails();
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        }
    };

    const onMarkDepositRetainedComplete = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="SecurityDeposit" />,
                title: t(`Subscription.subscription_mark_deposit_retained_title`),
                message: t(
                    `Subscription.subscription_mark_deposit_refunded_retained_confirmation_info`
                ),
                positiveText: t(`Subscription.subscription_mark_deposit_retained_confirmation`),
                negativeText: t(`no`),
            }))
        ) {
            return;
        } else {
            try {
                globalLoadingStore.addLoading();

                if (subscription?.id)
                    await subscriptionService.markSubscriptionSecurityDepositAsRetained(
                        subscription.id
                    );

                fetchSubscriptionEvents();
                fetchSubscriptionDetails();
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        }
    };

    const getSubscriptionStatus = (): { status: SubscriptionStatus; date: Moment | undefined } => {
        if (subscription!.isModificationInProgress) {
            const allModifications = subscription?.relatedOpportunities?.filter(
                (related) => related?.isSubscriptionModification
            );
            const date = allModifications?.[allModifications.length - 1]?.dateCreated
                ? moment.utc(allModifications?.[allModifications.length - 1]?.dateCreated)
                : undefined;
            return { status: SubscriptionStatus.renewalModification, date };
        }

        if (subscription!.isRenewalInProgress) {
            const allRenewals = subscription?.relatedOpportunities?.filter(
                (related) => related?.isSubscriptionRenewal
            );
            const date = allRenewals?.[allRenewals.length - 1]?.dateCreated
                ? moment.utc(allRenewals?.[allRenewals.length - 1]?.dateCreated)
                : undefined;
            return { status: SubscriptionStatus.renewalModification, date };
        }

        if (subscription!.isOnboardingInProgress)
            return {
                status: SubscriptionStatus.onboarding,
                date: subscription!.agreementSignedDate
                    ? moment.utc(subscription!.agreementSignedDate)
                    : undefined,
            };

        return {
            status: SubscriptionStatus.current,
            date: subscription?.onboardingCompletedDate
                ? moment.utc(subscription!.onboardingCompletedDate)
                : moment.utc(subscription?.startDate),
        };
    };

    const onEditMeetingComplete = (): void => {
        fetchSubscriptionEvents();
        fetchSubscriptionDetails();
        fetchSubscriptionActivites();
        setEditMeetingId(undefined);
        setEditMeetingModalOpen(false);
    };
    const onEditReminderComplete = (): void => {
        fetchSubscriptionEvents();
        fetchSubscriptionDetails();
        fetchSubscriptionActivites();
        setEditReminderId(undefined);
        setEditReminderModalOpen(false);
    };
    const onEditTaskComplete = (): void => {
        fetchSubscriptionEvents();
        fetchSubscriptionDetails();
        fetchSubscriptionActivites();
        setEditTaskId(undefined);
        setEditTaskModalOpen(false);
    };

    const createModificationOpportunityMComplete = async (
        success: boolean,
        id?: string
    ): Promise<void> => {
        setCreateModificationRenewalOpportunityModalOpen(false);

        if (success) history.push(`/opportunities/${id}/dashboard`);
    };

    const currentDate = moment().utcOffset(0, true);
    const isUpcoming = (
        activity: SalesActivityDto,
        upcomingArray: SalesActivityDto[],
        pastArray: SalesActivityDto[],
        date?: string
    ) => {
        if (!currentDate.isSameOrAfter(date)) {
            upcomingArray.push(activity);
        } else {
            pastArray.push(activity);
        }
    };

    const upcomingMeetings: SalesActivityDto[] = [];
    const pastMeetings: SalesActivityDto[] = [];
    const upcomingTours: SalesActivityDto[] = [];
    const pastTours: SalesActivityDto[] = [];
    const upcomingReminders: SalesActivityDto[] = [];
    const pastReminders: SalesActivityDto[] = [];
    const upcomingTasks: SalesActivityDto[] = [];
    const pastTasks: SalesActivityDto[] = [];

    activities?.forEach((activity) => {
        switch (activity?.type) {
            case SalesActivityTypeDto.Meeting:
                isUpcoming(activity, upcomingMeetings, pastMeetings, activity.meeting?.startTime);
                break;
            case SalesActivityTypeDto.Tour:
                isUpcoming(activity, upcomingTours, pastTours, activity.tour?.startTime);
                break;
            case SalesActivityTypeDto.Reminder:
                isUpcoming(activity, upcomingReminders, pastReminders, activity.reminder?.date);
                break;
            case SalesActivityTypeDto.Task:
                isUpcoming(activity, upcomingTasks, pastTasks, activity.task?.date);
                break;
        }
    });

    const cancelMeeting = async (id: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Coffee" />,
                title: t(`Lead.delete_meeting`),
                message: t(`Lead.delete_meeting_confirm_message`),
                positiveText: t(`Lead.delete_meeting`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        //call delete
        await meetingService.cancelMeeting(id);
        toastStore.toast({
            type: 'success',
            messageKey: `Lead.meeting_delete_success`,
        });

        fetchSubscriptionEvents();
        fetchSubscriptionDetails();
        fetchSubscriptionActivites();
    };

    const cancelReminder = async (id: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Coffee" />,
                title: t(`Lead.delete_reminder`),
                message: t(`Lead.delete_reminder_confirm_message`),
                positiveText: t(`Lead.delete_reminder`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        //call delete
        await reminderService.cancelReminder(id);
        toastStore.toast({
            type: 'success',
            messageKey: `Lead.meeting_delete_success`,
        });

        fetchSubscriptionEvents();
        fetchSubscriptionDetails();
        fetchSubscriptionActivites();
    };

    const cancelTask = async (id: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Tick />,
                title: t(`Lead.delete_task`),
                message: t(`Lead.delete_task_confirm_message`),
                positiveText: t(`Lead.delete_task`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        //call delete
        await taskService.cancelTask(id);
        toastStore.toast({
            type: 'success',
            messageKey: `Lead.task_delete_success`,
        });
        fetchSubscriptionEvents();
        fetchSubscriptionDetails();
        fetchSubscriptionActivites();
    };

    let upcomingActivities: (SalesActivityDto | null)[] = [];
    if (upcomingMeetings && upcomingReminders && upcomingTasks) {
        upcomingActivities = [...upcomingMeetings, ...upcomingReminders, ...upcomingTasks];
    }

    let pastActivities: (SalesActivityDto | null)[] = [];
    if (pastMeetings && pastReminders && pastTasks) {
        pastActivities = [...pastMeetings, ...pastReminders, ...pastTasks];
    }

    const getMeetingToEdit = (): MeetingDto | undefined => {
        return (
            activities
                ?.filter((activity) => activity?.type === SalesActivityTypeDto.Meeting)
                ?.find((activity) => activity?.meeting?.id === editMeetingId)?.meeting || undefined
        );
    };

    const getReminderToEdit = (): ReminderDto | undefined => {
        return (
            activities
                ?.filter((activity) => activity?.type === SalesActivityTypeDto.Reminder)
                ?.find((activity) => activity?.reminder?.id === editReminderId)?.reminder ||
            undefined
        );
    };

    const getTaskToEdit = (): TaskDto | undefined => {
        return (
            activities
                ?.filter((activity) => activity?.type === SalesActivityTypeDto.Task)
                ?.find((activity) => activity?.task?.id === editTaskId)?.task || undefined
        );
    };

    const viewPrepareSpaceFiles = (): void => {
        setViewSpaceFilesModalOpen(true);
    };

    const baseUrl = location.pathname.split('/');
    baseUrl.pop();
    const baseUrlCleaned = baseUrl.join('/') + '/';
    const ancestry = subscription?.ancestry?.subscriptions;

    const subscriptionTimeline: SubscriptionTimelineItemProps[] | undefined = ancestry
        ?.map((subscription: SubscriptionDetailsAncestrySubscriptionDto | null) => {
            const isFirstSubscription = subscription?.parentSubscriptionId === null;
            const isSubscriptionCurrentlyWatching = subscription?.id === subscriptionId;
            const linkLabel = isFirstSubscription
                ? t('Subscription.subscription_timeline_view_first_subscription')
                : t('Subscription.subscription_timeline_view_version');
            const linkUrl = isSubscriptionCurrentlyWatching
                ? undefined
                : baseUrlCleaned + subscription?.id;

            let subtitle = t('Subscription.subscription_timeline_version_current');
            if (subscription?.isCancelled) {
                subtitle = t('Subscription.subscription_timeline_version_cancelled');
            } else if (subscription?.isExpired) {
                subtitle = t('Subscription.subscription_timeline_version_expired');
            } else if (subscription?.isUpcoming) {
                subtitle = t('Subscription.subscription_timeline_version_upcoming');
            }

            const undefinedOrVal = (
                icon: string,
                text: number | string | null | undefined
            ): { icon: string; text: string } | undefined =>
                text ? { icon: icon, text: `${text}` } : undefined;

            const details = [
                subscription?.changes?.newAddOns
                    ? subscription?.changes?.newAddOns.map((addOn: any) =>
                          undefinedOrVal('Add', addOn?.name)
                      )
                    : undefined,
                subscription?.changes?.removedAddOns
                    ? subscription?.changes?.removedAddOns.map((addOn: any) =>
                          undefinedOrVal('Minus', addOn?.name)
                      )
                    : undefined,
                undefinedOrVal('Dollar', subscription?.changes?.newAmount),
                undefinedOrVal('Plan', subscription?.changes?.newPlan?.name),
            ].flat();
            const cleanedDetails = details.filter((detail) => detail !== undefined);

            return {
                date: `${displayFormatedDate(subscription?.startDate)}`,
                link: isSubscriptionCurrentlyWatching
                    ? undefined
                    : { label: linkLabel, url: linkUrl },
                subtitle: subtitle,
                disabled: subscription?.isUpcoming,
                emphasis: isSubscriptionCurrentlyWatching,
                circleEmphasis: isFirstSubscription,
                details: cleanedDetails,
            };
        })
        .reverse();

    const relatedOpportunityForHeader = ():
        | SubscriptionDetailsRelatedOpportunityDto
        | null
        | undefined => {
        return subscription?.relatedOpportunities?.find(
            (related) =>
                related !== null &&
                related?.status !== OpportunityStatusDto.AgreementSigned &&
                related?.status !== OpportunityStatusDto.Lost
        );
    };

    const currentSubscriptionVersionId = (): string | null | undefined => {
        if (subscription && subscription.ancestry) {
            if (subscription.ancestry.isActive) {
                return subscription.ancestry.subscriptions?.find((version) => version?.isActive)
                    ?.id;
            }
            if (subscription.ancestry.isExpired) {
                const allExpired = subscription.ancestry.subscriptions?.filter(
                    (version) => version?.isExpired
                );
                if (allExpired && allExpired.length > 0)
                    return allExpired[allExpired.length - 1]?.id;
            }

            if (subscription.ancestry.isUpcoming) {
                const allUpcoming = subscription.ancestry.subscriptions?.filter(
                    (version) => version?.isUpcoming
                );
                if (allUpcoming && allUpcoming.length > 0)
                    return allUpcoming[allUpcoming.length - 1]?.id;
            }
        } else return null;

        return null;
    };

    const fetchNote = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            //call service
            const response = await subscriptionService.getSubscriptionNote(subscriptionId);
            setSubscriptionNote(response?.note);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, subscriptionId, subscriptionService, toastStore]);

    useEffect(() => {
        fetchNote();
    }, [fetchNote]);

    const fetchAgreementTermsList = useCallback(async () => {
        if(subscription?.parentOpportunity?.id) {
            globalLoadingStore.addLoading();

            try {
                const resquest: GetAgreementTermsRequestDto = {
                    isSigned: true,
                    opportunityIds: [subscription.parentOpportunity.id],
                };

                const agreementTermsList = await agreementTermService.getAgreementTerms(resquest);
    
                setSignedAgreementTerm(
                    agreementTermsList?.items?.find((term) => term?.isSigned) ?? undefined
                );
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        }
    }, [agreementTermService, globalLoadingStore, toastStore, subscription?.parentOpportunity]);

    useEffect(() => {
        fetchAgreementTermsList();
    }, [fetchAgreementTermsList]);

    const fetchAgreementTermsDocument = useCallback(
        async (agreementTermsId: string | undefined) => {
            globalLoadingStore.addLoading();

            try {
                if (agreementTermsId) {
                    const agreementTermsDocument = await agreementTermService.getAgreementTermsDocument(
                        agreementTermsId
                    );
                    const documentUrl = agreementTermsDocument?.url || undefined;

                    if (agreementTermsDocument !== null) window.open(documentUrl, '_blank');
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [agreementTermService, globalLoadingStore]
    );

    //#region Render
    return (
        <div className="SubscriptionDetails">
            <DetailHeader
                title={
                    subscription
                        ? `${subscription?.membershipName} - ${subscription?.plan?.name}`
                        : ''
                }
                loading={false}
                routes={getBreadcrumb()}
                displayTags={currentSubscriptionVersionId() === subscriptionId}
                displayRefNumber
                subscriptionStatuses={getDetailHeaderSubscriptionTagStatuses(
                    subscription?.ancestry ?? undefined
                )}
                menuOptions={getCtaOptions()}
                subscriptionReferenceNumber={subscription?.referenceNumber || undefined}
                onEditReferenceNumberClick={onEditReferenceNumberClick}
                relatedOpportunity={relatedOpportunityForHeader()}
                onGoToOpportunityClick={(): void =>
                    history.push(`/opportunities/${relatedOpportunityForHeader()?.id}`)
                }
                onGoToCurrentSubscriptionVersionClick={
                    currentSubscriptionVersionId() &&
                    currentSubscriptionVersionId() !== subscriptionId
                        ? (): void => {
                              history.push(`/subscriptions/${currentSubscriptionVersionId()}`);
                          }
                        : undefined
                }
            />

            <Content>
                {subscription && !subscription.isCancelled && (
                    <SubscriptionDetailStatusbar
                        subscriptionStatus={getSubscriptionStatus().status}
                        subscriptionStatusActivityDate={getSubscriptionStatus().date}
                    />
                )}

                {subscription &&
                    !subscription.isCancelled &&
                    subscription.isOnboardingInProgress && (
                        <OnboardingStatusBar
                            categories={onboardingStatus?.categories
                                ?.filter((category) => category !== null)
                                .map((category) => category!)}
                            setInspectAndPrepareSpaceModalOpen={(visible) =>
                                setInspectAndPrepareSpaceModalOpen(visible)
                            }
                            setCreateMembersAccessModalOpen={(visible): void =>
                                setCreateMembersAccessModalOpen(visible)
                            }
                            setInviteMembersModalOpen={(visible) =>
                                setInviteMemberModalOpen(visible)
                            }
                        />
                    )}

                <Row gutter={formGutter}>
                    <Col span={12}>
                        {locationInformation && (
                            <Row>
                                <Col span={24}>
                                    <SimpleList title={t('location')} data={locationInformation} />
                                </Col>
                            </Row>
                        )}

                        <Row>
                            <Col span={24}>
                                <SimpleList title={t('company')}>
                                    <SimpleListContentWithSeeDetails
                                        title={subscription?.membership?.name ?? ''}
                                        imageUrl={subscription?.membership?.imageUrl ?? ''}
                                        defaultIcon={<Icon iconName="Company" />}
                                        seeDetailsUrl={`/companies/${subscription?.membership?.id}/dashboard`}
                                    />
                                </SimpleList>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <SimpleList title={t('plan')} data={planInformation} />
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24} className="units editable">
                                <SimpleList title={t('units')}>
                                    <Tooltip
                                        title={t('Subscription.subscription_edit_units_title')}
                                    >
                                        <Button icon={<PencilIcon />} onClick={onEditUnitsClick} />
                                    </Tooltip>
                                    {unitsInformation()}
                                </SimpleList>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <SimpleList
                                    title={t('Proposal.subscription_duration')}
                                    data={durationInformation()}
                                    className={'large-description'}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <SimpleList
                                    title={t('pricing')}
                                    data={pricingInformation()}
                                    className={'large-description'}
                                />
                            </Col>
                        </Row>

                        {(subscription?.addOns?.length ?? 0) > 0 && (
                            <Row>
                                <Col span={24}>
                                    <SimpleList title={t('add_ons')} data={addOnsInformation} />
                                </Col>
                            </Row>
                        )}

                        <Row>
                            <Col span={24}>
                                <SimpleList
                                    className="base-price"
                                    title={t('Proposal.base_monthly_price')}
                                >
                                    <div className="base-price-container">
                                        <div>{subscription?.plan?.name}</div>
                                        <div className="price">
                                            {subscription?.periods &&
                                                t('us_price_format', {
                                                    param1: subscription.periods[0]?.price?.toFixed(
                                                        2
                                                    ),
                                                })}
                                        </div>
                                    </div>

                                    {subscription?.addOns?.map((addOn: any) => (
                                        <div key={addOn?.id} className="base-price-container">
                                            <div>{addOn?.name}</div>
                                            <div className="price">
                                                {t('us_price_format', {
                                                    param1: addOn?.price?.toFixed(2),
                                                })}
                                            </div>
                                        </div>
                                    ))}

                                    <Divider className="base-price-divider" />
                                    <div className="base-price-container">
                                        <div className="total">{t('total')}</div>
                                        <div className="price-total">
                                            {t('us_price_format', {
                                                param1: getTotalBasePrice().toFixed(2),
                                            })}
                                        </div>
                                    </div>
                                </SimpleList>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <SimpleList
                                    title={t('security_deposit')}
                                    data={securityDepositInformation}
                                    className={'large-description'}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <SimpleList
                                    className="large-description"
                                    title={t('credits')}
                                    data={creditsInformation}
                                />
                            </Col>
                        </Row>

                        {(subscription?.discounts?.length ?? 0) > 0 && (
                            <Row>
                                <Col span={24} className="discounts-col">
                                    <SimpleList title={t('discounts')} className="discounts">
                                        {(subscription?.isActive || subscription?.isUpcoming) && (
                                            <Tooltip
                                                title={t(
                                                    'Subscription.subscription_edit_discounts_title'
                                                )}
                                            >
                                                <Button
                                                    icon={<PencilIcon />}
                                                    onClick={(): void =>
                                                        setEditDiscountsModalOpen(true)
                                                    }
                                                />
                                            </Tooltip>
                                        )}
                                        {subscription?.discounts?.map(
                                            (discount: any, i: number) => (
                                                <>
                                                    <div key={i} className="discount-container">
                                                        <div className="avatar">
                                                            <DiscountIcon
                                                                fill={theme['primary-color']}
                                                            />
                                                        </div>
                                                        <div className="discount-info-container">
                                                            <div className="title">
                                                                {discount?.title}
                                                            </div>
                                                            <div className="amount">
                                                                {t('us_price_format', {
                                                                    param1: discount?.amount.toFixed(
                                                                        2
                                                                    ),
                                                                })}
                                                            </div>
                                                            <div className="period">
                                                                {discount?.endDate
                                                                    ? t(
                                                                          'Proposal.discount_from_to',
                                                                          {
                                                                              param1: discount?.startDate
                                                                                  ? moment
                                                                                        .utc(
                                                                                            discount?.startDate
                                                                                        )
                                                                                        .format(
                                                                                            DISPLAY_DATE_FORMAT
                                                                                        )
                                                                                  : moment
                                                                                        .utc(
                                                                                            subscription?.startDate
                                                                                        )
                                                                                        .format(
                                                                                            DISPLAY_DATE_FORMAT
                                                                                        ),
                                                                              param2: discount?.endDate
                                                                                  ? moment
                                                                                        .utc(
                                                                                            discount?.endDate
                                                                                        )
                                                                                        .format(
                                                                                            DISPLAY_DATE_FORMAT
                                                                                        )
                                                                                  : '',
                                                                          }
                                                                      )
                                                                    : t('Proposal.discount_from', {
                                                                          param1: discount?.startDate
                                                                              ? moment
                                                                                    .utc(
                                                                                        discount?.startDate
                                                                                    )
                                                                                    .format(
                                                                                        DISPLAY_DATE_FORMAT
                                                                                    )
                                                                              : moment
                                                                                    .utc(
                                                                                        subscription?.startDate
                                                                                    )
                                                                                    .format(
                                                                                        DISPLAY_DATE_FORMAT
                                                                                    ),
                                                                      })}
                                                            </div>
                                                            {discount?.internalNote && (
                                                                <div className="note-container">
                                                                    <div className="prefix">
                                                                        {t(
                                                                            'Proposal.discount_note'
                                                                        )}
                                                                    </div>
                                                                    <div className="note">
                                                                        {discount?.internalNote}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {i < (subscription.discounts?.length ?? 0) && (
                                                        <Divider className="divider" />
                                                    )}
                                                </>
                                            )
                                        )}
                                    </SimpleList>
                                </Col>
                            </Row>
                        )}

                        <Row>
                            <Col span={24} className="editable">
                                <SimpleList
                                    title={t('payment_setting')}
                                    data={paymentModalitiesInformation()}
                                >
                                    <Tooltip title={t('Subscription.edit_payment_setting')}>
                                        <Button
                                            icon={<PencilIcon />}
                                            onClick={(): void =>
                                                setEditPaymentSettingModalOpen(true)
                                            }
                                        />
                                    </Tooltip>
                                </SimpleList>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24} className="settings editable">
                                <SimpleList
                                    title={t('settings')}
                                    data={settingsInformation()}
                                    className="large-description"
                                >
                                    <Tooltip title={t('Subscription.edit_subscription_settings')}>
                                        <Button
                                            icon={<PencilIcon />}
                                            onClick={(): void =>
                                                setEditSubscriptionSettingsModalOpen(true)
                                            }
                                        />
                                    </Tooltip>
                                </SimpleList>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={12}>
                        <SubscriptionActivityLog
                            activities={subscriptionEvents}
                            onAddedActivityComplete={onAddedActivityComplete}
                            upcomingActivities={upcomingActivities}
                            pastActivities={pastActivities}
                            onEditMeetingClick={(id: string): void => {
                                setEditMeetingId(id);
                                setEditMeetingModalOpen(true);
                            }}
                            onEditReminderClick={(id: string): void => {
                                setEditReminderId(id);
                                setEditReminderModalOpen(true);
                            }}
                            onEditTaskClick={(id: string): void => {
                                setEditTaskId(id);
                                setEditTaskModalOpen(true);
                            }}
                            onDeleteMeetingClick={(id: string): void => {
                                cancelMeeting(id);
                            }}
                            onDeleteReminderClick={(id: string): void => {
                                cancelReminder(id);
                            }}
                            onDeleteTaskClick={(id: string): void => {
                                cancelTask(id);
                            }}
                            onViewFilesClick={viewPrepareSpaceFiles}
                            onEditNoteClick={(): void => setEditNoteModalOpen(true)}
                            note={subscriptionNote}
                        >
                            {subscription?.parentOpportunity && signedAgreementTerm && (
                                <Row>
                                    <Col span={24}>
                                        <SimpleList
                                            title={t(
                                                'Opportunity.opportunity_agreement_AgreementAdded'
                                            )}
                                        >
                                            <ItemWithButtons
                                                title={`${t(
                                                    `Agreement.agreement_term_signed_uploaded_on`
                                                )} ${displayFormatedDate(
                                                    signedAgreementTerm?.dateCreated
                                                )}`}
                                                tag={{ label: 'signed' }}
                                                buttonsList={[
                                                    {
                                                        label: t(`Agreement.view_in_pdf_agreement`),
                                                        primary: true,
                                                        onClick: (): void => {
                                                            fetchAgreementTermsDocument(
                                                                signedAgreementTerm.id
                                                            );
                                                        },
                                                    },
                                                ]}
                                                lightTheme={true}
                                            />
                                        </SimpleList>
                                    </Col>
                                </Row>
                            )}
                            
                            <SubscriptionTimeline data={subscriptionTimeline} />

                            {subscription?.relatedOpportunities &&
                                subscription.relatedOpportunities.length > 0 && (
                                    <SubscriptionOpportunities
                                        opportunities={
                                            subscription?.relatedOpportunities
                                                ?.filter((opp) => opp !== null)
                                                .map((opp) => opp!) ?? null
                                        }
                                        subscriptionId={subscription?.id}
                                    />
                                )}
                        </SubscriptionActivityLog>
                    </Col>
                </Row>
            </Content>

            {editReferenceNumberModalOpen && (
                <EditReferenceNumberModal
                    visible={editReferenceNumberModalOpen}
                    subscriptionId={subscriptionId}
                    referenceNumber={subscription?.referenceNumber}
                    onComplete={(success: boolean): void => {
                        setEditReferenceNumberModalOpen(false);
                        if (success) {
                            fetchSubscriptionDetails();
                            fetchSubscriptionEvents();
                        }
                    }}
                />
            )}

            {editUnitsModalOpen && (
                <EditUnitsModal
                    visible={editUnitsModalOpen}
                    subscription={subscription || {}}
                    onComplete={(success: boolean): void => {
                        setEditUnitsModalOpen(false);
                        if (success) fetchSubscriptionDetails();
                    }}
                />
            )}

            {cancelSubscriptionModalOpen && (
                <CancelSubscriptionModal
                    visible={cancelSubscriptionModalOpen}
                    onComplete={(): void => {
                        setCancelSubscriptionModalOpen(false);
                        onAddedActivityComplete();
                    }}
                    subscriptionInfos={{
                        id: subscription?.id || '',
                        startDate: `${subscription?.startDate}`,
                        endDate: subscription?.endDate ? subscription.endDate : null,
                        isUpcoming: subscription?.isUpcoming,
                    }}
                />
            )}

            {inspectAndPrepareSpaceModalOpen && (
                <SpacePreparationModal
                    visible={inspectAndPrepareSpaceModalOpen}
                    onComplete={(success: boolean): void => {
                        setInspectAndPrepareSpaceModalOpen(false);
                        if (success) {
                            fetchOnboardingStatus();
                            fetchSubscriptionDetails();
                        }
                    }}
                    subscriptionId={subscription?.id || ''}
                    onboardingStatus={onboardingStatus}
                />
            )}

            {viewSpaceFilesModalOpen && (
                <SpacePreperationFiles
                    visible={viewSpaceFilesModalOpen}
                    onComplete={(): void => {
                        setViewSpaceFilesModalOpen(false);
                    }}
                    onboardingStatus={onboardingStatus}
                />
            )}

            {createMembersAccessModalOpen && (
                <MemberAccessModal
                    visible={createMembersAccessModalOpen}
                    onComplete={(success: boolean): void => {
                        setCreateMembersAccessModalOpen(false);
                        if (success) {
                            fetchOnboardingStatus();
                            fetchSubscriptionDetails();
                        }
                    }}
                    subscriptionId={subscription?.id || ''}
                />
            )}

            {inviteMemberModalOpen && (
                <InviteMemberModal
                    onComplete={(success: boolean): void => {
                        setInviteMemberModalOpen(false);
                        if (success) {
                            fetchOnboardingStatus();
                            fetchSubscriptionDetails();
                        }
                    }}
                    visible={inviteMemberModalOpen}
                    membershipId={subscription?.membershipId || ''}
                    onboardingStatus={onboardingStatus}
                    subscriptionId={subscription?.id || ''}
                    inviteMemberContext={InviteMemberContext.SubscriptionOnboarding}
                />
            )}

            {editMeetingModalOpen && (
                <EditMeetingModal
                    visible={editMeetingModalOpen}
                    onComplete={onEditMeetingComplete}
                    meeting={getMeetingToEdit()}
                />
            )}

            {editReminderModalOpen && (
                <EditReminderModal
                    visible={editReminderModalOpen}
                    onComplete={onEditReminderComplete}
                    reminder={getReminderToEdit()}
                />
            )}

            {editTaskModalOpen && (
                <EditTaskModal
                    visible={editTaskModalOpen}
                    onComplete={onEditTaskComplete}
                    task={getTaskToEdit()}
                />
            )}

            {createModificationOpportunityModalOpen && (
                <CreateOpportunityModal
                    visible={createModificationOpportunityModalOpen}
                    onComplete={createModificationOpportunityMComplete}
                    createModificationRenewalOpportunity={createModificationOpportunityInfos}
                />
            )}

            {editNoteModalOpen && (
                <EditNoteModal
                    visible={editNoteModalOpen}
                    onComplete={(success: boolean) => {
                        if (success) fetchNote();
                        setEditNoteModalOpen(false);
                    }}
                    entityId={subscriptionId}
                    entityType={NoteEntityTypeEnum.Subscription}
                    note={subscriptionNote}
                />
            )}

            {editDiscountsModalOpen && (
                <EditDiscountsModal
                    visible={editDiscountsModalOpen}
                    onComplete={(success: boolean): void => {
                        setEditDiscountsModalOpen(false);
                        if (success) {
                            fetchSubscriptionDetails();
                            fetchSubscriptionEvents();
                        }
                    }}
                    subscription={subscription}
                />
            )}

            {editSubscriptionSettingsModalOpen && (
                <EditSubscriptionSettingsModal
                    visible={editSubscriptionSettingsModalOpen}
                    onComplete={(success: boolean): void => {
                        setEditSubscriptionSettingsModalOpen(false);
                        if (success) fetchSubscriptionDetails();
                    }}
                    subscription={subscription}
                />
            )}

            {subscription && floorplanModalState.visible && (
                <FloorPlanModal
                    visible={floorplanModalState.visible}
                    onComplete={(): void => {
                        setFloorplanModalState({ visible: false, specificUnitId: undefined });
                    }}
                    subscription={subscription}
                    specificUnitId={floorplanModalState.specificUnitId}
                />
            )}

            {editPaymentSettingModalOpen && (
                <EditPaymentSettingModal
                    visible={editPaymentSettingModalOpen}
                    onComplete={(success: boolean): void => {
                        setEditPaymentSettingModalOpen(false);
                        if (success) fetchSubscriptionDetails();
                    }}
                    subscription={subscription}
                />
            )}
        </div>
    );

    //#endregion
};

export default SubscriptionDetails;
