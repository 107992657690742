import { Button, Form, } from 'antd';
import { CreateOpportunityRequestDto } from 'Api/Features/Opportunities/Dtos/CreateOpportunityRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Plan } from 'Components/icons';
import { useFormValidation, useService, useStores } from 'Hooks';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateOpportunitySchema } from 'Schemas';
import { OpportunityService } from 'Services/OpportunityService';
import OpportunityFormSection from './opportunity-form-section';
import './create-opportunity-for-contact-modal.less';


interface CreateOpportunityForContactModalProps {
    visible: boolean
    onComplete: (success: boolean, id?: string) => void;
    contactId?: string;
    contactName?: string
    membershipId?: string;
    membershipName?: string;
}

const CreateOpportunityForContactModal: FunctionComponent<CreateOpportunityForContactModalProps> = ({
    visible,
    onComplete,
    contactId,
    contactName,
    membershipId,
    membershipName
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(CreateOpportunitySchema, form);
    const { confirmationModalStore, globalLoadingStore, toastStore } = useStores();
    const opportunityService = useService(OpportunityService);

    const dismiss = (success = false, id?: string): void => {
        onComplete(success, id);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Plan />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t('Opportunity.opportunity_confirm_positive'),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };
    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const request: CreateOpportunityRequestDto = {
            ...formValues,
            ownerAccountId: formValues.ownerAccountId?.value,
            contactId,
            membershipId
        };

        if (!(await validateForm(request))) return;

        try {
            globalLoadingStore.addLoading();
            const createdId = await opportunityService.createOpportunity(request);

            toastStore.toast({
                type: 'success',
                messageKey: 'Opportunity.opportunity_create_success',
            });

            dismiss(true, createdId);
        } catch (e) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    useEffect(() => {
        form.setFieldsValue({
            contactConfirm: contactName,
            companyConfirm: membershipName,
            name: `${membershipName} opportunity` 
        })
    },[membershipName, contactName])

    return (
        <BaseModal
            className="FormModal CreateOpportunityForContactModal"
            visible={visible}
            title={t('Opportunity.opportunity_create')}
            onCancel={exit}
        >
            <Form layout="vertical" onFinish={submit} form={form}>

                <OpportunityFormSection
                    errors={errors}
                />

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>
                    <Button type="primary" className="positive" htmlType="submit">
                        {t('submit')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(CreateOpportunityForContactModal);
