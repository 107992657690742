import { Select } from 'antd';
import { FormItemProps } from 'antd/es/form';
import { countries, us_states as usState } from 'appcom-globalization';
import { ValidatedFormItem } from 'Components/validated-form-item';
import React, { FunctionComponent, ReactNode } from 'react';

interface SelectOption {
    id: string;
    name: string;
    order?: number;
}

type SelectType = 'country' | 'state';

const { Option } = Select;

interface GlobalizationSelectProps extends FormItemProps {
    errors?: Map<string, string[]>;
    selectType: SelectType;
    disabled?: boolean;
    defaultValue?: string;
    onChange?: (value: string) => void;
}

const GlobalizationSelect: FunctionComponent<GlobalizationSelectProps> = ({
    name,
    label,
    errors,
    selectType,
    disabled = false,
    defaultValue,
    onChange,
    ...props
}) => {
    const codeOrder = (countryCode: string): number => {
        return countryCode === 'US' ? 0 : 1;
    };

    const sorting = (a: any, b: any): number => {
        return a.order - b.order;
    };

    const list = selectType === 'country' ? countries : usState;

    const options: SelectOption[] = list
        .map(
            (option) =>
                ({
                    id: option.twoLetterCode,
                    name: option.englishName,
                    order: codeOrder(option.twoLetterCode),
                } as SelectOption)
        )
        .sort(sorting);

    if (defaultValue)
        options.unshift({
            id: defaultValue,
            name: defaultValue,
            order: 0,
        } as SelectOption);

    const selectOptions = (options: SelectOption[]): ReactNode => {
        return options.map((option) => (
            <Option key={option.id} value={option.id}>
                {option.name}
            </Option>
        ));
    };

    return (
        <ValidatedFormItem errors={errors} name={name} label={label} {...props}>
            <Select disabled={disabled} defaultValue={defaultValue} onChange={onChange}>
                {selectOptions(options)}
            </Select>
        </ValidatedFormItem>
    );
};

export default GlobalizationSelect;
