import { CampusFloorPlanDto } from 'Api/Features/FloorPlans/Dtos/CampusFloorPlanDto';
import { GetCampusFloorPlanRequestDto } from 'Api/Features/FloorPlans/Dtos/GetCampusFloorPlanRequestDto';
import { UpdateFloorPlanRequestDto } from 'Api/Features/FloorPlans/Dtos/UpdateFloorPlanRequestDto';
import { FloorPlanProxy } from 'Api/Features/FloorPlans/FloorPlanProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(FloorPlanProxy)
export class FloorPlanService extends ApiService {
    constructor(private readonly floorPlanProxy: FloorPlanProxy) {
        super();
    }

    public async getCampusFloorPlan(
        campusId: string,
        request: GetCampusFloorPlanRequestDto | null
    ): Promise<CampusFloorPlanDto | null> {
        const data: CampusFloorPlanDto | null = await this.floorPlanProxy.getCampusFloorPlan(
            campusId,
            request
        );
        return data;
    }

    public async updateFloorPlan(
        campusId: string,
        request: UpdateFloorPlanRequestDto
    ): Promise<void> {
        await this.floorPlanProxy.updateFloorPlan(campusId, request)
    }
}
