import { Button, Col, DatePicker, Form, Input, Row, TimePicker, Typography } from 'antd';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { LeadStatusDto } from 'Api/Features/Leads/Dtos/LeadStatusDto';
import { CreateMeetingRequestDto } from 'Api/Features/Meetings/Dtos/CreateMeetingRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Lead as LeadIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { TIME_PICKER_FORMAT } from 'Models/Constants';
import { toUpdateLeadRequestDto } from 'Models/Leads/DtoOutput';
import { Lead } from 'Models/Leads/Lead';
import { TouchBaseFormValidation } from 'Models/Leads/TouchBase';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { touchBaseStatusSchema } from 'Schemas';
import { LeadService } from 'Services/LeadService';
import { MeetingService } from 'Services/MeetingService';
import './touch-base.less';

const { Title } = Typography;

const formGutter: [Gutter, Gutter] = [40, 0];

interface TouchBaseModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    lead?: Lead;
}

const TouchBaseModal: FunctionComponent<TouchBaseModalProps> = ({ visible, onComplete, lead }) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(touchBaseStatusSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const leadService = useService(LeadService);
    const meetingService = useService(MeetingService);
    //#endregion

    //#region Effects
    //#endregion

    //#region Event handlers
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false, id?: string): void => {
        onComplete(success, id);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <LeadIcon />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`Lead.lead_confirm_positive_edit`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (values: Store): Promise<void> => {
        lead!.status = LeadStatusDto.TouchBase;
        const data = toUpdateLeadRequestDto(lead!);

        const validationForm: TouchBaseFormValidation = {
            meetingDate: values.meetingDate,
            hours: values.hours,
        };

        if (!(await validateForm(validationForm))) return;
        try {
            globalLoadingStore.addLoading();

            let createdId = '';

            if (lead?.id !== undefined) await leadService.updateLead(lead.id!, data);
            else createdId = await leadService.createLead(data);

            const meeting: CreateMeetingRequestDto = {
                startTime: values.hours[0].date(values.meetingDate.date()),
                endTime: values.hours[1].date(values.meetingDate.date()),
                leadId: lead!.id,
                note: values.note,
            };
            if (lead !== undefined) await meetingService.createMeeting(meeting);

            toastStore.toast({
                type: 'success',
                messageKey:
                    lead?.id !== undefined
                        ? `Lead.status_change_success`
                        : 'Lead.lead_create_success',
            });
            dismiss(true, createdId);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Lead.status_TouchBase')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="TouchBase">
                <Title level={4}>{t('Lead.touch_base_title')}</Title>
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="meetingDate"
                                label={t('Lead.meeting_scheduled_date')}
                                required
                            >
                                <DatePicker
                                    className="w-100"
                                    placeholder={t('Lead.meeting_scheduled_date_placeholder')}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="hours"
                                label={t('Lead.meeting_scheduled_time')}
                                required
                            >
                                <TimePicker.RangePicker
                                    format={TIME_PICKER_FORMAT}
                                    use12Hours
                                    style={{ width: '100%' }}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="note"
                                label={t('Lead.lead_add_a_note')}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('Activity.add_activity_Meeting')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(TouchBaseModal);
