import { Form } from 'antd';
import { Store } from 'antd/es/form/interface';
import { StoredFileDto } from 'Api/Features/General/Dtos/StoredFileDto';
import { LeadEventDto } from 'Api/Features/LeadEvents/Dtos/LeadEventDto';
import { LeadEventTypeDto } from 'Api/Features/LeadEvents/Dtos/LeadEventTypeDto';
import { CreateLeadCommentRequestDto } from 'Api/Features/Leads/Dtos/CreateLeadCommentRequestDto';
import { LeadStatusDto } from 'Api/Features/Leads/Dtos/LeadStatusDto';
import { CreateMeetingRequestDto } from 'Api/Features/Meetings/Dtos/CreateMeetingRequestDto';
import { CreateReminderRequestDto } from 'Api/Features/Reminders/Dtos/CreateReminderRequestDto';
import { SalesActivityDto } from 'Api/Features/SalesActivities/Dtos/SalesActivityDto';
import { SalesActivityTypeDto } from 'Api/Features/SalesActivities/Dtos/SalesActivityTypeDto';
import { CreateTaskRequestDto } from 'Api/Features/Tasks/Dtos/CreateTaskRequestDto';
import { CreateTourRequestDto } from 'Api/Features/Tours/Dtos/CreateTourRequestDto';
import { ActivityLogTab } from 'Components/activity-log-tabs/activity-log-tabs';
import ActivityLogWithTabs from 'Components/activity-log-with-tabs/activity-log-with-tabs';
import { ActivityLogItem, ActivityLogUpcomingItem } from 'Components/activity-log/activity-log';
import { useFormValidation, useService, useStores } from 'Hooks';
import { AddActivityType } from 'Models/AddActivityType/AddActivityType';
import {
    DATETIME_FORMAT,
    DISPLAY_DATETIME_12H_WRITTEN_FORMAT,
    DISPLAY_DATE_FORMAT,
    DISPLAY_TIME_12H_FORMAT,
    MOMENT_PARSING_FORMAT,
    MOMENT_TIME_FORMAT,
} from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CreateMeetingSchema, ScheduleTourSchema } from 'Schemas';
import { CreateCommentSchema } from 'Schemas/CreateCommentSchema';
import { CreateReminderSchema } from 'Schemas/CreateReminderSchema';
import { CreateTaskSchema } from 'Schemas/CreateTaskSchema';
import { LeadCommentService } from 'Services/LeadCommentService';
import { MeetingService } from 'Services/MeetingService';
import { ReminderService } from 'Services/ReminderService';
import { TaskService } from 'Services/TaskService';
import { TourService } from 'Services/TourService';
import { displayFormatedDateTime } from 'Utils/TimeUtils';

interface LeadActivityLogProps {
    activities?: LeadEventDto[];
    onAddedActivityComplete: () => void;
    upcomingActivities?: (SalesActivityDto | null)[];
    pastActivities?: (SalesActivityDto | null)[];
    onEditMeetingClick: (id: string) => void;
    onEditReminderClick: (id: string) => void;
    onEditTaskClick: (id: string) => void;
    onDeleteMeetingClick: (id: string) => void;
    onDeleteReminderClick: (id: string) => void;
    onDeleteTaskClick: (id: string) => void;
    onEditTourClick: (id: string) => void;
    onDeleteTourClick: (id: string) => void;
    leadLocationId?: string;
    onAddFileClick: () => void;
    files?: StoredFileDto[];
    onFileClick?: (url?: string) => void;
    onFileDeleteClick?: (id?: string) => void;
    handleFileListScrolledToBottom?: () => void;
    filesAreLoading?: boolean;
    note?: string | null;
    onEditNoteClick?: () => void;
}

const LeadActivityLog: FunctionComponent<LeadActivityLogProps> = ({
    activities,
    onAddedActivityComplete,
    upcomingActivities,
    pastActivities,
    onEditMeetingClick,
    onEditReminderClick,
    onEditTaskClick,
    onDeleteMeetingClick,
    onDeleteReminderClick,
    onDeleteTaskClick,
    onEditTourClick,
    onDeleteTourClick,
    leadLocationId,
    onAddFileClick,
    files,
    onFileClick,
    onFileDeleteClick,
    handleFileListScrolledToBottom,
    filesAreLoading,
    note,
    onEditNoteClick
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [meetingErrors, validateMeetingForm, resetMeetingErrors] = useFormValidation(
        CreateMeetingSchema, form
    );
    const [reminderErrors, validateReminderForm, resetReminderErrors] = useFormValidation(
        CreateReminderSchema, form
    );
    const [taskErrors, validateTaskForm, resetTaskErrors] = useFormValidation(CreateTaskSchema, form);
    const [commentErrors, validateCommentForm, resetCommentErrors] = useFormValidation(CreateCommentSchema, form);
    const [tourErrors, validateTourForm, resetTourErrors] = useFormValidation(ScheduleTourSchema, form);
    const { globalLoadingStore, toastStore } = useStores();
    const [selectedAddActivityType, setSelectedAddActivityType] = useState<AddActivityType>(
        AddActivityType.Comment
    );
    const meetingService = useService(MeetingService);
    const reminderService = useService(ReminderService);
    const taskService = useService(TaskService);
    const commentService = useService(LeadCommentService);
    const tourService = useService(TourService);
    const { locationStore } = useStores();
    const { id } = useParams<{ id: string }>();

    //#Region event handlers
    const onAddActivityTypeChange = (value: AddActivityType): void => {
        setSelectedAddActivityType(value);
        resetMeetingErrors();
        resetReminderErrors();
        resetTaskErrors();
        resetCommentErrors();
        resetTourErrors();
    };
    //#EndRegion event handlers

    //#Region Submit
    const submit = async (values: Store): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            
            let activityType = '';
            if (selectedAddActivityType === AddActivityType.Comment) {
                activityType = 'comment';
                const validateObject = {
                    note: values.note,
                };
                if (!(await validateCommentForm(validateObject))) return;
                await commentService.createComment({comment: values.note} as CreateLeadCommentRequestDto, id);
            } else if (selectedAddActivityType === AddActivityType.Meeting) {
                const validateMeeting = {
                    date: values.date ?? undefined,
                    hours: values.hours ?? undefined,
                };

                if (!(await validateMeetingForm(validateMeeting))) return;
                const meeting: CreateMeetingRequestDto = {
                    startTime: moment(
                        values.date.format(MOMENT_PARSING_FORMAT) +
                            values.hours[0].format(MOMENT_TIME_FORMAT),
                        DATETIME_FORMAT
                    ).format(),
                    endTime: moment(
                        values.date.format(MOMENT_PARSING_FORMAT) +
                            values.hours[1].format(MOMENT_TIME_FORMAT),
                        DATETIME_FORMAT
                    ).format(),
                    leadId: id,
                    note: values.note,
                };
                activityType = 'meeting';
                await meetingService.createMeeting(meeting);
            } else if (selectedAddActivityType === AddActivityType.Reminder) {
                const validateReminder = {
                    date: values.date ?? undefined,
                };
                if (!(await validateReminderForm(validateReminder))) return;

                const reminder: CreateReminderRequestDto = {
                    date: values.date,
                    leadId: id,
                    note: values.note,
                };
                activityType = 'reminder';
                await reminderService.createReminder(reminder);
            } else if (selectedAddActivityType === AddActivityType.Task) {
                const validateTask = { date: values.date ?? undefined, note: values.note };
                if (!(await validateTaskForm(validateTask))) return;

                const task: CreateTaskRequestDto = {
                    date: values.date,
                    leadId: id,
                    note: values.note,
                };
                activityType = 'task';
                await taskService.createTask(task);
            } else if (selectedAddActivityType === AddActivityType.Tour) {
                const data = {
                    date: values.date ?? undefined,
                    hours: values.hours ?? undefined,
                    note: values.note,
                    leadId: id,
                    campusId: values.campusId,
                };
                const validateTour = data;
                if (!(await validateTourForm(validateTour))) return;

                const tour: CreateTourRequestDto = {
                    ...data,
                    startTime: moment(
                        values.date.format(MOMENT_PARSING_FORMAT) +
                            values.hours[0].format(MOMENT_TIME_FORMAT),
                        DATETIME_FORMAT
                    ).format(),
                    endTime: moment(
                        values.date.format(MOMENT_PARSING_FORMAT) +
                            values.hours[1].format(MOMENT_TIME_FORMAT),
                        DATETIME_FORMAT
                    ).format(),
                };
                activityType = 'tour';
                await tourService.createTour(tour);
            }
            onAddedActivityComplete();
            resetMeetingErrors();
            resetReminderErrors();
            resetTaskErrors();
            resetCommentErrors();
            resetTourErrors();
            setSelectedAddActivityType(AddActivityType.Comment);
            form.resetFields();

            toastStore.toast({
                type: 'success',
                messageKey: `Lead.${activityType}_added_success`,
            });
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#End Region Submit

    const submitButtonLabel = (selectedAddActivityType: AddActivityType): string => {
        switch (selectedAddActivityType) {
            case AddActivityType.Comment:
                return t('Lead.add_comment');
            case AddActivityType.Meeting:
                return t('Lead.add_meeting');
            case AddActivityType.Task:
                return t('Lead.add_task');
            case AddActivityType.Reminder:
                return t('Lead.add_reminder');
            case AddActivityType.Tour:
                return t('Lead.add_tour');
        }
    };

    const displayDatePicker =
        selectedAddActivityType === AddActivityType.Meeting ||
        selectedAddActivityType === AddActivityType.Reminder ||
        selectedAddActivityType === AddActivityType.Task ||
        selectedAddActivityType === AddActivityType.Tour;

    const getUpcomingActivities = (
        upcomingActivities: (SalesActivityDto | null)[] | undefined
    ): (ActivityLogUpcomingItem | null)[] => {
        let upcomingActivitiesList: (ActivityLogUpcomingItem | null)[] = [];

        if (upcomingActivities) {
            upcomingActivitiesList = upcomingActivities.map((event: SalesActivityDto | null) => {
                let eventInfo = null;
                if (event) {
                    switch (event.type) {
                        case SalesActivityTypeDto.Meeting:
                            eventInfo = {
                                icon: { name: 'Coffee', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Lead.event_${LeadEventTypeDto.MeetingScheduled}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.meeting?.startTime)
                                            .format(
                                                DISPLAY_DATETIME_12H_WRITTEN_FORMAT
                                            )} - ${moment
                                            .utc(event.meeting?.endTime)
                                            .format(DISPLAY_TIME_12H_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditMeetingClick,
                                onDelete: onDeleteMeetingClick,
                                id: event.meeting?.id ?? '',
                                note: event.meeting?.note,
                            };
                            break;
                        case SalesActivityTypeDto.Reminder:
                            eventInfo = {
                                icon: { name: 'Bell', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Lead.event_${LeadEventTypeDto.ReminderScheduled}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.reminder?.date)
                                            .format(DISPLAY_DATETIME_12H_WRITTEN_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditReminderClick,
                                onDelete: onDeleteReminderClick,
                                id: event.reminder?.id ?? '',
                                note: event.reminder?.note,
                            };
                            break;
                        case SalesActivityTypeDto.Task:
                            eventInfo = {
                                icon: { name: 'Tick', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(`Lead.event_${LeadEventTypeDto.TaskAdded}`)}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.task?.date)
                                            .format(DISPLAY_DATE_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditTaskClick,
                                onDelete: onDeleteTaskClick,
                                id: event.task?.id ?? '',
                                note: event.task?.note,
                            };
                            break;
                        case SalesActivityTypeDto.Tour:
                            eventInfo = {
                                icon: { name: 'Location', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Lead.event_${LeadEventTypeDto.TourScheduled}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: event.tour?.campus?.name
                                            ? t('Activity.tour_at', {
                                                  param1: event.tour?.campus?.name,
                                              })
                                            : '',
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment.utc(
                                            event.tour?.startTime
                                        ).format(DISPLAY_DATETIME_12H_WRITTEN_FORMAT)} - ${moment.utc(
                                            event.tour?.endTime
                                        ).format(DISPLAY_TIME_12H_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditTourClick,
                                onDelete: onDeleteTourClick,
                                id: event.tour?.id ?? '',
                                note: event.tour?.note,
                            };
                            break;
                    }
                }

                return eventInfo;
            });
        }

        return upcomingActivitiesList;
    };

    const getPastActivities = (
        pastActivities: (SalesActivityDto | null)[] | undefined
    ): (ActivityLogUpcomingItem | null)[] => {
        let pastActivitiesList: (ActivityLogUpcomingItem | null)[] = [];

        if (pastActivities) {
            pastActivitiesList = pastActivities.map((event: SalesActivityDto | null) => {
                let eventInfo = null;

                if (event) {
                    switch (event.type) {
                        case SalesActivityTypeDto.Meeting:
                            eventInfo = {
                                icon: { name: 'Coffee', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Lead.event_${LeadEventTypeDto.MeetingScheduled}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.meeting?.startTime)
                                            .format(
                                                DISPLAY_DATETIME_12H_WRITTEN_FORMAT
                                            )} - ${moment
                                            .utc(event.meeting?.endTime)
                                            .format(DISPLAY_TIME_12H_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditMeetingClick,
                                onDelete: onDeleteMeetingClick,
                                id: event.meeting?.id ?? '',
                                note: event.meeting?.note ?? '',
                            };
                            break;
                        case SalesActivityTypeDto.Reminder:
                            eventInfo = {
                                icon: { name: 'Bell', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Lead.event_${LeadEventTypeDto.ReminderScheduled}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.reminder?.date)
                                            .format(DISPLAY_DATETIME_12H_WRITTEN_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditReminderClick,
                                onDelete: onDeleteReminderClick,
                                id: event.reminder?.id ?? '',
                                note: event.reminder?.note ?? '',
                            };
                            break;
                        case SalesActivityTypeDto.Tour:
                            eventInfo = {
                                icon: { name: 'Location', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Lead.event_${LeadEventTypeDto.TourScheduled}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: event.tour?.campus?.name
                                            ? t('Activity.tour_at', {
                                                  param1: event.tour?.campus?.name,
                                              })
                                            : '',
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment.utc(
                                            event.tour?.startTime
                                        ).format(DISPLAY_DATETIME_12H_WRITTEN_FORMAT)} - ${moment.utc(
                                            event.tour?.endTime
                                        ).format(DISPLAY_TIME_12H_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditTourClick,
                                onDelete: onDeleteTourClick,
                                id: event.tour?.id ?? '',
                                note: event.tour?.note,
                            };
                            break;
                        case SalesActivityTypeDto.Task:
                            eventInfo = {
                                icon: { name: 'Tick', className: 'primary-fill' },
                                title: [
                                    {
                                        text: `${t(`Lead.event_${LeadEventTypeDto.TaskAdded}`)}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.task?.date)
                                            .format(DISPLAY_DATE_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditTaskClick,
                                onDelete: onDeleteTaskClick,
                                id: event.task?.id ?? '',
                                note: event.task?.note ?? undefined,
                            };
                            break;
                    }
                }

                return eventInfo;
            });
        }

        return pastActivitiesList;
    };

    const getHistoryList = (history: LeadEventDto[] | undefined): (ActivityLogItem | null)[] => {
        let historyList: (ActivityLogItem | null)[] = [];

        if (history) {
            historyList = history.map((event: LeadEventDto) => {
                let eventInfo = null;

                if (event && event.id) {
                    const creator =
                        event.userCreated === null || event.userCreated?.id === null
                            ? 'Unknown'
                            : `${event.userCreated?.firstName} ${event.userCreated?.lastName}`;

                    eventInfo = {
                        id: event.id,
                        icon: { name: '', bold: undefined },
                        title: [
                            { text: creator, bold: true },
                            { text: t(`Lead.event_text_spacer_${event.type}`) },
                            { text: t(`Lead.event_${event.type}`), bold: true },
                        ],
                        date: displayFormatedDateTime(event.date),
                        note: event.note,
                        creator: creator,
                    };

                    switch (event.type) {
                        case LeadEventTypeDto.Created:
                            eventInfo = {
                                ...eventInfo,
                                icon: { name: 'Lead', primary: true },
                            };
                            break;
                        case LeadEventTypeDto.StatusUpdated:
                            eventInfo = {
                                ...eventInfo,
                                icon: {
                                    name:
                                        event.newStatus && event.newStatus === LeadStatusDto.Lost
                                            ? 'WarningLined'
                                            : 'Refresh',
                                    primary: true,
                                },
                                title: [
                                    { text: creator, bold: true },
                                    { text: t(`Lead.event_${event.type}`) },
                                    {
                                        text:
                                            event.newStatus && t(`Lead.status_${event.newStatus}`),
                                        bold: true,
                                    },
                                ],
                            };
                            break;
                        case LeadEventTypeDto.OwnerUpdated:
                            eventInfo = {
                                ...eventInfo,
                                icon: { name: 'Owner' },
                                title: [
                                    { text: creator, bold: true },
                                    { text: t(`Lead.event_${event.type}`) },
                                    {
                                        text:
                                            event.newOwnerAccount &&
                                            `${event.newOwnerAccount.firstName} ${event.newOwnerAccount.lastName}`,
                                        bold: true,
                                    },
                                ],
                            };
                            break;
                        case LeadEventTypeDto.CommentAdded:
                            eventInfo = {
                                ...eventInfo,
                                note: event.note,
                                icon: { name: 'File' },
                            };
                            break;
                        case LeadEventTypeDto.TaskAdded:
                        case LeadEventTypeDto.TaskUpdated:
                        case LeadEventTypeDto.TaskCancelled:
                            eventInfo = {
                                ...eventInfo,
                                icon: { name: 'Tick' },
                                note: event.task,
                            };
                            break;
                        case LeadEventTypeDto.LabelUpdated:
                            eventInfo = {
                                ...eventInfo,
                                icon: { name: 'Thermometer' },
                                title: [
                                    { text: creator, bold: true },
                                    { text: t(`Lead.event_${event.type}`) },
                                    {
                                        text: event.newLabel && t(`Lead.label_${event.newLabel}`),
                                        bold: true,
                                    },
                                ],
                            };
                            break;
                        case LeadEventTypeDto.MeetingScheduled:
                        case LeadEventTypeDto.MeetingUpdated:
                        case LeadEventTypeDto.MeetingCancelled:
                            eventInfo = {
                                ...eventInfo,
                                icon: { name: 'Coffee' },
                                title: [
                                    { text: creator, bold: true },
                                    { text: t(`Lead.event_text_spacer_${event.type}`) },
                                    { text: t(`Lead.event_${event.type}`), bold: true },
                                    {
                                        text: t('Opportunity.tour_for', {
                                            param1: moment
                                                .utc(event.meetingStartTime)
                                                .format(DISPLAY_DATETIME_12H_WRITTEN_FORMAT),
                                            param2: moment
                                                .utc(event.meetingEndTime)
                                                .format(DISPLAY_TIME_12H_FORMAT),
                                        }),
                                    },
                                ],
                            };
                            break;
                        case LeadEventTypeDto.ReminderScheduled:
                        case LeadEventTypeDto.ReminderUpdated:
                        case LeadEventTypeDto.ReminderCancelled:
                            eventInfo = {
                                ...eventInfo,
                                icon: { name: 'Bell' },
                                title: [
                                    { text: creator, bold: true },
                                    { text: t(`Lead.event_text_spacer_${event.type}`) },
                                    { text: t(`Lead.event_${event.type}`), bold: true },
                                    {
                                        text: `${t('Activity.add_activity_for')} ${moment
                                            .utc(event.reminderDate)
                                            .format(DISPLAY_DATETIME_12H_WRITTEN_FORMAT)}`,
                                    },
                                ],
                            };
                            break;
                        case LeadEventTypeDto.TourUpdated:
                        case LeadEventTypeDto.TourScheduled:
                        case LeadEventTypeDto.TourCancelled:
                            eventInfo = {
                                ...eventInfo,
                                icon: { name: 'Location' },
                                title: [
                                    { text: creator, bold: true },
                                    { text: t(`Lead.event_text_spacer_${event.type}`) },
                                    { text: t(`Lead.event_${event.type}`), bold: true },
                                    {
                                        text: locationStore.locations.find(
                                            (location) => location.id === event.tourCampusId
                                        )
                                            ? t('Activity.tour_at', {
                                                  param1: locationStore.locations.find(
                                                      (location) =>
                                                          location.id === event.tourCampusId
                                                  )?.name,
                                              })
                                            : '',
                                    },
                                    {
                                        text: t('Opportunity.tour_for', {
                                            param1: moment.utc(event.tourStartTime).format(
                                                DISPLAY_DATETIME_12H_WRITTEN_FORMAT
                                            ),
                                            param2: moment.utc(event.tourEndTime).format(
                                                DISPLAY_TIME_12H_FORMAT
                                            ),
                                        }),
                                    },
                                ],
                            };
                            break;
                    }
                }

                return eventInfo;
            });
        }

        return historyList;
    };

    return (
        <ActivityLogWithTabs
            onFinish={submit}
            form={form}
            selectErrors={meetingErrors}
            selectdefaultValue={AddActivityType.Comment}
            selectOnChange={onAddActivityTypeChange}
            selectOptions={AddActivityType}
            displayDatePicker={displayDatePicker}
            datePickerErrors={
                selectedAddActivityType === AddActivityType.Meeting
                    ? meetingErrors
                    : selectedAddActivityType === AddActivityType.Reminder
                    ? reminderErrors
                    : selectedAddActivityType === AddActivityType.Tour
                    ? tourErrors
                    : taskErrors
            }
            noteErrors={
                selectedAddActivityType === AddActivityType.Task ? taskErrors : commentErrors
            }
            submitButtonLabel={submitButtonLabel(selectedAddActivityType)}
            upcomingActivities={getUpcomingActivities(upcomingActivities)}
            pastActivities={getPastActivities(pastActivities)}
            historyList={getHistoryList(activities)}
            categoryName={t('Lead.lead_title')}
            locationErrors={tourErrors}
            defaultSelectedLocationId={leadLocationId}
            onAddFileClick={onAddFileClick}
            files={files}
            onFileClick={onFileClick}
            onFileDeleteClick={onFileDeleteClick}
            onFilesScrolledToBottom={handleFileListScrolledToBottom}
            tabs={[ActivityLogTab.activities, ActivityLogTab.files, ActivityLogTab.notes]}
            filesAreLoading={filesAreLoading}
            onEditNoteClick={onEditNoteClick}
            note={note}
        />
    );
};

export default LeadActivityLog;
