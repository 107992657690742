import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ZoomIn: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => (
    <span role="img" className="anticon">
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.73168 12.2301C3.5859 12.2301 1.03576 9.71998 1.03576 6.6247C1.03576 3.52893 3.5859 1.0193 6.73168 1.0193C9.87747 1.0193 12.4281 3.52893 12.4281 6.6247C12.4281 9.71998 9.87747 12.2301 6.73168 12.2301ZM15.8485 15.1303L11.7174 11.0648C12.7987 9.88902 13.4634 8.33513 13.4634 6.6247C13.4634 2.96629 10.4496 4.57764e-05 6.73168 4.57764e-05C3.01375 4.57764e-05 0 2.96629 0 6.6247C0 10.2831 3.01375 13.2489 6.73168 13.2489C8.33809 13.2489 9.81145 12.6937 10.9687 11.7695L15.1158 15.8505C15.3183 16.0501 15.6459 16.0501 15.8485 15.8505C16.0505 15.652 16.0505 15.3289 15.8485 15.1303Z"
                fill={fill || 'currentColor'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5026 6.25156V7.25181H3.00075V6.25156H10.5026Z"
                fill={fill || 'currentColor'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.25181 10.5026H6.25156V3.00075H7.25181V10.5026Z"
                fill={fill || 'currentColor'}
            />
        </svg>
    </span>
);

export default ZoomIn;
