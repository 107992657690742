import { Button, Form } from 'antd';
import BaseModal from 'Components/base-modal/base-modal';
import Icon from 'Components/icons/icon';
import { useFormValidation, useService, useStores } from 'Hooks';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CompanyFormSection from 'Routes/authenticated/opportunities/company-form-section';
import './create-company.less';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { CompanyRadioButtonValues } from 'Routes/authenticated/contacts/modals/create-contact';
import { CreateNewCompanyContactSchema } from 'Schemas/CreateContactSchema';
import { MembershipService } from 'Services/MembershipService';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { CreateUpdateAddressRequestDto } from 'Api/Features/General/Dtos/CreateUpdateAddressRequestDto';
import { dateMomentToString } from 'Utils/TimeUtils';
import { LightContactInfoDto } from 'Api/Features/General/Dtos/LightContactInfoDto';
import { MembershipDetailsPlusDto } from 'Api/Features/Memberships/Dtos/MembershipDetailsPlusDto';
import './edit-company.less';
import moment from 'moment';
import { UpdateMembershipRequestDto } from 'Api/Features/Memberships/Dtos/UpdateMembershipRequestDto';

interface EditCompanyProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    membership?: MembershipDetailsPlusDto
}

const EditCompany: FunctionComponent<EditCompanyProps> = ({ visible, onComplete, membership }) => {
    const membershipService = useService(MembershipService);
    const { confirmationModalStore, globalLoadingStore, toastStore } = useStores();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(CreateNewCompanyContactSchema, form);
    const { t } = useTranslation();

    const [companyImageDetails, setCompanyImageDetails] = useState<ImageDetails[]>();

    useEffect(() => {
        form.setFieldsValue({
            'membership.name': membership?.name,
            'membership.billingName': membership?.billingName,
            'membership.billingEmail': membership?.billingEmail ?? undefined,
            'membership.presentation': membership?.presentation,
            'membership.numberOfEmployees': membership?.numberOfEmployees,
            'membership.industry': membership?.industry,
            'membership.locations': membership?.campuses ? membership?.campuses?.map(x => ({ value: x?.id, label: x?.name })) : undefined,
            'membership.expertises': membership?.expertises ? membership?.expertises?.map(x => ({ value: x?.id, label: x?.description })) : undefined,
            'membership.address.addressLine1': membership?.address?.addressLine1,
            'membership.address.city': membership?.address?.city,
            'membership.address.country': membership?.address?.country,
            'membership.address.postalCode': membership?.address?.postalCode,
            'membership.address.state': membership?.address?.state,
            'membership.legalContact.firstName': membership?.legalContact?.firstName,
            'membership.legalContact.lastName': membership?.legalContact?.lastName,
            'membership.legalContact.phone': membership?.legalContact?.phone,
            'membership.legalContact.email': membership?.legalContact?.email ?? undefined,
            'membership.legalContact.birthDate': membership?.legalContact?.birthDate ? moment(membership.legalContact.birthDate) : undefined,
            'membership.legalContact.addressLine1': membership?.legalContact?.address?.addressLine1,
            'membership.legalContact.city': membership?.legalContact?.address?.city,
            'membership.legalContact.country': membership?.legalContact?.address?.country,
            'membership.legalContact.postalCode': membership?.legalContact?.address?.postalCode,
            'membership.legalContact.state': membership?.legalContact?.address?.state,
            'membership.contactInfo.website': membership?.contactInfo?.website,
            'membership.contactInfo.email': membership?.contactInfo?.email ?? undefined,
            'membership.contactInfo.phone1': membership?.contactInfo?.phone1,
            'membership.contactInfo.angelListUrl': membership?.contactInfo?.angelListUrl,
            'membership.contactInfo.instagramUrl': membership?.contactInfo?.instagramUrl,
            'membership.contactInfo.linkedInUrl': membership?.contactInfo?.linkedInUrl,
            'membership.contactInfo.twitterUrl': membership?.contactInfo?.twitterUrl,
            'membership.contactInfo.facebookUrl': membership?.contactInfo?.facebookUrl,
        })
    }, [membership])

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Company" />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t('Membership.company_leave_confirmation', {param1: 'edition'}),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const validateAndCreateMembershipRequestObject = async (): Promise<{isValid: boolean, requestObject?: UpdateMembershipRequestDto}> => {
        const formValues = form.getFieldsValue();
        const validateObject = {
            name: formValues['membership.name'],
            billingName: formValues['membership.billingName'],
            billingEmail: formValues['membership.billingEmail'],
            presentation: formValues['membership.presentation'],
            numberOfEmployees: formValues['membership.numberOfEmployees'],
            industry: formValues['membership.industry'],
            image: companyImageDetails
                ? ({
                      delete: companyImageDetails[0].isDeleted,
                      uploadBase64: companyImageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null,
            baseCampusIds: formValues['membership.locations']?.map(
                (location: { value: string; label: string }) => location.value
            ),
            expertiseIds:
                formValues['membership.expertises'] === ''
                    ? null
                    : formValues['membership.expertises']?.map(
                          (expertise: { value: string; label: string }) => expertise.value
                      ),
            address: {
                addressLine1: formValues['membership.address.addressLine1'],
                city: formValues['membership.address.city'],
                country: formValues['membership.address.country'],
                postalCode: formValues['membership.address.postalCode'],
                state: formValues['membership.address.state'],
            } as CreateUpdateAddressRequestDto,
            legalContact: {
                firstName: formValues['membership.legalContact.firstName'],
                lastName: formValues['membership.legalContact.lastName'],
                phone: formValues['membership.legalContact.phone'],
                email: formValues['membership.legalContact.email'],
                birthDate: dateMomentToString(formValues['membership.legalContact.birthDate']),
                addressLine1: formValues['membership.legalContact.addressLine1'],
                city: formValues['membership.legalContact.city'],
                country: formValues['membership.legalContact.country'],
                postalCode: formValues['membership.legalContact.postalCode'],
                state: formValues['membership.legalContact.state'],
            },
            contactInfo: {
                website: formValues['membership.contactInfo.website'],
                email: formValues['membership.contactInfo.email'],
                phone1: formValues['membership.contactInfo.phone1'],
                angelListUrl: formValues['membership.contactInfo.angelListUrl'],
                instagramUrl: formValues['membership.contactInfo.instagramUrl'],
                linkedInUrl: formValues['membership.contactInfo.linkedInUrl'],
                twitterUrl: formValues['membership.contactInfo.twitterUrl'],
                facebookUrl: formValues['membership.contactInfo.facebookUrl'],
            } as LightContactInfoDto,
        };
        
        if (!(await validateForm({membership: validateObject}))) return { isValid: false };

        const legalContactAddress: CreateUpdateAddressRequestDto = {
            addressLine1: validateObject.legalContact.addressLine1,
            city: validateObject.legalContact.city,
            country: validateObject.legalContact.country,
            postalCode: validateObject.legalContact.postalCode,
            state: validateObject.legalContact.state,
        };

        const request: UpdateMembershipRequestDto = {
            ...validateObject,
            legalContact: { ...validateObject.legalContact, address: legalContactAddress },
            id: membership?.id
        };

        return {isValid: true, requestObject: request}
    }

    const submit = async (): Promise<void> => {
        const membershipValidation = await validateAndCreateMembershipRequestObject();
        
        if (!membershipValidation.isValid) return;

        const request: UpdateMembershipRequestDto = {
            ...membershipValidation.requestObject,
        }

        try {
            globalLoadingStore.addLoading();
            await membershipService.updateMembership(membership?.id!, request);

            toastStore.toast({
                type: 'success',
                messageKey: t('Membership.membership_edit_success'),
            });
            dismiss(true);
        }
        catch (e) {
            if(e.response?.data.error === 'E004017') {
                toastStore.toast({
                    type: 'error',
                    message: e.response.data.error_description
                })
            }
            else if (!e.treated) {
                toastStore.serverError();
            } 
        }
        finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <BaseModal
            className="FormModal EditCompany"
            visible={visible}
            title={t(`Membership.edit_membership`)}
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <CompanyFormSection
                    radioButtonValue={CompanyRadioButtonValues.New}
                    errors={errors}
                    setImageDetails={setCompanyImageDetails}
                    membership={membership}
                />

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>
                    <Button type="primary" className="positive" htmlType="submit">
                        {t('submit')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(EditCompany);
