// GENERATED FILE - DO NOT MODIFY

export enum GetUnitsSortColumnDto {
    Order = "Order",
    Name = "Name",
    Type = "Type",
    Capacity = "Capacity",
    PriceOverride = "PriceOverride",
    DataJacks = "DataJacks",
    ActiveStatus = "ActiveStatus",
}