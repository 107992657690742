import { Col, DatePicker, Input, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { ValidatedFormItem } from 'Components/validated-form-item';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

const formGutter: [Gutter, Gutter] = [40, 0];

const SetOccupancyObjectiveFormSection: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <>
            <Row gutter={formGutter}>
                <Col span={12}>
                    <ValidatedFormItem label={t('occupancy')} name={'rate'}>
                        <NumberFormat
                            allowNegative={false}
                            isAllowed={(value: NumberFormatValues): boolean =>
                                value.floatValue === undefined ||
                                (value.floatValue <= 100 && value.floatValue >= 0)
                            }
                            customInput={(props: any) => <Input addonBefore={'%'} {...props} />}
                        />
                    </ValidatedFormItem>
                </Col>

                <Col span={12}>
                    <ValidatedFormItem name={'dueDate'} label={t('Reports.due_date')}>
                        <DatePicker className="clearable" style={{ width: '100%' }} allowClear />
                    </ValidatedFormItem>
                </Col>
            </Row>
        </>
    );
};

export default React.memo(SetOccupancyObjectiveFormSection);
