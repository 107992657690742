import { SubscriptionCampusDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionCampusDto';
import { SubscriptionDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDto';
import { SubscriptionMembershipDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionMembershipDto';
import { SubscriptionPeriodDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionPeriodDto';
import { SubscriptionPlanDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionPlanDto';

export class Subscription implements SubscriptionDto {
    constructor(dto: SubscriptionDto) {
        Object.assign(this, dto);
    }

    id?: string | null;
    campus?: SubscriptionCampusDto | null;
    membership?: SubscriptionMembershipDto | null;
    plan?: SubscriptionPlanDto | null;
    referenceNumber?: string | null;
    startDate?: string;
    endDate?: string | null;
    periods?: (SubscriptionPeriodDto | null)[] | null;
    isActive?: boolean;
    isUpcoming?: boolean;
    isExpired?: boolean;
    isCancelled?: boolean;
    expiresSoon?: boolean;
    isChargedAutomatically?: boolean;
    isCheckPayable?: boolean;
    isOnboardingInProgress?: boolean;
    isOnboardingCompleted?: boolean;
    onboardingCompletedDate?: string | null;
    agreementSignedDate?: string | null;
    isModificationInProgress?: boolean;
    isRenewalInProgress?: boolean;
}
