import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Calendar: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.965 23.025h20.026V7.102H1.965v15.923zm0-20.334h4.967V3.93a.463.463 0 10.926 0V2.691h8.183V3.93a.464.464 0 00.925 0V2.691h5.025v3.486H1.965V2.691zm20.488-.925h-5.487V.463a.463.463 0 10-.925 0v1.303H7.858V.463a.464.464 0 00-.926 0v1.303h-5.43a.462.462 0 00-.462.462v21.259c0 .257.207.463.462.463h20.951a.463.463 0 00.463-.463V2.227a.462.462 0 00-.463-.46z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.832 13.025h2.1v-2.1h-2.1v2.1zm2.563.925H4.37a.463.463 0 01-.463-.462v-3.025c0-.256.207-.463.463-.463h3.025c.255 0 .463.207.463.463v3.025a.463.463 0 01-.463.463zM10.928 13.025h2.1v-2.1h-2.1v2.1zm2.562.925h-3.025a.463.463 0 01-.462-.462v-3.025c0-.256.207-.463.462-.463h3.025c.256 0 .463.207.463.463v3.025a.463.463 0 01-.463.463zM16.967 13.025h2.1v-2.1h-2.1v2.1zm2.562.925h-3.025a.463.463 0 01-.463-.462v-3.025c0-.256.208-.463.463-.463h3.025c.256 0 .463.207.463.463v3.025a.463.463 0 01-.463.463zM4.832 19.201h2.1v-2.1h-2.1v2.1zm2.563.926H4.37a.463.463 0 01-.463-.463v-3.025c0-.256.207-.463.463-.463h3.025c.255 0 .463.207.463.463v3.025a.463.463 0 01-.463.463zM10.928 19.201h2.1v-2.1h-2.1v2.1zm2.562.926h-3.025a.463.463 0 01-.462-.463v-3.025c0-.256.207-.463.462-.463h3.025c.256 0 .463.207.463.463v3.025a.463.463 0 01-.463.463zM16.967 19.201h2.1v-2.1h-2.1v2.1zm2.562.926h-3.025a.463.463 0 01-.463-.463v-3.025c0-.256.208-.463.463-.463h3.025c.256 0 .463.207.463.463v3.025a.463.463 0 01-.463.463z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Calendar;
