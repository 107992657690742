import { action, observable } from 'mobx';

class AuthorizationStore {
    @observable currentRequestForbidden = false;
    @observable userIsNotManager = false;
    @observable currentRequestNotFound = false;

    @action
    handleErrorCode(statusCode: number): void {
        if (statusCode === 403) {
            this.currentRequestForbidden = true;
        }
        if (statusCode === 404) {
            this.currentRequestNotFound = true
        }
    }

    @action
    resetForbidden(): void {
        this.currentRequestForbidden = false;
    }

    @action
    resetNotFound(): void {
        this.currentRequestNotFound = false;
    }

    @action
    handleUserNotManager(): void {
        this.userIsNotManager = true;
    }

    @action
    resetUserIsNotManager(): void {
        this.userIsNotManager = false;
    }
}
export default AuthorizationStore;
