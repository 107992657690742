import React, { FunctionComponent } from 'react';
import { Select } from 'antd';
import { ActivitySource } from 'Models/ActivityBoard/ActivitySource';
import { ActivitySourceFilter } from 'Models/Filters/ActivitySourceFilter';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface ActivitySourceSelectProps {
    onSourceChange: (source: ActivitySource) => void;
    value?: ActivitySource | undefined;
}

const ActivitySourceSelect: FunctionComponent<ActivitySourceSelectProps> = ({
    onSourceChange,
    value,
}) => {
    const { t } = useTranslation();
    const filters: ActivitySourceFilter[] = Object.keys(ActivitySource).map((source) => ({
        id: source,
        name: t(`ActivityBoard.source_filter_${source}`),
    }));
    
    return (
        <Select
            onChange={onSourceChange}
            style={{ width: '100%' }}
            value={value}
        >
            {filters.map((filter) => (
                <Option key={filter.id} value={filter.id}>
                    {filter.name}
                </Option>
            ))}
        </Select>
    );
};

export default ActivitySourceSelect;
