import { Button, Form, Switch } from 'antd';
import BaseModal from 'Components/base-modal/base-modal';
import { Lead as LeadIcon } from 'Components/icons';
import { useService, useStores } from 'Hooks';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MembershipRoleDto } from 'Api/Features/Memberships/Dtos/MembershipRoleDto';
import './edit-role-modal.less';
import { MembershipService } from 'Services/MembershipService';
import { UpdateMembershipMemberRequestDto } from 'Api/Features/Memberships/Dtos/UpdateMembershipMemberRequestDto';
import { UpdateEmailInvitationRolesRequestDto } from 'Api/Features/Memberships/Dtos/UpdateEmailInvitationRolesRequestDto';

interface EditRoleModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    memberRoles?: MembershipRoleDto[];
    accountId?: string | null;
    membershipId: string;
    emailInvitationId?: string | null;
}

interface ToggleStates {
    [role: string]: {checked: boolean}
}

const EditRoleModal: FunctionComponent<EditRoleModalProps> = ({
    visible,
    onComplete,
    memberRoles,
    accountId,
    membershipId,
    emailInvitationId,
}) => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const membershipService = useService(MembershipService);
    const [form] = Form.useForm();

    const [toggleStates, setToggleStates] = useState<ToggleStates>({});

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <LeadIcon />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t('model_confirm_positive_edit', {param1: 'role'}),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        globalLoadingStore.addLoading();

        const checkedRoles = Object.keys(toggleStates).filter((key) => toggleStates[key].checked);

        try {
            if (emailInvitationId) {
                const request: UpdateEmailInvitationRolesRequestDto = {
                    roles: checkedRoles,
                };
                await membershipService.updateMembershipEmailInvitationRoles(
                    emailInvitationId,
                    request
                );
            } else {
                const request: UpdateMembershipMemberRequestDto = {
                    accountId: accountId,
                    membershipId: membershipId,
                    roles: checkedRoles,
                };
                await membershipService.updateMembershipMemberRoles(request);
            }

            toastStore.toast({
                type: 'success',
                messageKey: `Membership.roles_edited_successfully`,
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    useEffect(() => {
        //ToggleStates Type
        const rolesMap: Map<string, { checked: boolean }> = new Map(
            pertinentRoles().map((role) => [
                role,
                { checked: memberRoles?.some((memberRole) => memberRole === role) ?? false },
            ])
        );

        setToggleStates(Object.fromEntries(rolesMap));
    }, [memberRoles]);

    const pertinentRoles = useCallback(() => {
        return Object.keys(MembershipRoleDto).filter(
            (role) =>
                role !== MembershipRoleDto.PendingMember &&
                role !== MembershipRoleDto.Owner &&
                role !== MembershipRoleDto.Member
        );
    }, [MembershipRoleDto]);

    const handleToggleClick = (checked: boolean, role: string) => {
        let newState = { ...toggleStates };

        if (role === MembershipRoleDto.Administrator && checked) {
            //if admin is checked, uncheck all other roles
            const rolesMap: Map<string, { checked: boolean }> = new Map(
                pertinentRoles().map((role) => [role, { checked: false }])
            );

            newState = Object.fromEntries(rolesMap);
        }

        newState[role].checked = checked;
        setToggleStates(newState);
    };

    //#endregion
    return (
        <BaseModal visible={visible} title={t('edit_roles')} className="FormModal" onCancel={exit}>
            <div className="EditRoleModal">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    {pertinentRoles().map((role) => (
                        <div key={role} className="role-container">
                            <div className="info">
                                <div className="title">
                                    {t(`Membership.MembershipRoleDto_${role}`)}
                                </div>
                                <div className="description">
                                    {t(`Membership.description_MembershipRoleDto_${role}`)}
                                </div>
                            </div>
                            <div className="toggle">
                                <Switch
                                    checked={toggleStates[role]?.checked}
                                    onChange={(value) => handleToggleClick(value, role)}
                                />
                            </div>
                        </div>
                    ))}

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
};

export default EditRoleModal;
