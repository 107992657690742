import { AdvancedFilter } from "Models/Filters/AdvancedFilter";
import { FilterStore } from "Stores";

export const getAdvancedTableFiltersState = (filterStore: FilterStore, filterKey: string): {
    checkedFilters: any[];
    filterCount: number;
} => {
    const filters = filterStore.advancedFilters?.find(
        (filter: AdvancedFilter) => filter.key === filterKey
    );
    const checkedFilters = filters?.items
        .filter((item) => item.checked)
        .map((item) => {
            return item.key;
        });
    return {
        checkedFilters: checkedFilters ?? [],
        filterCount: filters?.items.length ?? 0,
    };
};