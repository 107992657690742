import { Col, Collapse, Divider, Row } from 'antd';
import { InvoiceItemDto } from 'Api/Features/Invoices/Dtos/InvoiceItemDto';
import { InvoiceStatusDto } from 'Api/Features/Invoices/Dtos/InvoiceStatusDto';
import { SubscriptionInvoicePreviewDto } from 'Api/Features/SubscriptionProposals/Dtos/SubscriptionInvoicePreviewDto';
import { AngleBottom, AngleTop, ArrowRight } from 'Components/icons';
import Tag, { TagColors } from 'Components/tag/tag';
import { DISPLAY_DATE_FORMAT, SHORT_MONTH_DATE } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'variant';
import './impact-on-invoices.less';

interface ImpactOnInvoices {
    invoices: SubscriptionInvoicePreviewDto[];
    impactType: ImpactOnInvoicesType;
}

export enum ImpactOnInvoicesType {
    ExistingInvoice,
    NewInvoices,
}

const { Panel } = Collapse;

const ImpactOnInvoices: FunctionComponent<ImpactOnInvoices> = ({ invoices, impactType }) => {
    const { t } = useTranslation();

    const invoiceStatusBadge = (
        invoiceStatus: InvoiceStatusDto,
        invoiceDueDate: string
    ): ReactNode => {
        let color: TagColors = TagColors.active;
        switch (invoiceStatus) {
            case InvoiceStatusDto.AwaitingPayment:
                color = TagColors.default;
                break;
            case InvoiceStatusDto.Failed:
                color = TagColors.error;
                break;
            case InvoiceStatusDto.Pending:
                color = TagColors.cold;
                break;
            case InvoiceStatusDto.Refunded:
                color = TagColors.disabled;
                break;
            case InvoiceStatusDto.Success:
                color = TagColors.active;
                break;
            case InvoiceStatusDto.Void:
                color = TagColors.disabled;
                break;
        }
        return (
            <Tag
                label={t(`Proposal.proposal_InvoiceStatusDto_${invoiceStatus}`, {
                    param1: invoiceDueDate,
                })}
                color={color}
            />
        );
    };

    const nextInvoiceCollapseHeader = (
        invoice: SubscriptionInvoicePreviewDto | null,
        isImpactOnInvoice = false
    ): ReactNode => {
        return (
            <div
                className={`invoice-header-container ${
                    invoice?.isUnchangedInvoice ? 'negative-margin' : ''
                }`}
            >
                <div className="left-container">
                    <div className="invoice-header-dates">
                        {t('Proposal.invoice_to', {
                            param1: moment.utc(invoice?.startDate).format(DISPLAY_DATE_FORMAT),
                            param2: moment.utc(invoice?.endDate).format(DISPLAY_DATE_FORMAT),
                        })}
                    </div>
                    <div className="invoice-header-price">
                        {t('us_price_format', { param1: invoice?.total?.toFixed(2) })}
                    </div>
                    {invoice?.isUnchangedInvoice && (
                        <div className="unchanged-text">
                            {t('Proposal.proposal_invoice_unchanged')}
                        </div>
                    )}
                </div>

                <div className="right-container">
                    {isImpactOnInvoice && (
                        <div className="status-container">
                            {invoice?.previousStatus !== invoice?.newStatus ? (
                                <>
                                    {invoiceStatusBadge(
                                        invoice?.previousStatus!,
                                        moment(invoice?.dueDate).format(SHORT_MONTH_DATE)
                                    )}
                                    <ArrowRight
                                        width={20}
                                        height={17}
                                        fill={theme['primary-color']}
                                    />
                                    {invoiceStatusBadge(
                                        invoice?.newStatus!,
                                        moment(invoice?.dueDate).format(SHORT_MONTH_DATE)
                                    )}
                                </>
                            ) : (
                                invoiceStatusBadge(
                                    invoice?.newStatus!,
                                    moment(invoice?.dueDate).format(SHORT_MONTH_DATE)
                                )
                            )}
                        </div>
                    )}

                    {invoice?.changeToPrepaidBalance !== 0 && (
                        <div
                            className={`changes-prepaid-balance ${
                                isImpactOnInvoice ? 'pt-10' : ''
                            }`}
                        >
                            {t('Proposal.changes_to_prepaid_balance', {
                                param1: invoice?.changeToPrepaidBalance! > 0 ? '+' : '-',
                                param2: Math.abs(invoice?.changeToPrepaidBalance!).toFixed(2),
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const nextInvoiceContent = (invoice: SubscriptionInvoicePreviewDto | null): ReactNode => {
        return (
            <div>
                {invoice?.invoiceDetails?.map((detail: InvoiceItemDto | null, i) => (
                    <div className="invoice-item-container" key={i}>
                        <div
                            className={`invoice-item-description ${
                                i < invoice.invoiceDetails?.length! - 1 ? 'pb-27' : ''
                            }`}
                        >
                            {detail?.description}
                        </div>
                        <div className="invoice-item-price">
                            {t('us_price_format', { param1: detail?.amount?.toFixed(2) })}
                        </div>
                    </div>
                ))}
                <Divider />
                <div className="invoice-item-container">
                    <div className="total">{t('total')}</div>
                    <div className="invoice-item-price total-price">
                        {t('us_price_format', { param1: invoice?.total?.toFixed(2) })}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="ImpactOnInvoices">
            {impactType === ImpactOnInvoicesType.ExistingInvoice ? (
                <Row>
                    <Col span={24}>
                        <div className="next-invoices-title impact">
                            <div>{t('Proposal.impact_on_existing_invoices')}</div>
                        </div>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col span={24}>
                        <div className="next-invoices-title">{t('Proposal.next_invoices')}</div>
                    </Col>
                </Row>
            )}

            <Row>
                <Col span={24}>
                    <Collapse
                        className="next-invoices-container"
                        expandIconPosition={'right'}
                        expandIcon={(panelProps): ReactNode => (
                            <div className="expand-button-container">
                                {panelProps.isActive ? <AngleTop /> : <AngleBottom />}
                            </div>
                        )}
                    >
                        {invoices.map((invoice, i) => (
                            <Panel header={nextInvoiceCollapseHeader(invoice, impactType === ImpactOnInvoicesType.ExistingInvoice)} key={i}>
                                {nextInvoiceContent(invoice)}
                            </Panel>
                        ))}
                    </Collapse>
                </Col>
            </Row>
        </div>
    );
};

export default React.memo(ImpactOnInvoices);
