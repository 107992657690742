import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { UploadFile } from 'antd/lib/upload/interface';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { ManagerUserDto } from 'Api/Features/ManagerUsers/Dtos/ManagerUserDto';
import { UpdateManagerUserRequestDto } from 'Api/Features/ManagerUsers/Dtos/UpdateManagerUserRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { User } from 'Components/icons';
import { ImagePicker } from 'Components/image-picker';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditProfileSchema } from 'Schemas';
import { ManagerUserService } from 'Services/ManagerUserService';
import './edit-modal.less';

const { Title } = Typography;

const formGutter: [Gutter, Gutter] = [40, 0];

interface EditProfileModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    user?: ManagerUserDto;
}

const EditProfile: FunctionComponent<EditProfileModalProps> = ({ visible, onComplete, user }) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors, setErrors] = useFormValidation(
        EditProfileSchema,
        form
    );
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const managerUserService = useService(ManagerUserService);
    const [imageDetails, setImageDetails] = useState<ImageDetails[]>();
    //#endregion

    //#region Effects
    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.contactInfo?.email,
                'contactInfo.phone1': user.contactInfo?.phone1,
                'contactInfo.phone2': user.contactInfo?.phone2,
                'address.addressLine1': user.address?.addressLine1,
                'address.city': user.address?.city,
                'address.country': user.address?.country,
                'address.postalCode': user.address?.postalCode,
                'address.state': user.address?.state,
                wifiPassword: user.wifiPassword,
            });
        }
    }, [user, form]);
    //#endregion

    //#region Event handlers
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <User />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`User.profile_confirm_positive_edit`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();

        const data: UpdateManagerUserRequestDto = {
            ...user,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            contactInfo: {
                ...user?.contactInfo,
                phone1: formValues['contactInfo.phone1'],
                phone2: formValues['contactInfo.phone2'],
            },
            currentPassword: formValues.currentPassword || null,
            password: formValues.password || null,
            confirmPassword: formValues.confirmPassword || null,
            image: imageDetails
                ? ({
                      delete: imageDetails[0].isDeleted,
                      uploadBase64: imageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null,
            address: {
                addressLine1: formValues['address.addressLine1'],
                city: formValues['address.city'],
                country: formValues['address.country'],
                postalCode: formValues['address.postalCode'],
                state: formValues['address.state'],
            },
            wifiPassword: formValues.wifiPassword,
        };

        if (!(await validateForm(data))) return;
        try {
            globalLoadingStore.addLoading();
            if (user) await managerUserService.editManagerUser(user.id!, data, []);

            toastStore.toast({
                type: 'success',
                messageKey: 'User.profile_updated_success',
            });
            dismiss(true);
        } catch (e) {
            const errors = new Map<string, string[]>();
            if (e.response?.data?.modelState['currentPassword'] !== undefined) {
                errors.set('currentPassword', [t('User.email_password_not_exist')]);
                setErrors(errors);
            } else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('User.edit_profile')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="EditProfile">
                <Title level={4}>{t('main_image')}</Title>
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row className="imagePicker-container">
                        <Col span={24}>
                            <ImagePicker
                                images={
                                    user?.imageUrl
                                        ? [
                                              {
                                                  url: user?.imageUrl,
                                                  uid: 'main',
                                              } as UploadFile,
                                          ]
                                        : undefined
                                }
                                setImagesDetails={(images: ImageDetails[] | undefined) =>
                                    setImageDetails(images)
                                }
                            />
                        </Col>
                    </Row>

                    <Title level={4}>{t('basic_information')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="firstName"
                                label={t('first_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="lastName"
                                label={t('last_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="email"
                                label={t('email')}
                                required
                            >
                                <Input disabled={true} />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="contactInfo.phone1"
                                label={t('User.mobile_number')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="contactInfo.phone2"
                                label={t('phone_number')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="wifiPassword"
                                label={t('wifi_password')}
                            >
                                <Input type={'password'} />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>{t('address')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name="address.addressLine1"
                                errors={errors}
                                label={t('address')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="address.city"
                                errors={errors}
                                label={t('city')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="address.state"
                                errors={errors}
                                label={t('state')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="address.country"
                                errors={errors}
                                label={t('country')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="address.postalCode"
                                errors={errors}
                                label={t('zip_code')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>{t('User.edit_password')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="currentPassword"
                                label={t('User.old_password')}
                            >
                                <Input type={'Password'} />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}></Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="password"
                                label={t('User.new_password')}
                            >
                                <Input type={'Password'} />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="confirmPassword"
                                label={t('User.confirm_password')}
                            >
                                <Input type={'Password'} />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(EditProfile);
