// GENERATED FILE - DO NOT MODIFY
import { CancelDayPassReservationResponseDto } from 'Api/Features/DayPasses/Dtos/CancelDayPassReservationResponseDto';
import { CreateDayPassReservationRequestDto } from 'Api/Features/DayPasses/Dtos/CreateDayPassReservationRequestDto';
import { DayPassReservationDto } from 'Api/Features/DayPasses/Dtos/DayPassReservationDto';
import { GetDayPassReservationsRequestDto } from 'Api/Features/DayPasses/Dtos/GetDayPassReservationsRequestDto';
import { GetDayPassReservationsResponseDto } from 'Api/Features/DayPasses/Dtos/GetDayPassReservationsResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class DayPassReservationProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getDayPassReservation(dayPassReservationId: string): Promise<DayPassReservationDto | null> {
        const uri = this.buildUri(
            "/day-pass-reservations/{dayPassReservationId}",
            { dayPassReservationId: dayPassReservationId },
            null
        );

        const data: DayPassReservationDto | null = await this.httpClient.get<DayPassReservationDto | null>(uri);
        return data;
    }

    public async getDayPassReservations(request: GetDayPassReservationsRequestDto | null): Promise<GetDayPassReservationsResponseDto | null> {
        const uri = this.buildUri(
            "/day-pass-reservations",
            null,
            { ...request || undefined }
        );

        const data: GetDayPassReservationsResponseDto | null = await this.httpClient.get<GetDayPassReservationsResponseDto | null>(uri);
        return data;
    }

    public async createDayPassReservation(request: CreateDayPassReservationRequestDto | null): Promise<string | null> {
        const uri = this.buildUri(
            "/day-passes-reservations",
            null,
            null
        );

        const data: string | null = await this.httpClient.post<CreateDayPassReservationRequestDto | null, string | null>(uri, request);
        return data;
    }

    public async cancelDayPassReservation(dayPassReservationId: string, validateOnly?: boolean): Promise<CancelDayPassReservationResponseDto | null> {
        const uri = this.buildUri(
            "/day-pass-reservations/{dayPassReservationId}/cancellation",
            { dayPassReservationId: dayPassReservationId },
            { validateOnly: (validateOnly === null || validateOnly === undefined) ? validateOnly : validateOnly.toString() }
        );

        const data: CancelDayPassReservationResponseDto | null = await this.httpClient.put<void, CancelDayPassReservationResponseDto | null>(uri, );
        return data;
    }
}