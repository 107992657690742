import { CreateMemberRequestDto } from 'Api/Features/Members/Dtos/CreateMemberRequestDto';
import { CreateMemberResponseDto } from 'Api/Features/Members/Dtos/CreateMemberResponseDto';
import { ExportMembersRequestDto } from 'Api/Features/Members/Dtos/ExportMembersRequestDto';
import { GetMemberNoteResponseDto } from 'Api/Features/Members/Dtos/GetMemberNoteResponseDto';
import { GetMembersRequestDto } from 'Api/Features/Members/Dtos/GetMembersRequestDto';
import { MemberDetailsDto } from 'Api/Features/Members/Dtos/MemberDetailsDto';
import { MemberDto } from 'Api/Features/Members/Dtos/MemberDto';
import { UpdateMemberNoteRequestDto } from 'Api/Features/Members/Dtos/UpdateMemberNoteRequestDto';
import { UpdateMemberRequestDto } from 'Api/Features/Members/Dtos/UpdateMemberRequestDto';
import { inject } from 'aurelia-dependency-injection';
import { Member } from 'Models/Members/Member';
import { arrayBufferToXlsx } from 'Utils/blobs';
import { cleanVal } from 'Utils/NumberUtils';
import { MemberProxy } from '../Api/Features/Members/MemberProxy';
import { ApiService } from './ApiService';

@inject(MemberProxy)
export class MemberService extends ApiService {
    constructor(private readonly memberProxy: MemberProxy) {
        super();
    }

    public async getMember(memberId: string | null): Promise<MemberDetailsDto | null> {
        const data = await this.memberProxy.getMember(memberId);
        return data;
    }

    public async getMembers(request: GetMembersRequestDto | null): Promise<[Member[], number]> {
        const response = await this.memberProxy.getMembers(request);
        const nonNullItems = response?.items
            ? response.items.filter((item): item is MemberDto => item !== null)
            : [];

        return [
            nonNullItems.map((item) => new Member(item)) || [],
            cleanVal.number(response?.totalItemCount),
        ];
    }

    public async createMember(
        request: CreateMemberRequestDto
    ): Promise<CreateMemberResponseDto | null> {
        return await this.memberProxy.createMember(request);
    }

    public async updateMember(memberId: string, request: UpdateMemberRequestDto): Promise<void> {
        await this.memberProxy.updateMember(memberId, request);
    }

    public async deleteMember(memberId: string): Promise<void> {
        await this.memberProxy.deleteMember(memberId);
    }

    public async getMemberNote(memberId: string | null): Promise<GetMemberNoteResponseDto | null> {
        const data: GetMemberNoteResponseDto | null = await this.memberProxy.getMemberNote(
            memberId
        );
        return data;
    }

    public async updateMemberNote(
        memberId: string | null,
        request: UpdateMemberNoteRequestDto | null
    ): Promise<void> {
        await this.memberProxy.updateMemberNote(memberId, request);
    }

    public async exportMembers(request: ExportMembersRequestDto | null): Promise<Blob> {
        const data: ArrayBuffer = await this.memberProxy.exportMembers(request);
        return arrayBufferToXlsx(data);
    }
}
