import { FloorPlanMembership as FloorPlanMembershipIcon } from 'Components/icons';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FloorPlanUnitObject, FloorPlanViewType } from './floor-plan';
import FloorPlanUnitTooltip from './floor-plan-unit-tooltip';

const pinSize = 28;
const pinRadius = pinSize / 2;
const pinStrokeWidth = 2;

interface FloorPlanUnitProps {
    unit: FloorPlanUnitObject;
    isSelected: boolean;
    onClick: any;
    className?: string;
    membershipImage?: string;
    unitIsInProposal?: boolean;
    viewType: FloorPlanViewType
}

const FloorPlanUnit: FunctionComponent<FloorPlanUnitProps> = ({
    unit,
    isSelected,
    onClick,
    className,
    membershipImage,
    unitIsInProposal,
    viewType
}) => {
    const [membershipLogo, setMembershipLogo] = useState<string | undefined>();
    useEffect(() => {
        if (membershipImage) {
            setMembershipLogo(membershipImage ?? undefined);
        }
        if (viewType === FloorPlanViewType.Location || viewType === FloorPlanViewType.HeatMap) {
            setMembershipLogo(unit.membershipImage);
        }
    }, [membershipImage]);

    const handleMouseUp = (e: any): void => {
        e.stopPropagation();
        onClick(unit);
    };

    return (
        <svg
            onMouseUp={handleMouseUp}
            className={`floorplan-unit ${unit.type} ${className} ${
                viewType === FloorPlanViewType.HeatMap ? unit.status : ''
            } ${viewType === FloorPlanViewType.HeatMap ? 'heatmap' : ''}`}
        >
            <polygon
                x={unit.poleOfInaccessibility.x}
                y={unit.poleOfInaccessibility.y}
                points={unit.points}
                style={{ cursor: 'pointer' }}
            />
            <svg
                width={pinSize}
                height={pinSize}
                x={unit.poleOfInaccessibility.x - pinRadius}
                y={unit.poleOfInaccessibility.y - pinRadius}
                id={`u-${unit.svgElementId}`}
                style={{ overflow: 'visible' }}
            >
                {(viewType === FloorPlanViewType.Location ||
                    viewType === FloorPlanViewType.HeatMap ||
                    unitIsInProposal) && (
                    <>
                        <circle
                            cx={pinRadius}
                            cy={pinRadius}
                            r={pinRadius - pinStrokeWidth}
                            style={{
                                stroke: 'rgba(255, 255, 255, 0.5)',
                                strokeWidth: pinStrokeWidth,
                                fill: 'white',
                                cursor: 'pointer',
                            }}
                        />
                        {membershipLogo ? (
                            <>
                                <clipPath id="clipCircle">
                                    <circle
                                        cx={pinRadius}
                                        cy={pinRadius}
                                        r={pinRadius - pinStrokeWidth}
                                        style={{
                                            stroke: 'rgba(255, 255, 255, 0.5)',
                                            strokeWidth: pinStrokeWidth,
                                            fill: '#ffffff',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </clipPath>
                                <image
                                    height="100%"
                                    clipPath="url(#clipCircle)"
                                    href={membershipLogo}
                                    x="0"
                                    y="0"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                />
                            </>
                        ) : (
                            <FloorPlanMembershipIcon x={7} y={8} />
                        )}
                    </>
                )}
                <FloorPlanUnitTooltip unit={unit} isSelected={isSelected} />
            </svg>
        </svg>
    );
};

export default FloorPlanUnit;
