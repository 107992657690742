import Icon from 'Components/icons/icon';
import React, { FunctionComponent } from 'react';
import './empty-page.less';

interface EmptyPageProps {
    iconName?: string;
    title?: string;
    subTitle?: string;
}

const EmptyPage: FunctionComponent<EmptyPageProps> = ({ iconName, title, subTitle }) => {
    return (
        <div className="EmptyPage">
            {iconName && <Icon iconName={iconName} />}
            {title && <p className="title">{title}</p>}
            {subTitle && <p className="subtitle">{subTitle}</p>}
        </div>
    );
};

export default EmptyPage;
