// GENERATED FILE - DO NOT MODIFY
import { CreateUnitRequestDto } from 'Api/Features/Units/Dtos/CreateUnitRequestDto';
import { GetUnitOrderResponseDto } from 'Api/Features/Units/Dtos/GetUnitOrderResponseDto';
import { GetUnitsRequestDto } from 'Api/Features/Units/Dtos/GetUnitsRequestDto';
import { GetUnitsResponseDto } from 'Api/Features/Units/Dtos/GetUnitsResponseDto';
import { ImportUnitsRequestDto } from 'Api/Features/Units/Dtos/ImportUnitsRequestDto';
import { ParseUnitImportationFileRequestDto } from 'Api/Features/Units/Dtos/ParseUnitImportationFileRequestDto';
import { ParseUnitImportationFileResponseDto } from 'Api/Features/Units/Dtos/ParseUnitImportationFileResponseDto';
import { UnitDto } from 'Api/Features/Units/Dtos/UnitDto';
import { UnitOccupancyDto } from 'Api/Features/Units/Dtos/UnitOccupancyDto';
import { UpdateUnitOrderRequestDto } from 'Api/Features/Units/Dtos/UpdateUnitOrderRequestDto';
import { UpdateUnitRequestDto } from 'Api/Features/Units/Dtos/UpdateUnitRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class UnitProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getUnit(unitId: string | null): Promise<UnitDto | null> {
        const uri = this.buildUri(
            "/units/{unitId}",
            { unitId: unitId },
            null
        );

        const data: UnitDto | null = await this.httpClient.get<UnitDto | null>(uri);
        return data;
    }

    public async getUnits(request: GetUnitsRequestDto | null): Promise<GetUnitsResponseDto | null> {
        const uri = this.buildUri(
            "/units",
            null,
            { ...request || undefined }
        );

        const data: GetUnitsResponseDto | null = await this.httpClient.get<GetUnitsResponseDto | null>(uri);
        return data;
    }

    public async getUnitOccupancy(unitId: string | null): Promise<UnitOccupancyDto | null> {
        const uri = this.buildUri(
            "/units/{unitId}/occupancy",
            { unitId: unitId },
            null
        );

        const data: UnitOccupancyDto | null = await this.httpClient.get<UnitOccupancyDto | null>(uri);
        return data;
    }

    public async createUnit(request: CreateUnitRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/units",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateUnitRequestDto | null, string>(uri, request);
        return data;
    }

    public async importUnits(request: ImportUnitsRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/units/import-units",
            null,
            null
        );

        await this.httpClient.post<ImportUnitsRequestDto | null, void>(uri, request);
    }

    public async updateUnit(unitId: string | null, request: UpdateUnitRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/units/{unitId}",
            { unitId: unitId },
            null
        );

        await this.httpClient.patch<UpdateUnitRequestDto | null, void>(uri, request);
    }

    public async deleteUnit(unitId: string | null): Promise<void> {
        const uri = this.buildUri(
            "/units/{unitId}",
            { unitId: unitId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async parseUnitImportationFile(request: ParseUnitImportationFileRequestDto | null): Promise<ParseUnitImportationFileResponseDto | null> {
        const uri = this.buildUri(
            "/parse-unit-importation-file",
            null,
            null
        );

        const data: ParseUnitImportationFileResponseDto | null = await this.httpClient.post<ParseUnitImportationFileRequestDto | null, ParseUnitImportationFileResponseDto | null>(uri, request);
        return data;
    }

    public async getUnitImportationFileTemplate(): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/unit-importation-file-template",
            null,
            null
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }

    public async getUnitOrdering(campusId: string | null): Promise<GetUnitOrderResponseDto | null> {
        const uri = this.buildUri(
            "/campus/{campusId}/units/order",
            { campusId: campusId },
            null
        );

        const data: GetUnitOrderResponseDto | null = await this.httpClient.get<GetUnitOrderResponseDto | null>(uri);
        return data;
    }

    public async updateUnitOrdering(campusId: string | null, request: UpdateUnitOrderRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/campus/{campusId}/units/order",
            { campusId: campusId },
            null
        );

        await this.httpClient.put<UpdateUnitOrderRequestDto | null, void>(uri, request);
    }
}