// GENERATED FILE - DO NOT MODIFY
import { ConferenceRoomDto } from 'Api/Features/Amenities/Dtos/ConferenceRooms/ConferenceRoomDto';
import { CreateAmenityRequestDto } from 'Api/Features/Amenities/Dtos/CreateAmenityRequestDto';
import { GetAmenitiesRequestDto } from 'Api/Features/Amenities/Dtos/GetAmenitiesRequestDto';
import { GetAmenitiesResponseDto } from 'Api/Features/Amenities/Dtos/GetAmenitiesResponseDto';
import { GetAmenityOrderResponseDto } from 'Api/Features/Amenities/Dtos/GetAmenityOrderResponseDto';
import { UpdateAmenityOrderRequestDto } from 'Api/Features/Amenities/Dtos/UpdateAmenityOrderRequestDto';
import { UpdateAmenityRequestDto } from 'Api/Features/Amenities/Dtos/UpdateAmenityRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class AmenityProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getAmenities_V1_1(request?: GetAmenitiesRequestDto | null): Promise<(ConferenceRoomDto | null)[] | null> {
        const uri = this.buildUri(
            "/amenities",
            null,
            { ...request || undefined }
        );

        const data: (ConferenceRoomDto | null)[] | null = await this.httpClient.get<(ConferenceRoomDto | null)[] | null>(uri);
        return data;
    }

    public async getAmenities(request: GetAmenitiesRequestDto | null): Promise<GetAmenitiesResponseDto | null> {
        const uri = this.buildUri(
            "/amenities",
            null,
            { ...request || undefined }
        );

        const data: GetAmenitiesResponseDto | null = await this.httpClient.get<GetAmenitiesResponseDto | null>(uri);
        return data;
    }

    public async getAmenity(amenityId: string | null): Promise<ConferenceRoomDto | null> {
        const uri = this.buildUri(
            "/amenities/{amenityId}",
            { amenityId: amenityId },
            null
        );

        const data: ConferenceRoomDto | null = await this.httpClient.get<ConferenceRoomDto | null>(uri);
        return data;
    }

    public async getCampusAmenities(campusId: string | null): Promise<(ConferenceRoomDto | null)[] | null> {
        const uri = this.buildUri(
            "/campus/{campusId}/amenities",
            { campusId: campusId },
            null
        );

        const data: (ConferenceRoomDto | null)[] | null = await this.httpClient.get<(ConferenceRoomDto | null)[] | null>(uri);
        return data;
    }

    public async createAmenity(request: CreateAmenityRequestDto | null): Promise<string | null> {
        const uri = this.buildUri(
            "/amenities",
            null,
            null
        );

        const data: string | null = await this.httpClient.post<CreateAmenityRequestDto | null, string | null>(uri, request);
        return data;
    }

    public async updateAmenity(amenityId: string | null, request: UpdateAmenityRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/amenities/{amenityId}",
            { amenityId: amenityId },
            null
        );

        await this.httpClient.patch<UpdateAmenityRequestDto | null, void>(uri, request);
    }

    public async deleteAmenity(amenityId: string | null): Promise<void> {
        const uri = this.buildUri(
            "/amenities/{amenityId}",
            { amenityId: amenityId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async getAmenityOrdering(campusId: string | null): Promise<GetAmenityOrderResponseDto | null> {
        const uri = this.buildUri(
            "/campus/{campusId}/amenities/order",
            { campusId: campusId },
            null
        );

        const data: GetAmenityOrderResponseDto | null = await this.httpClient.get<GetAmenityOrderResponseDto | null>(uri);
        return data;
    }

    public async updateAmenityOrdering(campusId: string | null, request: UpdateAmenityOrderRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/campus/{campusId}/amenities/order",
            { campusId: campusId },
            null
        );

        await this.httpClient.put<UpdateAmenityOrderRequestDto | null, void>(uri, request);
    }
}