import React, { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    Company,
    ConferenceRoom,
    Delete,
    Phone,
    ScreenSharing,
    Television,
    WhiteBoard,
} from 'Components/icons';
import { theme } from 'variant';
import { ActionMenu } from 'Components/action-menu';
import LocationHeader from 'Components/location-header/location-header';
import { useService, useStores } from 'Hooks';
import { AmenityService } from 'Services/AmenityService';
import { ConferenceRoomDto } from 'Api/Features/Amenities/Dtos/ConferenceRooms/ConferenceRoomDto';
import './index.less';
import { Col, Layout, Row } from 'antd';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import { MembershipService } from 'Services/MembershipService';
import ImageWithPlaceholder from 'Components/image-with-placeholder/image-with-placeholder';
import ConferenceRoomModal from '../create-conference-room';
import { PrivateMembershipDto } from 'Api/Features/Amenities/Dtos/PrivateMembershipDto';
import { MembershipDetailsPlusDto } from 'Api/Features/Memberships/Dtos/MembershipDetailsPlusDto';

const { Content } = Layout;

const LocationConferenceRoomsDetail: FunctionComponent = () => {
    const { t } = useTranslation();
    const { roomId } = useParams();
    const history = useHistory();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const amenityService = useService(AmenityService);
    const membershipsService = useService(MembershipService);
    const [data, setData] = useState<ConferenceRoomDto | null>(null);
    const [privateMemberships, setPrivateMemberships] = useState<MembershipDetailsPlusDto[]>([]);
    const [basicInformations, setBasicInformations] = useState<SimpleListItemProps[]>([]);
    const [description, setDescription] = useState<SimpleListItemProps[]>([]);
    const [settings, setSettings] = useState<SimpleListItemProps[]>([]);
    const [synchronizations, setSynchronizations] = useState<SimpleListItemProps[]>([]);
    const [privacy, setPrivacy] = useState<SimpleListItemProps[]>([]);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const deleteConferenceRoom = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                width: 850,
                icon: <Delete />,
                title: t(`ConferenceRoom.delete_confirm_title`),
                message: t(`action_cannot_be_undone`),
                positiveText: t(`ConferenceRoom.delete_confirm_positive`),
                negativeText: t(`ConferenceRoom.delete_confirm_negative`),
            }))
        )
            return;

        await amenityService.deleteAmenity(roomId);
        history.goBack();
    };

    const actions: ActionMenuOption[] = [
        {
            key: 'create',
            title: t('ConferenceRoom.edit_conference_room'),
            action: (): void => setEditModalOpen(true),
        },
        {
            key: 'reorder',
            title: t('ConferenceRoom.delete_conference_room'),
            action: (): Promise<void> => deleteConferenceRoom(),
        },
    ];

    const fetch = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            const response: ConferenceRoomDto | null = await amenityService.getAmenity(roomId);
            if (response) {
                setData(response);
            }
        } catch (e) {
            toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, roomId, toastStore, amenityService]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const getBreadcrumb = (): BreadcrumbSegment[] => {
        return [
            {
                path: 'management',
                nameKey: 'management',
            },
            {
                path: 'conference-rooms',
                nameKey: 'Location.management_section_conference_rooms',
            },
            {
                path: data?.id || '',
                name: data?.name || '',
            },
        ];
    };

    const getBasicInformations = useCallback((): SimpleListItemProps[] => {
        const basicInformations: SimpleListItemProps[] = [];
        if (data?.hasPhone) {
            basicInformations.push({
                title: 'Phone',
                avatar: <Phone fill={theme['black']} />,
            });
        }
        if (data?.hasTv) {
            basicInformations.push({
                title: 'Television',
                avatar: <Television fill={theme['black']} />,
            });
        }
        if (data?.hasScreenSharing) {
            basicInformations.push({
                title: 'Screen Sharing',
                avatar: <ScreenSharing fill={theme['black']} />,
            });
        }
        if (data?.hasWhiteboard) {
            basicInformations.push({
                title: 'Whiteboard',
                avatar: <WhiteBoard fill={theme['black']} />,
            });
        }
        return basicInformations;
    }, [data]);

    const getDescription = useCallback((): SimpleListItemProps[] => {
        const description: SimpleListItemProps[] = [];
        if (data?.description) {
            description.push({
                title: data?.description,
            });
        }
        return description;
    }, [data]);

    const getSettings = useCallback((): SimpleListItemProps[] => {
        const settings: SimpleListItemProps[] = [];
        if (data?.minimumBookingDuration) {
            settings.push({
                title: t('ConferenceRoom.minimum_booking_duration'),
                description: t('minutes', { param1: data.minimumBookingDuration }),
            });
        }
        if (data?.maximumBookingDuration) {
            settings.push({
                title: t('ConferenceRoom.maximum_booking_duration'),
                description: t('minutes', { param1: data.maximumBookingDuration }),
            });
        }
        if (data?.sameDayMaximumTotalBookingDuration) {
            settings.push({
                title: t('ConferenceRoom.same_day_maximum_total_booking_duration'),
                description: t('minutes', { param1: data.sameDayMaximumTotalBookingDuration }),
            });
        }
        if (data?.maximumContiguousBookingDuration) {
            settings.push({
                title: t('ConferenceRoom.maximum_contiguous_booking_duration'),
                description: t('minutes', { param1: data.maximumContiguousBookingDuration }),
            });
        }
        if (data?.openingTime === '24:00:00') {
            settings.push({
                description: t('ConferenceRoom.open_24h'),
            });
        }
        if (data?.acceptsDayPassBookings) {
            settings.push({
                description: t('ConferenceRoom.accepts_day_pass_booking'),
            });
        }
        return settings;
    }, [data, t]);

    const getSynchronizations = useCallback((): SimpleListItemProps[] => {
        const synchronizations: SimpleListItemProps[] = [];
        if (data?.densitySyncSettings?.synchronize) {
            synchronizations.push({
                title: `${t('ConferenceRoom.synchronize_bookings_to')} ${t(
                    'ConferenceRoom.google_calendar_for_density'
                )}`,
            });
        }
        if (data?.googleCalendarSyncSettings?.synchronize) {
            synchronizations.push({
                title: `${t('ConferenceRoom.synchronize_bookings_to')} ${t(
                    'ConferenceRoom.google_calendar'
                )}`,
            });
        }
        if (data?.office365SyncSettings?.synchronize) {
            synchronizations.push({
                title: `${t('ConferenceRoom.synchronize_bookings_to')} ${t(
                    'ConferenceRoom.office_365'
                )}`,
            });
        }
        return synchronizations;
    }, [data, t]);

    const fetchPrivateMemberships = useCallback(async (): Promise<void> => {
        if (data?.privateMemberships) {
            (data.privateMemberships as PrivateMembershipDto[]).map(
                async (x: PrivateMembershipDto) => {
                    if (x) {
                        const membership = await membershipsService.getMembership(x.id!);
                        if (membership) {
                            setPrivateMemberships((prevState) => [...prevState, membership]);
                        }
                    }
                }
            );
        }
    }, [data, membershipsService]);

    const getPrivacy = useCallback((): SimpleListItemProps[] => {
        const privacy: SimpleListItemProps[] = [];
        privateMemberships &&
            privateMemberships.forEach((membership) => {
                privacy.push({
                    title: membership.name || '',
                    avatar: (
                        <ImageWithPlaceholder
                            width="32"
                            height="32"
                            imgSrc={membership?.imageUrl}
                            defaultImg={<Company fill={theme['white']} />}
                        />
                    ),
                    description: t('Booking.book_a_room_credits_per_block', {
                        param1: membership.creditBalance?.totalAmount,
                    }),
                });
            });

        return privacy;
    }, [privateMemberships, t]);

    const getData = useCallback(() => {
        if (data) {
            setBasicInformations(getBasicInformations);
            setDescription(getDescription);
            setSettings(getSettings);
            setSynchronizations(getSynchronizations);
            fetchPrivateMemberships();
        }
    }, [
        data,
        fetchPrivateMemberships,
        getBasicInformations,
        getDescription,
        getSettings,
        getSynchronizations,
    ]);

    useEffect(() => {
        getData();
    }, [data, getData]);

    useEffect(() => {
        if (privateMemberships) {
            setPrivacy(getPrivacy);
        }
    }, [privateMemberships, getPrivacy]);

    const onEditComplete = (): void => {
        setPrivateMemberships([]);
        fetch();
        setEditModalOpen(false);
    };

    return (
        <div className="ConferenceRoomsDetail">
            {data && (
                <>
                    <LocationHeader
                        title={data?.name || ''}
                        subTitle={
                            <>
                                <div className="header-badge">
                                    {t(
                                        `Booking.book_a_room_${
                                            data?.isPrivate ? 'private' : 'public'
                                        }_room`
                                    )}
                                </div>

                                <div className="header-details">
                                    <div>
                                        {t('ConferenceRoom.credits_per_hour')}{' '}
                                        <strong>
                                            {t('Booking.book_a_room_credits_per_block', {
                                                param1: data?.creditPointsPerBlock,
                                            })}
                                        </strong>
                                    </div>
                                    <div className="divider" />
                                    <div>
                                        {t('ConferenceRoom.number_of_seats')}{' '}
                                        <strong>{data?.numberOfSeats}</strong>
                                    </div>
                                </div>
                            </>
                        }
                        imgSrc={data?.imageUrl}
                        defaultImg={<ConferenceRoom fill={theme['primary-color']} />}
                        routes={getBreadcrumb()}
                        action={<ActionMenu options={actions} type="primary" trigger="click" />}
                    />
                    <Content>
                        <Row gutter={40}>
                            <Col span={12}>
                                {basicInformations.length > 0 && (
                                    <SimpleList title={t('information')} data={basicInformations} />
                                )}

                                {description.length > 0 && (
                                    <SimpleList
                                        title={t('ConferenceRoom.description_title')}
                                        data={description}
                                        className="large-description"
                                    />
                                )}

                                {privacy.length > 0 && (
                                    <SimpleList
                                        className="privacy-list large-description"
                                        title={t('Location.conference_room_privacy')}
                                        data={privacy}
                                    />
                                )}
                            </Col>
                            <Col span={12}>
                                {settings.length > 0 && (
                                    <SimpleList
                                        title={t('settings')}
                                        data={settings}
                                        className="large-description"
                                    />
                                )}

                                {synchronizations.length > 0 && (
                                    <SimpleList
                                        title={t('ConferenceRoom.synchronization_title')}
                                        data={synchronizations}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Content>
                    {editModalOpen && (
                        <ConferenceRoomModal
                            visible={editModalOpen}
                            onComplete={onEditComplete}
                            conferenceRoom={data}
                            campusId={data.campusId || undefined}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default LocationConferenceRoomsDetail;
