import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Monitor: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.138 14.8385C23.138 15.717 22.426 16.4292 21.5473 16.4292H2.45833C1.57967 16.4292 0.867667 15.717 0.867667 14.8385V4.13721C0.867667 3.25854 1.57967 2.54638 2.45833 2.54638H21.5473C22.426 2.54638 23.138 3.25854 23.138 4.13721V14.8385ZM6.36283 21.6932H17.6427V20.8255H6.36283V21.6932ZM21.5762 1.67871H2.48717C1.13167 1.67871 0 2.75254 0 4.10838V14.8097C0 16.1652 1.13167 17.2969 2.48717 17.2969H11.569V19.9579H5.49533V22.5609H18.5103V19.9579H12.4367V17.2969H21.5762C22.9318 17.2969 24.0057 16.1652 24.0057 14.8097V4.10838C24.0057 2.75254 22.9318 1.67871 21.5762 1.67871Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.39845 13.8508H20.5711V5.10179H3.39845V13.8508ZM2.67528 14.574H21.2943V4.37863H2.67528V14.574Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.6141 6.46258C13.5021 6.39908 13.3589 6.43691 13.2942 6.54658L10.3679 12.1854C10.3032 12.2951 10.3301 12.4629 10.4537 12.4987C10.6047 12.5424 10.7087 12.5246 10.7734 12.4149L13.6997 6.77591C13.7644 6.66608 13.7261 6.52608 13.6141 6.46258ZM15.4301 9.36741L14.0274 8.23341C13.9352 8.14358 13.7857 8.14358 13.6937 8.23341C13.6014 8.32358 13.6014 8.46924 13.6937 8.55924L14.9091 9.54191L13.6937 10.5244C13.6014 10.6142 13.6014 10.7601 13.6937 10.8499C13.7857 10.9401 13.9352 10.9401 14.0274 10.8499L15.4301 9.71624C15.4791 9.66824 15.4999 9.60458 15.4969 9.54191C15.4999 9.47908 15.4791 9.41524 15.4301 9.36741ZM10.2759 10.5242L9.06073 9.54191L10.2759 8.55924C10.3681 8.46924 10.3681 8.32324 10.2759 8.23341C10.1837 8.14341 10.0342 8.14341 9.9419 8.23341L8.53957 9.36741C8.4904 9.41524 8.4694 9.47908 8.47273 9.54191C8.4694 9.60458 8.4904 9.66824 8.53957 9.71624L9.9419 10.8499C10.0342 10.9401 10.1837 10.9401 10.2759 10.8499C10.3681 10.7601 10.3681 10.6142 10.2759 10.5242Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Monitor;
