import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { DetailHeader } from 'Components/detail-header';
import { DetailHeaderProps } from 'Components/detail-header/detail-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { MenuItem } from 'Components/top-header-navigation/top-header-navigation';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { Opportunity } from 'Models/Opportunities/Opportunity';
import { SubscriptionProposal } from 'Models/Proposals/SubscriptionProposal';
import React, {
    Children,
    cloneElement,
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CreateProposalModal from 'Routes/authenticated/proposals/create-proposal';
import { OpportunityService } from 'Services/OpportunityService';
import { SubscriptionProposalService } from 'Services/SubscriptionProposalService';
import EditModal from '../id/edit-opportunity';
import MarkAsLost from './modals/mark-as-lost';
import MoveToQualified from '../id/modals/move-to-qualified';
import { SubscriptionProposalStatusDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionProposalStatusDto';
import { SubscriptionDetailsDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsDto';
import { SubscriptionService } from 'Services/SubscriptionService';
import { SalesActivityService } from 'Services/SalesActivityService';
import { GetSalesActivitiesRequestDto } from 'Api/Features/SalesActivities/Dtos/GetSalesActivitiesRequestDto';
import { SalesActivityDto } from 'Api/Features/SalesActivities/Dtos/SalesActivityDto';
import './index.less';
import { Trash } from 'Components/icons';
import Tag, { getDetailHeaderSubscriptionTagStatuses, getOpportunityStatusTag, TagColors } from 'Components/tag/tag';
import OpportunityTopHeaderNavigation from 'Components/opportunity-top-header-navigation/opportunity-top-header-navigation';
import ReopenOpportunityModal from './modals/reopen-opportunity-modal';
import { SubscriptionAncestryDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionAncestryDto';

export interface OpportunityRouteProps {
    data: Opportunity;
    fetchData: () => void;
    setBreadcrumbs: (arg0: BreadcrumbSegment[]) => void;
    setCtaOptions: (arg0: ActionMenuOption[]) => void;
    setEditModalOpen: any;
    setLostModalOpen: any;
    setCreateProposalModalOpen: any;
    setProposalResponseModalOpen: any;
    setMoveToQualifiedModalOpen: any;
    proposalList?: SubscriptionProposal[];
    fetchProposalList?: any;
    activities: (SalesActivityDto | null)[] | undefined | null;
    fetchActivities: any;
    setEditableTitle: (node: ReactNode) => void;
}

const OpportunityDetail: FunctionComponent = observer(({ children }: any) => {
    const [data, setData] = useState<Opportunity | undefined>();
    const [proposalList, setProposalList] = useState<SubscriptionProposal[] | undefined>();
    const [detailHeaderInformation, setDetailHeaderInformation] = useState<DetailHeaderProps>();
    const [editableTitle, setEditableTitle] = useState<ReactNode>();
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbSegment[]>();
    const [ctaOptions, setCtaOptions] = useState<ActionMenuOption[]>();
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [lostModalOpen, setLostModalOpen] = useState(false);
    const [moveToQualifiedModalOpen, setMoveToQualifiedModalOpen] = useState(false);
    const [createProposalModalOpen, setCreateProposalModalOpen] = useState(false);
    const [reopenOpportunityModalOpen, setReopenOpportunityModalOpen] = useState(false);
    const [subscription, setSubscription] = useState<SubscriptionDetailsDto | undefined>();
    const [salesActivities, setSalesActivities] = useState<
        (SalesActivityDto | null)[] | undefined | null
    >();
    const [subscriptionAncestryInfo, setSubscriptionAncestryInfo] = useState<
        SubscriptionAncestryDto | undefined
    >();
    const [opportunityHasCompleteInfo, setOpportunityHasCompleteInfo] = useState(false);

    const { globalLoadingStore, confirmationModalStore, toastStore, userPermissionsStore } =
        useStores();
    const history = useHistory();
    const opportunityService = useService(OpportunityService);
    const subscriptionProposalService = useService(SubscriptionProposalService);
    const subscriptionService = useService(SubscriptionService);
    const salesActivityService = useService(SalesActivityService);
    const location = useLocation();

    const proposalDetailUrl = /dashboard\/proposals\/+.*/;

    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();

    const fetch = useCallback(async () => {
        globalLoadingStore.addLoading();

        try {
            const opportunity = await opportunityService.getById(id);

            if (opportunity) {
                setData(opportunity);

                const opportunityName = opportunity.name;
                const tags = [getOpportunityStatusTag(opportunity, t)];
                if (opportunity.isSubscriptionModification) {
                    tags.push(<Tag boldLabel color={TagColors.cold} label={t('modification')} />);
                }
                if (opportunity.isSubscriptionRenewal) {
                    tags.push(<Tag boldLabel color={TagColors.cold} label={t('renewal')} />);
                }
                if (!opportunity.isSubscriptionRenewal && !opportunity.isSubscriptionModification) {
                    tags.push(
                        <Tag boldLabel color={TagColors.cold} label={t('new_subscription')} />
                    );
                }

                setDetailHeaderInformation({
                    title: opportunityName ?? undefined,
                    tags
                });
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [id, opportunityService, globalLoadingStore, t]);

    const fetchSubscriptionDetails = useCallback(
        async (subscriptionId: string) => {
            globalLoadingStore.addLoading();

            try {
                const subscription = await subscriptionService.getSubscription(subscriptionId);
                if (subscription) setSubscription(subscription);
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [globalLoadingStore, subscriptionService]
    );

    const fetchSubscriptionAncestry = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            if (data?.subscriptionAncestry?.id) {
                const response = await subscriptionService.getSubscriptionAncestry(
                    data.subscriptionAncestry.id
                );
                if (response) {
                    setSubscriptionAncestryInfo(response);
                }
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [data, subscriptionService, globalLoadingStore]);

    useEffect(() => {
        if (data?.subscriptionAncestry?.id) fetchSubscriptionAncestry();
    }, [data, fetchSubscriptionAncestry]);

    const fetchProposalList = useCallback(async () => {
        globalLoadingStore.addLoading();

        try {
            const [proposalsList] = await subscriptionProposalService.getSubscriptionProposal({
                pageSize: 25,
                page: 0,
                searchTerm: undefined,
                opportunityId: id,
            });

            if (proposalsList) {
                setProposalList(proposalsList);
                const acceptedProposal = proposalsList?.find(
                    (proposal) => proposal.status === SubscriptionProposalStatusDto.Accepted
                );
                if (acceptedProposal) fetchSubscriptionDetails(acceptedProposal.id!);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [id, globalLoadingStore, subscriptionProposalService, fetchSubscriptionDetails]);

    useEffect(() => {
        fetch();
    }, [fetch, editModalOpen, lostModalOpen]);

    const fetchActivities = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();

        try {
            const activities = await salesActivityService.getSalesActivities({
                opportunityIds: [id],
            } as GetSalesActivitiesRequestDto);
            if (activities?.items) setSalesActivities(activities.items);
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, id, salesActivityService]);

    useEffect(() => {
        fetchActivities();
    }, [fetchActivities, id]);

    useEffect(() => {
        if (data) {
            if (
                data.closeDate &&
                data.deskCount !== null &&
                data.deskCount !== undefined &&
                data.product &&
                data.salesChannel &&
                data.termLength
            )
                setOpportunityHasCompleteInfo(true);
        }
    }, [data]);
    //if trying to create proposal and opportunity has missing info, open the edit modal first
    useEffect(() => {
        if (createProposalModalOpen && !opportunityHasCompleteInfo) setEditModalOpen(true);
    }, [createProposalModalOpen, opportunityHasCompleteInfo]);

    const menuItems: MenuItem[] = [
        {
            key: `/opportunities/${id}/dashboard`,
            label: t('Opportunity.opportunity_dashboard'),
        },
        {
            key: `/opportunities/${id}/dashboard/proposals`,
            label: t('Opportunity.opportunity_proposals'),
        },
        {
            key: `/opportunities/${id}/dashboard/service-agreements`,
            label: t('Opportunity.opportunity_agreement_terms'),
        },
    ];

    const onEditComplete = (): void => {
        setOpportunityHasCompleteInfo(true);
        setEditModalOpen(false);
        fetch();
    };

    const onMarkAsLostComplete = (): void => {
        setLostModalOpen(false);
        fetch();
        fetchProposalList();
    };

    const onCreateProposalComplete = (): void => {
        fetchProposalList();
        setCreateProposalModalOpen(false);
        fetch();
        fetchProposalList();
    };

    const updateChildrenWithProps = Children.map(children, (child) => {
        return cloneElement(child, {
            data: data,
            fetchData: fetch,
            setBreadcrumbs: setBreadcrumbs,
            setLostModalOpen: setLostModalOpen,
            setCreateProposalModalOpen: setCreateProposalModalOpen,
            setMoveToQualifiedModalOpen: setMoveToQualifiedModalOpen,
            proposalList: proposalList,
            fetchProposalList: fetchProposalList,
            setCtaOptions: setCtaOptions,
            activities: salesActivities,
            fetchActivities: fetchActivities,
            setEditableTitle: setEditableTitle,
        });
    });

    useEffect(() => {
        let options: ActionMenuOption[] = [];

        if (data) {
            if (
                data.status !== OpportunityStatusDto.Lost &&
                data.status !== OpportunityStatusDto.AgreementSigned
            ) {
                options = [
                    {
                        key: 'edit',
                        title: t('Opportunity.opportunity_edit_opportunity_information'),
                        action: (): void => setEditModalOpen(true),
                    },
                ];

                if (
                    data.status !== OpportunityStatusDto.New &&
                    data.status !== OpportunityStatusDto.TourScheduled
                ) {
                    options.push({
                        key: 'newProposal',
                        title: t('Proposal.create_new_proposal'),
                        action: (): void => setCreateProposalModalOpen(true),
                    });
                } else {
                    options.push({
                        key: 'moveToQualified',
                        title: t('Opportunity.move_to_qualified'),
                        action: (): void => setMoveToQualifiedModalOpen(true),
                    });
                }

                options.push({
                    key: 'lost',
                    title: t('Opportunity.opportunity_mark_as_lost'),
                    action: (): void => setLostModalOpen(true),
                });
            }

            if (data.status === OpportunityStatusDto.Lost) {
                options.push({
                    key: 'reopen',
                    title: t('Opportunity.reopen_opportunity'),
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    action: (): void => setReopenOpportunityModalOpen(true),
                });
            }

            if (
                userPermissionsStore.userIsAdministrator &&
                data.status !== OpportunityStatusDto.AgreementSigned
            ) {
                options.push({
                    key: 'delete',
                    title: t('Opportunity.delete_opportunity'),
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    action: (): Promise<void> => deleteOpportunity(),
                });
            }
        }
        setCtaOptions(options);
    }, [
        data,
        setCreateProposalModalOpen,
        setCtaOptions,
        setEditModalOpen,
        setLostModalOpen,
        setMoveToQualifiedModalOpen,
        t,
    ]);

    const deleteOpportunity = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Trash />,
                title: t(`Opportunity.delete_opportunity`),
                message: t(`Opportunity.delete_opportunity_confirm_message`),
                positiveText: t(`Opportunity.delete_opportunity`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        //call delete
        globalLoadingStore.addLoading();
        await opportunityService.deleteOpportunity(id);
        globalLoadingStore.removeLoading();
        toastStore.toast({
            type: 'success',
            messageKey: `Opportunity.delete_opportunity_success`,
        });
        history.push('/opportunities');
    };

    return (
        <>
            <div className="OpportunityDetail">
                <OpportunityTopHeaderNavigation menuItems={menuItems} />

                <DetailHeader
                    title={
                        proposalDetailUrl.test(location.pathname) && editableTitle !== null
                            ? undefined
                            : detailHeaderInformation?.title
                    }
                    loading={false}
                    routes={breadcrumbs}
                    tags={detailHeaderInformation?.tags}
                    menuOptions={ctaOptions}
                    subscriptionStatuses={getDetailHeaderSubscriptionTagStatuses(subscription)}
                    editableTitle={
                        proposalDetailUrl.test(location.pathname) && editableTitle
                            ? editableTitle
                            : null
                    }
                    subscriptionAncestryInfo={subscriptionAncestryInfo}
                />

                {updateChildrenWithProps}
            </div>

            {lostModalOpen && (
                <MarkAsLost
                    visible={lostModalOpen}
                    onComplete={onMarkAsLostComplete}
                    opportunity={data}
                />
            )}

            {editModalOpen && (
                <EditModal visible={editModalOpen} onComplete={onEditComplete} opportunity={data} />
            )}

            {createProposalModalOpen && (
                <CreateProposalModal
                    onComplete={onCreateProposalComplete}
                    visible={createProposalModalOpen && opportunityHasCompleteInfo}
                    opportunity={data}
                />
            )}

            {moveToQualifiedModalOpen && (
                <MoveToQualified
                    visible={moveToQualifiedModalOpen}
                    onComplete={(): void => {
                        setMoveToQualifiedModalOpen(false);
                        fetch();
                    }}
                    opportunity={data}
                />
            )}

            {reopenOpportunityModalOpen && (
                <ReopenOpportunityModal
                    visible={reopenOpportunityModalOpen}
                    onComplete={(success: boolean): void => {
                        setReopenOpportunityModalOpen(false);
                        if (success) fetch();
                    }}
                    opportunity={data}
                />
            )}
        </>
    );
});

export default OpportunityDetail;
