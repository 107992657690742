import { GetOccupancyStatsReportRequestDto } from 'Api/Features/Reports/Dtos/OccupancyStats/GetOccupancyStatsReportRequestDto';
import { OccupancyStatsReportDto } from 'Api/Features/Reports/Dtos/OccupancyStats/OccupancyStatsReportDto';
import { OccupancyStatsReportProxy } from 'Api/Features/Reports/OccupancyStatsReportProxy';
import { inject } from 'aurelia-dependency-injection';
import { arrayBufferToXlsx } from 'Utils/blobs';
import { ApiService } from './ApiService';

@inject(OccupancyStatsReportProxy)
export class OccupancyStatsReportService extends ApiService {
    constructor(private readonly occupancyStatsReportProxy: OccupancyStatsReportProxy) {
        super();
    }

    public async getOccupancyStatsReport(
        request: GetOccupancyStatsReportRequestDto | null
    ): Promise<OccupancyStatsReportDto | null> {
        return await this.occupancyStatsReportProxy.getOccupancyStatsReport(request);
    }

    public async exportOccupancyStatsReport(
        request: GetOccupancyStatsReportRequestDto | null
    ): Promise<Blob> {
        const document = await this.occupancyStatsReportProxy.exportOccupancyStatsReport(request);

        return arrayBufferToXlsx(document);
    }
}
