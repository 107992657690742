import { BookingDetailsDto } from 'Api/Features/Amenities/Dtos/BookingDetailsDto';
import { BookingDto } from 'Api/Features/Amenities/Dtos/BookingDto';
import { BookingSummaryDto } from 'Api/Features/Amenities/Dtos/BookingSummaryDto';
import { BookingSummaryRequestDto } from 'Api/Features/Amenities/Dtos/BookingSummaryRequestDto';
import { CancelBookingRequestDto } from 'Api/Features/Amenities/Dtos/CancelBookingRequestDto';
import { CreateBookingRequestDto } from 'Api/Features/Amenities/Dtos/CreateBookingRequestDto';
import { DailyBookingSummaryDto } from 'Api/Features/Amenities/Dtos/DailyBookingSummaryDto';
import { GetRecurrencePresetsRequestDto } from 'Api/Features/Amenities/Dtos/GetRecurrencePresetsRequestDto';
import { RecurrencePresetDto } from 'Api/Features/Amenities/Dtos/RecurrencePresetDto';
import { UpdateBookingRequestDto } from 'Api/Features/Amenities/Dtos/UpdateBookingRequestDto';
import { BookingProxy } from 'Api/Features/Bookings/BookingProxy';
import { GetBookingHistoryRequestDto } from 'Api/Features/Bookings/Dtos/GetBookingHistoryRequestDto';
import { GetMembershipBookingsResponseDto } from 'Api/Features/Bookings/Dtos/GetMembershipBookingsResponseDto';
import { inject } from 'aurelia-dependency-injection';
import { LoadingStore } from 'Stores';
import { ApiService } from './ApiService';

@inject(BookingProxy, LoadingStore)
export class BookingService extends ApiService {
    constructor(
        private readonly bookingProxy: BookingProxy,
        private readonly loadingStore: LoadingStore
    ) {
        super();
    }

    public async getDailyBookingSummary(
        id: string,
        date: string
    ): Promise<DailyBookingSummaryDto | null> {
        this.loadingStore.addLoading();
        try {
            const response: DailyBookingSummaryDto | null = await this.bookingProxy.getDailyBookingSummary2(
                id,
                { date, enableMemberRestrictions: false }
            );
            return response;
        } finally {
            this.loadingStore.removeLoading();
        }
    }

    public async book(id: string, request: CreateBookingRequestDto): Promise<string> {
        this.loadingStore.addLoading();
        try {
            const response: string | null = await this.bookingProxy.createBooking(id, request);
            return response || '';
        } finally {
            this.loadingStore.removeLoading();
        }
    }

    public async getBooking(id: string): Promise<BookingDto | null> {
        this.loadingStore.addLoading();
        try {
            const response: BookingDto | null = await this.bookingProxy.getBooking(id);
            return response;
        } finally {
            this.loadingStore.removeLoading();
        }
    }

    public async updateBooking(
        id: string,
        request: UpdateBookingRequestDto
    ): Promise<BookingDetailsDto | null> {
        this.loadingStore.addLoading();
        try {
            const response: BookingDetailsDto | null = await this.bookingProxy.updateBooking(
                id,
                request
            );
            return response;
        } finally {
            this.loadingStore.removeLoading();
        }
    }

    public async getMemberBookings(
        accountId: string,
        request: GetBookingHistoryRequestDto | null
    ): Promise<[BookingDto[], number]> {
        const response = await this.bookingProxy.getMemberBookings(accountId, request);
        const nonNullBookings =
            response && response.items
                ? response.items.filter((booking): booking is BookingDto => booking !== null)
                : [];
        const nonNullTotalItemCount = response?.totalItemCount || 0;

        return [nonNullBookings, nonNullTotalItemCount];
    }

    public async cancelBooking(request: CancelBookingRequestDto): Promise<void> {
        this.loadingStore.addLoading();
        try {
            await this.bookingProxy.cancelBooking(request);
        } finally {
            this.loadingStore.removeLoading();
        }
    }

    public async getBookingRecurrencePresets(
        amenityId: string | null,
        request: GetRecurrencePresetsRequestDto | null
    ): Promise<(RecurrencePresetDto | null)[] | null> {
        const data:
            | (RecurrencePresetDto | null)[]
            | null = await this.bookingProxy.getBookingRecurrencePresets(amenityId, request);
        return data;
    }

    public async getBookingSummary(
        amenityId: string | null,
        request: BookingSummaryRequestDto | null
    ): Promise<BookingSummaryDto | null> {
        const data: BookingSummaryDto | null = await this.bookingProxy.getBookingSummary(
            amenityId,
            request
        );
        return data;
    }

    public async getMembershipBookings(membershipId: string | null, request: GetBookingHistoryRequestDto | null): Promise<[BookingDto[], number]> {
        const data: GetMembershipBookingsResponseDto | null = await this.bookingProxy.getMembershipBookings(membershipId, request);
        
        return [
            data?.items?.filter((item) => item !== null).map((item) => item!) || [],
            data?.totalItemCount || 0,
        ];
    }
}
