import { Table, TablePaginationConfig } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { ColumnType } from 'antd/lib/table';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetPrepaidBalanceTransactionsSortColumnDto } from 'Api/Features/PrepaidBalance/Dtos/GetPrepaidBalanceTransactionsSortColumnDto';
import { PrepaidBalanceTransactionDto } from 'Api/Features/PrepaidBalance/Dtos/PrepaidBalanceTransactionDto';
import CompanyHeader from 'Components/company-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { TableFilters } from 'Components/table-filters';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import React, {
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FilterStore } from 'Stores';
import { CompanyContext } from '../..';
import { PrepaidBalanceService } from 'Services/PrepaidBalanceService';
import { GetMembershipPrepaidBalanceTransactionsRequestDto } from 'Api/Features/PrepaidBalance/Dtos/GetMembershipPrepaidBalanceTransactionsRequestDto';
import moment from 'moment';
import { MOMENT_PARSING_FORMAT, TABLE_DEBOUNCE_DELAY } from 'Models/Constants';
import { currencyFormatter } from 'Utils/NumberUtils';
import './index.less';
import { MembershipPrepaidBalanceTransactionTypeDto } from 'Api/Features/PrepaidBalance/Dtos/MembershipPrepaidBalanceTransactionTypeDto';

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 20,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const PrepaidBalance: FunctionComponent = () => {
    const companyContext = useContext(CompanyContext);
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const prepaidBalanceService = useService(PrepaidBalanceService);
    const { toastStore } = useStores();
    const [prepaidTransactions, setPrepaidTransactions] =
        useState<PrepaidBalanceTransactionDto[]>();

    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const paginationRef = useRef(initialPaginationState);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters: [] }));

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `companies`,
            nameKey: 'companies',
        },
        {
            path: `${id}/dashboard`,
            nameKey: companyContext?.name ?? '',
        },
        {
            path: `finance`,
            nameKey: 'finance',
        },
        {
            path: `prepaid-balance`,
            nameKey: 'prepaid_balance',
        },
    ];

    const renderDescription = (transaction: PrepaidBalanceTransactionDto): string => {
        switch (transaction.type) {
            case MembershipPrepaidBalanceTransactionTypeDto.SubscriptionReplaced:
                return t(
                    'PrepaidBalance.MembershipPrepaidBalanceTransactionTypeDto_SubscriptionReplaced',
                    { param1: transaction.daysOverpaidOnInvoice }
                );
            case MembershipPrepaidBalanceTransactionTypeDto.SubscriptionCancelled:
                return t(
                    'PrepaidBalance.MembershipPrepaidBalanceTransactionTypeDto_SubscriptionCancelled',
                    { param1: transaction.daysOverpaidOnInvoice }
                );
            case MembershipPrepaidBalanceTransactionTypeDto.AppliedToInvoice:
                return t(
                    'PrepaidBalance.MembershipPrepaidBalanceTransactionTypeDto_AppliedToInvoice'
                );
            case MembershipPrepaidBalanceTransactionTypeDto.PreviousTransactionVoided:
                return t(
                    'PrepaidBalance.MembershipPrepaidBalanceTransactionTypeDto_PreviousTransactionVoided'
                );
            default:
                return '';
        }
    };

    const columns: ColumnType<PrepaidBalanceTransactionDto>[] = [
        {
            key: GetPrepaidBalanceTransactionsSortColumnDto.DateCreated,
            title: t('date'),
            render: (transaction: PrepaidBalanceTransactionDto) =>
                moment(transaction.dateCreated).format(MOMENT_PARSING_FORMAT),
            sorter: true,
        },
        {
            key: 'description',
            title: t('description'),
            render: renderDescription,
        },
        {
            key: 'invoice',
            title: t('invoice'),
            render: (transaction: PrepaidBalanceTransactionDto) => transaction.invoice?.number,
        },
        {
            key: 'deduction',
            title: t('deduction'),
            render: (transaction: PrepaidBalanceTransactionDto) =>
                transaction.amount && transaction.amount < 0
                    ? currencyFormatter(Math.abs(transaction.amount))
                    : '',
            align: 'right',
        },
        {
            key: 'addition',
            title: t('addition'),
            render: (transaction: PrepaidBalanceTransactionDto) =>
                transaction.amount && transaction.amount > 0
                    ? currencyFormatter(transaction.amount)
                    : '',
            align: 'right',
        },
        {
            key: 'balance',
            title: t('balance'),
            render: (transaction: PrepaidBalanceTransactionDto) =>
                currencyFormatter(transaction.balance ?? 0),
            align: 'right',
        },
    ];

    const fetchInvoices = useCallback(
        async (params: {
            searchTerm?: string;
            startDate: string;
            endDate: string;
            pagination: TablePaginationConfig;
            sortColumn: GetPrepaidBalanceTransactionsSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);

            try {
                const request: GetMembershipPrepaidBalanceTransactionsRequestDto = {
                    minDate: params.startDate,
                    maxDate: params.endDate,
                    searchTerm: params.searchTerm,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    page: (params.pagination.current || 1) - 1,
                    pageSize: params.pagination.pageSize || 10,
                };

                const [response, totalItemCount] =
                    await prepaidBalanceService.getMembershipPrepaidBalanceTransactions(
                        id,
                        request
                    );

                setPrepaidTransactions(response);
                setPagination({
                    ...params.pagination,
                    total: totalItemCount,
                });
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [setLoading, prepaidBalanceService, t]
    );

    const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any): void => {
        const { searchTerm, startDate, endDate } = filterStoreRef.current;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        fetchInvoices({
            searchTerm: searchTerm,
            startDate: startDate,
            endDate: endDate,
            pagination: pagination,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
    };

    const debounceSearch = useRef(
        debounce((params: { searchTerm?: string; startDate: string; endDate: string }) => {
            fetchInvoices({
                searchTerm: params.searchTerm,
                startDate: params.startDate,
                endDate: params.endDate,
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                sortColumn: null,
                sortDirection: null,
            });
        }, TABLE_DEBOUNCE_DELAY)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;

            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                startDate: filterStoreRef.current.startDate,
                endDate: filterStoreRef.current.endDate,
            });
        });

        return (): void => {
            disposer();
        };
    }, [fetchInvoices]);

    return (
        <div className="PrepaidBalance">
            <CompanyHeader
                company={companyContext}
                routes={breadcrumbs}
                headerData={{
                    title: t('prepaid_balance'),
                    iconName: 'Dollar',
                    subTitle: t('Company.company_prepaid_balance_label_subtitle'),
                }}
            />
            <Content>
                <div className="balance-total-container">
                    <div className="inner">
                        <div className="total">
                            {prepaidTransactions && prepaidTransactions.length > 0
                                ? currencyFormatter(prepaidTransactions[0].balance ?? 0)
                                : currencyFormatter(0)}
                        </div>
                        <div className="subtitle">{t('prepaid_balance_total')}</div>
                    </div>
                </div>

                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeDates
                    datesPrefix={{
                        start: t('date_from'),
                        end: t('date_to'),
                    }}
                    includeSearch
                />

                <Table
                    className="table-striped-rows"
                    bordered
                    columns={columns}
                    rowKey={(row: PrepaidBalanceTransactionDto): string =>
                        `${row.invoice?.id}${row.dateCreated}${row.type}${row.balance}`
                    }
                    dataSource={prepaidTransactions}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </Content>
        </div>
    );
};

export default PrepaidBalance;
