import { StoredFileDto } from 'Api/Features/General/Dtos/StoredFileDto';
import { CreateOpportunityFileRequestDto } from 'Api/Features/Opportunities/Dtos/CreateOpportunityFileRequestDto';
import { GetOpportunityFilesRequestDto } from 'Api/Features/Opportunities/Dtos/GetOpportunityFilesRequestDto';
import { GetOpportunityFilesResponseDto } from 'Api/Features/Opportunities/Dtos/GetOpportunityFilesResponseDto';
import { OpportunityFileProxy } from 'Api/Features/Opportunities/OpportunityFileProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(OpportunityFileProxy)
export class OpportunityFileService extends ApiService {
    constructor(private readonly opportunityFileProxy: OpportunityFileProxy) {
        super();
    }
    public async getOpportunityFiles(
        opportunityId: string,
        request: GetOpportunityFilesRequestDto | null
    ): Promise<[StoredFileDto[], number]> {
        const data: GetOpportunityFilesResponseDto | null = await this.opportunityFileProxy.getOpportunityFiles(
            opportunityId,
            request
        );
        return [
            data?.items?.filter((file) => file !== null).map((file) => file!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async createOpportunityFile(
        opportunityId: string,
        request: CreateOpportunityFileRequestDto | null
    ): Promise<string> {
        const data: string = await this.opportunityFileProxy.createOpportunityFile(
            opportunityId,
            request
        );
        return data;
    }

    public async deleteOpportunityFile(opportunityId: string, fileId: string): Promise<void> {
        await this.opportunityFileProxy.deleteOpportunityFile(opportunityId, fileId);
    }
}
