// GENERATED FILE - DO NOT MODIFY

export enum GetSubscriptionsFlagDto {
    IsActive = "IsActive",
    IsUpcoming = "IsUpcoming",
    IsExpired = "IsExpired",
    IsCancelled = "IsCancelled",
    ExpiresSoon = "ExpiresSoon",
    IsOnboardingInProgress = "IsOnboardingInProgress",
    IsModificationInProgress = "IsModificationInProgress",
    IsRenewalInProgress = "IsRenewalInProgress",
}