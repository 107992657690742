import React, { FunctionComponent } from 'react';
import { Select } from 'antd';

import { LeadOwnerFilter } from '../../../Models/Filters/LeadOwnerFilter';
import { MY_LEADS, ALL_LEADS, ALL_OPPORTUNITIES, MY_OPPORTUNITIES } from 'Models/Constants';
import { ManagerUser } from 'Models/ManagerUsers/ManagerUser';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface LeadOwnerSelectProps {
    availableLeadOwners: ManagerUser[];
    onLeadOwnerChange: (ownerId: string) => void;
    value?: string | undefined;
    forOpportunities?: boolean;
}

const mapOwners = (owner: ManagerUser[]): LeadOwnerFilter[] =>
    owner.map((dto) => {
        const filter: LeadOwnerFilter = {
            id: dto.id!,
            imageUrl: dto.imageUrl!,
            name: dto.name!,
        };
        return filter;
    });

const LeadOwnerSelect: FunctionComponent<LeadOwnerSelectProps> = ({
    availableLeadOwners,
    onLeadOwnerChange,
    value,
    forOpportunities,
}) => {
    const { t } = useTranslation();

    const filters: LeadOwnerFilter[] = [
        {
            id: forOpportunities ? MY_OPPORTUNITIES : MY_LEADS,
            imageUrl: '',
            name: forOpportunities ? t('Opportunity.my_opportunities') : t('Lead.my_leads'),
        },
        {
            id: forOpportunities ? ALL_OPPORTUNITIES : ALL_LEADS,
            imageUrl: '',
            name: forOpportunities
                ? t('Opportunity.all_opportunity_owners')
                : t('Lead.all_lead_owners'),
        },
    ].concat(mapOwners(availableLeadOwners));
    return (
        <Select
            defaultValue={forOpportunities ? MY_OPPORTUNITIES : MY_LEADS}
            onChange={onLeadOwnerChange}
            style={{ width: '100%' }}
            value={value}
        >
            {filters.map((ownerFilter) => (
                <Option key={ownerFilter.id} value={ownerFilter.id}>
                    {ownerFilter.name}
                </Option>
            ))}
        </Select>
    );
};

export default LeadOwnerSelect;
