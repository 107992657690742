import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import { theme } from 'variant';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { useService, useStores } from 'Hooks';
import { useTranslation } from 'react-i18next';
import { ForkKnife } from 'Components/icons';
import { autorun } from 'mobx';
import Table, { TablePaginationConfig } from 'antd/lib/table';
import { FilterStore } from 'Stores';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { TableFilters } from 'Components/table-filters';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { useHistory, useParams } from 'react-router';
import { ColumnType } from 'antd/es/table';
import { CampusSurroundingService } from 'Services/CampusSurroundingService';
import { CampusSurroundingDto } from 'Api/Features/CampusSurroundings/Dtos/CampusSurroundingDto';
import { GetCampusSurroundingsSortColumnDto } from 'Api/Features/CampusSurroundings/Dtos/GetCampusSurroundingsSortColumnDto';
import { Button } from 'antd';
import LocationHeader from 'Components/location-header/location-header';
import { CampusSurroundingTypeService } from 'Services/CampusSurroundingTypeService';
import { AdvancedFilterItem } from 'Models/Filters/AdvancedFilterItem';
import { getAdvancedTableFiltersState } from 'Utils/TableAdvancedFiltersUtils';
import EditAreaOfInterestModal from './edit-area-of-interest-modal';
import './index.less';
import { GetCampusSurroundingsRequestDto } from 'Api/Features/CampusSurroundings/Dtos/GetCampusSurroundingsRequestDto';
import { RequestType } from 'Stores/RequestStore';

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 10,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'management',
        nameKey: 'Location.management_label',
    },
    {
        path: 'areas-of-interest',
        nameKey: 'AreasOfInterest.areas_of_interest',
    },
];

const advancedFilters: AdvancedFilter[] = [{ key: 'type', nameKey: 'type', items: [] }];

const AreasOfInterest: FunctionComponent = () => {
    const { t } = useTranslation();
    const campusSurroundingService = useService(CampusSurroundingService);
    const campusSurroundingTypeService = useService(CampusSurroundingTypeService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const paginationRef = useRef(initialPaginationState);
    const [data, setData] = useState<CampusSurroundingDto[]>([]);
    const history = useHistory();
    const { requestStore, globalLoadingStore } = useStores();
    const { id } = useParams<{ id: string }>();

    const [createModalOpen, setCreateModalOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const onRowClick = (surrounding: CampusSurroundingDto): void => {
        history.push(`areas-of-interest/${surrounding.id}`);
    };

    const columns: ColumnType<CampusSurroundingDto>[] = [
        {
            title: t('name'),
            render: (item: CampusSurroundingDto) => item.title,
            sorter: true,
            key: GetCampusSurroundingsSortColumnDto.Title,
        },
        {
            title: t('type'),
            render: (item: CampusSurroundingDto) => item.type?.description,
            sorter: true,
            key: GetCampusSurroundingsSortColumnDto.Type,
        },
    ];

    const fetchCategoriesForAdvancedFilters = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const response = await campusSurroundingTypeService.getCampusSurroundingTypes();
            if (response) {
                const typeAdvancedFilters = response
                    .filter((type) => type !== null)
                    .map((type) => type!)
                    .map(
                        (type) =>
                            ({
                                key: type.id,
                                displayNameKey: type.description,
                                checked: true,
                            } as AdvancedFilterItem)
                    );
                filterStoreRef.current.advancedFilters = [
                    {
                        key: 'type',
                        nameKey: 'Type',
                        items: typeAdvancedFilters,
                    },
                ];
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, campusSurroundingTypeService]);

    useEffect(() => {
        fetchCategoriesForAdvancedFilters();
    }, [fetchCategoriesForAdvancedFilters]);

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: GetCampusSurroundingsSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            const filterStore = filterStoreRef.current;
            const { checkedFilters, filterCount } = getAdvancedTableFiltersState(
                filterStoreRef.current,
                'type'
            );
            if (checkedFilters.length === 0) {
                setData([]);
            } else {
                try {
                    setLoading(true);
                    // call api

                    const request: GetCampusSurroundingsRequestDto = requestStore.setRequest({
                        request: {
                            pageSize: params.pagination.pageSize || 10,
                            page: (params.pagination.current || 1) - 1,
                            searchTerm: filterStore.searchTerm,
                            sortColumn: params.sortColumn,
                            sortDirection: params.sortDirection,
                            campusIds: [id],
                            typeIds: checkedFilters.length === filterCount ? [] : checkedFilters,
                        },
                        requestType: RequestType.AreaOfInterest,
                    });

                    const [data, totalItems] = await campusSurroundingService.getCampusSurroundings(
                        request
                    );
                    setData(data);
                    setPagination({
                        ...params.pagination,
                        total: totalItems,
                    });
                } finally {
                    setLoading(false);
                }
            }
        },
        [campusSurroundingService, requestStore]
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            fetch({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                sortColumn: GetCampusSurroundingsSortColumnDto.Title,
                sortDirection: SortDirection.Ascending,
                searchTerm: filterStore.searchTerm,
            });
        });
        return (): void => {
            disposer();
        };
    }, [fetch]);

    const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any): void => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }
        fetch({
            pagination,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
    };

    return (
        <div className="AreasOfInterest">
            <LocationHeader
                title={t('AreasOfInterest.areas_of_interest')}
                subTitle={t('AreasOfInterest.manage_areas_for_location')}
                defaultImg={<ForkKnife fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            setCreateModalOpen(true);
                        }}
                    >
                        {t('AreasOfInterest.create_area_of_interest')}
                    </Button>
                }
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeAdvancedFilters
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(item: CampusSurroundingDto) => item.id ?? ''}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(row: CampusSurroundingDto) => ({
                        onClick: (): void => {
                            onRowClick(row);
                        },
                    })}
                />
            </Content>

            {createModalOpen && (
                <EditAreaOfInterestModal
                    visible={createModalOpen}
                    onComplete={(success?: boolean, id?: string) => {
                        setCreateModalOpen(false);
                        if (success && id) {
                            history.push(`areas-of-interest/${id}`);
                        }
                    }}
                />
            )}
        </div>
    );
};

export default AreasOfInterest;
