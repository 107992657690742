// GENERATED FILE - DO NOT MODIFY

export enum NotificationTypeDto {
    QuoteCreated = "QuoteCreated",
    QuoteRefused = "QuoteRefused",
    RequestCreated = "RequestCreated",
    RequestRefused = "RequestRefused",
    SubscriptionCancelled = "SubscriptionCancelled",
    SubscriptionCreated = "SubscriptionCreated",
    SubscriptionExpiresSoon = "SubscriptionExpiresSoon",
    SubscriptionExpiresSoonManager = "SubscriptionExpiresSoonManager",
    SubscriptionConvertedToMonthToMonth = "SubscriptionConvertedToMonthToMonth",
    SubscriptionConvertedToMonthToMonthManager = "SubscriptionConvertedToMonthToMonthManager",
    SubscriptionDiscountsModified = "SubscriptionDiscountsModified",
    ManagerSubscriptionUpdated = "ManagerSubscriptionUpdated",
    InvoiceCreated = "InvoiceCreated",
    InvoiceStatusAwaiting = "InvoiceStatusAwaiting",
    InvoiceStatusFailed = "InvoiceStatusFailed",
    InvoiceStatusPaid = "InvoiceStatusPaid",
    InvoiceStatusVoid = "InvoiceStatusVoid",
    InvoiceStatusRefunded = "InvoiceStatusRefunded",
    MembershipInvitation = "MembershipInvitation",
    MembershipEmailInvitation = "MembershipEmailInvitation",
    RecurrenceErrors = "RecurrenceErrors",
    OneOnOne = "OneOnOne",
    Welcome = "Welcome",
    GuestCheckIn = "GuestCheckIn",
    GuestArrived = "GuestArrived",
    GuestCheckInSummary = "GuestCheckInSummary",
    GuestProposeAccountCreation = "GuestProposeAccountCreation",
    CoffeeOrderCreated = "CoffeeOrderCreated",
    CoffeeOrderStatusUpdated = "CoffeeOrderStatusUpdated",
    AccountConfirmation = "AccountConfirmation",
    WelcomeAccountCreatedByManager = "WelcomeAccountCreatedByManager",
    ResetPassword = "ResetPassword",
    ContactFormReceived = "ContactFormReceived",
    FeedbackReceived = "FeedbackReceived",
    ManagerTourBookingUpdated = "ManagerTourBookingUpdated",
    TourBookingConfirmation = "TourBookingConfirmation",
    TourReservationConfirmation = "TourReservationConfirmation",
    TourReservationManager = "TourReservationManager",
    JobPostReported = "JobPostReported",
    InvitaOrderReceived = "InvitaOrderReceived",
    GoogleCalendarSyncErrors = "GoogleCalendarSyncErrors",
    AccountingSyncStatusSummary = "AccountingSyncStatusSummary",
    EmailUpdatedConfirmation = "EmailUpdatedConfirmation",
    FlaggedCheckIn = "FlaggedCheckIn",
    InformationRequestConfirmation = "InformationRequestConfirmation",
    InformationRequestManagerInformation = "InformationRequestManagerInformation",
    SubscriptionRequestConfirmation = "SubscriptionRequestConfirmation",
    SubscriptionRequestManagerInformation = "SubscriptionRequestManagerInformation",
    AgreementTermsAdded = "AgreementTermsAdded",
    AgreementTermsSigned = "AgreementTermsSigned",
    LeadUnassigned = "LeadUnassigned",
    LeadAssigned = "LeadAssigned",
    SubscriptionProposalAnsweredManager = "SubscriptionProposalAnsweredManager",
    DayPassPurchaseManagerInformation = "DayPassPurchaseManagerInformation",
    OpportunityAssigned = "OpportunityAssigned",
    LeadStatusUpdated = "LeadStatusUpdated",
    OpportunityStatusUpdated = "OpportunityStatusUpdated",
    OpportunityCreated = "OpportunityCreated",
}