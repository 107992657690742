import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetMembershipPrepaidBalanceTransactionsRequestDto } from 'Api/Features/PrepaidBalance/Dtos/GetMembershipPrepaidBalanceTransactionsRequestDto';
import { GetPrepaidBalanceTransactionsSortColumnDto } from 'Api/Features/PrepaidBalance/Dtos/GetPrepaidBalanceTransactionsSortColumnDto';
import ButtonLinkWithIcon from 'Components/button-link-with-icon/button-link-with-icon';
import CompanyHeader, { CompanyHeaderData, CompanyTabs } from 'Components/company-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { useService, useStores } from 'Hooks';
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PrepaidBalanceService } from 'Services/PrepaidBalanceService';
import { SecurityDepositService } from 'Services/SecurityDepositService';
import { CompanyContext } from '..';
import './index.less';

const CompanyFinances: FunctionComponent = () => {
    const companyContext = useContext(CompanyContext);
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore } = useStores();
    const securityDepositService = useService(SecurityDepositService);
    const prepaidBalanceService = useService(PrepaidBalanceService);
    const [securityDepositTotal, setSecurityDepositTotal] = useState<number>();
    const [prepaidBalance, setPrepaidBalance] = useState<number>();

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `companies`,
            nameKey: 'companies',
        },
        {
            path: `${id}/dashboard`,
            nameKey: companyContext?.name ?? '',
        },
        {
            path: `finance`,
            nameKey: 'finance',
        },
    ];

    const getHeaderData = (): CompanyHeaderData => {
        return {
            title: t('finance'),
            subTitle: t('Membership.membership_finance_section_subtitle'),
            iconName: 'Dollar',
            securityDepositTotal,
            prepaidBalance,
        };
    };

    const fetchSecurityDeposit = useCallback(async () => {
        try {
            globalLoadingStore.addLoading();

            const response = await securityDepositService.getMembershipSecurityDepositSummary(id);
            if (response) {
                setSecurityDepositTotal(response.balance ?? 0);
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [id, globalLoadingStore, securityDepositService, toastStore]);

    const fetchPrepaidBalance = useCallback(async () => {
        try {
            globalLoadingStore.addLoading();
            const request: GetMembershipPrepaidBalanceTransactionsRequestDto = {
                page: 0,
                pageSize: 1,
                sortColumn: GetPrepaidBalanceTransactionsSortColumnDto.DateCreated,
                sortDirection: SortDirection.Descending,
            };

            const [response] = await prepaidBalanceService.getMembershipPrepaidBalanceTransactions(
                id,
                request
            );

            if (response) {
                setPrepaidBalance(response.length > 0 ? response[0].balance : 0);
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [id, prepaidBalanceService, globalLoadingStore, toastStore]);

    useEffect(() => {
        fetchPrepaidBalance();
    }, [fetchPrepaidBalance]);

    useEffect(() => {
        fetchSecurityDeposit();
    }, [fetchSecurityDeposit]);

    return (
        <div className="CompanyFinances">
            <CompanyHeader
                tab={CompanyTabs.Finance}
                company={companyContext}
                routes={breadcrumbs}
                headerData={getHeaderData()}
            />

            <div className="button-list-wrapper">
                <div>
                    <ButtonLinkWithIcon
                        linkTo="finance/invoices"
                        iconName="Bookmark"
                        label={t('invoices')}
                    />
                </div>

                <div>
                    <ButtonLinkWithIcon
                        linkTo="finance/prepaid-balance"
                        iconName="Dollar"
                        label={t('prepaid_balance')}
                    />
                </div>

                <div>
                    <ButtonLinkWithIcon
                        linkTo="finance/security-deposit"
                        iconName="DollarShield"
                        label={t('Reports.security_deposits')}
                    />
                </div>
            </div>
        </div>
    );
};

export default CompanyFinances;
