// GENERATED FILE - DO NOT MODIFY
import { ClosedLeadsReportDto } from 'Api/Features/Reports/Dtos/ClosedLeads/ClosedLeadsReportDto';
import { GetClosedLeadsReportRequestDto } from 'Api/Features/Reports/Dtos/ClosedLeads/GetClosedLeadsReportRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class ClosedLeadsReportProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getClosedLeadsReport(request: GetClosedLeadsReportRequestDto | null): Promise<ClosedLeadsReportDto | null> {
        const uri = this.buildUri(
            "/closed-leads-report",
            null,
            { ...request || undefined }
        );

        const data: ClosedLeadsReportDto | null = await this.httpClient.get<ClosedLeadsReportDto | null>(uri);
        return data;
    }

    public async exportClosedLeadsReport(request: GetClosedLeadsReportRequestDto | null): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/closed-leads-report/export",
            null,
            { ...request || undefined }
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }
}