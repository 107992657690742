import React, { FunctionComponent, ReactNode } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
    Mousewheel,
    Scrollbar
  } from 'swiper';
import 'swiper/swiper.less';
import 'swiper/swiper-bundle.css';
import './index.less';

//https://swiperjs.com/react

interface SideSwippingCarousel {
    elements: ReactNode[];
    spaceBetweenElements?: number;
    swipeDisabled?: boolean;
    showScrollbar?: boolean;
}

const SideSwippingCarousel: FunctionComponent<SideSwippingCarousel> = ({
    elements,
    spaceBetweenElements = 20,
    swipeDisabled,
    showScrollbar
}) => {

    // install Swiper modules
    SwiperCore.use([Scrollbar, Mousewheel]);

    return (
        <div className="SideSwippingCarousel">
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={spaceBetweenElements}
                freeMode
                observer
                allowTouchMove={!swipeDisabled}
                scrollbar={
                    showScrollbar
                        ? {
                              draggable: true,
                          }
                        : false
                }
                mousewheel
            >
                {elements.length > 0 &&
                    elements.map((element, i) => (
                        <SwiperSlide key={i} virtualIndex={i}>
                            {element}
                        </SwiperSlide>
                    ))}
            </Swiper>
        </div>
    );
};

export default React.memo(SideSwippingCarousel);

