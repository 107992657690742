import { Col, Input, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { Delete } from 'Components/icons';
import NumberInput from 'Components/NumberInput/number-input';
import SelectCustom, { SelectCustomOption } from 'Components/select-custom/select-custom';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { AddOn as AddOnModel } from 'Models/AddOn/AddOn';
import { MILLION } from 'Models/Constants';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'variant';
import './add-on.less';

interface AddOnProps {
    index: number;
    errors: Map<string, string[]>;
    remove: (index: number) => void;
    onPriceChange: (value: number, index: number) => void;
    onRemove: (index: number) => void;
    availableAddOns?: (AddOnModel | null)[];
    preSelectedValue?: string;
}

const formGutter: [Gutter, Gutter] = [40, 0];

const AddOn: FunctionComponent<AddOnProps> = ({
    index,
    errors,
    remove,
    onPriceChange,
    onRemove,
    availableAddOns,
    preSelectedValue,
}) => {
    const { t } = useTranslation();
    const [addOnsOptions, setAddOnsOptions] = useState<SelectCustomOption[]>([]);
    const [selectedAddOnId, setSelectedAddOnId] = useState<string | undefined>(undefined);
    const [suggestedPrice, setSuggestedPrice] = useState<string>();

    //#region Event handlers

    const handleAddOnSelect = useCallback(
        (value: string): void => {
            setSelectedAddOnId(value);
            const suggestedPrice =
                availableAddOns?.find((addOn) => addOn?.id === value)?.suggestedPrice?.toFixed(2) ??
                '0.00';
            setSuggestedPrice(suggestedPrice);
        },
        [availableAddOns]
    );

    //#endregion Event handlers

    useEffect(() => {
        if (availableAddOns) {
            if (
                selectedAddOnId &&
                !availableAddOns.map((addOn) => addOn?.id).includes(selectedAddOnId)
            ) {
                remove(index);
                return;
            }
            setAddOnsOptions(
                availableAddOns.map(
                    (addOn: AddOnModel | null) =>
                        ({
                            value: addOn?.id,
                            label: addOn?.name,
                            badge: undefined,
                        } as SelectCustomOption)
                )
            );
        } else {
            remove(index);
        }
    }, [availableAddOns, index, remove, selectedAddOnId]);

    useEffect(() => {
        if (preSelectedValue) handleAddOnSelect(preSelectedValue);
    }, [preSelectedValue, handleAddOnSelect]);
    //#endregion Effect

    return (
        <div className="AddOn">
            <div className="add-on-container">
                <Row gutter={formGutter}>
                    <Col span={12}>
                        <ValidatedFormItem
                            name={[index, 'id']}
                            errors={errors}
                            label={t('add_on')}
                            required
                            rules={[{ required: true, message: t('Proposal.add_on_required') }]}
                        >
                            <SelectCustom
                                options={addOnsOptions}
                                strongLabel={true}
                                placeholder={t('SelectCustom.placeholder_default')}
                                hideSelectedOptions={false}
                                onChange={(value): void => handleAddOnSelect(value.value)}
                                selected={selectedAddOnId ? [selectedAddOnId] : null}
                                idAttribute={`addOns_${index}_id`}
                            />
                        </ValidatedFormItem>
                    </Col>

                    <Col span={12} className="custom-price-input-container">
                        <Input
                            disabled
                            className="custom-price-suggested"
                            addonBefore={t('suggested')}
                            value={suggestedPrice}
                        />
                        <ValidatedFormItem
                            name={[index, 'price']}
                            errors={errors}
                            label={t('price')}
                            className="custom-price-actual"
                            required
                            rules={[{ required: true, message: t('Proposal.price_required') }]}
                        >
                            <NumberInput
                                min={0}
                                addonBefore={t('actual')}
                                addonAfter={t('monthly')}
                                type={'number'}
                                step={0.01}
                                max={MILLION}
                                onChange={(value): void => onPriceChange(Number(value), index)}
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>
            </div>

            <span
                className="garbage-container"
                onClick={(): void => {
                    remove(index);
                    onRemove(index);
                }}
            >
                <Delete fill={theme['primary-color']} />
            </span>
        </div>
    );
};

export default AddOn;
