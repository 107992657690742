import { MenuOutlined } from '@ant-design/icons';
import { Button, Menu, Table } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import arrayMove from 'array-move';
import BaseModal from 'Components/base-modal/base-modal';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import './reorder-modal.less';
import { useStores } from 'Hooks';
import { QuestionMarkLined } from 'Components/icons';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';

const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerWrapper = SortableContainer((props: any) => <tbody {...props} />);

interface TableRowElement {
    key: string | null | undefined;
    name: string | null | undefined;
    index: number | null | undefined;
}

export interface ReorderModel {
    id: string;
    name: string;
    order: number;
}

interface ReorderModalProps {
    visible: boolean;
    dataSource: ReorderModel[];
    manual?: boolean;
    onDismiss: () => void;
    onSubmit: (orderingMethod: OrderingMethodDto, ids: string[]) => void;
    modelName: string;
}

const ReorderModal: FunctionComponent<ReorderModalProps> = ({
    visible,
    dataSource,
    manual = false,
    onDismiss,
    onSubmit,
    modelName
}) => {
    const [data, setData] = useState<any>([]);
    const [isManual, setIsManual] = useState<boolean>(manual);
    const { confirmationModalStore } = useStores();

    const { t } = useTranslation();

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            className: 'drag-visible',
        },
        {
            title: 'Sort',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => isManual && <DragHandle />,
        },
    ];

    useEffect(() => {
        setData(
            dataSource.map(
                (x): TableRowElement => {
                    return {
                        key: x.id,
                        name: x.name,
                        index: x.order,
                    };
                }
            )
        );
    }, [dataSource, isManual]);

    useEffect(() => {
        if (!isManual) {
            let newOrder = 0;
            setData((prevState: any) => {
                return prevState
                    .sort((a: any, b: any) => a.name.localeCompare(b.name))
                    .map((x: any) => {
                        return {
                            ...x,
                            index: newOrder++,
                        };
                    });
            });
        }
    }, [isManual]);

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <QuestionMarkLined />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`confirm_positive_reorder`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        onDismiss();
    };


    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(data), oldIndex, newIndex).filter((el) => !!el);
            setData(newData);
        }
    };

    const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = data.findIndex((x: any) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const DraggableContainer = (props: any) => (
        <>
            <SortableContainerWrapper
                useDragHandle
                helperClass="row-dragging"
                onSortEnd={onSortEnd}
                {...props}
            />
        </>
    );

    const onSelectItem = (param: MenuInfo): void => {
        setIsManual(param.key === 'manual');
    };

    const handleSubmit = (): void => {
        const method = isManual ? OrderingMethodDto.Manual : OrderingMethodDto.Alphabetical;
        const ids = isManual ? data.map((x: TableRowElement) => x.key) : [];
        onSubmit(method, ids)
    }

    return (
        <BaseModal
            className="FormModal ReorderModal"
            visible={visible}
            width={800}
            title={
                <>
                    <div className="title">{t('reorder_model', {param1: modelName})}</div>
                    <Menu
                        theme="light"
                        mode="horizontal"
                        defaultSelectedKeys={[isManual ? 'manual' : 'alphabetical']}
                        onSelect={onSelectItem}
                    >
                        <Menu.Item key="alphabetical">Alphabetical</Menu.Item>
                        <Menu.Item key="manual">Manual</Menu.Item>
                    </Menu>
                </>
            }
            onCancel={(): Promise<void> => exit()}
        >
            <Table
                pagination={false}
                showHeader={false}
                dataSource={data}
                columns={columns}
                rowKey="index"
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
            />

            <div className="actions">
                <Button
                    type="default"
                    className="secondary negative"
                    htmlType="button"
                    onClick={(): void => onDismiss()}
                >
                    {t('cancel')}
                </Button>
                <Button
                    type="primary"
                    className="positive"
                    htmlType="submit"
                    onClick={(): void => handleSubmit()}
                >
                    {t('save_new_order')}
                </Button>
            </div>
        </BaseModal>
    );
};

export default ReorderModal;
