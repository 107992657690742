import { Col, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { UploadFile } from 'antd/lib/upload/interface';
import { GetSubscriptionOnboardingStatusResponseDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionOnboardingStatusResponseDto';
import { SubscriptionOnboardingCategoryStatusDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionOnboardingCategoryStatusDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Plan } from 'Components/icons';
import { ImagePicker } from 'Components/image-picker';
import { useStores } from 'Hooks';
import { ONBOARDING_CATEGORY_ID_SPACE } from 'Models/Constants';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './space-preparation-files.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface SpacePreperationModalProps {
    visible: boolean;
    onComplete: () => void;
    onboardingStatus?: GetSubscriptionOnboardingStatusResponseDto;
}

const SpacePreparationModal: FunctionComponent<SpacePreperationModalProps> = ({
    visible,
    onComplete,
    onboardingStatus,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [spaceCategory, setSpaceCategory] = useState<SubscriptionOnboardingCategoryStatusDto | null | undefined>();
    const { confirmationModalStore } = useStores();
    //#endregion

    useEffect(() => {
        const spacePrepCategory = onboardingStatus?.categories?.find(
            (category) => category?.onboardingCategoryId === ONBOARDING_CATEGORY_ID_SPACE
        );
        setSpaceCategory(spacePrepCategory);
    }, [onboardingStatus])


    //#region  Exit
    const dismiss = (): void => {
        onComplete();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Plan />,
                title: t(`confirm_title`),
                positiveText: t(`confirm_positive`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };
    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Subscription.space_preparation_and_inspection_files')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="SpacePreperationFiles">
                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ImagePicker
                            allowMultiple
                            images={
                                spaceCategory?.documents
                                    ? spaceCategory.documents
                                          .filter((image) => image !== null)
                                          .map((image) => image!)
                                          .map(
                                              (image) =>
                                                  ({
                                                      url: image.url,
                                                      uid: image.id,
                                                  } as UploadFile)
                                          )
                                    : undefined
                            }
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            setImagesDetails={(): void => {}}
                            disableAddingImages
                            disableRemovingImages
                        />
                    </Col>
                </Row>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(SpacePreparationModal);
