// GENERATED FILE - DO NOT MODIFY
import { GetOccupancyStatsReportRequestDto } from 'Api/Features/Reports/Dtos/OccupancyStats/GetOccupancyStatsReportRequestDto';
import { OccupancyStatsReportDto } from 'Api/Features/Reports/Dtos/OccupancyStats/OccupancyStatsReportDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class OccupancyStatsReportProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getOccupancyStatsReport(request: GetOccupancyStatsReportRequestDto | null): Promise<OccupancyStatsReportDto | null> {
        const uri = this.buildUri(
            "/occupancy-stats-report",
            null,
            { ...request || undefined }
        );

        const data: OccupancyStatsReportDto | null = await this.httpClient.get<OccupancyStatsReportDto | null>(uri);
        return data;
    }

    public async exportOccupancyStatsReport(request: GetOccupancyStatsReportRequestDto | null): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/occupancy-stats-report/export",
            null,
            { ...request || undefined }
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }
}