import React, { FunctionComponent } from 'react';
import { currencyFormatter } from 'Utils/NumberUtils';
import './security-deposit-swipper-element.less';

interface SecurityDepositSwipperElementProps {
    planName: string,
    units: string[],
    amount: number;
}

const SecurityDepositSwipperElement: FunctionComponent<SecurityDepositSwipperElementProps> = ({planName, units, amount}) => {
    return (
        <div className="SecurityDepositSwipperElement">
            <div className="plan">{planName}</div>
            <div className="units">{units.join(', ')}</div>
            <div className="amount">{currencyFormatter(amount)}</div>
        </div>
    );
};

export default React.memo(SecurityDepositSwipperElement);
