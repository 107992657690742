import React, {
    FunctionComponent,
    useState,
    useEffect,
    useRef,
    useCallback,
    ReactNode,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ListSectionHeader } from 'Components/list-section-header';
import { User } from 'Components/icons';
import { theme, images } from 'variant';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { useService } from 'Hooks';
import { ManagerUserService } from 'Services/ManagerUserService';
import { ManagerUser } from 'Models/ManagerUsers/ManagerUser';
import { Button, Layout, Table } from 'antd';
import { ManagementRoleWithContextDto } from 'Api/Features/Accounts/Dtos/ManagementRoleWithContextDto';
import ManagerUserModal from './create-manager';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { TableFilters } from 'Components/table-filters';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import FilterStore from 'Stores/FilterStore';
import { autorun } from 'mobx';
import { ALL_ROLES } from 'Models/Constants';
import { useHistory } from 'react-router-dom';
import './index.less';
import { ManagementRoleDto } from 'Api/Features/ManagerUsers/Dtos/ManagementRoleDto';

const { usersHeader } = images;
const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 10,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const breadcrumbs: BreadcrumbSegment[] = [
    {
        nameKey: 'Administration.administration_title',
        path: 'administration',
    },
    {
        nameKey: 'ManagerUser.managers',
        path: 'manager-users',
    },
];

const advancedFilters: AdvancedFilter[] = [];

const ManagerUsers: FunctionComponent = () => {
    const { t } = useTranslation();
    const managerUserService = useService(ManagerUserService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ManagerUser[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const paginationRef = useRef(initialPaginationState);
    const history = useHistory();

    const keyOrNA = (key: string | null) => key || '';

    const rolesRender = (roles: Array<ManagementRoleWithContextDto> | null): ReactNode => {
        const distinctRoles = Array.from(new Set(roles?.map((role) => role.name?.toString())));

        if (distinctRoles.includes(ManagementRoleDto.Manager.toString())) {
            const managerLocationIds = roles
                ?.filter((x) => x.name === ManagementRoleDto.Manager)
                .map((x) => x.context?.campusId);
            const managerString = managerUserService.getManagerForCampusesString(
                managerLocationIds as string[]
            );
            distinctRoles.push(managerString);
        }

        return distinctRoles
            ? distinctRoles.filter((x) => x !== ManagementRoleDto.Manager).join(', ')
            : '';
    };

    const columns = [
        {
            title: t('User.user_name'),
            dataIndex: 'name',
            render: (name: string): string => name,
        },
        {
            title: t('email'),
            dataIndex: 'email',
            render: keyOrNA,
        },
        {
            title: t('User.roles'),
            dataIndex: 'roles',
            render: rolesRender,
        },
    ];

    const getRoleId = (currentRoleId: string): any => {
        if (currentRoleId === ALL_ROLES) return undefined;

        return [currentRoleId];
    };

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            currentRoleId?: string;
        }) => {
            setLoading(true);
            const filterStore = filterStoreRef.current;
            try {
                // call api
                const [data, totalItems] = await managerUserService.getManagerUsers({
                    pageSize: params.pagination.pageSize || 10,
                    page: (params.pagination.current || 1) - 1,
                    searchTerm: filterStore.searchTerm,
                    role: getRoleId(filterStore.currentRoleId),
                });
                setData(data);
                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [managerUserService]
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            fetch({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                searchTerm: filterStore.searchTerm,
            });
        });
        return (): void => {
            disposer();
        };
    }, [fetch]);

    const handleTableChange = (pagination: TablePaginationConfig): void => {
        fetch({
            pagination,
        });
    };

    const onCreationComplete = useCallback(
        (success: boolean) => {
            setCreateModalOpen(false);
            if (success) {
                fetch({
                    pagination,
                });
            }
        },
        [fetch, pagination]
    );

    const onRowClick = (manager: ManagerUser): void => {
        history.push(`/administration/manager-users/${manager.id}`);
    };

    return (
        <div className="ManagerUsers">
            <ListSectionHeader
                title={t('ManagerUser.managers')}
                subTitle=""
                defaultImg={<User fill={theme['primary-color']} />}
                backgroundImageUrl={usersHeader}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            setCreateModalOpen(true);
                        }}
                    >
                        {t('ManagerUser.manager_create')}
                    </Button>
                }
                routes={breadcrumbs}
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeManagerRoles
                />
                <Table
                    className="table-striped-rows"
                    bordered
                    columns={columns}
                    rowKey={(manager: ManagerUser): string => manager.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(row: ManagerUser) => ({
                        onClick: (): void => {
                            onRowClick(row);
                        },
                    })}
                />
            </Content>
            {createModalOpen && (
                <ManagerUserModal visible={createModalOpen} onComplete={onCreationComplete} />
            )}
        </div>
    );
};

export default ManagerUsers;
