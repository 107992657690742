import { Form } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { CreateAccountFileRequestDto } from 'Api/Features/Accounts/Dtos/CreateAccountFileRequestDto';
import { GetAccountFilesRequestDto } from 'Api/Features/Accounts/Dtos/GetAccountFilesRequestDto';
import { StoredFileDto } from 'Api/Features/General/Dtos/StoredFileDto';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import ActivityLogTabs, { ActivityLogTab } from 'Components/activity-log-tabs/activity-log-tabs';
import AddFileModal, { AddFileFormModel } from 'Components/add-file-modal/index';
import ContactsHeader, { ContactData } from 'Components/contacts-header';
import EditNoteModal, { NoteEntityTypeEnum } from 'Components/edit-note-modal/edit-note-modal';
import Icon from 'Components/icons/icon';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import React, {
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { AccountFileService } from 'Services/AccountFileService';
import { MemberService } from 'Services/MemberService';
import { cleanVal } from 'Utils/NumberUtils';
import { ContactContext } from '..';
import CreditTransactionModal, { CreditTransactionRecipientType } from '../../modals/credit-transaction-modal';
import EditContactModal from '../../modals/edit-contact';
import ContactDetailsBody from './contact-details-body';
import GiveDayPassModal, { DayPassTransactionRecipientType } from 'Components/give-day-pass-modal/index';
import SendMessageModal from '../messages/send-message-modal';
import { AccountService } from 'Services/AccountService';
import { ForgottenPasswordRequestDto } from 'Api/Features/Accounts/Dtos/ForgottenPasswordRequestDto';
import CreateManualInvoiceModal from '../invoices/modals/create-manual-invoice-modal';
import { PaymentMethodHolderTypeDto } from 'Api/Features/PaymentMethods/Dtos/PaymentMethodHolderTypeDto';
import { MessageReceiverTypeDto } from 'Api/Features/Messages/Dtos/MessageReceiverTypeDto';

const ContactDashboard: FunctionComponent = () => {
    const memberService = useService(MemberService);
    const accountFileService = useService(AccountFileService);
    const accountService = useService(AccountService);
    const { confirmationModalStore, toastStore, globalLoadingStore } = useStores();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const contactContext = useContext(ContactContext);
    const [form] = Form.useForm();

    const [editNoteModalOpen, setEditNoteModalOpen] = useState(false);
    const [contactNote, setContactNote] = useState<string | null | undefined>();
    const [giveDayPassModalOpen, setGiveDayPassModalOpen] = useState(false);
    const [sendMessageModalIsOpen, setSendMessageModalIsOpen] = useState(false);
    const [createManualInvoiceModalIsOpen, setCreateManualInvoiceModalIsOpen] = useState(false);

    const [editContactModalOpen, setEditContactModalOpen] = useState(false);
    const [creditTransactionModalOpen, setCreditTransactionModalOpen] = useState(false);
    const [addFileModalOpen, setAddFileModalOpen] = useState(false);
    const [files, setFiles] = useState<StoredFileDto[]>([]);
    const [filesCurrentPage, setFilesCurrentPage] = useState(0);
    const [filesMaxResults, setFilesMaxResults] = useState(false);
    const filesPageSize = 5;
    const [filesAreLoading, setFilesAreLoading] = useState(false);

    const headerData = {
        title: `${cleanVal.string(contactContext?.salutation)} ${cleanVal.string(contactContext?.firstName)}  ${cleanVal.string(
            contactContext?.lastName
        )}`,
    };

    const contactData: ContactData = {
        imageUrl: contactContext?.imageUrl || undefined,
        isSubscribed: contactContext?.isSubscribed,
        paymentMethod: contactContext?.paymentMethod,
        creditBalance: contactContext?.creditBalance?.[0]?.totalAmount,
        availableDayPasses: contactContext?.availableDayPasses,
    };

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `contacts`,
            nameKey: 'User.contacts_label',
        },
        {
            path: `${id}/dashboard`,
            nameKey: headerData.title,
        },
    ];

    const deleteContact = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Trash" />,
                title: t(`User.contact_profile_delete_contact_confirm_title`),
                message: t(`action_cannot_be_undone`),
                positiveText: t(`User.contact_profile_delete_contact_confirm_label`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        
        try {
            globalLoadingStore.addLoading();
            await memberService.deleteMember(id);
            toastStore.toast({
                type: 'success',
                messageKey: `User.contact_profile_delete_contact_confirm_message`,
            });
    
            history.push('/contacts');
        }
        catch (e) {
            if (e.response?.data?.error === 'E005416') {
                toastStore.toast({
                    type: 'error',
                    messageKey: e.response.data.error_description
                })
            }
            else if (e.response?.data?.error === 'E005405') {
                toastStore.toast({
                    type: 'error',
                    message: e.response.data.error_description
                })
            }
            else if (!e.treated) {
                toastStore.genericError()
            }
        }
        finally {
            globalLoadingStore.removeLoading();
        }
    };

    const sendPasswordReset = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="User" />,
                title: t(`Contact.send_this_member_password_reset`),
                message: t(`Contact.password_reset_24h`),
                positiveText: t('yes'),
                negativeText: t('no'),
            }))
        )
            return;
        
        try {
            globalLoadingStore.addLoading();
            const request: ForgottenPasswordRequestDto = {
                email: contactContext?.contactInfo?.email
            }
            await accountService.forgotPassword(request);
            toastStore.toast({
                type: 'success',
                messageKey: `Contact.reset_password_sent_successfully`,
            });
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const menuOptions: ActionMenuOption[] = [
        {
            key: 'sendMessage',
            title: t('Contact.contacts_messages_send_label'),
            action: () => setSendMessageModalIsOpen(true),
        },
        {
            key: 'createInvoice',
            title: t('Contact.contacts_invoices_create_manual_invoice'),
            action: () => setCreateManualInvoiceModalIsOpen(true),
        },
        {
            key: 'resetPasswordEmail',
            title: t('Contact.send_password_reset_email'),
            action: () => sendPasswordReset(),
        },
        {
            key: 'creditTransaction',
            title: t('Contact.make_credit_transaction'),
            action: (): void => setCreditTransactionModalOpen(true),
        },
        {
            key: 'giveDayPass',
            title: t('Membership.give_a_day_pass'),
            action: () => setGiveDayPassModalOpen(true),
        },
        {
            key: 'edit',
            title: t('User.contacts_edit_contact'),
            action: (): void => setEditContactModalOpen(true),
        },
        {
            key: 'delete',
            title: t('User.contact_profile_delete_contact'),
            action: (): Promise<void> => deleteContact(),
        },
        
    ];

    const actionButtons = <ActionMenu options={menuOptions} type="primary" trigger="click" />;

    const resetFilesSearch = (): void => {
        setFilesAreLoading(true);
        setFilesMaxResults(false);
        setFiles([]);
        setFilesCurrentPage(0);
    };

    const handleFileUploadSubmit = async (
        isSubmiting: boolean,
        formModel?: AddFileFormModel
    ): Promise<void> => {
        if (isSubmiting && formModel) {
            try {
                globalLoadingStore.addLoading();
                await accountFileService.createAccountFile(
                    id,
                    formModel as CreateAccountFileRequestDto
                );
                toastStore.toast({
                    type: 'success',
                    messageKey: t('file_added_successfully'),
                });
                setAddFileModalOpen(false);
                resetFilesSearch();
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                debounceFetchFiles.current(0);
            } catch (e) {
                if (e.response.data.modelState['file.FileName'] !== undefined) {
                    toastStore.toast({
                        type: 'error',
                        messageKey: e.response.data.modelState['file.FileName'][0],
                    });
                } else if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        } else {
            setAddFileModalOpen(false);
        }
    };

    const handleFileClick = (url?: string): void => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    const deleteFile = async (fileId: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="File" />,
                title: t(`model_delete_confirm_title`, { param1: 'file' }),
                message: t(`action_cannot_be_undone`),
                positiveText: t('model_confirm_positive_delete', { param1: 'file' }),
                negativeText: t('model_confirm_negative_delete', { param1: 'file' }),
            }))
        )
            return;
        //call delete
        await accountFileService.deleteAccountFile(id, fileId);
        toastStore.toast({
            type: 'success',
            messageKey: t('model_delete_success', { param1: 'File' }),
        });
        resetFilesSearch();
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        debounceFetchFiles.current(0);
    };

    const handleFileDeleteClick = async (id?: string): Promise<void> => {
        if (id) {
            await deleteFile(id);
        }
    };

    const fetchFiles = async (currentPage: number): Promise<StoredFileDto[]> => {
        try {
            globalLoadingStore.addLoading();

            const [results, totalItemCount] = await accountFileService.getAccountFiles(id, {
                page: currentPage,
                pageSize: filesPageSize,
            } as GetAccountFilesRequestDto);

            if (results.length + filesPageSize * currentPage >= totalItemCount) {
                setFilesMaxResults(true);
            }

            return results;
        } finally {
            globalLoadingStore.removeLoading();
            setFilesAreLoading(false);
        }
    };

    const debounceFetchFiles = useRef(
        debounce((page: number) => {
            fetchFiles(page).then((results) => {
                setFiles((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    useEffect(() => {
        debounceFetchFiles.current(filesCurrentPage);
    }, [filesCurrentPage]);

    const handleFileListScrolledToBottom = (): void => {
        if (!filesMaxResults) {
            const currentPage = filesCurrentPage + 1;
            setFilesCurrentPage(currentPage);
        }
    };

    const fetchNote = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            const response = await memberService.getMemberNote(id);
            setContactNote(response?.note);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, id, memberService, toastStore]);

    useEffect(() => {
        fetchNote();
    }, [fetchNote]);

    return (
        <div className="ContactDashboard">
            <ContactsHeader
                routes={breadcrumbs}
                headerData={headerData}
                contactData={contactData}
                note={
                    <ActivityLogTabs
                        form={form}
                        tabs={[ActivityLogTab.files, ActivityLogTab.notes]}
                        onAddFileClick={(): void => setAddFileModalOpen(true)}
                        files={files}
                        onFileClick={handleFileClick}
                        onFileDeleteClick={handleFileDeleteClick}
                        onFilesScrolledToBottom={handleFileListScrolledToBottom}
                        filesAreLoading={filesAreLoading}
                        onEditNoteClick={(): void => setEditNoteModalOpen(true)}
                        note={contactNote}
                    />
                }
                action={actionButtons}
            />

            <Content>{contactContext && <ContactDetailsBody contact={contactContext} />}</Content>

            {addFileModalOpen && (
                <AddFileModal visible={addFileModalOpen} onComplete={handleFileUploadSubmit} />
            )}

            {editNoteModalOpen && (
                <EditNoteModal
                    visible={editNoteModalOpen}
                    onComplete={(success: boolean) => {
                        if (success) fetchNote();
                        setEditNoteModalOpen(false);
                    }}
                    entityId={contactContext?.id}
                    entityType={NoteEntityTypeEnum.Contact}
                    note={contactNote}
                />
            )}

            {creditTransactionModalOpen && (
                <CreditTransactionModal
                    visible={creditTransactionModalOpen}
                    onComplete={(success: boolean) => {
                        if (success) contactContext?.fetchContact();
                        setCreditTransactionModalOpen(false);
                    }}
                    creditTransactionRecipientType={CreditTransactionRecipientType.Account}
                />
            )}

            {editContactModalOpen && (
                <EditContactModal
                    visible={editContactModalOpen}
                    onComplete={(success) => {
                        if (success) contactContext?.fetchContact();
                        setEditContactModalOpen(false);
                    }}
                />
            )}

            {giveDayPassModalOpen && (
                <GiveDayPassModal
                    visible={giveDayPassModalOpen}
                    dayPassTransactionRecipientType={DayPassTransactionRecipientType.Contact}
                    onComplete={(success: Boolean): void => {
                        if (success) contactContext?.fetchContact();
                        setGiveDayPassModalOpen(false);
                    }}
                    entityId={contactContext?.id!}
                />
            )}

            {sendMessageModalIsOpen && contactContext && (
                <SendMessageModal
                    visible={sendMessageModalIsOpen}
                    onComplete={(): void => {
                        setSendMessageModalIsOpen(false);
                    }}
                    receiverId={contactContext.id ?? ''}
                    name={`${contactContext.firstName} ${contactContext.lastName}`}
                    messageReceiverType={MessageReceiverTypeDto.Account}
                />
            )}

            {createManualInvoiceModalIsOpen && (
                <CreateManualInvoiceModal
                    visible={createManualInvoiceModalIsOpen}
                    entityId={id}
                    paymentMethodHolderType={PaymentMethodHolderTypeDto.Personal}
                    onComplete={() => setCreateManualInvoiceModalIsOpen(false)}
                />
            )}
        </div>
    );
};

export default ContactDashboard;
