import { Button, Col,  Form, Input, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { SubscriptionProposalDetailsDto } from 'Api/Features/SubscriptionProposals/Dtos/SubscriptionProposalDetailsDto';
import { UpdateSubscriptionProposalTitleRequestDto } from 'Api/Features/SubscriptionProposals/Dtos/UpdateSubscriptionProposalTitleRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Plan } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EditProposalTitleSchema } from 'Schemas';
import { SubscriptionProposalService } from 'Services/SubscriptionProposalService';
import './edit-title-modal.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface EditTourModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    proposal?: SubscriptionProposalDetailsDto | null;
}

const EditProposalTitleModal: FunctionComponent<EditTourModalProps> = ({
    visible,
    onComplete,
    proposal
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(EditProposalTitleSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const proposalService = useService(SubscriptionProposalService);

    //#region Effects
    useEffect(() => {
        form.setFieldsValue({
            title: proposal?.title ?? undefined
        })
    }, [proposal, form])
    //#endregion

    //#region Event handlers
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Plan />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`Proposal.edit_title_confirm_message_positive`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const data = {
            title: formValues.title
        } as UpdateSubscriptionProposalTitleRequestDto;

        if (!(await validateForm(data))) return;

        try {
            globalLoadingStore.addLoading();

            await proposalService.updateSubscriptionProposalTitle(proposal?.id!, data);

            toastStore.toast({
                type: 'success',
                messageKey: `Proposal.title_edit_success`,
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Proposal.edit_proposal_title')}
            className="FormModal EditProposalTitleModal"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem
                            errors={errors}
                            name="title"
                            label={t('Lead.title')}
                            required
                        >
                            <Input />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>
                    <Button type="primary" className="positive" htmlType="submit">
                        {t('save')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(EditProposalTitleModal);
