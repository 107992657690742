import { Button, Col, Form, Row, Select } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { SelectValue } from 'antd/lib/select';
import { CreateDayPassRequestDto } from 'Api/Features/DayPasses/Dtos/CreateDayPassRequestDto';
import { DayPassTypeDto } from 'Api/Features/DayPassTypes/Dtos/DayPassTypeDto';
import { GetDayPassTypesRequestDto } from 'Api/Features/DayPassTypes/Dtos/GetDayPassTypesRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Plan } from 'Components/icons';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { debounce } from 'lodash';
import { LightCampusDetailsInfo } from 'Models/Location/LightCampusInfo';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { GiveDayPassSchema } from 'Schemas/GiveDayPassSchema';
import { DayPassService } from 'Services/DayPassService';
import { DayPassTypeService } from 'Services/DayPassTypeService';
import { currencyFormatter } from 'Utils/NumberUtils';
import './index.less';

const { Option } = Select;

const formGutter: [Gutter, Gutter] = [40, 0];

interface GiveDayPassModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    entityId: string;
    dayPassTransactionRecipientType: DayPassTransactionRecipientType;
}

export enum DayPassTransactionRecipientType {
    Membership = 'Membership',
    Contact = 'Contact'
}

const GiveDayPassModal: FunctionComponent<GiveDayPassModalProps> = ({
    visible,
    onComplete,
    entityId,
    dayPassTransactionRecipientType
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(
        GiveDayPassSchema,
        form
    );
    const { globalLoadingStore, toastStore, confirmationModalStore, locationStore } = useStores();
    const dayPassTypeService = useService(DayPassTypeService);
    const dayPassService = useService(DayPassService);

    const pageSize = 25;
    const [dayPassTypeCurrentPage, setDayPassTypeCurrentPage] = useState(0);
    const [dayPassTypeSearchResults, setDayPassTypeSearchResults] = useState<DayPassTypeDto[]>([]);
    const [dayPassTypeSearchTerm, setDayPassTypeSearchTerm] = useState('');
    const [dayPassTypeMaxResults, setDayPassTypeMaxResults] = useState(false);
    const [dayPassTypeOptions, setDayPassTypeOptions] = useState<SelectCustomOption[]>([]);
    const [selectedDayPassTypeOptions, setSelectedDayPassTypeOptions] = useState<SelectCustomOption[]>([]);
    const [selecteddayPassTypeId, setSelectedDayPassTypeId] = useState<string[]>([]);
    const [selectLoading, setSelectLoading] = useState(false);

    const [selectedLocationId, setSelectedLocationId] = useState<string>();
    //#endregion



    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Plan />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`confirm_positive`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const validateObject = {
            membershipId: entityId,
            typeId: formValues.typeId?.value ?? undefined,
            noCharge: true,
            campusId: formValues.campusId ?? undefined
        }

        if (!(await validateForm(validateObject))) return;

        try {
            const request: CreateDayPassRequestDto = {
                membershipId: dayPassTransactionRecipientType === DayPassTransactionRecipientType.Membership ? entityId : undefined,
                accountId: dayPassTransactionRecipientType === DayPassTransactionRecipientType.Contact ? entityId : undefined,
                typeId: formValues.typeId?.value ?? undefined,
                noCharge: true,
            }

            globalLoadingStore.addLoading();
            await dayPassService.createDayPass(request);
            toastStore.toast({
                type: 'success',
                messageKey: 'DayPass.give_day_pass_success',
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    const searchDayPassTypes = async (page: number, searchTerm: string, locationId: string): Promise<DayPassTypeDto[]> => {
        const request: GetDayPassTypesRequestDto = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
            campusIds: [locationId]
        };
        const [results, totalItemCount] = await dayPassTypeService.getDayPassTypes(request);

        if (results.length + pageSize * page >= totalItemCount) {
            setDayPassTypeMaxResults(true);
        }
        setSelectLoading(false);
        return results;
    };

    const debounceDayPassTypesSearch = useRef(
        debounce((page: number, searchTerm: string, locationId: string) => {
            searchDayPassTypes(page, searchTerm, locationId).then((results) => {
                setDayPassTypeSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    useEffect(() => {
        if(selectedLocationId !== undefined) {
            setSelectLoading(true);
            debounceDayPassTypesSearch.current(dayPassTypeCurrentPage, dayPassTypeSearchTerm, selectedLocationId);
        }
    }, [dayPassTypeCurrentPage, dayPassTypeSearchTerm, selectedLocationId]);

    useEffect(() => {
        const searchResults = dayPassTypeSearchResults?.map(
            (x: DayPassTypeDto) =>
                ({
                    value: x?.id,
                    label: x?.name,
                    content: currencyFormatter(x.price ?? 0),
                    badge: undefined,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedDayPassTypeOptions);

        setDayPassTypeOptions(merged);
    }, [dayPassTypeSearchResults, t, selectedDayPassTypeOptions]);

    const handleDayPassTypeKeywordsChange = useCallback((value: string): void => {
        resetDayPassTypeSearch();
        setDayPassTypeSearchTerm(value);
    }, []);
    
    const resetDayPassTypeSearch = (): void => {
        setDayPassTypeCurrentPage(0);
        setDayPassTypeSearchResults([]);
        setDayPassTypeSearchTerm('');
        setDayPassTypeMaxResults(false);
    };

    const handleDayPassTypeMenuScrollToBottom = (): void => {
        if (!dayPassTypeMaxResults) {
            setDayPassTypeCurrentPage((prevPage) => prevPage + 1);
        }
    };

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Membership.give_a_day_pass')}
            className="FormModal GiveDayPassModal"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Row gutter={formGutter}>
                    <Col span={12}>
                        <ValidatedFormItem
                            name="campusId"
                            errors={errors}
                            label={t('location')}
                            required
                        >
                            <Select
                                onChange={(value: SelectValue) => {
                                    setSelectedLocationId(value.toString());
                                    resetDayPassTypeSearch();
                                    setSelectedDayPassTypeId([]);
                                    setSelectedDayPassTypeOptions([])
                                }}
                            >
                                {locationStore.locations?.map((x: LightCampusDetailsInfo) => (
                                    <Option key={x.id!} value={x.id!}>
                                        {x.name}
                                    </Option>
                                ))}
                            </Select>
                        </ValidatedFormItem>
                    </Col>
                    <Col span={12}>
                        <ValidatedFormItem
                            name="typeId"
                            errors={errors}
                            label={t('type')}
                            required
                        >
                            <SelectCustom
                                options={dayPassTypeOptions}
                                strongLabel
                                placeholder={t('SelectCustom.placeholder_default')}
                                onKeywordsChange={handleDayPassTypeKeywordsChange}
                                onMenuScrollToBottom={handleDayPassTypeMenuScrollToBottom}
                                onChange={(value: any): void => {
                                    setSelectedDayPassTypeId([value.value]);
                                    const options = [value as SelectCustomOption];
                                    setSelectedDayPassTypeOptions(options);
                                }}
                                hideSelectedOptions={false}
                                selected={selecteddayPassTypeId}
                                isLoading={selectLoading}
                                disabled={selectedLocationId === undefined}
                                idAttribute='typeId'
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>
                    <Button type="primary" className="positive" htmlType="submit">
                        {t('submit')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(GiveDayPassModal);
