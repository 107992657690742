import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { TourProxy } from 'Api/Features/Tours/TourProxy';
import { CreateTourRequestDto } from 'Api/Features/Tours/Dtos/CreateTourRequestDto';
import { UpdateTourRequestDto } from 'Api/Features/Tours/Dtos/UpdateTourRequestDto';
import { dateMomentToString } from 'Utils/TimeUtils';

@inject(TourProxy)
export class TourService extends ApiService {
    constructor(private readonly tourProxy: TourProxy) {
        super();
    }

    public async createTour(request: CreateTourRequestDto): Promise<string | null> {
        request.startTime = dateMomentToString(request.startTime);
        request.endTime = dateMomentToString(request.endTime);

        return await this.tourProxy.createTour(request);
    }

    public async updateTour(id: string, request: UpdateTourRequestDto): Promise<void> {
        request.startTime = dateMomentToString(request.startTime);
        request.endTime = dateMomentToString(request.endTime);

        await this.tourProxy.updateTour(id, request);
    }

    public async cancelTour(id: string): Promise<void> {
        await this.tourProxy.deleteTour(id);
    }
}
