import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import Icon from 'Components/icons/icon';
import React, { FunctionComponent } from 'react';
import './base-modal.less';

const BaseModal: FunctionComponent<ModalProps> = ({
    children,
    centered = true,
    closeIcon = <Icon iconName="Close" />,
    footer = null,
    maskClosable = false,
    width = 960,
    ...props
}) => {
    return (
        <Modal
            centered={centered}
            closeIcon={closeIcon}
            footer={footer}
            maskClosable={maskClosable}
            width={width}
            {...props}
        >
            {children}
        </Modal>
    );
};

export default BaseModal;
