import { Skeleton } from 'antd';
import Title from 'antd/lib/typography/Title';
import { GetMembershipsFlagDto } from 'Api/Features/Memberships/Dtos/GetMembershipsFlagDto';
import { MembershipDetailsPlusDto } from 'Api/Features/Memberships/Dtos/MembershipDetailsPlusDto';
import { AchPaymentMethodDto } from 'Api/Features/PaymentMethods/Dtos/AchPaymentMethodDto';
import { CreditCardPaymentMethodDto } from 'Api/Features/PaymentMethods/Dtos/CreditCardPaymentMethodDto';
import { PaymentMethodTypeDto } from 'Api/Features/PaymentMethods/Dtos/PaymentMethodTypeDto';
import { InvoiceData } from 'Components/contacts-header';
import Icon from 'Components/icons/icon';
import { RoutedBreadcrumb } from 'Components/routed-breadcrumb';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { Tag } from 'Components/tag';
import { getInvoiceStatusTag, TagColors } from 'Components/tag/tag';
import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyContext } from 'Routes/authenticated/companies/id';
import { currencyFormatter } from 'Utils/NumberUtils';
import { images, theme } from '../../variant';
import { ImageWithPlaceholder } from '../image-with-placeholder';
import { ImageWithPlaceholderProps } from '../image-with-placeholder/image-with-placeholder';
import './index.less';

export enum CompanyTabs {
    Dashboard = 'Dashboard',
    Finance = 'Finance',
    Messages = 'Messages',
}

export interface CompanyHeaderData {
    iconName?: string;
    title?: string | null;
    subTitle?: string;
    imageUrl?: string | null;
    tags?: ReactNode;
    prepaidBalance?: number;
    securityDepositTotal?: number
}

interface CompanyHeaderProps extends ImageWithPlaceholderProps {
    routes?: BreadcrumbSegment[];
    loading?: boolean;
    company?: CompanyContext;
    action?: ReactNode;
    tab?: CompanyTabs;
    note?: ReactNode;
    availableDayPassCount?: number,
    headerData: CompanyHeaderData
    showSubSection?: boolean,
    invoiceData?: InvoiceData
}

const CompanyHeader: FunctionComponent<CompanyHeaderProps> = ({
    routes = [],
    loading,
    company,
    action,
    tab,
    note,
    availableDayPassCount,
    headerData,
    showSubSection,
    invoiceData
}) => {
    const { t } = useTranslation();

    const renderStatus = (company: MembershipDetailsPlusDto): ReactNode => {
        const statuses: GetMembershipsFlagDto[] = [];
        company?.isSubscribed
            ? statuses.push(GetMembershipsFlagDto.IsSubscribed)
            : statuses.push(GetMembershipsFlagDto.NoSubscriptions);
        if (company?.hasPendingOnboarding)
            statuses.push(GetMembershipsFlagDto.HasPendingOnboarding);
        if (company?.hasPendingOpportunity)
            statuses.push(GetMembershipsFlagDto.HasPendingOpportunity);

        return statuses.map((status) => (
            <Tag
                label={t(`Membership.GetMembershipsFlagDto_${status}`)}
                key={status}
                color={
                    status === GetMembershipsFlagDto.NoSubscriptions
                        ? TagColors.slateBlue40
                        : TagColors.default
                }
            />
        ));
    };

    const renderPaymentMethod = (): string => {
        switch (company?.paymentMethod?.paymentMethodType) {
            case PaymentMethodTypeDto.ACH: {
                const achInfo = company.paymentMethod as AchPaymentMethodDto;
                return `${achInfo.bankName} ${achInfo.accountNumber}`;
            }
            case PaymentMethodTypeDto.CreditCard: {
                const creditCardInfo = company.paymentMethod as CreditCardPaymentMethodDto;
                return `${creditCardInfo.brand} ending with ${creditCardInfo.last4}`;
            }
            case PaymentMethodTypeDto.Unavailable:
            default:
                return t('none');
        }
    }

    return (
        <div
            className="CompanyHeader"
            style={{
                backgroundImage: `linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(${images.usersHeader}`,
            }}
        >
            <Skeleton loading={loading} active avatar>
                <ImageWithPlaceholder
                    className="header-icon"
                    imgSrc={headerData.imageUrl || undefined}
                    defaultImg={
                        <Icon
                            iconName={headerData?.iconName ? headerData.iconName : 'Company'}
                            fill={theme['primary-color']}
                        />
                    }
                />

                <div className="header-info header-info-content">
                    <RoutedBreadcrumb routes={routes} />

                    <Title level={1}>{headerData.title}</Title>

                    {headerData?.subTitle && <div>{headerData?.subTitle}</div>}

                    {company && tab === CompanyTabs.Dashboard && renderStatus(company)}

                    {headerData.tags && headerData.tags}

                    {showSubSection && (
                        <div className="header-info-lower-section">
                            <div className="credit-balance">
                                <Title level={2}>{t('Membership.credit_balance_total')}</Title>
                                <strong className="label-value">
                                    {company?.creditBalance?.totalAmount} {t('credits')}
                                </strong>
                                <div className="from-sub">
                                    {company?.creditBalance?.plansAmount}{' '}
                                    {t('Membership.credits_from_subscriptions')}
                                </div>
                            </div>
                            <div>
                                <Title level={2}>{t('User.contacts_available_day_passes')}</Title>
                                <strong className="label-value">{availableDayPassCount}</strong>
                            </div>
                        </div>
                    )}

                    {invoiceData && (
                        <div className="invoice-info">
                            <div>{getInvoiceStatusTag(invoiceData.invoice, t)}</div>
                            <div>
                                <Title level={2}>{t('Contact.contacts_invoices_amount_due')}</Title>
                                <strong className="label-value">
                                    {currencyFormatter(invoiceData?.amountDue)}
                                </strong>
                            </div>
                        </div>
                    )}
                </div>

                {tab === CompanyTabs.Finance && (
                    <div className="header-info-content finance-tab">
                        <div className="finance-tab-payment-method">
                            <Title level={2}>{t('Reports.payment_method')}</Title>
                            <strong className="label-value">
                                {renderPaymentMethod()}
                            </strong>
                        </div>

                        <div className="finance-tab-balance">
                            <div className="finance-tab-prepaid-balance">
                                <Title level={2}>{t('prepaid_balance')}</Title>
                                <strong className="label-value">
                                    {currencyFormatter(headerData?.prepaidBalance ?? 0)}
                                </strong>
                            </div>

                            <div className="finance-tab-security-deposit">
                                <Title level={2}>{t('security_deposit_total')}</Title>
                                <strong className="label-value">
                                    {currencyFormatter(headerData?.securityDepositTotal ?? 0)}
                                </strong>
                            </div>
                        </div>
                    </div>
                )}

                {note && <div className="header-note">{note}</div>}

                {action && <div className="header-action">{action}</div>}
            </Skeleton>
        </div>
    );
};
export default CompanyHeader;
