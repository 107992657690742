import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const DayPass: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.28404 15.35H14.6345V4.73488H1.28404V15.35ZM1.28404 1.79425H4.59548V2.61962C4.59548 2.78956 4.73342 2.92859 4.90335 2.92859C5.07438 2.92859 5.21231 2.78956 5.21231 2.61962V1.79425H10.6678V2.61962C10.6678 2.78956 10.8057 2.92859 10.9767 2.92859C11.1467 2.92859 11.2846 2.78956 11.2846 2.61962V1.79425H14.6345V4.11811H1.28404V1.79425ZM14.9423 1.17738H11.2844V0.308966C11.2844 0.139034 11.1464 0 10.9765 0C10.8055 0 10.6675 0.139034 10.6675 0.308966V1.17738H5.2121V0.308966C5.2121 0.139034 5.07416 0 4.90313 0C4.7332 0 4.59527 0.139034 4.59527 0.308966V1.17738H0.974854C0.804923 1.17738 0.666992 1.31531 0.666992 1.48524V15.6579C0.666992 15.829 0.804923 15.9669 0.974854 15.9669H14.9423C15.1133 15.9669 15.2513 15.829 15.2513 15.6579V1.48524C15.2513 1.31531 15.1133 1.17738 14.9423 1.17738Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.2852 12.8008H12.6852V11.4009H11.2852V12.8008ZM12.9932 13.4177H10.9766C10.8062 13.4177 10.6682 13.2795 10.6682 13.1093V11.0926C10.6682 10.9222 10.8062 10.7842 10.9766 10.7842H12.9932C13.1636 10.7842 13.3017 10.9222 13.3017 11.0926V13.1093C13.3017 13.2795 13.1636 13.4177 12.9932 13.4177Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default DayPass;
