// GENERATED FILE - DO NOT MODIFY

export enum GetOpportunitiesFlagDto {
    New = "New",
    TourScheduled = "TourScheduled",
    Qualified = "Qualified",
    Proposal = "Proposal",
    Agreed = "Agreed",
    AgreementSent = "AgreementSent",
    AgreementSigned = "AgreementSigned",
    Lost = "Lost",
    IsSubscriptionModification = "IsSubscriptionModification",
    IsSubscriptionRenewal = "IsSubscriptionRenewal",
    IsNewSubscription = "IsNewSubscription",
}