import { Button, Skeleton, Tooltip, Typography } from 'antd';
import { LeadAccountDto } from 'Api/Features/Leads/Dtos/LeadAccountDto';
import { SubscriptionAncestryDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionAncestryDto';
import { SubscriptionDetailsRelatedOpportunityDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsRelatedOpportunityDto';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { PencilBorder as PencilIcon, User as UserIcon } from 'Components/icons';
import { SimpleList } from 'Components/simple-list';
import Tag, {
    getDetailHeaderSubscriptionTagStatuses,
    getSubscriptionStatusTagColor,
    TagColors,
} from 'Components/tag/tag';
import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { images, theme } from 'variant';
import { ImageWithPlaceholder } from '../image-with-placeholder';
import { RoutedBreadcrumb } from '../routed-breadcrumb';
import { BreadcrumbSegment } from '../routed-breadcrumb/routed-breadcrumb';
import './detail-header.less';

const { Title, Text } = Typography;
const { usersHeader } = images;

export interface DetailHeaderProps {
    title?: string;
    editableTitle?: ReactNode;
    menuOptions?: ActionMenuOption[];
    routes?: BreadcrumbSegment[];
    loading?: boolean;
    tags?: ReactNode[];
    label?: string;
    ownerData?: LeadAccountDto | null;
    subscriptionStatuses?: string[];
    subscriptionReferenceNumber?: string;
    onEditReferenceNumberClick?: () => void;
    relatedOpportunity?: SubscriptionDetailsRelatedOpportunityDto | null;
    onGoToOpportunityClick?: () => void;
    displayTags?: boolean;
    onGoToCurrentSubscriptionVersionClick?: () => void;
    displayRefNumber?: boolean;
    subscriptionAncestryInfo?: SubscriptionAncestryDto;
}

const DetailHeader: FunctionComponent<DetailHeaderProps> = ({
    title,
    editableTitle,
    routes,
    loading,
    tags,
    label,
    ownerData,
    menuOptions,
    subscriptionStatuses,
    subscriptionReferenceNumber,
    onEditReferenceNumberClick,
    relatedOpportunity,
    onGoToOpportunityClick,
    displayTags,
    onGoToCurrentSubscriptionVersionClick,
    displayRefNumber,
    subscriptionAncestryInfo,
}) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div
            className="DetailHeader"
            style={{
                backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),url(${usersHeader})`,
            }}
        >
            <Skeleton loading={loading} active avatar>
                <div className="header-info">
                    {routes && <RoutedBreadcrumb routes={routes} />}

                    {title ? <Title className="header-title">{title}</Title> : null}

                    {editableTitle && editableTitle}

                    {displayRefNumber && (
                        <div className="sub-ref-number">
                            {subscriptionReferenceNumber
                                ? subscriptionReferenceNumber
                                : t('Subscription.subscription_no_reference_number')}

                            <Tooltip title={t('Subscription.subscription_reference_number')}>
                                <Button
                                    icon={<PencilIcon />}
                                    onClick={onEditReferenceNumberClick}
                                />
                            </Tooltip>
                        </div>
                    )}

                    {tags && tags}

                    {displayTags &&
                        subscriptionStatuses &&
                        subscriptionStatuses.map((status) => (
                            <Tag
                                key={status}
                                label={t(status)}
                                color={getSubscriptionStatusTagColor(status)}
                            />
                        ))}

                    {label && <Tag label={label} color={label.toLowerCase() as TagColors} />}
                </div>

                {ownerData && (
                    <div className="header-info owner-container">
                        <Text className="header-owner-title">{t('Lead.lead_owner')}</Text>

                        <div className="owner-info">
                            <ImageWithPlaceholder
                                className="owner-icon"
                                width="150"
                                imgSrc={ownerData.imageUrl}
                                defaultImg={<UserIcon fill={theme['primary-color']} />}
                            />

                            <Text className="header-owner">{`${ownerData.firstName} ${ownerData.lastName}`}</Text>
                        </div>
                    </div>
                )}

                {relatedOpportunity && (
                    <div className="subscription-nav-container" onClick={onGoToOpportunityClick}>
                        <div className="subscription-nav-text">
                            {t('Subscription.view_opportunity_for')}
                        </div>
                        <div className="subscription-nav-tags">
                            <Tag
                                label={
                                    relatedOpportunity.isSubscriptionRenewal
                                        ? t('renewal')
                                        : t('modification')
                                }
                            />
                        </div>
                    </div>
                )}

                {onGoToCurrentSubscriptionVersionClick && (
                    <Button
                        className="return-current-version-btn"
                        onClick={onGoToCurrentSubscriptionVersionClick}
                    >
                        {t('Subscription.return_to_current_version')}
                    </Button>
                )}

                {subscriptionAncestryInfo && (
                    <SimpleList
                        className="opportunity-subscription"
                        title={t(`Opportunity.opportunity_subscription`)}
                    >
                        <div className="opportunity-subscription-container">
                            <div>
                                <div className="text">
                                    {subscriptionAncestryInfo.activeSubscription?.referenceNumber
                                        ? `Ref # ${subscriptionAncestryInfo.activeSubscription?.referenceNumber}`
                                        : subscriptionAncestryInfo.membership?.name}
                                </div>
                                <div>
                                    {getDetailHeaderSubscriptionTagStatuses(
                                        subscriptionAncestryInfo
                                    ).map((status) => (
                                        <Tag
                                            key={status}
                                            label={t(status)}
                                            color={getSubscriptionStatusTagColor(status)}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div
                                className="view-subscription-link"
                                onClick={(): void =>
                                    history.push(
                                        `/subscriptions/${
                                            subscriptionAncestryInfo.activeSubscription?.id ??
                                            subscriptionAncestryInfo.id
                                        }`
                                    )
                                }
                            >
                                {t('Subscription.view_subscription')}
                            </div>
                        </div>
                    </SimpleList>
                )}

                {menuOptions && menuOptions?.length > 0 && (
                    <ActionMenu options={menuOptions} type="primary" trigger="click" />
                )}
            </Skeleton>
        </div>
    );
};

export default DetailHeader;
