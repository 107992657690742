import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const LinkedIn: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.08183 1.10217C1.81817 1.10217 0.935667 1.91884 0.935667 3.088C0.935667 4.2565 1.792 5.0725 3.01817 5.0725C4.32117 5.0725 5.19667 4.275 5.19667 3.088C5.1685 1.886 4.33817 1.10217 3.08183 1.10217ZM3.01817 6.00817C1.26933 6.00817 0 4.78017 0 3.088C0 1.39517 1.296 0.166504 3.08183 0.166504C4.83783 0.166504 6.09217 1.3635 6.13217 3.07717C6.13217 4.78017 4.8225 6.00817 3.01817 6.00817ZM1.06483 22.8753H4.714V7.78517H1.06483V22.8753ZM5.18183 23.811H0.597C0.338667 23.811 0.129167 23.6015 0.129167 23.3432V7.31734C0.129167 7.059 0.338667 6.8495 0.597 6.8495H5.18183C5.44033 6.8495 5.64967 7.059 5.64967 7.31734V23.3432C5.64967 23.6015 5.44033 23.811 5.18183 23.811ZM19.496 22.956H23.0643V13.9333C23.0643 9.90234 21.2053 7.59034 17.9638 7.59034C15.1615 7.59034 13.8278 9.374 13.4858 9.92067C13.4003 10.0573 13.2505 10.1403 13.0893 10.1403H12.9927C12.7517 10.1403 12.5502 9.95717 12.527 9.71734L12.3418 7.79334L9.072 7.856C9.10667 8.935 9.10517 10.1568 9.10317 11.543L9.10233 22.956H12.7518V14.16C12.7518 13.5375 12.8137 13.0927 12.9465 12.7598C13.3525 11.7263 14.6387 10.6253 16.2208 10.6253C18.2105 10.6253 19.496 12.1522 19.496 14.5152V22.956ZM23.5322 23.8917H19.0282C18.7698 23.8917 18.5603 23.6822 18.5603 23.4238V14.5152C18.5603 12.6653 17.6857 11.5608 16.2208 11.5608C15.0953 11.5608 14.1145 12.3457 13.8165 13.1043C13.7293 13.3227 13.6875 13.6672 13.6875 14.16V23.4238C13.6875 23.6822 13.478 23.8917 13.2197 23.8917H8.6345C8.37617 23.8917 8.16667 23.6822 8.16667 23.4238L8.1675 11.5418C8.16967 9.95784 8.17167 8.58984 8.119 7.4185C8.11333 7.29234 8.15883 7.16934 8.245 7.07734C8.33133 6.98534 8.45117 6.932 8.57733 6.92967L12.757 6.84967C13.0068 6.84784 13.2083 7.02917 13.2317 7.2725L13.357 8.57434C14.1612 7.72217 15.6342 6.65484 17.9638 6.65484C21.7435 6.65484 24 9.37567 24 13.9333V23.4238C24 23.6822 23.7905 23.8917 23.5322 23.8917Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default LinkedIn;
