import React, {
    FunctionComponent, useState,
} from 'react';
import { Content } from 'antd/lib/layout/layout';
import Icon from 'Components/icons/icon';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { theme } from 'variant';
import { useHistory, useParams } from 'react-router';
import ContactsTable from 'Routes/authenticated/contacts/contacts-table';
import CreateContact from 'Routes/authenticated/contacts/modals/create-contact';
import CreateOpportunityFromContactModal from 'Routes/authenticated/opportunities/create-opportunity-for-contact-modal';
import { Button } from 'antd';

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'contacts',
        nameKey: 'Contacts',
    },
];
const Contacts: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const [createContactModalIsVisible, setCreateContactModalIsVisible] = useState(false);
    const [createOpportunityState, setCreateOpportunityState] = useState<{
        visible: boolean;
        contactId?: string;
        contactName?: string;
        membershipId?: string;
        membershipName?: string;
    }>({ visible: false });

    return (
        <div className="Contacts">
            <LocationHeader
                title={t('contacts')}
                subTitle={t('Contact.manage_contacts_from_location')}
                defaultImg={<Icon iconName="User" fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            setCreateContactModalIsVisible(true);
                        }}
                    >
                        {t('User.contacts_create_contact')}
                    </Button>
                }
            />
            <Content>
                <ContactsTable campusId={id} />
            </Content>

            {createContactModalIsVisible && (
                <CreateContact
                    visible={createContactModalIsVisible}
                    onComplete={(success: boolean, id?: string) => {
                        setCreateContactModalIsVisible(false);
                        if (success && id) history.push(`contacts/${id}`);
                    }}
                    onCreateOpportunity={(contactId, contactName, membershipId, membershipName) =>
                        setCreateOpportunityState({
                            visible: true,
                            contactId,
                            contactName,
                            membershipId,
                            membershipName,
                        })
                    }
                />
            )}

            {createOpportunityState.visible && (
                <CreateOpportunityFromContactModal
                    visible={createOpportunityState.visible}
                    onComplete={(success: boolean, id?: string) => {
                        if (success && id) history.push(`/opportunities/${id}/dashboard`);
                        setCreateOpportunityState({ visible: false });
                    }}
                    contactId={createOpportunityState.contactId}
                    contactName={createOpportunityState.contactName}
                    membershipId={createOpportunityState.membershipId}
                    membershipName={createOpportunityState.membershipName}
                />
            )}
        </div>
    );
});

export default Contacts;
