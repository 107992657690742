import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { UploadFile } from 'antd/lib/upload/interface';
import { CreateDayPassTypeRequestDto } from 'Api/Features/DayPassTypes/Dtos/CreateDayPassTypeRequestDto';
import { DayPassTypeDto } from 'Api/Features/DayPassTypes/Dtos/DayPassTypeDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import Plan from 'Components/icons/Plan';
import { ImagePicker } from 'Components/image-picker';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { TENTHOUSAND } from 'Models/Constants';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { EditDayPassTypeSchema } from 'Schemas';
import { EditPricingDayPassTypeSchema } from 'Schemas/EditDayPassTypeSchema';
import { DayPassTypeService } from 'Services/DayPassTypeService';
import { cleanVal } from 'Utils/NumberUtils';
import './edit-day-pass-type-modal.less';

const { Title } = Typography;

const formGutter: [Gutter, Gutter] = [40, 0];

interface SelectOption {
    id: string;
    name: string;
}
interface EditDayPassTypeModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    dayPassType?: DayPassTypeDto;
    locationId?: string;
}

const EditDayPassTypeModal: FunctionComponent<EditDayPassTypeModalProps> = ({
    visible,
    onComplete,
    dayPassType,
    locationId,
}) => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const dayPassTypeService = useService(DayPassTypeService);
    const [form] = Form.useForm();

    const [imageDetails, setImageDetails] = useState<ImageDetails[]>();
    const [baseTypeOptions, setBaseTypeOptions] = useState<SelectOption[]>([]);
    const [errorsData, validateFormData, resetErrorsData] = useFormValidation(
        EditDayPassTypeSchema,
        form
    );
    const [errorsPricing, validateFormPricing, resetErrorsPricing] = useFormValidation(
        EditPricingDayPassTypeSchema,
        form
    );

    const selectOptions = (options: SelectOption[]): ReactNode[] => {
        return options.map((option) => (
            <Select.Option key={option.id} value={option.id}>
                {option.name}
            </Select.Option>
        ));
    };

    const fetchBaseTypeOptions = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();
        let options: SelectOption[] = [];

        try {
            const dayPassTypes = await dayPassTypeService.getDayPassTypes({ multiLocation: true });

            options = dayPassTypes[0].map((dayPassType) => ({
                id: cleanVal.string(dayPassType.id),
                name: cleanVal.string(dayPassType.name),
            }));

            setBaseTypeOptions(options);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, dayPassTypeService, toastStore]);

    const dismiss = (success = false, id?: string): void => {
        onComplete(success, id);
        form.resetFields();
        resetErrorsData();
        resetErrorsPricing();
    };

    const handleDescriptionChange = (value: string): void => {
        form.setFieldsValue({ description: value });
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();

        const pricingModel: CreateDayPassTypeRequestDto = {
            creditsPrice: formValues.creditsPrice,
            price: formValues.price,
        };
        let model: CreateDayPassTypeRequestDto;

        if (locationId) {
            model = { campusId: locationId, baseTypeId: formValues.baseType };
        } else {
            model = {
                description: formValues.description,
                name: formValues.name,
                displayName: formValues.displayName,
                image: imageDetails
                    ? ({
                          delete: imageDetails[0].isDeleted,
                          uploadBase64: imageDetails[0].base64,
                      } as UpdateFileRequestDto)
                    : null,
            };
        }

        const dataIsValid = locationId ? true : await validateFormData(model);
        const pricingIsValid = await validateFormPricing(pricingModel);

        if (!dataIsValid || !pricingIsValid) return;

        try {
            globalLoadingStore.addLoading();
            let createdId = undefined;

            if (dayPassType) {
                await dayPassTypeService.updateDayPassType(dayPassType.id!, {
                    ...model,
                    ...pricingModel,
                });
            } else {
                createdId = await dayPassTypeService.createDayPassType({
                    ...model,
                    ...pricingModel,
                });
            }

            toastStore.toast({
                type: 'success',
                messageKey: `DayPass.day_pass_type_${dayPassType ? 'edited' : 'created'}`,
            });
            dismiss(true, createdId);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Plan />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t(
                    `DayPass.day_pass_type_confirm_positive${
                        dayPassType !== undefined ? '_edit' : ''
                    }`
                ),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    useEffect(() => {
        if (dayPassType) {
            form.setFieldsValue({
                price: dayPassType.price,
                name: dayPassType.name,
                description: dayPassType.description ?? undefined,
                creditsPrice: dayPassType.creditsPrice,
                displayName: dayPassType.displayName,
            });
        }
    }, [dayPassType, form]);

    useEffect(() => {
        if (locationId) {
            fetchBaseTypeOptions();
        }
    }, [locationId, fetchBaseTypeOptions]);

    useEffect(() => {
        if (locationId) {
            form.setFieldsValue({
                baseType: baseTypeOptions[0]?.id,
            });
        }
    }, [locationId, form, baseTypeOptions]);

    return (
        <BaseModal
            visible={visible}
            title={
                dayPassType !== undefined
                    ? t('DayPass.edit_day_pass_type')
                    : t('DayPass.create_day_pass_type')
            }
            className="FormModal"
            onCancel={exit}
        >
            <div className="EditDayPassTypeModal">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    {!locationId && (
                        <>
                            <Title level={4}>{t('basic_information')}</Title>
                            <Row gutter={formGutter}>
                                <Col span={12} className="input-with-tip-container">
                                    <ValidatedFormItem
                                        errors={errorsData}
                                        name="name"
                                        label={t('name')}
                                        required={true}
                                        className="input-with-tip"
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                    <small className="input-tip">
                                        {t('DayPass.day_pass_type_name_tip')}
                                    </small>
                                </Col>
                                <Col span={12}>
                                    <ValidatedFormItem
                                        errors={errorsData}
                                        name="displayName"
                                        label={t('DayPass.display_name')}
                                        required={true}
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>
                            </Row>

                            <Row gutter={formGutter}>
                                <Col span={24}>
                                    <ValidatedFormItem
                                        errors={errorsData}
                                        name="description"
                                        label={t('ConferenceRoom.description_title')}
                                        className="description"
                                    >
                                        <Input hidden />
                                    </ValidatedFormItem>
                                    <ReactQuill
                                        theme="snow"
                                        className="description-quill"
                                        onChange={handleDescriptionChange}
                                        value={
                                            form.getFieldValue('description') ||
                                            dayPassType?.description ||
                                            null
                                        }
                                    />
                                </Col>
                            </Row>
                        </>
                    )}

                    {locationId && (
                        <>
                            <Title level={4}>{t('basic_information')}</Title>
                            <Row>
                                <Col span={6}>
                                    <ValidatedFormItem
                                        name="baseType"
                                        label={t('DayPass.day_pass_base_type')}
                                    >
                                        <Select>{selectOptions(baseTypeOptions)}</Select>
                                    </ValidatedFormItem>
                                </Col>
                            </Row>
                        </>
                    )}

                    <Title level={4}>{t('pricing')}</Title>
                    <small className="input-tip">
                        {t(`DayPass.${locationId ? 'location_' : ''}day_pass_type_pricing_tip`)}
                    </small>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errorsPricing}
                                name="price"
                                label={t('price')}
                                required={true}
                            >
                                <NumberFormat
                                    allowNegative={false}
                                    customInput={(props: any) => (
                                        <Input addonBefore={'$'} {...props} />
                                    )}
                                    isAllowed={(value: NumberFormatValues): boolean =>
                                        value.floatValue === undefined ||
                                        (value.floatValue !== undefined &&
                                            value.floatValue <= TENTHOUSAND)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errorsPricing}
                                name="creditsPrice"
                                label={t('credits_price')}
                                required={true}
                            >
                                <NumberFormat
                                    allowNegative={false}
                                    customInput={(props: any) => <Input {...props} />}
                                    isAllowed={(value: NumberFormatValues): boolean =>
                                        value.floatValue === undefined ||
                                        (value.floatValue !== undefined &&
                                            value.floatValue <= TENTHOUSAND)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    {!locationId && (
                        <>
                            {' '}
                            <Title level={4}>{t('main_image')}</Title>
                            <Row gutter={formGutter}>
                                <Col span={24}>
                                    <ValidatedFormItem errors={errorsData} name="image">
                                        <ImagePicker
                                            images={
                                                dayPassType?.imageUrl
                                                    ? [
                                                          {
                                                              url: dayPassType?.imageUrl,
                                                              uid: 'main',
                                                          } as UploadFile,
                                                      ]
                                                    : undefined
                                            }
                                            setImagesDetails={(
                                                images: ImageDetails[] | undefined
                                            ) => setImageDetails(images)}
                                        />
                                    </ValidatedFormItem>
                                </Col>
                            </Row>
                        </>
                    )}

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default EditDayPassTypeModal;
