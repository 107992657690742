import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Tag } from 'Components/tag';
import { TagColors } from 'Components/tag/tag';
import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import './upcoming-activities-item.less';

interface LinkInterface {
    label: string;
    url: string;
}

interface CheckboxInterface {
    checked?: boolean;
    onCheck?: (e: CheckboxChangeEvent) => Promise<void>;
}

export interface UpcomingActivitiesItemProps {
    id: string;
    title: string;
    label: string[];
    tagLabel: string;
    dragHandle?: ReactNode;
    className?: string;
    link: LinkInterface;
    checkbox?: CheckboxInterface;
}

const UpcomingActivitiesItem: FunctionComponent<UpcomingActivitiesItemProps> = ({
    id,
    title,
    label,
    tagLabel,
    link,
    dragHandle,
    className = '',
    checkbox,
}) => {
    return (
        <div className={`UpcomingActivitiesItem ${className}`}>
            <Checkbox
                key={id}
                onChange={checkbox?.onCheck}
                defaultChecked={checkbox?.checked}
            />

            <div className="title-label-wrapper">
                <div className="title">{title}</div>

                {label && (
                    <div className="label-list">
                        {label.map(
                            (item, i) =>
                                item && (
                                    <span key={i} className="label">
                                        {item}
                                    </span>
                                )
                        )}
                    </div>
                )}
            </div>

            <div className="tag-link-wrapper">
                <Tag label={tagLabel} color={TagColors.light} rounded />
                <Link to={link.url}>{link.label}</Link>
            </div>

            <div className="drag-icon">{dragHandle}</div>
        </div>
    );
};

export default UpcomingActivitiesItem;
