import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Instagram: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.0734 3.89086C17.9254 3.89086 17.8051 4.0112 17.8051 4.15919V6.3717C17.8051 6.5197 17.9254 6.64003 18.0734 6.64003H20.2863C20.4341 6.64003 20.5544 6.5197 20.5544 6.3717V4.15919C20.5544 4.0112 20.4341 3.89086 20.2863 3.89086H18.0734ZM20.2863 7.57819H18.0734C17.4081 7.57819 16.8669 7.03686 16.8669 6.3717V4.15919C16.8669 3.49386 17.4081 2.9527 18.0734 2.9527H20.2863C20.9513 2.9527 21.4926 3.49386 21.4926 4.15919V6.3717C21.4926 7.03686 20.9513 7.57819 20.2863 7.57819Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.993 8.5595H15.9592C14.8648 7.8025 13.5407 7.36117 12.1142 7.36117C10.6875 7.36117 9.36167 7.8025 8.26733 8.5595H1.007V5.09717C1.007 2.8415 2.84133 1.007 5.097 1.007H18.903C21.1587 1.007 22.993 2.8415 22.993 5.09717V8.5595ZM17.9613 14.1467C17.9613 17.3707 15.3382 19.9938 12.1142 19.9938C8.89 19.9938 6.26683 17.3707 6.26683 14.1467C6.26683 12.2922 7.1345 10.6373 8.484 9.5665C8.509 9.54633 8.53433 9.52617 8.5595 9.50767C9.54467 8.75083 10.7782 8.29933 12.1142 8.29933C13.4232 8.29933 14.6333 8.73233 15.6083 9.46083C15.6537 9.496 15.699 9.53117 15.7443 9.5665C17.0937 10.6373 17.9613 12.2922 17.9613 14.1467ZM22.993 18.2317C22.993 20.4873 21.1587 22.3217 18.903 22.3217H5.097C2.84133 22.3217 1.007 20.4873 1.007 18.2317V9.5665H7.10933C6.00333 10.7748 5.32867 12.3827 5.32867 14.1467C5.32867 17.8877 8.3715 20.9322 12.1142 20.9322C15.8552 20.9322 18.8995 17.8877 18.8995 14.1467C18.8995 12.3827 18.2248 10.7748 17.1172 9.5665H22.993V18.2317ZM18.903 0H5.097C2.28583 0 0 2.286 0 5.09717V18.2317C0 21.0428 2.28583 23.3287 5.097 23.3287H18.903C21.7142 23.3287 24 21.0428 24 18.2317V5.09717C24 2.286 21.7142 0 18.903 0Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.0411 11.1696C10.3999 11.1696 9.06473 12.5049 9.06473 14.1459C9.06473 15.7871 10.3999 17.1223 12.0411 17.1223C13.6822 17.1223 15.0172 15.7871 15.0172 14.1459C15.0172 12.5049 13.6822 11.1696 12.0411 11.1696ZM12.0411 18.0604C9.88257 18.0604 8.12657 16.3044 8.12657 14.1459C8.12657 11.9874 9.88257 10.2314 12.0411 10.2314C14.1994 10.2314 15.9554 11.9874 15.9554 14.1459C15.9554 16.3044 14.1994 18.0604 12.0411 18.0604Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Instagram;
