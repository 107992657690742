import { Button, Col, Input, Row, Select } from 'antd';
import { CampusStatusTypeDto } from 'Api/Features/Campuses/Dtos/CampusStatusTypeDto';
import GlobalizationSelect from 'Components/globalization-select/globalization-select';
import Icon from 'Components/icons/icon';
import React, { FunctionComponent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterStore } from 'Stores';
import './index.less';

interface SelectOption {
    id: string;
    name: string;
}

interface LocationSearchBarProps {
    displayGrid: (arg0: boolean) => void;
    isDisplayGrid: boolean;
    filterStore: FilterStore;
}

const LocationSearchBar: FunctionComponent<LocationSearchBarProps> = ({
    displayGrid,
    isDisplayGrid,
    filterStore,
}) => {
    const { t } = useTranslation();

    const selectOptions = (options: SelectOption[]): ReactElement[] => {
        return options.map((option, i) => {
            return (
                <Select.Option key={option.id} value={option.id}>
                    {option.name}
                </Select.Option>
            );
        });
    };

    const statusOptions: SelectOption[] = Object.keys(CampusStatusTypeDto).map((option) => ({
        id: option,
        name: t(`Location.campus_status_type_${option}`),
    }));

    const disabledStatusIndex = statusOptions.findIndex(
        (status) => status.id === CampusStatusTypeDto.Disabled
    );
    if (disabledStatusIndex !== -1) statusOptions.splice(disabledStatusIndex, 1);

    const statusDefaultValue = 'All statuses';
    statusOptions.unshift({
        id: statusDefaultValue,
        name: statusDefaultValue,
        order: 0,
    } as SelectOption);

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        filterStore.updateSearchTerm(event.target.value);
    };

    const onStateChange = (value: string): void => {
        filterStore.updateStateCodes([value]);
    };

    const onStatusChange = (value: string): void => {
        filterStore.updateLocationStatuses([value]);
    };

    return (
        <div className="LocationSearchBar">
            <Row gutter={[40, 40]}>
                <Col span={6}>
                    <GlobalizationSelect
                        selectType="state"
                        name="state"
                        defaultValue="All states"
                        onChange={onStateChange}
                    />
                </Col>

                <Col span={6}>
                    <Select defaultValue={statusDefaultValue} onChange={onStatusChange}>
                        {selectOptions(statusOptions)}
                    </Select>
                </Col>

                <Col span={12}>
                    <div className="search-buttons-wrapper">
                        <Input onChange={onSearchChange} prefix={<Icon iconName="Search" />} />

                        <Button
                            htmlType="button"
                            className={`selected-${isDisplayGrid === false}`}
                            onClick={(): void => displayGrid(false)}
                        >
                            <Icon iconName="LayoutList" />
                        </Button>

                        <Button
                            htmlType="button"
                            className={`selected-${isDisplayGrid === true}`}
                            onClick={(): void => displayGrid(true)}
                        >
                            <Icon iconName="LayoutGrid" />
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default LocationSearchBar;
