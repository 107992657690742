import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { SalesReportDto } from 'Api/Features/Reports/Dtos/Sales/SalesReportDto';
import { GetSalesReportRequestDto } from 'Api/Features/Reports/Dtos/Sales/GetSalesReportRequestDto';
import { SalesReportProxy } from 'Api/Features/Reports/SalesReportProxy';
import { arrayBufferToXlsx } from 'Utils/blobs';

@inject(SalesReportProxy)
export class SalesReportService extends ApiService {
    constructor(private readonly salesReportProxy: SalesReportProxy) {
        super();
    }

    public async getSalesReport(request: GetSalesReportRequestDto | null): Promise<SalesReportDto | null> {
        const data: SalesReportDto | null = await this.salesReportProxy.getSalesReport(request);
        return data;
    }

    public async exportSalesReport(request: GetSalesReportRequestDto | null): Promise<Blob> {
        const data: ArrayBuffer | null = await this.salesReportProxy.exportSalesReport(request);
        return arrayBufferToXlsx(data);
    }
}
