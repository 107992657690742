// GENERATED FILE - DO NOT MODIFY
import { AchConsentDto } from 'Api/Features/PaymentMethods/Dtos/AchConsentDto';
import { AchPaymentMethodDto } from 'Api/Features/PaymentMethods/Dtos/AchPaymentMethodDto';
import { CreatePaymentMethodPlaidRequestDto } from 'Api/Features/PaymentMethods/Dtos/CreatePaymentMethodPlaidRequestDto';
import { CreatePaymentMethodRequestDto } from 'Api/Features/PaymentMethods/Dtos/CreatePaymentMethodRequestDto';
import { CreditCardPaymentMethodDto } from 'Api/Features/PaymentMethods/Dtos/CreditCardPaymentMethodDto';
import { GetPaymentApiInfoResponseDto } from 'Api/Features/PaymentMethods/Dtos/GetPaymentApiInfoResponseDto';
import { PaymentMethodHolderTypeDto } from 'Api/Features/PaymentMethods/Dtos/PaymentMethodHolderTypeDto';
import { PaymentMethodVerificationRequestDto } from 'Api/Features/PaymentMethods/Dtos/PaymentMethodVerificationRequestDto';
import { RemovePaymentMethodRequestDto } from 'Api/Features/PaymentMethods/Dtos/RemovePaymentMethodRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class PaymentMethodProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getPaymentApiInfo(): Promise<GetPaymentApiInfoResponseDto | null> {
        const uri = this.buildUri(
            "/payment/apiinfo",
            null,
            null
        );

        const data: GetPaymentApiInfoResponseDto | null = await this.httpClient.get<GetPaymentApiInfoResponseDto | null>(uri);
        return data;
    }

    public async getPaymentMethod(id: string | null): Promise<AchPaymentMethodDto | CreditCardPaymentMethodDto | null> {
        const uri = this.buildUri(
            "/payment/{id}",
            { id: id },
            null
        );

        const data: AchPaymentMethodDto | CreditCardPaymentMethodDto | null = await this.httpClient.get<AchPaymentMethodDto | CreditCardPaymentMethodDto | null>(uri);
        return data;
    }

    public async getHolderPaymentMethod(holderType: PaymentMethodHolderTypeDto, holderId: string | null): Promise<AchPaymentMethodDto | CreditCardPaymentMethodDto | null> {
        const uri = this.buildUri(
            "/payment/forHolder",
            null,
            { holderType: holderType, holderId: holderId }
        );

        const data: AchPaymentMethodDto | CreditCardPaymentMethodDto | null = await this.httpClient.get<AchPaymentMethodDto | CreditCardPaymentMethodDto | null>(uri);
        return data;
    }

    public async createPaymentMethod(request: CreatePaymentMethodRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/payment/paymentmethod",
            null,
            null
        );

        await this.httpClient.post<CreatePaymentMethodRequestDto | null, void>(uri, request);
    }

    public async createPaymentMethodUsingPlaid(request: CreatePaymentMethodPlaidRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/payment/paymentmethod/plaid",
            null,
            null
        );

        await this.httpClient.post<CreatePaymentMethodPlaidRequestDto | null, void>(uri, request);
    }

    public async deletePaymentMethod(request: RemovePaymentMethodRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/payment/remove",
            null,
            null
        );

        await this.httpClient.post<RemovePaymentMethodRequestDto | null, void>(uri, request);
    }

    public async verifyPaymentMethod(request: PaymentMethodVerificationRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/payment/verify",
            null,
            null
        );

        await this.httpClient.post<PaymentMethodVerificationRequestDto | null, void>(uri, request);
    }

    public async getAchConsent(): Promise<AchConsentDto | null> {
        const uri = this.buildUri(
            "/payment/achconsent",
            null,
            null
        );

        const data: AchConsentDto | null = await this.httpClient.get<AchConsentDto | null>(uri);
        return data;
    }

    public async webHook(): Promise<void> {
        const uri = this.buildUri(
            "/payment/webhook",
            null,
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }
}