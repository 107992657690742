import { Button, Input, Typography } from 'antd';
import { Search } from 'Components/icons';
import Icon from 'Components/icons/icon';
import TableActionsButtons from 'Components/table-action-buttons/table-actions-buttons';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'variant';
import './collapse-controls.less';

interface CollapseControlsProps {
    onExpandClick: () => void;
    onCollapseClick: () => void;
    onCreateClick: () => void;
    onSearchTermChange: (string: string) => void;
}

const CollapseControls: FunctionComponent<CollapseControlsProps> = ({
    onExpandClick,
    onCollapseClick,
    onCreateClick,
    onSearchTermChange,
}) => {
    const { t } = useTranslation();
    return (
        <div className="CollapseControls">
            <div className='top-line'>
                <Typography.Title level={2}>{t('units')}</Typography.Title>
                <TableActionsButtons
                    buttonsList={[
                        {
                            iconName: 'UnExpandAll',
                            toolTip: 'unexpand_all',
                            onClick: onCollapseClick,
                        },
                        {
                            iconName: 'ExpandAll',
                            toolTip: 'expand_all',
                            onClick: onExpandClick,
                        },
                    ]}
                />
                <Button
                    className={'ant-btn-primary ant-btn-circle ant-btn-lg ant-btn-icon-only'}
                    onClick={onCreateClick}
                >
                    <Icon iconName="Add" />
                </Button>
            </div>
            <Input
                onChange={(e) => onSearchTermChange(e.target.value)}
                prefix={<Search fill={theme['primary-3']} />}
                className="search"
                placeholder={t('FloorPlan.search_for_unit')}
            />
        </div>
    );
};

export default CollapseControls;
