import { Button } from 'antd';
import BaseModal from 'Components/base-modal/base-modal';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './import-units-tip-modal.less';

interface ImportUnitsTipModalProps {
    visible: boolean;
    onComplete: () => void;
}

const ImportUnitsTipModal: FunctionComponent<ImportUnitsTipModalProps> = ({
    visible,
    onComplete,
}) => {
    const { t } = useTranslation();

    const dismiss = (): void => {
        onComplete();
    };

    const exit = (): void => {
        dismiss();
    };

    return (
        <BaseModal
            visible={visible}
            title={t('Membership.file_format')}
            className="FormModal ImportUnitsTipModal"
            onCancel={exit}
        >
            <ul>
                <li>{t('Membership.file_must_be_valid')}</li>
                <li>
                    {t('Membership.file_must_contain')}
                    <ul className="dashed">
                        <li>{t('Name')}</li>
                        <li>{t('Type')}</li>
                        <li>{t('Capacity')}</li>
                        <li>{t('Unit.square_feet')}</li>
                        <li>{t('Unit.number_of_desks')}</li>
                        <li>{t('Unit.six_month_price')}</li>
                        <li>{t('Unit.twelve_month_price')}</li>
                        <li>{t('Unit.price_override')}</li>
                        <li>{t('Unit.price_pultiplier')}</li>
                        <li>{t('Unit.data_jacks')}</li>
                        <li>{t('Note')}</li>
                    </ul>
                </li>
            </ul>

            <Button type="primary" onClick={(): void => exit()}>
                {t('ok')}
            </Button>
        </BaseModal>
    );
};

export default React.memo(ImportUnitsTipModal);
