import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Trash: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.02369 6.09431H20.6451V3.56565H3.02369V6.09431ZM4.55854 22.978H19.1104V7.01878H4.55854V22.978ZM9.17909 2.64111H14.4893V1.02369H9.17909V2.64111ZM21.1064 2.64112H15.414V0.562293C15.414 0.305954 15.2073 0.0992282 14.9509 0.0992282H8.7161C8.46158 0.0992282 8.25485 0.305954 8.25485 0.562293V2.64112H2.56064C2.30595 2.64112 2.09923 2.84785 2.09923 3.10419V6.55733C2.09923 6.81202 2.30595 7.01874 2.56064 7.01874H3.63396V23.4394C3.63396 23.694 3.84068 23.9008 4.09537 23.9008H19.5717C19.8262 23.9008 20.0347 23.694 20.0347 23.4394V7.01874H21.1064C21.3612 7.01874 21.5695 6.81202 21.5695 6.55733V3.10419C21.5695 2.84785 21.3612 2.64112 21.1064 2.64112Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.15562 0.562293C8.15562 0.251406 8.40652 0 8.7161 0H14.9509C15.2621 0 15.5132 0.251152 15.5132 0.562293V2.5419H21.1064C21.4158 2.5419 21.6687 2.79284 21.6687 3.10419V6.55733C21.6687 6.86726 21.4156 7.11797 21.1064 7.11797H20.134V23.4394C20.134 23.7493 19.8805 24 19.5717 24H4.09537C3.78588 24 3.53473 23.7488 3.53473 23.4394V7.11797H2.56064C2.25115 7.11797 2 6.86682 2 6.55733V3.10419C2 2.79328 2.25092 2.5419 2.56064 2.5419H8.15562V0.562293ZM8.7161 0.198456C8.51663 0.198456 8.35408 0.360501 8.35408 0.562293V2.74035H2.56064C2.36099 2.74035 2.19846 2.90242 2.19846 3.10419V6.55733C2.19846 6.75722 2.36076 6.91951 2.56064 6.91951H3.73319V23.4394C3.73319 23.6392 3.89549 23.8015 4.09537 23.8015H19.5717C19.7719 23.8015 19.9355 23.6388 19.9355 23.4394V6.91951H21.1064C21.3069 6.91951 21.4702 6.75678 21.4702 6.55733V3.10419C21.4702 2.90286 21.3067 2.74035 21.1064 2.74035H15.3148V0.562293C15.3148 0.360756 15.1525 0.198456 14.9509 0.198456H8.7161ZM9.07986 0.924461H14.5885V2.74034H9.07986V0.924461ZM9.27832 1.12292V2.54188H14.3901V1.12292H9.27832ZM2.92446 3.46642H20.7444V6.19354H2.92446V3.46642ZM3.12292 3.66487V5.99508H20.5459V3.66487H3.12292ZM4.45931 6.91955H19.2096V23.0772H4.45931V6.91955ZM4.65777 7.118V22.8787H19.0111V7.118H4.65777Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.99389 21.1199C7.73854 21.1199 7.53165 20.913 7.53165 20.6576V11.0175C7.53165 10.7621 7.73854 10.5552 7.99389 10.5552C8.24923 10.5552 8.45612 10.7621 8.45612 11.0175V20.6576C8.45612 20.913 8.24923 21.1199 7.99389 21.1199Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.99389 10.6544C7.79334 10.6544 7.63088 10.8169 7.63088 11.0175V20.6576C7.63088 20.8582 7.79334 21.0207 7.99389 21.0207C8.19443 21.0207 8.3569 20.8582 8.3569 20.6576V11.0175C8.3569 10.8169 8.19443 10.6544 7.99389 10.6544ZM7.43242 11.0175C7.43242 10.7073 7.68374 10.456 7.99389 10.456C8.30404 10.456 8.55535 10.7073 8.55535 11.0175V20.6576C8.55535 20.9678 8.30404 21.2191 7.99389 21.2191C7.68374 21.2191 7.43242 20.9678 7.43242 20.6576V11.0175Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.8334 21.1199C11.5781 21.1199 11.3712 20.913 11.3712 20.6576V11.0175C11.3712 10.7621 11.5781 10.5552 11.8334 10.5552C12.0888 10.5552 12.2957 10.7621 12.2957 11.0175V20.6576C12.2957 20.913 12.0888 21.1199 11.8334 21.1199Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.8334 10.6544C11.6329 10.6544 11.4704 10.8169 11.4704 11.0175V20.6576C11.4704 20.8582 11.6329 21.0207 11.8334 21.0207C12.034 21.0207 12.1964 20.8582 12.1964 20.6576V11.0175C12.1964 10.8169 12.034 10.6544 11.8334 10.6544ZM11.272 11.0175C11.272 10.7073 11.5233 10.456 11.8334 10.456C12.1436 10.456 12.3949 10.7073 12.3949 11.0175V20.6576C12.3949 20.9678 12.1436 21.2191 11.8334 21.2191C11.5233 21.2191 11.272 20.9678 11.272 20.6576V11.0175Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.6741 21.1199C15.4188 21.1199 15.2119 20.913 15.2119 20.6576V11.0175C15.2119 10.7621 15.4188 10.5552 15.6741 10.5552C15.9295 10.5552 16.1364 10.7621 16.1364 11.0175V20.6576C16.1364 20.913 15.9295 21.1199 15.6741 21.1199Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.6741 10.6544C15.4736 10.6544 15.3111 10.8169 15.3111 11.0175V20.6576C15.3111 20.8582 15.4736 21.0207 15.6741 21.0207C15.8747 21.0207 16.0371 20.8582 16.0371 20.6576V11.0175C16.0371 10.8169 15.8747 10.6544 15.6741 10.6544ZM15.1127 11.0175C15.1127 10.7073 15.364 10.456 15.6741 10.456C15.9843 10.456 16.2356 10.7073 16.2356 11.0175V20.6576C16.2356 20.9678 15.9843 21.2191 15.6741 21.2191C15.364 21.2191 15.1127 20.9678 15.1127 20.6576V11.0175Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Trash;
