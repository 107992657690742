import { Col, Row, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { CampusDetailsDto } from 'Api/Features/Campuses/Dtos/CampusDetailsDto';
import { CampusStatusTypeDto } from 'Api/Features/Campuses/Dtos/CampusStatusTypeDto';
import { GetCampusesSortColumnDto } from 'Api/Features/Campuses/Dtos/GetCampusesSortColumnDto';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { ActionMenu } from 'Components/action-menu';
import Icon from 'Components/icons/icon';
import LocationHeader from 'Components/location-header/location-header';
import LocationItem from 'Components/location-Item/location-item';
import LocationSearchBar from 'Components/location-search-bar/location-search-bar';
import ReorderModal, { ReorderModel } from 'Components/reorder-modal/reorder-modal';
import { Tag } from 'Components/tag';
import { TagColors } from 'Components/tag/tag';
import { TdWithImage } from 'Components/td-with-image';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { TABLE_DEBOUNCE_DELAY } from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { LightCampusDetailsInfo } from 'Models/Location/LightCampusInfo';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CampusService } from 'Services/CampusService';
import { FilterStore } from 'Stores';
import { useService, useStores } from '../../../Hooks';
import './index.less';
import CreateLocationModal from './modals/create-location-modal';
import EditLocationStatusModal from './modals/edit-location-status';

const advancedFilters: AdvancedFilter[] = [];

interface CreateOrEditModal {
    modalOpen: boolean;
    editionData?: CampusDetailsDto;
}

interface EditLocationStatusModal {
    modalOpen: boolean;
    campusId?: string;
}

const Locations: FunctionComponent = observer(() => {
    const campusService = useService(CampusService);
    const { userPermissionsStore, globalLoadingStore, toastStore, locationStore } = useStores();
    const [createOrEditModal, setCreateOrEditModal] = useState<CreateOrEditModal>({
        modalOpen: false,
        editionData: undefined,
    });
    const [editLocationStatusModal, setEditLocationStatusModal] = useState<EditLocationStatusModal>(
        {
            modalOpen: false,
            campusId: undefined,
        }
    );
    const [campusesData, setCampusesData] = useState<LightCampusDetailsInfo[]>();
    const [displayGrid, setDisplayGrid] = useState(true);
    const [reorderModalOpen, setReorderModalOpen] = useState(false);
    const [isManual, setIsManual] = useState(false);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const history = useHistory();
    const { t } = useTranslation();

    const fetchCampus = useCallback(
        async (campusId: string) => {
            globalLoadingStore.addLoading();

            try {
                const campusResponse = await campusService.getCampus(campusId);

                setCreateOrEditModal({ modalOpen: true, editionData: campusResponse ?? undefined });
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [campusService, globalLoadingStore, toastStore]
    );

    const fetchCampuses = useCallback(
        async (params: {
            searchTerm?: string;
            stateCodes?: string[];
            statuses?: string[];
            sortColumn?: GetCampusesSortColumnDto;
            sortDirection?: SortDirection | null;
        }) => {
            globalLoadingStore.addLoading();

            try {
                const statesCodesOrUndefined =
                    params.stateCodes &&
                    params.stateCodes[0] !== '' &&
                    params.stateCodes[0] !== 'All states'
                        ? params.stateCodes
                        : undefined;

                const statusesOrUndefined =
                    params.statuses &&
                    params.statuses[0] !== '' &&
                    params.statuses[0] !== 'All statuses'
                        ? (params.statuses as CampusStatusTypeDto[])
                        : undefined;

                const campusRequest = {
                    searchTerm: params?.searchTerm,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    stateCodes: statesCodesOrUndefined,
                    statuses: statusesOrUndefined,
                };

                const campusesResponse = await campusService.getCampuses(campusRequest);
                const notNullcampusesResponse = campusesResponse
                    ? ([] as LightCampusDetailsInfo[])
                    : undefined;

                if (notNullcampusesResponse && campusesResponse) {
                    campusesResponse.forEach((campus) => {
                        if (campus !== null && campus !== undefined)
                            notNullcampusesResponse.push(campus);
                    });
                }

                setCampusesData(notNullcampusesResponse);
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [campusService, globalLoadingStore]
    );

    const handleEditLocationModalOpen = (campuId: string): void => {
        fetchCampus(campuId);
    };

    const handleEditLocationStatusModalOpen = (campusId: string): void => {
        setEditLocationStatusModal({ modalOpen: true, campusId: campusId });
    };

    const handleTableChange = async (pagination: any, filter: any, sorter: any): Promise<void> => {
        const { searchTerm, stateCodes, locationStatuses } = filterStoreRef.current;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }
        await fetchCampuses({
            searchTerm,
            stateCodes,
            statuses: locationStatuses,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
    };

    const onRowClick = (row: LightCampusDetailsInfo): { onClick: () => void } => ({
        onClick: (): void => {
            history.push(`/locations/${row.id}/booking`);
        },
    });

    const menuOptions = [
        {
            key: 'createLocation',
            title: t('Location.create_location'),
            action: (): void => setCreateOrEditModal({ modalOpen: true, editionData: undefined }),
        },
        {
            key: 'reorder',
            title: t('reorder_model', { param1: 'locations' }),
            action: (): void => setReorderModalOpen(true),
        },
    ];

    const actionButton = userPermissionsStore.userIsAdministrator ? (
        <div className="ActionMenu-wrapper">
            <ActionMenu options={menuOptions} type="primary" trigger="click" />
        </div>
    ) : undefined;

    const locationRender = (location: LightCampusDetailsInfo): ReactNode =>
        location ? (
            <TdWithImage defaultImg={<Icon iconName="Location" />} imgSrc={location.mainImageUrl}>
                {location.name}
            </TdWithImage>
        ) : (
            ''
        );

    const statusRender = (location: LightCampusDetailsInfo): ReactNode => {
        let color;

        switch (location.status) {
            case CampusStatusTypeDto.Active:
                color = TagColors.active;
                break;
            case CampusStatusTypeDto.ComingSoon:
                color = TagColors.cold;
                break;
            case CampusStatusTypeDto.PreActive:
                color = TagColors.default;
                break;
            default:
                color = TagColors.disabled;
                break;
        }
        return <Tag label={t(`Location.campus_status_type_${location.status}`)} color={color} />;
    };

    const columns: ColumnType<LightCampusDetailsInfo>[] = [
        {
            title: t('location'),
            render: locationRender,
            sorter: true,
            key: GetCampusesSortColumnDto.Name,
        },
        {
            title: t('city'),
            dataIndex: ['address', 'city'],
            sorter: true,
            key: GetCampusesSortColumnDto.City,
        },
        {
            title: t('state'),
            dataIndex: ['address', 'state'],
            sorter: true,
            key: GetCampusesSortColumnDto.State,
        },
        {
            title: t('status'),
            render: statusRender,
            sorter: true,
            key: GetCampusesSortColumnDto.Status,
        },
    ];

    const onReorderComplete = (): void => {
        const filterStore = filterStoreRef.current;

        fetchCampuses({
            searchTerm: filterStore.searchTerm,
            stateCodes: filterStore.stateCodes,
            statuses: filterStore.locationStatuses,
        });

        setReorderModalOpen(false);

        toastStore.toast({
            type: 'success',
            messageKey: t('model_reorder_success', { param1: 'locations' }),
        });
    };

    const onReorderSubmit = async (method: OrderingMethodDto, ids: string[]): Promise<void> => {
        await campusService.updateCampusOrdering({ method, ids });
        onReorderComplete();
    };

    const debounceSearch = useRef(
        debounce((params: { stateCodes?: string[]; statuses?: string[]; searchTerm?: string }) => {
            fetchCampuses({
                searchTerm: params.searchTerm,
                stateCodes: params.stateCodes,
                statuses: params.statuses,
            });
        }, TABLE_DEBOUNCE_DELAY)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;

            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                stateCodes: filterStore.stateCodes,
                statuses: filterStore.locationStatuses,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debounceSearch, editLocationStatusModal, createOrEditModal]);

    return (
        <div className="Locations">
            <LocationHeader
                title={t('Location.location_title')}
                subTitle={t('Location.location_section_subtitle')}
                action={actionButton}
            />

            <LocationSearchBar
                displayGrid={setDisplayGrid}
                isDisplayGrid={displayGrid}
                filterStore={filterStoreRef.current}
            />

            <Row gutter={[40, 40]} className="m-5">
                {displayGrid &&
                    campusesData &&
                    campusesData.map((x) => {
                        return (
                            x &&
                            x.id &&
                            x.name && (
                                <Col span={8} key={x.id}>
                                    <LocationItem
                                        id={x.id}
                                        name={x.name}
                                        status={x.status}
                                        backgroundImgUrl={x.mainImageUrl}
                                        editLocationModalOpen={
                                            userPermissionsStore.userIsAdministrator
                                                ? handleEditLocationModalOpen
                                                : undefined
                                        }
                                        editLocationStatusModalOpen={
                                            userPermissionsStore.userIsAdministrator
                                                ? handleEditLocationStatusModalOpen
                                                : undefined
                                        }
                                    />
                                </Col>
                            )
                        );
                    })}

                {!displayGrid && (
                    <Table
                        className="table-striped-rows table-action-rows"
                        bordered
                        columns={columns}
                        rowKey={(record: LightCampusDetailsInfo): string => record.id!}
                        dataSource={campusesData}
                        onChange={handleTableChange}
                        onRow={onRowClick}
                    />
                )}
            </Row>

            {createOrEditModal.modalOpen && (
                <CreateLocationModal
                    visible={createOrEditModal.modalOpen}
                    onComplete={(success: boolean): void => {
                        setCreateOrEditModal({ modalOpen: false, editionData: undefined });
                        if (success) locationStore.setLocations();
                    }}
                    campusData={createOrEditModal.editionData}
                />
            )}

            {editLocationStatusModal.modalOpen && (
                <EditLocationStatusModal
                    visible={editLocationStatusModal.modalOpen}
                    onComplete={(): void =>
                        setEditLocationStatusModal({ modalOpen: false, campusId: undefined })
                    }
                    campusId={editLocationStatusModal.campusId}
                />
            )}

            {reorderModalOpen && (
                <ReorderModal
                    visible={reorderModalOpen}
                    dataSource={
                        campusesData
                            ? campusesData.map(
                                  (campus) =>
                                      ({
                                          id: campus.id,
                                          name: campus.name,
                                          order: campus.order,
                                      } as ReorderModel)
                              )
                            : []
                    }
                    onDismiss={(): void => setReorderModalOpen(false)}
                    manual={isManual}
                    onSubmit={onReorderSubmit}
                    modelName={'location'}
                />
            )}
        </div>
    );
});

export default Locations;
