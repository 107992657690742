import { ManagerUserDto } from 'Api/Features/ManagerUsers/Dtos/ManagerUserDto';
import { LightContactInfoDto } from '../../Api/Features/General/Dtos/LightContactInfoDto';
import { ManagementRoleWithContextDto } from '../../Api/Features/Accounts/Dtos/ManagementRoleWithContextDto';
import { AddressDto } from 'Api/Features/General/Dtos/AddressDto';

export class ManagerUser implements ManagerUserDto {
    constructor(dto: ManagerUserDto) {
        Object.assign(this, dto);
    }
    id = '';
    firstName: string | null = null;
    lastName: string | null = null;
    imageUrl: string | null = null;
    contactInfo: LightContactInfoDto | null = null;
    roles: Array<ManagementRoleWithContextDto | null> | null = null;
    wifiPassword?: string | null;
    address?: AddressDto | null;

    get name(): string | null {
        if (this.firstName && this.lastName) return `${this.firstName} ${this.lastName}`;
        if (this.firstName) return this.firstName;
        if (this.lastName) return this.lastName;
        return null;
    }

    get email(): string | null | undefined {
        return this.contactInfo?.email;
    }
}
