import { Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import { GetRevenueAndOccupancyReportRequestDto } from 'Api/Features/Reports/Dtos/RevenueAndOccupancy/GetRevenueAndOccupancyReportRequestDto';
import Icon from 'Components/icons/icon';
import { ListSectionHeader } from 'Components/list-section-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import TableActionsButtons from 'Components/table-action-buttons/table-actions-buttons';
import { TableFilters } from 'Components/table-filters';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import {
    ALL_LOCATIONS,
    MOMENT_PARSING_FORMAT,
    MOMENT_PARSING_MONTH_FORMAT,
} from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import moment from 'moment';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { RevenueAndOccupancyReportService } from 'Services/RevenueAndOccupancyReportService';
import { FilterStore } from 'Stores';
import { showFile } from 'Utils';
import { cleanVal, currencyFormatter, percentFormatter } from 'Utils/NumberUtils';
import { images, theme } from 'variant';
import './index.less';

const { usersHeader } = images;

const renderCurrency = (val?: number): string => currencyFormatter(cleanVal.number(val));
const renderPercentage = (val?: number): string => percentFormatter(cleanVal.number(val));

enum CampusRowType {
    Target = 'target',
    Actual = 'actual',
    Variance = 'variance',
}
interface CampusRow {
    type: CampusRowType;
    revenueCurrentMonth: string;
    revenueQuarterEnd: string;
    revenueYearEnd: string;
    occupancyCurrentMonth: string;
    occupancyQuarterEnd: string;
    occupancyYearEnd: string;
}

interface CampusFlatData {
    id: string;
    isTitle: boolean;
    campusName: string;
}

interface CampusRowFlat extends CampusRow {
    id: string;
    isTitle: boolean;
}

type ExctractRevenueAndOccupancyFlatData = CampusFlatData | CampusRowFlat;

const breadcrumbs: BreadcrumbSegment[] = [
    {
        nameKey: 'reports',
        path: 'reports',
    },
    {
        nameKey: 'Reports.revenue_occupancy',
        path: 'revenue-occupancy',
    },
];

const advancedFilters: AdvancedFilter[] = [];

const RevenueOccupancy: FunctionComponent = observer(() => {
    const revenueAndOccupancyReportService = useService(RevenueAndOccupancyReportService);
    const { locationStore, globalLoadingStore, toastStore, userPermissionsStore, requestStore } =
        useStores();
    const [revenueAndOccupancyData, setRevenueAndOccupancyData] =
        useState<ExctractRevenueAndOccupancyFlatData[]>();
    const [revenueAndOccupancyReportRequest, setRevenueAndOccupancyReportRequest] =
        useState<GetRevenueAndOccupancyReportRequestDto>();
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { t } = useTranslation();

    const onExportClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            let document;
            if (revenueAndOccupancyReportRequest) {
                const campusName =
                    locationStore?.locations.find(
                        (x) => x.id === revenueAndOccupancyReportRequest?.campusIds?.[0]
                    )?.name || ALL_LOCATIONS;

                document = await revenueAndOccupancyReportService.exportRevenueAndOccupancyReport(
                    revenueAndOccupancyReportRequest
                );

                showFile(
                    document,
                    `${t('Reports.revenue_occupancy')} - ${moment(
                        revenueAndOccupancyReportRequest.date
                    ).format(MOMENT_PARSING_MONTH_FORMAT)} - ${campusName}`
                );
            }
        } catch (e) {
            if (!e.treated) {
                if (e.treated) {
                    toastStore.genericError();
                }
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const tableActionButtons = [
        {
            iconName: 'Download',
            toolTip: 'export_table',
            onClick: (): Promise<void> => onExportClick(),
        },
    ];

    const renderTitleRow = (record: any): ReactNode => {
        if (record?.isTitle) {
            return {
                children: record.campusName,
                props: {
                    colSpan: 7,
                    align: 'center',
                    className: 'title-row',
                },
            };
        } else {
            return {
                children: record.type,
                props: {
                    colSpan: 1,
                    className: 'default-row',
                },
            };
        }
    };

    const renderRow = (record: any, field: string): ReactNode => {
        if (record?.isTitle) {
            return {
                props: {
                    colSpan: 0,
                },
            };
        } else {
            return {
                children: record[field],
                props: {
                    colSpan: 1,
                    className: 'default-row',
                },
            };
        }
    };

    const columns: ColumnType<ExctractRevenueAndOccupancyFlatData>[] = [
        {
            title: t('Unit.unit_type'),
            render: renderTitleRow,
        },
        {
            title: t('Reports.current_month'),
            render: (record): ReactNode => renderRow(record, 'revenueCurrentMonth'),
            align: 'right',
        },
        {
            title: t('Reports.end_of_quarter'),
            render: (record): ReactNode => renderRow(record, 'revenueQuarterEnd'),
            align: 'right',
        },
        {
            title: t('Reports.end_of_year'),
            render: (record): ReactNode => renderRow(record, 'revenueYearEnd'),
            align: 'right',
        },
        {
            title: t('Reports.current_month'),
            render: (record): ReactNode => renderRow(record, 'occupancyCurrentMonth'),
            align: 'right',
        },
        {
            title: t('Reports.end_of_quarter'),
            render: (record): ReactNode => renderRow(record, 'occupancyQuarterEnd'),
            align: 'right',
        },
        {
            title: t('Reports.end_of_year'),
            render: (record): ReactNode => renderRow(record, 'occupancyYearEnd'),
            align: 'right',
        },
    ];

    const renderTableHeader = (): ReactNode => (
        <>
            <div></div>
            <div>{t('revenue')}</div>
            <div>{t('occupancy')}</div>
        </>
    );

    const fetchRevenueAndOccupancy = useCallback(
        async (params: { date?: string; campusIds: string[] | null }) => {
            globalLoadingStore.addLoading();

            try {
                const revenueAndOccupancyResponse =
                    await revenueAndOccupancyReportService.getRevenueAndOccupancyReport({
                        date: params.date,
                        campusIds: params.campusIds,
                    });

                const nonNullRevenueAndOccupancyResponse = revenueAndOccupancyResponse?.campuses
                    ? revenueAndOccupancyResponse?.campuses
                          .filter((campus) => campus !== null)
                          .map((campus) => campus!)
                    : [];

                const revenueAndOccupancyData: ExctractRevenueAndOccupancyFlatData[][] =
                    nonNullRevenueAndOccupancyResponse.map((campus) => {
                        const rows: CampusRow[] = [];

                        for (const item in CampusRowType) {
                            const enumKey = item as keyof typeof CampusRowType;
                            const valKey = CampusRowType[enumKey];

                            rows.push({
                                type: t(`Reports.revenue_occupancy_row_type_${item}`),
                                revenueCurrentMonth: renderCurrency(
                                    campus.currentMonthRevenue?.[valKey]
                                ),
                                revenueQuarterEnd: renderCurrency(
                                    campus.quarterEndRevenue?.[valKey]
                                ),
                                revenueYearEnd: renderCurrency(campus.yearEndRevenue?.[valKey]),
                                occupancyCurrentMonth: renderPercentage(
                                    campus.currentMonthOccupancy?.[valKey]
                                ),
                                occupancyQuarterEnd: renderPercentage(
                                    campus.quarterEndOccupancy?.[valKey]
                                ),
                                occupancyYearEnd: renderPercentage(
                                    campus.yearEndOccupancy?.[valKey]
                                ),
                            });
                        }

                        const flatData: ExctractRevenueAndOccupancyFlatData[] = [
                            {
                                id: cleanVal.string(campus.id),
                                isTitle: true,
                                campusName: cleanVal.string(campus.name),
                            },
                        ];

                        rows.forEach((row) => {
                            flatData.push({
                                id: campus.id + row.type,
                                isTitle: false,
                                ...row,
                            });
                        });

                        return flatData;
                    });

                revenueAndOccupancyData.push([
                    {
                        id: 'total',
                        isTitle: true,
                        campusName: t('total'),
                    },
                    {
                        id: 'total-' + CampusRowType.Target,
                        isTitle: false,
                        type: t(`Reports.revenue_occupancy_row_type_Target`),
                        revenueCurrentMonth: renderCurrency(
                            revenueAndOccupancyResponse?.currentMonthRevenue?.target
                        ),
                        revenueQuarterEnd: renderCurrency(
                            revenueAndOccupancyResponse?.quarterEndRevenue?.target
                        ),
                        revenueYearEnd: renderCurrency(
                            revenueAndOccupancyResponse?.yearEndRevenue?.target
                        ),
                        occupancyCurrentMonth: renderCurrency(
                            revenueAndOccupancyResponse?.currentMonthOccupancy?.target
                        ),
                        occupancyQuarterEnd: renderCurrency(
                            revenueAndOccupancyResponse?.quarterEndOccupancy?.target
                        ),
                        occupancyYearEnd: renderCurrency(
                            revenueAndOccupancyResponse?.yearEndOccupancy?.target
                        ),
                    },
                    {
                        id: 'total-' + CampusRowType.Actual,
                        isTitle: false,
                        type: t(`Reports.revenue_occupancy_row_type_Actual`),
                        revenueCurrentMonth: renderCurrency(
                            revenueAndOccupancyResponse?.currentMonthRevenue?.actual
                        ),
                        revenueQuarterEnd: renderCurrency(
                            revenueAndOccupancyResponse?.quarterEndRevenue?.actual
                        ),
                        revenueYearEnd: renderCurrency(
                            revenueAndOccupancyResponse?.yearEndRevenue?.actual
                        ),
                        occupancyCurrentMonth: renderCurrency(
                            revenueAndOccupancyResponse?.currentMonthOccupancy?.actual
                        ),
                        occupancyQuarterEnd: renderCurrency(
                            revenueAndOccupancyResponse?.quarterEndOccupancy?.actual
                        ),
                        occupancyYearEnd: renderCurrency(
                            revenueAndOccupancyResponse?.yearEndOccupancy?.actual
                        ),
                    },
                    {
                        id: 'total-' + CampusRowType.Variance,
                        isTitle: false,
                        type: t(`Reports.revenue_occupancy_row_type_Variance`),
                        revenueCurrentMonth: renderCurrency(
                            revenueAndOccupancyResponse?.currentMonthRevenue?.variance
                        ),
                        revenueQuarterEnd: renderCurrency(
                            revenueAndOccupancyResponse?.quarterEndRevenue?.variance
                        ),
                        revenueYearEnd: renderCurrency(
                            revenueAndOccupancyResponse?.yearEndRevenue?.variance
                        ),
                        occupancyCurrentMonth: renderCurrency(
                            revenueAndOccupancyResponse?.currentMonthOccupancy?.variance
                        ),
                        occupancyQuarterEnd: renderCurrency(
                            revenueAndOccupancyResponse?.quarterEndOccupancy?.variance
                        ),
                        occupancyYearEnd: renderCurrency(
                            revenueAndOccupancyResponse?.yearEndOccupancy?.variance
                        ),
                    },
                ]);

                setRevenueAndOccupancyData(revenueAndOccupancyData.flat());
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [globalLoadingStore, revenueAndOccupancyReportService, t]
    );

    useEffect(() => {
        const filterStore = filterStoreRef.current;
        filterStore.month = moment().format(MOMENT_PARSING_FORMAT);
    }, []);

    useEffect(() => {
        const filterStore = filterStoreRef.current;

        const disposer = autorun(() => {
            const date = moment(filterStore.month).format(MOMENT_PARSING_FORMAT);

            const campusId =
                filterStore.currentLocationId === 'all'
                    ? requestStore.getReportRequestAvailableLocationIdsForUser()
                    : [filterStore.currentLocationId];

            fetchRevenueAndOccupancy({
                date: date,
                campusIds: campusId,
            });

            setRevenueAndOccupancyReportRequest({
                date: date,
                campusIds: campusId,
            });
        });

        return (): void => {
            disposer();
        };
    }, [fetchRevenueAndOccupancy]);

    return (
        <div className="RevenueOccupancy">
            <ListSectionHeader
                routes={breadcrumbs}
                backgroundImageUrl={usersHeader}
                defaultImg={<Icon iconName="Dollar" fill={theme['primary-color']} />}
                title={t('Reports.revenue_occupancy')}
                subTitle={t('Reports.revenue_occupancy_overview')}
            />

            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeMonth
                    includeLocations
                    availableLocations={userPermissionsStore.availableLocationsForUser}
                />

                <TableActionsButtons buttonsList={tableActionButtons} />

                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    dataSource={revenueAndOccupancyData}
                    rowKey={(record): string => `${record.id}`}
                    pagination={false}
                    title={renderTableHeader}
                />
            </Content>
        </div>
    );
});

export default RevenueOccupancy;
