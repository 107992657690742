import { observable, action } from 'mobx';

interface ToastConfig {
    type: 'info' | 'success' | 'error' | 'warning';
    message?: string;
    messageKey?: string;
    displayTime?: number;
}

class ToastStore {
    @observable currentToast: ToastConfig | null = null;

    @action
    genericError(): void {
        this.currentToast = {
            type: 'error',
            messageKey: 'Errors.unexpected_error',
        };
    }

    @action
    serverError(): void {
        this.currentToast = {
            type: 'error',
            messageKey: 'Errors.server_error',
        };
    }

    @action
    toast(config: ToastConfig): void {
        this.currentToast = config;
    }
}

export default ToastStore;
