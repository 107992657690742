export const ALL_LOCATIONS = 'all';
export const ALL_OWNERS = 'all';
export const ALL_ROOMS = 'all';
export const RETURN_URL = 'returnUrl';
export const ALL_LEADS = 'all';
export const ALL_ROLES = 'allRoles';
export const MY_LEADS = 'My leads';
export const DISPLAY_DATE_FORMAT = 'MMMM DD, YYYY';
export const DISPLAY_DAY_MONTH_FORMAT = 'dddd MMMM DD';
export const DISPLAY_MONTH_DAY_FORMAT = 'MMMM DD';
export const MOMENT_PARSING_FORMAT = 'YYYY-MM-DD';
export const MOMENT_PARSING_MONTH_FORMAT = 'YYYY-MM';
export const MOMENT_TIME_FORMAT = 'HH:mm:00';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:00';
export const DATETIME_LTS_FORMAT = 'YYYY-MM-DD LTS';
export const MY_OPPORTUNITIES = 'My opportunities';
export const ALL_OPPORTUNITIES = 'all';
export const CREDIT_SUGGESTION_DIVIDER = 250;
export const ONBOARDING_CATEGORY_ID_SPACE = '4d7dcbb7-8940-4214-8571-41aea8b388c0';
export const ONBOARDING_CATEGORY_ID_MEMBER_INVITATION = 'f486c1af-8e3c-4422-936b-4cba3e049a73';
export const ONBOARDING_CATEGORY_ID_MEMBER_ACCESS = '9067dc32-2c39-4e12-ba4f-50dc0f02d114';
export const ALL_ASSIGNED_MANAGERS = 'All assigned managers';
export const UNASSIGNED_LOCATIONS = 'Unassigned locations';
export const TIME_PICKER_FORMAT = 'HH:mm';
export const DISPLAY_DATETIME_12H_WRITTEN_FORMAT = 'MMMM D, YYYY h:mm A';
export const DISPLAY_DATETIME_12H = 'YYYY-MM-DD h:mm A';
export const DISPLAY_TIME_12H_FORMAT = 'h:mm A';
export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_TABLE_PAGE_SIZE = 10;
export const ACTIVITY_CALENDAR_DAY_HEADER_DISPLAY_FORMAT = 'ddd DD';
export const ACTIVTY_CALENDAR_EVENT_DATE_DISPLAY = 'dddd MMMM D';
export const MMMM_D_FORMAT = 'MMMM D';
export const MMMM_D_YYYY_FORMAT = 'MMMM D, YYYY';
export const US_LANGUAGE_CODE = 'EN-US';
export const UTC_DATE_TIME_ZONE = 'YYYY-MM-DDT00:00:00-00:00';
export const SHORT_MONTH_DATE = 'MMM D';
export const DISPLAY_MONTH_FORMAT = 'MMMM';
export const BILLION = 1000000000;
export const MILLION = 1000000;
export const TENTHOUSAND = 10000;
export const THOUSAND = 1000;
export const HUNDRED = 100;
export const TIME_WITH_OFFSET_FORMAT = 'HH:mm:ssZ';
export const BOOKING_CALENDAR_TIMESLOT_OFFSET_VALUE = 40;
export const DAY_MONTH_DATE_YEAR_DISPLAY_FORMAT = 'dddd, MMMM D, YYYY';
export const YEAR_FORMAT = 'YYYY';
export const API_RELEASE_VERSION = 1.2;
export const WEBSITE_REGEX =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
export const PHONE_REGEX =
    /^\s*((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?\s*$/;
export const TABLE_DEBOUNCE_DELAY = 400;
export const PAGE_SIZE_100 = 100;
export const DISPLAY_MONTH_YEAR_FORMAT = 'MMMM YYYY';
export const DISPLAY_TIME_12H_NO_PERIOD = 'h:mm';
export const MINUTES_IN_A_DAY = 1440;
