import React, { FunctionComponent } from 'react';
import './badge.less';

interface BadgeProps {
    type: 'primary' | 'dark';
}

const Badge: FunctionComponent<BadgeProps> = ({ children, type }) => {
    return <span className={`Badge Badge-${type}`}>{children}</span>;
};

export default Badge;
