import { Skeleton } from 'antd';
import Title from 'antd/lib/typography/Title';
import { InvoiceDto } from 'Api/Features/Invoices/Dtos/InvoiceDto';
import { AchPaymentMethodDto } from 'Api/Features/PaymentMethods/Dtos/AchPaymentMethodDto';
import { CreditCardPaymentMethodDto } from 'Api/Features/PaymentMethods/Dtos/CreditCardPaymentMethodDto';
import { PaymentMethodTypeDto } from 'Api/Features/PaymentMethods/Dtos/PaymentMethodTypeDto';
import Icon from 'Components/icons/icon';
import { RoutedBreadcrumb } from 'Components/routed-breadcrumb';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { Tag } from 'Components/tag';
import { getInvoiceStatusTag, TagColors } from 'Components/tag/tag';
import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { cleanVal, currencyFormatter } from 'Utils/NumberUtils';
import { images, theme } from '../../variant';
import { ImageWithPlaceholder } from '../image-with-placeholder';
import { ImageWithPlaceholderProps } from '../image-with-placeholder/image-with-placeholder';
import './index.less';

const LabelWithTitle: FunctionComponent<{ title: string; label: string | number }> = ({
    title,
    label,
}) => {
    return (
        <>
            <Title level={2}>{title}</Title>
            <strong className="label-value">{label}</strong>
        </>
    );
};

export interface HeaderData {
    iconName?: string;
    title?: string | null;
    subTitle?: string;
}

export interface ContactData {
    imageUrl?: string;
    isSubscribed?: boolean;
    paymentMethod?: AchPaymentMethodDto | CreditCardPaymentMethodDto | null;
    creditBalance?: number;
    availableDayPasses?: number;
}

export interface InvoiceData {
    amountDue: number;
    invoice: InvoiceDto;
}

interface ContactsHeaderProps extends ImageWithPlaceholderProps {
    routes?: BreadcrumbSegment[];
    headerData: HeaderData;
    contactData?: ContactData;
    invoiceData?: InvoiceData;
    note?: ReactNode;
    action?: ReactNode;
    loading?: boolean;
}

const ContactsHeader: FunctionComponent<ContactsHeaderProps> = ({
    routes = [],
    contactData,
    invoiceData,
    headerData,
    note,
    action,
    loading,
}) => {
    const { t } = useTranslation();

    const contactTagLabel = contactData?.isSubscribed
        ? t('Contact.subscribed')
        : t('Contact.no_subscription');

    const renderPaymentMethod = (): string => {
        switch (contactData?.paymentMethod?.paymentMethodType) {
            case PaymentMethodTypeDto.ACH: {
                const achInfo = contactData.paymentMethod as AchPaymentMethodDto;
                return `${achInfo.bankName} ${achInfo.accountNumber}`;
            }
            case PaymentMethodTypeDto.CreditCard: {
                const creditCardInfo = contactData.paymentMethod as CreditCardPaymentMethodDto;
                return `${creditCardInfo.brand} ending with ${creditCardInfo.last4}`;
            }
            case PaymentMethodTypeDto.Unavailable:
            default:
                return t('none');
        }
    };

    return (
        <div
            className="ContactsHeader"
            style={{
                backgroundImage: `linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(${images.usersHeader}`,
            }}
        >
            <Skeleton loading={loading} active avatar>
                <ImageWithPlaceholder
                    className="header-icon"
                    imgSrc={contactData?.imageUrl || undefined}
                    defaultImg={
                        <Icon
                            iconName={headerData?.iconName ? headerData.iconName : 'User'}
                            fill={theme['primary-color']}
                        />
                    }
                />

                <div className="header-info">
                    <RoutedBreadcrumb routes={routes} />

                    <Title level={1}>{headerData.title}</Title>

                    {headerData?.subTitle && <div>{headerData?.subTitle}</div>}

                    {contactData && (
                        <div className="contact-info">
                            <div>
                                <div>
                                    <Tag
                                        label={contactTagLabel}
                                        boldLabel
                                        color={
                                            contactData.isSubscribed
                                                ? TagColors.default
                                                : TagColors.slateBlue40
                                        }
                                    />
                                </div>
                            </div>
                            <div>
                                <LabelWithTitle
                                    title={t('Reports.payment_method')}
                                    label={renderPaymentMethod()}
                                />
                            </div>
                            <div>
                                <LabelWithTitle
                                    title={t('User.contact_profile_credit_balance')}
                                    label={cleanVal.number(contactData?.creditBalance)}
                                />
                            </div>
                            <div>
                                <LabelWithTitle
                                    title={t('User.contacts_available_day_passes')}
                                    label={cleanVal.number(contactData?.availableDayPasses)}
                                />
                            </div>
                        </div>
                    )}

                    {invoiceData && (
                        <div className="invoice-info">
                            <div>{getInvoiceStatusTag(invoiceData.invoice, t)}</div>
                            <div>
                                <LabelWithTitle
                                    title={t('Contact.contacts_invoices_amount_due')}
                                    label={currencyFormatter(invoiceData?.amountDue)}
                                />
                            </div>
                        </div>
                    )}
                </div>

                {note && <div className="header-note">{note}</div>}

                {action && <div className="header-action">{action}</div>}
            </Skeleton>
        </div>
    );
};
export default ContactsHeader;
