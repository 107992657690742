import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { SalesActivityProxy } from 'Api/Features/SalesActivities/SalesActivityProxy';
import { GetSalesActivitiesRequestDto } from 'Api/Features/SalesActivities/Dtos/GetSalesActivitiesRequestDto';
import { GetSalesActivitiesResponseDto } from 'Api/Features/SalesActivities/Dtos/GetSalesActivitiesResponseDto';
import { GetManagerUserSalesActivitiesResponseDto } from 'Api/Features/SalesActivities/Dtos/GetManagerUserSalesActivitiesResponseDto';
import { UpdateManagerUserSalesActivityStatusRequestDto } from 'Api/Features/SalesActivities/Dtos/UpdateManagerUserSalesActivityStatusRequestDto';
import { UpdateManagerUserSalesActivityOrderingsRequest } from 'Api/Features/SalesActivities/Dtos/UpdateManagerUserSalesActivityOrderingsRequest';
import { GetManagerUserSalesActivitiesRequestDto } from 'Api/Features/SalesActivities/Dtos/GetManagerUserSalesActivitiesRequestDto';

@inject(SalesActivityProxy)
export class SalesActivityService extends ApiService {
    constructor(private readonly salesActivityProxy: SalesActivityProxy) {
        super();
    }

    public async getSalesActivities(
        request: GetSalesActivitiesRequestDto | null
    ): Promise<GetSalesActivitiesResponseDto | null> {
        const data: GetSalesActivitiesResponseDto | null = await this.salesActivityProxy.getSalesActivities(
            request
        );
        return data;
    }

    public async getManagerUserSalesActivities(
        managerUserId: string | null,
        request: GetManagerUserSalesActivitiesRequestDto | null
    ): Promise<GetManagerUserSalesActivitiesResponseDto | null> {
        const data: GetManagerUserSalesActivitiesResponseDto | null = await this.salesActivityProxy.getManagerUserSalesActivities(
            managerUserId,
            request
        );
        return data;
    }

    public async updateManagerUserSalesActivityStatus(
        salesActivityId: string,
        request: UpdateManagerUserSalesActivityStatusRequestDto | null
    ): Promise<void> {
        await this.salesActivityProxy.updateManagerUserSalesActivityStatus(
            salesActivityId,
            request
        );
    }

    public async updateManagerUserSalesActivityOrderings(
        managerUserId: string | null,
        request: UpdateManagerUserSalesActivityOrderingsRequest | null
    ): Promise<void> {
        await this.salesActivityProxy.updateManagerUserSalesActivityOrderings(
            managerUserId,
            request
        );
    }
}
