import React, { FunctionComponent, useEffect } from 'react';
import { Button, Form } from 'antd';
import BaseModal from 'Components/base-modal/base-modal';
import { Dollar } from 'Components/icons';
import { useService, useStores } from 'Hooks';
import { useTranslation } from 'react-i18next';
import './set-occupancy-objective-modal.less';
import { CampusService } from 'Services/CampusService';
import { UpdateCampusOccupancyObjectiveRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusOccupancyObjectiveRequestDto';
import { CampusOccupancyObjectiveDto } from 'Api/Features/Campuses/Dtos/CampusOccupancyObjectiveDto';
import moment from 'moment';
import SetOccupancyObjectiveFormSection from './set-occupancy-objective-form-section';


interface SetTargetsModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    campusId: string;
    objective?: CampusOccupancyObjectiveDto;
}

const SetOccupancyObjectiveModal: FunctionComponent<SetTargetsModalProps> = ({
    visible,
    onComplete,
    campusId,
    objective,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const campusService = useService(CampusService);
    const [form] = Form.useForm();
    //#endregion

    //#region Effects
    useEffect(() => {
        form.setFieldsValue({
            dueDate: objective?.dueDate ? moment(objective.dueDate) : undefined,
            rate: objective?.rate ?? undefined,
        });
    }, [objective, form]);
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Dollar />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`confirm_positive`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const values = form.getFieldsValue();
        const request: UpdateCampusOccupancyObjectiveRequestDto = {
            dueDate: values.dueDate ?? undefined,
            rate: values.rate ?? undefined
        };

        try {
            globalLoadingStore.addLoading();

            await campusService.updateOccupancyObjective(campusId, request);

            toastStore.toast({
                type: 'success',
                messageKey: t('RevenueOccupancy.objective_edit_success'),
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('RevenueOccupancy.edit_occupancy_objective')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="SetOccupancyObjectiveModal">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    
                    <SetOccupancyObjectiveFormSection />

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(SetOccupancyObjectiveModal);
