import { Button, Col, Form, Radio, Row, Select, Typography } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { CreateUpdateAddressRequestDto } from 'Api/Features/General/Dtos/CreateUpdateAddressRequestDto';
import { LightContactInfoDto } from 'Api/Features/General/Dtos/LightContactInfoDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { CreateMemberRequestDto } from 'Api/Features/Members/Dtos/CreateMemberRequestDto';
import { CreateMemberResponseDto } from 'Api/Features/Members/Dtos/CreateMemberResponseDto';
import { MembershipRoleDto } from 'Api/Features/Memberships/Dtos/MembershipRoleDto';
import BaseModal from 'Components/base-modal/base-modal';
import FormStep from 'Components/form-step/form-step';
import Icon from 'Components/icons/icon';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { Membership } from 'Models/Memberships/Membership';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CompanyFormSection from 'Routes/authenticated/opportunities/company-form-section';
import ContactFormSection from 'Routes/authenticated/opportunities/contact-form-section';
import {
    CreateContactSchema,
    CreateExistingCompanyContactSchema,
    CreateNewCompanyContactSchema,
} from 'Schemas/CreateContactSchema';
import { MemberService } from 'Services/MemberService';
import { MembershipService } from 'Services/MembershipService';
import { dateMomentToString } from 'Utils/TimeUtils';
import { theme } from 'variant';
import './create-contact.less';

const { Title } = Typography;

const formGutter: [Gutter, Gutter] = [40, 0];

enum CreateContactFormStep {
    Contact = 'Contact',
    Company = 'Company',
}

export enum CompanyRadioButtonValues {
    New = 'New',
    Existing = 'Existing',
    None = 'None',
}

interface CreateContactModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    onCreateOpportunity: (
        contactId: string,
        contactName: string,
        membershipId: string,
        membershipName: string
    ) => void;
}

const CreateContact: FunctionComponent<CreateContactModalProps> = ({
    visible,
    onComplete,
    onCreateOpportunity,
}) => {
    const memberService = useService(MemberService);
    const membershipService = useService(MembershipService);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [activeFormStep, setactiveFormStep] = useState<CreateContactFormStep>(
        CreateContactFormStep.Contact
    );
    const [companyRadioState, setCompanyRadioState] = useState<CompanyRadioButtonValues>(
        CompanyRadioButtonValues.Existing
    );
    const [contactInfo, setContactInfo] = useState<any>(undefined);

    const [contactImageDetails, setContactImageDetails] = useState<ImageDetails[]>();
    const [companyImageDetails, setCompanyImageDetails] = useState<ImageDetails[]>();

    const [selectedExistingMembershipId, setSelectedExistingMembershipId] = useState<
        string | undefined
    >(undefined);
    const [selectedExistingMembshipName, setSelectedExistingMembershipName] = useState<string>();
    const [companiesSearchTerm, setCompaniesSearchTerm] = useState('');
    const [companiesSearchResults, setCompaniesSearchResults] = useState<Membership[]>([]);
    const [companiesOptions, setCompaniesOptions] = useState<SelectCustomOption[]>([]);
    const [selectedCompanyOptions, setSelectedCompanyOptions] = useState<SelectCustomOption[]>([]);
    const [companiesMaxResults, setCompaniesMaxResults] = useState(false);
    const [companiesCurrentPage, setCompaniesCurrentPage] = useState(0);

    const [selectLoading, setSelectLoading] = useState(false);
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [contactErrors, validateContactForm, resetContactErrors] = useFormValidation(
        CreateContactSchema,
        form
    );
    const [existingCompanyErrors, validateExistingCompanyForm, resetExistingCompanyErrors] =
        useFormValidation(CreateExistingCompanyContactSchema, form);
    const [newCompanyErrors, validateNewCompanyForm, resetNewCompanyErrors] = useFormValidation(
        CreateNewCompanyContactSchema,
        form
    );

    const modalRef = useRef<HTMLDivElement>(null);

    const pageSize = 25;

    const resetCompaniesSearch = (): void => {
        setCompaniesCurrentPage(0);
        setCompaniesSearchResults([]);
        setCompaniesSearchTerm('');
        setCompaniesMaxResults(false);
    };

    const handleCompaniesKeywordsChange = useCallback((value: string): void => {
        resetCompaniesSearch();
        setCompaniesSearchTerm(value);
    }, []);

    const handleCompaniesMenuScrollToBottom = (): void => {
        if (!companiesMaxResults) {
            setCompaniesCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const onBackClick = (): void => {
        if (activeFormStep === CreateContactFormStep.Company) {
            setactiveFormStep(CreateContactFormStep.Contact);
            if (modalRef.current) modalRef.current.scrollIntoView();
        }
    };

    const onNextClick = async (): Promise<void> => {
        const formValues = form.getFieldsValue();

        const contactForm: CreateMemberRequestDto = {
            image: contactImageDetails
                ? ({
                      delete: contactImageDetails[0].isDeleted,
                      uploadBase64: contactImageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null,
            salutation: formValues['contact.salutation'],
            firstName: formValues['contact.firstName'],
            middleName: formValues['contact.middleName'],
            lastName: formValues['contact.lastName'],
            birthDate: dateMomentToString(formValues['contact.birthDate']),
            wifiPassword: formValues['contact.wifiPassword'],
            jobPosition: formValues['contact.jobPosition'],
            introduction: formValues['contact.introduction'],
            expertiseIds: formValues['contact.expertises']?.map(
                (expertise: { value: string; label: string }) => expertise.value
            ),
            contactInfo: {
                phone1: formValues['contact.contactInfo.phone1'],
                phone2: formValues['contact.contactInfo.phone2'],
                email: formValues['contact.contactInfo.email'],
                angelListUrl: formValues['contact.contactInfo.angelListUrl'],
                twitterUrl: formValues['contact.contactInfo.twitterUrl'],
                facebookUrl: formValues['contact.contactInfo.facebookUrl'],
                linkedInUrl: formValues['contact.contactInfo.linkedInUrl'],
                instagramUrl: formValues['contact.contactInfo.instagramUrl'],
            },
            address: {
                addressLine1: formValues['contact.address.addressLine1'],
                city: formValues['contact.address.city'],
                country: formValues['contact.address.country'],
                postalCode: formValues['contact.address.postalCode'],
                state: formValues['contact.address.state'],
            } as CreateUpdateAddressRequestDto,
            isPrivate: !formValues['contact.isPrivate'],
            membershipRoles: [MembershipRoleDto.Owner],
        };

        if (!(await validateContactForm({ contact: contactForm }))) return;

        setContactInfo(contactForm);
        setactiveFormStep(CreateContactFormStep.Company);
        if (modalRef.current) modalRef.current.scrollIntoView();
    };

    const dismiss = (success = false, id?: string): void => {
        onComplete(success, id);
        form.resetFields();
        resetContactErrors();
        resetExistingCompanyErrors();
        resetNewCompanyErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="User" />,
                title: t('User.contacts_create_contact_confirm_title'),
                message: t('User.contacts_create_contact_confirm_message'),
                positiveText: t('User.contacts_create_contact_confirm_positive'),
                negativeText: t('User.contacts_create_contact_confirm_negative'),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const membershipRole = [formValues.membershipRoles];
        const newCompany = {
            name: formValues['membership.name'],
            billingName: formValues['membership.billingName'],
            billingEmail: formValues['membership.billingEmail'],
            presentation: formValues['membership.presentation'],
            numberOfEmployees: formValues['membership.numberOfEmployees'],
            industry: formValues['membership.industry'],
            image: companyImageDetails
                ? ({
                      delete: companyImageDetails[0].isDeleted,
                      uploadBase64: companyImageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null,
            baseCampusIds: formValues['membership.locations']?.map(
                (location: { value: string; label: string }) => location.value
            ),
            expertiseIds: formValues['membership.expertises']?.map(
                (expertise: { value: string; label: string }) => expertise.value
            ),
            address: {
                addressLine1: formValues['membership.address.addressLine1'],
                city: formValues['membership.address.city'],
                country: formValues['membership.address.country'],
                postalCode: formValues['membership.address.postalCode'],
                state: formValues['membership.address.state'],
            } as CreateUpdateAddressRequestDto,
            legalContact: {
                firstName: formValues['membership.legalContact.firstName'],
                lastName: formValues['membership.legalContact.lastName'],
                phone: formValues['membership.legalContact.phone'],
                email: formValues['membership.legalContact.email'],
                birthDate: dateMomentToString(formValues['membership.legalContact.birthDate']),
                addressLine1: formValues['membership.legalContact.addressLine1'],
                city: formValues['membership.legalContact.city'],
                country: formValues['membership.legalContact.country'],
                postalCode: formValues['membership.legalContact.postalCode'],
                state: formValues['membership.legalContact.state'],
            },
            contactInfo: {
                website: formValues['membership.contactInfo.website'],
                email: formValues['membership.contactInfo.email'],
                phone1: formValues['membership.contactInfo.phone1'],
                angelListUrl: formValues['membership.contactInfo.angelListUrl'],
                instagramUrl: formValues['membership.contactInfo.instagramUrl'],
                linkedInUrl: formValues['membership.contactInfo.linkedInUrl'],
                twitterUrl: formValues['membership.contactInfo.twitterUrl'],
                facebookUrl: formValues['membership.contactInfo.facebookUrl'],
            } as LightContactInfoDto,
        };

        switch (companyRadioState) {
            case CompanyRadioButtonValues.New:
                if (!(await validateNewCompanyForm({ membership: newCompany }))) return;
                break;

            case CompanyRadioButtonValues.Existing:
                if (
                    !(await validateExistingCompanyForm({
                        membershipId: selectedExistingMembershipId,
                    }))
                )
                    return;
                break;
        }

        try {
            globalLoadingStore.addLoading();
            let request: CreateMemberRequestDto;
            let legalContactAddress: CreateUpdateAddressRequestDto;
            let createMemberReponse: CreateMemberResponseDto | null;

            switch (companyRadioState) {
                case CompanyRadioButtonValues.New:
                    legalContactAddress = {
                        addressLine1: newCompany.legalContact.addressLine1,
                        city: newCompany.legalContact.city,
                        country: newCompany.legalContact.country,
                        postalCode: newCompany.legalContact.postalCode,
                        state: newCompany.legalContact.state,
                    };

                    delete newCompany.legalContact.addressLine1;
                    delete newCompany.legalContact.city;
                    delete newCompany.legalContact.country;
                    delete newCompany.legalContact.postalCode;
                    delete newCompany.legalContact.state;

                    request = {
                        ...contactInfo,
                        membership: {
                            ...newCompany,
                            legalContact: {
                                ...newCompany.legalContact,
                                address: legalContactAddress,
                            },
                        },
                    };

                    createMemberReponse = await memberService.createMember(request);
                    break;

                case CompanyRadioButtonValues.Existing:
                    request = {
                        ...contactInfo,
                        membershipId: selectedExistingMembershipId,
                        membershipRoles: membershipRole,
                    };

                    createMemberReponse = await memberService.createMember(request);
                    break;

                default:
                    request = {
                        ...contactInfo,
                        membershipRoles: null,
                    };

                    createMemberReponse = await memberService.createMember(request);
                    break;
            }

            toastStore.toast({
                type: 'success',
                messageKey: t('User.contacts_create_contact_confirmation'),
            });

            if (companyRadioState !== CompanyRadioButtonValues.None) {
                globalLoadingStore.removeLoading();
                if (
                    !(await confirmationModalStore.confirm({
                        icon: <Icon iconName="Plan" />,
                        title: t('Contact.create_an_opportunity'),
                        message: t('Contact.do_you_want_to_create_opportunity'),
                        positiveText: t('Contact.yes_create_opportunity'),
                        negativeText: t('Contact.no_create_opportunity'),
                    }))
                )
                    dismiss(true, createMemberReponse?.id!); //dismiss and redirect to contact page

                dismiss(); // dismiss but do not redirect to contact page

                onCreateOpportunity(
                    createMemberReponse?.id!,
                    `${contactInfo.firstName} ${contactInfo.lastName}`,
                    companyRadioState === CompanyRadioButtonValues.Existing
                        ? selectedExistingMembershipId!
                        : createMemberReponse?.membershipId!,
                    companyRadioState === CompanyRadioButtonValues.Existing
                        ? selectedExistingMembshipName
                        : formValues['membership.name']
                );
            } else {
                dismiss(true, createMemberReponse?.id!);
            }
        } catch (e) {
            if (e.response?.data?.modelState['contactInfo.Email']) {
                toastStore.toast({
                    type: 'error',
                    message: 'Contact email already in use',
                });
            } else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const isActiveFormStepOwner = activeFormStep === CreateContactFormStep.Contact;
    const actionsButtons = {
        prev: {
            label: isActiveFormStepOwner ? t('cancel') : t('back'),
            onClick: isActiveFormStepOwner ? exit : onBackClick,
        },
        next: {
            label: isActiveFormStepOwner ? t('next') : t('submit'),
            onClick: isActiveFormStepOwner ? onNextClick : submit,
        },
    };

    const searchCompanies = async (
        page: number,
        searchTerm: string | null
    ): Promise<Membership[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
        };

        const [results, totalItemCount] = await membershipService.getMemberships(args);

        if (results.length + pageSize * companiesCurrentPage >= totalItemCount) {
            setCompaniesMaxResults(true);
        }
        setSelectLoading(false);
        return results;
    };

    const debounceCompaniesSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchCompanies(page, searchTerm).then((results) => {
                setCompaniesSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    useEffect(() => {
        setSelectLoading(true);
        debounceCompaniesSearch.current(companiesCurrentPage, companiesSearchTerm);
    }, [companiesSearchTerm, companiesCurrentPage, companyRadioState, activeFormStep]);

    useEffect(() => {
        const searchResults = companiesSearchResults?.map(
            (x: Membership) =>
                ({
                    value: x?.id,
                    label: x?.name,
                    imageUrl: x?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedCompanyOptions);
        setCompaniesOptions(merged);
    }, [companiesSearchResults, selectedCompanyOptions]);

    return (
        <BaseModal
            className="FormModal CreateContact"
            visible={visible}
            title={t(`User.contacts_create_contact`)}
            onCancel={exit}
        >
            <div ref={modalRef}>
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <FormStep
                                Steps={Object.keys(CreateContactFormStep).map(
                                    (step: string, i: number) => {
                                        return {
                                            active: step === activeFormStep,
                                            stepNumber: i + 1,
                                            name: step,
                                        };
                                    }
                                )}
                            />
                        </Col>
                    </Row>

                    {isActiveFormStepOwner && (
                        <ContactFormSection
                            setImageDetails={setContactImageDetails}
                            imageDetails={contactImageDetails}
                            errors={contactErrors}
                        />
                    )}

                    {!isActiveFormStepOwner && (
                        <>
                            <Row className="radio-buttons radio-company" gutter={formGutter}>
                                <Col span={24}>
                                    <Radio.Group
                                        onChange={(e): void => {
                                            form.resetFields(['membershipId']);
                                            setSelectedExistingMembershipId(undefined);
                                            setCompanyRadioState(e.target.value);
                                            resetExistingCompanyErrors();
                                            resetNewCompanyErrors();
                                        }}
                                        value={companyRadioState}
                                    >
                                        <Radio value={CompanyRadioButtonValues.Existing}>
                                            {t('select_existing_company')}
                                        </Radio>

                                        <Radio value={CompanyRadioButtonValues.New}>
                                            {t('select_not_existing_company')}
                                        </Radio>

                                        <Radio value={CompanyRadioButtonValues.None}>
                                            {t('select_no_company')}
                                        </Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>

                            {companyRadioState === CompanyRadioButtonValues.Existing && (
                                <>
                                    <Title level={4}>
                                        {t('Membership.choose_existing_company')}
                                    </Title>
                                    <Row gutter={formGutter}>
                                        <Col span={12}>
                                            <ValidatedFormItem
                                                name="membershipId"
                                                label={t('company')}
                                                errors={existingCompanyErrors}
                                                required
                                            >
                                                <SelectCustom
                                                    options={companiesOptions}
                                                    defaultImg={
                                                        <Icon
                                                            iconName="Company"
                                                            fill={theme['white']}
                                                        />
                                                    }
                                                    strongLabel={true}
                                                    placeholder={t(
                                                        'SelectCustom.placeholder_default'
                                                    )}
                                                    onKeywordsChange={handleCompaniesKeywordsChange}
                                                    onMenuScrollToBottom={
                                                        handleCompaniesMenuScrollToBottom
                                                    }
                                                    hideSelectedOptions={false}
                                                    onChange={(value): void => {
                                                        setSelectedExistingMembershipId(
                                                            value.value
                                                        );
                                                        const options = [
                                                            value as SelectCustomOption,
                                                        ];
                                                        setSelectedCompanyOptions(options);
                                                        setSelectedExistingMembershipName(
                                                            value.label
                                                        );
                                                    }}
                                                    selected={
                                                        selectedExistingMembershipId
                                                            ? [selectedExistingMembershipId]
                                                            : null
                                                    }
                                                    isLoading={selectLoading}
                                                    idAttribute={'membershipId'}
                                                />
                                            </ValidatedFormItem>
                                        </Col>

                                        <Col span={12}>
                                            <ValidatedFormItem
                                                name="membershipRoles"
                                                label={t('User.role_title')}
                                                initialValue={MembershipRoleDto.Administrator}
                                            >
                                                <Select>
                                                    {Object.keys(MembershipRoleDto)
                                                        .filter(
                                                            (x) =>
                                                                x !== MembershipRoleDto.Owner &&
                                                                x !==
                                                                    MembershipRoleDto.PendingMember
                                                        )
                                                        .map((x) => (
                                                            <Select.Option key={x} value={x}>
                                                                {t(`Membership.member_role_${x}`)}
                                                            </Select.Option>
                                                        ))}
                                                </Select>
                                            </ValidatedFormItem>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            {companyRadioState === CompanyRadioButtonValues.New && (
                                <CompanyFormSection
                                    radioButtonValue={companyRadioState}
                                    errors={newCompanyErrors}
                                    companyOwnerName={`${contactInfo.firstName} ${contactInfo.lastName}`}
                                    setImageDetails={setCompanyImageDetails}
                                />
                            )}
                        </>
                    )}

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={actionsButtons.prev.onClick}
                        >
                            {actionsButtons.prev.label}
                        </Button>

                        <Button
                            type="primary"
                            className="positive"
                            htmlType="button"
                            onClick={actionsButtons.next.onClick}
                        >
                            {actionsButtons.next.label}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
};

export default React.memo(CreateContact);
