import { Button, Checkbox, Col, DatePicker, Form, Row, Select } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { CampusProxy } from 'Api/Features/Campuses/CampusProxy';
import { SageEntityDto } from 'Api/Features/SageIntacct/Dtos/SageEntityDto';
import BaseModal from 'Components/base-modal/base-modal';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditSageIntacctIntegrationSchema } from 'Schemas/EditSageIntacctIntegrationSchema';
import { SageIntacctService } from 'Services/SageIntacctService';
import { dateMomentToString } from 'Utils/TimeUtils';
import './index.less';

const formGutter: [Gutter, Gutter] = [40, 0];
export interface EditSageIntacctIntegrationData {
    campusId: string;
    sageCompanyId: string;
    accountingSyncEnabled: boolean;
}

interface EditUnitsModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    data?: EditSageIntacctIntegrationData;
}

const EditSageIntacctIntegrationModal: FunctionComponent<EditUnitsModalProps> = ({
    visible,
    onComplete,
    data,
}) => {
    const sageIntacctService = useService(SageIntacctService);
    const campusService = useService(CampusProxy);
    const { globalLoadingStore, toastStore } = useStores();
    const [selectLoading, setSelectLoading] = useState(false);
    const [entitiesOptions, setEntitiesOptions] = useState<SelectCustomOption[]>([]);
    const [selectedEntityOptions, setSelectedEntityOptions] = useState<SelectCustomOption[]>([]);
    const [selectedEntityId, setSelectedEntityId] = useState<string>();
    const [entitiesSearchTerm, setEntitiesSearchTerm] = useState('');
    const [entitiesSearchResults, setEntitiesSearchResults] = useState<SageEntityDto[]>([]);
    const [entitiesMaxResults, setEntitiesMaxResults] = useState(false);
    const [entitiesCurrentPage, setEntitiesCurrentPage] = useState(0);
    const [accountingSyncEnabled, setAccountingSyncEnabled] = useState<boolean>(
        data?.accountingSyncEnabled || false
    );
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(
        EditSageIntacctIntegrationSchema,
        form
    );
    const { t } = useTranslation();
    const pageSize = 25;
    const resetEntitiesSearch = (): void => {
        setEntitiesCurrentPage(0);
        setEntitiesSearchResults([]);
        setEntitiesSearchTerm('');
        setEntitiesMaxResults(false);
    };

    const handleEntitiesKeywordsChange = useCallback((value: string): void => {
        resetEntitiesSearch();
        setEntitiesSearchTerm(value);
    }, []);

    const handleEntitiesMenuScrollToBottom = (): void => {
        if (!entitiesMaxResults) {
            setEntitiesCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const searchEntities = async (page: number, searchTerm: string): Promise<SageEntityDto[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
        };

        const results = await sageIntacctService.getSageEntities(args);

        if (results?.items.length + pageSize * entitiesCurrentPage >= results?.totalItemCount) {
            setEntitiesMaxResults(true);
        }
        setSelectLoading(false);
        return results?.items;
    };

    const debounceEntitiesSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchEntities(page, searchTerm).then((results) => {
                setEntitiesSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    useEffect(() => {
        setSelectLoading(true);
        debounceEntitiesSearch.current(entitiesCurrentPage, entitiesSearchTerm);
    }, [entitiesSearchTerm, entitiesCurrentPage, accountingSyncEnabled]);

    useEffect(() => {
        const searchResults = entitiesSearchResults?.map(
            (x: SageEntityDto) =>
                ({
                    value: x?.id,
                    label: x?.name,
                    badge: undefined,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedEntityOptions);
        setEntitiesOptions(merged);
    }, [entitiesSearchResults, selectedEntityOptions]);

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = () => {
        dismiss();
    };

    const submit = async (): Promise<void> => {
        globalLoadingStore.addLoading();
        const formValues = form.getFieldsValue();

        try {
            const request = {
                accountingSyncEnabled: accountingSyncEnabled,
                accountingSyncStartDate: accountingSyncEnabled
                    ? dateMomentToString(formValues.accountingSyncStartDate)
                    : null,
                sageLocationEntityId: accountingSyncEnabled
                    ? formValues.sageLocationEntityId?.value
                    : null,
            };

            if (!(await validateForm(request))) return;

            if (data?.campusId)
                await campusService.updateCampusSageAccountingSyncConfig(data?.campusId, request);

            toastStore.toast({
                type: 'success',
                messageKey: 'sage_intacct_sync_edited',
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <BaseModal
            className="EditSageIntacctIntegrationModal FormModal"
            visible={visible}
            title={t('sage_intacct_edit')}
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Row gutter={formGutter}>
                    <Col span={24} className="sync-checkbox">
                        <Checkbox
                            value={accountingSyncEnabled}
                            checked={accountingSyncEnabled}
                            onClick={(): void => {
                                setAccountingSyncEnabled(!accountingSyncEnabled);
                            }}
                        >
                            {t('sage_intacct_sync_enable')}
                        </Checkbox>
                    </Col>

                    {accountingSyncEnabled && (
                        <>
                            <Col span={12}>
                                <ValidatedFormItem name="sageCompanyId" label={t('sage_company')}>
                                    <Select disabled defaultValue={data?.sageCompanyId}></Select>
                                </ValidatedFormItem>
                            </Col>

                            <Col span={12}>
                                <ValidatedFormItem
                                    name="sageLocationEntityId"
                                    label={t('sage_entity')}
                                    errors={errors}
                                    required
                                >
                                    <SelectCustom
                                        options={entitiesOptions}
                                        strongLabel={true}
                                        placeholder={t('SelectCustom.placeholder_default')}
                                        onKeywordsChange={handleEntitiesKeywordsChange}
                                        onMenuScrollToBottom={handleEntitiesMenuScrollToBottom}
                                        hideSelectedOptions={false}
                                        onChange={(value): void => {
                                            setSelectedEntityId(value.value);
                                            const options = [value as SelectCustomOption];
                                            setSelectedEntityOptions(options);
                                        }}
                                        selected={selectedEntityId ? [selectedEntityId] : null}
                                        isLoading={selectLoading}
                                        idAttribute="sageLocationEntityId"
                                    />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={12}>
                                <ValidatedFormItem
                                    name="accountingSyncStartDate"
                                    label={t('start_date')}
                                    initialValue={moment()}
                                    errors={errors}
                                >
                                    <DatePicker style={{ width: '100%' }} />
                                </ValidatedFormItem>
                            </Col>
                        </>
                    )}
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={exit}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" className="positive" htmlType="submit">
                        {t('save')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(EditSageIntacctIntegrationModal);
