// GENERATED FILE - DO NOT MODIFY
import { AgreementTermsDocumentDto } from 'Api/Features/AgreementTerms/Dtos/AgreementTermsDocumentDto';
import { CreateAgreementTermsRequestDto } from 'Api/Features/AgreementTerms/Dtos/CreateAgreementTermsRequestDto';
import { GetAgreementTermsRequestDto } from 'Api/Features/AgreementTerms/Dtos/GetAgreementTermsRequestDto';
import { GetAgreementTermsResponseDto } from 'Api/Features/AgreementTerms/Dtos/GetAgreementTermsResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class AgreementTermsProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getAgreementTerms(request: GetAgreementTermsRequestDto | null): Promise<GetAgreementTermsResponseDto | null> {
        const uri = this.buildUri(
            "/agreement-terms",
            null,
            { ...request || undefined }
        );

        const data: GetAgreementTermsResponseDto | null = await this.httpClient.get<GetAgreementTermsResponseDto | null>(uri);
        return data;
    }

    public async getAgreementTermsDocument(agreementTermsId: string): Promise<AgreementTermsDocumentDto | null> {
        const uri = this.buildUri(
            "/agreement-terms/{agreementTermsId}/document",
            { agreementTermsId: agreementTermsId },
            null
        );

        const data: AgreementTermsDocumentDto | null = await this.httpClient.get<AgreementTermsDocumentDto | null>(uri);
        return data;
    }

    public async createAgreementTerms(request: CreateAgreementTermsRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/agreement-terms",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateAgreementTermsRequestDto | null, string>(uri, request);
        return data;
    }
}