import * as yup from 'yup';

export const CreateConferenceRoomSchema = yup.object({
    name: yup.string().required('Errors.required'),
    creditPointsPerBlock: yup.number(),
    numberOfSeats: yup.number(),
    minimumBookingDuration: yup.number().positive().required('Errors.required'),
    maximumBookingDuration: yup
        .number()
        .positive()
        .required('Errors.required')
        .min(
            yup.ref('minimumBookingDuration'),
            'Maximum booking duration (minutes) must be more or equal to minimum'
        ),
});
