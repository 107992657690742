import { AddOnAddOnCategoryDto } from 'Api/Features/AddOns/Dtos/AddOnAddOnCategoryDto';
import { AddOnDto } from 'Api/Features/AddOns/Dtos/AddOnDto';

export class AddOn implements AddOnDto {
    constructor(dto: AddOnDto) {
        Object.assign(this, dto);
    }

    id?: string | null;
    name?: string | null;
    description?: string | null;
    amount?: number;
    suggestedPrice?: number | null;
    currency?: string | null;
    category?: AddOnAddOnCategoryDto | null;
}
