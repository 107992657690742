import { LeadSourceDto } from 'Api/Features/Leads/Dtos/LeadSourceDto';
import { OpportunityAccountDto } from 'Api/Features/Opportunities/Dtos/OpportunityAccountDto';
import { OpportunityCampusDto } from 'Api/Features/Opportunities/Dtos/OpportunityCampusDto';
import { OpportunityDto } from 'Api/Features/Opportunities/Dtos/OpportunityDto';
import { OpportunityLostReasonDto } from 'Api/Features/Opportunities/Dtos/OpportunityLostReasonDto';
import { OpportunityMembershipDto } from 'Api/Features/Opportunities/Dtos/OpportunityMembershipDto';
import { OpportunityProductDto } from 'Api/Features/Opportunities/Dtos/OpportunityProductDto';
import { OpportunitySalesChannelDto } from 'Api/Features/Opportunities/Dtos/OpportunitySalesChannelDto';
import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';
import { OpportunitySubscriptionAncestryDto } from 'Api/Features/Opportunities/Dtos/OpportunitySubscriptionAncestryDto';
import { OpportunitySubscriptionDto } from 'Api/Features/Opportunities/Dtos/OpportunitySubscriptionDto';
import { OpportunityTermLengthDto } from 'Api/Features/Opportunities/Dtos/OpportunityTermLengthDto';
import { OpportunityUpcomingActivityDto } from 'Api/Features/Opportunities/Dtos/OpportunityUpcomingActivityDto';
import moment, { Moment } from 'moment';

export class Opportunity implements OpportunityDto {
    constructor(dto: OpportunityDto) {
        Object.assign(this, dto);
    }

    id?: string;
    description?: string | null;
    membership?: OpportunityMembershipDto | null;
    contact?: OpportunityAccountDto | null;
    name?: string | null;
    ownerAccount?: OpportunityAccountDto | null;
    probability?: number | null;
    closeDate?: string | null;
    amount?: number | null;
    status?: OpportunityStatusDto;
    campus?: OpportunityCampusDto | null;
    deskCount?: number | null;
    product?: OpportunityProductDto | null;
    salesChannel?: OpportunitySalesChannelDto | null;
    termLength?: OpportunityTermLengthDto | null;
    monthlyRevenue?: number | null;
    officeNumber?: string | null;
    lostReason?: OpportunityLostReasonDto | null;
    source?: LeadSourceDto;
    sourceOther?: string | null;
    leadId?: string | null;
    upcomingActivities?: (OpportunityUpcomingActivityDto | null)[] | null;
    isSubscriptionModification?: boolean;
    isSubscriptionRenewal?: boolean;
    parentSubscription?: OpportunitySubscriptionDto | null;
    subscriptionAncestry?: OpportunitySubscriptionAncestryDto | null;
    dateCreated?: string;
    lastStatusChangeDate?: string | null;
    get closeDateMoment(): Moment | undefined {
        return moment.parseZone(this.closeDate);
    }
}
