import React, { FunctionComponent, ReactNode } from 'react';
import { Skeleton, Typography } from 'antd';

import { RoutedBreadcrumb } from '../routed-breadcrumb';
import { BreadcrumbSegment } from '../routed-breadcrumb/routed-breadcrumb';
import './list-section-header.less';
import { ImageWithPlaceholderProps } from '../image-with-placeholder/image-with-placeholder';
import { ImageWithPlaceholder } from '../image-with-placeholder';

const { Title } = Typography;

interface ListSectionHeaderProps extends ImageWithPlaceholderProps {
    title: string;
    subTitle: ReactNode;
    backgroundImageUrl: string;
    action?: ReactNode;
    routes?: BreadcrumbSegment[];
    loading?: boolean;
}

const ListSectionHeader: FunctionComponent<ListSectionHeaderProps> = ({
    title,
    subTitle,
    backgroundImageUrl,
    action,
    routes,
    loading,
    ...imageProps
}) => {
    return (
        <div
            className="ListSectionHeader"
            style={{
                backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),url(${backgroundImageUrl})`,
            }}
        >
            <Skeleton loading={loading} active avatar>
                <ImageWithPlaceholder className="header-icon" {...imageProps} />
                <div className="header-info">
                    {routes && <RoutedBreadcrumb routes={routes} />}
                    {title ? <Title className="header-title">{title}</Title> : <Skeleton />}
                    <div className="header-subtitle">{subTitle}</div>
                </div>
                {action && <div className="header-action">{action}</div>}
            </Skeleton>
        </div>
    );
};

export default ListSectionHeader;
