import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { SelectValue } from 'antd/lib/select';
import Title from 'antd/lib/typography/Title';
import { AddOnCategoryDto } from 'Api/Features/AddOnCategories/Dtos/AddOnCategoryDto';
import { CreateInvoiceRequestDto } from 'Api/Features/Invoices/Dtos/CreateInvoiceRequestDto';
import { ProductTypeDto } from 'Api/Features/Invoices/Dtos/ProductTypeDto';
import { PaymentMethodHolderTypeDto } from 'Api/Features/PaymentMethods/Dtos/PaymentMethodHolderTypeDto';
import { PlanDto } from 'Api/Features/Plans/Dtos/PlanDto';
import BaseModal from 'Components/base-modal/base-modal';
import Icon from 'Components/icons/icon';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { MILLION } from 'Models/Constants';
import { LightCampusDetailsInfo } from 'Models/Location/LightCampusInfo';
import { Plan } from 'Models/Plans/Plan';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { CreateManualInvoiceSchema } from 'Schemas/CreateManualInvoiceSchema';
import { AddOnCategoryService } from 'Services/AddOnCategoryService';
import { InvoiceService } from 'Services/InvoiceService';
import { PlanService } from 'Services/PlanService';
import { orderObjectArrayAlphabeticallyByProperty } from 'Utils/ArrayUtils';
import { cleanVal } from 'Utils/NumberUtils';
import { dateMomentToString } from 'Utils/TimeUtils';
import './create-manual-invoice-modal.less';

const formGutter: [Gutter, Gutter] = [40, 0];

const { Option } = Select;

interface PlanOptions {
    addon: AddOnCategoryDto[];
    plan: PlanDto[];
}

interface CreateManualInvoiceModalProps {
    visible: boolean;
    paymentMethodHolderType: PaymentMethodHolderTypeDto;
    entityId: string;
    onComplete: (success: boolean) => void;
}

const CreateManualInvoiceModal: FunctionComponent<CreateManualInvoiceModalProps> = observer(({
    visible,
    paymentMethodHolderType,
    entityId,
    onComplete,
}) => {
    const invoiceService = useService(InvoiceService);
    const planService = useService(PlanService);
    const addOnCategoryService = useService(AddOnCategoryService);
    const { globalLoadingStore, toastStore, confirmationModalStore, userPermissionsStore } = useStores();
    const [selectedLocationId, setSelectedLocationId] = useState<string>();
    const [selectedProductType, setSelectedProductType] = useState<ProductTypeDto>();
    const [planOptions, setPlanOptions] = useState<PlanOptions>({ addon: [], plan: [] });
    const [selectedPlanOptions, setSelectedPlanOptions] =
        useState<(AddOnCategoryDto | PlanDto)[]>();
    const [invoiceDate, setInvoiceDate] = useState<moment.Moment | null>(moment());
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(CreateManualInvoiceSchema, form);
    const { t } = useTranslation();

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Location" />,
                title: t('Booking.book_a_room_confirm_title'),
                message: t('Booking.book_a_room_confirm_message'),
                positiveText: t('Contact.contacts_invoices_create_manual_confirm_positive'),
                negativeText: t('Booking.book_a_room_confirm_negative'),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const basicInformation = {
            campusId: formValues.campusId,
            invoiceDate: dateMomentToString(formValues.invoiceDate),
            dueDate: dateMomentToString(formValues.dueDate),
            postedDate: dateMomentToString(formValues.postedDate),
            description: formValues.description,
            paymentMethodHolderType: paymentMethodHolderType,
            accountId:
                paymentMethodHolderType === PaymentMethodHolderTypeDto.Personal
                    ? entityId
                    : undefined,
            membershipId:
                paymentMethodHolderType === PaymentMethodHolderTypeDto.Membership
                    ? entityId
                    : undefined,
        };
        const invoiceItem = {
            amount: formValues.amount,
            productType: formValues.productType,
            productId: formValues.productId,
            description: formValues.description,
        };

        if (!(await validateForm({ ...basicInformation, ...invoiceItem }))) return;

        try {
            globalLoadingStore.addLoading();

            const request: CreateInvoiceRequestDto = { ...basicInformation, items: [invoiceItem] };

            await invoiceService.createInvoice(request);

            dismiss(true);

            toastStore.toast({
                type: 'success',
                messageKey: t(`Contact.contacts_invoices_create_manual_invoice_confirmed`),
            });
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const handleLocationChange = (val: SelectValue): void => {
        setSelectedLocationId(val.toString());
    };

    const handleProductTypeChange = (val: ProductTypeDto): void => {
        if (val === ProductTypeDto.Plan) {
            setSelectedPlanOptions(planOptions?.plan);
        }
        if (val === ProductTypeDto.AddOn) {
            setSelectedPlanOptions(planOptions?.addon);
        }
        setSelectedProductType(val);
        form.setFieldsValue({ productId: undefined });
    };

    const fetchAddonOptions = useCallback(async () => {
        globalLoadingStore.addLoading();

        try {
            const addOnResponse = await addOnCategoryService.getAddOnCategories(null);
            const planResponse = await planService.getPlans({
                campusIds: selectedLocationId ? [selectedLocationId] : null,
            });

            const nonNullAddOnResponse = addOnResponse?.items
                ? orderObjectArrayAlphabeticallyByProperty(
                      addOnResponse.items.filter((item): item is AddOnCategoryDto => item !== null),
                      'name'
                  )
                : [];
            const nonNullPlanResponse = planResponse[0]
                ? orderObjectArrayAlphabeticallyByProperty(
                      planResponse[0].filter((item): item is Plan => item !== null),
                      'name'
                  )
                : [];

            setPlanOptions({ addon: nonNullAddOnResponse, plan: nonNullPlanResponse });
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, addOnCategoryService, selectedLocationId]);

    useEffect(() => {
        if(selectedLocationId)
            fetchAddonOptions();
    }, [fetchAddonOptions, selectedLocationId]);

    return (
        <BaseModal
            visible={visible}
            title={t('Contact.contacts_invoices_create_manual_invoice')}
            className="CreateManualInvoiceModal FormModal"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Title level={4}>{t('basic_information')}</Title>

                <Row gutter={formGutter}>
                    <Col span={12}>
                        <ValidatedFormItem
                            name="campusId"
                            label={t('location')}
                            errors={errors}
                            required
                        >
                            <Select onChange={handleLocationChange}>
                                {userPermissionsStore.availableLocationsForUser.map(
                                    (x: LightCampusDetailsInfo) => (
                                        <Option key={x.id!} value={x.id!}>
                                            {x.name}
                                        </Option>
                                    )
                                )}
                            </Select>
                        </ValidatedFormItem>
                    </Col>
                    <Col span={12}>
                        <ValidatedFormItem
                            name="invoiceDate"
                            label={t('User.contact_profile_invoice_date')}
                            initialValue={moment()}
                            errors={errors}
                            required
                        >
                            <DatePicker
                                onChange={(value) => setInvoiceDate(value)}
                                style={{ width: '100%' }}
                            />
                        </ValidatedFormItem>
                    </Col>
                    <Col span={12}>
                        <ValidatedFormItem
                            name="dueDate"
                            label={t('User.contact_profile_invoice_due_date')}
                            initialValue={moment()}
                            errors={errors}
                            required
                        >
                            <DatePicker
                                disabledDate={(current: moment.Moment): boolean =>
                                    (invoiceDate && current < invoiceDate) || false
                                }
                                style={{ width: '100%' }}
                            />
                        </ValidatedFormItem>
                    </Col>
                    <Col span={12}>
                        <ValidatedFormItem
                            name="postedDate"
                            label={t('User.contact_profile_invoice_posted_date')}
                            initialValue={moment()}
                            errors={errors}
                            required
                        >
                            <DatePicker
                                disabledDate={(current: moment.Moment): boolean =>
                                    (invoiceDate && current < invoiceDate) || false
                                }
                                style={{ width: '100%' }}
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Title level={4}>{t('User.contact_profile_invoice_item')}</Title>

                <Row gutter={formGutter}>
                    <Col span={12}>
                        <ValidatedFormItem
                            name="description"
                            label={t('description')}
                            errors={errors}
                            required
                        >
                            <Input />
                        </ValidatedFormItem>
                    </Col>
                    <Col span={12}>
                        <ValidatedFormItem
                            name="amount"
                            label={t('amount')}
                            errors={errors}
                            required
                        >
                            <NumberFormat
                                allowNegative={false}
                                decimalScale={2}
                                fixedDecimalScale
                                customInput={(props: any) => <Input addonBefore={'$'} {...props} />}
                                isAllowed={(value: NumberFormatValues): boolean =>
                                    value.floatValue === undefined ||
                                    (value.floatValue !== undefined && value.floatValue <= MILLION)
                                }
                            />
                        </ValidatedFormItem>
                    </Col>
                    <Col span={12}>
                        <ValidatedFormItem
                            name="productType"
                            label={t('User.contact_profile_invoice_product_type')}
                            errors={errors}
                            required
                        >
                            <Select
                                onChange={handleProductTypeChange}
                                disabled={!selectedLocationId}
                            >
                                {Object.keys(ProductTypeDto)
                                    .sort()
                                    .map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`ProductTypeDto_${x}`)}
                                        </Option>
                                    ))}
                            </Select>
                        </ValidatedFormItem>
                    </Col>
                    <Col span={12}>
                        {(selectedProductType === ProductTypeDto.AddOn ||
                            selectedProductType === ProductTypeDto.Plan) && (
                            <ValidatedFormItem
                                name="productId"
                                label={
                                    selectedProductType === ProductTypeDto.AddOn
                                        ? t('Add-on category')
                                        : t('plan')
                                }
                                errors={errors}
                                required
                            >
                                <Select>
                                    {selectedPlanOptions &&
                                        selectedPlanOptions.map((x) => {
                                            const id = cleanVal.string(x?.id);

                                            return (
                                                <Option key={id} value={id}>
                                                    {x?.name}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </ValidatedFormItem>
                        )}
                    </Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" className="positive" htmlType="submit">
                        {t('send')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
});

export default React.memo(CreateManualInvoiceModal);
