// GENERATED FILE - DO NOT MODIFY

export enum ProductTypeDto {
    CreditPack = "CreditPack",
    Credits = "Credits",
    AddOn = "AddOn",
    Plan = "Plan",
    DayPass = "DayPass",
    Other = "Other",
    Adjustment = "Adjustment",
    SecurityDeposit = "SecurityDeposit",
    Discount = "Discount",
    Catering = "Catering",
    Fitness = "Fitness",
    Parking = "Parking",
    ITUpgrades = "ITUpgrades",
    MemberRequestedVinyl = "MemberRequestedVinyl",
    FinesReimbursed = "FinesReimbursed",
    LateFees = "LateFees",
    ConferenceRoomEvents = "ConferenceRoomEvents",
    SpaceRentalEvents = "SpaceRentalEvents",
    Printing = "Printing",
    Fob = "Fob",
}