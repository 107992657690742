import * as yup from 'yup';

export const CreateMessageSchema = yup.object(
    Object.assign({
        title: yup
            .string()
            .required('Errors.required')
            .max(100, 'Errors.must_be_under_one_hundred'),
        body: yup.string().required('Errors.required').max(5000),
    })
);
