// GENERATED FILE - DO NOT MODIFY

export enum IndustryDto {
    Agriculture = "Agriculture",
    Apparel = "Apparel",
    Banking = "Banking",
    Biotechnology = "Biotechnology",
    Chemicals = "Chemicals",
    Communications = "Communications",
    Construction = "Construction",
    Consulting = "Consulting",
    Education = "Education",
    Electronics = "Electronics",
    Energy = "Energy",
    Engineering = "Engineering",
    Entertainment = "Entertainment",
    Environmental = "Environmental",
    Finance = "Finance",
    FoodAndBeverage = "FoodAndBeverage",
    Government = "Government",
    Healthcare = "Healthcare",
    Hospitality = "Hospitality",
    Insurance = "Insurance",
    Machinery = "Machinery",
    Manufacturing = "Manufacturing",
    Marketing = "Marketing",
    Media = "Media",
    NotForProfit = "NotForProfit",
    OilAndGas = "OilAndGas",
    Other = "Other",
    RealEstate = "RealEstate",
    Recreation = "Recreation",
    Retail = "Retail",
    Shipping = "Shipping",
    Technology = "Technology",
    Telecommunications = "Telecommunications",
    Transportation = "Transportation",
    Utilities = "Utilities",
}