import { CampusSurroundingTypeProxy } from 'Api/Features/CampusSurroundings/CampusSurroundingTypeProxy';
import { CampusSurroundingTypeDto } from 'Api/Features/CampusSurroundings/Dtos/CampusSurroundingTypeDto';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(CampusSurroundingTypeProxy)
export class CampusSurroundingTypeService extends ApiService {
    constructor(private readonly campusSurroundingTypeProxy: CampusSurroundingTypeProxy) {
        super();
    }
    public async getCampusSurroundingType(
        campusSurroundingTypeId: string
    ): Promise<CampusSurroundingTypeDto | null> {
        const data: CampusSurroundingTypeDto | null = await this.campusSurroundingTypeProxy.getCampusSurroundingType(
            campusSurroundingTypeId
        );
        return data;
    }

    public async getCampusSurroundingTypes(): Promise<(CampusSurroundingTypeDto | null)[] | null> {
        const data:
            | (CampusSurroundingTypeDto | null)[]
            | null = await this.campusSurroundingTypeProxy.getCampusSurroundingTypes();
        return data;
    }
}
