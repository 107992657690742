import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Bell: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    d="M13.3185 9.758V5.19611C13.3185 3.80529 12.751 2.50348 11.7163 1.52434C10.6815 0.534075 9.31293 0 7.86648 0C4.86231 0 2.41446 2.32545 2.41446 5.19611V9.78025L0 13.7302H5.24061C5.27399 14.0529 5.363 14.3533 5.51878 14.6203C5.96384 15.4771 6.86509 16 7.86648 16C8.86787 16 9.76912 15.4659 10.2142 14.6203C10.37 14.3533 10.459 14.0529 10.4924 13.7302H15.7441L13.3185 9.758ZM3.08206 9.95827V5.19611C3.08206 2.69263 5.22949 0.667594 7.86648 0.667594C9.14604 0.667594 10.3477 1.14604 11.2601 2.00278C12.1613 2.85953 12.662 3.99444 12.662 5.19611V9.95827L14.5535 13.0737H1.19054L3.08206 9.95827ZM9.62448 14.2976C9.29068 14.943 8.62309 15.3324 7.86648 15.3324C7.121 15.3324 6.44228 14.943 6.10848 14.2976C6.00835 14.1307 5.94159 13.9305 5.91933 13.7302H9.82476C9.79138 13.9305 9.72462 14.1196 9.62448 14.2976Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Bell;
