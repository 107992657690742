import { ManagementRoleDto } from 'Api/Features/ManagerUsers/Dtos/ManagementRoleDto';
import { PHONE_REGEX } from 'Models/Constants';
import * as yup from 'yup';
import { StringSchema } from 'yup';

// See: https://github.com/jquense/yup/issues/58
yup.addMethod(yup.mixed, 'optional', function optional() {
    return this.transform((value) => {
        return (typeof value == 'string' && !value) ||
            (value instanceof Array && !value.length) ||
            value === null // allow to skip "nullable"
            ? undefined
            : value;
    });
});

const RoleSchema = yup.object({
    name: yup
        .string()
        .oneOf(Object.values(ManagementRoleDto), 'Errors.must_select_role')
        .defined(),
    context: yup
        .object()
        .when('name', {
            is: ManagementRoleDto.Manager,
            then: yup.object({
                CampusId: yup.string().required('Errors.must_select_location'),
            }),
            otherwise: yup.object(),
        })
        .defined(),
});

const BaseShape = {
    firstName: yup.string().required('Errors.required'),
    lastName: yup.string().required('Errors.required'),
    contactInfo: yup.object({
        email: yup.string().email('Errors.email_invalid').required('Errors.required'),
    }),
    roles: yup.array().of(RoleSchema).required('Errors.required'),
};

export const CreateManagerUserSchema = yup.object(
    Object.assign({}, BaseShape, {
        existingMemberId: yup.string().nullable(),
        password: yup
            .string()
            .required('Errors.required')
            .min(6, 'Errors.password_restrictions')
            .max(50, 'Errors.password_restrictions'),
        confirmPassword: yup
            .string()
            .required('Errors.required')
            .when('password', (password: string, schema: StringSchema) =>
                schema.equals([password], 'Errors.passwords_must_match')
            )
            .min(6, 'Errors.password_restrictions')
            .max(50, 'Errors.password_restrictions'),
    })
);

export const EditManagerUserSchema = yup.object().shape(
    {
        firstName: yup.string().required('Errors.required'),
        lastName: yup.string().required('Errors.required'),
        contactInfo: yup.object({
            email: yup.string().email('Errors.email_invalid').required('Errors.required'),
        }),
        password: yup
            .string()
            .optional()
            .nullable()
            .when('confirmPassword', {
                is: (confirmPassword) => confirmPassword?.length > 0,
                then: yup.string().required('Errors.required'),
            })
            .min(6, 'Errors.password_restrictions')
            .max(50, 'Errors.password_restrictions'),
        confirmPassword: yup
            .string()
            .optional()
            .nullable()
            .when('password', {
                is: (password) => password?.length > 0,
                then: yup.string().required('Errors.required'),
            })
            .when(
                'password',
                (password: string, schema: StringSchema) =>
                    password && schema.equals([password], 'Errors.passwords_must_match')
            )
            .min(6, 'Errors.password_restrictions')
            .max(50, 'Errors.password_restrictions'),
    },
    [['confirmPassword', 'password']]
); // this resolves cyclic dependencies

export const EditProfileSchema = yup.object().shape(
    {
        firstName: yup.string().required('Errors.required'),
        lastName: yup.string().required('Errors.required'),
        contactInfo: yup.object({
            email: yup.string().email('Errors.email_invalid').required('Errors.required'),
            phone1: yup
                .string()
                .nullable()
                .max(25, 'Errors.must_be_under_twenty_five')
                .optional()
                .matches(PHONE_REGEX, 'Errors.phone_invalid'),
            phone2: yup
                .string()
                .nullable()
                .max(25, 'Errors.must_be_under_twenty_five')
                .optional()
                .matches(PHONE_REGEX, 'Errors.phone_invalid'),
        }),
        currentPassword: yup
            .string()
            .optional()
            .nullable()
            .when('password', {
                is: (password) => password?.length > 0,
                then: yup.string().required('Errors.required'),
            })
            .when('confirmPassword', {
                is: (confirmPassword) => confirmPassword?.length > 0,
                then: yup.string().required('Errors.required'),
            })
            .min(6, 'Errors.password_restrictions')
            .max(50, 'Errors.password_restrictions'),
        password: yup
            .string()
            .optional()
            .nullable()
            .when('currentPassword', {
                is: (currentPassword) => currentPassword?.length > 0,
                then: yup.string().required('Errors.required'),
            })
            .when('confirmPassword', {
                is: (confirmPassword) => confirmPassword?.length > 0,
                then: yup.string().required('Errors.required'),
            })
            .min(6, 'Errors.password_restrictions')
            .max(50, 'Errors.password_restrictions'),
        confirmPassword: yup
            .string()
            .optional()
            .nullable()
            .when('password', {
                is: (password) => password?.length > 0,
                then: yup.string().required('Errors.required'),
            })
            .when(
                'password',
                (password: string, schema: StringSchema) =>
                    password && schema.equals([password], 'Errors.passwords_must_match')
            )
            .min(6, 'Errors.password_restrictions')
            .max(50, 'Errors.password_restrictions'),
    },
    [
        ['confirmPassword', 'password'],
        ['password', 'currentPassword'],
    ]
); // this resolves cyclic dependencies
