import { CreateOpportunityCommentRequestDto } from 'Api/Features/Opportunities/Dtos/CreateOpportunityCommentRequestDto';
import { OpportunityProxy } from 'Api/Features/Opportunities/OpportunityProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(OpportunityProxy)
export class OpportunityCommentService extends ApiService {
    constructor(private readonly opportunityProxy: OpportunityProxy) {
        super();
    }

    public async createComment(
        request: CreateOpportunityCommentRequestDto,
        opportunityId: string
    ): Promise<void> {
        return await this.opportunityProxy.createOpportunityComment(opportunityId, request);
    }
}
