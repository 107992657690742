import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const LayoutGrid: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.875 1.45144C0.875 1.19807 1.08091 0.992676 1.33491 0.992676H4.98495C5.23895 0.992676 5.44486 1.19807 5.44486 1.45144V5.09239C5.44486 5.34575 5.23895 5.55115 4.98495 5.55115H1.33491C1.08091 5.55115 0.875 5.34575 0.875 5.09239V1.45144ZM1.79481 1.9102V4.63363H4.52504V1.9102H1.79481Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.875 7.27696C0.875 7.02359 1.08091 6.8182 1.33491 6.8182H4.98495C5.23895 6.8182 5.44486 7.02359 5.44486 7.27696V10.9179C5.44486 11.1713 5.23895 11.3767 4.98495 11.3767H1.33491C1.08091 11.3767 0.875 11.1713 0.875 10.9179V7.27696ZM1.79481 7.73572V10.4592H4.52504V7.73572H1.79481Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.875 13.1025C0.875 12.8491 1.08091 12.6437 1.33491 12.6437H4.98495C5.23895 12.6437 5.44486 12.8491 5.44486 13.1025V16.7434C5.44486 16.9968 5.23895 17.2022 4.98495 17.2022H1.33491C1.08091 17.2022 0.875 16.9968 0.875 16.7434V13.1025ZM1.79481 13.5612V16.2847H4.52504V13.5612H1.79481Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.71507 1.45144C6.71507 1.19807 6.92098 0.992676 7.17498 0.992676H10.825C11.079 0.992676 11.2849 1.19807 11.2849 1.45144V5.09239C11.2849 5.34575 11.079 5.55115 10.825 5.55115H7.17498C6.92098 5.55115 6.71507 5.34575 6.71507 5.09239V1.45144ZM7.63488 1.9102V4.63363H10.3651V1.9102H7.63488Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.71507 7.27696C6.71507 7.02359 6.92098 6.8182 7.17498 6.8182H10.825C11.079 6.8182 11.2849 7.02359 11.2849 7.27696V10.9179C11.2849 11.1713 11.079 11.3767 10.825 11.3767H7.17498C6.92098 11.3767 6.71507 11.1713 6.71507 10.9179V7.27696ZM7.63488 7.73572V10.4592H10.3651V7.73572H7.63488Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.71507 13.1025C6.71507 12.8491 6.92098 12.6437 7.17498 12.6437H10.825C11.079 12.6437 11.2849 12.8491 11.2849 13.1025V16.7434C11.2849 16.9968 11.079 17.2022 10.825 17.2022H7.17498C6.92098 17.2022 6.71507 16.9968 6.71507 16.7434V13.1025ZM7.63488 13.5612V16.2847H10.3651V13.5612H7.63488Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.5551 1.45144C12.5551 1.19807 12.761 0.992676 13.015 0.992676H16.6651C16.9191 0.992676 17.125 1.19807 17.125 1.45144V5.09239C17.125 5.34575 16.9191 5.55115 16.6651 5.55115H13.015C12.761 5.55115 12.5551 5.34575 12.5551 5.09239V1.45144ZM13.475 1.9102V4.63363H16.2052V1.9102H13.475Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.5551 7.27696C12.5551 7.02359 12.761 6.8182 13.015 6.8182H16.6651C16.9191 6.8182 17.125 7.02359 17.125 7.27696V10.9179C17.125 11.1713 16.9191 11.3767 16.6651 11.3767H13.015C12.761 11.3767 12.5551 11.1713 12.5551 10.9179V7.27696ZM13.475 7.73572V10.4592H16.2052V7.73572H13.475Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.5551 13.1025C12.5551 12.8491 12.761 12.6437 13.015 12.6437H16.6651C16.9191 12.6437 17.125 12.8491 17.125 13.1025V16.7434C17.125 16.9968 16.9191 17.2022 16.6651 17.2022H13.015C12.761 17.2022 12.5551 16.9968 12.5551 16.7434V13.1025ZM13.475 13.5612V16.2847H16.2052V13.5612H13.475Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default LayoutGrid;
