import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { CreditProxy } from '../Api/Features/Credits/CreditProxy';
import { GetAccountAvailableCreditBalancesRequestDto } from 'Api/Features/Credits/Dtos/GetAccountAvailableCreditBalancesRequestDto';
import { CreateAccountCreditTransactionRequestDto } from 'Api/Features/Credits/Dtos/CreateAccountCreditTransactionRequestDto';
import { CreateMembershipCreditTransactionRequestDto } from 'Api/Features/Credits/Dtos/CreateMembershipCreditTransactionRequestDto';
import { CreditBalanceDetailsDto } from 'Api/Features/Credits/Dtos/CreditBalanceDetailsDto';

interface GetCreditBalancesRequest extends GetAccountAvailableCreditBalancesRequestDto {
    id: string;
}

@inject(CreditProxy)
export class CreditsService extends ApiService {
    constructor(private readonly creditsProxy: CreditProxy) {
        super();
    }

    public async getCreditBalances({
        id,
        campusId,
    }: GetCreditBalancesRequest): Promise<Array<CreditBalanceDetailsDto>> {
        const response: Array<
        CreditBalanceDetailsDto | null
        > | null = await this.creditsProxy.getAccountAvailableCreditBalances(id, {
            campusId: campusId,
        });

        if (response) {
            return response.filter((x): x is CreditBalanceDetailsDto => x !== null);
        }

        return  [];
    }

    public async createAccountCreditTransaction(accountId: string | null, request: CreateAccountCreditTransactionRequestDto | null): Promise<void> {
        await this.creditsProxy.createAccountCreditTransaction(accountId, request);
    }

    public async createMembershipCreditTransaction(membershipId: string | null, request: CreateMembershipCreditTransactionRequestDto | null): Promise<void> {
        await this.creditsProxy.createMembershipCreditTransaction(membershipId, request);
    }
}
