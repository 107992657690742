import { Button, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import { GetUnitInventoryReportRequestDto } from 'Api/Features/Reports/Dtos/UnitInventory/GetUnitInventoryReportRequestDto';
import { UnitInventoryReportDto } from 'Api/Features/Reports/Dtos/UnitInventory/UnitInventoryReportDto';
import { UnitInventoryReportUnitTypeDto } from 'Api/Features/Reports/Dtos/UnitInventory/UnitInventoryReportUnitTypeDto';
import Icon from 'Components/icons/icon';
import { ListSectionHeader } from 'Components/list-section-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import TableActionsButtons from 'Components/table-action-buttons/table-actions-buttons';
import { TableFilters } from 'Components/table-filters';
import { TdWithImage } from 'Components/td-with-image';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { ALL_LOCATIONS, MOMENT_PARSING_FORMAT } from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnitInventoryReportService } from 'Services/UnitInventoryReportService';
import { FilterStore } from 'Stores';
import { showFile } from 'Utils';
import { numberFormatter, ratioFormatter } from 'Utils/NumberUtils';
import { images, theme } from 'variant';
import './index.less';

const { usersHeader } = images;

const breadcrumbs: BreadcrumbSegment[] = [
    {
        nameKey: 'reports',
        path: 'reports',
    },
    {
        nameKey: 'Reports.unit_inventory',
        path: 'inventory',
    },
];

interface ExtractCampusesList {
    key: number;
    locationId: string;
    locationName: string;
    locationImgUrl: string;
    openDesksTotal: string;
    officeDesksTotal: string;
    desksTotal: string;
    occupiedOpenDesksTotal: string;
    occupiedOfficeDesksTotal: string;
    occupiedDesksTotal: string;
    occupiedPercent: string;
}

type ExctractUnitsList = (UnitInventoryReportUnitTypeDto[] | undefined)[];

const advancedFilters: AdvancedFilter[] = [];

const UnitInventory: FunctionComponent = observer(() => {
    const unitInventoryReportService = useService(UnitInventoryReportService);
    const { locationStore, userPermissionsStore, requestStore } = useStores();
    const { globalLoadingStore, toastStore } = useStores();
    const [unitInventoryList, setUnitInventoryList] = useState<UnitInventoryReportDto | undefined>(
        undefined
    );
    const [locationsList, setLocationsList] = useState<ExtractCampusesList[] | undefined>(
        undefined
    );
    const [unitsList, setUnitsList] = useState<ExctractUnitsList>([]);
    const [unitInventoryRequest, setUnitInventoryRequest] = useState<
        GetUnitInventoryReportRequestDto | undefined
    >(undefined);
    const refRowKeysToExpand = useRef<string[]>([]);
    const [rowKeysToExpand, setRowKeysToExpand] = useState<string[] | undefined>(undefined);
    const [isTableEmpty, setIsTableEmpty] = useState<boolean>(true);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { t } = useTranslation();

    const onExportClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            let document;
            if (unitInventoryRequest) {
                const campusName =
                    locationStore?.locations.find(
                        (x) => x.id === unitInventoryRequest?.campusIds?.[0]
                    )?.name || ALL_LOCATIONS;

                document = await unitInventoryReportService.exportUnitInventoryReport(
                    unitInventoryRequest
                );

                showFile(
                    document,
                    `${t('Reports.unit_inventory')} - ${moment(unitInventoryRequest.date).format(
                        MOMENT_PARSING_FORMAT
                    )} - ${campusName}`
                );
            }
        } catch (e) {
            if (!e.treated) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const tableActionButtons = [
        {
            iconName: 'UnExpandAll',
            toolTip: 'unexpand_all',
            onClick: (): void => setRowKeysToExpand([]),
        },
        {
            iconName: 'ExpandAll',
            toolTip: 'expand_all',
            onClick: (): void => setRowKeysToExpand(refRowKeysToExpand.current),
        },
        {
            iconName: 'Download',
            toolTip: 'export_table',
            onClick: (): Promise<void> => onExportClick(),
            disabled: isTableEmpty,
        },
    ];

    const renderLocation = (campusInfos: ExtractCampusesList) => (
        <TdWithImage defaultImg={<Icon iconName="Location" />} imgSrc={campusInfos.locationImgUrl}>
            {campusInfos.locationName}
        </TdWithImage>
    );

    const locationColumns: ColumnType<ExtractCampusesList>[] = [
        {
            title: t('location'),
            render: (campusInfos) => ({
                children: renderLocation(campusInfos),
                props: { colSpan: 2 },
            }),
        },
        {
            title: t('Unit.unit_type'),
            render: () => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('Reports.open_desks'),
            dataIndex: 'openDesksTotal',
            render: numberFormatter,
        },
        {
            title: t('Reports.office_desks'),
            dataIndex: 'officeDesksTotal',
            render: numberFormatter,
        },
        {
            title: t('total'),
            dataIndex: 'desksTotal',
            render: numberFormatter,
        },
        {
            title: t('Reports.open_desks'),
            dataIndex: 'occupiedOpenDesksTotal',
            render: numberFormatter,
        },
        {
            title: t('Reports.office_desks'),
            dataIndex: 'occupiedOfficeDesksTotal',
            render: numberFormatter,
        },
        {
            title: t('total'),
            dataIndex: 'occupiedDesksTotal',
            render: numberFormatter,
        },
        {
            title: t('Reports.occupied_percent'),
            dataIndex: 'occupiedPercent',
            render: ratioFormatter,
        },
    ];

    const nullOrVall = (val: number): string =>
        val === null || val === undefined ? '-' : numberFormatter(val);

    const expandedRowRender = (record: ExtractCampusesList) => {
        const columns: ColumnType<UnitInventoryReportUnitTypeDto>[] = [
            {
                title: t('empty'),
                render: (): string => '',
                key: 'empty',
            },
            {
                title: t('Unit.unit_type'),
                dataIndex: 'type',
                render: (type: string): string => t(`Unit.type_${type}`),
            },
            {
                title: t('Reports.open_desks'),
                dataIndex: 'openDesksTotal',
                render: nullOrVall,
            },
            {
                title: t('Reports.office_desks'),
                dataIndex: 'officeDesksTotal',
                render: nullOrVall,
            },
            {
                title: t('total'),
                dataIndex: 'desksTotal',
                render: numberFormatter,
            },
            {
                title: t('Reports.open_desks'),
                render: nullOrVall,
                dataIndex: 'occupiedOpenDesksTotal',
            },
            {
                title: t('Reports.office_desks'),
                dataIndex: 'occupiedOfficeDesksTotal',
                render: nullOrVall,
            },
            {
                title: t('total'),
                dataIndex: 'occupiedDesksTotal',
                render: numberFormatter,
            },
            {
                title: t('Reports.occupied_percent'),
                dataIndex: 'occupiedPercent',
                render: ratioFormatter,
            },
        ];

        return (
            <Table
                showHeader={false}
                columns={columns}
                dataSource={unitsList[record.key]}
                rowKey={(record): string => `${record.type}`}
                pagination={false}
            />
        );
    };

    const renderTableHeader = () => (
        <>
            <div></div>
            <div>{t('Unit.capacity')}</div>
            <div>{t('Unit.occupied')}</div>
        </>
    );

    const renderTableFooter = () => (
        <>
            <div>{t('total')}</div>
            <div>{numberFormatter(unitInventoryList?.openDesksTotal)}</div>
            <div>{numberFormatter(unitInventoryList?.officeDesksTotal)}</div>
            <div>{numberFormatter(unitInventoryList?.desksTotal)}</div>
            <div>{numberFormatter(unitInventoryList?.occupiedOpenDesksTotal)}</div>
            <div>{numberFormatter(unitInventoryList?.occupiedOfficeDesksTotal)}</div>
            <div>{numberFormatter(unitInventoryList?.occupiedDesksTotal)}</div>
            <div>{ratioFormatter(unitInventoryList?.occupiedPercent)}</div>
        </>
    );

    const fetchUnitInventory = useCallback(
        async (params: { date?: string; campusIds: string[] | null }) => {
            globalLoadingStore.addLoading();

            try {
                const unitInventory = await unitInventoryReportService.getUnitInventoryReport({
                    date: params.date,
                    campusIds: params.campusIds,
                });

                const nonNullcampuses = unitInventory?.campuses
                    ?.filter((campus) => campus !== null)
                    .map((campus) => campus!);

                const nonNullUnitInventory = {
                    campuses: nonNullcampuses,
                    desksTotal: unitInventory?.desksTotal,
                    occupiedDesksTotal: unitInventory?.occupiedDesksTotal,
                    occupiedOfficeDesksTotal: unitInventory?.occupiedOfficeDesksTotal,
                    occupiedOpenDesksTotal: unitInventory?.occupiedOpenDesksTotal,
                    occupiedPercent: unitInventory?.occupiedPercent,
                    officeDesksTotal: unitInventory?.officeDesksTotal,
                    openDesksTotal: unitInventory?.openDesksTotal,
                };

                setUnitInventoryList(nonNullUnitInventory);

                refRowKeysToExpand.current = [];

                const exctractUnitsList: ExctractUnitsList = [];
                const extractCampusesList: ExtractCampusesList[] | undefined =
                    unitInventory?.campuses !== null && unitInventory?.campuses !== undefined
                        ? unitInventory?.campuses.map((campus, i) => {
                              exctractUnitsList[i] = campus?.unitTypes
                                  ?.filter((unit) => unit !== null && unit !== undefined)
                                  .map((unit) => {
                                      return unit!;
                                  });

                              refRowKeysToExpand.current.push(`${campus?.id}`);

                              return {
                                  key: i,
                                  locationId: `${campus?.id}`,
                                  locationName: `${campus?.name}`,
                                  locationImgUrl: `${campus?.mainImageUrl}`,
                                  openDesksTotal: `${campus?.openDesksTotal}`,
                                  officeDesksTotal: `${campus?.officeDesksTotal}`,
                                  desksTotal: `${campus?.desksTotal}`,
                                  occupiedOpenDesksTotal: `${campus?.occupiedOpenDesksTotal}`,
                                  occupiedOfficeDesksTotal: `${campus?.occupiedOfficeDesksTotal}`,
                                  occupiedDesksTotal: `${campus?.occupiedDesksTotal}`,
                                  occupiedPercent: `${campus?.occupiedPercent}`,
                              };
                          })
                        : undefined;

                setIsTableEmpty(exctractUnitsList.length === 0);
                setLocationsList(extractCampusesList);
                setUnitsList(exctractUnitsList);
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [unitInventoryReportService, globalLoadingStore]
    );

    useEffect(() => {
        const filterStore = filterStoreRef.current;

        filterStore.date = moment().format(MOMENT_PARSING_FORMAT);
    }, []);

    useEffect(() => {
        const filterStore = filterStoreRef.current;

        const disposer = autorun(() => {
            const date = moment(filterStore.date).format(MOMENT_PARSING_FORMAT);
            const campusId =
                filterStore.currentLocationId === 'all'
                    ? requestStore.getReportRequestAvailableLocationIdsForUser()
                    : [filterStore.currentLocationId];

            fetchUnitInventory({
                date: date,
                campusIds: campusId,
            });

            setUnitInventoryRequest({
                date: date,
                campusIds: campusId,
            });
        });

        return (): void => {
            disposer();
        };
    }, [fetchUnitInventory]);

    return (
        <div className="UnitInventory">
            <ListSectionHeader
                routes={breadcrumbs}
                backgroundImageUrl={usersHeader}
                defaultImg={<Icon iconName="Units" fill={theme['primary-color']} />}
                title={t('Reports.unit_inventory')}
                subTitle={t('Reports.unit_inventory_overview')}
            />

            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeDate
                    includeLocations
                    availableLocations={userPermissionsStore.availableLocationsForUser}
                />

                <TableActionsButtons buttonsList={tableActionButtons} />

                <Table
                    className="expand-table table-striped-rows table-action-rows"
                    bordered
                    columns={locationColumns}
                    dataSource={locationsList || undefined}
                    expandable={{
                        expandedRowRender: expandedRowRender,
                        // eslint-disable-next-line react/display-name
                        expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                                <Button
                                    onClick={(e) => {
                                        setRowKeysToExpand(undefined);
                                        onExpand(record, e);
                                    }}
                                >
                                    <Icon iconName="AngleTop" />
                                </Button>
                            ) : (
                                <Button
                                    onClick={(e) => {
                                        setRowKeysToExpand(undefined);
                                        onExpand(record, e);
                                    }}
                                >
                                    <Icon iconName="AngleBottom" />
                                </Button>
                            ),
                        expandedRowKeys: rowKeysToExpand,
                    }}
                    rowKey={(record): string => record.locationId}
                    title={renderTableHeader}
                    footer={renderTableFooter}
                    pagination={false}
                />
            </Content>
        </div>
    );
});

export default UnitInventory;
