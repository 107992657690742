import { Button, Col, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { ImportMembersPreviewItemDto } from 'Api/Features/Memberships/Dtos/ImportMembersPreviewItemDto';
import BaseModal from 'Components/base-modal/base-modal';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './import-member-already-in-company.less';

interface ImportMemberTipModalProps {
    visible: boolean;
    members: ImportMembersPreviewItemDto[];
    onComplete: (submit: boolean) => void;
}

const formGutter: [Gutter, Gutter] = [40, 0];

const ImportMembersAlreadyInCompanyModal: FunctionComponent<ImportMemberTipModalProps> = ({
    visible,
    members,
    onComplete,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    //#endregion

    //#region Submit / Exit
    const dismiss = (submit: boolean): void => {
        onComplete(submit);
    };

    const exit = (): void => {
        dismiss(false);
    };

    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Membership.members_already_in_company')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="ImportMembersAlreadyInCompanyModal">
                <div className="title">{t('Membership.some_members_are_in_company')}</div>
                <div>
                    {members.map((member: ImportMembersPreviewItemDto, index) => (
                        <Row key={member.email!} className="item" gutter={formGutter}>
                            <Col span={6}>
                                <div className="label">{t('first_name')}</div>
                                <div className="label--value">{`${member.memberAccount?.firstName}`}</div>
                            </Col>

                            <Col span={6}>
                                <div className="label">{t('last_name')}</div>
                                <div className="label--value">{`${member.memberAccount?.lastName}`}</div>
                            </Col>

                            <Col span={6}>
                                <div className="label">{t('email')}</div>
                                <div className="label--value">{`${member.email}`}</div>
                            </Col>

                            <Col span={6}>
                                <div className="label">{t('User.roles')}</div>
                                <div className="label--role">
                                    {member?.roles?.map((role, i) => (
                                        <span key={role + i}>{role}</span>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    ))}
                </div>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): void => dismiss(false)}
                    >
                        {t('Membership.return_to_members_invitation')}
                    </Button>
                    <Button
                        type="primary"
                        className="positive"
                        onClick={(): void => onComplete(true)}
                    >
                        {t('i_understand')}
                    </Button>
                </div>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(ImportMembersAlreadyInCompanyModal);
