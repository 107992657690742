import { User } from 'Components/icons';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { useService } from 'Hooks';
import debounce from 'lodash.debounce';
import { ManagerUser } from 'Models/ManagerUsers/ManagerUser';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ManagerUserService } from 'Services/ManagerUserService';
import { theme } from 'variant';

interface ManagerUserSelectProps {
    onSelectChange: (selectedManagerId?: string) => void;
    isClearable?: boolean;
    isMultiple?: boolean;
    selectedManagerIds?: string[];
    initialManagerSearchResults?: ManagerUser[];
    initialSelectedManagerOptions?: SelectCustomOption[];
}

const ManagerUserSelect: FunctionComponent<ManagerUserSelectProps> = ({
    onSelectChange,
    isClearable,
    isMultiple,
    selectedManagerIds = [],
    initialManagerSearchResults,
    initialSelectedManagerOptions,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const managerUserService = useService(ManagerUserService);
    const pageSize = 25;
    const [managersCurrentPage, setManagersCurrentPage] = useState(0);
    const [managersSearchResults, setManagersSearchResults] = useState<ManagerUser[]>([]);
    const [managersSearchTerm, setManagersSearchTerm] = useState('');
    const [managersMaxResults, setManagersMaxResults] = useState(false);
    const [managersOptions, setManagersOptions] = useState<SelectCustomOption[]>([]);
    const [selectedManagerOptions, setSelectedManagerOptions] = useState<SelectCustomOption[]>([]);
    const [selectedManagerId, setSelectedManagerId] = useState<string[]>([]);
    const [selectLoading, setSelectLoading] = useState(false);
    const firstUpdate = useRef(true);
    //#endregion

    //#region Event handlers
    const resetManagersSearch = (): void => {
        setManagersCurrentPage(0);
        setManagersSearchResults([]);
        setManagersSearchTerm('');
        setManagersMaxResults(false);
    };

    const handleManagerKeywordsChange = useCallback((value: string): void => {
        resetManagersSearch();
        setManagersSearchTerm(value);
    }, []);

    const searchManagerUsers = async (page: number, searchTerm: string): Promise<ManagerUser[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
        };
        const [results, totalItemCount] = await managerUserService.getManagerUsers(args);

        if (results.length + pageSize * page >= totalItemCount) {
            setManagersMaxResults(true);
        }
        setSelectLoading(false);
        return results;
    };

    const debounceManagerSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchManagerUsers(page, searchTerm).then((results) => {
                setManagersSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    const handleManagersMenuScrollToBottom = (): void => {
        if (!managersMaxResults) {
            setManagersCurrentPage((prevPage) => prevPage + 1);
        }
    };
    //#endregion

    useEffect(() => {
        if(initialSelectedManagerOptions) {
            setSelectedManagerOptions(initialSelectedManagerOptions);
        }
    }, [initialSelectedManagerOptions]);

    useEffect(() => {
        setSelectedManagerId(selectedManagerIds)
    }, [selectedManagerIds])

    useEffect(() => {
        const searchResults = managersSearchResults?.map(
            (x: ManagerUser) =>
                ({
                    value: x?.id,
                    label: x?.name,
                    imageUrl: x?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedManagerOptions);

        setManagersOptions(merged);
    }, [managersSearchResults, selectedManagerOptions]);

    useEffect(() => {
        if (initialManagerSearchResults !== undefined && initialManagerSearchResults.length > 0) {
            setManagersSearchResults(initialManagerSearchResults);
            const searchResults = initialManagerSearchResults.map(
                (x: ManagerUser) =>
                    ({
                        value: x?.id,
                        label: x?.name,
                        imageUrl: x?.imageUrl,
                        badge: undefined,
                    } as SelectCustomOption)
            );

            const merged = mergeSelectedOptionsWithSearchResults(
                searchResults,
                selectedManagerOptions
            );

            setManagersOptions(merged);
        }
    }, [initialManagerSearchResults, selectedManagerOptions]);

    useEffect(() => {
        //If an initial set of results was passed to component, do not fetch on first page update.
        //Subsequent search term or current page changes will trigger fetch
        if (firstUpdate.current && initialManagerSearchResults) {
            firstUpdate.current = false;
            return;
        }
        setSelectLoading(true);
        debounceManagerSearch.current(managersCurrentPage, managersSearchTerm);
    }, [managersCurrentPage, managersSearchTerm]); // do not include initialManagerSearchResults

    //#region Render
    return (
        <SelectCustom
            options={managersOptions}
            defaultImg={<User fill={theme['white']} />}
            strongLabel={true}
            placeholder={t('SelectCustom.placeholder_default')}
            onKeywordsChange={handleManagerKeywordsChange}
            onMenuScrollToBottom={handleManagersMenuScrollToBottom}
            onChange={(value: any): void => {
                setSelectedManagerId([value.value]);
                const options = [value as SelectCustomOption];
                setSelectedManagerOptions(options);
                onSelectChange(value.value);
            }}
            hideSelectedOptions={false}
            selected={selectedManagerId}
            isClearable={isClearable}
            isMulti={isMultiple}
            isLoading={selectLoading}
            idAttribute={'ManagerUserSelect'}
        />
    );
    //#endregion
};

export default React.memo(ManagerUserSelect);
