import React, { createContext, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useService, useStores } from 'Hooks';
import { MembershipService } from 'Services/MembershipService';
import { MembershipDetailsPlusDto } from 'Api/Features/Memberships/Dtos/MembershipDetailsPlusDto';
import { DayPassService } from 'Services/DayPassService';
import { TopHeaderNavigation } from 'Components/top-header-navigation';
import { MenuItem } from 'Components/top-header-navigation/top-header-navigation';

export const CompanyContext = createContext<CompanyContext | undefined>(undefined);

export interface CompanyContext extends MembershipDetailsPlusDto {
    fetchCompany: () => void;
}

const CompanyDetail: FunctionComponent = observer(({ children }: any) => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const [companyContext, setCompanyContext] = useState<CompanyContext>();
    const { toastStore, globalLoadingStore } = useStores();

    const membershipService = useService(MembershipService);
    const dayPassService = useService(DayPassService);

    const fetchCompany = useCallback(async () => {
        try {
            globalLoadingStore.addLoading();

            const membershipResponse = await membershipService.getMembership(id);

            const [_, totalItemCount] = await dayPassService.getDayPasses({
                membershipId: id,
                available: true,
                page: 0,
                pageSize: 1,
                consumableByAccountId: membershipResponse?.owner?.id
            });
            if (membershipResponse)
                setCompanyContext({
                    ...membershipResponse,
                    fetchCompany,
                    availableDayPassCount: totalItemCount,
                });
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [membershipService, id, t]);

    useEffect(() => {
        fetchCompany();
    }, [fetchCompany]);

    const menuItems: MenuItem[] = [
        {
            key: `/companies/${id}/dashboard`,
            label: t('Contact.contacts_dashboard'),
        },
        {
            key: `/companies/${id}/dashboard/subscriptions`,
            label: t('subscriptions'),
        },
        {
            key: `/companies/${id}/dashboard/finance`,
            label: t('finance'),
        },
        {
            key: `/companies/${id}/dashboard/messages`,
            label: t('Contact.contacts_messages_label'),
        },
        {
            key: `/companies/${id}/dashboard/booking-history`,
            label: t('Contact.contacts_booking_history'),
        },
        {
            key: `/companies/${id}/dashboard/team`,
            label: t('team'),
        },
    ];

    return (
        <CompanyContext.Provider value={companyContext}>
            <div className="CompanyDetail">
                <TopHeaderNavigation menuItems={menuItems} hasDashboard/>

                {children}
            </div>
        </CompanyContext.Provider>
    );
});

export default CompanyDetail;
