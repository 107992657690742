import { Button, Col, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { Content } from 'antd/lib/layout/layout';
import Icon from 'Components/icons/icon';
import { ListSectionHeader } from 'Components/list-section-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { SimpleList } from 'Components/simple-list';
import { useService, useStores } from 'Hooks';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetSystemParametersResponse, SystemService } from 'Services/SystemService';
import { images, theme } from 'variant';
import './index.less';
import CreditConversionRatioModal from './modals/credit-conversion-ratio-modal';
import WelcomeMessageModal from './modals/welcome-message-modal';

const formGutter: [Gutter, Gutter] = [40, 0];
const { usersHeader } = images;

const breadcrumbs: BreadcrumbSegment[] = [
    {
        nameKey: 'Administration.administration_title',
        path: 'administration',
    },
    {
        nameKey: 'Administration.parameters',
        path: 'parameters',
    },
];

const defaultSystemParametersData = { welcomeMessage: '', creditConversionRatio: 0 };

const Parameters: FunctionComponent = () => {
    const systemService = useService(SystemService);
    const { globalLoadingStore, toastStore } = useStores();
    const [loading, setLoading] = useState(true);
    const [editWelcomeMessageModalOpen, setEditWelcomeMessageModalOpen] = useState(false);
    const [editCreditConversionRatioModalOpen, setEditCreditConversionRatioModalOpen] = useState(
        false
    );
    const [systemParametersData, setSystemParametersData] = useState<GetSystemParametersResponse>(
        defaultSystemParametersData
    );
    const { t } = useTranslation();

    const fetch = useCallback(async (): Promise<void> => {
        setLoading(true);
        globalLoadingStore.addLoading();

        try {
            const response = await systemService.getSystemParameters();

            setSystemParametersData(response);
        } catch (e) {
            toastStore.genericError();
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [
        globalLoadingStore,
        setEditWelcomeMessageModalOpen,
        setEditCreditConversionRatioModalOpen,
        systemService,
        toastStore,
    ]);

    const onEditWelcomeMessageClick = (): void => setEditWelcomeMessageModalOpen(true);
    const onEditCreditConversionRatioClick = (): void =>
        setEditCreditConversionRatioModalOpen(true);

    const onWelcomeMessageComplete = (): void => {
        setEditWelcomeMessageModalOpen(false);
        fetch();
    };
    const onCreditConversionRatioComplete = (): void => {
        setEditCreditConversionRatioModalOpen(false);
        fetch();
    };

    useEffect(() => {
        fetch();
    }, [fetch]);

    return (
        <div className="Parameters">
            <ListSectionHeader
                title={t('Administration.parameters')}
                subTitle={t('Administration.parameters_subtitle')}
                defaultImg={<Icon iconName="AdministrationNut" fill={theme['primary-color']} />}
                width="150"
                backgroundImageUrl={usersHeader}
                loading={loading}
                routes={breadcrumbs}
            />

            <Content>
                <Row gutter={formGutter}>
                    <Col span={12}>
                        <SimpleList
                            className="editor-style"
                            title={t('welcome_message')}
                            data={[{ title: systemParametersData.welcomeMessage }]}
                        >
                            <Button
                                icon={<Icon iconName="PencilBorder" />}
                                onClick={onEditWelcomeMessageClick}
                            />
                        </SimpleList>
                    </Col>

                    <Col span={12}>
                        <SimpleList
                            title={t('Administration.credit_conversion_ratio')}
                            data={[
                                {
                                    title: `${systemParametersData.creditConversionRatio}`,
                                },
                            ]}
                        >
                            <Button
                                icon={<Icon iconName="PencilBorder" />}
                                onClick={onEditCreditConversionRatioClick}
                            />
                        </SimpleList>
                    </Col>
                </Row>
            </Content>

            {editWelcomeMessageModalOpen && (
                <WelcomeMessageModal
                    visible={editWelcomeMessageModalOpen}
                    systemParametersData={systemParametersData}
                    onComplete={onWelcomeMessageComplete}
                />
            )}

            {editCreditConversionRatioModalOpen && (
                <CreditConversionRatioModal
                    visible={editCreditConversionRatioModalOpen}
                    systemParametersData={systemParametersData}
                    onComplete={onCreditConversionRatioComplete}
                />
            )}
        </div>
    );
};

export default Parameters;
