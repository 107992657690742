import React, { FunctionComponent } from 'react';
import { Row, Col, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { Gutter } from 'antd/es/grid/row';
import './summary-form-section.less';
import { SimpleList } from 'Components/simple-list';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import {
    Location as LocationIcon,
    Plan as PlanIcon,
    Calendar as CalendarIcon,
    Dollar as DollarIcon,
    Discount as DiscountIcon,
    Check as CheckIcon,
    SecurityDeposit as SecurityDepositIcon,
    Wallet as WalletIcon,
    Credit as CreditIcon,
    Company,
} from 'Components/icons';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import { theme } from 'variant';
import { SubscriptionProposalDetailsDto } from 'Api/Features/SubscriptionProposals/Dtos/SubscriptionProposalDetailsDto';
import moment from 'moment';
import { DISPLAY_DATE_FORMAT } from 'Models/Constants';
import FloorPlanComponent, { FloorPlanViewType } from 'Components/floor-plan/floor-plan';
import { OpportunityMembershipDto } from 'Api/Features/Opportunities/Dtos/OpportunityMembershipDto';
import ImpactOnInvoices, { ImpactOnInvoicesType } from 'Components/impact-on-invoices/impact-on-invoices';

const formGutter: [Gutter, Gutter] = [40, 0];

interface SummaryFormSectionProps {
    proposal?: SubscriptionProposalDetailsDto;
    membership?: OpportunityMembershipDto;
    isDetailsView?: boolean;
}

const SummaryFormSection: FunctionComponent<SummaryFormSectionProps> = ({
    proposal,
    membership,
    isDetailsView,
}) => {
    //#region Hooks
    const { t } = useTranslation();

    //#endregion Hooks

    const getTotalBasePrice = (): number => {
        const addOnsTotal =
            proposal?.addOns?.reduce((total, addOn) => total + (addOn?.price ?? 0), 0) ?? 0;
        const price = proposal?.periods ? proposal.periods[0]?.price! : 0;

        return price + addOnsTotal;
    };

    const locationInformation = [
        {
            title: proposal?.campus?.name ?? '',
            avatar: (
                <ImageWithPlaceholder
                    width="32"
                    height="32"
                    imgSrc={proposal?.campus?.mainImageUrl}
                    defaultImg={<LocationIcon />}
                />
            ),
        },
    ];

    const companyInformation = [
        {
            title: membership?.name ?? '',
            avatar: (
                <ImageWithPlaceholder
                    width="32"
                    height="32"
                    imgSrc={membership?.imageUrl}
                    defaultImg={<Company />}
                />
            ),
        },
    ];

    const planInformation = [
        {
            title: proposal?.plan?.name ?? '',
            avatar: (
                <ImageWithPlaceholder
                    width="32"
                    height="32"
                    imgSrc={proposal?.plan?.name}
                    defaultImg={<PlanIcon />}
                />
            ),
            description: t('Proposal.plan_starting_at', {
                param1: proposal?.plan?.price?.toFixed(2) ?? '0.00',
            }),
        },
    ];

    const unitsInformation = proposal?.units?.map((unit) => ({
        title: unit?.name ?? '',
        description: t(`Proposal.suggested_unit_price`, {
            param1: unit?.marketPrice?.toFixed(2) ?? '0.00',
        }),
    }));

    const durationInformation = (): SimpleListItemProps[] => {
        const duration: SimpleListItemProps[] = [
            {
                title: t('start_date'),
                avatar: <CalendarIcon fill={theme['primary-color']} />,
                description: moment.utc(proposal?.startDate).format(DISPLAY_DATE_FORMAT),
            },
            {
                title: t('end_date'),
                avatar: <CalendarIcon fill={theme['primary-color']} />,
                description: proposal?.endDate
                    ? moment.utc(proposal?.endDate).format(DISPLAY_DATE_FORMAT)
                    : t('none'),
            },
        ];
        if (proposal?.endDate && proposal.becomesMonthToMonthOnExpiration)
            duration.push({
                title: t('Proposal.month_to_month_after', {
                    param1: moment.utc(proposal?.endDate).format(DISPLAY_DATE_FORMAT),
                }),
                avatar: <PlanIcon fill={theme['primary-color']} />,
            });

        return duration;
    };

    const pricingInformation = (): SimpleListItemProps[] => {
        const pricing: SimpleListItemProps[] = [];

        const periods = proposal?.periods?.map((period) => ({
            title: t('Proposal.pricing_from', {
                param1: moment.utc(period?.startDate).format(DISPLAY_DATE_FORMAT),
            }),
            avatar: <DollarIcon fill={theme['primary-color']} />,
            description: t('us_price_format', {
                param1: period?.price?.toFixed(2) ?? '0.00',
            }),
        }));

        if (periods) pricing.push(...periods);

        if (proposal?.monthToMonthAmount) {
            pricing.push({
                title: t('mtm_price'),
                avatar: <DollarIcon fill={theme['primary-color']} />,
                description: t('us_price_format', {
                    param1: proposal?.monthToMonthAmount?.toFixed(2) ?? '0.00',
                }),
            });
        }

        return pricing;
    };

    const addOnsInformation = proposal?.addOns?.map((addOn) => ({
        title: addOn?.name ?? '',
        description: t('us_price_format', {
            param1: addOn?.price?.toFixed(2) ?? '0.00',
        }),
    }));

    const securityDepositInformation = [
        {
            title: t('us_price_format', {
                param1: proposal?.securityDeposit?.toFixed(2) ?? '0.00',
            }),
            avatar: <SecurityDepositIcon fill={theme['primary-color']} />,
        },
    ];

    const creditsInformation = [
        {
            title: proposal?.creditPointsPerInterval?.toFixed(2) ?? '0.00',
            avatar: <CreditIcon fill={theme['primary-color']} />,
        },
    ];

    const paymentModalitiesInformation = (): SimpleListItemProps[] => {
        if (proposal?.isCheckPayable)
            return [
                {
                    title: t('Proposal.check_transactions'),
                    avatar: <CheckIcon fill={theme['primary-color']} />,
                },
            ];
        if (proposal?.isPreAuthorizedTransaction)
            return [
                {
                    title: t('Proposal.payment_pre_authorized'),
                    avatar: <WalletIcon fill={theme['primary-color']} />,
                },
            ];
        else
            return [
                {
                    title: t('Proposal.payment_manual'),
                    avatar: <WalletIcon fill={theme['primary-color']} />,
                },
            ];
    };

    //#region Render
    return (
        <div className="SummaryFormSection">
            <Row className="floor-plan-row" gutter={formGutter}>
                <Col span={24}>
                    <FloorPlanComponent
                        campusId={proposal?.campus ? proposal.campus!.id! : undefined}
                        proposalUnitIds={
                            proposal?.units ? proposal.units.map((unit) => unit?.id!) : undefined
                        }
                        membershipImage={membership?.imageUrl ?? undefined}
                        viewType={FloorPlanViewType.Subscription}
                    />
                </Col>
            </Row>

            {!isDetailsView && <div className="proposal-title">{proposal?.title ?? t('summary')}</div>}

            <Row gutter={formGutter}>
                <Col span={12}>
                    <Row>
                        <Col span={24}>
                            <SimpleList title={t('location')} data={locationInformation} />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <SimpleList title={t('company')} data={companyInformation} />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <SimpleList title={t('plan')} data={planInformation} />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <SimpleList title={t('units')} data={unitsInformation} />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <SimpleList
                                title={t('Proposal.subscription_duration')}
                                data={durationInformation()}
                                className={'large-description'}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <SimpleList
                                title={t('pricing')}
                                data={pricingInformation()}
                                className={'large-description'}
                            />
                        </Col>
                    </Row>

                    {(proposal?.addOns?.length ?? 0) > 0 && (
                        <Row>
                            <Col span={24}>
                                <SimpleList title={t('add_ons')} data={addOnsInformation} />
                            </Col>
                        </Row>
                    )}
                </Col>

                <Col span={12}>
                    <Row>
                        <Col span={24}>
                            <SimpleList
                                className="base-price"
                                title={t('Proposal.base_monthly_price')}
                            >
                                <div className="base-price-container">
                                    <div>{proposal?.plan?.name}</div>
                                    <div className="price">
                                        {proposal?.periods &&
                                            t('us_price_format', {
                                                param1: proposal.periods[0]?.price?.toFixed(2),
                                            })}
                                    </div>
                                </div>

                                {proposal?.addOns?.map((addOn) => (
                                    <div key={addOn?.id} className="base-price-container">
                                        <div>{addOn?.name}</div>
                                        <div className="price">
                                            {t('us_price_format', {
                                                param1: addOn?.price?.toFixed(2),
                                            })}
                                        </div>
                                    </div>
                                ))}

                                <Divider className="base-price-divider" />
                                <div className="base-price-container">
                                    <div className="total">{t('total')}</div>
                                    <div className="price-total">
                                        {t('us_price_format', {
                                            param1: getTotalBasePrice().toFixed(2),
                                        })}
                                    </div>
                                </div>
                            </SimpleList>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <SimpleList
                                title={t('security_deposit')}
                                data={securityDepositInformation}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <SimpleList title={t('credits')} data={creditsInformation} />
                        </Col>
                    </Row>

                    {(proposal?.discounts?.length ?? 0) > 0 && (
                        <Row>
                            <Col span={24}>
                                <SimpleList title={t('discounts')} className="discounts">
                                    {proposal?.discounts?.map((discount, i) => (
                                        <>
                                            <div key={i} className="discount-container">
                                                <div className="avatar">
                                                    <DiscountIcon fill={theme['primary-color']} />
                                                </div>
                                                <div className="discount-info-container">
                                                    <div className="title">{discount?.title}</div>
                                                    <div className="amount">
                                                        {t('us_price_format', {
                                                            param1: discount?.amount,
                                                        })}
                                                    </div>
                                                    <div className="period">
                                                        {discount?.endDate
                                                            ? t('Proposal.discount_from_to', {
                                                                  param1: discount?.startDate
                                                                      ? moment
                                                                            .utc(
                                                                                discount?.startDate
                                                                            )
                                                                            .format(
                                                                                DISPLAY_DATE_FORMAT
                                                                            )
                                                                      : moment
                                                                            .utc(
                                                                                proposal?.startDate
                                                                            )
                                                                            .format(
                                                                                DISPLAY_DATE_FORMAT
                                                                            ),
                                                                  param2: discount?.endDate
                                                                      ? moment
                                                                            .utc(discount?.endDate)
                                                                            .format(
                                                                                DISPLAY_DATE_FORMAT
                                                                            )
                                                                      : '',
                                                              })
                                                            : t('Proposal.discount_from', {
                                                                  param1: discount?.startDate
                                                                      ? moment
                                                                            .utc(
                                                                                discount?.startDate
                                                                            )
                                                                            .format(
                                                                                DISPLAY_DATE_FORMAT
                                                                            )
                                                                      : moment
                                                                            .utc(
                                                                                proposal?.startDate
                                                                            )
                                                                            .format(
                                                                                DISPLAY_DATE_FORMAT
                                                                            ),
                                                              })}
                                                    </div>
                                                    {discount?.internalNote && (
                                                        <div className="note-container">
                                                            <div className="prefix">
                                                                {t('Proposal.discount_note')}
                                                            </div>
                                                            <div className="note">
                                                                {discount?.internalNote}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {i < (proposal.discounts?.length ?? 0) && (
                                                <Divider className="divider" />
                                            )}
                                        </>
                                    ))}
                                </SimpleList>
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Col span={24}>
                            <SimpleList
                                title={t('payment_methods')}
                                data={paymentModalitiesInformation()}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {proposal?.acceptancePreview?.adjustedInvoices &&
                proposal?.acceptancePreview?.adjustedInvoices.length > 0 && (
                    <div className="impacted-invoices-container">
                        <ImpactOnInvoices
                            invoices={proposal.acceptancePreview.adjustedInvoices
                                .filter((invoice) => invoice !== null)
                                .map((invoice) => invoice!)}
                            impactType={ImpactOnInvoicesType.ExistingInvoice}
                        />
                    </div>
                )}

            {proposal?.acceptancePreview?.createdInvoices &&
                proposal?.acceptancePreview?.createdInvoices.length > 0 && (
                    <ImpactOnInvoices
                        invoices={proposal.acceptancePreview.createdInvoices
                            .filter((invoice) => invoice !== null)
                            .map((invoice) => invoice!)}
                        impactType={ImpactOnInvoicesType.NewInvoices}
                    />
                )}
        </div>
    );

    //#endregion
};

export default React.memo(SummaryFormSection);
