import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { SalutationDto } from 'Api/Features/Accounts/Dtos/SalutationDto';
import { LeadDto } from 'Api/Features/Leads/Dtos/LeadDto';
import { LeadSourceDto } from 'Api/Features/Leads/Dtos/LeadSourceDto';
import { LeadStatusDto } from 'Api/Features/Leads/Dtos/LeadStatusDto';
import { UpdateLeadRequestDto } from 'Api/Features/Leads/Dtos/UpdateLeadRequestDto';
import { IndustryDto } from 'Api/Features/Memberships/Dtos/IndustryDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Lead, User } from 'Components/icons';
import SelectCustom, { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { BILLION } from 'Models/Constants';
import { toLeadDto } from 'Models/Leads/DtoOutput';
import { LeadSource } from 'Models/Leads/LeadSource';
import { LightCampusDetailsInfo } from 'Models/Location/LightCampusInfo';
import { ManagerUser } from 'Models/ManagerUsers/ManagerUser';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import LostModal from 'Routes/authenticated/leads/id/mark-as-lost';
import MoveToEngagedModal from 'Routes/authenticated/leads/id/move-to-engaged-modal';
import TouchBaseModal from 'Routes/authenticated/leads/id/touch-base';
import { CreateLeadSchema } from 'Schemas';
import { LeadService } from 'Services/LeadService';
import { ManagerUserService } from 'Services/ManagerUserService';
import { theme } from 'variant';
import './create-lead.less';

const { Title } = Typography;
const { Option } = Select;

const formGutter: [Gutter, Gutter] = [40, 0];

interface LeadModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    lead?: LeadDto;
}

const CreateLeadModal: FunctionComponent<LeadModalProps> = ({ visible, onComplete, lead }) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors, setErrors] = useFormValidation(CreateLeadSchema, form);
    const {
        locationStore,
        globalLoadingStore,
        toastStore,
        confirmationModalStore,
        userPermissionsStore,
    } = useStores();
    const leadService = useService(LeadService);
    const managerUserService = useService(ManagerUserService);
    const [selectedLeadStatus, setSelectedLeadStatus] = useState<LeadStatusDto>(LeadStatusDto.New);

    const pageSize = 25;
    const [managersCurrentPage, setManagersCurrentPage] = useState(0);
    const [managersSearchResults, setManagersSearchResults] = useState<ManagerUser[]>([]);
    const [managersSearchTerm, setManagersSearchTerm] = useState('');
    const [managersMaxResults, setManagersMaxResults] = useState(false);
    const [managersOptions, setManagersOptions] = useState<SelectCustomOption[]>([]);
    const [selectedOwnerOptions, setSelectedOwnerOptions] = useState<SelectCustomOption[]>([]);
    const [selectedOwnerId, setSelectedOwnerId] = useState<string[]>([]);
    const [selectLoading, setSelectLoading] = useState(false);
    const [leadSource, setLeadSource] = useState<LeadSourceDto | undefined>(
        lead ? lead.source : undefined
    );

    //lead statuses modal states
    const [touchBaseModalOpen, setTouchBaseModalOpen] = useState(false);
    const [lostModalOpen, setLostModalOpen] = useState(false);
    const [moveToEngagedModalOpen, setMoveToEngagedModalOpen] = useState(false);

    //#endregion

    const resetManagersSearch = (): void => {
        setManagersCurrentPage(0);
        setManagersSearchResults([]);
        setManagersSearchTerm('');
        setManagersMaxResults(false);
    };

    const dismiss = (success = false, id?: string): void => {
        onComplete(success, id);
        form.resetFields();
        resetErrors();
    };

    const createModel = (): UpdateLeadRequestDto => {
        const formValues = form.getFieldsValue();
        const model: UpdateLeadRequestDto = {
            ...formValues,
            email: formValues.email !== undefined ? (formValues.email as string).trim() : undefined,
            status:
                form.getFieldValue('status') === undefined
                    ? LeadStatusDto.New
                    : form.getFieldValue('status'),
            ownerAccountId:
                selectedOwnerId.length > 0 ? selectedOwnerId[0] : lead?.ownerAccount?.id,
        };
        return model;
    };

    //#region Event handlers
    const handleManagerKeywordsChange = useCallback((value: string): void => {
        resetManagersSearch();
        setManagersSearchTerm(value);
    }, []);

    const searchManagerUsers = async (page: number, searchTerm: string): Promise<ManagerUser[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
        };
        const [results, totalItemCount] = await managerUserService.getManagerUsers(args);

        if (results.length + pageSize * page >= totalItemCount) {
            setManagersMaxResults(true);
        }
        setSelectLoading(false);
        return results;
    };

    const debounceManagerSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchManagerUsers(page, searchTerm).then((results) => {
                setManagersSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    const handleManagersMenuScrollToBottom = (): void => {
        if (!managersMaxResults) {
            setManagersCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const onOpenStatusModal = async (): Promise<void> => {
        const data = createModel();
        if (!(await validateForm(data))) return;

        if (selectedLeadStatus === LeadStatusDto.TouchBase) setTouchBaseModalOpen(true);
        if (selectedLeadStatus === LeadStatusDto.Lost) setLostModalOpen(true);
        if (selectedLeadStatus === LeadStatusDto.Engaged) setMoveToEngagedModalOpen(true);
    };

    const onTouchBaseComplete = (success: boolean, id?: string): void => {
        setTouchBaseModalOpen(false);
        if (success) dismiss(true, id);
    };

    const onMarkAsLostComplete = (success: boolean, id?: string): void => {
        setLostModalOpen(false);
        if (success) dismiss(true, id);
    };

    const onMoveToEngagedComplete = (success: boolean, id?: string): void => {
        setMoveToEngagedModalOpen(false);
        if (success) dismiss(true, id);
    };

    //#endregion

    //#region Effects
    useEffect(() => {
        if (lead) {
            form.setFieldsValue({
                salutation: lead.salutation,
                firstName: lead.firstName,
                middleName: lead.middleName,
                lastName: lead.lastName,
                phoneNumber: lead.phoneNumber,
                phoneNumber2: lead.phoneNumber2,
                email: lead.email,
                companyName: lead.companyName,
                jobPosition: lead.jobPosition,
                campusId: lead.campus?.id,
                website: lead.website,
                officeNumber: lead.officeNumber,
                numberOfEmployees: lead.numberOfEmployees,
                industry: lead.industry,
                source: lead.source,
                status: lead.status,
                type: lead.type,
                description: lead.description,
                sourceOther: lead.sourceOther ?? undefined,
                address: lead.address
                    ? {
                          addressLine1: lead.address.addressLine1 ?? undefined,
                          city: lead.address.city ?? undefined,
                          state: lead.address.state ?? undefined,
                          country: lead.address.country ?? undefined,
                          postalCode: lead.address.postalCode ?? undefined,
                      }
                    : undefined,
            });
            setSelectedOwnerId(lead.ownerAccount?.id ? [lead.ownerAccount.id] : []);
            setSelectedOwnerOptions(
                lead.ownerAccount?.id
                    ? [
                          {
                              value: lead.ownerAccount?.id,
                              label: `${lead.ownerAccount?.firstName} ${lead.ownerAccount?.lastName}`,
                              imageUrl: lead.ownerAccount?.imageUrl,
                              badge: undefined,
                          } as SelectCustomOption,
                      ]
                    : []
            );
            setSelectedLeadStatus(lead.status || LeadStatusDto.New);
        }
    }, [lead, form, userPermissionsStore.userInfo]);

    //set the current user in the owner dropdown when creating lead
    useEffect(() => {
        if (lead === undefined && userPermissionsStore.userInfo) {
            setSelectedOwnerId([userPermissionsStore.userInfo.id!]);
            setSelectedOwnerOptions([
                {
                    value: userPermissionsStore.userInfo.id,
                    label: `${userPermissionsStore.userInfo.firstName} ${userPermissionsStore.userInfo.lastName}`,
                    imageUrl: userPermissionsStore.userInfo.imageUrl,
                    badge: undefined,
                } as SelectCustomOption,
            ]);
        }
    }, [lead, userPermissionsStore]);

    useEffect(() => {
        const searchResults = managersSearchResults?.map(
            (x: ManagerUser) =>
                ({
                    value: x?.id,
                    label: x?.name,
                    imageUrl: x?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedOwnerOptions);

        setManagersOptions(merged);
    }, [managersSearchResults, t, lead, selectedOwnerOptions]);

    useEffect(() => {
        setSelectLoading(true);
        debounceManagerSearch.current(managersCurrentPage, managersSearchTerm);
    }, [managersCurrentPage, managersSearchTerm]);
    //#endregion

    //#region Submit / Exit
    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Lead />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText:
                    lead !== undefined
                        ? t('Lead.lead_confirm_positive_edit')
                        : t(`Lead.lead_confirm_positive`),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const data = createModel();
        if (!(await validateForm(data))) return;

        try {
            globalLoadingStore.addLoading();
            let createdId = '';
            if (lead === undefined) createdId = await leadService.createLead(data);
            else {
                const updateData: UpdateLeadRequestDto = {
                    ...data,
                    label: lead.label,
                };
                await leadService.updateLead(lead.id!, updateData);
            }

            toastStore.toast({
                type: 'success',
                messageKey:
                    lead !== undefined ? 'Lead.lead_edit_success' : `Lead.lead_create_success`,
            });
            dismiss(true, createdId);
        } catch (e) {
            const errors = new Map<string, string[]>();
            if (e.response.data.modelState['request.Website'] !== undefined) {
                errors.set('website', [e.response.data.modelState['request.Website'][0]]);
                setErrors(errors);
            } else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={lead !== undefined ? t('Lead.lead_edit') : t('Lead.lead_create')}
            className="FormModal"
            onCancel={exit}
        >
            <Input hidden defaultValue={lead?.id} value={lead?.id} />
            <div className="CreateLead">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    {/* Contact Information */}
                    <Title level={4}>{t('contact_information')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="salutation"
                                errors={errors}
                                label={t('Lead.salutation')}
                            >
                                <Select>
                                    {Object.keys(SalutationDto).map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`Lead.salutation_${x}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="firstName"
                                errors={errors}
                                label={t('first_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="middleName"
                                errors={errors}
                                label={t('User.middle_name')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="lastName"
                                errors={errors}
                                label={t('last_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="phoneNumber"
                                errors={errors}
                                label={t('User.mobile_number')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="phoneNumber2"
                                errors={errors}
                                label={t('phone_number')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="email"
                                errors={errors}
                                label={t('email')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="jobPosition"
                                errors={errors}
                                label={t('Lead.job_position')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name={['address', 'addressLine1']}
                                errors={errors}
                                label={t('address')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <ValidatedFormItem
                                name={['address', 'city']}
                                errors={errors}
                                label={t('city')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                name={['address', 'state']}
                                errors={errors}
                                label={t('state')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                name={['address', 'country']}
                                errors={errors}
                                label={t('country')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                name={['address', 'postalCode']}
                                errors={errors}
                                label={t('zip_code')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    {/* Company Information */}
                    <Title level={4}>{t('company_information')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="companyName"
                                errors={errors}
                                label={t('company')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                name="campusId"
                                errors={errors}
                                label={t('location')}
                            >
                                <Select>
                                    {locationStore.locations?.map((x: LightCampusDetailsInfo) => (
                                        <Option key={x.id!} value={x.id!}>
                                            {x.name}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem name="website" errors={errors} label={t('website')}>
                                <Input placeholder={t('url_example')} />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="numberOfEmployees"
                                errors={errors}
                                label={t('Lead.number_of_employees')}
                            >
                                <NumberFormat
                                    className="ant-input"
                                    decimalScale={0}
                                    allowNegative={false}
                                    onValueChange={(values: NumberFormatValues): void => {
                                        const { value } = values;
                                        form.setFieldsValue({ numberOfEmployees: value });
                                    }}
                                    isAllowed={(value: NumberFormatValues): boolean =>
                                        value.floatValue === undefined ||
                                        (value.floatValue !== undefined &&
                                            value.floatValue <= BILLION)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="industry"
                                errors={errors}
                                label={t('Lead.industry')}
                            >
                                <Select>
                                    {Object.keys(IndustryDto).map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`Lead.industry_${x}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    {/* Lead Information */}
                    <Title level={4}>{t('lead_information')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="ownerAccountId"
                                errors={errors}
                                label={t('Lead.lead_owner')}
                                required
                            >
                                <SelectCustom
                                    options={managersOptions}
                                    defaultImg={<User fill={theme['white']} />}
                                    strongLabel={true}
                                    placeholder={t('SelectCustom.placeholder_default')}
                                    onKeywordsChange={handleManagerKeywordsChange}
                                    onMenuScrollToBottom={handleManagersMenuScrollToBottom}
                                    onChange={(value: any): void => {
                                        setSelectedOwnerId([value.value]);
                                        const options = [value as SelectCustomOption];
                                        setSelectedOwnerOptions(options);
                                    }}
                                    hideSelectedOptions={false}
                                    selected={selectedOwnerId}
                                    isLoading={selectLoading}
                                    idAttribute={'ownerAccountId'}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="status"
                                errors={errors}
                                label={t('Lead.lead_status')}
                                required
                            >
                                <Select
                                    disabled={!lead}
                                    defaultValue={
                                        lead !== undefined
                                            ? t(`Lead.status_${lead.status}`)
                                            : t(`Lead.status_${LeadStatusDto.New}`)
                                    }
                                    onChange={(value): void =>
                                        setSelectedLeadStatus(value as LeadStatusDto)
                                    }
                                >
                                    {Object.keys(LeadStatusDto)
                                        .filter((status) => status !== LeadStatusDto.Converted)
                                        .map((x) => (
                                            <Option key={x} value={x}>
                                                {t(`Lead.status_${x}`)}
                                            </Option>
                                        ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="type"
                                errors={errors}
                                label={t('Lead.lead_type')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="officeNumber"
                                errors={errors}
                                label={t('Lead.office_number')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="source"
                                errors={errors}
                                label={t('Lead.source')}
                                required
                            >
                                <Select
                                    onChange={(value): void =>
                                        setLeadSource(value as LeadSourceDto)
                                    }
                                >
                                    {Object.keys(LeadSource).map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`Lead.source_${x}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                        {leadSource === LeadSourceDto.Other && (
                            <Col span={8}>
                                <ValidatedFormItem
                                    name="sourceOther"
                                    errors={errors}
                                    label={t('Lead.lead_source_other')}
                                    required
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Col>
                        )}
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name="description"
                                errors={errors}
                                label={t('description')}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        {lead?.status !== selectedLeadStatus &&
                        selectedLeadStatus !== LeadStatusDto.New ? (
                            <Button type="primary" className="positive" onClick={onOpenStatusModal}>
                                {t('submit')}
                            </Button>
                        ) : (
                            <Button type="primary" className="positive" htmlType="submit">
                                {t('submit')}
                            </Button>
                        )}
                    </div>
                </Form>
            </div>

            <TouchBaseModal
                visible={touchBaseModalOpen}
                onComplete={onTouchBaseComplete}
                lead={toLeadDto(createModel(), lead?.id)}
            />
            <LostModal
                visible={lostModalOpen}
                onComplete={onMarkAsLostComplete}
                lead={toLeadDto(createModel(), lead?.id)}
            />
            <MoveToEngagedModal
                visible={moveToEngagedModalOpen}
                onComplete={onMoveToEngagedComplete}
                lead={toLeadDto(createModel(), lead?.id)}
            />
        </BaseModal>
    );
    //#endregion
};

export default React.memo(CreateLeadModal);
