// GENERATED FILE - DO NOT MODIFY

export enum OpportunityEventTypeDto {
    Created = "Created",
    StatusUpdated = "StatusUpdated",
    OwnerUpdated = "OwnerUpdated",
    CommentAdded = "CommentAdded",
    TaskAdded = "TaskAdded",
    TaskUpdated = "TaskUpdated",
    TaskCancelled = "TaskCancelled",
    TourScheduled = "TourScheduled",
    TourUpdated = "TourUpdated",
    TourCancelled = "TourCancelled",
    MeetingScheduled = "MeetingScheduled",
    MeetingUpdated = "MeetingUpdated",
    MeetingCancelled = "MeetingCancelled",
    ReminderScheduled = "ReminderScheduled",
    ReminderUpdated = "ReminderUpdated",
    ReminderCancelled = "ReminderCancelled",
    ProposalCreated = "ProposalCreated",
    ProposalAccepted = "ProposalAccepted",
    ProposalRefused = "ProposalRefused",
    ProposalResent = "ProposalResent",
    AgreementAdded = "AgreementAdded",
    AgreementSigned = "AgreementSigned",
}