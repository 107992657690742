import { Button, Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { CampusUnitPricingDto } from 'Api/Features/Campuses/Dtos/CampusUnitPricingDto';
import { CreateUnitRequestDto } from 'Api/Features/Units/Dtos/CreateUnitRequestDto';
import { UnitTypeDto } from 'Api/Features/Units/Dtos/UnitTypeDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Delete, Units as UnitIcon } from 'Components/icons';
import Icon from 'Components/icons/icon';
import { NumberInput } from 'Components/NumberInput';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { HUNDRED, THOUSAND } from 'Models/Constants';
import { Unit } from 'Models/Units/Unit';
import moment, { Moment } from 'moment';
import {
    default as React,
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { CreateUnitSchema } from 'Schemas';
import { CampusService } from 'Services/CampusService';
import { UnitService } from 'Services/UnitService';
import { theme } from 'variant';
import './create-unit.less';

const { Title } = Typography;
const { Option } = Select;

const formGutter: [Gutter, Gutter] = [40, 0];

interface CreateUnitModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    locationId: string;
    unit?: Unit;
}

enum UnitType {
    Communal = 'Communal',
    Other = 'Other',
}

const CreateUnitModal: FunctionComponent<CreateUnitModalProps> = ({
    visible,
    onComplete,
    locationId,
    unit,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(CreateUnitSchema, form);
    const unitService = useService(UnitService);
    const campusService = useService(CampusService);
    const { globalLoadingStore, toastStore, confirmationModalStore, locationStore } = useStores();
    const [priceOverrideCheckboxState, setPriceOverrideCheckboxState] = useState(
        unit ? unit.priceOverride !== 0 : false
    );
    const [unitPricing, setUnitPricing] = useState<CampusUnitPricingDto | null>(null);
    const [startDateValue, setStartDateValue] = useState<Moment | null>();
    const [unitType, setUnitType] = useState<UnitType>(UnitType.Communal);

    const locationDetails = locationStore.locations.find((location) => location.id === locationId);
    //#endregion

    const fetchUnitPricing = useCallback(async () => {
        globalLoadingStore.addLoading();

        try {
            const response = await campusService.getUnitPricing(locationId);

            setUnitPricing(response);
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, campusService, locationId]);

    const onTypeChange = (type: UnitTypeDto): void => {
        if (type === UnitTypeDto.CommunalArea) setUnitType(UnitType.Communal);
        else setUnitType(UnitType.Other);
    };

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const getCalculatedPrice = (): number => {
        if (locationDetails === undefined) return 0;
        const values = form.getFieldsValue(['numberOfDesks', 'squareFeet']);

        return (
            (unitPricing?.pricePerDesk || 0) * (values.numberOfDesks || 0) +
            (unitPricing?.pricePerSquareFoot || 0) * (values.squareFeet || 0)
        );
    };

    const getMarketPrice = (): number => {
        const values = form.getFieldsValue(['priceOverride', 'priceMultiplier', 'calculatedPrice']);
        if (values.priceOverride && values.priceOverride !== 0) return values.priceOverride;

        if (unitType === UnitType.Communal) {
            return (unitPricing?.communalAreaBasePrice || 0) * (values.priceMultiplier || 1);
        } else {
            return (values.calculatedPrice || 0) * (values.priceMultiplier || 1);
        }
    };

    const setFormFieldsPrices = (): void => {
        form.setFieldsValue({
            calculatedPrice: getCalculatedPrice().toFixed(2),
        });

        const marketPrice = getMarketPrice();
        form.setFieldsValue({
            marketPrice: Number(marketPrice.toFixed(2)),
            sixMonthPrice: Number((marketPrice * 6).toFixed(2)),
            twelveMonthPrice: Number((marketPrice * 12).toFixed(2)),
        });
    };

    const onPriceAffectingInputChange = (): void => {
        setFormFieldsPrices();
    };

    useEffect(() => {
        if (unit) {
            setUnitType(
                unit.type === UnitTypeDto.CommunalArea ? UnitType.Communal : UnitType.Other
            );
            setFormFieldsPrices();
            form.setFieldsValue({
                name: unit.name,
                type: unit.type,
                capacity: unit.capacity,
                squareFeet: unit.squareFeet,
                numberOfDesks: unit.numberOfDesks,
                note: unit.note,
                activeStartDate: unit.activeStartDate ? moment(unit.activeStartDate) : undefined,
                activeEndDate: unit.activeEndDate ? moment(unit.activeEndDate) : undefined,
                priceMultiplier: unit.priceMultiplier,
                priceOverride: unit.priceOverride,
                marketPrice: getMarketPrice(),
                sixMonthPrice: unit.sixMonthPrice,
                twelveMonthPrice: unit.twelveMonthPrice,
                communalbasePrice: unitPricing?.communalAreaBasePrice ?? 0,
            });
        } else {
            setUnitType(UnitType.Communal);
            form.setFieldsValue({
                type: UnitTypeDto.CommunalArea,
                priceMultiplier: 1,
                communalbasePrice: unitPricing?.communalAreaBasePrice ?? 0,
            });
        }
    }, [unit, form, unitPricing]);

    useEffect(() => {
        fetchUnitPricing();
    }, [fetchUnitPricing]);

    //#region Submit / Exit
    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <UnitIcon />,
                title: t('confirm_title'),
                message: t('confirm_message'),
                positiveText: t(`model_confirm_positive_${unit ? 'edit' : 'create'}`, {
                    param1: 'unit',
                }),
                negativeText: t('confirm_negative'),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValue = form.getFieldsValue();

        const model: CreateUnitRequestDto = {
            campusId: locationId,
            ...formValue,
            dataJacks: formValue.dataJacks
                ?.filter((jack: { name: string }) => jack.name)
                .map((jack: { name: string }) => jack.name),
        };

        if (!(await validateForm(model))) return;

        try {
            globalLoadingStore.addLoading();

            if (unit) await unitService.updateUnit(unit.id!, model);
            else await unitService.createUnit(model);
            toastStore.toast({
                type: 'success',
                messageKey: t(`model_${unit ? 'edit' : 'create'}_success`, { param1: 'Unit' }),
            });
            dismiss(true);
        } catch (e) {
            if (e.response?.data?.error === 'E009010') {
                toastStore.toast({
                    type: 'error',
                    messageKey: 'Errors.unit_has_active_subscription_outside_dates',
                });
            } else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={unit !== undefined ? t('Unit.edit_unit') : t('Unit.create_unit')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="CreateUnit">
                <Form
                    scrollToFirstError
                    layout="vertical"
                    onFinish={submit}
                    form={form}
                    initialValues={{
                        dataJacks: unit?.dataJacks
                            ? [...unit.dataJacks?.map((jack) => ({ name: jack }))]
                            : undefined,
                    }}
                >
                    <Title level={4}>{t('basic_information')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="name"
                                errors={errors}
                                label={t('name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="type"
                                errors={errors}
                                label={t('Unit.unit_type')}
                            >
                                <Select
                                    defaultValue={UnitTypeDto.CommunalArea}
                                    onChange={onTypeChange}
                                    disabled={unit !== undefined}
                                >
                                    {Object.keys(UnitTypeDto).map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`Unit.type_${x}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        {unitType === UnitType.Communal && (
                            <Col className="input-with-tip-container" span={12}>
                                <ValidatedFormItem
                                    name="capacity"
                                    errors={errors}
                                    label={t('Unit.capacity')}
                                    required
                                    className="input-with-tip"
                                >
                                    <NumberFormat
                                        allowNegative={false}
                                        decimalScale={0}
                                        customInput={(props: any) => <Input {...props} />}
                                        isAllowed={(value: NumberFormatValues): boolean =>
                                            value.floatValue === undefined ||
                                            (value.floatValue !== undefined &&
                                                value.floatValue >= 0 &&
                                                value.floatValue <= THOUSAND)
                                        }
                                        onChange={onPriceAffectingInputChange}
                                    />
                                </ValidatedFormItem>
                                <small className="input-tip">{t('Unit.capacity_tip')}</small>
                            </Col>
                        )}
                        {unitType === UnitType.Other && (
                            <>
                                <Col span={12}>
                                    <ValidatedFormItem
                                        name="squareFeet"
                                        errors={errors}
                                        label={t('square_feet')}
                                        required
                                    >
                                        <Input onChange={onPriceAffectingInputChange} />
                                    </ValidatedFormItem>
                                </Col>
                                <Col span={12}>
                                    <ValidatedFormItem
                                        name="numberOfDesks"
                                        errors={errors}
                                        label={t('Unit.number_of_desks')}
                                        required
                                    >
                                        <Input onChange={onPriceAffectingInputChange} />
                                    </ValidatedFormItem>
                                </Col>
                            </>
                        )}
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem name="note" errors={errors} label={t('note')}>
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>{t('Unit.active_dates')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="activeStartDate"
                                errors={errors}
                                label={t('start_date')}
                            >
                                <DatePicker
                                    allowClear
                                    className="clearable"
                                    style={{ width: '100%' }}
                                    onChange={(value): void => setStartDateValue(value)}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="activeEndDate"
                                errors={errors}
                                label={t('end_date')}
                            >
                                <DatePicker
                                    allowClear
                                    className="clearable"
                                    disabledDate={(current): boolean =>
                                        (startDateValue && current < startDateValue) || false
                                    }
                                    style={{ width: '100%' }}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>{t('pricing')}</Title>
                    <Row gutter={formGutter}>
                        {unitType === UnitType.Communal && (
                            <Col span={12}>
                                <ValidatedFormItem
                                    name="communalbasePrice"
                                    errors={errors}
                                    label={t('Unit.communal_area_base_price')}
                                >
                                    <Input disabled />
                                </ValidatedFormItem>
                            </Col>
                        )}

                        {unitType === UnitType.Other && (
                            <Col span={12} className="input-with-tip-container">
                                <ValidatedFormItem
                                    name="calculatedPrice"
                                    errors={errors}
                                    label={t('calculated_price')}
                                    className="input-with-tip"
                                >
                                    <Input disabled />
                                </ValidatedFormItem>
                                <small className="input-tip">
                                    {t('Unit.calculated_price_tip')}
                                </small>
                            </Col>
                        )}

                        <Col span={12}>
                            <ValidatedFormItem
                                name="priceMultiplier"
                                errors={errors}
                                label={t('Unit.price_multiplier')}
                            >
                                <NumberFormat
                                    allowNegative={false}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    customInput={(props: any) => <Input {...props} />}
                                    isAllowed={(value: NumberFormatValues): boolean =>
                                        value.floatValue === undefined ||
                                        (value.floatValue !== undefined &&
                                            value.floatValue >= 0 &&
                                            value.floatValue <= HUNDRED)
                                    }
                                    onChange={onPriceAffectingInputChange}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="priceOverride"
                                errors={errors}
                                label={t('price_override')}
                            >
                                <NumberInput
                                    min={0}
                                    type={'number'}
                                    step={0.01}
                                    disabled={!priceOverrideCheckboxState}
                                    onChange={onPriceAffectingInputChange}
                                    addonAfter={
                                        <Checkbox
                                            onChange={(value): void => {
                                                setPriceOverrideCheckboxState((old) => !old);
                                                if (!value.target.checked) {
                                                    form.setFieldsValue({
                                                        priceOverride: 0,
                                                    });
                                                }
                                                onPriceAffectingInputChange();
                                            }}
                                            checked={priceOverrideCheckboxState}
                                        />
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="marketPrice"
                                errors={errors}
                                label={t('Unit.market_price')}
                            >
                                <Input disabled />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="sixMonthPrice"
                                errors={errors}
                                label={t('Unit.six_month_price')}
                            >
                                <NumberInput min={0} type={'number'} step={0.01} />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="twelveMonthPrice"
                                errors={errors}
                                label={t('Unit.twelve_month_price')}
                            >
                                <NumberInput min={0} type={'number'} step={0.01} />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>{t('Unit.data_jacks')}</Title>
                    <Form.List name="dataJacks">
                        {(fields, { add, remove }): ReactNode => (
                            <>
                                {fields.map((field, index) => (
                                    <Row gutter={formGutter} key={field.fieldKey}>
                                        <Col span={12}>
                                            <ValidatedFormItem
                                                name={[index, 'name']}
                                                errors={errors}
                                                label={t('name')}
                                            >
                                                <Input
                                                    addonAfter={
                                                        <span
                                                            className="garbage-container"
                                                            onClick={(): void => {
                                                                remove(index);
                                                            }}
                                                        >
                                                            <Delete fill={theme['primary-color']} />
                                                        </span>
                                                    }
                                                />
                                            </ValidatedFormItem>
                                        </Col>
                                    </Row>
                                ))}

                                <Row gutter={formGutter}>
                                    <Col span={24} className="dynamic-list-button-container">
                                        <Button
                                            className={
                                                'ant-btn-primary ant-btn-circle ant-btn-lg ant-btn-icon-only'
                                            }
                                            onClick={(): void => add()}
                                        >
                                            <Icon iconName="Add" />
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form.List>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(CreateUnitModal);
