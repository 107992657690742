// GENERATED FILE - DO NOT MODIFY
import { CommunityEventDto } from 'Api/Features/CommunityEvents/Dtos/CommunityEventDto';
import { CreateCommunityEventRequestDto } from 'Api/Features/CommunityEvents/Dtos/CreateCommunityEventRequestDto';
import { GetCommunityEventsRequestDto } from 'Api/Features/CommunityEvents/Dtos/GetCommunityEventsRequestDto';
import { GetCommunityEventsResponseDto } from 'Api/Features/CommunityEvents/Dtos/GetCommunityEventsResponseDto';
import { UpdateCommunityEventRequestDto } from 'Api/Features/CommunityEvents/Dtos/UpdateCommunityEventRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class CommunityEventProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getCommunityEvent(communityEventId: string): Promise<CommunityEventDto | null> {
        const uri = this.buildUri(
            "/communityevents/{communityEventId}",
            { communityEventId: communityEventId },
            null
        );

        const data: CommunityEventDto | null = await this.httpClient.get<CommunityEventDto | null>(uri);
        return data;
    }

    public async getCommunityEvents(request: GetCommunityEventsRequestDto | null): Promise<GetCommunityEventsResponseDto | null> {
        const uri = this.buildUri(
            "/communityevents",
            null,
            { ...request || undefined }
        );

        const data: GetCommunityEventsResponseDto | null = await this.httpClient.get<GetCommunityEventsResponseDto | null>(uri);
        return data;
    }

    public async createCommunityEvent(request: CreateCommunityEventRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/communityevents",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateCommunityEventRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateCommunityEvent(communityEventId: string, request: UpdateCommunityEventRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/communityevents/{communityEventId}",
            { communityEventId: communityEventId },
            null
        );

        await this.httpClient.patch<UpdateCommunityEventRequestDto | null, void>(uri, request);
    }

    public async deleteCommunityEvent(communityEventId: string): Promise<void> {
        const uri = this.buildUri(
            "/communityevents/{communityEventId}",
            { communityEventId: communityEventId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}