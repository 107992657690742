import { Col, Form, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { BookingDto } from 'Api/Features/Amenities/Dtos/BookingDto';
import { CancelBookingRequestDto } from 'Api/Features/Amenities/Dtos/CancelBookingRequestDto';
import timezones from 'appcom-timezones';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import ActivityLogTabs, { ActivityLogTab } from 'Components/activity-log-tabs/activity-log-tabs';
import { ConferenceRoom, Location, Padlock, Refresh } from 'Components/icons';
import Icon from 'Components/icons/icon';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import { useService, useStores } from 'Hooks';
import { DAY_MONTH_DATE_YEAR_DISPLAY_FORMAT, DISPLAY_TIME_12H_FORMAT } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ReactNode } from 'react-router/node_modules/@types/react';
import { BookingService } from 'Services/BookingService';
import { theme } from 'variant';
import './index.less';
import EditNoteModal, { NoteEntityTypeEnum } from 'Components/edit-note-modal/edit-note-modal';
import { ContactContext } from '../..';
import ContactsHeader from 'Components/contacts-header';

const ContactBookingHistoryDetails: FunctionComponent = () => {
    const contactContext = useContext(ContactContext);
    const { id, bookingId } = useParams<{ id: string; bookingId: string }>();
    const { globalLoadingStore, toastStore, confirmationModalStore, locationStore } = useStores();
    const bookingService = useService(BookingService);
    const [loading, setLoading] = useState(false);
    const [booking, setBooking] = useState<BookingDto | null>();
    const { t } = useTranslation();
    const history = useHistory();
    const [form] = Form.useForm();
    const [editNoteModalOpen, setEditNoteModalOpen] = useState(false);

    const [conferenceRoom, setConferenceRoom] = useState<SimpleListItemProps[] | null>(null);
    const [bookingPrivacy, setBookingPrivacy] = useState<SimpleListItemProps[] | null>(null);
    const [location, setLocation] = useState<SimpleListItemProps[] | null>(null);
    const [summary, setSummary] = useState<ReactNode>(null);
    const [note, setNote] = useState<string | null | undefined>();

    const setSimpleListsData = useCallback(
        (booking: BookingDto) => {
            setConferenceRoom([
                {
                    title: booking?.amenity?.name || '',
                    avatar: (
                        <ImageWithPlaceholder
                            width="32"
                            height="32"
                            defaultImg={<ConferenceRoom />}
                            imgSrc={booking.amenity?.imageUrl}
                        />
                    ),
                    description: `${booking.amenity?.numberOfSeats ?? 0} seats`,
                },
            ]);

            const location = locationStore.locations.find(
                (campus) => campus.id === booking.amenity?.campusId
            );
            setLocation([
                {
                    title: location?.name ?? '',
                    avatar: (
                        <ImageWithPlaceholder
                            width="32"
                            height="32"
                            defaultImg={<Location />}
                            imgSrc={location?.mainImageUrl}
                        />
                    ),
                },
            ]);

            setBookingPrivacy([
                {
                    title: booking.isPrivate
                        ? t('Booking.booking_is_private')
                        : t('Booking.booking_is_public'),
                    avatar: <Padlock fill={theme['primary-color']} />,
                },
            ]);

            setSummary(
                <div className="summary-container">
                    <div className="booking-info-container">
                            <div className="date">
                                {moment(booking.periodStart).format(
                                    DAY_MONTH_DATE_YEAR_DISPLAY_FORMAT
                                )}
                            </div>
                            <div className="time">
                                {`${moment(booking.periodStart).format(
                                    DISPLAY_TIME_12H_FORMAT
                                )} - ${moment(booking.periodEnd).format(
                                    DISPLAY_TIME_12H_FORMAT
                                )} (${
                                    timezones.find((tz) => tz.id === location?.timeZone)
                                        ?.description
                                })`}
                            </div>
                            <div className="cost">{`${booking.cost ?? 0} credits`}</div>
                        </div>
                    {booking.recurrence && (
                        <div className="recurrence-container">
                            <div className="icon">
                                <Refresh fill={theme['primary-color']} width={24} height={24} />
                            </div>
                            <div>
                                <div className="title">{t('Booking.recurrent_booking')}</div>
                                <div className="name">{booking.recurrence.name}</div>
                                <div className="occurrences">{`for ${booking.recurrence.occurrences} occurences`}</div>
                            </div>
                        </div>
                    )}
                </div>
            );
        },
        [t]
    );

    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const booking = await bookingService.getBooking(bookingId);
            if (booking) {
                setBooking(booking);
                setSimpleListsData(booking);
                setNote(booking.note);
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [bookingId]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `contacts`,
            nameKey: 'Contacts',
        },
        {
            path: `${id}/dashboard`,
            nameKey: `${contactContext?.firstName} ${contactContext?.lastName}` ,
        },
        {
            path: `booking-history`,
            nameKey: 'Contact.contacts_booking_history',
        },
        {
            path: `${bookingId}`,
            nameKey: booking?.title ?? 'booking',
        },
    ];

    const headerDetails = {
        iconName: 'Bookmark',
        title: booking?.title ?? t('booking'),
    };

    const cancelWithRefund = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="ConferenceRoom" />,
                title: t('Booking.cancel_this_booking'),
                message: t('Booking.cancel_with_refund_explanation'),
                positiveText: t('Booking.cancel_and_refund'),
                negativeText: t('no'),
            }))
        )
            return;

        try {
            const request: CancelBookingRequestDto = {
                bookingId: bookingId,
                refundCredits: true,
            };
            await bookingService.cancelBooking(request);

            toastStore.toast({
                type: 'success',
                message: t('Booking.booking_canceled_successfully'),
            });
            history.push(`/contacts/${id}/dashboard/booking-history`);
        } catch (e) {
            if (e.response?.data?.error === 'E011003') {
                toastStore.toast({
                    type: 'error',
                    message: e.response.data.error_description,
                });
            } else if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const cancelWithoutRefund = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="ConferenceRoom" />,
                title: t('Booking.cancel_this_booking'),
                message: t('Booking.cancel_no_refund_explanation'),
                positiveText: t('Booking.cancel_no_refund'),
                negativeText: t('no'),
            }))
        )
            return;

        try {
            globalLoadingStore.addLoading();
            const request: CancelBookingRequestDto = {
                bookingId: bookingId,
                refundCredits: false,
            };
            await bookingService.cancelBooking(request);

            toastStore.toast({
                type: 'success',
                message: t('Booking.booking_canceled_successfully'),
            });
            history.push(`/contacts/${id}/dashboard/booking-history`);
        } catch (e) {
            if (e.response?.data?.error === 'E011003') {
                toastStore.toast({
                    type: 'error',
                    message: e.response.data.error_description,
                });
            } else if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const menuOptions: ActionMenuOption[] = [
        {
            key: 'cancelRefund',
            title: t('Booking.cancel_with_refund'),
            action: async () => await cancelWithRefund(),
        },
        {
            key: 'cancelNoRefund',
            title: t('Booking.cancel_without_refund'),
            action: async () => await cancelWithoutRefund(),
        },
    ];

    return (
        <div className="ContactBookingHistoryDetails">
            <ContactsHeader
                routes={breadcrumbs}
                headerData={headerDetails}
                loading={loading}
                action={
                    moment(booking?.periodStart).isAfter(moment()) && (
                        <ActionMenu options={menuOptions} type="primary" trigger="click" />
                    )
                }
                note={
                    <ActivityLogTabs
                        form={form}
                        tabs={[ActivityLogTab.notes]}
                        onEditNoteClick={(): void => setEditNoteModalOpen(true)}
                        note={note}
                    />
                }
            />

            <Content>
                <Row gutter={40}>
                    <Col span={12}>
                        {summary && (
                            <Row>
                                <Col span={24}>
                                    <SimpleList title={t('summary')} >{summary}</SimpleList>
                                </Col>
                            </Row>
                        )}

                        {location && location.length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <SimpleList title={t('location')} data={location}/>
                                </Col>
                            </Row>
                        )}
                    </Col>

                    <Col span={12}>
                        {conferenceRoom && conferenceRoom.length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <SimpleList
                                        title={t('Booking.conference_room')}
                                        data={conferenceRoom}
                                    />
                                </Col>
                            </Row>
                        )}

                        {bookingPrivacy && bookingPrivacy.length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <SimpleList
                                        title={t('Booking.booking_privacy')}
                                        data={bookingPrivacy}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </Content>

            {booking && editNoteModalOpen && (
                <EditNoteModal
                    visible={editNoteModalOpen}
                    onComplete={(success: boolean) => {
                        if (success) fetch();
                        setEditNoteModalOpen(false);
                    }}
                    entityId={bookingId}
                    entityType={NoteEntityTypeEnum.Booking}
                    note={note}
                    booking={booking}
                />
            )}
        </div>
    );
};

export default ContactBookingHistoryDetails;
