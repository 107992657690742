import { AchPaymentMethodDto } from 'Api/Features/PaymentMethods/Dtos/AchPaymentMethodDto';
import { CreditCardPaymentMethodDto } from 'Api/Features/PaymentMethods/Dtos/CreditCardPaymentMethodDto';
import { PaymentMethodHolderTypeDto } from 'Api/Features/PaymentMethods/Dtos/PaymentMethodHolderTypeDto';
import { PaymentMethodProxy } from 'Api/Features/PaymentMethods/PaymentMethodProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(PaymentMethodProxy)
export class PaymentMethodService extends ApiService {
    constructor(private readonly paymentMethodProxy: PaymentMethodProxy) {
        super();
    }

    public async getHolderPaymentMethod(
        holderType: PaymentMethodHolderTypeDto,
        holderId: string | null
    ): Promise<AchPaymentMethodDto | CreditCardPaymentMethodDto | null> {
        const data = await this.paymentMethodProxy.getHolderPaymentMethod(holderType, holderId);
        return data;
    }
}
