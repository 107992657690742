import { Form } from 'antd';
import { Store } from 'antd/es/form/interface';
import { CreateMeetingRequestDto } from 'Api/Features/Meetings/Dtos/CreateMeetingRequestDto';
import { CreateReminderRequestDto } from 'Api/Features/Reminders/Dtos/CreateReminderRequestDto';
import { SalesActivityDto } from 'Api/Features/SalesActivities/Dtos/SalesActivityDto';
import { SalesActivityTypeDto } from 'Api/Features/SalesActivities/Dtos/SalesActivityTypeDto';
import { SubscriptionEventDto } from 'Api/Features/SubscriptionEvents/Dtos/SubscriptionEventDto';
import { SubscriptionEventTypeDto } from 'Api/Features/SubscriptionEvents/Dtos/SubscriptionEventTypeDto';
import { CreateSubscriptionCommentRequestDto } from 'Api/Features/Subscriptions/Dtos/CreateSubscriptionCommentRequestDto';
import { CreateTaskRequestDto } from 'Api/Features/Tasks/Dtos/CreateTaskRequestDto';
import { ActivityLogTab } from 'Components/activity-log-tabs/activity-log-tabs';
import ActivityLogWithTabs from 'Components/activity-log-with-tabs/activity-log-with-tabs';
import { ActivityLogItem, ActivityLogUpcomingItem } from 'Components/activity-log/activity-log';
import { useFormValidation, useService, useStores } from 'Hooks';
import { AddActivityType } from 'Models/AddActivityType/AddActivityType';
import {
    DATETIME_FORMAT,
    DISPLAY_DATETIME_12H_WRITTEN_FORMAT,
    DISPLAY_DATE_FORMAT,
    DISPLAY_TIME_12H_FORMAT,
    MOMENT_PARSING_FORMAT,
    MOMENT_TIME_FORMAT,
    ONBOARDING_CATEGORY_ID_MEMBER_ACCESS,
    ONBOARDING_CATEGORY_ID_MEMBER_INVITATION,
    ONBOARDING_CATEGORY_ID_SPACE,
} from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CreateMeetingSchema, CreateReminderSchema } from 'Schemas';
import { CreateCommentSchema } from 'Schemas/CreateCommentSchema';
import { CreateTaskSchema } from 'Schemas/CreateTaskSchema';
import { MeetingService } from 'Services/MeetingService';
import { ReminderService } from 'Services/ReminderService';
import { SubscriptionService } from 'Services/SubscriptionService';
import { TaskService } from 'Services/TaskService';
import { displayFormatedDateTime } from 'Utils/TimeUtils';

interface SubscriptionActivityLogProps {
    activities?: SubscriptionEventDto[];
    onAddedActivityComplete: () => void;
    upcomingActivities?: (SalesActivityDto | null)[];
    pastActivities?: (SalesActivityDto | null)[];
    onEditMeetingClick: (id: string) => void;
    onEditReminderClick: (id: string) => void;
    onEditTaskClick: (id: string) => void;
    onDeleteMeetingClick: (id: string) => void;
    onDeleteReminderClick: (id: string) => void;
    onDeleteTaskClick: (id: string) => void;
    onViewFilesClick: () => void;
    note?: string | null;
    onEditNoteClick?: () => void;
}

const SubscriptionActivityLog: FunctionComponent<SubscriptionActivityLogProps> = ({
    activities,
    onAddedActivityComplete,
    upcomingActivities,
    pastActivities,
    onEditMeetingClick,
    onEditReminderClick,
    onDeleteTaskClick,
    onDeleteMeetingClick,
    onDeleteReminderClick,
    onEditTaskClick,
    onViewFilesClick,
    note,
    onEditNoteClick,
    children,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [meetingErrors, validateMeetingForm, resetMeetingErrors] = useFormValidation(
        CreateMeetingSchema,
        form
    );
    const [reminderErrors, validateReminderForm, resetReminderErrors] = useFormValidation(
        CreateReminderSchema,
        form
    );
    const [taskErrors, validateTaskForm, resetTaskErrors] = useFormValidation(
        CreateTaskSchema,
        form
    );
    const [commentErrors, validateCommentForm, resetCommentErrors] = useFormValidation(
        CreateCommentSchema,
        form
    );
    const { globalLoadingStore, toastStore } = useStores();
    const [selectedAddActivityType, setSelectedAddActivityType] = useState<AddActivityType>(
        AddActivityType.Comment
    );
    const subscriptionService = useService(SubscriptionService);
    const meetingService = useService(MeetingService);
    const reminderService = useService(ReminderService);
    const taskService = useService(TaskService);
    const { subscriptionId } = useParams<{ subscriptionId: string }>();

    //#Region event handlers
    const onAddActivityTypeChange = (value: AddActivityType): void => {
        setSelectedAddActivityType(value);
        resetTaskErrors();
        resetCommentErrors();
    };
    //#EndRegion event handlers

    //#Region Submit
    const submit = async (values: Store): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            let activityType = '';
            const validateObject = {
                note: values.note,
            };

            if (selectedAddActivityType === AddActivityType.Comment) {
                activityType = 'comment';

                if (!(await validateCommentForm(validateObject))) return;
                await subscriptionService.createSubscriptionComment(subscriptionId, {
                    comment: values.note,
                } as CreateSubscriptionCommentRequestDto);
            } else if (selectedAddActivityType === AddActivityType.Task) {
                const validateTask = {
                    date: values.date ?? undefined,
                    note: values.note,
                };
                activityType = 'task';

                if (!(await validateTaskForm(validateTask))) return;
                await taskService.createTask({
                    date: values.date,
                    subscriptionId: subscriptionId,
                    note: values.note,
                } as CreateTaskRequestDto);
            } else if (selectedAddActivityType === AddActivityType.Meeting) {
                const validateMeeting = {
                    date: values.date ?? undefined,
                    hours: values.hours ?? undefined,
                };

                if (!(await validateMeetingForm(validateMeeting))) return;
                const meeting: CreateMeetingRequestDto = {
                    startTime: moment(
                        values.date.format(MOMENT_PARSING_FORMAT) +
                            values.hours[0].format(MOMENT_TIME_FORMAT),
                        DATETIME_FORMAT
                    ).format(),
                    endTime: moment(
                        values.date.format(MOMENT_PARSING_FORMAT) +
                            values.hours[1].format(MOMENT_TIME_FORMAT),
                        DATETIME_FORMAT
                    ).format(),
                    subscriptionId: subscriptionId,
                    note: values.note,
                };
                activityType = 'meeting';
                await meetingService.createMeeting(meeting);
            } else if (selectedAddActivityType === AddActivityType.Reminder) {
                const validateReminder = {
                    date: values.date ?? undefined,
                };

                if (!(await validateReminderForm(validateReminder))) return;

                const reminder: CreateReminderRequestDto = {
                    date: values.date,
                    subscriptionId: subscriptionId,
                    note: values.note,
                };
                activityType = 'reminder';
                await reminderService.createReminder(reminder);
            }

            onAddedActivityComplete();
            resetMeetingErrors();
            resetReminderErrors();
            resetTaskErrors();
            resetCommentErrors();
            setSelectedAddActivityType(AddActivityType.Comment);
            form.resetFields();

            toastStore.toast({
                type: 'success',
                messageKey: `Lead.${activityType}_added_success`,
            });
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#End Region Submit

    const submitButtonLabel = (selectedAddActivityType: AddActivityType): string => {
        switch (selectedAddActivityType) {
            case AddActivityType.Comment:
                return t('Lead.add_comment');
            case AddActivityType.Meeting:
                return t('Lead.add_meeting');
            case AddActivityType.Task:
                return t('Lead.add_task');
            case AddActivityType.Reminder:
                return t('Lead.add_reminder');
            default:
                return '';
        }
    };

    const displayDatePicker =
        selectedAddActivityType === AddActivityType.Meeting ||
        selectedAddActivityType === AddActivityType.Reminder ||
        selectedAddActivityType === AddActivityType.Task;

    const getUpcomingActivities = (
        upcomingActivities: (SalesActivityDto | null)[] | undefined
    ): (ActivityLogUpcomingItem | null)[] => {
        let upcomingActivitiesList: (ActivityLogUpcomingItem | null)[] = [];

        if (upcomingActivities) {
            upcomingActivitiesList = upcomingActivities?.map((event: SalesActivityDto | null) => {
                let eventInfo = null;

                if (event) {
                    switch (event.type) {
                        case SalesActivityTypeDto.Meeting:
                            eventInfo = {
                                icon: { name: 'Coffee', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Opportunity.opportunity_event_${SubscriptionEventTypeDto.MeetingScheduled}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.meeting?.startTime)
                                            .format(DISPLAY_DATETIME_12H_WRITTEN_FORMAT)} - ${moment
                                            .utc(event.meeting?.endTime)
                                            .format(DISPLAY_TIME_12H_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditMeetingClick,
                                onDelete: onDeleteMeetingClick,
                                id: event.meeting?.id ?? '',
                                note: event.meeting?.note || undefined,
                            };
                            break;
                        case SalesActivityTypeDto.Reminder:
                            eventInfo = {
                                icon: { name: 'Bell', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Opportunity.opportunity_event_${SubscriptionEventTypeDto.ReminderScheduled}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.reminder?.date)
                                            .format(DISPLAY_DATETIME_12H_WRITTEN_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditReminderClick,
                                onDelete: onDeleteReminderClick,
                                id: event.reminder?.id ?? '',
                                note: event.reminder?.note || undefined,
                            };
                            break;
                        case SalesActivityTypeDto.Task:
                            eventInfo = {
                                icon: { name: 'Tick', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Lead.event_${SubscriptionEventTypeDto.TaskAdded}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.task?.date)
                                            .format(DISPLAY_DATE_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditTaskClick,
                                onDelete: onDeleteTaskClick,
                                id: event.task?.id ?? '',
                                note: event.task?.note,
                            };
                            break;
                    }
                }

                return eventInfo;
            });
        }

        return upcomingActivitiesList;
    };

    const getPastActivities = (
        pastActivities: (SalesActivityDto | null)[] | undefined
    ): (ActivityLogUpcomingItem | null)[] => {
        let pastActivitiesList: (ActivityLogUpcomingItem | null)[] = [];

        if (pastActivities) {
            pastActivitiesList = pastActivities?.map((event: SalesActivityDto | null) => {
                let eventInfo = null;

                if (event) {
                    switch (event.type) {
                        case SalesActivityTypeDto.Meeting:
                            eventInfo = {
                                icon: { name: 'Coffee', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Opportunity.opportunity_event_${SubscriptionEventTypeDto.MeetingScheduled}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.meeting?.startTime)
                                            .format(DISPLAY_DATETIME_12H_WRITTEN_FORMAT)} - ${moment
                                            .utc(event.meeting?.endTime)
                                            .format(DISPLAY_TIME_12H_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditMeetingClick,
                                onDelete: onDeleteMeetingClick,
                                id: event.meeting?.id ?? '',
                                note: event.meeting?.note,
                            };
                            break;
                        case SalesActivityTypeDto.Reminder:
                            eventInfo = {
                                icon: { name: 'Bell', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Opportunity.opportunity_event_${SubscriptionEventTypeDto.ReminderScheduled}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.reminder?.date)
                                            .format(DISPLAY_DATETIME_12H_WRITTEN_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditReminderClick,
                                onDelete: onDeleteReminderClick,
                                id: event.reminder?.id ?? '',
                                note: event.reminder?.note,
                            };
                            break;
                        case SalesActivityTypeDto.Task:
                            eventInfo = {
                                icon: { name: 'Tick', className: 'primary-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Opportunity.opportunity_event_${SubscriptionEventTypeDto.TaskAdded}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.task?.date)
                                            .format(DISPLAY_DATE_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditTaskClick,
                                onDelete: onDeleteTaskClick,
                                id: event.task?.id ?? '',
                                note: event.task?.note ?? undefined,
                            };
                            break;
                    }
                }
                return eventInfo;
            });
        }

        return pastActivitiesList;
    };

    const getHistoryList = (
        history: SubscriptionEventDto[] | undefined
    ): (ActivityLogItem | null)[] => {
        let historyList: (ActivityLogItem | null)[] = [];

        if (history) {
            historyList = history.map((event: SubscriptionEventDto) => {
                let eventInfo = null;

                const creator =
                    event.account === null || event.account?.id === null
                        ? 'Unknown'
                        : `${event.account?.firstName} ${event.account?.lastName}`;

                const behalf = event.onBehalfOfAccount
                    ? ` ${t('Opportunity.opportunity_proposal_on_behalf', {
                          param1: `${event.onBehalfOfAccount.firstName} ${event.onBehalfOfAccount.lastName}`,
                      })}`
                    : event.onBehalfOf
                    ? ` ${t('Opportunity.opportunity_proposal_on_behalf', {
                          param1: `${event.onBehalfOf}`,
                      })}`
                    : null;

                eventInfo = {
                    id: event.id ?? '',
                    icon: { name: '', bold: undefined },
                    title: [
                        { text: creator, bold: true },
                        {
                            text: t(`Subscription.subscription_event_text_spacer_${event.type}`),
                        },
                        { text: t(`Subscription.subscription_event_${event.type}`), bold: true },
                    ],
                    date: displayFormatedDateTime(event.date),
                    note: event.note,
                    creator: creator,
                    reason: event.reason,
                };

                switch (event.type) {
                    case SubscriptionEventTypeDto.Proposal:
                    case SubscriptionEventTypeDto.ModificationProposal:
                        eventInfo = {
                            ...eventInfo,
                            icon: { name: 'QuestionMarkLined', primary: true },
                        };
                        break;
                    case SubscriptionEventTypeDto.Request:
                    case SubscriptionEventTypeDto.ModificationRequest:
                        eventInfo = {
                            ...eventInfo,
                            icon: { name: 'Request', primary: true },
                        };
                        break;
                    case SubscriptionEventTypeDto.ProposalAccepted:
                    case SubscriptionEventTypeDto.ModificationProposalAccepted:
                        eventInfo = {
                            ...eventInfo,
                            icon: { name: 'TickLined', primary: true },
                        };
                        break;
                    case SubscriptionEventTypeDto.ProposalAcceptedByManager:
                    case SubscriptionEventTypeDto.ModificationProposalAcceptedByManager:
                        eventInfo = {
                            ...eventInfo,
                            icon: { name: 'TickLined', primary: true },
                            title: [
                                { text: creator, bold: true },
                                {
                                    text: t(
                                        `Subscription.subscription_event_text_spacer_${event.type}`
                                    ),
                                },
                                {
                                    text: t(`Subscription.subscription_event_${event.type}`),
                                    bold: true,
                                },
                                {
                                    text: behalf,
                                },
                            ],
                        };
                        break;
                    case SubscriptionEventTypeDto.ProposalRejected:
                    case SubscriptionEventTypeDto.ModificationProposalRejected:
                    case SubscriptionEventTypeDto.RequestRejected:
                    case SubscriptionEventTypeDto.ModificationRequestRejected:
                        eventInfo = {
                            ...eventInfo,
                            icon: { name: 'CloseLined', primary: true },
                        };
                        break;
                    case SubscriptionEventTypeDto.ProposalRejectedByManager:
                    case SubscriptionEventTypeDto.ModificationProposalRejectedByManager:
                        eventInfo = {
                            ...eventInfo,
                            icon: { name: 'CloseLined', primary: true },
                            title: [
                                { text: creator, bold: true },
                                {
                                    text: t(
                                        `Subscription.subscription_event_text_spacer_${event.type}`
                                    ),
                                },
                                {
                                    text: t(`Subscription.subscription_event_${event.type}`),
                                    bold: true,
                                },
                                {
                                    text: behalf,
                                },
                            ],
                        };
                        break;
                    case SubscriptionEventTypeDto.Started:
                    case SubscriptionEventTypeDto.Ended:
                        eventInfo = {
                            ...eventInfo,
                            icon: { name: 'Subscription', primary: true },
                            title: [
                                { text: t('subscription'), bold: true },
                                { text: t(`Subscription.subscription_event_${event.type}`) },
                            ],
                        };
                        break;
                    case SubscriptionEventTypeDto.Canceled:
                        eventInfo = {
                            ...eventInfo,
                            icon: { name: 'Trash', primary: true },
                        };
                        break;
                    case SubscriptionEventTypeDto.DayPass:
                        eventInfo = {
                            ...eventInfo,
                            icon: { name: 'DayPass', primary: true },
                        };
                        break;
                    case SubscriptionEventTypeDto.UnitAdded:
                    case SubscriptionEventTypeDto.UnitRemoved:
                        eventInfo = {
                            ...eventInfo,
                            icon: {
                                name:
                                    event.type === SubscriptionEventTypeDto.UnitAdded
                                        ? 'Units'
                                        : 'Trash',
                                primary: true,
                            },
                            title: [
                                { text: creator, bold: true },
                                {
                                    text: t(
                                        `Subscription.subscription_event_text_spacer_${event.type}`
                                    ),
                                },
                                { text: `${event.unit?.name}`, bold: true },
                                {
                                    text: t(
                                        event.type === SubscriptionEventTypeDto.UnitAdded
                                            ? 'Subscription.unit_to'
                                            : 'Subscription.unit_from'
                                    ),
                                },
                            ],
                        };
                        break;

                    case SubscriptionEventTypeDto.OnboardingCategoryCompleted:
                        eventInfo = {
                            ...eventInfo,
                            icon: {
                                name:
                                    event.onboardingCategory?.id === ONBOARDING_CATEGORY_ID_SPACE
                                        ? 'Units'
                                        : event.onboardingCategory?.id ===
                                          ONBOARDING_CATEGORY_ID_MEMBER_ACCESS
                                        ? 'Guest'
                                        : event.onboardingCategory?.id ===
                                          ONBOARDING_CATEGORY_ID_MEMBER_INVITATION
                                        ? 'User'
                                        : '',
                                primary: true,
                            },
                            title: [
                                { text: creator, bold: true },
                                {
                                    text: t(
                                        `Subscription.subscription_event_text_spacer_OnboardingCategoryCompleted`
                                    ),
                                },
                                {
                                    text: event.onboardingCategory?.name?.toLowerCase(),
                                    bold: true,
                                },
                                {
                                    text: 'as',
                                },
                                {
                                    text: 'completed',
                                    bold: true,
                                },
                                {
                                    text: event.onboardingCategory?.wontDo
                                        ? t('Subscription.task_wont_do')
                                        : '',
                                    bold: true,
                                },
                            ],
                            viewFiles: event.onboardingCategory?.hasDocuments,
                            onViewFilesClick: onViewFilesClick,
                        };
                        break;
                    case SubscriptionEventTypeDto.CommentAdded:
                        eventInfo = {
                            ...eventInfo,
                            icon: { name: 'File' },
                        };
                        break;
                    case SubscriptionEventTypeDto.TaskAdded:
                    case SubscriptionEventTypeDto.TaskCancelled:
                    case SubscriptionEventTypeDto.TaskUpdated:
                        eventInfo = {
                            ...eventInfo,
                            icon: { name: 'Tick' },
                            note: event.task,
                            title: [
                                { text: creator, bold: true },
                                {
                                    text: t(
                                        `Subscription.subscription_event_text_spacer_${event.type}`
                                    ),
                                },
                                {
                                    text: t(`Subscription.subscription_event_${event.type}`),
                                    bold: true,
                                },
                                {
                                    text: `${t('Activity.add_activity_for')} ${moment
                                        .utc(event.taskDate)
                                        .format(DISPLAY_DATE_FORMAT)}`,
                                },
                            ],
                        };
                        break;
                    case SubscriptionEventTypeDto.MeetingScheduled:
                    case SubscriptionEventTypeDto.MeetingUpdated:
                    case SubscriptionEventTypeDto.MeetingCancelled:
                        eventInfo = {
                            ...eventInfo,
                            icon: { name: 'Coffee' },
                            title: [
                                { text: creator, bold: true },
                                {
                                    text: t(
                                        `Subscription.subscription_event_text_spacer_${event.type}`
                                    ),
                                },
                                {
                                    text: t(`Subscription.subscription_event_${event.type}`),
                                    bold: true,
                                },
                                {
                                    text: t('Opportunity.tour_for', {
                                        param1: moment
                                            .utc(event.meetingStartTime)
                                            .format(DISPLAY_DATETIME_12H_WRITTEN_FORMAT),
                                        param2: moment
                                            .utc(event.meetingEndTime)
                                            .format(DISPLAY_TIME_12H_FORMAT),
                                    }),
                                },
                            ],
                        };
                        break;
                    case SubscriptionEventTypeDto.ReminderScheduled:
                    case SubscriptionEventTypeDto.ReminderUpdated:
                    case SubscriptionEventTypeDto.ReminderCancelled:
                        eventInfo = {
                            ...eventInfo,
                            icon: { name: 'Bell' },
                            title: [
                                { text: creator, bold: true },
                                {
                                    text: t(
                                        `Subscription.subscription_event_text_spacer_${event.type}`
                                    ),
                                },
                                {
                                    text: t(`Subscription.subscription_event_${event.type}`),
                                    bold: true,
                                },
                                {
                                    text: `${t('Activity.add_activity_for')} ${moment
                                        .utc(event.reminderDate)
                                        .format(DISPLAY_DATETIME_12H_WRITTEN_FORMAT)}`,
                                },
                            ],
                        };
                        break;
                    case SubscriptionEventTypeDto.AgreementSigned:
                        eventInfo = {
                            ...eventInfo,
                            title: [
                                {
                                    text: creator,
                                    bold: true,
                                },
                                {
                                    text: t(
                                        `Subscription.subscription_event_text_spacer_${event.type}`
                                    ),
                                },
                                {
                                    text: t(`Subscription.subscription_event_${event.type}`),
                                    bold: true,
                                },
                                {
                                    text: t(
                                        `Subscription.subscription_event_text_spacer2_${event.type}`
                                    ),
                                },
                                {
                                    text: t(`Opportunity.opportunity_status_${event.type}`),
                                    bold: true,
                                },
                            ],
                            icon: { name: 'PencilLined', primary: true },
                        };
                        break;
                    case SubscriptionEventTypeDto.ReferenceNumberModified:
                        eventInfo = {
                            ...eventInfo,
                            title: [
                                {
                                    text: creator,
                                    bold: true,
                                },
                                {
                                    text: t(
                                        `Subscription.subscription_event_text_spacer_${event.type}`
                                    ),
                                },
                                {
                                    text: t(`Subscription.subscription_event_${event.type}`),
                                    bold: true,
                                },
                                {
                                    text: event.previousReferenceNumber
                                        ? `from "${event.previousReferenceNumber}" to "${event.newReferenceNumber && event.newReferenceNumber}"`
                                        : `to "${event.newReferenceNumber}"`,
                                },
                            ],
                            icon: { name: 'PencilLined', primary: true },
                        };
                        break;
                    case SubscriptionEventTypeDto.DiscountsModified:
                        eventInfo = { ...eventInfo, icon: { name: 'Discount', primary: true } };
                        break;
                    case SubscriptionEventTypeDto.SecurityDepositRetained:
                        eventInfo = {
                            ...eventInfo,
                            title: [
                                {
                                    text: creator,
                                    bold: true,
                                },
                                {
                                    text: t(
                                        `Subscription.subscription_event_text_spacer_${event.type}`
                                    ),
                                },
                                {
                                    text: t(`Subscription.subscription_event_${event.type}`),
                                    bold: true,
                                },
                                {
                                    text: 'as retained',
                                },
                            ],
                            icon: { name: 'SecurityDeposit', primary: true },
                        };
                        break;
                    case SubscriptionEventTypeDto.SecurityDepositRefunded:
                        eventInfo = {
                            ...eventInfo,
                            title: [
                                {
                                    text: creator,
                                    bold: true,
                                },
                                {
                                    text: t(
                                        `Subscription.subscription_event_text_spacer_${event.type}`
                                    ),
                                },
                                {
                                    text: t(`Subscription.subscription_event_${event.type}`),
                                    bold: true,
                                },
                                {
                                    text: 'as refunded',
                                },
                            ],
                            icon: { name: 'SecurityDeposit', primary: true },
                        };
                }
                return eventInfo;
            });
        }

        return historyList;
    };

    return (
        <ActivityLogWithTabs
            onFinish={submit}
            form={form}
            selectErrors={meetingErrors}
            selectdefaultValue={AddActivityType.Comment}
            selectOnChange={onAddActivityTypeChange}
            selectOptions={{
                Comment: AddActivityType.Comment,
                Meeting: AddActivityType.Meeting,
                Task: AddActivityType.Task,
                Reminder: AddActivityType.Reminder,
            }}
            datePickerErrors={
                selectedAddActivityType === AddActivityType.Meeting
                    ? meetingErrors
                    : selectedAddActivityType === AddActivityType.Reminder
                    ? reminderErrors
                    : taskErrors
            }
            noteErrors={
                selectedAddActivityType === AddActivityType.Task ? taskErrors : commentErrors
            }
            submitButtonLabel={submitButtonLabel(selectedAddActivityType)}
            upcomingActivities={getUpcomingActivities(upcomingActivities)}
            pastActivities={getPastActivities(pastActivities)}
            historyList={getHistoryList(activities)}
            categoryName={t('subscription')}
            displayDatePicker={displayDatePicker}
            tabs={[ActivityLogTab.activities, ActivityLogTab.notes]}
            onEditNoteClick={onEditNoteClick}
            note={note}
        >
            {children}
        </ActivityLogWithTabs>
    );
};

export default SubscriptionActivityLog;
