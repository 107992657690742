// GENERATED FILE - DO NOT MODIFY
import { ClosedOpportunitiesReportDto } from 'Api/Features/Reports/Dtos/ClosedOpportunities/ClosedOpportunitiesReportDto';
import { GetClosedOpportunitiesReportRequestDto } from 'Api/Features/Reports/Dtos/ClosedOpportunities/GetClosedOpportunitiesReportRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class ClosedOpportunitiesReportProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getClosedOpportunitiesReport(request: GetClosedOpportunitiesReportRequestDto | null): Promise<ClosedOpportunitiesReportDto | null> {
        const uri = this.buildUri(
            "/closed-opportunities-report",
            null,
            { ...request || undefined }
        );

        const data: ClosedOpportunitiesReportDto | null = await this.httpClient.get<ClosedOpportunitiesReportDto | null>(uri);
        return data;
    }

    public async exportClosedOpportunitiesReport(request: GetClosedOpportunitiesReportRequestDto | null): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/closed-opportunities-report/export",
            null,
            { ...request || undefined }
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }
}