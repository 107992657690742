import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import LocationHeader from 'Components/location-header/location-header';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { Button, Col, Form, Row, Typography } from 'antd';
import { useService, useStores } from 'Hooks';
import { UnitService } from 'Services/UnitService';
import { Unit } from 'Models/Units/Unit';
import { FloorPlanService } from 'Services/FloorPlanService';
import { Content } from 'antd/lib/layout/layout';
import SvgPanZoom from 'svg-pan-zoom';
import './index.less';
import { Add, PencilBorder, ZoomIn, ZoomOut } from 'Components/icons';
import Icon from 'Components/icons/icon';
import { theme } from 'variant';
import { CampusFloorPlanDto } from 'Api/Features/FloorPlans/Dtos/CampusFloorPlanDto';
import { CampusFloorPlanFloorDto } from 'Api/Features/FloorPlans/Dtos/CampusFloorPlanFloorDto';
import { PAGE_SIZE_100, TABLE_DEBOUNCE_DELAY } from 'Models/Constants';
import { GetUnitsRequestDto } from 'Api/Features/Units/Dtos/GetUnitsRequestDto';
import { UpdateFloorPlanRequestDto } from 'Api/Features/FloorPlans/Dtos/UpdateFloorPlanRequestDto';
import UnitCollapse from './components/unit-collapse';
import { CampusFloorPlanUnitAreaDto } from 'Api/Features/FloorPlans/Dtos/CampusFloorPlanUnitAreaDto';
import UnitControl from './components/unit-control';
import CollapseControls from './components/collapse-controls';
import CreateUnitModal from '../units/create-unit';
import { UnitTypeDto } from 'Api/Features/Units/Dtos/UnitTypeDto';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import CreateEditFloorModal, { CreateEditFloorSubmitValue } from './create-edit-floor-modal';
import SideSwippingCarousel from 'Components/SideSwippingCarousel';

declare const SVG: any;

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'management',
        nameKey: 'management',
    },
    {
        path: 'floor-plan',
        nameKey: 'floor_plan',
    },
];

const FloorPlanEdition: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { toastStore, globalLoadingStore } = useStores();
    const unitService = useService(UnitService);
    const floorPlanService = useService(FloorPlanService);

    const [floors, setFloors] = useState<CampusFloorPlanFloorDto[]>([]);
    const [createEditFloorModalState, setCreateEditFloorModalState] = useState<{
        visible: boolean;
        floor?: CampusFloorPlanFloorDto;
    }>({ visible: false, floor: undefined });

    const [createUnitModalOpen, setCreateUnitModalOpen] = useState(false);

    const [units, setUnits] = useState<Unit[]>([]);
    const [filteredUnits, setFilteredUnits] = useState<Unit[]>([]);

    //the floor plan class that controls floorplan. Initial code from the old c# console
    const [floorPlanManager, setFloorPlanManager] = useState<FloorPlanManager>();

    //null == no selected polygon. {unitID: null} == selected but unassigned polygon. {unitId: string} selected and assigned polygon.
    const [currentSelectedPolygon, setCurrentSelectedPolygon] = useState<{
        unitId: string | null;
    } | null>(null);

    //ui control states
    const [selectedFloorId, setSelectedFloorId] = useState<string>();
    const [triggerPolygonUpdate, setTriggerPolygonUpdate] = useState(false);
    const [activeCollapsePanelKeys, setActiveCollapsePanelKeys] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>();

    //Create floor manager object
    useEffect(() => {
        setFloorPlanManager(
            new FloorPlanManager(
                (unitId: string | null) => setCurrentSelectedPolygon({ unitId }),
                () => setCurrentSelectedPolygon(null),
                floors
            )
        );
    }, []);

    //Call the init on floor manager
    useEffect(() => {
        if (floorPlanManager) floorPlanManager.init();
    }, [floorPlanManager]);

    //set the floor data in the floor manager. Changes to floor name and such will trigger this
    useEffect(() => {
        if (floorPlanManager) {
            floorPlanManager.setFloorPlanData({ floors: [...floors] });
        }
    }, [floors]);

    const fetchCampusFloorPlan = async () => {
        globalLoadingStore.addLoading();
        try {
            const result: CampusFloorPlanDto | null = await floorPlanService.getCampusFloorPlan(
                id,
                {
                    addUnitPolygonsToSvg: false,
                }
            );
            if (result) {
                setFloors(
                    result.floors?.filter((floor) => floor !== null).map((floor) => floor!) ?? []
                );
                setSelectedFloorId(result.floors?.[0]?.id);
            }
        } catch (e) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    useEffect(() => {
        fetchCampusFloorPlan();
    }, []);

    const getAllCampusUnits = async () => {
        let currentPage = 0;
        let fetchedAllUnits = false;
        let units: (Unit | null)[] = [];

        while (!fetchedAllUnits) {
            const [result, totalItemCount] = await fetchUnits(currentPage, PAGE_SIZE_100);
            units = [...units, ...result];
            if (units.length === totalItemCount) fetchedAllUnits = true;
            else currentPage++;
        }
        setUnits(units.filter((unit) => unit !== null).map((unit) => unit!) ?? []);
    };

    const fetchUnits = async (page?: number, pageSize?: number): Promise<[Unit[], number]> => {
        globalLoadingStore.addLoading();
        try {
            const request: GetUnitsRequestDto = {
                campusIds: [id],
                page: page,
                pageSize: pageSize,
            };
            return await unitService.getUnits(request);
        } catch (e) {
            if (!e.treated) toastStore.genericError();
            return [[], 0];
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    useEffect(() => {
        getAllCampusUnits();
    }, []);

    const debouncedUnitsSearch = useRef(
        debounce((searchTerm: string | undefined, units: Unit[]) => {
            if (searchTerm === undefined || searchTerm === '') {
                setActiveCollapsePanelKeys([]);
                setFilteredUnits(units);
            } else {
                setActiveCollapsePanelKeys(Object.keys(UnitTypeDto));
                const lowerCaseSearch = searchTerm.toLocaleLowerCase();
                setFilteredUnits(
                    units.filter((unit) =>
                        unit.name?.toLocaleLowerCase()?.includes(lowerCaseSearch)
                    )
                );
            }
        }, TABLE_DEBOUNCE_DELAY)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            debouncedUnitsSearch.current(searchTerm, units);
        });
        return (): void => {
            disposer();
        };
    }, [debouncedUnitsSearch, searchTerm, units]);

    //set units in the floor manager
    useEffect(() => {
        if (floorPlanManager) floorPlanManager.setUnits(units);
    }, [floorPlanManager, units]);

    //selected floor changed. Init new floor in manager
    useEffect(() => {
        globalLoadingStore.addLoading();
        if (selectedFloorId) {
            const floor = floors.find((floor) => floor.id === selectedFloorId);
            floorPlanManager?.initFloor(floor);
            floorPlanManager?.setCurrentFloorIndex(
                floors.findIndex((floor) => floor.id === selectedFloorId)
            );
        } else {
            floorPlanManager?.initFloor(null);
        }
        globalLoadingStore.removeLoading();
    }, [selectedFloorId, globalLoadingStore]);

    const handleRemoveFloor = (): void => {
        globalLoadingStore.addLoading();
        if (floorPlanManager) {
            floorPlanManager.removeCurrentFloor();
            const floorState = floors;
            const currentSelectedFloorIndex = floorState.findIndex(
                (floor) => floor.id === selectedFloorId
            );
            setSelectedFloorId(floorState[currentSelectedFloorIndex - 1]?.id ?? undefined);
            floorState.splice(currentSelectedFloorIndex, 1);
            setFloors([...floorState]);
        }
        globalLoadingStore.removeLoading();
    };

    const handleSideControlUnitClicked = (unitId: string, floorId: string) => {
        if (floorPlanManager) {
            //unit might be on different floor then one currently loaded in floorplanmanager.
            //Must wait for floor to be loaded before trying to select polygon. Trigger in useeffect
            if (selectedFloorId !== floorId) {
                setSelectedFloorId(floorId);
            }
            setCurrentSelectedPolygon({ unitId });
            setTriggerPolygonUpdate(true);
        }
    };

    //handleUnitClicked trigger
    useEffect(() => {
        if (
            triggerPolygonUpdate &&
            floorPlanManager &&
            currentSelectedPolygon &&
            currentSelectedPolygon.unitId
        ) {
            floorPlanManager.onSideControlUnitClicked(currentSelectedPolygon.unitId);
            setTriggerPolygonUpdate(false);
        }
    }, [floorPlanManager, currentSelectedPolygon, triggerPolygonUpdate]);

    const submit = async () => {
        if (floorPlanManager) {
            globalLoadingStore.addLoading();
            try {
                const request: UpdateFloorPlanRequestDto = {
                    floors: floors
                };
                await floorPlanService.updateFloorPlan(id, request);

                toastStore.toast({ type: 'success', messageKey: `Saved successfully` });
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        } else return;
    };

    const handleFloorsModalSubmit = (submitValue: CreateEditFloorSubmitValue) => {
        //was editing single floor
        if (createEditFloorModalState.floor) {
            if (submitValue.floors && submitValue.floors.length === 0) {
                handleRemoveFloor();
            } else {
                const floorsState = [...floors];
                const floorIndex = floorsState.indexOf(createEditFloorModalState.floor);
                if (floorIndex !== -1) {
                    const oldFloor = floorsState[floorIndex];
                    let base64;
                    if (floorsState[floorIndex].svg !== submitValue.floors[0].svg)
                        base64 = floorPlanManager?.svgToBase64(submitValue.floors[0].svg);

                    floorsState[floorIndex] = {
                        id: oldFloor.id,
                        name: submitValue.floors?.[0]?.name,
                        unitAreas: oldFloor.unitAreas,
                        svg: base64 ?? submitValue.floors?.[0]?.svg ?? null,
                    };
                    setFloors([...floorsState]);
                    //floor is already select so we need to re-init the floor
                    floorPlanManager?.initFloor(floorsState[floorIndex]);
                }
            }
        }
        //was adding floors
        else {
            const floorsState = [...floors];
            const newFloors = submitValue.floors.map((floor, i) => ({
                id: `${floor.name}${i}`,
                name: floor.name,
                svg: floor.svg ?? null,
                unitAreas: floor.unitAreas ?? [],
            }));
            newFloors.map((floor) => {
                if(floor.svg) {
                    const base64 = floorPlanManager?.svgToBase64(floor.svg);
                    floor.svg = base64 ?? null;
                }
            });
            setFloors([...floorsState, ...newFloors]);
            setSelectedFloorId(newFloors[0].id);
        }
    };

    const onRemoveAreaButtonClicked = () => {
        if(floorPlanManager?.selectedPolygon && floorPlanManager.selectedAreaViewModel) {
            const unitId = floorPlanManager.selectedAreaViewModel.unitId;
            floorPlanManager?.onRemoveAreaButtonClicked();

            const floorState = [...floors];
            const currentFloor = floorState.findIndex(x => x.id === selectedFloorId);
            floorState[currentFloor].unitAreas = floorState[currentFloor].unitAreas?.filter(area => area?.unitId !== unitId);
            
            setFloors(floorState);
        }
    }

    const onUnassignUnit = () => {
        if(floorPlanManager?.selectedPolygon && floorPlanManager.selectedAreaViewModel) {
            const floorState = [...floors];
            const currentFloor = floorState.findIndex(x => x.id === selectedFloorId);
            const currentUnitArea = floorState[currentFloor].unitAreas?.findIndex(x => x?.unitId === floorPlanManager.selectedAreaViewModel?.unitId);
            if(currentUnitArea) {
                floorState[currentFloor].unitAreas![currentUnitArea!] = {
                    ...floorState[currentFloor].unitAreas![currentUnitArea],
                    unitId: null,
                };
            }

            floorPlanManager?.onUnassignUnit();
            
            setFloors(floorState);
        }
    }

    const onAssignUnitClicked = (unitId: string) => {
        floorPlanManager?.onAssignUnitClicked(unitId);
        if(floorPlanManager?.selectedAreaViewModel) {
            const floorState = [...floors];
            const currentFloor = floorState.findIndex((x) => x.id === selectedFloorId);
            const currentUnitArea = floorState[currentFloor].unitAreas?.findIndex(
                (x) => x?.points === floorPlanManager.selectedAreaViewModel?.points
            );
            if (currentUnitArea) {
                floorState[currentFloor].unitAreas![currentUnitArea!] = {
                    ...floorState[currentFloor].unitAreas![currentUnitArea],
                    unitId: unitId,
                };
            }
            setFloors(floorState);
        }
    };

    return (
        <>
            <LocationHeader
                title={t('floor_plan')}
                subTitle={t('FloorPlan.floor_plan_edit_section_subtitle')}
                defaultImg={<Icon iconName="FloorPlanIcon" fill={theme['primary-color']} />}
                routes={breadcrumbs}
            />

            <Content className="FloorPlanEdition">
                <Form layout="vertical">
                    <Row gutter={40}>
                        <Col span={16}>
                            {/* Floor picker */}
                            <Row gutter={[0, 20]}>
                                <Col span={24}>
                                    <div className="floors-container" style={{ paddingBottom: 10 }}>
                                        {floors && floors.length > 0 && (
                                            <SideSwippingCarousel
                                                spaceBetweenElements={0}
                                                swipeDisabled
                                                showScrollbar
                                                elements={floors.map((floor) => (
                                                    <div
                                                        className={`floor-btn ${
                                                            selectedFloorId === floor.id && 'active'
                                                        }`}
                                                        key={floor.id}
                                                        onClick={() => {
                                                            setSelectedFloorId(floor.id);
                                                            setCurrentSelectedPolygon(null);
                                                        }}
                                                    >
                                                        <div>{floor.name}</div>
                                                        <div
                                                            className="action-btn"
                                                            onClick={() =>
                                                                setCreateEditFloorModalState({
                                                                    visible: true,
                                                                    floor: floor,
                                                                })
                                                            }
                                                        >
                                                            <PencilBorder
                                                                width={16}
                                                                height={16}
                                                                fill={theme['primary-color']}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            />
                                        )}

                                        <div
                                            className={`add-floor-btn ${
                                                floors.length > 0 ? 'has-floors' : ''
                                            }`}
                                            onClick={() => {
                                                setCreateEditFloorModalState({
                                                    visible: true,
                                                });
                                            }}
                                        >
                                            {floors.length === 0 && (
                                                <div>{t('FloorPlan.add_a_floor')}</div>
                                            )}
                                            <div className="action-btn">
                                                <Add
                                                    width={16}
                                                    height={16}
                                                    fill={theme['primary-color']}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <div className="floor-plan-edit-container">
                                        {/* Plan (SVG) */}
                                        <div>
                                            <div>
                                                {floors && floors.length > 0
                                                    ? t('FloorPlan.floor_edit_explanation')
                                                    : t('FloorPlan.add_a_floor_by_clicking')}
                                            </div>
                                        </div>

                                        <div id="svg-container-div" style={{ height: '550px' }}>
                                            {' '}
                                        </div>

                                        <div className="svg-zoom-container">
                                            <div>
                                                <a
                                                    id="zoomin-button"
                                                    title="Zoom in"
                                                    onClick={() =>
                                                        floorPlanManager?.panZoom?.zoomIn()
                                                    }
                                                >
                                                    <ZoomIn width={20} height={20} />
                                                </a>
                                                <a
                                                    id="zoomout-button"
                                                    title="Zoom out"
                                                    onClick={() =>
                                                        floorPlanManager?.panZoom?.zoomOut()
                                                    }
                                                >
                                                    <ZoomOut width={20} height={20} />
                                                </a>
                                                <a
                                                    id="reset-button"
                                                    title="Reset"
                                                    onClick={() =>
                                                        floorPlanManager?.panZoom?.reset()
                                                    }
                                                >
                                                    100%
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <Row>
                                        <Col span={24} className="action">
                                            {/* Save button */}
                                            <Button
                                                type="primary"
                                                className="positive"
                                                htmlType="button"
                                                onClick={() => submit()}
                                                style={{ width: 200 }}
                                            >
                                                {t('save')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={8}>
                            <CollapseControls
                                onCollapseClick={() => setActiveCollapsePanelKeys([])}
                                onExpandClick={() => {
                                    setActiveCollapsePanelKeys(Object.keys(UnitTypeDto));
                                }}
                                onSearchTermChange={(searchTerm: string) => {
                                    setSearchTerm(searchTerm);
                                }}
                                onCreateClick={() => setCreateUnitModalOpen(true)}
                            />

                            {currentSelectedPolygon && (
                                <UnitControl
                                    unit={units.find(
                                        (unit) => unit.id === currentSelectedPolygon.unitId
                                    )}
                                    onRemovePolygonClick={() => {
                                        onRemoveAreaButtonClicked();
                                        setCurrentSelectedPolygon(null);
                                    }}
                                    onUnassignClick={() => {
                                        onUnassignUnit();
                                        setCurrentSelectedPolygon({ unitId: null });
                                    }}
                                />
                            )}

                            <UnitCollapse
                                campusUnits={filteredUnits}
                                campusFloorPlanFloors={floors}
                                onUnitClick={(unitId: string, floorId: string) =>
                                    handleSideControlUnitClicked(unitId, floorId)
                                }
                                activePanelKeys={activeCollapsePanelKeys}
                                onActivePanelChange={(activeKeys) =>
                                    setActiveCollapsePanelKeys(activeKeys)
                                }
                                onAssignUnit={(unitId: string) => {
                                    onAssignUnitClicked(unitId);
                                    setCurrentSelectedPolygon({ unitId });
                                }}
                                showAssignButton={
                                    currentSelectedPolygon !== null &&
                                    (currentSelectedPolygon?.unitId === null ||
                                        currentSelectedPolygon.unitId === undefined)
                                }
                            />
                        </Col>
                    </Row>
                </Form>

                {createUnitModalOpen && (
                    <CreateUnitModal
                        visible={createUnitModalOpen}
                        onComplete={(success?: boolean) => {
                            setCreateUnitModalOpen(false);
                            if (success) getAllCampusUnits();
                        }}
                        locationId={id}
                    />
                )}

                {createEditFloorModalState.visible && floorPlanManager && (
                    <CreateEditFloorModal
                        visible={createEditFloorModalState.visible}
                        editFloor={createEditFloorModalState.floor}
                        onComplete={(
                            success: Boolean,
                            submitValue?: CreateEditFloorSubmitValue
                        ) => {
                            if (success && submitValue) handleFloorsModalSubmit(submitValue);
                            setCreateEditFloorModalState({ visible: false, floor: undefined });
                        }}
                        floorPlanManager={floorPlanManager}
                    />
                )}
            </Content>
        </>
    );
});

export class FloorPlanManager {
    constructor(
        private onPolygonSelected: (unitId: string | null) => void,
        private onPolygonUnSelected: () => void,
        private floorPlanData: any
    ) {}


    // List of all units of the location
    units: Unit[] = [];

    // Root element for the SVG (<svg> element).
    svgRoot: any = null;

    // Root shape inside the SVG that we use to contain all our other shapes (<g> element).
    svgBackgroundShape: any = null;

    // Viewbox coordinates (X, Y) contained in the selected SVG.
    // We need to consider those coordinates to ensure that the points of our polygons are in right client space.
    svgBackgroundViewBoxX = 0;
    svgBackgroundViewBoxY = 0;

    // svg-pan-zoom instance (used to zoom and pan inside an SVG)
    panZoom: SvgPanZoom.Instance | null = null;

    // Shape that is currently being drawn, if any.
    polygonBeingDrawn: any = null;

    // Index of the currently selected floor.
    currentFloorIndex = 0;

    // Shape that is currently selected and its associated area view model.
    selectedPolygon: any = null;
    selectedAreaViewModel: CampusFloorPlanUnitAreaDto | null = null;

    // A map of areaViewModel.unitId and the associated polygon that has been drawn for it
    unitIdAssociatedPolygons: any = new Map();

    floorViewModelUnitAreas: CampusFloorPlanUnitAreaDto[] = [];

    async init(): Promise<void> {
        // Create the SVG element.
        this.svgRoot = SVG('svg-container-div').attr('id', 'svg');

        // Listen for double clicks inside the SVG.
        this.svgRoot.dblclick((e: Event) => this.onSvgDoubleClick(e));

        // Listen for right clicks inside the SVG.
        document
            .getElementById('svg-container-div')
            ?.addEventListener('contextmenu', (e: Event) => this.onSvgRightClick(e));

        // Listen for keyboard events.
        document.addEventListener('keydown', (e: KeyboardEvent) => this.onKeyDown(e));
    }

    /*
     * Initializes the floor plan controls from a ViewModel received from the server.
     */
    initFloor(floorViewModel: any): void {
        // Clear the SVG and reset variables and controls.
        this.svgRoot.clear();
        this.svgBackgroundShape = null;
        this.svgBackgroundViewBoxX = 0;
        this.svgBackgroundViewBoxY = 0;
        this.selectedPolygon = null;
        this.selectedAreaViewModel = null;

        if (!floorViewModel) {
            return;
        }

        if (floorViewModel.svg) {
            // Draw the background (plan SVG).
            this.svgRoot.svg('<g></g>');
            this.svgBackgroundShape = this.svgRoot
                .select('g')
                .first()
                .addClass('svg-pan-zoom_viewport');

            this.svgBackgroundShape.svg(atob(floorViewModel.svg));

            // Init panning and zooming.
            try {
                if (this.panZoom) {
                    this.panZoom.destroy();
                    this.panZoom = null;
                }
                this.panZoom = SvgPanZoom('#svg', {
                    dblClickZoomEnabled: false,
                    fit: true,
                    center: true,
                });
            } catch (error) {
                console.log(error);
            }

            // Read the viewbox coordinates from the SVG.
            const backgroundSvgRoot = this.svgBackgroundShape.select('svg').first();
            if (backgroundSvgRoot && backgroundSvgRoot.attr('viewBox')) {
                this.svgBackgroundViewBoxX = parseFloat(
                    backgroundSvgRoot.attr('viewBox').split(' ')[0]
                );
                this.svgBackgroundViewBoxY = parseFloat(
                    backgroundSvgRoot.attr('viewBox').split(' ')[1]
                );
            }

            this.floorViewModelUnitAreas = floorViewModel.unitAreas;

            // Draw existing areas on the SVG.
            for (let i = 0; i < floorViewModel.unitAreas.length; i++) {
                const polygon = this.drawPolygonForArea(floorViewModel.unitAreas[i]);
                this.unitIdAssociatedPolygons.set(floorViewModel.unitAreas[i].unitId, polygon);
            }
        }
    }

    setCurrentFloorIndex(index: number): void {
        this.currentFloorIndex = index;
    }

    setFloorPlanData(data: any): void {
        this.floorPlanData = data;
    }

    setUnits(units: Unit[]): void {
        this.units = units;
    }

    onKeyDown(e: KeyboardEvent): void {
        if (this.polygonBeingDrawn != null) {
            if (e.keyCode == 27) {
                // Escape - cancel if we're currently drawing a shape.
                this.cancelPolygonDrawing();
            } else if (e.keyCode == 13) {
                // Enter - Finish the shape if we're currently drawing one.
                this.completePolygonDrawing();
            }

            this.polygonBeingDrawn = null;
        }
    }

    onSvgDoubleClick(eventData: any): void {
        // We're already drawing a shape, don't do anything.
        if (this.polygonBeingDrawn != null) {
            this.completePolygonDrawing();
            return;
        }

        if (this.svgBackgroundShape === null) {
            return;
        }

        // Start drawing a shape.
        this.startPolygonDrawing();

        // Place the first point.
        this.polygonBeingDrawn.draw('point', eventData);
    }

    onSvgRightClick(e: Event): boolean {
        e.preventDefault();

        if (!this.polygonBeingDrawn) {
            return false;
        }

        // Cancel the last point.
        const points = this.polygonBeingDrawn.array().valueOf().slice(0, -1);
        if (points.length <= 1) {
            this.cancelPolygonDrawing();
            return false;
        }

        this.polygonBeingDrawn.plot(points);
        this.polygonBeingDrawn.draw('drawCircles');
        this.polygonBeingDrawn.draw('update');

        return false;
    }

    onAssignUnitClicked(selectedUnitId?: string): void {
        if (!this.selectedAreaViewModel) {
            return;
        }

        // Set the associated unit ID for the selected area.
        this.selectedAreaViewModel.unitId = selectedUnitId;
    }

    onRemoveAreaButtonClicked(): boolean {
        if (!this.selectedPolygon) {
            return false;
        }

        // Remove the shape from the SVG.
        this.selectedPolygon.selectize(false, { deepSelect: true }).resize('stop').remove();
        this.selectedPolygon = null;

        if(this.selectedAreaViewModel)
            this.unitIdAssociatedPolygons.delete(this.selectedAreaViewModel.unitId);

        this.selectedAreaViewModel = null;

        return false;
    }

    removeCurrentFloor(): void {
        this.floorPlanData.floors.splice(this.currentFloorIndex, 1);
    }

    svgToBase64(content: any): string {
        const base64 = btoa(content);
        return base64;
    }

    /*
     * Draws an area on the floor plan SVG using the coordinates in a CampusFloorPlanAreaViewModel received from the server.
     */
    drawPolygonForArea(areaViewModel: any): any {
        if (this.svgBackgroundShape === null) {
            return;
        }

        // Draw the polygon
        // Points format: "x1,y1 x2,y2 x3,y3"
        const polygon = this.svgBackgroundShape
            .polygon(
                this.translatePoints(
                    areaViewModel.points,
                    -this.svgBackgroundViewBoxX,
                    -this.svgBackgroundViewBoxY
                )
            )
            .attr('fill', theme['primary-color'])
            .attr('fill-opacity', '0.5')
            .attr('stroke-width', 1);

        this.initPolygonFeatures(polygon, areaViewModel);

        return polygon;
    }

    startPolygonDrawing(): void {
        this.polygonBeingDrawn = this.svgBackgroundShape
            .polygon()
            .draw()
            .attr('fill', theme['primary-color'])
            .attr('fill-opacity', '0.5')
            .attr('stroke-width', 1);
    }

    cancelPolygonDrawing(): void {
        if (this.polygonBeingDrawn) {
            this.polygonBeingDrawn.draw('cancel');
            this.polygonBeingDrawn = null;
        }
    }

    completePolygonDrawing(): void {
        if (this.polygonBeingDrawn) {
            this.polygonBeingDrawn.draw('done');

            const areaViewModel = {
                unitId: null,
                points: this.translatePoints(
                    this.polygonBeingDrawn.attr('points'),
                    this.svgBackgroundViewBoxX,
                    this.svgBackgroundViewBoxY
                ),
            };
            this.floorPlanData.floors[this.currentFloorIndex].unitAreas.push(areaViewModel);

            this.initPolygonFeatures(this.polygonBeingDrawn, areaViewModel);

            this.polygonBeingDrawn = null;
        }
    }

    initPolygonFeatures(polygon: any, areaViewModel: any): void {
        // Listen for clicks on the polygon
        polygon.click(() => {
            this.onPolygonClicked(polygon, areaViewModel);
        });

        // Make polygon draggable and resizable
        polygon
            .draggable()
            .on('dragend', () => {
                areaViewModel.points = polygon.node.getAttribute('points');
            })
            .selectize({ deepSelect: true })
            .resize()
            .on('resizedone', () => {
                areaViewModel.points = polygon.node.getAttribute('points');
            });
    }

    //Side control unit clicked. Find the associated polygon on floor map
    onSideControlUnitClicked(unitId: string): void {
        const unitArea = this.floorViewModelUnitAreas.find(
            (unit: CampusFloorPlanUnitAreaDto) => unit.unitId === unitId
        );
        const polygon = this.unitIdAssociatedPolygons.get(unitId);
        this.onPolygonClicked(polygon, unitArea);
    }

    onPolygonClicked(polygon: any, areaViewModel: any): void {
        if (this.selectedPolygon) {
            this.selectedPolygon.attr('fill', theme['primary-color']).attr('fill-opacity', '0.5');
        }

        if (this.selectedPolygon === polygon) {
            this.selectedPolygon = null;
            this.selectedAreaViewModel = null;
            this.onPolygonUnSelected();
            return;
        } else {
            this.selectedPolygon = polygon;
            this.selectedAreaViewModel = areaViewModel;
            this.selectedPolygon.attr('fill', theme['primary-color']).attr('fill-opacity', '1');
            this.onPolygonSelected(areaViewModel.unitId);
        }
    }

    onUnassignUnit(): void {
        if (!this.selectedAreaViewModel) {
            return;
        }

        this.unitIdAssociatedPolygons.delete(this.selectedAreaViewModel.unitId);
        this.selectedAreaViewModel.unitId = undefined;
    }

    /**
     * Moves a list of points.
     */
    translatePoints(points: any, x: any, y: any): any {
        if (x === 0 && y === 0) {
            return points;
        }

        const points2 = points.split(' ');

        const translatedPoints = [];

        for (let i = 0; i < points2.length; i++) {
            const points3 = points2[i].split(',');
            const xx = parseFloat(points3[0]) + x;
            const yy = parseFloat(points3[1]) + y;

            const r = xx.toString() + ',' + yy.toString();
            translatedPoints.push(r);
        }

        return translatedPoints.join(' ');
    }

    isSvg(input: string): boolean {
        // ----------
        // is-svg
        // Code taken from the is-svg JavaScript package.
        // ----------
        const htmlCommentRegex = /<!--([\s\S]*?)-->/g;

        const isBinary = (buffer: any) => {
            for (let i = 0; i < 24; i++) {
                const characterCode = buffer.charCodeAt(i);

                if (characterCode === 65533 || characterCode <= 8) {
                    return true;
                }
            }

            return false;
        };

        const regex =
            /^\s*(?:<\?xml[^>]*>\s*)?(?:<!doctype svg[^>]*\s*(?:\[?(?:\s*<![^>]*>\s*)*\]?)*[^>]*>\s*)?(?:<svg[^>]*>[^]*<\/svg>|<svg[^/>]*\/\s*>)\s*$/i;

        return (
            Boolean(input) &&
            !isBinary(input) &&
            regex.test(input.toString().replace(htmlCommentRegex, ''))
        );
    }
}

export default FloorPlanEdition;
