import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const WarningLined: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.898241 13.2565L7.96005 1.02632L15.0209 13.2565H0.898241ZM15.8745 13.4208L8.24375 0.204392C8.18578 0.102474 8.07729 0.0400085 7.95992 0.0400085C7.84156 0.0400085 7.73307 0.102474 7.6751 0.204392L0.0443014 13.4208C-0.0147671 13.5227 -0.0147671 13.6477 0.0443014 13.7496C0.103589 13.8515 0.212082 13.914 0.329342 13.914H15.5896C15.708 13.914 15.8165 13.8515 15.8745 13.7496C15.9336 13.6477 15.9336 13.5227 15.8745 13.4208Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.95967 11.5962C7.75254 11.5962 7.58378 11.4285 7.58378 11.2214C7.58378 11.0143 7.75254 10.8466 7.95967 10.8466C8.16569 10.8466 8.33446 11.0143 8.33446 11.2214C8.33446 11.4285 8.16569 11.5962 7.95967 11.5962ZM7.95987 10.2669C7.43275 10.2669 7.00535 10.6954 7.00535 11.2214C7.00535 11.7474 7.43275 12.1759 7.95987 12.1759C8.48601 12.1759 8.91439 11.7474 8.91439 11.2214C8.91439 10.6954 8.48601 10.2669 7.95987 10.2669Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.18495 9.32988H7.73454L7.59208 5.66303H8.32731L8.18495 9.32988ZM8.83685 5.17316C8.78315 5.11617 8.70655 5.08329 8.62863 5.08329H7.29066C7.21274 5.08329 7.13614 5.11617 7.08233 5.17316C7.02754 5.22905 6.99806 5.30685 7.00124 5.38466L7.16671 9.63124C7.1723 9.78685 7.29943 9.90959 7.45614 9.90959H8.46315C8.61987 9.90959 8.74699 9.78685 8.75258 9.63124L8.91795 5.38466C8.92124 5.30685 8.89165 5.22905 8.83685 5.17316Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default WarningLined;
