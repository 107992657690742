// GENERATED FILE - DO NOT MODIFY
import { CreateInvoiceRequestDto } from 'Api/Features/Invoices/Dtos/CreateInvoiceRequestDto';
import { GetMemberInvoicesRequestDto } from 'Api/Features/Invoices/Dtos/GetMemberInvoicesRequestDto';
import { GetMemberInvoicesResponseDto } from 'Api/Features/Invoices/Dtos/GetMemberInvoicesResponseDto';
import { GetMembershipInvoicesRequestDto } from 'Api/Features/Invoices/Dtos/GetMembershipInvoicesRequestDto';
import { GetMembershipInvoicesResponseDto } from 'Api/Features/Invoices/Dtos/GetMembershipInvoicesResponseDto';
import { GuestInvoiceDto } from 'Api/Features/Invoices/Dtos/GuestInvoiceDto';
import { InvoiceDto } from 'Api/Features/Invoices/Dtos/InvoiceDto';
import { InvoiceExportRequestDto } from 'Api/Features/Invoices/Dtos/InvoiceExportRequestDto';
import { LightInvoiceDto } from 'Api/Features/Invoices/Dtos/LightInvoiceDto';
import { PayInvoiceWithTokenRequestDto } from 'Api/Features/Invoices/Dtos/PayInvoiceWithTokenRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class InvoiceProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async exportInvoice(request: InvoiceExportRequestDto | null): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/invoices/export",
            null,
            null
        );

        const data: ArrayBuffer = await this.httpClient.postAsBuffer<InvoiceExportRequestDto | null>(uri, request);
        return data;
    }

    public async getInvoice(invoiceId: string | null): Promise<InvoiceDto | null> {
        const uri = this.buildUri(
            "/invoices/{invoiceId}",
            { invoiceId: invoiceId },
            null
        );

        const data: InvoiceDto | null = await this.httpClient.get<InvoiceDto | null>(uri);
        return data;
    }

    public async getInvoiceWithToken(invoiceId: string | null): Promise<GuestInvoiceDto | null> {
        const uri = this.buildUri(
            "/invoices/{invoiceId}/getwithtoken",
            { invoiceId: invoiceId },
            null
        );

        const data: GuestInvoiceDto | null = await this.httpClient.get<GuestInvoiceDto | null>(uri);
        return data;
    }

    public async getCurrentMemberInvoices(): Promise<(LightInvoiceDto | null)[] | null> {
        const uri = this.buildUri(
            "/profile/invoices",
            null,
            null
        );

        const data: (LightInvoiceDto | null)[] | null = await this.httpClient.get<(LightInvoiceDto | null)[] | null>(uri);
        return data;
    }

    public async getMemberInvoices(memberId: string | null, request: GetMemberInvoicesRequestDto | null): Promise<GetMemberInvoicesResponseDto | null> {
        const uri = this.buildUri(
            "/members/{memberId}/invoices",
            { memberId: memberId },
            { ...request || undefined }
        );

        const data: GetMemberInvoicesResponseDto | null = await this.httpClient.get<GetMemberInvoicesResponseDto | null>(uri);
        return data;
    }

    public async getMembershipInvoices_V1_1(membershipId: string | null): Promise<(LightInvoiceDto | null)[] | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/invoices",
            { membershipId: membershipId },
            null
        );

        const data: (LightInvoiceDto | null)[] | null = await this.httpClient.get<(LightInvoiceDto | null)[] | null>(uri);
        return data;
    }

    public async getMembershipInvoices(membershipId: string | null, request: GetMembershipInvoicesRequestDto | null): Promise<GetMembershipInvoicesResponseDto | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/invoices",
            { membershipId: membershipId },
            { ...request || undefined }
        );

        const data: GetMembershipInvoicesResponseDto | null = await this.httpClient.get<GetMembershipInvoicesResponseDto | null>(uri);
        return data;
    }

    public async retryInvoicePayment(invoiceId: string | null): Promise<InvoiceDto | null> {
        const uri = this.buildUri(
            "/invoices/{invoiceId}/retry",
            { invoiceId: invoiceId },
            null
        );

        const data: InvoiceDto | null = await this.httpClient.post<void, InvoiceDto | null>(uri, );
        return data;
    }

    public async tryInvoicePayment(invoiceId: string | null): Promise<InvoiceDto | null> {
        const uri = this.buildUri(
            "/invoices/{invoiceId}/try",
            { invoiceId: invoiceId },
            null
        );

        const data: InvoiceDto | null = await this.httpClient.post<void, InvoiceDto | null>(uri, );
        return data;
    }

    public async createInvoice(request: CreateInvoiceRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/invoices",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateInvoiceRequestDto | null, string>(uri, request);
        return data;
    }

    public async tryInvoicePaymentUsingToken(invoiceId: string | null, payInvoiceRequest: PayInvoiceWithTokenRequestDto | null): Promise<InvoiceDto | null> {
        const uri = this.buildUri(
            "/invoices/{invoiceId}/trywithtoken",
            { invoiceId: invoiceId },
            null
        );

        const data: InvoiceDto | null = await this.httpClient.post<PayInvoiceWithTokenRequestDto | null, InvoiceDto | null>(uri, payInvoiceRequest);
        return data;
    }

    public async voidInvoice(invoiceId: string | null): Promise<void> {
        const uri = this.buildUri(
            "/invoices/{invoiceId}/void",
            { invoiceId: invoiceId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async closeInvoice(invoiceId: string | null): Promise<void> {
        const uri = this.buildUri(
            "/invoices/{invoiceId}/close",
            { invoiceId: invoiceId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async refundInvoice(invoiceId: string | null): Promise<void> {
        const uri = this.buildUri(
            "/invoices/{invoiceId}/refund",
            { invoiceId: invoiceId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async resendInvoice(invoiceId: string | null): Promise<void> {
        const uri = this.buildUri(
            "/invoices/{invoiceId}/resend",
            { invoiceId: invoiceId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async performInvoiceChargeMaintenance(): Promise<void> {
        const uri = this.buildUri(
            "/invoices/charge-maintenance",
            null,
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }
}