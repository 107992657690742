import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ArrowRight: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => (
    <span role="img" className="anticon">
        <svg width="1em" height="1em" viewBox="0 0 21 18" fill="none" {...props}>
            <path
                fillRule={"evenodd"}
                clipRule="evenodd"
                d="M0.0341797 8.93313C0.0341797 8.38856 0.467047 7.95569 1.01162 7.95569L16.6647 7.96965L11.1072 2.35633C10.7302 1.96535 10.7302 1.33699 11.1212 0.959981C11.3167 0.764492 11.568 0.666748 11.8194 0.666748C12.0707 0.666748 12.322 0.764492 12.5175 0.959981L19.7087 8.22099C19.7646 8.27685 19.8204 8.3327 19.8623 8.40252C20.1276 8.7935 20.0857 9.31015 19.7367 9.65923L12.5874 16.8504C12.2103 17.2274 11.582 17.2274 11.191 16.8504C10.8 16.4595 10.8 15.8311 11.191 15.4541L16.6647 9.95247L1.01162 9.92454C0.467047 9.92454 0.0341797 9.49167 0.0341797 8.93313Z"
                fill={fill}
            />
        </svg>
    </span>
);

export default ArrowRight;
