import { AccountProxy } from 'Api/Features/Accounts/AccountProxy';
import { ForgottenPasswordRequestDto } from 'Api/Features/Accounts/Dtos/ForgottenPasswordRequestDto';
import { IsEmailAvailableRequestDto } from 'Api/Features/Accounts/Dtos/IsEmailAvailableRequestDto';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(
    AccountProxy
)
export class AccountService extends ApiService {
    constructor(
        private readonly accountProxy: AccountProxy
    ) {
        super();
    }

    public async isEmailAvailable(email: string): Promise<boolean> {
        const request: IsEmailAvailableRequestDto = {
            email: email,
        };
        const isEmailAvailable: boolean = await this.accountProxy.isEmailAvailable(request);

        return isEmailAvailable;
    }

    public async forgotPassword(forgottenPasswordRequest: ForgottenPasswordRequestDto | null): Promise<void> {
        await this.accountProxy.forgotPassword(forgottenPasswordRequest);
    }
}
