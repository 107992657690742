import { AcceptSubscriptionProposalRequestDto } from 'Api/Features/SubscriptionProposals/Dtos/AcceptSubscriptionProposalRequestDto';
import { CreateSubscriptionProposalRequestDto } from 'Api/Features/SubscriptionProposals/Dtos/CreateSubscriptionProposalRequestDto';
import { GetSubscriptionProposalsResponseDto } from 'Api/Features/SubscriptionProposals/Dtos/GetSubscriptionProposalsResponseDto';
import { RejectSubscriptionProposalRequestDto } from 'Api/Features/SubscriptionProposals/Dtos/RejectSubscriptionProposalRequestDto';
import { SubscriptionProposalDetailsDto } from 'Api/Features/SubscriptionProposals/Dtos/SubscriptionProposalDetailsDto';
import { UpdateSubscriptionProposalTitleRequestDto } from 'Api/Features/SubscriptionProposals/Dtos/UpdateSubscriptionProposalTitleRequestDto';
import { SubscriptionProposalProxy } from 'Api/Features/SubscriptionProposals/SubscriptionProposalProxy';
import { inject } from 'aurelia-dependency-injection';
import { SubscriptionProposal } from 'Models/Proposals/SubscriptionProposal';
import { SubscriptionProposalDetails } from 'Models/Proposals/SubscriptionProposalDetails';
import { arrayBufferToPdf } from 'Utils';
import { dateMomentToString } from 'Utils/TimeUtils';
import { PagedRequest } from '../Models/Requests/PagedRequest';
import { SearchableRequest } from '../Models/Requests/SearchableRequest';
import { ApiService } from './ApiService';

interface SubscriptionProposalRequest extends PagedRequest, SearchableRequest {
    opportunityId: string;
}

@inject(SubscriptionProposalProxy)
export class SubscriptionProposalService extends ApiService {
    constructor(private readonly subscriptionProposalProxy: SubscriptionProposalProxy) {
        super();
    }

    public async getSubscriptionProposalPreview(request: CreateSubscriptionProposalRequestDto): Promise<SubscriptionProposalDetailsDto | null> {
        request.periods = request.periods?.map((x) => ({
            startDate: dateMomentToString(x?.startDate),
            endDate: dateMomentToString(x?.endDate),
            amount: x?.amount,
        }));

        request.discounts = request.discounts?.map((x) => ({
            startDate: dateMomentToString(x?.startDate),
            endDate: dateMomentToString(x?.endDate),
            amount: x?.amount,
            internalNote: x?.internalNote,
            title: x?.title
        }));

        return await this.subscriptionProposalProxy.getSubscriptionProposalPreview(request);
    }

    public async createSubscriptionProposal(request: CreateSubscriptionProposalRequestDto): Promise<string | null> {
        request.periods = request.periods?.map((x) => ({
            startDate: dateMomentToString(x?.startDate),
            endDate: dateMomentToString(x?.endDate),
            amount: x?.amount,
        }));

        request.discounts = request.discounts?.map((x) => ({
            startDate: dateMomentToString(x?.startDate),
            endDate: dateMomentToString(x?.endDate),
            amount: x?.amount,
            internalNote: x?.internalNote,
            title: x?.title
        }));
        
        return await this.subscriptionProposalProxy.createSubscriptionProposal(request);
    }

    public async getSubscriptionProposal({
        searchTerm,
        page,
        pageSize,
        opportunityId,
    }: SubscriptionProposalRequest): Promise<[SubscriptionProposal[], number]> {
        const response: GetSubscriptionProposalsResponseDto | null = await this.subscriptionProposalProxy.getSubscriptionProposals(
            opportunityId,
            {
                searchTerm,
                page,
                pageSize,
            }
        );
        return [
            response?.items?.map((x) => new SubscriptionProposal(x!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async getSubscriptionProposalById(id: string): Promise<SubscriptionProposal | null> {
        const response = await this.subscriptionProposalProxy.getSubscriptionProposal(id);
        return response ? new SubscriptionProposalDetails(response!) : null;
    }

    public async resendSubscriptionProposal(subscriptionProposalId: string | null): Promise<void> {
        return await this.subscriptionProposalProxy.resendSubscriptionProposal(
            subscriptionProposalId
        );
    }

    public async acceptSubscriptionProposal(
        subscriptionProposalId: string | null,
        request: AcceptSubscriptionProposalRequestDto | null
    ): Promise<void> {
        return await this.subscriptionProposalProxy.acceptSubscriptionProposal(
            subscriptionProposalId,
            request
        );
    }

    public async rejectSubscriptionProposal(
        subscriptionProposalId: string | null,
        request: RejectSubscriptionProposalRequestDto | null
    ): Promise<void> {
        return await this.subscriptionProposalProxy.rejectSubscriptionProposal(
            subscriptionProposalId,
            request
        );
    }

    public async exportSubscriptionProposal(subscriptionProposalId: string): Promise<Blob> {
        const document = await this.subscriptionProposalProxy.exportSubscriptionProposal(
            subscriptionProposalId
        );
        return arrayBufferToPdf(document);
    }

    public async updateSubscriptionProposalTitle(subscriptionProposalId: string | null, request: UpdateSubscriptionProposalTitleRequestDto | null): Promise<void> {
        await this.subscriptionProposalProxy.updateSubscriptionProposalTitle(subscriptionProposalId, request);
    }
}
