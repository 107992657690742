import { Table } from 'antd';
import { ColumnType, TablePaginationConfig } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetMembersSortColumnDto } from 'Api/Features/Members/Dtos/GetMembersSortColumnDto';
import { GetMembershipMembersRequestDto } from 'Api/Features/Memberships/Dtos/GetMembershipMembersRequestDto';
import { GetMembershipMembersResponseItemDto } from 'Api/Features/Memberships/Dtos/GetMembershipMembersResponseItemDto';
import { MembershipRoleDto } from 'Api/Features/Memberships/Dtos/MembershipRoleDto';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import CompanyHeader from 'Components/company-header';
import Icon from 'Components/icons/icon';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { TableFilters } from 'Components/table-filters';
import { Tag } from 'Components/tag';
import { TdWithImage } from 'Components/td-with-image';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { MembershipService } from 'Services/MembershipService';
import { FilterStore } from 'Stores';
import { CompanyContext } from '..';
import TransferCompanyOwnershipModal from '../modals/transfer-company-ownership';
import InviteMemberModal, {
    InviteMemberContext,
} from 'Routes/authenticated/locations/id/subscriptions/id/modals/invite-members';
import './index.less';
import { MemberService } from 'Services/MemberService';
import { ExportMembersRequestDto } from 'Api/Features/Members/Dtos/ExportMembersRequestDto';
import { showFile } from 'Utils';

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 20,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'roles',
        nameKey: 'Roles',
        items: [
            {
                key: MembershipRoleDto.Owner,
                displayNameKey: `Membership.MembershipRoleDto_${MembershipRoleDto.Owner}`,
                checked: true,
            },
            {
                key: MembershipRoleDto.Administrator,
                displayNameKey: `Membership.MembershipRoleDto_${MembershipRoleDto.Administrator}`,
                checked: true,
            },
            {
                key: MembershipRoleDto.FinanceManager,
                displayNameKey: `Membership.MembershipRoleDto_${MembershipRoleDto.FinanceManager}`,
                checked: true,
            },
            {
                key: MembershipRoleDto.CreditManager,
                displayNameKey: `Membership.MembershipRoleDto_${MembershipRoleDto.CreditManager}`,
                checked: true,
            },
            {
                key: MembershipRoleDto.DayPassConsumer,
                displayNameKey: `Membership.MembershipRoleDto_${MembershipRoleDto.DayPassConsumer}`,
                checked: true,
            },
            {
                key: MembershipRoleDto.JobManager,
                displayNameKey: `Membership.MembershipRoleDto_${MembershipRoleDto.JobManager}`,
                checked: true,
            },
            {
                key: MembershipRoleDto.Member,
                displayNameKey: `Membership.MembershipRoleDto_${MembershipRoleDto.Member}`,
                checked: true,
            },
        ],
    },
];

const CompanyTeam: FunctionComponent = () => {
    const membershipService = useService(MembershipService);
    const memberService = useService(MemberService);
    const [members, setMembers] = useState<GetMembershipMembersResponseItemDto[]>();
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const paginationRef = useRef(initialPaginationState);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { toastStore, globalLoadingStore } = useStores();
    const companyContext = useContext(CompanyContext);
    const history = useHistory();

    const [exportRequest, setExportRequest] = useState<ExportMembersRequestDto | null>(null);
    const [isTableEmpty, setIsTableEmpty] = useState<boolean>(true);
    const [transferOwnerModalOpen, setTransferOwnerModalOpen] = useState(false);
    const [inviteMemberModalOpen, setInviteMemberModalOpen] = useState<boolean>(false);

    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `companies`,
            nameKey: 'companies',
        },
        {
            path: `${id}/dashboard`,
            nameKey: companyContext?.name ?? '',
        },
        {
            path: 'team',
            nameKey: 'team',
        },
    ];

    const memberRolesStringArray = (member: GetMembershipMembersResponseItemDto): string[] => {
        const roles =
            member.emailInvitation !== null ||
            member.roles?.some((role) => role === MembershipRoleDto.PendingMember)
                ? member.pendingRoles
                : member.roles;

        if (!roles?.some((role) => role !== MembershipRoleDto.Member))
            return [t(`Membership.member_role_${MembershipRoleDto.Member}`)];

        return roles
            ?.filter((role) => role !== MembershipRoleDto.Member)
            ?.map((role) => t(`Membership.member_role_${role}`));
    };

    const roleRender = (member: GetMembershipMembersResponseItemDto): ReactNode => {
        //Pending member
        if (
            member.emailInvitation !== null ||
            member.roles?.some((role) => role === MembershipRoleDto.PendingMember)
        )
            return (
                <div>
                    <span className="pendingRoles">
                        {memberRolesStringArray(member)?.join(', ')}
                    </span>
                    <Tag label={t(`Membership.app_invitation_sent`)} />
                </div>
            );

        if (!member.roles?.some((role) => role !== MembershipRoleDto.Member))
            return t(`Membership.member_role_${MembershipRoleDto.Member}`);

        return (
            <div>
                {member.roles
                    ?.filter((role) => role !== MembershipRoleDto.Member)
                    ?.map((role) => {
                        return t(`Membership.member_role_${role}`);
                    })
                    ?.join(', ')}
            </div>
        );
    };

    const columns: ColumnType<GetMembershipMembersResponseItemDto>[] = [
        {
            title: t('name'),
            render: (member: GetMembershipMembersResponseItemDto) =>
                member.emailInvitation === null ? (
                    <TdWithImage defaultImg={<Icon iconName="User" />} imgSrc={member?.imageUrl}>
                        {member?.firstName} {member.lastName}
                    </TdWithImage>
                ) : (
                    ''
                ),
            key: GetMembersSortColumnDto.Name,
            sorter: true,
        },
        {
            title: t('roles'),
            render: roleRender,
        },
        {
            title: t('email'),
            render: (member: GetMembershipMembersResponseItemDto) => member.contactInfo?.email,
            key: GetMembersSortColumnDto.Email,
            sorter: true,
        },
    ];

    const getAdvancedFilterRoles = (): {
        checkedRoles: MembershipRoleDto[];
        rolesCount: number;
    } => {
        const filterStore = filterStoreRef.current;
        const roles = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'roles'
        );
        const checkedRoles = roles?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key;
            });

        return {
            checkedRoles:
                checkedRoles?.map((x) => MembershipRoleDto[x as keyof typeof MembershipRoleDto]) ??
                [],
            rolesCount: roles?.items.length ?? 0,
        };
    };

    const fetch = useCallback(
        async (params: {
            searchTerm?: string;
            pagination: TablePaginationConfig;
            sortColumn: GetMembersSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);
            try {
                const roles = getAdvancedFilterRoles();
                if (roles.checkedRoles.length === 0) setMembers([]);
                else {
                    const request: GetMembershipMembersRequestDto = {
                        searchTerm: params.searchTerm,
                        pageSize: params.pagination.pageSize || 10,
                        page: (params.pagination.current || 1) - 1,
                        sortColumn: params.sortColumn,
                        sortDirection: params.sortDirection,
                        roles:
                            roles.checkedRoles.length === roles.rolesCount
                                ? []
                                : roles.checkedRoles,
                        includeEmailInvitations: true,
                    };
                    const [data, totalItemCount] = await membershipService.getMembershipMembers(
                        id,
                        request
                    );

                    setMembers(data);
                    setPagination({
                        ...params.pagination,
                        total: totalItemCount,
                    });
                    setExportRequest(request);
                    setIsTableEmpty(data.length === 0);
                }
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [setLoading, membershipService, t]
    );

    const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any): void => {
        const { searchTerm } = filterStoreRef.current;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        fetch({
            searchTerm: searchTerm,
            pagination: pagination,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
    };

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;

            fetch({
                searchTerm: filterStore.searchTerm,
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                sortColumn: null,
                sortDirection: null,
            });
        });

        return (): void => {
            disposer();
        };
    }, [fetch]);

    const headerData = {
        iconName: 'User',
        title: t('Team'),
        subTitle: t('Membership.membership_team_section_subtitle'),
    };

    const menuOptions: ActionMenuOption[] = [
        {
            key: 'transferOwner',
            title: t('Lead.transfer_ownership'),
            action: () => setTransferOwnerModalOpen(true),
        },
        {
            key: 'addMembers',
            title: t('Membership.add_members'),
            action: () => setInviteMemberModalOpen(true),
        },
    ];

    const onExportClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            //TODO api will make a new call to export  https://appcom.atlassian.net/browse/CGCC-13719
            const document = await memberService.exportMembers({
                ...exportRequest,
                membershipIds: [id],
            });

            showFile(document, `${companyContext?.name}'s team`);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <div className="CompanyTeam">
            <CompanyHeader
                company={companyContext}
                routes={breadcrumbs}
                headerData={headerData}
                action={<ActionMenu options={menuOptions} type="primary" trigger="click" />}
            />

            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeAdvancedFilters
                    includeExport
                    onExportClick={onExportClick}
                    exportBtnDisabled={isTableEmpty}
                />

                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(row: GetMembershipMembersResponseItemDto): string => row.id ?? ''}
                    dataSource={members}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(row: GetMembershipMembersResponseItemDto) => ({
                        onClick: (): void => {
                            if (row.emailInvitation !== null) {
                                history.push(
                                    `/companies/${companyContext?.id}/dashboard/team/non-member/${row.contactInfo?.email}`
                                );
                            } else {
                                history.push(
                                    `/companies/${companyContext?.id}/dashboard/team/${row.id}`
                                );
                            }
                        },
                    })}
                />
            </Content>

            {transferOwnerModalOpen && (
                <TransferCompanyOwnershipModal
                    visible={transferOwnerModalOpen}
                    onComplete={(success: boolean) => {
                        if (success) {
                            const filterStore = filterStoreRef.current;
                            fetch({
                                searchTerm: filterStore.searchTerm,
                                pagination: {
                                    ...paginationRef.current,
                                    current: 1,
                                },
                                sortColumn: null,
                                sortDirection: null,
                            });
                            companyContext?.fetchCompany();
                        }

                        setTransferOwnerModalOpen(false);
                    }}
                    membershipId={id}
                    currentOwnerId={companyContext?.owner?.id}
                />
            )}

            {inviteMemberModalOpen && (
                <InviteMemberModal
                    onComplete={(success: boolean): void => {
                        setInviteMemberModalOpen(false);
                        if (success) {
                            toastStore.toast({
                                type: 'success',
                                message: t('Membership.invited_members_success'),
                            });
                            const filterStore = filterStoreRef.current;
                            fetch({
                                searchTerm: filterStore.searchTerm,
                                pagination: {
                                    ...paginationRef.current,
                                    current: 1,
                                },
                                sortColumn: null,
                                sortDirection: null,
                            });
                        }
                    }}
                    visible={inviteMemberModalOpen}
                    membershipId={id}
                    inviteMemberContext={InviteMemberContext.Company}
                />
            )}
        </div>
    );
};

export default CompanyTeam;
