import { Button, Checkbox, Col, Form, Input, Row, Select, Tooltip } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import TextArea from 'antd/lib/input/TextArea';
import Title from 'antd/lib/typography/Title';
import { UploadFile } from 'antd/lib/upload/interface';
import { CampusDetailsDto } from 'Api/Features/Campuses/Dtos/CampusDetailsDto';
import { CampusStatusTypeDto } from 'Api/Features/Campuses/Dtos/CampusStatusTypeDto';
import { CreateCampusRequestDto } from 'Api/Features/Campuses/Dtos/CreateCampusRequestDto';
import { UpdateCampusOccupancyObjectiveRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusOccupancyObjectiveRequestDto';
import { UpdateCampusUnitPricingRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusUnitPricingRequestDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import timezones from 'appcom-timezones';
import BaseModal from 'Components/base-modal/base-modal';
import { FormStep } from 'Components/form-step';
import { StepInfo } from 'Components/form-step/form-step';
import GlobalizationSelect from 'Components/globalization-select/globalization-select';
import Icon from 'Components/icons/icon';
import { ImagePicker } from 'Components/image-picker';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { NumberInput } from 'Components/NumberInput';
import { ValidatedFormItem } from 'Components/validated-form-item';
import GoogleMapReact, { Coords } from 'google-map-react';
import { useFormValidation, useService, useStores } from 'Hooks';
import moment from 'moment';
import React, { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import {
    CreateLocationAddressSchema,
    CreateLocationContactSchema,
    CreateLocationSchema,
} from 'Schemas/CreateLocationSchema';
import { CampusService } from 'Services/CampusService';
import EditPricingFormSection from '../id/management/pricing/edit-pricing-form-section';
import SetOccupancyObjectiveFormSection from '../id/management/revenue-occupancy-targets/set-occupancy-objective-form-section';
import './create-location-modal.less';

const formGutter: [Gutter, Gutter] = [40, 0];
interface MarkerModel extends Coords {
    img: string;
}
const markerImg = 'CG';
const mapCenter: Coords = { lat: 37.2756537, lng: -104.6561154 };
const defaultMapZoom = 4;
const mapZoom = 15;
const Marker = ({ lat, lng, img }: MarkerModel): ReactElement => (
    <div style={{ fontSize: '40px', margin: '-20px 0 0 -20px' }}>
        <Icon iconName={img} />
    </div>
);

interface SelectOption {
    id: string;
    name: string;
}

enum CreateLocationFormStep {
    Location = 'Location',
    UnitPricing = 'UnitPricing',
    OccupancyObjective = 'OccupancyObjective',
}

interface CreateLocationModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    campusData?: CampusDetailsDto;
}

const CreateLocationModal: FunctionComponent<CreateLocationModalProps> = ({
    visible,
    onComplete,
    campusData,
}) => {
    const campusService = useService(CampusService);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const [contactErrors, contactValidateForm, contactResetErrors] = useFormValidation(
        CreateLocationContactSchema,
        form
    );
    const [addressErrors, addressValidateForm, addressResetErrors] = useFormValidation(
        CreateLocationAddressSchema,
        form
    );
    const [locationErrors, locationValidateForm, locationResetErrors] = useFormValidation(
        CreateLocationSchema,
        form
    );
    const [locationStatus, setLocationStatus] = useState<CampusStatusTypeDto>(
        CampusStatusTypeDto.Active
    );
    const [mainImageDetails, setMainImageDetails] = useState<ImageDetails[]>();
    const [appHomeBackgroundImageDetails, setAppHomeBackgroundImageDetails] = useState<ImageDetails[]>();
    const [imagesDetails, setImagesDetails] = useState<ImageDetails[]>();
    const [locationSettingsCheckedOptions, setLocationSettingsCheckedOptions] = useState<
        CheckboxValueType[]
    >([]);
    const [googleMaps, setGoogleMaps] = useState<any>();
    const [geocoder, setGeocoder] = useState<any>();
    const [center, setCenter] = useState<Coords>(mapCenter);
    const [marker, setMarker] = useState<MarkerModel | null>();
    const [zoom, setZoom] = useState<number>(defaultMapZoom);
    const [isSeachingLocation, setIsSearchingLocation] = useState<boolean>(false);
    const { t } = useTranslation();

    const [activeFormStep, setActiveFormStep] = useState<CreateLocationFormStep>(
        CreateLocationFormStep.Location
    );
    const modalRef = useRef<HTMLDivElement>(null);

    const formSteps = Object.keys(CreateLocationFormStep).map((step: string, i: number) => {
        return {
            active: step === activeFormStep,
            stepNumber: i + 1,
            name: step,
        } as StepInfo;
    });

    const isEdition = !!campusData;

    const editionData = {
        id: isEdition && campusData?.id ? campusData.id : '',
        mainImage:
            isEdition && campusData?.mainImageUrl
                ? [
                      {
                          url: campusData.mainImageUrl,
                          uid: 'mainImage',
                      } as UploadFile,
                  ]
                : undefined,
        appHomeBackgroundImage:
            isEdition && campusData?.appHomeBackgroundImageUrl
                ? [
                      {
                          url: campusData.appHomeBackgroundImageUrl,
                          uid: 'appHomeBackgroundImage',
                      } as UploadFile,
                  ]
                : undefined,
        images:
            isEdition && campusData?.images
                ? campusData.images.map(
                      (image) =>
                          ({
                              url: image?.url,
                              uid: image?.id,
                          } as UploadFile)
                  )
                : undefined,
        description: isEdition && campusData?.description ? campusData.description : '',
        marketingDescription:
            isEdition && campusData?.shortDescription ? campusData.shortDescription : '',
        perks: isEdition && campusData?.perks ? campusData.perks : '',
        buildingEssentials:
            isEdition && campusData?.buildingEssentials ? campusData.buildingEssentials : '',
        rulesAndAgreements:
            isEdition && campusData?.rulesAndAgreements ? campusData.rulesAndAgreements : '',
        welcomeMessage: isEdition && campusData?.welcomeMessage ? campusData.welcomeMessage : '',
    };

    const modalTexts = {
        title: isEdition ? t('Location.edit_location') : t('Location.create_new_location'),
        submitButton: isEdition ? t('save_changes') : t('Location.save_new_location'),
        confirmPositiveText: isEdition
            ? t('Location.edit_location_confirm_positive')
            : t('Location.create_location_confirm_positive'),
    };

    const locationSettingsOptions = [
        { label: t('Location.management_location_details_setting_hasInvita'), value: 'hasInvita' },
        {
            label: t('Location.management_location_details_setting_hasCoffeeOrders'),
            value: 'hasCoffeeOrders',
        },
    ];

    const statusOptions: SelectOption[] = Object.keys(CampusStatusTypeDto).map((option) => ({
        id: option,
        name: t(`Location.campus_status_type_${option}`),
    }));

    if (!isEdition) {
        const disabledStatusIndex = statusOptions.findIndex(
            (status) => status.id === CampusStatusTypeDto.Disabled
        );

        if (disabledStatusIndex !== -1) statusOptions.splice(disabledStatusIndex, 1);
    }

    const timeZonesOptions: SelectOption[] = timezones.map((option) => ({
        id: option.id,
        name: `(GMT${moment.tz(new Date(), option.id).format('Z')}) ${option.description}`,
    }));

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        locationResetErrors();
        contactResetErrors();
        addressResetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Location" />,
                title: t('Booking.book_a_room_confirm_title'),
                message: t('Booking.book_a_room_confirm_message'),
                positiveText: modalTexts.confirmPositiveText,
                negativeText: t('Booking.book_a_room_confirm_negative'),
            }))
        )
            return;
        dismiss();
    };

    const validateLocationForm = async (
        formValues: any
    ): Promise<{ isValid: boolean; contactData?: {}; addressData?: {}; locationData?: {} }> => {
        const contactData = {
            phone1: formValues.phone1,
            email: formValues.email,
            website: formValues.website,
            twitterUrl: formValues.twitterUrl,
            facebookUrl: formValues.facebookUrl,
            linkedInUrl: formValues.linkedInUrl,
            instagramUrl: formValues.instagramUrl,
            angelListUrl: formValues.angelListUrl,
        };

        const addressData = {
            addressLine1: formValues.addressLine1,
            city: formValues.city,
            state: formValues.state,
            country: formValues.country,
            postalCode: formValues.postalCode,
        };

        const locationData = {
            name: formValues.name,
            legalName: formValues.legalName,
            status: formValues.status,
            openingDate:
                formValues.status === CampusStatusTypeDto.PreActive
                    ? formValues.openingDate
                    : undefined,
            description: formValues.description,
            marketingDescription: formValues.marketingDescription,
            perks: formValues.perks,
            buildingEssentials: formValues.buildingEssentials,
            rulesAndAgreements: formValues.rulesAndAgreements,
            welcomeMessage: formValues.welcomeMessage,
            timeZone: formValues.timeZone,
            latitude: formValues.latitude,
            longitude: formValues.longitude,
            hasInvita: locationSettingsCheckedOptions.includes('hasInvita'),
            hasCoffeeOrders: locationSettingsCheckedOptions.includes('hasCoffeeOrders'),
        };

        const locationIsValid = await locationValidateForm(locationData);
        const contactIsValid = await contactValidateForm(contactData);
        const addressIsValid = await addressValidateForm(addressData);

        if (!locationIsValid || !contactIsValid || !addressIsValid) return { isValid: false };
        else return { isValid: true, contactData, addressData, locationData };
    };

    const submit = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            const formValues = form.getFieldsValue();
            const validationResult = await validateLocationForm(formValues);

            if (!validationResult.isValid) return;
            
            const mainImage = mainImageDetails
                ? ({
                      delete: mainImageDetails[0].isDeleted,
                      uploadBase64: mainImageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null;

            const appHomeBackgroundImage = appHomeBackgroundImageDetails
                ? ({
                      delete: appHomeBackgroundImageDetails[0].isDeleted,
                      uploadBase64: appHomeBackgroundImageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null;

            const images = imagesDetails
                ? imagesDetails
                      ?.filter((image) => image.isDeleted || (!image.isDeleted && image.base64))
                      .map(
                          (image) =>
                              ({
                                  delete: image.isDeleted,
                                  uploadBase64: image.base64,
                                  id: image.id,
                              } as UpdateFileRequestDto)
                      )
                : null;
            
                const campusRequest: CreateCampusRequestDto = {
                    ...validationResult.locationData,
                    images: images,
                    appHomeBackgroundImage: appHomeBackgroundImage,
                    mainImage: mainImage,
                    contactInfo: validationResult.contactData,
                    address: validationResult.addressData,
                };
    

            let locationId = isEdition ? editionData.id : '';

            if (isEdition) {
                await campusService.updateCampus(locationId, campusRequest);
            } else {
                locationId = await campusService.createCampus(campusRequest);
                try {
                    const unitPricingRequest: UpdateCampusUnitPricingRequestDto = {
                        communalAreaBasePrice:
                            formValues.communalAreaBasePrice === '' ||
                            formValues.communalAreaBasePrice === undefined
                                ? 0
                                : formValues.communalAreaBasePrice,
                        pricePerDesk:
                            formValues.pricePerDesk === '' || formValues.pricePerDesk === undefined
                                ? 0
                                : formValues.pricePerDesk,
                        pricePerSquareFoot:
                            formValues.pricePerSquareFoot === '' ||
                            formValues.communalAreaBasePrice === undefined
                                ? 0
                                : formValues.pricePerSquareFoot,
                    };

                    await campusService.updateUnitPricing(locationId, unitPricingRequest);
                } catch (e) {
                    if (!e.treated) {
                        toastStore.toast({
                            type: 'error',
                            message: t('Errors.location_unit_pricing_error'),
                        });
                    }
                }
                try {
                    const request: UpdateCampusOccupancyObjectiveRequestDto = {
                        dueDate: formValues.dueDate ?? undefined,
                        rate: formValues.rate ?? undefined,
                    };
                    await campusService.updateOccupancyObjective(locationId, request);
                } catch (e) {
                    if (!e.treated) {
                        toastStore.toast({
                            type: 'error',
                            message: t('Errors.location_occupancy_objective_error'),
                        });
                    }
                }
            }

            dismiss(true);
        } catch (e) {
            toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const handleStatusChange = (value: CampusStatusTypeDto): void => {
        setLocationStatus(value);
    };

    const handleMainImageChange = (images: ImageDetails[] | undefined): void => {
        setMainImageDetails(images);
    };

    const handleBackgroundImageChange = (images: ImageDetails[] | undefined): void => {
        setAppHomeBackgroundImageDetails(images);
    };

    const handleImagesChange = (images: ImageDetails[] | undefined): void => {
        setImagesDetails(images);
    };

    const handleDescriptionChange = (value: string): void => {
        form.setFieldsValue({ description: value });
    };

    const handleMarketingDescriptionChange = (value: string): void => {
        form.setFieldsValue({ marketingDescription: value });
    };

    const handlePerksChange = (value: string): void => {
        form.setFieldsValue({ perks: value });
    };

    const handleBuildingEssentialsChange = (value: string): void => {
        form.setFieldsValue({ buildingEssentials: value });
    };

    const handleWelcomeMessageChange = (value: string): void => {
        form.setFieldsValue({ welcomeMessage: value });
    };

    const handleLocationSettingsOptionsChange = (checkedValues: CheckboxValueType[]): void => {
        setLocationSettingsCheckedOptions(checkedValues);
    };

    const handleOnGoogleApiLoaded = ({ map, maps }: any): void => {
        setGoogleMaps(maps);
        setGeocoder(new maps.Geocoder());
    };

    const handleAddressSearch = (): void => {
        setIsSearchingLocation(true);
        const address = form.getFieldValue('addressSearch');

        geocoder.geocode({ address: address }, function (results: any, status: any) {
            if (status === googleMaps.GeocoderStatus.OK) {
                const latitude = results[0].geometry.location.lat();
                const longitude = results[0].geometry.location.lng();

                form.setFieldsValue({
                    latitude: latitude,
                    longitude: longitude,
                });

                setCenter({ lat: latitude, lng: longitude });

                setMarker({
                    lat: latitude,
                    lng: longitude,
                    img: markerImg,
                });

                setZoom(mapZoom);
            }
            setIsSearchingLocation(false);
        });
    };

    const handleUseLocationAddress = (): void => {
        const locationAddressInfo = [];
        const address = form.getFieldValue('addressLine1');
        const city = form.getFieldValue('city');
        const state = form.getFieldValue('state');
        const postalCode = form.getFieldValue('postalCode');
        const country = form.getFieldValue('country');

        if (address) {
            locationAddressInfo.push(address.trim());
        }

        if (city) {
            locationAddressInfo.push(city.trim());
        }

        if (state) {
            locationAddressInfo.push(state.trim());
        }

        if (postalCode) {
            locationAddressInfo.push(postalCode.trim());
        }

        if (country) {
            locationAddressInfo.push(country.trim());
        }

        form.setFieldsValue({ addressSearch: locationAddressInfo.join(', ') });
    };

    const selectOptions = (options: SelectOption[]): ReactElement[] => {
        return options.map((option) => (
            <Select.Option key={option.id} value={option.id}>
                {option.name}
            </Select.Option>
        ));
    };

    const onBackClick = (): void => {
        if (activeFormStep === CreateLocationFormStep.UnitPricing)
            setActiveFormStep(CreateLocationFormStep.Location);
        else if (activeFormStep === CreateLocationFormStep.OccupancyObjective)
            setActiveFormStep(CreateLocationFormStep.UnitPricing);

        if (modalRef.current) modalRef.current.scrollIntoView();
    };

    const onNextClick = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        if (activeFormStep === CreateLocationFormStep.Location) {
            if ((await validateLocationForm(formValues)).isValid) {
                setActiveFormStep(CreateLocationFormStep.UnitPricing);
                if (modalRef.current) modalRef.current.scrollIntoView();
            }
        }

        if (activeFormStep === CreateLocationFormStep.UnitPricing) {
            setActiveFormStep(CreateLocationFormStep.OccupancyObjective);
            if (modalRef.current) modalRef.current.scrollIntoView();
        }

        if (activeFormStep === CreateLocationFormStep.OccupancyObjective) submit();
    };

    const actionButtons = {
        back: {
            label: activeFormStep === CreateLocationFormStep.Location ? t('cancel') : t('back'),
            onClick: activeFormStep === CreateLocationFormStep.Location ? exit : onBackClick,
        },
        next: {
            label:
                activeFormStep === CreateLocationFormStep.OccupancyObjective
                    ? t('submit')
                    : t('next'),
            onClick:
                activeFormStep === CreateLocationFormStep.OccupancyObjective ? submit : onNextClick,
        },
    };

    useEffect(() => {
        if (isEdition) {
            const settingsCheckedValues = [];
            campusData?.hasInvita && settingsCheckedValues.push('hasInvita');
            campusData?.hasCoffeeOrders && settingsCheckedValues.push('hasCoffeeOrders');
            const addressLines =
                campusData?.address?.addressLine1 +
                `${
                    campusData?.address?.addressLine2
                        ? ', ' + campusData?.address?.addressLine2
                        : ''
                }`;
            const fullAddress =
                addressLines &&
                campusData?.address?.city &&
                campusData?.address?.stateCode &&
                campusData?.address?.postalCode &&
                campusData?.address?.country
                    ? `${addressLines}, ${campusData?.address?.city}, ${campusData?.address?.stateCode}, ${campusData?.address?.postalCode}, ${campusData?.address?.country}`
                    : undefined;

            const lat = campusData?.location?.latitude ? campusData?.location?.latitude : undefined;
            const lng = campusData?.location?.longitude
                ? campusData?.location?.longitude
                : undefined;

            form.setFieldsValue({
                name: campusData?.name ?? undefined,
                legalName: campusData?.legalEntityName ?? undefined,
                status: campusData?.status ?? undefined,
                openingDate: campusData?.openingDate ?? undefined,
                mainImage: campusData?.mainImageUrl ?? undefined,
                description: campusData?.description ?? undefined,
                marketingDescription: campusData?.shortDescription ?? undefined,
                perks: campusData?.perks ?? undefined,
                buildingEssentials: campusData?.buildingEssentials ?? undefined,
                welcomeMessage: campusData?.welcomeMessage ?? undefined,
                rulesAndAgreements: campusData?.rulesAndAgreements ?? undefined,
                phone1: campusData?.contactInfo?.phone1 ?? undefined,
                email: campusData?.contactInfo?.email ?? undefined,
                website: campusData?.contactInfo?.website ?? undefined,
                twitterUrl: campusData?.contactInfo?.twitterUrl ?? undefined,
                facebookUrl: campusData?.contactInfo?.facebookUrl ?? undefined,
                linkedInUrl: campusData?.contactInfo?.linkedInUrl ?? undefined,
                instagramUrl: campusData?.contactInfo?.instagramUrl ?? undefined,
                angelListUrl: campusData?.contactInfo?.angelListUrl ?? undefined,
                addressLine1: addressLines,
                city: campusData?.address?.city ?? undefined,
                state: campusData?.address?.stateCode ?? undefined,
                postalCode: campusData?.address?.postalCode ?? undefined,
                addressSearch: fullAddress,
                timeZone: campusData?.timeZone ?? undefined,
                latitude: campusData?.location?.latitude ?? undefined,
                longitude: campusData?.location?.longitude ?? undefined,
                hasInvita: campusData?.hasInvita ?? undefined,
                hasCoffeeOrders: campusData?.hasCoffeeOrders ?? undefined,
                appHomeBackgroundImage: campusData?.appHomeBackgroundImageUrl ?? undefined,
                images: campusData?.imageUrls ?? undefined,
            });

            if (googleMaps && lat && lng) {
                setMarker({
                    lat: lat,
                    lng: lng,
                    img: markerImg,
                });
                setCenter({
                    lat: lat,
                    lng: lng,
                });
                setZoom(mapZoom);
            }
            setLocationSettingsCheckedOptions(settingsCheckedValues);
            setLocationStatus(campusData?.status ?? CampusStatusTypeDto.Active);
        }
    }, [form, campusData, googleMaps]);

    return (
        <BaseModal
            visible={visible}
            title={modalTexts.title}
            className="CreateLocationModal FormModal"
            onCancel={exit}
        >
            <div ref={modalRef}>
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    {!isEdition && (
                        <Row gutter={formGutter}>
                            <Col span={24}>
                                <FormStep Steps={formSteps} />
                            </Col>
                        </Row>
                    )}

                    <div
                        className={
                            activeFormStep === CreateLocationFormStep.Location ? '' : 'd-none'
                        }
                    >
                        <Title level={4}>{t('basic_information')}</Title>

                        <Row gutter={formGutter}>
                            <Col span={12}>
                                <ValidatedFormItem
                                    name="name"
                                    label={t('name')}
                                    required={true}
                                    errors={locationErrors}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={12} className="legalName-wrapper">
                                <Tooltip title={t('Location.legal_entity_name')}>
                                    <Icon iconName="Info" />
                                </Tooltip>

                                <ValidatedFormItem
                                    name="legalName"
                                    label={t('Location.legal_entity_name')}
                                    required={true}
                                    errors={locationErrors}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={12}>
                                <ValidatedFormItem
                                    name="status"
                                    label={t('status')}
                                    required={true}
                                    errors={locationErrors}
                                    initialValue={CampusStatusTypeDto.Active}
                                >
                                    <Select onChange={handleStatusChange}>
                                        {selectOptions(statusOptions)}
                                    </Select>
                                </ValidatedFormItem>
                            </Col>

                            {locationStatus === CampusStatusTypeDto.PreActive && (
                                <Col span={12}>
                                    <ValidatedFormItem
                                        name="openingDate"
                                        label={t('Location.opening_date')}
                                        required={true}
                                        errors={locationErrors}
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>
                            )}

                            <Col span={24}>
                                <ValidatedFormItem label={t('main_image')} name="mainImage">
                                    <ImagePicker
                                        images={editionData.mainImage}
                                        setImagesDetails={handleMainImageChange}
                                    />
                                </ValidatedFormItem>
                            </Col>
                        </Row>

                        <Row gutter={formGutter}>
                            <Col className="ReactQuill" span={24}>
                                <ValidatedFormItem name="description" label={t('description')}>
                                    <Input hidden />
                                </ValidatedFormItem>

                                <ReactQuill
                                    theme="snow"
                                    onChange={handleDescriptionChange}
                                    value={
                                        form.getFieldValue('description') ||
                                        editionData.description ||
                                        null
                                    }
                                />
                            </Col>
                        </Row>

                        <Row gutter={formGutter}>
                            <Col className="ReactQuill" span={24}>
                                <ValidatedFormItem
                                    name="marketingDescription"
                                    label={t('marketing_description')}
                                >
                                    <Input hidden />
                                </ValidatedFormItem>

                                <ReactQuill
                                    theme="snow"
                                    onChange={handleMarketingDescriptionChange}
                                    value={
                                        form.getFieldValue('marketingDescription') ||
                                        editionData.marketingDescription ||
                                        null
                                    }
                                />
                            </Col>

                            <Col className="ReactQuill" span={24}>
                                <ValidatedFormItem
                                    name="perks"
                                    label={t('Location.location_perks')}
                                >
                                    <Input hidden />
                                </ValidatedFormItem>

                                <ReactQuill
                                    theme="snow"
                                    onChange={handlePerksChange}
                                    value={form.getFieldValue('perks') || editionData.perks || null}
                                />
                            </Col>

                            <Col className="ReactQuill" span={24}>
                                <ValidatedFormItem
                                    name="buildingEssentials"
                                    label={t('Location.building_essentials')}
                                >
                                    <Input hidden />
                                </ValidatedFormItem>

                                <ReactQuill
                                    theme="snow"
                                    onChange={handleBuildingEssentialsChange}
                                    value={
                                        editionData.buildingEssentials ||
                                        form.getFieldValue('buildingEssentials') ||
                                        null
                                    }
                                />
                            </Col>

                            <Col span={24}>
                                <ValidatedFormItem
                                    name="rulesAndAgreements"
                                    label={t('Location.rules_agreements')}
                                    required={true}
                                    errors={locationErrors}
                                >
                                    <TextArea />
                                </ValidatedFormItem>
                            </Col>
                        </Row>

                        <Title level={4}>{t('welcome_message')}</Title>

                        <Row gutter={formGutter}>
                            <Col className="ReactQuill" span={24}>
                                <ValidatedFormItem
                                    name="welcomeMessage"
                                    label={t('Location.location_creation_welcome_message_info')}
                                >
                                    <Input hidden />
                                </ValidatedFormItem>

                                <ReactQuill
                                    theme="snow"
                                    onChange={handleWelcomeMessageChange}
                                    value={
                                        editionData.welcomeMessage ||
                                        form.getFieldValue('welcomeMessage') ||
                                        null
                                    }
                                />
                            </Col>
                        </Row>

                        <Title level={4}>{t('contact_information')}</Title>

                        <Row gutter={formGutter}>
                            <Col span={12}>
                                <ValidatedFormItem
                                    name="phone1"
                                    label={t('phone_number')}
                                    required={true}
                                    errors={contactErrors}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={12}>
                                <ValidatedFormItem
                                    name="email"
                                    label={t('email')}
                                    required={true}
                                    errors={contactErrors}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={24}>
                                <ValidatedFormItem
                                    name="website"
                                    label={t('website')}
                                    required={true}
                                    errors={contactErrors}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Col>
                        </Row>

                        <Title level={4}>{t('Location.social_media_contacts')}</Title>

                        <Row gutter={formGutter}>
                            <Col span={12}>
                                <ValidatedFormItem name="twitterUrl" label={t('twitter_url')}>
                                    <Input />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={12}>
                                <ValidatedFormItem name="facebookUrl" label={t('facebook_url')}>
                                    <Input />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={12}>
                                <ValidatedFormItem name="linkedInUrl" label={t('linkedIn_url')}>
                                    <Input />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={12}>
                                <ValidatedFormItem name="instagramUrl" label={t('instagram_url')}>
                                    <Input />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={12}>
                                <ValidatedFormItem name="angelListUrl" label={t('angelList_url')}>
                                    <Input />
                                </ValidatedFormItem>
                            </Col>
                        </Row>

                        <Title level={4}>{t('address')}</Title>

                        <Row gutter={formGutter}>
                            <Col span={24}>
                                <ValidatedFormItem
                                    name="addressLine1"
                                    label={t('address')}
                                    required={true}
                                    errors={addressErrors}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={6}>
                                <ValidatedFormItem
                                    name="city"
                                    label={t('city')}
                                    required={true}
                                    errors={addressErrors}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={6}>
                                <GlobalizationSelect
                                    selectType="state"
                                    name="state"
                                    label={t('state')}
                                    required={true}
                                    errors={addressErrors}
                                />
                            </Col>

                            <Col span={6}>
                                <GlobalizationSelect
                                    selectType="country"
                                    name="country"
                                    label={t('country')}
                                    initialValue="US"
                                    disabled
                                />
                            </Col>

                            <Col span={6}>
                                <ValidatedFormItem
                                    name="postalCode"
                                    label={t('zip_code')}
                                    required={true}
                                    errors={addressErrors}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Col>
                        </Row>

                        <Title level={4}>{t('geographical_position')}</Title>

                        <Row gutter={formGutter}>
                            <Col span={24} className="timeZone-wrapper">
                                <div className="subLabel">{t('Location.time_zone_sub_label')} </div>

                                <ValidatedFormItem
                                    name="timeZone"
                                    label={t('time_zone')}
                                    required={true}
                                    errors={locationErrors}
                                >
                                    <Select disabled={isEdition}>
                                        {selectOptions(timeZonesOptions)}
                                    </Select>
                                </ValidatedFormItem>
                            </Col>

                            <Col span={12}>
                                <ValidatedFormItem
                                    name="latitude"
                                    label={t('latitude')}
                                    required={true}
                                    errors={locationErrors}
                                >
                                    <NumberInput min={-90} max={90} />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={12}>
                                <ValidatedFormItem
                                    name="longitude"
                                    label={t('longitude')}
                                    required={true}
                                    errors={locationErrors}
                                >
                                    <NumberInput min={-180} max={180} />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={24} className="addressSearch-wrapper">
                                <ValidatedFormItem name="addressSearch">
                                    <Input
                                        placeholder={t('Location.search_to_move_pin')}
                                        prefix={<Icon iconName="Search" />}
                                    />
                                </ValidatedFormItem>

                                <Button
                                    type="default"
                                    className="secondary negative"
                                    htmlType="button"
                                    onClick={handleAddressSearch}
                                    disabled={isSeachingLocation}
                                >
                                    {t('Location.map_search')}
                                </Button>

                                <Button
                                    type="primary"
                                    className="positive"
                                    htmlType="button"
                                    onClick={handleUseLocationAddress}
                                >
                                    {t('Location.map_fill_with_location')}
                                </Button>
                            </Col>
                        </Row>

                        <Row gutter={formGutter}>
                            <Col span={24} className="GoogleMapReact-wrapper">
                                <GoogleMapReact
                                    bootstrapURLKeys={{
                                        key: window.Environment.REACT_APP_GOOGLE_API_KEY || '',
                                    }}
                                    defaultCenter={mapCenter}
                                    zoom={zoom}
                                    center={center}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={handleOnGoogleApiLoaded}
                                >
                                    {marker && (
                                        <Marker
                                            lat={marker.lat}
                                            lng={marker.lng}
                                            img={marker.img}
                                        />
                                    )}
                                </GoogleMapReact>
                            </Col>
                        </Row>

                        <Title level={4}>{t('Location.location_settings')}</Title>

                        <Row gutter={formGutter}>
                            <Col span={24}>
                                <Checkbox.Group
                                    options={locationSettingsOptions}
                                    onChange={handleLocationSettingsOptionsChange}
                                    defaultValue={locationSettingsCheckedOptions}
                                />
                            </Col>
                        </Row>

                        <Title level={4}>{t('Location.app_home_background_image')}</Title>

                        <p className="background-image-info">
                            {t('Location.app_home_background_image_info')}
                        </p>

                        <Row gutter={formGutter}>
                            <Col span={24}>
                                <ValidatedFormItem name="appHomeBackgroundImage">
                                    <ImagePicker
                                        images={editionData.appHomeBackgroundImage}
                                        setImagesDetails={handleBackgroundImageChange}
                                    />
                                </ValidatedFormItem>
                            </Col>
                        </Row>

                        <Title level={4}>{t('images')}</Title>

                        <Row gutter={formGutter}>
                            <Col span={24}>
                                <ValidatedFormItem name="images">
                                    <ImagePicker
                                        allowMultiple
                                        images={editionData.images}
                                        setImagesDetails={handleImagesChange}
                                    />
                                </ValidatedFormItem>
                            </Col>
                        </Row>
                    </div>

                    {!isEdition && (
                        <div
                            className={
                                activeFormStep === CreateLocationFormStep.UnitPricing
                                    ? ''
                                    : 'd-none'
                            }
                        >
                            <EditPricingFormSection />
                        </div>
                    )}
                    {!isEdition && (
                        <div
                            className={
                                activeFormStep === CreateLocationFormStep.OccupancyObjective
                                    ? ''
                                    : 'd-none'
                            }
                        >
                            <SetOccupancyObjectiveFormSection />
                        </div>
                    )}

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={
                                isEdition ? (): Promise<void> => exit() : actionButtons.back.onClick
                            }
                        >
                            {isEdition ? t('cancel') : actionButtons.back.label}
                        </Button>

                        <Button
                            type="primary"
                            className="positive"
                            htmlType={isEdition ? 'submit' : 'button'}
                            onClick={isEdition ? undefined : actionButtons.next.onClick}
                        >
                            {isEdition ? modalTexts.submitButton : actionButtons.next.label}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
};

export default React.memo(CreateLocationModal);
