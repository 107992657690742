import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Wallet: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    d="M22.1374 6.75994H21.1734L21.1092 1.42348C21.1077 1.28903 21.0435 1.16223 20.9335 1.08126C20.8235 1.00182 20.683 0.9789 20.5531 1.02015L2.31013 6.77521L2.29791 6.7798C2.27805 6.78591 2.25972 6.79355 2.24139 6.80271C2.22152 6.81188 2.20319 6.82257 2.18639 6.83479C2.17722 6.83938 2.16958 6.84549 2.16194 6.85313C2.14819 6.86229 2.13597 6.87299 2.12528 6.88521C2.11 6.90049 2.09625 6.91577 2.08403 6.9341C2.08403 6.9341 2.08097 6.93563 2.0825 6.93715C2.07028 6.9509 2.06111 6.96618 2.05194 6.98299C2.05042 6.98604 2.04889 6.9891 2.04736 6.99215C2.03667 7.01201 2.02903 7.03188 2.02139 7.05326C2.01375 7.07465 2.00917 7.09604 2.00611 7.11896C2.00153 7.14035 2 7.16326 2 7.18618V22.5722C2 22.8075 2.19097 23 2.42777 23H22.1374C22.3742 23 22.5667 22.809 22.5667 22.5722V7.18618C22.5667 6.9509 22.3742 6.75994 22.1374 6.75994ZM20.2598 2.01166L20.3163 6.75994H5.20676L20.2598 2.01166ZM21.7096 16.5681H18.3088V14.5561H21.7096V16.5681ZM21.7096 13.7005H17.881C17.6442 13.7005 17.4517 13.8915 17.4517 14.1298V16.9959C17.4517 17.2327 17.6427 17.4252 17.881 17.4252H21.7096V22.1445H2.85554V7.61548H21.7096V13.7005Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Wallet;
