import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { SimpleList } from 'Components/simple-list';
import { Content } from 'antd/lib/layout/layout';
import { useService, useStores } from 'Hooks';
import { CommunityEventService } from 'Services/CommunityEventService';
import { CommunityEventDto } from 'Api/Features/CommunityEvents/Dtos/CommunityEventDto';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import LocationHeader from 'Components/location-header/location-header';
import Icon from 'Components/icons/icon';
import { theme } from 'variant';
import moment from 'moment';
import {
    DAY_MONTH_DATE_YEAR_DISPLAY_FORMAT,
    DISPLAY_MONTH_YEAR_FORMAT,
    DISPLAY_TIME_12H_FORMAT,
} from 'Models/Constants';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { User } from 'Components/icons';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import CreateEventModal from '../create-event-modal';
import { ReactNode } from 'react-router/node_modules/@types/react';
import { Tag } from 'Components/tag';
import { TagColors } from 'Components/tag/tag';
import SimpleListContentWithSeeDetails, {
    SimpleListContentWithSeeDetailsProps,
} from 'Components/SimpleListContentWithSeeDetails';
import { observer } from 'mobx-react';

const EventDetail: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { id: locationId, eventId } = useParams<{ id: string; eventId: string }>();
    const communityEventService = useService(CommunityEventService);
    const { globalLoadingStore, toastStore, confirmationModalStore, userPermissionsStore } = useStores();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<CommunityEventDto>();
    const [duration, setDuration] = useState<SimpleListItemProps[]>([]);
    const [description, setDescription] = useState<SimpleListItemProps[]>([]);
    const [host, setHost] = useState<SimpleListItemProps[]>([]);
    const [externalspeaker, setExternalSpeaker] = useState<SimpleListItemProps[]>([]);
    const [speakerCompany, setSpeakerCompany] = useState<SimpleListContentWithSeeDetailsProps>();
    const [speaker, setSpeaker] = useState<SimpleListContentWithSeeDetailsProps>();

    const [editModalOpen, setEditModalOpen] = useState(false);

    const fetch = useCallback(async () => {
        try {
            globalLoadingStore.addLoading();
            setLoading(true);
            const response = await communityEventService.getCommunityEvent(eventId);
            if (response) {
                setData(response);
                setSimpleListsData(response);
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
            setLoading(false);
        }
    }, [communityEventService, eventId, t]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const setSimpleListsData = useCallback(
        (event: CommunityEventDto): void => {
            const date = event.isAllMonth
                ? moment(event.periodStart).format(DISPLAY_MONTH_YEAR_FORMAT)
                : moment(event.startDate).isSame(moment(event.endDate))
                ? moment(event.startDate).utc().format(DAY_MONTH_DATE_YEAR_DISPLAY_FORMAT)
                : `${moment(event.startDate)
                      .utc()
                      .format(DAY_MONTH_DATE_YEAR_DISPLAY_FORMAT)} - ${moment(event.endDate)
                      .utc()
                      .format(DAY_MONTH_DATE_YEAR_DISPLAY_FORMAT)}`;

            const time = event.isAllDay
                ? t('all_day')
                : `${moment(event.periodStart)
                      .tz(event.timeZone ?? '')
                      .format(DISPLAY_TIME_12H_FORMAT)} - ${moment(event.periodEnd)
                      .tz(event.timeZone ?? '')
                      .format(DISPLAY_TIME_12H_FORMAT)}`;

            setDuration([
                {
                    title: date,
                    description: time,
                },
            ]);

            setDescription([
                {
                    description: event.description ?? '',
                },
            ]);

            if (event.hostName || event.hostUrl || event.hostImageUrl) {
                const host: SimpleListItemProps[] = [];
                if (event.hostName || event.hostImageUrl || event.hostUrl) {
                    host.push({
                        title: event.hostName ?? '',
                        description: event.hostUrl ?? '',
                        avatar: (
                            <ImageWithPlaceholder
                                width="32"
                                height="32"
                                imgSrc={event.hostImageUrl}
                                defaultImg={<User />}
                            />
                        ),
                    });
                }
                setHost(host);
            } else setHost([]);

            if (event.speakerUrl || event.speakerName || event.speakerImageUrl) {
                const speaker: SimpleListItemProps[] = [];
                if (event.speakerImageUrl || event.speakerName || event.speakerUrl) {
                    speaker.push({
                        title: event.speakerName ?? '',
                        description: event.speakerUrl ?? '',
                        avatar: (
                            <ImageWithPlaceholder
                                width="32"
                                height="32"
                                imgSrc={event.speakerImageUrl}
                                defaultImg={<User />}
                            />
                        ),
                    });
                }

                setExternalSpeaker(speaker);
            } else setExternalSpeaker([]);

            if (event.speakerMembership && event.speakerMember) {
                setSpeakerCompany({
                    defaultIcon: <Icon iconName={'Company'} />,
                    seeDetailsUrl: `/companies/${event.speakerMembership.id}/dashboard`,
                    imageUrl: event.speakerMembership.imageUrl ?? undefined,
                    title: event.speakerMembership.name ?? undefined,
                });
                setSpeaker({
                    defaultIcon: <Icon iconName={'User'} />,
                    seeDetailsUrl: `/contacts/${event.speakerMember.id}/dashboard`,
                    imageUrl: event.speakerMember.imageUrl ?? undefined,
                    title:
                        event.speakerMember.firstName && event.speakerMember.lastName
                            ? `${event.speakerMember.firstName} ${event.speakerMember.lastName}`
                            : undefined,
                });
            } else {
                setSpeakerCompany(undefined);
                setSpeaker(undefined);
            }
        },
        [t]
    );

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'events',
            nameKey: 'events',
        },
        {
            nameKey: data?.title ?? '',
            path: data?.id ?? '',
        },
    ];

    const deleteEvent = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="CommunityEvent" />,
                title: t(`model_delete_confirm_title`, { param1: 'event' }),
                message: t(`action_cannot_be_undone`),
                positiveText: t('model_confirm_positive_delete', { param1: 'Event' }),
                negativeText: t('model_confirm_negative_delete', { param1: 'Event' }),
            }))
        )
            return;
        //call delete
        try {
            globalLoadingStore.addLoading();
            await communityEventService.deleteCommunityEvent(eventId);
            toastStore.toast({
                type: 'success',
                messageKey: t('model_delete_success', { param1: 'Event' }),
            });
            history.push(`/locations/${locationId}/events`);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const menuOptions = (): ActionMenuOption[] => {
        const options: ActionMenuOption[] = [
            {
                key: 'edit',
                title: t('CommunityEvent.edit_community_event'),
                action: () => setEditModalOpen(true),
            },
            {
                key: 'delete',
                title: t('CommunityEvent.delete_community_event'),
                action: () => deleteEvent(),
            },
        ];
        return options;
    };

    const headerBadges = useCallback((): ReactNode => {
        const badges = [];
        if (data?.isExternalSpeaker && externalspeaker.length === 0) {
            badges.push(<Tag label={t('CommunityEvent.no_speaker')} color={TagColors.disabled} />);
        } else if (data?.isExternalSpeaker) {
            badges.push(
                <Tag label={t('CommunityEvent.GetCommunityEventsFlagDto_IsExternalSpeaker')} />
            );
        } else {
            badges.push(
                <Tag label={t('CommunityEvent.GetCommunityEventsFlagDto_IsInternalSpeaker')} />
            );
        }

        if (data?.isFromSystem) {
            badges.push(
                <Tag
                    label={t('CommunityEvent.GetCommunityEventsFlagDto_IsFromSystem')}
                    color={TagColors.cold}
                />
            );
        }

        return <div>{badges}</div>;
    }, [data, externalspeaker]);

    return (
        <div>
            <LocationHeader
                title={data?.title ?? ''}
                subTitle={' '}
                imgSrc={data?.imageUrl}
                defaultImg={<Icon iconName="CommunityEvent" fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={
                    userPermissionsStore
                        .availableLocationsForUser
                        .some((loc) => loc.id === locationId) && (
                        <ActionMenu options={menuOptions()} type="primary" trigger="click" />
                    )
                }
                loading={loading}
                headerBadges={headerBadges()}
            />

            <Content>
                <Row gutter={40}>
                    <Col span={12}>
                        <Row>
                            {description && description.length > 0 && (
                                <Col span={24}>
                                    <SimpleList
                                        title={t('description')}
                                        data={description}
                                        largeDescription
                                    />
                                </Col>
                            )}

                            {duration && duration.length > 0 && (
                                <Col span={24}>
                                    <SimpleList title={t('date_and_time')} data={duration} />
                                </Col>
                            )}

                            {host && host.length > 0 && (
                                <Col span={24}>
                                    <SimpleList title={t('host')} data={host} />
                                </Col>
                            )}
                        </Row>
                    </Col>

                    <Col span={12}>
                        <Row>
                            {speakerCompany && (
                                <Col span={24}>
                                    <SimpleList title={t('company')}>
                                        <SimpleListContentWithSeeDetails
                                            seeDetailsUrl={speakerCompany.seeDetailsUrl}
                                            imageUrl={speakerCompany.imageUrl}
                                            defaultIcon={speakerCompany.defaultIcon}
                                            title={speakerCompany.title}
                                        />
                                    </SimpleList>
                                </Col>
                            )}

                            {speaker && (
                                <Col span={24}>
                                    <SimpleList title={t('speaker')}>
                                        <SimpleListContentWithSeeDetails
                                            seeDetailsUrl={speaker.seeDetailsUrl}
                                            imageUrl={speaker.imageUrl}
                                            defaultIcon={speaker.defaultIcon}
                                            title={speaker.title}
                                        />
                                    </SimpleList>
                                </Col>
                            )}

                            {externalspeaker && externalspeaker.length > 0 && (
                                <Col span={24}>
                                    <SimpleList title={t('speaker')} data={externalspeaker} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Content>

            {editModalOpen && (
                <CreateEventModal
                    visible={editModalOpen}
                    onComplete={(success: boolean) => {
                        setEditModalOpen(false);
                        if (success) fetch();
                    }}
                    event={data}
                />
            )}
        </div>
    );
});

export default EventDetail;
