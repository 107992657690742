import React, { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import LocationHeader from 'Components/location-header/location-header';
import FlexibleBooking, { FlexibleBookingViewType } from 'Components/booking/flexible-booking/flexible-booking';
import BookingModal from 'Routes/authenticated/booking/booking-modal';
import { useStores } from 'Hooks';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Company, Filter, PrivateRoom } from 'Components/icons';
import { Typography, Checkbox, Button } from 'antd';
import { AmenityPrivacyTypeDto } from 'Api/Features/Amenities/Dtos/AmenityPrivacyTypeDto';
import Select, { components } from 'react-select';
import DateControls from 'Components/booking/flexible-booking/date-controls';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { useService } from 'Hooks';

import './index.less';
import moment from 'moment';
import { DailyBookingSummaryAmenityDto } from 'Api/Features/Amenities/Dtos/DailyBookingSummaryAmenityDto';
import { BookingDto } from 'Api/Features/Amenities/Dtos/BookingDto';
import { BookingService } from 'Services/BookingService';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { images, theme } from 'variant';

interface ConferenceRoomFilters {
    roomTypes: AmenityPrivacyTypeDto[];
    roomIds: string[];
}

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'booking',
        nameKey: 'Location.bookings',
    },
];

const defaultMembershipImg = (Icon?: any) => (
    <div className="wg-6 hg-6 rounded-circle bg-light text-white align-item-center mr-1 membership-circle">
        {Icon && <Icon />}
    </div>
);

const privateRoomIcon = <div className="resource-private">{<PrivateRoom />}</div>;

// eslint-disable-next-line react/display-name
export const generateResourcePeriod = (t: any) => (period: any) => {
    if (period && period.booking) {
        if (period.unavailabilityReason === 'Booked') {
            return (
                <div className="booking-information d-flex align-items-center position-absolute text-white">
                    {period.booking.isAdministrative ? (
                        <div
                            style={{
                                backgroundImage: `url(${images.logoColorIcon})`,
                                backgroundColor: theme['bg-light'],
                            }}
                        />
                    ) : period.booking.membership && period.booking.membership.imageUrl ? (
                        <div
                            style={{
                                backgroundImage: `url(${period.booking.membership.imageUrl})`,
                            }}
                        />
                    ) : (
                        <ImageWithPlaceholder
                            defaultImg={defaultMembershipImg(Company)}
                            imgSrc={null}
                            className="FlexibleBooking__Booking_Image"
                        />
                    )}
                    {period.booking.isAdministrative ? (
                        <p>{t('brand_name')}</p>
                    ) : (
                        period.booking.membership && (
                            <p>
                                {period.booking.membership.name} <br /> {period.booking.title}
                            </p>
                        )
                    )}
                </div>
            );
        } else {
            return '';
        }
    } else {
        return null;
    }
};

// eslint-disable-next-line react/display-name
export const generateResourceHeader = (t: any) => (resource: any) => {
    return (
        <>
            <div className="resource-head-thumb">
                {
                    <ImageWithPlaceholder
                        defaultImg={defaultMembershipImg()}
                        imgSrc={resource.imageUrl}
                        className="FlexibleBooking__Amenity_Image mr-2"
                    />
                }
                <span className="resource-head-seats">
                    {resource.numberOfSeats}{' '}
                    <span className="people">
                        {t('Booking.people', {
                            count: resource.numberOfSeats,
                        })}
                    </span>
                </span>

                {resource.isPrivate && privateRoomIcon}
            </div>
            <h3 className="resource-title" title={resource.name}>
                {resource.name}
            </h3>
        </>
    );
};


const LocationBooking: FunctionComponent = observer(() => {
    const { id } = useParams<{id: string}>();
    const { t } = useTranslation();
    const { bookingStore, locationStore } = useStores();
    const [bookingModalOpen, setBookingModalOpen] = useState(false);
    const [newBookingInfo, setNewBookingInfo] = useState(false);
    const [booking, setBooking] = useState(false);
    const [selectedResource, setSelectedResource] = useState();
    const [timezone, setTimezone] = useState<string | null>();
    const [filterAmount, setFilterAmount] = useState<number>(Object.values(AmenityPrivacyTypeDto).length);
    const [showFilters, setShowFilters] = useState(false);
    const [activeFilters, setActiveFilters] = useState<ConferenceRoomFilters>({
        roomTypes: [AmenityPrivacyTypeDto.Private, AmenityPrivacyTypeDto.Public],
        roomIds: [],
    });
    const bookingService = useService(BookingService);

    const filterCheckboxes = Object.values(AmenityPrivacyTypeDto).map((key) => {
        return { key: key, checked: true };
    });
    const [filterCheckboxesState, setFilterCheckboxesState] = useState(filterCheckboxes);
    const [selectedDate, setSelectedDate] = useState(moment());
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        bookingStore.setDailyBookingSummary(id, selectedDate.format('YYYY-MM-DD'));
    }, [bookingStore, selectedDate, id]);

    useEffect(() => {
        const locationData = locationStore.locations.find((location) => location.id === id);
        if (locationData) {
            setTimezone(locationData.timeZone);
        }
    }, [locationStore.locations, id]);

    const getFilteredAmenities = ():
        | (DailyBookingSummaryAmenityDto | null)[]
        | null
        | undefined => {
        //no specific room selected and types are both selected or unselected => return all rooms
        if (
            activeFilters?.roomTypes.length < 1 &&
            activeFilters?.roomIds.length < 1 &&
            activeFilters?.roomTypes?.includes(AmenityPrivacyTypeDto.Private) &&
            activeFilters?.roomTypes?.includes(AmenityPrivacyTypeDto.Public)
        )
            return bookingStore.dailyBookingSummary;

        const privateRoomsChecked: boolean = activeFilters?.roomTypes?.includes(
            AmenityPrivacyTypeDto.Private
        );
        const publicRoomsChecked: boolean = activeFilters?.roomTypes?.includes(
            AmenityPrivacyTypeDto.Public
        );

        const result = bookingStore.dailyBookingSummary?.filter(function (room: any) {
            const isFilteredbyId =
                activeFilters.roomIds.length === 0 ||
                activeFilters.roomIds.some((id) => room.id === id);
            const isFilteredByType =
                privateRoomsChecked === publicRoomsChecked ||
                (publicRoomsChecked && !room?.isPrivate) ||
                (privateRoomsChecked && room?.isPrivate);
            const noCheckedFilter = !privateRoomsChecked && !publicRoomsChecked
            return isFilteredbyId && isFilteredByType && !noCheckedFilter;
        });
        return result;
    };

    const onBookingComplete = useCallback(
        (success: boolean) => {
            setBookingModalOpen(false);
            if (success) {
                bookingStore.setDailyBookingSummary(id, moment().format('YYYY-MM-DD'));
                setSelectedDate(moment());
            }
        },
        [bookingStore, id]
    );

    const getBookingInfo = async (id: string): Promise<BookingDto | null> => {
        return await bookingService.getBooking(id);
    };

    const onTimeCellClick = async (e: any, period: any, resource: any): Promise<void> => {
        if (period.bookingId !== undefined) {
            setSelectedResource(period.bookingAmenity[0]);
            const booking = await getBookingInfo(period.bookingId);
            setIsEditing(true);
            setNewBookingInfo(false);
            const amenityBooking: any = { bookingAmenity: period.bookingAmenity, ...booking };
            setBooking(amenityBooking);
        } else {
            setSelectedResource(period.bookingAmenity[0]);
            setIsEditing(false);
            setBooking(false);
            setNewBookingInfo(period);
        }
        setBookingModalOpen(true);
    };

    const handleDateChange = (selectedDate: any) => {
        setSelectedDate(moment(selectedDate));
    };

    const conferenceRoomsOptions = () => {
        return bookingStore.dailyBookingSummary?.map((x) => ({
            value: x?.id,
            label: x?.name,
            imageUrl: x?.imageUrl,
        }));
    };

    const onAdvancedFilterClick = () => {
        setShowFilters(!showFilters);
    };

    const onPrivacyTypeFilterChange = (
        privacyType: AmenityPrivacyTypeDto,
        checked: boolean
    ): void => {
        setFilterAmount(checked ? filterAmount + 1 : filterAmount - 1);
        const filter: ConferenceRoomFilters = activeFilters;
        if (checked) filter.roomTypes.push(privacyType);
        else {
            const index = filter.roomTypes.indexOf(privacyType);
            if (index !== -1) filter.roomTypes.splice(index, 1);
        }

        setActiveFilters(filter);
        const checkboxesStates = filterCheckboxesState;
        const index = checkboxesStates.findIndex((obj) => obj.key === privacyType);
        checkboxesStates[index].checked = checked;
        setFilterCheckboxesState(checkboxesStates);
    };

    const onConferenceRoomFilterChange = (e: any): void => {
        const filters = activeFilters;
        setActiveFilters({
            roomIds: e?.map((x: any) => x.value) || [],
            roomTypes: filters.roomTypes,
        });
    };

    const onClearFilters = (): void => {
        const filter: ConferenceRoomFilters = activeFilters;
        setActiveFilters({ roomIds: filter.roomIds, roomTypes: [] });
        setFilterAmount(0);
        setFilterCheckboxesState(
            filterCheckboxesState.map((x) => {
                return { key: x.key, checked: false };
            })
        );
    };

    // -- Region custom dropdown --
    const customSelectStyles: any = {
        option: (provided: any, state: any) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
        multiValue: (styles: any) => ({
            ...styles,
            backgroundColor: 'transparent',
            fontSize: '14px',
        }),
        multiValueRemove: (styles: any) => ({
            ...styles,
            display: 'none',
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            overflow: 'hidden',
            flexWrap: 'nowrap',
            padding: '9px 7px',
        }),
        menu: (styles: any) => ({
            ...styles,
            width: '170%',
        }),
        placeholder: (styles: any) => ({
            ...styles,
            fontFamily: 'Raleway',
            fontWeight: 'Bold',
            letterSpacing: '0.476191px',
            textTransform: 'uppercase',
            color: 'black',
        }),
        indicatorSeparator: (styles: any) => ({
            ...styles,
            display: 'none',
        }),
    };

    const Option = (props: any) => {
        return (
            <div>
                <components.Option {...props}>
                    <img
                        style={{ flex: '0 0 auto' }}
                        className="conference-room-dropdown-option-img"
                        width="40px"
                        height="40px"
                        src={props.data.imageUrl}
                        alt=""
                    />
                    {props.data.label}
                    <Checkbox
                        style={{ marginLeft: 'auto', flex: '0 0 auto' }}
                        checked={props.isSelected}
                    />
                </components.Option>
            </div>
        );
    };

    const ValueContainer = ({ children, ...props }: any): any => {
        //if more than two rooms are selected, we only show two elements in the value container
        if (children[0] != null && children[0].length >= 2) {
            return (
                <components.ValueContainer {...props}>
                    {children[0][0]}
                    {children[0][1]}...
                </components.ValueContainer>
            );
        } else {
            return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
        }
    };
    // -- End Region custom dropdown --

    return (
        <div className="LocationBooking">
            <LocationHeader routes={breadcrumbs} />
            <div className="FlexibleBooking__actions d-flex align-items-center justify-content-flex-start mb-1 flex-wrap mx-40 my-45">
                <div className="pr-20" style={{ width: '12%', zIndex: 1000 }}>
                    <Select
                        onChange={(e): void => onConferenceRoomFilterChange(e)}
                        options={conferenceRoomsOptions()}
                        isMulti
                        isClearable={false}
                        styles={customSelectStyles}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option, ValueContainer }}
                        placeholder={t('Filter.all_rooms')}
                        className="conference-dropdown"
                    />
                </div>
                <div className="pr-20">
                    <Button className="bordered-btn" onClick={onAdvancedFilterClick}>
                        {t('Filter.advanced_filters')}{' '}
                        <div className="room_filter_icon_container d-inline-block position-relative">
                            <Filter />
                            <span className="room_filter_icon_filter_count position-absolute d-flex justify-content-flex-start">
                                {filterAmount}
                            </span>
                        </div>
                    </Button>
                </div>

                <div onClick={onClearFilters}>
                    <Typography.Text className="clear-filters pr-50">
                        {t('Filter.clear_filters')}
                    </Typography.Text>
                </div>

                <DateControls
                    selectedDate={selectedDate}
                    onChange={handleDateChange}
                    showTodayButton={true}
                />

                {showFilters && (
                    <div className="advanced-filters">
                        <Typography.Title level={4}>
                            {t('Filter.advanced_filters')}
                        </Typography.Title>
                        <Typography.Text>{t('Filter.room_types')}</Typography.Text>
                        <div className="filter-section">
                            {filterCheckboxesState.map((item) => {
                                return (
                                    <Checkbox
                                        key={item.key}
                                        onChange={(e) =>
                                            onPrivacyTypeFilterChange(item.key, e.target.checked)
                                        }
                                        style={{ paddingRight: '12%' }}
                                        checked={item.checked}
                                    >
                                        {item.key}
                                    </Checkbox>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
            <FlexibleBooking
                view={FlexibleBookingViewType.bookings}
                isEditing={false}
                filters={[]}
                min={0}
                max={24}
                zoom={0}
                resizable={false}
                resources={getFilteredAmenities()}
                resourceHeader={generateResourceHeader(t)}
                resourcePeriod={generateResourcePeriod(t)}
                classNames="my-20 mx-40"
                onTimeCellClick={onTimeCellClick}
                timezone={timezone}
                selectedDate={selectedDate}
            />
            {bookingModalOpen && (
                <BookingModal
                    visible={bookingModalOpen}
                    onComplete={onBookingComplete}
                    newBookingInfo={newBookingInfo}
                    locationId={id}
                    resource={[selectedResource]}
                    resourcePeriod={generateResourcePeriod(t)}
                    timezone={timezone}
                    selectedDate={selectedDate}
                    booking={booking || undefined}
                    isEditing={isEditing}
                />
            )}
        </div>
    );
});

export default LocationBooking;
