import { Button, Col, Form, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { CreateSubscriptionProposalDiscountDto } from 'Api/Features/SubscriptionProposals/Dtos/CreateSubscriptionProposalDiscountDto';
import { SubscriptionInvoicePreviewDto } from 'Api/Features/SubscriptionProposals/Dtos/SubscriptionInvoicePreviewDto';
import { SubscriptionDetailsDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsDto';
import { UpdateSubscriptionDiscountsRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionDiscountsRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Discount as DiscountIcon } from 'Components/icons';
import Icon from 'Components/icons/icon';
import Discount from 'Components/proposal/discount';
import { useService, useStores } from 'Hooks';
import { UTC_DATE_TIME_ZONE } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionService } from 'Services/SubscriptionService';
import './edit-discounts-modal.less';
import ImpactOnInvoicesModal from './impact-on-invoices-modal';

interface EditDiscountsModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    subscription?: SubscriptionDetailsDto;
}

const formGutter: [Gutter, Gutter] = [40, 0];

const EditDiscountsModal: FunctionComponent<EditDiscountsModalProps> = ({
    visible,
    onComplete,
    subscription,
}) => {
    const subscriptionService = useService(SubscriptionService);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [impactOnInvoicesModalOpen, setImpactOnInvoicesModalOpen] = useState(false);
    const [adjustedInvoices, setAdjustedInvoices] = useState<SubscriptionInvoicePreviewDto[]>();
    const [createdInvoices, setCreatedInvoices] = useState<SubscriptionInvoicePreviewDto[]>();
    const isEdition = subscription?.discounts && subscription?.discounts?.length > 0 ? true : false;

    useEffect(() => {
        if (subscription) {
            form.setFieldsValue({
                discounts: subscription.discounts?.map((discount) => ({
                    title: discount?.title,
                    internalNote: discount?.internalNote,
                    startDate: discount?.startDate ? moment.utc(discount.startDate) : undefined,
                    endDate: discount?.endDate ? moment.utc(discount.endDate) : undefined,
                    amount: discount?.amount,
                })),
            });
        }
    }, [subscription]);

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <DiscountIcon />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: isEdition
                    ? t(`model_confirm_positive_edit`, { param1: 'discount' })
                    : t('Subscription.subscription_add_discounts_confirm_positive'),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const updateDiscounts = async (
        subscriptionId: string,
        request: UpdateSubscriptionDiscountsRequestDto
    ): Promise<void> => {
        await subscriptionService.updateSubscriptionDiscounts(subscriptionId, request);
        toastStore.toast({
            type: 'success',
            message: t('model_edit_success', { param1: 'discounts' }),
        });
        dismiss(true);
    };
    const handleSubmit = async (acceptedPreview: boolean): Promise<void> => {
        const formValues = form.getFieldsValue();

        const discounts = formValues.discounts?.map(
            (discount: any) =>
                ({
                    ...discount,
                    startDate: discount.startDate
                        ? moment(discount.startDate).format(UTC_DATE_TIME_ZONE)
                        : undefined,
                    endDate: discount.endDate
                        ? moment(discount.endDate).format(UTC_DATE_TIME_ZONE)
                        : undefined,
                    title: discount.title ?? t('discount'),
                } as CreateSubscriptionProposalDiscountDto)
        );

        const request: UpdateSubscriptionDiscountsRequestDto = {
            discounts,
        };

        try {
            globalLoadingStore.addLoading();
            if (subscription?.id) {
                if (acceptedPreview) {
                    await updateDiscounts(subscription.id, request);
                } else {
                    const preview = await subscriptionService.getUpdateSubscriptionDiscountsPreview(
                        subscription?.id!,
                        request
                    );
                    if (
                        (preview?.adjustedInvoices && preview.adjustedInvoices.length > 0) ||
                        (preview?.createdInvoices && preview.createdInvoices.length > 0)
                    ) {
                        setAdjustedInvoices(
                            preview?.adjustedInvoices
                                ?.filter((invoice) => invoice !== null)
                                .map((invoice) => invoice!)
                        );
                        setCreatedInvoices(
                            preview?.createdInvoices
                                ?.filter((invoice) => invoice !== null)
                                .map((invoice) => invoice!)
                        );
                        setImpactOnInvoicesModalOpen(true);
                    } else {
                        //no impact on invoices, no need to open impact modal just post.
                        await updateDiscounts(subscription.id, request);
                    }
                }
            }
        } catch (e) {
            if (e.response?.data?.error === 'E008018') {
                toastStore.toast({
                    type: 'error',
                    message: e.response.data.error_description,
                    displayTime: 15,
                });
            } else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const submit = async (): Promise<void> => {
        handleSubmit(false);
    };

    return (
        <BaseModal
            className="EditDiscountsModal FormModal"
            visible={visible}
            title={t(isEdition ? 'Subscription.edit_discounts' : 'Subscription.add_discounts')}
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Form.List name="discounts">
                    {(fields, { add, remove }): ReactNode => (
                        <>
                            {fields.map((field, index) => (
                                <Discount
                                    index={index}
                                    key={field.fieldKey}
                                    remove={remove}
                                    proposalStart={
                                        moment() /*take todays date because invoice may have already been closed for the subscription and discounts cannot be edited*/
                                    }
                                    proposalEnd={
                                        subscription?.endDate ? moment(subscription.endDate) : null
                                    }
                                    form={form}
                                />
                            ))}
                            <Row gutter={formGutter}>
                                <Col span={24} className="dynamic-list-button-container">
                                    <Button
                                        className={
                                            'ant-btn-primary ant-btn-circle ant-btn-lg ant-btn-icon-only'
                                        }
                                        onClick={(): void => add()}
                                    >
                                        <Icon iconName="Add" />
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form.List>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={exit}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" className="positive" htmlType="submit">
                        {t('save')}
                    </Button>
                </div>
            </Form>

            {impactOnInvoicesModalOpen && (
                <ImpactOnInvoicesModal
                    adjustedInvoices={adjustedInvoices}
                    createdInvoices={createdInvoices}
                    visible={impactOnInvoicesModalOpen}
                    onComplete={(success: boolean) => {
                        setImpactOnInvoicesModalOpen(false);
                        if (success) {
                            handleSubmit(true);
                        }
                    }}
                />
            )}
        </BaseModal>
    );
};

export default React.memo(EditDiscountsModal);
