import React, { FunctionComponent } from 'react';
import { ImageWithPlaceholderProps } from '../image-with-placeholder/image-with-placeholder';
import { ImageWithPlaceholder } from '../image-with-placeholder';
import './td-with-image.less';

const TdWithImage: FunctionComponent<ImageWithPlaceholderProps> = ({ children, ...imageProps }) => {
    return (
        <span className="TdWithImage">
            <span className="image">
                <ImageWithPlaceholder {...imageProps} />
            </span>
            {children}
        </span>
    );
};

export default TdWithImage;
