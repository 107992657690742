// GENERATED FILE - DO NOT MODIFY
import { CreateMembershipFileRequestDto } from 'Api/Features/Memberships/Dtos/CreateMembershipFileRequestDto';
import { GetMembershipFilesRequestDto } from 'Api/Features/Memberships/Dtos/GetMembershipFilesRequestDto';
import { GetMembershipFilesResponseDto } from 'Api/Features/Memberships/Dtos/GetMembershipFilesResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class MembershipFileProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getMembershipFiles(membershipId: string | null, request: GetMembershipFilesRequestDto | null): Promise<GetMembershipFilesResponseDto | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/files",
            { membershipId: membershipId },
            { ...request || undefined }
        );

        const data: GetMembershipFilesResponseDto | null = await this.httpClient.get<GetMembershipFilesResponseDto | null>(uri);
        return data;
    }

    public async createMembershipFile(membershipId: string | null, request: CreateMembershipFileRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/files",
            { membershipId: membershipId },
            null
        );

        const data: string = await this.httpClient.post<CreateMembershipFileRequestDto | null, string>(uri, request);
        return data;
    }

    public async deleteMembershipFile(membershipId: string | null, fileId: string): Promise<void> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/files/{fileId}",
            { membershipId: membershipId, fileId: fileId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}