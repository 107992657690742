import { SubscriptionDetailsDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsDto';
import BaseModal from 'Components/base-modal/base-modal';
import FloorPlanComponent, { FloorPlanViewType } from 'Components/floor-plan/floor-plan';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './floor-plan-modal.less';

interface FloorPlanModalProps {
    visible: boolean;
    onComplete: () => void;
    subscription: SubscriptionDetailsDto;
    specificUnitId?: string;
}

const FloorPlanModal: FunctionComponent<FloorPlanModalProps> = ({
    visible,
    onComplete,
    subscription,
    specificUnitId
}) => {
    const { t } = useTranslation();

    const dismiss = (): void => {
        onComplete();
    };

    return (
        <BaseModal
            visible={visible}
            title={t('floor_plan')}
            className="FloorPlanModal"
            onCancel={dismiss}
        >
            {subscription && (
                <FloorPlanComponent
                    campusId={subscription?.campusId ?? undefined}
                    proposalUnitIds={subscription?.units
                        ?.filter((unit) => unit !== null && unit.id)
                        .map((unit) => unit?.id!)}
                    membershipImage={subscription?.membership?.imageUrl ?? undefined}
                    height={700}
                    svgInstanceZoom={1.75}
                    specificUnitId={specificUnitId}
                    viewType={FloorPlanViewType.Subscription}
                />
            )}
        </BaseModal>
    );
};

export default React.memo(FloorPlanModal);
