import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { UploadFile } from 'antd/lib/upload/interface';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { GetSubscriptionOnboardingStatusResponseDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionOnboardingStatusResponseDto';
import { SubscriptionOnboardingCategoryStatusDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionOnboardingCategoryStatusDto';
import { SubscriptionOnboardingTaskStatusDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionOnboardingTaskStatusDto';
import { UpdateSubscriptionOnboardingCategoryDocumentsRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionOnboardingCategoryDocumentsRequestDto';
import { UpdateSubscriptionOnboardingStatusRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionOnboardingStatusRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Plan as PlanIcon } from 'Components/icons';
import { ImagePicker } from 'Components/image-picker';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useService, useStores } from 'Hooks';
import { ONBOARDING_CATEGORY_ID_SPACE } from 'Models/Constants';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionService } from 'Services/SubscriptionService';
import './space-preparation.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface SpacePreperationModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    subscriptionId: string;
    onboardingStatus?: GetSubscriptionOnboardingStatusResponseDto;
}

const SpacePreparationModal: FunctionComponent<SpacePreperationModalProps> = ({
    visible,
    onComplete,
    subscriptionId,
    onboardingStatus,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [images, setImages] = useState<ImageDetails[] | undefined>();
    const subscriptionService = useService(SubscriptionService);
    const [tasksStatuses, setTasksStatuses] = useState<SubscriptionOnboardingTaskStatusDto[]>();
    const [spaceCategory, setSpaceCategory] = useState<
        SubscriptionOnboardingCategoryStatusDto | null | undefined
    >();
    const [form] = Form.useForm();
    const [imageErrors, setImageErrors] = React.useState(new Map<string, string[]>());
    //#endregion

    useEffect(() => {
        const spacePrepCategory = onboardingStatus?.categories?.find(
            (category) => category?.onboardingCategoryId === ONBOARDING_CATEGORY_ID_SPACE
        );
        setSpaceCategory(spacePrepCategory);
        setTasksStatuses(spacePrepCategory!.tasks?.map((task) => task!));
    }, [onboardingStatus]);

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <PlanIcon />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`Subscription.yes_leave_this_step`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const processSubmit = async (wontDo: boolean): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            const formValues = form.getFieldsValue();

            const spaceImages: UpdateSubscriptionOnboardingCategoryDocumentsRequestDto | null = images
                ? {
                      documents: images
                          ?.filter((image) => image.isDeleted || (!image.isDeleted && image.base64))
                          .map(
                              (image) =>
                                  ({
                                      delete: image.isDeleted,
                                      uploadBase64: image.base64,
                                      id: image.id,
                                      fileName: image.fileName,
                                  } as UpdateFileRequestDto)
                          ),
                  }
                : null;
            if (spaceImages) {
                await subscriptionService.updateSubscriptionOnboardingCategoryDocuments(
                    subscriptionId,
                    ONBOARDING_CATEGORY_ID_SPACE,
                    spaceImages
                );
            }

            const updateModel: UpdateSubscriptionOnboardingStatusRequestDto = {
                categories: [...onboardingStatus?.categories!],
            };
            const spacePrepIndex = updateModel.categories?.findIndex(
                (category) => category?.onboardingCategoryId === ONBOARDING_CATEGORY_ID_SPACE
            );

            updateModel.categories![spacePrepIndex!] = {
                ...updateModel.categories![spacePrepIndex!],
                note: formValues['note'],
                tasks: tasksStatuses,
                wontDo: wontDo,
                isCompleted: wontDo
                    ? true
                    : tasksStatuses?.some((task) => !task.isCompleted) === false,
            };
            await subscriptionService.updateSubscriptionOnboardingStatus(
                subscriptionId,
                updateModel
            );

            toastStore.toast({
                type: 'success',
                messageKey: `Subscription.onboarding_step_updated`,
            });
            dismiss(true);
        } catch (e) {
            if (e.response?.data?.modelState?.['documents']) {
                const errors = new Map<string, string[]>();
                errors.set('pictures', [e.response?.data?.modelState['documents']]);
                setImageErrors(errors);
            } else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const submit = async (): Promise<void> => {
        await processSubmit(false);
    };

    const wontDo = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <PlanIcon />,
                title: t(`Subscription.are_you_sure_wont_do_step`),
                message: t(`action_cannot_be_undone`),
                positiveText: t(`Subscription.yes_wont_do`),
                negativeText: t(`Subscription.no_keep_step`),
            }))
        )
            return;
        await processSubmit(true);
    };
    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Subscription.space_preparation_and_inspection')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="SpacePreperation">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={12} className="please-confirm">
                            {t('Subscription.please_confirm_the_following')}
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24} className="tasks">
                            <ValidatedFormItem>
                                {tasksStatuses?.map((task) => (
                                    <Row key={task?.onboardingTaskId}>
                                        <Col span={24} className="task">
                                            <Checkbox
                                                onChange={(e): void => {
                                                    setTasksStatuses((state) => {
                                                        const index = state?.findIndex(
                                                            (task) =>
                                                                task.onboardingTaskId ===
                                                                e.target.name
                                                        );
                                                        const newState = [...state!];
                                                        newState[index!] = {
                                                            ...newState[index!],
                                                            isCompleted: e.target.checked,
                                                        };
                                                        return newState;
                                                    });
                                                }}
                                                value={task?.isCompleted}
                                                defaultChecked={task?.isCompleted}
                                                name={task?.onboardingTaskId}
                                            >
                                                {task?.onboardingTaskDescription}
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                ))}
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name="pictures"
                                label={t('Subscription.space_pictures')}
                                errors={imageErrors}
                            >
                                <ImagePicker
                                    allowMultiple
                                    images={
                                        spaceCategory?.documents
                                            ? spaceCategory.documents
                                                  .filter((image) => image !== null)
                                                  .map((image) => image!)
                                                  .map(
                                                      (image) =>
                                                          ({
                                                              url: image.url,
                                                              uid: image.id,
                                                          } as UploadFile)
                                                  )
                                            : undefined
                                    }
                                    setImagesDetails={(images: ImageDetails[] | undefined): void =>
                                        setImages(images)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem name="note" label={t('Lead.lead_add_a_note')}>
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            type="default"
                            className="secondary wont-do"
                            htmlType="button"
                            onClick={(): Promise<void> => wontDo()}
                        >
                            {t('wont_do')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('confirm')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(SpacePreparationModal);
