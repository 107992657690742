// GENERATED FILE - DO NOT MODIFY

export enum SubscriptionEventTypeDto {
    Request = "Request",
    ModificationRequest = "ModificationRequest",
    Proposal = "Proposal",
    ModificationProposal = "ModificationProposal",
    RequestRejected = "RequestRejected",
    ModificationRequestRejected = "ModificationRequestRejected",
    ProposalAccepted = "ProposalAccepted",
    ProposalRejected = "ProposalRejected",
    ModificationProposalAccepted = "ModificationProposalAccepted",
    ModificationProposalRejected = "ModificationProposalRejected",
    ProposalAcceptedByManager = "ProposalAcceptedByManager",
    ProposalRejectedByManager = "ProposalRejectedByManager",
    ModificationProposalAcceptedByManager = "ModificationProposalAcceptedByManager",
    ModificationProposalRejectedByManager = "ModificationProposalRejectedByManager",
    AgreementSigned = "AgreementSigned",
    Started = "Started",
    Ended = "Ended",
    Canceled = "Canceled",
    DayPass = "DayPass",
    UnitAdded = "UnitAdded",
    UnitRemoved = "UnitRemoved",
    ReferenceNumberModified = "ReferenceNumberModified",
    DiscountsModified = "DiscountsModified",
    OnboardingCategoryCompleted = "OnboardingCategoryCompleted",
    CommentAdded = "CommentAdded",
    SecurityDepositRetained = "SecurityDepositRetained",
    SecurityDepositRefunded = "SecurityDepositRefunded",
    TaskAdded = "TaskAdded",
    TaskUpdated = "TaskUpdated",
    TaskCancelled = "TaskCancelled",
    MeetingScheduled = "MeetingScheduled",
    MeetingUpdated = "MeetingUpdated",
    MeetingCancelled = "MeetingCancelled",
    ReminderScheduled = "ReminderScheduled",
    ReminderUpdated = "ReminderUpdated",
    ReminderCancelled = "ReminderCancelled",
}