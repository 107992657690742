import { FormInstance } from 'antd/lib/form';
import { StoredFileDto } from 'Api/Features/General/Dtos/StoredFileDto';
import { ActivityLogItemProps } from 'Components/activity-log-item/activity-log-item';
import ActivityLogList from 'Components/activity-log-list/activity-log-list';
import { SimpleList } from 'Components/simple-list';
import { Callbacks } from 'rc-field-form/lib/interface';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './activity-log-with-tabs.less';
import ActivityLogTabs, { ActivityLogTab } from 'Components/activity-log-tabs/activity-log-tabs';

export type ActivityLogUpcomingItem = {
    id: string;
    onEdit?: (value: string) => void;
    onDelete?: (value: string) => void;
} & ActivityLogItemProps;

export type ActivityLogItem = { id: string } & ActivityLogItemProps;

interface ActivityLogWithTabsProps {
    onFinish?: Callbacks['onFinish'];
    form: FormInstance;
    selectErrors: Map<string, string[]>;
    selectdefaultValue: any;
    selectOnChange: any;
    selectOptions: unknown;
    displayDatePicker?: boolean;
    datePickerErrors?: Map<string, string[]>;
    noteErrors: Map<string, string[]>;
    submitButtonLabel: string;
    upcomingActivities: (ActivityLogUpcomingItem | null)[];
    pastActivities: (ActivityLogUpcomingItem | null)[];
    historyList: (ActivityLogItem | null)[];
    categoryName?: string;
    locationErrors?: Map<string, string[]>;
    defaultSelectedLocationId?: string;
    onAddFileClick?: () => void;
    files?: StoredFileDto[];
    onFileClick?: (url?: string) => void;
    onFileDeleteClick?: (id?: string) => void;
    onFilesScrolledToBottom?: () => void;
    filesAreLoading?: boolean;
    note?: string | null;
    onEditNoteClick?: () => void;
    tabs: ActivityLogTab[];
}

const ActivityLogWithTabs: FunctionComponent<ActivityLogWithTabsProps> = ({
    onFinish,
    form,
    selectErrors,
    selectdefaultValue,
    selectOnChange,
    selectOptions,
    displayDatePicker,
    datePickerErrors,
    noteErrors,
    submitButtonLabel,
    upcomingActivities,
    pastActivities,
    historyList,
    categoryName,
    locationErrors,
    defaultSelectedLocationId,
    onAddFileClick,
    files,
    onFileClick,
    onFileDeleteClick,
    onFilesScrolledToBottom,
    filesAreLoading,
    note,
    onEditNoteClick,
    tabs,
    children,
}) => {
    const { t } = useTranslation();

    return (
        <div className="ActivityLogWithTabs">
            <ActivityLogTabs
                onFinish={onFinish}
                form={form}
                selectErrors={selectErrors}
                selectdefaultValue={selectdefaultValue}
                selectOnChange={selectOnChange}
                selectOptions={selectOptions}
                displayDatePicker={displayDatePicker}
                datePickerErrors={datePickerErrors}
                noteErrors={noteErrors}
                submitButtonLabel={submitButtonLabel}
                upcomingActivities={upcomingActivities}
                pastActivities={pastActivities}
                locationErrors={locationErrors}
                defaultSelectedLocationId={defaultSelectedLocationId}
                onAddFileClick={onAddFileClick}
                files={files}
                onFileClick={onFileClick}
                onFileDeleteClick={onFileDeleteClick}
                onFilesScrolledToBottom={onFilesScrolledToBottom}
                filesAreLoading={filesAreLoading}
                note={note}
                onEditNoteClick={onEditNoteClick}
                tabs={tabs}
            />
            {children}
            <SimpleList title={`${categoryName ? categoryName + ' ' : ''}${t('Activity.history')}`}>
                {historyList.length > 0 && <ActivityLogList lined activities={historyList} />}
            </SimpleList>
        </div>
    );
};

export default ActivityLogWithTabs;
