import { Button, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import { GetSecurityDepositsReportRequestDto } from 'Api/Features/Reports/Dtos/SecurityDeposits/GetSecurityDepositsReportRequestDto';
import Icon from 'Components/icons/icon';
import { ListSectionHeader } from 'Components/list-section-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import TableActionsButtons from 'Components/table-action-buttons/table-actions-buttons';
import { TableFilters } from 'Components/table-filters';
import { TdWithImage } from 'Components/td-with-image';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { ALL_LOCATIONS, MOMENT_PARSING_FORMAT } from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import moment from 'moment';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SecurityDepositsReportService } from 'Services/SecurityDepositsReportService';
import { FilterStore } from 'Stores';
import { showFile } from 'Utils';
import { cleanVal, currencyFormatter } from 'Utils/NumberUtils';
import { displayFormatedDate } from 'Utils/TimeUtils';
import { images, theme } from 'variant';
import './index.less';

const { usersHeader } = images;

const renderCurrency = (val?: number): string => currencyFormatter(cleanVal.number(val));

const renderCompany = (companyInfos: ExctractCompaniesList): ReactNode => (
    <TdWithImage defaultImg={<Icon iconName="Location" />} imgSrc={companyInfos.imageUrl}>
        {companyInfos.name}
    </TdWithImage>
);

const breadcrumbs: BreadcrumbSegment[] = [
    {
        nameKey: 'reports',
        path: 'reports',
    },
    {
        nameKey: 'Reports.security_deposits',
        path: 'security-deposits',
    },
];

const advancedFilters: AdvancedFilter[] = [];

interface SecurityDeposit {
    securityDepositOriginal: string;
    securityDepositHeld: string;
}

interface ExctractCompaniesList extends SecurityDeposit {
    key: number;
    id: string;
    name: string;
    imageUrl: string;
}

interface ExctractSubscriptionsList extends SecurityDeposit {
    id: string;
    campusName: string;
    referenceNumber: string;
    planName: string;
    startDate: string;
    endDate: string;
}

interface SecurityDepositsReportData {
    companies: ExctractCompaniesList[];
    subscriptions: ExctractSubscriptionsList[][];
    securityDepositTotal: SecurityDeposit;
}

const SecurityDeposits: FunctionComponent = observer(() => {
    const securityDepositsReportService = useService(SecurityDepositsReportService);
    const { locationStore, globalLoadingStore, toastStore, userPermissionsStore, requestStore } =
        useStores();
    const [securityDepositsReportData, setSecurityDepositsReportData] =
        useState<SecurityDepositsReportData>();
    const [securityDepositsReportRequest, setSecurityDepositsReporRequest] =
        useState<GetSecurityDepositsReportRequestDto>();
    const [rowKeysToExpand, setRowKeysToExpand] = useState<string[]>();
    const refRowKeysToExpand = useRef<string[]>([]);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { t } = useTranslation();

    const onExportClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            let document;
            if (securityDepositsReportRequest) {
                const campusName =
                    locationStore?.locations.find(
                        (x) => x.id === filterStoreRef.current.currentLocationId
                    )?.name || ALL_LOCATIONS;

                document = await securityDepositsReportService.exportSecurityDepositsReport(
                    securityDepositsReportRequest
                );

                showFile(
                    document,
                    `${t('Reports.security_deposits')} - ${moment(
                        securityDepositsReportRequest.date
                    ).format(MOMENT_PARSING_FORMAT)} - ${campusName}`
                );
            }
        } catch (e) {
            if (!e.treated) {
                if (e.treated) {
                    toastStore.genericError();
                }
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const tableActionButtons = [
        {
            iconName: 'UnExpandAll',
            toolTip: 'unexpand_all',
            onClick: (): void => setRowKeysToExpand([]),
        },
        {
            iconName: 'ExpandAll',
            toolTip: 'expand_all',
            onClick: (): void => setRowKeysToExpand(refRowKeysToExpand.current),
        },
        {
            iconName: 'Download',
            toolTip: 'export_table',
            onClick: (): Promise<void> => onExportClick(),
        },
    ];

    const companiesColumns: ColumnType<ExctractCompaniesList>[] = [
        {
            title: t('location'),
            render: (campusInfos): ReactNode => ({
                children: renderCompany(campusInfos),
                props: { colSpan: 5 },
            }),
        },
        {
            title: t('Subscription.subscription_reference_number'),
            render: (): ReactNode => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('plan'),
            render: (): ReactNode => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('start_date'),
            render: (): ReactNode => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('end_date'),
            render: (): ReactNode => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('Reports.security_deposit_original'),
            dataIndex: 'securityDepositOriginal',
        },
        {
            title: t('Reports.security_deposit_held'),
            dataIndex: 'securityDepositHeld',
        },
    ];

    const expandedRowRender = (record: ExctractCompaniesList): ReactNode => {
        const columns: ColumnType<ExctractSubscriptionsList>[] = [
            {
                title: t('empty'),
                render: (): string => '',
                key: 'empty',
            },
            {
                dataIndex: 'campusName',
            },
            {
                dataIndex: 'referenceNumber',
            },
            {
                dataIndex: 'planName',
            },
            {
                dataIndex: 'startDate',
            },
            {
                dataIndex: 'endDate',
            },
            {
                dataIndex: 'securityDepositOriginal',
            },
            {
                dataIndex: 'securityDepositHeld',
            },
        ];

        return (
            <Table
                showHeader={false}
                columns={columns}
                dataSource={securityDepositsReportData?.subscriptions[record.key]}
                rowKey={(record): string => record.id}
                pagination={false}
            />
        );
    };

    const renderTableFooter = (): ReactNode => (
        <>
            <div>{t('total')}</div>
            <div>{securityDepositsReportData?.securityDepositTotal.securityDepositOriginal}</div>
            <div>{securityDepositsReportData?.securityDepositTotal.securityDepositHeld}</div>
        </>
    );

    const fetchSecurityDepositsReport = useCallback(
        async (params: { date?: string; campusIds: string[] | null }) => {
            globalLoadingStore.addLoading();

            try {
                refRowKeysToExpand.current = [];

                const securityDepositsReportRequest: GetSecurityDepositsReportRequestDto = {
                    date: params.date,
                    campusIds: params.campusIds,
                };

                const securityDepositsReportResponse =
                    await securityDepositsReportService.getSecurityDepositsReport(
                        securityDepositsReportRequest
                    );

                const securityDepositTotal: SecurityDeposit = {
                    securityDepositOriginal: currencyFormatter(
                        securityDepositsReportResponse.totalSecurityDeposit
                    ),
                    securityDepositHeld: currencyFormatter(
                        securityDepositsReportResponse.totalHeldSecurityDeposit
                    ),
                };

                const exctractSubscriptionsList: ExctractSubscriptionsList[][] = [];
                const exctractCompaniesList: ExctractCompaniesList[] =
                    securityDepositsReportResponse.memberships.map((membership, i) => {
                        exctractSubscriptionsList[i] = membership.subscriptions
                            ? membership.subscriptions.map((subscription) => ({
                                  id: cleanVal.string(subscription?.id),
                                  campusName: cleanVal.string(subscription?.campus?.name),
                                  referenceNumber: cleanVal.string(
                                      subscription?.referenceNumber,
                                      'N/A'
                                  ),
                                  planName: cleanVal.string(subscription?.plan?.name),
                                  startDate: subscription?.startDate
                                      ? displayFormatedDate(
                                            subscription.startDate,
                                            MOMENT_PARSING_FORMAT
                                        )
                                      : '-',
                                  endDate: subscription?.endDate
                                      ? displayFormatedDate(
                                            subscription.endDate,
                                            MOMENT_PARSING_FORMAT
                                        )
                                      : '-',
                                  securityDepositOriginal: renderCurrency(
                                      subscription?.securityDeposit
                                  ),
                                  securityDepositHeld: renderCurrency(
                                      subscription?.heldSecurityDeposit
                                  ),
                              }))
                            : [];

                        refRowKeysToExpand.current.push(cleanVal.string(membership.id));

                        return {
                            key: i,
                            id: cleanVal.string(membership.id),
                            name: cleanVal.string(membership.name),
                            imageUrl: cleanVal.string(membership.imageUrl),
                            securityDepositOriginal: renderCurrency(
                                membership.totalSecurityDeposit
                            ),
                            securityDepositHeld: renderCurrency(
                                membership.totalHeldSecurityDeposit
                            ),
                        };
                    });

                setSecurityDepositsReportData({
                    companies: exctractCompaniesList,
                    subscriptions: exctractSubscriptionsList,
                    securityDepositTotal: securityDepositTotal,
                });
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [securityDepositsReportService, globalLoadingStore]
    );

    useEffect(() => {
        const filterStore = filterStoreRef.current;

        filterStore.date = moment().format(MOMENT_PARSING_FORMAT);
    }, []);

    useEffect(() => {
        const filterStore = filterStoreRef.current;

        const disposer = autorun(() => {
            const request = {
                date: moment(filterStore.date).format(MOMENT_PARSING_FORMAT),
                campusIds:
                    filterStore.currentLocationId === 'all'
                        ? requestStore.getReportRequestAvailableLocationIdsForUser()
                        : [filterStore.currentLocationId],
            };

            fetchSecurityDepositsReport(request);
            setSecurityDepositsReporRequest(request);
        });

        return (): void => {
            disposer();
        };
    }, [fetchSecurityDepositsReport]);

    return (
        <div className="SecurityDeposits">
            <ListSectionHeader
                routes={breadcrumbs}
                backgroundImageUrl={usersHeader}
                defaultImg={<Icon iconName="DollarShield" fill={theme['primary-color']} />}
                title={t('Reports.security_deposits')}
                subTitle={t('Reports.security_deposits_overview')}
            />

            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeDate
                    includeLocations
                    availableLocations={userPermissionsStore.availableLocationsForUser}
                />

                <TableActionsButtons buttonsList={tableActionButtons} />

                <Table
                    className="expand-table table-striped-rows table-action-rows"
                    rowClassName="company-row"
                    bordered
                    columns={companiesColumns}
                    dataSource={securityDepositsReportData?.companies}
                    expandable={{
                        expandedRowRender: expandedRowRender,
                        // eslint-disable-next-line react/display-name
                        expandIcon: ({ expanded, onExpand, record }): ReactNode =>
                            expanded ? (
                                <Button
                                    onClick={(e): void => {
                                        setRowKeysToExpand(undefined);
                                        onExpand(record, e);
                                    }}
                                >
                                    <Icon iconName="AngleTop" />
                                </Button>
                            ) : (
                                <Button
                                    onClick={(e): void => {
                                        setRowKeysToExpand(undefined);
                                        onExpand(record, e);
                                    }}
                                >
                                    <Icon iconName="AngleBottom" />
                                </Button>
                            ),
                        expandedRowKeys: rowKeysToExpand,
                    }}
                    rowKey={(record): string => record.id}
                    footer={renderTableFooter}
                    pagination={false}
                />
            </Content>
        </div>
    );
});

export default SecurityDeposits;
