import React, { FunctionComponent, useEffect, useState } from 'react';
import { Input, Row, Col, Button, DatePicker } from 'antd';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import './table-filters.less';
import { Search, Filter } from 'Components/icons';
import { theme } from 'variant';
import { LocationSelect } from './location-select';
import { AdvancedFilters } from './advanced-filters';
import { Badge } from 'Components/badge';
import { LightCampusDetailsInfo } from '../../Models/Location/LightCampusInfo';
import FilterStore from '../../Stores/FilterStore';
import { LeadOwnerSelect } from './lead-owner-select';
import { ManagerRoleSelect } from './manager-role-select';
import { ManagerUser } from 'Models/ManagerUsers/ManagerUser';
import ManagerAssignementSelect from './manager-assignment-select/manager-assignemnent-select';
import { ActivitySource } from 'Models/ActivityBoard/ActivitySource';
import ActivitySourceSelect from './activity-source-select';
import { useTranslation } from 'react-i18next';
import { DISPLAY_MONTH_YEAR_FORMAT, YEAR_FORMAT } from 'Models/Constants';
import ConferenceRoomSelect from './conference-room-select/conference-room-select';
import { DateRangeDropdownCommunityEventEnum, DateRangeDropdownEnum } from 'Models/Filters/DateRangeDropdownOption';
import DateRangeSelect from './date-range-select';
import { ManagementRoleDto } from 'Api/Features/ManagerUsers/Dtos/ManagementRoleDto';
import TableActionsButtons from 'Components/table-action-buttons/table-actions-buttons';

interface TableFiltersProps {
    filterStore: FilterStore;
    includeSearch?: boolean;

    includeLocations?: boolean;
    availableLocations?: LightCampusDetailsInfo[];

    includeAdvancedFilters?: boolean;
    includeMonth?: boolean;
    includeYear?: boolean;
    includeDate?: boolean;
    includeDates?: boolean;
    datesPrefix?: { start: string; end: string };
    dateLabels?: { start: string; end: string };
    dateRangeDropdownType?: typeof DateRangeDropdownEnum | typeof DateRangeDropdownCommunityEventEnum;

    includeLeadOwner?: boolean;
    availableLeadOwners?: ManagerUser[];

    includeManagerRoles?: boolean;
    roles?: ManagementRoleDto[];

    forOpportunities?: boolean;

    includeLeadOpportunityAssignementManagers?: boolean;
    availableAssignementManagers?: ManagerUser[];
    includeUnassignedLocations?: boolean;

    includeActivitySource?: boolean;

    includeConferenceRooms?: boolean;

    includeExport?: boolean;
    onExportClick?: () => void;
    exportBtnDisabled?: boolean;
}

const TableFilters: FunctionComponent<TableFiltersProps> = observer(
    ({
        filterStore,
        includeSearch,
        includeLocations,
        availableLocations,
        includeAdvancedFilters,
        includeMonth,
        includeYear,
        includeDate,
        includeDates,
        datesPrefix,
        dateLabels = { start: 'Start Date', end: 'End Date' },
        dateRangeDropdownType,
        includeLeadOwner,
        availableLeadOwners,
        includeManagerRoles,
        forOpportunities,
        includeLeadOpportunityAssignementManagers,
        availableAssignementManagers,
        includeUnassignedLocations,
        includeActivitySource,
        includeConferenceRooms,
        includeExport,
        onExportClick,
        exportBtnDisabled
    }) => {
        const { t } = useTranslation();
        const [showAdvanced, setShowAdvanced] = useState(false);
        const [selectedDateRangeDropdownHidesPickers, setSelectedDateRangeDropdownHidesPickers] = useState(false);

        const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            filterStore.updateSearchTerm(event.target.value);
        };

        const onLocationChange = (locationId: string): void => {
            filterStore.updateLocation(locationId);
        };
        const onConferenceRoomChange = (roomId: string): void => {
            filterStore.updateRoom(roomId);
        };

        const onAdvancedFilterChange = (key: string, parentKey: string): void => {
            filterStore.toggleAdvancedFilter(key, parentKey);
        };

        const onToggleSection = (key: string): void => {
            filterStore.toggleSection(key);
        };

        const toggleAdvanced = (): void => {
            setShowAdvanced(!showAdvanced);
        };

        const checkedCount = filterStore?.advancedFilters?.reduce(
            (prev, filter) => prev + filter.items.filter((item) => item.checked).length,
            0
        );

        const onMonthChange = (date: Moment | null): void => {
            if (date) filterStore.updateMonth(date?.format('YYYY-MM'));
        };

        const onYearChange = (date: Moment | null): void => {
            if (date) filterStore.updateYear(date?.format('YYYY'));
        };

        const onDateChange = (date: Moment | null, dateString: string): void => {
            filterStore.updateDate(dateString);
        };

        const onStartDateChange = (date: Moment | null, dateString: string): void => {
            filterStore.updateStartDate(dateString);
            filterStore.updateDateRange(DateRangeDropdownEnum.Custom);
        };

        const onEndDateChange = (date: Moment | null, dateString: string): void => {
            filterStore.updateEndDate(dateString);
            filterStore.updateDateRange(DateRangeDropdownEnum.Custom);
        };

        const onDateRangeDropdownChange = (range: string): void => {
            const currentDate = moment();
            filterStore.updateDateRange(range);

            if(range !== DateRangeDropdownEnum.Custom) {
                setSelectedDateRangeDropdownHidesPickers(true);
            }
            else {
                setSelectedDateRangeDropdownHidesPickers(false);
            }

            switch (range) {
                case DateRangeDropdownEnum.ThisWeek:
                    filterStore.updateStartDate(currentDate.startOf('week').format());
                    filterStore.updateEndDate(currentDate.endOf('week').format());
                    break;
                case DateRangeDropdownEnum.LastWeek:
                    filterStore.updateStartDate(
                        moment(currentDate).subtract(7, 'days').startOf('week').format()
                    );
                    filterStore.updateEndDate(
                        moment(currentDate).subtract(7, 'days').endOf('week').format()
                    );
                    break;
                case DateRangeDropdownEnum.ThisMonth:
                    filterStore.updateStartDate(currentDate.startOf('month').format());
                    filterStore.updateEndDate(currentDate.endOf('month').format());
                    break;
                case DateRangeDropdownEnum.LastMonth:
                    filterStore.updateStartDate(
                        moment(currentDate).subtract(1, 'month').startOf('month').format()
                    );
                    filterStore.updateEndDate(
                        moment(currentDate).subtract(1, 'month').endOf('month').format()
                    );
                    break;
                case DateRangeDropdownCommunityEventEnum.Past:
                    filterStore.updateStartDate('');
                    filterStore.updateEndDate(moment(currentDate).format());
                    break;
                case DateRangeDropdownCommunityEventEnum.Ongoing:
                    filterStore.updateStartDate(moment(currentDate).format());
                    filterStore.updateEndDate(moment(currentDate).format());
                    break;
                case DateRangeDropdownCommunityEventEnum.Upcoming:
                    filterStore.updateStartDate(moment(currentDate).format());
                    filterStore.updateEndDate('');
                    break;
                case DateRangeDropdownCommunityEventEnum.OngoingAndUpcoming:
                    filterStore.updateStartDate('');
                    filterStore.updateEndDate(moment(currentDate).format());
                    break;
            }
        };

        const onLeadOwnerChange = (leadOwnerId: string): void => {
            filterStore.updateLeadOwner(leadOwnerId);
        };

        const onOpportunityOwnerChange = (opportunityOwnerId: string): void => {
            filterStore.updateOpportunityOwner(opportunityOwnerId);
        };

        const onRoleChange = (roleId: string): void => {
            filterStore.updateRole(roleId);
        };

        const onManagerAssignementChange = (managerId: string): void => {
            filterStore.updateManagerAssignment(managerId);
        };

        const onActivitySourceChange = (source: ActivitySource): void => {
            filterStore.updateActivitySource(source);
        };

        useEffect(() => {
            if (filterStore.dateRange !== '') {
                if (filterStore.dateRange !== DateRangeDropdownEnum.Custom)
                    setSelectedDateRangeDropdownHidesPickers(true);
            }
        }, [filterStore.dateRange]);

        const tableActionButtons = [
            {
                iconName: 'Download',
                toolTip: 'export_table',
                onClick: (): void => {
                    if (onExportClick) onExportClick();
                },
                disabled: exportBtnDisabled,
            },
        ];

        return (
            <div className="TableFilters">
                {includeMonth && (
                    <Row gutter={[20, 20]}>
                        <Col>
                            <div className="datepicker-month-wrapper">
                                <div className="label">Month</div>

                                <DatePicker
                                    picker="month"
                                    onChange={onMonthChange}
                                    value={filterStore.month ? moment(filterStore.month) : null}
                                    format={DISPLAY_MONTH_YEAR_FORMAT}
                                />
                            </div>
                        </Col>
                    </Row>
                )}

                {includeYear && (
                    <Row gutter={[20, 20]}>
                        <Col>
                            <div className="datepicker-month-wrapper">
                                <div className="label">{t('Year')}</div>

                                <DatePicker
                                    picker="year"
                                    onChange={onYearChange}
                                    value={filterStore.year ? moment(filterStore.year) : null}
                                    format={YEAR_FORMAT}
                                />
                            </div>
                        </Col>
                    </Row>
                )}

                {includeDate && (
                    <Row gutter={[20, 20]}>
                        <Col>
                            <div className="datepicker-month-wrapper">
                                <div className="label">Date</div>

                                <DatePicker
                                    onChange={onDateChange}
                                    value={filterStore.date ? moment(filterStore.date) : null}
                                />
                            </div>
                        </Col>
                    </Row>
                )}

                {includeDates && (
                    <Row gutter={[20, 20]}>
                        {dateRangeDropdownType && (
                            <Col span={3}>
                                <DateRangeSelect
                                    onChange={onDateRangeDropdownChange}
                                    value={filterStore.dateRange}
                                    optionType={dateRangeDropdownType}
                                />
                            </Col>
                        )}

                        <Col className={selectedDateRangeDropdownHidesPickers ? 'd-none' : ''}>
                            <div className="datepicker-dates-wrapper">
                                {datesPrefix && <div className="label">{t(datesPrefix.start)}</div>}

                                <DatePicker
                                    onChange={onStartDateChange}
                                    placeholder={dateLabels.start}
                                    value={
                                        filterStore.startDate ? moment(filterStore.startDate) : null
                                    }
                                    allowClear
                                    className="clearable"
                                />
                            </div>
                        </Col>

                        <Col className={selectedDateRangeDropdownHidesPickers ? 'd-none' : ''}>
                            <div className="datepicker-dates-wrapper">
                                {datesPrefix && <div className="label">{t(datesPrefix.end)}</div>}

                                <DatePicker
                                    onChange={onEndDateChange}
                                    placeholder={dateLabels.end}
                                    allowClear
                                    className="clearable"
                                    value={filterStore.endDate ? moment(filterStore.endDate) : null}
                                />
                            </div>
                        </Col>
                    </Row>
                )}

                <Row gutter={[20, 20]}>
                    {includeManagerRoles ? (
                        <Col span={3}>
                            <ManagerRoleSelect
                                onRoleChange={onRoleChange}
                                value={filterStore.currentRoleId}
                            />
                        </Col>
                    ) : null}
                    {includeLeadOwner && availableLeadOwners ? (
                        <Col span={forOpportunities ? 4 : 3}>
                            <LeadOwnerSelect
                                availableLeadOwners={availableLeadOwners}
                                onLeadOwnerChange={
                                    forOpportunities ? onOpportunityOwnerChange : onLeadOwnerChange
                                }
                                value={
                                    forOpportunities
                                        ? filterStore.currentOpportunityOwnerId
                                        : filterStore.currentLeadOwnerId
                                }
                                forOpportunities={forOpportunities}
                            />
                        </Col>
                    ) : null}
                    {includeLocations && availableLocations ? (
                        <Col span={3}>
                            <LocationSelect
                                availableLocations={availableLocations}
                                onLocationChange={onLocationChange}
                                value={filterStore.currentLocationId}
                                includeUnassignedLocations={includeUnassignedLocations}
                            />
                        </Col>
                    ) : null}
                    {includeConferenceRooms && availableLocations && (
                        <Col span={3}>
                            <ConferenceRoomSelect
                                onConferenceRoomChange={onConferenceRoomChange}
                                campusId={filterStore.currentLocationId}
                            />
                        </Col>
                    )}
                    {includeLeadOpportunityAssignementManagers && availableAssignementManagers ? (
                        <Col span={4}>
                            <ManagerAssignementSelect
                                onManagerAssignmentChange={onManagerAssignementChange}
                                value={filterStore.currentManagerAssignmentId}
                                availableAssignementManagers={availableAssignementManagers}
                            />
                        </Col>
                    ) : null}
                    {includeActivitySource ? (
                        <Col span={3}>
                            <ActivitySourceSelect
                                onSourceChange={onActivitySourceChange}
                                value={filterStore.currentActivitysource}
                            />
                        </Col>
                    ) : null}
                    {includeAdvancedFilters && filterStore.advancedFilters ? (
                        <Col span={6}>
                            <div className="advanced-filters-actions">
                                <Button
                                    type="default"
                                    onClick={toggleAdvanced}
                                    className={`advanced-filters-btn ${
                                        showAdvanced ? ' active' : ''
                                    }`}
                                >
                                    <Filter />
                                    Advanced filters
                                    <Badge type="primary">{checkedCount}</Badge>
                                </Button>
                                <span
                                    className="clear-filters"
                                    onClick={(): void => filterStore.clear()}
                                >
                                    Clear filters
                                </span>
                            </div>
                        </Col>
                    ) : null}
                    <Col flex="auto" />

                    {includeExport && (
                        <TableActionsButtons buttonsList={tableActionButtons}  />
                    )}
                    
                    {includeSearch && (
                        <Col span={6}>
                            <Input
                                onChange={onSearchChange}
                                prefix={<Search fill={theme['primary-3']} />}
                                value={filterStore.searchTerm}
                            />
                        </Col>
                    )}
                </Row>
                {showAdvanced && includeAdvancedFilters && filterStore.advancedFilters ? (
                    <Row gutter={[20, 20]}>
                        <Col span={24}>
                            <AdvancedFilters
                                filters={filterStore.advancedFilters}
                                onItemChange={onAdvancedFilterChange}
                                onToggleSection={onToggleSection}
                            />
                        </Col>
                    </Row>
                ) : null}
            </div>
        );
    }
);

export default TableFilters;