// GENERATED FILE - DO NOT MODIFY

export enum GetInvoicesSortColumnDto {
    IssuedDate = "IssuedDate",
    PeriodStart = "PeriodStart",
    Number = "Number",
    Description = "Description",
    AmountDue = "AmountDue",
    Total = "Total",
    DueDate = "DueDate",
    LastPaymentDate = "LastPaymentDate",
    Status = "Status",
}