import { GetRentRollReportRequestDto } from 'Api/Features/Reports/Dtos/RentRoll/GetRentRollReportRequestDto';
import { RentRollReportDto } from 'Api/Features/Reports/Dtos/RentRoll/RentRollReportDto';
import { RentRollReportProxy } from 'Api/Features/Reports/RentRollReportProxy';
import { inject } from 'aurelia-dependency-injection';
import { arrayBufferToXlsx } from 'Utils/blobs';
import { ApiService } from './ApiService';

@inject(RentRollReportProxy)
export class RentRollReportService extends ApiService {
    constructor(private readonly rentRollReportProxy: RentRollReportProxy) {
        super();
    }

    public async getRentRollReport(
        request: GetRentRollReportRequestDto | null
    ): Promise<RentRollReportDto | null> {
        return await this.rentRollReportProxy.getRentRollReport(request);
    }

    public async exportRentRollReport(request: GetRentRollReportRequestDto | null): Promise<Blob> {
        const document = await this.rentRollReportProxy.exportRentRollReport(request);

        return arrayBufferToXlsx(document);
    }
}
