import { Button, Table, TablePaginationConfig } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { ColumnType } from 'antd/lib/table';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetInvoicesSortColumnDto } from 'Api/Features/Invoices/Dtos/GetInvoicesSortColumnDto';
import { GetMembershipInvoicesRequestDto } from 'Api/Features/Invoices/Dtos/GetMembershipInvoicesRequestDto';
import { InvoiceStatusDto } from 'Api/Features/Invoices/Dtos/InvoiceStatusDto';
import { LightInvoiceDto } from 'Api/Features/Invoices/Dtos/LightInvoiceDto';
import CompanyHeader from 'Components/company-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { TableFilters } from 'Components/table-filters';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import { MOMENT_PARSING_FORMAT, TABLE_DEBOUNCE_DELAY } from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import moment from 'moment';
import React, {
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { InvoiceService } from 'Services/InvoiceService';
import { FilterStore } from 'Stores';
import { cleanVal, currencyFormatter } from 'Utils/NumberUtils';
import { CompanyContext } from '../..';
import CreateManualInvoiceModal from 'Routes/authenticated/contacts/id/invoices/modals/create-manual-invoice-modal';
import { PaymentMethodHolderTypeDto } from 'Api/Features/PaymentMethods/Dtos/PaymentMethodHolderTypeDto';
import { RequestType } from 'Stores/RequestStore';
import { getInvoiceStatusTag } from 'Components/tag/tag';

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 20,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'statuses',
        nameKey: 'Statuses',
        items: [
            {
                key: InvoiceStatusDto.Pending,
                displayNameKey: `User.contact_profile_InvoiceStatusDto_${InvoiceStatusDto.Pending}`,
                checked: true,
            },
            {
                key: InvoiceStatusDto.AwaitingPayment,
                displayNameKey: `User.contact_profile_InvoiceStatusDto_${InvoiceStatusDto.AwaitingPayment}`,
                checked: true,
            },
            {
                key: InvoiceStatusDto.Void,
                displayNameKey: `User.contact_profile_InvoiceStatusDto_${InvoiceStatusDto.Void}`,
                checked: true,
            },
            {
                key: InvoiceStatusDto.Failed,
                displayNameKey: `User.contact_profile_InvoiceStatusDto_${InvoiceStatusDto.Failed}`,
                checked: true,
            },
            {
                key: InvoiceStatusDto.Success,
                displayNameKey: `User.contact_profile_InvoiceStatusDto_${InvoiceStatusDto.Success}`,
                checked: true,
            },
            {
                key: InvoiceStatusDto.Refunded,
                displayNameKey: `User.contact_profile_InvoiceStatusDto_${InvoiceStatusDto.Refunded}`,
                checked: true,
            },
        ],
    },
];

const CompanyInvoices: FunctionComponent = () => {
    const companyContext = useContext(CompanyContext);
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();

    const invoiceService = useService(InvoiceService);
    const [invoices, setInvoices] = useState<LightInvoiceDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const paginationRef = useRef(initialPaginationState);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { toastStore } = useStores();
    const { requestStore } = useStores();
    const history = useHistory();
    const [requestSetFromCache, setRequestSetFromCache] = useState(false);
    const [createManualInvoiceModalOpen, setCreateManualInvoiceModalOpen] = useState(false);

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `companies`,
            nameKey: 'companies',
        },
        {
            path: `${id}/dashboard`,
            nameKey: companyContext?.name ?? '',
        },
        {
            path: `finance`,
            nameKey: 'finance',
        },
        {
            path: `invoices`,
            nameKey: 'Invoices',
        },
    ];

    const onRowClick = (invoice: LightInvoiceDto): void => {
        history.push(`invoices/${invoice.id}`);
    };

    const columns: ColumnType<LightInvoiceDto>[] = [
        {
            key: GetInvoicesSortColumnDto.IssuedDate,
            title: t('billing_period'),
            render: (invoice: LightInvoiceDto) =>
                `${moment(invoice.periodStart).format(MOMENT_PARSING_FORMAT)}${
                    invoice.periodEnd !== invoice.periodStart
                        ? ` - ${moment(invoice.periodEnd).format(MOMENT_PARSING_FORMAT)}`
                        : ''
                }`,
            sorter: true,
        },
        {
            key: GetInvoicesSortColumnDto.Number,
            title: t('Contact.contacts_invoices_invoice_number'),
            dataIndex: 'number',
            sorter: true,
        },
        {
            key: GetInvoicesSortColumnDto.Description,
            title: t('description'),
            dataIndex: 'description',
            sorter: true,
        },
        {
            key: GetInvoicesSortColumnDto.AmountDue,
            title: t('Contact.contacts_invoices_amount_due'),
            render: (invoice: LightInvoiceDto) => currencyFormatter(invoice.amountDue ?? 0),
            align: 'right',
            sorter: true,
        },
        {
            key: GetInvoicesSortColumnDto.Total,
            title: t('total'),
            render: (invoice: LightInvoiceDto) => currencyFormatter(invoice.total ?? 0),
            align: 'right',
            sorter: true,
        },
        {
            key: GetInvoicesSortColumnDto.DueDate,
            title: t('Contact.contacts_invoices_due'),
            render: (invoice: LightInvoiceDto) =>
                moment(invoice.dueDate).format(MOMENT_PARSING_FORMAT),
            sorter: true,
        },
        {
            key: GetInvoicesSortColumnDto.LastPaymentDate,
            title: t('Contact.contacts_invoices_last_payment'),
            render: (invoice: LightInvoiceDto) =>
                invoice.datePayment
                    ? moment(invoice.datePayment).format(MOMENT_PARSING_FORMAT)
                    : 'N/A',
            sorter: true,
        },
        {
            key: GetInvoicesSortColumnDto.Status,
            title: t('status'),
            render: (invoice: LightInvoiceDto) => getInvoiceStatusTag(invoice, t),
            sorter: true,
        },
    ];

    const getAdvancedFilterStatuses = (): {
        checkedStatuses: InvoiceStatusDto[];
        statusesCount: number;
    } => {
        const filterStore = filterStoreRef.current;
        const statuses = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'statuses'
        );
        const checkedStatuses = statuses?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key;
            });

        return {
            checkedStatuses:
                checkedStatuses?.map((x) => InvoiceStatusDto[x as keyof typeof InvoiceStatusDto]) ??
                [],
            statusesCount: statuses?.items.length ?? 0,
        };
    };

    const fetchInvoices = useCallback(
        async (params: {
            searchTerm?: string;
            startDate: string;
            endDate: string;
            pagination: TablePaginationConfig;
            sortColumn: GetInvoicesSortColumnDto | null;
            sortDirection: SortDirection | null;
            statuses?: InvoiceStatusDto[];
        }) => {
            setLoading(true);

            try {
                const noFilters =
                    !params?.statuses || (params?.statuses && params.statuses.length < 1);
                if (noFilters) {
                    setInvoices([]);
                } else {
                    const request: GetMembershipInvoicesRequestDto = requestStore.setRequest({
                        request: {
                            minPeriodStart: params.startDate,
                            maxPeriodEnd: params.endDate,
                            statuses: params.statuses,
                            searchTerm: params.searchTerm,
                            sortColumn: params.sortColumn,
                            sortDirection: params.sortDirection,
                            page: (params.pagination.current || 1) - 1,
                            pageSize: params.pagination.pageSize || 10,
                        },
                        requestType: RequestType.Invoice,
                    });

                    const [response, totalItemCount] = await invoiceService.getMembershipInvoices(
                        id,
                        request
                    );

                    setInvoices(response);
                    setPagination({
                        ...params.pagination,
                        total: totalItemCount,
                    });
                }
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [setLoading, invoiceService, t]
    );

    const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any): void => {
        const { searchTerm, startDate, endDate } = filterStoreRef.current;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        fetchInvoices({
            searchTerm: searchTerm,
            startDate: startDate,
            endDate: endDate,
            pagination: pagination,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
            statuses: getAdvancedFilterStatuses().checkedStatuses,
        });
    };

    const debounceSearch = useRef(
        debounce(
            (params: {
                searchTerm?: string;
                startDate: string;
                endDate: string;
                statuses?: InvoiceStatusDto[];
            }) => {
                fetchInvoices({
                    searchTerm: params.searchTerm,
                    startDate: params.startDate,
                    endDate: params.endDate,
                    pagination: {
                        ...paginationRef.current,
                        current: 1,
                    },
                    sortColumn: null,
                    sortDirection: null,
                    statuses: params.statuses,
                });
            },
            TABLE_DEBOUNCE_DELAY
        )
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;

            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                startDate: filterStoreRef.current.startDate,
                endDate: filterStoreRef.current.endDate,
                statuses: getAdvancedFilterStatuses().checkedStatuses,
            });
        });

        return (): void => {
            disposer();
        };
    }, [fetchInvoices]);

    useEffect(() => {
        if (
            requestStore?.requestInfo?.request &&
            requestStore.requestInfo?.requestType === RequestType.Invoice &&
            !requestSetFromCache
        ) {
            setRequestSetFromCache(true);
            const filterStore = filterStoreRef.current;
            const requestFromStore: GetMembershipInvoicesRequestDto =
                requestStore.requestInfo.request;
            const filtersList = [{ key: requestFromStore?.statuses || [], parentKey: 'statuses' }];

            if (requestStore.requestInfo.request.minPeriodStart) {
                filterStore.updateStartDate(requestStore.requestInfo.request.minPeriodStart);
            }
            if (requestStore.requestInfo.request.maxPeriodEnd) {
                filterStore.updateEndDate(requestStore.requestInfo.request.maxPeriodEnd);
            }

            filterStore.updateSearchTerm(cleanVal.string(requestFromStore.searchTerm));
            filterStore.tickMultipleAdvancedFilter(filtersList);
        }
    }, [requestStore]);

    return (
        <div className="CompanyInvoices">
            <CompanyHeader
                company={companyContext}
                routes={breadcrumbs}
                headerData={{
                    title: t('invoices'),
                    iconName: 'Bookmark',
                    subTitle: t('Company.company_invoices_label_subtitle'),
                }}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            setCreateManualInvoiceModalOpen(true);
                        }}
                    >
                        {t('Contact.contacts_invoices_create_manual_invoice')}
                    </Button>
                }
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeDates
                    datesPrefix={{
                        start: t('Contact.contacts_invoices_from'),
                        end: t('Contact.contacts_invoices_to'),
                    }}
                    includeSearch
                    includeAdvancedFilters
                />

                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(row: LightInvoiceDto): string => row.id ?? ''}
                    dataSource={invoices}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(row: LightInvoiceDto) => ({
                        onClick: (): void => {
                            onRowClick(row);
                        },
                    })}
                />
            </Content>
            {createManualInvoiceModalOpen && (
                <CreateManualInvoiceModal
                    visible={createManualInvoiceModalOpen}
                    entityId={id}
                    paymentMethodHolderType={PaymentMethodHolderTypeDto.Membership}
                    onComplete={(success: boolean) => {
                        if (success) {
                            const filterStore = filterStoreRef.current;

                            debounceSearch.current({
                                searchTerm: filterStore.searchTerm,
                                startDate: filterStoreRef.current.startDate,
                                endDate: filterStoreRef.current.endDate,
                                statuses: getAdvancedFilterStatuses().checkedStatuses,
                            });
                        }
                        setCreateManualInvoiceModalOpen(false);
                    }}
                />
            )}
        </div>
    );
};

export default CompanyInvoices;
