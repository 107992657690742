import React, { ReactNode } from 'react';
import moment from 'moment';
import timezone from 'moment-timezone';
import CurrentBooking from 'Components/booking/flexible-booking/current-booking';
import { FlexibleBookingViewType } from './flexible-booking';

const ZOOM_HOURS = 0;
const ZOOM_HALF_HOURS = 1;
const ZOOM_QUARTER_HOURS = 2;

interface ResourcesProps {
    zoom: number;
    resources: { id: string; periods: { end: string; start: string; status: string }[] }[];
    view: FlexibleBookingViewType;
    flexibleBookingCalendarRef: React.RefObject<any>;
    resourcePeriod: (period: any) => any;
    resourceHeader: (period: any) => any;
    currentBooking?: any;
    onTimeCellClick: (e: any, bookingInformation: any, period: any) => void;
    min: number;
    max: number;
    resizable: boolean;
    timezone: any;
    isEditing: boolean;
    selectedDate: any;
    onBookingResize?: any;
    currentBookingIsErrored?: boolean;
    onCurrentBookingClick?: any;
    recurrenceBlocksResize?: boolean;
    onRecurrenceBlockedResize?: any;
    onResizeStopped?: () => void;
}

interface ResourcesState {
    subCellsPerTimespan: number;
    timespanDivision: number;
    resources: { id: string; periods: { end: string; start: string; status: string }[] }[];
}

export default class Resources extends React.Component<ResourcesProps, ResourcesState> {
    private isOdd = false;
    private timeCellIndex = 0;
    private currentResourceId: string | null = null;
    private loaded = false;

    constructor(props: ResourcesProps) {
        super(props);

        this.state = {
            timespanDivision: this.calculateTimepanDivision(props.zoom),
            subCellsPerTimespan: this.calculateSubCellsPerTimespan(props.zoom),
            resources: props.resources || [],
        };
    }

    componentDidMount() {
        setTimeout(this.scrollToFirstAvailablePeriod, 300);
    }

    componentDidUpdate(prevProps: any) {
        // Watch for this.props.zoom update
        if (prevProps.zoom !== this.props.zoom) {
            this.setState({
                timespanDivision: this.calculateTimepanDivision(this.props.zoom),
                subCellsPerTimespan: this.calculateSubCellsPerTimespan(this.props.zoom),
            });
        }

        if (prevProps.resources !== this.props.resources) {
            this.setState({
                resources: this.props.resources,
            });
        }
    }

    scrollToFirstAvailablePeriod = () => {
        const classToScrollTo = this.props.view === 'bookings' ? 'Available' : 'CurrentBooking';
        const availablePeriods = document.getElementsByClassName(classToScrollTo);
        if (availablePeriods.length) {
            let earliestAvailablePeriod = (availablePeriods[0] as HTMLElement).offsetTop;

            if (availablePeriods.length) {
                for (const element of availablePeriods) {
                    if ((element as HTMLElement).offsetTop < earliestAvailablePeriod)
                        earliestAvailablePeriod = (element as HTMLElement).offsetTop;
                }

                // Skip scrollTo if navigator === edge
                if (window.navigator.userAgent.indexOf('Edge') <= -1) {
                    this.props.flexibleBookingCalendarRef.current.scrollTo({
                        top: earliestAvailablePeriod,
                        behavior: 'smooth',
                    });
                }
            }
        }
    };

    calculateTimepanDivision = (zoom: number): number => {
        switch (zoom) {
            case ZOOM_HOURS:
                return 1;
            case ZOOM_HALF_HOURS:
                return 2;
            case ZOOM_QUARTER_HOURS:
                return 4;
            default:
                return 1;
        }
    };

    calculateSubCellsPerTimespan = (zoom: number): number => {
        switch (zoom) {
            case ZOOM_HOURS:
                return 4;
            case ZOOM_HALF_HOURS:
                return 2;
            case ZOOM_QUARTER_HOURS:
                return 1;
            default:
                return 4;
        }
    };

    generatePeriod = (resourceId: string, period: any, resource: any): ReactNode => {
        if (resourceId !== this.currentResourceId) {
            this.currentResourceId = resourceId;
            this.timeCellIndex = 0;
        }

        const start = moment.parseZone(period.start);
        let quarterCellTime = moment.parseZone(period.start);
        const end = moment.parseZone(period.end);
        const duration = moment.duration(end.diff(start)).asHours();

        const periodContent =
            this.props.zoom === 0 && duration >= 1
                ? this.props.resourcePeriod(period)
                : this.props.zoom === 1 && duration >= 0.5
                ? this.props.resourcePeriod(period)
                : this.props.zoom === 2
                ? this.props.resourcePeriod(period)
                : null;

        const numberOfQuarterCells = (duration * 60) / 15;
        const isAvailablePeriod = period.status === 'Available';
        const quarterCells = [];

        for (let index = 1; index <= numberOfQuarterCells; index++) {
            if (this.timeCellIndex % this.state.subCellsPerTimespan === 0) {
                this.isOdd = !this.isOdd;
            }

            quarterCells.push(
                <div
                    key={`${resourceId}_${index}`}
                    id={`${this.props.view}_${resourceId}_${quarterCellTime.format('HH:mm')}`}
                    data-id={resourceId}
                    data-time={quarterCellTime.format('HH:mm')}
                    className={`quarterCells zoom-${this.props.zoom} ${
                        this.isOdd ? 'odd' : 'even'
                    }`}
                    onClick={
                        isAvailablePeriod ||
                        (period.booking &&
                            period.booking.id === this.props.currentBooking?.bookingId) ||
                        (period.booking && this.props.view === FlexibleBookingViewType.bookings)
                            ? (e: any) => this.handleTimeCellClick(e, period, resource)
                            : undefined
                    }
                >
                    <div className="period-background w-100 h-100" />
                </div>
            );

            this.timeCellIndex++;
            quarterCellTime = quarterCellTime.add(15, 'minutes');
        }

        return (
            <div
                key={`${resourceId}_${end}`}
                className={`period ${
                    period.unavailabilityReason ? period.unavailabilityReason : 'Available'
                } ${
                    period.booking && period.booking.id === this.props.currentBooking?.bookingId
                        ? 'CurrentBookingPeriod'
                        : ''
                }`}
            >
                {periodContent ? (
                    <div
                        data-id={resourceId}
                        data-time={quarterCellTime.format('HH:mm')}
                        onClick={
                            this.props.view === FlexibleBookingViewType.bookings ||
                            isAvailablePeriod ||
                            (period.booking &&
                                period.booking.id === this.props.currentBooking?.bookingId)
                                ? (e: any) => this.handleTimeCellClick(e, period, resource)
                                : undefined
                        }
                    >
                        {periodContent}
                    </div>
                ) : undefined}
                {quarterCells}
            </div>
        );
    };

    handleTimeCellClick = (e: any, period: any, resource: any) => {
        const { id, time } = e.currentTarget.dataset;
        const splitTime = time.split(':');
        const bookingInformation = this.getBookingInformation(
            id,
            splitTime,
            period,
            resource,
            period.booking?.id
        );
        this.props.onTimeCellClick(e, bookingInformation, period);
    };

    getBookingInformation = (
        conferenceRoomId: string,
        splitTime: any,
        { start, end }: any,
        resource: any,
        bookingId?: string
    ) => {
        const bookingStart = moment.parseZone(start).hours(parseInt(splitTime[0]));
        let bookingEnd = null;
        const { maximumBookingDuration } = resource;
        const maximumBookingDurationInHours = moment.duration(maximumBookingDuration).asHours();
        // Calculate booking start time based on zoom
        if (this.props.zoom === ZOOM_HOURS) {
            bookingStart.minutes(0);
            while (bookingStart.isBefore(moment.parseZone(start))) {
                bookingStart.add(15, 'minutes');
            }
        } else if (this.props.zoom === ZOOM_HALF_HOURS) {
            const minutes = splitTime[1] === '00' || splitTime[1] === '15' ? 0 : 30;
            bookingStart.minutes(minutes);
            while (bookingStart.isBefore(moment.parseZone(start))) {
                bookingStart.add(30, 'minutes');
            }
        } else {
            bookingStart.minutes(splitTime[1]);
        }
        const bookingEndTimeToAdd =
            maximumBookingDurationInHours < 1 ? maximumBookingDurationInHours : 1;
        // Calculate booking end time
        bookingEnd = moment(bookingStart).add(bookingEndTimeToAdd, 'hours');

        //if editing a booking and the next period in the ressource is that booking, it can overlap on that period. 
        //Otherwise we remove quartercells as to not overlap a booked period
        if (
            this.props.isEditing &&
            !bookingEnd.isBetween(
                moment(this.props.currentBooking.periodStart),
                moment(this.props.currentBooking.periodEnd)
            )
        ) {
            while (bookingEnd.isAfter(moment.parseZone(end))) {
                bookingEnd.subtract(15, 'minutes');
            }
        }
        
        return {
            conferenceRoomId,
            bookingStart: timezone(bookingStart).tz(this.props.timezone).format(),
            bookingEnd: timezone(bookingEnd).tz(this.props.timezone).format(),
            bookingAmenity: [resource],
            bookingId,
        };
    };

    handleBookingResize = ({ bookingStart, bookingEnd }: any) => {
        const formatedBookingStart = bookingStart
            ? timezone(bookingStart).tz(this.props.timezone).format()
            : null;
        const formatedBookingEnd = bookingEnd
            ? timezone(bookingEnd).tz(this.props.timezone).format()
            : null;
        this.props.onBookingResize({ formatedBookingStart, formatedBookingEnd });
    };

    render() {
        const { resources } = this.state;
        if (resources.length <= 0) {
            return null;
        }
        return (
            <div className="FlexibleBooking__Resources">
                {(this.props.view === 'bookings' || this.props.view === 'conflictAmenities') && (
                    <div className="resources-header-row">
                        {resources.length &&
                            resources.map((resource) => {
                                return (
                                    <div className="resource-head" key={resource.id}>
                                        {this.props.resourceHeader(resource)}
                                    </div>
                                );
                            })}
                    </div>
                )}

                <div className="resources-content-row">
                    {resources.length &&
                        resources.map((resource, index: number) => {
                            if (index === resources.length - 1) {
                                this.loaded = true;
                            }
                            const { periods } = resource;
                            return (
                                <div key={resource.id} id={resource.id} className="resource">
                                    <div
                                        className={`resource-content zoom-${this.props.zoom} position-relative`}
                                    >
                                        {periods.map((period) => {
                                            return this.generatePeriod(
                                                resource.id,
                                                period,
                                                resource
                                            );
                                        })}

                                        {this.props.currentBooking &&
                                            this.props.currentBooking.conferenceRoomId ===
                                                resource.id && (
                                                <CurrentBooking
                                                    currentBooking={this.props.currentBooking}
                                                    selectedDate={this.props.selectedDate}
                                                    zoom={this.props.zoom}
                                                    view={this.props.view}
                                                    isEditing={this.props.isEditing}
                                                    resizable={this.props.resizable}
                                                    onResize={this.handleBookingResize}
                                                    isErrored={this.props.currentBookingIsErrored}
                                                    onClick={this.props.onCurrentBookingClick}
                                                    recurrenceBlocksResize={this.props.recurrenceBlocksResize}
                                                    onRecurrenceBlockedResize={this.props.onRecurrenceBlockedResize}
                                                    onResizeStopped={this.props.onResizeStopped}
                                                />
                                            )}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
}
