import { CreateMessageRequestDto } from 'Api/Features/Messages/Dtos/CreateMessageRequestDto';
import { GetAccountMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetAccountMessagesRequestDto';
import { GetMembershipMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetMembershipMessagesRequestDto';
import { GetMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetMessagesRequestDto';
import { GetMessagesResponseDto } from 'Api/Features/Messages/Dtos/GetMessagesResponseDto';
import { MessageDto } from 'Api/Features/Messages/Dtos/MessageDto';
import { SetAllMessageReadStatusesRequestDto } from 'Api/Features/Messages/Dtos/SetAllMessageReadStatusesRequestDto';
import { SetMessageReadStatusesRequestDto } from 'Api/Features/Messages/Dtos/SetMessageReadStatusesRequestDto';
import { MessageProxy } from 'Api/Features/Messages/MessageProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(MessageProxy)
export class MessageService extends ApiService {
    constructor(
        private readonly messageProxy: MessageProxy,
        private cacheTs: number,
        private readonly cacheDelay: number,
        private cacheMessages: GetMessagesResponseDto | null
    ) {
        super();

        this.cacheTs = 0;
        this.cacheDelay = 2 * 60 * 1000; // 2 min, milliseconds
        this.cacheMessages = { items: [], totalItemCount: 0 };
    }

    public async getMessage(messageId: string | null): Promise<MessageDto | null> {
        return await this.messageProxy.getMessage(messageId);
    }

    public async getMessages(request: GetMessagesRequestDto | null): Promise<MessageDto[]> {
        const response = await this.messageProxy.getMessages(request);
        const nonNullResponse = response?.filter(
            (message): message is MessageDto => message !== null
        );

        return nonNullResponse || [];
    }

    public async getAccountMessages(
        accountId: string | null,
        request: GetAccountMessagesRequestDto | null,
        forceUpdate: boolean
    ): Promise<GetMessagesResponseDto | null> {
        const useCache = this.cacheTs > Date.now() - this.cacheDelay;
        let data;

        if (useCache && this.cacheTs !== 0 && !forceUpdate) {
            data = this.cacheMessages;
        } else {
            data = await this.messageProxy.getAccountMessages(accountId, request);
            this.cacheMessages = data;
            this.cacheTs = Date.now();
        }

        return data;
    }

    public async getMembershipMessages(
        membershipId: string | null,
        request: GetMembershipMessagesRequestDto | null
    ): Promise<{
        items: MessageDto[];
        totalItemCount: number;
    }> {
        const data: GetMessagesResponseDto | null = await this.messageProxy.getMembershipMessages(
            membershipId,
            request
        );
        const nonNullItems = data?.items
            ? data.items.filter((item): item is MessageDto => item !== null)
            : [];

        return { items: nonNullItems, totalItemCount: data?.totalItemCount || 0 };
    }

    public async hasCurrentUserUnreadMessaged(
        forManagers?: boolean | null
    ): Promise<(MessageDto | null)[] | null> {
        return await this.messageProxy.hasCurrentUserUnreadMessaged(forManagers);
    }

    public async createMessage(request: CreateMessageRequestDto | null): Promise<string> {
        return await this.messageProxy.createMessage(request);
    }

    public async setMessageStatuses(
        request: SetMessageReadStatusesRequestDto | null
    ): Promise<void> {
        await this.messageProxy.setMessageStatuses(request);
    }

    public async setAllMessageStatuses(
        request: SetAllMessageReadStatusesRequestDto | null
    ): Promise<void> {
        await this.messageProxy.setAllMessageStatuses(request);
    }
}
