import { Collapse } from 'antd';
import { CampusFloorPlanFloorDto } from 'Api/Features/FloorPlans/Dtos/CampusFloorPlanFloorDto';
import { UnitTypeDto } from 'Api/Features/Units/Dtos/UnitTypeDto';
import { Unit } from 'Models/Units/Unit';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './unit-collapse.less';

interface FloorPlanUnit extends Unit {
    floorId?: string
    floorName?: string;
    isAssigned: boolean
}

interface UnitCollapseProps {
    campusUnits: Unit[];
    campusFloorPlanFloors: CampusFloorPlanFloorDto[];
    onUnitClick: (unitId: string, floorId: string) => void;
    activePanelKeys?: string[];
    onActivePanelChange: (keys: string[]) => void;
    onAssignUnit: (unitId: string) => void;
    showAssignButton: boolean;
}

const UnitCollapse: FunctionComponent<UnitCollapseProps> = ({
    campusUnits,
    campusFloorPlanFloors,
    onUnitClick,
    activePanelKeys,
    onActivePanelChange,
    onAssignUnit,
    showAssignButton
}) => {
    const { t } = useTranslation();
    const [floorPlanUnits, setFloorPlanUnits] = useState<FloorPlanUnit[]>();

    useEffect(() => {
        const units: Unit[] = [...campusUnits];

        const floorUnits: (FloorPlanUnit | undefined)[] = campusFloorPlanFloors.flatMap((floor) =>
            floor.unitAreas
                ?.filter((unitArea) => unitArea?.unitId !== null)
                .map((unitArea) => {
                    //get the corresponding unit from campusUnits and remove it from array.
                    const unit: Unit[] = units.splice(
                        units.findIndex((unit) => unit.id === unitArea?.unitId),
                        1
                    );
                    return {
                        floorName: floor.name,
                        floorId: floor.id,
                        isAssigned: true,
                        ...unit[0],
                    } as FloorPlanUnit;
                })
        );

        setFloorPlanUnits(
            floorUnits
                .filter((unit) => unit !== undefined)
                .map((unit) => unit!)
                //add units that were not found on floor plan
                .concat(
                    units.map(
                        (unit) =>
                            ({
                                isAssigned: false,
                                ...unit,
                            } as FloorPlanUnit)
                    )
                )
        );
    }, [campusUnits, campusFloorPlanFloors]);

    return (
        <div className="UnitCollapse">
            <Collapse
                activeKey={activePanelKeys}
                onChange={(activeKeys) => onActivePanelChange([...activeKeys])}
                expandIconPosition={'right'}
            >
                {Object.keys(UnitTypeDto).map((unitType) => (
                    <Collapse.Panel key={unitType} header={t(`Unit.type_${unitType}`)}>
                        <div>
                            {floorPlanUnits
                                ?.filter((unit) => unit.type === unitType)
                                .map((unit) => (
                                    <div
                                        key={unit.id}
                                        className={`unit ${!unit.isAssigned && 'unassigned'}`}
                                    >
                                        <div className="unit-name">{unit.name}</div>

                                        {unit.isAssigned && (
                                            <div
                                                className="floor-name"
                                                onClick={() => onUnitClick(unit.id!, unit.floorId!)}
                                            >
                                                {unit.floorName}
                                            </div>
                                        )}

                                        {!unit.isAssigned && showAssignButton && (
                                            <div
                                                className="assign-btn"
                                                onClick={() => onAssignUnit(unit.id!)}
                                            >
                                                {t('assign')}
                                            </div>
                                        )}
                                    </div>
                                ))}
                        </div>
                    </Collapse.Panel>
                ))}
            </Collapse>
        </div>
    );
};

export default UnitCollapse