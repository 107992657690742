// GENERATED FILE - DO NOT MODIFY
import { AccountsReceivableReportDto } from 'Api/Features/Reports/Dtos/AccountsReceivable/AccountsReceivableReportDto';
import { GetAccountsReceivableReportRequestDto } from 'Api/Features/Reports/Dtos/AccountsReceivable/GetAccountsReceivableReportRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class AccountsReceivableReportProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getAccountsReceivableReport(request: GetAccountsReceivableReportRequestDto | null): Promise<AccountsReceivableReportDto | null> {
        const uri = this.buildUri(
            "/accounts-receivable-report",
            null,
            { ...request || undefined }
        );

        const data: AccountsReceivableReportDto | null = await this.httpClient.get<AccountsReceivableReportDto | null>(uri);
        return data;
    }

    public async exportAccountsReceivableReport(request: GetAccountsReceivableReportRequestDto | null): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/accounts-receivable-report/export",
            null,
            { ...request || undefined }
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }
}