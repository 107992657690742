import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { OpportunityProductDto } from 'Api/Features/Opportunities/Dtos/OpportunityProductDto';
import { OpportunitySalesChannelDto } from 'Api/Features/Opportunities/Dtos/OpportunitySalesChannelDto';
import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';
import { OpportunityTermLengthDto } from 'Api/Features/Opportunities/Dtos/OpportunityTermLengthDto';
import BaseModal from 'Components/base-modal/base-modal';
import Icon from 'Components/icons/icon';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { BILLION } from 'Models/Constants';
import { LightCampusDetailsInfo } from 'Models/Location/LightCampusInfo';
import { ManagerUser } from 'Models/ManagerUsers/ManagerUser';
import { Opportunity } from 'Models/Opportunities/Opportunity';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { OpportunityMoveToQualifiedSchema } from 'Schemas';
import { ManagerUserService } from 'Services/ManagerUserService';
import { OpportunityService } from 'Services/OpportunityService';
import { theme } from 'variant';
import './move-to-qualified.less';

const { Option } = Select;

const formGutter: [Gutter, Gutter] = [40, 0];

interface MoveToQualifiedProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    opportunity?: Opportunity;
}

const MoveToQualified: FunctionComponent<MoveToQualifiedProps> = ({
    visible,
    onComplete,
    opportunity,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(OpportunityMoveToQualifiedSchema, form);
    const opportunityService = useService(OpportunityService);
    const managerUserService = useService(ManagerUserService);
    const { globalLoadingStore, toastStore, confirmationModalStore, locationStore } = useStores();
    const [managersOptions, setManagersOptions] = useState<SelectCustomOption[]>([]);
    const [managersSearchResults, setManagersSearchResults] = useState<ManagerUser[]>([]);
    const [selectedManagerOptions, setSelectedManagerOptions] = useState<SelectCustomOption[]>([]);
    const [selectedOwnerId, setSelectedOwnerId] = useState<string[] | undefined>(
        opportunity?.ownerAccount?.id ? [opportunity?.ownerAccount?.id] : []
    );
    const [managersSearchTerm, setManagersSearchTerm] = useState('');
    const [managersMaxResults, setManagersMaxResults] = useState(false);
    const [managersCurrentPage, setManagersCurrentPage] = useState(0);
    const [selectLoading, setSelectLoading] = useState(false);
    const pageSize = 25;

    const resetManagersSearch = (): void => {
        setManagersCurrentPage(0);
        setManagersSearchResults([]);
        setManagersSearchTerm('');
        setManagersMaxResults(false);
    };

    const handleManagerKeywordsChange = useCallback((value: string): void => {
        resetManagersSearch();
        setManagersSearchTerm(value);
    }, []);

    const handleManagersMenuScrollToBottom = (): void => {
        if (!managersMaxResults) {
            setManagersCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const searchManagerUsers = async (page: number, searchTerm: string): Promise<ManagerUser[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
        };
        const [results, totalItemCount] = await managerUserService.getManagerUsers(args);

        if (results.length + pageSize * page >= totalItemCount) {
            setManagersMaxResults(true);
        }
        setSelectLoading(false);
        return results;
    };

    const debounceManagerSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchManagerUsers(page, searchTerm).then((results) => {
                setManagersSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    const dismiss = (success = false, id?: string): void => {
        onComplete(success, id);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Plan" />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t(`Opportunity.move_to_qualified_confirm_negative`),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (values: Store): Promise<void> => {
        const formValues = form.getFieldsValue();

        const data = {
            status: OpportunityStatusDto.Qualified,
            contactId: opportunity?.contact?.id,
            name: opportunity?.name,
            closeDate: formValues.closeDate ?? undefined,
            deskCount: formValues.deskCount,
            product: formValues.product,
            salesChannel: formValues.salesChannel,
            source: opportunity?.source,
            campusId: formValues.campusId ?? '',
            termLength: formValues.termLength,
            monthlyRevenue:
                formValues.monthlyRevenue === '' ? undefined : formValues.monthlyRevenue,
            ownerAccountId: formValues.ownerAccountId?.value ?? opportunity?.ownerAccount?.id,
            note: formValues.note,
        };

        if (!(await validateForm(data))) return;

        try {
            globalLoadingStore.addLoading();

            if (opportunity?.id !== undefined) {
                await opportunityService.updateOpportunity(opportunity.id, data);
            }

            toastStore.toast({
                type: 'success',
                messageKey:
                    opportunity !== undefined
                        ? 'Opportunity.opportunity_edit_success'
                        : `Opportunity.opportunity_create_success`,
            });
            dismiss(true);
        } catch (e) {
            toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    useEffect(() => {
        const searchResults = managersSearchResults?.map(
            (x: ManagerUser) =>
                ({
                    value: x?.id,
                    label: x?.name,
                    imageUrl: x?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedManagerOptions);

        setManagersOptions(merged);
    }, [managersSearchResults, t, selectedManagerOptions]);

    useEffect(() => {
        setSelectLoading(true);
        debounceManagerSearch.current(managersCurrentPage, managersSearchTerm);
    }, [managersCurrentPage, managersSearchTerm]);

    useEffect(() => {
        if (opportunity) {
            form.setFieldsValue({
                campusId: opportunity.campus?.id ?? undefined,
                deskCount: opportunity.deskCount ?? undefined,
                product: opportunity.product ?? undefined,
                salesChannel: opportunity.salesChannel ?? undefined,
                termLength: opportunity.termLength ?? undefined,
                monthlyRevenue: opportunity.monthlyRevenue ?? undefined,
                closeDate: opportunity?.closeDate ? moment(opportunity?.closeDate) : null,
            });
        }
    }, [opportunity, form]);

    return (
        <BaseModal
            visible={visible}
            title={t('Opportunity.move_to_qualified')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="MoveToQualified">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <p className="sub-title">{t('Opportunity.opportunity_information')}</p>

                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="campusId"
                                errors={errors}
                                label={t('location')}
                                initialValue={opportunity?.campus?.id || undefined}
                                required
                            >
                                <Select>
                                    {locationStore.locations?.map((x: LightCampusDetailsInfo) => (
                                        <Option key={x.id!} value={x.id!}>
                                            {x.name}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>

                        <Col span={6}>
                            <ValidatedFormItem
                                name="deskCount"
                                errors={errors}
                                label={t('Opportunity.desk_count')}
                                required
                            >
                                <NumberFormat
                                    allowNegative={false}
                                    customInput={(props: any) => <Input {...props} />}
                                    isAllowed={(value: NumberFormatValues): boolean =>
                                        value.floatValue === undefined ||
                                        (value.floatValue !== undefined &&
                                            value.floatValue <= BILLION)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={6}>
                            <ValidatedFormItem
                                name="product"
                                errors={errors}
                                label={t('Opportunity.product')}
                                required
                            >
                                <Select>
                                    {Object.keys(OpportunityProductDto).map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`Opportunity.product_${x}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>

                        <Col span={6}>
                            <ValidatedFormItem
                                name="salesChannel"
                                errors={errors}
                                label={t('Opportunity.sales_channel')}
                                required
                            >
                                <Select>
                                    {Object.keys(OpportunitySalesChannelDto).map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`Opportunity.salesChannel_${x}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>

                        <Col span={6}>
                            <ValidatedFormItem
                                name="termLength"
                                errors={errors}
                                label={t('Opportunity.term_length')}
                                initialValue={opportunity?.termLength || undefined}
                                required
                            >
                                <Select>
                                    {Object.keys(OpportunityTermLengthDto).map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`Opportunity.term_${x}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>

                        <Col span={6}>
                            <ValidatedFormItem
                                name="monthlyRevenue"
                                errors={errors}
                                label={t('Opportunity.monthly_revenue')}
                                initialValue={opportunity?.monthlyRevenue || undefined}
                                required
                            >
                                <NumberFormat
                                    allowNegative={false}
                                    customInput={(props: any) => <Input {...props} />}
                                    isAllowed={(value: NumberFormatValues): boolean =>
                                        value.floatValue === undefined ||
                                        (value.floatValue !== undefined &&
                                            value.floatValue <= BILLION)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={6}>
                            <ValidatedFormItem
                                name="closeDate"
                                errors={errors}
                                label={t('Opportunity.close_date')}
                                required
                            >
                                <DatePicker style={{ width: '100%' }} />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="ownerAccountId"
                                errors={errors}
                                label={t('Opportunity.opportunity_owner')}
                                required
                            >
                                <SelectCustom
                                    options={managersOptions}
                                    defaultImg={<Icon iconName="User" fill={theme['white']} />}
                                    strongLabel={true}
                                    placeholder={t('SelectCustom.placeholder_default')}
                                    onKeywordsChange={handleManagerKeywordsChange}
                                    onMenuScrollToBottom={handleManagersMenuScrollToBottom}
                                    onChange={(value: any): void => {
                                        setSelectedOwnerId([value.value]);
                                        const options = [value as SelectCustomOption];
                                        setSelectedManagerOptions(options);
                                    }}
                                    hideSelectedOptions={false}
                                    selected={selectedOwnerId}
                                    isLoading={selectLoading}
                                    idAttribute="ownerAccountId"
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="note"
                                label={t('Lead.lead_add_a_note')}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>

                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(MoveToQualified);
