import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { PagedRequest } from '../Models/Requests/PagedRequest';
import { SearchableRequest } from '../Models/Requests/SearchableRequest';
import { OpportunityProxy } from 'Api/Features/Opportunities/OpportunityProxy';
import { GetOpportunitiesResponseDto } from 'Api/Features/Opportunities/Dtos/GetOpportunitiesResponseDto';
import { CreateOpportunityRequestDto } from 'Api/Features/Opportunities/Dtos/CreateOpportunityRequestDto';
import { OpportunityDto } from 'Api/Features/Opportunities/Dtos/OpportunityDto';
import { Opportunity } from 'Models/Opportunities/Opportunity';
import { UpdateOpportunityStatusToLostRequestDto } from 'Api/Features/Opportunities/Dtos/UpdateOpportunityStatusToLostRequestDto';
import { UpdateOpportunityRequestDto } from 'Api/Features/Opportunities/Dtos/UpdateOpportunityRequestDto';
import { OpportunityEventDto } from 'Api/Features/OpportunityEvents/Dtos/OpportunityEventDto';
import { GetOpportunityEventsResponseDto } from 'Api/Features/OpportunityEvents/Dtos/GetOpportunityEventsResponseDto';
import { OpportunityEventProxy } from 'Api/Features/OpportunityEvents/OpportunityEventProxy';
import { LeadEventDto } from 'Api/Features/LeadEvents/Dtos/LeadEventDto';
import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';
import { dateMomentToString } from 'Utils/TimeUtils';
import { GetOpportunitiesSortColumnDto } from 'Api/Features/Opportunities/Dtos/GetOpportunitiesSortColumnDto';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetOpportunitiesFlagDto } from 'Api/Features/Opportunities/Dtos/GetOpportunitiesFlagDto';
import { GetOpportunityNoteResponseDto } from 'Api/Features/Opportunities/Dtos/GetOpportunityNoteResponseDto';
import { UpdateOpportunityNoteRequestDto } from 'Api/Features/Opportunities/Dtos/UpdateOpportunityNoteRequestDto';
import { ExportOpportunitiesRequestDto } from 'Api/Features/Opportunities/Dtos/ExportOpportunitiesRequestDto';
import { arrayBufferToXlsx } from 'Utils/blobs';

export interface OpportunityRequest extends PagedRequest, SearchableRequest {
    ownerAccountIds?: string[] | null;
    campusIds?: string[] | null;
    statuses?: string[] | null;
    flags?: string[] | null;
    sortColumn?: GetOpportunitiesSortColumnDto | null;
    sortDirection?: SortDirection | null;
    membershipIds?: string[] | null;
}

interface OpportunityEventRequest extends PagedRequest, SearchableRequest {
    opportunityId: string;
}

@inject(OpportunityProxy, OpportunityEventProxy)
export class OpportunityService extends ApiService {
    constructor(
        private readonly opportunityProxy: OpportunityProxy,
        private readonly opportunityEventProxy: OpportunityEventProxy
    ) {
        super();
    }

    public async createOpportunity(request: CreateOpportunityRequestDto): Promise<string> {
        request.closeDate = dateMomentToString(request.closeDate);

        return await this.opportunityProxy.createOpportunity(request);
    }

    public async getOpportunities({
        page,
        pageSize,
        searchTerm,
        campusIds,
        ownerAccountIds,
        statuses,
        flags,
        sortColumn,
        sortDirection,
        membershipIds,
    }: OpportunityRequest): Promise<[Opportunity[], number]> {
        const response: GetOpportunitiesResponseDto | null =
            await this.opportunityProxy.getOpportunities({
                page,
                pageSize,
                searchTerm,
                campusIds,
                ownerAccountIds,
                statuses: statuses?.map(
                    (x) => OpportunityStatusDto[x as keyof typeof OpportunityStatusDto]
                ),
                flags: flags?.map(
                    (x) => GetOpportunitiesFlagDto[x as keyof typeof GetOpportunitiesFlagDto]
                ),
                sortColumn,
                sortDirection,
                membershipIds,
            });
        return [
            response?.items?.map((x) => new Opportunity(x!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async getById(id: string): Promise<Opportunity | null> {
        const response: OpportunityDto | null = await this.opportunityProxy.getOpportunity(id);
        return response ? new Opportunity(response) : null;
    }

    public async setOpportunityAsLost(
        id: string,
        request: UpdateOpportunityStatusToLostRequestDto
    ): Promise<void> {
        await this.opportunityProxy.updateOpportunityStatusToLost(id, request);
    }

    public async updateOpportunity(
        id: string,
        request: UpdateOpportunityRequestDto
    ): Promise<void> {
        request.closeDate = dateMomentToString(request.closeDate);

        await this.opportunityProxy.updateOpportunity(id, request);
    }

    public async getOpportunityEventsById({
        page,
        searchTerm,
        opportunityId,
    }: OpportunityEventRequest): Promise<[(OpportunityEventDto | LeadEventDto)[], number]> {
        const response: GetOpportunityEventsResponseDto | null =
            await this.opportunityEventProxy.getOpportunityEvents(opportunityId, {
                page,
                searchTerm,
            });
        return [response?.items?.map((x) => x!) || [], response?.totalItemCount || 0];
    }

    public async deleteOpportunity(opportunityId: string): Promise<void> {
        await this.opportunityProxy.deleteOpportunity(opportunityId);
    }

    public async getOpportunityNote(
        opportunityId: string
    ): Promise<GetOpportunityNoteResponseDto | null> {
        const data: GetOpportunityNoteResponseDto | null =
            await this.opportunityProxy.getOpportunityNote(opportunityId);
        return data;
    }

    public async updateOpportunityNote(
        opportunityId: string,
        request: UpdateOpportunityNoteRequestDto | null
    ): Promise<void> {
        await this.opportunityProxy.updateOpportunityNote(opportunityId, request);
    }

    public async exportOpportunities(request: ExportOpportunitiesRequestDto | null): Promise<Blob> {
        const data: ArrayBuffer = await this.opportunityProxy.exportOpportunities(request);
        return arrayBufferToXlsx(data);
    }
}
