import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { TopHeaderNavigation } from 'Components/top-header-navigation';
import './index.less';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MenuItem } from 'Components/top-header-navigation/top-header-navigation';

const LocationDetail: FunctionComponent = observer(({ children }: any) => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();

    const menuItems: MenuItem[] = [
        {
            key: `/locations/${id}/floor-plan`,
            label: t('floor_plan'),
        },
        {
            key: `/locations/${id}/companies`,
            label: t('companies'),
        },
        {
            key: `/locations/${id}/subscriptions`,
            label: t('Location.subscriptions_label'),
        },
        {
            key: `/locations/${id}/reserved-day-passes`,
            label: t('DayPass.reserved_day_passes'),
        },
        {
            key: `/locations/${id}/contacts`,
            label: t('User.contacts_label'),
        },
        {
            key: `/locations/${id}/booking`,
            label: t('Location.bookings'),
        },
        {
            key: `/locations/${id}/check-ins`,
            label: t('CheckIn.check_ins_title'),
        },
        {
            key: `/locations/${id}/events`,
            label: t('events'),
        },
        {
            key: `/locations/${id}/management`,
            label: t('management'),
        },
    ];

    return (
        <div className="LocationDetail">
            <TopHeaderNavigation menuItems={menuItems} />

            {children}
        </div>
    );
});

export default LocationDetail;
