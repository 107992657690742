import { GetSubscriptionsFlagDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionsFlagDto';
import { UnitOccupancySubscriptionDto } from 'Api/Features/Units/Dtos/UnitOccupancySubscriptionDto';
import { UnitOccupancySubscriptionProposalDto } from 'Api/Features/Units/Dtos/UnitOccupancySubscriptionProposalDto';
import { Company, Units } from 'Components/icons';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { SimpleList } from 'Components/simple-list';
import Tag, {
    getSubscriptionProposalStatusTagColor,
    getSubscriptionStatusTagColor,
} from 'Components/tag/tag';
import { MMMM_D_YYYY_FORMAT } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { firstLetterLowerCase } from 'Utils/StringUtils';
import { theme } from 'variant';
import './index.less';

interface UnitOccupancyProps {
    proposals: (UnitOccupancySubscriptionProposalDto | null)[] | null | undefined;
    subscriptions: (UnitOccupancySubscriptionDto | null)[] | null | undefined;
}

const UnitOccupancy: FunctionComponent<UnitOccupancyProps> = ({
    proposals: proposalProps,
    subscriptions: subscriptionProps,
}) => {
    const { t } = useTranslation();
    const [sortedByDate, setSortedByDate] = useState<
        (UnitOccupancySubscriptionProposalDto | UnitOccupancySubscriptionDto)[]
    >();

    const statusesList = [
        firstLetterLowerCase(GetSubscriptionsFlagDto.IsCancelled),
        firstLetterLowerCase(GetSubscriptionsFlagDto.IsExpired),
        firstLetterLowerCase(GetSubscriptionsFlagDto.ExpiresSoon),
        firstLetterLowerCase(GetSubscriptionsFlagDto.IsUpcoming),
        firstLetterLowerCase(GetSubscriptionsFlagDto.IsActive),
    ];

    useEffect(() => {
        const proposals: UnitOccupancySubscriptionProposalDto[] =
            proposalProps?.filter((proposal) => proposal !== null).map((proposal) => proposal!) ??
            [];

        const subs: UnitOccupancySubscriptionDto[] =
            subscriptionProps?.filter((sub) => sub !== undefined).map((sub) => sub!) ?? [];

        const sortedByDate: (
            | UnitOccupancySubscriptionProposalDto
            | UnitOccupancySubscriptionDto
        )[] = [
            ...proposals,
            ...subs,
        ].sort(
            (
                a: UnitOccupancySubscriptionProposalDto | UnitOccupancySubscriptionDto,
                b: UnitOccupancySubscriptionProposalDto | UnitOccupancySubscriptionDto
            ) => moment(b.startDate).diff(a.startDate)
        );
        setSortedByDate(sortedByDate);
    }, [proposalProps, subscriptionProps]);

    const renderTag = (
        isSubscription: boolean,
        proposal: UnitOccupancySubscriptionProposalDto,
        subscription: UnitOccupancySubscriptionDto
    ): ReactNode => {
        if (isSubscription) {
            const status = statusesList.find(
                (status) => subscription[status as keyof UnitOccupancySubscriptionDto]
            );
            return (
                <Tag
                    label={t(`Subscription.subscription_status_${status}`)}
                    color={getSubscriptionStatusTagColor(
                        `Subscription.subscription_status_${status}`
                    )}
                />
            );
        } else {
            return (
                <Tag
                    label={t(`Proposal.status_${proposal.status}`)}
                    color={getSubscriptionProposalStatusTagColor(proposal.status)}
                />
            );
        }
    };

    const renderType = (
        isSubscription: boolean,
        proposal: UnitOccupancySubscriptionProposalDto,
        subscription: UnitOccupancySubscriptionDto
    ): string => {
        if (isSubscription) {
            if (subscription.isRenewal) return t('Subscription.renewa_subscription');
            if (subscription.isModification) return t('Subscription.modification_subscription');
            return t('subscription');
        } else if (
            proposal.opportunity !== null &&
            proposal?.opportunity?.isSubscriptionModification
        )
            return t('Subscription.subscription_event_ModificationProposal');
        else if (proposal.opportunity !== null && proposal?.opportunity?.isSubscriptionRenewal)
            return t('Subscription.agreement_renewal_proposal');
        else return t('proposal');
    };

    return (
        <div className="UnitOccupancy">
            {subscriptionProps &&
            subscriptionProps?.length < 1 &&
            proposalProps &&
            proposalProps?.length < 1 ? (
                <SimpleList title={t('Unit.unit_occupancy')}>
                    <div className="empty-container">
                        <Units width={60} height={60} fill={theme['secondary-color']} />
                        <div className="title">{t('available unit')}</div>
                        <div className="sub-title">{t('Unit.you_can_select_this_unit')}</div>
                    </div>
                </SimpleList>
            ) : (
                <SimpleList title={t('Unit.unit_occupancy')}>
                    {sortedByDate?.map((item) => {
                        const isSubscription =
                            (item as UnitOccupancySubscriptionDto).isActive !== undefined;

                        const proposal = item as UnitOccupancySubscriptionProposalDto;
                        const sub = item as UnitOccupancySubscriptionDto;
                        const startDate = moment
                            .utc(isSubscription ? sub.startDate : proposal.startDate)
                            .format(MMMM_D_YYYY_FORMAT);

                        let endDate;
                        if(isSubscription) {
                            endDate = sub.endDate
                            ? moment.utc(sub.endDate).format(MMMM_D_YYYY_FORMAT) : t('mtm')
                        } else {
                            endDate = proposal.endDate
                                ? moment.utc(proposal.endDate).format(MMMM_D_YYYY_FORMAT)
                                : t('mtm');
                        } 

                        return (
                            <div key={item.id!} className="occupancy-container">
                                <div className="occupancy-item-container">
                                    <div>
                                        <div className="date">{`${startDate} - ${endDate}`}</div>
                                        <div className="info-container">
                                            <div className="status">
                                                {renderTag(isSubscription, proposal, sub)}
                                            </div>
                                            <div className="type">
                                                {renderType(isSubscription, proposal, sub)}
                                            </div>
                                            <div className="company">
                                                <ImageWithPlaceholder
                                                    imgSrc={
                                                        isSubscription
                                                            ? sub.membership?.imageUrl
                                                            : proposal.membership?.imageUrl
                                                    }
                                                    width={16}
                                                    height={16}
                                                    defaultImg={<Company />}
                                                />
                                                {isSubscription
                                                    ? sub.membership?.name
                                                    : proposal.membership?.name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="link-container">
                                        {isSubscription && (
                                            <Link to={`/subscriptions/${sub.id}`}>
                                                {t('Subscription.view_subscription')}
                                            </Link>
                                        )}

                                        {!isSubscription && proposal.opportunity !== null ? (
                                            <Link
                                                to={`/opportunities/${proposal.opportunity?.id}/dashboard/proposals/${proposal.id}`}
                                            >
                                                {t('Proposal.proposal_cta_label_view_proposal')}
                                            </Link>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </SimpleList>
            )}
        </div>
    );
};

export default UnitOccupancy;
