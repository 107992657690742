import { Button, Checkbox, Col, Form, Input, List, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { AccountDto } from 'Api/Features/Accounts/Dtos/AccountDto';
import { BookingOccurrenceSummaryDto } from 'Api/Features/Amenities/Dtos/BookingOccurrenceSummaryDto';
import { BookingSummaryRequestDto } from 'Api/Features/Amenities/Dtos/BookingSummaryRequestDto';
import { CreateBookingRequestDto } from 'Api/Features/Amenities/Dtos/CreateBookingRequestDto';
import { CreateBookingRequestRecurrenceParametersDto } from 'Api/Features/Amenities/Dtos/CreateBookingRequestRecurrenceParametersDto';
import { GetRecurrencePresetsRequestDto } from 'Api/Features/Amenities/Dtos/GetRecurrencePresetsRequestDto';
import { RecurrenceBookingExceptionRequestDto } from 'Api/Features/Amenities/Dtos/RecurrenceBookingExceptionRequestDto';
import { RecurrencePresetDto } from 'Api/Features/Amenities/Dtos/RecurrencePresetDto';
import { UpdateBookingRequestDto } from 'Api/Features/Amenities/Dtos/UpdateBookingRequestDto';
import { DailyBookingSummaryPeriodUnavailabilityReasonDto } from 'Api/Features/Bookings/Dtos/DailyBookingSummaryPeriodUnavailabilityReasonDto';
import { CreditBalanceDetailsDto } from 'Api/Features/Credits/Dtos/CreditBalanceDetailsDto';
import { PaymentMethodHolderTypeDto } from 'Api/Features/PaymentMethods/Dtos/PaymentMethodHolderTypeDto';
import BaseModal from 'Components/base-modal/base-modal';
import FlexibleBooking, {
    FlexibleBookingViewType,
} from 'Components/booking/flexible-booking/flexible-booking';
import {
    Calendar,
    ConferenceRoom,
    Phone,
    PrivateRoom,
    ScreenSharing,
    Television,
    User,
    WhiteBoard,
} from 'Components/icons';
import ImageWithPlaceholder from 'Components/image-with-placeholder/image-with-placeholder';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { SimpleList } from 'Components/simple-list';
import Tag, { TagColors } from 'Components/tag/tag';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { ManagerUser } from 'Models/ManagerUsers/ManagerUser';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CreateBookingSchema } from 'Schemas';
import { BookingService } from 'Services/BookingService';
import { CreditsService } from 'Services/CreditsService';
import { ManagerUserService } from 'Services/ManagerUserService';
import { MemberService } from 'Services/MemberService';
import { MembershipService } from 'Services/MembershipService';
import { theme } from 'variant';
import BookingConflictsModal from './booking-conflicts-modal';
import './booking-modal.less';

const formGutter: [Gutter, Gutter] = [20, 0];

interface BookingModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    booking?: any;
    newBookingInfo?: any;
    locationId: string;
    resource: any;
    resourcePeriod?: any;
    timezone: any;
    selectedDate: any;
    isEditing: boolean;
}

const BookingModal: FunctionComponent<BookingModalProps> = ({
    visible,
    onComplete,
    booking,
    newBookingInfo,
    locationId,
    resource,
    resourcePeriod,
    timezone,
    selectedDate,
    isEditing,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors, setErrors] = useFormValidation(
        CreateBookingSchema,
        form
    );
    const pageSize = 25;
    const memberService = useService(MemberService);
    const creditsService = useService(CreditsService);
    const managerUserService = useService(ManagerUserService);
    const membershipsService = useService(MembershipService);
    const bookingService = useService(BookingService);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [conferenceRoom, setConferenceRoom] = useState<any>(false);
    const [date, setDate] = useState('');
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [creditBalances, setCreditBalances] = useState<CreditBalanceDetailsDto[]>([]);
    const [members, setMembers] = useState<any>([]);
    const [administrativeBooking, setAdministrativeBooking] = useState(false);
    const [privateBooking, setPrivateBooking] = useState(false);
    const [currentBooking, setCurrentBooking] = useState(newBookingInfo);
    const [userCurrentPage, setUserCurrentPage] = useState(0);
    const [userSearchResults, setUserSearchResults] = useState<AccountDto[]>([]);
    const [userSearchTerm, setUserSearchTerm] = useState('');
    const [userMaxResults, setUserMaxResults] = useState(false);
    const [userOptions, setUserOptions] = useState<SelectCustomOption[]>([]);
    const [selectedUserOption, setSelectedUserOption] = useState<SelectCustomOption[]>([]);
    const [creditOptions, setCreditOptions] = useState<SelectCustomOption[]>([]);
    const [memberOptions, setMemberOptions] = useState<SelectCustomOption[]>([]);
    const [selectedAttendees, setSelectedAttendees] = useState<string[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [selectedCreditBalance, setSelectedCreditBalance] = useState<CreditBalanceDetailsDto>();
    const [selectLoading, setSelectLoading] = useState(false);
    const [bookingTitle, setBookingTitle] = useState('');

    //booking recurrence
    const [fetchedRecurrencePresets, setFetchedRecurrencePresets] = useState(false);
    const [selectedRecurrenceTypeIndex, setSelectedRecurrenceTypeIndex] = useState<string>(
        t('Booking.does_not_repeat')
    );
    const [availableRecurrenceTypeOptions, setAvailableRecurrenceTypeOptions] = useState<
        SelectCustomOption[]
    >([]);
    const [availableBookingRecurrencePresets, setAvailableBookingRecurrencePresets] = useState<
        RecurrencePresetDto[] | null
    >(null);
    const [bookingRecurrenceDurationOptions, setBookingRecurrenceDurationOptions] = useState<
        SelectCustomOption[]
    >([]);
    const [selectedRecurrenceDuration, setSelectedRecurrenceDuration] = useState<string>();
    const [selectedCustomNumberOfOccurences, setSelectedCustomNumberOfOccurences] =
        useState<string>();
    const [customNumberOfOccurencesOptions, setCustomNumberOfOccurencesOptions] = useState<
        SelectCustomOption[]
    >([]);

    const [conflictModalVisible, setConflictModalVisible] = useState(false);
    const [bookingsWithRecurrenceErrors, setBookingsWithRecurrenceErrors] = useState<
        BookingOccurrenceSummaryDto[]
    >([]);
    const [currentBookingIsErrored, setCurrentBookingIsErrored] = useState<boolean>(false);
    const [recurrenceBlocksResize, setRecurrenceBlocksResize] = useState(false);

    const isMembershipCreditBalanceHolderType = (
        creditBalance: CreditBalanceDetailsDto | undefined
    ): boolean => {
        return (
            creditBalance?.paymentMethodHolder?.holderType === PaymentMethodHolderTypeDto.Membership
        );
    };

    const showAttendees = (): boolean => {
        return (
            members.length > 0 &&
            !administrativeBooking &&
            isMembershipCreditBalanceHolderType(selectedCreditBalance)
        );
    };

    const setMembershipMembers = useCallback(
        (creditBalance: CreditBalanceDetailsDto | undefined): void => {
            if (isMembershipCreditBalanceHolderType(creditBalance)) {
                globalLoadingStore.addLoading();
                membershipsService
                    .getMembership(creditBalance?.paymentMethodHolder?.holderId || '')
                    .then((results) => {
                        setMembers(results?.members || []);
                        globalLoadingStore.removeLoading();
                    });
            }
        },
        [membershipsService]
    );

    const handleCreditBalanceSelect = (value: any): void => {
        const creditBalance = creditBalances.find((x) => x.id === value.value);
        setSelectedCreditBalance(creditBalance);
        setMembershipMembers(creditBalance);
        setSelectedAttendees([]);
    };

    const getUserCreditBalances = useCallback(
        async (
            id: string,
            callback: (results: CreditBalanceDetailsDto[]) => void
        ): Promise<void> => {
            globalLoadingStore.addLoading();
            const results = await creditsService.getCreditBalances({
                id: id,
                campusId: locationId,
            });
            callback(results);
            globalLoadingStore.removeLoading();
        },
        [creditsService, locationId]
    );

    const handleUserSelect = (value: any): void => {
        setSelectedUserId(value.value);
        setSelectedUserOption(value);
        getUserCreditBalances(value.value, (results: CreditBalanceDetailsDto[]) => {
            if (resource[0].privateMembershipIds.length > 0) {
                const membershipBalances = results.filter(
                    (payMethod) =>
                        payMethod.paymentMethodHolder?.holderType ===
                        PaymentMethodHolderTypeDto.Membership
                );
                const correspondingMembershipBalances = membershipBalances.filter((balance) =>
                    resource[0].privateMembershipIds.some(
                        (id: string) => id === balance.paymentMethodHolder?.holderId
                    )
                );
                setCreditBalances(correspondingMembershipBalances);
            } else setCreditBalances(results);
        });
        setSelectedAttendees([]);
        setSelectedCreditBalance(undefined);
    };

    const handleAttendeeSelect = (values: any): void => {
        setSelectedAttendees(values === '' ? [] : values.map((x: any) => x.value));
    };

    useEffect(() => {
        if (!isEditing && newBookingInfo) {
            setStartDate(newBookingInfo.bookingStart);
            setEndDate(newBookingInfo.bookingEnd);
            setConferenceRoom(newBookingInfo.bookingAmenity[0]);
            setCurrentBooking(newBookingInfo);
        } else if (booking) {
            setCurrentBooking({
                bookingId: booking?.id,
                bookingStart: booking?.periodStart,
                bookingEnd: booking?.periodEnd,
                conferenceRoomId: booking?.amenity?.id,
                ...booking,
            });
            setStartDate(booking.periodStart);
            setEndDate(booking.periodEnd);
            setConferenceRoom(booking.bookingAmenity[0]);
            setSelectedCreditBalance(booking.creditBalance);
            setMembershipMembers(booking.creditBalance);
            setSelectedAttendees(
                booking.invitedAccounts ? booking.invitedAccounts.map((x: any) => x.id) : []
            );
            setPrivateBooking(booking.isPrivate);
            setBookingTitle(booking.title)
            form.setFieldsValue({
                title: booking.title
            })
        }
        setDate(selectedDate.format('dddd MMMM D, YYYY'));
    }, [newBookingInfo, booking, isEditing, selectedDate, setMembershipMembers]);

    const searchUsers = async (
        page: number,
        searchTerm: string,
        administrativeBooking: boolean
    ): Promise<AccountDto[]> => {
        let results = [];

        let args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
            campusIds: [locationId],
            membershipIds: [],
        };

        if (administrativeBooking) {
            const [data] = await managerUserService.getManagerUsers(args);
            results = data.map((element: ManagerUser): AccountDto => {
                return {
                    id: element.id,
                    firstName: element.firstName,
                    lastName: element.lastName,
                    contactInfo: element.contactInfo,
                };
            });
        } else {
            args = { ...args, membershipIds: resource[0].privateMembershipIds };
            const response = await memberService.getMembers(args);
            results = response[0];
        }

        if (results.length < pageSize) {
            setUserMaxResults(true);
        }
        setSelectLoading(false);
        return results;
    };

    const debounceUserSearch = useRef(
        debounce((page: number, searchTerm: string, administrativeBooking: boolean) => {
            searchUsers(page, searchTerm, administrativeBooking).then((results) => {
                setUserSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    useEffect(() => {
        setSelectLoading(true);
        debounceUserSearch.current(userCurrentPage, userSearchTerm, administrativeBooking);
    }, [userSearchTerm, userCurrentPage, administrativeBooking]);

    const handleKeywordsChange = useCallback((value: string): void => {
        setUserSearchTerm(value);
    }, []);

    const handleMenuScrollToBottom = () => {
        if (!userMaxResults) {
            setUserCurrentPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        const searchResults = userSearchResults?.map(
            (x) =>
                ({
                    value: x?.id,
                    label: x?.firstName + ' ' + x?.lastName,
                    content: x?.contactInfo?.email,
                    imageUrl: x?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption)
        );
        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedUserOption);
        setUserOptions(merged);
    }, [userSearchResults]);

    useEffect(() => {
        setCreditOptions(
            creditBalances.map(
                (x) =>
                    ({
                        value: x?.id,
                        label: x?.title,
                        content: x?.totalAmount + ' ' + t('Booking.book_a_room_credits'),
                        imageUrl: x?.imageUrl,
                    } as SelectCustomOption)
            )
        );
    }, [creditBalances, t]);

    useEffect(() => {
        if (members) {
            const options = members
                .filter((x: AccountDto) => x !== selectedUserId)
                .map(
                    (x: AccountDto) =>
                        ({
                            value: x?.id,
                            label: x?.firstName + ' ' + x?.lastName,
                            content: x?.contactInfo?.email,
                            imageUrl: x?.imageUrl,
                        } as SelectCustomOption)
                );
            setMemberOptions(options);
        }
    }, [members, selectedUserId]);

    const handleDescriptionChange = (value: string): void => {
        form.setFieldsValue({ description: value });
    };

    const handleNoteChange = (value: string): void => {
        form.setFieldsValue({ note: value });
    };

    const handleAdministrativeBookingChange = (): void => {
        setAdministrativeBooking((prevState) => !prevState);
        setUserSearchResults([]);
        setSelectedUserId(null);
        setSelectedCreditBalance(undefined);
        setSelectedAttendees([]);
        setCreditBalances([]);
    };

    const confirmRecurrenceReset = async (): Promise<boolean> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <ConferenceRoom />,
                title: t(`Booking.current_recurrence_confirm_title`),
                message: t(`Booking.current_recurrence_confirm_message`),
                positiveText: t('yes'),
                negativeText: t('no'),
            }))
        )
            return false;
        return true;
    };

    const resetRecurrence = (): void => {
        setSelectedRecurrenceTypeIndex(t('Booking.does_not_repeat'));
        setSelectedRecurrenceDuration(undefined);
        setSelectedCustomNumberOfOccurences(undefined);
    };

    const onRecurrenceBlockedResize = async (): Promise<void> => {
        if (await confirmRecurrenceReset()) {
            setRecurrenceBlocksResize(false);
            resetRecurrence();
        }
    };

    const handleTimeCellClick = async (e: any, bookingInformation: any, period: any) => {
        if (
            selectedRecurrenceTypeIndex === t('Booking.does_not_repeat') ||
            (await confirmRecurrenceReset())
        ) {
            let bookingInfo = bookingInformation;
            if (currentBooking.id) {
                bookingInfo = {
                    ...currentBooking,
                    ...bookingInformation,
                    bookingId: currentBooking.id,
                };
            }

            setCurrentBooking(bookingInfo);
            resetRecurrence();
        }
    };

    const handleBookingResize = async ({ formatedBookingStart, formatedBookingEnd }: any) => {
        if (
            selectedRecurrenceTypeIndex === t('Booking.does_not_repeat') ||
            (await confirmRecurrenceReset())
        ) {
            const booking: any = currentBooking;
            const start = formatedBookingStart
                ? formatedBookingStart
                : momentTimezone(booking.bookingStart).tz(timezone).format();
            const bookingEnd = formatedBookingEnd
                ? formatedBookingEnd
                : momentTimezone(booking.bookingEnd).tz(timezone).format();
            if (booking !== undefined) {
                booking.bookingStart = start;
                booking.bookingEnd = bookingEnd;
                setCurrentBooking({ ...booking });
            }
            resetRecurrence();
        }
    };

    useEffect(() => {
        //create recurrence duration dropdown options
        if (availableBookingRecurrencePresets) {
            if (selectedRecurrenceTypeIndex === t('Booking.does_not_repeat')) return;

            setBookingRecurrenceDurationOptions(
                availableBookingRecurrencePresets[parseInt(selectedRecurrenceTypeIndex)].endDates
                    ?.map(
                        (preset) =>
                            ({
                                value: preset?.occurrences?.toString(),
                                label: preset?.name,
                            } as SelectCustomOption)
                    )
                    .concat([
                        {
                            value: t('Booking.select_number_occurences'),
                            label: t('Booking.select_number_occurences'),
                        } as SelectCustomOption,
                    ]) ?? []
            );
        }
    }, [availableBookingRecurrencePresets, t, selectedRecurrenceTypeIndex]);

    useEffect(() => {
        //create number or occurences dropdown options
        if (
            selectedRecurrenceDuration === t('Booking.select_number_occurences') &&
            availableBookingRecurrencePresets &&
            selectedRecurrenceTypeIndex !== t('Booking.does_not_repeat')
        ) {
            const maxAmount =
                availableBookingRecurrencePresets[parseInt(selectedRecurrenceTypeIndex)]
                    .maxOccurrences;
            if (!maxAmount) return;
            const options = [];
            let i = 2;
            while (i < maxAmount + 1) {
                options.push({
                    label: t('Booking.index_occurences', { param1: i }),
                    value: i.toString(),
                } as SelectCustomOption);
                i++;
            }
            setCustomNumberOfOccurencesOptions(options);
        }
    }, [
        selectedRecurrenceDuration,
        selectedRecurrenceTypeIndex,
        availableBookingRecurrencePresets,
        t,
    ]);

    const getRecurrencePresets = useCallback(
        async (amenityId: string, periodStart: string, periodEnd: string): Promise<void> => {
            try {
                globalLoadingStore.addLoading();
                const response = await bookingService.getBookingRecurrencePresets(amenityId, {
                    periodStart,
                    periodEnd,
                    enableMemberRestrictions: false,
                } as GetRecurrencePresetsRequestDto);
                if (response) {
                    const items = response
                        .filter((recurrence) => recurrence !== null)
                        .map((recurrence) => recurrence!);
                    setAvailableBookingRecurrencePresets(items);
                    setAvailableRecurrenceTypeOptions(
                        items
                            .map(
                                (preset, i) =>
                                    ({
                                        label: preset.name,
                                        content: t('Booking.from_to_credits', {
                                            param1: preset.estimatedLowerMonthlyCost,
                                            param2: preset.estimatedUpperMonthlyCost,
                                        }),
                                        value: i.toString(),
                                    } as SelectCustomOption)
                            )
                            .concat([
                                {
                                    label: t('Booking.does_not_repeat'),
                                    value: t('Booking.does_not_repeat'),
                                } as SelectCustomOption,
                            ])
                    );
                }
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [globalLoadingStore, bookingService, toastStore, t]
    );

    useEffect(() => {
        if (resource && currentBooking && !fetchedRecurrencePresets) {
            getRecurrencePresets(
                resource[0].id,
                currentBooking.bookingStart,
                currentBooking.bookingEnd
            );
            setFetchedRecurrencePresets(true);
        }
    }, [resource, getRecurrencePresets, currentBooking, fetchedRecurrencePresets]);

    const onResizeStopped = (): void => {
        if (resource && currentBooking) {
            getRecurrencePresets(
                resource[0].id,
                currentBooking.bookingStart,
                currentBooking.bookingEnd
            );
        }
    };

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        setCurrentBooking(newBookingInfo);
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <ConferenceRoom />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t(`Booking.book_a_room_confirm_positive${isEditing ? '_edit' : ''}`),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const createBooking = async (
        recurrenceExceptions?: RecurrenceBookingExceptionRequestDto[] | null
    ): Promise<void> => {
        const values = form.getFieldsValue();

        const recurrence: CreateBookingRequestRecurrenceParametersDto | undefined =
            selectedRecurrenceTypeIndex === t('Booking.does_not_repeat')
                ? undefined
                : {
                      occurrences:
                          selectedRecurrenceDuration === t('Booking.select_number_occurences')
                              ? values.occurenceCount.value
                              : selectedRecurrenceDuration,
                      type: availableBookingRecurrencePresets![
                          Number.parseInt(selectedRecurrenceTypeIndex)
                      ].recurrence?.type,
                      frequency:
                          availableBookingRecurrencePresets![
                              Number.parseInt(selectedRecurrenceTypeIndex)
                          ].recurrence?.frequency,
                      options:
                          availableBookingRecurrencePresets![
                              Number.parseInt(selectedRecurrenceTypeIndex)
                          ].recurrence?.options,
                  };

        const data: CreateBookingRequestDto = {
            title: bookingTitle,
            description: values.description,
            isAdministrative: administrativeBooking,
            bookingCreatorId: values.bookingCreatorId?.value,
            recurrence: recurrence,
            exceptions: recurrenceExceptions,
            invitedAccountIds: selectedAttendees,
            creditBalanceId: values.creditBalanceId?.value,
            isPrivate: privateBooking,
            periodStart: currentBooking.bookingStart,
            periodEnd: currentBooking.bookingEnd,
            note: values.note,
        };

        if (!(await validateForm(data))) return;

        try {
            globalLoadingStore.addLoading();
            if (data.recurrence !== undefined) {
                const response = await bookingService.getBookingSummary(
                    newBookingInfo.conferenceRoomId,
                    {
                        enableMemberRestrictions: false,
                        ...data,
                    } as BookingSummaryRequestDto
                );
                if (response?.occurrences?.some((occurence) => occurence?.hasConflict)) {
                    setBookingsWithRecurrenceErrors(
                        response.occurrences
                            .filter((booking) => booking !== null && booking?.hasConflict)
                            .map((booking) => booking!)
                    );
                    setConflictModalVisible(true);
                    return;
                } else {
                    await bookingService.book(newBookingInfo.conferenceRoomId, data);
                }
            } else {
                await bookingService.book(newBookingInfo.conferenceRoomId, data);
            }

            toastStore.toast({
                type: 'success',
                messageKey: `Booking.booking_created_success`,
            });
            dismiss(true);
        } catch (e) {
            if (e.response?.data?.error === 'E010001') {
                const errors = new Map<string, string[]>();
                errors.set('creditBalanceId', [e.response?.data?.error_description]);
                setErrors(errors);
            } else if (
                e.response?.data?.error === 'E028002' ||
                e.response?.data?.error === 'E028001'
            ) {
                toastStore.toast({
                    type: 'error',
                    message: e.response?.data?.error_description,
                });
            } else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const editBooking = async (): Promise<void> => {
        const oldValues = booking;
        const values = form.getFieldsValue();

        const data: UpdateBookingRequestDto = {
            amenityId: currentBooking.conferenceRoomId,
            description: values.description || oldValues.description,
            invitedAccountIds:
                selectedAttendees || oldValues.invitedAccounts?.map((x: { id: any }) => x.id),
            isPrivate: privateBooking,
            note: values.note || oldValues.note,
            periodStart: currentBooking.bookingStart,
            periodEnd: currentBooking.bookingEnd,
            title: bookingTitle,
        };
        try {
            globalLoadingStore.addLoading();
            await bookingService.updateBooking(currentBooking.id, data);
            toastStore.toast({
                type: 'success',
                messageKey: `Booking.booking_created_success`,
            });
            dismiss(true);
        } catch (e) {
            if (e.response?.data?.error === 'E010001') {
                const errors = new Map<string, string[]>();
                errors.set('creditBalanceId', [e.response?.data?.error_description]);
                setErrors(errors);
            } else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const submit = async (
        recurrenceExceptions?: RecurrenceBookingExceptionRequestDto[] | null
    ): Promise<void> => {
        if (currentBooking?.id) {
            await editBooking();
        } else {
            await createBooking(recurrenceExceptions);
        }
    };

    const cancelBooking = async (withRefund: boolean): Promise<void> => {
        try {
            await bookingService.cancelBooking({
                bookingId: currentBooking.id,
                refundCredits: withRefund,
            });
            toastStore.toast({
                type: 'success',
                messageKey: `Booking.booking_canceled_success`,
            });
            dismiss(true);
        } catch (e) {
            if (e.response?.data?.error === 'E011003') {
                toastStore.toast({
                    type: 'error',
                    messageKey: `Booking.booking_canceled_past_error`,
                });
            } else if (!e.treated) {
                toastStore.genericError();
            }
        }
    };

    const isPastBooking = (): boolean => {
        return moment(endDate).isBefore();
    };

    useEffect(() => {
        //set if current booking is conflicting with another in the calendar
        if (currentBooking) {
            const resourcePeriods = resource[0]?.periods;
            const bookedPeriods = resourcePeriods?.filter(
                (period: any) =>
                    period.unavailabilityReason ===
                    DailyBookingSummaryPeriodUnavailabilityReasonDto.Booked
            );

            let currentBookingIsErrored = false;
            if (bookedPeriods) {
                bookedPeriods.forEach((period: any) => {
                    if (
                        currentBooking.bookingId &&
                        currentBooking.bookingId === period.booking?.id
                    ) {
                        return;
                    }
                    const bookedPeriodStart = moment(period?.start);
                    const bookedPeriodEnd = moment(period?.end);
                    const currentStart = moment(currentBooking?.bookingStart);
                    const currentEnd = moment(currentBooking?.bookingEnd);
                    if (
                        currentStart.isSame(bookedPeriodStart) ||
                        currentStart.isBetween(bookedPeriodStart, bookedPeriodEnd) ||
                        (currentStart.isSameOrBefore(bookedPeriodStart) &&
                            currentEnd.isAfter(bookedPeriodStart))
                    ) {
                        currentBookingIsErrored = true;
                        return;
                    }
                });
            }
            setCurrentBookingIsErrored(currentBookingIsErrored);
        }
    }, [currentBooking, resource]);

    return (
        <BaseModal
            visible={visible}
            title={isEditing ? t('Booking.book_a_room_edit') : t('Booking.book_a_room')}
            className="FormModal BookingModal"
            onCancel={(): Promise<void> => exit()}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Row gutter={formGutter}>
                    <Col span={12}>
                        <SimpleList
                            title={t('Booking.conference_room')}
                            className="conference-room"
                        >
                            {conferenceRoom && (
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Row>
                                                <Col span={12}>
                                                    {conferenceRoom.name}

                                                    {conferenceRoom.isPrivate && (
                                                        <div className="room-info private-room">
                                                            <PrivateRoom
                                                                fill={theme['primary-color']}
                                                            />{' '}
                                                            {t('Booking.book_a_room_private_room')}
                                                        </div>
                                                    )}

                                                    <div className="room-info peoples">
                                                        {t('Booking.book_a_room_people', {
                                                            param1: conferenceRoom.numberOfSeats,
                                                            count: conferenceRoom.numberOfSeats,
                                                        })}
                                                    </div>
                                                </Col>
                                                <Col span={12} style={{ textAlign: 'right' }}>
                                                    <div className="aminities-list">
                                                        {conferenceRoom.hasPhone && (
                                                            <Phone fill={theme['black']} />
                                                        )}
                                                        {conferenceRoom.hasScreenSharing && (
                                                            <ScreenSharing fill={theme['black']} />
                                                        )}
                                                        {conferenceRoom.hasTv && (
                                                            <Television fill={theme['black']} />
                                                        )}
                                                        {conferenceRoom.hasWhiteboard && (
                                                            <WhiteBoard fill={theme['black']} />
                                                        )}
                                                    </div>
                                                    <Tag
                                                        label={`${t(
                                                            'Booking.book_a_room_credits_per_block',
                                                            {
                                                                param1: conferenceRoom.creditPointsPerBlock,
                                                                count: conferenceRoom.creditPointsPerBlock,
                                                            }
                                                        )} / ${conferenceRoom.minutesPerBlock} ${t(
                                                            'Booking.book_a_room_per_min'
                                                        )}`}
                                                        color={TagColors.dark}
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                        avatar={
                                            <ImageWithPlaceholder
                                                width="32"
                                                height="32"
                                                imgSrc={conferenceRoom?.imageUrl}
                                                defaultImg={
                                                    <ConferenceRoom fill={theme['white']} />
                                                }
                                            />
                                        }
                                    />
                                </List.Item>
                            )}
                        </SimpleList>
                        <SimpleList title={t('date')} className="booking-date">
                            {startDate && (
                                <List.Item>
                                    <List.Item.Meta
                                        title={date}
                                        avatar={<Calendar fill={theme['primary-color']} />}
                                    />
                                </List.Item>
                            )}
                        </SimpleList>

                        <ValidatedFormItem
                            errors={errors}
                            name="title"
                            label={t('Booking.book_a_room_title')}
                        >
                            <Input value={bookingTitle} onChange={(e) => setBookingTitle(e.target.value)}/>
                        </ValidatedFormItem>

                        <ValidatedFormItem
                            errors={errors}
                            name="description"
                            label={t('Booking.book_a_room_description')}
                            className="description"
                        >
                            <Input hidden />
                        </ValidatedFormItem>

                        <ReactQuill
                            theme="snow"
                            className="description-quill"
                            value={
                                form.getFieldValue('description') ||
                                currentBooking?.description ||
                                null
                            }
                            onChange={handleDescriptionChange}
                        />

                        <ValidatedFormItem errors={errors} name="isAdministrative">
                            <Checkbox
                                defaultChecked={booking?.isAdministrative}
                                onChange={handleAdministrativeBookingChange}
                                disabled={currentBooking?.id}
                            >
                                {t('Booking.book_a_room_administrative_booking')}
                            </Checkbox>
                        </ValidatedFormItem>

                        <ValidatedFormItem
                            errors={errors}
                            name="bookingCreatorId"
                            label={t('Booking.book_a_room_creator')}
                            required
                        >
                            {currentBooking?.id ? (
                                <Input
                                    defaultValue={`${currentBooking?.organizer?.firstName ?? ''} ${
                                        currentBooking?.organizer?.lastName ?? ''
                                    }`}
                                    disabled={true}
                                />
                            ) : (
                                <SelectCustom
                                    options={userOptions}
                                    defaultImg={<User fill={theme['white']} />}
                                    strongLabel={true}
                                    placeholder={t('SelectCustom.placeholder_default')}
                                    onChange={handleUserSelect}
                                    onKeywordsChange={handleKeywordsChange}
                                    onMenuScrollToBottom={handleMenuScrollToBottom}
                                    hideSelectedOptions={false}
                                    selected={selectedUserId ? [selectedUserId] : null}
                                    isLoading={selectLoading}
                                    idAttribute={'bookingCreatorId'}
                                />
                            )}
                        </ValidatedFormItem>

                        {((creditBalances.length > 0 && !administrativeBooking) ||
                            (booking?.id && !booking?.isAdministrative)) && (
                            <>
                                <ValidatedFormItem
                                    errors={errors}
                                    name="creditBalanceId"
                                    label={t('Booking.book_a_room_credits_balance')}
                                    required
                                >
                                    {booking?.id && !booking?.isAdministrative ? (
                                        <Input
                                            defaultValue={booking.creditBalance.title}
                                            disabled={true}
                                        />
                                    ) : (
                                        <SelectCustom
                                            options={creditOptions}
                                            defaultImg={<User fill={theme['white']} />}
                                            strongLabel={true}
                                            placeholder={t('SelectCustom.placeholder_default')}
                                            onChange={handleCreditBalanceSelect}
                                            hideSelectedOptions={false}
                                            selected={[selectedCreditBalance?.id!]}
                                            isLoading={selectLoading}
                                            idAttribute={'creditBalanceId'}
                                        />
                                    )}
                                </ValidatedFormItem>

                                <ValidatedFormItem errors={errors} name="private" required>
                                    <Checkbox
                                        defaultChecked={booking?.isPrivate}
                                        onChange={() => setPrivateBooking(!privateBooking)}
                                    >
                                        {t('Booking.book_a_room_private')}
                                    </Checkbox>
                                </ValidatedFormItem>

                                {showAttendees() && (
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="attendees"
                                        label={t('Booking.book_a_room_attendees')}
                                        required
                                    >
                                        <SelectCustom
                                            options={memberOptions}
                                            defaultImg={<User fill={theme['white']} />}
                                            isMulti={true}
                                            strongLabel={true}
                                            placeholder={t('SelectCustom.placeholder_default')}
                                            hideSelectedOptions={false}
                                            onChange={handleAttendeeSelect}
                                            selected={selectedAttendees}
                                            isLoading={selectLoading}
                                            idAttribute={'attendees'}
                                        />
                                    </ValidatedFormItem>
                                )}
                            </>
                        )}

                        {!isEditing && (
                            <ValidatedFormItem
                                errors={errors}
                                name="recurrenceType"
                                label={t('Booking.recurrence_type')}
                            >
                                <SelectCustom
                                    className="recurrence-dropdown"
                                    options={availableRecurrenceTypeOptions}
                                    onChange={(value): void => {
                                        setSelectedRecurrenceTypeIndex(value.value);
                                        if (value !== t('Booking.does_not_repeat'))
                                            setRecurrenceBlocksResize(true);
                                    }}
                                    hideSelectedOptions={false}
                                    selected={
                                        selectedRecurrenceTypeIndex
                                            ? [selectedRecurrenceTypeIndex]
                                            : [t('Booking.does_not_repeat')]
                                    }
                                    strongLabel
                                    idAttribute={'recurrenceType'}
                                />
                            </ValidatedFormItem>
                        )}

                        {!isEditing &&
                            selectedRecurrenceTypeIndex !== t('Booking.does_not_repeat') && (
                                <ValidatedFormItem
                                    errors={errors}
                                    name="recurrenceDuration"
                                    label={t('Booking.recurrence_duration')}
                                    required
                                >
                                    <SelectCustom
                                        className="recurrence-dropdown"
                                        options={bookingRecurrenceDurationOptions}
                                        onChange={(value): void =>
                                            setSelectedRecurrenceDuration(value.value)
                                        }
                                        hideSelectedOptions={false}
                                        selected={
                                            selectedRecurrenceDuration
                                                ? [selectedRecurrenceDuration]
                                                : null
                                        }
                                        strongLabel
                                        idAttribute={'recurrenceDuration'}
                                    />
                                </ValidatedFormItem>
                            )}

                        {!isEditing &&
                            selectedRecurrenceDuration ===
                                t('Booking.select_number_occurences') && (
                                <ValidatedFormItem
                                    errors={errors}
                                    name="occurenceCount"
                                    label={t('Booking.number_of_occurences')}
                                    required
                                >
                                    <SelectCustom
                                        className="recurrence-dropdown"
                                        options={customNumberOfOccurencesOptions}
                                        onChange={(value): void =>
                                            setSelectedCustomNumberOfOccurences(value.value)
                                        }
                                        hideSelectedOptions={false}
                                        selected={
                                            selectedCustomNumberOfOccurences
                                                ? [selectedCustomNumberOfOccurences]
                                                : null
                                        }
                                        strongLabel
                                        idAttribute={'occurenceCount'}
                                    />
                                </ValidatedFormItem>
                            )}

                        <ValidatedFormItem
                            errors={errors}
                            name="note"
                            label={t('Booking.booking_note')}
                            className="description"
                        >
                            <Input hidden />
                        </ValidatedFormItem>

                        <ReactQuill
                            theme="snow"
                            className="description-quill"
                            value={form.getFieldValue('note') || currentBooking?.note || null}
                            onChange={handleNoteChange}
                        />
                    </Col>
                    <Col span={12}>
                        <SimpleList
                            title={t('Booking.time')}
                            className="conference-room booking-time"
                        />
                        <FlexibleBooking
                            view={FlexibleBookingViewType.details}
                            classNames="w-100 position-relative"
                            resizable
                            resources={resource}
                            currentBooking={currentBooking}
                            timezone={timezone}
                            onTimeCellClick={handleTimeCellClick}
                            onBookingResize={handleBookingResize}
                            min={0}
                            max={24}
                            zoom={2}
                            isEditing
                            filters={[]}
                            resourcePeriod={resourcePeriod}
                            selectedDate={selectedDate}
                            currentBookingIsErrored={currentBookingIsErrored}
                            recurrenceBlocksResize={recurrenceBlocksResize}
                            onRecurrenceBlockedResize={onRecurrenceBlockedResize}
                            onResizeStopped={onResizeStopped}
                        />
                    </Col>
                </Row>
                <div className="actions d-flex">
                    {isEditing && (
                        <>
                            <Button
                                type="default"
                                className="secondary negative f-auto"
                                htmlType="button"
                                onClick={(): Promise<void> => cancelBooking(true)}
                                disabled={isPastBooking()}
                            >
                                {t('Booking.cancel_with_refund')}
                            </Button>
                            <Button
                                type="default"
                                className="secondary positive"
                                htmlType="button"
                                onClick={(): Promise<void> => cancelBooking(false)}
                                disabled={isPastBooking()}
                            >
                                {t('Booking.cancel_without_refund')}
                            </Button>
                        </>
                    )}
                    <Button
                        type="default"
                        className="secondary negative m-l-auto"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('close')}
                    </Button>
                    <Button
                        type="primary"
                        className="positive"
                        onClick={(): Promise<void> => submit()}
                    >
                        {t('save')}
                    </Button>
                </div>
            </Form>

            {conflictModalVisible && (
                <BookingConflictsModal
                    visible={conflictModalVisible}
                    onComplete={(
                        success: boolean,
                        exceptions: RecurrenceBookingExceptionRequestDto[] | null
                    ): void => {
                        setConflictModalVisible(false);
                        if (success) {
                            submit(exceptions);
                        }
                    }}
                    originalAmenity={resource[0]}
                    bookingsWithRecurrenceErrors={bookingsWithRecurrenceErrors}
                    timezone={timezone}
                    locationId={locationId}
                />
            )}
        </BaseModal>
    );
};

export default React.memo(BookingModal);
