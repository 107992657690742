// GENERATED FILE - DO NOT MODIFY

export enum LeadSourceDto {
    BrokerListingAgent = "BrokerListingAgent",
    BrokerTenantRep = "BrokerTenantRep",
    CallIn = "CallIn",
    CG5K = "CG5K",
    CoStarLoopNet = "CoStarLoopNet",
    CoworkerCom = "CoworkerCom",
    DeskPass = "DeskPass",
    Drift = "Drift",
    EmailCampaign = "EmailCampaign",
    EmployeeReferral = "EmployeeReferral",
    FacebookAds = "FacebookAds",
    FoundersFloor = "FoundersFloor",
    InstantOffice = "InstantOffice",
    LinkedInAds = "LinkedInAds",
    LiquidSpace = "LiquidSpace",
    MemberReferral = "MemberReferral",
    OfficeFreedom = "OfficeFreedom",
    Other = "Other",
    PivotDesk = "PivotDesk",
    Podium = "Podium",
    SubscriptionModification = "SubscriptionModification",
    SubscriptionRenewal = "SubscriptionRenewal",
    Tradeshow = "Tradeshow",
    WalkIn = "WalkIn",
    Website = "Website",
    WorkplaceEvent = "WorkplaceEvent",
    Workthere = "Workthere",
}