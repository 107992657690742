import { Button, Form, Input } from 'antd';
import BaseModal from 'Components/base-modal/base-modal';
import { Plan } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useService, useStores } from 'Hooks';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionService } from 'Services/SubscriptionService';
import './edit-reference-number-modal.less';

interface EditReferenceNumberModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    subscriptionId: string;
    referenceNumber?: string | null;
}

const EditReferenceNumberModal: FunctionComponent<EditReferenceNumberModalProps> = ({
    visible,
    onComplete,
    subscriptionId,
    referenceNumber,
}) => {
    const subscriptionService = useService(SubscriptionService);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Plan />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`confirm_positive`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const request = { referenceNumber: form.getFieldsValue().referenceNumber };

        try {
            globalLoadingStore.addLoading();

            await subscriptionService.updateSubscriptionReferenceNumber(subscriptionId, request);

            toastStore.toast({
                type: 'success',
                message: t('model_edit_success', { param1: 'Reference number' }),
            });

            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    useEffect(() => {
        form.setFieldsValue({ referenceNumber: referenceNumber ? referenceNumber : '' });
    }, [form, referenceNumber]);

    return (
        <BaseModal
            className="EditReferenceNumberModal FormModal"
            visible={visible}
            title={t('Subscription.subscription_reference_number')}
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <ValidatedFormItem
                    name={'referenceNumber'}
                    label={t('Subscription.subscription_reference_number_edit')}
                >
                    <Input
                        onChange={(event): void => {
                            form.setFieldsValue({ referenceNumber: event.target.value });
                        }}
                        defaultValue={referenceNumber ? referenceNumber : ''}
                    />
                </ValidatedFormItem>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={exit}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" className="positive" htmlType="submit">
                        {t('Subscription.subscription_reference_number_save')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(EditReferenceNumberModal);
