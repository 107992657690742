import { Button, Table } from 'antd';
import { ColumnType, TablePaginationConfig } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetAccountMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetAccountMessagesRequestDto';
import { GetMessagesSortColumnDto } from 'Api/Features/Messages/Dtos/GetMessagesSortColumnDto';
import { MessageReceiverTypeDto } from 'Api/Features/Messages/Dtos/MessageReceiverTypeDto';
import ContactsHeader from 'Components/contacts-header';
import Icon from 'Components/icons/icon';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { TableFilters } from 'Components/table-filters';
import { TdWithImage } from 'Components/td-with-image';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MessageService } from 'Services/MessageService';
import { FilterStore } from 'Stores';
import { cleanVal } from 'Utils/NumberUtils';
import { displayFormatedDateTime } from 'Utils/TimeUtils';
import { ContactContext } from '..';
import './index.less';
import SendMessageModal from './send-message-modal';

const advancedFilters: AdvancedFilter[] = [];

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 20,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

interface ImageAndName {
    name?: string;
    imageUrl?: string | null;
}

const imageCellRender = (item: ImageAndName): ReactNode => {
    if (item.name) {
        return (
            <TdWithImage defaultImg={<Icon iconName="User" />} imgSrc={item?.imageUrl}>
                {item?.name}
            </TdWithImage>
        );
    } else {
        return '-';
    }
};

interface Messages {
    id: string;
    title: string;
    message: string;
    creator: ReactNode;
    date: string;
}

const Messages: FunctionComponent = () => {
    const messageService = useService(MessageService);
    const [messages, setMessages] = useState<Messages[]>([]);
    const [loading, setLoading] = useState(false);
    const [sendMessageModalIsOpen, setSendMessageModalIsOpen] = useState(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const paginationRef = useRef(initialPaginationState);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { toastStore } = useStores();
    const contactContext = useContext(ContactContext);
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();

    const headerDetails = {
        iconName: 'MessageBubble',
        title: t('message'),
        subTitle: t('Contact.message_section_subtitle'),
        breadCrumbMemberName: `${cleanVal.string(contactContext?.firstName)}  ${cleanVal.string(
            contactContext?.lastName
        )}`,
    };

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `contacts`,
            nameKey: 'User.contacts_label',
        },
        {
            path: `${id}/dashboard`,
            nameKey: headerDetails?.breadCrumbMemberName,
        },
        {
            path: `messages`,
            nameKey: 'Contact.contacts_messages_label',
        },
    ];

    const actionButton = (
        <Button
            type="primary"
            onClick={(): void => {
                setSendMessageModalIsOpen(true);
            }}
        >
            {t('Contact.contacts_messages_send_label')}
        </Button>
    );

    const columns: ColumnType<Messages>[] = [
        {
            title: t('title'),
            dataIndex: 'title',
            key: GetMessagesSortColumnDto.Title,
            sorter: true,
        },
        {
            title: t('Contact.contacts_message_label'),
            dataIndex: 'message',
        },
        {
            title: t('Contact.contacts_message_creator'),
            dataIndex: 'creator',
            key: GetMessagesSortColumnDto.SenderName,
            sorter: true,
        },
        {
            title: t('Contact.contacts_message_date'),
            dataIndex: 'date',
            key: GetMessagesSortColumnDto.Date,
            sorter: true,
            className: 'dateCol',
        },
    ];

    const fetchMessages = useCallback(
        async (params: {
            searchTerm?: string;
            pagination: TablePaginationConfig;
            sortColumn: GetMessagesSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);

            try {
                const request: GetAccountMessagesRequestDto = {
                    searchTerm: params.searchTerm,
                    pageSize: params.pagination.pageSize || 10,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                };

                const response = await messageService.getAccountMessages(id, request, true);
                const responseMapping: Messages[] = response?.items
                    ? response.items.map((message) => ({
                          id: cleanVal.string(message?.id),
                          title: cleanVal.string(message?.title),
                          message: cleanVal.string(message?.body),
                          creator: imageCellRender({
                              name:
                                  message?.sender?.firstName && message?.sender?.lastName
                                      ? cleanVal.string(
                                            `${message?.sender?.firstName} ${message?.sender?.lastName}`
                                        )
                                      : undefined,
                              imageUrl: message?.sender?.imageUrl,
                          }),
                          date: displayFormatedDateTime(message?.date),
                      }))
                    : [];

                setMessages(responseMapping);
                setPagination({
                    ...params.pagination,
                    total: response?.totalItemCount,
                });
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [setLoading, messageService, t]
    );

    const CreateMessageModalComplete = (success: boolean): void => {
        setSendMessageModalIsOpen(false);
        if (success) {
            fetchMessages({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                sortColumn: GetMessagesSortColumnDto.Date,
                sortDirection: SortDirection.Descending,
            });
        }
    };

    const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any): void => {
        const { searchTerm } = filterStoreRef.current;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        fetchMessages({
            searchTerm: searchTerm,
            pagination: pagination,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
    };

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;

            fetchMessages({
                searchTerm: filterStore.searchTerm,
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                sortColumn: GetMessagesSortColumnDto.Date,
                sortDirection: SortDirection.Descending,
            });
        });

        return (): void => {
            disposer();
        };
    }, [fetchMessages]);

    return (
        <div className="Messages">
            <ContactsHeader
                routes={breadcrumbs}
                headerData={headerDetails}
                loading={loading}
                action={actionButton}
            />

            <Content>
                <TableFilters filterStore={filterStoreRef.current} includeSearch />

                <Table
                    className="table-striped-rows"
                    bordered
                    columns={columns}
                    rowKey={(row: Messages): string => row.id}
                    dataSource={messages}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </Content>

            {sendMessageModalIsOpen && (
                <SendMessageModal
                    visible={sendMessageModalIsOpen}
                    onComplete={(success: boolean) => CreateMessageModalComplete(success)}
                    receiverId={id}
                    name={cleanVal.string(headerDetails.breadCrumbMemberName)}
                    messageReceiverType={MessageReceiverTypeDto.Account}
                />
            )}
        </div>
    );
};

export default Messages;
