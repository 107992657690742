import { SimpleList } from 'Components/simple-list';
import SubscriptionTimelineItem, {
    SubscriptionTimelineItemProps,
} from 'Components/subscription-timeline-item/subscription-timeline-item';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './subscription-timeline.less';

interface SubscriptionTimelineProps {
    data: SubscriptionTimelineItemProps[] | undefined;
}

const SubscriptionTimeline: FunctionComponent<SubscriptionTimelineProps> = ({ data }) => {
    const { t } = useTranslation();

    return (
        <SimpleList
            className="SubscriptionTimeline"
            title={t('Subscription.subscription_timeline_title')}
        >
            <div className="timeline-wrapper">
                {data &&
                    data.map((item, i) => (
                        <SubscriptionTimelineItem
                            key={`${item.date}-${i}`}
                            date={item.date}
                            link={item.link}
                            subtitle={item.subtitle}
                            details={item.details}
                            circleEmphasis={item.circleEmphasis}
                            emphasis={item.emphasis}
                            disabled={item.disabled}
                        />
                    ))}
            </div>
        </SimpleList>
    );
};

export default SubscriptionTimeline;
