import { Checkbox, Col, DatePicker, Input, Radio, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { Delete } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { DATETIME_FORMAT, DISPLAY_MONTH_YEAR_FORMAT, MILLION } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'variant';
import { FormInstance } from 'antd/lib/form';
import './discount.less';
import { NumberInput } from 'Components/NumberInput';
import { number } from 'yup';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

interface DiscountProps {
    index: number;
    errors?: Map<string, string[]>;
    remove: (index: number) => void;
    proposalStart?: moment.Moment;
    proposalEnd?: moment.Moment | null;
    form: FormInstance;
}

const formGutter: [Gutter, Gutter] = [40, 0];

interface DisabledDateRange {
    start: moment.Moment;
    end: moment.Moment;
}

const Discount: FunctionComponent<DiscountProps> = ({
    index,
    errors,
    remove,
    proposalStart,
    proposalEnd,
    form,
}) => {
    const { t } = useTranslation();
    const [startCheckboxValue, setStartCheckboxValue] = useState<boolean>(
        form.getFieldValue('discounts')?.[index]?.startDate === undefined
    );
    const [endCheckboxValue, setEndCheckboxValue] = useState<boolean>(
        form.getFieldValue('discounts')?.[index]?.endDate === undefined
    );

    const [startDateValue, setStartDateValue] = useState<moment.Moment | null>(null);
    const [endDateValue, setEndDateValue] = useState<moment.Moment | null>(null);

    const disabledDates = () => {
        const disabledDates: DisabledDateRange[] = [];
        if (proposalStart) {
            disabledDates.push({
                start: moment.utc('0001-01-01'),
                end: proposalStart,
            });
        }
        if (proposalEnd) {
            disabledDates.push({
                start: proposalEnd,
                end: moment.utc('9999-01-01'),
            });
        }

        return disabledDates;
    };

    //#region Effect
    useEffect(() => {
        let invalid = false;
        if (proposalStart) {
            const proposalStartOfMonth = moment(proposalStart).startOf('month');
            if (startDateValue && proposalStartOfMonth > startDateValue) invalid = true;
            if (endDateValue && proposalStart > endDateValue) invalid = true;
            if (proposalEnd && proposalStart > proposalEnd) invalid = true;
        }
        if (proposalEnd) {
            const proposalEndOfMonth = moment(proposalEnd).endOf('month');
            if (endDateValue && proposalEndOfMonth < endDateValue) invalid = true;
            if (endDateValue && proposalStart && proposalStart > endDateValue) invalid = true;
            if (startDateValue && proposalEnd < startDateValue) invalid = true;
        }

        if (invalid) remove(index);
    }, [proposalStart, proposalEnd, startDateValue, endDateValue, index, remove]);
    //#endregion Effect

    //#region Render helpers
    const titleLabel = (): ReactNode => {
        return (
            <>
                {t('Proposal.discount_title')}
                <small className="title-tip">{t('Proposal.discount_title_tip')}</small>
            </>
        );
    };

    const noteLabel = (): ReactNode => {
        return (
            <>
                {t('note')}
                <small className="title-tip">{t('Proposal.discount_note_tip')}</small>
            </>
        );
    };
    //#endregion Render helpers

    return (
        <div className="Discount">
            <div className="discount-container">
                <Row gutter={formGutter} className="discount-container__date-price">
                    <Col span={8} className="custom-date-container">
                        <Radio.Group className="custom-date-actions">
                            <Radio.Button
                                onClick={(): void => {
                                    setStartCheckboxValue((prev) => {
                                        if (prev === false) {
                                            const formValues = form!.getFieldsValue();
                                            const discounts = formValues.discounts;
                                            discounts[index] = {
                                                ...discounts[index],
                                                startDate: null,
                                            };
                                            form!.setFieldsValue({
                                                discounts: [...discounts],
                                            });
                                            setStartDateValue(null);
                                        }
                                        return !prev;
                                    });
                                }}
                            >
                                <>
                                    <span className="discount-dates-pre-text">
                                        {t('Proposal.from_start')}
                                    </span>
                                    <Checkbox
                                        className="discount-dates-checkbox"
                                        value={startCheckboxValue}
                                        checked={startCheckboxValue}
                                        onClick={(): void => {
                                            setStartCheckboxValue((prev) => {
                                                if (prev === false) {
                                                    const formValues = form!.getFieldsValue();
                                                    const discounts = formValues.discounts;
                                                    discounts[index] = {
                                                        ...discounts[index],
                                                        startDate: null,
                                                    };
                                                    form!.setFieldsValue({
                                                        discounts: [...discounts],
                                                    });
                                                    setStartDateValue(null);
                                                }
                                                return !prev;
                                            });
                                        }}
                                    />
                                </>
                            </Radio.Button>
                        </Radio.Group>
                        <ValidatedFormItem
                            name={[index, 'startDate']}
                            errors={errors}
                            label={t('start_month')}
                            required
                            className="discount-dates-start"
                            rules={[
                                {
                                    required: !startCheckboxValue,
                                    message: t('Proposal.start_month_required'),
                                },
                            ]}
                        >
                            <DatePicker
                                disabledDate={(current: moment.Moment): boolean =>
                                    disabledDates().some((date) =>
                                        current.isBetween(
                                            moment(date['start'], DATETIME_FORMAT),
                                            moment(date['end'], DATETIME_FORMAT),
                                            'month'
                                        )
                                    )
                                }
                                picker={'month'}
                                disabled={startCheckboxValue}
                                onChange={(value): void =>
                                    setStartDateValue(value?.startOf('month') ?? null)
                                }
                                value={startDateValue}
                                format={DISPLAY_MONTH_YEAR_FORMAT}
                            />
                        </ValidatedFormItem>
                    </Col>

                    <Col span={8} className="custom-date-container">
                        <Radio.Group className="custom-date-actions">
                            <Radio.Button
                                onClick={(): void =>
                                    setEndCheckboxValue((prev) => {
                                        if (prev === false) {
                                            const formValues = form!.getFieldsValue();
                                            const discounts = formValues.discounts;
                                            discounts[index] = {
                                                ...discounts[index],
                                                endDate: null,
                                            };
                                            form!.setFieldsValue({
                                                discounts: [...discounts],
                                            });
                                        }
                                        return !prev;
                                    })
                                }
                            >
                                <>
                                    <span className="discount-dates-pre-text">
                                        {t('Proposal.until_end')}
                                    </span>
                                    <Checkbox
                                        className="discount-dates-checkbox"
                                        value={endCheckboxValue}
                                        checked={endCheckboxValue}
                                        onClick={(): void =>
                                            setEndCheckboxValue((prev) => {
                                                if (prev === false) {
                                                    const formValues = form!.getFieldsValue();
                                                    const discounts = formValues.discounts;
                                                    discounts[index] = {
                                                        ...discounts[index],
                                                        endDate: null,
                                                    };
                                                    form!.setFieldsValue({
                                                        discounts: [...discounts],
                                                    });
                                                }
                                                return !prev;
                                            })
                                        }
                                    />
                                </>
                            </Radio.Button>
                        </Radio.Group>
                        <ValidatedFormItem
                            name={[index, 'endDate']}
                            errors={errors}
                            label={t('last_month')}
                            required
                            className="discount-dates-end"
                            rules={[
                                {
                                    required: !endCheckboxValue,
                                    message: t('Proposal.end_month_required'),
                                },
                            ]}
                        >
                            <DatePicker
                                disabledDate={(current: moment.Moment): boolean =>
                                    disabledDates().some((date) =>
                                        current.isBetween(
                                            moment(date['start'], DATETIME_FORMAT),
                                            moment(date['end'], DATETIME_FORMAT),
                                            'month'
                                        )
                                    )
                                }
                                picker={'month'}
                                disabled={endCheckboxValue}
                                onChange={(value): void =>
                                    setEndDateValue(value?.endOf('month') ?? null)
                                }
                                value={endDateValue}
                                format={DISPLAY_MONTH_YEAR_FORMAT}
                            />
                        </ValidatedFormItem>
                    </Col>
                    <Col span={8}>
                        <ValidatedFormItem
                            name={[index, 'amount']}
                            errors={errors}
                            label={t('amount')}
                            required
                            rules={[{ required: true, message: t('Proposal.price_required') }]}
                        >
                            <NumberFormat
                                allowNegative={false}
                                decimalScale={2}
                                fixedDecimalScale
                                customInput={(props: any) => (
                                    <Input addonBefore={'$'} addonAfter={t('monthly')} {...props} />
                                )}
                                isAllowed={(value: NumberFormatValues): boolean =>
                                    value.floatValue === undefined ||
                                    (value.floatValue !== undefined && value.floatValue <= MILLION)
                                }
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>
                <div className="discount-container__info">
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name={[index, 'title']}
                                errors={errors}
                                label={titleLabel()}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name={[index, 'internalNote']}
                                errors={errors}
                                label={noteLabel()}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                </div>
            </div>

            <span className="garbage-container" onClick={(): void => remove(index)}>
                <Delete fill={theme['primary-color']} />
            </span>
        </div>
    );
};

export default Discount;
