import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ConferenceRoom: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.764 4.443h18.325v-1.68H2.764v1.68zm.967 13.999h16.39V5.207H3.732v13.235zm9.143 3.847a.948.948 0 01-1.895 0 .948.948 0 011.895 0zM21.852 2H2v3.207h.967v13.998h8.552v1.423a1.713 1.713 0 00-1.303 1.661c0 .944.767 1.711 1.71 1.711.944 0 1.71-.767 1.71-1.71 0-.822-.58-1.51-1.353-1.674v-1.41h8.603v-14h.967V2z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.41 16.508h1.884v-1.884H5.41v1.884zm3.971-8.196h5.04v-1.17H9.38v1.17zm7.178 8.196h1.883v-1.884h-1.883v1.884zm-5.6 0h1.934v-1.884H10.96v1.884zM8.057 13.86H6.734v-1.883h4.785v1.883h-1.323v3.411h3.46v-3.41h-1.373v-1.884h4.836v1.883h-1.324v3.411h3.41v-3.41h-1.323v-2.647h-5.6V9.076h2.902V6.378H8.618v2.698h2.901v2.138H5.97v2.646H4.647v3.411h3.41v-3.41z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ConferenceRoom;
