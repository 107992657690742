import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CG: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 55 55" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.5 55C42.6878 55 55 42.6878 55 27.5C55 12.3122 42.6878 0 27.5 0C12.3122 0 0 12.3122 0 27.5C0 42.6878 12.3122 55 27.5 55Z"
                    fill="#244C5A"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M41.9556 29.826L41.6814 29.7754L41.6315 30.053C40.3966 36.9528 34.5035 41.9277 27.6112 41.9277C19.7581 41.9277 13.3699 35.4596 13.3699 27.5092C13.3699 19.5588 19.7581 13.0908 27.6112 13.0908C34.5035 13.0908 40.3966 18.0657 41.6233 24.9215L41.6732 25.1991L41.9556 25.1916L42.7543 25.045L43.0293 24.9944L42.9785 24.716C41.6266 17.1575 35.1672 11.7029 27.6112 11.7029C19.0036 11.7029 12 18.794 12 27.5092C12 36.2253 19.0036 43.3156 27.6112 43.3156C35.1672 43.3156 41.6266 37.8601 42.9712 30.3447L43.0211 30.0663L42.7543 29.9735L41.9556 29.826Z"
                    fill="#FEFEFE"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.6114 19.6437C30.9552 19.6437 33.9169 21.803 34.98 25.018L35.0692 25.2856L35.3466 25.2375L36.1175 24.9765L36.3827 24.8862L36.2926 24.6177C35.0291 20.7962 31.5461 18.2566 27.6114 18.2566C22.5719 18.2566 18.4727 22.407 18.4727 27.5096C18.4727 32.6112 22.5719 36.7617 27.6114 36.7617C32.6501 36.7617 36.7501 32.6112 36.7501 27.5096V27.0986V26.816H36.471H28.5255H28.2464V27.0986V27.9205V28.2031H28.5255H35.3499C35.0013 32.2475 31.6574 35.3746 27.6114 35.3746C23.328 35.3746 19.8426 31.8464 19.8426 27.5096C19.8426 23.1718 23.328 19.6437 27.6114 19.6437Z"
                    fill="#FEFEFE"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M38.2271 28.2025H46.3674V26.8154H38.2271V28.2025Z"
                    fill="#FEFEFE"
                />
            </svg>
        </span>
    );
};

export default CG;
