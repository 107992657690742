import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Caution: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 22" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.34736 20.3847L11.9401 2.03953L22.5313 20.3847H1.34736ZM23.8118 20.6313L12.3656 0.806634C12.2787 0.653757 12.1159 0.560059 11.9399 0.560059C11.7623 0.560059 11.5996 0.653757 11.5126 0.806634L0.066452 20.6313C-0.0221507 20.7842 -0.0221507 20.9716 0.066452 21.1244C0.155384 21.2773 0.318123 21.371 0.494014 21.371H23.3844C23.562 21.371 23.7247 21.2773 23.8118 21.1244C23.9004 20.9716 23.9004 20.7842 23.8118 20.6313Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.9395 17.8944C11.6288 17.8944 11.3757 17.6428 11.3757 17.3322C11.3757 17.0215 11.6288 16.77 11.9395 16.77C12.2485 16.77 12.5017 17.0215 12.5017 17.3322C12.5017 17.6428 12.2485 17.8944 11.9395 17.8944ZM11.9398 15.9004C11.1491 15.9004 10.508 16.5431 10.508 17.3321C10.508 18.1212 11.1491 18.7639 11.9398 18.7639C12.729 18.7639 13.3716 18.1212 13.3716 17.3321C13.3716 16.5431 12.729 15.9004 11.9398 15.9004Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.2774 14.4949H11.6018L11.3881 8.99459H12.491L12.2774 14.4949ZM13.2553 8.25978C13.1747 8.1743 13.0598 8.12498 12.9429 8.12498H10.936C10.8191 8.12498 10.7042 8.1743 10.6235 8.25978C10.5413 8.34361 10.4971 8.46033 10.5019 8.57704L10.7501 14.9469C10.7585 15.1803 10.9491 15.3644 11.1842 15.3644H12.6947C12.9298 15.3644 13.1205 15.1803 13.1289 14.9469L13.3769 8.57704C13.3819 8.46033 13.3375 8.34361 13.2553 8.25978Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Caution;
