import React, { FunctionComponent, useEffect } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import BaseModal from 'Components/base-modal/base-modal';
import { File } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useService, useStores } from 'Hooks';
import { useTranslation } from 'react-i18next';
import './edit-note-modal.less';
import { LeadService } from 'Services/LeadService';
import { OpportunityService } from 'Services/OpportunityService';
import { SubscriptionService } from 'Services/SubscriptionService';
import { MemberService } from 'Services/MemberService';
import { MembershipService } from 'Services/MembershipService';
import { BookingDto } from 'Api/Features/Amenities/Dtos/BookingDto';
import { BookingService } from 'Services/BookingService';


const formGutter: [Gutter, Gutter] = [40, 0];

interface EditNoteModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    note?: string | null;
    entityId?: string | null;
    entityType: NoteEntityTypeEnum;
    booking?: BookingDto
}

export enum NoteEntityTypeEnum {
    Lead,
    Opportunity,
    Subscription,
    Contact,
    Membership,
    Booking
}

const EditNoteModal: FunctionComponent<EditNoteModalProps> = ({
    visible,
    onComplete,
    note,
    entityId,
    entityType,
    booking
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const leadService = useService(LeadService);
    const opportunityService = useService(OpportunityService);
    const subscriptionService = useService(SubscriptionService);
    const memberService = useService(MemberService);
    const membershipService = useService(MembershipService);
    const bookingService = useService(BookingService);
    //#endregion
    
    //#region Effects
    useEffect(() => {
        if (note) {
            form.setFieldsValue({
                note: note
            });
        }
    }, [note, form]);
    //#endregion


    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <File />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`model_confirm_positive_edit`, {param1 : 'note'}),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const model = {
            note: form.getFieldValue('note') ?? '',
        };

        try {
            globalLoadingStore.addLoading();

            if (entityId) {
                switch (entityType) {
                    case NoteEntityTypeEnum.Lead:
                        await leadService.updateLeadNote(entityId, model);
                        break;
                    case NoteEntityTypeEnum.Opportunity:
                        await opportunityService.updateOpportunityNote(entityId, model);
                        break;
                    case NoteEntityTypeEnum.Subscription:
                        await subscriptionService.updateSubscriptionNote(entityId, model);
                        break;
                    case NoteEntityTypeEnum.Contact:
                        await memberService.updateMemberNote(entityId, model);
                        break;
                    case NoteEntityTypeEnum.Membership:
                        await membershipService.updateMembershipNote(entityId, model);
                        break;
                    case NoteEntityTypeEnum.Booking:
                        await bookingService.updateBooking(entityId, {
                            ...booking,
                            note: model.note,
                        });
                        break;
                }
            }
            toastStore.toast({
                type: 'success',
                messageKey: t(`model_edit_success`, { param1: 'Note' }),
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion


    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('edit_note')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="EditNoteModal">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name="note"
                                label={t('note')}
                            >
                                <Input.TextArea style={{height: 200}}/>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(EditNoteModal);
