import { Button, Popover } from 'antd';
import { CalendarEventDto } from 'Api/Features/CalendarEvents/Dtos/CalendarEventDto';
import { CalendarEventTypeDto } from 'Api/Features/CalendarEvents/Dtos/CalendarEventTypeDto';
import { Company, Location, User } from 'Components/icons';
import Icon from 'Components/icons/icon';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { Tag } from 'Components/tag';
import { TagColors } from 'Components/tag/tag';
import { useStores } from 'Hooks';
import {
    ACTIVITY_CALENDAR_DAY_HEADER_DISPLAY_FORMAT,
    ACTIVTY_CALENDAR_EVENT_DATE_DISPLAY,
    DISPLAY_MONTH_FORMAT,
    DISPLAY_TIME_12H_FORMAT,
    DISPLAY_TIME_12H_NO_PERIOD,
    MMMM_D_FORMAT,
    MMMM_D_YYYY_FORMAT,
    MOMENT_PARSING_FORMAT,
    MOMENT_TIME_FORMAT,
    US_LANGUAGE_CODE,
} from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { Calendar, Culture, DateLocalizer, DateRange, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import timezones from 'appcom-timezones';
import './index.less';

const localizer = momentLocalizer(moment);

interface ActivityBoardCalendarEvent {
    id: string;
    start: Date;
    end: Date;
    title: ReactNode;
    allDay: boolean;
    eventType: CalendarEventTypeDto;
    description?: string;
    eventTitle?: string;
    user?: any;
    company?: any;
    location?: any;
    linkedEntity?: any;
    timezone?: string;
    allMonth: boolean;
    calendarEventDto: CalendarEventDto;
}

interface ActivityBoardCalendarProps {
    calendarEvents: CalendarEventDto[] | null | undefined;
    onNavigateDateRange: (range: any) => void;
    onCancelMeeting: (id: string) => void;
    onCancelTour: (id: string) => void;
    onCancelCommunityEvent: (id: string) => void;
}

const ActivityBoardCalendar: FunctionComponent<ActivityBoardCalendarProps> = ({
    calendarEvents,
    onNavigateDateRange,
    onCancelMeeting,
    onCancelTour,
    onCancelCommunityEvent
}) => {
    const { t } = useTranslation();
    const { locationStore } = useStores();
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [events, setEvents] = useState<ActivityBoardCalendarEvent[]>([]);

    const getEventContent = (startTime: string, endTime: string, user?: any, location?: any, eventTitle?: string): ReactNode => {
        return (
            <div className="event-content">
                <div className="rbc-event-label-custom">{startTime} — {endTime}</div>
                {eventTitle && <div className="event-title">{eventTitle}</div>}
                {user && (
                    <div className="event-info-container">
                        <ImageWithPlaceholder
                            imgSrc={user?.imageUrl}
                            width={16}
                            height={16}
                            defaultImg={<User />}
                        />{' '}
                        <span className="event-info">
                            {user?.firstName} {user?.lastName}
                        </span>
                    </div>
                )}

                {location && (
                    <div className="event-info-container">
                        <ImageWithPlaceholder
                            width={16}
                            height={16}
                            defaultImg={<Location />}
                            imgSrc={location?.mainImageUrl}
                        />{' '}
                        <span className="event-info">{location?.name}</span>
                    </div>
                )}
            </div>
        );
    };

    const getEventCompany = (event: CalendarEventDto) => {
        if (
            (event.type === CalendarEventTypeDto.Meeting && event.meeting?.lead !== null) ||
            (event.type === CalendarEventTypeDto.Tour && event.meeting?.lead !== null)
        )
            return null;

        if (event.type === CalendarEventTypeDto.Meeting) {
            return (
                event.meeting?.opportunity?.membership ?? event.meeting?.subscription?.membership
            );
        }

        if (event.type === CalendarEventTypeDto.Tour) {
            return event.tour?.opportunity?.membership;
        }

        return null;
    };

    const getEventUser = (event: CalendarEventDto) => {
        if (event.type === CalendarEventTypeDto.Meeting) {
            return event.meeting?.lead
                ? { firstName: event.meeting.lead.firstName, lastName: event.meeting.lead.lastName }
                : event.meeting?.opportunity?.contact;
        }
        if (event.type === CalendarEventTypeDto.Tour) {
            return event.tour?.lead
                ? { firstName: event.tour.lead.firstName, lastName: event.tour.lead.lastName }
                : event.tour?.opportunity?.contact;
        }
        return null;
    };

    const getActivityBoardCalendarEvent = useCallback(
        (event: CalendarEventDto): ActivityBoardCalendarEvent | null => {
            if (event.type === CalendarEventTypeDto.Meeting) {
                const start = moment.utc(event.meeting?.startTime);
                const end = moment.utc(event.meeting?.endTime);
                const location =
                    event.meeting?.lead?.campus ??
                    event.meeting?.opportunity?.campus ??
                    event.meeting?.subscription?.campus;
                return {
                    id: event.meeting?.id,
                    start: new Date(
                        start.year(),
                        start.month(),
                        start.date(),
                        start.hour(),
                        start.minute()
                    ),
                    end: new Date(end.year(), end.month(), end.date(), end.hour(), end.minute()),
                    title: getEventContent(
                        start.format(DISPLAY_TIME_12H_NO_PERIOD),
                        end.format(DISPLAY_TIME_12H_NO_PERIOD),
                        getEventUser(event),
                        location,
                        t('Lead.lead_event_Meeting')
                    ),
                    allDay: false,
                    eventType: event.type,
                    company: getEventCompany(event),
                    description: event.meeting?.note,
                    eventTitle: t('Lead.lead_event_Meeting'),
                    location: location,
                    user: getEventUser(event),
                    linkedEntity: event.meeting?.lead
                        ? {
                              ...event.meeting.lead,
                              entityType: 'lead',
                          }
                        : event.meeting?.opportunity
                        ? {
                              ...event.meeting.opportunity,
                              entityType: 'opportunity',
                          }
                        : {
                              ...event.meeting?.subscription,
                              entityType: 'subscription',
                          },
                    timezone: undefined,
                    calendarEventDto: event,
                } as ActivityBoardCalendarEvent;
            }

            if (event.type === CalendarEventTypeDto.Tour) {
                const start = moment.utc(event.tour?.startTime);
                const end = moment.utc(event.tour?.endTime);
                return {
                    id: event.tour?.id,
                    start: new Date(
                        start.year(),
                        start.month(),
                        start.date(),
                        start.hour(),
                        start.minute()
                    ),
                    end: new Date(end.year(), end.month(), end.date(), end.hour(), end.minute()),
                    title: getEventContent(
                        moment.parseZone(event.tour?.startTime).format(DISPLAY_TIME_12H_NO_PERIOD),
                        moment.parseZone(event.tour?.endTime).format(DISPLAY_TIME_12H_NO_PERIOD),
                        getEventUser(event),
                        event.tour?.campus,
                        t('tour')
                    ),
                    allDay: false,
                    eventType: event.type,
                    company: getEventCompany(event),
                    description: event.tour?.note,
                    eventTitle: t('tour'),
                    location: event.tour?.campus,
                    user: getEventUser(event),
                    linkedEntity: event.tour?.lead
                        ? {
                              ...event.tour.lead,
                              entityType: 'lead',
                          }
                        : event.tour?.opportunity
                        ? {
                              ...event.tour.opportunity,
                              entityType: 'opportunity',
                          }
                        : null,
                    timezone: event.tour?.campus
                        ? locationStore.locations.find(
                              (location) => location.id === event.tour?.campus?.id
                          )?.timeZone
                        : undefined,
                    calendarEventDto: event,
                } as ActivityBoardCalendarEvent;
            }

            if (event.type === CalendarEventTypeDto.CommunityEvent) {
                let start, end;
                if(event.communityEvent?.isAllDay) {
                    start = moment(
                        moment(event.communityEvent?.startDate).utc().format(MOMENT_PARSING_FORMAT)
                    ).toDate();
    
                    end = moment(
                        moment(event.communityEvent?.endDate)
                            //if event is all day, add 1 day to endDate so calendar displays correctly
                            .add(event.communityEvent?.isAllDay ? 1 : 0, 'day')
                            .utc()
                            .format(MOMENT_PARSING_FORMAT)
                    ).toDate();
                }
                else {
                    //Take the time of the event, regardless of timezone, and put the browser's timezone in that date.
                    //That way the calendar will display the original time of the event without converting time.
                    //A timezone mention is displayed in event.
                    start = moment.parseZone(event.communityEvent?.periodStart).tz(browserTimezone, true).toDate();
                    end = moment.parseZone(event.communityEvent?.periodEnd).tz(browserTimezone, true).toDate();
                }

                return {
                    id: event.communityEvent?.id,
                    start,
                    end,
                    title: getEventContent(
                        moment.parseZone(event.communityEvent?.periodStart).tz(browserTimezone, true).format(DISPLAY_TIME_12H_NO_PERIOD),
                        moment.parseZone(event.communityEvent?.periodEnd).tz(browserTimezone, true).format(DISPLAY_TIME_12H_NO_PERIOD),
                        undefined,
                        event.communityEvent?.campus,
                        event.communityEvent?.title ?? 'Community event'
                    ),
                    allDay: event.communityEvent?.isAllDay,
                    eventType: event.type,
                    popOverVisible: false,
                    company: getEventCompany(event),
                    description: event.communityEvent?.description,
                    eventTitle: event.communityEvent?.title,
                    location: event.communityEvent?.campus,
                    user: getEventUser(event),
                    linkedEntity: { ...event.communityEvent, entityType: 'community event' },
                    timezone: event.communityEvent?.timeZone ?? undefined,
                    allMonth: event.communityEvent?.isAllMonth,
                    calendarEventDto: event,
                } as ActivityBoardCalendarEvent;
            }

            return null;
        },
        [t]
    );

    /**Events that span more than one day must be divided into multiple events. If the event is allDay, we can leave them as is*/
    const manageMultiDayEvents = useCallback((events: CalendarEventDto[]): CalendarEventDto[] => {
        const result: CalendarEventDto[] = [];
        events.map((event: CalendarEventDto) => {
            //as of now only community events can span multiple days
            if (
                event.type !== CalendarEventTypeDto.CommunityEvent ||
                event.communityEvent?.isAllDay
            )
                result.push(event);
            else if (event.communityEvent) {
                // create an event for every day of the month with start/end for that day.
                const startTimeString = moment
                    .parseZone(event.communityEvent?.periodStart)
                    .format(MOMENT_TIME_FORMAT);

                const endTimeString = moment
                    .parseZone(event.communityEvent?.periodEnd)
                    .format(MOMENT_TIME_FORMAT);

                const lastDayOfEvent = moment.parseZone(event.communityEvent.endDate);
                const currentDay = moment.parseZone(event.communityEvent.startDate);

                while (currentDay.isSameOrBefore(lastDayOfEvent)) {
                    result.push({
                        ...event,
                        communityEvent: {
                            ...event.communityEvent,
                            periodStart: `${moment(currentDay).format(
                                MOMENT_PARSING_FORMAT
                            )}T${startTimeString}+00:00`,
                            periodEnd: `${moment(currentDay).format(
                                MOMENT_PARSING_FORMAT
                            )}T${endTimeString}+00:00`,
                        },
                    });
                    currentDay.add(1, 'day');
                }
            }
        });

        return result;
    }, [events])

    useEffect(() => {
        if (calendarEvents) {
            const eventsWithMultiDayManaged = manageMultiDayEvents(calendarEvents);
            const activityBoardCalendarEvents: ActivityBoardCalendarEvent[] = eventsWithMultiDayManaged
                .map((event) => getActivityBoardCalendarEvent(event))
                .filter((event) => event !== null)
                .map((event) => event!);
            setEvents(activityBoardCalendarEvents);
        }
    }, [calendarEvents, getActivityBoardCalendarEvent]);

    const formats = {
        dayFormat: ACTIVITY_CALENDAR_DAY_HEADER_DISPLAY_FORMAT,
        eventTimeRangeFormat: (
            { start, end }: DateRange,
            culture?: Culture,
            localizer?: DateLocalizer
        ): string =>
            localizer?.format(start, DISPLAY_TIME_12H_NO_PERIOD, US_LANGUAGE_CODE) +
            ' — ' +
            localizer?.format(end, DISPLAY_TIME_12H_NO_PERIOD, US_LANGUAGE_CODE),
        dayRangeHeaderFormat: (
            { start, end }: DateRange,
            culture?: Culture,
            localizer?: DateLocalizer
        ): string =>
            localizer?.format(start, MMMM_D_FORMAT, US_LANGUAGE_CODE) +
            ' — ' +
            localizer?.format(end, MMMM_D_YYYY_FORMAT, US_LANGUAGE_CODE),
    };

    //#region Event handlers
    const onClosePopOver = (): void => {
        const eventsState = [...events];
        setEvents(eventsState);
    };

    const onDeleteEvent = (event: any): void => {
        if (event.eventType === CalendarEventTypeDto.Meeting) onCancelMeeting(event.id);

        if (event.eventType === CalendarEventTypeDto.Tour) onCancelTour(event.id);

        if (event.eventType === CalendarEventTypeDto.CommunityEvent) onCancelCommunityEvent(event.id);
    };

    const onViewEntityClick = (event: any): string => {
        switch (event.linkedEntity.entityType) {
            case 'lead':
                return `leads/${event.linkedEntity.id}`;
            case 'opportunity':
                return `opportunities/${event.linkedEntity.id}/dashboard`;
            case 'subscription':
                return `locations/${event.location.id}/subscriptions/${event.linkedEntity.id}`;
            case 'community event':
                return `locations/${event.location.id}/events/${event.linkedEntity.id}`;
            default:
                return '#';
        }
    };
    //#End region Event handlers

    //#region components
    const CustomToolbar = (toolbar: any): JSX.Element => {
        const moveBack = (): void => {
            toolbar.onNavigate('PREV');
        };
        const moveForward = (): void => {
            toolbar.onNavigate('NEXT');
        };
        const goToCurrent = (): void => {
            toolbar.onNavigate('TODAY');
        };

        return (
            <div className="custom-toolbar">
                <label className="date">{toolbar.label}</label>
                <div className="date-change-buttons-container">
                    <Button className="date-change-btn" onClick={moveBack}>
                        <Icon iconName="AngleLeft" />
                    </Button>

                    <Button className="date-change-btn" onClick={moveForward}>
                        <Icon iconName="AngleRight" />
                    </Button>

                    <Button className="date-change-btn today" onClick={goToCurrent}>
                        {t('Filter.today')}
                    </Button>
                </div>
            </div>
        );
    };

    const PopoverActions = (calendarObject: any): ReactNode => (
        <div className="popover-actions-container">
            <span
                className="popover-action"
                onClick={(): void => onDeleteEvent(calendarObject.event)}
            >
                <Icon iconName="Trash"></Icon>
            </span>
            <span
                className="popover-action"
                onClick={(): void => onClosePopOver()}
            >
                <Icon iconName="Close"></Icon>
            </span>
        </div>
    );

    const getPopOverContentEventTimeString = (calendarObject: any): string => {
        let result = `${moment(calendarObject.event.start).format(
            ACTIVTY_CALENDAR_EVENT_DATE_DISPLAY
        )}, ${moment(calendarObject.event.start).format(DISPLAY_TIME_12H_FORMAT)} - ${moment(
            calendarObject.event.end
        ).format(DISPLAY_TIME_12H_FORMAT)}`;

        if (calendarObject.event.eventType === CalendarEventTypeDto.CommunityEvent) {
            let dateString, timeString;
            //dateSection
            if (calendarObject.event.allMonth) {
                dateString = `All ${moment(calendarObject.event.calendarEventDto.startDate).format(
                    DISPLAY_MONTH_FORMAT
                )}`;
            } else {
                if (
                    moment(calendarObject.event.calendarEventDto.startDate).isSame(
                        moment(calendarObject.event.calendarEventDto.endDate)
                    )
                ) {
                    dateString = moment(calendarObject.event.start).format(
                        ACTIVTY_CALENDAR_EVENT_DATE_DISPLAY
                    );
                } else {
                    dateString = `${moment(calendarObject.event.end).format(
                        ACTIVTY_CALENDAR_EVENT_DATE_DISPLAY
                    )} - ${moment(calendarObject.event.start).format(
                        ACTIVTY_CALENDAR_EVENT_DATE_DISPLAY
                    )}`;
                }
            }
            //timeSection
            if (calendarObject.event.allDay) {
                timeString = 'All day';
            } else {
                timeString = `${moment(calendarObject.event.start).format(
                    DISPLAY_TIME_12H_FORMAT
                )} - ${moment(calendarObject.event.end).format(DISPLAY_TIME_12H_FORMAT)}`;
            }

            result = `${dateString}, ${timeString}`;
        }

        return result;
    };

    const PopoverContent = (calendarObject: any): ReactNode => {
        let timezoneString = calendarObject.event.timezone
        ? timezones.find((tz) => tz.id === calendarObject.event.timezone)
              ?.description
        : 'Unspecified timezone';
        if(timezoneString !== 'Unspecified timezone') {
            timezoneString = timezoneString?.split(' - ')[0]
        }

        return (
            <div className="popover-content-container">
                <div className="event-title">{calendarObject.event.eventTitle}</div>
                <div className="event-time">
                    {getPopOverContentEventTimeString(calendarObject)}
                    {!calendarObject.event.allDay && (
                        <div className="timezone">{`(${timezoneString})`}</div>
                    )}
                </div>
                <div className="event-info-container">
                    <div className="event-info-first-line-container">
                        {calendarObject.event.user && (
                            <div className="text-with-image">
                                <ImageWithPlaceholder
                                    width={16}
                                    height={16}
                                    defaultImg={<User />}
                                    imgSrc={calendarObject.event.user?.imageUrl}
                                />
                                <span className="event-info-text">
                                    {calendarObject.event.user?.firstName}{' '}
                                    {calendarObject.event.user?.lastName}
                                </span>
                                {calendarObject.event.company && (
                                    <span className="event-info-spacer">{' - '}</span>
                                )}
                            </div>
                        )}
                        {calendarObject.event.company && (
                            <div className="text-with-image">
                                <ImageWithPlaceholder
                                    width={16}
                                    height={16}
                                    defaultImg={<Company />}
                                    imgSrc={calendarObject.event.company?.imageUrl}
                                />
                                <span className="event-info-text">
                                    {calendarObject.event.company?.name}
                                </span>
                            </div>
                        )}
                    </div>

                    {calendarObject.event.location && (
                        <div className="text-with-image location">
                            <ImageWithPlaceholder
                                width={16}
                                height={16}
                                defaultImg={<Location />}
                                imgSrc={calendarObject.event.location?.mainImageUrl}
                            />
                            <span className="event-info-text">
                                {calendarObject.event.location?.name}
                            </span>
                        </div>
                    )}
                </div>
                {calendarObject.event.description && (
                    <div
                        className="event-description"
                        dangerouslySetInnerHTML={{ __html: calendarObject.event.description }}
                    />
                )}
                <div className="event-footer-container">
                    <Tag
                        label={t(
                            `ActivityBoard.CalendarEventTypeDto_${calendarObject.event.eventType}`
                        )}
                        rounded
                        color={TagColors.light}
                    />
                    <Link to={(): string => onViewEntityClick(calendarObject.event)}>
                        {t('view_entity', {
                            param1: calendarObject.event.linkedEntity?.entityType,
                        })}
                    </Link>
                </div>
            </div>
        );
    }

    const CustomEventComponent = (calendarObject: any): JSX.Element => {
        const dayOfWeek: number = moment(calendarObject.event.start).day();
        return (
            <Popover
                trigger="click"
                title={PopoverActions(calendarObject)}
                content={PopoverContent(calendarObject)}
                placement={dayOfWeek <= 3 ? 'right' : 'left'}
                overlayClassName="calendar-event-popover"
            >
                {calendarObject.title}
            </Popover>
        );
    };
    //#End region components

    return (
        <div className="ActivityBoardCalendar">
            <Calendar
                localizer={localizer}
                events={events}
                view="week"
                views={['week']}
                style={{ height: 711 }}
                formats={formats}
                components={{
                    toolbar: CustomToolbar,
                    event: CustomEventComponent,
                }}
                slotPropGetter={(date: Date): any => {
                    if (moment(date).isBefore(moment().subtract(30, 'minute')))
                        return { className: 'past' };
                    else return { className: 'future' };
                }}
                eventPropGetter={(event, start, end): any => {
                    if (moment(end).isBefore(moment())) return { className: 'past-event' };
                    return { className: 'future-event' };
                }}
                onRangeChange={(range): void => onNavigateDateRange(range)}
                tooltipAccessor={(event): string =>
                    event.eventType === 'CommunityEvent'
                        ? event.eventTitle ?? ''
                        : event.description ?? ''
                }
            />
        </div>
    );
};

export default ActivityBoardCalendar;
