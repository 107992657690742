// GENERATED FILE - DO NOT MODIFY

export enum GetLeadsSortColumnDto {
    CompanyName = "CompanyName",
    PhoneNumber = "PhoneNumber",
    CampusName = "CampusName",
    Status = "Status",
    OwnerName = "OwnerName",
    Source = "Source",
    DateCreated = "DateCreated",
    NumberOfEmployees = "NumberOfEmployees",
}