import { Typography } from 'antd';
import ActivityActions from 'Components/activity-actions/activity-actions';
import Icon from 'Components/icons/icon';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './activity-log-item.less';

export interface ActivityLogItemProps {
    icon?: { name: string; primary?: boolean };
    title?: { text: string | null | undefined; bold?: boolean }[];
    note?: string | null;
    date?: string;
    lined?: boolean;
    onClickEdit?: () => void;
    onDeleteClick?: () => void;
    reason?: string | null;
    viewFiles?: boolean;
    onViewFilesClick?: () => void;
}

const ActivityLogItem: FunctionComponent<ActivityLogItemProps> = ({
    icon,
    title,
    note,
    date,
    lined = false,
    onClickEdit,
    onDeleteClick,
    reason, 
    viewFiles,
    onViewFilesClick
}) => {
    const { t } = useTranslation();

    return (
        <div className={`ActivityLogItem${lined ? ' lined' : ''}`}>
            <div className="wrapper">
                <ImageWithPlaceholder
                    className={icon && icon.primary ? 'primary-fill' : 'black-2-fill'}
                    width="32"
                    height="32"
                    defaultImg={<Icon iconName={icon && icon.name ? icon.name : 'WarningLined'} />}
                />

                <div className="activity-text">
                    {title &&
                        title.map(
                            (text, i) =>
                                text.text && (
                                    <Typography.Text key={i} className={text.bold ? 'bold' : ''}>
                                        {`${text.text} `}
                                    </Typography.Text>
                                )
                        )}

                    {viewFiles && (
                        <div className="note-container">
                            <div className="note-prefix" onClick={onViewFilesClick}>
                                <Typography.Text className="note-prefix view-file">
                                    {t('view_files')}
                                </Typography.Text>
                            </div>
                        </div>
                    )}

                    {note && (
                        <div className="note-container">
                            <div className="note-prefix">
                                <Typography.Text className="note-prefix">
                                    {`${t('note')}: `}
                                </Typography.Text>
                            </div>

                            <div className="note-content">
                                <Typography.Text className="note">{note}</Typography.Text>
                            </div>
                        </div>
                    )}

                    {reason && (
                        <div className="note-container">
                            <div className="note-prefix">
                                <Typography.Text className="note-prefix">
                                    {`${t('reason')}: `}
                                </Typography.Text>
                            </div>

                            <div className="note-content">
                                <Typography.Text className="note">{reason}</Typography.Text>
                            </div>
                        </div>
                    )}

                    {date && <div className="activity-text-date">{date}</div>}
                </div>

                {onClickEdit && onDeleteClick && (
                    <ActivityActions onClickEdit={onClickEdit} onDeleteClick={onDeleteClick} />
                )}
            </div>
        </div>
    );
};

export default ActivityLogItem;
