import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import { ListSectionHeader } from 'Components/list-section-header';
import { theme, images } from 'variant';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { useService, useStores } from 'Hooks';
import { useTranslation } from 'react-i18next';
import { Credit } from 'Components/icons';
import { autorun } from 'mobx';
import Table, { TablePaginationConfig } from 'antd/lib/table';
import { FilterStore } from 'Stores';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { TableFilters } from 'Components/table-filters';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { CreditPackService } from 'Services/CreditPackService';
import CreditPackEditModal from './edit-credit-pack-modal';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { useHistory } from 'react-router';
import { ColumnType } from 'antd/es/table';
import { cleanVal, currencyFormatter } from 'Utils/NumberUtils';
import './index.less';
import { CreditPackDto } from 'Api/Features/CreditPacks/Dtos/CreditPackDto';
import { GetCreditPacksSortColumnDto } from 'Api/Features/CreditPacks/Dtos/GetCreditPacksSortColumnDto';
import ReorderModal, { ReorderModel } from 'Components/reorder-modal/reorder-modal';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';
import { UpdateCreditPackOrderRequestDto } from 'Api/Features/CreditPacks/Dtos/UpdateCreditPackOrderRequestDto';

const { usersHeader } = images;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 20,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const breadcrumbs: BreadcrumbSegment[] = [
    {
        nameKey: 'Administration.administration_title',
        path: 'administration',
    },
    {
        nameKey: 'Administration.credit_packs',
        path: 'credit-packs',
    },
];

const advancedFilters: AdvancedFilter[] = [];

const CreditPacks: FunctionComponent = () => {
    const { t } = useTranslation();
    const creditPackService = useService(CreditPackService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const paginationRef = useRef(initialPaginationState);
    const [data, setData] = useState<CreditPackDto[]>([]);
    const history = useHistory();
    const { toastStore } = useStores();

    const [createModalOpen, setCreateModalOpen] = useState(false);

    const [reorderModalOpen, setReorderModalOpen] = useState(false);
    const [isManual, setIsManual] = useState<boolean>(false);

    const [loading, setLoading] = useState(false);

    const onRowClick = (pack: CreditPackDto): void => {
        history.push(`credit-packs/${pack.id}`);
    };

    const menuOptions: ActionMenuOption[] = [
        {
            key: 'create',
            title: t('Administration.create_credit_pack'),
            action: (): void => setCreateModalOpen(true),
        },
        {
            key: 'ordering',
            title: t('Administration.reorder_credit_packs'),
            action: (): void => setReorderModalOpen(true),
        },
    ];

    const columns: ColumnType<CreditPackDto>[] = [
        {
            title: t('order'),
            render: (pack: CreditPackDto) => cleanVal.number(pack?.order) + 1,
            sorter: true,
            key: GetCreditPacksSortColumnDto.Order,
        },
        {
            title: t('name'),
            render: (pack: CreditPackDto) => pack.name,
            sorter: true,
            key: GetCreditPacksSortColumnDto.Name,
        },
        {
            title: t('credits'),
            render: (pack: CreditPackDto) => pack.points,
            sorter: true,
            key: GetCreditPacksSortColumnDto.Credits,
            align: 'right',
        },
        {
            title: t('price'),
            render: (pack: CreditPackDto) => currencyFormatter(pack.price ?? 0),
            sorter: true,
            key: GetCreditPacksSortColumnDto.Price,
            align: 'right',
        },
    ];

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: GetCreditPacksSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);
            const filterStore = filterStoreRef.current;
            try {
                // call api
                const [data, totalItems] = await creditPackService.getCreditPacks({
                    pageSize: params.pagination.pageSize || 10,
                    page: (params.pagination.current || 1) - 1,
                    searchTerm: filterStore.searchTerm,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                });
                setData(data);
                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });

                const order = await creditPackService.getCreditPackOrdering();
                setIsManual(order?.method === OrderingMethodDto.Manual);
            } finally {
                setLoading(false);
            }
        },
        [creditPackService]
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            fetch({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                sortColumn: null,
                sortDirection: null,
                searchTerm: filterStore.searchTerm,
            });
        });
        return (): void => {
            disposer();
        };
    }, [fetch]);

    const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any): void => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }
        fetch({
            pagination,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
    };

    const onReorderSubmit = async (method: OrderingMethodDto, ids: string[]): Promise<void> => {
        await creditPackService.updateCreditPackOrdering({
            ids,
            method,
        } as UpdateCreditPackOrderRequestDto);

        setReorderModalOpen(false);
        const filterStore = filterStoreRef.current;
        fetch({
            pagination: {
                ...paginationRef.current,
                current: 1,
            },
            sortColumn: null,
            sortDirection: null,
            searchTerm: filterStore.searchTerm,
        });
        toastStore.toast({
            type: 'success',
            messageKey: t('model_reorder_success', { param1: t('Administration.credit_packs') }),
        });
    };

    return (
        <div className="CreditPacks">
            <ListSectionHeader
                title={t('Administration.credit_packs')}
                subTitle={t('Administration.credit_packs_subtitle')}
                defaultImg={<Credit fill={theme['primary-color']} />}
                backgroundImageUrl={usersHeader}
                action={<ActionMenu options={menuOptions} type="primary" trigger="click" />}
                routes={breadcrumbs}
            />
            <Content>
                <TableFilters filterStore={filterStoreRef.current} includeSearch />
                <Table
                    className="table-striped-rows"
                    bordered
                    columns={columns}
                    rowKey={(pack: CreditPackDto) => pack.id ?? ''}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(row: CreditPackDto) => ({
                        onClick: (): void => {
                            onRowClick(row);
                        },
                    })}
                />
            </Content>

            {createModalOpen && (
                <CreditPackEditModal
                    visible={createModalOpen}
                    onComplete={(success: boolean, id?: string): void => {
                        setCreateModalOpen(false);
                        if (success && id) history.push(`credit-packs/${id}`);
                    }}
                />
            )}

            {reorderModalOpen && (
                <ReorderModal
                    visible={reorderModalOpen}
                    dataSource={data.map(
                        (amenity) =>
                            ({
                                id: amenity.id,
                                name: amenity.name,
                                order: amenity.order,
                            } as ReorderModel)
                    )}
                    onDismiss={(): void => setReorderModalOpen(false)}
                    manual={isManual}
                    onSubmit={async (
                        orderingMethod: OrderingMethodDto,
                        ids: string[]
                    ): Promise<void> => await onReorderSubmit(orderingMethod, ids)}
                    modelName={'credit packs'}
                />
            )}
        </div>
    );
};

export default CreditPacks;
