import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const LogOut: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.606 2.57v-.54a7.295 7.295 0 00-.582 0v.54a5.653 5.653 0 01.582 0zm.868-.45v.549c3.001.546 5.284 3.18 5.284 6.337a6.45 6.45 0 01-6.443 6.441 6.449 6.449 0 01-6.441-6.441c0-3.157 2.282-5.79 5.283-6.337V2.12c-3.3.552-5.824 3.43-5.824 6.885a6.99 6.99 0 006.982 6.981 6.99 6.99 0 006.982-6.981c0-3.456-2.523-6.333-5.823-6.885z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.024 7.629h.582V0h-.582v7.629z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default LogOut;
