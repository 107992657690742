import { CampusStatusTypeDto } from 'Api/Features/Campuses/Dtos/CampusStatusTypeDto';
import { PHONE_REGEX, WEBSITE_REGEX } from 'Models/Constants';
import * as yup from 'yup';

export const CreateLocationSchema = yup.object(
    Object.assign({
        name: yup.string().required('Errors.required').max(100, 'Errors.must_be_under_one_hundred'),
        legalName: yup
            .string()
            .required('Errors.required')
            .max(100, 'Errors.must_be_under_one_hundred'),
        status: yup
            .string()
            .required('Errors.required')
            .max(100, 'Errors.must_be_under_one_hundred'),
        openingDate: yup.string().when('status', {
            is: (status) => status === CampusStatusTypeDto.PreActive,
            then: yup.string().required('Errors.required').max(30, 'Errors.must_be_under_thirty'),
        }),
        rulesAndAgreements: yup.string().required('Errors.required'),
        timeZone: yup
            .string()
            .required('Errors.required')
            .max(100, 'Errors.must_be_under_one_hundred'),
        latitude: yup
            .number()
            .required('Errors.required')
            .max(100, 'Errors.must_be_under_one_hundred'),
        longitude: yup
            .number()
            .required('Errors.required')
            .max(100, 'Errors.must_be_under_one_hundred'),
    })
);

export const CreateLocationContactSchema = yup.object(
    Object.assign({
        phone1: yup
            .string()
            .required('Errors.required')
            .max(25, 'Errors.must_be_under_twenty_five')
            .matches(PHONE_REGEX, 'Errors.phone_invalid'),
        email: yup.string().required('Errors.required').email('Errors.email_invalid'),
        website: yup
            .string()
            .required('Errors.required')
            .matches(WEBSITE_REGEX, 'Errors.link_invalid'),
    })
);

export const CreateLocationAddressSchema = yup.object(
    Object.assign({
        addressLine1: yup
            .string()
            .required('Errors.required')
            .max(100, 'Errors.must_be_under_one_hundred'),
        city: yup.string().required('Errors.required').max(100, 'Errors.must_be_under_one_hundred'),
        state: yup
            .string()
            .required('Errors.required')
            .max(100, 'Errors.must_be_under_one_hundred'),
        country: yup
            .string()
            .required('Errors.required')
            .max(100, 'Errors.must_be_under_one_hundred'),
        postalCode: yup
            .string()
            .required('Errors.required')
            .max(100, 'Errors.must_be_under_one_hundred'),
    })
);
