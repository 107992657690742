import { DayPassTransactionRecipientType } from 'Components/give-day-pass-modal';
import * as yup from 'yup';

export const GiveDayPassSchema = yup.object({
    typeId: yup.string().required('Errors.required'),
    campusId: yup.string().required('Errors.required'),
});

export const GiveLocationDayPassSchema = yup.object({
    typeId: yup.string().required('Errors.required'),
    dayPassTransactionRecipientType: yup.string(),
    selectedMemberId: yup.string().when('dayPassTransactionRecipientType', {
        is: (recipient) => recipient === DayPassTransactionRecipientType.Contact,
        then: yup.string().required('Errors.required')
    }),
    selectedMembershipId: yup.string().when('dayPassTransactionRecipientType', {
        is: (recipient) => recipient === DayPassTransactionRecipientType.Membership,
        then: yup.string().required('Errors.required')
    }),
});