import { action, observable } from 'mobx';

interface Url {
    previousUrl?: string;
    currentUrl?: string;
}

class UrlStore {
    @observable urlHistory: Url = { previousUrl: undefined, currentUrl: undefined };

    @action
    setUrl(url: string): void {
        if (this.urlHistory.currentUrl !== url)
            this.urlHistory = { previousUrl: this.urlHistory.currentUrl, currentUrl: url };
    }

    @action
    urlsAreFromSameContext(): boolean {
        // ['', 'baseurl', ...'rest of url']
        const prevUrlSplit = this.urlHistory.previousUrl?.split('/');
        const currentUrlSplit = this.urlHistory.currentUrl?.split('/');
        if (!prevUrlSplit || !currentUrlSplit) return false;
        return prevUrlSplit[1] === currentUrlSplit[1];
    }
}

export default UrlStore;
