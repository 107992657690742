import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import Icon from 'Components/icons/icon';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { theme } from 'variant';
import Table, { ColumnType, TablePaginationConfig } from 'antd/lib/table';
import debounce from 'lodash.debounce';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { FilterStore } from 'Stores';
import { useService, useStores } from 'Hooks';
import { TableFilters } from 'Components/table-filters';
import { MOMENT_PARSING_FORMAT, TABLE_DEBOUNCE_DELAY } from 'Models/Constants';
import { autorun } from 'mobx';
import moment from 'moment';
import { Button } from 'antd';
import { useParams } from 'react-router';
import { DayPassReservationDto } from 'Api/Features/DayPasses/Dtos/DayPassReservationDto';
import { DayPassReservationService } from 'Services/DayPassReservationService';
import { TdWithImage } from 'Components/td-with-image';
import { GetDayPassReservationsRequestDto } from 'Api/Features/DayPasses/Dtos/GetDayPassReservationsRequestDto';
import { GetDayPassReservationsSortColumnDto } from 'Api/Features/DayPasses/Dtos/GetDayPassReservationsSortColumnDto';
import GiveLocationDayPassModal from './give-location-day-pass-modal';

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 10,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const ReservedDayPasses: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const dayPassReservationService = useService(DayPassReservationService);
    const { id } = useParams<{ id: string }>();

    const [loading, setLoading] = useState(false);
    const paginationRef = useRef(initialPaginationState);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters: [] }));
    const [data, setData] = useState<DayPassReservationDto[]>([]);

    const [giveDayPassModalOpen, setGiveDayPassModalOpen] = useState(false);

    const { toastStore } = useStores();

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'reserved-day-passes',
            nameKey: 'DayPass.reserved_day_passes',
        },
    ];

    const columns: ColumnType<DayPassReservationDto>[] = [
        {
            title: t('DayPass.day_pass_date'),
            render: (pass: DayPassReservationDto) =>
                moment(pass.periodStart).format(MOMENT_PARSING_FORMAT),
            sorter: true,
            key: GetDayPassReservationsSortColumnDto.Date,
        },
        {
            title: t('type'),
            render: (event: DayPassReservationDto) => (
                <TdWithImage
                    defaultImg={<Icon iconName="Plan" />}
                    imgSrc={event.dayPass?.type?.imageUrl}
                >
                    {event.dayPass?.type?.displayName}
                </TdWithImage>
            ),
            sorter: true,
            key: GetDayPassReservationsSortColumnDto.TypeDisplayName,
        },
        {
            title: t('company'),
            render: (event: DayPassReservationDto) =>
                event.dayPass?.membership ? (
                    <TdWithImage
                        defaultImg={<Icon iconName="Company" />}
                        imgSrc={event.dayPass.membership.imageUrl}
                    >
                        {event.dayPass?.membership?.name}
                    </TdWithImage>
                ) : undefined,
            sorter: true,
            key: GetDayPassReservationsSortColumnDto.MembershipName,
        },
        {
            title: t('user'),
            render: (event: DayPassReservationDto) => (
                <TdWithImage
                    defaultImg={<Icon iconName="User" />}
                    imgSrc={event.consumerAccount?.imageUrl}
                >
                    {`${event.consumerAccount?.firstName} ${event.consumerAccount?.lastName}`}
                </TdWithImage>
            ),
            sorter: true,
            key: GetDayPassReservationsSortColumnDto.ConsumerAccountName,
        },
    ];

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: GetDayPassReservationsSortColumnDto | null;
            sortDirection: SortDirection | null;
            startDate?: string;
            endDate?: string;
        }) => {
            setLoading(true);
            try {
                const request: GetDayPassReservationsRequestDto = {
                    searchTerm: params.searchTerm,
                    pageSize: params.pagination.pageSize || 0,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    campusIds: [id],
                    maxDate: params.endDate,
                    minDate: params.startDate,
                };
                const data = await dayPassReservationService.getDayPassReservations(request);
                setData(data?.items?.filter((x) => x !== null).map((x) => x!) ?? []);
                setPagination({
                    ...params.pagination,
                    total: data?.totalItemCount,
                });
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [t]
    );

    const debounceSearch = useRef(
        debounce((params: { searchTerm?: string; startDate?: string; endDate?: string }) => {
            fetch({
                pagination: { ...paginationRef.current, current: 1 },
                searchTerm: params.searchTerm,
                sortColumn: null,
                sortDirection: null,
                startDate: params.startDate,
                endDate: params.endDate,
            });
        }, TABLE_DEBOUNCE_DELAY)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;

            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                startDate: filterStore.startDate,
                endDate: filterStore.endDate,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        const { searchTerm, startDate, endDate } = filterStoreRef.current;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        await fetch({
            pagination,
            searchTerm,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
            startDate,
            endDate,
        });
        paginationRef.current = pagination;
    };

    return (
        <div className="ReservedDayPasses">
            <LocationHeader
                title={t('DayPass.reserved_day_passes')}
                subTitle={t('DayPass.reserved_day_passes_section_subtitle')}
                defaultImg={<Icon iconName="Plan" fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            setGiveDayPassModalOpen(true);
                        }}
                    >
                        {t('Membership.give_a_day_pass')}
                    </Button>
                }
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeDates
                    datesPrefix={{ start: t('DayPass.day_pass_date_from'), end: t('to') }}
                    dateLabels={{
                        start: '',
                        end: '',
                    }}
                    includeSearch
                />

                <Table
                    className="table-striped-rows"
                    bordered
                    dataSource={data}
                    columns={columns}
                    loading={loading}
                    rowKey={(reservation: DayPassReservationDto): string => reservation.id || ''}
                    pagination={pagination}
                    onChange={handleTableChange}
                />
            </Content>

            {giveDayPassModalOpen && (
                <GiveLocationDayPassModal
                    visible={giveDayPassModalOpen}
                    onComplete={() => {
                        setGiveDayPassModalOpen(false);
                    }}
                    locationId={id}
                />
            )}
        </div>
    );
});

export default ReservedDayPasses;
