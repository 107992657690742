/**
 * @IMPORTANT
 * DO NOT EDIT THIS FILE. It is generated at build-time, and it is git-ignored. If you want to modify
 * the contents of this file, edit "scripts/generate-variant.js" instead.
 */

// Import fonts.
import './Style/cg/fonts';

// Import variant's strings.
import enVariant from './i18n/en_cg.json';

// Import variant-specific images.
import logoColor from './Assets/Images/cg/logo-color.svg';
import logoWhite from './Assets/Images/cg/logo-white.svg';
import logoSmallWhite from './Assets/Images/cg/logo-small-white.svg';
import homeHeader from './Assets/Images/cg/home-header.jpg';
import usersHeader from './Assets/Images/cg/users-header.jpg';
import anonymousBackground from './Assets/Images/cg/login-page.jpg';
import logoColorIcon from './Assets/Images/cg/logo-color-icon.svg';
import activityBoardHeader from './Assets/Images/cg/activity-board-header.png';
import locationHeader from './Assets/Images/cg/location-header.jpg';
import locationLogo from './Assets/Images/cg/location-logo.jpg';
import cgLogo404 from './Assets/Images/cg/cg-logo-404.svg';
// Expose variant specifics.
export const i18nVariant = { en: enVariant };
export const images = {
    logoColor,
    logoWhite,
    logoSmallWhite,
    homeHeader,
    usersHeader,
    anonymousBackground,
    logoColorIcon,
    activityBoardHeader,
    locationHeader,
    locationLogo,
    cgLogo404,
};
export const theme = {
    'font-family': "'Roboto', serif",
    'heading-font-family': 'Spectral, serif',
    'font-size-base': '14px',
    'slate-blue': '#244c5a',
    'slate-blue-80': '#50707b',
    'slate-blue-60': '#7c949c',
    'slate-blue-40': '#a7b7bd',
    'slate-blue-20': '#d3dbde',
    'slate-blue-5': '#f4f6f7',
    'soft-blue': '#b8c9e1',
    'soft-blue-80': '#c6d4e7',
    'soft-blue-60': '#d4dfed',
    'soft-blue-40': '#e3e9f3',
    'soft-blue-20': '#f1f4f9',
    'soft-blue-5': '#fbfcfd',
    'design-black': '#101820',
    'design-black-80': '#40464d',
    'design-black-60': '#707479',
    'design-black-40': '#9fa3a6',
    'design-black-20': '#cfd1d2',
    'design-black-5': '#f3f3f4',
    'burning-red': '#642734',
    'burning-red-40': '#f3b2ba',
    'burning-red-60': '#ec8b97',
    'burning-red-80': '#e03e52',
    orange: '#ff6900',
    'cactus-green': '#273f35',
    'cactus-green-80': '#497b55',
    plum: '#7d2248',
    'heatmap-yellow': '#F8E711CC',
    'heatmap-orange': '#EA8308CC',
    'primary-color': '#244c5a',
    'primary-1': '#50707b',
    'primary-2': '#7c949c',
    'primary-3': '#a7b7bd',
    'primary-4': '#d3dbde',
    'primary-5': '#f4f6f7',
    'secondary-color': '#b8c9e1',
    'secondary-1': '#c6d4e7',
    'secondary-2': '#d4dfed',
    'secondary-3': '#e3e9f3',
    'secondary-4': '#f1f4f9',
    'secondary-5': '#fbfcfd',
    black: '#101820',
    'black-1': '#40464d',
    'black-2': '#707479',
    'black-3': '#9fa3a6',
    'black-4': '#cfd1d2',
    'black-5': '#f3f3f4',
    'pure-black': '#000',
    white: '#fff',
    'error-red': '#642734',
    'error-red-1': '#e03e52',
    'error-red-2': '#ec8b97',
    'error-red-3': '#f3b2ba',
    warning: '#ff6900',
    'success-green': '#273f35',
    'success-green-1': '#497b55',
    'login-gray': '#19212a',
    'bg-light': '#e1e1e1',
    'dark-grey': '#43434c',
    confetti: '#7d2248',
    'floor-plan-grey': '#808080',
    'height-base': '50px',
    'btn-padding-horizontal-base': '30px',
    'height-lg': '60px',
    'padding-lg': '24px',
    'padding-md': '16px',
    'padding-sm': '12px',
    'padding-xs': '8px',
    'padding-xss': '4px',
    'text-color': '#101820',
    'layout-sider-background': '#101820',
    'layout-trigger-background': 'rgba(@pure-black, 0.6)',
    'menu-dark-bg': '#101820',
    'menu-dark-color': '#fff',
    'menu-dark-item-active-bg': '#50707b',
    'menu-inline-toplevel-item-height': '56px',
    'menu-item-font-size': '14px',
    'heading-1-size': 'ceil(@font-size-base * 3.42)',
    'heading-2-size': 'ceil(@font-size-base * 2.28)',
    'btn-font-weight': 'bold',
    'btn-border-radius-base': '0',
    'btn-square-only-icon-size-lg': '24px',
    'btn-ghost-color': '#fff',
    'btn-ghost-bg': 'transparent',
    'btn-ghost-border': 'transparent',
    'btn-default-color': '#244c5a',
    'btn-default-bg': '#fff',
    'btn-default-border': '#fff',
    'breadcrumb-separator-color': '#b8c9e1',
    'breadcrumb-last-item-color': '#9fa3a6',
    'breadcrumb-link-color': '#40464d',
    'breadcrumb-separator-margin': '12px',
    'breadcrumb-link-color-hover': '#50707b',
    'dropdown-vertical-padding': '10px',
    'dropdown-edge-child-vertical-padding': '10px',
    'modal-body-padding': '20px 40px',
    'modal-header-padding-vertical': '30px',
    'modal-header-padding-horizontal': '40px',
    'border-color-base': '#d3dbde',
    'border-color-split': '#d3dbde',
    'table-header-bg': '#fff',
    'table-header-color': '#101820',
    'table-bg': '#f4f6f7',
    'table-padding-vertical': '10px',
    'table-padding-horizontal': '20px',
    'pagination-font-family': "'Roboto', serif",
    'pagination-item-bg-active': '#f1f4f9',
    'pagination-font-weight-active': 'bold',
    'pagination-item-size': '40px',
    'form-item-margin-bottom': '40px',
};
