import { Button, Checkbox, Col, DatePicker, Form, Input, Radio, TimePicker, Typography } from 'antd';
import Row, { Gutter } from 'antd/lib/grid/row';
import { GetMembersRequestDto } from 'Api/Features/Members/Dtos/GetMembersRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Company, User } from 'Components/icons';
import Icon from 'Components/icons/icon';
import SelectCustom, { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { Member } from 'Models/Members/Member';
import { Membership } from 'Models/Memberships/Membership';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router';
import { MemberService } from 'Services/MemberService';
import { MembershipService } from 'Services/MembershipService';
import { theme } from 'variant';
import './create-event-modal.less';
import 'react-quill/dist/quill.snow.css';
import ImagePicker, { ImageDetails } from 'Components/image-picker/image-picker';
import { DISPLAY_MONTH_YEAR_FORMAT, TIME_PICKER_FORMAT } from 'Models/Constants';
import { CreateCommunityEvent } from 'Schemas/CreateCommunityEventSchema';
import { CommunityEventService } from 'Services/CommunityEventService';
import { CreateCommunityEventRequestDto } from 'Api/Features/CommunityEvents/Dtos/CreateCommunityEventRequestDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import moment from 'moment';
import { CommunityEventDto } from 'Api/Features/CommunityEvents/Dtos/CommunityEventDto';
import { UploadFile } from 'antd/lib/upload/interface';

const formGutter: [Gutter, Gutter] = [40, 0];
const { Title } = Typography;

interface CreateCommunityEventModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    event?: CommunityEventDto;
}

export enum EventSpeakerType {
    noSpeaker = "noSpeaker",
    InternalSpeaker = "internalSpeaker",
    ExternalSpeaker = "externalSpeaker"
}

const CreateCommunityEventModal: FunctionComponent<CreateCommunityEventModalProps> = ({
    visible,
    onComplete,
    event
}) => {
    const memberService = useService(MemberService);
    const membershipService = useService(MembershipService);
    const communityEventService = useService(CommunityEventService);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(CreateCommunityEvent, form);

    const [allDayCheckboxState, setAllDayCheckboxState] = useState(event ? event.isAllDay : false);
    const [allMonthCheckboxState, setAllMonthCheckboxState] = useState(event ? event.isAllMonth : false);
    const [cgEventCheckboxState, setCgEventCheckboxState] = useState(event? event.isFromSystem : false);

    const [eventImageDetails, setEventImageDetails] = useState<ImageDetails[]>();
    const [speakerImageDetails, setSpeakerImageDetails] = useState<ImageDetails[]>();
    const [hostImageDetails, setHostImageDetails] = useState<ImageDetails[]>();

    const [selectLoading, setSelectLoading] = useState(false);
    //Company dropdown
    const [selectedMembershipId, setSelectedMembershipId] = useState<string | undefined>(undefined);
    const [companiesSearchTerm, setCompaniesSearchTerm] = useState('');
    const [companiesSearchResults, setCompaniesSearchResults] = useState<Membership[]>([]);
    const [companiesOptions, setCompaniesOptions] = useState<SelectCustomOption[]>([]);
    const [selectedCompanyOptions, setSelectedCompanyOptions] = useState<SelectCustomOption[]>([]);
    const [companiesMaxResults, setCompaniesMaxResults] = useState(false);
    const [companiesCurrentPage, setCompaniesCurrentPage] = useState(0);

    //Member dropdown
    const [selectedMemberId, setSelectedMemberId] = useState<string | undefined>(undefined);
    const [membersSearchTerm, setMembersSearchTerm] = useState('');
    const [membersSearchResults, setMembersSearchResults] = useState<Member[]>([]);
    const [membersOptions, setMembersOptions] = useState<SelectCustomOption[]>([]);
    const [selectedMemberOptions, setSelectedMemberOptions] = useState<SelectCustomOption[]>([]);
    const [membersMaxResults, setMembersMaxResults] = useState(false);
    const [membersCurrentPage, setMembersCurrentPage] = useState(0);

    const { t } = useTranslation();
    const { id: locationId } = useParams<{ id: string }>();

    const pageSize = 25;

    const getSpeakerType =
        useCallback((): EventSpeakerType => {
            //!edit => noSpeaker
            //edit && event.externalspeaker && no info in any field => noSpeaker
            //edit && event.externalspeaker && info in a field => externalspeaker
            //edit && !event.externalspeaker && event.speakerMember => internalSpeaker
            //edit && !event.externalspeaker && !event.speakermember = > noSpeaker
            if (event) {
                if (event.isExternalSpeaker) {
                    if (
                        !event.hostName &&
                        !event.hostImageUrl &&
                        !event.hostUrl &&
                        !event.speakerImageUrl &&
                        !event.speakerName &&
                        !event.speakerUrl
                    )
                        return EventSpeakerType.noSpeaker;
                    else return EventSpeakerType.ExternalSpeaker;
                } else {
                    return EventSpeakerType.InternalSpeaker;
                }
            } else {
                return EventSpeakerType.noSpeaker;
            }
        }, [event]);

    const [speakerType, setSpeakerType] = useState(getSpeakerType());

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="CommunityEvent" />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`confirm_positive_text_model`, { param1: 'event' }),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const validationObject = {
            ...formValues,
            isAllDay: allDayCheckboxState,
            isAllMonth: allMonthCheckboxState,
            isFromSystem: cgEventCheckboxState,
            speakerType: speakerType,
            speakerName: speakerType === EventSpeakerType.noSpeaker ? undefined : formValues.speakerName,
            speakerUrl: speakerType === EventSpeakerType.noSpeaker ? undefined : formValues.speakerUrl,
            hostName: speakerType === EventSpeakerType.noSpeaker ? undefined : formValues.hostName,
            hostUrl: speakerType === EventSpeakerType.noSpeaker ? undefined : formValues.hostUrl
        };
        
        if (!(await validateForm(validationObject))) return;

        try {
            globalLoadingStore.addLoading();

            const image = eventImageDetails
                ? ({
                      delete: eventImageDetails[0].isDeleted,
                      uploadBase64: eventImageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null;
            const speakerImage =
                speakerType !== EventSpeakerType.ExternalSpeaker
                    ? { delete: true }
                    : speakerImageDetails
                    ? ({
                          delete: speakerImageDetails[0].isDeleted,
                          uploadBase64: speakerImageDetails[0].base64,
                      } as UpdateFileRequestDto)
                    : null;
            const hostImage =
                speakerType !== EventSpeakerType.ExternalSpeaker
                    ? { delete: true }
                    : hostImageDetails
                    ? ({
                          delete: hostImageDetails[0].isDeleted,
                          uploadBase64: hostImageDetails[0].base64,
                      } as UpdateFileRequestDto)
                    : null;

            const request: CreateCommunityEventRequestDto = {
                ...validationObject,
                campusId: locationId,
                image,
                speakerImage,
                hostImage,
                startDate: validationObject.isAllMonth
                    ? moment(validationObject.month).startOf('month')
                    : validationObject.dates[0],
                endDate: validationObject.isAllMonth
                    ? moment(validationObject.month).endOf('month')
                    : validationObject.dates[1],
                startTime: validationObject.isAllDay
                    ? null
                    : validationObject.time[0].format(TIME_PICKER_FORMAT),
                endTime: validationObject.isAllDay
                    ? null
                    : validationObject.time[1].format(TIME_PICKER_FORMAT),
                speakerAccountId:
                    speakerType === EventSpeakerType.InternalSpeaker &&
                    validationObject.speakerAccountId
                        ? validationObject.speakerAccountId.value
                        : null,
                speakerMembershipId:
                    speakerType === EventSpeakerType.InternalSpeaker &&
                    validationObject.speakerMembershipId
                        ? validationObject.speakerMembershipId.value
                        : null,
                // true is equal to no speaker or external speaker for backend.
                isExternalSpeaker: speakerType !== EventSpeakerType.InternalSpeaker,
            };

            if(event)
                await communityEventService.updateCommunityEvent(event.id!, request)
            else
                await communityEventService.createCommunityEvent(request);

            toastStore.toast({
                type: 'success',
                messageKey: t(`model_${event ? 'edit' : 'create'}_success`, {param1: 'Event'}),
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    //edit set info in form
    useEffect(() => {
        if(event) {
            form.setFieldsValue({
                title: event.title,
                description: event.description,
                month: event.isAllMonth ? moment(event.periodStart) : undefined,
                dates: !event.isAllMonth
                    ? [
                          moment(event.startDate).utc(),
                          moment(event.endDate).utc(),
                      ]
                    : undefined,
                time: !event.isAllDay
                    ? [
                          moment(event.periodStart).tz(event.timeZone ?? ''),
                          moment(event.periodEnd).tz(event.timeZone ?? ''),
                      ]
                    : undefined,
                hostName: event.hostName,
                hostUrl: event.hostUrl,
                speakerName: event.speakerName,
                speakerUrl: event.speakerUrl,
                speakerMembershipId: event.speakerMembership
                    ? { value: event.speakerMembership.id }
                    : undefined,
                speakerAccountId: event.speakerMember
                    ? { value: event.speakerMember.id }
                    : undefined,
            });
            setSelectedMembershipId(event.speakerMembership?.id ? event.speakerMembership.id : undefined);
            setSelectedCompanyOptions(
                event.speakerMembership
                    ? [
                          {
                              label: event.speakerMembership.name,
                              value: event.speakerMembership.id,
                              imageUrl: event.speakerMembership.imageUrl,
                          } as SelectCustomOption,
                      ]
                    : []
            );
            setSelectedMemberId(event.speakerMember?.id ? event.speakerMember.id : undefined);
            setSelectedMemberOptions(
                event.speakerMember
                    ? [
                          {
                              label: `${event.speakerMember.firstName} ${event.speakerMember.lastName}`,
                              value: event.speakerMember.id,
                              imageUrl: event.speakerMember.imageUrl,
                          } as SelectCustomOption,
                      ]
                    : []
            );
        }
    }, [event])

    // Region Companies Dropdown
    const resetCompaniesSearch = (): void => {
        setCompaniesCurrentPage(0);
        setCompaniesSearchResults([]);
        setCompaniesSearchTerm('');
        setCompaniesMaxResults(false);
    };

    const handleCompaniesKeywordsChange = useCallback((value: string): void => {
        resetCompaniesSearch();
        setCompaniesSearchTerm(value);
    }, []);

    const handleCompaniesMenuScrollToBottom = (): void => {
        if (!companiesMaxResults) {
            setCompaniesCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const searchCompanies = async (
        page: number,
        searchTerm: string | null
    ): Promise<Membership[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
            locationId: locationId,
        };

        const [results, totalItemCount] = await membershipService.getMemberships(args);

        if (results.length + pageSize * companiesCurrentPage >= totalItemCount) {
            setCompaniesMaxResults(true);
        }
        setSelectLoading(false);
        return results;
    };

    const debounceCompaniesSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchCompanies(page, searchTerm).then((results) => {
                setCompaniesSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    useEffect(() => {
        setSelectLoading(true);
        debounceCompaniesSearch.current(companiesCurrentPage, companiesSearchTerm);
    }, [companiesSearchTerm, companiesCurrentPage]);

    useEffect(() => {
        const searchResults = companiesSearchResults?.map(
            (x: Membership) =>
                ({
                    value: x?.id,
                    label: x?.name,
                    imageUrl: x?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedCompanyOptions);
        setCompaniesOptions(merged);
    }, [companiesSearchResults, selectedCompanyOptions]);
    //End region Company dropdown

    //Region Member Dropdown
    const resetMembersSearch = (): void => {
        setMembersCurrentPage(0);
        setMembersSearchResults([]);
        setMembersSearchTerm('');
        setMembersMaxResults(false);
    };

    const handleMembersKeywordsChange = useCallback((value: string): void => {
        resetMembersSearch();
        setMembersSearchTerm(value);
    }, []);

    const handleMembersMenuScrollToBottom = (): void => {
        if (!membersMaxResults) {
            setMembersCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const searchMembers = async (
        page: number,
        searchTerm: string | null,
        selectedMembershipId?: string
    ): Promise<Member[]> => {
        if (!selectedMembershipId) {
            setSelectLoading(false);
            return [];
        }

        const args: GetMembersRequestDto = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
            membershipIds: [selectedMembershipId],
        };

        const [results, totalItemCount] = await memberService.getMembers(args);

        if (results.length + pageSize * membersCurrentPage >= totalItemCount) {
            setMembersMaxResults(true);
        }
        setSelectLoading(false);
        return results;
    };

    const debounceMembersSearch = useRef(
        debounce((page: number, searchTerm: string, selectedMembershipId?: string) => {
            searchMembers(page, searchTerm, selectedMembershipId).then((results) => {
                setMembersSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    useEffect(() => {
        setSelectLoading(true);
        debounceMembersSearch.current(membersCurrentPage, membersSearchTerm, selectedMembershipId);
    }, [membersSearchTerm, membersCurrentPage, selectedMembershipId]);

    useEffect(() => {
        const searchResults = membersSearchResults?.map(
            (x: Member) =>
                ({
                    value: x?.id,
                    label: x?.name,
                    imageUrl: x?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedMemberOptions);
        setMembersOptions(merged);
    }, [membersSearchResults, selectedMemberOptions]);
    //End region member dropdown

    return (
        <BaseModal
            className="FormModal CreateCommunityEventModal"
            visible={visible}
            title={t(`CommunityEvent.${event ? 'edit' : 'create'}_community_event`)}
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Row gutter={formGutter}>
                    <Col className="cg-event-checkbox">
                        <Checkbox
                            checked={cgEventCheckboxState}
                            onChange={(e) => setCgEventCheckboxState(e.target.checked)}
                        >
                            {t('CommunityEvent.community_event_cg_event')}
                        </Checkbox>
                    </Col>
                </Row>
                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem name="title" errors={errors} label={t('Title')} required>
                            <Input />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Row gutter={formGutter} className="time-checkboxes">
                    <Col className="month">
                        <Checkbox
                            checked={allMonthCheckboxState}
                            onChange={(e) => setAllMonthCheckboxState(e.target.checked)}
                        >
                            {t('all_month')}
                        </Checkbox>
                    </Col>
                    <Col className="day">
                        <Checkbox
                            checked={allDayCheckboxState}
                            onChange={(e) => setAllDayCheckboxState(e.target.checked)}
                        >
                            {t('all_day')}
                        </Checkbox>
                    </Col>
                </Row>

                <Row gutter={formGutter}>
                    <Col span={12}>
                        {allMonthCheckboxState ? (
                            <ValidatedFormItem name="month" errors={errors} label={t('Month')}>
                                <DatePicker
                                    picker={'month'}
                                    style={{ width: '100%' }}
                                    format={DISPLAY_MONTH_YEAR_FORMAT}
                                />
                            </ValidatedFormItem>
                        ) : (
                            <ValidatedFormItem name="dates" errors={errors} label={t('Dates')}>
                                <DatePicker.RangePicker style={{ width: '100%' }} />
                            </ValidatedFormItem>
                        )}
                    </Col>
                    {!allDayCheckboxState && (
                        <Col span={12}>
                            <ValidatedFormItem name="time" errors={errors} label={t('event_time')}>
                                <TimePicker.RangePicker
                                    format={TIME_PICKER_FORMAT}
                                    use12Hours
                                    style={{ width: '100%' }}
                                />
                            </ValidatedFormItem>
                        </Col>
                    )}
                </Row>

                <Title level={4}>{t('content')}</Title>

                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem
                            name="description"
                            errors={errors}
                            label={t('description')}
                            className="description"
                            required
                        >
                            <Input hidden />
                        </ValidatedFormItem>
                        <ReactQuill
                            theme="snow"
                            className="description-quill"
                            onChange={(value: string) =>
                                form.setFieldsValue({ description: value })
                            }
                            value={form.getFieldValue('description') || null}
                        />
                    </Col>
                </Row>

                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem
                            name="eventImage"
                            errors={errors}
                            label={t('main_image')}
                        >
                            <ImagePicker
                                images={
                                    event?.imageUrl
                                        ? [
                                              {
                                                  url: event?.imageUrl,
                                                  uid: 'main',
                                              } as UploadFile,
                                          ]
                                        : undefined
                                }
                                setImagesDetails={(image) => setEventImageDetails(image)}
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Title level={4}>{t('speaker')}</Title>

                <Row gutter={formGutter}>
                    <Col className="speaker-type">
                        <Radio.Group
                            value={speakerType}
                            onChange={(value) => setSpeakerType(value.target.value)}
                        >
                            <Radio value={EventSpeakerType.noSpeaker}>
                                {t('CommunityEvent.no_speaker')}
                            </Radio>
                            <Radio value={EventSpeakerType.InternalSpeaker}>
                                {t('CommunityEvent.speaker_is_cg_member')}
                            </Radio>
                            <Radio value={EventSpeakerType.ExternalSpeaker}>
                                {t('CommunityEvent.GetCommunityEventsFlagDto_IsExternalSpeaker')}
                            </Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                <div className={speakerType !== EventSpeakerType.ExternalSpeaker ? 'd-none' : ''}>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="speakerName"
                                errors={errors}
                                label={t('CommunityEvent.speaker_name')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="speakerUrl"
                                errors={errors}
                                label={t('CommunityEvent.speaker_url')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name="speakerImage"
                                errors={errors}
                                label={t('CommunityEvent.speaker_image')}
                            >
                                <ImagePicker
                                    images={
                                        event?.speakerImageUrl
                                            ? [
                                                  {
                                                      url: event?.speakerImageUrl,
                                                      uid: 'main',
                                                  } as UploadFile,
                                              ]
                                            : undefined
                                    }
                                    setImagesDetails={(image) => setSpeakerImageDetails(image)}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="hostName"
                                errors={errors}
                                label={t('CommunityEvent.host_name')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="hostUrl"
                                errors={errors}
                                label={t('CommunityEvent.host_url')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name="hostImage"
                                errors={errors}
                                label={t('CommunityEvent.host_image')}
                            >
                                <ImagePicker
                                    images={
                                        event?.hostImageUrl
                                            ? [
                                                  {
                                                      url: event?.hostImageUrl,
                                                      uid: 'main',
                                                  } as UploadFile,
                                              ]
                                            : undefined
                                    }
                                    setImagesDetails={(image) => setHostImageDetails(image)}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                </div>

                <div className={speakerType !== EventSpeakerType.InternalSpeaker ? 'd-none' : ''}>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="speakerMembershipId"
                                errors={errors}
                                label={t('CommunityEvent.speaker_company')}
                                required
                            >
                                <SelectCustom
                                    options={companiesOptions}
                                    defaultImg={<Company fill={theme['white']} />}
                                    strongLabel={true}
                                    placeholder={t('SelectCustom.placeholder_default')}
                                    onKeywordsChange={handleCompaniesKeywordsChange}
                                    onMenuScrollToBottom={handleCompaniesMenuScrollToBottom}
                                    hideSelectedOptions={false}
                                    onChange={(value): void => {
                                        setSelectedMembershipId(value.value);
                                        const options = [value as SelectCustomOption];
                                        setSelectedCompanyOptions(options);
                                        //reset member dropdown
                                        setMembersSearchResults([]);
                                        setSelectedMemberOptions([]);
                                        setSelectedMemberId(undefined);
                                    }}
                                    selected={selectedMembershipId ? [selectedMembershipId] : null}
                                    isLoading={selectLoading}
                                    idAttribute="speakerMembershipId"
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="speakerAccountId"
                                errors={errors}
                                label={t('Speaker')}
                                required
                            >
                                <SelectCustom
                                    options={membersOptions}
                                    defaultImg={<Company fill={theme['white']} />}
                                    strongLabel={true}
                                    placeholder={t('SelectCustom.placeholder_default')}
                                    onKeywordsChange={handleMembersKeywordsChange}
                                    onMenuScrollToBottom={handleMembersMenuScrollToBottom}
                                    hideSelectedOptions={false}
                                    onChange={(value): void => {
                                        setSelectedMemberId(value.value);
                                        const options = [value as SelectCustomOption];
                                        setSelectedMemberOptions(options);
                                    }}
                                    selected={selectedMemberId ? [selectedMemberId] : null}
                                    isLoading={selectLoading}
                                    idAttribute="speakerAccountId"
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                </div>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>
                    <Button type="primary" className="positive" htmlType="submit">
                        {t('submit')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(CreateCommunityEventModal);
