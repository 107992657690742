import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { CalendarEventProxy } from 'Api/Features/CalendarEvents/CalendarEventProxy';
import { GetCalendarEventsRequestDto } from 'Api/Features/CalendarEvents/Dtos/GetCalendarEventsRequestDto';
import { GetCalendarEventsResponseDto } from 'Api/Features/CalendarEvents/Dtos/GetCalendarEventsResponseDto';

@inject(CalendarEventProxy)
export class ActivityBoardCalendarService extends ApiService {
    constructor(private readonly calendarEventProxy: CalendarEventProxy) {
        super();
    }

    public async getManagerUserCalendarEvents(managerUserId: string | null, request: GetCalendarEventsRequestDto | null): Promise<GetCalendarEventsResponseDto | null> {
        const data: GetCalendarEventsResponseDto | null = await this.calendarEventProxy.getManagerUserCalendarEvents(managerUserId, request);
        return data;
    }
}
