import React from 'react';
import Timespan from './timespan';
import Resources from './resources';
import { AngleLeft, AngleRight, ZoomIn, ZoomOut } from 'Components/icons';
import './flexible-booking.less';

interface FlexibleBookingProps {
    view: FlexibleBookingViewType;
    isEditing: boolean;
    resources: any;
    filters: [];
    resourceHeader?: any;
    resourcePeriod?: any;
    min: number;
    max: number;
    zoom: number;
    resizable: boolean;
    currentBooking?: BookingShape;
    currentConflict?: ConflictShape;
    classNames?: string;
    onTimeCellClick?: any;
    timezone?: any;
    selectedDate: any;
    onBookingResize?: ({ formatedBookingStart, formatedBookingEnd }: any) => void;
    currentBookingIsErrored?: boolean;
    onCurrentBookingClick?: any;
    recurrenceBlocksResize?: boolean;
    onRecurrenceBlockedResize?: any;
    onResizeStopped?: () => void;
}

export enum FlexibleBookingViewType {
    bookings = 'bookings',
    details = 'details',
    conflictAmenities = 'conflictAmenities',
    conflicts = 'conflicts'
}

interface BookingShape {
    bookingStart: string;
    bookingEnd: string;
    conferenceRoomId: string;
}

interface ConflictShape extends BookingShape {
    reason: string;
}

interface FlexibleBookingState {
    zoom: number;
}

class FlexibleBooking extends React.Component<FlexibleBookingProps, FlexibleBookingState> {
    private flexibleBookingCalendarRef: React.RefObject<any>;

    constructor(
        props: FlexibleBookingProps = {
            view: FlexibleBookingViewType.bookings,
            isEditing: false,
            resources: [],
            filters: [],
            resourceHeader: () => {/**/},
            resourcePeriod: () => {/**/},
            min: 0,
            max: 24,
            zoom: 0,
            resizable: false,
            classNames: '',
            onTimeCellClick: (e: any, period: any, resource: any) => {/**/},
            timezone: '',
            selectedDate: null,
            onBookingResize: () => {/**/},
        }
    ) {
        super(props);

        this.state = {
            zoom: props.zoom,
        };
        this.flexibleBookingCalendarRef = React.createRef();
    }

    calculateScrollPosition = (): number => {
        const { scrollTop, scrollHeight } = this.flexibleBookingCalendarRef.current;
        return (scrollTop * 100) / scrollHeight;
    };

    calculateNewScrollPosition = (scrollPositionPercentage: number): number => {
        const { scrollHeight } = this.flexibleBookingCalendarRef.current;
        return (scrollPositionPercentage * scrollHeight) / 100;
    };

    handleZoomOut = (): void => {
        const scrollPositionPercentage = this.calculateScrollPosition();
        if (this.state.zoom > 0) {
            this.setState(
                (state) => {
                    return {
                        zoom: state.zoom - 1,
                    };
                },
                () =>
                    setTimeout(
                        this.flexibleBookingCalendarRef.current.scrollTo(
                            0,
                            this.calculateNewScrollPosition(scrollPositionPercentage)
                        ),
                        300
                    )
            );
        }
    };

    handleZoomIn = (): void => {
        const scrollPositionPercentage = this.calculateScrollPosition();
        if (this.state.zoom < 2) {
            this.setState(
                (state) => {
                    return {
                        zoom: state.zoom + 1,
                    };
                },
                () =>
                    setTimeout(
                        this.flexibleBookingCalendarRef.current.scrollTo(
                            0,
                            this.calculateNewScrollPosition(scrollPositionPercentage)
                        ),
                        300
                    )
            );
        }
    };

    handleScrollClick = ({ currentTarget }: any): void => {
        const element = document.getElementsByClassName('resource-head')[0] as HTMLElement;
        const resourceWidth = element.offsetWidth;
        if (currentTarget.classList.value.includes('arrow-left')) {
            this.flexibleBookingCalendarRef.current.scrollLeft -= resourceWidth;
        } else {
            this.flexibleBookingCalendarRef.current.scrollLeft += resourceWidth;
        }
    };

    render() {
        return (
            <>
                <div className={`FlexibleBooking ${this.props.classNames}`}>
                    {(this.props.view === 'bookings' || this.props.view === 'conflictAmenities') && this.props.resources.length > 1 && (
                        <div className="resources-arrows-row">
                            <div
                                onClick={this.handleScrollClick}
                                className="resources-arrows arrow-left"
                            >
                                <AngleLeft fill="#fff" />
                            </div>
                            <div
                                onClick={this.handleScrollClick}
                                className="resources-arrows arrow-right"
                            >
                                <AngleRight fill="#fff" />
                            </div>
                        </div>
                    )}
                    <div
                        className="FlexibleBooking__Calendar"
                        ref={this.flexibleBookingCalendarRef}
                    >
                        <div className="left-col">
                            {(this.props.view === 'bookings' || this.props.view === 'conflictAmenities') && (
                                <div className="zoom">
                                    <button
                                        onClick={this.handleZoomOut}
                                        disabled={this.state.zoom === 0}
                                    >
                                        <ZoomOut />
                                    </button>
                                    <button
                                        onClick={this.handleZoomIn}
                                        disabled={this.state.zoom === 2}
                                    >
                                        <ZoomIn />
                                    </button>
                                </div>
                            )}
                            <Timespan
                                zoom={this.state.zoom}
                                min={this.props.min}
                                max={this.props.max}
                            />
                        </div>

                        <Resources
                            view={this.props.view}
                            resources={this.props.resources}
                            flexibleBookingCalendarRef={this.flexibleBookingCalendarRef}
                            timezone={this.props.timezone}
                            resourceHeader={this.props.resourceHeader}
                            resourcePeriod={this.props.resourcePeriod}
                            onTimeCellClick={this.props.onTimeCellClick}
                            zoom={this.state.zoom}
                            min={this.props.min}
                            max={this.props.max}
                            resizable={this.props.resizable}
                            isEditing={this.props.isEditing}
                            selectedDate={this.props.selectedDate}
                            currentBooking={this.props.currentBooking}
                            onBookingResize={this.props.onBookingResize}
                            currentBookingIsErrored={this.props.currentBookingIsErrored}
                            onCurrentBookingClick={this.props.onCurrentBookingClick}
                            recurrenceBlocksResize={this.props.recurrenceBlocksResize}
                            onRecurrenceBlockedResize={this.props.onRecurrenceBlockedResize}
                            onResizeStopped={this.props.onResizeStopped}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default FlexibleBooking;
