import { Button } from 'antd';
import BaseModal from 'Components/base-modal/base-modal';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './import-members-tip-modal.less';

interface ImportMemberTipModalProps {
    visible: boolean;
    onComplete: () => void;
}

const ImportMemberTipModal: FunctionComponent<ImportMemberTipModalProps> = ({
    visible,
    onComplete,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    //#endregion

    //#region Submit / Exit
    const dismiss = (): void => {
        onComplete();
    };

    const exit = (): void => {
        dismiss();
    };

    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Membership.file_format')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="ImportMemberTipModal">
                <ul className="dashed">
                    <li>{t('Membership.file_must_be_valid')}</li>
                    <li>
                        {t('Membership.file_must_contain')}
                        <ul className="dashed">
                            <li>{t('Email')}</li>
                            <li>{t('FirstName')}</li>
                            <li>{t('LastName')}</li>
                            <li>{t('Roles')}</li>
                        </ul>
                    </li>
                    <li>{t('Membership.file_email_must_contain')}</li>
                    <li>{t('Membership.file_roles_must_be')}</li>
                </ul>

                <div className="actions">
                    <Button type="primary" className="positive" onClick={(): void => exit()}>
                        {t('ok')}
                    </Button>
                </div>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(ImportMemberTipModal);
