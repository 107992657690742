import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SummaryFormSection from 'Routes/authenticated/proposals/summary-form-section';
import { useService, useStores } from 'Hooks';
import { SubscriptionProposalService } from 'Services/SubscriptionProposalService';
import { useParams } from 'react-router-dom';
import { SubscriptionProposalDetails } from 'Models/Proposals/SubscriptionProposalDetails';
import { Col, Layout, Row } from 'antd';
import { OpportunityRouteProps } from '../..';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { showFile } from 'Utils';
import { PencilBorder } from 'Components/icons';
import { theme } from 'variant';
import EditProposalTitleModal from './edit-title-modal';
import { SubscriptionProposalStatusDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionProposalStatusDto';

const { Content } = Layout;

interface ProposalDetailsProps {
    routeProps?: OpportunityRouteProps;
}

const ProposalDetails: FunctionComponent<ProposalDetailsProps> = ({ routeProps }) => {
    //#region Hooks
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore } = useStores();

    const { setCtaOptions, data, setBreadcrumbs, setEditableTitle } = routeProps!;
    const subscriptionProposalService = useService(SubscriptionProposalService);
    const [proposal, setProposal] = useState<SubscriptionProposalDetails>();
    const [editTitleModalOpen, setEditTitleModalOpen] = useState(false);

    const { id, proposalId } = useParams<{ id: string; proposalId: string }>();
    //#endregion Hooks

    useEffect(() => {
        setBreadcrumbs([
            {
                nameKey: 'Opportunities',
                path: 'opportunities',
            },
            {
                nameKey: data?.name ?? data?.membership?.name ?? '',
                path: `${id}/dashboard`,
            },
            {
                nameKey: `Proposals`,
                path: `proposals`,
            },
            {
                nameKey: proposal?.title ?? '',
                path: `${proposalId}`,
            },
        ]);
    }, [data, id, setBreadcrumbs, proposal]); 

    useEffect(() => {
        if (
            proposal &&
            proposal.status === SubscriptionProposalStatusDto.Pending
        ) {
            setEditableTitle(
                <div className="editable-title-container">
                    <h1 className="ant-typography header-title">{proposal?.title}</h1>
                    <div onClick={(): void => setEditTitleModalOpen(true)}>
                        <PencilBorder width={20} height={20} fill={theme['primary-color']} />
                    </div>
                </div>
            );
        }
        else {
            setEditableTitle(null);
        }
    }, [proposal]);

    const onExportInPdfClick = useCallback(async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            const document = await subscriptionProposalService.exportSubscriptionProposal(
                proposalId
            );

            showFile(document, `${data?.membership?.name} - proposal`);
        } catch (e) {
            if (!e.treated) {
                toastStore.toast({
                    type: 'error',
                    messageKey: 'Proposal.proposal_pdf_error',
                });
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, subscriptionProposalService, data, proposalId, toastStore]);

    const onSendAgainButtonClick = useCallback(async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            if (proposalId)
                await subscriptionProposalService.resendSubscriptionProposal(proposalId);

            toastStore.toast({
                type: 'success',
                messageKey: 'Proposal.proposal_has_been_sent_by_email',
            });
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, subscriptionProposalService, proposalId, toastStore]);

    const fetch = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const data = await subscriptionProposalService.getSubscriptionProposalById(proposalId);
            if (data) {
                setProposal(data);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, proposalId, subscriptionProposalService]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    useEffect(() => {
        const options: ActionMenuOption[] = [
            {
                key: 'sendAgain',
                title: t('Proposal.proposal_cta_label_send_again'),
                action: (): Promise<void> => onSendAgainButtonClick(),
            },
            {
                key: 'export',
                title: t('Proposal.proposal_cta_label_export'),
                action: (): Promise<void> => onExportInPdfClick(),
            },
        ];
        setCtaOptions(options);
    }, [proposal, setCtaOptions, t, onExportInPdfClick, onSendAgainButtonClick]);

    //#region Render
    return (
        <Content className="ProposalDetails">
            <Row gutter={40}>
                <Col span={24}>
                    <SummaryFormSection
                        proposal={proposal}
                        membership={data?.membership ?? undefined}
                        isDetailsView={true}
                    />
                </Col>
            </Row>

            {editTitleModalOpen && (
                <EditProposalTitleModal
                    visible={editTitleModalOpen}
                    onComplete={(success: boolean) => {
                        setEditTitleModalOpen(false);
                        if (success) fetch();
                    }}
                    proposal={proposal}
                />
            )}
        </Content>
    );

    //#endregion
};

export default ProposalDetails;
