import { GetRevenueAndOccupancyReportRequestDto } from 'Api/Features/Reports/Dtos/RevenueAndOccupancy/GetRevenueAndOccupancyReportRequestDto';
import { RevenueAndOccupancyReportDto } from 'Api/Features/Reports/Dtos/RevenueAndOccupancy/RevenueAndOccupancyReportDto';
import { RevenueAndOccupancyReportProxy } from 'Api/Features/Reports/RevenueAndOccupancyReportProxy';
import { inject } from 'aurelia-dependency-injection';
import { arrayBufferToXlsx } from 'Utils/blobs';
import { ApiService } from './ApiService';

@inject(RevenueAndOccupancyReportProxy)
export class RevenueAndOccupancyReportService extends ApiService {
    constructor(private readonly revenueAndOccupancyReportProxy: RevenueAndOccupancyReportProxy) {
        super();
    }

    public async getRevenueAndOccupancyReport(
        request: GetRevenueAndOccupancyReportRequestDto | null
    ): Promise<RevenueAndOccupancyReportDto | null> {
        return await this.revenueAndOccupancyReportProxy.getRevenueAndOccupancyReport(request);
    }

    public async exportRevenueAndOccupancyReport(
        request: GetRevenueAndOccupancyReportRequestDto | null
    ): Promise<Blob> {
        const document = await this.revenueAndOccupancyReportProxy.exportRevenueAndOccupancyReport(
            request
        );

        return arrayBufferToXlsx(document);
    }
}
