import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Owner: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 12 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.73309 1.06445C7.10088 1.06445 8.21341 2.17709 8.21341 3.54477C8.21341 4.91245 7.10088 6.02509 5.73309 6.02509C4.36573 6.02509 3.25288 4.91245 3.25288 3.54477C3.25288 2.17709 4.36573 1.06445 5.73309 1.06445ZM5.73319 6.64505C7.44285 6.64505 8.83357 5.25444 8.83357 3.54479C8.83357 1.83524 7.44285 0.444519 5.73319 0.444519C4.02386 0.444519 2.63293 1.83524 2.63293 3.54479C2.63293 5.25444 4.02386 6.64505 5.73319 6.64505Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.5699 15.1843H8.11013L6.0409 11.5709L7.38736 9.10775H7.56688C9.23408 9.10775 10.5699 10.4756 10.5699 12.1429V15.1843ZM5.69128 12.2214L7.39496 15.1843H4.06152L5.69128 12.2214ZM0.855365 12.1429C0.855365 10.4756 2.23232 9.10775 3.89952 9.10775H3.91755L5.32918 11.5776L3.3536 15.1843H0.855365V12.1429ZM6.67991 9.10775L5.67916 10.9271L4.63297 9.10775H6.67991ZM7.56687 8.48782H3.89967C1.8905 8.48782 0.235352 10.134 0.235352 12.143V15.8044H11.1899V12.143C11.1899 10.1338 9.57605 8.48782 7.56687 8.48782Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Owner;
