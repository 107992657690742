import { Button, Col, Row, Tooltip } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { Content } from 'antd/lib/layout/layout';
import { GetMembershipMembersRequestDto } from 'Api/Features/Memberships/Dtos/GetMembershipMembersRequestDto';
import { GetMembershipMembersResponseItemDto } from 'Api/Features/Memberships/Dtos/GetMembershipMembersResponseItemDto';
import { MembershipRoleDto } from 'Api/Features/Memberships/Dtos/MembershipRoleDto';
import CompanyHeader, { CompanyHeaderData } from 'Components/company-header';
import { PencilBorder, Company } from 'Components/icons';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import { Tag } from 'Components/tag';
import { TagColors } from 'Components/tag/tag';
import { useService, useStores } from 'Hooks';
import React, {
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { MembershipService } from 'Services/MembershipService';
import { CompanyContext } from '../..';
import EditRoleModal from '../edit-role-modal';
import './index.less';

const formGutter: [Gutter, Gutter] = [40, 0];

const NonMemberDetails: FunctionComponent = () => {
    const companyContext = useContext(CompanyContext);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const membershipService = useService(MembershipService);
    const { id, email } = useParams<{ id: string; email: string }>();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [nonMember, setNonMember] = useState<GetMembershipMembersResponseItemDto>();

    const [editRolesModalOpen, setEditRolesModalOpen] = useState(false);

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `companies`,
            nameKey: 'companies',
        },
        {
            path: `${id}/dashboard`,
            nameKey: companyContext?.name ?? '',
        },
        {
            path: 'team',
            nameKey: 'team',
        },
        {
            path: `non-member/${email}`,
            nameKey: `${nonMember?.contactInfo?.email}`,
        },
    ];

    const fetch = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            const request: GetMembershipMembersRequestDto = {
                includeEmailInvitations: true,
            };
            const [data] = await membershipService.getMembershipMembers(id, request);
            setNonMember(data.find((x) => x.contactInfo?.email === email));
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [setLoading, membershipService, t]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const onEditRolesClick = () => {
        setEditRolesModalOpen(true);
    }

    const memberRolesStringArray = (): string[] => {
        const roles = nonMember?.pendingRoles

        if (!roles?.some((role) => role !== MembershipRoleDto.Member))
            return [t(`Membership.member_role_${MembershipRoleDto.Member}`)];

        return roles
            ?.filter((role) => role !== MembershipRoleDto.Member)
            ?.map((role) =>  t(`Membership.member_role_${role}`),
            );
    };

    const headerData: CompanyHeaderData = {
        iconName: 'User',
        title: nonMember?.contactInfo?.email,
        tags: <Tag label={t('Membership.app_invitation_sent')} color={TagColors.default} />,
    };

    const removeFromMembership = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Company/>,
                title: t('Membership.remove_from_company_confirmation'),
                positiveText: t('yes'),
                negativeText: t('no'),
            }))
        )
            return;

        try {
            globalLoadingStore.addLoading();
            if(nonMember?.emailInvitation?.id) {
                await membershipService.cancelMembershipEmailInvitation(
                    nonMember?.emailInvitation?.id
                );

                toastStore.toast({
                    type: 'success',
                    message: t('Membership.remove_member_success'),
                });
                history.push(`/companies/${id}/dashboard/team`);
            }
        } catch (e) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const getRoles = (): SimpleListItemProps[] | undefined => {
        const roles = memberRolesStringArray();

        if (!roles?.some((role) => role !== MembershipRoleDto.Member))
            return [{ title: t(`Membership.MembershipRoleDto_${MembershipRoleDto.Member}`) }];

        return roles
            ?.filter((role) => role !== MembershipRoleDto.Member)
            ?.map((role) => ({
                title: role,
            }));
    };

    return (
        <div className="NonMemberDetails">
            <CompanyHeader
                company={companyContext}
                routes={breadcrumbs}
                headerData={headerData}
                loading={loading}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            removeFromMembership();
                        }}
                    >
                        {t('Membership.remove_from_company')}
                    </Button>
                }
            />
            <Content>
                <Row gutter={formGutter}>
                    <Col span={12}>
                        <Row>
                            <Col span={24} className="editable">
                                <SimpleList title={t(`roles`)} data={getRoles()}>
                                    <Tooltip title={t('edit_roles')}>
                                        <Button
                                            icon={<PencilBorder />}
                                            onClick={onEditRolesClick}
                                        />
                                    </Tooltip>
                                </SimpleList>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content>

            {editRolesModalOpen && (
                <EditRoleModal
                    memberRoles={nonMember?.pendingRoles
                        ?.filter((role) => role !== null)
                        .map((role) => role!)}
                    visible={editRolesModalOpen}
                    emailInvitationId={nonMember?.emailInvitation?.id}
                    membershipId={id}
                    onComplete={(success: Boolean) => {
                        setEditRolesModalOpen(false);
                        if (success) {
                            fetch();
                        }
                    }}
                />
            )}
        </div>
    );
};

export default NonMemberDetails;
