import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Phone: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.762 2.1c-.453-.353-.956-.398-1.472-.167-1.285.576-2.295 1.475-2.942 2.723-.148.285-.121.751.002 1.065.203.514.546.974.838 1.452 1.053 1.724 1 2.322-.401 3.735-1.82 1.833-3.763 3.526-5.884 5.006-.427.298-.87.574-1.323.83-.993.56-1.582.51-2.466-.219-.588-.484-1.153-.996-1.725-1.499-.385-.337-.805-.4-1.28-.207-1.179.478-2.164 1.206-2.93 2.225-.463.614-.465 1.232-.101 1.906 1.273 2.36 3.853 3.485 6.605 2.87 1.773-.395 3.35-1.22 4.809-2.258 2.976-2.118 5.821-4.409 8.215-7.18 1.399-1.619 2.477-3.46 2.485-6.134-.094-1.49-.946-2.988-2.43-4.147zM.382 19.312c-.604-1-.465-1.943.227-2.844.773-1.009 1.769-1.744 2.903-2.283.903-.428 1.772-.398 2.547.349.505.486 1.056.924 1.58 1.39.495.438 1.01.534 1.572.127 1.262-.913 2.582-1.756 3.773-2.754 1.281-1.074 2.469-2.266 3.643-3.46.672-.681.65-.907.169-1.756-.365-.644-.758-1.273-1.137-1.91-.411-.688-.37-1.364.04-2.042.758-1.249 1.776-2.212 3.095-2.847.92-.443 1.789-.373 2.581.289 2.833 2.365 3.296 5.406 1.759 8.585-.835 1.725-2.053 3.187-3.454 4.489-2.542 2.363-5.127 4.677-8.136 6.45-1.656.976-3.411 1.68-5.383 1.684-2.629.018-4.463-1.293-5.778-3.467z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Phone;
