import { Col, Divider, Input, Radio, Row, Typography } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { MembershipRoleDto } from 'Api/Features/Memberships/Dtos/MembershipRoleDto';
import { Delete } from 'Components/icons';
import Icon from 'Components/icons/icon';
import SelectCustom, { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useService } from 'Hooks';
import debounce from 'lodash.debounce';
import { Member } from 'Models/Members/Member';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MemberService } from 'Services/MemberService';
import { theme } from 'variant';
import './invite-member-form-item.less';

interface InviteMemberFormItemProps {
    index: number;
    remove: (index: number) => void;
    membershipdId?: string;
}

enum MemberRadioButtonValues {
    New = 'New',
    Existing = 'Existing',
}

const formGutter: [Gutter, Gutter] = [40, 0];
const { Title } = Typography;

const InviteMemberFormItem: FunctionComponent<InviteMemberFormItemProps> = ({
    index,
    remove,
    membershipdId,
}) => {
    const { t } = useTranslation();
    const [selectedMemberId, setSelectedMemberId] = useState<string | undefined>(undefined);
    const [memberRadioState, setMemberRadioState] = useState<MemberRadioButtonValues>(
        MemberRadioButtonValues.New
    );
    const [selectedRoles, setSelectedRoles] = useState<MembershipRoleDto[]>([]);

    const memberService = useService(MemberService);
    const pageSize = 25;
    //members
    const [membersCurrentPage, setMembersCurrentPage] = useState(0);
    const [membersSearchResults, setMembersSearchResults] = useState<Member[]>([]);
    const [membersSearchTerm, setMembersSearchTerm] = useState('');
    const [membersMaxResults, setMembersMaxResults] = useState(false);
    const [membersOptions, setMembersOptions] = useState<SelectCustomOption[]>([]);
    const [selectedMemberOption, setSelectedMemberOption] = useState<SelectCustomOption[]>([]);
    const [selectLoading, setSelectLoading] = useState(false);

    const [formItemRequired, setFormItemRequired] = useState(true);
    const [firstNameDirtyState, setFirstNameDirtyState] = useState(false);
    const [lastNameDirtyState, setLastNameDirtyState] = useState(false);
    const [EmailDirtyState, setEmailDirtyState] = useState(false);
    const [rolesDirtyState, setRolesDirtyState] = useState(false);
    //#region Event handlers

    const resetMembersSearch = (): void => {
        setMembersCurrentPage(0);
        setMembersSearchResults([]);
        setMembersSearchTerm('');
        setMembersMaxResults(false);
    };

    const handleMembersKeywordsChange = useCallback((value: string): void => {
        resetMembersSearch();
        setMembersSearchTerm(value);
    }, []);

    const handleMembersMenuScrollToBottom = (): void => {
        if (!membersMaxResults) {
            setMembersCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const searchMembers = async (
        page: number,
        searchTerm: string,
        excludedMembershipId?: string
    ): Promise<Member[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
            excludedMembershipId,
        };
        const [results, totalItemCount] = await memberService.getMembers(args);

        if (results.length + pageSize * page >= totalItemCount) {
            setMembersMaxResults(true);
        }
        setSelectLoading(false);
        return results;
    };

    const debounceMembersSearch = useRef(
        debounce((page: number, searchTerm: string, excludedMembershipId?: string) => {
            searchMembers(page, searchTerm, excludedMembershipId).then((results) => {
                setMembersSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    useEffect(() => {
        debounceMembersSearch.current(membersCurrentPage, membersSearchTerm, membershipdId);
    }, [membersCurrentPage, membersSearchTerm, membershipdId]);

    useEffect(() => {
        const searchResults = membersSearchResults?.map(
            (member: Member) =>
                ({
                    value: member?.id,
                    label: member?.name,
                    imageUrl: member?.imageUrl,
                    badge: undefined,
                    content: member.contactInfo?.email,
                    additionalInfoObject: {
                        firstName: member.firstName,
                        lastName: member.lastName
                    }
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedMemberOption);

        setMembersOptions(merged);
    }, [membersSearchResults, t, selectedMemberOption]);


    const handleRoleSelect = (values: any): void => {
        if (values === '') {
            setSelectedRoles([]);
            setRolesDirtyState(false);
        } else {
            setSelectedRoles(values?.map((x: any) => x.value));
            setRolesDirtyState(true);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.currentTarget.classList.contains('firstName')) {
            setFirstNameDirtyState(event.currentTarget.value !== '');
        }
        if (event.currentTarget.classList.contains('lastName')) {
            setLastNameDirtyState(event.currentTarget.value !== '');
        }
        if (event.currentTarget.classList.contains('email')) {
            setEmailDirtyState(event.currentTarget.value !== '');
        }
    };

    useEffect(() => {
        if (memberRadioState === MemberRadioButtonValues.New)
            setFormItemRequired(
                firstNameDirtyState || lastNameDirtyState || EmailDirtyState || rolesDirtyState
            );
        else
            setFormItemRequired(
                rolesDirtyState || (selectedMemberId !== undefined && selectedMemberId !== '')
            );
    }, [
        firstNameDirtyState,
        lastNameDirtyState,
        EmailDirtyState,
        rolesDirtyState,
        memberRadioState,
        selectedMemberOption,
        selectedMemberId,
    ]);

    useEffect(() => {
        if (memberRadioState === MemberRadioButtonValues.Existing) {
            setSelectLoading(true);
            debounceMembersSearch.current(membersCurrentPage, membersSearchTerm, membershipdId);
        }
    }, [membersCurrentPage, membersSearchTerm, membershipdId, memberRadioState]);

    useEffect(() => {
        setSelectedRoles([]);
        setRolesDirtyState(false);
    }, [memberRadioState]);

    const roleOptions = (): SelectCustomOption[] => {
        const options = Object.keys(MembershipRoleDto)
            .filter((role) => role !== MembershipRoleDto.PendingMember)
            .filter((role) => role !== MembershipRoleDto.Owner)
            .map(
                (role) =>
                    ({
                        label: t(`Membership.member_role_${role}`),
                        value: role,
                    } as SelectCustomOption)
            );
        return options;
    };

    return (
        <div className="InviteMemberFormItem">
            <Title level={4}>{t('Membership.member_is')}</Title>

            <Row gutter={formGutter} className="radio-buttons">
                <Col span={24}>
                    <Radio.Group
                        onChange={(e): void => {
                            setMemberRadioState(e.target.value);
                        }}
                        value={memberRadioState}
                    >
                        <Radio value={MemberRadioButtonValues.Existing}>
                            {t(`Membership.an_existing_contact`)}
                        </Radio>
                        <Radio value={MemberRadioButtonValues.New}>
                            {t(`Membership.a_new_contact`)}
                        </Radio>
                    </Radio.Group>
                </Col>
            </Row>

            {memberRadioState === MemberRadioButtonValues.Existing && (
                <Row gutter={formGutter}>
                    <Col span={6}>
                        <ValidatedFormItem
                            name={[index, 'id']}
                            label={t('member')}
                            required
                            rules={[
                                {
                                    required: formItemRequired,
                                    message: t('Membership.member_required'),
                                },
                            ]}
                        >
                            <SelectCustom
                                options={membersOptions}
                                defaultImg={<Icon iconName="User"/>}
                                strongLabel={true}
                                placeholder={t('SelectCustom.placeholder_default')}
                                hideSelectedOptions={false}
                                onKeywordsChange={handleMembersKeywordsChange}
                                onMenuScrollToBottom={handleMembersMenuScrollToBottom}
                                onChange={(value): void => {
                                    const options = [value as SelectCustomOption];
                                    setSelectedMemberOption(options);
                                    setSelectedMemberId(value.value);
                                }}
                                selected={selectedMemberId ? [selectedMemberId] : null}
                                isLoading={selectLoading}
                                idAttribute={`invited-members_${index}_id`}
                            />
                        </ValidatedFormItem>
                    </Col>
                    <Col span={6}>
                        <ValidatedFormItem
                            name={[index, 'role']}
                            label={t('User.roles')}
                            required
                            rules={[
                                {
                                    required: formItemRequired,
                                    message: t('Membership.roles_required'),
                                },
                            ]}
                        >
                            <SelectCustom
                                onChange={handleRoleSelect}
                                selected={selectedRoles}
                                isMulti
                                options={roleOptions()}
                                isLoading={selectLoading}
                                idAttribute={`invited-members_${index}_role`}
                            />
                        </ValidatedFormItem>
                    </Col>
                    <span
                        className="garbage-container"
                        onClick={(): void => {
                            remove(index);
                        }}
                    >
                        <Delete fill={theme['primary-color']} />
                    </span>
                </Row>
            )}

            {memberRadioState === MemberRadioButtonValues.New && (
                <Row gutter={formGutter}>
                    <Col span={6}>
                        <ValidatedFormItem
                            name={[index, 'firstName']}
                            label={t('first_name')}
                            required
                            rules={[
                                {
                                    required: formItemRequired,
                                    message: t('Membership.first_name_required'),
                                },
                            ]}
                        >
                            <Input
                                className="firstName"
                                onChange={(event): void => handleInputChange(event)}
                            />
                        </ValidatedFormItem>
                    </Col>
                    <Col span={6}>
                        <ValidatedFormItem
                            name={[index, 'lastName']}
                            label={t('last_name')}
                            required
                            rules={[
                                {
                                    required: formItemRequired,
                                    message: t('Membership.last_name_required'),
                                },
                            ]}
                        >
                            <Input
                                className="lastName"
                                onChange={(event): void => handleInputChange(event)}
                            />
                        </ValidatedFormItem>
                    </Col>
                    <Col span={6}>
                        <ValidatedFormItem
                            name={[index, 'email']}
                            label={t('email')}
                            required
                            rules={[
                                {
                                    required: formItemRequired,
                                    message: t('Membership.email_required'),
                                },
                                { type: 'email', message: t('Membership.email_not_valid_email') },
                            ]}
                        >
                            <Input
                                className="email"
                                onChange={(event): void => handleInputChange(event)}
                            />
                        </ValidatedFormItem>
                    </Col>
                    <Col span={6}>
                        <ValidatedFormItem
                            name={[index, 'role']}
                            label={t('User.roles')}
                            required
                            rules={[
                                {
                                    required: formItemRequired,
                                    message: t('Membership.roles_required'),
                                },
                            ]}
                        >
                            <SelectCustom
                                onChange={handleRoleSelect}
                                selected={selectedRoles}
                                isMulti
                                options={roleOptions()}
                                isLoading={selectLoading}
                                idAttribute={`invited-members_${index}_role`}
                            />
                        </ValidatedFormItem>
                    </Col>
                    <span
                        className="garbage-container"
                        onClick={(): void => {
                            remove(index);
                        }}
                    >
                        <Delete fill={theme['primary-color']} />
                    </span>
                </Row>
            )}
            <Divider />
        </div>
    );
};

export default InviteMemberFormItem;
