import React, { FunctionComponent, useState } from 'react';
import { Button, Row, Col, Input, Alert, Spin, Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Store } from 'antd/es/form/interface';
import { observer } from 'mobx-react';

import { ValidatedFormItem } from 'Components/validated-form-item';
import { LoginRequestSchema } from 'Schemas';
import { useFormValidation, useService, useStores } from 'Hooks';
import { AuthenticationService } from 'Services/AuthenticationService';
import { images } from 'variant';
import './index.less';

const Login: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [form] = Form.useForm();
    const [errors, validateForm] = useFormValidation(LoginRequestSchema, form);
    const authenticationService = useService(AuthenticationService);
    const [loginErrorState, setLoginErrorState] = useState();
    const [spinner, setSpinner] = useState(false);
    const { authorizationStore } = useStores();

    const history = useHistory();
    const hasResetPassword = history.location.state;
    //clear history state after consuming so that re-render does not keep state.
    window.history.replaceState(null, '');

    async function submit(values: Store): Promise<void> {
        const valid = await validateForm(values);
        if (!valid) return;
        try {
            setSpinner(true);
            await authenticationService.initAccessToken(values.username, values.password);
        } catch (error) {
            if (error.response.status === 400)
                setLoginErrorState(t('User.email_password_not_exist'));
            else setLoginErrorState(t('Errors.unexpected_error'));
        } finally {
            setSpinner(false);
        }
    }

    return (
        <>
            <Row className="mt-20" gutter={[0, loginErrorState ? 30 : 60]}>
                <Col span={4} />
                <Col span={16}>
                    <img id="logo" src={images.logoWhite} alt="logo" />
                </Col>
                <Col span={4} />
            </Row>
            {loginErrorState && (
                <Row gutter={[0, 40]}>
                    <Col span={12} offset={6}>
                        <Alert className="login-error" message={loginErrorState} type="error" />
                    </Col>
                </Row>
            )}
            {hasResetPassword && (
                <Row gutter={[0, 40]}>
                    <Col span={12} offset={6}>
                        <Alert
                            className="password-reset"
                            message={t('User.password_email_sent')}
                            type="error"
                        />
                    </Col>
                </Row>
            )}
            {authorizationStore.userIsNotManager && (
                <Row gutter={[0, 40]}>
                    <Col span={12} offset={6}>
                        <Alert
                            className="login-error"
                            message={t('Errors.forbidden')}
                            type="error"
                        />
                    </Col>
                </Row>
            )}

            <Row gutter={[0, 80]}>
                <Col span={12} offset={6}>
                    <Form
                        scrollToFirstError
                        layout="vertical"
                        onFinish={async (values): Promise<void> => {
                            submit(values);
                        }}
                        form={form}
                    >
                        <ValidatedFormItem
                            errors={errors}
                            label={t('email')}
                            name="username"
                            required
                        >
                            <Input maxLength={50} />
                        </ValidatedFormItem>

                        <ValidatedFormItem
                            errors={errors}
                            label={t('User.password')}
                            name="password"
                            required
                        >
                            <Input type="password" maxLength={50} />
                        </ValidatedFormItem>

                        {spinner && <Spin indicator={antIcon} size="large" />}

                        <p className="forgot-password">
                            <Link to="/forgot-Password">{t('User.forgot_password')}</Link>
                        </p>

                        <Button type="primary" htmlType="submit" className="login-btn">
                            {t('Log in')}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    );
});

export default Login;
