import React, { FunctionComponent } from 'react';
import { Select } from 'antd';
import { ALL_ASSIGNED_MANAGERS } from 'Models/Constants';
import { ManagerUser } from 'Models/ManagerUsers/ManagerUser';
import { useTranslation } from 'react-i18next';
import { ManagerAssignementFilter } from 'Models/Filters/ManagerAssignementFilter';

const { Option } = Select;

interface ManagerAssignementSelectProps {
    availableAssignementManagers: ManagerUser[];
    onManagerAssignmentChange: (managerId: string) => void;
    value?: string | undefined;
}

const mapManagers = (manager: ManagerUser[]): ManagerAssignementFilter[] =>
    manager.map((dto) => {
        const filter: ManagerAssignementFilter = {
            id: dto.id!,
            imageUrl: dto.imageUrl!,
            name: dto.name!,
        };
        return filter;
    });

const ManagerAssignementSelect: FunctionComponent<ManagerAssignementSelectProps> = ({
    availableAssignementManagers,
    onManagerAssignmentChange,
    value,
}) => {
    const { t } = useTranslation();

    const filters: ManagerAssignementFilter[] = [
        {
            id: ALL_ASSIGNED_MANAGERS,
            imageUrl: '',
            name: t('Administration.all_assigned_managers'),
        },
    ].concat(mapManagers(availableAssignementManagers));
    return (
        <Select
            defaultValue={ALL_ASSIGNED_MANAGERS}
            onChange={onManagerAssignmentChange}
            style={{ width: '100%' }}
            value={value}
        >
            {filters.map((managerFilter) => (
                <Option key={managerFilter.id} value={managerFilter.id}>
                    {managerFilter.name}
                </Option>
            ))}
        </Select>
    );
};

export default ManagerAssignementSelect;
