import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Television: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 2.917v13.038h4.066v1.567h11.697v-1.567H20V2.917H0zm.77 12.268h18.46V3.686H.77v11.499zm4.066 1.567h10.157v-.774H4.836v.774z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Television;
