// GENERATED FILE - DO NOT MODIFY
import { CreateOpportunityFileRequestDto } from 'Api/Features/Opportunities/Dtos/CreateOpportunityFileRequestDto';
import { GetOpportunityFilesRequestDto } from 'Api/Features/Opportunities/Dtos/GetOpportunityFilesRequestDto';
import { GetOpportunityFilesResponseDto } from 'Api/Features/Opportunities/Dtos/GetOpportunityFilesResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class OpportunityFileProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getOpportunityFiles(opportunityId: string, request: GetOpportunityFilesRequestDto | null): Promise<GetOpportunityFilesResponseDto | null> {
        const uri = this.buildUri(
            "/opportunities/{opportunityId}/files",
            { opportunityId: opportunityId },
            { ...request || undefined }
        );

        const data: GetOpportunityFilesResponseDto | null = await this.httpClient.get<GetOpportunityFilesResponseDto | null>(uri);
        return data;
    }

    public async createOpportunityFile(opportunityId: string, request: CreateOpportunityFileRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/opportunities/{opportunityId}/files",
            { opportunityId: opportunityId },
            null
        );

        const data: string = await this.httpClient.post<CreateOpportunityFileRequestDto | null, string>(uri, request);
        return data;
    }

    public async deleteOpportunityFile(opportunityId: string, fileId: string): Promise<void> {
        const uri = this.buildUri(
            "/opportunities/{opportunityId}/files/{fileId}",
            { opportunityId: opportunityId, fileId: fileId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}