import Icon from 'Components/icons/icon';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { ImageWithPlaceholderProps } from 'Components/image-with-placeholder/image-with-placeholder';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import './activity-board-notification-item.less';

interface LinkInterface {
    label: string;
    url: string;
}

export interface ActivityBoardNotificationItemProps {
    titleImg: ImageWithPlaceholderProps;
    title: string;
    companyName: string;
    date: string;
    link?: LinkInterface;
    isNew?: boolean;
}

const ActivityBoardNotificationItem: FunctionComponent<ActivityBoardNotificationItemProps> = ({
    titleImg,
    title,
    companyName,
    date,
    link,
    isNew = false,
}) => {
    return (
        <div className="ActivityBoardNotificationItem">
            <ImageWithPlaceholder
                defaultImg={titleImg.defaultImg || <Icon iconName="User" />}
                imgSrc={titleImg.imgSrc}
            />

            <div className={`notification-infos isNew-${isNew}`}>
                <div
                    className="title"
                    dangerouslySetInnerHTML={{
                        __html: `${title}`,
                    }}
                />

                <div className="date">{date}</div>

                <div className="company-link-wrapper">
                    <div className="company-wrapper">
                        <div className="label">{companyName}</div>
                    </div>

                    {link && (
                        <Link className="link" to={link.url}>
                            {link.label}
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ActivityBoardNotificationItem;
