import { ColumnType } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import Table, { TablePaginationConfig } from 'antd/lib/table';
import Icon from 'Components/icons/icon';
import { ListSectionHeader } from 'Components/list-section-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { TableFilters } from 'Components/table-filters';
import { useService } from 'Hooks';
import { autorun } from 'mobx';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CampusService, GetCampusIntegrationStatusesResponse } from 'Services/CampusService';
import { FilterStore } from 'Stores';
import { cleanVal } from 'Utils/NumberUtils';
import { images, theme } from 'variant';

const { usersHeader } = images;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 20,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const advancedFilters: AdvancedFilter[] = [];

export interface CampusIntegrationStatusesColumns {
    id: string;
    locationName: string;
    stripe: string;
    sageIntacct: string;
}

const Integration: FunctionComponent = () => {
    const campusService = useService(CampusService);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [data, setData] = useState<CampusIntegrationStatusesColumns[]>([]);
    const [loading, setLoading] = useState(false);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const paginationRef = useRef(initialPaginationState);
    const history = useHistory();
    const { t } = useTranslation();

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            nameKey: 'Administration.administration_title',
            path: 'administration',
        },
        {
            nameKey: 'integrations',
            path: 'integration',
        },
    ];

    const onRowClick = (integration: CampusIntegrationStatusesColumns): void => {
        history.push({ pathname: `integration/${integration.id}`, state: integration });
    };

    const columns: ColumnType<CampusIntegrationStatusesColumns>[] = [
        {
            title: t('location'),
            dataIndex: 'locationName',
        },
        {
            title: t('stripe'),
            dataIndex: 'stripe',
        },
        {
            title: t('sage_intacct'),
            dataIndex: 'sageIntacct',
        },
    ];

    const fetch = useCallback(
        async (params: { pagination: TablePaginationConfig; searchTerm?: string }) => {
            setLoading(true);

            try {
                const response: GetCampusIntegrationStatusesResponse =
                    await campusService.getCampusIntegrationStatuses({
                        page: (params.pagination.current || 1) - 1,
                        pageSize: params.pagination.pageSize || 10,
                        searchTerm: params.searchTerm,
                    });

                const responseMapped: CampusIntegrationStatusesColumns[] = response.items.map(
                    (item) => ({
                        id: cleanVal.string(item.campus?.id),
                        locationName: cleanVal.string(item.campus?.name),
                        stripe: item.stripeConfig?.isDefaultAccount
                            ? t('default_account')
                            : item.stripeConfig?.stripeConnectedAccountName
                            ? item.stripeConfig.stripeConnectedAccountName
                            : '',
                        sageIntacct: item.sageIntacctConfig?.accountingSyncEnabled
                            ? `${item.sageIntacctConfig.sageLocationEntityName}`
                            : t('disabled'),
                    })
                );

                setData(responseMapped);
                setPagination({
                    ...params.pagination,
                    total: response.totalItemCount,
                });
            } finally {
                setLoading(false);
            }
        },
        [campusService, setData, setPagination, setLoading]
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            fetch({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                searchTerm: filterStore.searchTerm,
            });
        });
        return (): void => {
            disposer();
        };
    }, [fetch, filterStoreRef]);

    return (
        <div className="Integration">
            <ListSectionHeader
                title={t('integrations')}
                subTitle={t('integrations_subtitle')}
                defaultImg={<Icon iconName="Monitor" fill={theme['primary-color']} />}
                backgroundImageUrl={usersHeader}
                routes={breadcrumbs}
            />
            <Content>
                <TableFilters filterStore={filterStoreRef.current} includeSearch />

                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(integration: CampusIntegrationStatusesColumns): string =>
                        integration.id
                    }
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onRow={(row: CampusIntegrationStatusesColumns) => ({
                        onClick: (): void => {
                            onRowClick(row);
                        },
                    })}
                />
            </Content>
        </div>
    );
};

export default Integration;
