import ActivityLogItem, {
    ActivityLogItemProps,
} from 'Components/activity-log-item/activity-log-item';
import React, { FunctionComponent } from 'react';
import './activity-log-list.less';

type ActivityLogitem =
    | ({
          id: string;
          onEdit?: (value: string) => void;
          onDelete?: (value: string) => void;
      } & ActivityLogItemProps)
    | null;

interface ActivityLogListProps {
    activities: ActivityLogitem[];
    lined?: boolean;
}

const ActivityLogList: FunctionComponent<ActivityLogListProps> = ({
    activities,
    lined = false,
}) => {
    
    return (
        <div className="ActivityLogList">
            {activities.map((item: ActivityLogitem) => {
                if (item) {
                    const onEdit = item.onEdit ? (): void => item.onEdit!(item.id) : undefined;
                    const onDelete = item.onDelete
                        ? (): void => item.onDelete!(item.id)
                        : undefined;
                    return (
                        <ActivityLogItem
                            key={item.id}
                            lined={lined}
                            icon={item.icon}
                            title={item.title}
                            note={item.note !== null ? item.note : undefined}
                            date={item.date}
                            onClickEdit={onEdit}
                            onDeleteClick={onDelete}
                            reason={item.reason !== null ? item.reason : undefined}
                            viewFiles={item.viewFiles}
                            onViewFilesClick={item.onViewFilesClick}
                        />
                    );
                } else {
                    return null;
                }
            })}
        </div>
    );
};

export default ActivityLogList;
