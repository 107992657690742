import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { ALL_ROOMS } from 'Models/Constants';
import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AmenityService } from 'Services/AmenityService';
import { cleanVal } from 'Utils/NumberUtils';

interface SelectOption {
    id: string;
    name: string;
}
interface ConferenceRoomSelectProps {
    onConferenceRoomChange: any;
    campusId: string;
}

const ConferenceRoomSelect: FunctionComponent<ConferenceRoomSelectProps> = observer(({
    onConferenceRoomChange,
    campusId,
}) => {
    const amenityService = useService(AmenityService);
    const { globalLoadingStore, requestStore } = useStores();
    const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);
    const [selected, setSelected] = useState<SelectValue>();

    const { t } = useTranslation();

    const fetch = useCallback(
        async (params: { campusId: string }) => {
            globalLoadingStore.addLoading();

            try {
                const response = await amenityService.getAmenities({
                    campusIds: params.campusId === 'all' ? requestStore.getReportRequestAvailableLocationIdsForUser() : [params.campusId],
                });

                const options: SelectOption[] = response.items.map((option) => ({
                    id: cleanVal.string(option.id),
                    name: cleanVal.string(option.name),
                }));

                options.unshift({
                    id: ALL_ROOMS,
                    name: t('Filter.all_rooms'),
                });

                setSelected(ALL_ROOMS);
                setSelectOptions(options);
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [amenityService]
    );

    const renderSelectOptions = (options: SelectOption[]): ReactElement[] => {
        return options.map((option) => {
            return (
                <Select.Option key={option.id} value={option.id}>
                    {option.name}
                </Select.Option>
            );
        });
    };

    const handleChange = (value: SelectValue): void => {
        setSelected(value);
        onConferenceRoomChange(value);
    };

    useEffect(() => {
        fetch({ campusId: campusId });
    }, [fetch, campusId]);

    return (
        <Select
            defaultValue="all"
            onChange={handleChange}
            style={{ width: '100%' }}
            value={selected}
        >
            {renderSelectOptions(selectOptions)}
        </Select>
    );
});

export default ConferenceRoomSelect;
