// GENERATED FILE - DO NOT MODIFY
import { AmenityAvailabilityDto } from 'Api/Features/Amenities/Dtos/AmenityAvailabilityDto';
import { BookingDetailsDto } from 'Api/Features/Amenities/Dtos/BookingDetailsDto';
import { BookingDto } from 'Api/Features/Amenities/Dtos/BookingDto';
import { BookingSummaryDto } from 'Api/Features/Amenities/Dtos/BookingSummaryDto';
import { BookingSummaryRequestDto } from 'Api/Features/Amenities/Dtos/BookingSummaryRequestDto';
import { CancelBookingRequestDto } from 'Api/Features/Amenities/Dtos/CancelBookingRequestDto';
import { CancelRecurrenceRequestDto } from 'Api/Features/Amenities/Dtos/CancelRecurrenceRequestDto';
import { CreateBookingRequestDto } from 'Api/Features/Amenities/Dtos/CreateBookingRequestDto';
import { DailyBookingSummaryDto } from 'Api/Features/Amenities/Dtos/DailyBookingSummaryDto';
import { ErrorsForRecurrenceDto } from 'Api/Features/Amenities/Dtos/ErrorsForRecurrenceDto';
import { GetRecurrencePresetsRequestDto } from 'Api/Features/Amenities/Dtos/GetRecurrencePresetsRequestDto';
import { RecurrencePresetDto } from 'Api/Features/Amenities/Dtos/RecurrencePresetDto';
import { TimeSlotsInfoDto } from 'Api/Features/Amenities/Dtos/TimeSlotsInfoDto';
import { UpdateBookingRequestDto } from 'Api/Features/Amenities/Dtos/UpdateBookingRequestDto';
import { GetBookablePeriodsResponseDto } from 'Api/Features/Bookings/Dtos/GetBookablePeriodsResponseDto';
import { GetBookingHistoryRequestDto } from 'Api/Features/Bookings/Dtos/GetBookingHistoryRequestDto';
import { GetDailyBookingSummaryRequestDto } from 'Api/Features/Bookings/Dtos/GetDailyBookingSummaryRequestDto';
import { GetMemberBookingsResponseDto } from 'Api/Features/Bookings/Dtos/GetMemberBookingsResponseDto';
import { GetMembershipBookingsResponseDto } from 'Api/Features/Bookings/Dtos/GetMembershipBookingsResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class BookingProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async cancelBooking(request: CancelBookingRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/bookings/cancel",
            null,
            null
        );

        await this.httpClient.post<CancelBookingRequestDto | null, void>(uri, request);
    }

    public async cancelBookingRecurrence(request: CancelRecurrenceRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/bookings/cancelrecurrence",
            null,
            null
        );

        await this.httpClient.post<CancelRecurrenceRequestDto | null, void>(uri, request);
    }

    public async getErrorsForBookingRecurrence(recurrenceId: string | null): Promise<ErrorsForRecurrenceDto | null> {
        const uri = this.buildUri(
            "/bookings/errorsforrecurrence/{recurrenceId}",
            { recurrenceId: recurrenceId },
            null
        );

        const data: ErrorsForRecurrenceDto | null = await this.httpClient.get<ErrorsForRecurrenceDto | null>(uri);
        return data;
    }

    public async getBooking(bookingId: string | null): Promise<BookingDetailsDto | null> {
        const uri = this.buildUri(
            "/bookings/{bookingId}",
            { bookingId: bookingId },
            null
        );

        const data: BookingDetailsDto | null = await this.httpClient.get<BookingDetailsDto | null>(uri);
        return data;
    }

    public async updateBooking(bookingId: string | null, updateBookingRequest: UpdateBookingRequestDto | null): Promise<BookingDetailsDto | null> {
        const uri = this.buildUri(
            "/bookings/{bookingId}",
            { bookingId: bookingId },
            null
        );

        const data: BookingDetailsDto | null = await this.httpClient.post<UpdateBookingRequestDto | null, BookingDetailsDto | null>(uri, updateBookingRequest);
        return data;
    }

    public async getMemberBookings_V1_1(request: GetBookingHistoryRequestDto | null): Promise<(BookingDto | null)[] | null> {
        const uri = this.buildUri(
            "/bookings",
            null,
            { ...request || undefined }
        );

        const data: (BookingDto | null)[] | null = await this.httpClient.get<(BookingDto | null)[] | null>(uri);
        return data;
    }

    public async getMemberBookings(accountId: string, request: GetBookingHistoryRequestDto | null): Promise<GetMemberBookingsResponseDto | null> {
        const uri = this.buildUri(
            "/accounts/{accountId}/bookings",
            { accountId: accountId },
            { ...request || undefined }
        );

        const data: GetMemberBookingsResponseDto | null = await this.httpClient.get<GetMemberBookingsResponseDto | null>(uri);
        return data;
    }

    public async getMembershipBookings_V1_1(membershipId: string | null, request: GetBookingHistoryRequestDto | null): Promise<(BookingDto | null)[] | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/bookings",
            { membershipId: membershipId },
            { ...request || undefined }
        );

        const data: (BookingDto | null)[] | null = await this.httpClient.get<(BookingDto | null)[] | null>(uri);
        return data;
    }

    public async getMembershipBookings(membershipId: string | null, request: GetBookingHistoryRequestDto | null): Promise<GetMembershipBookingsResponseDto | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/bookings",
            { membershipId: membershipId },
            { ...request || undefined }
        );

        const data: GetMembershipBookingsResponseDto | null = await this.httpClient.get<GetMembershipBookingsResponseDto | null>(uri);
        return data;
    }

    public async getBookingRecurrencePresets(amenityId: string | null, request: GetRecurrencePresetsRequestDto | null): Promise<(RecurrencePresetDto | null)[] | null> {
        const uri = this.buildUri(
            "/amenities/{amenityId}/recurringpresets",
            { amenityId: amenityId },
            null
        );

        const data: (RecurrencePresetDto | null)[] | null = await this.httpClient.post<GetRecurrencePresetsRequestDto | null, (RecurrencePresetDto | null)[] | null>(uri, request);
        return data;
    }

    public async getBookingSummary(amenityId: string | null, request: BookingSummaryRequestDto | null): Promise<BookingSummaryDto | null> {
        const uri = this.buildUri(
            "/amenities/{amenityId}/summary",
            { amenityId: amenityId },
            null
        );

        const data: BookingSummaryDto | null = await this.httpClient.post<BookingSummaryRequestDto | null, BookingSummaryDto | null>(uri, request);
        return data;
    }

    public async createBooking(amenityId: string | null, request: CreateBookingRequestDto | null): Promise<string | null> {
        const uri = this.buildUri(
            "/amenities/{amenityId}/book",
            { amenityId: amenityId },
            null
        );

        const data: string | null = await this.httpClient.post<CreateBookingRequestDto | null, string | null>(uri, request);
        return data;
    }

    public async getTimeslots(date: string, campusId: string | null, creditBalanceId: string | null, amenityId?: string | null, bookingId?: string | null): Promise<TimeSlotsInfoDto | null> {
        const uri = this.buildUri(
            "/amenitytimeslots",
            null,
            { date: date, campusId: campusId, creditBalanceId: creditBalanceId, amenityId: amenityId, bookingId: bookingId }
        );

        const data: TimeSlotsInfoDto | null = await this.httpClient.get<TimeSlotsInfoDto | null>(uri);
        return data;
    }

    public async getAmenityAvailabilities(amenityId: string | null, date: string, bookingId?: string | null): Promise<AmenityAvailabilityDto | null> {
        const uri = this.buildUri(
            "/amenities/{amenityId}/availabilities",
            { amenityId: amenityId },
            { date: date, bookingId: bookingId }
        );

        const data: AmenityAvailabilityDto | null = await this.httpClient.get<AmenityAvailabilityDto | null>(uri);
        return data;
    }

    public async getDailyBookingSummary1(campusId: string | null, request: GetDailyBookingSummaryRequestDto | null): Promise<DailyBookingSummaryDto | null> {
        const uri = this.buildUri(
            "/campus/{campusId}/dailybookingsummary",
            { campusId: campusId },
            { ...request || undefined }
        );

        const data: DailyBookingSummaryDto | null = await this.httpClient.get<DailyBookingSummaryDto | null>(uri);
        return data;
    }

    public async getDailyBookingSummary2(campusId: string | null, request: GetDailyBookingSummaryRequestDto | null): Promise<DailyBookingSummaryDto | null> {
        const uri = this.buildUri(
            "/campus/{campusId}/dailybookingsummary",
            { campusId: campusId },
            null
        );

        const data: DailyBookingSummaryDto | null = await this.httpClient.post<GetDailyBookingSummaryRequestDto | null, DailyBookingSummaryDto | null>(uri, request);
        return data;
    }

    public async getAccountBookablePeriods(accountId: string | null, campusId: string | null, creditBalanceId: string | null): Promise<GetBookablePeriodsResponseDto | null> {
        const uri = this.buildUri(
            "/accounts/{accountId}/bookable-periods",
            { accountId: accountId },
            { campusId: campusId, creditBalanceId: creditBalanceId }
        );

        const data: GetBookablePeriodsResponseDto | null = await this.httpClient.get<GetBookablePeriodsResponseDto | null>(uri);
        return data;
    }
}