import { Button, Col, Row } from 'antd';
import { SubscriptionOnboardingCategoryStatusDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionOnboardingCategoryStatusDto';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ONBOARDING_CATEGORY_ID_MEMBER_ACCESS,
    ONBOARDING_CATEGORY_ID_MEMBER_INVITATION,
    ONBOARDING_CATEGORY_ID_SPACE,
} from 'Models/Constants';
import './onboarding-status-bar.less';
import { displayFormatedDate } from 'Utils/TimeUtils';
import { SubscriptionOnboardingTaskStatusDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionOnboardingTaskStatusDto';

interface OnboardingStatusBarProps {
    categories?: SubscriptionOnboardingCategoryStatusDto[];
    setInspectAndPrepareSpaceModalOpen: (visible: boolean) => void;
    setInviteMembersModalOpen: (visible: boolean) => void;
    setCreateMembersAccessModalOpen: (visible: boolean) => void;
}

const OnboardingStatusBar: FunctionComponent<OnboardingStatusBarProps> = ({
    categories,
    setInspectAndPrepareSpaceModalOpen,
    setCreateMembersAccessModalOpen,
    setInviteMembersModalOpen,
}) => {
    const { t } = useTranslation();

    const allCategoriesComplete = (): boolean => {
        const completed = categories?.filter((category) => category.isCompleted);
        const categoryCount = categories?.length;
        return completed === categoryCount;
    };

    const buttonText = (onboardingCategoryId?: string): string => {
        let text = '';

        switch (onboardingCategoryId) {
            case ONBOARDING_CATEGORY_ID_SPACE:
                text = t('Subscription.inspect_and_prepare_space');
                break;
            case ONBOARDING_CATEGORY_ID_MEMBER_INVITATION:
                text = t('invite_members');
                break;
            case ONBOARDING_CATEGORY_ID_MEMBER_ACCESS:
                text = t('Subscription.review_members_access');
                break;
        }

        return text;
    };

    const completedTasksString = (
        tasks: (SubscriptionOnboardingTaskStatusDto | null)[] | null | undefined
    ): string => {
        let string = '';
        if (tasks) {
            const completed = tasks.filter((task) => task?.isCompleted);
            const taskAmount = tasks.length;
            if (completed.length > 0 && completed.length !== taskAmount) {
                string = `(${completed.length} of ${taskAmount} checked)`;
            }
        }
        return string;
    };

    const encouragementString = (
        categories?: SubscriptionOnboardingCategoryStatusDto[]
    ): string => {
        if (
            categories?.some(
                (category) =>
                    category?.onboardingCategoryId === ONBOARDING_CATEGORY_ID_SPACE &&
                    category.isCompleted === false
            )
        )
            return t('Subscription.alright_prepare_space');
        if (
            categories?.some(
                (category) =>
                    category?.onboardingCategoryId === ONBOARDING_CATEGORY_ID_MEMBER_INVITATION &&
                    category.isCompleted === false
            )
        )
            return t('Subscription.now_create_company_members');
        if (
            categories?.some(
                (category) =>
                    category?.onboardingCategoryId === ONBOARDING_CATEGORY_ID_MEMBER_ACCESS &&
                    category.isCompleted === false
            )
        )
            return t('Subscription.make_sure_members_activated');

        return '';
    };

    const handleClick = (categoryId: string | undefined): void => {
        switch (categoryId) {
            case ONBOARDING_CATEGORY_ID_SPACE:
                setInspectAndPrepareSpaceModalOpen(true);
                break;
            case ONBOARDING_CATEGORY_ID_MEMBER_ACCESS:
                setCreateMembersAccessModalOpen(true);
                break;
            case ONBOARDING_CATEGORY_ID_MEMBER_INVITATION:
                setInviteMembersModalOpen(true);
                break;
        }
    };

    return (
        <div className={`OnboardingStatusBar`}>
            <div className="flex-container">
                {allCategoriesComplete() ? (
                    <div className={`${allCategoriesComplete() ? 'all-completed' : ''}`}>
                        {t('Subscription.good_job_this_subscription_is_ready')}
                    </div>
                ) : (
                    <>
                        {categories?.map((category) => {
                            return (
                                <Row
                                    key={category?.onboardingCategoryId}
                                    className={`category ${
                                        category.isCompleted ? 'completed' : ''
                                    }`}
                                >
                                    <Col span={24}>
                                        <Row className="category-name">
                                            <Col span={24}>
                                                {category?.onboardingCategoryName}{' '}
                                                {completedTasksString(category?.tasks)}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                {category.isCompleted ? (
                                                    <p className="completed-on">
                                                        {category.wontDo
                                                            ? t(
                                                                  'Subscription.completed_wont_do_on',
                                                                  {
                                                                      param1: displayFormatedDate(
                                                                          category.dateCompleted ||
                                                                              ''
                                                                      ),
                                                                  }
                                                              )
                                                            : t('Subscription.completed_on', {
                                                                  param1: displayFormatedDate(
                                                                      category.dateCompleted || ''
                                                                  ),
                                                              })}
                                                    </p>
                                                ) : (
                                                    <Button
                                                        type="primary"
                                                        className="category-button"
                                                        onClick={(): void =>
                                                            handleClick(
                                                                category?.onboardingCategoryId
                                                            )
                                                        }
                                                    >
                                                        {buttonText(category.onboardingCategoryId)}
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            );
                        })}
                    </>
                )}
            </div>

            <Row className="encouragement">
                <Col span={24}>
                    <p>{encouragementString(categories)}</p>
                </Col>
            </Row>
        </div>
    );
};

export default OnboardingStatusBar;
