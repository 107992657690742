import { UnitTypeDto } from 'Api/Features/Units/Dtos/UnitTypeDto';
import * as yup from 'yup';

export const CreateUnitSchema = yup.object({
    name: yup.string().required('Errors.required'),
    type: yup.string().required('Errors.required'),
    capacity: yup.number().when('type', {
        is: UnitTypeDto.CommunalArea,
        then: yup
            .number()
            .required('Errors.required')
            .min(1, 'Errors.unit_capacity')
            .max(1000, 'Errors.unit_capacity'),
    }),
    squareFeet: yup.number().when('type', {
        is: (type) => type !== UnitTypeDto.CommunalArea,
        then: yup
            .number()
            .required('Errors.required')
            .min(1, 'Errors.unit_square_feet')
            .max(10000000, 'Errors.unit_square_feet'),
    }),
    numberOfDesks: yup.number().when('type', {
        is: (type) => type !== UnitTypeDto.CommunalArea,
        then: yup
            .number()
            .required('Errors.required')
            .min(1, 'Errors.unit_number_desks')
            .max(500, 'Errors.unit_number_desks'),
    }),
});
