// GENERATED FILE - DO NOT MODIFY
import { GetSubscriptionEventsRequestDto } from 'Api/Features/SubscriptionEvents/Dtos/GetSubscriptionEventsRequestDto';
import { GetSubscriptionEventsResponseDto } from 'Api/Features/SubscriptionEvents/Dtos/GetSubscriptionEventsResponseDto';
import { SubscriptionEventDto } from 'Api/Features/SubscriptionEvents/Dtos/SubscriptionEventDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class SubscriptionEventProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getSubscriptionEvent(subscriptionEventId: string): Promise<SubscriptionEventDto | null> {
        const uri = this.buildUri(
            "/subscription-events/{subscriptionEventId}",
            { subscriptionEventId: subscriptionEventId },
            null
        );

        const data: SubscriptionEventDto | null = await this.httpClient.get<SubscriptionEventDto | null>(uri);
        return data;
    }

    public async getSubscriptionEvents(subscriptionId: string | null, request: GetSubscriptionEventsRequestDto | null): Promise<GetSubscriptionEventsResponseDto | null> {
        const uri = this.buildUri(
            "/subscriptions/{subscriptionId}/events",
            { subscriptionId: subscriptionId },
            { ...request || undefined }
        );

        const data: GetSubscriptionEventsResponseDto | null = await this.httpClient.get<GetSubscriptionEventsResponseDto | null>(uri);
        return data;
    }
}