import { Button, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import { GetAgedReceivablesReportRequestDto } from 'Api/Features/Reports/Dtos/AgedReceivables/GetAgedReceivablesReportRequestDto';
import Icon from 'Components/icons/icon';
import { ListSectionHeader } from 'Components/list-section-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import TableActionsButtons from 'Components/table-action-buttons/table-actions-buttons';
import { TableFilters } from 'Components/table-filters';
import { TdWithImage } from 'Components/td-with-image';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { ALL_LOCATIONS, MOMENT_PARSING_FORMAT } from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import moment from 'moment';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AgedReceivablesReportService } from 'Services/AgedReceivablesReportService';
import { FilterStore } from 'Stores';
import { showFile } from 'Utils';
import { cleanVal, currencyFormatter } from 'Utils/NumberUtils';
import { displayFormatedDate } from 'Utils/TimeUtils';
import { images, theme } from 'variant';
import './index.less';

const { usersHeader } = images;

const renderCurrency = (val?: number): string => currencyFormatter(cleanVal.number(val));

const renderCompany = (companyInfos: ExctractCompaniesList): ReactNode => (
    <TdWithImage defaultImg={<Icon iconName="Location" />} imgSrc={companyInfos.imageUrl}>
        {companyInfos.name}
    </TdWithImage>
);

const renderDate = (date: string): string => displayFormatedDate(date, MOMENT_PARSING_FORMAT);

const breadcrumbs: BreadcrumbSegment[] = [
    {
        nameKey: 'reports',
        path: 'reports',
    },
    {
        nameKey: 'Reports.aged_receivables',
        path: 'aged-receivables',
    },
];

interface AgedAmounts {
    agedAmountsCurrent: number;
    agedAmounts30Days: number;
    agedAmounts60Days: number;
    agedAmounts90Days: number;
    agedAmounts120Days: number;
    agedAmountsOlder: number;
}

interface ExctractCompaniesList extends AgedAmounts {
    key: number;
    id: string;
    name: string;
    imageUrl: string;
}

interface ExctractInvoicesList extends AgedAmounts {
    id: string;
    campusName: string;
    invoiceDate: string;
    dueDate: string;
    invoiceNumber: string;
}

interface AgedReceivablesMergedData {
    companies: ExctractCompaniesList[];
    invoices: ExctractInvoicesList[][];
    totalAgedAmounts: AgedAmounts;
}

const advancedFilters: AdvancedFilter[] = [];

const AgedReceivables: FunctionComponent = observer(() => {
    const agedReceivablesReportService = useService(AgedReceivablesReportService);
    const { locationStore, globalLoadingStore, toastStore, userPermissionsStore, requestStore } = useStores();
    const [agedReceivablesMergedData, setAgedReceivablesMergedData] = useState<
        AgedReceivablesMergedData
    >();
    const [agedReceivablesRequest, setAgedReceivablesRequest] = useState<
        GetAgedReceivablesReportRequestDto
    >();
    const refRowKeysToExpand = useRef<string[]>([]);
    const [rowKeysToExpand, setRowKeysToExpand] = useState<string[] | undefined>(undefined);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { t } = useTranslation();

    const onExportClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            let document;
            if (agedReceivablesRequest) {
                const campusName =
                    locationStore?.locations.find(
                        (x) => x.id === filterStoreRef.current.currentLocationId
                    )?.name || ALL_LOCATIONS;

                document = await agedReceivablesReportService.exportAgedReceivablesReport(
                    agedReceivablesRequest
                );

                showFile(
                    document,
                    `${t('Reports.aged_receivables')} - ${moment(
                        agedReceivablesRequest.date
                    ).format(MOMENT_PARSING_FORMAT)} - ${campusName}`
                );
            }
        } catch (e) {
            if (!e.treated) {
                if (e.treated) {
                    toastStore.genericError();
                }
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const tableActionButtons = [
        {
            iconName: 'UnExpandAll',
            toolTip: 'unexpand_all',
            onClick: (): void => setRowKeysToExpand([]),
        },
        {
            iconName: 'ExpandAll',
            toolTip: 'expand_all',
            onClick: (): void => setRowKeysToExpand(refRowKeysToExpand.current),
        },
        {
            iconName: 'Download',
            toolTip: 'export_table',
            onClick: (): Promise<void> => onExportClick(),
        },
    ];

    const companiesColumns: ColumnType<ExctractCompaniesList>[] = [
        {
            title: t('location'),
            render: (campusInfos): ReactNode => ({
                children: renderCompany(campusInfos),
                props: { colSpan: 4 },
            }),
        },
        {
            title: t('Reports.issued'),
            render: (): ReactNode => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('Reports.due_date'),
            render: (): ReactNode => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('invoice'),
            render: (): ReactNode => ({
                props: { colSpan: 0 },
            }),
        },
        {
            title: t('current'),
            dataIndex: 'agedAmountsCurrent',
            render: renderCurrency,
        },
        {
            title: t('Reports.1_30_days'),
            dataIndex: 'agedAmounts30Days',
            render: renderCurrency,
        },
        {
            title: t('Reports.31_60_days'),
            dataIndex: 'agedAmounts60Days',
            render: renderCurrency,
        },
        {
            title: t('Reports.61_90_days'),
            dataIndex: 'agedAmounts90Days',
            render: renderCurrency,
        },
        {
            title: t('Reports.91_120_days'),
            dataIndex: 'agedAmounts120Days',
            render: renderCurrency,
        },
        {
            title: t('older'),
            dataIndex: 'agedAmountsOlder',
            render: renderCurrency,
        },
    ];

    const expandedRowRender = (record: ExctractCompaniesList): ReactNode => {
        const columns: ColumnType<ExctractInvoicesList>[] = [
            {
                title: t('empty'),
                render: (): string => '',
                key: 'empty',
            },
            {
                dataIndex: 'campusName',
            },
            {
                dataIndex: 'invoiceDate',
                render: renderDate,
            },
            {
                dataIndex: 'dueDate',
                render: renderDate,
            },
            {
                dataIndex: 'invoiceNumber',
            },
            {
                dataIndex: 'agedAmountsCurrent',
                render: renderCurrency,
            },
            {
                dataIndex: 'agedAmounts30Days',
                render: renderCurrency,
            },
            {
                dataIndex: 'agedAmounts60Days',
                render: renderCurrency,
            },
            {
                dataIndex: 'agedAmounts90Days',
                render: renderCurrency,
            },
            {
                dataIndex: 'agedAmounts120Days',
                render: renderCurrency,
            },
            {
                dataIndex: 'agedAmountsOlder',
                render: renderCurrency,
            },
        ];

        return (
            <Table
                showHeader={false}
                columns={columns}
                dataSource={agedReceivablesMergedData?.invoices[record.key]}
                rowKey={(record): string => `${record.id}`}
                pagination={false}
            />
        );
    };

    const renderTableFooter = (): ReactNode => (
        <>
            <div>{t('total')}</div>
            <div>
                {renderCurrency(agedReceivablesMergedData?.totalAgedAmounts.agedAmountsCurrent)}
            </div>
            <div>
                {renderCurrency(agedReceivablesMergedData?.totalAgedAmounts.agedAmounts30Days)}
            </div>
            <div>
                {renderCurrency(agedReceivablesMergedData?.totalAgedAmounts.agedAmounts60Days)}
            </div>
            <div>
                {renderCurrency(agedReceivablesMergedData?.totalAgedAmounts.agedAmounts90Days)}
            </div>
            <div>
                {renderCurrency(agedReceivablesMergedData?.totalAgedAmounts.agedAmounts120Days)}
            </div>
            <div>
                {renderCurrency(agedReceivablesMergedData?.totalAgedAmounts.agedAmountsOlder)}
            </div>
        </>
    );

    const fetchAgedReceivables = useCallback(
        async (params: { date?: string; campusIds: string[]}) => {
            globalLoadingStore.addLoading();

            try {
                const agedReceivablesResponse =
                    await agedReceivablesReportService.getAgedReceivablesReport({
                        date: params.date,
                        campusIds: params.campusIds,
                    });

                const nonNullCompanies = agedReceivablesResponse?.customers
                    ? agedReceivablesResponse.customers
                          .filter((company) => company !== null)
                          .map((company) => company!)
                    : [];

                const nonNullTotalAgedAmounts = {
                    agedAmountsCurrent: cleanVal.number(
                        agedReceivablesResponse?.agedAmounts?.current
                    ),
                    agedAmounts30Days: cleanVal.number(
                        agedReceivablesResponse?.agedAmounts?.thirtyDays
                    ),
                    agedAmounts60Days: cleanVal.number(
                        agedReceivablesResponse?.agedAmounts?.sixtyDays
                    ),
                    agedAmounts90Days: cleanVal.number(
                        agedReceivablesResponse?.agedAmounts?.ninetyDays
                    ),
                    agedAmounts120Days: cleanVal.number(
                        agedReceivablesResponse?.agedAmounts?.oneHundredTwentyDays
                    ),
                    agedAmountsOlder: cleanVal.number(agedReceivablesResponse?.agedAmounts?.older),
                };

                refRowKeysToExpand.current = [];

                const exctractInvoicesList: ExctractInvoicesList[][] = [];
                const exctractCompaniesList: ExctractCompaniesList[] | [] = nonNullCompanies.map(
                    (company, i) => {
                        exctractInvoicesList[i] = company.invoices
                            ? company.invoices
                                  .filter((invoice) => invoice !== null)
                                  .map((invoice) => ({
                                      id: cleanVal.string(invoice?.id),
                                      campusName: cleanVal.string(invoice?.campusName),
                                      invoiceDate: cleanVal.string(invoice?.invoiceDate),
                                      dueDate: cleanVal.string(invoice?.dueDate),
                                      invoiceNumber: cleanVal.string(invoice?.number),
                                      agedAmountsCurrent: cleanVal.number(
                                          invoice?.agedAmounts?.current
                                      ),
                                      agedAmounts30Days: cleanVal.number(
                                          invoice?.agedAmounts?.thirtyDays
                                      ),
                                      agedAmounts60Days: cleanVal.number(
                                          invoice?.agedAmounts?.sixtyDays
                                      ),
                                      agedAmounts90Days: cleanVal.number(
                                          invoice?.agedAmounts?.ninetyDays
                                      ),
                                      agedAmounts120Days: cleanVal.number(
                                          invoice?.agedAmounts?.oneHundredTwentyDays
                                      ),
                                      agedAmountsOlder: cleanVal.number(
                                          invoice?.agedAmounts?.older
                                      ),
                                  }))
                            : [];

                        refRowKeysToExpand.current.push(cleanVal.string(company.id));

                        return {
                            key: i,
                            id: cleanVal.string(company.id),
                            name: cleanVal.string(company.name),
                            imageUrl: cleanVal.string(company.imageUrl),
                            agedAmountsCurrent: cleanVal.number(company.agedAmounts?.current),
                            agedAmounts30Days: cleanVal.number(company.agedAmounts?.thirtyDays),
                            agedAmounts60Days: cleanVal.number(company.agedAmounts?.sixtyDays),
                            agedAmounts90Days: cleanVal.number(company.agedAmounts?.ninetyDays),
                            agedAmounts120Days: cleanVal.number(
                                company.agedAmounts?.oneHundredTwentyDays
                            ),
                            agedAmountsOlder: cleanVal.number(company.agedAmounts?.older),
                        };
                    }
                );

                setAgedReceivablesMergedData({
                    companies: exctractCompaniesList,
                    invoices: exctractInvoicesList,
                    totalAgedAmounts: nonNullTotalAgedAmounts,
                });
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [agedReceivablesReportService, globalLoadingStore]
    );

    useEffect(() => {
        const filterStore = filterStoreRef.current;

        filterStore.date = moment().format(MOMENT_PARSING_FORMAT);
    }, []);

    useEffect(() => {
        const filterStore = filterStoreRef.current;

        const disposer = autorun(() => {
            const date = moment(filterStore.date).format(MOMENT_PARSING_FORMAT);
            const campusId =
                filterStore.currentLocationId === 'all'
                    ? requestStore.getReportRequestAvailableLocationIdsForUser()
                    : [filterStore.currentLocationId];

            fetchAgedReceivables({
                date: date,
                campusIds: campusId,
            });

            setAgedReceivablesRequest({
                date: date,
                campusIds: campusId,
            });
        });

        return (): void => {
            disposer();
        };
    }, [fetchAgedReceivables]);

    return (
        <div className="AgedReceivables">
            <ListSectionHeader
                routes={breadcrumbs}
                backgroundImageUrl={usersHeader}
                defaultImg={<Icon iconName="Calendar" fill={theme['primary-color']} />}
                title={t('Reports.aged_receivables')}
                subTitle={t('Reports.aged_receivables_overview')}
            />

            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeDate
                    includeLocations
                    availableLocations={userPermissionsStore.availableLocationsForUser}
                />

                <TableActionsButtons buttonsList={tableActionButtons} />

                <Table
                    className="expand-table table-striped-rows table-action-rows"
                    rowClassName="company-row"
                    bordered
                    columns={companiesColumns}
                    dataSource={agedReceivablesMergedData?.companies || undefined}
                    expandable={{
                        expandedRowRender: expandedRowRender,
                        // eslint-disable-next-line react/display-name
                        expandIcon: ({ expanded, onExpand, record }): ReactNode =>
                            expanded ? (
                                <Button
                                    onClick={(e): void => {
                                        setRowKeysToExpand(undefined);
                                        onExpand(record, e);
                                    }}
                                >
                                    <Icon iconName="AngleTop" />
                                </Button>
                            ) : (
                                <Button
                                    onClick={(e): void => {
                                        setRowKeysToExpand(undefined);
                                        onExpand(record, e);
                                    }}
                                >
                                    <Icon iconName="AngleBottom" />
                                </Button>
                            ),
                        expandedRowKeys: rowKeysToExpand,
                    }}
                    rowKey={(record): string => record.id}
                    footer={renderTableFooter}
                    pagination={false}
                />
            </Content>
        </div>
    );
});

export default AgedReceivables;
