import { Skeleton, Typography } from 'antd';
import { ActivityBoardNotification } from 'Components/activity-board-notification';
import { ActivityBoardNotificationProps } from 'Components/activity-board-notification/activity-board-notification';
import React, { FunctionComponent } from 'react';
import { ImageWithPlaceholder } from '../image-with-placeholder';
import { ImageWithPlaceholderProps } from '../image-with-placeholder/image-with-placeholder';
import './index.less';

const { Title } = Typography;

interface ListSectionHeaderProps extends ImageWithPlaceholderProps {
    title: string;
    subTitle: string;
    backgroundImageUrl: string;
    loading?: boolean;
    notifications?: ActivityBoardNotificationProps;
}

const ActivityBoardHeader: FunctionComponent<ListSectionHeaderProps> = ({
    title,
    subTitle,
    backgroundImageUrl,
    loading,
    notifications,
    ...imageProps
}) => {
    return (
        <div
            className="ActivityBoardHeader"
            style={{
                backgroundImage: `url(${backgroundImageUrl})`,
            }}
        >
            <div className="header-infos">
                <Skeleton loading={loading} active avatar>
                    <ImageWithPlaceholder className="header-icon" {...imageProps} />
                    <div className="header-info">
                        <div className="header-subtitle">{subTitle}</div>
                        <Title className="header-title">{title}</Title>
                    </div>
                </Skeleton>
            </div>

            <ActivityBoardNotification
                newNotifications={notifications?.newNotifications}
                notificationsList={notifications?.notificationsList}
                updateNotificationsStatus={notifications?.updateNotificationsStatus}
            />
        </div>
    );
};

export default ActivityBoardHeader;
