import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    Row,
    Select,
    Skeleton,
    Tabs,
    TimePicker,
    Typography,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { StoredFileDto } from 'Api/Features/General/Dtos/StoredFileDto';
import { ActivityLogItemProps } from 'Components/activity-log-item/activity-log-item';
import ActivityLogList from 'Components/activity-log-list/activity-log-list';
import Add from 'Components/icons/Add';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useStores } from 'Hooks';
import { AddActivityType } from 'Models/AddActivityType/AddActivityType';
import { TIME_PICKER_FORMAT } from 'Models/Constants';
import { LightCampusDetailsInfo } from 'Models/Location/LightCampusInfo';
import { Callbacks } from 'rc-field-form/lib/interface';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileList from 'Components/file-list/file-list';
import { PencilBorder } from 'Components/icons';
import { theme } from 'variant';
import './activity-log-tabs.less';

export type ActivityLogUpcomingItem = {
    id: string;
    onEdit?: (value: string) => void;
    onDelete?: (value: string) => void;
} & ActivityLogItemProps;

export type ActivityLogItem = { id: string } & ActivityLogItemProps;

interface ActivityLogTabsProps {
    onFinish?: Callbacks['onFinish'];
    form: FormInstance;
    selectErrors?: Map<string, string[]>;
    selectdefaultValue?: any;
    selectOnChange?: any;
    selectOptions?: any;
    displayDatePicker?: boolean;
    datePickerErrors?: Map<string, string[]>;
    noteErrors?: Map<string, string[]>;
    submitButtonLabel?: string;
    upcomingActivities?: (ActivityLogUpcomingItem | null)[];
    pastActivities?: (ActivityLogUpcomingItem | null)[];
    locationErrors?: Map<string, string[]>;
    defaultSelectedLocationId?: string;
    onAddFileClick?: () => void;
    files?: StoredFileDto[];
    onFileClick?: (url?: string) => void;
    onFileDeleteClick?: (id?: string) => void;
    onFilesScrolledToBottom?: () => void;
    filesAreLoading?: boolean;
    note?: string | null;
    onEditNoteClick?: () => void;
    tabs: ActivityLogTab[];
}

const { TabPane } = Tabs;

export enum ActivityLogTab {
    notes = 1,
    activities = 2,
    files = 3,
}

const ActivityLogTabs: FunctionComponent<ActivityLogTabsProps> = ({
    onFinish,
    form,
    selectErrors,
    selectdefaultValue,
    selectOnChange,
    selectOptions,
    displayDatePicker,
    datePickerErrors,
    noteErrors,
    submitButtonLabel,
    upcomingActivities,
    pastActivities,
    locationErrors,
    defaultSelectedLocationId,
    onAddFileClick,
    files,
    onFileClick,
    onFileDeleteClick,
    onFilesScrolledToBottom,
    filesAreLoading,
    note,
    onEditNoteClick,
    tabs,
}) => {
    const { t } = useTranslation();
    const [addActivityType, setAddActivityType] = useState<AddActivityType>(selectdefaultValue);
    const [activeTab, setActiveTab] = useState<ActivityLogTab>(ActivityLogTab.activities);
    const { locationStore } = useStores();

    useEffect(() => {
        if (defaultSelectedLocationId) {
            form.setFieldsValue({
                campusId: defaultSelectedLocationId,
            });
        }
    }, [defaultSelectedLocationId, form]);

    return (
        <div className="ActivityLogTabs">
            <Tabs
                className="tab-section-container"
                onChange={(activeKey: string) =>
                    setActiveTab(parseInt(activeKey) as ActivityLogTab)
                }
            >
                {tabs.includes(ActivityLogTab.notes) && (
                    <TabPane key={1} tab={t('note')}>
                        <div className="note-container p-20 min-170">
                            <div className="note-text">{note}</div>
                            <div className="action" onClick={onEditNoteClick}>
                                <PencilBorder
                                    fill={theme['primary-color']}
                                    width={24}
                                    height={24}
                                />
                            </div>
                        </div>
                    </TabPane>
                )}

                {tabs.includes(ActivityLogTab.activities) && (
                    <TabPane tab={t('Activity.activities')} key={2}>
                        <div className="activities-container">
                            <Form scrollToFirstError layout="vertical" onFinish={onFinish} form={form}>
                                <Row>
                                    <Col span={6}>
                                        <ValidatedFormItem name="type" errors={selectErrors}>
                                            {selectOptions && (
                                                <Select
                                                    defaultValue={selectdefaultValue}
                                                    onChange={(value): void => {
                                                        setAddActivityType(value);
                                                        selectOnChange(value);
                                                    }}
                                                    style={{ width: '100%' }}
                                                >
                                                    {Object.keys(selectOptions).map((x) => (
                                                        <Select.Option key={x} value={x}>
                                                            {t(`Activity.add_activity_${x}`)}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </ValidatedFormItem>
                                    </Col>

                                    {displayDatePicker && datePickerErrors && (
                                        <>
                                            <Col span={2} className="add-activity-on-date">
                                                <Typography.Text>
                                                    {t(`Activity.add_activity_on`)}
                                                </Typography.Text>
                                            </Col>

                                            <Col span={6}>
                                                <div className="date-time-picker-wrapper">
                                                    <ValidatedFormItem
                                                        name="date"
                                                        errors={datePickerErrors}
                                                    >
                                                        <DatePicker
                                                            showTime={
                                                                addActivityType ===
                                                                AddActivityType.Reminder
                                                                    ? { format: TIME_PICKER_FORMAT }
                                                                    : false
                                                            }
                                                            use12Hours
                                                        />
                                                    </ValidatedFormItem>
                                                </div>
                                            </Col>

                                            {(addActivityType === AddActivityType.Meeting ||
                                                addActivityType === AddActivityType.Tour) && (
                                                <Col span={8}>
                                                    <div className="date-time-picker-wrapper">
                                                        <ValidatedFormItem
                                                            name="hours"
                                                            errors={datePickerErrors}
                                                        >
                                                            <TimePicker.RangePicker
                                                                format={TIME_PICKER_FORMAT}
                                                                use12Hours
                                                                style={{ width: '100%' }}
                                                            />
                                                        </ValidatedFormItem>
                                                    </div>
                                                </Col>
                                            )}
                                        </>
                                    )}
                                </Row>

                                {addActivityType === AddActivityType.Tour && (
                                    <Row>
                                        <Col span={8}>
                                            <ValidatedFormItem
                                                name="campusId"
                                                errors={locationErrors}
                                                required
                                            >
                                                <Select placeholder={'Select location'}>
                                                    {locationStore.locations?.map(
                                                        (x: LightCampusDetailsInfo) => (
                                                            <Select.Option
                                                                key={x.id!}
                                                                value={x.id!}
                                                            >
                                                                {x.name}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </ValidatedFormItem>
                                        </Col>
                                    </Row>
                                )}

                                <ValidatedFormItem
                                    name="note"
                                    errors={noteErrors}
                                    className="add-activity-note"
                                >
                                    <Input.TextArea />
                                </ValidatedFormItem>

                                <div className="add-activity-submit">
                                    <Button
                                        type="default"
                                        className="secondary negative"
                                        htmlType="submit"
                                    >
                                        {submitButtonLabel}
                                    </Button>
                                </div>
                            </Form>

                            {upcomingActivities && upcomingActivities.length > 0 && (
                                <div className="ActivityLogList--wrapper">
                                    <p>{t('Activity.upcoming')}</p>
                                    <ActivityLogList activities={upcomingActivities} />
                                </div>
                            )}

                            {pastActivities && pastActivities.length > 0 && (
                                <div className="ActivityLogList--wrapper">
                                    <p>{t('Activity.past')}</p>
                                    <ActivityLogList activities={pastActivities} />
                                </div>
                            )}
                        </div>
                    </TabPane>
                )}

                {tabs.includes(ActivityLogTab.files) && (
                    <TabPane
                        key={3}
                        tab={
                            <div className="files-tab-container">
                                <div>{t('files')}</div>
                                {onAddFileClick && (
                                    <div
                                        className={`add-icon ${
                                            activeTab === ActivityLogTab.files
                                                ? 'active'
                                                : 'inactive'
                                        }`}
                                        onClick={(): void => onAddFileClick()}
                                    >
                                        <Add />
                                    </div>
                                )}
                            </div>
                        }
                    >
                        <div className="files-container">
                            <Skeleton loading={filesAreLoading} active>
                                {files && files.length > 0 ? (
                                    <FileList
                                        files={files}
                                        onFileClick={onFileClick}
                                        onDeleteClick={onFileDeleteClick}
                                        onScrolledToBottom={onFilesScrolledToBottom}
                                    />
                                ) : (
                                    <div className="empty-files">{t('no_files')}</div>
                                )}
                            </Skeleton>
                        </div>
                    </TabPane>
                )}
            </Tabs>
        </div>
    );
};

export default ActivityLogTabs;
