import Typography from 'antd/lib/typography';
import { GetClosedLeadsReportRequestDto } from 'Api/Features/Reports/Dtos/ClosedLeads/GetClosedLeadsReportRequestDto';
import ButtonLinkWithIcon from 'Components/button-link-with-icon/button-link-with-icon';
import Icon from 'Components/icons/icon';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { MOMENT_PARSING_FORMAT } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ClosedLeadsReportService } from 'Services/ClosedLeadsReportService';
import { showFile } from 'Utils';
import { ListSectionHeader } from '../../../Components/list-section-header';
import { images, theme } from '../../../variant';
import './index.less';

const { usersHeader } = images;

const Reports: FunctionComponent = observer(() => {
    const { userPermissionsStore, requestStore, globalLoadingStore, toastStore } = useStores();
    const { t } = useTranslation();
    const closedLeadsReportService = useService(ClosedLeadsReportService);

    const handleDownloadClosedLeadsClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            const resquest: GetClosedLeadsReportRequestDto = {
                startDate: moment().subtract(90, 'day').format(),
                endDate: moment().format(),
                campusIds: userPermissionsStore.userIsAdministrator
                    ? undefined
                    : requestStore.getReportRequestAvailableLocationIdsForUser(),
            };

            const document = await closedLeadsReportService.exportClosedLeadsReport(resquest);

            showFile(
                document,
                `${t('Reports.closed_leads')} - ${moment(resquest.startDate).format(
                    MOMENT_PARSING_FORMAT
                )} to ${moment(resquest.endDate).format(MOMENT_PARSING_FORMAT)}`
            );
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <div className="Reports">
            <ListSectionHeader
                title={t('reports')}
                subTitle={t('Reports.reports_overview')}
                defaultImg={<Icon iconName="BarGraph" fill={theme['primary-color']} />}
                backgroundImageUrl={usersHeader} // TODO img ?
            />

            <div className="button-list-wrapper">
                <div>
                    <Typography.Title level={2}>{t('operation')}</Typography.Title>
                    <ButtonLinkWithIcon
                        linkTo="reports/closed-opportunities"
                        iconName="TickLined"
                        label={t('Reports.closed_opportunities')}
                    />

                    <div
                        className="download-button"
                        onClick={(): Promise<void> => handleDownloadClosedLeadsClick()}
                    >
                        <Icon iconName={'TickLined'} fill={theme['primary-color']} />
                        <div>
                            <div>
                                <Typography.Text>{t('Reports.closed_leads')}</Typography.Text>
                            </div>
                            <div>
                                <Typography.Text className="subtitle">
                                    {t('Reports.download_data_of_last_90_days')}
                                </Typography.Text>
                            </div>
                        </div>
                    </div>
                    <ButtonLinkWithIcon
                        linkTo="reports/inventory"
                        iconName="Units"
                        label={t('Reports.unit_inventory')}
                    />

                    <ButtonLinkWithIcon
                        linkTo="reports/occupancy-stats"
                        iconName="PieChart"
                        label={t('Reports.occupancy_stats')}
                    />

                    <ButtonLinkWithIcon
                        linkTo="reports/revenue-occupancy"
                        iconName="Dollar"
                        label={t('Reports.revenue_occupancy')}
                    />

                    <ButtonLinkWithIcon
                        linkTo="reports/rent-roll"
                        iconName="Ticket"
                        label={t('Reports.rent_roll')}
                    />

                    <ButtonLinkWithIcon
                        linkTo="reports/sale-activities"
                        iconName="Plan"
                        label={t('Reports.new_sale_activities')}
                    />
                </div>

                <div>
                    <Typography.Title level={2}>{t('accounting')}</Typography.Title>

                    <ButtonLinkWithIcon
                        linkTo="reports/accounts-receivable"
                        iconName="Dollar"
                        label={t('Reports.accounts_receivable')}
                    />

                    <ButtonLinkWithIcon
                        linkTo="reports/aged-receivables"
                        iconName="Calendar"
                        label={t('Reports.aged_receivables')}
                    />
                    <ButtonLinkWithIcon
                        linkTo="reports/security-deposits"
                        iconName="DollarShield"
                        label={t('Reports.security_deposits')}
                    />
                </div>
                
                <div>
                    <Typography.Title level={2}>{t('management')}</Typography.Title>

                    <ButtonLinkWithIcon
                        linkTo="reports/bookings"
                        iconName="Bookmark"
                        label={t('Reports.report_bookings')}
                    />
                </div>
            </div>
        </div>
    );
});

export default Reports;
