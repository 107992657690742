import { Col, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Content } from 'antd/lib/layout/layout';
import { CalendarEventDto } from 'Api/Features/CalendarEvents/Dtos/CalendarEventDto';
import { GetCalendarEventsRequestDto } from 'Api/Features/CalendarEvents/Dtos/GetCalendarEventsRequestDto';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { MeetingDto } from 'Api/Features/Meetings/Dtos/MeetingDto';
import { GetMessagesSortColumnDto } from 'Api/Features/Messages/Dtos/GetMessagesSortColumnDto';
import { MessageEntityTypeDto } from 'Api/Features/Messages/Dtos/MessageEntityTypeDto';
import { MessageReadStatusDto } from 'Api/Features/Messages/Dtos/MessageReadStatusDto';
import { ReminderDto } from 'Api/Features/Reminders/Dtos/ReminderDto';
import { GetManagerUserSalesActivitiesRequestDto } from 'Api/Features/SalesActivities/Dtos/GetManagerUserSalesActivitiesRequestDto';
import { SalesActivityTypeDto } from 'Api/Features/SalesActivities/Dtos/SalesActivityTypeDto';
import { TaskDto } from 'Api/Features/Tasks/Dtos/TaskDto';
import { TourDto } from 'Api/Features/Tours/Dtos/TourDto';
import ActivityBoardCalendar from 'Components/activity-board-calendar';
import ActivityBoardHeader from 'Components/activity-board-header';
import { ActivityBoardList } from 'Components/activity-board-list';
import { ActivityBoardListItems } from 'Components/activity-board-list/activity-board-list';
import { ActivityBoardNotificationProps } from 'Components/activity-board-notification/activity-board-notification';
import { Bell, Coffee, Location } from 'Components/icons';
import Icon from 'Components/icons/icon';
import { TableFilters } from 'Components/table-filters';
import { useService } from 'Hooks';
import { useStores } from 'Hooks/use-stores';
import { observer } from 'mobx-react';
import { ActivitySource } from 'Models/ActivityBoard/ActivitySource';
import { ALL_LOCATIONS, MOMENT_PARSING_FORMAT, TIME_PICKER_FORMAT } from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityBoardCalendarService } from 'Services/ActivityBoardCalendarService';
import { CommunityEventService } from 'Services/CommunityEventService';
import { MeetingService } from 'Services/MeetingService';
import { MessageService } from 'Services/MessageService';
import { SalesActivityService } from 'Services/SalesActivityService';
import { TourService } from 'Services/TourService';
import { FilterStore } from 'Stores';
import { dateMomentToString, displayFormatedDateTime } from 'Utils/TimeUtils';
import { images, theme } from 'variant';
import './index.less';

const { homeHeader } = images;

enum TimeGreeting {
    morning = 'morning',
    afternoon = 'afternoon',
    evening = 'evening',
}

enum TimeCutOff {
    morning = '12:00:00',
    afternoon = '17:00:00',
    evening = '2:00:00',
}

const advancedFilters: AdvancedFilter[] = [];

const ActivityBoard: FunctionComponent = observer(() => {
    const salesActivityService = useService(SalesActivityService);
    const meetingService = useService(MeetingService);
    const tourService = useService(TourService);
    const messageService = useService(MessageService);
    const communityEventService = useService(CommunityEventService);
    const [notificationsData, setNotificationsData] = useState<ActivityBoardNotificationProps>();
    const [updateActivitiesList, setUpdateActivitiesList] = useState<boolean>(false);
    const [activitiesList, setActivitiesList] = useState<ActivityBoardListItems[]>();
    const [pastActivitiesList, setPastActivitiesList] = useState<ActivityBoardListItems[]>();
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { locationStore } = useStores();
    const activityBoardCalendarService = useService(ActivityBoardCalendarService);
    const { globalLoadingStore, userPermissionsStore, confirmationModalStore, toastStore } =
        useStores();

    const [calendarEvents, setCalendarEvents] = useState<CalendarEventDto[]>();
    const [currentTimeRange, setCurrentTimeRange] = useState<string[]>([
        moment().startOf('week').format(),
        moment().startOf('week').add(1, 'week').format(),
    ]);
    const [canReorderActivites, setCanReorderActivites] = useState(true);
    const { t } = useTranslation();
    const accountId = userPermissionsStore.userInfo?.id;
    const isAdministrator = userPermissionsStore.userIsAdministrator;

    const fetchNotifications = useCallback(
        async (forceUpdate: boolean) => {
            try {
                const request = {
                    forManagers: true,
                    sortColumn: GetMessagesSortColumnDto.Date,
                    sortDirection: SortDirection.Descending,
                    startDate: dateMomentToString(moment().subtract(7, 'days')),
                    pageSize: 7,
                };
                let unreadCount = 0;

                if (accountId) {
                    const response = await messageService.getAccountMessages(
                        accountId,
                        request,
                        forceUpdate
                    );

                    const notifications = response?.items?.map((notification) => {
                        let link = undefined;

                        if (notification?.status === MessageReadStatusDto.Unread) unreadCount++;

                        switch (notification?.entityType) {
                            case MessageEntityTypeDto.Opportunity:
                                link = {
                                    label: t('Opportunity.view_opportunity'),
                                    url: `/opportunities/${notification.entityId}/dashboard`,
                                };
                                break;
                            case MessageEntityTypeDto.Lead:
                                link = {
                                    label: t('Lead.view_lead'),
                                    url: `/leads/${notification.entityId}`,
                                };
                                break;
                            case MessageEntityTypeDto.Subscription:
                                link = {
                                    label: t('Subscription.view_subscription'),
                                    url: `/subscriptions/${notification.entityId}`,
                                };
                                break;
                        }

                        return {
                            titleImg: { imgSrc: notification?.imageUrl },
                            title: notification?.formattedTitle || '',
                            date: displayFormatedDateTime(notification?.date),
                            companyName: notification?.properties?.membershipName || '',
                            link: link,
                            isNew: notification?.status === MessageReadStatusDto.Unread,
                        };
                    });

                    setNotificationsData({
                        notificationsList: notifications,
                        newNotifications: unreadCount,
                    });
                }
            } catch (e) {
                toastStore.genericError();
            }
        },
        [isAdministrator, messageService, toastStore, accountId, t]
    );

    const updateNotificationsStatus = useCallback(
        async (isVisible) => {
            if (!isVisible) {
                try {
                    await messageService.setAllMessageStatuses({
                        status: MessageReadStatusDto.Read,
                        forManagers: true,
                    });
                } catch (e) {
                    toastStore.genericError();
                } finally {
                    fetchNotifications(true);
                }
            }
        },
        [isAdministrator, messageService, toastStore, fetchNotifications]
    );

    const fetchCalendarEvents = useCallback(
        async (startDate: string, endDate: string): Promise<void> => {
            globalLoadingStore.addLoading();

            try {
                if (userPermissionsStore.userInfo) {
                    const response =
                        await activityBoardCalendarService.getManagerUserCalendarEvents(
                            userPermissionsStore.userInfo?.id ?? null,
                            {
                                startDate: startDate ?? moment().startOf('week').format(),
                                endDate:
                                    endDate ?? moment().startOf('week').add(1, 'week').format(),
                                sources:
                                    filterStoreRef.current.currentActivitysource ===
                                    ActivitySource.AllSources
                                        ? null
                                        : [filterStoreRef.current.currentActivitysource],
                                campusIds:
                                    filterStoreRef.current.currentLocationId === ALL_LOCATIONS
                                        ? null
                                        : [filterStoreRef.current.currentLocationId],
                            } as GetCalendarEventsRequestDto
                        );
                    if (response?.items)
                        setCalendarEvents(
                            response.items.filter((event) => event !== null).map((event) => event!)
                        );
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [globalLoadingStore, activityBoardCalendarService, userPermissionsStore.userInfo]
    );

    const onNavigateDateRange = (range: any): void => {
        const start = moment(range[0]).format();
        const end = moment(range[range.length - 1]).format();
        setCurrentTimeRange([start, end]);
    };

    const getTimeGreeting = (): TimeGreeting => {
        const currentTime = moment();
        const morningCutOff = moment(TimeCutOff.morning, TIME_PICKER_FORMAT);
        const afterNoonCutOff = moment(TimeCutOff.afternoon, TIME_PICKER_FORMAT);
        const eveningCutOff = moment(TimeCutOff.evening, TIME_PICKER_FORMAT);

        if (currentTime.isBetween(eveningCutOff, morningCutOff)) return TimeGreeting.morning;
        if (currentTime.isBetween(morningCutOff, afterNoonCutOff)) return TimeGreeting.afternoon;

        return TimeGreeting.evening;
    };

    const cancelMeeting = async (id: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Coffee />,
                title: t(`Lead.delete_meeting`),
                message: t(`Lead.delete_meeting_confirm_message`),
                positiveText: t(`Lead.delete_meeting`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        //call delete
        await meetingService.cancelMeeting(id);
        toastStore.toast({
            type: 'success',
            messageKey: `Lead.meeting_delete_success`,
        });
        fetchCalendarEvents(currentTimeRange[0], currentTimeRange[1]);
    };

    const cancelTour = async (id: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Location />,
                title: t(`Lead.delete_tour`),
                message: t(`Lead.delete_tour_confirm_message`),
                positiveText: t(`Lead.delete_tour`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        //call delete
        await tourService.cancelTour(id);
        toastStore.toast({
            type: 'success',
            messageKey: `Lead.tour_delete_success`,
        });
        fetchCalendarEvents(currentTimeRange[0], currentTimeRange[1]);
    };

    const cancelCommunityEvent = async (id: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="CommunityEvent" />,
                title: t(`model_delete_confirm_title`, { param1: 'event' }),
                message: t(`action_cannot_be_undone`),
                positiveText: t('model_confirm_positive_delete', { param1: 'Event' }),
                negativeText: t('model_confirm_negative_delete', { param1: 'Event' }),
            }))
        )
            return;
        //call delete
        try {
            globalLoadingStore.addLoading();
            await communityEventService.deleteCommunityEvent(id);
            toastStore.toast({
                type: 'success',
                messageKey: t('model_delete_success', { param1: 'Event' }),
            });
            fetchCalendarEvents(currentTimeRange[0], currentTimeRange[1]);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const saveOrder = useCallback(
        async (date: string, ids: string[]): Promise<void> => {
            try {
                const userId = userPermissionsStore.userInfo?.id;
                const request = {
                    date: date,
                    salesActivityIds: ids,
                };

                if (ids && userId) {
                    await salesActivityService.updateManagerUserSalesActivityOrderings(
                        userId,
                        request
                    );

                    setUpdateActivitiesList(true);
                }
            } catch (e) {
                toastStore.genericError();
            }
        },
        [salesActivityService, toastStore, userPermissionsStore.userInfo]
    );

    const updateActivityStatus = useCallback(
        async (e: CheckboxChangeEvent, id?: string): Promise<void> => {
            try {
                const userId = userPermissionsStore.userInfo?.id;

                if (id && userId) {
                    await salesActivityService.updateManagerUserSalesActivityStatus(id, {
                        managerUserId: userId,
                        isCompleted: e.target?.checked,
                    });

                    setUpdateActivitiesList(true);
                }
            } catch (e) {
                toastStore.genericError();
            }
        },
        [salesActivityService, toastStore, userPermissionsStore.userInfo]
    );

    const fetchActivities = useCallback(async (): Promise<void> => {
        try {
            const userId = userPermissionsStore.userInfo?.id;

            if (userId) {
                const dateInfo = {
                    current: dateMomentToString(moment()),
                    before: dateMomentToString(moment().subtract(7, 'days')),
                    isBeforeToday: (date: string): boolean => {
                        const day = moment(moment(date).format(MOMENT_PARSING_FORMAT));
                        const today = moment(moment().format(MOMENT_PARSING_FORMAT));

                        return day.isBefore(today);
                    },
                    isSameDay: (date1: string, date2: string): boolean => {
                        const day1 = moment(moment(date1).format(MOMENT_PARSING_FORMAT));
                        const day2 = moment(moment(date2).format(MOMENT_PARSING_FORMAT));

                        return day1.isSame(day2);
                    },
                };

                const response = await salesActivityService.getManagerUserSalesActivities(userId, {
                    startDate: dateInfo.before,
                    endDate: null,
                    sources:
                        filterStoreRef.current.currentActivitysource === ActivitySource.AllSources
                            ? null
                            : filterStoreRef.current.currentActivitysource ===
                              ActivitySource.CommunityEvent
                            ? null
                            : [filterStoreRef.current.currentActivitysource],
                    campusIds:
                        filterStoreRef.current.currentLocationId === ALL_LOCATIONS
                            ? null
                            : [filterStoreRef.current.currentLocationId],
                    types: [SalesActivityTypeDto.Task, SalesActivityTypeDto.Reminder],
                } as GetManagerUserSalesActivitiesRequestDto);

                const items = response?.items;

                const activities: {
                    pastActivities: ActivityBoardListItems[];
                    activities: ActivityBoardListItems[];
                } = {
                    pastActivities: [],
                    activities: [],
                };

                if (items) {
                    items.forEach((item) => {
                        let tempItem: any = {
                            tagLabel: item?.activity?.type,
                            checkbox: { checked: item?.status?.isCompleted },
                        };
                        let date = '';
                        let labelAndLink;

                        const nullOrType = (val: string | null | undefined): string =>
                            val === null || val === undefined ? tempItem.tagLabel : val;

                        const activityLabelAndLink = (
                            activity:
                                | TaskDto
                                | MeetingDto
                                | ReminderDto
                                | TourDto
                                | null
                                | undefined
                        ):
                            | {
                                  label: (string | undefined | null)[];
                                  link: { label: string; url: string };
                              }
                            | undefined => {
                            if (activity?.lead !== null && activity?.lead?.firstName)
                                return {
                                    label: [
                                        `${activity.lead.firstName}${
                                            activity.lead.lastName
                                                ? ' ' + activity.lead.lastName
                                                : ''
                                        }`,
                                    ],
                                    link: {
                                        label: t('Lead.view_lead'),
                                        url: `/leads/${activity.lead.id}`,
                                    },
                                };

                            if (activity?.opportunity !== null && activity?.opportunity)
                                return {
                                    label: [
                                        activity.opportunity?.membership?.name,
                                        'campus' in activity.opportunity
                                            ? activity.opportunity?.campus?.name
                                            : undefined,
                                    ],
                                    link: {
                                        label: t('Opportunity.view_opportunity'),
                                        url: `/opportunities/${activity.opportunity.id}/dashboard`,
                                    },
                                };

                            if (
                                activity &&
                                'subscription' in activity &&
                                activity.subscription !== null
                            )
                                return {
                                    label: [
                                        activity.subscription?.membership?.name,
                                        activity.subscription?.campus?.name,
                                    ],
                                    link: {
                                        label: t('Subscription.view_subscription'),
                                        url: `/locations/${activity?.subscription?.campus?.id}/subscriptions/${activity?.subscription?.id}`,
                                    },
                                };

                            return undefined;
                        };

                        switch (item?.activity?.type) {
                            case SalesActivityTypeDto.Task:
                                date = `${item.activity.task?.date}`;

                                labelAndLink = activityLabelAndLink(item.activity.task);

                                tempItem = {
                                    ...tempItem,
                                    id: item.activity.task?.id,
                                    title: nullOrType(item.activity.task?.note),
                                    label: labelAndLink?.label,
                                    link: {
                                        label: labelAndLink?.link.label,
                                        url: labelAndLink?.link.url,
                                    },
                                    checkbox: {
                                        ...tempItem.checkbox,
                                        onCheck: (e: CheckboxChangeEvent): Promise<void> =>
                                            updateActivityStatus(e, item.activity?.task?.id),
                                    },
                                };
                                break;
                            case SalesActivityTypeDto.Meeting:
                                date = `${item.activity.meeting?.startTime}`;

                                labelAndLink = activityLabelAndLink(item.activity.meeting);

                                tempItem = {
                                    ...tempItem,
                                    id: item.activity.meeting?.id,
                                    title: nullOrType(item.activity.meeting?.note),
                                    label: labelAndLink?.label,
                                    link: {
                                        label: labelAndLink?.link.label,
                                        url: labelAndLink?.link.url,
                                    },
                                    checkbox: {
                                        ...tempItem.checkbox,
                                        onCheck: (e: CheckboxChangeEvent): Promise<void> =>
                                            updateActivityStatus(e, item.activity?.meeting?.id),
                                    },
                                };
                                break;
                            case SalesActivityTypeDto.Reminder:
                                date = `${item.activity.reminder?.date}`;

                                labelAndLink = activityLabelAndLink(item.activity.reminder);

                                tempItem = {
                                    ...tempItem,
                                    id: item.activity.reminder?.id,
                                    title: nullOrType(item.activity.reminder?.note),
                                    label: labelAndLink?.label,
                                    link: {
                                        label: labelAndLink?.link.label,
                                        url: labelAndLink?.link.url,
                                    },
                                    checkbox: {
                                        ...tempItem.checkbox,
                                        onCheck: (e: CheckboxChangeEvent): Promise<void> =>
                                            updateActivityStatus(e, item.activity?.reminder?.id),
                                    },
                                };
                                break;
                            case SalesActivityTypeDto.Tour:
                                date = `${item.activity.tour?.startTime}`;
                                labelAndLink = activityLabelAndLink(item.activity.tour);

                                tempItem = {
                                    ...tempItem,
                                    id: item.activity.tour?.id,
                                    title: nullOrType(item.activity.tour?.note),
                                    label: labelAndLink?.label,
                                    link: {
                                        label: labelAndLink?.link.label,
                                        url: labelAndLink?.link.url,
                                    },
                                    checkbox: {
                                        ...tempItem.checkbox,
                                        onCheck: (e: CheckboxChangeEvent): Promise<void> =>
                                            updateActivityStatus(e, item.activity?.tour?.id),
                                    },
                                };
                                break;
                        }

                        const activitiesKey = dateInfo.isBeforeToday(date)
                            ? 'pastActivities'
                            : 'activities';

                        if (activities[activitiesKey].length > 0) {
                            if (
                                dateInfo.isSameDay(
                                    activities[activitiesKey][activities[activitiesKey].length - 1]
                                        ?.date,
                                    date
                                )
                            ) {
                                activities[activitiesKey][
                                    activities[activitiesKey].length - 1
                                ].items.push(tempItem);
                            } else {
                                activities[activitiesKey].push({
                                    date: date,
                                    items: [tempItem],
                                });
                            }
                        } else {
                            activities[activitiesKey].push({
                                date: date,
                                items: [tempItem],
                            });
                        }
                    });

                    setPastActivitiesList(
                        activities['pastActivities'].length < 1
                            ? undefined
                            : activities['pastActivities']
                    );

                    setActivitiesList(
                        activities['activities'].length < 1 ? undefined : activities['activities']
                    );
                }
            }
        } catch (e) {
            toastStore.genericError();
        }
    }, [toastStore, salesActivityService, t, updateActivityStatus, userPermissionsStore.userInfo]);

    useEffect(() => {
        fetchNotifications(false);
    }, [fetchNotifications]);

    useEffect(() => {
        if (updateActivitiesList) {
            fetchActivities();
            setUpdateActivitiesList(false);
        }
    }, [updateActivitiesList, fetchActivities]);

    useEffect(() => {
        fetchCalendarEvents(currentTimeRange[0], currentTimeRange[1]);
    }, [currentTimeRange, fetchCalendarEvents, userPermissionsStore.userInfo]);

    useEffect(() => {
        fetchActivities();
    }, [fetchActivities, userPermissionsStore.userInfo]);

    useEffect(() => {
        if (
            filterStoreRef.current.currentLocationId !== ALL_LOCATIONS ||
            filterStoreRef.current.currentActivitysource !== ActivitySource.AllSources
        ) {
            setCanReorderActivites(false);
        } else {
            setCanReorderActivites(true);
        }
    }, [filterStoreRef.current.currentActivitysource, filterStoreRef.current.currentLocationId]);

    return (
        <div className="ActivityBoard">
            <ActivityBoardHeader
                title={t('ActivityBoard.activity_board_header_subtitle')}
                subTitle={t('ActivityBoard.greeting_user', {
                    param1: `Good ${getTimeGreeting()}`,
                    param2: userPermissionsStore.userInfo?.firstName,
                })}
                defaultImg={<Bell fill={theme['primary-color']} />}
                imgSrc={userPermissionsStore.userInfo?.imageUrl}
                backgroundImageUrl={homeHeader}
                notifications={{
                    ...notificationsData,
                    updateNotificationsStatus: updateNotificationsStatus,
                }}
            />

            <div className="filter-container">
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeLocations
                    availableLocations={locationStore.locations}
                    includeActivitySource
                />
            </div>

            <Content>
                <Row>
                    <Col className="activity-board-calendar-container" span={16}>
                        <ActivityBoardCalendar
                            calendarEvents={calendarEvents}
                            onNavigateDateRange={(range: any): void => onNavigateDateRange(range)}
                            onCancelMeeting={cancelMeeting}
                            onCancelTour={cancelTour}
                            onCancelCommunityEvent={cancelCommunityEvent}
                        />
                    </Col>
                    <Col className="activity-board-list-container" span={8}>
                        <ActivityBoardList
                            pastItems={pastActivitiesList}
                            items={activitiesList}
                            saveOrder={saveOrder}
                            canReorder={canReorderActivites}
                        />
                    </Col>
                </Row>
            </Content>
        </div>
    );
});

export default ActivityBoard;
