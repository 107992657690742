import React, { FunctionComponent, useEffect } from 'react';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import BaseModal from 'Components/base-modal/base-modal';
import { Dollar } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useService, useStores } from 'Hooks';
import { useTranslation } from 'react-i18next';
import { RevenueOccupancyTargetsService } from 'Services/RevenueOccupancyTargetsService';
import { RevenueOccupancyTargetMonthDto } from 'Api/Features/RevenueOccupancyTargets/Dtos/RevenueOccupancyTargetMonthDto';
import moment from 'moment';
import { BILLION, DISPLAY_MONTH_FORMAT } from 'Models/Constants';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { UpdateRevenueOccupancyTargetsRequestDto } from 'Api/Features/RevenueOccupancyTargets/Dtos/UpdateRevenueOccupancyTargetsRequestDto';
import { UpdateRevenueOccupancyTargetsRequestMonthDto } from 'Api/Features/RevenueOccupancyTargets/Dtos/UpdateRevenueOccupancyTargetsRequestMonthDto';
import './set-targets-modal.less';

const { Title } = Typography;

const formGutter: [Gutter, Gutter] = [40, 0];

interface SetTargetsModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    campusId: string;
    targets?: RevenueOccupancyTargetMonthDto[];
    year: number;
}

const SetTargetsModal: FunctionComponent<SetTargetsModalProps> = ({
    visible,
    onComplete,
    campusId,
    targets,
    year
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const revenueOccupancyTargetsService = useService(RevenueOccupancyTargetsService);
    const [form] = Form.useForm();
    //#endregion
    
    //#region Effects
    useEffect(() => {
        if(targets) {
            const values = targets?.map((target, i) => ({
                [`${i}.revenue`]: target.revenue,
                [`${i}.occupancy`]: target.occupancy,
            }))

            form.setFieldsValue(
                values.reduce((accumulated, current) => ({ ...accumulated, ...current }))
            );
        }
    }, [targets, form])
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Dollar />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`confirm_positive`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const months: UpdateRevenueOccupancyTargetsRequestMonthDto[] = [];

        //create month object for whole year
        for (let i = 0; i <= 11; i++) {
            months.push({
                occupancy: formValues[`${i}.occupancy`] === "" ? 0 : formValues[`${i}.occupancy`],
                revenue: formValues[`${i}.revenue`] === "" ? 0 : formValues[`${i}.revenue`],
                period: moment(`${year}-${i + 1}-01`).format(),
            } as UpdateRevenueOccupancyTargetsRequestMonthDto);
        }
        
        const request: UpdateRevenueOccupancyTargetsRequestDto = {
            months
        };

        try {
            globalLoadingStore.addLoading();

            await revenueOccupancyTargetsService.updateRevenueOccupancyTargets(campusId, request)

            toastStore.toast({
                type: 'success',
                messageKey: t('RevenueOccupancy.targets_edit_success'),
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('RevenueOccupancy.edit_targets')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="SetTargetsModal">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <div className="year">
                        {year}
                    </div>
                    {targets?.map((target, i) => (
                        <>
                            <Title level={4}>
                                {moment.utc(target.period).format(DISPLAY_MONTH_FORMAT)}
                            </Title>
                            <Row gutter={formGutter}>
                                <Col span={12}>
                                    <ValidatedFormItem
                                        name={`${i}.revenue`}
                                        label={t('revenue')}
                                        required
                                    >
                                        <NumberFormat
                                            allowNegative={false}
                                            customInput={(props: any) => (
                                                <Input addonBefore={'$'} {...props} />
                                            )}
                                            isAllowed={(value: NumberFormatValues): boolean => 
                                                value.floatValue === undefined ||
                                                (value.floatValue !== undefined &&
                                                value.floatValue <= BILLION)
                                            }
                                        />
                                    </ValidatedFormItem>
                                </Col>
                                <Col span={12}>
                                    <ValidatedFormItem
                                        name={`${i}.occupancy`}
                                        label={t('occupancy')}
                                        required
                                    >
                                        <NumberFormat
                                            allowNegative={false}
                                            isAllowed={(value: NumberFormatValues): boolean => 
                                                value.floatValue === undefined ||
                                                (value.floatValue !== undefined &&
                                                value.floatValue <= 100)
                                            }
                                            customInput={(props: any) => (
                                                <Input addonBefore={'%'} {...props} />
                                            )}
                                        />
                                    </ValidatedFormItem>
                                </Col>
                            </Row>
                        </>
                    ))}

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(SetTargetsModal);
