// GENERATED FILE - DO NOT MODIFY
import { CreatePlanRequestDto } from 'Api/Features/Plans/Dtos/CreatePlanRequestDto';
import { GetCampusPlansRequestDto } from 'Api/Features/Plans/Dtos/GetCampusPlansRequestDto';
import { GetPlanOrderResponseDto } from 'Api/Features/Plans/Dtos/GetPlanOrderResponseDto';
import { GetPlansRequestDto } from 'Api/Features/Plans/Dtos/GetPlansRequestDto';
import { GetPlansResponseDto } from 'Api/Features/Plans/Dtos/GetPlansResponseDto';
import { PlanDto } from 'Api/Features/Plans/Dtos/PlanDto';
import { UpdatePlanOrderRequestDto } from 'Api/Features/Plans/Dtos/UpdatePlanOrderRequestDto';
import { UpdatePlanRequestDto } from 'Api/Features/Plans/Dtos/UpdatePlanRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class PlanProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getPlan(planId: string | null): Promise<PlanDto | null> {
        const uri = this.buildUri(
            "/plans/{planId}",
            { planId: planId },
            null
        );

        const data: PlanDto | null = await this.httpClient.get<PlanDto | null>(uri);
        return data;
    }

    public async getCampusPlans(campusId: string | null, request: GetCampusPlansRequestDto | null): Promise<(PlanDto | null)[] | null> {
        const uri = this.buildUri(
            "/campus/{campusId}/plans",
            { campusId: campusId },
            { ...request || undefined }
        );

        const data: (PlanDto | null)[] | null = await this.httpClient.get<(PlanDto | null)[] | null>(uri);
        return data;
    }

    public async getPlans(request: GetPlansRequestDto | null): Promise<GetPlansResponseDto | null> {
        const uri = this.buildUri(
            "/plans",
            null,
            { ...request || undefined }
        );

        const data: GetPlansResponseDto | null = await this.httpClient.get<GetPlansResponseDto | null>(uri);
        return data;
    }

    public async getCampusPlanOrdering(campusId: string | null): Promise<GetPlanOrderResponseDto | null> {
        const uri = this.buildUri(
            "/campus/{campusId}/plans/order",
            { campusId: campusId },
            null
        );

        const data: GetPlanOrderResponseDto | null = await this.httpClient.get<GetPlanOrderResponseDto | null>(uri);
        return data;
    }

    public async updateCampusPlansOrdering(campusId: string | null, request: UpdatePlanOrderRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/campus/{campusId}/plans/order",
            { campusId: campusId },
            null
        );

        await this.httpClient.put<UpdatePlanOrderRequestDto | null, void>(uri, request);
    }

    public async createPlan(request: CreatePlanRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/plans",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreatePlanRequestDto | null, string>(uri, request);
        return data;
    }

    public async updatePlan(planId: string | null, request: UpdatePlanRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/plans/{planId}",
            { planId: planId },
            null
        );

        await this.httpClient.patch<UpdatePlanRequestDto | null, void>(uri, request);
    }

    public async deletePlan(planId: string | null): Promise<void> {
        const uri = this.buildUri(
            "/plans/{planId}",
            { planId: planId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}