import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const User: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32.131 32.214c0 3.506-3.088 6.357-6.883 6.357h-10.52c-3.797 0-6.883-2.851-6.883-6.357v-3.857c0-2.954 2.18-5.492 5.303-6.172l.266-.06.212.17c1.874 1.49 4.074 2.276 6.362 2.276 2.286 0 4.486-.786 6.36-2.277l.215-.168.262.06c3.123.68 5.306 3.216 5.306 6.17v3.858zM10.417 12.286c0-5.986 4.291-10.858 9.571-10.858 5.277 0 9.572 4.872 9.572 10.858 0 5.988-4.295 10.856-9.572 10.856-5.28 0-9.571-4.868-9.571-10.856zm18.32 8.708l-.734-.254.462-.626c1.626-2.197 2.523-4.977 2.523-7.828C30.988 5.51 26.051 0 19.988 0c-6.065 0-11 5.511-11 12.286 0 2.85.895 5.63 2.52 7.828l.466.626-.737.254c-2.883.997-4.82 3.697-4.82 6.72v5.143A7.15 7.15 0 0013.56 40h12.857c3.937 0 7.143-3.203 7.143-7.143v-5.143c0-3.023-1.937-5.723-4.823-6.72z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default User;
