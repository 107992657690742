import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { CreateAddOnRequestDto } from 'Api/Features/AddOns/Dtos/CreateAddOnRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { AddOn as AddOnIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { AddOn } from 'Models/AddOn/AddOn';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { EditAddOnSchema } from 'Schemas';
import { AddOnService } from 'Services/AddOnService';
import { AddOnCategoryDto } from 'Api/Features/AddOnCategories/Dtos/AddOnCategoryDto'
import { AddOnCategoryService } from 'Services/AddOnCategoryService';
import './edit-add-on.less';

const { Title } = Typography;

const formGutter: [Gutter, Gutter] = [40, 0];

interface EditAddOnModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    locationId: string;
    addOn?: AddOn;
}

const { Option } = Select;

const EditAddOnModal: FunctionComponent<EditAddOnModalProps> = ({
    visible,
    onComplete,
    addOn,
    locationId,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(EditAddOnSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const addOnService = useService(AddOnService);
    const addOnCategoryService = useService(AddOnCategoryService);
    const [categories, setCategories] = useState<AddOnCategoryDto[] | undefined>(undefined);
    //#endregion
    
    //#region Effects
    useEffect(() => {
        if (addOn) {
            form.setFieldsValue({
                name: addOn.name,
                categoryId: addOn.category?.id,
                suggestedPrice: addOn.suggestedPrice,
            });
        }
    }, [addOn, form]);
    //#endregion

    const fetchCategories = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const categories = await addOnCategoryService.getAddOnCategories({});
            if (categories?.items)
                setCategories(
                    categories?.items
                        .filter((category) => category !== null)
                        .map((category) => category!)
                );
            
                
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, addOnCategoryService])

    useEffect(() => {
        fetchCategories()
    }, [fetchCategories])


    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <AddOnIcon />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`model_confirm_positive_${addOn ? 'edit' : 'create'}`, {param1 : 'add-on'}),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const model: CreateAddOnRequestDto = {
            ...form.getFieldsValue(),
            campusId: locationId,
        };

        if (!(await validateForm(model))) return;
        try {
            globalLoadingStore.addLoading();
            if(addOn){
                await addOnService.updateAddOn(addOn.id!, model);
            }
            else{
                await addOnService.createAddOn(model);
            }

            toastStore.toast({
                type: 'success',
                messageKey: t(`model_${addOn ? 'edit' : 'create'}_success`, {param1: t('add-on')}),
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    const handleDescriptionChange = (value: string): void => {
        form.setFieldsValue({ description: value });
    };

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={addOn !== undefined ? t('AddOn.edit_add_on') : t('AddOn.create_add_on')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="EditAddOn">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Title level={4}>{t('basic_information')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="name"
                                label={t('name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="categoryId"
                                label={t('category')}
                                required
                            >
                                <Select>
                                    {categories &&
                                        categories.map((category) => (
                                            <Option key={category.id!} value={category.id!}>
                                                {category.name}
                                            </Option>
                                        ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="suggestedPrice"
                                label={t('suggested_price_monthly')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="description"
                                label={t('ConferenceRoom.description_title')}
                                className="description"
                            >
                                <Input hidden />
                            </ValidatedFormItem>
                            <ReactQuill
                                theme="snow"
                                className="description-quill"
                                onChange={handleDescriptionChange}
                                value={
                                    form.getFieldValue('description') || addOn?.description || null
                                }
                            />
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(EditAddOnModal);
