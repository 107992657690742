import { Button, Col, Form, Input, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import BaseModal from 'Components/base-modal/base-modal';
import Icon from 'Components/icons/icon';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { TENTHOUSAND } from 'Models/Constants';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { ParametersSchema } from 'Schemas/ParametersSchema';
import { GetSystemParametersResponse, SystemService } from 'Services/SystemService';
import './parameters-modal.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface CreditConversionRatioModalProps {
    visible: boolean;
    systemParametersData: GetSystemParametersResponse;
    onComplete: () => void;
}

const CreditConversionRatioModal: FunctionComponent<CreditConversionRatioModalProps> = ({
    visible,
    systemParametersData,
    onComplete,
}) => {
    const systemService = useService(SystemService);
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(ParametersSchema, form);
    const { globalLoadingStore, toastStore } = useStores();
    const { confirmationModalStore } = useStores();
    const { t } = useTranslation();

    const dismiss = (): void => {
        onComplete();
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="AdministrationNut" />,
                title: t('Booking.book_a_room_confirm_title'),
                message: t('Booking.book_a_room_confirm_message'),
                positiveText: t('Administration.credit_conversion_ratio_confirm_positive'),
                negativeText: t('Booking.book_a_room_confirm_negative'),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        globalLoadingStore.addLoading();

        try {
            const formValues = form.getFieldsValue();
            const request: GetSystemParametersResponse = {
                welcomeMessage: systemParametersData.welcomeMessage,
                creditConversionRatio: formValues.creditConversionRatio,
            };

            if (!(await validateForm(request))) return;

            await systemService.updateSystemParameters(request);

            dismiss();
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    useEffect(() => {
        form.setFieldsValue({
            creditConversionRatio: systemParametersData.creditConversionRatio,
        });
    }, [form, systemParametersData]);

    return (
        <BaseModal
            visible={visible}
            title={t('Administration.credit_conversion_ratio')}
            className="CreditConversionRatioModal FormModal"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem
                            name="creditConversionRatio"
                            label={t('Administration.credit_conversion_ratio')}
                            errors={errors}
                        >
                            <NumberFormat
                                decimalScale={0}
                                allowNegative={false}
                                customInput={(props: any) => <Input {...props} />}
                                isAllowed={(value: NumberFormatValues): boolean =>
                                    value.floatValue === undefined ||
                                    (value.floatValue !== undefined &&
                                        value.floatValue <= TENTHOUSAND)
                                }
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" className="positive" htmlType="submit">
                        {t('save')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(CreditConversionRatioModal);
