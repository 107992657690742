import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Guest: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.4668 16H12.7246V1.45972H3.4668V16ZM3.86928 15.4745H12.3221V1.98535H3.86928V15.4745Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.94274 11.7931H11.2488V11.3906H4.94274V11.7931Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.94274 13.1238H11.2488V12.7214H4.94274V13.1238Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.94274 14.4814H11.2488V14.0789H4.94274V14.4814Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.11104 6.58407H8.10868C7.90045 6.58328 7.70428 6.50193 7.55668 6.35365C7.40919 6.20536 7.32783 6.00841 7.32862 5.80007C7.32862 5.59105 7.41076 5.39477 7.55904 5.24728C7.70733 5.10046 7.90271 5.01911 8.11104 5.01911H8.11262C8.32085 5.0199 8.51713 5.10204 8.66541 5.24953C8.8129 5.3986 8.89426 5.59477 8.89347 5.80311C8.89268 6.23432 8.54136 6.58407 8.11104 6.58407ZM8.924 4.99173C8.70721 4.77427 8.41978 4.65415 8.11341 4.65336H8.11026C7.80535 4.65336 7.51792 4.77201 7.30124 4.98801C7.08378 5.20469 6.96355 5.4928 6.96288 5.79928C6.9622 6.43196 7.47533 6.94756 8.10812 6.94903H8.11104C8.41595 6.94903 8.7035 6.83049 8.92085 6.61449C9.13831 6.3986 9.25854 6.11038 9.25921 5.8039C9.26 5.4981 9.14068 5.20919 8.924 4.99173Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.25399 7.82472C7.31641 7.81041 7.38097 7.80219 7.45094 7.80083C7.45793 7.80005 7.46548 7.80005 7.47224 7.80005L8.74063 7.80286C8.74751 7.80286 8.75427 7.80286 8.76576 7.80365C8.88362 7.80703 8.99832 7.83159 9.10615 7.87667C9.46942 8.02799 9.70356 8.38281 9.70266 8.77976L9.70063 9.75689L6.49027 9.75002L6.4923 8.77278C6.49331 8.32421 6.81353 7.92568 7.25399 7.82472ZM8.74142 7.43779L7.47303 7.43509C6.73241 7.43351 6.12813 8.03362 6.12655 8.77199L6.12362 10.1141L10.0648 10.1227L10.0677 8.78055C10.0693 8.04219 9.47438 7.43948 8.74142 7.43779Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.89204 3.60721H9.29925V3.03256H6.89204V3.60721ZM6.58274 3.91662H9.60877V2.72316H6.58274V3.91662Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.45113 3.33333H8.74037V0H7.45113V3.33333Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Guest;
