import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { scrollToErrors } from 'Utils/ScrollToErrors';
import * as yup from 'yup';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { appInsights } from "AppInsights";
import { useStores } from 'Hooks';

export const useFormValidation = (
    schema: yup.ObjectSchema,
    form: FormInstance
): [
    Map<string, string[]>,
    (values: any) => Promise<boolean>,
    () => void,
    (errors: Map<string, string[]>) => void
] => {
    const { userPermissionsStore } = useStores();

    const appInsightsReactPlugin = useAppInsightsContext();
    const eventTracker = useTrackEvent(appInsightsReactPlugin, "Form validation failed", {})
    
    const [errors, setErrors] = React.useState(new Map<string, string[]>());

    const resetErrors = (): void => {
        setErrors(new Map<string, string[]>());
    };

    const validateForm = async (values: any): Promise<boolean> => {
        try {
            await schema.validate(values, { abortEarly: false });
            resetErrors();
            return true;
        } catch (validationErrors) {
            const errors = new Map<string, string[]>();
            for (const error of validationErrors.inner) {
                errors.set(error.path, (errors.get(error.path) ?? []).concat(error.errors));
            }
            setErrors(errors);
            scrollToErrors(errors, form);
            
            // Log validation error to Azure AppInsights
            if (userPermissionsStore.userInfo?.contactInfo?.email)
                appInsights.setAuthenticatedUserContext(userPermissionsStore.userInfo?.contactInfo?.email);

            eventTracker({
                userSubmittedFormValues: validationErrors.value,
                validationErrors: validationErrors.inner,
            });

            return false;
        }
    };

    return [errors, validateForm, resetErrors, setErrors];
};
