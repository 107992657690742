import { SelectCustomOption } from "./select-custom";

export const mergeSelectedOptionsWithSearchResults = (
    searchResults: SelectCustomOption[],
    selectedOptions: SelectCustomOption[]
): SelectCustomOption[] => {
    if (selectedOptions === undefined) return searchResults;

    const merged = searchResults.concat(selectedOptions);
    for (let i = 0; i < merged.length; ++i) {
        for (let j = i + 1; j < merged.length; ++j) {
            if (merged[i].value === merged[j].value) merged.splice(j--, 1);
        }
    }

    return merged;
};
