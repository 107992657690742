import * as yup from 'yup';

export const CreateBookingSchema = yup.object({
    bookingCreatorId: yup.string().required('Errors.required'),
    isAdministrative: yup.boolean(),
    creditBalanceId: yup.string().when('isAdministrative', {
        is: false,
        then: yup.string().required('Errors.required'),
    }),
});
