import { Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { MembershipDetailsPlusDto } from 'Api/Features/Memberships/Dtos/MembershipDetailsPlusDto';
import Icon from 'Components/icons/icon';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { useService, useStores } from 'Hooks';
import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import CompanyDetailsBody from 'Routes/authenticated/companies/id/dashboard/company-details-body';
import { MembershipService } from 'Services/MembershipService';
import { theme } from 'variant';

const LocationCompanyDetails: FunctionComponent = () => {
    const membershipService = useService(MembershipService);
    const { toastStore, globalLoadingStore } = useStores();
    const history = useHistory();
    const { membershipId } = useParams<{ membershipId: string }>();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [membership, setMemberbership] = useState<MembershipDetailsPlusDto>();

    const fetchMembership = useCallback(async () => {
        try {
            globalLoadingStore.addLoading();
            setLoading(true);

            const membershipResponse = await membershipService.getMembership(membershipId);

            if (membershipResponse)
                setMemberbership(membershipResponse);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
            setLoading(false);
        }
    }, [membershipService, membershipId, t]);

    useEffect(() => {
        fetchMembership();
    }, [fetchMembership]);

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `companies`,
            nameKey: 'companies',
        },
        {
            path: `${membership?.id}`,
            nameKey: `${membership?.name}`
        },
    ];

    return (
        <div className="LocationCompanyDetails">
            <LocationHeader
                title={membership?.name ?? ''}
                subTitle={' '}
                imgSrc={membership?.imageUrl}
                defaultImg={<Icon iconName="Company" fill={theme['primary-color']} />}
                routes={breadcrumbs}
                loading={loading}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            history.push(`/companies/${membershipId}/dashboard`);
                        }}
                    >
                        {t('Membership.manage_this_company')}
                    </Button>
                }
            />

            <Content>
                {membership && <CompanyDetailsBody company={membership} />}
            </Content>
        </div>
    );
};

export default LocationCompanyDetails;
