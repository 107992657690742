import { LightContactInfoDto } from 'Api/Features/General/Dtos/LightContactInfoDto';
import { ExpertiseDto } from 'Api/Features/Expertises/Dtos/ExpertiseDto';
import { MemberDto } from 'Api/Features/Members/Dtos/MemberDto';
import { MemberCampusDto } from 'Api/Features/Members/Dtos/MemberCampusDto';
import { MemberMembershipDto } from 'Api/Features/Members/Dtos/MemberMembershipDto';

export class Member implements MemberDto {
    constructor(dto: MemberDto) {
        Object.assign(this, dto);
    }

    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    contactInfo?: LightContactInfoDto | null;
    imageUrl?: string | null;
    jobPosition?: string | null;
    introduction?: string | null;
    isPrivate?: boolean;
    expertises?: (ExpertiseDto | null)[] | null;
    isInSortFirstMembership?: boolean | null;
    campuses?: (MemberCampusDto | null)[] | null;
    memberships?: (MemberMembershipDto | null)[] | null;
    isSubscribed?: boolean;

    get name(): string | null {
        if (this.firstName && this.lastName) return `${this.firstName} ${this.lastName}`;
        if (this.firstName) return this.firstName;
        if (this.lastName) return this.lastName;
        return null;
    }
}
