import { CreateTaskRequestDto } from 'Api/Features/Tasks/Dtos/CreateTaskRequestDto';
import { UpdateTaskRequestDto } from 'Api/Features/Tasks/Dtos/UpdateTaskRequestDto';
import { TaskProxy } from 'Api/Features/Tasks/TaskProxy';
import { inject } from 'aurelia-dependency-injection';
import { dateMomentToString } from 'Utils/TimeUtils';
import { ApiService } from './ApiService';

@inject(TaskProxy)
export class TaskService extends ApiService {
    constructor(private readonly taskProxy: TaskProxy) {
        super();
    }

    public async createTask(request: CreateTaskRequestDto): Promise<void> {
        request.date = dateMomentToString(request.date);

        return await this.taskProxy.createTask(request);
    }

    public async updateTask(id: string, request: UpdateTaskRequestDto): Promise<void> {
        request.date = dateMomentToString(request.date);

        await this.taskProxy.updateTask(id, request);
    }

    public async cancelTask(id: string): Promise<void> {
        await this.taskProxy.deleteTask(id);
    }
}
