// GENERATED FILE - DO NOT MODIFY
import { CreateMeetingRequestDto } from 'Api/Features/Meetings/Dtos/CreateMeetingRequestDto';
import { GetMeetingsRequestDto } from 'Api/Features/Meetings/Dtos/GetMeetingsRequestDto';
import { GetMeetingsResponseDto } from 'Api/Features/Meetings/Dtos/GetMeetingsResponseDto';
import { MeetingDto } from 'Api/Features/Meetings/Dtos/MeetingDto';
import { UpdateMeetingRequestDto } from 'Api/Features/Meetings/Dtos/UpdateMeetingRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class MeetingProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getMeeting(meetingId: string): Promise<MeetingDto | null> {
        const uri = this.buildUri(
            "/meetings/{meetingId}",
            { meetingId: meetingId },
            null
        );

        const data: MeetingDto | null = await this.httpClient.get<MeetingDto | null>(uri);
        return data;
    }

    public async getMeetings(request: GetMeetingsRequestDto | null): Promise<GetMeetingsResponseDto | null> {
        const uri = this.buildUri(
            "/meetings",
            null,
            { ...request || undefined }
        );

        const data: GetMeetingsResponseDto | null = await this.httpClient.get<GetMeetingsResponseDto | null>(uri);
        return data;
    }

    public async createMeeting(request: CreateMeetingRequestDto | null): Promise<string | null> {
        const uri = this.buildUri(
            "/meetings",
            null,
            null
        );

        const data: string | null = await this.httpClient.post<CreateMeetingRequestDto | null, string | null>(uri, request);
        return data;
    }

    public async updateMeeting(meetingId: string, request: UpdateMeetingRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/meetings/{meetingId}",
            { meetingId: meetingId },
            null
        );

        await this.httpClient.patch<UpdateMeetingRequestDto | null, void>(uri, request);
    }

    public async deleteMeeting(meetingId: string): Promise<void> {
        const uri = this.buildUri(
            "/meetings/{meetingId}",
            { meetingId: meetingId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}