import { Button, Form, Switch } from 'antd';
import { GetCampusOnboardingConfigResponseCategoryDto } from 'Api/Features/Campuses/Dtos/GetCampusOnboardingConfigResponseCategoryDto';
import { GetCampusOnboardingConfigResponseTaskDto } from 'Api/Features/Campuses/Dtos/GetCampusOnboardingConfigResponseTaskDto';
import BaseModal from 'Components/base-modal/base-modal';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useService, useStores } from 'Hooks';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationService } from 'Services/LocationService';
import './edit-onboarding-modal.less';

interface EditOnboardingModalProps {
    locationId: string;
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    onboardingCategoriesCheckList: GetCampusOnboardingConfigResponseCategoryDto[];
    tasksListToDisplay: (GetCampusOnboardingConfigResponseTaskDto | null | undefined)[];
    onboardingCheckList: (GetCampusOnboardingConfigResponseTaskDto | null | undefined)[];
}

const EditOnboardingModal: FunctionComponent<EditOnboardingModalProps> = ({
    locationId,
    visible,
    onComplete,
    onboardingCategoriesCheckList,
    tasksListToDisplay,
    onboardingCheckList,
}) => {
    const locationService = useService(LocationService);
    const { globalLoadingStore, toastStore } = useStores();
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const dismiss = (success = false, id?: string): void => {
        onComplete(success, id);
    };

    const exit = (): void => {
        dismiss();
    };

    const submit = async (): Promise<void> => {
        interface CategoryId {
            categoryId?: string;
        }
        type UpdatedOnboardingCheckList =
            | (CategoryId & GetCampusOnboardingConfigResponseTaskDto)
            | undefined
            | null;

        const formValues = form.getFieldsValue();
        const updatedOnboardingCheckList: UpdatedOnboardingCheckList[] = onboardingCheckList.map(
            (item) => {
                if (item && item.id) {
                    item.isEnabled =
                        formValues[item.id] === undefined ? item.isEnabled : formValues[item.id];
                }
                return item;
            }
        );
        const updatedOnboardingCategoriesCheckList = {
            categories: onboardingCategoriesCheckList.map(
                (category: GetCampusOnboardingConfigResponseCategoryDto) => ({
                    id: category.id,
                    tasks: updatedOnboardingCheckList
                        .filter((item) => category.id === item?.categoryId)
                        .map((item) => ({ id: item?.id, isEnabled: item?.isEnabled })),
                })
            ),
        };

        try {
            globalLoadingStore.addLoading();

            await locationService.updateCampusOnboardingConfig(
                locationId,
                updatedOnboardingCategoriesCheckList
            );

            toastStore.toast({
                type: 'success',
                messageKey: `Lead.meeting_changed_success`,
            });

            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <BaseModal
            visible={visible}
            title={t('Location.onboarding_edit_label')}
            className="FormModal EditOnboardingModal"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                {tasksListToDisplay && (
                    <div className="list">
                        {tasksListToDisplay.map((item) => {
                            return (
                                item && (
                                    <ValidatedFormItem name={item.id} label={item.description}>
                                        <Switch defaultChecked={item.isEnabled} />
                                    </ValidatedFormItem>
                                )
                            );
                        })}
                    </div>
                )}

                <div className="actions">
                    <Button type="default" className="secondary" htmlType="button" onClick={exit}>
                        {t('cancel')}
                    </Button>

                    <Button type="primary" htmlType="button" onClick={submit}>
                        {t('submit')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(EditOnboardingModal);
