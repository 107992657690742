import { Typography } from 'antd';
import Icon from 'Components/icons/icon';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import './button-link-with-icon.less';

interface ButtonLinkWithIconProps {
    linkTo: string;
    iconName: string;
    label: string;
}

const ButtonLinkWithIcon: FunctionComponent<ButtonLinkWithIconProps> = ({
    linkTo,
    iconName,
    label,
}) => {
    return (
        <Link className="ButtonLinkWithIcon" to={linkTo}>
            <Icon iconName={iconName} />

            <Typography.Text>{label}</Typography.Text>
        </Link>
    );
};

export default ButtonLinkWithIcon;
