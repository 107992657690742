import { GetSageEntitiesRequestDto } from 'Api/Features/SageIntacct/Dtos/GetSageEntitiesRequestDto';
import { GetSageEntitiesResponseDto } from 'Api/Features/SageIntacct/Dtos/GetSageEntitiesResponseDto';
import { SageEntityDto } from 'Api/Features/SageIntacct/Dtos/SageEntityDto';
import { SageIntacctProxy } from 'Api/Features/SageIntacct/SageIntacctProxy';
import { inject } from 'aurelia-dependency-injection';
import { LoadingStore } from 'Stores';
import { ApiService } from './ApiService';

interface GetSageEntitiesResponse {
    items: SageEntityDto[];
    totalItemCount: number;
}

@inject(SageIntacctProxy, LoadingStore)
export class SageIntacctService extends ApiService {
    constructor(private readonly sageIntacctProxy: SageIntacctProxy) {
        super();
    }

    public async getSageEntities(
        request: GetSageEntitiesRequestDto
    ): Promise<GetSageEntitiesResponse> {
        const response: GetSageEntitiesResponseDto | null =
            await this.sageIntacctProxy.getSageEntities(request);
        const nonNullResponse = response?.items
            ? response.items.filter((item): item is SageEntityDto => item !== null)
            : [];
        return { items: nonNullResponse, totalItemCount: response?.totalItemCount || 0 };
    }
}
