// GENERATED FILE - DO NOT MODIFY
import { GetAddOnCategoriesRequestDto } from 'Api/Features/AddOnCategories/Dtos/GetAddOnCategoriesRequestDto';
import { GetAddOnCategoriesResponseDto } from 'Api/Features/AddOnCategories/Dtos/GetAddOnCategoriesResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class AddOnCategoryProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getAddOnCategories(request: GetAddOnCategoriesRequestDto | null): Promise<GetAddOnCategoriesResponseDto | null> {
        const uri = this.buildUri(
            "/addon-categories",
            null,
            { ...request || undefined }
        );

        const data: GetAddOnCategoriesResponseDto | null = await this.httpClient.get<GetAddOnCategoriesResponseDto | null>(uri);
        return data;
    }
}