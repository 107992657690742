import { Col, DatePicker, Input, Row, Select, Typography } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { LeadAccountDto } from 'Api/Features/Leads/Dtos/LeadAccountDto';
import { LeadSourceDto } from 'Api/Features/Leads/Dtos/LeadSourceDto';
import { OpportunityProductDto } from 'Api/Features/Opportunities/Dtos/OpportunityProductDto';
import { OpportunitySalesChannelDto } from 'Api/Features/Opportunities/Dtos/OpportunitySalesChannelDto';
import { OpportunityTermLengthDto } from 'Api/Features/Opportunities/Dtos/OpportunityTermLengthDto';
import { User } from 'Components/icons';
import SelectCustom, { SelectCustomOption } from 'Components/select-custom/select-custom';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { LeadSource } from 'Models/Leads/LeadSource';
import { BILLION, HUNDRED } from 'Models/Constants';
import { LightCampusDetailsInfo } from 'Models/Location/LightCampusInfo';
import { ManagerUser } from 'Models/ManagerUsers/ManagerUser';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { ManagerUserService } from 'Services/ManagerUserService';
import { theme } from 'variant';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import './opportunity-form-section.less';

const { Title } = Typography;
const { Option } = Select;

const formGutter: [Gutter, Gutter] = [40, 0];

interface OpportunityFormSectionProps {
    errors: Map<string, string[]>;
    leadOwner?: LeadAccountDto | null;
    leadDescription?: string;
    createModificationRenewalOpportunityOwner?: any;
    isModificationOrRenewal?: boolean;
    leadSource?: LeadSourceDto;
}

const OpportunityFormSection: FunctionComponent<OpportunityFormSectionProps> = ({
    errors,
    leadOwner,
    leadDescription,
    createModificationRenewalOpportunityOwner,
    isModificationOrRenewal,
    leadSource,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const managerUserService = useService(ManagerUserService);
    const { locationStore } = useStores();
    const pageSize = 25;

    const [managersCurrentPage, setManagersCurrentPage] = useState(0);
    const [managersSearchResults, setManagersSearchResults] = useState<ManagerUser[]>([]);
    const [managersSearchTerm, setManagersSearchTerm] = useState('');
    const [managersMaxResults, setManagersMaxResults] = useState(false);
    const [managersOptions, setManagersOptions] = useState<SelectCustomOption[]>([]);
    const [selectedManagerOptions, setSelectedManagerOptions] = useState<SelectCustomOption[]>([]);
    const [selectedOwnerId, setSelectedOwnerId] = useState<string[] | undefined>(
        leadOwner?.id
            ? [leadOwner.id]
            : createModificationRenewalOpportunityOwner?.id
            ? [createModificationRenewalOpportunityOwner.id]
            : []
    );
    const [selectLoading, setSelectLoading] = useState(false);
    const [leadSourceVal, setLeadSourceVal] = useState<LeadSourceDto | undefined>(leadSource);
    const resetManagersSearch = (): void => {
        setManagersCurrentPage(0);
        setManagersSearchResults([]);
        setManagersSearchTerm('');
        setManagersMaxResults(false);
    };

    //#region Event handlers

    const handleManagerKeywordsChange = useCallback((value: string): void => {
        resetManagersSearch();
        setManagersSearchTerm(value);
    }, []);

    const searchManagerUsers = async (page: number, searchTerm: string): Promise<ManagerUser[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
        };
        const [results, totalItemCount] = await managerUserService.getManagerUsers(args);

        if (results.length + pageSize * page >= totalItemCount) {
            setManagersMaxResults(true);
        }
        setSelectLoading(false);
        return results;
    };

    const debounceManagerSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchManagerUsers(page, searchTerm).then((results) => {
                setManagersSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    const handleManagersMenuScrollToBottom = (): void => {
        if (!managersMaxResults) {
            setManagersCurrentPage((prevPage) => prevPage + 1);
        }
    };
    //#endregion

    //#region Effect
    useEffect(() => {
        const searchResults = managersSearchResults?.map(
            (x: ManagerUser) =>
                ({
                    value: x?.id,
                    label: x?.name,
                    imageUrl: x?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedManagerOptions);

        setManagersOptions(merged);
    }, [managersSearchResults, t, selectedManagerOptions]);

    useEffect(() => {
        setSelectLoading(true);
        debounceManagerSearch.current(managersCurrentPage, managersSearchTerm);
    }, [managersCurrentPage, managersSearchTerm]);

    useEffect(() => {
        if (leadOwner) {
            setSelectedManagerOptions([
                {
                    value: leadOwner.id,
                    label: `${leadOwner?.firstName} ${leadOwner?.lastName}`,
                    imageUrl: leadOwner?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption,
            ]);
            setSelectedOwnerId([leadOwner.id!]);
        }
    }, [leadOwner]);

    useEffect(() => {
        if (createModificationRenewalOpportunityOwner) {
            setSelectedManagerOptions([
                {
                    value: createModificationRenewalOpportunityOwner.id,
                    label: `${createModificationRenewalOpportunityOwner?.firstName} ${createModificationRenewalOpportunityOwner?.lastName}`,
                    imageUrl: createModificationRenewalOpportunityOwner?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption,
            ]);
        }
    }, [createModificationRenewalOpportunityOwner]);

    //#endregion

    //#region Render
    return (
        <div className="OpportunityFormSection">
            <Title level={4}>{t('Opportunity.opportunity_information')}</Title>
            <Row gutter={formGutter}>
                <Col span={6}>
                    <ValidatedFormItem
                        name="name"
                        errors={errors}
                        label={t('Opportunity.opportunity_name')}
                        required
                    >
                        <Input />
                    </ValidatedFormItem>
                </Col>
                <Col span={6}>
                    <ValidatedFormItem
                        name="ownerAccountId"
                        errors={errors}
                        label={t('Opportunity.opportunity_owner')}
                        required
                    >
                        <SelectCustom
                            options={managersOptions}
                            defaultImg={<User fill={theme['white']} />}
                            strongLabel={true}
                            placeholder={t('SelectCustom.placeholder_default')}
                            onKeywordsChange={handleManagerKeywordsChange}
                            onMenuScrollToBottom={handleManagersMenuScrollToBottom}
                            onChange={(value: any): void => {
                                setSelectedOwnerId([value.value]);
                                const options = [value as SelectCustomOption];
                                setSelectedManagerOptions(options);
                            }}
                            hideSelectedOptions={false}
                            selected={selectedOwnerId}
                            isLoading={selectLoading}
                            idAttribute="ownerAccountId"
                        />
                    </ValidatedFormItem>
                </Col>
                <Col span={6}>
                    <ValidatedFormItem name="companyConfirm" errors={errors} label={t('company')}>
                        <Input disabled />
                    </ValidatedFormItem>
                </Col>
                <Col span={6}>
                    <ValidatedFormItem
                        name="contactConfirm"
                        errors={errors}
                        label={t('FormStep.step_Contact')}
                    >
                        <Input disabled />
                    </ValidatedFormItem>
                </Col>
            </Row>

            <Row gutter={formGutter}>
                <Col span={6}>
                    <ValidatedFormItem
                        name="probability"
                        errors={errors}
                        label={t('Opportunity.probability')}
                    >
                        <NumberFormat
                            allowNegative={false}
                            customInput={(props: any) => <Input {...props} />}
                            isAllowed={(value: NumberFormatValues): boolean =>
                                value.floatValue === undefined ||
                                (value.floatValue !== undefined && value.floatValue <= HUNDRED)
                            }
                        />
                    </ValidatedFormItem>
                </Col>
                <Col span={6}>
                    <ValidatedFormItem
                        name="closeDate"
                        errors={errors}
                        label={t('Opportunity.close_date')}
                        required
                    >
                        <DatePicker style={{ width: '100%' }} />
                    </ValidatedFormItem>
                </Col>
            </Row>

            <Title level={4}>{t('Opportunity.deal_information')}</Title>
            <Row gutter={formGutter}>
                <Col span={6}>
                    <ValidatedFormItem name="campusId" errors={errors} label={t('location')}>
                        <Select disabled={isModificationOrRenewal}>
                            {locationStore.locations?.map((x: LightCampusDetailsInfo) => (
                                <Option key={x.id!} value={x.id!}>
                                    {x.name}
                                </Option>
                            ))}
                        </Select>
                    </ValidatedFormItem>
                </Col>
                <Col span={6}>
                    <ValidatedFormItem
                        name="deskCount"
                        errors={errors}
                        label={t('Opportunity.desk_count')}
                        required
                    >
                        <NumberFormat
                            allowNegative={false}
                            customInput={(props: any) => <Input {...props} />}
                            isAllowed={(value: NumberFormatValues): boolean =>
                                value.floatValue === undefined ||
                                (value.floatValue !== undefined && value.floatValue <= BILLION)
                            }
                        />
                    </ValidatedFormItem>
                </Col>
                <Col span={6}>
                    <ValidatedFormItem
                        name="product"
                        errors={errors}
                        label={t('Opportunity.product')}
                        required
                    >
                        <Select>
                            {Object.keys(OpportunityProductDto).map((x) => (
                                <Option key={x} value={x}>
                                    {t(`Opportunity.product_${x}`)}
                                </Option>
                            ))}
                        </Select>
                    </ValidatedFormItem>
                </Col>
                <Col span={6}>
                    <ValidatedFormItem
                        name="salesChannel"
                        errors={errors}
                        label={t('Opportunity.sales_channel')}
                        required
                    >
                        <Select>
                            {Object.keys(OpportunitySalesChannelDto).map((x) => (
                                <Option key={x} value={x}>
                                    {t(`Opportunity.salesChannel_${x}`)}
                                </Option>
                            ))}
                        </Select>
                    </ValidatedFormItem>
                </Col>
            </Row>

            <Row gutter={formGutter}>
                <Col span={6}>
                    <ValidatedFormItem
                        name="termLength"
                        errors={errors}
                        label={t('Opportunity.term_length')}
                    >
                        <Select>
                            {Object.keys(OpportunityTermLengthDto).map((x) => (
                                <Option key={x} value={x}>
                                    {t(`Opportunity.term_${x}`)}
                                </Option>
                            ))}
                        </Select>
                    </ValidatedFormItem>
                </Col>
                <Col span={6}>
                    <ValidatedFormItem
                        name="monthlyRevenue"
                        errors={errors}
                        label={t('Opportunity.monthly_revenue')}
                        required={isModificationOrRenewal}
                    >
                        <NumberFormat
                            allowNegative={false}
                            customInput={(props: any) => <Input {...props} />}
                            isAllowed={(value: NumberFormatValues): boolean =>
                                value.floatValue === undefined ||
                                (value.floatValue !== undefined && value.floatValue <= BILLION)
                            }
                        />
                    </ValidatedFormItem>
                </Col>
                <Col span={6}>
                    <ValidatedFormItem
                        name="officeNumber"
                        errors={errors}
                        label={t('Lead.office_number')}
                    >
                        <Input />
                    </ValidatedFormItem>
                </Col>
            </Row>

            <Title level={4}>{t('Opportunity.additional_information')}</Title>
            <Row gutter={formGutter}>
                <Col span={24}>
                    <ValidatedFormItem
                        name="description"
                        errors={errors}
                        label={t('ConferenceRoom.description_title')}
                        initialValue={leadDescription}
                    >
                        <Input.TextArea />
                    </ValidatedFormItem>
                </Col>
            </Row>

            {!isModificationOrRenewal && (
                <Row gutter={formGutter}>
                    <Col span={6}>
                        <ValidatedFormItem
                            name="source"
                            errors={errors}
                            label={t('Lead.source')}
                            required
                        >
                            <Select
                                onChange={(value): void => setLeadSourceVal(value as LeadSourceDto)}
                            >
                                {Object.keys(LeadSource).map((x) => (
                                    <Option key={x} value={x}>
                                        {t(`Lead.source_${x}`)}
                                    </Option>
                                ))}
                            </Select>
                        </ValidatedFormItem>
                    </Col>

                    {leadSourceVal === LeadSourceDto.Other && (
                        <Col span={8}>
                            <ValidatedFormItem
                                name="sourceOther"
                                errors={errors}
                                label={t('Lead.lead_source_other')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    )}
                </Row>
            )}
        </div>
    );
};

//#endregion

export default React.memo(OpportunityFormSection);
