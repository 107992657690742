import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { MembershipProxy } from '../Api/Features/Memberships/MembershipProxy';
import { ParseMemberImportationFileRequestDto } from 'Api/Features/Memberships/Dtos/ParseMemberImportationFileRequestDto';
import { ParseMemberImportationFileResponseDto } from 'Api/Features/Memberships/Dtos/ParseMemberImportationFileResponseDto';
import { ImportMembersRequestDto } from 'Api/Features/Memberships/Dtos/ImportMembersRequestDto';
import { ImportMembersPreviewResponseDto } from 'Api/Features/Memberships/Dtos/ImportMembersPreviewResponseDto';
import { GetMembershipsResponseDto } from 'Api/Features/Memberships/Dtos/GetMembershipsResponseDto';
import { GetMembershipsRequestDto } from 'Api/Features/Memberships/Dtos/GetMembershipsRequestDto';
import { CreateMembershipRequestDto } from 'Api/Features/Memberships/Dtos/CreateMembershipRequestDto';
import { MembershipDetailsPlusDto } from 'Api/Features/Memberships/Dtos/MembershipDetailsPlusDto';
import { MembershipPublicDetailsDto } from 'Api/Features/Memberships/Dtos/MembershipPublicDetailsDto';
import { MembershipDto } from 'Api/Features/Memberships/Dtos/MembershipDto';
import { GetMembershipNoteResponseDto } from 'Api/Features/Memberships/Dtos/GetMembershipNoteResponseDto';
import { UpdateMembershipNoteRequestDto } from 'Api/Features/Memberships/Dtos/UpdateMembershipNoteRequestDto';
import { GetMembershipMembersRequestDto } from 'Api/Features/Memberships/Dtos/GetMembershipMembersRequestDto';
import { MembershipRemoveMemberRequestDto } from 'Api/Features/Memberships/Dtos/MembershipRemoveMemberRequestDto';
import { GetMembershipMembersResponseItemDto } from 'Api/Features/Memberships/Dtos/GetMembershipMembersResponseItemDto';
import { GetMembershipMembersResponseDto } from 'Api/Features/Memberships/Dtos/GetMembershipMembersResponseDto';
import { MembershipOwnershipTransferRequestDto } from 'Api/Features/Memberships/Dtos/MembershipOwnershipTransferRequestDto';
import { UpdateMembershipRequestDto } from 'Api/Features/Memberships/Dtos/UpdateMembershipRequestDto';
import { AcceptMembershipInvitationRequestDto } from 'Api/Features/Memberships/Dtos/AcceptMembershipInvitationRequestDto';
import { RejectMembershipInvitationRequestDto } from 'Api/Features/Memberships/Dtos/RejectMembershipInvitationRequestDto';
import { UpdateMembershipMemberRequestDto } from 'Api/Features/Memberships/Dtos/UpdateMembershipMemberRequestDto';
import { UpdateEmailInvitationRolesRequestDto } from 'Api/Features/Memberships/Dtos/UpdateEmailInvitationRolesRequestDto';
import { CreateMembershipResponseDto } from 'Api/Features/Memberships/Dtos/CreateMembershipResponseDto';
import { ExportMembershipsRequestDto } from 'Api/Features/Memberships/Dtos/ExportMembershipsRequestDto';
import { arrayBufferToXlsx } from 'Utils/blobs';

@inject(MembershipProxy)
export class MembershipService extends ApiService {
    constructor(private readonly membershipProxy: MembershipProxy) {
        super();
    }

    public async getMembership(
        id: string,
        excludePendingMembers?: boolean
    ): Promise<MembershipDetailsPlusDto | null> {
        return await this.membershipProxy.getMembership(id, excludePendingMembers);
    }

    public async getMembershipPublic(id: string): Promise<MembershipPublicDetailsDto | null> {
        return await this.membershipProxy.getMembershipPublicProfile(id);
    }

    public async getMemberships(
        request: GetMembershipsRequestDto | null
    ): Promise<[MembershipDto[], number]> {
        const response: GetMembershipsResponseDto | null =
            await this.membershipProxy.getMemberships(request);
        return [
            response?.items?.filter((item) => item !== null).map((x) => x!) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async importMembers(
        membershipId: string | null,
        request: ImportMembersRequestDto | null
    ): Promise<void> {
        await this.membershipProxy.importMembers(membershipId, request);
    }

    public async importMembersPreview(
        membershipId: string | null,
        request: ImportMembersRequestDto | null
    ): Promise<ImportMembersPreviewResponseDto | null> {
        return await this.membershipProxy.importMembersPreview(membershipId, request);
    }

    public async parseMemberImportationFile(
        membershipId: string | null,
        request: ParseMemberImportationFileRequestDto | null
    ): Promise<ParseMemberImportationFileResponseDto | null> {
        const data: ParseMemberImportationFileResponseDto | null =
            await this.membershipProxy.parseMemberImportationFile(membershipId, request);

        return data;
    }

    public async getMemberImportationFileTemplate(): Promise<ArrayBuffer> {
        const data: ArrayBuffer = await this.membershipProxy.getMemberImportationFileTemplate();
        return data;
    }

    public async createMembership(
        request: CreateMembershipRequestDto | null
    ): Promise<CreateMembershipResponseDto | null> {
        const data: CreateMembershipResponseDto | null =
            await this.membershipProxy.createMembership(request);
        return data;
    }

    public async getMembershipNote(
        membershipId: string | null
    ): Promise<GetMembershipNoteResponseDto | null> {
        const data: GetMembershipNoteResponseDto | null =
            await this.membershipProxy.getMembershipNote(membershipId);
        return data;
    }

    public async updateMembershipNote(
        membershipId: string | null,
        request: UpdateMembershipNoteRequestDto | null
    ): Promise<void> {
        await this.membershipProxy.updateMembershipNote(membershipId, request);
    }

    public async getMembershipMembers(
        membershipId: string | null,
        request: GetMembershipMembersRequestDto | null
    ): Promise<[GetMembershipMembersResponseItemDto[], number]> {
        const data: GetMembershipMembersResponseDto | null =
            await this.membershipProxy.getMembershipMembers(membershipId, request);
        return [
            data?.items?.filter((member) => member !== null).map((member) => member!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async removeUserFromMembership(
        membershipRemoveMemberRequest: MembershipRemoveMemberRequestDto | null
    ): Promise<void> {
        await this.membershipProxy.removeUserFromMembership(membershipRemoveMemberRequest);
    }

    public async transferMembershipOwnership(
        transferOwnershipRequest: MembershipOwnershipTransferRequestDto | null
    ): Promise<void> {
        await this.membershipProxy.transferMembershipOwnership(transferOwnershipRequest);
    }

    public async updateMembership(
        membershipId: string | null,
        request: UpdateMembershipRequestDto | null
    ): Promise<void> {
        await this.membershipProxy.updateMembership(membershipId, request);
    }

    public async deleteMembership(membershipId: string | null): Promise<void> {
        await this.membershipProxy.deleteMembership(membershipId);
    }

    public async cancelMembershipEmailInvitation(emailInvitationId: string): Promise<void> {
        await this.membershipProxy.cancelMembershipEmailInvitation(emailInvitationId);
    }

    public async acceptMembershipInvitation(
        acceptInvitationRequest: AcceptMembershipInvitationRequestDto | null
    ): Promise<void> {
        await this.membershipProxy.acceptMembershipInvitation(acceptInvitationRequest);
    }

    public async rejectMembershipInvitation(
        rejectInvitationRequest: RejectMembershipInvitationRequestDto | null
    ): Promise<void> {
        await this.membershipProxy.rejectMembershipInvitation(rejectInvitationRequest);
    }

    public async updateMembershipMemberRoles(
        request: UpdateMembershipMemberRequestDto | null
    ): Promise<void> {
        await this.membershipProxy.updateMembershipMemberRoles(request);
    }

    public async updateMembershipEmailInvitationRoles(
        emailInvitationId: string,
        request: UpdateEmailInvitationRolesRequestDto | null
    ): Promise<void> {
        await this.membershipProxy.updateMembershipEmailInvitationRoles(emailInvitationId, request);
    }

    public async exportMemberships(request: ExportMembershipsRequestDto | null): Promise<Blob> {
        const data: ArrayBuffer = await this.membershipProxy.exportMemberships(request);
        return arrayBufferToXlsx(data);
    }
}
