import React, { FunctionComponent } from 'react';
import { AdvancedFilter } from '../../../Models/Filters/AdvancedFilter';
import { Badge } from 'Components/badge';
import './panel-header.less';
import { useTranslation } from 'react-i18next';

interface PanelHeaderProps {
    filter: AdvancedFilter;
}

const PanelHeader: FunctionComponent<PanelHeaderProps> = ({ filter }) => {
    const { t } = useTranslation();
    const checkedCount = filter.items.filter((item) => item.checked).length;
    return (
        <span className="FilterPanelHeader">
            {t(filter.nameKey)}
            <Badge type="primary">{checkedCount}</Badge>
        </span>
    );
};

export default PanelHeader;
