// GENERATED FILE - DO NOT MODIFY
import { GetSageEntitiesRequestDto } from 'Api/Features/SageIntacct/Dtos/GetSageEntitiesRequestDto';
import { GetSageEntitiesResponseDto } from 'Api/Features/SageIntacct/Dtos/GetSageEntitiesResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class SageIntacctProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getSageEntities(request: GetSageEntitiesRequestDto | null): Promise<GetSageEntitiesResponseDto | null> {
        const uri = this.buildUri(
            "/sage-entities",
            null,
            { ...request || undefined }
        );

        const data: GetSageEntitiesResponseDto | null = await this.httpClient.get<GetSageEntitiesResponseDto | null>(uri);
        return data;
    }
}