import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import Icon from 'Components/icons/icon';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { theme } from 'variant';
import Table, { ColumnType, TablePaginationConfig } from 'antd/lib/table';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import debounce from 'lodash.debounce';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { FilterStore } from 'Stores';
import { useService, useStores } from 'Hooks';
import { CommunityEventService } from 'Services/CommunityEventService';
import { TableFilters } from 'Components/table-filters';
import {
    DISPLAY_DATETIME_12H,
    MOMENT_PARSING_FORMAT,
    TABLE_DEBOUNCE_DELAY,
} from 'Models/Constants';
import { autorun } from 'mobx';
import { DateRangeDropdownCommunityEventEnum } from 'Models/Filters/DateRangeDropdownOption';
import { GetCommunityEventsRequestDto } from 'Api/Features/CommunityEvents/Dtos/GetCommunityEventsRequestDto';
import moment from 'moment';
import { RequestType } from 'Stores/RequestStore';
import { cleanVal } from 'Utils/NumberUtils';
import { CommunityEventDto } from 'Api/Features/CommunityEvents/Dtos/CommunityEventDto';
import { GetCommunityEventsFlagDto } from 'Api/Features/CommunityEvents/Dtos/GetCommunityEventsFlagDto';
import { GetCommunityEventsSortColumnDto } from 'Api/Features/CommunityEvents/Dtos/GetCommunityEventsSortColumnDto';
import { Button } from 'antd';
import CreateCommunityEventModal from './create-event-modal';
import { useHistory, useParams } from 'react-router';

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 10,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'type',
        nameKey: 'Type',
        items: [
            {
                key: GetCommunityEventsFlagDto.IsInternalSpeaker,
                displayNameKey: `CommunityEvent.GetCommunityEventsFlagDto_${GetCommunityEventsFlagDto.IsInternalSpeaker}`,
                checked: true,
            },
            {
                key: GetCommunityEventsFlagDto.IsExternalSpeaker,
                displayNameKey: `CommunityEvent.GetCommunityEventsFlagDto_${GetCommunityEventsFlagDto.IsExternalSpeaker}`,
                checked: true,
            },
            {
                key: GetCommunityEventsFlagDto.NoSpeaker,
                displayNameKey: `CommunityEvent.GetCommunityEventsFlagDto_${GetCommunityEventsFlagDto.NoSpeaker}`,
                checked: true,
            },
            {
                key: GetCommunityEventsFlagDto.IsFromSystem,
                displayNameKey: `CommunityEvent.GetCommunityEventsFlagDto_${GetCommunityEventsFlagDto.IsFromSystem}`,
                checked: true,
            },
        ],
    },
];

interface CommunityEventDateFilter {
    minStartDate?: string;
    maxStartDate?: string;
    minEndDate?: string;
    maxEndDate?: string;
}

const Events: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const [loading, setLoading] = useState(false);
    const paginationRef = useRef(initialPaginationState);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [data, setData] = useState<CommunityEventDto[]>([]);
    const communityEventService = useService(CommunityEventService);
    const [requestSetFromCache, setRequestSetFromCache] = useState(false);

    const { toastStore, requestStore, userPermissionsStore } = useStores();

    const [createModalOpen, setCreateModalOpen] = useState(false);

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'events',
            nameKey: 'events',
        },
    ];

    const columns: ColumnType<CommunityEventDto>[] = [
        {
            title: t('start'),
            render: (event: CommunityEventDto) =>
                moment(event.periodStart)
                    .tz(event.timeZone ?? '')
                    .format(DISPLAY_DATETIME_12H),
            sorter: true,
            key: GetCommunityEventsSortColumnDto.StartDate,
        },
        {
            title: t('end'),
            render: (event: CommunityEventDto) =>
                moment(event.periodEnd)
                    .tz(event.timeZone ?? '')
                    .format(DISPLAY_DATETIME_12H),
            key: GetCommunityEventsSortColumnDto.EndDate,
            sorter: true,
        },
        {
            title: t('title'),
            render: (event: CommunityEventDto) => event.title,
            key: GetCommunityEventsSortColumnDto.Title,
            sorter: true,
        },
        {
            title: t('CommunityEvent.event_speaker'),
            key: GetCommunityEventsSortColumnDto.SpeakerName,
            render: (event: CommunityEventDto) =>
                event.speakerMember
                    ? `${event.speakerMember?.firstName} ${event.speakerMember.lastName}`
                    : event.speakerName,
            sorter: true,
        },
        {
            title: t('company'),
            key: GetCommunityEventsSortColumnDto.SpeakerMembershipName,
            render: (event: CommunityEventDto) => event.speakerMembership?.name,
            sorter: true,
        },
        {
            title: t('CommunityEvent.event_host'),
            key: GetCommunityEventsSortColumnDto.HostName,
            render: (event: CommunityEventDto) => event.hostName,
            sorter: true,
        },
    ];

    const getAdvancedFilterType = (): {
        checkedTypes: GetCommunityEventsFlagDto[];
        typesCount: number;
    } => {
        const filterStore = filterStoreRef.current;
        const types = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'type'
        );
        const checkedTypes = types?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key;
            });

        return {
            checkedTypes:
                checkedTypes?.map(
                    (x) => GetCommunityEventsFlagDto[x as keyof typeof GetCommunityEventsFlagDto]
                ) ?? [],
            typesCount: types?.items.length ?? 0,
        };
    };

    const fetchCommunityEvents = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            currentLocationId?: string;
            advancedFilters?: AdvancedFilter[];
            sortColumn: GetCommunityEventsSortColumnDto | null;
            sortDirection: SortDirection | null;
            dateFilter: CommunityEventDateFilter;
        }) => {
            setLoading(true);
            try {
                const types = getAdvancedFilterType();
                if (types.checkedTypes.length === 0) {
                    setData([]);
                } else {
                    const request: GetCommunityEventsRequestDto = requestStore.setRequest({
                        request: {
                            searchTerm: params.searchTerm,
                            pageSize: params.pagination.pageSize || 0,
                            page: (params.pagination.current || 1) - 1,
                            sortColumn: params.sortColumn,
                            sortDirection: params.sortDirection,
                            flags:
                                types.checkedTypes.length === types.typesCount
                                    ? []
                                    : types.checkedTypes,
                            minStartDate: params.dateFilter.minStartDate,
                            maxStartDate: params.dateFilter.maxStartDate,
                            minEndDate: params.dateFilter.minEndDate,
                            maxEndDate: params.dateFilter.maxEndDate,
                            campusIds: [id],
                        },
                        requestType: RequestType.CommunityEvent,
                    });
                    const [data, totalItems] = await communityEventService.getCommunityEvents(
                        request
                    );
                    setData(data);
                    setPagination({
                        ...params.pagination,
                        total: totalItems,
                    });
                }
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [t]
    );

    const getDateFiltersForRangeType = (
        dateRangeType: DateRangeDropdownCommunityEventEnum
    ): CommunityEventDateFilter => {
        switch (dateRangeType) {
            case DateRangeDropdownCommunityEventEnum.Custom:
                return {
                    minStartDate: filterStoreRef.current.startDate,
                    maxEndDate: filterStoreRef.current.endDate,
                };
            case DateRangeDropdownCommunityEventEnum.Upcoming:
                return {
                    minStartDate: moment().format(MOMENT_PARSING_FORMAT),
                };
            case DateRangeDropdownCommunityEventEnum.Ongoing:
                return {
                    maxStartDate: moment().format(MOMENT_PARSING_FORMAT),
                    minEndDate: moment().format(MOMENT_PARSING_FORMAT),
                };
            case DateRangeDropdownCommunityEventEnum.OngoingAndUpcoming:
                return {
                    minEndDate: moment().format(MOMENT_PARSING_FORMAT),
                };
            case DateRangeDropdownCommunityEventEnum.Past:
                return {
                    maxEndDate: moment().format(MOMENT_PARSING_FORMAT),
                };
        }
    };

    const debounceSearch = useRef(
        debounce(
            (params: {
                searchTerm?: string;
                currentLocationId?: string;
                advancedFilters?: AdvancedFilter[];
                dateFilter: CommunityEventDateFilter;
            }) => {
                fetchCommunityEvents({
                    pagination: { ...paginationRef.current, current: 1 },
                    searchTerm: params.searchTerm,
                    currentLocationId: params.currentLocationId,
                    advancedFilters: params.advancedFilters,
                    sortColumn: null,
                    sortDirection: null,
                    dateFilter: params.dateFilter,
                });
            },
            TABLE_DEBOUNCE_DELAY
        )
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;

            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                currentLocationId: filterStore.currentLocationId,
                advancedFilters: filterStore.advancedFilters,
                dateFilter: getDateFiltersForRangeType(
                    filterStore.dateRange as DateRangeDropdownCommunityEventEnum
                ),
            });
        });
        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    useEffect(() => {
        if (
            requestStore?.requestInfo?.request &&
            requestStore?.requestInfo?.requestType === RequestType.CommunityEvent &&
            !requestSetFromCache
        ) {
            setRequestSetFromCache(true);
            const filterStore = filterStoreRef.current;
            const requestFromStore: GetCommunityEventsRequestDto = requestStore.requestInfo.request;
            const filtersList = [{ key: requestFromStore?.flags || [], parentKey: 'type' }];

            filterStore.updateSearchTerm(cleanVal.string(requestFromStore.searchTerm));
            filterStore.tickMultipleAdvancedFilter(filtersList);
        }
    }, [requestStore, userPermissionsStore.userInfo]);

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        const { currentLocationId, advancedFilters, searchTerm } = filterStoreRef.current;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        await fetchCommunityEvents({
            pagination,
            searchTerm,
            currentLocationId,
            advancedFilters,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
            dateFilter: getDateFiltersForRangeType(
                filterStoreRef.current.dateRange as DateRangeDropdownCommunityEventEnum
            ),
        });
        paginationRef.current = pagination;
    };

    useEffect(() => {
        const filterStore = filterStoreRef.current;
        filterStore.updateDateRange(DateRangeDropdownCommunityEventEnum.OngoingAndUpcoming);
        filterStore.startDate = moment().format(MOMENT_PARSING_FORMAT);
        filterStore.endDate = moment().format(MOMENT_PARSING_FORMAT);
    }, []);

    return (
        <div className="Events">
            <LocationHeader
                title={t('events')}
                subTitle={t('CommunityEvent.event_section_subtitle')}
                defaultImg={<Icon iconName="CommunityEvent" fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={
                    userPermissionsStore.availableLocationsForUser.some((loc) => loc.id === id) && (
                        <Button
                            type="primary"
                            onClick={(): void => {
                                setCreateModalOpen(true);
                            }}
                        >
                            {t('CommunityEvent.create_community_event')}
                        </Button>
                    )
                }
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeDates
                    dateRangeDropdownType={DateRangeDropdownCommunityEventEnum}
                    datesPrefix={{ start: t('start'), end: t('end') }}
                    includeSearch
                    includeAdvancedFilters
                />

                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    dataSource={data}
                    columns={columns}
                    loading={loading}
                    rowKey={(record: CommunityEventDto): string => record.id || ''}
                    pagination={pagination}
                    onChange={handleTableChange}
                    onRow={(row: CommunityEventDto) => ({
                        onClick: (): void => {
                            history.push(`/locations/${id}/events/${row.id}`);
                        },
                    })}
                />
            </Content>

            {createModalOpen && (
                <CreateCommunityEventModal
                    visible={createModalOpen}
                    onComplete={(success: boolean) => {
                        setCreateModalOpen(false);
                        if (success)
                            fetchCommunityEvents({
                                pagination: { ...paginationRef.current, current: 1 },
                                searchTerm: filterStoreRef.current.searchTerm,
                                currentLocationId: filterStoreRef.current.currentLocationId,
                                advancedFilters: filterStoreRef.current.advancedFilters,
                                sortColumn: null,
                                sortDirection: null,
                                dateFilter: getDateFiltersForRangeType(
                                    filterStoreRef.current
                                        .dateRange as DateRangeDropdownCommunityEventEnum
                                ),
                            });
                    }}
                />
            )}
        </div>
    );
});

export default Events;
