import { ProductTypeDto } from 'Api/Features/Invoices/Dtos/ProductTypeDto';
import * as yup from 'yup';

export const CreateManualInvoiceSchema = yup.object(
    Object.assign({
        campusId: yup.string().required('Errors.required'),
        invoiceDate: yup.date().required('Errors.required'),
        dueDate: yup
            .date()
            .required('Errors.required')
            .when(
                'invoiceDate',

                (invoiceDate: any, schema: any) =>
                    invoiceDate && schema.min(invoiceDate, 'Must be after invoice date')
            ),
        postedDate: yup
            .date()
            .required('Errors.required')
            .when(
                'invoiceDate',

                (invoiceDate: any, schema: any) =>
                    invoiceDate && schema.min(invoiceDate, 'Must be after invoice date')
            ),
        description: yup
            .string()
            .required('Errors.required')
            .max(100, 'Errors.must_be_under_one_hundred'),
        amount: yup.number().required('Errors.required').min(1, 'Errors.minimum_1_dollar'),
        productType: yup.string().required('Errors.required'),
        productId: yup
            .string()
            .optional()
            .nullable()
            .when('productType', {
                is: (productType) =>
                    productType === ProductTypeDto.AddOn || productType === ProductTypeDto.Plan,
                then: yup.string().required('Errors.required'),
            }),
    })
);
