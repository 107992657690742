import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const HammerLined: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.917 0.00268555L7.67355 1.24613L8.22744 1.7998L3.65867 6.36835L3.105 5.81468L1.85889 7.0608L6.01222 11.2141L7.25833 9.96802L6.70455 9.41424L8.28089 7.83802L14.2494 13.9242L15.6336 12.5396L9.66533 6.45346L11.2732 4.84569L11.8271 5.39935L13.073 4.15324L8.92244 0.00268555H8.917ZM8.50444 1.24613L8.91967 0.830574L12.2424 4.15324L11.8271 4.5688L8.50444 1.24613ZM4.074 6.78391L8.64267 2.21513L10.8578 4.43024L6.28922 8.99891L4.074 6.78391ZM2.68967 7.0608L3.105 6.64546L6.42767 9.96802L6.01222 10.3835L2.68967 7.0608ZM8.69611 7.4228L9.24978 6.86891L14.8071 12.5356L14.2534 13.0892L8.69611 7.4228Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.783 13.0188V14.1936H0V15.9556H7.83155V14.1936H6.89178V13.0188H0.783ZM1.37044 14.1937H6.30444V13.6061H1.37044V14.1937ZM0.587333 15.3683H7.24422V14.7809H0.587333V15.3683Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default HammerLined;
