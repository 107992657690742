import { BookingsReportProxy } from 'Api/Features/Reports/BookingsReportProxy';
import { BookingsReportDateDto } from 'Api/Features/Reports/Dtos/Bookings/BookingsReportDateDto';
import { GetBookingsReportRequestDto } from 'Api/Features/Reports/Dtos/Bookings/GetBookingsReportRequestDto';
import { inject } from 'aurelia-dependency-injection';
import { LoadingStore } from 'Stores';
import { arrayBufferToXlsx } from 'Utils/blobs';
import { ApiService } from './ApiService';

@inject(BookingsReportProxy, LoadingStore)
export class BookingReportService extends ApiService {
    constructor(private readonly bookingReportService: BookingsReportProxy) {
        super();
    }

    public async getBookingsReport(
        request: GetBookingsReportRequestDto | null
    ): Promise<{ dates: BookingsReportDateDto[] }> {
        const response = await this.bookingReportService.getBookingsReport(request);

        const nonNullResponse = response?.dates
            ? response?.dates.filter((date): date is BookingsReportDateDto => date !== null)
            : [];

        return { dates: nonNullResponse };
    }

    public async exportBookingsReportReport(
        request: GetBookingsReportRequestDto | null
    ): Promise<Blob> {
        const document = await this.bookingReportService.exportBookingsReportReport(request);
        return arrayBufferToXlsx(document);
    }
}
