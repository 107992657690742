import { Col, Layout, Row } from 'antd';
import { StoredFileDto } from 'Api/Features/General/Dtos/StoredFileDto';
import { LeadEventDto } from 'Api/Features/LeadEvents/Dtos/LeadEventDto';
import { CreateOpportunityFileRequestDto } from 'Api/Features/Opportunities/Dtos/CreateOpportunityFileRequestDto';
import { GetOpportunityFilesRequestDto } from 'Api/Features/Opportunities/Dtos/GetOpportunityFilesRequestDto';
import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';
import { UpdateOpportunityRequestDto } from 'Api/Features/Opportunities/Dtos/UpdateOpportunityRequestDto';
import { OpportunityEventDto } from 'Api/Features/OpportunityEvents/Dtos/OpportunityEventDto';
import { SalesActivityDto } from 'Api/Features/SalesActivities/Dtos/SalesActivityDto';
import { SalesActivityTypeDto } from 'Api/Features/SalesActivities/Dtos/SalesActivityTypeDto';
import AddFileModal, { AddFileFormModel } from 'Components/add-file-modal';
import EditNoteModal, { NoteEntityTypeEnum } from 'Components/edit-note-modal/edit-note-modal';
import { File, Tick } from 'Components/icons';
import Icon from 'Components/icons/icon';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import OpportunityActivityLog from 'Components/opportunity-activity-log/opportunity-activity-log';
import { OpportunityDetailStatusbar } from 'Components/opportunity-detail-statusbar';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import SimpleListContentWithSeeDetails from 'Components/SimpleListContentWithSeeDetails';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { MOMENT_PARSING_FORMAT } from 'Models/Constants';
import moment from 'moment';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import EditMeetingModal from 'Routes/authenticated/leads/id/edit-meeting';
import EditReminderModal from 'Routes/authenticated/leads/id/edit-reminder';
import { MeetingService } from 'Services/MeetingService';
import { OpportunityFileService } from 'Services/OpportunityFileService';
import { OpportunityService } from 'Services/OpportunityService';
import { ReminderService } from 'Services/ReminderService';
import { TaskService } from 'Services/TaskService';
import { TourService } from 'Services/TourService';
import { currencyFormatter } from 'Utils/NumberUtils';
import { displayFormatedDate } from 'Utils/TimeUtils';
import { OpportunityRouteProps } from '..';
import EditTaskModal from '../../../leads/id/edit-task';
import EditTourModal from '../modals/edit-tour';
import ScheduleATourModal from '../modals/schedule-a-tour';
import SendAgreementModal from '../modals/send-agreement';

interface OpportunitiesDetailProps {
    routeProps?: OpportunityRouteProps;
}

const { Content } = Layout;

const OpportunitiesDetail: FunctionComponent<OpportunitiesDetailProps> = ({ routeProps }) => {
    const [events, setEvents] = useState<(OpportunityEventDto | LeadEventDto)[] | undefined>(
        undefined
    );
    const [editTourModalOpen, setEditTourModalOpen] = useState(false);
    const [editTourId, setEditTourId] = useState<string>();
    const [editMeetingModalOpen, setEditMeetingModalOpen] = useState(false);
    const [editMeetingId, setEditMeetingId] = useState<string>();
    const [editReminderModalOpen, setEditReminderModalOpen] = useState(false);
    const [editReminderId, setEditReminderId] = useState<string>();
    const [scheduleATourModalOpen, setScheduleATourModalOpen] = useState(false);
    const [editTaskId, setEditTaskId] = useState<string>();
    const [editTaskModalOpen, setEditTaskModalOpen] = useState(false);
    const [addFileModalOpen, setAddFileModalOpen] = useState(false);
    const [files, setFiles] = useState<StoredFileDto[]>([]);
    const [filesAreLoading, setFilesAreLoading] = useState(false);
    const [filesMaxResults, setFilesMaxResults] = useState(false);
    const [filesCurrentPage, setFilesCurrentPage] = useState(0);
    const filesPageSize = 5;
    const [opportunityNote, setOpportunityNote] = useState<string | null | undefined>();

    const [sendAgreementModalOpen, setSendAgreementModalOpen] = useState(false);
    const [AgreementIsSigned, setAgreementIsSigned] = useState(false);
    const [editNoteModalOpen, setEditNoteModalOpen] = useState(false);
    const { globalLoadingStore, confirmationModalStore, toastStore } = useStores();
    const meetingService = useService(MeetingService);
    const reminderService = useService(ReminderService);
    const tourService = useService(TourService);
    const opportunityService = useService(OpportunityService);
    const taskService = useService(TaskService);
    const opportunityFileService = useService(OpportunityFileService);

    const {
        data,
        fetchData,
        setBreadcrumbs,
        setCreateProposalModalOpen,
        setMoveToQualifiedModalOpen,
        fetchProposalList,
        activities,
        fetchActivities,
    } = routeProps!;
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { t } = useTranslation();

    const getContactInformation = (): ReactNode => {
        return (
            <SimpleListContentWithSeeDetails
                title={`${data?.contact?.firstName} ${data?.contact?.lastName}`}
                description={data.contact?.email ?? undefined}
                description2={data.contact?.phoneNumber ?? undefined}
                imageUrl={data?.contact?.imageUrl ?? ''}
                defaultIcon={<Icon iconName="User" />}
                seeDetailsUrl={`/contacts/${data?.contact?.id}/dashboard`}
            />
        );
    };

    const locationInformation =
        data?.campus !== null
            ? [
                  {
                      title: `${data?.campus?.name}`,
                      avatar: (
                          <ImageWithPlaceholder
                              width="32"
                              height="32"
                              imgSrc={data?.campus?.mainImageUrl}
                              defaultImg={<Icon iconName="Location" />}
                          />
                      ),
                  },
              ]
            : null;

    const getCompanyInformation = (): ReactNode => {
        return (
            <SimpleListContentWithSeeDetails
                title={data.membership?.name ?? ''}
                imageUrl={data?.membership?.imageUrl ?? ''}
                defaultIcon={<Icon iconName="Company" />}
                seeDetailsUrl={`/companies/${data?.membership?.id}/dashboard`}
            />
        );
    };

    const lostData = data?.lostReason
        ? [
              {
                  title: t('Opportunity.opportunity_loss_reason'),
                  description: t(`Lead.loss_reason_${data.lostReason}`),
              },
          ]
        : undefined;

    const getOpportunityInformation = (): SimpleListItemProps[] => {
        const info = [];

        if (data?.ownerAccount?.firstName)
            info.push({
                title: t(`Opportunity.opportunity_owner`),
                description:
                    data.ownerAccount.firstName +
                    (data.ownerAccount.lastName ? ` ${data.ownerAccount.lastName}` : ''),
            });

        if (data?.probability)
            info.push({
                title: t(`Opportunity.probability`),
                description: `${data.probability}%`,
            });

        if (data?.closeDate)
            info.push({
                title: t(`Opportunity.close_date`),
                description: displayFormatedDate(data.closeDate, MOMENT_PARSING_FORMAT),
            });

        if (data?.amount)
            info.push({
                title: t(`amount`),
                description: currencyFormatter(data.amount),
            });

        return info;
    };

    const getDealInformation = (): SimpleListItemProps[] => {
        const info = [];

        if (data?.deskCount)
            info.push({
                title: t(`Opportunity.desk_count`),
                description: `${data?.deskCount}`,
            });

        if (data?.product)
            info.push({
                title: t(`Opportunity.product`),
                description: t(`Opportunity.product_${data?.product}`),
            });

        if (data?.salesChannel)
            info.push({
                title: t(`Opportunity.sales_channel`),
                description: t(`Opportunity.salesChannel_${data?.salesChannel}`),
            });

        if (data?.termLength)
            info.push({
                title: t(`Opportunity.term_length`),
                description: t(`Opportunity.term_${data?.termLength}`),
            });

        if (data?.monthlyRevenue)
            info.push({
                title: t(`Opportunity.monthly_revenue`),
                description: currencyFormatter(data?.monthlyRevenue, 0),
            });

        if (data?.officeNumber)
            info.push({
                title: t(`Lead.office_number`),
                description: `${data.officeNumber}`,
            });

        return info;
    };

    const getAdditionalInformation = (): SimpleListItemProps[] => {
        const info = [];

        if (data?.description)
            info.push({
                title: t(`description`),
                description: `${data.description}`,
            });

        if (data?.source) {
            const otherSource = data.sourceOther ? ' - ' + data.sourceOther : '';

            info.push({
                title: t(`Lead.source`),
                description: t(`Lead.source_${data.source}`) + otherSource,
            });
        }
        return info;
    };

    const currentDate = moment().utcOffset(0, true);
    const isUpcoming = (
        activity: SalesActivityDto,
        upcomingArray: SalesActivityDto[],
        pastArray: SalesActivityDto[],
        date?: string
    ) => {
        if (!currentDate.isSameOrAfter(date)) {
            upcomingArray.push(activity);
        } else {
            pastArray.push(activity);
        }
    };

    const upcomingMeetings: SalesActivityDto[] = [];
    const pastMeetings: SalesActivityDto[] = [];
    const upcomingTours: SalesActivityDto[] = [];
    const pastTours: SalesActivityDto[] = [];
    const upcomingReminders: SalesActivityDto[] = [];
    const pastReminders: SalesActivityDto[] = [];
    const upcomingTasks: SalesActivityDto[] = [];
    const pastTasks: SalesActivityDto[] = [];

    activities?.forEach((activity) => {
        switch (activity?.type) {
            case SalesActivityTypeDto.Meeting:
                isUpcoming(activity, upcomingMeetings, pastMeetings, activity.meeting?.startTime);
                break;
            case SalesActivityTypeDto.Tour:
                isUpcoming(activity, upcomingTours, pastTours, activity.tour?.startTime);
                break;
            case SalesActivityTypeDto.Reminder:
                isUpcoming(activity, upcomingReminders, pastReminders, activity.reminder?.date);
                break;
            case SalesActivityTypeDto.Task:
                isUpcoming(activity, upcomingTasks, pastTasks, activity.task?.date);
                break;
        }
    });

    let upcomingActivities: (SalesActivityDto | null)[] = [];
    if (upcomingTours && upcomingMeetings && upcomingReminders && upcomingTasks) {
        upcomingActivities = [
            ...upcomingTours,
            ...upcomingMeetings,
            ...upcomingReminders,
            ...upcomingTasks,
        ];
    }

    let pastActivities: (SalesActivityDto | null)[] = [];
    if (pastTours && pastMeetings && pastReminders && pastTasks) {
        pastActivities = [...pastTours, ...pastMeetings, ...pastReminders, ...pastTasks];
    }

    const fetchEvents = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            const [opportunityEvents] = await opportunityService.getOpportunityEventsById({
                pageSize: 10,
                page: 0,
                searchTerm: undefined,
                opportunityId: id,
            });
            if (opportunityEvents) setEvents(opportunityEvents);
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [id, opportunityService, globalLoadingStore]);

    useEffect(() => {
        fetchEvents();
    }, [editMeetingModalOpen, editReminderModalOpen, editTourModalOpen, fetchEvents, data]);

    const fetchNote = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            //call service
            const response = await opportunityService.getOpportunityNote(id);
            setOpportunityNote(response?.note);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, id, opportunityService, toastStore]);

    useEffect(() => {
        fetchNote();
    }, [fetchNote]);

    useEffect(() => {
        setBreadcrumbs([
            {
                nameKey: 'Opportunities',
                path: 'opportunities',
            },
            {
                name: data?.name ?? data?.membership?.name ?? '',
                path: `${id}/dashboard`,
            },
        ]);
    }, [data, id, setBreadcrumbs]);

    const onAddedActivityComplete = (): void => {
        fetchData();
        fetchActivities();
    };

    const onEditMeetingComplete = (): void => {
        fetchData();
        setEditMeetingId(undefined);
        setEditMeetingModalOpen(false);
        fetchActivities();
    };
    const onEditReminderComplete = (): void => {
        fetchData();
        setEditReminderId(undefined);
        setEditReminderModalOpen(false);
        fetchActivities();
    };
    const onEditTourComplete = (): void => {
        fetchData();
        setEditTourId(undefined);
        setEditTourModalOpen(false);
        fetchActivities();
    };
    const onEditTaskComplete = (): void => {
        fetchData();
        setEditTaskId(undefined);
        setEditTaskModalOpen(false);
        fetchActivities();
    };

    const getUpcomingMeeting = (): SalesActivityDto | undefined => {
        return upcomingMeetings ? upcomingMeetings[upcomingMeetings.length - 1]! : undefined;
    };

    const getUpcomingReminder = (): SalesActivityDto | undefined => {
        return upcomingReminders ? upcomingReminders[upcomingReminders.length - 1]! : undefined;
    };

    const getUpcomingTour = (): SalesActivityDto | undefined => {
        return upcomingTours ? upcomingTours[upcomingTours.length - 1]! : undefined;
    };

    const getUpcomingTask = (): SalesActivityDto | undefined => {
        return upcomingTasks ? upcomingTasks[upcomingTasks.length - 1]! : undefined;
    };

    const getMeetingToEdit = (): SalesActivityDto | undefined => {
        if (editMeetingId)
            return (
                activities
                    ?.filter((activity) => activity?.type === SalesActivityTypeDto.Meeting)
                    .find((activity) => activity?.meeting?.id === editMeetingId) || undefined
            );

        return getUpcomingMeeting();
    };

    const getReminderToEdit = (): SalesActivityDto | undefined => {
        if (editReminderId)
            return (
                activities
                    ?.filter((activity) => activity?.type === SalesActivityTypeDto.Reminder)
                    .find((activity) => activity?.reminder?.id === editReminderId) || undefined
            );

        return getUpcomingReminder();
    };

    const getTourToEdit = (): SalesActivityDto | undefined => {
        if (editTourId)
            return (
                activities
                    ?.filter((activity) => activity?.type === SalesActivityTypeDto.Tour)
                    .find((activity) => activity?.tour?.id === editTourId) || undefined
            );

        return getUpcomingTour();
    };

    const getTaskToEdit = (): SalesActivityDto | undefined => {
        if (editTaskId)
            return (
                activities
                    ?.filter((activity) => activity?.type === SalesActivityTypeDto.Task)
                    .find((activity) => activity?.task?.id === editTaskId) || undefined
            );

        return getUpcomingTask();
    };

    const cancelMeeting = async (id: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Coffee" />,
                title: t(`Lead.delete_meeting`),
                message: t(`Lead.delete_meeting_confirm_message`),
                positiveText: t(`Lead.delete_meeting`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        //call delete
        await meetingService.cancelMeeting(id);
        toastStore.toast({
            type: 'success',
            messageKey: `Lead.meeting_delete_success`,
        });

        fetchData();
        fetchEvents();
        fetchActivities();
    };

    const cancelReminder = async (id: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Coffee" />,
                title: t(`Lead.delete_reminder`),
                message: t(`Lead.delete_reminder_confirm_message`),
                positiveText: t(`Lead.delete_reminder`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        //call delete
        await reminderService.cancelReminder(id);
        toastStore.toast({
            type: 'success',
            messageKey: `Lead.reminder_delete_success`,
        });

        fetchData();
        fetchEvents();
        fetchActivities();
    };

    const cancelTour = async (id: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Coffee" />,
                title: t(`Lead.delete_tour`),
                message: t(`Lead.delete_tour_confirm_message`),
                positiveText: t(`Lead.delete_tour`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        //call delete
        await tourService.cancelTour(id);
        toastStore.toast({
            type: 'success',
            messageKey: `Lead.tour_delete_success`,
        });

        fetchData();
        fetchEvents();
        fetchActivities();
    };

    const cancelTask = async (id: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Tick />,
                title: t(`Lead.delete_task`),
                message: t(`Lead.delete_task_confirm_message`),
                positiveText: t(`Lead.delete_task`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        //call delete
        await taskService.cancelTask(id);
        toastStore.toast({
            type: 'success',
            messageKey: `Lead.task_delete_success`,
        });
        fetchData();
        fetchEvents();
        fetchActivities();
    };

    useEffect(() => {
        if (data?.status === OpportunityStatusDto.AgreementSigned) fetchProposalList();
    }, [fetchProposalList, data]);

    const resetFilesSearch = (): void => {
        setFilesAreLoading(true);
        setFilesMaxResults(false);
        setFiles([]);
        setFilesCurrentPage(0);
    };

    const handleFileUploadSubmit = async (
        isSubmiting: boolean,
        formModel?: AddFileFormModel
    ): Promise<void> => {
        if (isSubmiting && formModel) {
            try {
                globalLoadingStore.addLoading();
                await opportunityFileService.createOpportunityFile(
                    id,
                    formModel as CreateOpportunityFileRequestDto
                );
                toastStore.toast({
                    type: 'success',
                    messageKey: t('file_added_successfully'),
                });
                setAddFileModalOpen(false);
                resetFilesSearch();
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                debounceFetchFiles.current(0);
            } catch (e) {
                if (!e.treated) {
                    if (e.response.data.modelState['file.FileName'][0]) {
                        toastStore.toast({
                            type: 'error',
                            messageKey: e.response.data.modelState['file.FileName'][0],
                        });
                    } else {
                        toastStore.genericError();
                    }
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        } else {
            setAddFileModalOpen(false);
        }
    };

    const handleFileClick = (url?: string): void => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    const deleteFile = async (fileId: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <File />,
                title: t(`model_delete_confirm_title`, { param1: 'file' }),
                message: t(`action_cannot_be_undone`),
                positiveText: t('model_confirm_positive_delete', { param1: 'file' }),
                negativeText: t('model_confirm_negative_delete', { param1: 'file' }),
            }))
        )
            return;
        //call delete
        await opportunityFileService.deleteOpportunityFile(id, fileId);
        toastStore.toast({
            type: 'success',
            messageKey: t('model_delete_success', { param1: 'File' }),
        });
        resetFilesSearch();
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        debounceFetchFiles.current(0);
    };

    const handleFileDeleteClick = async (id?: string): Promise<void> => {
        if (id) {
            await deleteFile(id);
        }
    };

    const fetchFiles = async (currentPage: number): Promise<StoredFileDto[]> => {
        try {
            globalLoadingStore.addLoading();

            const [results, totalItemCount] = await opportunityFileService.getOpportunityFiles(id, {
                page: currentPage,
                pageSize: filesPageSize,
            } as GetOpportunityFilesRequestDto);

            if (results.length + filesPageSize * currentPage >= totalItemCount) {
                setFilesMaxResults(true);
            }

            return results;
        } finally {
            globalLoadingStore.removeLoading();
            setFilesAreLoading(false);
        }
    };

    const debounceFetchFiles = useRef(
        debounce((page: number) => {
            fetchFiles(page).then((results) => {
                setFiles((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    useEffect(() => {
        debounceFetchFiles.current(filesCurrentPage);
    }, [filesCurrentPage]);

    const handleFileListScrolledToBottom = (): void => {
        if (!filesMaxResults) {
            const currentPage = filesCurrentPage + 1;
            setFilesCurrentPage(currentPage);
        }
    };

    const handleAgreementSentClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            const request: UpdateOpportunityRequestDto = {
                ...routeProps?.data,
                status: OpportunityStatusDto.AgreementSent,
                ownerAccountId: routeProps?.data.ownerAccount?.id,
                campusId: routeProps?.data.campus?.id,
                contactId: routeProps?.data.contact?.id,
            };
            await opportunityService.updateOpportunity(id, request);
            toastStore.toast({
                type: 'success',
                messageKey: t('Opportunity.opportunity_edit_success'),
            });
            fetchData();
            fetchActivities();
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <>
            <Content className="opportunities-content">
                <OpportunityDetailStatusbar
                    opportunityStatus={data?.status || OpportunityStatusDto.New}
                    opportunityStatusActivityDate={
                        data?.lastStatusChangeDate
                            ? moment.parseZone(data.lastStatusChangeDate)
                            : undefined
                    }
                    setCreateProposalModalOpen={setCreateProposalModalOpen}
                    onProposalResponseClick={(): void => history.push('dashboard/proposals')}
                    setSendAgreementModalOpen={(visible: boolean, isSigned: boolean): void => {
                        setAgreementIsSigned(isSigned);
                        setSendAgreementModalOpen(visible);
                    }}
                    setScheduleATourModalOpen={setScheduleATourModalOpen}
                    setMoveToQualifiedModalOpen={setMoveToQualifiedModalOpen}
                    tourScheduledDate={
                        data?.status === OpportunityStatusDto.TourScheduled
                            ? activities
                                  ?.filter(
                                      (activity) => activity?.type === SalesActivityTypeDto.Tour
                                  )
                                  ?.find((activity) => activity?.tour)?.tour?.startTime
                            : undefined
                    }
                    setEditATourModalOpen={setEditTourModalOpen}
                    opportunityIsSubscriptionRenewal={data?.isSubscriptionRenewal}
                    opportunityIsSubscriptionModification={data?.isSubscriptionModification}
                    onAgreementSentClick={() => handleAgreementSentClick()}
                />

                <Row gutter={40}>
                    <Col span={12}>
                        {data?.contact && (
                            <Col span={24}>
                                <SimpleList title={t(`contact`)}>
                                    {getContactInformation()}
                                </SimpleList>
                            </Col>
                        )}

                        {data?.membership && (
                            <Col span={24}>
                                <SimpleList title={t(`company`)}>
                                    {getCompanyInformation()}
                                </SimpleList>
                            </Col>
                        )}

                        {locationInformation && (
                            <Col span={24}>
                                <SimpleList title={t(`location`)} data={locationInformation} />
                            </Col>
                        )}

                        <Col span={24}>
                            <SimpleList
                                title={t(`Opportunity.opportunity_information`)}
                                data={getOpportunityInformation()}
                                className="large-description"
                            />
                        </Col>

                        <Col span={24}>
                            <SimpleList
                                title={t(`Opportunity.deal_information`)}
                                data={getDealInformation()}
                                className="large-description"
                            />
                        </Col>

                        <Col span={24}>
                            <SimpleList
                                title={t(`Opportunity.additional_information`)}
                                data={getAdditionalInformation()}
                                className="large-description"
                            />
                        </Col>

                        {lostData && (
                            <Col span={24}>
                                <SimpleList
                                    title={t('Opportunity.opportunity_loss')}
                                    data={lostData}
                                    className={'large-description'}
                                />
                            </Col>
                        )}
                    </Col>

                    <Col span={12}>
                        <OpportunityActivityLog
                            events={events}
                            opportunity={data}
                            onAddedActivityComplete={onAddedActivityComplete}
                            upcomingActivities={upcomingActivities}
                            pastActivities={pastActivities}
                            onEditMeetingClick={(id: string): void => {
                                setEditMeetingId(id);
                                setEditMeetingModalOpen(true);
                            }}
                            onEditReminderClick={(id: string): void => {
                                setEditReminderId(id);
                                setEditReminderModalOpen(true);
                            }}
                            onEditTourClick={(id: string): void => {
                                setEditTourId(id);
                                setEditTourModalOpen(true);
                            }}
                            onEditTaskClick={(id: string): void => {
                                setEditTaskId(id);
                                setEditTaskModalOpen(true);
                            }}
                            onDeleteMeetingClick={(id: string): void => {
                                cancelMeeting(id);
                            }}
                            onDeleteReminderClick={(id: string): void => {
                                cancelReminder(id);
                            }}
                            onDeleteTourClick={(id: string): void => {
                                cancelTour(id);
                            }}
                            onDeleteTaskClick={(id: string): void => {
                                cancelTask(id);
                            }}
                            onAddFileClick={(): void => setAddFileModalOpen(true)}
                            files={files}
                            onFileClick={handleFileClick}
                            onFileDeleteClick={handleFileDeleteClick}
                            handleFileListScrolledToBottom={handleFileListScrolledToBottom}
                            filesAreLoading={filesAreLoading}
                            onEditNoteClick={(): void => setEditNoteModalOpen(true)}
                            note={opportunityNote}
                        />
                    </Col>
                </Row>
            </Content>

            {editMeetingModalOpen && (
                <EditMeetingModal
                    visible={editMeetingModalOpen}
                    onComplete={onEditMeetingComplete}
                    meeting={getMeetingToEdit()?.meeting}
                />
            )}

            {editReminderModalOpen && (
                <EditReminderModal
                    visible={editReminderModalOpen}
                    onComplete={onEditReminderComplete}
                    reminder={getReminderToEdit()?.reminder}
                />
            )}

            {scheduleATourModalOpen && (
                <ScheduleATourModal
                    visible={scheduleATourModalOpen}
                    onComplete={(): void => {
                        setScheduleATourModalOpen(false);
                        fetchData();
                    }}
                    opportunity={data}
                />
            )}

            {editTourModalOpen && (
                <EditTourModal
                    visible={editTourModalOpen}
                    onComplete={onEditTourComplete}
                    tour={getTourToEdit()?.tour}
                />
            )}

            {editTaskModalOpen && (
                <EditTaskModal
                    visible={editTaskModalOpen}
                    onComplete={onEditTaskComplete}
                    task={getTaskToEdit()?.task}
                />
            )}

            {sendAgreementModalOpen && (
                <SendAgreementModal
                    visible={sendAgreementModalOpen}
                    opportunity={data}
                    onComplete={(): void => {
                        setSendAgreementModalOpen(false);
                        fetchData();
                    }}
                    isSigned={AgreementIsSigned}
                />
            )}

            {addFileModalOpen && (
                <AddFileModal visible={addFileModalOpen} onComplete={handleFileUploadSubmit} />
            )}

            {editNoteModalOpen && (
                <EditNoteModal
                    visible={editNoteModalOpen}
                    onComplete={(success: boolean) => {
                        if (success) fetchNote();
                        setEditNoteModalOpen(false);
                    }}
                    entityId={data.id}
                    entityType={NoteEntityTypeEnum.Opportunity}
                    note={opportunityNote}
                />
            )}
        </>
    );
};

export default OpportunitiesDetail;
