import { Col, DatePicker, Input, Radio, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Gutter } from 'antd/lib/grid/row';
import { SubscriptionDetailsDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsDto';
import Icon from 'Components/icons/icon';
import NumberInput from 'Components/NumberInput/number-input';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { DISPLAY_MONTH_YEAR_FORMAT, MILLION, MOMENT_PARSING_FORMAT } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProposalDateType } from 'Routes/authenticated/proposals/proposal-form-section';
import { theme } from 'variant';
import './pricing.less';

interface DisabledDateRange {
    start: moment.Moment;
    end: moment.Moment;
}

interface PricingProps {
    index: number;
    errors?: Map<string, string[]>;
    remove: (index: number) => void;
    proposalStartDateType: ProposalDateType;
    proposalEndDateType: ProposalDateType;
    proposalStartDate?: moment.Moment;
    pricingStartDates?: (moment.Moment | undefined)[];
    proposalEndDate?: moment.Moment | null;
    suggestedPrice?: string;
    onPriceChange: (value: number, index: number) => void;
    onRemove: (index: number) => void;
    form: FormInstance;
    onDateChange: (value: moment.Moment | undefined, index: number) => void;
    isMtmPricing?: boolean;
    isConvertMtmPricing?: boolean;
    oldSubscription?: SubscriptionDetailsDto;
    onPricingTouched: (index: number) => void;
    ignoreOldSubValue?: boolean;
    isLastItem?: boolean;
}

const formGutter: [Gutter, Gutter] = [40, 0];

const Pricing: FunctionComponent<PricingProps> = ({
    index,
    errors,
    remove,
    proposalStartDateType,
    proposalEndDateType,
    proposalStartDate,
    pricingStartDates,
    proposalEndDate,
    suggestedPrice,
    onPriceChange,
    onRemove,
    form,
    onDateChange,
    isMtmPricing,
    isConvertMtmPricing,
    oldSubscription,
    onPricingTouched,
    ignoreOldSubValue,
    isLastItem,
}) => {
    const { t } = useTranslation();
    const [startDateType, setStartDateType] = useState<ProposalDateType>(ProposalDateType.Month);
    const [startDateValue, setStartDateValue] = useState<moment.Moment | null | undefined>(
        oldSubscription?.periods &&
            oldSubscription.periods[index] !== undefined &&
            !ignoreOldSubValue
            ? moment.utc(oldSubscription.periods[index]?.startDate)
            : undefined
    );

    const disabledDates = (): DisabledDateRange[] => {
        const disabledDates: DisabledDateRange[] = [];

        if (proposalStartDate) {
            disabledDates.push({
                start: moment.utc('0001-01-01'),
                end:
                    startDateType === ProposalDateType.Month
                        ? moment(proposalStartDate).endOf('month')
                        : moment(proposalStartDate).add(1, 'day'),
            });
        }
        if (proposalEndDate) {
            disabledDates.push({
                start: proposalEndDate,
                end: moment.utc('9999-01-01'),
            });
        }

        if (pricingStartDates) {
            disabledDates.push(
                ...pricingStartDates.map(
                    (date) =>
                        ({
                            start: moment(date?.format(MOMENT_PARSING_FORMAT)),
                            end:
                                startDateType === ProposalDateType.Month
                                    ? moment(date?.format(MOMENT_PARSING_FORMAT)).endOf('month')
                                    : moment(date?.format(MOMENT_PARSING_FORMAT)).add(1, 'day'),
                        } as DisabledDateRange)
                )
            );
        }

        return disabledDates.filter((date) => date !== undefined);
    };

    //#region Effect
    useEffect(() => {
        if (index === 0) setStartDateType(proposalStartDateType);
    }, [proposalStartDateType, index]);

    useEffect(() => {
        if (isMtmPricing) setStartDateType(proposalStartDateType);
    }, [isMtmPricing, proposalStartDateType]);

    useEffect(() => {
        if (isConvertMtmPricing) setStartDateType(ProposalDateType.Date);
    }, [isConvertMtmPricing, proposalEndDateType]);

    useEffect(() => {
        if (index === 0) {
            const formValues = form.getFieldsValue();
            formValues.periods.splice(0, 1, {
                startDate: proposalStartDate,
                amount: formValues.periods[0].amount,
            });
            form.setFieldsValue({
                periods: [...formValues.periods],
            });
        }
    }, [proposalStartDate, index, form]);

    //month to month pricing
    useEffect(() => {
        if (isMtmPricing) {
            form.setFieldsValue({
                mtmStartDate: proposalStartDate,
            });
        }
        if (isConvertMtmPricing && proposalEndDate) {
            form.setFieldsValue({
                mtmStartDate: moment(proposalEndDate).add(1, 'day'),
            });
        }
    }, [proposalStartDate, proposalEndDate, isMtmPricing, isConvertMtmPricing, form]);

    useEffect(() => {
        let invalid = false;
        if (index === 0) return;

        if (proposalStartDate) {
            if (
                startDateValue &&
                moment(startDateValue).format(MOMENT_PARSING_FORMAT) <=
                    moment(proposalStartDate).format(MOMENT_PARSING_FORMAT)
            )
                invalid = true;
        }
        if (proposalEndDate) {
            if (
                startDateValue &&
                moment(proposalEndDate).format(MOMENT_PARSING_FORMAT) <
                    moment(startDateValue).format(MOMENT_PARSING_FORMAT)
            )
                invalid = true;
        }

        if (invalid) {
            remove(index);
            onPricingTouched(isLastItem ? index : index + 1);
        }
    }, [
        proposalStartDate,
        proposalEndDate,
        index,
        remove,
        startDateValue,
        isLastItem,
        onPricingTouched,
    ]);

    useEffect(() => {
        if (oldSubscription?.periods && !ignoreOldSubValue) {
            const period = oldSubscription.periods[index];
            if (
                period?.startDate &&
                !moment
                    .utc(period.startDate)
                    .isSame(moment.utc(period.startDate).startOf('month'), 'day')
            ) {
                setStartDateType(ProposalDateType.Date);
            }
        }
    }, [oldSubscription, ignoreOldSubValue, index]);
    //#endregion Effect

    const getDatePickerType = (): ReactNode => {
        if (startDateType === ProposalDateType.Month) {
            return (
                <DatePicker
                    picker={'month'}
                    disabled={index === 0 || isMtmPricing || isConvertMtmPricing}
                    disabledDate={(current: moment.Moment): boolean =>
                        disabledDates().some((date) =>
                            current.isBetween(
                                moment(date['start'], MOMENT_PARSING_FORMAT),
                                moment(date['end'], MOMENT_PARSING_FORMAT)
                            )
                        )
                    }
                    onChange={(value): void => {
                        onDateChange(value?.startOf('month'), index);
                        setStartDateValue(value?.startOf('month'));
                        onPricingTouched(index);
                    }}
                    format={DISPLAY_MONTH_YEAR_FORMAT}
                />
            );
        } else {
            return (
                <DatePicker
                    disabled={index === 0 || isMtmPricing || isConvertMtmPricing}
                    disabledDate={(current: moment.Moment): boolean =>
                        disabledDates().some((date) =>
                            current.isBetween(
                                moment(date['start'], MOMENT_PARSING_FORMAT),
                                moment(date['end'], MOMENT_PARSING_FORMAT)
                            )
                        )
                    }
                    showToday={false}
                    onChange={(value): void => {
                        setStartDateValue(value);
                        onDateChange(moment(value), index);
                    }}
                />
            );
        }
    };

    return (
        <div className="Pricing">
            <div className="pricing-container">
                <Row gutter={formGutter}>
                    <Col span={12} className="custom-date-container">
                        <Radio.Group
                            onChange={(e): void => {
                                setStartDateType(e.target.value);
                            }}
                            value={startDateType}
                            className="custom-date-actions"
                            disabled={index === 0 || isMtmPricing || isConvertMtmPricing}
                        >
                            <Radio.Button value={ProposalDateType.Month}>
                                {ProposalDateType.Month}
                            </Radio.Button>
                            <Radio.Button value={ProposalDateType.Date}>
                                {ProposalDateType.Date}
                            </Radio.Button>
                        </Radio.Group>
                        <ValidatedFormItem
                            name={
                                isMtmPricing || isConvertMtmPricing
                                    ? 'mtmStartDate'
                                    : [index, 'startDate']
                            }
                            errors={errors}
                            label={
                                isMtmPricing || isConvertMtmPricing
                                    ? t('mtm_price')
                                    : startDateType === ProposalDateType.Month
                                    ? t('start_month')
                                    : t('start_date')
                            }
                            required
                            rules={[
                                { required: true, message: t('Proposal.start_month_required') },
                            ]}
                        >
                            {getDatePickerType()}
                        </ValidatedFormItem>
                    </Col>

                    <Col span={12} className="custom-price-input-container">
                        <Input
                            disabled
                            className="custom-price-suggested"
                            addonBefore={t('suggested')}
                            value={suggestedPrice}
                        />
                        <ValidatedFormItem
                            name={
                                isMtmPricing || isConvertMtmPricing
                                    ? 'monthToMonthAmount'
                                    : [index, 'amount']
                            }
                            errors={errors}
                            label={t('price')}
                            className="custom-price-actual"
                            required
                            rules={[{ required: true, message: t('Proposal.price_required') }]}
                        >
                            <NumberInput
                                min={0}
                                addonBefore={t('actual')}
                                addonAfter={t('monthly')}
                                type={'number'}
                                step={0.01}
                                max={MILLION}
                                onChange={(value): void => onPriceChange(Number(value), index)}
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>
            </div>

            <span
                className="garbage-container"
                onClick={(): void => {
                    if (index !== 0) {
                        remove(index);
                        onRemove(index);
                        onPricingTouched(isLastItem ? index : index + 1);
                    }
                }}
            >
                <Icon
                    iconName="Delete"
                    fill={index <= 0 || isMtmPricing ? theme['black-4'] : theme['primary-color']}
                />
            </span>
        </div>
    );
};

export default Pricing;
