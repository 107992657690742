import { AgedReceivablesReportProxy } from 'Api/Features/Reports/AgedReceivablesReportProxy';
import { AgedReceivablesReportDto } from 'Api/Features/Reports/Dtos/AgedReceivables/AgedReceivablesReportDto';
import { GetAgedReceivablesReportRequestDto } from 'Api/Features/Reports/Dtos/AgedReceivables/GetAgedReceivablesReportRequestDto';
import { inject } from 'aurelia-dependency-injection';
import { arrayBufferToXlsx } from 'Utils/blobs';
import { ApiService } from './ApiService';

@inject(AgedReceivablesReportProxy)
export class AgedReceivablesReportService extends ApiService {
    constructor(private readonly agedReceivablesReportProxy: AgedReceivablesReportProxy) {
        super();
    }

    public async getAgedReceivablesReport(
        request: GetAgedReceivablesReportRequestDto | null
    ): Promise<AgedReceivablesReportDto | null> {
        return await this.agedReceivablesReportProxy.getAgedReceivablesReport(request);
    }

    public async exportAgedReceivablesReport(
        request: GetAgedReceivablesReportRequestDto | null
    ): Promise<Blob> {
        const document = await this.agedReceivablesReportProxy.exportAgedReceivablesReport(request);

        return arrayBufferToXlsx(document);
    }
}
