import { CampusProxy } from 'Api/Features/Campuses/CampusProxy';
import { CampusDetailsDto } from 'Api/Features/Campuses/Dtos/CampusDetailsDto';
import { CampusDto } from 'Api/Features/Campuses/Dtos/CampusDto';
import { CampusIntegrationStatusDto } from 'Api/Features/Campuses/Dtos/CampusIntegrationStatusDto';
import { CampusOccupancyObjectiveDto } from 'Api/Features/Campuses/Dtos/CampusOccupancyObjectiveDto';
import { CampusSageAccountingSyncConfigDto } from 'Api/Features/Campuses/Dtos/CampusSageAccountingSyncConfigDto';
import { CampusSettingsDto } from 'Api/Features/Campuses/Dtos/CampusSettingsDto';
import { CampusStripeConfigDto } from 'Api/Features/Campuses/Dtos/CampusStripeConfigDto';
import { CampusUnitPricingDto } from 'Api/Features/Campuses/Dtos/CampusUnitPricingDto';
import { ConfirmCampusStripeConnectConfigurationRequestDto } from 'Api/Features/Campuses/Dtos/ConfirmCampusStripeConnectConfigurationRequestDto';
import { CreateCampusRequestDto } from 'Api/Features/Campuses/Dtos/CreateCampusRequestDto';
import { GetCampusesRequestDto } from 'Api/Features/Campuses/Dtos/GetCampusesRequestDto';
import { GetCampusIntegrationStatusesRequestDto } from 'Api/Features/Campuses/Dtos/GetCampusIntegrationStatusesRequestDto';
import { GetCampusIntegrationStatusesResponseDto } from 'Api/Features/Campuses/Dtos/GetCampusIntegrationStatusesResponseDto';
import { GetCampusOnboardingConfigResponseDto } from 'Api/Features/Campuses/Dtos/GetCampusOnboardingConfigResponseDto';
import { GetCampusOrderResponseDto } from 'Api/Features/Campuses/Dtos/GetCampusOrderResponseDto';
import { GetCampusStripeConnectAuthorizationUrlResponseDto } from 'Api/Features/Campuses/Dtos/GetCampusStripeConnectAuthorizationUrlResponseDto';
import { GetUserHomeCampusRequestDto } from 'Api/Features/Campuses/Dtos/GetUserHomeCampusRequestDto';
import { LightCampusDetailDto } from 'Api/Features/Campuses/Dtos/LightCampusDetailDto';
import { UpdateCampusOccupancyObjectiveRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusOccupancyObjectiveRequestDto';
import { UpdateCampusOnboardingConfigRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusOnboardingConfigRequestDto';
import { UpdateCampusOrderRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusOrderRequestDto';
import { UpdateCampusRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusRequestDto';
import { UpdateCampusSageAccountingSyncConfigRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusSageAccountingSyncConfigRequestDto';
import { UpdateCampusSettingsRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusSettingsRequestDto';
import { UpdateCampusStatusRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusStatusRequestDto';
import { UpdateCampusUnitPricingRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusUnitPricingRequestDto';
import { inject } from 'aurelia-dependency-injection';
import { LoadingStore } from 'Stores';
import { ApiService } from './ApiService';

export interface GetCampusIntegrationStatusesResponse {
    items: CampusIntegrationStatusDto[];
    totalItemCount: number;
}

@inject(CampusProxy, LoadingStore)
export class CampusService extends ApiService {
    constructor(private readonly campusProxy: CampusProxy) {
        super();
    }

    public async getCampuses(
        request: GetCampusesRequestDto | null
    ): Promise<(LightCampusDetailDto | null)[] | null> {
        return await this.campusProxy.getCampuses(request);
    }

    public async getCampus(campusId: string | null): Promise<CampusDetailsDto | null> {
        return this.campusProxy.getCampus(campusId);
    }

    public async getCurrentUserHomeCampus(
        request?: GetUserHomeCampusRequestDto | null
    ): Promise<CampusDto | null> {
        return this.campusProxy.getCurrentUserHomeCampus(request);
    }

    public async getCampusRulesAndAgreement(campusId: string | null): Promise<string | null> {
        return this.campusProxy.getCampusRulesAndAgreement(campusId);
    }

    public async getCampusTime(campusId: string | null): Promise<string> {
        return this.campusProxy.getCampusTime(campusId);
    }

    public async getCampusesSettings(
        campusIds: (string | null)[] | null
    ): Promise<Record<string, unknown> | null> {
        return this.campusProxy.getCampusesSettings(campusIds);
    }

    public async getCampusSettings(campusId: string | null): Promise<CampusSettingsDto | null> {
        return this.campusProxy.getCampusSettings(campusId);
    }

    public async updateCampusSettings(
        campusId: string | null,
        request: UpdateCampusSettingsRequestDto | null
    ): Promise<void> {
        await this.campusProxy.updateCampusSettings(campusId, request);
    }

    public async getCampusOnboardingConfig(
        campusId: string | null
    ): Promise<GetCampusOnboardingConfigResponseDto | null> {
        return this.campusProxy.getCampusOnboardingConfig(campusId);
    }

    public async updateCampusOnboardingConfig(
        campusId: string | null,
        request: UpdateCampusOnboardingConfigRequestDto | null
    ): Promise<void> {
        await this.campusProxy.updateCampusOnboardingConfig(campusId, request);
    }

    public async removeCampusStripeConnectConfiguration(campusId: string): Promise<void> {
        await this.campusProxy.removeCampusStripeConnectConfiguration(campusId);
    }

    public async getCampusIntegrationStatuses(
        request: GetCampusIntegrationStatusesRequestDto
    ): Promise<GetCampusIntegrationStatusesResponse> {
        const response: GetCampusIntegrationStatusesResponseDto | null =
            await this.campusProxy.getCampusIntegrationStatuses(request);

        const nonNullResponse: CampusIntegrationStatusDto[] = response?.items
            ? response.items.filter((item): item is CampusIntegrationStatusDto => item !== null)
            : [];

        return { items: nonNullResponse, totalItemCount: response?.totalItemCount || 0 };
    }

    public async getCampusSageAccountingSyncConfig(
        campusId: string
    ): Promise<CampusSageAccountingSyncConfigDto> {
        const response: CampusSageAccountingSyncConfigDto | null =
            await this.campusProxy.getCampusSageAccountingSyncConfig(campusId);

        const nonNullResponse = {
            accountingSyncEnabled: response?.accountingSyncEnabled,
            accountingSyncStartDate: response?.accountingSyncStartDate,
            sageLocationEntityId: response?.sageLocationEntityId,
            sageLocationEntityName: response?.sageLocationEntityName,
            sageCompanyId: response?.sageCompanyId,
        };

        return nonNullResponse;
    }

    public async updateCampusSageAccountingSyncConfig(
        campusId: string,
        request: UpdateCampusSageAccountingSyncConfigRequestDto
    ): Promise<void> {
        await this.campusProxy.updateCampusSageAccountingSyncConfig(campusId, request);
    }

    public async getCampusStripeConfig(campusId: string): Promise<CampusStripeConfigDto> {
        const response: CampusStripeConfigDto | null = await this.campusProxy.getCampusStripeConfig(
            campusId
        );

        const nonNullResponse = {
            isDefaultAccount: response?.isDefaultAccount,
            stripeConnectedAccountName: response?.stripeConnectedAccountName,
        };

        return nonNullResponse;
    }

    public async getCampusStripeConnectAuthorizationUrl(campusId: string): Promise<string> {
        const data: GetCampusStripeConnectAuthorizationUrlResponseDto | null =
            await this.campusProxy.getCampusStripeConnectAuthorizationUrl(campusId);
        return data?.stripeConnectAuthorizationUrl || '';
    }

    public async confirmCampusStripeConnectConfiguration(
        campusId: string,
        request: ConfirmCampusStripeConnectConfigurationRequestDto
    ): Promise<void> {
        await this.campusProxy.confirmCampusStripeConnectConfiguration(campusId, request);
    }

    public async createCampus(request: CreateCampusRequestDto | null): Promise<string> {
        const id = await this.campusProxy.createCampus(request);
        return id;
    }

    public async updateCampus(
        campusId: string | null,
        request: UpdateCampusRequestDto | null
    ): Promise<void> {
        await this.campusProxy.updateCampus(campusId, request);
    }
    
    public async getCampusOrdering(): Promise<GetCampusOrderResponseDto | null> {
        return await this.campusProxy.getCampusOrdering();
    }

    public async updateCampusOrdering(request: UpdateCampusOrderRequestDto | null): Promise<void> {
        await this.campusProxy.updateCampusOrdering(request);
    }

    public async updateCampusStatus(
        campusId: string | null,
        request: UpdateCampusStatusRequestDto | null
    ): Promise<void> {
        await this.campusProxy.updateCampusStatus(campusId, request);
    }

    public async getUnitPricing(campusId: string | null): Promise<CampusUnitPricingDto | null> {
        return await this.campusProxy.getUnitPricing(campusId);
    }

    public async updateUnitPricing(
        campusId: string | null,
        request: UpdateCampusUnitPricingRequestDto | null
    ): Promise<void> {
        return await this.campusProxy.updateUnitPricing(campusId, request);
    }

    public async getOccupancyObjective(
        campusId: string | null
    ): Promise<CampusOccupancyObjectiveDto | null> {
        const data: CampusOccupancyObjectiveDto | null =
            await this.campusProxy.getOccupancyObjective(campusId);
        return data;
    }

    public async updateOccupancyObjective(
        campusId: string | null,
        request: UpdateCampusOccupancyObjectiveRequestDto | null
    ): Promise<void> {
        await this.campusProxy.updateOccupancyObjective(campusId, request);
    }
}
