import { Col, Input, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import Title from 'antd/lib/typography/Title';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { BILLION } from 'Models/Constants';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

const formGutter: [Gutter, Gutter] = [40, 0];

const EditPricingFormSection: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <>
            <Title level={4}>{t('Unit.unit_communal_areas')}</Title>
            <div className="explanation">{t('Unit.base_price_explanation')}</div>

            <Row gutter={formGutter}>
                <Col span={12}>
                    <ValidatedFormItem
                        name="communalAreaBasePrice"
                        label={t('Unit.communal_area_base_price')}
                    >
                        <NumberFormat
                            allowNegative={false}
                            customInput={(props: any) => <Input addonBefore={'$'} {...props} />}
                            isAllowed={(value: NumberFormatValues): boolean =>
                                value.floatValue === undefined ||
                                (value.floatValue !== undefined && value.floatValue <= BILLION)
                            }
                        />
                    </ValidatedFormItem>
                </Col>
            </Row>

            <Title level={4}>{t('Unit.unit_standard_unit')}</Title>
            <div className="explanation">{t('Unit.price_desk_square_foot_explanation')}</div>

            <Row gutter={formGutter}>
                <Col span={12}>
                    <ValidatedFormItem
                        name="pricePerDesk"
                        label={t('Location.price_per_desk')}
                    >
                        <NumberFormat
                            allowNegative={false}
                            customInput={(props: any) => <Input addonBefore={'$'} {...props} />}
                            isAllowed={(value: NumberFormatValues): boolean =>
                                value.floatValue === undefined ||
                                (value.floatValue !== undefined && value.floatValue <= BILLION)
                            }
                        />
                    </ValidatedFormItem>
                </Col>
                <Col span={12}>
                    <ValidatedFormItem
                        name="pricePerSquareFoot"
                        label={t('Location.price_per_sq_ft')}
                    >
                        <NumberFormat
                            allowNegative={false}
                            customInput={(props: any) => <Input addonBefore={'$'} {...props} />}
                            isAllowed={(value: NumberFormatValues): boolean =>
                                value.floatValue === undefined ||
                                (value.floatValue !== undefined && value.floatValue <= BILLION)
                            }
                        />
                    </ValidatedFormItem>
                </Col>
            </Row>
        </>
    );
};

export default React.memo(EditPricingFormSection);
