import { DayPassProxy } from 'Api/Features/DayPasses/DayPassProxy';
import { CreateDayPassRequestDto } from 'Api/Features/DayPasses/Dtos/CreateDayPassRequestDto';
import { DayPassDto } from 'Api/Features/DayPasses/Dtos/DayPassDto';
import { GetDayPassesRequestDto } from 'Api/Features/DayPasses/Dtos/GetDayPassesRequestDto';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(DayPassProxy)
export class DayPassService extends ApiService {
    constructor(private readonly dayPassProxy: DayPassProxy) {
        super();
    }

    public async getDayPasses(params: {
        ids?: string[] | null;
        accountId?: string | null;
        membershipId?: string | null;
        consumableByAccountId?: string | null;
        available?: boolean | null;
        page?: number | null;
        pageSize?: number | null;
    }): Promise<[DayPassDto[], number]> {
        const request: GetDayPassesRequestDto = {
            ids: params.ids,
            accountId: params.accountId,
            membershipId: params.membershipId,
            consumableByAccountId: params.consumableByAccountId,
            available: params.available,
            page: params.page,
            pageSize: params.pageSize
        }
        const response = await this.dayPassProxy.getDayPasses(
            request
        );
        const nonNullDayPasses =
            response && response.items
                ? response.items.filter((item): item is DayPassDto => item !== null)
                : [];

        return [nonNullDayPasses, response?.totalItemCount ?? 0];
    }

    public async createDayPass(request: CreateDayPassRequestDto | null): Promise<string> {
        const data: string = await this.dayPassProxy.createDayPass(request);
        return data;
    }
}
