import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App/App';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { HttpClient } from 'HttpClient/HttpClient';
import { Container } from 'aurelia-dependency-injection';
import { EventBus } from 'ts-bus';
import 'reflect-metadata';
import 'mobx-react-lite/batchingForReactDom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MaterialTheme from 'Data/MaterialTheme';
import { Environment } from 'Misc/Environment';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "AppInsights";

// Import variant specific imports.
import './variant';

// Setup i18n
import 'i18n';

declare global {
    interface Window {
        Environment: Environment;
    }
}

// Setup DI
const rootContainer = new Container();
rootContainer.makeGlobal();

// Register the object containing environment variables.
if (!window.Environment) {
    throw new Error('Environment object not found.');
}
rootContainer.registerInstance(Environment, window.Environment);

// Register HttpClient under both HttpClient and ApiHttpClient.
rootContainer.registerSingleton(HttpClient, HttpClient);
const httpClient = rootContainer.get(HttpClient);
rootContainer.registerInstance(ApiHttpClient, httpClient);

rootContainer.registerSingleton(EventBus, EventBus);

// Go!
ReactDOM.render(
    <React.StrictMode>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <MuiThemeProvider theme={MaterialTheme}>
                <AppInsightsContext.Provider value={reactPlugin}>
                    <App />
                </AppInsightsContext.Provider>
            </MuiThemeProvider>
        </MuiPickersUtilsProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
