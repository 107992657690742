import { AgreementTermsProxy } from 'Api/Features/AgreementTerms/AgreementTermsProxy';
import { AgreementTermsDocumentDto } from 'Api/Features/AgreementTerms/Dtos/AgreementTermsDocumentDto';
import { CreateAgreementTermsRequestDto } from 'Api/Features/AgreementTerms/Dtos/CreateAgreementTermsRequestDto';
import { GetAgreementTermsRequestDto } from 'Api/Features/AgreementTerms/Dtos/GetAgreementTermsRequestDto';
import { GetAgreementTermsResponseDto } from 'Api/Features/AgreementTerms/Dtos/GetAgreementTermsResponseDto';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(AgreementTermsProxy)
export class AgreementTermService extends ApiService {
    constructor(private readonly agreementTermsProxy: AgreementTermsProxy) {
        super();
    }

    public async createAgreementTerms(
        request: CreateAgreementTermsRequestDto
    ): Promise<string | null> {
        return await this.agreementTermsProxy.createAgreementTerms(request);
    }

    public async getAgreementTerms(
        request: GetAgreementTermsRequestDto
    ): Promise<GetAgreementTermsResponseDto | null> {
        return await this.agreementTermsProxy.getAgreementTerms(request);
    }

    public async getAgreementTermsDocument(
        agreementTermsId: string
    ): Promise<AgreementTermsDocumentDto | null> {
        return await this.agreementTermsProxy.getAgreementTermsDocument(agreementTermsId);
    }
}
