import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const FloorPlanIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 38 38" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.25 0.25V38H38V0.25H0.25ZM8.25 36.25V26.25C8.25 25.75 8 25.5 7.5 25.5C7 25.5 6.75 25.75 6.75 26.25V36.25H1.75V10.5H14.25C14.75 10.5 15 10.25 15 9.75C15 9.25 14.75 9 14.25 9H1.75V1.75H36.25V23.5H24.5V13.25C24.5 12.75 24.25 12.5 23.75 12.5C23.25 12.5 23 12.75 23 13.25V23.75H16.5C16 23.75 15.75 24 15.75 24.5C15.75 25 16 25.25 16.5 25.25H36.25V36.25H8.25Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default FloorPlanIcon;