import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CG: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg
                width="1em"
                height="1em"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M41.9922 26.8325L41.9283 27.1878C40.3476 36.0194 32.8045 42.3873 23.9823 42.3873C13.9304 42.3873 5.75354 34.1082 5.75354 23.9317C5.75354 13.7552 13.9304 5.4761 23.9823 5.4761C32.8045 5.4761 40.3476 11.844 41.9178 20.6194L41.9817 20.9747L42.3431 20.9652L43.7175 20.7128L43.6525 20.3564C41.922 10.6816 33.654 3.69958 23.9823 3.69958C12.9646 3.69958 4 12.7763 4 23.9317C4 35.0882 12.9646 44.1638 23.9823 44.1638C33.654 44.1638 41.922 37.1808 43.6431 27.5611L43.707 27.2047L43.3655 27.0859L41.9922 26.8325Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.9826 13.8638C28.2627 13.8638 32.0536 16.6277 33.4144 20.7429L33.5285 21.0855L33.8836 21.0239L34.8704 20.6899L35.2098 20.5743L35.0946 20.2306C33.4772 15.3391 29.019 12.0883 23.9826 12.0883C17.532 12.0883 12.285 17.4009 12.285 23.9321C12.285 30.4623 17.532 35.7749 23.9826 35.7749C30.4321 35.7749 35.6801 30.4623 35.6801 23.9321V23.0444H24.7954V24.8199H33.8878C33.4416 29.9967 29.1615 33.9994 23.9826 33.9994C18.4999 33.9994 14.0385 29.4833 14.0385 23.9321C14.0385 18.3799 18.4999 13.8638 23.9826 13.8638Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M37.5707 24.8192H47.9903V23.0438H37.5707V24.8192Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default CG;
