import { observable, action, computed } from 'mobx';
import { LightCampusDetailsInfo } from 'Models/Location/LightCampusInfo';
import { inject } from 'aurelia-dependency-injection';
import { LocationService } from 'Services/LocationService';

@inject(LocationService)
class LocationStore {
    constructor(private readonly locationService: LocationService) {}

    @observable
    private _locations: LightCampusDetailsInfo[] = [];

    @computed
    get locations(): LightCampusDetailsInfo[] {
        return this._locations;
    }

    @action
    async setLocations(): Promise<void> {
        const locations = await this.locationService.getCampuses(null);
        this._locations = [...locations?.map(
            (x) =>
                ({
                    id: x.id,
                    name: x.name,
                    mainImageUrl: x.mainImageUrl,
                    status: x.status,
                    timeZone: x.timeZone,
                } as LightCampusDetailsInfo)
        )]
    }
}

export default LocationStore;
