import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Bookmark: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 29 40" fill="none" {...props}>
                <path
                    d="M0.0416667 40L0 0H28.3722V40L14.1889 29.8972L0.0416667 40ZM14.1861 27.9778L26.8194 36.9833V1.55278H1.55556L1.59444 36.9944L14.1861 27.9778Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Bookmark;
