import { GetManagerUsersRequestDto } from 'Api/Features/ManagerUsers/Dtos/GetManagerUsersRequestDto';
import { GetManagerUsersResponseDto } from 'Api/Features/ManagerUsers/Dtos/GetManagerUsersResponseDto';
import { ManagerUserDto } from 'Api/Features/ManagerUsers/Dtos/ManagerUserDto';
import { inject } from 'aurelia-dependency-injection';
import { LightCampusDetailsInfo } from 'Models/Location/LightCampusInfo';
import { LocationNotificationPref } from 'Models/ManagerUsers/LocationNotificationPref';
import { useTranslation } from 'react-i18next';
import { LocationStore } from 'Stores';
import { GetManagerUserPreferencesResponseDto } from '../Api/Features/ManagerUserPreferences/Dto/GetManagerUserPreferencesResponseDto';
import { ManagerUserPreferenceProxy } from '../Api/Features/ManagerUserPreferences/ManagerUserPreferenceProxy';
import { CreateManagerUserRequestDto } from '../Api/Features/ManagerUsers/Dtos/CreateManagerUserRequestDto';
import { UpdateManagerUserRequestDto } from '../Api/Features/ManagerUsers/Dtos/UpdateManagerUserRequestDto';
import { ManagerUserProxy } from '../Api/Features/ManagerUsers/ManagerUserProxy';
import { ManagerUser } from '../Models/ManagerUsers/ManagerUser';
import { ApiService } from './ApiService';

@inject(ManagerUserProxy, LocationStore, ManagerUserPreferenceProxy)
export class ManagerUserService extends ApiService {
    constructor(
        private readonly managerUserProxy: ManagerUserProxy,
        private readonly locationStore: LocationStore,
        private readonly managerUserPreferenceProxy: ManagerUserPreferenceProxy
    ) {
        super();
    }

    public async getManagerUsers({
        page,
        pageSize,
        searchTerm,
        role,
        campusId,
    }: GetManagerUsersRequestDto): Promise<[ManagerUser[], number]> {
        const response: GetManagerUsersResponseDto | null = await this.managerUserProxy.getManagerUsers(
            {
                page: page,
                pageSize: pageSize,
                searchTerm: searchTerm,
                role: role,
                campusId: campusId,
            }
        );
        return [
            response?.items?.map((dto) => new ManagerUser(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async getById(id: string): Promise<ManagerUser | null> {
        const response: ManagerUserDto | null = await this.managerUserProxy.getManagerUser(id);

        return response ? new ManagerUser(response!) : null;
    }

    public async userIsManager(id: string): Promise<boolean> {
        let response = true;
        try {
            await this.managerUserProxy.getManagerUser(id);
        } catch {
            response = false;
        }

        return response;
    }

    public async createManagerUser(
        request: CreateManagerUserRequestDto,
        preferences: LocationNotificationPref[]
    ): Promise<void> {
        const id = await this.managerUserProxy.createManagerUser(request);
        await this.setPreferences(id!, preferences);
    }

    public async editManagerUser(
        id: string,
        request: UpdateManagerUserRequestDto,
        preferences: LocationNotificationPref[]
    ): Promise<void> {
        await this.managerUserProxy.updateManagerUser(id, request);
        await this.setPreferences(id, preferences);
    }

    public async getPreferences(id: string): Promise<GetManagerUserPreferencesResponseDto> {
        const response: GetManagerUserPreferencesResponseDto | null = await this.managerUserPreferenceProxy.getManagerUserPreferences(
            id
        );
        return response || { items: [] };
    }

    public async setPreferences(
        id: string,
        preferences: LocationNotificationPref[]
    ): Promise<void> {
        await this.managerUserPreferenceProxy.setManagerUserPreferences(id, {
            items: preferences
                .filter((pref) => pref.checked)
                .map((pref) => ({
                    key: 'EnableNotifications',
                    context: pref.location.id,
                    value: pref.checked.toString(),
                })),
        });
    }

    public getManagerForCampusesString(campudIds: string[]): string {
        const { t } = useTranslation();
        const campusNames = this.locationStore.locations
            .filter((x: LightCampusDetailsInfo) => campudIds.find((y) => y === x.id))
            .map((x: LightCampusDetailsInfo) => x.name)
            .join(', ');

        return `${t('ManagerUser.management_role_Manager')} (${campusNames})`;
    }

    public async deleteManagerUser(managerUserId: string | null): Promise<void> {
        await this.managerUserProxy.deleteManagerUser(managerUserId);
    }
}
