import React, { FunctionComponent } from 'react';
import { Layout } from 'antd';

import { SideNavigation } from '../side-navigation';
import './authenticated-layout.less';

const AuthenticatedLayout: FunctionComponent = ({ children }) => {
    return (
        <Layout className="AuthenticatedLayout">
            <SideNavigation />
            <Layout className="site-layout">{children}</Layout>
        </Layout>
    );
};

export default AuthenticatedLayout;
