import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Close: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.63 7.933l6.76-6.76a.541.541 0 00-.765-.765l-6.76 6.76L1.174.477a.541.541 0 00-.766.765L7.1 7.933.408 14.624a.541.541 0 00.766.766l6.69-6.691 6.76 6.76a.54.54 0 10.766-.766l-6.76-6.76z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Close;
