import { Button } from 'antd';
import BaseModal from 'Components/base-modal/base-modal';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './tip-modal.less';

export interface TipModalProps {
    visible: boolean;
    onComplete?: () => void;
    title: string;
    content: string;
}

const TipModal: FunctionComponent<TipModalProps> = ({ visible, onComplete, title, content }) => {
    const { t } = useTranslation();

    const dismiss = (): void => {
        onComplete && onComplete();
    };

    const exit = (): void => {
        dismiss();
    };

    return (
        <BaseModal
            visible={visible}
            title={t(title)}
            className="FormModal TipModal"
            onCancel={exit}
        >
            <p>{t(content)}</p>

            <div className="actions">
                <Button type="primary" className="positive" onClick={(): void => exit()}>
                    {t('ok')}
                </Button>
            </div>
        </BaseModal>
    );
};

export default React.memo(TipModal);
