import { Button, Checkbox, Col, Form, Input, Row, Select, Typography } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CreatePlanRequestDto } from 'Api/Features/Plans/Dtos/CreatePlanRequestDto';
import { PlanDto } from 'Api/Features/Plans/Dtos/PlanDto';
import { PlanTypeDto } from 'Api/Features/Plans/Dtos/PlanTypeDto';
import BaseModal from 'Components/base-modal/base-modal';
import Icon from 'Components/icons/icon';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { AddOn } from 'Models/AddOn/AddOn';
import { MILLION } from 'Models/Constants';
import { Plan } from 'Models/Plans/Plan';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import 'react-quill/dist/quill.snow.css';
import { CreateSubscriptionPlanSchema } from 'Schemas/CreateSubscriptionPlanSchema';
import { AddOnService } from 'Services/AddOnService';
import { PlanService } from 'Services/PlanService';
import { cleanVal } from 'Utils/NumberUtils';
import './create-subscription-plan-modal.less';

const { Title } = Typography;

const formGutter: [Gutter, Gutter] = [40, 0];

const selectOptions = (options: SelectOption[]): ReactNode[] => {
    return options.map((option) => (
        <Select.Option key={option.id} value={option.id}>
            {option.name}
        </Select.Option>
    ));
};
interface SelectOption {
    id: string;
    name: string;
}
interface CreateSubscriptionPlanModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    subscriptionPlan?: PlanDto;
    campusId: string;
}

const CreateSubscriptionPlanModal: FunctionComponent<CreateSubscriptionPlanModalProps> = ({
    visible,
    onComplete,
    subscriptionPlan,
    campusId,
}) => {
    const planService = useService(PlanService);
    const addOnService = useService(AddOnService);
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(
        CreateSubscriptionPlanSchema,
        form
    );
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [templates, setTemplates] = useState<Plan[]>([]);
    const [addOns, setAddOns] = useState<AddOn[]>([]);
    const [selectedAddOns, setSelectedAddOns] = useState<string[]>([]);
    const { t } = useTranslation();

    const templatesOptions: SelectOption[] =
        templates.length > 0
            ? templates.map((template) => ({
                  id: cleanVal.string(template.id),
                  name: cleanVal.string(template.name),
              }))
            : [];

    const dismiss = (success = false, id?: string): void => {
        onComplete(success, id);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Plan" />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t(
                    `Administration.subscription_plans_confirm_positive${
                        subscriptionPlan !== undefined ? '_edit' : ''
                    }`
                ),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const handleIsPriceFlexibleChange = (e: CheckboxChangeEvent): void => {
        form.setFieldsValue({
            isPriceFlexible: e.target.checked,
        });
    };

    const handleAddOnsSelect = (values: any): void => {
        if (values === '') {
            setSelectedAddOns([]);
        } else {
            setSelectedAddOns(values?.map((x: any) => x.value));
        }
    };

    const fetchTemplates = useCallback(async () => {
        globalLoadingStore.addLoading();

        try {
            const [items] = await planService.getPlans({
                types: [PlanTypeDto.Template],
            });

            setTemplates(items);
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [planService, globalLoadingStore]);

    const fetchAddOns = useCallback(async () => {
        globalLoadingStore.addLoading();

        try {
            const [items] = await addOnService.getAddOns({
                campusIds: [campusId],
            });

            setAddOns(items);
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [planService, globalLoadingStore, addOnService, campusId]);

    const addOnsOptions = (): SelectCustomOption[] => {
        if (addOns.length > 0) {
            const options = addOns.map(
                (addOn) =>
                    ({
                        label: addOn.name,
                        value: addOn.id,
                    } as SelectCustomOption)
            );
            return options;
        }
        return [];
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();

        const data: CreatePlanRequestDto = {
            templateId: formValues.template,
            price: formValues.price,
            isPriceFlexible: formValues.isPriceFlexible,
            addOnIds: selectedAddOns,
        };

        if (!(await validateForm(data))) return;

        try {
            globalLoadingStore.addLoading();
            let createdId = undefined;

            if (subscriptionPlan) {
                await planService.updatePlan(subscriptionPlan.id!, data);
            } else {
                createdId = await planService.createPlan({ ...data, campusId });
            }

            toastStore.toast({
                type: 'success',
                messageKey: `Administration.subscription_plans_${
                    subscriptionPlan ? 'edited' : 'created'
                }`,
            });

            dismiss(true, createdId);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    useEffect(() => {
        fetchTemplates();
    }, [fetchTemplates]);

    useEffect(() => {
        fetchAddOns();
    }, [fetchAddOns]);

    useEffect(() => {
        if (subscriptionPlan) {
            if (subscriptionPlan?.addOns && subscriptionPlan?.addOns?.length > 0)
                setSelectedAddOns(
                    subscriptionPlan?.addOns.map((addOn) => cleanVal.string(addOn?.id))
                );
        }
    }, [subscriptionPlan]);

    useEffect(() => {
        if (subscriptionPlan) {
            form.setFieldsValue({
                template: subscriptionPlan.name,
                price: subscriptionPlan.price,
                isPriceFlexible: subscriptionPlan.isPriceFlexible,
            });
        } else {
            if (templatesOptions.length > 0) {
                form.setFieldsValue({
                    template: templatesOptions[0].id,
                });
            }

            form.setFieldsValue({
                isPriceFlexible: false,
                price: 0,
            });
        }
    }, [subscriptionPlan, templatesOptions, form]);

    return (
        <BaseModal
            visible={visible}
            title={
                subscriptionPlan !== undefined
                    ? t('Administration.subscription_plans_edit')
                    : t('Administration.subscription_plans_create_plan')
            }
            className="CreateSubscriptionPlanModal FormModal"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Title level={4}>{t('basic_information')}</Title>
                <Row gutter={formGutter}>
                    <Col span={12}>
                        <ValidatedFormItem name="template" label={t('template')}>
                            <Select disabled={!!subscriptionPlan}>
                                {selectOptions(templatesOptions)}
                            </Select>
                        </ValidatedFormItem>
                    </Col>

                    <Col span={12}>
                        <ValidatedFormItem
                            className="price"
                            name="price"
                            label={t('price')}
                            errors={errors}
                        >
                            <NumberFormat
                                allowNegative={false}
                                customInput={(props: any) => <Input addonBefore={'$'} {...props} />}
                                isAllowed={(value: NumberFormatValues): boolean =>
                                    value.floatValue === undefined ||
                                    (value.floatValue !== undefined && value.floatValue <= MILLION)
                                }
                            />
                        </ValidatedFormItem>

                        <div className="isPriceFlexible-wrapper">
                            <ValidatedFormItem name="isPriceFlexible">
                                <Checkbox
                                    onChange={handleIsPriceFlexibleChange}
                                    defaultChecked={subscriptionPlan?.isPriceFlexible}
                                />
                            </ValidatedFormItem>

                            <label htmlFor="isPriceFlexible">
                                {t('Administration.subscription_plans_price_is_flexible')}
                            </label>
                        </div>
                    </Col>

                    <Col span={24}>
                        <ValidatedFormItem name="addOns" label={t('add_ons')}>
                            <SelectCustom
                                onChange={handleAddOnsSelect}
                                selected={selectedAddOns}
                                isMulti
                                options={addOnsOptions()}
                                idAttribute="addOns"
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" className="positive" htmlType="submit">
                        {t('submit')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
    //#endregion
};

export default CreateSubscriptionPlanModal;
