import { Button, Col, Form, Input, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import BaseModal from 'Components/base-modal/base-modal';
import Icon from 'Components/icons/icon';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useService, useStores } from 'Hooks';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { GetSystemParametersResponse, SystemService } from 'Services/SystemService';
import './parameters-modal.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface WelcomeMessageModal {
    visible: boolean;
    systemParametersData: GetSystemParametersResponse;
    onComplete: () => void;
}

const WelcomeMessageModal: FunctionComponent<WelcomeMessageModal> = ({
    visible,
    systemParametersData,
    onComplete,
}) => {
    const systemService = useService(SystemService);
    const { globalLoadingStore, toastStore } = useStores();
    const { confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const dismiss = (): void => {
        onComplete();
        form.resetFields();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="AdministrationNut" />,
                title: t('Booking.book_a_room_confirm_title'),
                message: t('Booking.book_a_room_confirm_message'),
                positiveText: t('Administration.welcome_message_confirm_positive'),
                negativeText: t('Booking.book_a_room_confirm_negative'),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        globalLoadingStore.addLoading();

        try {
            const formValues = form.getFieldsValue();
            const request: GetSystemParametersResponse = {
                welcomeMessage: formValues.welcomeMessage,
                creditConversionRatio: systemParametersData.creditConversionRatio,
            };

            await systemService.updateSystemParameters(request);

            dismiss();
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const handleWelcomeMessageChange = (value: string): void => {
        form.setFieldsValue({ welcomeMessage: value });
    };

    return (
        <BaseModal
            visible={visible}
            title={t('welcome_message')}
            className="WelcomeMessageModal FormModal"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Row gutter={formGutter}>
                    <Col className="ReactQuill" span={24}>
                        <ValidatedFormItem name="welcomeMessage" label={t('welcome_message')}>
                            <Input hidden />
                        </ValidatedFormItem>

                        <ReactQuill
                            theme="snow"
                            onChange={handleWelcomeMessageChange}
                            value={
                                form.getFieldValue('welcomeMessage') ||
                                systemParametersData.welcomeMessage ||
                                null
                            }
                        />
                    </Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" className="positive" htmlType="submit">
                        {t('save')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(WelcomeMessageModal);
