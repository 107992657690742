// GENERATED FILE - DO NOT MODIFY
import { GetCalendarEventsRequestDto } from 'Api/Features/CalendarEvents/Dtos/GetCalendarEventsRequestDto';
import { GetCalendarEventsResponseDto } from 'Api/Features/CalendarEvents/Dtos/GetCalendarEventsResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class CalendarEventProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getManagerUserCalendarEvents(managerUserId: string | null, request: GetCalendarEventsRequestDto | null): Promise<GetCalendarEventsResponseDto | null> {
        const uri = this.buildUri(
            "/manager-users/{managerUserId}/calendar-events",
            { managerUserId: managerUserId },
            { ...request || undefined }
        );

        const data: GetCalendarEventsResponseDto | null = await this.httpClient.get<GetCalendarEventsResponseDto | null>(uri);
        return data;
    }
}