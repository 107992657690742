import { Button, Col, Form, Input, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { CreateMessageRequestDto } from 'Api/Features/Messages/Dtos/CreateMessageRequestDto';
import { MessageReceiverTypeDto } from 'Api/Features/Messages/Dtos/MessageReceiverTypeDto';
import { NotificationTypeDto } from 'Api/Features/Messages/Dtos/NotificationTypeDto';
import BaseModal from 'Components/base-modal/base-modal';
import Icon from 'Components/icons/icon';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { CreateMessageSchema } from 'Schemas/CreateMessageSchema';
import { MessageService } from 'Services/MessageService';
import './send-message-modal.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface SendMessageModalProps {
    visible: boolean;
    messageReceiverType: MessageReceiverTypeDto;
    name: string;
    receiverId: string;
    onComplete: (success: boolean) => void;
}

const SendMessageModal: FunctionComponent<SendMessageModalProps> = ({
    visible,
    messageReceiverType,
    name,
    receiverId,
    onComplete,
}) => {
    const messageService = useService(MessageService);
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(CreateMessageSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const { t } = useTranslation();

    const dismiss = (success = false): void => {
        onComplete(success);
        resetErrors();
        form.resetFields();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Location" />,
                title: t('Booking.book_a_room_confirm_title'),
                message: t('Booking.book_a_room_confirm_message'),
                positiveText: t('Contact.contacts_messages_send_confirm_positive'),
                negativeText: t('Booking.book_a_room_confirm_negative'),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();

        const request: CreateMessageRequestDto = {
            title: formValues.title,
            body: formValues.body,
            receiverType: messageReceiverType,
            type: NotificationTypeDto.OneOnOne,
            receiverId: receiverId,
        };

        try {
            globalLoadingStore.addLoading();

            if (!(await validateForm(request))) return;

            await messageService.createMessage(request);

            toastStore.toast({
                type: 'success',
                messageKey: 'Contact.contacts_messages_creation_success',
            });

            dismiss(true);
        } catch (e) {
            toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const handleDescriptionChange = (value: string): void => {
        form.setFieldsValue({ body: value });
    };

    return (
        <BaseModal
            visible={visible}
            title={t('Contact.contacts_messages_send_label')}
            className="SendMessageModal FormModal"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                {messageReceiverType === MessageReceiverTypeDto.Membership && (
                    <div className="warning">{t('Membership.membership_message_warning')}</div>
                )}

                <Row gutter={formGutter}>
                    <Col span={12}>
                        <ValidatedFormItem
                            name="title"
                            label={t('Contact.contacts_messages_title_label')}
                            required={true}
                            errors={errors}
                        >
                            <Input />
                        </ValidatedFormItem>
                    </Col>

                    <Col span={12}>
                        <ValidatedFormItem
                            label={
                                messageReceiverType === MessageReceiverTypeDto.Account ||
                                MessageReceiverTypeDto.Membership
                                    ? t('Contact.contacts_messages_recipient_label')
                                    : t('location')
                            }
                        >
                            <Input disabled value={name} />
                        </ValidatedFormItem>
                    </Col>

                    <Col className="ReactQuill" span={24}>
                        <ValidatedFormItem
                            name="body"
                            label={t('Contact.contacts_messages_content_label')}
                            errors={errors}
                        >
                            <Input hidden />
                        </ValidatedFormItem>

                        <ReactQuill theme="snow" onChange={handleDescriptionChange} />
                    </Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" className="positive" htmlType="submit">
                        {t('send')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(SendMessageModal);
