import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { GetLeadsResponseDto } from 'Api/Features/Leads/Dtos/GetLeadsResponseDto';
import { LeadProxy } from 'Api/Features/Leads/LeadProxy';
import { Lead } from 'Models/Leads/Lead';
import { PagedRequest } from '../Models/Requests/PagedRequest';
import { SearchableRequest } from '../Models/Requests/SearchableRequest';
import { CreateLeadRequestDto } from 'Api/Features/Leads/Dtos/CreateLeadRequestDto';
import { UpdateLeadRequestDto } from 'Api/Features/Leads/Dtos/UpdateLeadRequestDto';
import { GetLeadEventsResponseDto } from 'Api/Features/LeadEvents/Dtos/GetLeadEventsResponseDto';
import { LeadEventProxy } from 'Api/Features/LeadEvents/LeadEventProxy';
import { LeadEventDto } from 'Api/Features/LeadEvents/Dtos/LeadEventDto';
import { LeadStatusDto } from 'Api/Features/Leads/Dtos/LeadStatusDto';
import { GetLeadsRequestDto } from 'Api/Features/Leads/Dtos/GetLeadsRequestDto';
import { GetLeadsSortColumnDto } from 'Api/Features/Leads/Dtos/GetLeadsSortColumnDto';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetLeadNoteResponseDto } from 'Api/Features/Leads/Dtos/GetLeadNoteResponseDto';
import { UpdateLeadNoteRequestDto } from 'Api/Features/Leads/Dtos/UpdateLeadNoteRequestDto';
import { ExportLeadsRequestDto } from 'Api/Features/Leads/Dtos/ExportLeadsRequestDto';
import { arrayBufferToXlsx } from 'Utils/blobs';

export interface LeadRequest extends PagedRequest, SearchableRequest {
    ownerAccountIds: string[] | null;
    campusIds: string[] | null;
    statuses: LeadStatusDto[] | null;
    sortColumn: GetLeadsSortColumnDto | null;
    sortDirection: SortDirection | null;
}

interface LeadEventRequest extends PagedRequest, SearchableRequest {
    leadId: string;
}

@inject(LeadProxy, LeadEventProxy)
export class LeadService extends ApiService {
    constructor(
        private readonly leadProxy: LeadProxy,
        private readonly leadEventProxy: LeadEventProxy
    ) {
        super();
    }

    public async createLead(request: CreateLeadRequestDto): Promise<string> {
        return await this.leadProxy.createLead(request);
    }

    public async getLeads({
        page,
        pageSize,
        searchTerm,
        campusIds,
        ownerAccountIds,
        statuses,
        sortColumn,
        sortDirection,
    }: LeadRequest): Promise<[Lead[], number]> {
        const response: GetLeadsResponseDto | null = await this.leadProxy.getLeads({
            page,
            pageSize,
            searchTerm,
            campusIds,
            ownerAccountIds,
            statuses,
            sortColumn,
            sortDirection,
        } as GetLeadsRequestDto);
        return [response?.items?.map((x) => new Lead(x!)) || [], response?.totalItemCount || 0];
    }

    public async getById(id: string): Promise<Lead | null> {
        const response: Lead | null = await this.leadProxy.getLead(id);
        return response ? new Lead(response) : null;
    }

    public async updateLead(id: string, request: UpdateLeadRequestDto): Promise<void> {
        await this.leadProxy.updateLead(id, request);
    }

    public async getLeadEventsById({
        page,
        searchTerm,
        leadId,
    }: LeadEventRequest): Promise<[LeadEventDto[], number]> {
        const response: GetLeadEventsResponseDto | null = await this.leadEventProxy.getLeadEvents(
            leadId,
            { page, searchTerm }
        );
        return [response?.items?.map((x) => x!) || [], response?.totalItemCount || 0];
    }

    public async deleteLead(leadId: string): Promise<void> {
        await this.leadProxy.deleteLead(leadId);
    }

    public async getLeadNote(leadId: string): Promise<GetLeadNoteResponseDto | null> {
        const data: GetLeadNoteResponseDto | null = await this.leadProxy.getLeadNote(leadId);
        return data;
    }

    public async updateLeadNote(
        leadId: string,
        request: UpdateLeadNoteRequestDto | null
    ): Promise<void> {
        await this.leadProxy.updateLeadNote(leadId, request);
    }

    public async exportLeads(request: ExportLeadsRequestDto | null): Promise<Blob> {
        const data: ArrayBuffer = await this.leadProxy.exportLeads(request);
        return arrayBufferToXlsx(data);
    }
}
