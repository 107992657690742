import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Minus: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 7 2" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 0.804199V1.19753H7V0.804199H0Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Minus;
