// GENERATED FILE - DO NOT MODIFY
import { GetMembershipSecurityDepositSummaryResponseDto } from 'Api/Features/SecurityDeposits/Dtos/GetMembershipSecurityDepositSummaryResponseDto';
import { GetMembershipSecurityDepositTransactionsRequestDto } from 'Api/Features/SecurityDeposits/Dtos/GetMembershipSecurityDepositTransactionsRequestDto';
import { GetMembershipSecurityDepositTransactionsResponseDto } from 'Api/Features/SecurityDeposits/Dtos/GetMembershipSecurityDepositTransactionsResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class SecurityDepositProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getMembershipSecurityDepositTransactions(membershipId: string | null, request: GetMembershipSecurityDepositTransactionsRequestDto | null): Promise<GetMembershipSecurityDepositTransactionsResponseDto | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/security-deposit-transactions",
            { membershipId: membershipId },
            { ...request || undefined }
        );

        const data: GetMembershipSecurityDepositTransactionsResponseDto | null = await this.httpClient.get<GetMembershipSecurityDepositTransactionsResponseDto | null>(uri);
        return data;
    }

    public async getMembershipSecurityDepositSummary(membershipId: string | null): Promise<GetMembershipSecurityDepositSummaryResponseDto | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/security-deposit-summary",
            { membershipId: membershipId },
            null
        );

        const data: GetMembershipSecurityDepositSummaryResponseDto | null = await this.httpClient.get<GetMembershipSecurityDepositSummaryResponseDto | null>(uri);
        return data;
    }
}