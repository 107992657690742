import { Button, Checkbox, Col, DatePicker, Divider, Form, Input, Row, Tooltip } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { CampusUnitPricingDto } from 'Api/Features/Campuses/Dtos/CampusUnitPricingDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { CreateUnitRequestDto } from 'Api/Features/Units/Dtos/CreateUnitRequestDto';
import { ImportUnitsRequestDto } from 'Api/Features/Units/Dtos/ImportUnitsRequestDto';
import { ParseUnitImportationFileRequestDto } from 'Api/Features/Units/Dtos/ParseUnitImportationFileRequestDto';
import { ParseUnitImportationFileResponseDto } from 'Api/Features/Units/Dtos/ParseUnitImportationFileResponseDto';
import { UnitTypeDto } from 'Api/Features/Units/Dtos/UnitTypeDto';
import BaseModal from 'Components/base-modal/base-modal';
import CreateUnitItem from 'Components/create-unit-item/create-unit-item';
import Icon from 'Components/icons/icon';
import InputFile from 'Components/input-file/input-file';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useService, useStores } from 'Hooks';
import { UnitImportationFileItem } from 'Models/Units/UnitImportationFileItem';
import moment from 'moment';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CampusService } from 'Services/CampusService';
import { UnitService } from 'Services/UnitService';
import { theme } from 'variant';
import './import-units-modal.less';
import ImportUnitsTipModal from './import-units-tip-modal';

const formGutter: [Gutter, Gutter] = [4, 0];

interface ImportUnitsModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    campusId: string;
}

const ImportUnitsModal: FunctionComponent<ImportUnitsModalProps> = ({
    visible,
    onComplete,
    campusId,
}) => {
    const campusService = useService(CampusService);
    const unitService = useService(UnitService);
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const [fileBase64, setFileBase64] = useState<string | ArrayBuffer | null>();
    const [parsedFileValue, setParsedFileValue] = useState<
        (UnitImportationFileItem | null)[] | null | undefined
    >();
    const [tipVisible, setTipVisible] = useState(false);
    const [unitRemovedLine, setUnitRemovedLine] = useState(false);
    const [priceOverrideCheckboxState, setPriceOverrideCheckboxState] = useState(false);
    const [unitPricing, setUnitPricing] = useState<CampusUnitPricingDto | null>(null);

    const fetchUnitPricing = useCallback(async () => {
        globalLoadingStore.addLoading();

        try {
            const response = await campusService.getUnitPricing(campusId);

            setUnitPricing(response);
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, campusService, campusId]);

    const typeOptions = (): SelectCustomOption[] => {
        const options = Object.keys(UnitTypeDto).map(
            (type) =>
                ({
                    label: t(`Unit.type_${type}`),
                    value: type,
                } as SelectCustomOption)
        );
        return options;
    };

    const submitImportationFile = useCallback(
        async (fileBase64: string | ArrayBuffer): Promise<void> => {
            try {
                globalLoadingStore.addLoading();

                const parsed: ParseUnitImportationFileResponseDto | null = await unitService.parseUnitImportationFile(
                    {
                        file: { uploadBase64: fileBase64 } as UpdateFileRequestDto,
                    } as ParseUnitImportationFileRequestDto
                );

                setParsedFileValue(
                    parsed?.items?.map(
                        (item) => ({ ...item, isDeleted: false } as UnitImportationFileItem)
                    )
                );
            } catch (e) {
                if (e.response.data.error_description) {
                    toastStore.toast({
                        type: 'error',
                        messageKey: e.response.data.error_description,
                    });
                } else if (e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [globalLoadingStore, toastStore, unitService]
    );

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
    };

    const fetchFileTemplate = useCallback(async () => {
        globalLoadingStore.addLoading();

        try {
            const template = await unitService.getUnitImportationFileTemplate();

            if (template !== null) {
                const byteArray = new Uint8Array(template);

                window.open(
                    URL.createObjectURL(
                        new Blob([byteArray], {
                            type:
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        })
                    ),
                    '_blank'
                );
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [unitService, globalLoadingStore]);

    useEffect(() => {
        if (parsedFileValue) {
            const parsedFromFile = parsedFileValue
                ?.filter((item) => !item?.isDeleted)
                .map((item, index) => {
                    const field = {} as any;

                    field[`imported_${index}_name`] = item?.name;
                    field[`imported_${index}_type`] = item?.type;
                    field[`imported_${index}_capacity`] = item?.capacity;
                    field[`imported_${index}_squareFeet`] = item?.squareFeet;
                    field[`imported_${index}_numberOfDesks`] = item?.numberOfDesks;
                    field[`imported_${index}_sixMonthPrice`] = item?.sixMonthPrice;
                    field[`imported_${index}_twelveMonthPrice`] = item?.twelveMonthPrice;
                    field[`imported_${index}_priceOverride`] = item?.priceOverride;
                    field[`imported_${index}_priceMultiplier`] = item?.priceMultiplier;
                    field[`imported_${index}_dataJacks`] = item?.dataJacks;
                    field[`imported_${index}_note`] = item?.note;
                    field[`imported_${index}_startDate`] = item?.activeStartDate
                        ? moment.utc(item.activeStartDate)
                        : undefined;
                    field[`imported_${index}_endDate`] = item?.activeEndDate
                        ? moment.utc(item.activeEndDate)
                        : undefined;

                    return field;
                });

            if (parsedFromFile.length > 0) {
                const fieldValues = parsedFromFile.reduce((result, current) => {
                    for (const key in current) {
                        result[key] = current[key];
                    }
                    return result;
                });

                form.setFieldsValue(fieldValues);
            }
        }
    }, [parsedFileValue, form]);

    useEffect(() => {
        if (fileBase64) {
            submitImportationFile(fileBase64);
        }
    }, [fileBase64, submitImportationFile]);

    const getBase64 = (e: any): void => {
        if (e.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(e.files[0]);
            reader.onload = (): void => {
                const str = reader.result as string;
                const result = str.substring(str.lastIndexOf(',') + 1);
                setFileBase64(undefined);
                setFileBase64(result);
            };
            reader.onerror = (error): void => {
                console.log('Error: ', error);
            };
        }
    };

    const handleFileSelect = (e: any): void => {
        if (e) {
            getBase64(e);
            e.value = null;
        }
    };

    const getSubmitModelFromForm = (): ImportUnitsRequestDto => {
        const formValues = form.getFieldsValue();

        let parsedUnits: CreateUnitRequestDto[] = [];
        if (parsedFileValue) {
            parsedUnits = parsedFileValue
                .filter((unit) => !unit?.isDeleted)
                .map((unit) => ({
                    campusId: campusId,
                    ...unit,
                }));
        }

        let existingUnits: CreateUnitRequestDto[] = [];
        existingUnits = formValues['unitsCreate']
            ?.filter((unit: any) => unit.name !== undefined && unit.name !== null)
            .map((unit: any) => ({
                campusId: campusId,
                ...unit,

                dataJacks: unit.dataJacks ? unit.dataJacks.split(',') : [],
            }));

        let newUnits: CreateUnitRequestDto[] = [];
        newUnits = formValues['unitsCreate']
            ?.filter((unit: any) => unit.name === undefined || unit.name === null)
            .map((unit: any) => ({
                campusId: campusId,
                ...unit,
                dataJacks: unit.dataJacks ? unit.dataJacks.split(',') : [],
            }));

        return {
            units: [...parsedUnits, ...existingUnits, ...newUnits] || [],
        } as ImportUnitsRequestDto;
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Location" />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`Unit.yes_leave_importation`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        globalLoadingStore.addLoading();

        const data = getSubmitModelFromForm();

        try {
            await unitService.importUnits(data);

            dismiss();
        } catch (e) {
            if (e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const modalHeader = (): ReactNode => {
        return (
            <>
                {t('Unit.import_units')}
                <div className="buttons-wrapper">
                    <button className="download-template" onClick={fetchFileTemplate}>
                        <Icon iconName="CrossFile" />
                        Download template
                    </button>

                    <button className="template-info" onClick={(): void => setTipVisible(true)}>
                        <Icon iconName="Info" />
                    </button>

                    <InputFile
                        onChange={(e): void => handleFileSelect(e.target ?? null)}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                </div>
            </>
        );
    };

    const getCalculatedPrice = (numberOfDesks: string, squareFeet: string): string =>
        (
            (unitPricing?.pricePerDesk || 0) * (Number(numberOfDesks) || 0) +
            (unitPricing?.pricePerSquareFoot || 0) * (Number(squareFeet) || 0)
        ).toFixed(2);

    const getMarketPrice = (
        priceOverride: string,
        priceMultiplier: string,
        calculatedPrice: string,
        unitType: UnitTypeDto
    ): string => {
        const priceOverrideNum = Number(priceOverride);
        const priceMultiplierNum = Number(priceMultiplier || 1);
        const calculatedPriceNum = Number(calculatedPrice);

        if (priceOverrideNum && priceOverrideNum !== 0) return priceOverrideNum.toFixed(2);

        if (unitType === UnitTypeDto.CommunalArea) {
            return ((unitPricing?.communalAreaBasePrice || 0) * priceMultiplierNum).toFixed(2);
        } else {
            return ((calculatedPriceNum || 0) * (priceMultiplierNum || 1)).toFixed(2);
        }
    };

    const setFormFieldsPrices = (index: number): void => {
        const formValues = form.getFieldsValue();
        const priceMultiplier = formValues[`imported_${index}_priceMultiplier`];
        const priceOverride = priceOverrideCheckboxState
            ? formValues[`imported_${index}_priceOverride`]
            : undefined;
        const calculatedPrice = getCalculatedPrice(
            formValues[`imported_${index}_numberOfDesks`],
            formValues[`imported_${index}_squareFeet`]
        );
        const marketPrice = getMarketPrice(
            priceOverride,
            priceMultiplier,
            calculatedPrice,
            formValues[`imported_${index}_type`]
        );

        form.setFieldsValue({
            [`imported_${index}_calculatedPrice`]: calculatedPrice,
            [`imported_${index}_marketPrice`]: marketPrice,
            [`imported_${index}_priceMultiplier`]: priceMultiplier,
            [`imported_${index}_sixMonthPrice`]: priceOverride,
            [`imported_${index}_twelveMonthPrice`]: priceOverride,
        });
    };

    const onPriceAffectingInputChange = (index: number): void => {
        setFormFieldsPrices(index);
    };

    useEffect(() => {
        fetchUnitPricing();
    }, [fetchUnitPricing]);

    return (
        <BaseModal
            visible={visible}
            title={modalHeader()}
            className="ImportUnitsModal"
            onCancel={exit}
            width={1550}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                {parsedFileValue &&
                    parsedFileValue
                        .filter((item) => !item?.isDeleted)
                        .map((item, index) => (
                            <div className="units-upload" key={index}>
                                <Row className="units-upload-row">
                                    <Col span={23}>
                                        <Row gutter={formGutter}>
                                            <Col span={4}>
                                                <ValidatedFormItem
                                                    name={`imported_${index}_type`}
                                                    label={t('Unit.unit_type')}
                                                >
                                                    <SelectCustom
                                                        onChange={(values: any): void => {
                                                            const newState = parsedFileValue.slice();
                                                            const index = newState?.findIndex(
                                                                (line) => line?.name === item?.name
                                                            );
                                                            newState[index] = {
                                                                ...newState[index],
                                                                type: values.value,
                                                            };
                                                            setParsedFileValue(newState);
                                                        }}
                                                        selected={[`${item?.type}`]}
                                                        options={typeOptions()}
                                                        idAttribute={`imported_${index}_type`}
                                                    />
                                                </ValidatedFormItem>
                                            </Col>

                                            <Col span={2}>
                                                <ValidatedFormItem
                                                    name={`imported_${index}_name`}
                                                    label={t('name')}
                                                    required
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('Unit.required_name'),
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </ValidatedFormItem>
                                            </Col>

                                            {item?.type === UnitTypeDto.CommunalArea ? (
                                                <Col span={2}>
                                                    <ValidatedFormItem
                                                        name={`imported_${index}_capacity`}
                                                        label={t('Unit.capacity')}
                                                        required
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t(
                                                                    'Unit.required_capacity'
                                                                ),
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </ValidatedFormItem>
                                                </Col>
                                            ) : (
                                                <>
                                                    <Col span={2}>
                                                        <ValidatedFormItem
                                                            name={`imported_${index}_squareFeet`}
                                                            label={t('square_ft')}
                                                            required
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t(
                                                                        'Unit.required_squareFeet'
                                                                    ),
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                onChange={(): void =>
                                                                    onPriceAffectingInputChange(
                                                                        index
                                                                    )
                                                                }
                                                            />
                                                        </ValidatedFormItem>
                                                    </Col>

                                                    <Col span={2}>
                                                        <ValidatedFormItem
                                                            name={`imported_${index}_numberOfDesks`}
                                                            label={t('Unit.nbr_of_desks')}
                                                            required
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t(
                                                                        'Unit.required_numberOfDesks'
                                                                    ),
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                onChange={(): void =>
                                                                    onPriceAffectingInputChange(
                                                                        index
                                                                    )
                                                                }
                                                            />
                                                        </ValidatedFormItem>
                                                    </Col>
                                                </>
                                            )}

                                            <Col
                                                span={
                                                    item?.type === UnitTypeDto.CommunalArea ? 8 : 6
                                                }
                                            >
                                                <ValidatedFormItem
                                                    name={`imported_${index}_note`}
                                                    label={t('note')}
                                                >
                                                    <Input />
                                                </ValidatedFormItem>
                                            </Col>

                                            <Col span={4}>
                                                <ValidatedFormItem
                                                    name={`imported_${index}_startDate`}
                                                    label={t('start_date')}
                                                >
                                                    <DatePicker style={{ width: '100%' }} />
                                                </ValidatedFormItem>
                                            </Col>

                                            <Col span={4}>
                                                <ValidatedFormItem
                                                    name={`imported_${index}_endDate`}
                                                    label={t('end_date')}
                                                >
                                                    <DatePicker style={{ width: '100%' }} />
                                                </ValidatedFormItem>
                                            </Col>

                                            {item?.type === UnitTypeDto.CommunalArea ? (
                                                <Col span={4}>
                                                    <ValidatedFormItem
                                                        name={`imported_${index}_communalAreaBasePrice`}
                                                        label={t('Unit.communal_area_base_price')}
                                                        initialValue={
                                                            unitPricing?.communalAreaBasePrice || 0
                                                        }
                                                    >
                                                        <Input disabled />
                                                    </ValidatedFormItem>
                                                </Col>
                                            ) : (
                                                <Col span={4} className="tip-wrapper">
                                                    <ValidatedFormItem
                                                        name={`imported_${index}_calculatedPrice`}
                                                        label={
                                                            <Tooltip
                                                                title={t(
                                                                    'Unit.calculated_price_tip'
                                                                )}
                                                            >
                                                                {t('calculated_price')}
                                                                <Icon iconName="Info" />
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Input disabled />
                                                    </ValidatedFormItem>
                                                </Col>
                                            )}

                                            <Col span={2}>
                                                <ValidatedFormItem
                                                    name={`imported_${index}_priceMultiplier`}
                                                    label={t('Unit.price_multiplier')}
                                                >
                                                    <Input
                                                        onChange={(): void =>
                                                            onPriceAffectingInputChange(index)
                                                        }
                                                    />
                                                </ValidatedFormItem>
                                            </Col>

                                            <Col span={2}>
                                                <ValidatedFormItem
                                                    name={`imported_${index}_priceOverride`}
                                                    label={t('price_override')}
                                                >
                                                    <Input
                                                        disabled={!priceOverrideCheckboxState}
                                                        onChange={(): void =>
                                                            onPriceAffectingInputChange(index)
                                                        }
                                                        addonAfter={
                                                            <Checkbox
                                                                onChange={(value): void => {
                                                                    setPriceOverrideCheckboxState(
                                                                        (old) => !old
                                                                    );
                                                                    if (!value.target.checked) {
                                                                        form.setFieldsValue({
                                                                            priceOverride: 0,
                                                                        });
                                                                    }
                                                                    onPriceAffectingInputChange(
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </ValidatedFormItem>
                                            </Col>

                                            <Col span={4}>
                                                <ValidatedFormItem
                                                    name={`imported_${index}_marketPrice`}
                                                    label={t('Unit.market_price')}
                                                >
                                                    <Input disabled />
                                                </ValidatedFormItem>
                                            </Col>

                                            <Col span={4}>
                                                <ValidatedFormItem
                                                    name={`imported_${index}_sixMonthPrice`}
                                                    label={t('Unit.six_month_price')}
                                                >
                                                    <Input />
                                                </ValidatedFormItem>
                                            </Col>

                                            <Col span={4}>
                                                <ValidatedFormItem
                                                    name={`imported_${index}_twelveMonthPrice`}
                                                    label={t('Unit.twelve_month_price')}
                                                >
                                                    <Input />
                                                </ValidatedFormItem>
                                            </Col>

                                            <Col span={4} className="tip-wrapper">
                                                <ValidatedFormItem
                                                    name={`imported_${index}_dataJacks`}
                                                    label={
                                                        <Tooltip title={t('Unit.data_jacks_info')}>
                                                            {t('Unit.data_jacks')}
                                                            <Icon iconName="Info" />
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Input />
                                                </ValidatedFormItem>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col span={1} className="units-upload-row-garbage-container">
                                        <span
                                            className="garbage-container"
                                            onClick={(): void => {
                                                const newState = parsedFileValue.slice();
                                                const index = newState?.findIndex(
                                                    (line) => line?.name === item?.name
                                                );
                                                newState[index] = {
                                                    ...newState[index],
                                                    isDeleted: true,
                                                };
                                                setParsedFileValue(newState);
                                            }}
                                        >
                                            <Icon iconName="Delete" fill={theme['primary-color']} />
                                        </span>
                                    </Col>
                                </Row>

                                <Divider />
                            </div>
                        ))}

                <Form.List name="unitsCreate">
                    {(fields, { add, remove }): ReactNode => {
                        if (fields.length === 0 && !unitRemovedLine) add();
                        return (
                            <>
                                {fields.map((field, index) => (
                                    <CreateUnitItem
                                        index={index}
                                        key={field.fieldKey}
                                        remove={(index): void => {
                                            remove(index);
                                            setUnitRemovedLine(true);
                                        }}
                                        form={form}
                                        unitPricing={unitPricing}
                                    />
                                ))}

                                <Row gutter={formGutter}>
                                    <Col span={24} className="dynamic-list-button-container">
                                        <Button
                                            className={
                                                'ant-btn-primary ant-btn-circle ant-btn-lg ant-btn-icon-only'
                                            }
                                            onClick={(): void => add()}
                                        >
                                            <Icon iconName="Add" />
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        );
                    }}
                </Form.List>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" htmlType="submit">
                        {t('save')}
                    </Button>
                </div>
            </Form>

            {tipVisible && (
                <ImportUnitsTipModal
                    onComplete={(): void => setTipVisible(false)}
                    visible={tipVisible}
                />
            )}
        </BaseModal>
    );
    //#endregion
};

export default React.memo(ImportUnitsModal);
