import AuthenticatedLayout from 'Components/authenticated-layout/authenticated-layout';
import { ValidatedRoute } from 'Components/validated-route';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React, { FunctionComponent, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { BaseDataService } from 'Services/BaseDataService';
import { NotFoundPage } from '../error/not-found';
import ActivityBoard from './activity-board';
import Administration from './administration';
import CreditPacks from './administration/credit-packs';
import CreditPackDetails from './administration/credit-packs/id';
import DayPassTypes from './administration/day-pass-types';
import DayPassTypesDetails from './administration/day-pass-types/id/index';
import LeadOpportunityAssignement from './administration/lead-opportunity-assignment/index';
import ManagerUsers from './administration/manager-users';
import ManagerUserDetail from './administration/manager-users/id';
import Parameters from './administration/parameters';
import SubscriptionPlans from './administration/subscription-plans';
import SubscriptionPlansDetails from './administration/subscription-plans/id';
import CheckIns from './check-ins';
import GlobalCompanies from './companies';
import CompanyDetail from './companies/id';
import GlobalContacts from './contacts';
import ContactsDetail from './contacts/id';
import ContactBookingHistory from './contacts/id/booking-history';
import ContactDashboard from './contacts/id/dashboard';
import Invoices from './contacts/id/invoices';
import InvoiceDetails from './contacts/id/invoices/id';
import Messages from './contacts/id/messages';
import Leads from './leads';
import LeadDetail from './leads/id';
import Locations from './locations';
import LocationBooking from './locations/id/booking';
import Companies from './locations/id/companies';
import LocationContactDetails from './locations/id/contacts/id';
import LocationDashboard from './locations/id/dashboard';
import LocationDetail from './locations/id/index';
import LocationManagement from './locations/id/management';
import LocationAddOns from './locations/id/management/add-ons';
import AddOnDetails from './locations/id/management/add-ons/id';
import AreasOfInterest from './locations/id/management/areas-of-interest';
import AreaOfInterestDetails from './locations/id/management/areas-of-interest/id';
import LocationConferenceRooms from './locations/id/management/conference-rooms';
import LocationConferenceRoomsDetail from './locations/id/management/conference-rooms/id';
import LocationDetails from './locations/id/management/location-details';
import Onboarding from './locations/id/management/onboarding';
import LocationPricing from './locations/id/management/pricing';
import RevenueOccupancyTargets from './locations/id/management/revenue-occupancy-targets';
import LocationUnits from './locations/id/management/units';
import UnitDetails from './locations/id/management/units/id';
import Subscriptions from './locations/id/subscriptions';
import SubscriptionDetails from './locations/id/subscriptions/id/index';
import Opportunities from './opportunities';
import OpportunityDetail from './opportunities/id';
import AgreementTerms from './opportunities/id/agreement-terms';
import OpportunitiesDashboard from './opportunities/id/dashboard';
import Proposals from './opportunities/id/proposals';
import ProposalDetail from './opportunities/id/proposals/id';
import Profile from './profile/id';
import Reports from './reports';
import AccountsReceivable from './reports/accounts-receivable';
import AgedReceivables from './reports/aged-receivables';
import Bookings from './reports/bookings';
import ClosedOpportunities from './reports/closed-opportunities';
import NewSaleActivities from './reports/new-sale-activities';
import OccupancyStats from './reports/occupancy-stats';
import RentRoll from './reports/rent-roll';
import RevenueOccupancy from './reports/revenue-occupancy';
import SecurityDeposits from './reports/security-deposits';
import UnitInventory from './reports/unit-inventory';
import GlobalSubscriptions from './subscriptions';
import CompanyDashboard from './companies/id/dashboard/index';
import CompanyMessages from './companies/id/messages';
import CompanySubscriptions from './companies/id/subscriptions';
import CompanyBookingHistory from './companies/id/booking-history';
import CompanyBookingHistoryDetails from './companies/id/booking-history/id';
import CompanyTeam from './companies/id/team';
import CompanyTeamDetails from './companies/id/team/id';
import ContactBookingHistoryDetails from './contacts/id/booking-history/id';
import CompanyFinances from './companies/id/finance';
import CompanyInvoices from './companies/id/finance/company-invoices';
import PrepaidBalance from './companies/id/finance/prepaid-balance';
import SecurityDeposit from './companies/id/finance/security-deposit';
import CompanyInvoiceDetails from './companies/id/finance/company-invoices/id';
import Contacts from './locations/id/contacts';
import LocationCompanyDetails from './locations/id/companies/id';
import NonMemberDetails from './companies/id/team/non-member-details';
import FloorPlanComponent from 'Routes/authenticated/locations/id/floor-plan';
import Events from './locations/id/events';
import EventDetail from './locations/id/events/id';
import Integration from './administration/integration';
import IntegrationDetails from './administration/integration/id';
import LocationStripeConnectConfirm from './administration/integration/location-stripe-connect-confirm';
import FloorPlanEdition from './locations/id/management/floor-plan';
import ReservedDayPasses from './locations/id/reserved-day-passes';

interface AuthenticatedProps {
    returnUrl: string;
}

const AuthenticatedRoutes: FunctionComponent<AuthenticatedProps> = observer(({ returnUrl }) => {
    const location = useLocation();
    const { authorizationStore } = useStores();
    const baseDataService = useService(BaseDataService);
    let redirectionUrl = returnUrl;

    useEffect(() => {
        // Reset forbidden and notFound state on route change.
        authorizationStore.resetForbidden();
        authorizationStore.resetNotFound();

        baseDataService.initBaseData();
    }, [location, authorizationStore, baseDataService]);

    if (!returnUrl) redirectionUrl = '/activity-board';

    return (
        <AuthenticatedLayout>
            <Switch>
                <Redirect exact from="/" to={redirectionUrl} />

                <ValidatedRoute exact path="/activity-board">
                    <ActivityBoard />
                </ValidatedRoute>

                {/* Location */}
                <ValidatedRoute exact path="/locations">
                    <Locations />
                </ValidatedRoute>
                <ValidatedRoute path="/locations/:id">
                    <Switch>
                        <ValidatedRoute exact path="/locations/:id">
                            <LocationDetail>
                                <LocationDashboard />
                            </LocationDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/locations/:id/companies">
                            <LocationDetail>
                                <Companies />
                            </LocationDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/locations/:id/companies/:membershipId">
                            <LocationDetail>
                                <LocationCompanyDetails />
                            </LocationDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/locations/:id/booking">
                            <LocationDetail>
                                <LocationBooking />
                            </LocationDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/locations/:id/check-ins">
                            <LocationDetail>
                                <CheckIns />
                            </LocationDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/locations/:id/contacts">
                            <LocationDetail>
                                <Contacts />
                            </LocationDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/locations/:id/contacts/:contactId">
                            <LocationDetail>
                                <LocationContactDetails />
                            </LocationDetail>
                        </ValidatedRoute>
                        <ValidatedRoute path="/locations/:id/management">
                            <ValidatedRoute exact path="/locations/:id/management">
                                <LocationDetail>
                                    <LocationManagement />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute exact path="/locations/:id/management/conference-rooms">
                                <LocationDetail>
                                    <LocationConferenceRooms />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute
                                exact
                                path="/locations/:id/management/conference-rooms/:roomId"
                            >
                                <LocationDetail>
                                    <LocationConferenceRoomsDetail />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute exact path="/locations/:id/management/onboarding">
                                <LocationDetail>
                                    <Onboarding />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute exact path="/locations/:id/management/location-details">
                                <LocationDetail>
                                    <LocationDetails />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute exact path="/locations/:id/management/units">
                                <LocationDetail>
                                    <LocationUnits />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute exact path="/locations/:id/management/units/:unitId">
                                <LocationDetail>
                                    <UnitDetails />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute exact path="/locations/:id/management/add-ons">
                                <LocationDetail>
                                    <LocationAddOns />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute exact path="/locations/:id/management/add-ons/:addOnId">
                                <LocationDetail>
                                    <AddOnDetails />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute
                                exact
                                path="/locations/:id/management/revenueOccupancyTargets"
                            >
                                <LocationDetail>
                                    <RevenueOccupancyTargets />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute exact path="/locations/:id/management/pricing">
                                <LocationDetail>
                                    <LocationPricing />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute
                                exact
                                path="/locations/:id/management/subscription-plans"
                            >
                                <LocationDetail>
                                    <SubscriptionPlans />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute
                                exact
                                path="/locations/:id/management/subscription-plans/:locationSubscriptionPlanId"
                            >
                                <LocationDetail>
                                    <SubscriptionPlansDetails />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute
                                exact
                                path="/locations/:id/management/areas-of-interest"
                            >
                                <LocationDetail>
                                    <AreasOfInterest />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute
                                exact
                                path="/locations/:id/management/areas-of-interest/:areaOfInterestId"
                            >
                                <LocationDetail>
                                    <AreaOfInterestDetails />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute exact path="/locations/:id/management/day-pass-types">
                                <LocationDetail>
                                    <DayPassTypes />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute
                                exact
                                path="/locations/:id/management/day-pass-types/:locationDayPassTypesId"
                            >
                                <LocationDetail>
                                    <DayPassTypesDetails />
                                </LocationDetail>
                            </ValidatedRoute>
                            <ValidatedRoute exact path="/locations/:id/management/floor-plan">
                                <LocationDetail>
                                    <FloorPlanEdition />
                                </LocationDetail>
                            </ValidatedRoute>
                        </ValidatedRoute>

                        <ValidatedRoute exact path="/locations/:id/subscriptions">
                            <LocationDetail>
                                <Subscriptions />
                            </LocationDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/locations/:id/subscriptions/:subscriptionId">
                            <LocationDetail>
                                <SubscriptionDetails userNavigatedFromLocation />
                            </LocationDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/locations/:id/events">
                            <LocationDetail>
                                <Events />
                            </LocationDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/locations/:id/events/:eventId">
                            <LocationDetail>
                                <EventDetail />
                            </LocationDetail>
                        </ValidatedRoute>

                        <ValidatedRoute exact path="/locations/:id/floor-plan">
                            <LocationDetail>
                                <FloorPlanComponent />
                            </LocationDetail>
                        </ValidatedRoute>

                        <ValidatedRoute exact path="/locations/:id/reserved-day-passes">
                            <LocationDetail>
                                <ReservedDayPasses />
                            </LocationDetail>
                        </ValidatedRoute>
                        <Route>
                            {/* must be last route in /locations */}
                            <NotFoundPage />
                        </Route>
                    </Switch>
                </ValidatedRoute>
                {/* End Location */}

                {/* Contact */}
                <ValidatedRoute exact path="/contacts">
                    <GlobalContacts />
                </ValidatedRoute>
                <ValidatedRoute exact path="/contacts/:id/dashboard">
                    <ContactsDetail>
                        <ContactDashboard />
                    </ContactsDetail>
                </ValidatedRoute>
                <ValidatedRoute exact path="/contacts/:id/dashboard/invoices">
                    <ContactsDetail>
                        <Invoices />
                    </ContactsDetail>
                </ValidatedRoute>
                <ValidatedRoute exact path="/contacts/:id/dashboard/invoices/:invoiceId">
                    <ContactsDetail>
                        <InvoiceDetails />
                    </ContactsDetail>
                </ValidatedRoute>
                <ValidatedRoute exact path="/contacts/:id/dashboard/booking-history">
                    <ContactsDetail>
                        <ContactBookingHistory />
                    </ContactsDetail>
                </ValidatedRoute>
                <ValidatedRoute exact path="/contacts/:id/dashboard/booking-history/:bookingId">
                    <ContactsDetail>
                        <ContactBookingHistoryDetails />
                    </ContactsDetail>
                </ValidatedRoute>
                <ValidatedRoute exact path="/contacts/:id/dashboard/messages">
                    <ContactsDetail>
                        <Messages />
                    </ContactsDetail>
                </ValidatedRoute>
                {/* End Contact */}

                {/* Companies */}
                <ValidatedRoute exact path="/companies">
                    <GlobalCompanies />
                </ValidatedRoute>
                <ValidatedRoute path="/companies/:id">
                    <Switch>
                        <ValidatedRoute exact path="/companies/:id/dashboard">
                            <CompanyDetail>
                                <CompanyDashboard />
                            </CompanyDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/companies/:id/dashboard/subscriptions">
                            <CompanyDetail>
                                <CompanySubscriptions />
                            </CompanyDetail>
                        </ValidatedRoute>
                        <ValidatedRoute
                            exact
                            path="/companies/:id/dashboard/subscriptions/:subscriptionId"
                        >
                            <CompanyDetail>
                                <SubscriptionDetails userNavigatedFromCompanySubsriptions />
                            </CompanyDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/companies/:id/dashboard/finance">
                            <CompanyDetail>
                                <CompanyFinances />
                            </CompanyDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/companies/:id/dashboard/finance/invoices">
                            <CompanyDetail>
                                <CompanyInvoices />
                            </CompanyDetail>
                        </ValidatedRoute>
                        <ValidatedRoute
                            exact
                            path="/companies/:id/dashboard/finance/invoices/:invoiceId"
                        >
                            <CompanyDetail>
                                <CompanyInvoiceDetails />
                            </CompanyDetail>
                        </ValidatedRoute>
                        <ValidatedRoute
                            exact
                            path="/companies/:id/dashboard/finance/prepaid-balance"
                        >
                            <CompanyDetail>
                                <PrepaidBalance />
                            </CompanyDetail>
                        </ValidatedRoute>
                        <ValidatedRoute
                            exact
                            path="/companies/:id/dashboard/finance/security-deposit"
                        >
                            <CompanyDetail>
                                <SecurityDeposit />
                            </CompanyDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/companies/:id/dashboard/messages">
                            <CompanyDetail>
                                <CompanyMessages />
                            </CompanyDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/companies/:id/dashboard/booking-history">
                            <CompanyDetail>
                                <CompanyBookingHistory />
                            </CompanyDetail>
                        </ValidatedRoute>
                        <ValidatedRoute
                            exact
                            path="/companies/:id/dashboard/booking-history/:bookingId"
                        >
                            <CompanyDetail>
                                <CompanyBookingHistoryDetails />
                            </CompanyDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/companies/:id/dashboard/team">
                            <CompanyDetail>
                                <CompanyTeam />
                            </CompanyDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/companies/:id/dashboard/team/:memberId">
                            <CompanyDetail>
                                <CompanyTeamDetails />
                            </CompanyDetail>
                        </ValidatedRoute>
                        <ValidatedRoute
                            exact
                            path="/companies/:id/dashboard/team/non-member/:email"
                        >
                            <CompanyDetail>
                                <NonMemberDetails />
                            </CompanyDetail>
                        </ValidatedRoute>
                    </Switch>
                </ValidatedRoute>
                {/* End Companies */}

                {/* Reports */}
                <ValidatedRoute exact path="/reports">
                    <Reports />
                </ValidatedRoute>
                <ValidatedRoute exact path="/reports/closed-opportunities">
                    <ClosedOpportunities />
                </ValidatedRoute>
                <ValidatedRoute exact path="/reports/inventory">
                    <UnitInventory />
                </ValidatedRoute>
                <ValidatedRoute exact path="/reports/occupancy-stats">
                    <OccupancyStats />
                </ValidatedRoute>
                <ValidatedRoute exact path="/reports/bookings">
                    <Bookings />
                </ValidatedRoute>
                <ValidatedRoute exact path="/reports/accounts-receivable">
                    <AccountsReceivable />
                </ValidatedRoute>
                <ValidatedRoute exact path="/reports/revenue-occupancy">
                    <RevenueOccupancy />
                </ValidatedRoute>
                <ValidatedRoute exact path="/reports/aged-receivables">
                    <AgedReceivables />
                </ValidatedRoute>
                <ValidatedRoute exact path="/reports/security-deposits">
                    <SecurityDeposits />
                </ValidatedRoute>
                <ValidatedRoute exact path="/reports/rent-roll">
                    <RentRoll />
                </ValidatedRoute>
                <ValidatedRoute exact path="/reports/sale-activities">
                    <NewSaleActivities />
                </ValidatedRoute>
                {/* End Reports */}

                <ValidatedRoute exact path="/subscriptions">
                    <GlobalSubscriptions />
                </ValidatedRoute>
                <ValidatedRoute exact path="/subscriptions/:subscriptionId">
                    <SubscriptionDetails />
                </ValidatedRoute>

                <ValidatedRoute path="/check-ins">
                    <CheckIns />
                </ValidatedRoute>

                {/* Adminisration */}
                <ValidatedRoute exact path="/administration">
                    <Administration />
                </ValidatedRoute>
                <ValidatedRoute exact path="/administration/manager-users">
                    <ManagerUsers />
                </ValidatedRoute>
                <ValidatedRoute path="/administration/manager-users/:id">
                    <ManagerUserDetail />
                </ValidatedRoute>
                <ValidatedRoute exact path="/administration/parameters">
                    <Parameters />
                </ValidatedRoute>
                <ValidatedRoute exact path="/administration/integration">
                    <Integration />
                </ValidatedRoute>
                <ValidatedRoute exact path="/administration/integration/:id">
                    <IntegrationDetails />
                </ValidatedRoute>
                <ValidatedRoute exact path="/location-stripe-connect-confirm">
                    <LocationStripeConnectConfirm />
                </ValidatedRoute>
                <ValidatedRoute exact path="/administration/lead-opportunity-assignment">
                    <LeadOpportunityAssignement />
                </ValidatedRoute>
                <ValidatedRoute exact path="/administration/subscription-plans">
                    <SubscriptionPlans />
                </ValidatedRoute>
                <ValidatedRoute exact path="/administration/subscription-plans/:id">
                    <SubscriptionPlansDetails />
                </ValidatedRoute>
                <ValidatedRoute exact path="/administration/credit-packs">
                    <CreditPacks />
                </ValidatedRoute>
                <ValidatedRoute exact path="/administration/credit-packs/:id">
                    <CreditPackDetails />
                </ValidatedRoute>
                <ValidatedRoute exact path="/administration/day-pass-types">
                    <DayPassTypes />
                </ValidatedRoute>
                <ValidatedRoute exact path="/administration/day-pass-types/:id">
                    <DayPassTypesDetails />
                </ValidatedRoute>
                {/* End Administration */}

                <ValidatedRoute exact path="/leads">
                    <Leads />
                </ValidatedRoute>
                <ValidatedRoute path="/leads/:id">
                    <LeadDetail />
                </ValidatedRoute>

                <ValidatedRoute path="/profile/:id">
                    <Profile />
                </ValidatedRoute>

                {/* Opportunities */}
                <ValidatedRoute exact path="/opportunities">
                    <Opportunities />
                </ValidatedRoute>
                <ValidatedRoute path="/opportunities/:id">
                    <Switch>
                        {/* the root of opportunity/id is dashboard */}
                        <Redirect
                            exact
                            from="/opportunities/:id"
                            to="/opportunities/:id/dashboard"
                        />
                        {/* components are route validated before rendering the OpportunityDetail to validate malformed urls */}
                        {/* could not follow same logic as /locations because of route props in opportunities to diminish api calls. TODO? refactor opportunities so as to not need updateChildrenWithProps in OpportunityDetail*/}
                        <ValidatedRoute exact path="/opportunities/:id/dashboard">
                            <OpportunityDetail>
                                <ValidatedRoute exact path="/opportunities/:id/dashboard">
                                    <OpportunitiesDashboard />
                                </ValidatedRoute>
                            </OpportunityDetail>
                        </ValidatedRoute>
                        <ValidatedRoute exact path="/opportunities/:id/dashboard/proposals">
                            <OpportunityDetail>
                                <ValidatedRoute exact path="/opportunities/:id/dashboard/proposals">
                                    <Proposals />
                                </ValidatedRoute>
                            </OpportunityDetail>
                        </ValidatedRoute>
                        <ValidatedRoute
                            exact
                            path="/opportunities/:id/dashboard/proposals/:proposalId"
                        >
                            <OpportunityDetail>
                                <ValidatedRoute
                                    exact
                                    path="/opportunities/:id/dashboard/proposals/:proposalId"
                                >
                                    <ProposalDetail />
                                </ValidatedRoute>
                            </OpportunityDetail>
                        </ValidatedRoute>
                        <ValidatedRoute
                            exact
                            path="/opportunities/:id/dashboard/service-agreements"
                        >
                            <OpportunityDetail>
                                <ValidatedRoute
                                    exact
                                    path="/opportunities/:id/dashboard/service-agreements"
                                >
                                    <AgreementTerms />
                                </ValidatedRoute>
                            </OpportunityDetail>
                        </ValidatedRoute>
                        <Route>
                            {/* must be last route  in /opportunity */}
                            <NotFoundPage />
                        </Route>
                    </Switch>
                </ValidatedRoute>
                {/* End Opportunities */}

                <Route>
                    {/* must be last route*/}
                    <NotFoundPage />
                </Route>
            </Switch>
        </AuthenticatedLayout>
    );
});

export default AuthenticatedRoutes;
