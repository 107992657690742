import { PHONE_REGEX, WEBSITE_REGEX } from 'Models/Constants';
import * as yup from 'yup';

export const EditAreaOfInterestSchema = yup.object().shape(
    {
        introduction: yup
            .string()
            .required('Errors.required')
            .max(4000, 'Errors.must_be_under_four_thousand'),
        type: yup.string().required('Errors.required'),
        title: yup.string().required('Errors.required'),
        website: yup.string().optional().matches(WEBSITE_REGEX, 'Errors.link_invalid'),
        email: yup.string().optional().email('Errors.email_invalid'),
        phone1: yup.string().optional().max(25, 'Errors.must_be_under_twenty_five').matches(PHONE_REGEX, 'Errors.phone_invalid'),
        phone2: yup.string().optional().max(25, 'Errors.must_be_under_twenty_five').matches(PHONE_REGEX, 'Errors.phone_invalid'),
        addressLine1: yup.string().optional().max(100, 'Errors.must_be_under_one_hundred'),
        city: yup.string().optional().max(100, 'Errors.must_be_under_one_hundred'),
        state: yup.string().optional().max(100, 'Errors.must_be_under_one_hundred'),
        country: yup.string().optional().max(100, 'Errors.must_be_under_one_hundred'),
        postalCode: yup.string().optional().max(100, 'Errors.must_be_under_one_hundred'),
        latitude: yup.number().required('Errors.required'),
        longitude: yup.number().required('Errors.required')
    }
);
