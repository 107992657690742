// GENERATED FILE - DO NOT MODIFY
import { CreateCreditPackPurchaseRequestDto } from 'Api/Features/CreditPacks/Dtos/CreateCreditPackPurchaseRequestDto';
import { CreateCreditPackRequestDto } from 'Api/Features/CreditPacks/Dtos/CreateCreditPackRequestDto';
import { CreditPackDto } from 'Api/Features/CreditPacks/Dtos/CreditPackDto';
import { CreditPackPurchasePreviewDto } from 'Api/Features/CreditPacks/Dtos/CreditPackPurchasePreviewDto';
import { GetCreditPackOrderResponseDto } from 'Api/Features/CreditPacks/Dtos/GetCreditPackOrderResponseDto';
import { GetCreditPacksRequestDto } from 'Api/Features/CreditPacks/Dtos/GetCreditPacksRequestDto';
import { GetCreditPacksResponseDto } from 'Api/Features/CreditPacks/Dtos/GetCreditPacksResponseDto';
import { OldCreateCreditPackPurchaseRequestDto } from 'Api/Features/CreditPacks/Dtos/OldCreateCreditPackPurchaseRequestDto';
import { UpdateCreditPackOrderRequestDto } from 'Api/Features/CreditPacks/Dtos/UpdateCreditPackOrderRequestDto';
import { UpdateCreditPackRequestDto } from 'Api/Features/CreditPacks/Dtos/UpdateCreditPackRequestDto';
import { InvoiceSummaryDto } from 'Api/Features/Invoices/Dtos/InvoiceSummaryDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class CreditPackProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getCreditPacks_V1_1(): Promise<(CreditPackDto | null)[] | null> {
        const uri = this.buildUri(
            "/credits/packs",
            null,
            null
        );

        const data: (CreditPackDto | null)[] | null = await this.httpClient.get<(CreditPackDto | null)[] | null>(uri);
        return data;
    }

    public async getCreditPacks(request: GetCreditPacksRequestDto | null): Promise<GetCreditPacksResponseDto | null> {
        const uri = this.buildUri(
            "/credit-packs",
            null,
            { ...request || undefined }
        );

        const data: GetCreditPacksResponseDto | null = await this.httpClient.get<GetCreditPacksResponseDto | null>(uri);
        return data;
    }

    public async getCreditPack_V1_1(creditPackId: string | null): Promise<CreditPackDto | null> {
        const uri = this.buildUri(
            "/packs/{creditPackId}",
            { creditPackId: creditPackId },
            null
        );

        const data: CreditPackDto | null = await this.httpClient.get<CreditPackDto | null>(uri);
        return data;
    }

    public async getCreditPack(creditPackId: string | null): Promise<CreditPackDto | null> {
        const uri = this.buildUri(
            "/credit-packs/{creditPackId}",
            { creditPackId: creditPackId },
            null
        );

        const data: CreditPackDto | null = await this.httpClient.get<CreditPackDto | null>(uri);
        return data;
    }

    public async createCreditPackPurchase_V1_1(purchasePackRequest: OldCreateCreditPackPurchaseRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/credits/purchase",
            null,
            null
        );

        await this.httpClient.post<OldCreateCreditPackPurchaseRequestDto | null, void>(uri, purchasePackRequest);
    }

    public async createCreditPackPurchase(request: CreateCreditPackPurchaseRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/credit-pack-purchases",
            null,
            null
        );

        await this.httpClient.post<CreateCreditPackPurchaseRequestDto | null, void>(uri, request);
    }

    public async getCreditPackInvoiceSummary_V1_1(purchasePackRequest: OldCreateCreditPackPurchaseRequestDto | null): Promise<InvoiceSummaryDto | null> {
        const uri = this.buildUri(
            "/credits/invoicesummary",
            null,
            null
        );

        const data: InvoiceSummaryDto | null = await this.httpClient.post<OldCreateCreditPackPurchaseRequestDto | null, InvoiceSummaryDto | null>(uri, purchasePackRequest);
        return data;
    }

    public async getCreditPackPurchasePreview(request: CreateCreditPackPurchaseRequestDto | null): Promise<CreditPackPurchasePreviewDto | null> {
        const uri = this.buildUri(
            "/credit-pack-purchases/preview",
            null,
            null
        );

        const data: CreditPackPurchasePreviewDto | null = await this.httpClient.post<CreateCreditPackPurchaseRequestDto | null, CreditPackPurchasePreviewDto | null>(uri, request);
        return data;
    }

    public async getCreditPackOrdering(): Promise<GetCreditPackOrderResponseDto | null> {
        const uri = this.buildUri(
            "/credit-packs/order",
            null,
            null
        );

        const data: GetCreditPackOrderResponseDto | null = await this.httpClient.get<GetCreditPackOrderResponseDto | null>(uri);
        return data;
    }

    public async updateCreditPackOrdering(request: UpdateCreditPackOrderRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/credit-packs/order",
            null,
            null
        );

        await this.httpClient.put<UpdateCreditPackOrderRequestDto | null, void>(uri, request);
    }

    public async createCreditPack(request: CreateCreditPackRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/credit-packs",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateCreditPackRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateCreditPack(creditPackId: string | null, request: UpdateCreditPackRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/credit-packs/{creditPackId}",
            { creditPackId: creditPackId },
            null
        );

        await this.httpClient.patch<UpdateCreditPackRequestDto | null, void>(uri, request);
    }

    public async deleteCreditPack(creditPackId: string | null): Promise<void> {
        const uri = this.buildUri(
            "/credit-packs/{creditPackId}",
            { creditPackId: creditPackId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}