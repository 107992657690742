import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Filter: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    d="M1.84891 0.940952L9.26139 9.31824C9.32936 9.39524 9.36695 9.49419 9.36695 9.59689V18.7265L11.0596 17.599V9.59689C11.0596 9.49373 11.0975 9.39434 11.1659 9.31734L18.7092 0.845914L1.84891 0.940952ZM8.94663 19.9319C8.87866 19.9319 8.81039 19.9155 8.74828 19.8821C8.61174 19.809 8.52633 19.6665 8.52633 19.5116V9.75594L0.605573 0.804511C0.49625 0.680902 0.469333 0.504962 0.536551 0.354436C0.603919 0.20391 0.753092 0.106466 0.918054 0.105564L19.6486 0H19.651C19.816 0 19.966 0.0966917 20.034 0.247218C20.1024 0.398496 20.0752 0.57579 19.965 0.699699L11.9002 9.75684V17.8239C11.9002 17.9645 11.8299 18.0958 11.7129 18.1738L9.17971 19.8614C9.10933 19.9083 9.02813 19.9319 8.94663 19.9319Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Filter;
