export const currencyFormatter = (value: number | string, FractionDigits = 2): string => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: FractionDigits,
    });

    return formatter.format(Number(value));
};

export const percentFormatter = (value: number | string): string => {
    return `${value}%`;
};


export const phoneNumberFormatter = (value?: string | null): string =>
    `${value?.substr(0, 3)} ${value?.substr(3, 3)} - ${value?.substr(6, 4)}`;

export const ratioFormatter = (value: number | string | undefined): string =>
    value ? `${value}%` : '';

export const numberFormatter = (value: number | undefined): string =>
    value ? (Math.round(value * 100) / 100).toLocaleString('en') : '';

export const cleanVal = {
    string: (val?: string | null, placeholder = ''): string => (val ? val : placeholder),
    number: (val?: number | null, placeholder = 0): number => (val ? val : placeholder),
};
