// GENERATED FILE - DO NOT MODIFY

export enum OpportunityLostReasonDto {
    Other = "Other",
    Amenity = "Amenity",
    Availability = "Availability",
    Budget = "Budget",
    Configuration = "Configuration",
    Covid19 = "Covid19",
    Location = "Location",
    NoDecision = "NoDecision",
    NonResponse = "NonResponse",
    Parking = "Parking",
    Term = "Term",
    TraditionalLease = "TraditionalLease",
}