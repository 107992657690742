import React, { FunctionComponent } from 'react';
import { Button, Col, Form, Row, Select } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { useTranslation } from 'react-i18next';

import { Delete } from 'Components/icons';
import { LightCampusDetailsInfo } from '../../Models/Location/LightCampusInfo';
import { FormItemProps } from 'antd/es/form';
import { ManagementRoleDto } from 'Api/Features/ManagerUsers/Dtos/ManagementRoleDto';

const { Option } = Select;

interface ManagementRoleSelectorProps {
    id: string;
    gutter: [Gutter, Gutter];
    locations: LightCampusDetailsInfo[];
    selectedLocationId?: string;
    selectedRole: ManagementRoleDto;
    onRoleChange: (id: string, roleId: ManagementRoleDto) => void;
    onLocationChange: (id: string, locationId: string) => void;
    onRemoveRole: (id: string) => void;
    errors: Map<string, string[]>;
    errorKey: string;
    allSelectedRoles: Array<{ roleId: ManagementRoleDto; locationId?: string }>;
}

const ManagementRoleSelector: FunctionComponent<ManagementRoleSelectorProps> = ({
    id,
    gutter,
    locations,
    selectedRole,
    selectedLocationId,
    onRoleChange,
    onLocationChange,
    onRemoveRole,
    errors,
    errorKey,
    allSelectedRoles,
}) => {
    const { t } = useTranslation();
    const handleRoleChange = (value: ManagementRoleDto): void => {
        onRoleChange(id, value);
    };
    const handleLocationChange = (value: string): void => {
        onLocationChange(id, value);
    };
    const generateErrors = (key: string | undefined): FormItemProps => {
        const fieldErrors = errors.get(key || '');
        const errorProps: FormItemProps = {};
        if (fieldErrors) {
            errorProps.hasFeedback = true;
            errorProps.help = fieldErrors.map((errorKey) => t(errorKey)).join('\n');
            errorProps.validateStatus = 'error';
        }
        return errorProps;
    };

    const keys = Array.from(errors.keys()).filter((k) => k.includes(errorKey));
    const roleErrorKey = keys.find((k) => k.includes('name'));
    const locationErrorKey = keys.find((k) => k.includes('CampusId'));
    const roleErrors = generateErrors(roleErrorKey);
    const locationErrors = generateErrors(locationErrorKey);
    let availableRoles: ManagementRoleDto[] = Object.values(ManagementRoleDto);
    if (
        allSelectedRoles.some((role) => role.roleId === ManagementRoleDto.Administrator) &&
        selectedRole !== ManagementRoleDto.Administrator
    ) {
        availableRoles = availableRoles.filter(
            (role) => role !== ManagementRoleDto.Administrator
        );
    }
    
    const chosenLocations = allSelectedRoles
        .filter((role) => role.locationId)
        .map((role) => role.locationId);
    const availableLocations: LightCampusDetailsInfo[] = locations.filter(
        (location) =>
            !chosenLocations.some((locationId) => location.id === locationId) ||
            location.id === selectedLocationId
    );
    return (
        <>
            <Row gutter={gutter}>
                <Col span={11}>
                    <Form.Item label={t('User.role_title')} colon={false} {...roleErrors}>
                        <Select className="w-100" value={selectedRole} onChange={handleRoleChange}>
                            {availableRoles.map((val) => (
                                <Option key={val} value={val}>
                                    {t(`ManagerUser.management_role_${val}`)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={11}>
                    {selectedRole === ManagementRoleDto.Manager ? (
                        <Form.Item label={t('location')} colon={false} {...locationErrors}>
                            <Select
                                className="w-100"
                                value={selectedLocationId}
                                onChange={handleLocationChange}
                            >
                                {availableLocations.map((location) => (
                                    <Option key={location.id!} value={location.id!}>
                                        {location.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    ) : null}
                </Col>
                <Col span={2}>
                    <Form.Item label="&nbsp;" colon={false}>
                        <Button
                            type="default"
                            icon={<Delete />}
                            onClick={(): void => onRemoveRole(id)}
                            htmlType="button"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <hr />
        </>
    );
};

export default ManagementRoleSelector;
