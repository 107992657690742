import { Button, Col, Form, Input, Row, Select } from 'antd';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { LeadStatusDto } from 'Api/Features/Leads/Dtos/LeadStatusDto';
import { OpportunityLostReasonDto } from 'Api/Features/Opportunities/Dtos/OpportunityLostReasonDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Lead as LeadIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { toUpdateLeadRequestDto } from 'Models/Leads/DtoOutput';
import { Lead } from 'Models/Leads/Lead';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { MarkLeadAsLostSchema } from 'Schemas';
import { LeadService } from 'Services/LeadService';
import './mark-as-lost.less';

const { Option } = Select;

const formGutter: [Gutter, Gutter] = [40, 0];

interface LeadModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    lead?: Lead;
}

const LostModal: FunctionComponent<LeadModalProps> = ({ visible, onComplete, lead }) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(MarkLeadAsLostSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const leadService = useService(LeadService);

    //#endregion

    //#region Effects
    //#endregion

    //#region Event handlers
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false, id?: string): void => {
        onComplete(success, id);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <LeadIcon />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t('Lead.lead_confirm_positive_lost'),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (values: Store): Promise<void> => {
        lead!.status = LeadStatusDto.Lost;
        lead!.lostReason = values.lostReason;
        const data = toUpdateLeadRequestDto(lead!);
        data.note = values.note;

        if (!(await validateForm(data))) return;
        try {
            globalLoadingStore.addLoading();
            let createdId = '';

            if (lead?.id !== undefined) await leadService.updateLead(lead.id!, data);
            else createdId = await leadService.createLead(data);

            toastStore.toast({
                type: 'success',
                messageKey: `Lead.lead_edit_success`,
            });
            dismiss(true, createdId);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Lead.lost_are_you_sure')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="MarkAsLost">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name="lostReason"
                                errors={errors}
                                label={t('Lead.loss_reason')}
                                required
                            >
                                <Select>
                                    {Object.keys(OpportunityLostReasonDto).map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`Lead.loss_reason_${x}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name="note"
                                errors={errors}
                                label={t('Lead.lead_add_a_note')}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('Lead.no_keep_lead')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('Lead.yes_lead_lost')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(LostModal);
