// GENERATED FILE - DO NOT MODIFY
import { GetMembershipPrepaidBalanceTransactionsRequestDto } from 'Api/Features/PrepaidBalance/Dtos/GetMembershipPrepaidBalanceTransactionsRequestDto';
import { GetMembershipPrepaidBalanceTransactionsResponseDto } from 'Api/Features/PrepaidBalance/Dtos/GetMembershipPrepaidBalanceTransactionsResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class PrepaidBalanceProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getMembershipPrepaidBalanceTransactions(membershipId: string | null, request: GetMembershipPrepaidBalanceTransactionsRequestDto | null): Promise<GetMembershipPrepaidBalanceTransactionsResponseDto | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/prepaid-balance-transactions",
            { membershipId: membershipId },
            { ...request || undefined }
        );

        const data: GetMembershipPrepaidBalanceTransactionsResponseDto | null = await this.httpClient.get<GetMembershipPrepaidBalanceTransactionsResponseDto | null>(uri);
        return data;
    }
}