import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useService, useStores } from 'Hooks';
import { AddOn as AddOnIcon, Delete } from 'Components/icons';
import { theme } from 'variant';
import { Col, Layout, Row } from 'antd';
import { SimpleList } from 'Components/simple-list';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { AddOnService } from 'Services/AddOnService';
import { AddOn } from 'Models/AddOn/AddOn';
import LocationHeader from 'Components/location-header/location-header';
import { currencyFormatter } from 'Utils/NumberUtils';
import EditAddOnModal from '../edit-add-on';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { ActionMenu } from 'Components/action-menu';
import { Tag } from 'Components/tag';

const { Content } = Layout;

interface SimpleListItemProps {
    title: string;
    description?: string;
    avatar?: React.ReactNode;
}

const AddOnDetails: FunctionComponent = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { globalLoadingStore, confirmationModalStore, toastStore } = useStores();
    const [data, setData] = useState<AddOn | undefined>(undefined);
    const [description, setDescription] = useState<SimpleListItemProps[]>([]);
    const [suggestedPrice, setSuggestedPrice] = useState<SimpleListItemProps[]>([]);
    const addOnService = useService(AddOnService);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const { id, addOnId } = useParams<{ id: string; addOnId: string }>();

    const setSimpleListsData = useCallback((addOn: AddOn): void => {
        const description: SimpleListItemProps[] = [];
        if (addOn.description) {
            description.push({
                title: addOn.description,
            });
        }
        setDescription(description);

        const suggestedPrice: SimpleListItemProps[] = [];
        if (addOn.suggestedPrice) {
            suggestedPrice.push({
                title: currencyFormatter(addOn.suggestedPrice),
            });
        }
        setSuggestedPrice(suggestedPrice);
    }, []);

    const fetch = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const data = await addOnService.getAddOn(addOnId);
            if (data) {
                setData(data);
                setSimpleListsData(data);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, setSimpleListsData, addOnId, addOnService]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const deleteAddOn = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                width: 850,
                icon: <Delete />,
                title: t('model_delete_confirm_title', { param1: t('add_on') }),
                message: t(`action_cannot_be_undone`),
                positiveText: t('model_confirm_positive_delete', { param1: t('add_on') }),
                negativeText: t('model_confirm_negative_delete', { param1: t('add_on') }),
            }))
        )
            return;

        await addOnService.deleteAddOn(addOnId);
        toastStore.toast({
            type: 'success',
            messageKey: t('model_delete_success', { param1: t('add-on') }),
        });
        history.goBack();
    };

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'management',
            nameKey: 'Location.management_label',
        },
        {
            path: 'add-ons',
            nameKey: 'addons',
        },
        {
            name: data?.name || '',
            path: data?.id || '',
        },
    ];

    const actions: ActionMenuOption[] = [
        {
            key: 'edit',
            title: t('AddOn.edit_this_add_on'),
            action: (): void => setEditModalOpen(true),
        },
        {
            key: 'delete',
            title: t('AddOn.delete_this_add_on'),
            action: (): Promise<void> => deleteAddOn(),
        },
    ];

    return (
        <div className="AddOnDetails">
            <LocationHeader
                title={data?.name || ''}
                subTitle={<Tag label={data?.category?.name || ''} />}
                defaultImg={<AddOnIcon fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={<ActionMenu options={actions} type="primary" trigger="click" />}
            />
            <Content>
                <Row gutter={40}>
                    {suggestedPrice.length > 0 && (
                        <Col span={12}>
                            <SimpleList
                                title={t('suggested_price_monthly')}
                                data={suggestedPrice}
                            />
                        </Col>
                    )}
                    {description.length > 0 && (
                        <Col span={12}>
                            <SimpleList title={t('description')} data={description} />
                        </Col>
                    )}
                </Row>
            </Content>

            {editModalOpen && (
                <EditAddOnModal
                    visible={editModalOpen}
                    locationId={id}
                    addOn={data}
                    onComplete={(success: boolean): void => {
                        setEditModalOpen(false);
                        if (success) fetch();
                    }}
                />
            )}
        </div>
    );
};

export default AddOnDetails;
