import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { DayPassTypeProxy } from 'Api/Features/DayPassTypes/DayPassTypeProxy';
import { CreateDayPassTypeRequestDto } from 'Api/Features/DayPassTypes/Dtos/CreateDayPassTypeRequestDto';
import { DayPassTypeDto } from 'Api/Features/DayPassTypes/Dtos/DayPassTypeDto';
import { GetDayPassTypeOrderResponseDto } from 'Api/Features/DayPassTypes/Dtos/GetDayPassTypeOrderResponseDto';
import { GetDayPassTypesResponseDto } from 'Api/Features/DayPassTypes/Dtos/GetDayPassTypesResponseDto';
import { UpdateDayPassTypeOrderRequestDto } from 'Api/Features/DayPassTypes/Dtos/UpdateDayPassTypeOrderRequestDto';
import { UpdateDayPassTypeRequestDto } from 'Api/Features/DayPassTypes/Dtos/UpdateDayPassTypeRequestDto';
import { GetDayPassTypesRequestDto } from 'Api/Features/DayPassTypes/Dtos/GetDayPassTypesRequestDto';


@inject(DayPassTypeProxy)
export class DayPassTypeService extends ApiService {
    constructor(private readonly dayPasstypeProxy: DayPassTypeProxy) {
        super();
    }

    public async getDayPassType(dayPassTypeId: string): Promise<DayPassTypeDto | null> {
        const data: DayPassTypeDto | null = await this.dayPasstypeProxy.getDayPassType(
            dayPassTypeId
        );
        return data;
    }

    public async getDayPassTypes(
        request: GetDayPassTypesRequestDto | null
    ): Promise<[DayPassTypeDto[], number]> {
        const data: GetDayPassTypesResponseDto | null = await this.dayPasstypeProxy.getDayPassTypes(
            request
        );
        return [
            data?.items?.filter((item) => item !== null).map((item) => item!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async createDayPassType(request: CreateDayPassTypeRequestDto | null): Promise<string> {
        const data: string = await this.dayPasstypeProxy.createDayPassType(request);
        return data;
    }

    public async updateDayPassType(
        dayPassTypeId: string,
        request: UpdateDayPassTypeRequestDto | null
    ): Promise<void> {
        await this.dayPasstypeProxy.updateDayPassType(dayPassTypeId, request);
    }

    public async deleteDayPassType(dayPassTypeId: string): Promise<void> {
        await this.dayPasstypeProxy.deleteDayPassType(dayPassTypeId);
    }

    public async getDayPassTypeOrdering(): Promise<GetDayPassTypeOrderResponseDto | null> {
        const data: GetDayPassTypeOrderResponseDto | null = await this.dayPasstypeProxy.getDayPassTypeOrdering();
        return data;
    }

    public async updateDayPassTypeOrdering(
        request: UpdateDayPassTypeOrderRequestDto | null
    ): Promise<void> {
        await this.dayPasstypeProxy.updateDayPassTypeOrdering(request);
    }

    public async getCampusDayPassTypeOrdering(
        campusId: string | null
    ): Promise<GetDayPassTypeOrderResponseDto | null> {
        const data: GetDayPassTypeOrderResponseDto | null = await this.dayPasstypeProxy.getCampusDayPassTypeOrdering(
            campusId
        );
        return data;
    }

    public async updateCampusDayPassTypeOrdering(
        campusId: string | null,
        request: UpdateDayPassTypeOrderRequestDto | null
    ): Promise<void> {
        await this.dayPasstypeProxy.updateCampusDayPassTypeOrdering(campusId, request);
    }
}
