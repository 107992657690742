import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Info: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.0005 0C4.47708 0 0.000488281 4.47737 0.000488281 10C0.000488281 15.5234 4.47708 20.0008 10.0005 20.0008C15.5231 20.0008 20.0005 15.5234 20.0005 10C20.0005 4.47737 15.5231 0 10.0005 0ZM10.0005 1.09633C14.9104 1.09633 18.9042 5.09091 18.9042 10C18.9042 14.9099 14.9104 18.9044 10.0005 18.9044C5.09062 18.9044 1.09681 14.9099 1.09681 10C1.09681 5.09091 5.09062 1.09633 10.0005 1.09633Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.95345 4.61385C9.34378 4.61385 8.85016 5.10747 8.85016 5.71714C8.85016 6.32604 9.34378 6.82043 9.95345 6.82043C10.5631 6.82043 11.0567 6.32604 11.0567 5.71714C11.0567 5.10747 10.5631 4.61385 9.95345 4.61385Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.9649 8.1272C9.96026 8.1272 9.95561 8.12797 9.95097 8.12797H8.29991C8.07863 8.12797 7.89759 8.30979 7.89759 8.53106C7.89759 8.75311 8.07863 8.93416 8.29991 8.93416H9.07205C9.06819 8.96588 9.062 8.99683 9.062 9.0301V14.0986C9.062 14.1334 9.06896 14.1667 9.07283 14.2007H8.29991C8.07863 14.2007 7.89759 14.3817 7.89759 14.603C7.89759 14.8251 8.07863 15.0061 8.29991 15.0061H11.7011C11.9223 15.0061 12.1034 14.8251 12.1034 14.603C12.1034 14.3817 11.9223 14.2007 11.7011 14.2007H10.857C10.8608 14.1667 10.8678 14.1334 10.8678 14.0986V9.0301C10.8678 8.93803 10.8624 8.61075 10.8624 8.52101C10.8624 8.29973 10.7293 8.12797 10.5073 8.12797H9.97883C9.97418 8.12797 9.96954 8.1272 9.9649 8.1272Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Info;
