import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ImageFile: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 20 25" fill="none" {...props}>
                <path
                    d="M19.0331 5.9L13.3665 0.533333V0.5H0.799805V24.5H19.0165V5.93333L19.0331 5.9ZM13.4831 2.1L17.3331 5.8L13.4165 5.88333L13.4831 2.1ZM18.0165 23.5H1.7998V1.5H12.3665V6.93333H18.0165V23.5Z"
                    fill={fill}
                />
                <path
                    d="M11.6158 10.5669L8.54909 15.8836L6.89909 13.0002L3.48242 18.9169H16.4491L11.6158 10.5669ZM5.21576 17.9169L6.89909 15.0002L7.96576 16.8836L8.56575 17.9169H5.21576ZM9.73242 17.9169L9.13242 16.8836L11.6158 12.5669L14.6991 17.9169H9.73242Z"
                    fill={fill}
                />
                <path
                    d="M8.44954 12.2834C7.56621 12.2834 6.86621 11.5667 6.86621 10.7C6.86621 9.83337 7.58288 9.1167 8.44954 9.1167C9.31621 9.1167 10.0329 9.83337 10.0329 10.7C10.0329 11.5667 9.33288 12.2834 8.44954 12.2834ZM8.44954 10.1C8.11621 10.1 7.86621 10.3667 7.86621 10.6834C7.86621 11 8.13288 11.2667 8.44954 11.2667C8.76621 11.2667 9.03288 11 9.03288 10.6834C9.03288 10.3667 8.78288 10.1 8.44954 10.1Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ImageFile;
