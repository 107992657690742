import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { ReminderDto } from 'Api/Features/Reminders/Dtos/ReminderDto';
import { UpdateReminderRequestDto } from 'Api/Features/Reminders/Dtos/UpdateReminderRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Lead } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { TIME_PICKER_FORMAT } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateReminderSchema } from 'Schemas';
import { ReminderService } from 'Services/ReminderService';
import './edit-reminder.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface EditReminderModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    reminder?: ReminderDto | null;
}

const EditReminderModal: FunctionComponent<EditReminderModalProps> = ({
    visible,
    onComplete,
    reminder,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(CreateReminderSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const reminderService = useService(ReminderService);
    //#endregion
    
    //#region Effects
    useEffect(() => {
        if (reminder) {
            form.setFieldsValue({
                oldDate: moment.utc(reminder.date),
                note: reminder.note,
            });
        }
    }, [reminder, form]);
    //#endregion

    //#region Event handlers
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Lead />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`Lead.reminder_confirm_positive_edit`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (values: Store): Promise<void> => {
        const updateReminder: UpdateReminderRequestDto = {
            date: values.date ?? undefined,
            note: values.note,
        };

        if (!(await validateForm(updateReminder))) return;
        try {
            globalLoadingStore.addLoading();

            await reminderService.updateReminder(reminder?.id!, updateReminder);

            toastStore.toast({
                type: 'success',
                messageKey: `Lead.reminder_changed_success`,
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Lead.edit_reminder')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="EditReminder">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="oldDate"
                                label={t('Lead.old_reminder_date')}
                                required
                            >
                                <DatePicker
                                    className="w-100"
                                    placeholder={t('Lead.reminder_scheduled_date_placeholder')}
                                    defaultValue={moment.utc(reminder?.date)}
                                    disabled={true}
                                    showTime={{format: TIME_PICKER_FORMAT}}
                                    use12Hours
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="date"
                                label={t('Lead.new_reminder_date')}
                                required
                            >
                                <DatePicker
                                    className="w-100"
                                    placeholder={t('Lead.reminder_scheduled_date_placeholder')}
                                    showTime={{format: TIME_PICKER_FORMAT}}
                                    use12Hours
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="note"
                                label={t('Lead.lead_add_a_note')}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('Lead.update_activity_reminder')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(EditReminderModal);
