import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Units: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 14" fill="none" {...props}>
                <path
                    d="M15.68 4H0.32C0.142933 4 0 4.14293 0 4.32C0 4.49707 0.142933 4.64 0.32 4.64H1.38347V11.4667C1.38347 11.6437 1.52747 11.7867 1.70347 11.7867C1.88053 11.7867 2.02347 11.6437 2.02347 11.4667V4.64H9.2V9.2992H13.9765V11.4667C13.9765 11.6437 14.1195 11.7867 14.2965 11.7867C14.4725 11.7867 14.6165 11.6437 14.6165 11.4667V4.64H15.68C15.8571 4.64 16 4.49707 16 4.32C16 4.14293 15.8571 4 15.68 4ZM13.9733 4.82987V6.39147H9.84V4.82987H13.9733ZM13.9733 8.6592H9.84V7.09867H13.9733V8.6592Z"
                    fill={fill}
                />
                <path
                    d="M12.2933 5.93067H11.52C11.3429 5.93067 11.2 5.78773 11.2 5.61067C11.2 5.4336 11.3429 5.29067 11.52 5.29067H12.2933C12.4704 5.29067 12.6133 5.4336 12.6133 5.61067C12.6133 5.78773 12.4704 5.93067 12.2933 5.93067Z"
                    fill={fill}
                />
                <path
                    d="M12.2933 8.19947H11.52C11.3429 8.19947 11.2 8.05653 11.2 7.87947C11.2 7.7024 11.3429 7.55947 11.52 7.55947H12.2933C12.4704 7.55947 12.6133 7.7024 12.6133 7.87947C12.6133 8.05653 12.4704 8.19947 12.2933 8.19947Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Units;
