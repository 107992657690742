import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AngleBottom: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => (
    <span role="img" className="anticon">
        <svg width="1em" height="1em" viewBox="0 0 17 10" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.8113 1.47522C16.0714 1.2125 16.0714 0.787078 15.8113 0.523689C15.5513 0.261635 15.1245 0.261635 14.8644 0.523689L8.00968 8.04726L1.14825 0.523689C0.888198 0.261635 0.468111 0.261635 0.201388 0.523689C-0.0586659 0.787078 -0.0586659 1.2125 0.201388 1.47522L7.49624 9.48156C7.63627 9.62159 7.82298 9.6816 8.00968 9.67226C8.18972 9.6816 8.37643 9.62159 8.51646 9.48156L15.8113 1.47522Z"
                fill={fill || 'currentColor'}
            />
        </svg>
    </span>
);

export default AngleBottom;
