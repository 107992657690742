import { StoredFileDto } from "Api/Features/General/Dtos/StoredFileDto";
import { CreateMembershipFileRequestDto } from "Api/Features/Memberships/Dtos/CreateMembershipFileRequestDto";
import { GetMembershipFilesRequestDto } from "Api/Features/Memberships/Dtos/GetMembershipFilesRequestDto";
import { GetMembershipFilesResponseDto } from "Api/Features/Memberships/Dtos/GetMembershipFilesResponseDto";
import { MembershipFileProxy } from "Api/Features/Memberships/MembershipFileProxy";
import { inject } from "aurelia-dependency-injection";
import { ApiService } from "./ApiService";

@inject(MembershipFileProxy)
export class MembershipFileService extends ApiService {
    constructor(private readonly membershipFileProxy: MembershipFileProxy) {
        super();
    }

    public async getMembershipFiles(membershipId: string | null, request: GetMembershipFilesRequestDto | null): Promise<[StoredFileDto[], number]> {
        const data: GetMembershipFilesResponseDto | null = await this.membershipFileProxy.getMembershipFiles(
            membershipId,
            request
        );
        return [
            data?.items?.filter((file) => file !== null).map((file) => file!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async createMembershipFile(membershipId: string | null, request: CreateMembershipFileRequestDto | null): Promise<string> {
        const data: string = await this.membershipFileProxy.createMembershipFile(membershipId, request);
        return data;
    }

    public async deleteMembershipFile(membershipId: string | null, fileId: string): Promise<void> {
        await this.membershipFileProxy.deleteMembershipFile(membershipId, fileId);
    }
}
