import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Actions: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.2 2.913A1.915 1.915 0 0012.287 1a1.915 1.915 0 00-1.913 1.913c0 1.054.858 1.912 1.913 1.912A1.915 1.915 0 0014.2 2.913zm-4.825 0A2.916 2.916 0 0112.287 0 2.916 2.916 0 0115.2 2.913a2.916 2.916 0 01-2.913 2.912 2.916 2.916 0 01-2.912-2.912zM14.2 11.975a1.915 1.915 0 00-1.913-1.913 1.915 1.915 0 00-1.913 1.913c0 1.055.858 1.913 1.913 1.913a1.915 1.915 0 001.913-1.913zm-4.825 0a2.916 2.916 0 012.912-2.913 2.916 2.916 0 012.913 2.913 2.916 2.916 0 01-2.913 2.913 2.916 2.916 0 01-2.912-2.913zM14.2 21.038a1.915 1.915 0 00-1.913-1.913 1.915 1.915 0 00-1.913 1.913c0 1.054.858 1.912 1.913 1.912a1.915 1.915 0 001.913-1.912zm-4.825 0a2.916 2.916 0 012.912-2.913 2.916 2.916 0 012.913 2.913 2.916 2.916 0 01-2.913 2.912 2.916 2.916 0 01-2.912-2.912z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Actions;
