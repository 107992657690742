// GENERATED FILE - DO NOT MODIFY
import { GetRevenueOccupancyTargetsRequestDto } from 'Api/Features/RevenueOccupancyTargets/Dtos/GetRevenueOccupancyTargetsRequestDto';
import { GetRevenueOccupancyTargetsResponseDto } from 'Api/Features/RevenueOccupancyTargets/Dtos/GetRevenueOccupancyTargetsResponseDto';
import { UpdateRevenueOccupancyTargetsRequestDto } from 'Api/Features/RevenueOccupancyTargets/Dtos/UpdateRevenueOccupancyTargetsRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class RevenueOccupancyTargetsProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getRevenueOccupancyTargets(campusId: string | null, request: GetRevenueOccupancyTargetsRequestDto | null): Promise<GetRevenueOccupancyTargetsResponseDto | null> {
        const uri = this.buildUri(
            "/campuses/{campusId}/revenue-occupancy-targets",
            { campusId: campusId },
            { ...request || undefined }
        );

        const data: GetRevenueOccupancyTargetsResponseDto | null = await this.httpClient.get<GetRevenueOccupancyTargetsResponseDto | null>(uri);
        return data;
    }

    public async updateRevenueOccupancyTargets(campusId: string | null, request: UpdateRevenueOccupancyTargetsRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/campuses/{campusId}/revenue-occupancy-targets",
            { campusId: campusId },
            null
        );

        await this.httpClient.post<UpdateRevenueOccupancyTargetsRequestDto | null, void>(uri, request);
    }
}