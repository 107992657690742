import { Button, Col, Form, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { CreateSubscriptionProposalAddOnDto } from 'Api/Features/SubscriptionProposals/Dtos/CreateSubscriptionProposalAddOnDto';
import { CreateSubscriptionProposalDiscountDto } from 'Api/Features/SubscriptionProposals/Dtos/CreateSubscriptionProposalDiscountDto';
import { CreateSubscriptionProposalPeriodDto } from 'Api/Features/SubscriptionProposals/Dtos/CreateSubscriptionProposalPeriodDto';
import { CreateSubscriptionProposalRequestDto } from 'Api/Features/SubscriptionProposals/Dtos/CreateSubscriptionProposalRequestDto';
import { SubscriptionProposalDetailsDto } from 'Api/Features/SubscriptionProposals/Dtos/SubscriptionProposalDetailsDto';
import { SubscriptionDetailsDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsDto';
import BaseModal from 'Components/base-modal/base-modal';
import FormStep, { StepInfo } from 'Components/form-step/form-step';
import { Plan } from 'Components/icons';
import { useFormValidation, useService, useStores } from 'Hooks';
import { UTC_DATE_TIME_ZONE } from 'Models/Constants';
import { Opportunity } from 'Models/Opportunities/Opportunity';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProposalFormSection, {
    PaymentModalities,
} from 'Routes/authenticated/proposals/proposal-form-section';
import SummaryFormSection from 'Routes/authenticated/proposals/summary-form-section';
import { CreateProposalSchema } from 'Schemas';
import { SubscriptionProposalService } from 'Services/SubscriptionProposalService';
import { SubscriptionService } from 'Services/SubscriptionService';
import { dateMomentToString } from 'Utils/TimeUtils';
import './create-proposal.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface ProposalModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    opportunity?: Opportunity;
}

enum ProposalFormStep {
    Proposal = 'Proposal',
    Summary = 'Summary',
}

const CreateProposalModal: FunctionComponent<ProposalModalProps> = ({
    visible,
    onComplete,
    opportunity,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(CreateProposalSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const subscriptionProposalService = useService(SubscriptionProposalService);
    const subscriptionService = useService(SubscriptionService);

    const [convertMtmExpirationCheckboxState, setConvertMtmExpirationCheckboxState] = useState(
        false
    );
    const [isMtm, setIsMtm] = useState(true);

    const [activeFormStep, setactiveFormStep] = useState<ProposalFormStep>(
        ProposalFormStep.Proposal
    );
    const [proposalSummary, setProposalSummary] = useState<SubscriptionProposalDetailsDto>();

    const [selectedUnitIds, setSelectedUnitIds] = useState<string[] | undefined>();

    const [oldSubscription, setOldSubscription] = useState<SubscriptionDetailsDto>();

    const modalRef = useRef<HTMLDivElement>(null);
    //#endregion

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const success = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Plan />,
                title: t(`Proposal.proposal_sent`),
                message: t(`Proposal.proposal_has_been_sent_by_email`),
                positiveText: t('ok'),
            }))
        )
            return;
        dismiss(true);
    };

    const onBackClick = (): void => {
        setactiveFormStep(ProposalFormStep.Proposal);
        if (modalRef.current) modalRef.current.scrollIntoView();
    };

    //If opportunity was created from subscription renewal or modification, fetch and populate proposal with info from old subscription
    const fetchOldSubscription = useCallback(
        async (subscriptionId: string) => {
            globalLoadingStore.addLoading();

            try {
                const subscription = await subscriptionService.getSubscription(subscriptionId);

                if (subscription) setOldSubscription(subscription);
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [globalLoadingStore, subscriptionService]
    );

    useEffect(() => {
        if (
            (opportunity?.isSubscriptionRenewal || opportunity?.isSubscriptionModification) &&
            opportunity.parentSubscription?.id
        )
            fetchOldSubscription(opportunity.parentSubscription?.id);
    }, [opportunity, fetchOldSubscription]);

    useEffect(() => {
        //populate proposal for renewal
        if (oldSubscription && opportunity?.isSubscriptionRenewal) {
            form.setFieldsValue({
                startDate: oldSubscription.monthToMonthAmount
                    ? moment()
                    : moment.utc(oldSubscription?.endDate),
                referenceNumber: oldSubscription.referenceNumber,
                securityDeposit: oldSubscription.securityDeposit,
                creditPointsPerInterval: oldSubscription.creditPointsPerInterval,
                paymentModalities: oldSubscription.isCheckPayable
                    ? PaymentModalities.Check
                    : oldSubscription.isChargedAutomatically
                    ? PaymentModalities.PreAuthorized
                    : PaymentModalities.Manual,
                addOns: oldSubscription?.addOns?.map((addOn) => ({
                    id: { value: addOn?.addOnId },
                    price: addOn?.price,
                })),
                planId: { value: oldSubscription.planId },
            });
        }

        //populate proposal for modification
        if (oldSubscription && opportunity?.isSubscriptionModification) {
            const startDate = moment.utc(oldSubscription.startDate).isAfter(moment())
            ? moment.utc(oldSubscription.startDate).add(1, 'day')
            : moment();
            
            form.setFieldsValue({
                startDate: startDate,
                endDate: oldSubscription.endDate ? moment.utc(oldSubscription.endDate) : undefined,
                referenceNumber: oldSubscription.referenceNumber,
                securityDeposit: oldSubscription.securityDeposit,
                creditPointsPerInterval: oldSubscription.creditPointsPerInterval,
                paymentModalities: oldSubscription.isCheckPayable
                    ? PaymentModalities.Check
                    : oldSubscription.isChargedAutomatically
                    ? PaymentModalities.PreAuthorized
                    : PaymentModalities.Manual,
                addOns: oldSubscription?.addOns?.map((addOn) => ({
                    id: { value: addOn?.addOnId },
                    price: addOn?.price,
                })),
                discounts: oldSubscription.discounts
                    ?.filter(
                        (discount) =>
                            !(discount?.endDate && moment(discount.endDate).isBefore(startDate))
                    )
                    .map((discount) => ({
                        title: discount?.title,
                        internalNote: discount?.internalNote,
                        startDate: discount?.startDate ? moment.utc(discount.startDate) : undefined,
                        endDate: discount?.endDate ? moment.utc(discount.endDate) : undefined,
                        amount: discount?.amount,
                    })),
                planId: { value: oldSubscription.planId },
                periods: oldSubscription?.periods?.map((period) => ({
                    startDate: period?.startDate ? moment.utc(period.startDate) : undefined,
                    amount: period?.price,
                })),
                monthToMonthAmount: oldSubscription.monthToMonthAmount,
            });
            setConvertMtmExpirationCheckboxState(
                oldSubscription.becomesMonthToMonthOnExpiration ?? false
            );
            setIsMtm(oldSubscription.endDate === null);
            setSelectedUnitIds(oldSubscription.units?.map((unit) => unit?.id!));
        }
    }, [oldSubscription, form, opportunity]);

    //#region Submit / Exit
    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Plan />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t(`Proposal.proposal_confirm_positive`),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const multiplePricings: CreateSubscriptionProposalPeriodDto[] = [];
        if (isMtm) {
            multiplePricings.push({
                startDate: formValues.startDate,
                endDate: null,
                amount: formValues.monthToMonthAmount,
            });
        } else if (formValues.periods) {
            formValues.periods.sort(
                (period: any, compare: any) => period.startDate - compare.startDate
            );
            let i: number;
            for (i = 0; i < formValues.periods.length; i++) {
                const currentPeriod = formValues.periods[i];
                if (formValues.periods[i + 1] === undefined) {
                    //create period to subscription end
                    multiplePricings.push({
                        startDate: moment(currentPeriod.startDate).format(UTC_DATE_TIME_ZONE),
                        endDate: dateMomentToString(
                            moment(formValues.endDate).format(UTC_DATE_TIME_ZONE)
                        ),
                        amount: currentPeriod.amount,
                    } as CreateSubscriptionProposalPeriodDto);
                } else {
                    multiplePricings.push({
                        startDate: moment(currentPeriod.startDate).format(UTC_DATE_TIME_ZONE),
                        endDate: dateMomentToString(
                            moment(formValues.periods[i + 1].startDate)
                                .add(-1, 'day')
                                .format(UTC_DATE_TIME_ZONE)
                        ),
                        amount: currentPeriod.amount,
                    } as CreateSubscriptionProposalPeriodDto);
                }
            }
        }

        const discounts = formValues.discounts?.map(
            (discount: any) =>
                ({
                    ...discount,
                    startDate: discount.startDate
                        ? moment(discount.startDate).format(UTC_DATE_TIME_ZONE)
                        : undefined,
                    endDate: discount.endDate
                        ? moment(discount.endDate).format(UTC_DATE_TIME_ZONE)
                        : undefined,
                    title: discount.title ?? t('discount'),
                } as CreateSubscriptionProposalDiscountDto)
        );

        const addOns = formValues.addOns?.map(
            (addOn: any) =>
                ({
                    id: addOn.id.value,
                    price: addOn.price,
                } as CreateSubscriptionProposalAddOnDto)
        );

        const data = {
            ...formValues,
            planId: formValues.planId?.value,
            opportunityId: opportunity ? opportunity.id : undefined,
            periods: multiplePricings,
            discounts: discounts,
            addOns: addOns,
            unitIds: selectedUnitIds,
            membershipId: opportunity ? opportunity.membership?.id : formValues.membershipId,
            campusId: opportunity ? opportunity.campus?.id : formValues.campusId,
            becomesMonthToMonthOnExpiration: isMtm ? false : convertMtmExpirationCheckboxState,
            isCheckPayable: formValues.paymentModalities === PaymentModalities.Check,
            isChargedAutomatically:
                formValues.paymentModalities === undefined ||
                formValues.paymentModalities === PaymentModalities.PreAuthorized,
            isMtm: isMtm,
            monthToMonthAmount: isMtm ? null : formValues.monthToMonthAmount, //if mtm the price will be in period and api doesnt want it here
            securityDeposit: formValues.securityDeposit ?? undefined
        } as CreateSubscriptionProposalRequestDto;

        if (!(await validateForm(data))) return;

        try {
            globalLoadingStore.addLoading();
            if (activeFormStep === ProposalFormStep.Proposal) {
                const summary = await subscriptionProposalService.getSubscriptionProposalPreview(
                    data
                );
                if (summary) {
                    setProposalSummary(summary);
                    setactiveFormStep(ProposalFormStep.Summary);
                    if (modalRef.current) modalRef.current.scrollIntoView();
                }
            } else {
                await subscriptionProposalService.createSubscriptionProposal(data);
                globalLoadingStore.removeLoading();
                await success();
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Proposal.make_a_proposal')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="CreateProposal" ref={modalRef}>
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <FormStep
                                Steps={Object.keys(ProposalFormStep).map(
                                    (step: string, i: number) => {
                                        return {
                                            active: step === activeFormStep,
                                            stepNumber: i + 1,
                                            name: step,
                                        } as StepInfo;
                                    }
                                )}
                            />
                        </Col>
                    </Row>

                    <div className={activeFormStep === ProposalFormStep.Proposal ? '' : 'd-none'}>
                        <ProposalFormSection
                            errors={errors}
                            opportunity={opportunity}
                            form={form}
                            handleConvertToMtmOnExpirationChange={(value: boolean): void =>
                                setConvertMtmExpirationCheckboxState(value)
                            }
                            handleMtmChange={(value: boolean): void => setIsMtm(value)}
                            handleUnitChange={useCallback(
                                (values: string[]): void => setSelectedUnitIds(values),
                                []
                            )}
                            oldSubscription={oldSubscription}
                            isSubscriptionModification={opportunity?.isSubscriptionModification}
                            isSubscriptionRenewal={opportunity?.isSubscriptionRenewal}
                        />
                    </div>

                    <div className={activeFormStep === ProposalFormStep.Summary ? '' : 'd-none'}>
                        <SummaryFormSection
                            proposal={proposalSummary}
                            membership={opportunity?.membership ?? undefined}
                        />
                    </div>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={
                                activeFormStep === ProposalFormStep.Proposal ? exit : onBackClick
                            }
                        >
                            {activeFormStep === ProposalFormStep.Proposal ? t('cancel') : t('back')}
                        </Button>

                        <Button type="primary" className="positive" htmlType="submit">
                            {activeFormStep === ProposalFormStep.Proposal
                                ? t('next')
                                : t('Proposal.send_proposal')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(CreateProposalModal);
