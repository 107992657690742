import { Button, Row } from 'antd';
import { Calendar, Plan } from 'Components/icons';
import Icon from 'Components/icons/icon';
import { Tag } from 'Components/tag';
import { TagProps } from 'Components/tag/tag';
import { DISPLAY_DATETIME_12H } from 'Models/Constants';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { displayFormatedDate } from 'Utils/TimeUtils';
import { theme } from 'variant';
import './item-with-buttons.less';

export interface ButtonsProps {
    label: string;
    primary?: boolean;
    onClick: () => void;
}

export interface ItemWithButtonsProps {
    title: string;
    subTitle?: string;
    tag?: TagProps;
    buttonsList: ButtonsProps[];
    lightTheme?: boolean;
    small?: boolean;
    proposalAnswerSubTitle?: ProposalAnswerSubTitle;
    proposalAnswerDate?: string | null;
    proposalDetails?: ItemWithButtonsProposalDetails;
}

export interface ProposalAnswerSubTitle {
    answeredBy?: string;
    answerAction?: string;
    answeredOnBehalf?: string;
    answerTrigger?: string;
}

export interface ItemWithButtonsProposalDetails {
    planName: string;
    planPrice: string;
    dateString: string;
}

const ItemWithButtons: FunctionComponent<ItemWithButtonsProps> = ({
    title,
    subTitle,
    tag,
    buttonsList,
    lightTheme,
    small = false,
    proposalAnswerSubTitle,
    proposalAnswerDate,
    proposalDetails,
}) => {
    const classNamesFromProps = [lightTheme ? 'light-theme' : '', small ? 'small' : ''].join(' ');
    const { t } = useTranslation();

    return (
        <div className={`ItemWithButtons ${classNamesFromProps}`}>
            <Row className="ItemWithButtons__divider">
                <div>
                    <div className="ItemWithButtons__title">{title}</div>

                    {proposalDetails && (
                        <div className="ItemsWithButtons__proposal-details">
                            <div className="details-container">
                                <Plan fill={theme['primary-color']} />
                                <div className="price-items-container">
                                    <span className="bold text">{proposalDetails.planName}</span>
                                    <span className="bold price text">
                                        {proposalDetails.planPrice}
                                    </span>
                                </div>
                            </div>

                            <div className="details-container">
                                <Calendar fill={theme['primary-color']} />
                                <span className="bold text">{proposalDetails.dateString}</span>
                            </div>
                        </div>
                    )}

                    {proposalAnswerSubTitle && (
                        <div className="ItemsWithButtons__proposal-answer-sub-title">
                            <span className="bold">{proposalAnswerSubTitle.answeredBy}</span>{' '}
                            <span>{proposalAnswerSubTitle.answerAction}</span>{' '}
                            <span className="bold">
                                {t('Opportunity.opportunity_proposal_label')}
                            </span>{' '}
                            <span>{proposalAnswerSubTitle.answeredOnBehalf}</span>{' '}
                            <span>{proposalAnswerSubTitle.answerTrigger}</span>
                            {proposalAnswerDate && (
                                <div className="proposal-answer-time">
                                    {displayFormatedDate(proposalAnswerDate, DISPLAY_DATETIME_12H)}
                                </div>
                            )}
                        </div>
                    )}

                    {subTitle && (
                        <div className="ItemWithButtons__sub-title">
                            <span>File :</span>
                            <Icon iconName={'Pdf'} />
                            {subTitle}
                        </div>
                    )}

                    {tag && <Tag label={tag.label} color={tag.color} />}
                </div>

                <div className="ItemWithButtons__buttons-list">
                    {buttonsList &&
                        buttonsList.map((item, i) => (
                            <Button
                                key={i}
                                className={item.primary ? undefined : 'secondary'}
                                type={item.primary ? 'primary' : undefined}
                                onClick={item.onClick}
                            >
                                {item.label}
                            </Button>
                        ))}
                </div>
            </Row>
        </div>
    );
};

export default ItemWithButtons;
