import { LeadProxy } from 'Api/Features/Leads/LeadProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { CreateLeadCommentRequestDto } from 'Api/Features/Leads/Dtos/CreateLeadCommentRequestDto';

@inject(LeadProxy)
export class LeadCommentService extends ApiService {
    constructor(private readonly leadProxy: LeadProxy) {
        super();
    }

    public async createComment(request: CreateLeadCommentRequestDto, leadId: string): Promise<void> {
        return await this.leadProxy.createLeadComment(leadId, request);
    }
}
