import React, { ReactNode } from 'react';
import { generateTimespan } from 'Utils/TimeUtils';

interface TimespanProps {
    min: number;
    max: number;
    zoom: number;
}
export default class Timespan extends React.Component<TimespanProps> {
    render(): ReactNode {
        return (
            <div className="FlexibleBooking__Timespan">
                {generateTimespan(this.props.min, this.props.max, this.props.zoom, false, true).map(
                    (timespan) => {
                        return (
                            <div className="time-cell" key={timespan} id={timespan}>
                                <div className="time">{timespan}</div>
                            </div>
                        );
                    }
                )}
            </div>
        );
    }
}
