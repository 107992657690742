export enum DateRangeDropdownEnum {
    ThisWeek = 'ThisWeek',
    LastWeek = 'LastWeek',
    ThisMonth = 'ThisMonth',
    LastMonth = 'LastMonth',
    Custom = 'Custom'
}

export enum DateRangeDropdownCommunityEventEnum {
    Past = 'Past',
    Ongoing = 'Ongoing',
    Upcoming = 'Upcoming',
    OngoingAndUpcoming = 'OngoingAndUpcoming',
    Custom = 'Custom'
}