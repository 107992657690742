import React, { FunctionComponent, memo } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { SelectInfo } from 'rc-menu/lib/interface';
import { Link } from 'react-router-dom';

import { Actions } from '../icons';
import './action-menu.less';

export interface ActionMenuOption {
    /**
     * Key of the option. Must be unique in the options array.
     */
    key: string;
    title: string;
    action?: () => void;
    link?: string;
    isDivider?: boolean;
}

interface ActionMenuProps {
    options: ActionMenuOption[];
    type: 'primary' | 'ghost';
    trigger: 'click' | 'hover';
}

const ActionMenu: FunctionComponent<ActionMenuProps> = ({ options, type, trigger }) => {
    const onMenuItemClick = ({ key }: SelectInfo): void => {
        const clickedItem = options.find((opt) => opt.key === key);
        clickedItem?.action?.();
    };

    const menu = (
        <Menu onClick={onMenuItemClick} className="ActionMenu">
            {options.map((option) =>
                option.isDivider ? (
                    <Menu.Divider key={option.key} />
                ) : (
                    <Menu.Item key={option.key}>
                        {option.link ? <Link to={option.link}>{option.title}</Link> : option.title}
                    </Menu.Item>
                )
            )}
        </Menu>
    );

    return (
        <Dropdown className="ActionMenu" overlay={menu} trigger={[trigger]} placement="bottomRight">
            <Button type={type} shape="circle" icon={<Actions />} size="large" />
        </Dropdown>
    );
};

export default memo(ActionMenu);
