import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Upload: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 60 60" fill="none" {...props}>
                <path
                    d="M45.9063 38.9346V46.2871H39.642V48.5734H48.1925V41.2208H55.4444V57.444H3.55601V41.2208H10.808V48.5734H19.3539V46.2871H13.0942V38.9346H1V60H58.0005V38.9346H45.9063Z"
                    fill={fill}
                />
                <path
                    d="M12.1061 18.5619L14.6347 20.1166L27.8994 6.26657L27.8994 45H31.0956L31.0956 6.26199L44.3603 20.1166L46.8935 18.562L29.4998 0.395508L12.1061 18.5619Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Upload;
