import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useService, useStores } from 'Hooks';
import { images, theme } from 'variant';
import { Col, Layout, Row } from 'antd';
import { Credit, Delete } from 'Components/icons';
import { SimpleList } from 'Components/simple-list';
import EditCreditPackModal from '../edit-credit-pack-modal';
import './index.less';
import { CreditPackService } from 'Services/CreditPackService';
import { currencyFormatter } from 'Utils/NumberUtils';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { ListSectionHeader } from 'Components/list-section-header';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { CreditPackDto } from 'Api/Features/CreditPacks/Dtos/CreditPackDto';

const { Content } = Layout;
const { usersHeader } = images;

interface SimpleListItemProps {
    title: string;
    description?: string;
    avatar?: React.ReactNode;
}

const CreditPackDetails: FunctionComponent = () => {
    const { t } = useTranslation();
    const { globalLoadingStore, confirmationModalStore, toastStore } = useStores();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<CreditPackDto | undefined>(undefined);
    const [description, setDescription] = useState<SimpleListItemProps[]>([]);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const creditPackService = useService(CreditPackService);
    const history = useHistory();

    const { id } = useParams<{ id: string }>();

    const setSimpleListsData = (creditPack: CreditPackDto): void => {
        const info: SimpleListItemProps[] = [];
        if (creditPack?.description)
            info.push({
                title: creditPack?.description,
            });
        setDescription(info);
    };

    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const data = await creditPackService.getCreditPack(id);
            if (data) {
                setData(data);
                setSimpleListsData(data);
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [creditPackService, globalLoadingStore, id]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const onEditComplete = (success: boolean): void => {
        setEditModalOpen(false);
        if (success) fetch();
    };

    const getBreadcrumb = (): BreadcrumbSegment[] => {
        return [
            {
                nameKey: 'Administration.administration_title',
                path: 'administration',
            },
            {
                nameKey: 'Administration.credit_packs',
                path: 'credit-packs',
            },
            {
                path: data?.id || '',
                name: data?.name || '',
            },
        ];
    };

    const deleteCreditPack = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                width: 850,
                icon: <Delete />,
                title: t(`Administration.credit_pack_delete_confirm_title`),
                message: t(`action_cannot_be_undone`),
                positiveText: t(`model_confirm_positive_delete`, { param1: 'credit pack' }),
                negativeText: t(`model_confirm_negative_delete`, { param1: 'credit pack' }),
            }))
        )
            return;

        await creditPackService.deleteCreditPack(id);
        toastStore.toast({
            type: 'success',
            messageKey: t('model_delete_success', { param1: 'Credit pack' }),
        });
        history.goBack();
    };

    const menuOptions: ActionMenuOption[] = [
        {
            key: 'edit',
            title: t('Administration.edit_credit_pack'),
            action: (): void => setEditModalOpen(true),
        },
        {
            key: 'delete',
            title: t('Administration.delete_credit_pack'),
            action: (): Promise<void> => deleteCreditPack(),
        },
    ];

    return (
        <div className="CreditPackDetails">
            <ListSectionHeader
                title={data?.name ?? ''}
                subTitle={
                    <div className="header-details">
                        <div className="header-item">
                            <div>{t('price')}</div>
                            <div>
                                <strong>{currencyFormatter(data?.price || 0)}</strong>
                            </div>
                        </div>
                        <div className="divider" />
                        <div className="header-item">
                            <div>{t('credits')}</div>
                            <div>
                                <strong>{data?.points}</strong>
                            </div>
                        </div>
                    </div>
                }
                imgSrc={data?.mainImageUrl}
                defaultImg={<Credit fill={theme['primary-color']} />}
                backgroundImageUrl={usersHeader}
                action={<ActionMenu options={menuOptions} type="primary" trigger="click" />}
                routes={getBreadcrumb()}
                loading={loading}
            />

            <Content>
                <Row gutter={40}>
                    <Col span={12}>
                        {description.length > 0 && (
                            <SimpleList
                                className="editor-style"
                                title={t('description')}
                                data={description}
                            />
                        )}
                    </Col>
                </Row>
            </Content>

            {editModalOpen && (
                <EditCreditPackModal
                    onComplete={onEditComplete}
                    visible={editModalOpen}
                    creditPack={data}
                />
            )}
        </div>
    );
};

export default CreditPackDetails;
