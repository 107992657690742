import { SalutationDto } from 'Api/Features/Accounts/Dtos/SalutationDto';
import { AddressDto } from 'Api/Features/General/Dtos/AddressDto';
import { LeadAccountDto } from 'Api/Features/Leads/Dtos/LeadAccountDto';
import { LeadCampusDto } from 'Api/Features/Leads/Dtos/LeadCampusDto';
import { LeadDto } from 'Api/Features/Leads/Dtos/LeadDto';
import { LeadLabelDto } from 'Api/Features/Leads/Dtos/LeadLabelDto';
import { LeadSourceDto } from 'Api/Features/Leads/Dtos/LeadSourceDto';
import { LeadStatusDto } from 'Api/Features/Leads/Dtos/LeadStatusDto';
import { LeadUpcomingActivityDto } from 'Api/Features/Leads/Dtos/LeadUpcomingActivityDto';
import { IndustryDto } from 'Api/Features/Memberships/Dtos/IndustryDto';
import { OpportunityLostReasonDto } from 'Api/Features/Opportunities/Dtos/OpportunityLostReasonDto';

export class Lead implements LeadDto {
    constructor(dto: LeadDto) {
        Object.assign(this, dto);
    }

    id?: string;
    description?: string | null;
    salutation?: SalutationDto | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
    phoneNumber2?: string | null;
    email?: string | null;
    jobPosition?: string | null;
    companyName?: string | null;
    website?: string | null;
    numberOfEmployees?: number | null;
    industry?: IndustryDto | null;
    officeNumber?: string | null;
    campus?: LeadCampusDto | null;
    ownerAccount?: LeadAccountDto | null;
    source?: LeadSourceDto;
    sourceOther?: string | null;
    status?: LeadStatusDto;
    label?: LeadLabelDto | null;
    lostReason?: OpportunityLostReasonDto | null;
    type?: string | null;
    address?: AddressDto | null;
    dateCreated?: string;
    upcomingActivities?: (LeadUpcomingActivityDto | null)[] | null;
}
