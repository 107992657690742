import { GetUnitInventoryReportRequestDto } from 'Api/Features/Reports/Dtos/UnitInventory/GetUnitInventoryReportRequestDto';
import { UnitInventoryReportDto } from 'Api/Features/Reports/Dtos/UnitInventory/UnitInventoryReportDto';
import { UnitInventoryReportProxy } from 'Api/Features/Reports/UnitInventoryReportProxy';
import { inject } from 'aurelia-dependency-injection';
import { arrayBufferToXlsx } from 'Utils/blobs';
import { ApiService } from './ApiService';

@inject(UnitInventoryReportProxy)
export class UnitInventoryReportService extends ApiService {
    constructor(private readonly unitInventoryReportProxy: UnitInventoryReportProxy) {
        super();
    }

    public async getUnitInventoryReport(
        request: GetUnitInventoryReportRequestDto | null
    ): Promise<UnitInventoryReportDto | null> {
        return await this.unitInventoryReportProxy.getUnitInventoryReport(request);
    }

    public async exportUnitInventoryReport(
        request: GetUnitInventoryReportRequestDto | null
    ): Promise<Blob> {
        const document = await this.unitInventoryReportProxy.exportUnitInventoryReport(request);

        return arrayBufferToXlsx(document);
    }
}
