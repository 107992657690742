import { Typography } from 'antd';
import { MessageReceiverTypeDto } from 'Api/Features/Messages/Dtos/MessageReceiverTypeDto';
import ButtonLinkWithIcon from 'Components/button-link-with-icon/button-link-with-icon';
import { Administration as AdministrationIcon } from 'Components/icons';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { theme } from 'variant';
import SendMessageModal from '../../../contacts/id/messages/send-message-modal';

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'management',
        nameKey: 'management',
    },
];

const LocationManagement: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();

    const { locationStore, userPermissionsStore } = useStores();
    const locationData = locationStore.locations.find((location) => location.id === id);

    const [sendMessageModalIsOpen, setSendMessageModalIsOpen] = useState(false)

    return (
        <>
            <LocationHeader
                title={t('management')}
                subTitle={t('Location.management_section_subtitle')}
                defaultImg={<AdministrationIcon fill={theme['primary-color']} />}
                menuOptions={[
                    {
                        key: 'sendMessage',
                        title: t('Contact.contacts_messages_send_label'),
                        action: (): void => setSendMessageModalIsOpen(true),
                    },
                ]}
                routes={breadcrumbs}
            />

            {locationData && (
                <div className="Administration">
                    <div className="button-list-wrapper">
                        <div>
                            <Typography.Title level={2}>
                                {t('Location.management_section_components', {
                                    param1: locationData?.name,
                                })}
                            </Typography.Title>

                            <ButtonLinkWithIcon
                                linkTo="management/floor-plan"
                                iconName="FloorPlanIcon"
                                label={t('floor_plan')}
                            />

                            <ButtonLinkWithIcon
                                linkTo="management/conference-rooms"
                                iconName="ConferenceRoom"
                                label={t('Location.management_section_conference_rooms')}
                            />

                            <ButtonLinkWithIcon
                                linkTo="management/units"
                                iconName="Units"
                                label={t('units')}
                            />

                            <ButtonLinkWithIcon
                                linkTo="management/subscription-plans"
                                iconName="Plan"
                                label={t('Administration.subscription_plans_title')}
                            />

                            <ButtonLinkWithIcon
                                linkTo="management/day-pass-types"
                                iconName="Plan"
                                label={t('DayPass.day_pass_types')}
                            />

                            <ButtonLinkWithIcon
                                linkTo="management/add-ons"
                                iconName="AddOn"
                                label={t('add-ons')}
                            />

                            <ButtonLinkWithIcon
                                linkTo="management/areas-of-interest"
                                iconName="ForkKnife"
                                label={t('AreasOfInterest.areas_of_interest')}
                            />
                        </div>

                        <div>
                            <Typography.Title level={2}>
                                {t('Location.management_section_settings', {
                                    param1: locationData?.name,
                                })}
                            </Typography.Title>

                            {userPermissionsStore.userIsAdministrator && (
                                <ButtonLinkWithIcon
                                    linkTo="management/revenueOccupancyTargets"
                                    iconName="Dollar"
                                    label={t('RevenueOccupancy.revenue_occupancy_targets')}
                                />
                            )}

                            <ButtonLinkWithIcon
                                linkTo="management/pricing"
                                iconName="Dollar"
                                label={t('pricing')}
                            />

                            <ButtonLinkWithIcon
                                linkTo="management/location-details"
                                iconName="Building"
                                label={t('Location.management_section_location_details')}
                            />

                            <ButtonLinkWithIcon
                                linkTo="management/onboarding"
                                iconName="Tick"
                                label={t('Location.management_section_onboarding')}
                            />
                        </div>
                    </div>
                </div>
            )}

            {sendMessageModalIsOpen && (
                <SendMessageModal
                    visible={sendMessageModalIsOpen}
                    onComplete={() => setSendMessageModalIsOpen(false)}
                    messageReceiverType={MessageReceiverTypeDto.Campus}
                    receiverId={id}
                    name={locationStore.locations.find((loc) => loc.id === id)?.name ?? ''}
                />
            )}
        </>
    );
});

export default LocationManagement;
