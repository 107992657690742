import { Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import { GetOccupancyStatsReportRequestDto } from 'Api/Features/Reports/Dtos/OccupancyStats/GetOccupancyStatsReportRequestDto';
import { OccupancyStatsReportDto } from 'Api/Features/Reports/Dtos/OccupancyStats/OccupancyStatsReportDto';
import { OccupancyStatsReportUnitTypeDto } from 'Api/Features/Reports/Dtos/OccupancyStats/OccupancyStatsReportUnitTypeDto';
import Icon from 'Components/icons/icon';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import TableActionsButtons from 'Components/table-action-buttons/table-actions-buttons';
import { TableFilters } from 'Components/table-filters';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { ALL_LOCATIONS, MOMENT_PARSING_FORMAT } from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OccupancyStatsReportService } from 'Services/OccupancyStatsReportService';
import { FilterStore } from 'Stores';
import { showFile } from 'Utils';
import { currencyFormatter } from 'Utils/NumberUtils';
import { ListSectionHeader } from '../../../../Components/list-section-header';
import { images, theme } from '../../../../variant';
import './index.less';

const { usersHeader } = images;

const breadcrumbs: BreadcrumbSegment[] = [
    {
        nameKey: 'reports',
        path: 'reports',
    },
    {
        nameKey: 'Reports.occupancy_stats',
        path: 'occupancy-stats',
    },
];

const advancedFilters: AdvancedFilter[] = [];

const OccupancyStats: FunctionComponent = observer(() => {
    const occupancyStatsReportService = useService(OccupancyStatsReportService);
    const { locationStore, globalLoadingStore, toastStore, userPermissionsStore, requestStore } = useStores();
    const [loading, setLoading] = useState(false);
    const [unitTypesList, setUnitTypesList] = useState<
        OccupancyStatsReportUnitTypeDto[] | undefined
    >(undefined);
    const [unitTypesTotal, setUnitTypesTotal] = useState<OccupancyStatsReportDto | undefined>(
        undefined
    );
    const [exportOccupancyStatsReportRequest, setExportOccupancyStatsReportRequest] = useState<
        GetOccupancyStatsReportRequestDto | undefined
    >(undefined);
    const [isTableEmpty, setIsTableEmpty] = useState<boolean>(true);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { t } = useTranslation();

    const onExportClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            let document;
            if (exportOccupancyStatsReportRequest) {
                const campusName =
                    locationStore?.locations.find(
                        (x) => x.id === exportOccupancyStatsReportRequest?.campusIds?.[0]
                    )?.name || ALL_LOCATIONS;

                document = await occupancyStatsReportService.exportOccupancyStatsReport(
                    exportOccupancyStatsReportRequest
                );

                showFile(
                    document,
                    `${t('Reports.occupancy_stats')} - ${moment(
                        exportOccupancyStatsReportRequest.date
                    ).format(MOMENT_PARSING_FORMAT)} - ${campusName}`
                );
            }
        } catch (e) {
            if (!e.treated) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const tableActionButtons = [
        {
            iconName: 'Download',
            toolTip: 'export_table',
            onClick: (): Promise<void> => onExportClick(),
            disabled: isTableEmpty,
        },
    ];

    const renderOccupiedDesk = (record: OccupancyStatsReportUnitTypeDto) =>
        record.numberOfOccupiedDesksTotal &&
        record.numberOfDesksTotal &&
        `${record.numberOfOccupiedDesksTotal}
        (${Math.floor((record.numberOfOccupiedDesksTotal / record.numberOfDesksTotal) * 100)}%)`;

    const columns: ColumnType<OccupancyStatsReportUnitTypeDto>[] = [
        {
            title: t('Unit.unit_type'),
            dataIndex: 'type',
        },
        {
            title: t('Reports.occupied_desk'),
            render: (record) => renderOccupiedDesk(record),
            align: 'right',
        },
        {
            title: t('Reports.number_desk'),
            dataIndex: 'numberOfDesksTotal',
            align: 'right',
        },
        {
            title: t('Reports.expected_revenue'),
            dataIndex: 'expectedRevenueTotal',
            render: (expectedRevenueTotal) => currencyFormatter(expectedRevenueTotal),
            align: 'right',
        },
        {
            title: t('Reports.actual_revenue'),
            dataIndex: 'actualRevenueTotal',
            render: (actualRevenueTotal) => currencyFormatter(actualRevenueTotal),
            align: 'right',
        },
    ];

    const renderTableFooter = () => (
        <>
            <div>{t('total')}</div>
            <div>{unitTypesTotal?.numberOfOccupiedDesksTotal}</div>
            <div>{unitTypesTotal?.numberOfDesksTotal}</div>
            <div>{currencyFormatter(unitTypesTotal?.expectedRevenueTotal || '')}</div>
            <div>{currencyFormatter(unitTypesTotal?.actualRevenueTotal || '')}</div>
        </>
    );

    const fetch = useCallback(
        async (params: { date?: string; campusIds: string[] | null }) => {
            setLoading(true);

            try {
                const request = {
                    date: params.date,
                    campusIds: params.campusIds,
                };
                const response = await occupancyStatsReportService.getOccupancyStatsReport(request);
                const nonNullUnitTypes = response?.unitTypes
                    ?.filter((unitType) => unitType !== null)
                    .map((unitType) => unitType!);
                const nonNullUnitTypesTotal = {
                    actualRevenueTotal: response?.actualRevenueTotal || 0,
                    expectedRevenueTotal: response?.expectedRevenueTotal || 0,
                    numberOfDesksTotal: response?.numberOfDesksTotal || 0,
                    numberOfOccupiedDesksTotal: response?.numberOfOccupiedDesksTotal || 0,
                };

                setIsTableEmpty(response?.unitTypes?.length === 0);
                setUnitTypesList(nonNullUnitTypes);
                setUnitTypesTotal(nonNullUnitTypesTotal);
            } finally {
                setLoading(false);
            }
        },
        [occupancyStatsReportService]
    );

    useEffect(() => {
        const filterStore = filterStoreRef.current;
        filterStore.date = moment().format(MOMENT_PARSING_FORMAT);
    }, []);

    useEffect(() => {
        const filterStore = filterStoreRef.current;

        const disposer = autorun(() => {
            const campusId =
                filterStore.currentLocationId === 'all' ? requestStore.getReportRequestAvailableLocationIdsForUser() : [filterStore.currentLocationId];

            fetch({
                date: filterStore.date,
                campusIds: campusId,
            });

            setExportOccupancyStatsReportRequest({
                date: filterStore.date,
                campusIds: campusId,
            });
        });

        return (): void => {
            disposer();
        };
    }, [fetch]);

    return (
        <div className="OccupancyStats">
            <ListSectionHeader
                routes={breadcrumbs}
                backgroundImageUrl={usersHeader}
                defaultImg={<Icon iconName="PieChart" fill={theme['primary-color']} />}
                title={t('Reports.occupancy_stats')}
                subTitle={t('Reports.occupancy_stats_overview')}
            />

            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeDate
                    includeLocations
                    availableLocations={userPermissionsStore.availableLocationsForUser}
                />

                <TableActionsButtons buttonsList={tableActionButtons} />

                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    dataSource={unitTypesList}
                    rowKey={(record): string => `${record.type}`}
                    loading={loading}
                    pagination={false}
                    footer={renderTableFooter}
                />
            </Content>
        </div>
    );
});

export default OccupancyStats;
