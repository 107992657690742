import { Button, Col, Form, Input, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';
import { UpdateOpportunityRequestDto } from 'Api/Features/Opportunities/Dtos/UpdateOpportunityRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Plan } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useService, useStores } from 'Hooks';
import { Opportunity } from 'Models/Opportunities/Opportunity';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { OpportunityService } from 'Services/OpportunityService';
import './reopen-opportunity-modal.less';

interface ReopenOpportunityModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    opportunity?: Opportunity;
}

const formGutter: [Gutter, Gutter] = [40, 0];

const ReopenOpportunityModal: FunctionComponent<ReopenOpportunityModalProps> = ({
    visible,
    onComplete,
    opportunity,
}) => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const opportunityService = useService(OpportunityService);
    const [form] = Form.useForm();

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Plan />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t('Opportunity.reopen_opportunity_confirm_positive'),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const request: UpdateOpportunityRequestDto = { 
            ...opportunity,
            ownerAccountId: opportunity?.ownerAccount?.id,
            contactId: opportunity?.contact?.id,
            campusId: opportunity?.campus?.id,
            status: OpportunityStatusDto.New,
            note: formValues.note
        }

        try {
            globalLoadingStore.addLoading();

            if (opportunity !== undefined) await opportunityService.updateOpportunity(opportunity.id!, request);

            toastStore.toast({
                type: 'success',
                messageKey: `Opportunity.reopen_opportunity_success`,
            });
            dismiss(true);
        } catch (e) {
            if (e.response?.data?.error === 'E045013') {
                toastStore.toast({
                    type: 'error',
                    messageKey: 'Errors.E045013'
                })
            }
            else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion
    return (
        <BaseModal
            visible={visible}
            title={t('Opportunity.reopen_opportunity')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="ReopenOpportunityModal">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name="note"
                                label={t('Lead.lead_add_a_note')}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
};

export default ReopenOpportunityModal;
