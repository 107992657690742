import { UnitActiveStatusDto } from 'Api/Features/Units/Dtos/UnitActiveStatusDto';
import { UnitDto } from 'Api/Features/Units/Dtos/UnitDto';
import { UnitTypeDto } from 'Api/Features/Units/Dtos/UnitTypeDto';
import { UnitCampusDto } from 'Api/Features/Units/Dtos/UnitCampusDto';

export class Unit implements UnitDto {
    constructor(dto: UnitDto) {
        Object.assign(this, dto);
    }

    id?: string | null;
    campus?: UnitCampusDto | null;
    name?: string | null;
    type?: UnitTypeDto;
    activeStartDate?: string | null;
    activeEndDate?: string | null;
    note?: string | null;
    priceMultiplier?: number;
    priceOverride?: number;
    sixMonthPrice?: number;
    twelveMonthPrice?: number;
    marketPrice?: number;
    dataJacks?: (string | null)[] | null;
    squareFeet?: number | null;
    numberOfDesks?: number | null;
    capacity?: number | null;
    status?: UnitActiveStatusDto;
    isAvailable?: boolean | null;
    order?: number;
}
