import { LeadSourceDto } from 'Api/Features/Leads/Dtos/LeadSourceDto';
import { PHONE_REGEX, WEBSITE_REGEX } from 'Models/Constants';
import * as yup from 'yup';

// See: https://github.com/jquense/yup/issues/58
yup.addMethod(yup.mixed, 'optional', function optional() {
    return this.transform((value) => {
        return (typeof value == 'string' && !value) ||
            (value instanceof Array && !value.length) ||
            value === null // allow to skip "nullable"
            ? undefined
            : value;
    });
});

const Opportunity = {
    name: yup.string().max(50, 'Errors.must_be_under_fifty').required('Errors.required'),
    ownerAccountId: yup.string().required('Errors.required'),
    probability: yup.number().typeError('Errors.number_invalid'),
    closeDate: yup.date().required('Errors.required'),
    amount: yup.number().typeError('Errors.number_invalid'),
    deskCount: yup.number().required('Errors.required').typeError('Errors.number_invalid'),
    product: yup.string().required('Errors.required'),
    salesChannel: yup.string().required('Errors.required'),
    source: yup.string().required('Errors.required'),
    sourceOther: yup.string().when('source', {
        is: LeadSourceDto.Other,
        then: yup.string().required('Errors.required'),
    }),
};

const isNotEmpty = (val: any) => val !== undefined && val !== '';
const OpportunityCompany = {
    existingCompany: yup.boolean(),
    membershipId: yup.string().when('existingCompany', {
        is: true,
        then: yup.string().required('Errors.required'),
    }),
    membership: yup.object().when('existingCompany', {
        is: false,
        then: yup.object({
            name: yup.string().required('Errors.required'),
            billingEmail: yup.string().email('Errors.email_invalid'),
            contactInfo: yup.object({
                email: yup.string().email('Errors.email_invalid'),
                phone1: yup
                    .string()
                    .optional()
                    .max(25, 'Errors.must_be_under_twenty_five')
                    .matches(PHONE_REGEX, 'Errors.phone_invalid'),
                website: yup
                    .string()
                    .optional()
                    .max(25, 'Errors.must_be_under_twenty_five')
                    .matches(WEBSITE_REGEX, 'Errors.link_invalid'),
                angelListUrl: yup.string().optional().matches(WEBSITE_REGEX, 'Errors.link_invalid'),
                instagramUrl: yup.string().optional().matches(WEBSITE_REGEX, 'Errors.link_invalid'),
                linkedInUrl: yup.string().optional().matches(WEBSITE_REGEX, 'Errors.link_invalid'),
                twitterUrl: yup.string().optional().matches(WEBSITE_REGEX, 'Errors.link_invalid'),
                facebookUrl: yup.string().optional().matches(WEBSITE_REGEX, 'Errors.link_invalid'),
            }),
            numberOfEmployees: yup.number(),

            legalContact: yup.object().shape(
                {
                    firstName: yup
                        .string()
                        .optional()
                        .nullable()
                        .when(
                            [
                                'lastName',
                                'phone',
                                'email',
                                'birthDate',
                                'addressLine1',
                                'city',
                                'state',
                                'country',
                                'postalCode',
                            ],
                            {
                                is: (
                                    lastName,
                                    phone,
                                    email,
                                    birthDate,
                                    addressLine1,
                                    city,
                                    state,
                                    country,
                                    postalCode
                                ) =>
                                    isNotEmpty(lastName) ||
                                    isNotEmpty(phone) ||
                                    isNotEmpty(email) ||
                                    isNotEmpty(birthDate) ||
                                    isNotEmpty(addressLine1) ||
                                    isNotEmpty(city) ||
                                    isNotEmpty(state) ||
                                    isNotEmpty(country) ||
                                    isNotEmpty(postalCode),
                                then: yup.string().required('Errors.required'),
                            }
                        ),
                    lastName: yup
                        .string()
                        .optional()
                        .nullable()
                        .when(
                            [
                                'firstName',
                                'phone',
                                'email',
                                'birthDate',
                                'addressLine1',
                                'city',
                                'state',
                                'country',
                                'postalCode',
                            ],
                            {
                                is: (
                                    firstName,
                                    phone,
                                    email,
                                    birthDate,
                                    addressLine1,
                                    city,
                                    state,
                                    country,
                                    postalCode
                                ) =>
                                    isNotEmpty(firstName) ||
                                    isNotEmpty(phone) ||
                                    isNotEmpty(email) ||
                                    isNotEmpty(birthDate) ||
                                    isNotEmpty(addressLine1) ||
                                    isNotEmpty(city) ||
                                    isNotEmpty(state) ||
                                    isNotEmpty(country) ||
                                    isNotEmpty(postalCode),
                                then: yup.string().required('Errors.required'),
                            }
                        ),
                    phone: yup
                        .string()
                        .optional()
                        .nullable()
                        .max(25, 'Errors.must_be_under_twenty_five')
                        .matches(PHONE_REGEX, 'Errors.phone_invalid')
                        .when(
                            [
                                'firstName',
                                'lastName',
                                'email',
                                'birthDate',
                                'addressLine1',
                                'city',
                                'state',
                                'country',
                                'postalCode',
                            ],
                            {
                                is: (
                                    firstName,
                                    lastName,
                                    email,
                                    birthDate,
                                    addressLine1,
                                    city,
                                    state,
                                    country,
                                    postalCode
                                ) =>
                                    isNotEmpty(firstName) ||
                                    isNotEmpty(lastName) ||
                                    isNotEmpty(email) ||
                                    isNotEmpty(birthDate) ||
                                    isNotEmpty(addressLine1) ||
                                    isNotEmpty(city) ||
                                    isNotEmpty(state) ||
                                    isNotEmpty(country) ||
                                    isNotEmpty(postalCode),
                                then: yup.string().required('Errors.required'),
                            }
                        ),
                    email: yup
                        .string()
                        .email('Errors.email_invalid')
                        .optional()
                        .nullable()
                        .when(
                            [
                                'firstName',
                                'lastName',
                                'phone',
                                'birthDate',
                                'addressLine1',
                                'city',
                                'state',
                                'country',
                                'postalCode',
                            ],
                            {
                                is: (
                                    firstName,
                                    lastName,
                                    phone,
                                    birthDate,
                                    addressLine1,
                                    city,
                                    state,
                                    country,
                                    postalCode
                                ) =>
                                    isNotEmpty(firstName) ||
                                    isNotEmpty(lastName) ||
                                    isNotEmpty(phone) ||
                                    isNotEmpty(birthDate) ||
                                    isNotEmpty(addressLine1) ||
                                    isNotEmpty(city) ||
                                    isNotEmpty(state) ||
                                    isNotEmpty(country) ||
                                    isNotEmpty(postalCode),
                                then: yup.string().required('Errors.required'),
                            }
                        ),
                    birthDate: yup
                        .date()
                        .optional()
                        .nullable()
                        .when(
                            [
                                'firstName',
                                'lastName',
                                'phone',
                                'email',
                                'addressLine1',
                                'city',
                                'state',
                                'country',
                                'postalCode',
                            ],
                            {
                                is: (
                                    firstName,
                                    lastName,
                                    phone,
                                    email,
                                    addressLine1,
                                    city,
                                    state,
                                    country,
                                    postalCode
                                ) =>
                                    isNotEmpty(firstName) ||
                                    isNotEmpty(lastName) ||
                                    isNotEmpty(phone) ||
                                    isNotEmpty(email) ||
                                    isNotEmpty(addressLine1) ||
                                    isNotEmpty(city) ||
                                    isNotEmpty(state) ||
                                    isNotEmpty(country) ||
                                    isNotEmpty(postalCode),
                                then: yup.date().required('Errors.required'),
                            }
                        ),
                    addressLine1: yup
                        .string()
                        .optional()
                        .nullable()
                        .when(
                            [
                                'firstName',
                                'lastName',
                                'phone',
                                'email',
                                'birthDate',
                                'city',
                                'state',
                                'country',
                                'postalCode',
                            ],
                            {
                                is: (
                                    firstName,
                                    lastName,
                                    phone,
                                    email,
                                    birthDate,
                                    city,
                                    state,
                                    country,
                                    postalCode
                                ) =>
                                    isNotEmpty(firstName) ||
                                    isNotEmpty(lastName) ||
                                    isNotEmpty(phone) ||
                                    isNotEmpty(email) ||
                                    isNotEmpty(birthDate) ||
                                    isNotEmpty(city) ||
                                    isNotEmpty(state) ||
                                    isNotEmpty(country) ||
                                    isNotEmpty(postalCode),
                                then: yup.string().required('Errors.required'),
                            }
                        ),
                    city: yup
                        .string()
                        .optional()
                        .nullable()
                        .when(
                            [
                                'firstName',
                                'lastName',
                                'phone',
                                'email',
                                'birthDate',
                                'addressLine1',
                                'state',
                                'country',
                                'postalCode',
                            ],
                            {
                                is: (
                                    firstName,
                                    lastName,
                                    phone,
                                    email,
                                    birthDate,
                                    addressLine1,
                                    state,
                                    country,
                                    postalCode
                                ) =>
                                    isNotEmpty(firstName) ||
                                    isNotEmpty(lastName) ||
                                    isNotEmpty(phone) ||
                                    isNotEmpty(email) ||
                                    isNotEmpty(birthDate) ||
                                    isNotEmpty(addressLine1) ||
                                    isNotEmpty(state) ||
                                    isNotEmpty(country) ||
                                    isNotEmpty(postalCode),
                                then: yup.string().required('Errors.required'),
                            }
                        ),
                    state: yup
                        .string()
                        .optional()
                        .nullable()
                        .when(
                            [
                                'firstName',
                                'lastName',
                                'phone',
                                'email',
                                'birthDate',
                                'addressLine1',
                                'city',
                                'country',
                                'postalCode',
                            ],
                            {
                                is: (
                                    firstName,
                                    lastName,
                                    phone,
                                    email,
                                    birthDate,
                                    addressLine1,
                                    city,
                                    country,
                                    postalCode
                                ) =>
                                    isNotEmpty(firstName) ||
                                    isNotEmpty(lastName) ||
                                    isNotEmpty(phone) ||
                                    isNotEmpty(email) ||
                                    isNotEmpty(birthDate) ||
                                    isNotEmpty(addressLine1) ||
                                    isNotEmpty(city) ||
                                    isNotEmpty(country) ||
                                    isNotEmpty(postalCode),
                                then: yup.string().required('Errors.required'),
                            }
                        ),
                    country: yup
                        .string()
                        .optional()
                        .nullable()
                        .when(
                            [
                                'firstName',
                                'lastName',
                                'phone',
                                'email',
                                'birthDate',
                                'addressLine1',
                                'city',
                                'state',
                                'postalCode',
                            ],
                            {
                                is: (
                                    firstName,
                                    lastName,
                                    phone,
                                    email,
                                    birthDate,
                                    addressLine1,
                                    city,
                                    state,
                                    postalCode
                                ) =>
                                    isNotEmpty(firstName) ||
                                    isNotEmpty(lastName) ||
                                    isNotEmpty(phone) ||
                                    isNotEmpty(email) ||
                                    isNotEmpty(birthDate) ||
                                    isNotEmpty(addressLine1) ||
                                    isNotEmpty(city) ||
                                    isNotEmpty(state) ||
                                    isNotEmpty(postalCode),
                                then: yup.string().required('Errors.required'),
                            }
                        ),
                    postalCode: yup
                        .string()
                        .optional()
                        .nullable()
                        .when(
                            [
                                'firstName',
                                'lastName',
                                'phone',
                                'email',
                                'birthDate',
                                'addressLine1',
                                'city',
                                'state',
                                'country',
                            ],
                            {
                                is: (
                                    firstName,
                                    lastName,
                                    phone,
                                    email,
                                    birthDate,
                                    addressLine1,
                                    city,
                                    state,
                                    country
                                ) =>
                                    isNotEmpty(firstName) ||
                                    isNotEmpty(lastName) ||
                                    isNotEmpty(phone) ||
                                    isNotEmpty(email) ||
                                    isNotEmpty(birthDate) ||
                                    isNotEmpty(addressLine1) ||
                                    isNotEmpty(city) ||
                                    isNotEmpty(state) ||
                                    isNotEmpty(country),
                                then: yup.string().required('Errors.required'),
                            }
                        ),
                },
                [
                    ['firstName', 'lastName'],
                    ['firstName', 'phone'],
                    ['firstName', 'email'],
                    ['firstName', 'birthDate'],
                    ['firstName', 'addressLine1'],
                    ['firstName', 'city'],
                    ['firstName', 'state'],
                    ['firstName', 'country'],
                    ['firstName', 'postalCode'],
                    ['lastName', 'phone'],
                    ['lastName', 'email'],
                    ['lastName', 'birthDate'],
                    ['lastName', 'addressLine1'],
                    ['lastName', 'city'],
                    ['lastName', 'state'],
                    ['lastName', 'country'],
                    ['lastName', 'postalCode'],
                    ['phone', 'email'],
                    ['phone', 'birthDate'],
                    ['phone', 'addressLine1'],
                    ['phone', 'city'],
                    ['phone', 'state'],
                    ['phone', 'country'],
                    ['phone', 'postalCode'],
                    ['email', 'birthDate'],
                    ['email', 'addressLine1'],
                    ['email', 'city'],
                    ['email', 'state'],
                    ['email', 'country'],
                    ['email', 'postalCode'],
                    ['birthDate', 'addressLine1'],
                    ['birthDate', 'city'],
                    ['birthDate', 'state'],
                    ['birthDate', 'country'],
                    ['birthDate', 'postalCode'],
                    ['addressLine1', 'city'],
                    ['addressLine1', 'state'],
                    ['addressLine1', 'country'],
                    ['addressLine1', 'postalCode'],
                    ['city', 'state'],
                    ['city', 'country'],
                    ['city', 'postalCode'],
                    ['state', 'country'],
                    ['state', 'postalCode'],
                    ['country', 'postalCode'],
                ] // this resolves cyclic dependencies
            ),
        }),
    }),
};

const OpportunityContact = {
    existingContact: yup.boolean(),
    contactId: yup.string().when('existingContact', {
        is: true,
        then: yup.string().required('Errors.required'),
    }),
    contact: yup.object().when('existingContact', {
        is: false,
        then: yup.object({
            firstName: yup.string().required('Errors.required'),
            lastName: yup.string().required('Errors.required'),
            contactInfo: yup.object({
                email: yup.string().email('Errors.email_invalid').required('Errors.required'),
                angelListUrl: yup.string().optional().matches(WEBSITE_REGEX, 'Errors.link_invalid'),
                instagramUrl: yup.string().optional().matches(WEBSITE_REGEX, 'Errors.link_invalid'),
                linkedInUrl: yup.string().optional().matches(WEBSITE_REGEX, 'Errors.link_invalid'),
                twitterUrl: yup.string().optional().matches(WEBSITE_REGEX, 'Errors.link_invalid'),
                facebookUrl: yup.string().optional().matches(WEBSITE_REGEX, 'Errors.link_invalid'),
            }),
        }),
    }),
};

export const CreateOpportunitySchema = yup.object(
    Object.assign({}, Opportunity, OpportunityCompany, OpportunityContact)
);

const CreateOpportunityNoCompanyWithMonthlyRevenueSchema = {
    monthlyRevenue: yup.string().required('Errors.required'),
};

export const CreateOpportunityNoCompanySchema = yup.object(
    Object.assign(
        {},
        Opportunity,
        CreateOpportunityNoCompanyWithMonthlyRevenueSchema,
        OpportunityContact
    )
);

export const EditOpportunityNewAndTourSchema = yup.object(
    Object.assign({
        name: yup.string().required('Errors.required'),
        ownerAccountId: yup.string().required('Errors.required'),
        probability: yup.number().typeError('Errors.number_invalid'),
        closeDate: yup.date().required('Errors.required'),
        amount: yup.number().typeError('Errors.number_invalid'),
        deskCount: yup.number().required('Errors.required').typeError('Errors.number_invalid'),
        product: yup.string().required('Errors.required'),
        salesChannel: yup.string().required('Errors.required'),
        source: yup.string().required('Errors.required'),
        contactId: yup.string().required('Errors.required'),
    })
);

export const EditOpportunitySchema = yup.object(
    Object.assign(Opportunity, {
        contactId: yup.string().required('Errors.required'),
        campusId: yup.string().required('Errors.required'),
        monthlyRevenue: yup.string().required('Errors.required'),
        termLength: yup.string().required('Errors.required'),
    })
);

export const getSchema = (isNewOrTourScheduled: boolean): any =>
    isNewOrTourScheduled ? EditOpportunityNewAndTourSchema : EditOpportunitySchema;

export const OpportunityCompanySchema = yup.object(Object.assign(OpportunityCompany));

export const OpportunityContactSchema = yup.object(Object.assign(OpportunityContact));

export const OpportunityMoveToQualifiedSchema = yup.object({
    ownerAccountId: yup.string().required('Errors.required'),
    monthlyRevenue: yup.number().required('Errors.required'),
    termLength: yup.string().required('Errors.required'),
    campusId: yup.string().required('Errors.required'),
    closeDate: yup.date().required('Errors.required'),
    salesChannel: yup.string().required('Errors.required'),
    product: yup.string().required('Errors.required'),
    deskCount: yup.number().required('Errors.required').typeError('Errors.number_invalid'),
});
