import { Button, Col, Form, Radio, Row, Space } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { SubscriptionDetailsDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsDto';
import { UpdateSubscriptionPaymentSettingsRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionPaymentSettingsRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { AdministrationNut, Subscription} from 'Components/icons';
import { useService, useStores } from 'Hooks';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionService } from 'Services/SubscriptionService';
import './edit-payment-setting-modal.less';

const formGutter: [Gutter, Gutter] = [40, 40];

interface EditPaymentSettingModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    subscription?: SubscriptionDetailsDto;
}

enum PaymentSetting {
    Check,
    PreAuthorized,
    Manual
}

const EditPaymentSettingModal: FunctionComponent<EditPaymentSettingModalProps> = ({
    visible,
    onComplete,
    subscription,
}) => {
    const subscriptionService = useService(SubscriptionService);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const [paymentSettingState, setPaymentSettingState] = useState<PaymentSetting>()
    const { t } = useTranslation();

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <AdministrationNut />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`confirm_positive`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            if (subscription?.id) {
                const request: UpdateSubscriptionPaymentSettingsRequestDto = {
                    isChargedAutomatically: paymentSettingState === PaymentSetting.PreAuthorized,
                    isCheckPayable: paymentSettingState == PaymentSetting.Check,
                };
                await subscriptionService.updateSubscriptionPaymentSettings(
                    subscription.id,
                    request
                );
                toastStore.toast({
                    type: 'success',
                    message: t('model_edit_success', { param1: 'Payment setting' }),
                });

                dismiss(true);
            }
        } catch (e) {
            toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    useEffect(() => {
        if (subscription) {
            const setting = subscription.isChargedAutomatically
                ? PaymentSetting.PreAuthorized
                : subscription.isCheckPayable
                ? PaymentSetting.Check
                : PaymentSetting.Manual;
            setPaymentSettingState(setting);
        }
    }, [subscription])
    
    return (
        <BaseModal
            visible={visible}
            title={t('Subscription.edit_payment_setting')}
            className="EditPaymentSettingModal FormModal"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Row gutter={formGutter}>
                    <Col span={24}>
                        <span className="explanation">
                            {t('Subscription.edit_payment_setting_explanation')}
                        </span>
                    </Col>
                </Row>

                <Row gutter={formGutter}>
                    <Col span={24}>
                        <Radio.Group name="paymentMethod" value={paymentSettingState} onChange={(event) => setPaymentSettingState(event.target.value)}>
                            <Space direction="vertical">
                                <Radio value={PaymentSetting.Check}><span className="radio-value">{t('Subscription.payment_setting_paid_by_check')}</span></Radio>
                                <Radio value={PaymentSetting.PreAuthorized}><span className="radio-value">{t('Proposal.payment_pre_authorized')}</span></Radio>
                                <Radio value={PaymentSetting.Manual}><span className="radio-value">{t('Proposal.payment_manual')}</span></Radio>
                            </Space>
                        </Radio.Group>
                    </Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" className="positive" htmlType="submit">
                        {t('save')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(EditPaymentSettingModal);
