import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { ManagerRoleFilter } from '../../../Models/Filters/ManagerRoleFilter';
import { ALL_ROLES } from 'Models/Constants';
import { ManagementRoleDto } from 'Api/Features/ManagerUsers/Dtos/ManagementRoleDto';

const { Option } = Select;

interface ManagerRoleSelectProps {
    onRoleChange: (roleId: string) => void;
    value?: string | undefined;
}

const ManagerRoleSelect: FunctionComponent<ManagerRoleSelectProps> = ({
    onRoleChange: OnRoleManagerChange,
    value,
}) => {
    const { t } = useTranslation();
    const filters: ManagerRoleFilter[] = [
        {
            id: ALL_ROLES,
            imageUrl: '',
            name: 'All roles',
        },
        {
            id: ManagementRoleDto.Administrator,
            imageUrl: '',
            name: t('ManagerUser.management_role_Administrator'),
        },
        {
            id: ManagementRoleDto.Manager,
            imageUrl: '',
            name: t('ManagerUser.management_role_Manager'),
        },
    ];
    return (
        <Select
            defaultValue={ALL_ROLES}
            onChange={OnRoleManagerChange}
            style={{ width: '100%' }}
            value={value}
        >
            {filters.map((filter) => (
                <Option key={filter.id} value={filter.id}>
                    {filter.name}
                </Option>
            ))}
        </Select>
    );
};

export default ManagerRoleSelect;
