import React, { CSSProperties, FunctionComponent } from 'react';
import { FloorPlanUnitObject } from './floor-plan';

interface FloorPlanUnitTooltipProps {
    unit: FloorPlanUnitObject;
    isSelected: boolean;
    onUnitInformationClick?: any;
}

const FloorPlanUnitTooltip: FunctionComponent<FloorPlanUnitTooltipProps> = ({
    unit,
    isSelected,
    onUnitInformationClick,
}) => {
    const styles: CSSProperties = isSelected
        ? {
              stroke: 'none',
              visibility: 'visible',
              pointerEvents: 'auto',
          }
        : {
              stroke: 'none',
              visibility: 'hidden',
              pointerEvents: 'none',
          };

    const handleMouseUp = (e: any): void => {
        e.stopPropagation();
    }

    return (
        <>
            <svg
                width="165"
                height="61"
                viewBox="0 0 165 61"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                x="calc(50% - 82px)"
                y="-61"
                style={styles}
                onMouseUp={handleMouseUp}
            >
                <g filter="url(#filter0_c)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6 10C6 7.79086 7.79086 6 10 6H155C157.209 6 159 7.79086 159 10V40.8928C159 43.102 157.209 44.8928 155 44.8928H91.68L82.9371 55L75.1178 44.8928H10C7.79087 44.8928 6 43.102 6 40.8928V10Z"
                        fill="white"
                    />
                    <path
                        d="M91.68 44.3928H91.4514L91.3018 44.5657L82.9587 54.2108L75.5132 44.5869L75.3631 44.3928H75.1178H10C8.06701 44.3928 6.5 42.8258 6.5 40.8928V10C6.5 8.067 8.067 6.5 10 6.5H155C156.933 6.5 158.5 8.067 158.5 10V40.8928C158.5 42.8258 156.933 44.3928 155 44.3928H91.68Z"
                        stroke="#EDEDED"
                    />
                    <foreignObject style={{ width: '100%', height: '100%' }}>
                        <div className="tooltip-content" style={{ height: '45px' }}>
                            <p
                                title={unit.name}
                            >
                                {unit.name}
                            </p>
                            <div
                                onClick={(): void => onUnitInformationClick(unit)}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    </foreignObject>
                </g>
                <defs>
                    <filter
                        id="filter0_c"
                        x="0"
                        y="0"
                        width="165"
                        height="61"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="3" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow"
                            result="shape"
                        />
                    </filter>
                </defs>
            </svg>
        </>
    );
}

export default FloorPlanUnitTooltip;