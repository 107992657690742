import * as yup from 'yup';

export const CreateProposalSchema = yup.object({
    membershipId: yup.string().required('Errors.required'),
    campusId: yup.string().required('Errors.required'),
    startDate: yup.date().required('Errors.required'),
    isMtm: yup.bool(),
    endDate: yup.date().when('isMtm', {
        is: false,
        then: yup.date().required('Errors.required')
    }),
    planId: yup.string().required('Errors.required'),
    unitIds: yup.array().required('Errors.required'),
    creditPointsPerInterval: yup.number().required('Errors.required'),
    securityDeposit: yup.number().required('Errors.required')
    //all dynamic form items are validated with the antd submiting process
});