import React, { FunctionComponent } from 'react';
import { Typography, Collapse, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { PanelHeader } from './';

import './advanced-filters.less';

const { Title } = Typography;
const { Panel } = Collapse;

interface AdvancedFiltersProps {
    filters: AdvancedFilter[];
    onItemChange: (key: string, parentKey: string) => void;
    onToggleSection: (key: string) => void;
}

const AdvancedFilters: FunctionComponent<AdvancedFiltersProps> = observer(
    ({ filters, onItemChange, onToggleSection }) => {
        const { t } = useTranslation();

        const genExtra = (key: string) => (
            <span
                onClick={(e) => {
                    onToggleSection(key);
                    e.stopPropagation();
                }}
            >
                {filters.find((x) => x.key === key)?.items.some((x) => x.checked)
                    ? t('unselect_all')
                    : t('select_all')}
            </span>
        );

        return (
            <>
                <div>
                    <Title level={3}>Advanced Filters</Title>
                </div>

                <Collapse
                    expandIconPosition="right"
                    defaultActiveKey={filters.map((filter) => filter.key)}
                    className="AdvancedFilters"
                >
                    {filters.map((filter) => (
                        <Panel
                            key={filter.key}
                            header={<PanelHeader filter={filter} />}
                            extra={genExtra(filter.key)}
                        >
                            <div className="filter-container">
                                {filter.items.map((filterItem) => (
                                    <Checkbox
                                        key={filterItem.key}
                                        checked={filterItem.checked}
                                        onChange={(): void =>
                                            onItemChange(filterItem.key, filter.key)
                                        }
                                    >
                                        {t(filterItem.displayNameKey)}
                                    </Checkbox>
                                ))}
                            </div>
                        </Panel>
                    ))}
                </Collapse>
            </>
        );
    }
);

export default AdvancedFilters;
