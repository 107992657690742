import { Content } from 'antd/lib/layout/layout';
import { InvoiceDto } from 'Api/Features/Invoices/Dtos/InvoiceDto';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { InvoiceData } from 'Components/contacts-header';
import Icon from 'Components/icons/icon';
import PdfViewer from 'Components/pdf-viewer';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { InvoiceService } from 'Services/InvoiceService';
import { cleanVal } from 'Utils/NumberUtils';
import { InvoiceStatusDto } from 'Api/Features/Invoices/Dtos/InvoiceStatusDto';
import CompanyHeader from 'Components/company-header';
import { CompanyContext } from '../../..';
import { showFile } from 'Utils';
import './index.less';
import { ManagementRoleDto } from 'Api/Features/ManagerUsers/Dtos/ManagementRoleDto';

const CompanyInvoiceDetails: FunctionComponent = () => {
    const invoiceService = useService(InvoiceService);
    const [loading, setLoading] = useState(false);
    const [pdf, setPdf] = useState<Blob>();
    const [invoice, setInvoice] = useState<InvoiceDto | null>();
    const [invoiceHeaderData, setInvoiceHeaderData] = useState<InvoiceData>();
    const { toastStore, globalLoadingStore, confirmationModalStore, userPermissionsStore } =
        useStores();
    const companyContext = useContext(CompanyContext);
    const { id, invoiceId } = useParams<{ id: string; invoiceId: string }>();
    const { t } = useTranslation();

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `companies`,
            nameKey: 'companies',
        },
        {
            path: `${id}/dashboard`,
            nameKey: companyContext?.name ?? '',
        },
        {
            path: `finance`,
            nameKey: 'finance',
        },
        {
            path: `invoices`,
            nameKey: 'Invoices',
        },
        {
            path: invoiceId,
            nameKey: cleanVal.string(invoice?.number),
        },
    ];

    const refundInvoice = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="SecurityDeposit" />,
                title: t(`User.contact_profile_invoice_refund_title`),
                message: t(`User.contact_profile_invoice_refund_subtitle`),
                positiveText: t(`User.contact_profile_invoice_refund_confirm_positive`),
                negativeText: t(`User.contact_profile_invoice_refund_confirm_negative`),
            }))
        ) {
            return;
        } else {
            try {
                globalLoadingStore.addLoading();

                await invoiceService.refundInvoice(invoiceId);

                toastStore.toast({
                    type: 'success',
                    messageKey: t(`User.contact_profile_invoice_refund_confirmed`),
                });
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        }
    };

    const resendInvoice = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            await invoiceService.resendInvoice(invoiceId);

            globalLoadingStore.removeLoading();

            await confirmationModalStore.confirm({
                icon: <Icon iconName="SecurityDeposit" />,
                title: t(`User.contact_profile_invoice_resend_title`),
                message: t(`User.contact_profile_invoice_resend_subtitle`),
                positiveText: t(`ok`),
            });
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }

            globalLoadingStore.removeLoading();
        }
    };

    const markClosed = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            await invoiceService.closeInvoice(invoiceId);

            toastStore.toast({
                type: 'success',
                messageKey: t(`Invoice.mark_as_closed_success`),
            });
            fetchInvoice();
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const MarkVoid = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            await invoiceService.voidInvoice(invoiceId);

            toastStore.toast({
                type: 'success',
                messageKey: t(`Invoice.mark_as_void_success`),
            });
            fetchInvoice();
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const retryPayment = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            const invoice = await invoiceService.tryInvoicePayment(invoiceId);

            if (invoice?.status === InvoiceStatusDto.Success) {
                toastStore.toast({
                    type: 'success',
                    messageKey: t(`Invoice.retry_payment_success`),
                });
            } else {
                toastStore.toast({
                    type: 'error',
                    messageKey: t(`Invoice.retry_payment_failed`),
                });
            }
            fetchInvoice();
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const exportInvoice = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            const document = await invoiceService.exportInvoice({ ids: [invoiceId] });
            showFile(document, `Invoice ${invoice?.number}`);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [setLoading, toastStore, invoice]);

    const menuOptions = (): ActionMenuOption[] => {
        const actions = [
            {
                key: 'resend',
                title: t('User.contact_profile_invoice_cta_resend'),
                action: (): Promise<void> => resendInvoice(),
            },
        ];

        const closedInvoiceStatuses = [
            InvoiceStatusDto.Success,
            InvoiceStatusDto.Refunded,
            InvoiceStatusDto.Void,
        ];

        if (
            !closedInvoiceStatuses.some((status) => invoice?.status === status) &&
            userPermissionsStore.permissions?.some(
                (role) => role.name === ManagementRoleDto.Administrator
            )
        ) {
            actions.unshift(
                {
                    key: 'markVoid',
                    title: t('Invoice.mark_as_void'),
                    action: (): Promise<void> => MarkVoid(),
                },
                {
                    key: 'markClosed',
                    title: t('Invoice.mark_as_closed'),
                    action: (): Promise<void> => markClosed(),
                }
            );
        }

        if (invoice?.status === InvoiceStatusDto.Success)
            actions.unshift({
                key: 'refund',
                title: t('User.contact_profile_invoice_cta_refund'),
                action: (): Promise<void> => refundInvoice(),
            });

        if (
            invoice?.status === InvoiceStatusDto.Failed &&
            userPermissionsStore.permissions?.some(
                (role) => role.name === ManagementRoleDto.Administrator
            )
        )
            actions.push({
                key: 'retryPayment',
                title: t('User.contact_profile_invoice_cta_retry_payment'),
                action: (): Promise<void> => retryPayment(),
            });

        actions.push({
            key: 'export',
            title: t('Proposal.proposal_cta_label_export'),
            action: () => exportInvoice(),
        });

        return actions;
    };

    const actionButton = <ActionMenu options={menuOptions()} type="primary" trigger="click" />;

    const headerDetails = {
        iconName: 'Bookmark',
        title: cleanVal.string(invoice?.number),
    };

    const fetchInvoice = useCallback(async () => {
        setLoading(true);

        try {
            const invoice = await invoiceService.getInvoice(invoiceId);

            if (invoice) {
                setInvoice(invoice);
                setInvoiceHeaderData({
                    invoice: invoice!,
                    amountDue: invoice?.amountDue || 0,
                });
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            setLoading(false);
        }
    }, [setLoading, invoiceService, invoiceId]);

    const fetchPdf = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();

        try {
            const document = await invoiceService.exportInvoice({ ids: [invoiceId] });

            setPdf(document);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
            setLoading(false);
        }
    }, [id, invoiceService, globalLoadingStore, toastStore]);

    useEffect(() => {
        fetchPdf();
    }, [fetchPdf]);

    useEffect(() => {
        const disposer = autorun(() => {
            fetchInvoice();
        });

        return (): void => {
            disposer();
        };
    }, [fetchInvoice]);

    return (
        <div className="CompanyInvoiceDetails">
            <CompanyHeader
                routes={breadcrumbs}
                headerData={headerDetails}
                invoiceData={invoiceHeaderData}
                loading={loading}
                action={actionButton}
            />

            <Content>
                {invoice?.status === InvoiceStatusDto.Failed && (
                    <div className="failed-container">
                        <div className="inner-container">
                            <div className="message">{invoice.reasonMessage}</div>
                            <div className="description">{invoice.reasonDescription}</div>
                        </div>
                    </div>
                )}
                <PdfViewer file={pdf} />
            </Content>
        </div>
    );
};

export default CompanyInvoiceDetails;
