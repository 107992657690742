// GENERATED FILE - DO NOT MODIFY
import { CampusFloorPlanDto } from 'Api/Features/FloorPlans/Dtos/CampusFloorPlanDto';
import { GetCampusFloorPlanRequestDto } from 'Api/Features/FloorPlans/Dtos/GetCampusFloorPlanRequestDto';
import { UpdateFloorPlanRequestDto } from 'Api/Features/FloorPlans/Dtos/UpdateFloorPlanRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class FloorPlanProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getCampusFloorPlan(campusId: string | null, request: GetCampusFloorPlanRequestDto | null): Promise<CampusFloorPlanDto | null> {
        const uri = this.buildUri(
            "/campus/{campusId}/floorplan",
            { campusId: campusId },
            { ...request || undefined }
        );

        const data: CampusFloorPlanDto | null = await this.httpClient.get<CampusFloorPlanDto | null>(uri);
        return data;
    }

    public async updateFloorPlan(campusId: string | null, request: UpdateFloorPlanRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/campus/{campusId}/floorplan",
            { campusId: campusId },
            null
        );

        await this.httpClient.put<UpdateFloorPlanRequestDto | null, void>(uri, request);
    }
}