import { FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import React from 'react';
import { Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { ListSectionHeader } from 'Components/list-section-header';
import { theme, images } from 'variant';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { useService, useStores } from 'Hooks';
import { useTranslation } from 'react-i18next';
import { Plan as PlanIcon } from 'Components/icons';
import { autorun } from 'mobx';
import Table, { TablePaginationConfig } from 'antd/lib/table';
import { FilterStore } from 'Stores';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { TableFilters } from 'Components/table-filters';
import { ALL_ASSIGNED_MANAGERS, ALL_LOCATIONS, UNASSIGNED_LOCATIONS } from 'Models/Constants';
import { ManagerUser } from 'Models/ManagerUsers/ManagerUser';
import EditAssignments from './edit-assignments';
import { LeadAssignationService } from 'Services/LeadAssignmentService';
import { LeadAssignationRuleDto } from 'Api/Features/LeadAssignationRules/Dtos/LeadAssignationRuleDto';

const { usersHeader } = images;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 20,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const breadcrumbs: BreadcrumbSegment[] = [
    {
        nameKey: 'Administration.administration_title',
        path: 'administration',
    },
    {
        nameKey: 'Administration.lead_opportunity_assignment',
        path: 'lead-opportunity-assignment',
    },
];

const advancedFilters: AdvancedFilter[] = [];

const LeadOpportunityAssignement: FunctionComponent = () => {
    const { t } = useTranslation();
    const leadAssignationService = useService(LeadAssignationService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { locationStore } = useStores();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<LeadAssignationRuleDto[]>();
    const [filteredData, setFilteredData] = useState<LeadAssignationRuleDto[]>();
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [assignedManagers, setAssignedManagers] = useState<ManagerUser[] | undefined>([]);
    const [editAssignmentsModalOpen, setEditAssignmentsModalOpen] = useState(false);

    const locationRender = (rule: LeadAssignationRuleDto): ReactNode => {
        return rule.campus?.id ? (
            <span>{rule.campus.name}</span>
        ) : (
            //the default location line
            <span>{t('Administration.assignment_default_location')}</span>
        );
    };

    const managerRender = (rule: LeadAssignationRuleDto): ReactNode =>
        rule.account ? (
            <span>
                {rule.account.firstName || ''} {rule.account.lastName || ''}
            </span>
        ) : (
            ''
        );

    const columns = [
        {
            title: t('Administration.lead_opportunity_location'),
            render: locationRender,
            sorter: {
                compare: (a: any, b: any): number => a.campus?.name?.localeCompare(b.campus?.name),
            },
        },
        {
            title: t('Administration.manager_assigned'),
            render: managerRender,
            sorter: {
                compare: (a: any, b: any): number =>
                    a.account?.lastName?.localeCompare(b.account?.lastName),
            },
        },
    ];

    const fetch = useCallback(async () => {
        setLoading(true);
        try {
            const data = await leadAssignationService.getLeadAssignationRules();
            const rules = data?.items?.filter((rule) => rule !== null).map((rule) => rule!);

            setData(rules);
            const uniqueAssignedManagers = rules
                ?.filter((rule) => rule.account !== null)
                ?.map((rule: LeadAssignationRuleDto) => rule.account)
                .filter(
                    (manager, i, array) =>
                        array.findIndex((_manager) => _manager?.id === manager?.id) === i
                )
                .filter((unique) => unique !== null || unique !== undefined)
                .map((manager) => manager!);

            setAssignedManagers(
                uniqueAssignedManagers?.map(
                    (manager) =>
                        new ManagerUser({
                            id: manager.id,
                            firstName: manager.firstName,
                            lastName: manager.lastName,
                        })
                )
            );
        } finally {
            setLoading(false);
        }
    }, [leadAssignationService]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const filterResults = useCallback(
        (params: {
            searchTerm?: string;
            currentManagerAssignmentId?: string;
            currentLocationId?: string;
        }) => {
            const filtered = data?.filter(
                (rule) =>
                    //search term filter
                    (params.searchTerm === '' ||
                        rule?.campus?.name?.includes(params.searchTerm!) ||
                        rule?.account?.firstName?.includes(params.searchTerm!) ||
                        rule?.account?.lastName?.includes(params.searchTerm!)) &&
                    //manager filter
                    (params.currentManagerAssignmentId === ALL_ASSIGNED_MANAGERS ||
                        rule?.account?.id === params.currentManagerAssignmentId) &&
                    //location filter
                    (params.currentLocationId === ALL_LOCATIONS ||
                        (params.currentLocationId === UNASSIGNED_LOCATIONS &&
                            rule?.account === null) ||
                        rule?.campus?.id === params.currentLocationId)
            );
            setFilteredData(filtered);
        },
        [data]
    );

    const handleTableChange = (pagination: TablePaginationConfig): void => {
        const { searchTerm, currentManagerAssignmentId, currentLocationId } = filterStoreRef.current;
        filterResults({ searchTerm, currentManagerAssignmentId, currentLocationId });
        setPagination(pagination);
    };

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            filterResults({
                searchTerm: filterStore.searchTerm,
                currentManagerAssignmentId: filterStore.currentManagerAssignmentId,
                currentLocationId: filterStore.currentLocationId
            });
        });
        return (): void => {
            disposer();
        };
    }, [filterResults]);

    return (
        <div className="LeadOpportunityAssignment">
            <ListSectionHeader
                title={t('Administration.lead_opportunity_assignment')}
                subTitle={t('Administration.manage_manager_assigned')}
                defaultImg={<PlanIcon fill={theme['primary-color']} />}
                backgroundImageUrl={usersHeader}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            setEditAssignmentsModalOpen(true);
                        }}
                    >
                        {t('Administration.edit_assignments')}
                    </Button>
                }
                routes={breadcrumbs}
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeLeadOpportunityAssignementManagers
                    availableAssignementManagers={assignedManagers}
                    includeLocations
                    availableLocations={locationStore.locations}
                    includeUnassignedLocations
                />
                <Table
                    className="table-striped-rows"
                    bordered
                    columns={columns}
                    rowKey={(rule: LeadAssignationRuleDto) => rule.campus?.id ?? 'default'}
                    dataSource={filteredData}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </Content>

            {editAssignmentsModalOpen && (
                <EditAssignments
                    visible={editAssignmentsModalOpen}
                    onComplete={(): void => {
                        setEditAssignmentsModalOpen(false);
                        fetch();
                    }}
                    rules={data}
                />
            )}
        </div>
    );
};

export default LeadOpportunityAssignement;
