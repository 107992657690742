// GENERATED FILE - DO NOT MODIFY
import { CreateLeadCommentRequestDto } from 'Api/Features/Leads/Dtos/CreateLeadCommentRequestDto';
import { CreateLeadRequestDto } from 'Api/Features/Leads/Dtos/CreateLeadRequestDto';
import { ExportLeadsRequestDto } from 'Api/Features/Leads/Dtos/ExportLeadsRequestDto';
import { GetLeadNoteResponseDto } from 'Api/Features/Leads/Dtos/GetLeadNoteResponseDto';
import { GetLeadsRequestDto } from 'Api/Features/Leads/Dtos/GetLeadsRequestDto';
import { GetLeadsResponseDto } from 'Api/Features/Leads/Dtos/GetLeadsResponseDto';
import { LeadDto } from 'Api/Features/Leads/Dtos/LeadDto';
import { UpdateLeadNoteRequestDto } from 'Api/Features/Leads/Dtos/UpdateLeadNoteRequestDto';
import { UpdateLeadRequestDto } from 'Api/Features/Leads/Dtos/UpdateLeadRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class LeadProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getLead(leadId: string): Promise<LeadDto | null> {
        const uri = this.buildUri(
            "/leads/{leadId}",
            { leadId: leadId },
            null
        );

        const data: LeadDto | null = await this.httpClient.get<LeadDto | null>(uri);
        return data;
    }

    public async getLeads(request: GetLeadsRequestDto | null): Promise<GetLeadsResponseDto | null> {
        const uri = this.buildUri(
            "/leads",
            null,
            { ...request || undefined }
        );

        const data: GetLeadsResponseDto | null = await this.httpClient.get<GetLeadsResponseDto | null>(uri);
        return data;
    }

    public async exportLeads(request: ExportLeadsRequestDto | null): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/leads/export",
            null,
            { ...request || undefined }
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }

    public async createLead(request: CreateLeadRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/leads",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateLeadRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateLead(leadId: string, request: UpdateLeadRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/leads/{leadId}",
            { leadId: leadId },
            null
        );

        await this.httpClient.patch<UpdateLeadRequestDto | null, void>(uri, request);
    }

    public async createLeadComment(leadId: string, request: CreateLeadCommentRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/leads/{leadId}/comments",
            { leadId: leadId },
            null
        );

        await this.httpClient.post<CreateLeadCommentRequestDto | null, void>(uri, request);
    }

    public async deleteLead(leadId: string): Promise<void> {
        const uri = this.buildUri(
            "/leads/{leadId}",
            { leadId: leadId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async getLeadNote(leadId: string): Promise<GetLeadNoteResponseDto | null> {
        const uri = this.buildUri(
            "/leads/{leadId}/notes",
            { leadId: leadId },
            null
        );

        const data: GetLeadNoteResponseDto | null = await this.httpClient.get<GetLeadNoteResponseDto | null>(uri);
        return data;
    }

    public async updateLeadNote(leadId: string, request: UpdateLeadNoteRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/leads/{leadId}/notes",
            { leadId: leadId },
            null
        );

        await this.httpClient.put<UpdateLeadNoteRequestDto | null, void>(uri, request);
    }
}