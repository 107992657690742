import { StoredFileDto } from 'Api/Features/General/Dtos/StoredFileDto';
import { CreateLeadFileRequestDto } from 'Api/Features/Leads/Dtos/CreateLeadFileRequestDto';
import { GetLeadFilesRequestDto } from 'Api/Features/Leads/Dtos/GetLeadFilesRequestDto';
import { GetLeadFilesResponseDto } from 'Api/Features/Leads/Dtos/GetLeadFilesResponseDto';
import { LeadFileProxy } from 'Api/Features/Leads/LeadFileProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(LeadFileProxy)
export class LeadFileService extends ApiService {
    constructor(private readonly leadFileProxy: LeadFileProxy) {
        super();
    }

    public async getLeadFiles(leadId: string, request: GetLeadFilesRequestDto | null): Promise<[StoredFileDto[], number]> {
        const data: GetLeadFilesResponseDto | null = await this.leadFileProxy.getLeadFiles(leadId, request);
        return [
            data?.items?.filter((file) => file !== null).map((file) => file!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async createLeadFile(leadId: string, request: CreateLeadFileRequestDto | null): Promise<string> {
        const data: string = await this.leadFileProxy.createLeadFile(leadId, request);
        return data;
    }

    public async deleteLeadFile(leadId: string, fileId: string): Promise<void> {
        await this.leadFileProxy.deleteLeadFile(leadId, fileId);
    }
}
