import React, { FunctionComponent, useState } from 'react';
import { PasswordResetSchema } from '../../../Schemas';
import { useFormValidation, useService } from '../../../Hooks';
import { useTranslation } from 'react-i18next';
import { ValidatedFormItem } from '../../../Components/validated-form-item';
import { AuthenticationService } from '../../../Services/AuthenticationService';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Row, Col, Form, Input, Typography } from 'antd';

import { images } from '../../../variant';
import './forgot-password.less';

const { Title, Text } = Typography;

const ForgotPassword: FunctionComponent = () => {
    const [form] = Form.useForm();
    const [errors, validateForm] = useFormValidation(PasswordResetSchema, form);
    const authenticationService = useService(AuthenticationService);
    const [redirectPath, setRedirect] = useState<string>();
    const history = useHistory();
    const { t } = useTranslation();

    if (redirectPath) {
        return (
            <Redirect
                to={{
                    pathname: redirectPath,
                    state: { resetPasword: true },
                }}
            />
        );
    }
    return (
        <div className="ForgotPassword">
            <Row>
                <Col>
                    <Button
                        type="primary"
                        htmlType="button"
                        className="back-btn"
                        onClick={(): void => history.goBack()}
                    >
                        {'<'}
                    </Button>
                </Col>
            </Row>
            <Row className="mt-20" gutter={[0, 30]}>
                <Col span={4} />
                <Col span={16}>
                    <img id="logo" src={images.logoWhite} alt="logo" />
                </Col>
                <Col span={4} />
            </Row>
            <Row gutter={[0, 40]}>
                <Col span={12} offset={6}>
                    <Title level={2} className="singular-color">
                        {t('User.you_forgot_your_password')}
                    </Title>
                    <Text className="text-lg text-white">
                        {t('User.send_you_an_email_to_reset')}
                        &nbsp;
                        <Text strong={true} className="text-lg text-white">
                            {t('User.please_enter_account_email')}
                        </Text>
                    </Text>
                </Col>
            </Row>
            <Form
                scrollToFirstError
                layout="vertical"
                onFinish={async (values): Promise<void> => {
                    const valid = await validateForm(values);
                    if (!valid) return;
                    try {
                        await authenticationService.forgottenPassword(values.username);
                    } finally {
                        setRedirect('/');
                    }
                }}
                form={form}
            >
                <Row gutter={[0, 50]}>
                    <Col span={12} offset={6}>
                        <ValidatedFormItem
                            errors={errors}
                            label={t('email')}
                            name="username"
                            required
                        >
                            <Input />
                        </ValidatedFormItem>
                    </Col>
                </Row>
                <Row gutter={[0, 60]}>
                    <Col span={12} offset={6}>
                        <Button type="primary" htmlType="submit" className="login-btn">
                            {t('User.reset_password')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default ForgotPassword;
