// GENERATED FILE - DO NOT MODIFY
import { CreateAccountFileRequestDto } from 'Api/Features/Accounts/Dtos/CreateAccountFileRequestDto';
import { GetAccountFilesRequestDto } from 'Api/Features/Accounts/Dtos/GetAccountFilesRequestDto';
import { GetAccountFilesResponseDto } from 'Api/Features/Accounts/Dtos/GetAccountFilesResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class AccountFileProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getAccountFiles(accountId: string | null, request: GetAccountFilesRequestDto | null): Promise<GetAccountFilesResponseDto | null> {
        const uri = this.buildUri(
            "/accounts/{accountId}/files",
            { accountId: accountId },
            { ...request || undefined }
        );

        const data: GetAccountFilesResponseDto | null = await this.httpClient.get<GetAccountFilesResponseDto | null>(uri);
        return data;
    }

    public async createAccountFile(accountId: string | null, request: CreateAccountFileRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/accounts/{accountId}/files",
            { accountId: accountId },
            null
        );

        const data: string = await this.httpClient.post<CreateAccountFileRequestDto | null, string>(uri, request);
        return data;
    }

    public async deleteAccountFile(accountId: string | null, fileId: string): Promise<void> {
        const uri = this.buildUri(
            "/accounts/{accountId}/files/{fileId}",
            { accountId: accountId, fileId: fileId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}