import { theme } from 'variant';
import { createTheme } from '@material-ui/core/styles';

export default createTheme ({
    palette: {
        primary: {
            main: theme["primary-color"],
        },
    },

    typography: {
        fontFamily: "'Raleway', serif",
    },
    overrides: {
        MuiMenuItem: {
            root: {
                fontSize: 14,
                fontWeight: 500,
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: theme["primary-color"],
            },
        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                color: theme["white"],
            },
            toolbarBtnSelected: {
                color: theme["white"],
            },
        },

        MuiPickersDay: {
            daySelected: {
                color: theme["white"],
            },
            current: {
                color: theme["primary-color"],
            },
        },
    },
});
