import { Col, Row, Select } from 'antd';
import { CampusFloorPlanFloorDto } from 'Api/Features/FloorPlans/Dtos/CampusFloorPlanFloorDto';
import { CampusFloorPlanUnitAreaDto } from 'Api/Features/FloorPlans/Dtos/CampusFloorPlanUnitAreaDto';
import { SubscriptionExpirationStatusTypeDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionExpirationStatusTypeDto';
import { GetUnitOccupancyStatusesRequestDto } from 'Api/Features/UnitOccupancy/Dtos/GetUnitOccupancyStatusesRequestDto';
import { UnitOccupancyStatusDto } from 'Api/Features/UnitOccupancy/Dtos/UnitOccupancyStatusDto';
import { UnitTypeDto } from 'Api/Features/Units/Dtos/UnitTypeDto';
import { useService, useStores } from 'Hooks';
import { PAGE_SIZE_100 } from 'Models/Constants';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FloorPlanService } from 'Services/FloorPlanService';
import { UnitOccupancyService } from 'Services/UnitOccupancyService';
import FloorPlanSvg from './floor-plan-svg';
import './floor-plan.less';

export const mapUnitsToFloor = (
    unitAreas: (CampusFloorPlanUnitAreaDto | null)[] | null | undefined,
    unitOccupancyStatuses: UnitOccupancyStatusDto[]
): FloorPlanUnitObject[] => {
    const units: FloorPlanUnitObject[] = [];
    unitAreas &&
        unitAreas.forEach((unitArea: any) => {
            unitOccupancyStatuses.forEach((unitOccupancyStatus: UnitOccupancyStatusDto) => {
                if (unitOccupancyStatus.id === unitArea.unitId) {
                    // Remove unitId because we already have same id from unitOccupancy.id
                    const { unitId, ...unitAreaRest } = unitArea;
                    units.push({
                        ...unitOccupancyStatus,
                        ...unitAreaRest,
                        membershipImage:
                            unitOccupancyStatus.type !== UnitTypeDto.CommunalArea
                                ? unitOccupancyStatus.subscriptions?.[0]?.membership?.imageUrl
                                : undefined,
                        status:
                            unitOccupancyStatus.subscriptions?.[0]?.status ??
                            SubscriptionExpirationStatusTypeDto.Vacant,
                    });
                }
            });
        });

    return units;
};

export interface FloorPlanUnitObject {
    campus: { id: string; name: string };
    capacity: number;
    id: string;
    marketPrice: number;
    name: string;
    points: string;
    poleOfInaccessibility: { x: number; y: number };
    poleOfInaccessibilityPercent: { x: number; y: number };
    subscriptions: Array<any>;
    svgElementId: string;
    type: string;
    membershipImage?: string;
    status: string;
}

export enum FloorPlanViewType {
    Location = 'Location',
    Subscription = 'Subscription',
    HeatMap = 'HeatMap'
}

interface FloorPlanProps {
    campusId?: string;
    proposalUnitIds?: string[];
    membershipImage?: string;
    height?: number;
    svgInstanceZoom?: number;
    specificUnitId?: string;
    viewType: FloorPlanViewType
}

const FloorPlanComponent: FunctionComponent<FloorPlanProps> = ({
    campusId,
    proposalUnitIds,
    membershipImage,
    height,
    svgInstanceZoom,
    specificUnitId,
    viewType
}) => {
    const { t } = useTranslation();
    const unitOccupancyService = useService(UnitOccupancyService);
    const floorPlanService = useService(FloorPlanService);
    const { globalLoadingStore, toastStore } = useStores();

    const [floors, setFloors] = useState<(CampusFloorPlanFloorDto | null)[] | null>();
    const [selectedFloor, setSelectedFloor] = useState<CampusFloorPlanFloorDto | null>();
    const [campusUnits, setCampusUnits] = useState<UnitOccupancyStatusDto[]>()
    const [selectedFloorUnits, setSelectedFloorUnits] = useState<FloorPlanUnitObject[]>();
    const [campusIdState, setCampusIdState] = useState<string>();

    const displayFloorPlan =
        floors &&
        floors.length > 0 &&
        (viewType === FloorPlanViewType.Location ||
            viewType === FloorPlanViewType.HeatMap ||
            (selectedFloorUnits && selectedFloorUnits.length > 0));

    const fetchCampusUnits = async (
        campusId?: string,
        page?: number,
        pageSize?: number
    ): Promise<(UnitOccupancyStatusDto | null)[]> => {
        globalLoadingStore.addLoading();
        try {
            const request: GetUnitOccupancyStatusesRequestDto = {
                campusIds: campusId ? [campusId] : undefined,
                page: page,
                pageSize: pageSize
            };
            const unitOccupancyStatuses = await unitOccupancyService.getUnitOccupancyStatuses(
                request
            );
            return unitOccupancyStatuses
                ? unitOccupancyStatuses
                : [];
        } catch (e) {
            toastStore.genericError();
            return [];
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const getCampusUnits = async (): Promise<UnitOccupancyStatusDto[]> => {
        let currentPage = 0;
        let fetchedAllUnits = false;
        let units: (UnitOccupancyStatusDto | null)[] = [];

        while (!fetchedAllUnits) {
            const result = await fetchCampusUnits(campusId, currentPage, PAGE_SIZE_100);
            units = [...units, ...result]
            if( result.length === 0 || result.length !== PAGE_SIZE_100)
                fetchedAllUnits = true;
            else
                currentPage++;
        }
        return units?.filter((unit) => unit !== null).map((unit) => unit!) ?? [];
    }

    const handleSelectedFloor = (selectedFloor: CampusFloorPlanFloorDto): void => {
        if (campusUnits) {
            const units = mapUnitsToFloor(selectedFloor?.unitAreas, campusUnits);
            setSelectedFloorUnits(units);
        }
    }

    useEffect(() => {
        setCampusIdState(campusId);
    }, [campusId]);
    
    useEffect(() => {
        if (campusIdState) {
            const fetchFloorPlan = async (campusId: string) => {
                globalLoadingStore.addLoading();
                try {
                    const floorPlanData = await floorPlanService.getCampusFloorPlan(campusId, null);
                    if (floorPlanData?.floors) {
                        setFloors(floorPlanData.floors);

                        let selectedFloor: CampusFloorPlanFloorDto | null | undefined = undefined;
                        if (specificUnitId) {
                            selectedFloor = floorPlanData.floors.find((floor) =>
                                floor?.unitAreas?.find((unit) => unit?.unitId === specificUnitId)
                            );
                        } else {
                            selectedFloor = proposalUnitIds
                                ? floorPlanData.floors.find((floor) =>
                                      floor?.unitAreas?.find((unit) =>
                                          proposalUnitIds.find((id) => unit?.unitId === id)
                                      )
                                  )
                                : floorPlanData.floors[0];
                        }

                        setSelectedFloor(selectedFloor);
                        const campusUnits = await getCampusUnits();
                        setCampusUnits(campusUnits);
                        if(selectedFloor)
                            handleSelectedFloor(selectedFloor);
                    }
                } catch (e) {
                    toastStore.genericError();
                } finally {
                    globalLoadingStore.removeLoading();
                }
            };
            fetchFloorPlan(campusIdState);
        }
    }, [
        campusIdState,
        globalLoadingStore,
        floorPlanService,
        proposalUnitIds,
        toastStore,
    ]);

    useEffect(() => {
        if(selectedFloor && campusUnits)
            handleSelectedFloor(selectedFloor)
    }, [selectedFloor, campusUnits])

    const onFloorChange = async (floorId: string): Promise<void> => {
        if (floorId !== selectedFloor?.id) {
            const selectedFloor = floors?.find((floor) => floor?.id === floorId);
            if (selectedFloor) {
                setSelectedFloor(selectedFloor);
            }
        }
    };

    const unitsSpanMultipleFloors = (): boolean => {
        if (!proposalUnitIds || proposalUnitIds.length <= 1) return false;
        const numberOfFloors = floors?.filter((floor) =>
            floor?.unitAreas?.some((unit) => proposalUnitIds.some((id) => unit?.unitId === id))
        );
        return numberOfFloors ? numberOfFloors.length > 1 : false;
    };

    return (
        <div className="FloorPlan floorplan-container w-100 mt-3">
            <Row>
                <Col className={`displayFloorPlan-${displayFloorPlan}`} span={3}></Col>

                <Col span={displayFloorPlan ? 18 : 24}>
                    <div className="d-flex w-100" style={{ height: height ?? undefined }}>
                        {displayFloorPlan ? (
                            <FloorPlanSvg
                                floor={selectedFloor}
                                units={selectedFloorUnits}
                                className="d-flex ml-1"
                                unitIds={proposalUnitIds}
                                membershipImage={membershipImage}
                                svgInstanceZoom={svgInstanceZoom}
                                viewType={viewType}
                            />
                        ) : (
                            <div
                                style={{ height: height ?? undefined }}
                                className="no-results d-flex flex-column align-items-center justify-content-center text-center w-100"
                            >
                                <h3>
                                    {floors && floors.length > 0
                                        ? t('FloorPlan.unit_not_defined_title')
                                        : t('FloorPlan.no_available_floorplan_title')}
                                </h3>
                                <p>
                                    {floors && floors.length > 0
                                        ? t('FloorPlan.unit_not_defined_message')
                                        : viewType === FloorPlanViewType.Location ||
                                          viewType === FloorPlanViewType.HeatMap
                                        ? t('FloorPlan.add_a_floor_plan_in_management')
                                        : t('FloorPlan.no_available_floorplan_message')}
                                </p>
                            </div>
                        )}
                    </div>
                </Col>

                <Col
                    className={`displayFloorPlan-${displayFloorPlan}`}
                    style={{ paddingLeft: '15px' }}
                    span={3}
                >
                    {(viewType === FloorPlanViewType.Location ||
                        viewType === FloorPlanViewType.HeatMap ||
                        displayFloorPlan) && (
                        <Select
                            className="w-100"
                            onChange={onFloorChange}
                            value={selectedFloor?.id}
                            disabled={
                                viewType !== FloorPlanViewType.Location &&
                                viewType !== FloorPlanViewType.HeatMap &&
                                !unitsSpanMultipleFloors()
                            }
                        >
                            {floors &&
                                floors
                                    .filter(
                                        (floor) =>
                                            viewType === FloorPlanViewType.Location ||
                                            viewType === FloorPlanViewType.HeatMap ||
                                            floor?.unitAreas?.find((unit) =>
                                                proposalUnitIds?.find((id) => unit?.unitId === id)
                                            )
                                    )
                                    .map((floor) => (
                                        <Select.Option key={floor?.id} value={floor?.id!}>
                                            {floor?.name}
                                        </Select.Option>
                                    ))}
                        </Select>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default FloorPlanComponent;
