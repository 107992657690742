import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { RevenueOccupancyTargetsProxy } from 'Api/Features/RevenueOccupancyTargets/RevenueOccupancyTargetsProxy';
import { GetRevenueOccupancyTargetsRequestDto } from 'Api/Features/RevenueOccupancyTargets/Dtos/GetRevenueOccupancyTargetsRequestDto';
import { GetRevenueOccupancyTargetsResponseDto } from 'Api/Features/RevenueOccupancyTargets/Dtos/GetRevenueOccupancyTargetsResponseDto';
import { UpdateRevenueOccupancyTargetsRequestDto } from 'Api/Features/RevenueOccupancyTargets/Dtos/UpdateRevenueOccupancyTargetsRequestDto';

@inject(RevenueOccupancyTargetsProxy)
export class RevenueOccupancyTargetsService extends ApiService {
    constructor(private readonly revenueOccupancyTargetsProxy: RevenueOccupancyTargetsProxy) {
        super();
    }

    public async getRevenueOccupancyTargets(campusId: string | null, request: GetRevenueOccupancyTargetsRequestDto | null): Promise<GetRevenueOccupancyTargetsResponseDto | null> {
        const data: GetRevenueOccupancyTargetsResponseDto | null = await this.revenueOccupancyTargetsProxy.getRevenueOccupancyTargets(campusId, request);
        return data;
    }

    public async updateRevenueOccupancyTargets(campusId: string | null, request: UpdateRevenueOccupancyTargetsRequestDto | null): Promise<void> {
        await this.revenueOccupancyTargetsProxy.updateRevenueOccupancyTargets(campusId, request);
    }
}
