import { GetMembershipSecurityDepositSummaryResponseDto } from 'Api/Features/SecurityDeposits/Dtos/GetMembershipSecurityDepositSummaryResponseDto';
import { GetMembershipSecurityDepositTransactionsRequestDto } from 'Api/Features/SecurityDeposits/Dtos/GetMembershipSecurityDepositTransactionsRequestDto';
import { GetMembershipSecurityDepositTransactionsResponseDto } from 'Api/Features/SecurityDeposits/Dtos/GetMembershipSecurityDepositTransactionsResponseDto';
import { SecurityDepositTransactionDto } from 'Api/Features/SecurityDeposits/Dtos/SecurityDepositTransactionDto';
import { SecurityDepositProxy } from 'Api/Features/SecurityDeposits/SecurityDepositProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(SecurityDepositProxy)
export class SecurityDepositService extends ApiService {
    constructor(private readonly securityDepositProxy: SecurityDepositProxy) {
        super();
    }

    public async getMembershipSecurityDepositTransactions(membershipId: string | null, request: GetMembershipSecurityDepositTransactionsRequestDto | null):
     Promise<[SecurityDepositTransactionDto[], number]> {
        const data: GetMembershipSecurityDepositTransactionsResponseDto | null =
            await this.securityDepositProxy.getMembershipSecurityDepositTransactions(
                membershipId,
                request
            );
        return [
            data?.items?.filter((x) => x !== null).map((x) => x!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async getMembershipSecurityDepositSummary(membershipId: string | null): Promise<GetMembershipSecurityDepositSummaryResponseDto | null> {
        const data: GetMembershipSecurityDepositSummaryResponseDto | null =
            await this.securityDepositProxy.getMembershipSecurityDepositSummary(membershipId);
        return data;
    }
}
