import { Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';
import { GetSalesReportRequestDto } from 'Api/Features/Reports/Dtos/Sales/GetSalesReportRequestDto';
import { SalesReportItemDto } from 'Api/Features/Reports/Dtos/Sales/SalesReportItemDto';
import { SalesReportItemTypeDto } from 'Api/Features/Reports/Dtos/Sales/SalesReportItemTypeDto';
import { Company, Location, User } from 'Components/icons';
import Icon from 'Components/icons/icon';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import TableActionsButtons from 'Components/table-action-buttons/table-actions-buttons';
import { TableFilters } from 'Components/table-filters';
import { Tag } from 'Components/tag';
import { TagColors } from 'Components/tag/tag';
import { TdWithImage } from 'Components/td-with-image';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { ALL_LOCATIONS, MOMENT_PARSING_FORMAT, TABLE_DEBOUNCE_DELAY } from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { DateRangeDropdownEnum } from 'Models/Filters/DateRangeDropdownOption';
import moment from 'moment';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SalesReportService } from 'Services/SalesReportService';
import { FilterStore } from 'Stores';
import { showFile } from 'Utils';
import { cleanVal } from 'Utils/NumberUtils';
import { getAdvancedTableFiltersState } from 'Utils/TableAdvancedFiltersUtils';
import { ListSectionHeader } from '../../../../Components/list-section-header';
import { images, theme } from '../../../../variant';
import './index.less';

const { usersHeader } = images;

const breadcrumbs: BreadcrumbSegment[] = [
    {
        nameKey: 'reports',
        path: 'reports',
    },
    {
        nameKey: 'Reports.new_sale_activities',
        path: 'sale-activities',
    },
];

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'types',
        nameKey: 'Types',
        items: [
            {
                key: SalesReportItemTypeDto.LeadCreated,
                displayNameKey: 'Reports.SalesReportItemTypeDto_LeadCreated',
                checked: true,
            },
            {
                key: SalesReportItemTypeDto.OpportunityCreated,
                displayNameKey: 'Reports.SalesReportItemTypeDto_OpportunityCreated',
                checked: true,
            },
            {
                key: SalesReportItemTypeDto.TourScheduled,
                displayNameKey: 'Reports.SalesReportItemTypeDto_TourScheduled',
                checked: true,
            },
        ],
    },
];

const NewSaleActivities: FunctionComponent = observer(() => {
    const salesReportService = useService(SalesReportService);
    const { locationStore, globalLoadingStore, toastStore, userPermissionsStore, requestStore } =
        useStores();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<SalesReportItemDto[]>();

    const [isTableEmpty, setIsTableEmpty] = useState<boolean>(true);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { t } = useTranslation();

    const getCampusId = (currentLocationId: string): string[] => {
        return currentLocationId !== ALL_LOCATIONS
            ? [currentLocationId]
            : requestStore.getReportRequestAvailableLocationIdsForUser();
    };

    const onExportClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            const campusName =
                locationStore?.locations.find(
                    (x) => x.id === filterStoreRef.current.currentLocationId
                )?.name || ALL_LOCATIONS;

            const { checkedFilters } = getAdvancedTableFiltersState(
                filterStoreRef.current,
                'types'
            );
            const campusIds = getCampusId(filterStoreRef.current.currentLocationId);

            const document = await salesReportService.exportSalesReport({
                startDate: filterStoreRef.current.startDate,
                endDate: filterStoreRef.current.endDate,
                campusIds: campusIds,
                itemTypes: checkedFilters,
            } as GetSalesReportRequestDto);

            showFile(
                document,
                `${t('Reports.new_sale_activities')} - ${moment(
                    filterStoreRef.current.startDate
                ).format(MOMENT_PARSING_FORMAT)} - ${moment(filterStoreRef.current.endDate).format(
                    MOMENT_PARSING_FORMAT
                )} - ${campusName}`
            );
        } catch (e) {
            if (!e.treated) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const tableActionButtons = [
        {
            iconName: 'Download',
            toolTip: 'export_table',
            onClick: (): Promise<void> => onExportClick(),
            disabled: isTableEmpty,
        },
    ];

    const contactRender = (item: SalesReportItemDto): ReactNode =>
        item.contact ? (
            <TdWithImage defaultImg={<User />} imgSrc={item.contact.imageUrl}>
                {`${item.contact.firstName} ${item.contact.lastName}`}
            </TdWithImage>
        ) : (
            ''
        );

    const ownerRender = (item: SalesReportItemDto): ReactNode =>
        item.ownerAccount ? (
            <TdWithImage defaultImg={<User />} imgSrc={item.ownerAccount.imageUrl}>
                {`${item.ownerAccount.firstName} ${item.ownerAccount.lastName}`}
            </TdWithImage>
        ) : (
            ''
        );

    const companyRender = (item: SalesReportItemDto): ReactNode =>
        item.membership ? (
            <TdWithImage defaultImg={<Company />} imgSrc={item.membership.imageUrl}>
                {item.membership.name}
            </TdWithImage>
        ) : (
            ''
        );

    const locationRender = (item: SalesReportItemDto): ReactNode =>
        item.campus ? (
            <TdWithImage defaultImg={<Location />} imgSrc={item.campus.mainImageUrl}>
                {item.campus.name}
            </TdWithImage>
        ) : (
            ''
        );

    const statusRender = (item: SalesReportItemDto): ReactNode => {
        if (item.type === SalesReportItemTypeDto.TourScheduled) return null;
        else if (item.type === SalesReportItemTypeDto.LeadCreated) {
            return <Tag label={t(`Lead.status_${item.lead?.status}`)} />;
        } else {
            const tagColor =
                item.opportunity?.status === OpportunityStatusDto.AgreementSigned
                    ? TagColors.active
                    : item.opportunity?.status === OpportunityStatusDto.Lost
                    ? TagColors.disabled
                    : TagColors.default;

            return (
                <div>
                    <Tag
                        label={t(`Opportunity.opportunity_status_${item.opportunity?.status}`)}
                        color={tagColor}
                    />
                    {item.opportunity?.isSubscriptionModification && (
                        <Tag label={t('modification')} color={TagColors.cold} />
                    )}
                    {item.opportunity?.isSubscriptionRenewal && (
                        <Tag label={t('renewal')} color={TagColors.cold} />
                    )}
                </div>
            );
        }
    };

    const columns: ColumnType<SalesReportItemDto>[] = [
        {
            title: t('activity_date'),
            render: (item: SalesReportItemDto): string =>
                moment(item.date).format(MOMENT_PARSING_FORMAT),
        },
        {
            title: t('type'),
            render: (item: SalesReportItemDto): string =>
                t(`Reports.SalesReportItemTypeDto_${item.type}`),
        },
        {
            title: t('contact'),
            render: (item: SalesReportItemDto): ReactNode => contactRender(item),
        },
        {
            title: t('owner'),
            render: (item: SalesReportItemDto): ReactNode => ownerRender(item),
        },
        {
            title: t('company'),
            render: (item: SalesReportItemDto): ReactNode => companyRender(item),
        },
        {
            title: t('location'),
            render: (item: SalesReportItemDto): ReactNode => locationRender(item),
        },
        {
            title: t('Opportunity.desk_count'),
            dataIndex: ['opportunity', 'deskCount'],
            render: (deskCount): string => `${deskCount < 1 ? '' : cleanVal.number(deskCount)}`,
        },
        {
            title: t('current_status'),
            // eslint-disable-next-line react/display-name
            render: (item: SalesReportItemDto): ReactNode | null => statusRender(item),
        },
    ];

    const fetch = useCallback(
        async (params: {
            startDate?: string;
            endDate?: string;
            campusIds?: string[];
            advancedFilters?: AdvancedFilter[];
        }) => {
            const { checkedFilters, filterCount } = getAdvancedTableFiltersState(
                filterStoreRef.current,
                'types'
            );
            if (checkedFilters.length === 0) {
                setData([]);
                setIsTableEmpty(true);
            } else {
                try {
                    setLoading(true);
                    const request: GetSalesReportRequestDto = {
                        campusIds: params.campusIds,
                        endDate: params.endDate,
                        startDate: params.startDate,
                        itemTypes: checkedFilters.length === filterCount ? [] : checkedFilters,
                    };
                    const response = await salesReportService.getSalesReport(request);
                    if (response?.items)
                        setData(
                            response.items.filter((item) => item !== null).map((item) => item!)
                        );

                    setIsTableEmpty(response?.items?.length === 0);
                } finally {
                    setLoading(false);
                }
            }
        },
        [salesReportService]
    );

    useEffect(() => {
        const filterStore = filterStoreRef.current;
        filterStore.startDate = moment().startOf(`week`).format(MOMENT_PARSING_FORMAT);
        filterStore.endDate = moment().endOf(`week`).format(MOMENT_PARSING_FORMAT);
        filterStore.updateDateRange(DateRangeDropdownEnum.ThisWeek);
    }, []);

    const debounceSearch = useRef(
        debounce(
            (params: {
                startDate?: string;
                endDate?: string;
                campusIds?: string[];
                advancedFilters?: AdvancedFilter[];
            }) => {
                fetch(params);
            },
            TABLE_DEBOUNCE_DELAY
        )
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                startDate: filterStore.startDate,
                endDate: filterStore.endDate,
                campusIds: getCampusId(filterStore.currentLocationId),
                advancedFilters: filterStore.advancedFilters,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    return (
        <div className="NewSaleActivities">
            <ListSectionHeader
                routes={breadcrumbs}
                backgroundImageUrl={usersHeader}
                defaultImg={<Icon iconName="Plan" fill={theme['primary-color']} />}
                title={t('Reports.new_sale_activities')}
                subTitle={t('Reports.new_sale_activities_subtitle')}
            />

            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeDates
                    datesPrefix={{ start: t('start'), end: t('end') }}
                    dateRangeDropdownType={DateRangeDropdownEnum}
                    includeLocations
                    availableLocations={userPermissionsStore.availableLocationsForUser}
                    includeAdvancedFilters
                />

                <TableActionsButtons buttonsList={tableActionButtons} />

                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    dataSource={data}
                    rowKey={(record): string => `${record.date} ${record.type}`}
                    loading={loading}
                    pagination={false}
                />
            </Content>
        </div>
    );
});

export default NewSaleActivities;
