import React, { FunctionComponent } from 'react';
import './status-badge.less';

interface StatusBadgeProps {
    status: StatusBadgeStatus | null;
}

export enum StatusBadgeStatus {
    Active = 'Active',
}

const StatusBadge: FunctionComponent<StatusBadgeProps> = ({ status }) => {
    let badgeClass = '';
    switch (status) {
        case StatusBadgeStatus.Active:
            badgeClass = 'badge-success';
            break;
    }
    return <span className={`StatusBadge ${badgeClass}`}>{status}</span>;
};

export default StatusBadge;
