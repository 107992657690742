import { Button, Table } from 'antd';
import { ColumnType, TablePaginationConfig } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetMembershipMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetMembershipMessagesRequestDto';
import { GetMessagesSortColumnDto } from 'Api/Features/Messages/Dtos/GetMessagesSortColumnDto';
import CompanyHeader from 'Components/company-header';
import Icon from 'Components/icons/icon';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { TableFilters } from 'Components/table-filters';
import { TdWithImage } from 'Components/td-with-image';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MessageService } from 'Services/MessageService';
import { FilterStore } from 'Stores';
import { cleanVal } from 'Utils/NumberUtils';
import { displayFormatedDateTime } from 'Utils/TimeUtils';
import { CompanyContext } from '..';
import './index.less';
import SendMessageModal from '../../../contacts/id/messages/send-message-modal';
import { MessageReceiverTypeDto } from 'Api/Features/Messages/Dtos/MessageReceiverTypeDto';

const advancedFilters: AdvancedFilter[] = [];

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 20,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

interface ImageAndName {
    name?: string;
    imageUrl?: string | null;
}

const imageCellRender = (item: ImageAndName): ReactNode => {
    if (item.name) {
        return (
            <TdWithImage defaultImg={<Icon iconName="User" />} imgSrc={item?.imageUrl}>
                {item?.name}
            </TdWithImage>
        );
    } else {
        return '-';
    }
};

interface Messages {
    id: string;
    title: string;
    message: string;
    creator: ReactNode;
    date: string;
}

const CompanyMessages: FunctionComponent = () => {
    const messageService = useService(MessageService);
    const [messages, setMessages] = useState<Messages[]>([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const paginationRef = useRef(initialPaginationState);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { toastStore } = useStores();
    const companyContext = useContext(CompanyContext);
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const [sendMessageModalIsOpen, setSendMessageModalIsOpen] = useState(false);

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `companies`,
            nameKey: 'companies',
        },
        {
            path: `${id}/dashboard`,
            nameKey: companyContext?.name ?? '',
        },
        {
            path: 'messages',
            nameKey: 'messages',
        },
    ];

    const columns: ColumnType<Messages>[] = [
        {
            title: t('title'),
            dataIndex: 'title',
            key: GetMessagesSortColumnDto.Title,
            sorter: true,
        },
        {
            title: t('Contact.contacts_message_label'),
            dataIndex: 'message',
        },
        {
            title: t('Contact.contacts_message_creator'),
            dataIndex: 'creator',
            key: GetMessagesSortColumnDto.SenderName,
            sorter: true,
        },
        {
            title: t('Contact.contacts_message_date'),
            dataIndex: 'date',
            key: GetMessagesSortColumnDto.Date,
            sorter: true,
            className: 'dateCol',
        },
    ];

    const fetchMessages = useCallback(
        async (params: {
            searchTerm?: string;
            pagination: TablePaginationConfig;
            sortColumn: GetMessagesSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);

            try {
                const request: GetMembershipMessagesRequestDto = {
                    searchTerm: params.searchTerm,
                    pageSize: params.pagination.pageSize || 10,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                };

                const response = await messageService.getMembershipMessages(id, request);

                const responseMapping: Messages[] = response.items.map((message) => ({
                    id: cleanVal.string(message.id),
                    title: cleanVal.string(message.title),
                    message: cleanVal.string(message.body),
                    creator: imageCellRender({
                        name:
                            message.sender?.firstName && message.sender?.lastName
                                ? cleanVal.string(
                                      `${message?.sender?.firstName} ${message?.sender?.lastName}`
                                  )
                                : undefined,
                        imageUrl: message?.sender?.imageUrl,
                    }),
                    date: displayFormatedDateTime(message?.date),
                }));

                setMessages(responseMapping);
                setPagination({
                    ...params.pagination,
                    total: response?.totalItemCount,
                });
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [setLoading, messageService, t]
    );

    const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any): void => {
        const { searchTerm } = filterStoreRef.current;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        fetchMessages({
            searchTerm: searchTerm,
            pagination: pagination,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
    };

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;

            fetchMessages({
                searchTerm: filterStore.searchTerm,
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                sortColumn: GetMessagesSortColumnDto.Date,
                sortDirection: SortDirection.Descending,
            });
        });

        return (): void => {
            disposer();
        };
    }, [fetchMessages]);

    const headerData = {
        iconName: 'MessageBubble',
        title: t('messages'),
        subTitle: t('Membership.membership_message_section_subtitle'),
    };

    return (
        <div className="CompanyMessages">
            <CompanyHeader
                company={companyContext}
                routes={breadcrumbs}
                headerData={headerData}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            setSendMessageModalIsOpen(true);
                        }}
                    >
                        {t('Contact.contacts_messages_send_label')}
                    </Button>
                }
            />

            <Content>
                <TableFilters filterStore={filterStoreRef.current} includeSearch />

                <Table
                    className="table-striped-rows"
                    bordered
                    columns={columns}
                    rowKey={(row: Messages): string => row.id}
                    dataSource={messages}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </Content>

            {sendMessageModalIsOpen && companyContext && (
                <SendMessageModal
                    visible={sendMessageModalIsOpen}
                    onComplete={(success?: boolean): void => {
                        setSendMessageModalIsOpen(false);
                        if (success) {
                            const filterStore = filterStoreRef.current;
                            fetchMessages({
                                searchTerm: filterStore.searchTerm,
                                pagination: {
                                    ...paginationRef.current,
                                    current: 1,
                                },
                                sortColumn: GetMessagesSortColumnDto.Date,
                                sortDirection: SortDirection.Descending,
                            });
                        }
                    }}
                    receiverId={id}
                    messageReceiverType={MessageReceiverTypeDto.Membership}
                    name={companyContext.name ?? ''}
                />
            )}
        </div>
    );
};

export default CompanyMessages;
