import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import StatusBadge from 'Components/status-badge/status-badge';
import { RoutedBreadcrumb } from 'Components/routed-breadcrumb';
import { StatusBadgeStatus } from 'Components/status-badge/status-badge';

import './location-header.less';
import { CampusStatusTypeDto } from 'Api/Features/Campuses/Dtos/CampusStatusTypeDto';
import { ImageWithPlaceholderProps } from '../image-with-placeholder/image-with-placeholder';
import { ImageWithPlaceholder } from '../image-with-placeholder';
import { theme, images } from '../../variant';
import { Location } from '../icons';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { ActionMenu } from 'Components/action-menu';

const { Title, Paragraph } = Typography;

interface LocationHeaderProps extends ImageWithPlaceholderProps {
    title?: string;
    subTitle?: ReactNode | string;
    showStatusBadge?: boolean;
    routes?: BreadcrumbSegment[];
    action?: ReactNode;
    loading?: boolean;
    headerBadges?: ReactNode;
    menuOptions?: ActionMenuOption[];
}

const LocationHeader: FunctionComponent<LocationHeaderProps> = observer(
    ({
        title,
        subTitle,
        showStatusBadge = false,
        routes = [],
        action,
        loading,
        headerBadges,
        menuOptions,
        ...imageProps
    }) => {
        const { t } = useTranslation();
        const { id } = useParams<{ id: string }>();

        const { locationStore } = useStores();

        const locationData = locationStore.locations.find((location) => location.id === id);
        const statusBadge: CampusStatusTypeDto | null = locationData?.status || null;

        const locationRoutes: BreadcrumbSegment[] = [
            {
                path: 'locations',
                name: 'Locations',
            },
        ];

        if (locationData?.id) {
            locationRoutes.push(
                {
                    path: `${locationData?.id || ''}`,
                    name: `${locationData?.name || ''}`,
                },
                ...routes
            );
        }

        return (
            <div
                className="LocationHeader"
                style={{
                    background: `linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(${
                        locationData?.mainImageUrl || images.locationHeader
                    }`,
                }}
            >
                <Skeleton loading={loading} active avatar>
                    <div className="flex-container">
                        {imageProps.imgSrc || imageProps.defaultImg ? (
                            <ImageWithPlaceholder
                                className="header-icon"
                                defaultImg={
                                    imageProps.defaultImg || (
                                        <Location fill={theme['primary-color']} />
                                    )
                                }
                                {...imageProps}
                            />
                        ) : (
                            <img
                                className="location-avatar"
                                src={locationData?.mainImageUrl || images.locationLogo}
                                alt=""
                            />
                        )}
                        <div>
                            {locationRoutes.length > 1 && (
                                <RoutedBreadcrumb routes={locationRoutes} />
                            )}

                            <div className="location-avatar-info-container">
                                <Title className="text-primary location-avatar-info">
                                    {title ? title : locationData?.name}
                                </Title>
                                {showStatusBadge && (
                                    <div className="location-avatar-badge">
                                        <StatusBadge
                                            status={
                                                (statusBadge?.toString() as StatusBadgeStatus) ||
                                                null
                                            }
                                        />
                                    </div>
                                )}
                            </div>

                            {headerBadges && <div className="header-badges">{headerBadges}</div>}

                            <div>
                                <Paragraph>
                                    {subTitle ? subTitle : t('Location.header_explanation')}
                                </Paragraph>
                            </div>
                        </div>
                    </div>

                    {action && <div className="header-action">{action}</div>}

                    {menuOptions && menuOptions?.length > 0 && (
                        <ActionMenu options={menuOptions} type="primary" trigger="click" />
                    )}
                </Skeleton>
            </div>
        );
    }
);

export default LocationHeader;
