import { CampusSurroundingProxy } from 'Api/Features/CampusSurroundings/CampusSurroundingProxy';
import { CampusSurroundingDto } from 'Api/Features/CampusSurroundings/Dtos/CampusSurroundingDto';
import { CreateCampusSurroundingRequestDto } from 'Api/Features/CampusSurroundings/Dtos/CreateCampusSurroundingRequestDto';
import { GetCampusSurroundingsRequestDto } from 'Api/Features/CampusSurroundings/Dtos/GetCampusSurroundingsRequestDto';
import { GetCampusSurroundingsResponseDto } from 'Api/Features/CampusSurroundings/Dtos/GetCampusSurroundingsResponseDto';
import { UpdateCampusSurroundingRequestDto } from 'Api/Features/CampusSurroundings/Dtos/UpdateCampusSurroundingRequestDto';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(CampusSurroundingProxy)
export class CampusSurroundingService extends ApiService {
    constructor(private readonly campusSurroundingProxy: CampusSurroundingProxy) {
        super();
    }

    public async getCampusSurrounding(campusSurroundingId: string): Promise<CampusSurroundingDto | null> {
        const data: CampusSurroundingDto | null = await this.campusSurroundingProxy.getCampusSurrounding(campusSurroundingId);
        return data;
    }

    public async getCampusSurroundings(request: GetCampusSurroundingsRequestDto | null): Promise<[CampusSurroundingDto[], number]> {
        const data: GetCampusSurroundingsResponseDto | null = await this.campusSurroundingProxy.getCampusSurroundings(request);
        return [
            data?.items?.filter(item => item !== null).map(item => item!) || [],
            data?.totalItemCount || 0,
        ];
    }

    public async createCampusSurrounding(request: CreateCampusSurroundingRequestDto | null): Promise<string> {
        const data: string = await this.campusSurroundingProxy.createCampusSurrounding(request);
        return data;
    }

    public async updateCampusSurrounding(campusSurroundingId: string, request: UpdateCampusSurroundingRequestDto | null): Promise<void> {
        await this.campusSurroundingProxy.updateCampusSurrounding(campusSurroundingId, request);
    }

    public async deleteCampusSurrounding(campusSurroundingId: string): Promise<void> {
        await this.campusSurroundingProxy.deleteCampusSurrounding(campusSurroundingId);
    }
}
