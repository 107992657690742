import { LeadSourceDto } from 'Api/Features/Leads/Dtos/LeadSourceDto';

const lastKey = LeadSourceDto.Other;

export const LeadSource: any = {};

Object.keys(LeadSourceDto).sort((a: any, b: any) => a - b).forEach((key) => {
    if (key !== lastKey) LeadSource[key] = LeadSourceDto[key as LeadSourceDto];
});

LeadSource[lastKey] = lastKey;