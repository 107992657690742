// GENERATED FILE - DO NOT MODIFY

export enum GetOpportunitiesSortColumnDto {
    Date = "Date",
    Name = "Name",
    OwnerAccountName = "OwnerAccountName",
    MembershipName = "MembershipName",
    CampusName = "CampusName",
    Status = "Status",
    DateCreated = "DateCreated",
    Source = "Source",
    DeskCount = "DeskCount",
}