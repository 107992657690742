// GENERATED FILE - DO NOT MODIFY

export enum OpportunityTermLengthDto {
    MonthToMonth = "MonthToMonth",
    OneMonth = "OneMonth",
    TwoMonths = "TwoMonths",
    ThreeMonths = "ThreeMonths",
    FourMonths = "FourMonths",
    FiveMonths = "FiveMonths",
    SixMonths = "SixMonths",
    SevenMonths = "SevenMonths",
    EightMonths = "EightMonths",
    NineMonths = "NineMonths",
    TenMonths = "TenMonths",
    ElevenMonths = "ElevenMonths",
    TwelveMonths = "TwelveMonths",
    More = "More",
}