import { Form } from 'antd';
import { Store } from 'antd/es/form/interface';
import { LeadEventDto } from 'Api/Features/LeadEvents/Dtos/LeadEventDto';
import { LeadEventTypeDto } from 'Api/Features/LeadEvents/Dtos/LeadEventTypeDto';
import { CreateMeetingRequestDto } from 'Api/Features/Meetings/Dtos/CreateMeetingRequestDto';
import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';
import { OpportunityEventDto } from 'Api/Features/OpportunityEvents/Dtos/OpportunityEventDto';
import { OpportunityEventTypeDto } from 'Api/Features/OpportunityEvents/Dtos/OpportunityEventTypeDto';
import { CreateReminderRequestDto } from 'Api/Features/Reminders/Dtos/CreateReminderRequestDto';
import { SalesActivityDto } from 'Api/Features/SalesActivities/Dtos/SalesActivityDto';
import { SalesActivityTypeDto } from 'Api/Features/SalesActivities/Dtos/SalesActivityTypeDto';
import { CreateTaskRequestDto } from 'Api/Features/Tasks/Dtos/CreateTaskRequestDto';
import { CreateTourRequestDto } from 'Api/Features/Tours/Dtos/CreateTourRequestDto';
import { ActivityLogItem, ActivityLogUpcomingItem } from 'Components/activity-log/activity-log';
import ActivityLogWithTabs from 'Components/activity-log-with-tabs/activity-log-with-tabs';
import { useFormValidation, useService, useStores } from 'Hooks';
import { AddActivityType } from 'Models/AddActivityType/AddActivityType';
import {
    DATETIME_FORMAT,
    DISPLAY_DATETIME_12H_WRITTEN_FORMAT,
    DISPLAY_DATE_FORMAT,
    DISPLAY_TIME_12H_FORMAT,
    MOMENT_PARSING_FORMAT,
    MOMENT_TIME_FORMAT,
} from 'Models/Constants';
import { Opportunity } from 'Models/Opportunities/Opportunity';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CreateMeetingSchema, ScheduleTourSchema } from 'Schemas';
import { CreateCommentSchema } from 'Schemas/CreateCommentSchema';
import { CreateReminderSchema } from 'Schemas/CreateReminderSchema';
import { CreateTaskSchema } from 'Schemas/CreateTaskSchema';
import { MeetingService } from 'Services/MeetingService';
import { OpportunityCommentService } from 'Services/OpportunityCommentService';
import { ReminderService } from 'Services/ReminderService';
import { TaskService } from 'Services/TaskService';
import { TourService } from 'Services/TourService';
import { displayFormatedDateTime } from 'Utils/TimeUtils';
import { StoredFileDto } from 'Api/Features/General/Dtos/StoredFileDto';
import { CreateOpportunityCommentRequestDto } from 'Api/Features/Opportunities/Dtos/CreateOpportunityCommentRequestDto';
import { ActivityLogTab } from 'Components/activity-log-tabs/activity-log-tabs';

interface OpportunityActivityLogProps {
    events?: (OpportunityEventDto | LeadEventDto)[];
    opportunity?: Opportunity;
    onAddedActivityComplete: () => void;
    upcomingActivities?: (SalesActivityDto | null)[];
    pastActivities?: (SalesActivityDto | null)[];
    onEditMeetingClick: (id: string) => void;
    onEditReminderClick: (id: string) => void;
    onEditTourClick: (id: string) => void;
    onEditTaskClick: (id: string) => void;
    onDeleteMeetingClick: (id: string) => void;
    onDeleteReminderClick: (id: string) => void;
    onDeleteTourClick: (id: string) => void;
    onDeleteTaskClick: (id: string) => void;
    onAddFileClick: () => void;
    files?: StoredFileDto[];
    onFileClick?: (url?: string) => void;
    onFileDeleteClick?: (id?: string) => void;
    handleFileListScrolledToBottom?: () => void;
    filesAreLoading?: boolean;
    note?: string | null;
    onEditNoteClick?: () => void;
}

const OpportunityActivityLog: FunctionComponent<OpportunityActivityLogProps> = ({
    events,
    opportunity,
    onAddedActivityComplete,
    upcomingActivities,
    pastActivities,
    onEditMeetingClick,
    onEditReminderClick,
    onEditTourClick,
    onEditTaskClick,
    onDeleteMeetingClick,
    onDeleteReminderClick,
    onDeleteTourClick,
    onDeleteTaskClick,
    onAddFileClick,
    files,
    onFileClick,
    onFileDeleteClick,
    handleFileListScrolledToBottom,
    filesAreLoading,
    note,
    onEditNoteClick,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [meetingErrors, validateMeetingForm, resetMeetingErrors] = useFormValidation(
        CreateMeetingSchema, form
    );
    const [reminderErrors, validateReminderForm, resetReminderErrors] = useFormValidation(
        CreateReminderSchema, form
    );
    const [taskErrors, validateTaskForm, resetTaskErrors] = useFormValidation(CreateTaskSchema, form);
    const [commentErrors, validateCommentForm, resetCommentErrors] = useFormValidation(
        CreateCommentSchema, form
    );
    const [tourErrors, validateTourForm, resetTourErrors] = useFormValidation(ScheduleTourSchema, form);
    const { globalLoadingStore, toastStore } = useStores();
    const [selectedAddActivityType, setSelectedAddActivityType] = useState<AddActivityType>(
        AddActivityType.Comment
    );
    const meetingService = useService(MeetingService);
    const reminderService = useService(ReminderService);
    const taskService = useService(TaskService);
    const commentService = useService(OpportunityCommentService);
    const tourService = useService(TourService);
    const { locationStore } = useStores();
    const { id } = useParams<{ id: string }>();

    //#Region event handlers
    const onAddActivityTypeChange = (value: AddActivityType): void => {
        setSelectedAddActivityType(value);
        resetMeetingErrors();
        resetReminderErrors();
        resetTaskErrors();
        resetCommentErrors();
        resetTourErrors();
    };
    //#EndRegion event handlers

    //#Region Submit
    const submit = async (values: Store): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            let activityType = '';
            if (selectedAddActivityType === AddActivityType.Comment) {
                activityType = 'comment';
                const validateObject = {
                    note: values.note,
                };
                if (!(await validateCommentForm(validateObject))) return;
                await commentService.createComment({comment: values.note} as CreateOpportunityCommentRequestDto, id);
            } else if (selectedAddActivityType === AddActivityType.Meeting) {
                const validateMeeting = {
                    date: values.date ?? undefined,
                    hours: values.hours ?? undefined,
                };

                if (!(await validateMeetingForm(validateMeeting))) return;
                const meeting: CreateMeetingRequestDto = {
                    startTime: moment(
                        values.date.format(MOMENT_PARSING_FORMAT) +
                            values.hours[0].format(MOMENT_TIME_FORMAT),
                        DATETIME_FORMAT
                    ).format(),
                    endTime: moment(
                        values.date.format(MOMENT_PARSING_FORMAT) +
                            values.hours[1].format(MOMENT_TIME_FORMAT),
                        DATETIME_FORMAT
                    ).format(),
                    opportunityId: id,
                    note: values.note,
                };
                activityType = 'meeting';
                await meetingService.createMeeting(meeting);
            } else if (selectedAddActivityType === AddActivityType.Reminder) {
                const validateReminder = {
                    date: values.date ?? undefined,
                };

                if (!(await validateReminderForm(validateReminder))) return;

                const reminder: CreateReminderRequestDto = {
                    date: values.date,
                    opportunityId: id,
                    note: values.note,
                };
                activityType = 'reminder';
                await reminderService.createReminder(reminder);
            } else if (selectedAddActivityType === AddActivityType.Task) {
                const validateTask = { date: values.date ?? undefined, note: values.note };
                if (!(await validateTaskForm(validateTask))) return;

                const task: CreateTaskRequestDto = {
                    date: values.date,
                    opportunityId: id,
                    note: values.note,
                };
                activityType = 'task';
                await taskService.createTask(task);
            } else if (selectedAddActivityType === AddActivityType.Tour) {
                const data = {
                    date: values.date ?? undefined,
                    hours: values.hours ?? undefined,
                    note: values.note,
                    opportunityId: id,
                    campusId: values.campusId,
                };
                const validateTour = data;
                if (!(await validateTourForm(validateTour))) return;

                const tour: CreateTourRequestDto = {
                    ...data,
                    startTime: moment(
                        values.date.format(MOMENT_PARSING_FORMAT) +
                            values.hours[0].format(MOMENT_TIME_FORMAT),
                        DATETIME_FORMAT
                    ).format(),
                    endTime: moment(
                        values.date.format(MOMENT_PARSING_FORMAT) +
                            values.hours[1].format(MOMENT_TIME_FORMAT),
                        DATETIME_FORMAT
                    ).format(),
                };
                activityType = 'tour';
                await tourService.createTour(tour);
            }

            onAddedActivityComplete();
            resetMeetingErrors();
            resetReminderErrors();
            resetTaskErrors();
            resetCommentErrors();
            resetTourErrors();
            setSelectedAddActivityType(AddActivityType.Comment);
            form.resetFields();

            toastStore.toast({
                type: 'success',
                messageKey: `Lead.${activityType}_added_success`,
            });
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#End Region Submit

    const submitButtonLabel = (selectedAddActivityType: AddActivityType): string => {
        switch (selectedAddActivityType) {
            case AddActivityType.Comment:
                return t('Lead.add_comment');
            case AddActivityType.Meeting:
                return t('Lead.add_meeting');
            case AddActivityType.Task:
                return t('Lead.add_task');
            case AddActivityType.Reminder:
                return t('Lead.add_reminder');
            case AddActivityType.Tour:
                return t('Lead.add_tour');
        }
    };

    const displayDatePicker =
        selectedAddActivityType === AddActivityType.Meeting ||
        selectedAddActivityType === AddActivityType.Reminder ||
        selectedAddActivityType === AddActivityType.Task ||
        selectedAddActivityType === AddActivityType.Tour;

    const getUpcomingActivities = (
        upcomingActivities: (SalesActivityDto | null)[] | undefined
    ): (ActivityLogUpcomingItem | null)[] => {
        let upcomingActivitiesList: (ActivityLogUpcomingItem | null)[] = [];

        if (upcomingActivities) {
            upcomingActivitiesList = upcomingActivities?.map((event: SalesActivityDto | null) => {
                let eventInfo = null;

                if (event) {
                    switch (event.type) {
                        case SalesActivityTypeDto.Meeting:
                            eventInfo = {
                                icon: { name: 'Coffee', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Opportunity.opportunity_event_${LeadEventTypeDto.MeetingScheduled}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.meeting?.startTime)
                                            .format(
                                                DISPLAY_DATETIME_12H_WRITTEN_FORMAT
                                            )} - ${moment
                                            .utc(event.meeting?.endTime)
                                            .format(DISPLAY_TIME_12H_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditMeetingClick,
                                onDelete: onDeleteMeetingClick,
                                id: event.meeting?.id ?? '',
                                note: event.meeting?.note ?? undefined,
                            };
                            break;
                        case SalesActivityTypeDto.Reminder:
                            eventInfo = {
                                icon: { name: 'Bell', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Opportunity.opportunity_event_${LeadEventTypeDto.ReminderScheduled}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.reminder?.date)
                                            .format(DISPLAY_DATETIME_12H_WRITTEN_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditReminderClick,
                                onDelete: onDeleteReminderClick,
                                id: event.reminder?.id ?? '',
                                note: event.reminder?.note ?? undefined,
                            };
                            break;
                        case SalesActivityTypeDto.Tour:
                            eventInfo = {
                                icon: { name: 'Location', className: 'primary-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Opportunity.opportunity_event_${OpportunityEventTypeDto.TourScheduled}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: event.tour?.campus?.name
                                            ? t('Activity.tour_at', {
                                                  param1: event.tour?.campus?.name,
                                              })
                                            : '',
                                    },
                                    {
                                        text: t('Opportunity.tour_for', {
                                            param1: moment.utc(event.tour?.startTime).format(
                                                DISPLAY_DATETIME_12H_WRITTEN_FORMAT
                                            ),
                                            param2: moment.utc(event.tour?.endTime).format(
                                                DISPLAY_TIME_12H_FORMAT
                                            ),
                                        }),
                                    },
                                ],
                                onEdit: onEditTourClick,
                                onDelete: onDeleteTourClick,
                                id: event.tour?.id ?? '',
                                note: event.tour?.note ?? undefined,
                            };
                            break;
                        case SalesActivityTypeDto.Task:
                            eventInfo = {
                                icon: { name: 'Tick', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(`Lead.event_${LeadEventTypeDto.TaskAdded}`)}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.task?.date)
                                            .format(DISPLAY_DATE_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditTaskClick,
                                onDelete: onDeleteTaskClick,
                                id: event.task?.id ?? '',
                                note: event.task?.note,
                            };
                            break;
                    }
                }

                return eventInfo;
            });
        }

        return upcomingActivitiesList;
    };

    const getPastActivities = (
        pastActivities: (SalesActivityDto | null)[] | undefined
    ): (ActivityLogUpcomingItem | null)[] => {
        let pastActivitiesList: (ActivityLogUpcomingItem | null)[] = [];

        if (pastActivities) {
            pastActivitiesList = pastActivities?.map((event: SalesActivityDto | null) => {
                let eventInfo = null;

                if (event) {
                    switch (event.type) {
                        case SalesActivityTypeDto.Meeting:
                            eventInfo = {
                                icon: { name: 'Coffee', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Opportunity.opportunity_event_${LeadEventTypeDto.MeetingScheduled}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.meeting?.startTime)
                                            .format(
                                                DISPLAY_DATETIME_12H_WRITTEN_FORMAT
                                            )} - ${moment
                                            .utc(event.meeting?.endTime)
                                            .format(DISPLAY_TIME_12H_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditMeetingClick,
                                onDelete: onDeleteMeetingClick,
                                id: event.meeting?.id ?? '',
                                note: event.meeting?.note ?? undefined,
                            };
                            break;
                        case SalesActivityTypeDto.Reminder:
                            eventInfo = {
                                icon: { name: 'Bell', className: 'black-2-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Opportunity.opportunity_event_${LeadEventTypeDto.ReminderScheduled}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.reminder?.date)
                                            .format(DISPLAY_DATETIME_12H_WRITTEN_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditReminderClick,
                                onDelete: onDeleteReminderClick,
                                id: event.reminder?.id ?? '',
                                note: event.reminder?.note ?? undefined,
                            };
                            break;
                        case SalesActivityTypeDto.Tour:
                            eventInfo = {
                                icon: { name: 'Location', className: 'primary-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Opportunity.opportunity_event_${OpportunityEventTypeDto.TourScheduled}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: event.tour?.campus?.name
                                            ? t('Activity.tour_at', {
                                                  param1: event.tour?.campus?.name,
                                              })
                                            : '',
                                    },
                                    {
                                        text: t('Opportunity.tour_for', {
                                            param1: moment.utc(event.tour?.startTime).format(
                                                DISPLAY_DATETIME_12H_WRITTEN_FORMAT
                                            ),
                                            param2: moment.utc(event.tour?.endTime).format(
                                                DISPLAY_TIME_12H_FORMAT
                                            ),
                                        }),
                                    },
                                ],
                                onEdit: onEditTourClick,
                                onDelete: onDeleteTourClick,
                                id: event.tour?.id ?? '',
                                note: event.tour?.note ?? undefined,
                            };
                            break;
                        case SalesActivityTypeDto.Task:
                            eventInfo = {
                                icon: { name: 'Tick', className: 'primary-fill' },
                                title: [
                                    {
                                        text: `${t(
                                            `Opportunity.opportunity_event_${OpportunityEventTypeDto.TaskAdded}`
                                        )}`,
                                        bold: true,
                                    },
                                    {
                                        text: ` ${t(`Activity.add_activity_on`)} ${moment
                                            .utc(event.task?.date)
                                            .format(DISPLAY_DATE_FORMAT)}`,
                                    },
                                ],
                                onEdit: onEditTaskClick,
                                onDelete: onDeleteTaskClick,
                                id: event.task?.id ?? '',
                                note: event.task?.note ?? undefined,
                            };
                            break;
                    }
                }

                return eventInfo;
            });
        }

        return pastActivitiesList;
    };

    const getHistoryList = (
        history: (OpportunityEventDto | LeadEventDto)[] | undefined
    ): (ActivityLogItem | null)[] => {
        let activitiesList: (ActivityLogItem | null)[] = [];

        if (history) {
            activitiesList = history.map((event: OpportunityEventDto | LeadEventDto) => {
                let eventInfo = null;

                if (event && event.id) {
                    const isLead = (event as LeadEventDto).leadId !== undefined;
                    const creator =
                        event.userCreated === null || event.userCreated?.id === null
                            ? 'Unknown'
                            : `${event.userCreated?.firstName} ${event.userCreated?.lastName}`;
                    const onBehalfOfAccount = (event as OpportunityEventDto).onBehalfOfAccount;
                    const behalf = onBehalfOfAccount
                        ? ` ${t('Opportunity.opportunity_proposal_on_behalf', {
                              param1: `${onBehalfOfAccount.firstName} ${onBehalfOfAccount.lastName}`,
                          })}`
                        : null;
                    let statusString = '';

                    eventInfo = {
                        id: event.id,
                        icon: { name: '', bold: undefined },
                        title: [
                            { text: creator, bold: true },
                            { text: t(`Opportunity.opportunity_event_text_spacer_${event.type}`) },
                            { text: t(`Opportunity.opportunity_event_${event.type}`), bold: true },
                        ],
                        date: displayFormatedDateTime(event.date),
                        note: event.note,
                        creator: creator,
                    };
                    switch (event.type) {
                        case OpportunityEventTypeDto.Created:
                            {
                                const isAutomaticRenewal =
                                    opportunity?.isSubscriptionRenewal &&
                                    (event.userCreated === null || event.userCreated === undefined);
                                eventInfo = {
                                    ...eventInfo,
                                    icon: { name: isLead ? 'Lead' : 'Plan', primary: true },
                                    title: isLead
                                        ? [
                                              { text: creator, bold: true },
                                              {
                                                  text: t(`Lead.event_text_spacer_${event.type}`),
                                              },
                                              {
                                                  text: t(`Lead.event_${event.type}`),
                                                  bold: true,
                                              },
                                          ]
                                        : opportunity?.leadId !== null
                                        ? [
                                              { text: creator, bold: true },
                                              {
                                                  text: t(
                                                      `Opportunity.opportunity_event_text_spacer_Converted`
                                                  ),
                                              },
                                              {
                                                  text: t(
                                                      `Opportunity.opportunity_event_Converted`
                                                  ),
                                                  bold: true,
                                              },
                                          ]
                                        : opportunity.isSubscriptionRenewal && !isAutomaticRenewal
                                        ? [
                                              {
                                                  text: creator,
                                                  bold: true,
                                              },
                                              {
                                                  text: t(
                                                      `Opportunity.opportunity_event_text_spacer_${event.type}`
                                                  ),
                                              },
                                              {
                                                  text: t(
                                                      `Opportunity.opportunity_event_${event.type}`
                                                  ),
                                                  bold: true,
                                              },
                                              {
                                                  text: ' for ',
                                              },
                                              {
                                                  text: t('renewal'),
                                                  bold: true,
                                              },
                                          ]
                                        : opportunity.isSubscriptionRenewal && isAutomaticRenewal
                                        ? [
                                              {
                                                  text: t('opportunity'),
                                                  bold: true,
                                              },
                                              {
                                                  text: t(
                                                      `Opportunity.opportunity_has_been_created_for`
                                                  ),
                                              },
                                              {
                                                  text: t('renewal'),
                                                  bold: true,
                                              },
                                          ]
                                        : opportunity.isSubscriptionModification
                                        ? [
                                              {
                                                  text: creator,
                                                  bold: true,
                                              },
                                              {
                                                  text: t(
                                                      `Opportunity.opportunity_event_text_spacer_${event.type}`
                                                  ),
                                              },
                                              {
                                                  text: t(
                                                      `Opportunity.opportunity_event_${event.type}`
                                                  ),
                                                  bold: true,
                                              },
                                              {
                                                  text: ' for ',
                                              },
                                              {
                                                  text: t('modification'),
                                                  bold: true,
                                              },
                                          ]
                                        : [
                                              { text: creator, bold: true },
                                              {
                                                  text: t(
                                                      `Opportunity.opportunity_event_text_spacer_${event.type}`
                                                  ),
                                              },
                                              {
                                                  text: t(
                                                      `Opportunity.opportunity_event_${event.type}`
                                                  ),
                                                  bold: true,
                                              },
                                          ],
                                };
                            }
                            break;
                        case OpportunityEventTypeDto.StatusUpdated:
                            statusString =
                                event.newStatus &&
                                Object.values(OpportunityStatusDto).includes(event.newStatus)
                                    ? t(`Opportunity.opportunity_status_${event.newStatus}`)
                                    : t(`Lead.status_${event.newStatus}`);

                            eventInfo = {
                                ...eventInfo,
                                icon: {
                                    name:
                                        event.newStatus &&
                                        event.newStatus === OpportunityStatusDto.Lost
                                            ? 'WarningLined'
                                            : 'Refresh',
                                    primary: true,
                                },
                                title: [
                                    { text: creator, bold: true },
                                    { text: t(`Opportunity.opportunity_event_${event.type}`) },
                                    {
                                        text: t(statusString),
                                        bold: true,
                                    },
                                ],
                            };
                            break;
                        case OpportunityEventTypeDto.OwnerUpdated:
                            eventInfo = {
                                ...eventInfo,
                                icon: { name: 'Owner' },
                                title: [
                                    { text: creator, bold: true },
                                    { text: t(`Opportunity.opportunity_event_${event.type}`) },
                                    {
                                        text:
                                            event.newOwnerAccount &&
                                            `${event.newOwnerAccount.firstName} ${event.newOwnerAccount.lastName}`,
                                    },
                                ],
                            };
                            break;
                        case OpportunityEventTypeDto.CommentAdded:
                            eventInfo = {
                                ...eventInfo,
                                icon: { name: 'File' },
                            };
                            break;
                        case OpportunityEventTypeDto.TaskAdded:
                        case OpportunityEventTypeDto.TaskCancelled:
                        case OpportunityEventTypeDto.TaskUpdated:
                            eventInfo = {
                                ...eventInfo,
                                icon: { name: 'Tick' },
                                note: event.task,
                                title: [
                                    { text: creator, bold: true },
                                    {
                                        text: t(
                                            `Opportunity.opportunity_event_text_spacer_${event.type}`
                                        ),
                                    },
                                    {
                                        text: t(`Opportunity.opportunity_event_${event.type}`),
                                        bold: true,
                                    },
                                    {
                                        text: `${t('Activity.add_activity_for')} ${moment
                                            .utc(event.taskDate)
                                            .format(DISPLAY_DATE_FORMAT)}`,
                                    },
                                ],
                            };
                            break;
                        case OpportunityEventTypeDto.TourScheduled:
                        case OpportunityEventTypeDto.TourUpdated:
                        case OpportunityEventTypeDto.TourCancelled:
                            eventInfo = {
                                ...eventInfo,
                                icon: { name: 'Location', primary: true },
                                title: [
                                    { text: creator, bold: true },
                                    {
                                        text: t(
                                            `Opportunity.opportunity_event_text_spacer_${event.type}`
                                        ),
                                    },
                                    {
                                        text: t(`Opportunity.opportunity_event_${event.type}`),
                                        bold: true,
                                    },
                                    {
                                        text: locationStore.locations.find(
                                            (location) => location.id === event.tourCampusId
                                        )
                                            ? t('Activity.tour_at', {
                                                  param1: locationStore.locations.find(
                                                      (location) =>
                                                          location.id === event.tourCampusId
                                                  )?.name,
                                              })
                                            : '',
                                    },
                                    {
                                        text: t('Opportunity.tour_for', {
                                            param1: moment.utc(event.tourStartDate).format(
                                                DISPLAY_DATETIME_12H_WRITTEN_FORMAT
                                            ),
                                            param2: moment.utc(event.tourEndDate).format(
                                                DISPLAY_TIME_12H_FORMAT
                                            ),
                                        }),
                                    },
                                ],
                            };
                            break;
                        case OpportunityEventTypeDto.MeetingScheduled:
                        case OpportunityEventTypeDto.MeetingUpdated:
                        case OpportunityEventTypeDto.MeetingCancelled:
                            eventInfo = {
                                ...eventInfo,
                                icon: { name: 'Coffee' },
                                title: [
                                    { text: creator, bold: true },
                                    {
                                        text: t(
                                            `Opportunity.opportunity_event_text_spacer_${event.type}`
                                        ),
                                    },
                                    {
                                        text: t(`Opportunity.opportunity_event_${event.type}`),
                                        bold: true,
                                    },
                                    {
                                        text: t('Opportunity.tour_for', {
                                            param1: moment
                                                .utc(event.meetingStartDate)
                                                .format(DISPLAY_DATETIME_12H_WRITTEN_FORMAT),
                                            param2: moment
                                                .utc(event.meetingEndDate)
                                                .format(DISPLAY_TIME_12H_FORMAT),
                                        }),
                                    },
                                ],
                            };
                            break;
                        case OpportunityEventTypeDto.ReminderScheduled:
                        case OpportunityEventTypeDto.ReminderUpdated:
                        case OpportunityEventTypeDto.ReminderCancelled:
                            eventInfo = {
                                ...eventInfo,
                                icon: { name: 'Bell' },
                                title: [
                                    { text: creator, bold: true },
                                    {
                                        text: t(
                                            `Opportunity.opportunity_event_text_spacer_${event.type}`
                                        ),
                                    },
                                    {
                                        text: t(`Opportunity.opportunity_event_${event.type}`),
                                        bold: true,
                                    },
                                    {
                                        text: `${t('Activity.add_activity_for')} ${moment
                                            .utc(event.reminderDate)
                                            .format(DISPLAY_DATETIME_12H_WRITTEN_FORMAT)}`,
                                    },
                                ],
                            };
                            break;
                        case OpportunityEventTypeDto.ProposalCreated:
                            eventInfo = {
                                ...eventInfo,
                                title: [
                                    { text: creator, bold: true },
                                    { text: t(`Opportunity.opportunity_proposal_sent_label`) },
                                    {
                                        text: t(`Opportunity.opportunity_proposal_label`),
                                        bold: true,
                                    },
                                ],
                                icon: { name: 'QuestionMarkLined', primary: true },
                            };
                            break;
                        case OpportunityEventTypeDto.ProposalAccepted:
                            eventInfo = {
                                ...eventInfo,
                                title: [
                                    { text: creator, bold: true },
                                    { text: t(`Opportunity.opportunity_proposal_accepted_label`) },
                                    {
                                        text: t(`Opportunity.opportunity_proposal_label`),
                                        bold: true,
                                    },
                                    {
                                        text: behalf,
                                    },
                                ],
                                icon: { name: 'TickLined', primary: true },
                            };
                            break;
                        case OpportunityEventTypeDto.ProposalRefused:
                            eventInfo = {
                                ...eventInfo,
                                title: [
                                    { text: creator, bold: true },
                                    { text: t(`Opportunity.opportunity_proposal_refused_label`) },
                                    {
                                        text: t(`Opportunity.opportunity_proposal_label`),
                                        bold: true,
                                    },
                                    {
                                        text: behalf,
                                    },
                                ],
                                icon: { name: 'CloseLined', primary: true },
                            };
                            break;
                        case OpportunityEventTypeDto.AgreementAdded:
                            eventInfo = {
                                ...eventInfo,
                                title: [
                                    { text: creator, bold: true },
                                    {
                                        text: t(
                                            `Opportunity.opportunity_agreement_status_${event.type}`
                                        ),
                                    },
                                    {
                                        text: t(`Opportunity.opportunity_agreement_${event.type}`),
                                        bold: true,
                                    },
                                ],
                                icon: { name: 'HammerLined', primary: true },
                            };
                            break;
                        case OpportunityEventTypeDto.AgreementSigned:
                            eventInfo = {
                                ...eventInfo,
                                title: [
                                    { text: creator, bold: true },
                                    {
                                        text: t(
                                            `Opportunity.opportunity_agreement_status_${event.type}`
                                        ),
                                    },
                                    {
                                        text: t(`Opportunity.opportunity_agreement_${event.type}`),
                                        bold: true,
                                    },
                                ],
                                icon: { name: 'PencilLined', primary: true },
                            };
                            break;
                        case LeadEventTypeDto.LabelUpdated:
                            eventInfo = {
                                ...eventInfo,
                                icon: { name: 'Thermometer' },
                                title: [
                                    { text: creator, bold: true },
                                    { text: t(`Opportunity.opportunity_event_${event.type}`) },
                                    { text: event.newLabel && t(`Lead.label_${event.newLabel}`) },
                                ],
                            };
                            break;
                    }
                }

                return eventInfo;
            });
        }

        return activitiesList;
    };

    return (
        <ActivityLogWithTabs
            onFinish={submit}
            form={form}
            selectErrors={meetingErrors}
            selectdefaultValue={AddActivityType.Comment}
            selectOnChange={onAddActivityTypeChange}
            selectOptions={AddActivityType}
            displayDatePicker={displayDatePicker}
            datePickerErrors={
                selectedAddActivityType === AddActivityType.Meeting
                    ? meetingErrors
                    : selectedAddActivityType === AddActivityType.Reminder
                    ? reminderErrors
                    : taskErrors
            }
            noteErrors={
                selectedAddActivityType === AddActivityType.Task ? taskErrors : commentErrors
            }
            submitButtonLabel={submitButtonLabel(selectedAddActivityType)}
            upcomingActivities={getUpcomingActivities(upcomingActivities)}
            pastActivities={getPastActivities(pastActivities)}
            historyList={getHistoryList(events)}
            categoryName={t('opportunity')}
            locationErrors={tourErrors}
            defaultSelectedLocationId={opportunity?.campus?.id ?? undefined}
            onAddFileClick={onAddFileClick}
            files={files}
            onFileClick={onFileClick}
            onFileDeleteClick={onFileDeleteClick}
            onFilesScrolledToBottom={handleFileListScrolledToBottom}
            filesAreLoading={filesAreLoading}
            onEditNoteClick={onEditNoteClick}
            tabs={[ActivityLogTab.activities, ActivityLogTab.files, ActivityLogTab.notes]}
            note={note}
        />
    );
};

export default OpportunityActivityLog;
