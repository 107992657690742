import { Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Icon from 'Components/icons/icon';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ListSectionHeader } from '../../../Components/list-section-header';
import { images, theme } from '../../../variant';
import ContactsTable from './contacts-table';
import './index.less';
import CreateContact from './modals/create-contact';
import CreateOpportunityFromContactModal from '../opportunities/create-opportunity-for-contact-modal';

const { usersHeader } = images;

const GlobalContacts: FunctionComponent = () => {
    const [createContactModalIsVisible, setCreateContactModalIsVisible] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();

    const [createOpportunityState, setCreateOpportunityState] = useState<{
        visible: boolean;
        contactId?: string;
        contactName?: string;
        membershipId?: string;
        membershipName?: string;
    }>({ visible: false });

    const ActionButton = (
        <Button
            type="primary"
            onClick={(): void => {
                setCreateContactModalIsVisible(true);
            }}
        >
            {t('User.contacts_create_contact')}
        </Button>
    );

    const onCompleteCreateContact = (success: boolean, id?: string): void => {
        setCreateContactModalIsVisible(false);
        if (success && id) history.push(`contacts/${id}/dashboard`);
    };

    return (
        <div className="GlobalContacts">
            <ListSectionHeader
                title={t('User.contacts_label')}
                subTitle={t('User.contacts_title')}
                defaultImg={<Icon iconName="User" fill={theme['primary-color']} />}
                backgroundImageUrl={usersHeader}
                action={ActionButton}
            />

            <Content>
                <ContactsTable globalList />
            </Content>

            {createContactModalIsVisible && (
                <CreateContact
                    visible={createContactModalIsVisible}
                    onComplete={onCompleteCreateContact}
                    onCreateOpportunity={(contactId, contactName, membershipId, membershipName) =>
                        setCreateOpportunityState({
                            visible: true,
                            contactId,
                            contactName,
                            membershipId,
                            membershipName,
                        })
                    }
                />
            )}

            {createOpportunityState.visible && (
                <CreateOpportunityFromContactModal
                    visible={createOpportunityState.visible}
                    onComplete={(success: boolean, id?: string) => {
                        if (success && id) history.push(`opportunities/${id}/dashboard`);
                        setCreateOpportunityState({ visible: false });
                    }}
                    contactId={createOpportunityState.contactId}
                    contactName={createOpportunityState.contactName}
                    membershipId={createOpportunityState.membershipId}
                    membershipName={createOpportunityState.membershipName}
                />
            )}
        </div>
    );
};

export default GlobalContacts;
