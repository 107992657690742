import * as yup from 'yup';

export const ChangeLeadStatusSchema = yup.object({
    campusId: yup.string().required('Errors.required'),
});

export const touchBaseStatusSchema = yup.object({
    meetingDate: yup.date().required('Errors.required'),
    hours: yup.string().required('Time is required'),
});

export const MoveToEngagedSchema = yup.object().shape({
    email: yup.string().email().required('Errors.required'),
    numberOfEmployees: yup.number().typeError('Errors.number_invalid'),
});
