// GENERATED FILE - DO NOT MODIFY
import { GetLeadAssignationRulesResponseDto } from 'Api/Features/LeadAssignationRules/Dtos/GetLeadAssignationRulesResponseDto';
import { UpdateLeadAssignationRulesRequestDto } from 'Api/Features/LeadAssignationRules/Dtos/UpdateLeadAssignationRulesRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class LeadAssignationRuleProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getLeadAssignationRules(): Promise<GetLeadAssignationRulesResponseDto | null> {
        const uri = this.buildUri(
            "/lead-assignation-rules",
            null,
            null
        );

        const data: GetLeadAssignationRulesResponseDto | null = await this.httpClient.get<GetLeadAssignationRulesResponseDto | null>(uri);
        return data;
    }

    public async updateLeadAssignationRule(request: UpdateLeadAssignationRulesRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/lead-assignation-rules",
            null,
            null
        );

        await this.httpClient.put<UpdateLeadAssignationRulesRequestDto | null, void>(uri, request);
    }
}