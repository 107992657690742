import React, { FunctionComponent, useCallback, useContext, useState } from 'react';
import { Form } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { StoredFileDto } from 'Api/Features/General/Dtos/StoredFileDto';
import { GetMembershipFilesRequestDto } from 'Api/Features/Memberships/Dtos/GetMembershipFilesRequestDto';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import ActivityLogTabs, { ActivityLogTab } from 'Components/activity-log-tabs/activity-log-tabs';
import CompanyHeader, { CompanyTabs } from 'Components/company-header';
import Icon from 'Components/icons/icon';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { MembershipFileService } from 'Services/MembershipFileService';
import { MembershipService } from 'Services/MembershipService';
import { CompanyContext } from '..';
import AddFileModal, { AddFileFormModel } from 'Components/add-file-modal/index';
import EditNoteModal, { NoteEntityTypeEnum } from 'Components/edit-note-modal/edit-note-modal';
import { CreateMembershipFileRequestDto } from 'Api/Features/Memberships/Dtos/CreateMembershipFileRequestDto';
import { useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';
import { useService, useStores } from 'Hooks';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import GiveDayPassModal, { DayPassTransactionRecipientType } from 'Components/give-day-pass-modal/index';
import CreditTransactionModal, { CreditTransactionRecipientType } from 'Routes/authenticated/contacts/modals/credit-transaction-modal';
import EditCompany from '../../modals/edit-company';
import CompanyDetailsBody from './company-details-body';
import CreateManualInvoiceModal from 'Routes/authenticated/contacts/id/invoices/modals/create-manual-invoice-modal';
import { PaymentMethodHolderTypeDto } from 'Api/Features/PaymentMethods/Dtos/PaymentMethodHolderTypeDto';
import SendMessageModal from '../../../contacts/id/messages/send-message-modal';
import InviteMemberModal, { InviteMemberContext } from 'Routes/authenticated/locations/id/subscriptions/id/modals/invite-members';
import CreateOpportunityModal from 'Routes/authenticated/opportunities/create-opportunity';
import { observer } from 'mobx-react';
import { MessageReceiverTypeDto } from 'Api/Features/Messages/Dtos/MessageReceiverTypeDto';

const CompanyDashboard: FunctionComponent = observer(() => {
    const companyContext = useContext(CompanyContext);
    const { t } = useTranslation();
    const history = useHistory();
    const { globalLoadingStore, confirmationModalStore, toastStore, userPermissionsStore } = useStores();
    const membershipFileService = useService(MembershipFileService);
    const membershipService = useService(MembershipService);
    const [form] = Form.useForm();
    const { id } = useParams<{ id: string }>()

    const [addFileModalOpen, setAddFileModalOpen] = useState(false);
    const [files, setFiles] = useState<StoredFileDto[]>([]);
    const [filesCurrentPage, setFilesCurrentPage] = useState(0);
    const [filesMaxResults, setFilesMaxResults] = useState(false);
    const filesPageSize = 5;
    const [filesAreLoading, setFilesAreLoading] = useState(false);

    const [editNoteModalOpen, setEditNoteModalOpen] = useState(false);
    const [membershipNote, setMembershipNote] = useState<string | null | undefined>();

    const [giveDayPassModalOpen, setGiveDayPassModalOpen] = useState(false);
    const [creditTransactionModalOpen, setCreditTransactionModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [createManualInvoiceModalOpen, setCreateManualInvoiceModalOpen] = useState(false);
    const [sendMessageModalIsOpen, setSendMessageModalIsOpen] = useState(false);
    const [inviteMemberModalOpen ,setInviteMemberModalOpen] = useState(false);
    const [createOpportunityModalOpen, setCreateOpportunityModalOpen] = useState(false);
    
    const deleteCompany = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Company" />,
                title: t(`model_delete_confirm_title`, { param1: 'company' }),
                message: t(`action_cannot_be_undone`),
                positiveText: t('model_confirm_positive_delete', { param1: 'Company' }),
                negativeText: t('model_confirm_negative_delete', { param1: 'Company' }),
            }))
        )
            return;
        //call delete
        try {
            globalLoadingStore.addLoading();
            await membershipService.deleteMembership(id);
            toastStore.toast({
                type: 'success',
                messageKey: t('model_delete_success', { param1: 'Company' }),
            });
            history.push('/companies')
        } catch (e) {
            if (e.response?.data?.error === 'E004017') {
                toastStore.toast({
                    type: 'error',
                    message: 'Errors.E004017',
                });
            }
            else if (e.response?.data?.error === 'E008013') {
                toastStore.toast({
                    type: 'error',
                    message: 'Errors.E008013',
                });
            }
            else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `companies`,
            nameKey: 'companies',
        },
        {
            path: `${id}/dashboard`,
            nameKey: companyContext?.name ?? '',
        },
    ];

    const menuOptions = (): ActionMenuOption[] => {
        const options: ActionMenuOption[] = [
            {
                key: 'createOpportunity',
                title: t('Opportunity.create_new_opportunity'),
                action: () => setCreateOpportunityModalOpen(true),
            },
            {
                key: 'sendMessage',
                title: t('Contact.contacts_messages_send_label'),
                action: () => setSendMessageModalIsOpen(true),
            },
            {
                key: 'createInvoice',
                title: t('Contact.contacts_invoices_create_manual_invoice'),
                action: () => setCreateManualInvoiceModalOpen(true),
            },
            // {
            //     key: 'reserveDayPass',
            //     title: t('Membership.reserve_date_day_pass'),
            // },
            
        ];
        //(only admins or managers of one of companie's base locations ||company has no location associated) can:
        if (
            (companyContext?.campuses && companyContext.campuses.length == 0) ||
            userPermissionsStore.userIsAdministrator ||
            userPermissionsStore.userIsManagerForAtLeastOneOfCampus(
                companyContext?.campuses
                    ?.filter((location) => location?.id !== null)
                    .map((location) => location?.id!) ?? []
            )
        ) {
            options.splice(
                options.length - 1,
                0,
                {
                    key: 'edit',
                    title: t('Membership.edit_membership'),
                    action: () => setEditModalOpen(true),
                },
                {
                    key: 'addMember',
                    title: t('Membership.add_members'),
                    action: () => setInviteMemberModalOpen(true),
                },
                {
                    key: 'makeCreditTransaction',
                    title: t('Contact.make_credit_transaction'),
                    action: () => setCreditTransactionModalOpen(true),
                },
                {
                    key: 'giveDayPass',
                    title: t('Membership.give_a_day_pass'),
                    action: () => setGiveDayPassModalOpen(true),
                },
                {
                    key: 'delete',
                    title: t('Membership.delete_membership'),
                    action: () => deleteCompany(),
                }
            );
        }

        return options;
    };


    const handleFileClick = (url?: string): void => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    const deleteFile = async (fileId: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="File" />,
                title: t(`model_delete_confirm_title`, { param1: 'file' }),
                message: t(`action_cannot_be_undone`),
                positiveText: t('model_confirm_positive_delete', { param1: 'file' }),
                negativeText: t('model_confirm_negative_delete', { param1: 'file' }),
            }))
        )
            return;
        //call delete
        await membershipFileService.deleteMembershipFile(id, fileId);
        toastStore.toast({
            type: 'success',
            messageKey: t('model_delete_success', { param1: 'File' }),
        });
        resetFilesSearch();
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        debounceFetchFiles.current(0);
    };

    const handleFileDeleteClick = async (id?: string): Promise<void> => {
        if (id) {
            await deleteFile(id);
        }
    };

    const handleFileUploadSubmit = async (
        isSubmiting: boolean,
        formModel?: AddFileFormModel
    ): Promise<void> => {
        if (isSubmiting && formModel) {
            try {
                globalLoadingStore.addLoading();
                await membershipFileService.createMembershipFile(
                    id,
                    formModel as CreateMembershipFileRequestDto
                );
                toastStore.toast({
                    type: 'success',
                    messageKey: t('file_added_successfully'),
                });
                setAddFileModalOpen(false);
                resetFilesSearch();
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                debounceFetchFiles.current(0);
            } catch (e) {
                if (e.response.data.modelState['file.FileName'] !== undefined) {
                    toastStore.toast({
                        type: 'error',
                        messageKey: e.response.data.modelState['file.FileName'][0],
                    });
                } else if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        } else {
            setAddFileModalOpen(false);
        }
    };

    const resetFilesSearch = (): void => {
        setFilesAreLoading(true);
        setFilesMaxResults(false);
        setFiles([]);
        setFilesCurrentPage(0);
    };

    const fetchFiles = async (currentPage: number): Promise<StoredFileDto[]> => {
        try {
            globalLoadingStore.addLoading();

            const [results, totalItemCount] = await membershipFileService.getMembershipFiles(id, {
                page: currentPage,
                pageSize: filesPageSize,
            } as GetMembershipFilesRequestDto);

            if (results.length + filesPageSize * currentPage >= totalItemCount) {
                setFilesMaxResults(true);
            }

            return results;
        } finally {
            globalLoadingStore.removeLoading();
            setFilesAreLoading(false);
        }
    };

    const debounceFetchFiles = useRef(
        debounce((page: number) => {
            fetchFiles(page).then((results) => {
                setFiles((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    useEffect(() => {
        debounceFetchFiles.current(filesCurrentPage);
    }, [filesCurrentPage]);

    const handleFileListScrolledToBottom = (): void => {
        if (!filesMaxResults) {
            const currentPage = filesCurrentPage + 1;
            setFilesCurrentPage(currentPage);
        }
    };

    const fetchNote = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            const response = await membershipService.getMembershipNote(id);
            setMembershipNote(response?.note);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, id, membershipService, toastStore]);

    useEffect(() => {
        fetchNote();
    }, [fetchNote]);
    
    return (
        <div className="CompanyDashboard">
            <CompanyHeader
                tab={CompanyTabs.Dashboard}
                company={companyContext}
                routes={breadcrumbs}
                action={<ActionMenu options={menuOptions()} type="primary" trigger="click" />}
                note={
                    <ActivityLogTabs
                        form={form}
                        tabs={[ActivityLogTab.files, ActivityLogTab.notes]}
                        onAddFileClick={(): void => setAddFileModalOpen(true)}
                        files={files}
                        onFileClick={handleFileClick}
                        onFileDeleteClick={handleFileDeleteClick}
                        onFilesScrolledToBottom={handleFileListScrolledToBottom}
                        filesAreLoading={filesAreLoading}
                        onEditNoteClick={(): void => setEditNoteModalOpen(true)}
                        note={membershipNote}
                    />
                }
                availableDayPassCount={companyContext?.availableDayPassCount}
                headerData={{
                    title: companyContext?.name ?? '',
                    imageUrl: companyContext?.imageUrl,
                }}
                showSubSection
            />

            <Content>{companyContext && <CompanyDetailsBody company={companyContext} />}</Content>

            {addFileModalOpen && (
                <AddFileModal visible={addFileModalOpen} onComplete={handleFileUploadSubmit} />
            )}

            {editNoteModalOpen && (
                <EditNoteModal
                    visible={editNoteModalOpen}
                    onComplete={(success: boolean) => {
                        if (success) fetchNote();
                        setEditNoteModalOpen(false);
                    }}
                    entityId={companyContext?.id}
                    entityType={NoteEntityTypeEnum.Membership}
                    note={membershipNote}
                />
            )}

            {creditTransactionModalOpen && (
                <CreditTransactionModal
                    visible={creditTransactionModalOpen}
                    onComplete={(success: boolean) => {
                        if (success) companyContext?.fetchCompany();
                        setCreditTransactionModalOpen(false);
                    }}
                    creditTransactionRecipientType={CreditTransactionRecipientType.Membership}
                />
            )}

            {giveDayPassModalOpen && (
                <GiveDayPassModal
                    visible={giveDayPassModalOpen}
                    dayPassTransactionRecipientType={DayPassTransactionRecipientType.Membership}
                    onComplete={(success: Boolean): void => {
                        if (success) companyContext?.fetchCompany();
                        setGiveDayPassModalOpen(false);
                    }}
                    entityId={companyContext?.id!}
                />
            )}

            {editModalOpen && (
                <EditCompany
                    visible={editModalOpen}
                    onComplete={(success: boolean) => {
                        if (success) companyContext?.fetchCompany();
                        setEditModalOpen(false);
                    }}
                    membership={companyContext}
                />
            )}

            {createManualInvoiceModalOpen && (
                <CreateManualInvoiceModal
                    visible={createManualInvoiceModalOpen}
                    entityId={id}
                    paymentMethodHolderType={PaymentMethodHolderTypeDto.Membership}
                    onComplete={() => {
                        setCreateManualInvoiceModalOpen(false);
                    }}
                />
            )}

            {sendMessageModalIsOpen && companyContext && (
                <SendMessageModal
                    visible={sendMessageModalIsOpen}
                    onComplete={(): void => {
                        setSendMessageModalIsOpen(false);
                    }}
                    receiverId={id}
                    messageReceiverType={MessageReceiverTypeDto.Membership}
                    name={companyContext.name ?? ''}
                />
            )}

            {inviteMemberModalOpen && (
                <InviteMemberModal
                    onComplete={(success: boolean): void => {
                        setInviteMemberModalOpen(false);
                        if (success) {
                            toastStore.toast({
                                type: 'success',
                                message: t('Membership.invited_members_success'),
                            });
                        }
                    }}
                    visible={inviteMemberModalOpen}
                    membershipId={id}
                    inviteMemberContext={InviteMemberContext.Company}
                />
            )}

            {companyContext && createOpportunityModalOpen && (
                <CreateOpportunityModal
                    onComplete={(success: Boolean, id?: string) => {
                        setCreateOpportunityModalOpen(false);
                        if (success && id) history.push(`/opportunities/${id}/dashboard`);
                    }}
                    visible={createOpportunityModalOpen}
                    existingMembership={{
                        membershipId: companyContext?.id ?? '',
                        membershipName: companyContext?.name ?? '',
                    }}
                />
            )}
        </div>
    );
});

export default CompanyDashboard;
