import { Button, Col, Form, Input, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import FileUpload, { FileDetails } from 'Components/file-upload/file-upload';
import { File } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useStores } from 'Hooks';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddFileSchema } from 'Schemas';
import './index.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface AddFileModalProps {
    visible: boolean;
    onComplete: (isSubmiting: boolean, formModel?: AddFileFormModel) => void;
}

export interface AddFileFormModel {
    description?: string;
    file?: UpdateFileRequestDto;
}

const AddFileModal: FunctionComponent<AddFileModalProps> = ({
    visible,
    onComplete,
}) => {
    const { confirmationModalStore } = useStores();

    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors, setErrors] = useFormValidation(AddFileSchema, form);
    const [uploadedFile, setUploadedFile] = useState<FileDetails | undefined>();
    const { t } = useTranslation();

    const dismiss = (isSubmiting: boolean, formModel?: AddFileFormModel): void => {
        onComplete(isSubmiting, formModel);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <File />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`confirm_positive_text_model`, {param1: 'file upload'}),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;

        dismiss(false, undefined);
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const model: AddFileFormModel = {
            description: formValues.description,
            file: uploadedFile
                ? ({
                      fileName: uploadedFile?.filename,
                      uploadBase64: uploadedFile?.base64,
                  } as UpdateFileRequestDto)
                : undefined,
        };

        if (!(await validateForm(model))) return;

        onComplete(true, model)
    };

    useEffect(() => {
        if (errors.get('file.fileName') !== undefined) {
            const errors = new Map<string, string[]>();
            errors.set('file', ['Errors.file_name_under_150']);
            setErrors(errors);
        }
    }, [errors]);

    return (
        <BaseModal
            visible={visible}
            title={t('add_file')}
            className="FormModal AddFileModal"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Row gutter={formGutter} className="file-upload">
                    <Col span={24}>
                        <ValidatedFormItem name="file" errors={errors}>
                            <FileUpload
                                setFileDetails={(file: FileDetails | undefined): void =>
                                    setUploadedFile(file)
                                }
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem name="description" errors={errors} label={t('description')}>
                            <Input.TextArea />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary cancel"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" htmlType="submit">
                        {t('upload')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(AddFileModal);
