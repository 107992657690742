import { Button, Col, Form, Row, Switch } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { SubscriptionDetailsDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsDto';
import { UpdateSubscriptionSettingsRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionSettingsRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { AdministrationNut } from 'Components/icons';
import { useService, useStores } from 'Hooks';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionService } from 'Services/SubscriptionService';
import './edit-subscription-settings-modal.less';

const formGutter: [Gutter, Gutter] = [40, 40];

interface EditSubscriptionSettingsModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    subscription?: SubscriptionDetailsDto;
}

const EditSubscriptionSettingsModal: FunctionComponent<EditSubscriptionSettingsModalProps> = ({
    visible,
    onComplete,
    subscription,
}) => {
    const subscriptionService = useService(SubscriptionService);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const [renewalState, setRenewalState] = useState(
        subscription?.isAutomaticRenewalOpportunityCreationEnabled
    );
    const [mtmState, setMtmState] = useState(subscription?.becomesMonthToMonthOnExpiration);
    const [expirationState, setExpirationState] = useState(subscription?.sendExpirationReminder);

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <AdministrationNut />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`confirm_positive`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            const request: UpdateSubscriptionSettingsRequestDto = {
                becomesMonthToMonthOnExpiration: mtmState,
                isAutomaticRenewalOpportunityCreationEnabled: renewalState,
                sendExpirationReminder: expirationState,
            };
            if (subscription?.id) {
                await subscriptionService.updateSubscriptionSettings(subscription?.id!, request);

                toastStore.toast({
                    type: 'success',
                    message: t('model_edit_success', { param1: 'Subscription settings' }),
                });

                dismiss(true);
            }
        } catch (e) {
            toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    
    return (
        <BaseModal
            visible={visible}
            title={t('Subscription.edit_subscription_settings')}
            className="EditSubscriptionSettingsModal FormModal"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Row gutter={formGutter}>
                    <Col span={12}>
                        <div className="setting-container">
                            <div>
                                <div className="title">
                                    {t('Subscription.automatic_renewal_opportunity')}
                                </div>
                                <div className="description">
                                    {t('Subscription.automatic_renewal_opportunity_explanation')}
                                </div>
                            </div>
                            <Switch
                                checked={renewalState}
                                onChange={(value): void => setRenewalState(value)}
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="setting-container">
                            <div>
                                <div className="title">{t('Subscription.mtm_conversion')}</div>
                                <div className="description">
                                    {t('Subscription.mtm_conversion_explanation')}
                                </div>
                            </div>
                            <Switch
                                checked={mtmState}
                                disabled={subscription?.endDate === null}
                                onChange={(value): void => setMtmState(value)}
                            />
                        </div>
                    </Col>
                </Row>

                <Row gutter={formGutter}>
                    <Col span={12}>
                        <div className="setting-container">
                            <div>
                                <div className="title">{t('Subscription.expiration_reminder')}</div>
                                <div className="description">
                                    {t('Subscription.expiration_reminder_explanation')}
                                </div>
                            </div>
                            <Switch
                                checked={expirationState}
                                onChange={(value): void => setExpirationState(value)}
                            />
                        </div>
                    </Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" className="positive" htmlType="submit">
                        {t('save')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(EditSubscriptionSettingsModal);
