import { Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';
import { ClosedOpportunityReportItemDto } from 'Api/Features/Reports/Dtos/ClosedOpportunities/ClosedOpportunityReportItemDto';
import { ClosedOpportunityStatusDto } from 'Api/Features/Reports/Dtos/ClosedOpportunities/ClosedOpportunityStatusDto';
import { GetClosedOpportunitiesFlagDto } from 'Api/Features/Reports/Dtos/ClosedOpportunities/GetClosedOpportunitiesFlagDto';
import { GetClosedOpportunitiesReportRequestDto } from 'Api/Features/Reports/Dtos/ClosedOpportunities/GetClosedOpportunitiesReportRequestDto';
import Icon from 'Components/icons/icon';
import { ListSectionHeader } from 'Components/list-section-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import TableActionsButtons from 'Components/table-action-buttons/table-actions-buttons';
import { TableFilters } from 'Components/table-filters';
import { Tag } from 'Components/tag';
import { TagColors } from 'Components/tag/tag';
import { TdWithImage } from 'Components/td-with-image';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import {
    ALL_LOCATIONS,
    MOMENT_PARSING_FORMAT,
    MOMENT_PARSING_MONTH_FORMAT,
} from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import moment from 'moment';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ClosedOpportunitiesReportService } from 'Services/ClosedOpportunitiesReportService';
import { FilterStore } from 'Stores';
import { showFile } from 'Utils';
import { cleanVal, currencyFormatter } from 'Utils/NumberUtils';
import { truncate } from 'Utils/StringUtils';
import { displayFormatedDate } from 'Utils/TimeUtils';
import { images, theme } from 'variant';
import './index.less';

const { usersHeader } = images;

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'types',
        nameKey: 'Types',
        items: [
            {
                key: GetClosedOpportunitiesFlagDto.IsNewSubscription,
                displayNameKey: `new_subscription`,
                checked: true,
            },
            {
                key: GetClosedOpportunitiesFlagDto.IsSubscriptionModification,
                displayNameKey: `subscription_modification`,
                checked: true,
            },
            {
                key: GetClosedOpportunitiesFlagDto.IsSubscriptionRenewal,
                displayNameKey: `subscription_renewal`,
                checked: true,
            },
        ],
    },
    {
        key: 'statuses',
        nameKey: 'Statuses',
        items: [
            {
                key: OpportunityStatusDto.Lost,
                displayNameKey: `Opportunity.opportunity_status_${OpportunityStatusDto.Lost}`,
                checked: true,
            },
            {
                key: OpportunityStatusDto.AgreementSigned,
                displayNameKey: `Opportunity.opportunity_status_${OpportunityStatusDto.AgreementSigned}`,
                checked: true,
            },
        ],
    },
];

const renderNameWithImage = (data: NameWithImage, iconName: string): ReactNode => (
    <TdWithImage defaultImg={<Icon iconName={iconName} />} imgSrc={data.imageUrl}>
        {data.name}
    </TdWithImage>
);

interface NameWithImage {
    name: string;
    imageUrl?: string | null;
}

interface Opportunities {
    id: string;
    ownerInfos: NameWithImage;
    companyInfos: NameWithImage;
    location: string;
    lossWinDate: string;
    deskCount: number;
    product: string;
    monthlyRevenue: string;
    lossReason?: string | null;
    lossReasonNote?: string | null;
}

const ClosedOpportunities: FunctionComponent = observer(() => {
    const closedOpportunitiesReportService = useService(ClosedOpportunitiesReportService);
    const { locationStore } = useStores();
    const { globalLoadingStore, toastStore, userPermissionsStore, requestStore } = useStores();
    const [opportunitiesList, setOpportunitiesList] = useState<Opportunities[]>([]);
    const [subscriptionsRequest, setSubscriptionsRequest] = useState<
        GetClosedOpportunitiesReportRequestDto | undefined
    >(undefined);
    const [isTableEmpty, setIsTableEmpty] = useState<boolean>(true);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { t } = useTranslation();

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            nameKey: 'reports',
            path: 'reports',
        },
        {
            nameKey: 'Reports.closed_opportunities',
            path: 'closed-opportunities',
        },
    ];

    const onExportClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            let document;
            if (subscriptionsRequest) {
                const campusName =
                    locationStore?.locations.find(
                        (x) => x.id === subscriptionsRequest?.campusIds?.[0]
                    )?.name || ALL_LOCATIONS;

                document = await closedOpportunitiesReportService.exportClosedOpportunitiesReport(
                    subscriptionsRequest
                );

                showFile(
                    document,
                    `${t('Reports.closed_opportunities')} - ${moment(
                        subscriptionsRequest.startDate
                    ).format(MOMENT_PARSING_MONTH_FORMAT)} - ${campusName}`
                );
            }
        } catch (e) {
            if (!e.treated) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const tableActionButtons = [
        {
            iconName: 'Download',
            toolTip: 'export_table',
            onClick: (): Promise<void> => onExportClick(),
            disabled: isTableEmpty,
        },
    ];

    const columns: ColumnType<Opportunities>[] = [
        {
            title: t('company'),
            dataIndex: 'companyInfos',
            render: (companyInfos): ReactNode => renderNameWithImage(companyInfos, 'Company'),
        },
        {
            title: t('Opportunity.opportunity_owner'),
            dataIndex: 'ownerInfos',
            render: (ownerInfos): ReactNode => renderNameWithImage(ownerInfos, 'Owner'),
        },
        {
            title: t('location'),
            dataIndex: 'location',
        },
        {
            title: t('Opportunity.loss_win_date'),
            dataIndex: 'lossWinDate',
        },
        {
            title: t('Opportunity.desk_count'),
            dataIndex: 'deskCount',
        },
        {
            title: t('product'),
            dataIndex: 'product',
        },
        {
            title: t('Opportunity.term_length'),
            dataIndex: 'termLength',
        },
        {
            title: t('Opportunity.monthly_revenue'),
            dataIndex: 'monthlyRevenue',
            align: 'right',
        },
        {
            title: t('Lead.loss_reason'),
            dataIndex: 'lossReason',
        },
        {
            title: t('Report.loss_reason_note'),
            dataIndex: 'lossReasonNote',
        },
        {
            title: t('status'),
            dataIndex: 'reportItem',
            render: (opportunity: ClosedOpportunityReportItemDto): ReactNode => (
                <div>
                    <Tag
                        label={t(`Opportunity.opportunity_status_${opportunity.status}`)}
                        color={
                            opportunity.status === ClosedOpportunityStatusDto.AgreementSigned
                                ? TagColors.active
                                : opportunity.status === ClosedOpportunityStatusDto.Lost
                                ? TagColors.disabled
                                : TagColors.default
                        }
                    />
                    {opportunity.isSubscriptionModification && (
                        <Tag boldLabel color={TagColors.cold} label={t('modification')} />
                    )}
                    {opportunity.isSubscriptionRenewal && (
                        <Tag boldLabel color={TagColors.cold} label={t('renewal')} />
                    )}
                    {!opportunity.isSubscriptionRenewal &&
                        !opportunity.isSubscriptionModification && (
                            <Tag boldLabel color={TagColors.cold} label={t('new_subscription')} />
                        )}
                </div>
            ),
        },
    ];

    const fetchSubscriptions = useCallback(
        async (params: {
            startDate: string;
            endDate: string;
            campusIds: string[];
            statuses: ClosedOpportunityStatusDto[];
            flags: GetClosedOpportunitiesFlagDto[];
        }) => {
            globalLoadingStore.addLoading();

            try {
                if (params.flags.length === 0 || params.statuses.length === 0) {
                    setOpportunitiesList([]);
                    setIsTableEmpty(true);
                } else {
                    const response =
                        await closedOpportunitiesReportService.getClosedOpportunitiesReport({
                            startDate: params.startDate,
                            endDate: params.endDate,
                            campusIds: params.campusIds,
                            statuses: params.statuses,
                            flags: params.flags,
                        });

                    const mappedResponse: Opportunities[] = response.map((opportunity) => {
                        return {
                            id: cleanVal.string(opportunity.id),
                            ownerInfos: {
                                name: `${opportunity.ownerAccount?.firstName} ${opportunity.ownerAccount?.lastName}`,
                                imageUrl: opportunity.ownerAccount?.imageUrl,
                            },
                            companyInfos: {
                                name: cleanVal.string(opportunity.membership?.name),
                                imageUrl: opportunity.membership?.imageUrl,
                            },
                            location: cleanVal.string(opportunity.campus?.name),
                            lossWinDate: displayFormatedDate(
                                cleanVal.string(opportunity.lastStatusChangeDate),
                                MOMENT_PARSING_FORMAT
                            ),
                            deskCount: cleanVal.number(opportunity.deskCount),
                            product: t(`Opportunity.product_${opportunity.product}`),
                            monthlyRevenue: currencyFormatter(
                                cleanVal.number(opportunity.monthlyRevenue)
                            ),
                            termLength: opportunity.termLength
                                ? t(`Opportunity.term_${opportunity.termLength}`)
                                : '',
                            reportItem: opportunity,
                            lossReason: opportunity.lostReason,
                            lossReasonNote: opportunity.lostReasonNote ? truncate(opportunity.lostReasonNote, 20) : ''
                        };
                    });

                    setOpportunitiesList(mappedResponse);
                    setIsTableEmpty(mappedResponse.length === 0);
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [
            closedOpportunitiesReportService,
            globalLoadingStore,
            t,
        ]
    );

    const getSearchInfos = (): {
        startDate: string;
        endDate: string;
        campusIds: string[];
        flags: GetClosedOpportunitiesFlagDto[];
        statuses: ClosedOpportunityStatusDto[];
    } => {
        const filterStore = filterStoreRef.current;
        const startDate = moment(filterStore.month).startOf('month').format(MOMENT_PARSING_FORMAT);
        const endDate = moment(filterStore.month).endOf('month').format(MOMENT_PARSING_FORMAT);
        const campusIds =
            filterStore.currentLocationId === 'all'
                ? requestStore.getReportRequestAvailableLocationIdsForUser()
                : [filterStore.currentLocationId];
        const filterFlags = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'types'
        );
        const filterStatuses = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'statuses'
        );
        const flags = filterFlags?.items
            ? filterFlags.items
                  .filter((item) => item.checked)
                  .map((item) => {
                      return item.key as GetClosedOpportunitiesFlagDto;
                  })
            : [];
        const statuses = filterStatuses?.items
            ? filterStatuses.items
                  .filter((item) => item.checked)
                  .map((item) => {
                      return item.key as ClosedOpportunityStatusDto;
                  })
            : [];

        return {
            startDate,
            endDate,
            campusIds,
            flags: flags,
            statuses: statuses,
        };
    };

    useEffect(() => {
        const filterStore = filterStoreRef.current;

        filterStore.month = moment().format(MOMENT_PARSING_MONTH_FORMAT);
        filterStore.startDate = moment().clone().startOf('month').format(MOMENT_PARSING_FORMAT);
        filterStore.endDate = moment().clone().endOf('month').format(MOMENT_PARSING_FORMAT);
    }, []);

    useEffect(() => {
        const disposer = autorun(() => {
            const searchInfos = getSearchInfos();

            fetchSubscriptions({
                startDate: searchInfos.startDate,
                endDate: searchInfos.endDate,
                campusIds: searchInfos.campusIds,
                statuses: searchInfos.statuses,
                flags: searchInfos.flags,
            });

            setSubscriptionsRequest({
                startDate: searchInfos.startDate,
                endDate: searchInfos.endDate,
                campusIds: searchInfos.campusIds,
                statuses: searchInfos.statuses,
                flags: searchInfos.flags,
            });
        });

        return (): void => {
            disposer();
        };
    }, [fetchSubscriptions]);

    const handleTableChange = (): void => {
        const searchInfos = getSearchInfos();

        fetchSubscriptions({
            startDate: searchInfos.startDate,
            endDate: searchInfos.endDate,
            campusIds: searchInfos.campusIds,
            statuses: searchInfos.statuses,
            flags: searchInfos.flags,
        });
    };

    return (
        <div className="ClosedOpportunities">
            <ListSectionHeader
                routes={breadcrumbs}
                backgroundImageUrl={usersHeader}
                defaultImg={<Icon iconName="TickLined" fill={theme['primary-color']} />}
                title={t('Reports.closed_opportunities')}
                subTitle={t('Reports.closed_opportunities_overview')}
            />

            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeMonth
                    includeAdvancedFilters
                    includeLocations
                    availableLocations={userPermissionsStore.availableLocationsForUser}
                />

                <TableActionsButtons buttonsList={tableActionButtons} />

                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    dataSource={opportunitiesList}
                    pagination={false}
                    onChange={handleTableChange}
                    rowKey={(record): string => record.id}
                />
            </Content>
        </div>
    );
});

export default ClosedOpportunities;
