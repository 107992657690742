// GENERATED FILE - DO NOT MODIFY
import { GetManagerUserPreferencesResponseDto } from 'Api/Features/ManagerUserPreferences/Dto/GetManagerUserPreferencesResponseDto';
import { SetManagerUserPreferencesRequestDto } from 'Api/Features/ManagerUserPreferences/Dto/SetManagerUserPreferencesRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class ManagerUserPreferenceProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getManagerUserPreferences(managerUserId: string | null): Promise<GetManagerUserPreferencesResponseDto | null> {
        const uri = this.buildUri(
            "/manager-users/{managerUserId}/preferences",
            { managerUserId: managerUserId },
            null
        );

        const data: GetManagerUserPreferencesResponseDto | null = await this.httpClient.get<GetManagerUserPreferencesResponseDto | null>(uri);
        return data;
    }

    public async setManagerUserPreferences(managerUserId: string | null, request: SetManagerUserPreferencesRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/manager-users/{managerUserId}/preferences",
            { managerUserId: managerUserId },
            null
        );

        await this.httpClient.put<SetManagerUserPreferencesRequestDto | null, void>(uri, request);
    }
}