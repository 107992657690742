import React, { FunctionComponent } from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

const TableEmpty: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <div style={{ textAlign: 'center' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('no_results')}/>
        </div>
    )
}

export default TableEmpty;
