import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './form-step.less';

interface FormStepProps {
    Steps: StepInfo[];
}

export interface StepInfo {
    stepNumber: number;
    name: string;
    active: boolean;
}

const FormStep: FunctionComponent<FormStepProps> = ({ Steps }) => {
    const { t } = useTranslation();
    return (
        <div className="FormStep">
            {Steps.map((step: StepInfo, i: number) => (
                <div className="step-container" key={i}>
                    <div className={`circle ${step.active ? 'active' : ''}`}>{step.stepNumber}</div>
                    <div className={`step-name ${step.active ? 'active' : ''}`}>
                        {t(`FormStep.step_${step.name}`)}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FormStep;
