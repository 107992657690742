import { GetMembershipPrepaidBalanceTransactionsRequestDto } from 'Api/Features/PrepaidBalance/Dtos/GetMembershipPrepaidBalanceTransactionsRequestDto';
import { GetMembershipPrepaidBalanceTransactionsResponseDto } from 'Api/Features/PrepaidBalance/Dtos/GetMembershipPrepaidBalanceTransactionsResponseDto';
import { PrepaidBalanceTransactionDto } from 'Api/Features/PrepaidBalance/Dtos/PrepaidBalanceTransactionDto';
import { PrepaidBalanceProxy } from 'Api/Features/PrepaidBalance/PrepaidBalanceProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(PrepaidBalanceProxy)
export class PrepaidBalanceService extends ApiService {
    constructor(private readonly prepaidBalanceProxy: PrepaidBalanceProxy) {
        super();
    }

    public async getMembershipPrepaidBalanceTransactions(
        membershipId: string | null,
        request: GetMembershipPrepaidBalanceTransactionsRequestDto | null
    ): Promise<[PrepaidBalanceTransactionDto[], number]> {
        const data: GetMembershipPrepaidBalanceTransactionsResponseDto | null =
            await this.prepaidBalanceProxy.getMembershipPrepaidBalanceTransactions(
                membershipId,
                request
            );

        return [
            data?.items?.filter((x) => x !== null).map((x) => x!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }
}
