import { Button, Col, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { Content } from 'antd/lib/layout/layout';
import { CampusDetailsDto } from 'Api/Features/Campuses/Dtos/CampusDetailsDto';
import { CampusStatusTypeDto } from 'Api/Features/Campuses/Dtos/CampusStatusTypeDto';
import { AddressDto } from 'Api/Features/General/Dtos/AddressDto';
import { ContactInfoDto } from 'Api/Features/General/Dtos/ContactInfoDto';
import Icon from 'Components/icons/icon';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import { Tag } from 'Components/tag';
import { TagColors, TagProps } from 'Components/tag/tag';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CampusService } from 'Services/CampusService';
import { phoneNumberFormatter } from 'Utils/NumberUtils';
import CreateLocationModal from '../../../modals/create-location-modal';
import './index.less';

const formGutter: [Gutter, Gutter] = [40, 0];

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'management',
        nameKey: 'management',
    },
    {
        path: 'location-details',
        nameKey: 'Location.management_section_location_details',
    },
];

interface CampusData {
    name: string;
    statusTag: TagProps;
    description?: SimpleListItemProps[];
    marketingDescription?: SimpleListItemProps[];
    perks?: SimpleListItemProps[];
    buildingEssentials?: SimpleListItemProps[];
    rulesAndAgreements?: SimpleListItemProps[];
    welcomeMessage?: SimpleListItemProps[];
    contactInfos?: SimpleListItemProps[];
    socialMedia?: SimpleListItemProps[];
    settings?: SimpleListItemProps[];
}

const LocationDetails: FunctionComponent = observer(() => {
    const campusService = useService(CampusService);
    const { userPermissionsStore, globalLoadingStore, toastStore, locationStore } = useStores();
    const [campusResponseData, setCampusResponseData] = useState<CampusDetailsDto | null>();
    const [campusData, setCampusData] = useState<CampusData>();
    const [editLocationModalOpen, setEditLocationModalOpen] = useState(false);

    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();

    const fetchCampus = useCallback(async () => {
        globalLoadingStore.addLoading();

        const getStatusTag = (status?: CampusStatusTypeDto): TagProps => {
            let color;

            switch (status) {
                case CampusStatusTypeDto.Active:
                    color = TagColors.active;
                    break;
                case CampusStatusTypeDto.ComingSoon:
                    color = TagColors.cold;
                    break;
                case CampusStatusTypeDto.PreActive:
                    color = TagColors.default;
                    break;
                default:
                    color = TagColors.disabled;
                    break;
            }

            return { label: t(`Location.campus_status_type_${status}`), color: color };
        };

        const valOrUndefined = (val?: string | null): SimpleListItemProps[] | undefined =>
            val ? [{ title: val }] : undefined;

        const getSettingsString = (
            hasInvita?: boolean,
            hasCoffeeOrders?: boolean
        ): SimpleListItemProps[] | undefined => {
            const settings: SimpleListItemProps[] = [];

            if (hasInvita)
                settings.push({
                    title: t('Location.management_location_details_setting_hasInvita'),
                });
            if (hasCoffeeOrders)
                settings.push({
                    title: t('Location.management_location_details_setting_hasCoffeeOrders'),
                });

            return settings.length > 0 ? settings : undefined;
        };

        const getContactInfos = (
            contact?: ContactInfoDto | null,
            address?: AddressDto | null
        ): (SimpleListItemProps[] | undefined)[] => {
            const contactInfosToDisplay: (keyof ContactInfoDto)[] = [
                'phone1',
                'phone2',
                'email',
                'website',
            ];
            const contactInfosToDisplayIcons: string[] = ['Phone', 'Phone', 'Email', 'Television'];
            const socialMediaToDisplay: (keyof ContactInfoDto)[] = [
                'twitterUrl',
                'facebookUrl',
                'linkedInUrl',
                'instagramUrl',
                'angelListUrl',
            ];
            const socialMediaToDisplayIcons: string[] = [
                'Twitter',
                'Facebook',
                'LinkedIn',
                'Instagram',
                'AngelList',
            ];
            const contactInfos: { title: string; avatar: ReactElement }[] = [];
            const socialMediaInfos: { title: string; avatar: ReactElement }[] = [];
            const addressInfos: string[] = [];
            const getAvatarComponent = (icon: string): ReactElement => (
                <ImageWithPlaceholder
                    width="32"
                    height="32"
                    defaultImg={<Icon iconName={icon} />}
                />
            );

            if (contact) {
                contactInfosToDisplay.forEach((key, i) => {
                    if (contact[key]) {
                        const val =
                            key === contactInfosToDisplay[0] || key === contactInfosToDisplay[1]
                                ? phoneNumberFormatter(contact[key])
                                : contact[key];

                        if (val)
                            contactInfos.push({
                                title: val,
                                avatar: getAvatarComponent(contactInfosToDisplayIcons[i]),
                            });
                    }
                });

                socialMediaToDisplay.forEach((key, i) => {
                    if (contact[key]) {
                        const val = contact[key];

                        if (val)
                            socialMediaInfos.push({
                                title: val,
                                avatar: getAvatarComponent(socialMediaToDisplayIcons[i]),
                            });
                    }
                });
            }

            if (address?.addressLine1) addressInfos.push(address.addressLine1);
            if (address?.addressLine2) addressInfos.push(address.addressLine2);
            if (address?.state && address.postalCode)
                addressInfos.push(`${address.state} ${address.postalCode}`);

            contactInfos.push({
                title: addressInfos.join(', '),
                avatar: getAvatarComponent('RoadSign'),
            });

            return [
                contactInfos.length > 0 ? contactInfos : undefined,
                socialMediaInfos.length > 0 ? socialMediaInfos : undefined,
            ];
        };

        try {
            const campusResponse = await campusService.getCampus(id);
            const [contactInfos, socialMediaInfos] = getContactInfos(
                campusResponse?.contactInfo,
                campusResponse?.address
            );

            const campusDataMapping: CampusData = {
                name: campusResponse?.name || '',
                statusTag: getStatusTag(campusResponse?.status),
                description: valOrUndefined(campusResponse?.description),
                marketingDescription: valOrUndefined(campusResponse?.shortDescription),
                perks: valOrUndefined(campusResponse?.perks),
                buildingEssentials: valOrUndefined(campusResponse?.buildingEssentials),
                rulesAndAgreements: valOrUndefined(campusResponse?.rulesAndAgreements),
                welcomeMessage: valOrUndefined(campusResponse?.welcomeMessage),
                contactInfos: contactInfos,
                socialMedia: socialMediaInfos,
                settings: getSettingsString(
                    campusResponse?.hasInvita,
                    campusResponse?.hasCoffeeOrders
                ),
            };

            setCampusResponseData(campusResponse);
            setCampusData(campusDataMapping);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, toastStore, campusService, id, t]);

    const actionButon = userPermissionsStore.userIsAdministrator ? (
        <Button
            type="primary"
            onClick={(): void => {
                setEditLocationModalOpen(true);
            }}
        >
            {t('Location.edit_location')}
        </Button>
    ) : undefined;

    useEffect(() => {
        if (!editLocationModalOpen) {
            fetchCampus();
        }
    }, [fetchCampus, editLocationModalOpen]);

    return (
        <div className="LocationDetails">
            <LocationHeader
                routes={breadcrumbs}
                title={campusData?.name}
                subTitle={
                    campusData && (
                        <Tag
                            label={campusData.statusTag.label}
                            color={campusData.statusTag.color}
                        />
                    )
                }
                action={actionButon}
            />

            <Content>
                <Row gutter={formGutter}>
                    <Col span={12}>
                        {campusData?.description && (
                            <SimpleList
                                className="editor-style"
                                title={t(`description`)}
                                data={campusData.description}
                                maxHeight
                            />
                        )}

                        {campusData?.marketingDescription && (
                            <SimpleList
                                title={t(`marketing_description`)}
                                data={campusData.marketingDescription}
                                maxHeight
                            />
                        )}

                        {campusData?.perks && (
                            <SimpleList title={t(`perks`)} data={campusData.perks} maxHeight />
                        )}

                        {campusData?.buildingEssentials && (
                            <SimpleList
                                title={t(`Location.building_essentials`)}
                                data={campusData.buildingEssentials}
                                maxHeight
                            />
                        )}

                        {campusData?.welcomeMessage && (
                            <SimpleList
                                title={t(`welcome_message`)}
                                data={campusData.welcomeMessage}
                                maxHeight
                            />
                        )}

                        {campusData?.rulesAndAgreements && (
                            <SimpleList
                                title={t(`Location.rules_agreements`)}
                                data={campusData.rulesAndAgreements}
                                maxHeight
                            />
                        )}
                    </Col>

                    <Col span={12}>
                        {campusData?.contactInfos && (
                            <SimpleList
                                className="contact-infos"
                                title={t(`contact_information`)}
                                data={campusData.contactInfos}
                            />
                        )}

                        {campusData?.socialMedia && (
                            <SimpleList
                                className="contact-infos"
                                title={t(`Location.social_media_contacts`)}
                                data={campusData.socialMedia}
                            />
                        )}

                        {campusData?.settings && (
                            <SimpleList title={t(`settings`)} data={campusData.settings} />
                        )}
                    </Col>
                </Row>
            </Content>

            {editLocationModalOpen && (
                <CreateLocationModal
                    visible={editLocationModalOpen}
                    onComplete={(success?: boolean): void => {
                        setEditLocationModalOpen(false);
                        if (success) locationStore.setLocations();
                    }}
                    campusData={campusResponseData ?? undefined}
                />
            )}
        </div>
    );
});

export default LocationDetails;
