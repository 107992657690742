import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ExpandAll: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="6" height="16" viewBox="0 0 6 16" fill="none" {...props}>
                <path fillRule="evenodd" clipRule="evenodd" d="M3 0L6 4L0 4L3 0Z" fill={fill} />
                <path d="M2 4H4V7H2V4Z" fill={fill} />
                <path fillRule="evenodd" clipRule="evenodd" d="M3 16L0 12H6L3 16Z" fill={fill} />
                <path d="M2 9H4V12H2V9Z" fill={fill} />
            </svg>
        </span>
    );
};

export default ExpandAll;
