import { Button, Col, Form, Input, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { CreateAgreementTermsRequestDto } from 'Api/Features/AgreementTerms/Dtos/CreateAgreementTermsRequestDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { SubscriptionProposalStatusDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionProposalStatusDto';
import confetti from 'canvas-confetti';
import BaseModal from 'Components/base-modal/base-modal';
import FileUpload, { AcceptedFileExtension, FileDetails } from 'Components/file-upload/file-upload';
import { Plan as PlanIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { DEFAULT_PAGE_SIZE } from 'Models/Constants';
import { Opportunity } from 'Models/Opportunities/Opportunity';
import { SubscriptionProposal } from 'Models/Proposals/SubscriptionProposal';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SendAgreementSchema } from 'Schemas';
import { AgreementTermService } from 'Services/AgreementTermsService';
import { SubscriptionProposalService } from 'Services/SubscriptionProposalService';
import { theme } from 'variant';
import './send-agreement.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface SendAgreementProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    opportunity?: Opportunity;
    isSigned?: boolean;
}

const SendAgreement: FunctionComponent<SendAgreementProps> = ({
    visible,
    onComplete,
    opportunity,
    isSigned,
}) => {
    const { confirmationModalStore, globalLoadingStore, toastStore } = useStores();
    const agreementTermService = useService(AgreementTermService);
    const subscriptionProposalService = useService(SubscriptionProposalService);
    const history = useHistory();

    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors, setErrors] = useFormValidation(SendAgreementSchema, form);
    const [uploadedFile, setUploadedFile] = useState<FileDetails | undefined>();
    const { t } = useTranslation();
    const [showConfetti, setShowConfetti] = useState(false);
    const [answeringProposal, setAnsweringProposal] = useState<SubscriptionProposal | undefined>();

    const dismiss = (success = false, id?: string): void => {
        onComplete(success, id);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <PlanIcon />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`Agreement.answer_agreement_confirm_positive`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;

        dismiss();
    };

    const signedSuccess = async (): Promise<void> => {
        setShowConfetti(true);
        if (
            !(await confirmationModalStore.confirm({
                icon: <PlanIcon />,
                title: t(`good_job`),
                message: t('Proposal.signed_agreement_term_upload_success'),
                positiveText: t(`ok`),
                negativeText: t(`Opportunity.go_to_subscription`),
            }))
        ) {
            if (answeringProposal)
                history.push(
                    `/locations/${answeringProposal?.campus?.id}/subscriptions/${answeringProposal?.id}`
                );
        }

        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const data = {
            file: uploadedFile
                ? ({
                      fileName: uploadedFile?.filename,
                      uploadBase64: uploadedFile?.base64,
                  } as UpdateFileRequestDto)
                : undefined,
            note: formValues.note,
            opportunityId: opportunity?.id,
            isSigned: isSigned,
        } as CreateAgreementTermsRequestDto;
        if (!(await validateForm(data))) return;

        try {
            globalLoadingStore.addLoading();

            await agreementTermService.createAgreementTerms(data);

            if (isSigned) {
                signedSuccess();
            } else {
                toastStore.toast({
                    type: 'success',
                    messageKey: t('Proposal.agreement_term_create_success'),
                });
                dismiss(true);
            }
        } catch (e) {
            if (e?.response?.data?.modelState['file.FileName']) {
                toastStore.toast({
                    type: 'error',
                    messageKey: e.response.data.modelState['file.FileName'],
                });
            }
            else if (e?.response?.data?.modelState['file.UploadBase64']) {
                toastStore.toast({
                    type: 'error',
                    messageKey: e.response.data.modelState['file.UploadBase64'],
                });
            }
            else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const fetchProposalList = useCallback(async () => {
        if (opportunity) {
            try {
                globalLoadingStore.addLoading();

                const [proposalsList] = await subscriptionProposalService.getSubscriptionProposal({
                    pageSize: DEFAULT_PAGE_SIZE,
                    page: 0,
                    searchTerm: undefined,
                    opportunityId: opportunity.id!,
                });

                const acceptedProposal = proposalsList.filter(
                    (proposal) => proposal.status === SubscriptionProposalStatusDto.Accepted
                );

                if (acceptedProposal) {
                    setAnsweringProposal(acceptedProposal[0]);
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        }
    }, [globalLoadingStore, subscriptionProposalService, opportunity]);

    useEffect(() => {
        if (opportunity && isSigned) {
            fetchProposalList();
        }
    }, [isSigned, fetchProposalList, opportunity]);

    useEffect(() => {
        let canvasLeft: HTMLCanvasElement | null = null;
        let canvasRight: HTMLCanvasElement | null = null;

        if (showConfetti) {
            canvasLeft = document.createElement('canvas');
            canvasRight = document.createElement('canvas');

            const end = Date.now() + 5 * 1000;
            const canvasStyles = 'position:absolute;top:0;width:100vw;height:100vh;';
            canvasLeft.style.cssText = canvasStyles;
            canvasRight.style.cssText = canvasStyles;

            document.body.appendChild(canvasLeft);
            document.body.appendChild(canvasRight);

            const confettiLeft = confetti.create(canvasLeft, {
                resize: true,
                useWorker: true,
            });
            const confettiRight = confetti.create(canvasRight, {
                resize: true,
                useWorker: true,
            });
            (function frame(): void {
                confettiLeft({
                    particleCount: 2,
                    startVelocity: 55,
                    angle: 60,
                    spread: 55,
                    origin: { x: 0 },
                    colors: [theme['confetti'], theme['secondary-color']],
                });

                confettiRight({
                    particleCount: 2,
                    startVelocity: 55,
                    angle: 120,
                    spread: 55,
                    origin: { x: 1 },
                    colors: [theme['confetti'], theme['secondary-color']],
                });

                if (Date.now() < end) {
                    requestAnimationFrame(frame);
                }
            })();
        }

        return (): void => {
            if (canvasLeft && canvasRight) {
                document.body.removeChild(canvasLeft);
                document.body.removeChild(canvasRight);
            }
        };
    }, [showConfetti]);

    useEffect(() => {
        if (errors.get('file.fileName') !== undefined) {
            const errors = new Map<string, string[]>();
            errors.set('file', ['Errors.file_name_under_150']);
            setErrors(errors);
        }
    }, [errors]);

    return (
        <BaseModal
            visible={visible}
            title={
                isSigned
                    ? t('Proposal.upload_signed_agreement')
                    : t('Opportunity.send_agreement_terms')
            }
            className="FormModal SendAgreement"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Row gutter={formGutter} className="file-upload">
                    <Col span={24}>
                        <ValidatedFormItem name="file" errors={errors}>
                            <FileUpload
                                setFileDetails={(file: FileDetails | undefined): void =>
                                    setUploadedFile(file)
                                }
                                acceptedFileExtensions={[AcceptedFileExtension.PDF]}
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                {uploadedFile && (
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name="note"
                                errors={errors}
                                label={t('Lead.lead_add_a_note')}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                )}

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary cancel"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" htmlType="submit">
                        {isSigned ? t('upload') : t('Opportunity.send_agreement_terms')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(SendAgreement);
