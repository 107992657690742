import React, { FunctionComponent, useEffect, useState, useCallback, useRef } from 'react';
import { Row, Col, Input, Typography, Select, Checkbox, DatePicker, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { Gutter } from 'antd/es/grid/row';
import { User } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { theme } from 'variant';
import { SelectCustom } from 'Components/select-custom';
import {
    SelectCustomOption,
    SelectCustomGroupOption,
} from 'Components/select-custom/select-custom';
import { MemberService } from 'Services/MemberService';
import { Member } from 'Models/Members/Member';
import { OpportunityRadioButtonValues } from './create-opportunity';
import { SalutationDto } from 'Api/Features/Accounts/Dtos/SalutationDto';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import Icon from 'Components/icons/icon';
import TipModal, { TipModalProps } from '../contacts/modals/tip-modal';
import { ImagePicker } from 'Components/image-picker';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { ExpertiseDto } from 'Api/Features/Expertises/Dtos/ExpertiseDto';
import { ExpertiseService } from 'Services/ExpertiseService';
import './contact-form-section.less';
import { MemberDetailsDto } from 'Api/Features/Members/Dtos/MemberDetailsDto';
import moment from 'moment';
import { UploadFile } from 'antd/lib/upload/interface';

const { Title } = Typography;
const { Option } = Select;

const formGutter: [Gutter, Gutter] = [40, 0];

interface ExpertisesOptions {
    selected?: ExpertiseDto;
    options: ExpertiseDto[];
}

interface ContactFormSectionProps {
    radioButtonValue?: OpportunityRadioButtonValues;
    errors: Map<string, string[]>;
    handleOnContactChange?: any;
    handleOnFirstNameChange?: any;
    handleOnLastNameChange?: any;
    selectedExistingMembershipId?: string;
    selectedExistingContactId?: string;
    handleOnContactLabelChange?: any;
    setImageDetails: (images?: ImageDetails[]) => void;
    imageDetails?: ImageDetails[];
    contactData?: MemberDetailsDto;
    form?: FormInstance<any>;
}

const ContactFormSection: FunctionComponent<ContactFormSectionProps> = ({
    radioButtonValue,
    errors,
    handleOnContactChange,
    handleOnFirstNameChange,
    handleOnLastNameChange,
    selectedExistingMembershipId,
    selectedExistingContactId,
    handleOnContactLabelChange,
    setImageDetails,
    imageDetails,
    contactData,
    form,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const memberService = useService(MemberService);
    const expertiseService = useService(ExpertiseService);
    const pageSize = 25;
    //members
    const [membersCurrentPage, setMembersCurrentPage] = useState(0);
    const [membersSearchResults, setMembersSearchResults] = useState<Member[]>([]);
    const [membersSearchTerm, setMembersSearchTerm] = useState('');
    const [membersMaxResults, setMembersMaxResults] = useState(false);
    const [membersOptions, setMembersOptions] = useState<SelectCustomGroupOption[]>([]);
    const [selectedMemberOption, setSelectedMemberOption] = useState<SelectCustomOption[]>([]);
    const [expertisesOptions, setExpertisesOptions] = useState<ExpertisesOptions>({ options: [] });
    const [selectedExpertisesIds, SetSelectedExpertisesIds] = useState<string[]>([]);
    const { globalLoadingStore, toastStore } = useStores();
    const [previousExistingMembershipId, setPreviousExistingMembershipId] = useState<
        string | undefined
    >(undefined);
    const [tipModalData, setTipModalData] = useState<TipModalProps>({
        visible: false,
        onComplete: undefined,
        title: '',
        content: '',
    });
    const [selectLoading, setSelectLoading] = useState(false);

    const contactEdition = contactData ? true : false;

    //#endregion
    const resetMembersSearch = (): void => {
        setMembersCurrentPage(0);
        setMembersSearchResults([]);
        setMembersSearchTerm('');
        setMembersMaxResults(false);
    };

    //#region Event handlers

    const handleMembersKeywordsChange = useCallback((value: string): void => {
        resetMembersSearch();
        setMembersSearchTerm(value);
    }, []);

    const handleMembersMenuScrollToBottom = (): void => {
        if (!membersMaxResults) {
            setMembersCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handleTipModalClick = (title: string, content: string): void => {
        setTipModalData({
            visible: true,
            onComplete: () =>
                setTipModalData({
                    ...tipModalData,
                    visible: false,
                }),
            title: title,
            content: content,
        });
    };

    const handleExprtisesSelect = useCallback((values: any): void => {
        if (values === '') {
            SetSelectedExpertisesIds([]);
        } else {
            SetSelectedExpertisesIds(values?.map((x: any) => x.value));
        }
    }, []);

    const fetchExpertises = useCallback(async () => {
        globalLoadingStore.addLoading();

        try {
            const response = await expertiseService.getExpertises({ page: 0, pageSize: 100 });
            setExpertisesOptions({ selected: response[0], options: response });
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, expertiseService, toastStore]);

    useEffect(() => {
        fetchExpertises();
    }, [fetchExpertises]);

    const searchMembers = async (
        page: number,
        searchTerm: string,
        selectedExistingMembershipId?: string
    ): Promise<Member[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
            sortFirstMembershipId: selectedExistingMembershipId,
        };

        const [results, totalItemCount] = await memberService.getMembers(args);

        if (results.length + pageSize * page >= totalItemCount) {
            setMembersMaxResults(true);
        }
        setSelectLoading(false);
        return results;
    };

    const debounceMembersSearch = useRef(
        debounce((page: number, searchTerm: string, selectedExistingMembershipId?: string) => {
            searchMembers(page, searchTerm, selectedExistingMembershipId).then((results) => {
                setMembersSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    //#endregion

    //#region Effects

    useEffect(() => {
        if (previousExistingMembershipId !== selectedExistingMembershipId) {
            setPreviousExistingMembershipId(selectedExistingMembershipId);
            resetMembersSearch();
        }
        setSelectLoading(true);
        debounceMembersSearch.current(
            membersCurrentPage,
            membersSearchTerm,
            selectedExistingMembershipId
        );
    }, [
        membersCurrentPage,
        membersSearchTerm,
        radioButtonValue,
        selectedExistingMembershipId,
        previousExistingMembershipId,
    ]);

    useEffect(() => {
        const searchResults = membersSearchResults?.map(
            (member: Member) =>
                ({
                    value: member?.id,
                    label: member?.name,
                    imageUrl: member?.imageUrl,
                    badge: undefined,
                    content: member.contactInfo?.email,
                    isInSortFirstMembership: member.isInSortFirstMembership,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedMemberOption);

        const groupedMembersSearchResultOptions = [
            {
                label: t('company_members'),
                options: merged?.filter((member) => {
                    return member.isInSortFirstMembership;
                }),
            } as SelectCustomGroupOption,
            {
                label: t('other_contacts'),
                options: merged?.filter((member) => {
                    return !member.isInSortFirstMembership;
                }),
            } as SelectCustomGroupOption,
        ];

        setMembersOptions(groupedMembersSearchResultOptions);
    }, [membersSearchResults, t, selectedMemberOption]);

    useEffect(() => {
        if (contactEdition && contactData && form)
            form.setFieldsValue({
                ['contact.salutation']: contactData.salutation,
                ['contact.firstName']: contactData.firstName,
                ['contact.middleName']: contactData.middleName,
                ['contact.lastName']: contactData.lastName,
                ['contact.birthDate']: contactData.birthDate ? moment(contactData.birthDate) : undefined,
                ['contact.wifiPassword']: contactData.wifiPassword,
                ['contact.jobPosition']: contactData.jobPosition,
                ['contact.introduction']: contactData.introduction,
                ['contact.contactInfo.phoneNumber2']: contactData.contactInfo?.phone2,
                ['contact.contactInfo.phoneNumber']: contactData.contactInfo?.phone1,
                ['contact.contactInfo.email']: contactData.contactInfo?.email,
                ['contact.contactInfo.angelListUrl']: contactData.contactInfo?.angelListUrl,
                ['contact.contactInfo.twitterUrl']: contactData.contactInfo?.twitterUrl,
                ['contact.contactInfo.facebookUrl']: contactData.contactInfo?.facebookUrl,
                ['contact.contactInfo.linkedInUrl']: contactData.contactInfo?.linkedInUrl,
                ['contact.contactInfo.instagramUrl']: contactData.contactInfo?.instagramUrl,
                ['contact.address.addressLine1']: contactData.address?.addressLine1,
                ['contact.address.city']: contactData.address?.city,
                ['contact.address.state']: contactData.address?.state,
                ['contact.address.country']: contactData.address?.country,
                ['contact.address.postalCode']: contactData.address?.postalCode,
                ['contact.isPrivate']: !contactData.isPrivate,
            });

        const expertiseIds: string[] = [];
        contactData?.expertises?.forEach((expertise) => {
            if (expertise?.id) expertiseIds.push(expertise.id);
        });

        SetSelectedExpertisesIds(expertiseIds);
    }, [contactEdition, form]);
    //#endregion

    //#region Render
    return (
        <div className="ContactFormSection">
            {radioButtonValue === OpportunityRadioButtonValues.Existing && (
                <>
                    <Title level={4}>{t('Contact.choose_existing_contact')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={10}>
                            <ValidatedFormItem label=" " name="contactId" errors={errors} required>
                                <SelectCustom
                                    groupedOptions={membersOptions}
                                    defaultImg={<User fill={theme['white']} />}
                                    strongLabel={true}
                                    placeholder={t('SelectCustom.placeholder_default')}
                                    onKeywordsChange={handleMembersKeywordsChange}
                                    onMenuScrollToBottom={handleMembersMenuScrollToBottom}
                                    hideSelectedOptions={false}
                                    onChangeGetLabel={handleOnContactLabelChange}
                                    onChange={(value): void => {
                                        handleOnContactChange(value);
                                        const options = [value as SelectCustomOption];
                                        setSelectedMemberOption(options);
                                    }}
                                    selected={
                                        selectedExistingContactId
                                            ? [selectedExistingContactId]
                                            : null
                                    }
                                    isLoading={selectLoading}
                                    idAttribute="contactId"
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                </>
            )}

            {(radioButtonValue === OpportunityRadioButtonValues.New ||
                radioButtonValue === undefined) && (
                <>
                    <Title level={4}>{t('basic_information')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                label={t('main_image')}
                                errors={errors}
                                name="contact.image"
                            >
                                <ImagePicker
                                    images={
                                        imageDetails?.[0].base64 || contactData?.imageUrl
                                            ? [
                                                  {
                                                      url: imageDetails?.[0].base64
                                                          ? `data:image/png;base64,${imageDetails?.[0].base64}`
                                                          : contactData?.imageUrl,
                                                      uid: 'mainImage',
                                                  } as UploadFile,
                                              ]
                                            : undefined
                                    }
                                    setImagesDetails={(images?: ImageDetails[]) =>
                                        setImageDetails(images)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                name="contact.salutation"
                                errors={errors}
                                label={t('Lead.salutation')}
                            >
                                <Select>
                                    {Object.keys(SalutationDto).map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`Lead.salutation_${x}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                name="contact.firstName"
                                errors={errors}
                                label={t('first_name')}
                                required
                            >
                                <Input
                                    onChange={(e): void => {
                                        if (handleOnFirstNameChange)
                                            handleOnFirstNameChange(e.currentTarget.value);
                                    }}
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                name="contact.middleName"
                                errors={errors}
                                label={t('User.middle_name')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                name="contact.lastName"
                                errors={errors}
                                label={t('last_name')}
                                required
                            >
                                <Input
                                    onChange={(e): void => {
                                        if (handleOnLastNameChange)
                                            handleOnLastNameChange(e.currentTarget.value);
                                    }}
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem name="contact.birthDate" label={t('birth_date')}>
                                <DatePicker style={{ width: '100%' }} />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                name="contact.wifiPassword"
                                errors={errors}
                                label={t('wifi_password')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>{t('professional_information')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="contact.jobPosition"
                                errors={errors}
                                label={t('Lead.job_position')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={24}>
                            <ValidatedFormItem
                                name="contact.introduction"
                                errors={errors}
                                label={t('introduction')}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={24}>
                            <ValidatedFormItem name="contact.expertises" label={t('expertises')}>
                                <SelectCustom
                                    options={expertisesOptions.options.map(
                                        (expertise) =>
                                            ({
                                                value: expertise.id,
                                                label: expertise.description,
                                            } as SelectCustomOption)
                                    )}
                                    strongLabel={true}
                                    hideSelectedOptions={false}
                                    isMulti
                                    onChange={handleExprtisesSelect}
                                    selected={selectedExpertisesIds}
                                    idAttribute="contact.expertises"
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>{t('contact_information')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="contact.contactInfo.phone2"
                                errors={errors}
                                label={t('phone_number')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="contact.contactInfo.phone1"
                                errors={errors}
                                label={t('User.mobile_number')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="contact.contactInfo.email"
                                errors={errors}
                                label={t('email')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="contact.contactInfo.angelListUrl"
                                errors={errors}
                                label={t('angelList_url')}
                            >
                                <Input placeholder={t('url_example')} />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="contact.contactInfo.twitterUrl"
                                errors={errors}
                                label={t('twitter_url')}
                            >
                                <Input placeholder={t('url_example')} />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="contact.contactInfo.facebookUrl"
                                errors={errors}
                                label={t('facebook_url')}
                            >
                                <Input placeholder={t('url_example')} />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="contact.contactInfo.linkedInUrl"
                                errors={errors}
                                label={t('linkedIn_url')}
                            >
                                <Input placeholder={t('url_example')} />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="contact.contactInfo.instagramUrl"
                                errors={errors}
                                label={t('instagram_url')}
                            >
                                <Input placeholder={t('url_example')} />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>{t('address')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name="contact.address.addressLine1"
                                errors={errors}
                                label={t('address')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="contact.address.city"
                                errors={errors}
                                label={t('city')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="contact.address.state"
                                errors={errors}
                                label={t('state')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="contact.address.country"
                                errors={errors}
                                label={t('country')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="contact.address.postalCode"
                                errors={errors}
                                label={t('zip_code')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>{t('cg_plus_settings')}</Title>
                    <Row>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="contact.isPrivate"
                                valuePropName={'checked'}
                                initialValue={false}
                            >
                                <Checkbox>
                                    {t('User.contact_profile_will_be_private')}

                                    <button
                                        className="tip-button"
                                        type="button"
                                        onClick={(): void =>
                                            handleTipModalClick(
                                                'User.contact_profile_will_be_private_tip_title',
                                                'User.contact_profile_will_be_private_tip'
                                            )
                                        }
                                    >
                                        <Icon iconName="Info" />
                                    </button>
                                </Checkbox>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <TipModal
                        visible={tipModalData.visible}
                        onComplete={tipModalData.onComplete}
                        title={tipModalData.title}
                        content={tipModalData.content}
                    />
                </>
            )}
        </div>
    );

    //#endregion
};

export default React.memo(ContactFormSection);
