// GENERATED FILE - DO NOT MODIFY
import { CreateReminderRequestDto } from 'Api/Features/Reminders/Dtos/CreateReminderRequestDto';
import { GetRemindersRequestDto } from 'Api/Features/Reminders/Dtos/GetRemindersRequestDto';
import { GetRemindersResponseDto } from 'Api/Features/Reminders/Dtos/GetRemindersResponseDto';
import { ReminderDto } from 'Api/Features/Reminders/Dtos/ReminderDto';
import { UpdateReminderRequestDto } from 'Api/Features/Reminders/Dtos/UpdateReminderRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class ReminderProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getReminder(reminderId: string): Promise<ReminderDto | null> {
        const uri = this.buildUri(
            "/reminders/{reminderId}",
            { reminderId: reminderId },
            null
        );

        const data: ReminderDto | null = await this.httpClient.get<ReminderDto | null>(uri);
        return data;
    }

    public async getReminders(request: GetRemindersRequestDto | null): Promise<GetRemindersResponseDto | null> {
        const uri = this.buildUri(
            "/reminders",
            null,
            { ...request || undefined }
        );

        const data: GetRemindersResponseDto | null = await this.httpClient.get<GetRemindersResponseDto | null>(uri);
        return data;
    }

    public async createReminder(request: CreateReminderRequestDto | null): Promise<string | null> {
        const uri = this.buildUri(
            "/reminders",
            null,
            null
        );

        const data: string | null = await this.httpClient.post<CreateReminderRequestDto | null, string | null>(uri, request);
        return data;
    }

    public async updateReminder(reminderId: string, request: UpdateReminderRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/reminders/{reminderId}",
            { reminderId: reminderId },
            null
        );

        await this.httpClient.patch<UpdateReminderRequestDto | null, void>(uri, request);
    }

    public async deleteReminder(reminderId: string): Promise<void> {
        const uri = this.buildUri(
            "/reminders/{reminderId}",
            { reminderId: reminderId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}