import { Button, Col, Form, Input, Row, Select } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { LeadStatusDto } from 'Api/Features/Leads/Dtos/LeadStatusDto';
import { UpdateLeadRequestDto } from 'Api/Features/Leads/Dtos/UpdateLeadRequestDto';
import { IndustryDto } from 'Api/Features/Memberships/Dtos/IndustryDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Lead as LeadIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { toUpdateLeadRequestDto } from 'Models/Leads/DtoOutput';
import { Lead } from 'Models/Leads/Lead';
import { LightCampusDetailsInfo } from 'Models/Location/LightCampusInfo';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MoveToEngagedSchema } from 'Schemas';
import { LeadService } from 'Services/LeadService';
import './move-to-engaged-modal.less';

const { Option } = Select;

const formGutter: [Gutter, Gutter] = [40, 0];

interface MoveToEngagedModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    lead?: Lead;
}

const MoveToEngagedModal: FunctionComponent<MoveToEngagedModalProps> = ({
    visible,
    onComplete,
    lead,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(MoveToEngagedSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore, locationStore } = useStores();
    const leadService = useService(LeadService);
    //#endregion

    //#region Effects
    useEffect(() => {
        if (lead) {
            form.setFieldsValue({
                numberOfEmployees: lead.numberOfEmployees,
                industry: lead.industry,
                email: lead.email,
            });
        }
    }, [lead, form]);
    //#endregion

    const dismiss = (success = false, id?: string): void => {
        onComplete(success, id);
        form.resetFields();
        resetErrors();
    };

    //#region Submit / Exit
    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <LeadIcon />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText:
                    lead !== undefined
                        ? t('Lead.lead_confirm_positive_edit')
                        : t(`Lead.lead_confirm_positive`),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const leadObject = {
            ...lead,
            status: LeadStatusDto.Engaged,
            numberOfEmployees: formValues.numberOfEmployees || undefined,
            industry: formValues.industry || undefined,
            email: formValues.email || undefined,
            campus: {
                id: formValues.campusId
            }
        }

        const request: UpdateLeadRequestDto = toUpdateLeadRequestDto(leadObject);
        request.note = formValues.note;

        if (!(await validateForm(request))) return;
        try {
            globalLoadingStore.addLoading();
            let createdId = '';

            if (lead?.id !== undefined) await leadService.updateLead(lead.id!, request);
            else createdId = await leadService.createLead(request);

            toastStore.toast({
                type: 'success',
                messageKey:
                    lead?.id !== undefined
                        ? `Lead.status_change_success`
                        : 'Lead.lead_create_success',
            });
            dismiss(true, createdId);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Lead.move_to_engaged')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="MoveToEngagedModal">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <p className="sub-title">{t('Lead.move_to_interested_sub_title')}</p>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="email"
                                errors={errors}
                                label={t('email')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="numberOfEmployees"
                                errors={errors}
                                label={t('Lead.number_of_employees')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="industry"
                                errors={errors}
                                label={t('Lead.industry')}
                            >
                                <Select>
                                    {Object.keys(IndustryDto).map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`Lead.industry_${x}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="campusId"
                                errors={errors}
                                label={t('location')}
                                initialValue={lead?.campus?.id || undefined}
                            >
                                <Select>
                                    {locationStore.locations?.map((x: LightCampusDetailsInfo) => (
                                        <Option key={x.id!} value={x.id!}>
                                            {x.name}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="note"
                                label={t('Lead.lead_add_a_note')}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>

                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(MoveToEngagedModal);
