import { inject } from 'aurelia-dependency-injection';
import { ManagerUserProxy } from '../Api/Features/ManagerUsers/ManagerUserProxy';
import { ApiService } from './ApiService';
import { ManagerUserDto } from 'Api/Features/ManagerUsers/Dtos/ManagerUserDto';
import { ManagementRoleWithContextDto } from 'Api/Features/Accounts/Dtos/ManagementRoleWithContextDto';

@inject(ManagerUserProxy)
export class UserPermissionsService extends ApiService {
    constructor(private readonly managerUserProxy: ManagerUserProxy) {
        super();
    }

    public async getUserInfo(userId: string): Promise<ManagerUserDto | null> {
        const response: ManagerUserDto | null = await this.managerUserProxy.getManagerUser(userId);

        return response || null;
    }

    public getPermissions(managerUser: ManagerUserDto): ManagementRoleWithContextDto[] | null {
        const roles = managerUser.roles?.map((x) => {
            const map: ManagementRoleWithContextDto = {
                name: x?.name || undefined,
                context: x?.context || null,
            };
            return map;
        });
        return roles || null;
    }
}
