import { Button, Col, Form, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { GetMembershipMembersRequestDto } from 'Api/Features/Memberships/Dtos/GetMembershipMembersRequestDto';
import { GetMembershipMembersResponseItemDto } from 'Api/Features/Memberships/Dtos/GetMembershipMembersResponseItemDto';
import { MembershipOwnershipTransferRequestDto } from 'Api/Features/Memberships/Dtos/MembershipOwnershipTransferRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { User } from 'Components/icons';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { debounce } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState,  } from 'react';
import { useTranslation } from 'react-i18next';
import { TransferCompanyOwnershipSchema } from 'Schemas/TransferCompanyOwnershipSchema';
import { MembershipService } from 'Services/MembershipService';
import { theme } from 'variant';
import './transfer-company-ownership.less';

interface TransferCompanyOwnershipModal {
    visible: boolean;
    onComplete: (success: boolean) => void;
    membershipId: string;
    currentOwnerId?: string | null;
}

const formGutter: [Gutter, Gutter] = [40, 0];

const TransferCompanyOwnershipModal: FunctionComponent<TransferCompanyOwnershipModal> = ({
    visible,
    onComplete,
    membershipId,
    currentOwnerId
}) => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const membershipService = useService(MembershipService);
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(TransferCompanyOwnershipSchema, form);

    const pageSize = 25;
    const [membersCurrentPage, setMembersCurrentPage] = useState(0);
    const [membersSearchResults, setMembersSearchResults] = useState<GetMembershipMembersResponseItemDto[]>([]);
    const [membersSearchTerm, setMembersSearchTerm] = useState('');
    const [membersMaxResults, setMembersMaxResults] = useState(false);
    const [membersOptions, setMembersOptions] = useState<SelectCustomOption[]>([]);
    const [selectedMemberOption, setSelectedMemberOption] = useState<SelectCustomOption[]>([]);
    const [selectLoading, setSelectLoading] = useState(false);
    const [selectedMemberId, setSelectedMemberId] = useState<string | undefined>(undefined);


    const resetMembersSearch = (): void => {
        setMembersCurrentPage(0);
        setMembersSearchResults([]);
        setMembersSearchTerm('');
        setMembersMaxResults(false);
    };

    const handleMembersKeywordsChange = useCallback((value: string): void => {
        resetMembersSearch();
        setMembersSearchTerm(value);
    }, []);

    const handleMembersMenuScrollToBottom = (): void => {
        if (!membersMaxResults) {
            setMembersCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const searchMembers = async (
        page: number,
        searchTerm: string,
    ): Promise<GetMembershipMembersResponseItemDto[]> => {
        const args: GetMembershipMembersRequestDto = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
        };

        const [results, totalItemCount] = await membershipService.getMembershipMembers(membershipId, args);

        if (results.length + pageSize * page >= totalItemCount) {
            setMembersMaxResults(true);
        }
        setSelectLoading(false);

        return results.filter(member => member.id !== currentOwnerId);
    };

    const debounceMembersSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchMembers(page, searchTerm).then((results) => {
                setMembersSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    useEffect(() => {
        debounceMembersSearch.current(membersCurrentPage, membersSearchTerm);
    }, [membersCurrentPage, membersSearchTerm]);

    useEffect(() => {
        const searchResults = membersSearchResults?.map(
            (x: GetMembershipMembersResponseItemDto) =>
                ({
                    value: x?.id,
                    label: `${x?.firstName} ${x?.lastName}`,
                    imageUrl: x?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedMemberOption);

        setMembersOptions(merged);
    }, [membersSearchResults, t, selectedMemberOption]);

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <User />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`model_confirm_positive_edit`, {param1 : 'ownership'}),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            const formValues = form.getFieldsValue();
            const request: MembershipOwnershipTransferRequestDto = {
                membershipId: membershipId,
                newOwnerAccountId: formValues.newOwnerAccountId?.value ?? undefined
            }

            if (!(await validateForm(request))) return;

            await membershipService.transferMembershipOwnership(request)

            toastStore.toast({
                type: 'success',
                messageKey: `Membership.ownership_tranfer_success`,
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion
    return (
        <BaseModal
            visible={visible}
            title={t('Lead.transfer_ownership')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="TransferCompanyOwnershipModal">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem label={t('member')} name="newOwnerAccountId" errors={errors} required>
                                <SelectCustom
                                    options={membersOptions}
                                    strongLabel={true}
                                    defaultImg={<User fill={theme['white']} />}
                                    placeholder={t('SelectCustom.placeholder_default')}
                                    hideSelectedOptions={false}
                                    onKeywordsChange={handleMembersKeywordsChange}
                                    onMenuScrollToBottom={handleMembersMenuScrollToBottom}
                                    onChange={(value): void => {
                                        const options = [value as SelectCustomOption];
                                        setSelectedMemberOption(options);
                                        setSelectedMemberId(value.value);
                                    }}
                                    selected={selectedMemberId ? [selectedMemberId] : null}
                                    isLoading={selectLoading}
                                    idAttribute={'newOwnerAccountId'}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
};

export default TransferCompanyOwnershipModal;
