import { useService, useStores } from 'Hooks';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { CampusService } from 'Services/CampusService';

const LocationStripeConnectConfirm: FunctionComponent = () => {
    const campusService = useService(CampusService);
    const { globalLoadingStore, toastStore } = useStores();
    const location = useLocation();
    const history = useHistory();

    const code = new URLSearchParams(decodeURI(location.search)).get('code');
    const urlState = new URLSearchParams(decodeURI(location.search)).get('state');
    let campusId = '';

    const fetch = useCallback(async () => {
        globalLoadingStore.addLoading();

        try {
            if (urlState) {
                const stateObj = JSON.parse(urlState);
                campusId = stateObj.CampusId;

                await campusService.confirmCampusStripeConnectConfiguration(campusId, {
                    authorizationCode: code,
                });
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
            history.push(`administration/integration/${campusId}`);
        }
    }, [campusService, globalLoadingStore, toastStore]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return <div className="LocationStripeConnectConfirm"></div>;
};

export default LocationStripeConnectConfirm;
