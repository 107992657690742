import { ExpertiseDto } from 'Api/Features/Expertises/Dtos/ExpertiseDto';
import { GetExpertisesRequestDto } from 'Api/Features/Expertises/Dtos/GetExpertisesRequestDto';
import { ExpertiseProxy } from 'Api/Features/Expertises/ExpertiseProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(ExpertiseProxy)
export class ExpertiseService extends ApiService {
    constructor(private readonly expertiseProxy: ExpertiseProxy) {
        super();
    }

    public async getExpertise(expertiseId: string): Promise<ExpertiseDto | null> {
        const data: ExpertiseDto | null = await this.expertiseProxy.getExpertise(expertiseId);
        return data;
    }

    public async getExpertises(request: GetExpertisesRequestDto | null): Promise<ExpertiseDto[]> {
        const data: (ExpertiseDto | null)[] | null = await this.expertiseProxy.getExpertises(
            request
        );
        const nonNullData = data ? data?.filter((item): item is ExpertiseDto => item !== null) : [];
        return nonNullData;
    }
}
