// GENERATED FILE - DO NOT MODIFY
import { AcceptMembershipInvitationRequestDto } from 'Api/Features/Memberships/Dtos/AcceptMembershipInvitationRequestDto';
import { CancelMembershipInvitationRequestDto } from 'Api/Features/Memberships/Dtos/CancelMembershipInvitationRequestDto';
import { CreateMembershipRequestDto } from 'Api/Features/Memberships/Dtos/CreateMembershipRequestDto';
import { CreateMembershipResponseDto } from 'Api/Features/Memberships/Dtos/CreateMembershipResponseDto';
import { ExportMembershipsRequestDto } from 'Api/Features/Memberships/Dtos/ExportMembershipsRequestDto';
import { GetMembershipMainUnitResponseDto } from 'Api/Features/Memberships/Dtos/GetMembershipMainUnitResponseDto';
import { GetMembershipMembersRequestDto } from 'Api/Features/Memberships/Dtos/GetMembershipMembersRequestDto';
import { GetMembershipMembersResponseDto } from 'Api/Features/Memberships/Dtos/GetMembershipMembersResponseDto';
import { GetMembershipNoteResponseDto } from 'Api/Features/Memberships/Dtos/GetMembershipNoteResponseDto';
import { GetMembershipsRequestDto } from 'Api/Features/Memberships/Dtos/GetMembershipsRequestDto';
import { GetMembershipsResponseDto } from 'Api/Features/Memberships/Dtos/GetMembershipsResponseDto';
import { ImportMembersPreviewResponseDto } from 'Api/Features/Memberships/Dtos/ImportMembersPreviewResponseDto';
import { ImportMembersRequestDto } from 'Api/Features/Memberships/Dtos/ImportMembersRequestDto';
import { InviteMembersRequestDto } from 'Api/Features/Memberships/Dtos/InviteMembersRequestDto';
import { InviteMembersResponseDto } from 'Api/Features/Memberships/Dtos/InviteMembersResponseDto';
import { LeaveMembershipRequestDto } from 'Api/Features/Memberships/Dtos/LeaveMembershipRequestDto';
import { LightMembershipDto } from 'Api/Features/Memberships/Dtos/LightMembershipDto';
import { MembershipDetailsDto } from 'Api/Features/Memberships/Dtos/MembershipDetailsDto';
import { MembershipDetailsPlusDto } from 'Api/Features/Memberships/Dtos/MembershipDetailsPlusDto';
import { MembershipDto } from 'Api/Features/Memberships/Dtos/MembershipDto';
import { MembershipEmailInvitationWithStatusDto } from 'Api/Features/Memberships/Dtos/MembershipEmailInvitationWithStatusDto';
import { MembershipOwnershipTransferRequestDto } from 'Api/Features/Memberships/Dtos/MembershipOwnershipTransferRequestDto';
import { MembershipPublicDetailsDto } from 'Api/Features/Memberships/Dtos/MembershipPublicDetailsDto';
import { MembershipRemoveMemberRequestDto } from 'Api/Features/Memberships/Dtos/MembershipRemoveMemberRequestDto';
import { ParseMemberImportationFileRequestDto } from 'Api/Features/Memberships/Dtos/ParseMemberImportationFileRequestDto';
import { ParseMemberImportationFileResponseDto } from 'Api/Features/Memberships/Dtos/ParseMemberImportationFileResponseDto';
import { ProposalDto } from 'Api/Features/Memberships/Dtos/ProposalDto';
import { RejectMembershipInvitationRequestDto } from 'Api/Features/Memberships/Dtos/RejectMembershipInvitationRequestDto';
import { UpdateEmailInvitationRolesRequestDto } from 'Api/Features/Memberships/Dtos/UpdateEmailInvitationRolesRequestDto';
import { UpdateMembershipMemberRequestDto } from 'Api/Features/Memberships/Dtos/UpdateMembershipMemberRequestDto';
import { UpdateMembershipNoteRequestDto } from 'Api/Features/Memberships/Dtos/UpdateMembershipNoteRequestDto';
import { UpdateMembershipRequestDto } from 'Api/Features/Memberships/Dtos/UpdateMembershipRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class MembershipProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getMembership(membershipId: string | null, excludePendingMembers?: boolean): Promise<MembershipDetailsPlusDto | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}",
            { membershipId: membershipId },
            { excludePendingMembers: (excludePendingMembers === null || excludePendingMembers === undefined) ? excludePendingMembers : excludePendingMembers.toString() }
        );

        const data: MembershipDetailsPlusDto | null = await this.httpClient.get<MembershipDetailsPlusDto | null>(uri);
        return data;
    }

    public async getMembershipPublicProfile(membershipId: string | null): Promise<MembershipPublicDetailsDto | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/public",
            { membershipId: membershipId },
            null
        );

        const data: MembershipPublicDetailsDto | null = await this.httpClient.get<MembershipPublicDetailsDto | null>(uri);
        return data;
    }

    public async getMemberships_V1_1(request: GetMembershipsRequestDto | null): Promise<(MembershipDto | null)[] | null> {
        const uri = this.buildUri(
            "/memberships",
            null,
            { ...request || undefined }
        );

        const data: (MembershipDto | null)[] | null = await this.httpClient.get<(MembershipDto | null)[] | null>(uri);
        return data;
    }

    public async getMemberships(request: GetMembershipsRequestDto | null): Promise<GetMembershipsResponseDto | null> {
        const uri = this.buildUri(
            "/memberships",
            null,
            { ...request || undefined }
        );

        const data: GetMembershipsResponseDto | null = await this.httpClient.get<GetMembershipsResponseDto | null>(uri);
        return data;
    }

    public async exportMemberships(request: ExportMembershipsRequestDto | null): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/memberships/export",
            null,
            { ...request || undefined }
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }

    public async getCurrentAccountMemberships(excludePendingMembers?: boolean): Promise<(MembershipDetailsDto | null)[] | null> {
        const uri = this.buildUri(
            "/accounts/current/memberships",
            null,
            { excludePendingMembers: (excludePendingMembers === null || excludePendingMembers === undefined) ? excludePendingMembers : excludePendingMembers.toString() }
        );

        const data: (MembershipDetailsDto | null)[] | null = await this.httpClient.get<(MembershipDetailsDto | null)[] | null>(uri);
        return data;
    }

    public async createMembership_V1_1(request: CreateMembershipRequestDto | null): Promise<MembershipDetailsDto | null> {
        const uri = this.buildUri(
            "/memberships/create",
            null,
            null
        );

        const data: MembershipDetailsDto | null = await this.httpClient.post<CreateMembershipRequestDto | null, MembershipDetailsDto | null>(uri, request);
        return data;
    }

    public async createMembership(request: CreateMembershipRequestDto | null): Promise<CreateMembershipResponseDto | null> {
        const uri = this.buildUri(
            "/memberships",
            null,
            null
        );

        const data: CreateMembershipResponseDto | null = await this.httpClient.post<CreateMembershipRequestDto | null, CreateMembershipResponseDto | null>(uri, request);
        return data;
    }

    public async updateMembership_V1_1(request: UpdateMembershipRequestDto | null): Promise<MembershipDetailsDto | null> {
        const uri = this.buildUri(
            "/memberships/update",
            null,
            null
        );

        const data: MembershipDetailsDto | null = await this.httpClient.post<UpdateMembershipRequestDto | null, MembershipDetailsDto | null>(uri, request);
        return data;
    }

    public async updateMembership(membershipId: string | null, request: UpdateMembershipRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/memberships/{membershipId}",
            { membershipId: membershipId },
            null
        );

        await this.httpClient.patch<UpdateMembershipRequestDto | null, void>(uri, request);
    }

    public async deleteMembership(membershipId: string | null): Promise<void> {
        const uri = this.buildUri(
            "/memberships/{membershipId}",
            { membershipId: membershipId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async getMembershipProposals(): Promise<(ProposalDto | null)[] | null> {
        const uri = this.buildUri(
            "/memberships/proposals",
            null,
            null
        );

        const data: (ProposalDto | null)[] | null = await this.httpClient.get<(ProposalDto | null)[] | null>(uri);
        return data;
    }

    public async getMembershipInvitation(membershipId: string | null): Promise<LightMembershipDto | null> {
        const uri = this.buildUri(
            "/memberships/invitations/{membershipId}",
            { membershipId: membershipId },
            null
        );

        const data: LightMembershipDto | null = await this.httpClient.get<LightMembershipDto | null>(uri);
        return data;
    }

    public async importMembers(membershipId: string | null, request: ImportMembersRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/import-members",
            { membershipId: membershipId },
            null
        );

        await this.httpClient.post<ImportMembersRequestDto | null, void>(uri, request);
    }

    public async importMembersPreview(membershipId: string | null, request: ImportMembersRequestDto | null): Promise<ImportMembersPreviewResponseDto | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/import-members-preview",
            { membershipId: membershipId },
            null
        );

        const data: ImportMembersPreviewResponseDto | null = await this.httpClient.post<ImportMembersRequestDto | null, ImportMembersPreviewResponseDto | null>(uri, request);
        return data;
    }

    public async inviteMembers(membershipInvitationRequest: InviteMembersRequestDto | null): Promise<InviteMembersResponseDto | null> {
        const uri = this.buildUri(
            "/memberships/invite",
            null,
            null
        );

        const data: InviteMembersResponseDto | null = await this.httpClient.post<InviteMembersRequestDto | null, InviteMembersResponseDto | null>(uri, membershipInvitationRequest);
        return data;
    }

    public async getMembershipEmailInvitation(token: string): Promise<MembershipEmailInvitationWithStatusDto | null> {
        const uri = this.buildUri(
            "/memberships/emailinvitations",
            null,
            { token: token }
        );

        const data: MembershipEmailInvitationWithStatusDto | null = await this.httpClient.get<MembershipEmailInvitationWithStatusDto | null>(uri);
        return data;
    }

    public async leaveMembership(leaveMembershipRequest: LeaveMembershipRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/memberships/leave",
            null,
            null
        );

        await this.httpClient.post<LeaveMembershipRequestDto | null, void>(uri, leaveMembershipRequest);
    }

    public async removeUserFromMembership(membershipRemoveMemberRequest: MembershipRemoveMemberRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/memberships/remove",
            null,
            null
        );

        await this.httpClient.post<MembershipRemoveMemberRequestDto | null, void>(uri, membershipRemoveMemberRequest);
    }

    public async acceptMembershipInvitation(acceptInvitationRequest: AcceptMembershipInvitationRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/memberships/accept",
            null,
            null
        );

        await this.httpClient.post<AcceptMembershipInvitationRequestDto | null, void>(uri, acceptInvitationRequest);
    }

    public async rejectMembershipInvitation(rejectInvitationRequest: RejectMembershipInvitationRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/memberships/reject",
            null,
            null
        );

        await this.httpClient.post<RejectMembershipInvitationRequestDto | null, void>(uri, rejectInvitationRequest);
    }

    public async cancelMembershipInvitation(cancelInvitationRequest: CancelMembershipInvitationRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/memberships/cancel",
            null,
            null
        );

        await this.httpClient.post<CancelMembershipInvitationRequestDto | null, void>(uri, cancelInvitationRequest);
    }

    public async cancelMembershipEmailInvitation(emailInvitationId: string): Promise<void> {
        const uri = this.buildUri(
            "/memberships/emailinvitations/{emailInvitationId}/cancel",
            { emailInvitationId: emailInvitationId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async transferMembershipOwnership(request: MembershipOwnershipTransferRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/memberships/transfer",
            null,
            null
        );

        await this.httpClient.post<MembershipOwnershipTransferRequestDto | null, void>(uri, request);
    }

    public async updateMembershipMemberRoles(request: UpdateMembershipMemberRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/memberships/updateMember",
            null,
            null
        );

        await this.httpClient.post<UpdateMembershipMemberRequestDto | null, void>(uri, request);
    }

    public async updateMembershipEmailInvitationRoles(emailInvitationId: string, request: UpdateEmailInvitationRolesRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/memberships/emailinvitations/{emailInvitationId}/roles",
            { emailInvitationId: emailInvitationId },
            null
        );

        await this.httpClient.post<UpdateEmailInvitationRolesRequestDto | null, void>(uri, request);
    }

    public async getMembershipMainUnit(membershipId: string | null): Promise<GetMembershipMainUnitResponseDto | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/mainunit",
            { membershipId: membershipId },
            null
        );

        const data: GetMembershipMainUnitResponseDto | null = await this.httpClient.get<GetMembershipMainUnitResponseDto | null>(uri);
        return data;
    }

    public async parseMemberImportationFile(membershipId: string | null, request: ParseMemberImportationFileRequestDto | null): Promise<ParseMemberImportationFileResponseDto | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/parse-member-importation-file",
            { membershipId: membershipId },
            null
        );

        const data: ParseMemberImportationFileResponseDto | null = await this.httpClient.post<ParseMemberImportationFileRequestDto | null, ParseMemberImportationFileResponseDto | null>(uri, request);
        return data;
    }

    public async getMemberImportationFileTemplate(): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/member-importation-file-template",
            null,
            null
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }

    public async getMembershipNote(membershipId: string | null): Promise<GetMembershipNoteResponseDto | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/notes",
            { membershipId: membershipId },
            null
        );

        const data: GetMembershipNoteResponseDto | null = await this.httpClient.get<GetMembershipNoteResponseDto | null>(uri);
        return data;
    }

    public async updateMembershipNote(membershipId: string | null, request: UpdateMembershipNoteRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/notes",
            { membershipId: membershipId },
            null
        );

        await this.httpClient.put<UpdateMembershipNoteRequestDto | null, void>(uri, request);
    }

    public async getMembershipMembers(membershipId: string | null, request: GetMembershipMembersRequestDto | null): Promise<GetMembershipMembersResponseDto | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/members",
            { membershipId: membershipId },
            { ...request || undefined }
        );

        const data: GetMembershipMembersResponseDto | null = await this.httpClient.get<GetMembershipMembersResponseDto | null>(uri);
        return data;
    }
}