import React, { createContext, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { TopHeaderNavigation } from 'Components/top-header-navigation';
import './index.less';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MenuItem } from 'Components/top-header-navigation/top-header-navigation';
import { MemberDetailsDto } from 'Api/Features/Members/Dtos/MemberDetailsDto';
import { useService, useStores } from 'Hooks';
import { MemberService } from 'Services/MemberService';
import { CreditsService } from 'Services/CreditsService';
import { PaymentMethodService } from 'Services/PaymentMethodService';
import { PaymentMethodHolderTypeDto } from 'Api/Features/PaymentMethods/Dtos/PaymentMethodHolderTypeDto';
import { DayPassService } from 'Services/DayPassService';
import { BalanceTypeDto } from 'Api/Features/Credits/Dtos/BalanceTypeDto';
import { CreditBalanceDetailsDto } from 'Api/Features/Credits/Dtos/CreditBalanceDetailsDto';
import { AchPaymentMethodDto } from 'Api/Features/PaymentMethods/Dtos/AchPaymentMethodDto';
import { CreditCardPaymentMethodDto } from 'Api/Features/PaymentMethods/Dtos/CreditCardPaymentMethodDto';

export const ContactContext = createContext<ContactContext | undefined>(undefined);

interface ContactContext extends MemberDetailsDto {
    fetchContact: () => void;
    creditBalance: CreditBalanceDetailsDto[] | null;
    paymentMethod: AchPaymentMethodDto | CreditCardPaymentMethodDto | null;
    availableDayPasses: number;
}

const ContactsDetail: FunctionComponent = observer(({ children }: any) => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const [contactContext, setContactContext] = useState<ContactContext | undefined>();
    const { toastStore, globalLoadingStore } = useStores();

    const memberService = useService(MemberService);
    const creditsService = useService(CreditsService);
    const paymentMethodService = useService(PaymentMethodService);
    const dayPassService = useService(DayPassService);

    const fetchMember = useCallback(async () => {
        try {
            globalLoadingStore.addLoading();

            const memberResponse = await memberService.getMember(id);
            const creditBalance = await creditsService.getCreditBalances({
                id: id,
                campusId: null,
                types: [BalanceTypeDto.Personal]
            });
            const paymentMethod = await paymentMethodService.getHolderPaymentMethod(
                PaymentMethodHolderTypeDto.Personal,
                id
            );
            const [_, totalItemCount] = await dayPassService.getDayPasses({
                accountId: id,
                available: true,
                page: 0,
                pageSize: 1,
                consumableByAccountId: id
            });

            if (memberResponse)
                setContactContext({
                    ...memberResponse,
                    fetchContact: fetchMember,
                    creditBalance,
                    paymentMethod,
                    availableDayPasses: totalItemCount,
                });
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [memberService, id, t]);

    useEffect(() => {
        fetchMember();
    }, [fetchMember]);

    const menuItems: MenuItem[] = [
        {
            key: `/contacts/${id}/dashboard`,
            label: t('Contact.contacts_dashboard'),
        },
        {
            key: `/contacts/${id}/dashboard/invoices`,
            label: t('Contact.contacts_invoices_label'),
        },
        {
            key: `/contacts/${id}/dashboard/messages`,
            label: t('Contact.contacts_messages_label'),
        },
        {
            key: `/contacts/${id}/dashboard/booking-history`,
            label: t('Contact.contacts_booking_history'),
        },
    ];

    return (
        <ContactContext.Provider value={contactContext}>
            <div className="ContactsDetail">
                <TopHeaderNavigation menuItems={menuItems} hasDashboard/>

                {children}
            </div>
        </ContactContext.Provider>
    );
});

export default ContactsDetail;
