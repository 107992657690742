import { CheckInDto } from '../../Api/Features/CheckIns/Dtos/CheckInDto';
import { CheckInProperty } from './CheckInProperty';
import { CheckInSourceDto } from '../../Api/Features/CheckIns/Dtos/CheckInSourceDto';
import { CheckInCampusDto } from '../../Api/Features/CheckIns/Dtos/CheckInCampusDto';
import moment, { Moment } from 'moment';

export class CheckIn implements CheckInDto {
    constructor(dto: CheckInDto) {
        Object.assign(this, dto);
    }

    company: string | null = null;
    email: string | null = null;
    firstName: string | null = null;
    id = '';
    lastName: string | null = null;
    phoneNumber: string | null = null;
    properties: Array<CheckInProperty | null> | null = null;
    source: CheckInSourceDto | null = null;
    campus: CheckInCampusDto | null = null;
    date?: string = undefined;
    get dateMoment(): Moment | undefined { return moment.parseZone(this.date); }
    isFlagged: boolean | undefined;

    get name(): string | null {
        if (this.firstName && this.lastName) return `${this.firstName} ${this.lastName}`;
        if (this.firstName) return this.firstName;
        if (this.lastName) return this.lastName;
        return null;
    }
}
