import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const BarGraph: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.7076 13.4153H13.1375V4.85605C13.1375 4.69428 13.007 4.56369 12.8452 4.56369H10.711C10.5492 4.56369 10.4186 4.69428 10.4186 4.85605V13.4163H10.0814V7.68801C10.0814 7.52624 9.95086 7.39566 9.78908 7.39566H7.65488C7.49311 7.39566 7.36252 7.52624 7.36252 7.68801V13.4153H7.02534V6.5449C7.02534 6.38313 6.89475 6.25254 6.73298 6.25254H4.59975C4.43798 6.25254 4.30739 6.38313 4.30739 6.5449V13.4163H3.97021V8.86816C3.97021 8.70639 3.83962 8.5758 3.67785 8.5758H1.54364C1.38187 8.5758 1.25129 8.70639 1.25129 8.86816V13.4153H0.584714V0.292357C0.584714 0.130586 0.454128 0 0.292357 0C0.130586 0 0 0.130586 0 0.292357V13.7076C0 13.8694 0.130586 14 0.292357 14H1.54267H3.67688H4.59877H6.73298H7.65488H9.78908H10.711H12.8452H13.7076C13.8694 14 14 13.8694 14 13.7076C14 13.5459 13.8694 13.4153 13.7076 13.4153ZM1.83503 13.4153V9.16052H3.38452V13.4153H1.83503ZM4.89211 13.4153V6.83725H6.4416V13.4163H4.89211V13.4153ZM7.94724 13.4153V7.98037H9.49673V13.4153H7.94724ZM11.0043 13.4153V5.14841H12.5538V13.4163H11.0043V13.4153Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default BarGraph;
