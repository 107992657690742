import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const LayoutList: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 20 12" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.73069 2.54982H20V1.77412H5.73069V2.54982Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.92552 1.01691C1.29261 1.01691 0.777608 1.53063 0.777608 2.16197C0.777608 2.79331 1.29261 3.30703 1.92552 3.30703C2.55844 3.30703 3.07344 2.79331 3.07344 2.16197C3.07344 1.53063 2.55844 1.01691 1.92552 1.01691ZM1.92553 4.08259C0.86372 4.08259 -3.05176e-05 3.22113 -3.05176e-05 2.16197C-3.05176e-05 1.10281 0.86372 0.241211 1.92553 0.241211C2.98733 0.241211 3.85094 1.10281 3.85094 2.16197C3.85094 3.22113 2.98733 4.08259 1.92553 4.08259Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.73069 7.53972H20V6.76402H5.73069V7.53972Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.92552 6.00688C1.29261 6.00688 0.777608 6.5206 0.777608 7.15194C0.777608 7.78328 1.29261 8.29686 1.92552 8.29686C2.55844 8.29686 3.07344 7.78328 3.07344 7.15194C3.07344 6.5206 2.55844 6.00688 1.92552 6.00688ZM1.92553 9.07256C0.86372 9.07256 -3.05176e-05 8.21096 -3.05176e-05 7.15194C-3.05176e-05 6.09278 0.86372 5.23118 1.92553 5.23118C2.98733 5.23118 3.85094 6.09278 3.85094 7.15194C3.85094 8.21096 2.98733 9.07256 1.92553 9.07256Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.73069 12.5298H20V11.7541H5.73069V12.5298Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.92552 10.9969C1.29261 10.9969 0.777608 11.5106 0.777608 12.1419C0.777608 12.7733 1.29261 13.2868 1.92552 13.2868C2.55844 13.2868 3.07344 12.7733 3.07344 12.1419C3.07344 11.5106 2.55844 10.9969 1.92552 10.9969ZM1.92553 14.0625C0.86372 14.0625 -3.05176e-05 13.2009 -3.05176e-05 12.1419C-3.05176e-05 11.0828 0.86372 10.2212 1.92553 10.2212C2.98733 10.2212 3.85094 11.0828 3.85094 12.1419C3.85094 13.2009 2.98733 14.0625 1.92553 14.0625Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default LayoutList;
