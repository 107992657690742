import { Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetSubscriptionAncestriesRequestDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionAncestriesRequestDto';
import { GetSubscriptionsFlagDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionsFlagDto';
import { GetSubscriptionsSortColumnDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionsSortColumnDto';
import { SubscriptionAncestryDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionAncestryDto';
import { SubscriptionDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDto';
import Icon from 'Components/icons/icon';
import { TableFilters } from 'Components/table-filters';
import Tag, { getSubscriptionStatusTagColor } from 'Components/tag/tag';
import { TdWithImage } from 'Components/td-with-image';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { ALL_LOCATIONS, MOMENT_PARSING_FORMAT, TABLE_DEBOUNCE_DELAY } from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SubscriptionService } from 'Services/SubscriptionService';
import { FilterStore } from 'Stores';
import { RequestType } from 'Stores/RequestStore';
import { cleanVal, currencyFormatter } from 'Utils/NumberUtils';
import { firstLetterLowerCase } from 'Utils/StringUtils';
import { displayFormatedDate } from 'Utils/TimeUtils';
import './subscription-table.less';

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 10,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

// ordre alphabetique
const statusesList = [
    firstLetterLowerCase(GetSubscriptionsFlagDto.IsActive),
    firstLetterLowerCase(GetSubscriptionsFlagDto.IsCancelled),
    firstLetterLowerCase(GetSubscriptionsFlagDto.IsExpired),
    firstLetterLowerCase(GetSubscriptionsFlagDto.ExpiresSoon),
    firstLetterLowerCase(GetSubscriptionsFlagDto.IsModificationInProgress),
    firstLetterLowerCase(GetSubscriptionsFlagDto.IsOnboardingInProgress),
    firstLetterLowerCase(GetSubscriptionsFlagDto.IsRenewalInProgress),
    firstLetterLowerCase(GetSubscriptionsFlagDto.IsUpcoming),
];

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'statuses',
        nameKey: 'Statuses',
        items: [
            {
                key: GetSubscriptionsFlagDto.IsOnboardingInProgress,
                displayNameKey: `Subscription.subscription_status_${statusesList[5]}`,
                checked: true,
            },
            {
                key: GetSubscriptionsFlagDto.IsUpcoming,
                displayNameKey: `Subscription.subscription_status_${statusesList[7]}`,
                checked: true,
            },
            {
                key: GetSubscriptionsFlagDto.IsActive,
                displayNameKey: `Subscription.subscription_status_${statusesList[0]}`,
                checked: true,
            },
            {
                key: GetSubscriptionsFlagDto.ExpiresSoon,
                displayNameKey: `Subscription.subscription_status_${statusesList[3]}`,
                checked: true,
            },
            {
                key: GetSubscriptionsFlagDto.IsRenewalInProgress,
                displayNameKey: `Subscription.subscription_status_${statusesList[6]}`,
                checked: true,
            },
            {
                key: GetSubscriptionsFlagDto.IsModificationInProgress,
                displayNameKey: `Subscription.subscription_status_${statusesList[4]}`,
                checked: true,
            },
            {
                key: GetSubscriptionsFlagDto.IsCancelled,
                displayNameKey: `Subscription.subscription_status_${statusesList[1]}`,
                checked: true,
            },
            {
                key: GetSubscriptionsFlagDto.IsExpired,
                displayNameKey: `Subscription.subscription_status_${statusesList[2]}`,
                checked: true,
            },
        ],
    },
];

interface SubscriptionTableProps {
    globalList?: boolean;
    campusId?: string;
    membershipId?: string | null;
    defaultSortcolumn?: GetSubscriptionsSortColumnDto;
}

const SubscriptionTable: FunctionComponent<SubscriptionTableProps> = observer(
    ({ globalList, campusId, membershipId, defaultSortcolumn }) => {
        const subscriptionService = useService(SubscriptionService);

        const [loading, setLoading] = useState(false);
        const [data, setData] = useState<SubscriptionAncestryDto[]>([]);
        const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);

        const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
        const paginationRef = useRef(initialPaginationState);
        const { t } = useTranslation();
        const history = useHistory();
        const { requestStore, userPermissionsStore } = useStores();
        const [requestSetFromCache, setRequestSetFromCache] = useState(false);

        const inLocation = {
            isTrue: !!campusId,
            id: campusId,
        };

        const labelWithImage = (
            imageUrl: string,
            label: string,
            defaultImg = <Icon iconName="Company" />
        ): ReactNode => (
            <TdWithImage defaultImg={defaultImg} imgSrc={imageUrl}>
                {label}
            </TdWithImage>
        );

        const renderStatuses = (item: SubscriptionDto): ReactNode => {
            return statusesList.map((status) => {
                const key = status as keyof SubscriptionDto;
                return item[key] ? (
                    <Tag
                        key={status}
                        label={t(`Subscription.subscription_status_${status}`)}
                        color={getSubscriptionStatusTagColor(
                            `Subscription.subscription_status_${status}`
                        )}
                    />
                ) : null;
            });
        };

        const locationRender = (subscription: SubscriptionDto): ReactNode => {
            return {
                children: subscription?.campus?.id ? (
                    <TdWithImage
                        defaultImg={<Icon iconName="Location" />}
                        imgSrc={subscription.campus?.mainImageUrl}
                    >
                        {subscription.campus?.name}
                    </TdWithImage>
                ) : (
                    ''
                ),
                props: { colSpan: inLocation.isTrue ? 0 : 1 },
            };
        };

        const columns = (): ColumnType<SubscriptionDto>[] => {
            const columns: ColumnType<SubscriptionDto>[] = [
                {
                    title: t('Proposal.reference_number'),
                    dataIndex: 'referenceNumber',
                    render: (referenceNumber): string => referenceNumber,
                    sorter: true,
                    key: GetSubscriptionsSortColumnDto.ReferenceNumber,
                },
                {
                    title: t('location'),
                    render: locationRender,
                    sorter: true,
                    key: GetSubscriptionsSortColumnDto.LocationName,
                    colSpan: inLocation.isTrue ? 0 : 1,
                },
                {
                    title: t('plan'),
                    dataIndex: 'plan',
                    render: (plan): ReactNode =>
                        labelWithImage(plan.imageUrl, plan.name, <Icon iconName="Plan" />),
                    sorter: true,
                    key: GetSubscriptionsSortColumnDto.PlanName,
                },
                {
                    title: t('price'),
                    render: (subscription): string => currencyFormatter(subscription.price),
                    key: GetSubscriptionsSortColumnDto.Price,
                    align: 'right',
                },
                {
                    title: t('start_date'),
                    render: (subscription): string =>
                        displayFormatedDate(subscription.startDate, MOMENT_PARSING_FORMAT),
                    sorter: true,
                    key: GetSubscriptionsSortColumnDto.StartDate,
                    className: 'date-size',
                },
                {
                    title: t('end_date'),
                    render: (subscription): string =>
                        subscription.endDate
                            ? displayFormatedDate(subscription.endDate, MOMENT_PARSING_FORMAT)
                            : t('mtm'),
                    sorter: true,
                    key: GetSubscriptionsSortColumnDto.EndDate,
                    className: 'date-size',
                },
                {
                    title: t('status'),
                    render: (item): ReactNode => renderStatuses(item),
                    className: 'status-col',
                },
            ];

            if (membershipId === null || membershipId === undefined) {
                columns.unshift({
                    title: t('company'),
                    dataIndex: 'membership',
                    render: (membership): ReactNode =>
                        labelWithImage(membership.imageUrl, membership.name),
                    sorter: true,
                    key: GetSubscriptionsSortColumnDto.MembershipName,
                });
            }

            return columns;
        };

        const getAdvancedFilterStatuses = (): {
            checkedStatuses: GetSubscriptionsFlagDto[];
            statusesCount: number;
        } => {
            const filterStore = filterStoreRef.current;
            const statuses = filterStore.advancedFilters?.find(
                (filter: AdvancedFilter) => filter.key === 'statuses'
            );
            const checkedStatuses = statuses?.items
                .filter((item) => item.checked)
                .map((item) => {
                    return item.key;
                });

            return {
                checkedStatuses:
                    checkedStatuses?.map(
                        (x) => GetSubscriptionsFlagDto[x as keyof typeof GetSubscriptionsFlagDto]
                    ) ?? [],
                statusesCount: statuses?.items.length ?? 0,
            };
        };

        const fetchData = useCallback(
            async (params: {
                pagination: TablePaginationConfig;
                searchTerm?: string;
                currentLocationId?: string;
                advancedFilters?: AdvancedFilter[];
                startDate?: string;
                endDate?: string;
                sortColumn: GetSubscriptionsSortColumnDto | null;
                sortDirection: SortDirection | null;
            }) => {
                setLoading(true);

                try {
                    const statuses = getAdvancedFilterStatuses();
                    if (statuses.checkedStatuses.length === 0) {
                        setData([]);
                    } else {
                        const campusId = inLocation.isTrue
                            ? [inLocation.id]
                            : params.currentLocationId === ALL_LOCATIONS
                            ? []
                            : [params.currentLocationId];

                        const request: GetSubscriptionAncestriesRequestDto =
                            requestStore.setRequest({
                                request: {
                                    campusIds: campusId,
                                    maxStartDate: params.endDate,
                                    minEndDate: params.startDate,
                                    flags:
                                        statuses.checkedStatuses.length === statuses.statusesCount
                                            ? []
                                            : statuses.checkedStatuses,
                                    page: (params.pagination.current || 1) - 1,
                                    pageSize: params.pagination.pageSize || 10,
                                    searchTerm: params.searchTerm,
                                    sortColumn: params.sortColumn,
                                    sortDirection: params.sortDirection,
                                    membershipIds: membershipId ? [membershipId] : undefined,
                                },
                                requestType: RequestType.Subscription,
                            });

                        const response = await subscriptionService.getSubscriptionAncestries(
                            request
                        );

                        setData(
                            response?.items
                                ? response.items
                                      ?.filter((item) => item !== null)
                                      .map((item) => item!)
                                : []
                        );
                        setPagination({
                            ...params.pagination,
                            total: response?.totalItemCount ?? 0,
                        });
                    }
                } finally {
                    setLoading(false);
                }
            },
            [requestStore, subscriptionService, campusId]
        );

        const handleTableChange = async (
            pagination: TablePaginationConfig,
            filter: any,
            sorter: any
        ): Promise<void> => {
            const { currentLocationId, advancedFilters, searchTerm, startDate, endDate } =
                filterStoreRef.current;

            let sortDirection: SortDirection | null;
            switch (sorter.order) {
                case 'ascend':
                    sortDirection = SortDirection.Ascending;
                    break;
                case 'descend':
                    sortDirection = SortDirection.Descending;
                    break;
                default:
                    sortDirection = null;
                    break;
            }

            await fetchData({
                pagination,
                searchTerm,
                currentLocationId,
                advancedFilters,
                startDate,
                endDate,
                sortColumn: sorter.columnKey,
                sortDirection: sortDirection,
            });
            paginationRef.current = pagination;
        };

        const debounceSearch = useRef(
            debounce(
                (params: {
                    startDate?: string;
                    endDate?: string;
                    searchTerm?: string;
                    advancedFilters?: AdvancedFilter[];
                    currentLocationId: string;
                    defaultSortColumn?: GetSubscriptionsSortColumnDto;
                }) => {
                    fetchData({
                        pagination: { ...paginationRef.current, current: 1 },
                        startDate: params.startDate,
                        endDate: params.endDate,
                        advancedFilters: params.advancedFilters,
                        searchTerm: params.searchTerm,
                        currentLocationId: params.currentLocationId,
                        sortColumn: params.defaultSortColumn ?? null,
                        sortDirection: params.defaultSortColumn ? SortDirection.Descending : null,
                    });
                },
                TABLE_DEBOUNCE_DELAY
            )
        );

        useEffect(() => {
            const disposer = autorun(() => {
                const filterStore = filterStoreRef.current;

                debounceSearch.current({
                    startDate: filterStore.startDate,
                    endDate: filterStore.endDate,
                    advancedFilters: filterStore.advancedFilters,
                    searchTerm: filterStore.searchTerm,
                    currentLocationId: filterStore.currentLocationId,
                    defaultSortColumn: defaultSortcolumn,
                });
            });
            return (): void => {
                disposer();
            };
        }, [debounceSearch]);

        useEffect(() => {
            if (
                requestStore?.requestInfo?.request &&
                requestStore?.requestInfo?.requestType === RequestType.Subscription &&
                !requestSetFromCache
            ) {
                setRequestSetFromCache(true);
                const filterStore = filterStoreRef.current;
                const requestFromStore: GetSubscriptionAncestriesRequestDto =
                    requestStore.requestInfo.request;
                const filtersList = [{ key: requestFromStore?.flags || [], parentKey: 'statuses' }];

                filterStore.startDate = cleanVal.string(requestFromStore.maxStartDate);
                filterStore.endDate = cleanVal.string(requestFromStore.minEndDate);

                filterStore.updateLocation(
                    requestFromStore.campusIds && requestFromStore?.campusIds.length > 0
                        ? cleanVal.string(requestFromStore.campusIds[0])
                        : ALL_LOCATIONS
                );

                filterStore.updateSearchTerm(cleanVal.string(requestFromStore.searchTerm));
                filterStore.tickMultipleAdvancedFilter(filtersList);
            }
        }, [requestStore, userPermissionsStore.userInfo]);

        return (
            <div className="SubscriptionTable">
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeDates
                    dateLabels={{
                        start: t('Subscriptions.subscriptions_active_between'),
                        end: t('Subscriptions.subscriptions_active_and'),
                    }}
                    includeSearch
                    includeAdvancedFilters
                    includeLocations={globalList}
                    availableLocations={userPermissionsStore.availableLocationsForUser}
                />

                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns()}
                    rowKey={(record): string => `${record.id}`}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(row: SubscriptionAncestryDto) => ({
                        onClick: (): void => {
                            if (membershipId) {
                                history.push(
                                    `/companies/${row.membership?.id}/dashboard/subscriptions/${
                                        row.activeSubscription
                                            ? row.activeSubscription.id
                                            : row.lastSubscription?.id
                                    }`
                                );
                            } else {
                                history.push(
                                    `${
                                        globalList ? '' : `/locations/${row.campus?.id}`
                                    }/subscriptions/${
                                        row.activeSubscription
                                            ? row.activeSubscription.id
                                            : row.lastSubscription?.id
                                    }`
                                );
                            }
                        },
                    })}
                />
            </div>
        );
    }
);

export default SubscriptionTable;
