import { inject } from 'aurelia-dependency-injection';
import moment from 'moment';
import { LocationStore, UserPermissionsStore, LoadingStore } from 'Stores';
import { UserNotManagerError } from 'Stores/UserPermissionsStore';

//Service to collect base data from api for the app.
@inject(LocationStore, UserPermissionsStore, LoadingStore)
export class BaseDataService {
    constructor(
        private readonly locationStore: LocationStore,
        private readonly userPermissionsStore: UserPermissionsStore,
        private readonly loadingStore: LoadingStore
    ) {}

    lastCallTime: moment.Moment | undefined = undefined;

    public async initBaseData(): Promise<void> {
        if (this.lastCallTime === undefined || moment().diff(this.lastCallTime, 'minutes') > 15) {
            this.loadingStore.addLoading();
            this.lastCallTime = moment();
            try {
                await this.locationStore.setLocations();
                await this.userPermissionsStore.setUserInfoAndPermissions();
            }
            catch (e) {
                if(e instanceof UserNotManagerError) {
                    this.lastCallTime = undefined;
                }
            } finally {
                this.loadingStore.removeLoading();
            }
        }
    }
}
