import { SubscriptionProposalAccountDto } from 'Api/Features/SubscriptionProposals/Dtos/SubscriptionProposalAccountDto';
import { SubscriptionProposalAddOnDto } from 'Api/Features/SubscriptionProposals/Dtos/SubscriptionProposalAddOnDto';
import { SubscriptionProposalCampusDto } from 'Api/Features/SubscriptionProposals/Dtos/SubscriptionProposalCampusDto';
import { SubscriptionProposalDiscountDto } from 'Api/Features/SubscriptionProposals/Dtos/SubscriptionProposalDiscountDto';
import { SubscriptionProposalDto } from 'Api/Features/SubscriptionProposals/Dtos/SubscriptionProposalDto';
import { SubscriptionProposalPeriodDto } from 'Api/Features/SubscriptionProposals/Dtos/SubscriptionProposalPeriodDto';
import { SubscriptionProposalPlanDto } from 'Api/Features/SubscriptionProposals/Dtos/SubscriptionProposalPlanDto';
import { SubscriptionProposalUnitDto } from 'Api/Features/SubscriptionProposals/Dtos/SubscriptionProposalUnitDto';
import { SubscriptionProposalAnswerTriggerDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionProposalAnswerTriggerDto';
import { SubscriptionProposalStatusDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionProposalStatusDto';

export class SubscriptionProposal implements SubscriptionProposalDto {
    constructor(dto: SubscriptionProposalDto) {
        Object.assign(this, dto);
    }

    id?: string | null;
    campus?: SubscriptionProposalCampusDto | null;
    plan?: SubscriptionProposalPlanDto | null;
    units?: (SubscriptionProposalUnitDto | null)[] | null;
    referenceNumber?: string | null;
    becomesMonthToMonthOnExpiration?: boolean;
    securityDeposit?: number;
    creditPointsPerInterval?: number | null;
    periods?: (SubscriptionProposalPeriodDto | null)[] | null;
    addOns?: (SubscriptionProposalAddOnDto | null)[] | null;
    discounts?: (SubscriptionProposalDiscountDto | null)[] | null;
    isCheckPayable?: boolean;
    isPreAuthorizedTransaction?: boolean;
    status?: SubscriptionProposalStatusDto;
    dateCreated?: string;
    answerDate?: string | null;
    answeredByUser?: SubscriptionProposalAccountDto | null;
    answeredOnBehalfOfAccount?: SubscriptionProposalAccountDto | null;
    answerTrigger?: SubscriptionProposalAnswerTriggerDto | null;
    startDate?: string;
    endDate?: string | null;
    title?: string | null
}
