import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import './index.less';
import { ConferenceRoom } from 'Components/icons';
import { theme } from 'variant';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { useTranslation } from 'react-i18next';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { useService, useStores } from 'Hooks';
import { AmenityService } from 'Services/AmenityService';
import { Layout, Table } from 'antd';
import { AmenityDto } from 'Api/Features/Amenities/Dtos/AmenityDto';
import { DailyBookingSummaryAmenityDto } from 'Api/Features/Amenities/Dtos/DailyBookingSummaryAmenityDto';
import { useHistory, useParams } from 'react-router-dom';
import { TdWithImage } from 'Components/td-with-image';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import ReorderModal, { ReorderModel } from 'Components/reorder-modal/reorder-modal';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { TableFilters } from 'Components/table-filters';
import FilterStore from 'Stores/FilterStore';
import { autorun } from 'mobx';
import ConferenceRoomModal from './create-conference-room';
import { AmenityPrivacyTypeDto } from 'Api/Features/Amenities/Dtos/AmenityPrivacyTypeDto';
import { GetAmenitiesRequestDto } from 'Api/Features/Amenities/Dtos/GetAmenitiesRequestDto';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetAmenitiesSortColumnDto } from 'Api/Features/Amenities/Dtos/GetAmenitiesSortColumnDto';
import { cleanVal } from 'Utils/NumberUtils';
import { debounce } from 'lodash';
import { RequestType } from 'Stores/RequestStore';
import { TABLE_DEBOUNCE_DELAY } from 'Models/Constants';

const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    pageSize: 10,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'privacyTypes',
        nameKey: 'Status',
        items: [
            {
                key: AmenityPrivacyTypeDto.Public,
                displayNameKey: 'public',
                checked: true,
            },
            {
                key: AmenityPrivacyTypeDto.Private,
                displayNameKey: 'private',
                checked: true,
            },
        ],
    },
];

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'management',
        nameKey: 'management',
    },
    {
        path: 'conference-rooms',
        nameKey: 'Location.management_section_conference_rooms',
    },
];

const LocationConferenceRooms: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const amenityService = useService(AmenityService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<AmenityDto[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [reorderModalOpen, setReorderModalOpen] = useState<boolean>(false);
    const [orderingMethod, setOrderingMethod] = useState<{ isManual: boolean }>();
    const { requestStore, userPermissionsStore, toastStore, globalLoadingStore } = useStores();
    const paginationRef = useRef(initialPaginationState);
    const [requestSetFromCache, setRequestSetFromCache] = useState(false);

    const actions: ActionMenuOption[] = [
        {
            key: 'create',
            title: t('Location.management_section_conference_rooms_create'),
            action: (): void => setCreateModalOpen(true),
        },
        {
            key: 'reorder',
            title: t('reorder_model', { param1: 'conference rooms' }),
            action: (): void => {
                setReorderModalOpen(true);
                fetchOrderingMethod();
            },
        },
    ];

    const keyOrNA = (key: string | null): string => key || '';
    const PrivateOrPublic = (value: boolean | undefined): string =>
        value ? t('private') : t('public');

    const conferenceRoomRender = (conferenceRoom: DailyBookingSummaryAmenityDto): ReactNode =>
        conferenceRoom ? (
            <TdWithImage defaultImg={<ConferenceRoom />} imgSrc={conferenceRoom?.imageUrl}>
                {conferenceRoom?.name}
            </TdWithImage>
        ) : (
            ''
        );

    const columns = [
        {
            title: t('Location.conference_room_order'),
            dataIndex: 'order',
            key: GetAmenitiesSortColumnDto.Order,
            render: (key: string): string => key + 1 || '1',
            sorter: true,
        },
        {
            title: t('Location.conference_room_label'),
            render: conferenceRoomRender,
            key: GetAmenitiesSortColumnDto.Name,
            sorter: true,
        },
        {
            title: t('Location.conference_room_credits_per_hour'),
            dataIndex: 'creditPointsPerBlock',
            render: keyOrNA,
            key: GetAmenitiesSortColumnDto.CreditPerHour,
            sorter: true,
        },
        {
            title: t('Location.conference_room_number_of_seats'),
            dataIndex: 'numberOfSeats',
            render: keyOrNA,
            key: GetAmenitiesSortColumnDto.NumberOfSeats,
            sorter: true,
        },
        {
            title: t('Location.conference_room_privacy'),
            dataIndex: 'isPrivate',
            render: PrivateOrPublic,
            key: GetAmenitiesSortColumnDto.Privacy,
            sorter: true,
        },
    ];

    const getAdvancedFilterPrivacyTypes = (): {
        checkedPrivacyTypes: AmenityPrivacyTypeDto[];
        privacyCount: number;
    } => {
        const filterStore = filterStoreRef.current;
        const privacyTypes = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'privacyTypes'
        );
        const checkedPrivacyTypes = privacyTypes?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key;
            });

        return {
            checkedPrivacyTypes:
                checkedPrivacyTypes?.map(
                    (x) => AmenityPrivacyTypeDto[x as keyof typeof AmenityPrivacyTypeDto]
                ) ?? [],
            privacyCount: privacyTypes?.items.length ?? 0,
        };
    };

    const fetch = useCallback(
        async (params: {
            sortColumn: GetAmenitiesSortColumnDto | null;
            sortDirection: SortDirection | null;
            searchTerm?: string;
            advancedFilters?: AdvancedFilter[];
            pagination: TablePaginationConfig;
        }) => {
            setLoading(true);
            try {
                const privacyTypes = getAdvancedFilterPrivacyTypes();

                if (privacyTypes.checkedPrivacyTypes.length === 0) {
                    setData([]);
                } else {
                    const request: GetAmenitiesRequestDto = requestStore.setRequest({
                        request: {
                            campusIds: [id],
                            privacyTypes:
                                privacyTypes.checkedPrivacyTypes.length ===
                                privacyTypes.privacyCount
                                    ? []
                                    : privacyTypes.checkedPrivacyTypes,
                            sortColumn: params.sortColumn,
                            sortDirection: params.sortDirection,
                            searchTerm: params.searchTerm,
                            pageSize: params.pagination.pageSize || 10,
                            page: (params.pagination.current || 1) - 1,
                        },
                        requestType: RequestType.ConferenceRoom,
                    });

                    const response = await amenityService.getAmenities(request);

                    setData(response.items);
                    setPagination({
                        ...params.pagination,
                        total: response.totalItemCount,
                    });
                }
            } finally {
                setLoading(false);
            }
        },
        [amenityService, id, requestStore]
    );

    const debounceSearch = useRef(
        debounce((params: { searchTerm?: string; advancedFilters?: AdvancedFilter[] }) => {
            fetch({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                searchTerm: params.searchTerm,
                advancedFilters: params.advancedFilters,
                sortColumn: null,
                sortDirection: null,
            });
        }, TABLE_DEBOUNCE_DELAY)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                advancedFilters: filterStore.advancedFilters,
            });
        });
        return (): void => {
            disposer();
        };
    }, [fetch]);

    const onReorderComplete = (): void => {
        const filterStore = filterStoreRef.current;
        fetch({
            sortColumn: null,
            sortDirection: null,
            searchTerm: filterStore.searchTerm,
            advancedFilters: filterStore.advancedFilters,
            pagination: {
                ...paginationRef.current,
                current: 1,
            },
        });
        setReorderModalOpen(false);
        setOrderingMethod(undefined);
        toastStore.toast({
            type: 'success',
            messageKey: t('model_reorder_success', { param1: 'conference rooms' }),
        });
    };

    const onRowClick = (roomId: string | null): void => {
        if (roomId) {
            history.push(`/locations/${id}/management/conference-rooms/${roomId}`);
        }
    };

    const onCreationComplete = useCallback(
        (success: boolean) => {
            setCreateModalOpen(false);
            const filterStore = filterStoreRef.current;
            if (success) {
                fetch({
                    sortColumn: null,
                    sortDirection: null,
                    searchTerm: filterStore.searchTerm,
                    advancedFilters: filterStore.advancedFilters,
                    pagination: {
                        ...paginationRef.current,
                        current: 1,
                    },
                });
            }
        },
        [fetch]
    );

    const fetchOrderingMethod = useCallback(async () => {
        try {
            globalLoadingStore.addLoading();
            const order = await amenityService.getAmenitiesForLocationOrder(id);
            setOrderingMethod({ isManual: order?.method === OrderingMethodDto.Manual });
        } catch (e) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [amenityService, globalLoadingStore, id, toastStore]);

    const onReorderSubmit = async (
        orderingMethod: OrderingMethodDto,
        ids: string[]
    ): Promise<void> => {
        await amenityService.setAmenitiesForLocationOrder(id, orderingMethod, ids);
        onReorderComplete();
    };

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        const { advancedFilters, searchTerm } = filterStoreRef.current;

        let sortDirection: SortDirection | null;

        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        await fetch({
            pagination,
            advancedFilters,
            searchTerm,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
        paginationRef.current = pagination;
    };

    useEffect(() => {
        if (
            requestStore?.requestInfo?.request &&
            requestStore?.requestInfo?.requestType === RequestType.ConferenceRoom &&
            !requestSetFromCache
        ) {
            setRequestSetFromCache(true);
            const filterStore = filterStoreRef.current;
            const requestFromStore: GetAmenitiesRequestDto = requestStore.requestInfo.request;

            const filtersList = [
                { key: requestFromStore.privacyTypes || [], parentKey: 'privacyTypes' },
            ];

            filterStore.updateSearchTerm(cleanVal.string(requestFromStore.searchTerm));
            filterStore.tickMultipleAdvancedFilter(filtersList);
        }
    }, [requestStore, userPermissionsStore.userInfo]);

    return (
        <div className="ConferenceRooms">
            <LocationHeader
                title={t('Location.management_section_conference_rooms')}
                subTitle={t('Location.management_section_conference_rooms_subtitle')}
                defaultImg={<ConferenceRoom fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={<ActionMenu options={actions} type="primary" trigger="click" />}
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeAdvancedFilters
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(record: AmenityDto): string => record.id || ''}
                    dataSource={data}
                    loading={loading}
                    pagination={pagination}
                    onRow={(row: AmenityDto) => ({
                        onClick: (): void => {
                            onRowClick(row.id || null);
                        },
                    })}
                    onChange={handleTableChange}
                />
            </Content>

            {reorderModalOpen && orderingMethod && (
                <ReorderModal
                    visible={reorderModalOpen}
                    dataSource={data.map(
                        (amenity) =>
                            ({
                                id: amenity.id,
                                name: amenity.name,
                                order: amenity.order,
                            } as ReorderModel)
                    )}
                    onDismiss={(): void => {
                        setReorderModalOpen(false);
                        setOrderingMethod(undefined);
                    }}
                    manual={orderingMethod?.isManual}
                    onSubmit={async (
                        orderingMethod: OrderingMethodDto,
                        ids: string[]
                    ): Promise<void> => await onReorderSubmit(orderingMethod, ids)}
                    modelName={'conference rooms'}
                />
            )}
            {createModalOpen && (
                <ConferenceRoomModal
                    visible={createModalOpen}
                    onComplete={onCreationComplete}
                    campusId={id}
                />
            )}
        </div>
    );
};

export default LocationConferenceRooms;
