import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { CreditPackProxy } from 'Api/Features/CreditPacks/CreditPackProxy';
import { CreateCreditPackRequestDto } from 'Api/Features/CreditPacks/Dtos/CreateCreditPackRequestDto';
import { CreditPackDto } from 'Api/Features/CreditPacks/Dtos/CreditPackDto';
import { GetCreditPackOrderResponseDto } from 'Api/Features/CreditPacks/Dtos/GetCreditPackOrderResponseDto';
import { GetCreditPacksRequestDto } from 'Api/Features/CreditPacks/Dtos/GetCreditPacksRequestDto';
import { GetCreditPacksResponseDto } from 'Api/Features/CreditPacks/Dtos/GetCreditPacksResponseDto';
import { UpdateCreditPackOrderRequestDto } from 'Api/Features/CreditPacks/Dtos/UpdateCreditPackOrderRequestDto';
import { UpdateCreditPackRequestDto } from 'Api/Features/CreditPacks/Dtos/UpdateCreditPackRequestDto';

@inject(CreditPackProxy)
export class CreditPackService extends ApiService {
    constructor(private readonly creditPackProxy: CreditPackProxy) {
        super();
    }

    public async getCreditPacks(request: GetCreditPacksRequestDto): Promise<[CreditPackDto[], number]> {
        const data: GetCreditPacksResponseDto | null = await this.creditPackProxy.getCreditPacks(request);
        return [
            data?.items?.filter(pack => pack !== null).map(pack => pack!) || [],
            data?.totalItemCount || 0,
        ];
    }

    public async getCreditPack(creditPackId: string | null): Promise<CreditPackDto | null> {
        const data: CreditPackDto | null = await this.creditPackProxy.getCreditPack(creditPackId);
        return data;
    }

    public async getCreditPackOrdering(): Promise<GetCreditPackOrderResponseDto | null> {
        const data: GetCreditPackOrderResponseDto | null = await this.creditPackProxy.getCreditPackOrdering();
        return data;
    }

    public async updateCreditPackOrdering(request: UpdateCreditPackOrderRequestDto | null): Promise<void> {
        await this.creditPackProxy.updateCreditPackOrdering(request);
    }

    public async updateCreditPack(creditPackId: string | null, request: UpdateCreditPackRequestDto | null): Promise<void> {
        await this.creditPackProxy.updateCreditPack(creditPackId, request);
    }

    public async deleteCreditPack(creditPackId: string | null): Promise<void> {
        await this.creditPackProxy.deleteCreditPack(creditPackId);
    }

    public async createCreditPack(request: CreateCreditPackRequestDto | null): Promise<string> {
        const data: string = await this.creditPackProxy.createCreditPack(request);
        return data;
    }
}
