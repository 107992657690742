import Icon from 'Components/icons/icon';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import './subscription-timeline-item.less';

interface LinkInterface {
    label?: string;
    url?: string;
}

interface DetailsInterface {
    icon: string;
    text: string;
}

export interface SubscriptionTimelineItemProps {
    date: string;
    link?: LinkInterface;
    subtitle?: string;
    details?: (DetailsInterface | undefined)[];
    circleEmphasis?: boolean;
    emphasis?: boolean;
    disabled?: boolean;
}

const SubscriptionTimelineItem: FunctionComponent<SubscriptionTimelineItemProps> = ({
    date,
    link,
    subtitle,
    details,
    circleEmphasis = false,
    emphasis = false,
    disabled = false,
}) => {
    return (
        <div
            className={`SubscriptionTimelineItem circleEmphasis-${circleEmphasis} emphasis-${emphasis} disabled-${disabled}`}
        >
            <div className="timeline-circle"></div>

            <div className="date-link-wrapper">
                <div className="date">{date}</div>

                {link && link.url && (
                    <Link className="link" to={link.url}>
                        {link.label}
                    </Link>
                )}
            </div>

            <div className="subtitle">{subtitle}</div>

            {details && (
                <div className="details">
                    {details.map(
                        (item, i) =>
                            item && (
                                <div key={i} className="details-item">
                                    <ImageWithPlaceholder
                                        defaultImg={<Icon iconName={item.icon} />}
                                    />
                                    {item.text}
                                </div>
                            )
                    )}
                </div>
            )}
        </div>
    );
};

export default SubscriptionTimelineItem;
