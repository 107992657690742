import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useService, useStores } from 'Hooks';
import { images, theme } from 'variant';
import { Col, Layout, Row } from 'antd';
import { Delete, Plan } from 'Components/icons';
import { SimpleList } from 'Components/simple-list';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { ListSectionHeader } from 'Components/list-section-header';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { DayPassTypeDto } from 'Api/Features/DayPassTypes/Dtos/DayPassTypeDto';
import { DayPassTypeService } from 'Services/DayPassTypeService';
import { cleanVal, currencyFormatter } from 'Utils/NumberUtils';
import EditDayPassTypeModal from '../edit-day-pass-type-modal';

const { Content } = Layout;
const { usersHeader } = images;

interface SimpleListItemProps {
    title: string;
    description?: string;
    avatar?: React.ReactNode;
}

const DayPassTypeDetails: FunctionComponent = () => {
    const { t } = useTranslation();
    const { globalLoadingStore, confirmationModalStore, toastStore } = useStores();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<DayPassTypeDto | undefined>(undefined);
    const [basicInfo, setBasicInfo] = useState<SimpleListItemProps[]>([]);
    const [pricing, setPricing] = useState<SimpleListItemProps[]>([]);
    const [description, setDescription] = useState<SimpleListItemProps[]>([]);
    const dayPassTypeService = useService(DayPassTypeService);
    const history = useHistory();
    const [editModalOpen, setEditModalOpen] = useState(false);

    const { id, locationDayPassTypesId } = useParams<{
        id: string;
        locationDayPassTypesId?: string;
    }>();

    const locationManagement = {
        isTrue: !!locationDayPassTypesId,
        dayPassTypeId: cleanVal.string(locationDayPassTypesId),
        locationId: cleanVal.string(id),
    };

    const currentDayPassTypesId = locationManagement.isTrue ? locationManagement.dayPassTypeId : id;

    const setSimpleListsData = useCallback(
        (dayPassType: DayPassTypeDto): void => {
            const basic: SimpleListItemProps[] = [
                {
                    title: t('DayPass.display_name'),
                    description: dayPassType.displayName ?? '',
                },
            ];
            setBasicInfo(basic);

            const pricing: SimpleListItemProps[] = [
                {
                    title: t('price'),
                    description: currencyFormatter(dayPassType.price ?? 0),
                },
                {
                    title: t('credits_price'),
                    description: currencyFormatter(dayPassType.creditsPrice ?? 0),
                },
            ];
            setPricing(pricing);

            const description: SimpleListItemProps[] = [];
            if (dayPassType?.description)
                description.push({
                    title: dayPassType?.description,
                });
            setDescription(description);
        },
        [t]
    );

    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const data = await dayPassTypeService.getDayPassType(currentDayPassTypesId);
            if (data) {
                setData(data);
                setSimpleListsData(data);
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [dayPassTypeService, globalLoadingStore, currentDayPassTypesId, setSimpleListsData]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const breadcrumb: BreadcrumbSegment[] = [
        {
            nameKey: locationManagement.isTrue
                ? 'management'
                : 'Administration.administration_title',
            path: locationManagement.isTrue
                ? `locations/${locationManagement.locationId}/management`
                : 'administration',
        },

        {
            nameKey: 'DayPass.day_pass_types',
            path: 'day-pass-types',
        },
        {
            path: cleanVal.string(data?.id),
            name: cleanVal.string(data?.name),
        },
    ];

    const deleteDayPassType = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                width: 850,
                icon: <Delete />,
                title: t(`DayPass.delete_day_pass_type_confirm_title`),
                message: t(`action_cannot_be_undone`),
                positiveText: t(`model_confirm_positive_delete`, { param1: 'day pass type' }),
                negativeText: t(`model_confirm_negative_delete`, { param1: 'day pass type' }),
            }))
        )
            return;

        await dayPassTypeService.deleteDayPassType(currentDayPassTypesId);

        toastStore.toast({
            type: 'success',
            messageKey: t('model_delete_success', { param1: 'Day pass type' }),
        });
        history.goBack();
    };

    const menuOptions: ActionMenuOption[] = [
        {
            key: 'edit',
            title: t('DayPass.edit_day_pass_type'),
            action: (): void => setEditModalOpen(true),
        },
        {
            key: 'delete',
            title: t('DayPass.delete_day_pass_type'),
            action: (): Promise<void> => deleteDayPassType(),
        },
    ];

    return (
        <div className="DayPassTypeDetails">
            <ListSectionHeader
                title={data?.name ?? ''}
                subTitle={''}
                imgSrc={data?.imageUrl}
                defaultImg={<Plan fill={theme['primary-color']} />}
                backgroundImageUrl={usersHeader}
                action={<ActionMenu options={menuOptions} type="primary" trigger="click" />}
                routes={breadcrumb}
                loading={loading}
            />

            <Content>
                <Row gutter={40}>
                    <Col span={12}>
                        {basicInfo.length > 0 && (
                            <SimpleList
                                title={t('basic_information')}
                                data={basicInfo}
                                className={'large-description'}
                            />
                        )}

                        {description.length > 0 && (
                            <SimpleList title={t('description')} data={description} />
                        )}
                    </Col>
                    <Col span={12}>
                        {pricing.length > 0 && (
                            <SimpleList
                                title={t('pricing')}
                                data={pricing}
                                className={'large-description'}
                            />
                        )}
                    </Col>
                </Row>
            </Content>

            {editModalOpen && (
                <EditDayPassTypeModal
                    visible={editModalOpen}
                    onComplete={(success: boolean): void => {
                        if (success) {
                            fetch();
                        }
                        setEditModalOpen(false);
                    }}
                    dayPassType={data}
                    locationId={
                        locationManagement.isTrue ? locationManagement.locationId : undefined
                    }
                />
            )}
        </div>
    );
};

export default DayPassTypeDetails;
