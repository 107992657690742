import { Button, Layout, Table } from 'antd';
import { ColumnType, TablePaginationConfig } from 'antd/lib/table/interface';
import { GetCampusOnboardingConfigResponseCategoryDto } from 'Api/Features/Campuses/Dtos/GetCampusOnboardingConfigResponseCategoryDto';
import { GetCampusOnboardingConfigResponseTaskDto } from 'Api/Features/Campuses/Dtos/GetCampusOnboardingConfigResponseTaskDto';
import Icon from 'Components/icons/icon';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { TableFilters } from 'Components/table-filters';
import { Tag } from 'Components/tag';
import { useService } from 'Hooks';
import { autorun } from 'mobx';
import { ONBOARDING_CATEGORY_ID_MEMBER_ACCESS } from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LocationService } from 'Services/LocationService';
import FilterStore from 'Stores/FilterStore';
import { theme } from 'variant';
import EditOnboardingModal from './modals/edit-onboarding-modal';
import './onboarding.less';

const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    pageSize: 10,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'isCompleted',
        nameKey: 'Status',
        items: [
            {
                key: 'completed',
                displayNameKey: 'Location.onboarding_category_status_activated',
                checked: true,
            },
            {
                key: 'notCompleted',
                displayNameKey: 'Location.onboarding_category_status_deactivated',
                checked: true,
            },
        ],
    },
];

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'management',
        nameKey: 'management',
    },
    {
        path: 'onboarding',
        nameKey: 'Location.onboarding',
    },
];

const Onboarding: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const locationService = useService(LocationService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<GetCampusOnboardingConfigResponseCategoryDto[]>();
    const [filteredData, setFilteredData] = useState<GetCampusOnboardingConfigResponseTaskDto[]>();
    const [pagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const globalTasksList = data
        ? data
              .map((item) =>
                  item.tasks?.map((task) => ({
                      categoryId: item.id,
                      id: task?.id,
                      description: task?.description,
                      isEnabled: task?.isEnabled,
                  }))
              )
              .flat(1)
        : [];

    const renderTaskDescription = (row: GetCampusOnboardingConfigResponseTaskDto): string =>
        `${row.description}`;

    const renderStatus = (row: GetCampusOnboardingConfigResponseTaskDto): ReactNode =>
        row.isEnabled ? (
            <Tag label={t('Location.onboarding_category_status_activated')} />
        ) : (
            <Tag label={t('Location.onboarding_category_status_deactivated')} deactivated />
        );

    const columns: ColumnType<GetCampusOnboardingConfigResponseTaskDto>[] = [
        {
            title: t('Checklist item'),
            render: renderTaskDescription,
            className: 'description',
            sorter: {
                compare: (a: any, b: any): number =>
                    a.description.localeCompare(b.description),
            },
        },
        {
            title: t('status'),
            render: renderStatus,
            sorter: {
                compare: (a: any, b: any): number => {
                    const aVal = a.isEnabled ? 1 : -2;
                    const bVal = b.isEnabled ? 1 : -2;
                    return aVal === bVal ? 0 : aVal - bVal;
                },
            },
        },
    ];

    const fetch = useCallback(async () => {
        setLoading(true);

        try {
            const results = await locationService.getCampusOnboardingConfig(id);

            if (results) {
                setData(
                    results.categories
                        ?.filter((category) => category !== null)
                        .map((category) => category!) ?? undefined
                );
            }
        } finally {
            setLoading(false);
        }
    }, [locationService, id]);

    const onEditionComplete = (): void => {
        setEditModalOpen(false);
        fetch();
    };

    const filterResults = useCallback(
        (params: { searchTerm?: string; advancedFilters?: AdvancedFilter[] }) => {
            const filterStore = filterStoreRef.current;
            const sources = filterStore.advancedFilters?.find(
                (filter: AdvancedFilter) => filter.key === 'isCompleted'
            );
            const checkedSources = sources?.items
                .filter((item) => item.checked)
                .map((item) => {
                    return item.key;
                });

            const filters =
                checkedSources?.length === 2 || checkedSources?.length === 0
                    ? undefined
                    : checkedSources?.includes('completed');

            const category = data?.find(
                (category) => category.id === ONBOARDING_CATEGORY_ID_MEMBER_ACCESS
            );
            const tasks = category?.tasks
                ?.filter((task) => task !== undefined && task !== null)
                .map((task) => task!);
            let filtered = tasks?.filter(
                (task) =>
                    params.searchTerm === '' ||
                    (params.searchTerm &&
                        task?.description
                            ?.toLocaleLowerCase()
                            .includes(params.searchTerm.toLocaleLowerCase()))
            );

            if (filters !== undefined) {
                if (filters) {
                    filtered = filtered && filtered.filter((task) => task?.isEnabled);
                } else {
                    filtered = filtered && filtered.filter((task) => !task?.isEnabled);
                }
            }

            const cleanedFiltered: GetCampusOnboardingConfigResponseTaskDto[] = [];
            filtered &&
                filtered.forEach((item) => {
                    if (item !== null && item !== undefined) {
                        cleanedFiltered.push(item);
                    }
                });

            setFilteredData(cleanedFiltered);
        },
        [data]
    );

    useEffect(() => {
        const disposer = autorun(() => {
            fetch();
        });
        return (): void => {
            disposer();
        };
    }, [fetch]);

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;

            filterResults({
                searchTerm: filterStore.searchTerm,
                advancedFilters: filterStore.advancedFilters,
            });
        });
        return (): void => {
            disposer();
        };
    }, [filterResults, filterStoreRef]);

    return (
        <>
            <div className="Onboarding">
                <LocationHeader
                    title={t('Location.management_section_onboarding')}
                    subTitle={t('Location.management_section_onboarding_subtitle')}
                    defaultImg={<Icon iconName="Tick" fill={theme['primary-color']} />}
                    routes={breadcrumbs}
                    action={
                        <Button
                            type="primary"
                            onClick={(): void => {
                                setEditModalOpen(true);
                            }}
                        >
                            {t('Location.management_section_onboarding_edit')}
                        </Button>
                    }
                />

                <Content>
                    <TableFilters
                        filterStore={filterStoreRef.current}
                        includeSearch
                        includeAdvancedFilters
                    />
                    <Table
                        className="table-striped-rows table-action-rows"
                        bordered
                        columns={columns}
                        rowKey={(record): string => `${record.id}`}
                        dataSource={filteredData}
                        loading={loading}
                        pagination={pagination}
                    />
                </Content>
            </div>

            <EditOnboardingModal
                locationId={id}
                visible={editModalOpen}
                onboardingCategoriesCheckList={data ?? []}
                tasksListToDisplay={
                    globalTasksList.filter(
                        (task) => task?.categoryId === ONBOARDING_CATEGORY_ID_MEMBER_ACCESS
                    ) ?? []
                }
                onboardingCheckList={globalTasksList}
                onComplete={onEditionComplete}
            />
        </>
    );
};

export default Onboarding;
