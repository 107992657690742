// GENERATED FILE - DO NOT MODIFY

export enum GetSubscriptionsSortColumnDto {
    MembershipName = "MembershipName",
    ReferenceNumber = "ReferenceNumber",
    PlanName = "PlanName",
    LocationName = "LocationName",
    Price = "Price",
    StartDate = "StartDate",
    EndDate = "EndDate",
}