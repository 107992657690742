import { GetUnitOccupancyStatusesRequestDto } from 'Api/Features/UnitOccupancy/Dtos/GetUnitOccupancyStatusesRequestDto';
import { UnitOccupancyStatusDto } from 'Api/Features/UnitOccupancy/Dtos/UnitOccupancyStatusDto';
import { UnitOccupancyProxy } from 'Api/Features/UnitOccupancy/UnitOccupancyProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(UnitOccupancyProxy)
export class UnitOccupancyService extends ApiService {
    constructor(private readonly unitOccupancyProxy: UnitOccupancyProxy) {
        super();
    }

    public async getUnitOccupancyStatuses(request: GetUnitOccupancyStatusesRequestDto | null): Promise<(UnitOccupancyStatusDto | null)[] | null> {
        const data: (UnitOccupancyStatusDto | null)[] | null = await this.unitOccupancyProxy.getUnitOccupancyStatuses(request);
        return data;
    }
}
