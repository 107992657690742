import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CommunityEvent: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.9961 13.4162L20.9642 13.5939C20.1738 18.0097 16.4022 21.1937 11.9912 21.1937C6.96521 21.1937 2.87677 17.0541 2.87677 11.9659C2.87677 6.87761 6.96521 2.73807 11.9912 2.73807C16.4022 2.73807 20.1738 5.922 20.9589 10.3097L20.9909 10.4874L21.1716 10.4826L21.8587 10.3564L21.8263 10.1782C20.961 5.34079 16.827 1.84981 11.9912 1.84981C6.48231 1.84981 2 6.38814 2 11.9659C2 17.5441 6.48231 22.0819 11.9912 22.0819C16.827 22.0819 20.961 18.5904 21.8215 13.7806L21.8535 13.6024L21.6828 13.543L20.9961 13.4162Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.9913 6.93191C14.1313 6.93191 16.0268 8.31388 16.7072 10.3715L16.7643 10.5427L16.9418 10.512L17.4352 10.3449L17.6049 10.2871L17.5473 10.1153C16.7386 7.66957 14.5095 6.04419 11.9913 6.04419C8.76599 6.04419 6.14249 8.70047 6.14249 11.9661C6.14249 15.2312 8.76599 17.8874 11.9913 17.8874C15.216 17.8874 17.8401 15.2312 17.8401 11.9661V11.5222H12.3977V12.4099H16.9439C16.7208 14.9984 14.5807 16.9997 11.9913 16.9997C9.24994 16.9997 7.01926 14.7417 7.01926 11.9661C7.01926 9.18994 9.24994 6.93191 11.9913 6.93191Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.7853 12.4096H23.9951V11.5219H18.7853V12.4096Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default CommunityEvent;
