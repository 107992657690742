import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const DragHandle: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="25" height="10" viewBox="0 0 25 10" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.5 2.64286H24.4998V0.5H0.5V2.64286Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.5 9.78571H24.4998V7.64286H0.5V9.78571Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default DragHandle;
