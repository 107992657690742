import { Button, Col, Form, Row, Select } from 'antd';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { ManagerUserDto } from 'Api/Features/ManagerUsers/Dtos/ManagerUserDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Lead as LeadIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { toUpdateLeadRequestDto } from 'Models/Leads/DtoOutput';
import { Lead } from 'Models/Leads/Lead';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransferOwnershipSchema } from 'Schemas';
import { LeadService } from 'Services/LeadService';
import { ManagerUserService } from 'Services/ManagerUserService';
import './transfer-ownership.less';

interface TransferOwnershipModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    lead?: Lead;
}

const { Option } = Select;

const formGutter: [Gutter, Gutter] = [40, 0];

const TransferOwnershipModal: FunctionComponent<TransferOwnershipModalProps> = ({
    visible,
    onComplete,
    lead,
}) => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(TransferOwnershipSchema, form);
    const leadService = useService(LeadService);
    const [managersSearchResults, setManagersSearchResults] = useState<ManagerUserDto[]>([]);
    const managerUserService = useService(ManagerUserService);
    const [ownerAccountId, setOwnerAccountId] = useState<string>();

    //#region Effects
    useEffect(() => {
        if (lead) {
            setOwnerAccountId(lead.ownerAccount?.id || undefined);
        }
    }, [lead, form]);
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <LeadIcon />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t('Lead.lead_confirm_leave_ownership_transfer'),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const searchManagers = useCallback(
        async (value: string, callback: (results: ManagerUserDto[]) => void): Promise<void> => {
            const results = await managerUserService.getManagerUsers({
                pageSize: 10,
                page: 0,
                searchTerm: value,
            });
            callback(results[0]);
        },
        [managerUserService]
    );

    const debouncedSearch = debounce(searchManagers, 300);
    const handleSearch = (value: string): void => {
        if (value) {
            debouncedSearch(value, (results: ManagerUserDto[]) => {
                setManagersSearchResults(results);
            });
        } else {
            setManagersSearchResults([]);
        }
    };

    const onOwnerChange = (ownerId: string): void => {
        setOwnerAccountId(ownerId);
    };

    const submit = async (values: Store): Promise<void> => {
        lead!.ownerAccount!.id = ownerAccountId;

        const data = toUpdateLeadRequestDto(lead!);

        try {
            globalLoadingStore.addLoading();

            if (!(await validateForm(data))) return;

            if (lead !== undefined) await leadService.updateLead(lead.id!, data);

            toastStore.toast({
                type: 'success',
                messageKey: `Lead.lead_ownership_transfered_success`,
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion
    return (
        <BaseModal
            visible={visible}
            title={t('Lead.transfer_lead_ownership')}
            className="FormModal"
            width={720}
            onCancel={exit}
        >
            <div className="TransferOwnership">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="ownerAccountId"
                                errors={errors}
                                label={t('Lead.choose_new_lead_owner')}
                                required
                            >
                                <Select
                                    showSearch
                                    filterOption={false}
                                    onSearch={handleSearch}
                                    placeholder={t('search')}
                                    onChange={onOwnerChange}
                                    defaultValue={
                                        lead !== undefined
                                            ? `${lead?.ownerAccount?.firstName} ${lead?.ownerAccount?.lastName}`
                                            : ''
                                    }
                                >
                                    {managersSearchResults?.map((user) => (
                                        <Option key={user.id!} value={user.id!}>
                                            {`${user.firstName} ${user.lastName}`}
                                            <br />
                                            {user.contactInfo?.email}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('Lead.transfer_ownership')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
};

export default TransferOwnershipModal;
