import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useService, useStores } from 'Hooks';
import { theme } from 'variant';
import { Button, Col, Layout, Row } from 'antd';
import { SimpleList } from 'Components/simple-list';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import LocationHeader from 'Components/location-header/location-header';
import { CampusService } from 'Services/CampusService';
import { CampusUnitPricingDto } from 'Api/Features/Campuses/Dtos/CampusUnitPricingDto';
import { Dollar } from 'Components/icons';
import { currencyFormatter } from 'Utils/NumberUtils';
import EditPricingModal from './edit-pricing-modal';
import { observer } from 'mobx-react';

const { Content } = Layout;

interface SimpleListItemProps {
    title: string;
    description?: string;
    avatar?: React.ReactNode;
}

const LocationPricing: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, userPermissionsStore } = useStores();
    const [data, setData] = useState<CampusUnitPricingDto>();
    const [basicInformation, setBasicInformation] = useState<SimpleListItemProps[]>([]);
    const campusService = useService(CampusService);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const { id } = useParams<{ id: string }>();

    const setSimpleListsData = useCallback(
        (pricing: CampusUnitPricingDto): void => {
            const info: SimpleListItemProps[] = [];
            if (pricing) {
                info.push({
                    title: t('Unit.communal_area_base_price'),
                    description: currencyFormatter(pricing.communalAreaBasePrice ?? 0),
                });
                info.push({
                    title: t('Location.price_per_desk'),
                    description: currencyFormatter(pricing.pricePerDesk ?? 0),
                });
                info.push({
                    title: t('Location.price_per_sq_ft'),
                    description: currencyFormatter(pricing.pricePerSquareFoot ?? 0),
                });
            }
            setBasicInformation(info);
        },
        [t]
    );

    const fetch = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const data = await campusService.getUnitPricing(id);
            if (data) {
                setData(data);
                setSimpleListsData(data);
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, id, campusService, setSimpleListsData, toastStore]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'management',
            nameKey: 'management',
        },
        {
            path: 'pricing',
            nameKey: 'pricing',
        },
    ];

    return (
        <div className="LocationPricing">
            <LocationHeader
                title={t('pricing')}
                subTitle={t('Location.manage_pricing_settings')}
                defaultImg={<Dollar fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={
                    userPermissionsStore.userIsAdministrator && (
                        <Button
                            type="primary"
                            onClick={(): void => {
                                setEditModalOpen(true);
                            }}
                        >
                            {t('Location.edit_pricing')}
                        </Button>
                    )
                }
            />
            <Content>
                <Row gutter={40}>
                    {basicInformation.length > 0 && (
                        <Col span={12}>
                            <SimpleList
                                className="large-description"
                                title={t('Location.unit_pricing')}
                                data={basicInformation}
                            />
                        </Col>
                    )}
                </Row>
            </Content>

            {editModalOpen && (
                <EditPricingModal
                    pricing={data}
                    onComplete={(success: boolean): void => {
                        if (success) fetch();
                        setEditModalOpen(false);
                    }}
                    visible={editModalOpen}
                    locationId={id}
                />
            )}
        </div>
    );
});

export default LocationPricing;
