import { Button, Col, Form, Row, Typography } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { LeadAssignationRuleDto } from 'Api/Features/LeadAssignationRules/Dtos/LeadAssignationRuleDto';
import { UpdateLeadAssignationRulesRequestDto } from 'Api/Features/LeadAssignationRules/Dtos/UpdateLeadAssignationRulesRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { User } from 'Components/icons';
import ManagerUserSelect from 'Components/manager-user-select';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { ManagerUser } from 'Models/ManagerUsers/ManagerUser';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LeadOpportunityAssignmentSchema } from 'Schemas';
import { LeadAssignationService } from 'Services/LeadAssignmentService';
import { ManagerUserService } from 'Services/ManagerUserService';
import './edit-assignments.less';

const { Title } = Typography;

const formGutter: [Gutter, Gutter] = [40, 0];

interface EditAssignmentsModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    rules?: LeadAssignationRuleDto[];
}

interface LocationManager {
    campusId?: string | null;
    accountId?: string | null;
}

const EditAssignments: FunctionComponent<EditAssignmentsModalProps> = ({
    visible,
    onComplete,
    rules,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(LeadOpportunityAssignmentSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [defaultLocationManagerId, setDefaultLocationManagerId] = useState<string | undefined>();
    const [locationsManager, setLocationsManager] = useState<LocationManager[] | undefined>([]);
    const leadAssignationService = useService(LeadAssignationService);
    const managerUserService = useService(ManagerUserService);

    const [initialManagerSearchResult, setInitialManagerSearchResult] = useState<ManagerUser[]>([]);
    //#endregion

    useEffect(() => {
        setLocationsManager(
            rules
                ?.filter((rule: LeadAssignationRuleDto) => rule?.campus !== null)
                .map(
                    (rule: LeadAssignationRuleDto) =>
                        ({
                            campusId: rule?.campus?.id,
                            accountId: rule.account?.id,
                        } as LocationManager)
                )
        );
        setDefaultLocationManagerId(
            rules?.find((rule: LeadAssignationRuleDto) => rule.campus === null)?.account?.id ||
                undefined
        );
    }, [rules]);

    const onManagerSelect = (locationId: string, managerId?: string): void => {
        const locationManagerState = locationsManager;
        const index = locationManagerState?.findIndex((obj) => obj.campusId === locationId);
        if (locationManagerState && index !== undefined)
            locationManagerState[index] = { campusId: locationId, accountId: managerId };
        setLocationsManager(locationManagerState);
    };

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <User />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`Administration.assignment_confirm_positive`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const validateObject = { managerUserDefault: defaultLocationManagerId };
        if (!(await validateForm(validateObject))) return;

        try {
            globalLoadingStore.addLoading();
            const request: UpdateLeadAssignationRulesRequestDto = {
                rules: locationsManager
                    ?.filter((location) => location.accountId !== undefined)
                    .concat({
                        accountId: validateObject.managerUserDefault,
                        campusId: null,
                    } as LocationManager),
            };
            await leadAssignationService.updateLeadAssignationRule(request);

            toastStore.toast({
                type: 'success',
                messageKey: 'Administration.assignment_update_success',
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const initialSelectedManagerOption = (managerId?: string): SelectCustomOption[] => {
        if (managerId) {
            const manager = rules?.find((rule) => rule.account?.id === managerId);
            return manager
                ? [
                      {
                          label: `${manager.account?.firstName} ${manager.account?.lastName}`,
                          value: manager.account?.id,
                          imageUrl: manager.account?.imageUrl,
                      } as SelectCustomOption,
                  ]
                : [];
        }

        return [];
    };

    const searchManagerUsers = useCallback(async (): Promise<void> => {
        const args = {
            pageSize: 25,
            page: 0,
        };
        try {
            globalLoadingStore.addLoading();
            const [results] = await managerUserService.getManagerUsers(args);
            setInitialManagerSearchResult(results);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [managerUserService, globalLoadingStore, toastStore]);

    useEffect(() => {
        searchManagerUsers();
    }, [searchManagerUsers]);

    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Administration.edit_assignments')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="EditAssignments">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    {/* the default location */}
                    <Row gutter={formGutter}>
                        {rules
                            ?.filter((rule: any) => rule.campus === null)
                            .map((rule: any) => (
                                <Col className="default-location" span={12} key={rule}>
                                    <Title level={4}>{t('default')}</Title>
                                    <div className="tip-container">
                                        <span className="tip">
                                            {t('Administration.assignment_default_tip')}
                                        </span>
                                    </div>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="managerUserDefault"
                                        label={t('ManagerUser.manager_label')}
                                        required
                                    >
                                        <ManagerUserSelect
                                            selectedManagerIds={
                                                defaultLocationManagerId
                                                    ? [defaultLocationManagerId]
                                                    : []
                                            }
                                            onSelectChange={(id?: string): void =>
                                                setDefaultLocationManagerId(id)
                                            }
                                            initialManagerSearchResults={initialManagerSearchResult}
                                            initialSelectedManagerOptions={initialSelectedManagerOption(
                                                defaultLocationManagerId
                                            )}
                                        />
                                    </ValidatedFormItem>
                                </Col>
                            ))}
                    </Row>
                    <Row gutter={formGutter}>
                        {rules
                            ?.filter((rule: any) => rule.campus !== null)
                            .map((rule: any) => (
                                <Col span={12} key={rule}>
                                    <Title level={4}>{rule.campus?.name}</Title>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name={`managerUser_${rule.campus?.id}`}
                                        label={t('ManagerUser.manager_label')}
                                    >
                                        <ManagerUserSelect
                                            isClearable
                                            selectedManagerIds={locationsManager
                                                ?.filter((obj) => obj.campusId === rule.campus?.id)
                                                .map((obj) => obj.accountId!)}
                                            onSelectChange={(id?: string): void =>
                                                onManagerSelect(rule.campus?.id, id)
                                            }
                                            initialManagerSearchResults={initialManagerSearchResult}
                                            initialSelectedManagerOptions={initialSelectedManagerOption(
                                                locationsManager?.find(
                                                    (obj) => obj.campusId === rule.campus?.id
                                                )?.accountId ?? undefined
                                            )}
                                        />
                                    </ValidatedFormItem>
                                </Col>
                            ))}
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(EditAssignments);
