import { AddressDto } from "Api/Features/General/Dtos/AddressDto";

export const oneLineAddress = (address?: AddressDto | null): string => {
    const oneLineAddress: string[] = [];

    if (address?.addressLine1) oneLineAddress.push(address.addressLine1);
    if (address?.addressLine2) oneLineAddress.push(address.addressLine2);
    if (address?.city) oneLineAddress.push(address.city);
    if (address?.state) oneLineAddress.push(address?.state);
    if (address?.postalCode) oneLineAddress.push(address.postalCode);

    return oneLineAddress.join(' ');
};