import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useService, useStores } from 'Hooks';
import { ManagerUserService } from 'Services/ManagerUserService';
import { ManagerUser } from 'Models/ManagerUsers/ManagerUser';
import { Trash, User as UserIcon } from 'Components/icons';
import { images, theme } from 'variant';
import { ListSectionHeader } from 'Components/list-section-header';
import { Col, Layout, Row } from 'antd';
import { SimpleList } from 'Components/simple-list';
import { LocationNotificationPref } from 'Models/ManagerUsers/LocationNotificationPref';
import ManagerUserModal from 'Routes/authenticated/administration/manager-users/create-manager';
import { ManagementRoleWithContextDto } from 'Api/Features/Accounts/Dtos/ManagementRoleWithContextDto';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { ActionMenu } from 'Components/action-menu';
import Icon from 'Components/icons/icon';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import { oneLineAddress } from 'Utils/AddressHelper';
import { observer } from 'mobx-react';
import { ManagementRoleDto } from 'Api/Features/ManagerUsers/Dtos/ManagementRoleDto';

const { usersHeader } = images;
const { Content } = Layout;

const ManagerUserDetail: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const {
        userPermissionsStore,
        locationStore,
        globalLoadingStore,
        confirmationModalStore,
        toastStore,
    } = useStores();
    const managerUserService = useService(ManagerUserService);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ManagerUser | undefined>(undefined);
    const [notificationsPreferences, setNotificationsPreferences] = useState<
        LocationNotificationPref[] | undefined
    >(undefined);
    const [basicInformation, setBasicInformation] = useState<SimpleListItemProps[] | null>(null);
    const [managementRoles, setManagementRoles] = useState<SimpleListItemProps[] | null>(null);
    const [address, setAddress] = useState<SimpleListItemProps[] | null>(null);

    const [editModalOpen, setEditModalOpen] = useState(false);
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const locationName = useCallback(
        (id: string | undefined): string => {
            const location = locationStore.locations.find((location) => location.id === id);
            return location?.name || '';
        },
        [locationStore]
    );

    const managementRoleName = useCallback(
        (role: ManagementRoleWithContextDto | null): string => {
            if (role?.name === ManagementRoleDto.Manager) {
                return (
                    t('ManagerUser.management_role_Manager') +
                    ' - ' +
                    locationName(role.context?.campusId)
                );
            }
            return role?.name || '';
        },
        [locationName, t]
    );

    const deleteManager = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Trash />,
                title: t(`ManagerUser.delete_manager`),
                message: t(`ManagerUser.delete_manager_confirm_message`),
                positiveText: t(`ManagerUser.delete_manager`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        // call delete
        await managerUserService.deleteManagerUser(id);
        toastStore.toast({
            type: 'success',
            messageKey: `ManagerUser.delete_manager_success`,
        });
        history.push('/administration/manager-users');
    };

    const ctaOptions: ActionMenuOption[] = [
        {
            key: 'edit',
            title: t('ManagerUser.manager_edit'),
            action: (): void => setEditModalOpen(true),
        },
    ];

    if (userPermissionsStore.userIsAdministrator) {
        ctaOptions.push({
            key: 'delete',
            title: t('ManagerUser.delete_manager'),
            action: (): Promise<void> => deleteManager(),
        });
    }

    const filterRoles = useCallback(
        (role: ManagementRoleWithContextDto | null): boolean => {
            const locationId = role?.context?.campusId;
            if (locationId) {
                return !!locationStore.locations.find((location) => location.id === locationId);
            }
            return true;
        },
        [locationStore]
    );

    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const data = await managerUserService.getById(id);

            const preferences = await managerUserService.getPreferences(id);
            if (data && preferences) {
                const user = new ManagerUser(data);
                setData(user);
                setNotificationsPreferences(
                    locationStore.locations.map(
                        (loc) =>
                            ({
                                location: loc,
                                checked: preferences.items?.some(
                                    (element) => element?.context === loc.id
                                ),
                            } as LocationNotificationPref)
                    )
                );

                setBasicInformation([
                    { title: user?.name || '' },
                    {
                        title: `${user.contactInfo?.email}`,
                        avatar: <UserIcon fill={theme['primary-color']} />,
                    },
                ]);

                if (user.address) {
                    setAddress([
                        {
                            avatar: <Icon iconName="RoadSign" fill={theme['primary-1']} />,
                            description: oneLineAddress(user?.address),
                        },
                    ]);
                }

                if (data.roles) {
                    setManagementRoles(
                        data.roles
                            .filter((role) => filterRoles(role))
                            .map(
                                (role) =>
                                    ({
                                        title: managementRoleName(role),
                                    } as SimpleListItemProps)
                            )
                    );
                }
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [
        managerUserService,
        id,
        globalLoadingStore,
        filterRoles,
        locationStore.locations,
        managementRoleName,
    ]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const onEditComplete = useCallback(
        (success: boolean) => {
            setEditModalOpen(false);
            if(success)
                fetch();
        },
        [fetch]
    );

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            nameKey: 'Administration.administration_title',
            path: 'administration',
        },
        {
            nameKey: 'ManagerUser.managers',
            path: 'manager-users',
        },
        {
            name: data?.name || '',
            path: data?.id || '',
        },
    ];

    return (
        <div>
            <ListSectionHeader
                title={data?.name || ''}
                subTitle={''}
                defaultImg={<UserIcon fill={theme['primary-color']} />}
                imgSrc={data?.imageUrl}
                width="150"
                backgroundImageUrl={usersHeader}
                loading={loading}
                routes={breadcrumbs}
                action={<ActionMenu options={ctaOptions} type="primary" trigger="click" />}
            />
            <Content>
                <Row gutter={40}>
                    <Col span={12}>
                        {basicInformation && (
                            <SimpleList title={t('basic_information')} data={basicInformation} />
                        )}
                        {managementRoles && (
                            <SimpleList
                                title={t('ManagerUser.management_roles')}
                                data={managementRoles}
                            />
                        )}
                    </Col>
                    <Col span={12}>
                        {address && address.length > 0 && (
                            <SimpleList
                                className="large-description"
                                title={t('address')}
                                data={address}
                            />
                        )}
                        {notificationsPreferences &&
                            notificationsPreferences.some((pref) => pref.checked) && (
                                <SimpleList
                                    title={t('Notification.notifications')}
                                    data={
                                        notificationsPreferences
                                            ?.filter((pref) => pref.checked)
                                            .map(
                                                (pref) =>
                                                    ({
                                                        title: pref.location.name,
                                                        description: t(
                                                            'ManagerUser.notification_explanation'
                                                        ),
                                                    } as SimpleListItemProps)
                                            ) || undefined
                                    }
                                />
                            )}
                    </Col>
                </Row>
            </Content>
            {editModalOpen && (
                <ManagerUserModal
                    visible={editModalOpen}
                    onComplete={onEditComplete}
                    managerUser={data}
                    previousPreferences={notificationsPreferences}
                />
            )}
        </div>
    );
});

export default ManagerUserDetail;
