import { ClosedOpportunitiesReportProxy } from 'Api/Features/Reports/ClosedOpportunitiesReportProxy';
import { ClosedOpportunitiesReportDto } from 'Api/Features/Reports/Dtos/ClosedOpportunities/ClosedOpportunitiesReportDto';
import { ClosedOpportunityReportItemDto } from 'Api/Features/Reports/Dtos/ClosedOpportunities/ClosedOpportunityReportItemDto';
import { GetClosedOpportunitiesReportRequestDto } from 'Api/Features/Reports/Dtos/ClosedOpportunities/GetClosedOpportunitiesReportRequestDto';
import { inject } from 'aurelia-dependency-injection';
import { arrayBufferToXlsx } from 'Utils/blobs';
import { ApiService } from './ApiService';

@inject(ClosedOpportunitiesReportProxy)
export class ClosedOpportunitiesReportService extends ApiService {
    constructor(private readonly closedOpportunitiesReportProxy: ClosedOpportunitiesReportProxy) {
        super();
    }

    public async getClosedOpportunitiesReport(
        request: GetClosedOpportunitiesReportRequestDto | null
    ): Promise<ClosedOpportunityReportItemDto[]> {
        const response: ClosedOpportunitiesReportDto | null = await this.closedOpportunitiesReportProxy.getClosedOpportunitiesReport(
            request
        );
        const closedOpportunities = response?.closedOpportunities
            ? response.closedOpportunities?.filter(
                  (closedOpportunity): closedOpportunity is ClosedOpportunityReportItemDto =>
                      closedOpportunity !== null
              )
            : [];

        return closedOpportunities;
    }

    public async exportClosedOpportunitiesReport(
        request: GetClosedOpportunitiesReportRequestDto | null
    ): Promise<Blob> {
        const document = await this.closedOpportunitiesReportProxy.exportClosedOpportunitiesReport(
            request
        );
        return arrayBufferToXlsx(document);
    }
}
