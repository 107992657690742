import { Button, Layout, Table, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetLeadsSortColumnDto } from 'Api/Features/Leads/Dtos/GetLeadsSortColumnDto';
import { LeadStatusDto } from 'Api/Features/Leads/Dtos/LeadStatusDto';
import { LeadUpcomingActivityDto } from 'Api/Features/Leads/Dtos/LeadUpcomingActivityDto';
import { ListSectionHeader } from 'Components/list-section-header';
import { TableFilters } from 'Components/table-filters';
import Tag, { TagColors } from 'Components/tag/tag';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import {
    ALL_LEADS,
    ALL_LOCATIONS,
    DISPLAY_DATE_FORMAT,
    MOMENT_PARSING_FORMAT,
    MY_LEADS,
    TABLE_DEBOUNCE_DELAY,
} from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { Lead } from 'Models/Leads/Lead';
import { ManagerUser } from 'Models/ManagerUsers/ManagerUser';
import moment from 'moment';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LeadRequest, LeadService } from 'Services/LeadService';
import { ManagerUserService } from 'Services/ManagerUserService';
import FilterStore from 'Stores/FilterStore';
import { RequestType } from 'Stores/RequestStore';
import { showFile } from 'Utils';
import { cleanVal } from 'Utils/NumberUtils';
import { images } from 'variant';
import CreateLeadModal from './create-lead';
import './index.less';

const { usersHeader } = images;
const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 10,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'statuses',
        nameKey: 'Statuses',
        items: [
            {
                key: LeadStatusDto.New,
                displayNameKey: 'Lead.status_New',
                checked: true,
            },
            {
                key: LeadStatusDto.TouchBase,
                displayNameKey: 'Lead.status_TouchBase',
                checked: true,
            },
            {
                key: LeadStatusDto.Engaged,
                displayNameKey: 'Lead.status_Engaged',
                checked: true,
            },
            {
                key: LeadStatusDto.Converted,
                displayNameKey: 'Lead.status_Converted',
                checked: true,
            },
            {
                key: LeadStatusDto.Lost,
                displayNameKey: 'Lead.status_Lost',
                checked: true,
            },
        ],
    },
];

const Leads: FunctionComponent = () => {
    const { t } = useTranslation();
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const paginationRef = useRef(initialPaginationState);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const {
        requestStore,
        userPermissionsStore,
        locationStore,
        authenticationStore,
        globalLoadingStore,
        toastStore,
    } = useStores();
    const [data, setData] = useState<Lead[]>([]);
    const leadService = useService(LeadService);
    const managerUserService = useService(ManagerUserService);
    const [loading, setLoading] = useState(false);
    const [managers, setManagers] = useState<ManagerUser[]>();
    const history = useHistory();
    const [requestSetFromCache, setRequestSetFromCache] = useState(false);
    const [isTableEmpty, setIsTableEmpty] = useState<boolean>(true);

    const renderUpcomingActivity = (lead: Lead): ReactNode => {
        const currentDate = moment();
        let closestTimeStamp = Number.MAX_SAFE_INTEGER;
        let eventType = '';
        let eventDate = moment();
        let hasActivity = false;

        const getActivity = (activities: (LeadUpcomingActivityDto | null)[]): void => {
            activities
                .filter(
                    (activity) =>
                        activity !== null &&
                        activity.startTime !== undefined &&
                        !currentDate.isSameOrAfter(activity.startTime)
                )
                .map((x) => x)
                .forEach((activity) => {
                    if (activity) {
                        const diff = moment(activity.startTime).diff(currentDate);

                        if (diff !== undefined && diff < closestTimeStamp) {
                            closestTimeStamp = diff;
                            eventType = activity.type?.toString() ?? '';
                            eventDate = moment.parseZone(activity.startTime);
                            hasActivity = true;
                        }
                    }
                });
        };

        if (lead.upcomingActivities) {
            getActivity(lead.upcomingActivities);
        }
        return (
            hasActivity && (
                <>
                    <Typography.Text className="event-type-label">
                        {`${t(`Lead.lead_event_${eventType}`)} `}
                    </Typography.Text>

                    <Typography.Text>
                        {`${t('Activity.add_activity_on')}
                        ${moment(eventDate, MOMENT_PARSING_FORMAT).format(DISPLAY_DATE_FORMAT)}`}
                    </Typography.Text>
                </>
            )
        );
    };

    const columns: ColumnType<Lead>[] = [
        {
            title: t('contact'),
            render: (lead: Lead): string => `${lead.firstName} ${lead.lastName}` || '',
        },
        {
            title: t('company'),
            render: (lead: Lead): string => lead.companyName || '',
            key: GetLeadsSortColumnDto.CompanyName,
            sorter: true,
        },
        {
            title: t('phone_number'),
            render: (lead: Lead): string => lead.phoneNumber || '',
            sorter: true,
            key: GetLeadsSortColumnDto.PhoneNumber,
        },
        {
            title: t('creation'),
            render: (lead: Lead): string => moment(lead.dateCreated).format(MOMENT_PARSING_FORMAT),
            sorter: true,
            key: GetLeadsSortColumnDto.DateCreated,
        },
        {
            title: t('Lead.lead_owner'),
            render: (lead: Lead): string =>
                `${lead.ownerAccount?.firstName} ${lead.ownerAccount?.lastName}`,
            sorter: true,
            key: GetLeadsSortColumnDto.OwnerName,
        },
        {
            title: t('Lead.source'),
            render: (lead: Lead): string => t(`Lead.source_${lead.source}`),
            sorter: true,
            key: GetLeadsSortColumnDto.Source,
        },
        {
            title: t('Lead.number_of_employees'),
            render: (lead: Lead): string =>
                lead.numberOfEmployees ? lead.numberOfEmployees + '' : '',
            sorter: true,
            key: GetLeadsSortColumnDto.NumberOfEmployees,
        },
        {
            title: t('location'),
            render: (lead: Lead): string => lead.campus?.name ?? '',
            sorter: true,
            key: GetLeadsSortColumnDto.CampusName,
        },
        {
            title: t('Lead.upcoming_activity'),
            render: renderUpcomingActivity,
        },
        {
            title: t('status'),
            dataIndex: 'status',
            // eslint-disable-next-line react/display-name
            render: (leadStatus: LeadStatusDto): ReactNode | null => (
                <Tag
                    label={t(`Lead.status_${leadStatus}`)}
                    color={
                        leadStatus === LeadStatusDto.Lost ? TagColors.disabled : TagColors.default
                    }
                />
            ),
            align: 'center',
            sorter: true,
            key: GetLeadsSortColumnDto.Status,
        },
    ];

    const getCampusId = (currentLocationId: string): string[] => {
        return currentLocationId !== ALL_LOCATIONS ? [currentLocationId] : [];
    };

    const getLeadOwnerId = useCallback(
        (currentLeadOwnerId: string): string[] => {
            if (currentLeadOwnerId === ALL_LEADS) return [];
            if (currentLeadOwnerId === MY_LEADS) return [authenticationStore.userId!];

            return [currentLeadOwnerId];
        },
        [authenticationStore.userId]
    );

    const getAdvancedFilterCheckedStatuses = (): {
        checkedStatuses: LeadStatusDto[];
        statusCount: number;
    } => {
        const filterStore = filterStoreRef.current;
        const statuses = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'statuses'
        );
        const checkedStatuses = statuses?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key;
            });
        return {
            checkedStatuses:
                checkedStatuses?.map((x) => LeadStatusDto[x as keyof typeof LeadStatusDto]) ?? [],
            statusCount: statuses?.items.length ?? 0,
        };
    };

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            currentLocationId?: string;
            advancedFilters?: AdvancedFilter[];
            currentLeadOwnerId?: string;
            sortColumn: GetLeadsSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);
            const filterStore = filterStoreRef.current;

            try {
                const statuses = getAdvancedFilterCheckedStatuses();
                if (statuses.checkedStatuses.length === 0) {
                    setData([]);
                } else {
                    const request: LeadRequest = requestStore.setRequest({
                        request: {
                            pageSize: params.pagination.pageSize || 10,
                            page: (params.pagination.current || 1) - 1,
                            searchTerm: params.searchTerm,
                            campusIds: getCampusId(filterStore.currentLocationId),
                            ownerAccountIds: getLeadOwnerId(filterStore.currentLeadOwnerId),
                            statuses:
                                statuses.checkedStatuses.length === statuses.statusCount
                                    ? []
                                    : statuses.checkedStatuses,
                            sortColumn: params.sortColumn,
                            sortDirection: params.sortDirection,
                        },
                        requestType: RequestType.Lead,
                    });

                    // call api
                    const [data, totalItems] = await leadService.getLeads(request);
                    setData(data);
                    setPagination({
                        ...params.pagination,
                        total: totalItems,
                    });
                    setIsTableEmpty(data.length === 0);
                }
            } finally {
                setLoading(false);
            }
        },
        [requestStore, leadService, getLeadOwnerId]
    );

    const getSaleManagers = useCallback(async () => {
        setLoading(true);
        try {
            // call api
            const [data] = await managerUserService.getManagerUsers({
                page: 0,
                pageSize: 1000,
            }); //TODO probablement devoir integrer un select custom dans les filtres de table
            const managersWithoutCurrentUser = data?.filter(
                (x: ManagerUser) => x.id !== authenticationStore.userId
            );
            setManagers(managersWithoutCurrentUser);
        } finally {
            setLoading(false);
        }
    }, [managerUserService, authenticationStore.userId]);

    useEffect(() => {
        getSaleManagers();
    }, [getSaleManagers]);

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        const { currentLocationId, advancedFilters, searchTerm, currentLeadOwnerId } =
            filterStoreRef.current;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        await fetch({
            pagination,
            currentLocationId,
            advancedFilters,
            searchTerm,
            currentLeadOwnerId,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
        paginationRef.current = pagination;
    };

    const onRowClick = (lead: Lead): void => {
        history.push(`leads/${lead.id}`);
    };

    const debounceSearch = useRef(
        debounce(
            (params: {
                searchTerm?: string;
                currentLocationId?: string;
                advancedFilters?: AdvancedFilter[];
                currentLeadOwnerId?: string;
            }) => {
                fetch({
                    pagination: {
                        ...paginationRef.current,
                        current: 1,
                    },
                    searchTerm: params.searchTerm,
                    currentLocationId: params.currentLocationId,
                    currentLeadOwnerId: params.currentLeadOwnerId,
                    advancedFilters: params.advancedFilters,
                    sortColumn: null,
                    sortDirection: null,
                });
            },
            TABLE_DEBOUNCE_DELAY
        )
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;

            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                currentLocationId: filterStore.currentLocationId,
                currentLeadOwnerId: filterStore.currentLeadOwnerId,
                advancedFilters: filterStore.advancedFilters,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    useEffect(() => {
        if (
            requestStore?.requestInfo?.request &&
            requestStore?.requestInfo?.requestType === RequestType.Lead &&
            !requestSetFromCache
        ) {
            setRequestSetFromCache(true);
            const filterStore = filterStoreRef.current;
            const requestFromStore: LeadRequest = requestStore.requestInfo.request;
            const filtersList = [{ key: requestFromStore?.statuses || [], parentKey: 'statuses' }];

            filterStore.updateLeadOwner(
                requestFromStore.ownerAccountIds && requestFromStore.ownerAccountIds.length > 0
                    ? requestFromStore.ownerAccountIds[0] === userPermissionsStore.userInfo?.id
                        ? MY_LEADS
                        : requestFromStore.ownerAccountIds[0]
                    : ALL_LEADS
            );
            filterStore.updateLocation(
                requestFromStore.campusIds && requestFromStore?.campusIds.length > 0
                    ? requestFromStore.campusIds[0]
                    : ALL_LOCATIONS
            );
            filterStore.updateSearchTerm(cleanVal.string(requestFromStore.searchTerm));
            filterStore.tickMultipleAdvancedFilter(filtersList);
        }
    }, [requestStore, userPermissionsStore.userInfo]);

    const onCreationComplete = (success: boolean, id?: string) => {
        setCreateModalOpen(false);
        if (success && id) history.push(`leads/${id}`);
    };

    const onExportClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            const document = await leadService.exportLeads(requestStore.requestInfo.request);

            showFile(document, 'Leads');
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <div className="Leads">
            <ListSectionHeader
                title={t('Lead.lead_title_plural')}
                subTitle={t('Lead.manage_all_leads')}
                imgSrc={images.usersHeader}
                backgroundImageUrl={usersHeader}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            setCreateModalOpen(true);
                        }}
                    >
                        {t('Lead.lead_create')}
                    </Button>
                }
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeLocations
                    includeLeadOwner
                    availableLocations={locationStore.locations}
                    includeAdvancedFilters
                    availableLeadOwners={managers}
                    includeExport
                    onExportClick={onExportClick}
                    exportBtnDisabled={isTableEmpty}
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(record: Lead): string => record.id!}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(row: Lead) => ({
                        onClick: (): void => {
                            onRowClick(row);
                        },
                    })}
                />
            </Content>
            {createModalOpen && (
                <CreateLeadModal visible={createModalOpen} onComplete={onCreationComplete} />
            )}
        </div>
    );
};

export default Leads;
