import { Button, Dropdown, Menu } from 'antd';
import { ActivityBoardNotificationItem } from 'Components/activity-board-notification-item';
import { ActivityBoardNotificationItemProps } from 'Components/activity-board-notification-item/activity-board-notification-item';
import { EmptyPage } from 'Components/empty-page';
import Icon from 'Components/icons/icon';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './activity-board-notification.less';

export interface ActivityBoardNotificationProps {
    newNotifications?: number;
    notificationsList?: ActivityBoardNotificationItemProps[];
    updateNotificationsStatus?: (isVisible: boolean) => void;
}

const ActivityBoardNotification: FunctionComponent<ActivityBoardNotificationProps> = ({
    newNotifications = 0,
    notificationsList,
    updateNotificationsStatus,
}) => {
    const { t } = useTranslation();

    const menu = (
        <Menu className="ActivityBoardNotification--menu">
            {notificationsList && notificationsList?.length > 0 ? (
                notificationsList.map((notification) => (
                    <Menu.Item key={notification.date}>
                        <ActivityBoardNotificationItem
                            titleImg={notification.titleImg}
                            title={notification.title}
                            date={notification.date}
                            link={notification.link}
                            companyName={notification.companyName}
                            isNew={notification.isNew}
                        />
                    </Menu.Item>
                ))
            ) : (
                <EmptyPage
                    iconName={'Bell'}
                    subTitle={t(`ActivityBoard.activity_board_notification_empty_subtitle`)}
                />
            )}
        </Menu>
    );

    return (
        <div className="ActivityBoardNotification">
            {newNotifications > 0 && (
                <div className="new-notifications">
                    You have <span className="bold">{newNotifications}</span> notifications
                </div>
            )}

            <Dropdown
                overlay={menu}
                trigger={['click']}
                placement="bottomRight"
                onVisibleChange={updateNotificationsStatus}
            >
                <div className={`button-wrapper hasNewNotifications-${newNotifications > 0}`}>
                    <Button shape="circle" icon={<Icon iconName="Bell" />} size="large" />
                </div>
            </Dropdown>
        </div>
    );
};

export default ActivityBoardNotification;
