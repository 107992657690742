import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';
import { SubscriptionDetailsRelatedOpportunityDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsRelatedOpportunityDto';
import { Tag } from 'Components/tag';
import { TagColors } from 'Components/tag/tag';
import { DISPLAY_DATE_FORMAT } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './subscription-opportunities-list-item.less';

interface SubscriptionOpportunitiesListItemProps {
    opportunity: SubscriptionDetailsRelatedOpportunityDto;
}

const SubscriptionOpportunitiesListItem: FunctionComponent<SubscriptionOpportunitiesListItemProps> =
    ({ opportunity }) => {
        const { t } = useTranslation();
        let tagColor = TagColors.default;

        switch (opportunity.status) {
            case OpportunityStatusDto.AgreementSigned:
                tagColor = TagColors.active;
                break;
            case OpportunityStatusDto.Lost:
                tagColor = TagColors.disabled;
                break;
        }

        return (
            <div className={'SubscriptionOpportunitiesListItem'} key={opportunity.id}>
                <div className="info">
                    <div className="name">{opportunity.name}</div>
                    <div>
                        <Tag
                            color={tagColor}
                            label={t(`Opportunity.opportunity_status_${opportunity.status}`)}
                        />{' '}
                        <span className="date">
                            {t('on')}{' '}
                            {moment(opportunity.lastStatusChangeDate).format(DISPLAY_DATE_FORMAT)}
                        </span>
                    </div>
                </div>
                <div className="link">
                    <Link to={`/opportunities/${opportunity.id}/dashboard`}>
                        {t('Opportunity.view_opportunity')}
                    </Link>
                </div>
            </div>
        );
    };

export default SubscriptionOpportunitiesListItem;
