import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { UploadFile } from 'antd/lib/upload/interface';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { CreatePlanRequestDto } from 'Api/Features/Plans/Dtos/CreatePlanRequestDto';
import { PlanDto } from 'Api/Features/Plans/Dtos/PlanDto';
import { UnitTypeDto } from 'Api/Features/Units/Dtos/UnitTypeDto';
import BaseModal from 'Components/base-modal/base-modal';
import Icon from 'Components/icons/icon';
import ImagePicker, { ImageDetails } from 'Components/image-picker/image-picker';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CreateSubscriptionPlanTemplateSchema } from 'Schemas/CreateSubscriptionPlanSchema';
import { PlanService } from 'Services/PlanService';
import './create-subscription-plan-template-modal.less';

const { Title } = Typography;

const formGutter: [Gutter, Gutter] = [40, 0];

const selectOptions = (options: SelectOption[]): ReactNode[] => {
    return options.map((option) => (
        <Select.Option key={option.id} value={option.id}>
            {option.name}
        </Select.Option>
    ));
};
interface SelectOption {
    id: string;
    name: string;
}
interface CreateSubscriptionPlanTemplateModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    subscriptionPlan?: PlanDto;
}

const CreateSubscriptionPlanTemplateModal: FunctionComponent<CreateSubscriptionPlanTemplateModalProps> = ({
    visible,
    onComplete,
    subscriptionPlan,
}) => {
    const planService = useService(PlanService);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [mainImageDetails, setMainImageDetails] = useState<ImageDetails[]>();
    const [imagesDetails, setImagesDetails] = useState<ImageDetails[]>();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(
        CreateSubscriptionPlanTemplateSchema, form
    );
    const { t } = useTranslation();

    const unitTypeOptions: SelectOption[] = Object.keys(UnitTypeDto).map((type) => ({
        id: type,
        name: t(`Unit.type_${type}`),
    }));

    const dismiss = (success = false, id?: string): void => {
        onComplete(success, id);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Plan" />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t(
                    `Administration.subscription_plans_confirm_positive${
                        subscriptionPlan !== undefined ? '_edit' : ''
                    }`
                ),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const handleTermsChange = (value: string): void => {
        form.setFieldsValue({ terms: value });
    };
    const handleDescriptionChange = (value: string): void => {
        form.setFieldsValue({ description: value });
    };
    const handleDesignedForChange = (value: string): void => {
        form.setFieldsValue({ designedFor: value });
    };
    const handleInclusionChange = (value: string): void => {
        form.setFieldsValue({ inclusion: value });
    };
    const handleMainImageChange = (images: ImageDetails[] | undefined): void => {
        setMainImageDetails(images);
    };
    const handleImagesChange = (images: ImageDetails[] | undefined): void => {
        setImagesDetails(images);
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();

        const data: CreatePlanRequestDto = {
            name: formValues.name,
            unitType: formValues.unitType,
            terms: formValues.terms,
            description: formValues.description,
            designedFor: formValues.designedFor,
            inclusion: formValues.inclusion,
            mainImage: mainImageDetails
                ? ({
                      delete: mainImageDetails[0].isDeleted,
                      uploadBase64: mainImageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null,
            images: imagesDetails
                ? imagesDetails
                      ?.filter((image) => image.isDeleted || (!image.isDeleted && image.base64))
                      .map(
                          (image) =>
                              ({
                                  delete: image.isDeleted,
                                  uploadBase64: image.base64,
                                  id: image.id,
                              } as UpdateFileRequestDto)
                      )
                : null,
        };

        if (!(await validateForm(data))) return;

        try {
            globalLoadingStore.addLoading();
            let createdId = undefined;

            if (subscriptionPlan) {
                await planService.updatePlan(subscriptionPlan.id!, data);
            } else {
                createdId = await planService.createPlan(data);
            }

            toastStore.toast({
                type: 'success',
                messageKey: `Administration.subscription_plans_${
                    subscriptionPlan ? 'edited' : 'created'
                }`,
            });

            dismiss(true, createdId);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    useEffect(() => {
        if (subscriptionPlan) {
            form.setFieldsValue({
                name: subscriptionPlan.name,
                unitType: subscriptionPlan.unitType,
                terms: subscriptionPlan.terms,
                description: subscriptionPlan.description,
                designedFor: subscriptionPlan.designedFor,
                inclusion: subscriptionPlan.inclusion,
                mainImage: subscriptionPlan.imageUrl,
                images: subscriptionPlan.imageUrls,
            });
        } else {
            form.setFieldsValue({
                unitType: unitTypeOptions[0].id,
            });
        }
    }, [subscriptionPlan, form]);

    return (
        <BaseModal
            visible={visible}
            title={
                subscriptionPlan !== undefined
                    ? t('Administration.subscription_plans_edit_plan_template')
                    : t('Administration.subscription_plans_create_plan_template')
            }
            className="CreateSubscriptionPlanTemplateModal FormModal"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Title level={4}>{t('basic_information')}</Title>

                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem
                            errors={errors}
                            name="name"
                            label={t('name')}
                            required={true}
                        >
                            <Input />
                        </ValidatedFormItem>
                    </Col>

                    <Col span={12}>
                        <ValidatedFormItem name="unitType" label={t('Unit.type_title')}>
                            <Select>{selectOptions(unitTypeOptions)}</Select>
                        </ValidatedFormItem>
                    </Col>

                    <Col span={24} className="ReactQuill-wrapper">
                        <ValidatedFormItem
                            errors={errors}
                            name="terms"
                            label={t('Administration.subscription_plans_terms_of_payment')}
                        >
                            <Input hidden />
                        </ValidatedFormItem>

                        <ReactQuill
                            theme="snow"
                            onChange={handleTermsChange}
                            value={form.getFieldValue('terms') || subscriptionPlan?.terms || null}
                        />
                    </Col>

                    <Col span={24} className="ReactQuill-wrapper">
                        <ValidatedFormItem
                            errors={errors}
                            name="description"
                            label={t('ConferenceRoom.description_title')}
                        >
                            <Input hidden />
                        </ValidatedFormItem>

                        <ReactQuill
                            theme="snow"
                            onChange={handleDescriptionChange}
                            value={
                                form.getFieldValue('description') ||
                                subscriptionPlan?.description ||
                                null
                            }
                        />
                    </Col>

                    <Col span={24} className="ReactQuill-wrapper">
                        <ValidatedFormItem
                            errors={errors}
                            name="designedFor"
                            label={t('Administration.subscription_plans_designed_for')}
                        >
                            <Input hidden />
                        </ValidatedFormItem>

                        <ReactQuill
                            theme="snow"
                            onChange={handleDesignedForChange}
                            value={
                                form.getFieldValue('designedFor') ||
                                subscriptionPlan?.designedFor ||
                                null
                            }
                        />
                    </Col>

                    <Col span={24} className="ReactQuill-wrapper">
                        <ValidatedFormItem
                            errors={errors}
                            name="inclusion"
                            label={t('Administration.subscription_plans_what_is_included')}
                        >
                            <Input hidden />
                        </ValidatedFormItem>
                        <ReactQuill
                            theme="snow"
                            onChange={handleInclusionChange}
                            value={
                                form.getFieldValue('inclusion') ||
                                subscriptionPlan?.inclusion ||
                                null
                            }
                        />
                    </Col>
                </Row>

                <Title level={4}>{t('main_image')}</Title>

                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem name="mainImage">
                            <ImagePicker
                                images={
                                    subscriptionPlan?.imageUrl
                                        ? [
                                              {
                                                  url: subscriptionPlan?.imageUrl,
                                                  uid: 'main',
                                              } as UploadFile,
                                          ]
                                        : undefined
                                }
                                setImagesDetails={handleMainImageChange}
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Title level={4}>{t('images')}</Title>

                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem name="images">
                            <ImagePicker
                                allowMultiple
                                images={
                                    subscriptionPlan?.images
                                        ? subscriptionPlan.images.map(
                                              (image) =>
                                                  ({
                                                      url: image?.url,
                                                      uid: image?.id,
                                                  } as UploadFile)
                                          )
                                        : undefined
                                }
                                setImagesDetails={handleImagesChange}
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>
                    <Button type="primary" className="positive" htmlType="submit">
                        {t('submit')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
    //#endregion
};

export default CreateSubscriptionPlanTemplateModal;
