import React, {
    FunctionComponent, useState,
} from 'react';
import { Content } from 'antd/lib/layout/layout';
import Icon from 'Components/icons/icon';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { theme } from 'variant';
import { useHistory, useParams } from 'react-router';
import CompaniesTable from 'Routes/authenticated/companies/companies-table';
import { Button } from 'antd';
import CreateCompany from 'Routes/authenticated/companies/modals/create-company';
import CreateOpportunityFromContactModal from 'Routes/authenticated/opportunities/create-opportunity-for-contact-modal';

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'companies',
        nameKey: 'companies',
    },
];
const Companies: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const [addCompanyModalIsVisible, setAddCompanyModalIsVisible] = useState(false);
    const [createOpportunityState, setCreateOpportunityState] = useState<{
        visible: boolean;
        contactId?: string;
        contactName?: string;
        membershipId?: string;
        membershipName?: string;
    }>({ visible: false });

    return (
        <div className="Companies">
            <LocationHeader
                title={t('companies')}
                subTitle={t('Membership.manage_companies_from_location')}
                defaultImg={<Icon iconName="Company" fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            setAddCompanyModalIsVisible(true);
                        }}
                    >
                        {t('Membership.create_company')}
                    </Button>
                }
            />
            <Content>
                <CompaniesTable campusId={id} />
            </Content>

            {addCompanyModalIsVisible && (
                <CreateCompany
                    visible={addCompanyModalIsVisible}
                    onComplete={() => setAddCompanyModalIsVisible(false)}
                    onCreateOpportunity={(contactId, contactName, membershipId, membershipName) =>
                        setCreateOpportunityState({
                            visible: true,
                            contactId,
                            contactName,
                            membershipId,
                            membershipName,
                        })
                    }
                />
            )}

            {createOpportunityState.visible && (
                <CreateOpportunityFromContactModal
                    visible={createOpportunityState.visible}
                    onComplete={(success: boolean, id?: string) => {
                        if (success && id) history.push(`/opportunities/${id}/dashboard`);
                        setCreateOpportunityState({ visible: false });
                    }}
                    contactId={createOpportunityState.contactId}
                    contactName={createOpportunityState.contactName}
                    membershipId={createOpportunityState.membershipId}
                    membershipName={createOpportunityState.membershipName}
                />
            )}
        </div>
    );
});

export default Companies;
