import React, {
    FunctionComponent, useState,
} from 'react';
import { Content } from 'antd/lib/layout/layout';
import Icon from 'Components/icons/icon';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { theme } from 'variant';
import { useHistory, useParams } from 'react-router';
import FloorPlanComponent, { FloorPlanViewType } from 'Components/floor-plan/floor-plan';
import { Button, Col, Row } from 'antd';
import './index.less';
import { AngleLeft, AngleRight } from 'Components/icons';

const FloorPlan: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const [viewType, setViewType] = useState<FloorPlanViewType>(FloorPlanViewType.HeatMap);

    const [legendIsOpen, setLegendIsOpen] = useState(false);

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'floor-plan',
            nameKey: 'floor_plan',
        },
    ];

    return (
        <div className="FloorPlanPage">
            <LocationHeader
                title={t('floor_plan')}
                subTitle={t('FloorPlan.floor_plan_section_subtitle')}
                defaultImg={<Icon iconName="FloorPlanIcon" fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            history.push('management/floor-plan');
                        }}
                    >
                        {t('FloorPlan.manage_floor_plan')}
                    </Button>
                }
            />
            <Content>
                <Row gutter={[0, 40]}>
                    <Col span={24}>
                        <span
                            className="heat-map-toggle"
                            onClick={() => {
                                if (viewType === FloorPlanViewType.HeatMap)
                                    setViewType(FloorPlanViewType.Location);
                                else setViewType(FloorPlanViewType.HeatMap);
                            }}
                        >
                            {t(`FloorPlan.show_hide_heat_map`, {
                                param1: viewType === FloorPlanViewType.HeatMap ? 'hide' : 'show',
                            })}
                        </span>
                    </Col>
                </Row>

                <FloorPlanComponent
                    campusId={id}
                    height={700}
                    svgInstanceZoom={1.75}
                    viewType={viewType}
                />

                <div
                    className={`legend-container ${legendIsOpen ? 'open' : ''}`}
                    onClick={() => setLegendIsOpen((old) => !old)}
                >
                    <div className="inner-container">
                        <div className="arrow">{legendIsOpen ? <AngleRight /> : <AngleLeft />}</div>
                        <div>
                            <div className="item">
                                <div className="circle CommunalArea" />
                                {legendIsOpen ? (
                                    <div className="text">{t('Unit.type_CommunalArea')}</div>
                                ) : (
                                    ''
                                )}
                            </div>

                            <div className="item">
                                <div className="circle SixPlusMonths" />
                                {legendIsOpen ? (
                                    <div className="text">{t('FloorPlan.heat_map_6+')}</div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="item">
                                <div className="circle ThreeToSixMonths" />
                                {legendIsOpen ? (
                                    <div className="text">{t('FloorPlan.heat_map_3_6')}</div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="item">
                                <div className="circle OneToThreeMonths" />
                                {legendIsOpen ? (
                                    <div className="text">{t('FloorPlan.heat_map_1_3')}</div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="item">
                                <div className="circle ThisMonth" />
                                {legendIsOpen ? (
                                    <div className="text">{t('FloorPlan.heat_map_this_month')}</div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="item">
                                <div className="circle Vacant" />
                                {legendIsOpen ? <div className="text">{t('FloorPlan.heat_map_vacant')}</div> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </div>
    );
});

export default FloorPlan;
