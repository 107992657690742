import memoize from 'memoize-one';
import { DATETIME_LTS_FORMAT, DISPLAY_DATE_FORMAT, MOMENT_PARSING_FORMAT } from 'Models/Constants';
import moment, { Moment } from 'moment';

const quarterHours = ['00', '15', '30', '45'];
const halfHours = ['00', '30'];
const zoomMinutes = [60, 30, 15];

function splitToDigit(n: number): number[] {
    return [...(n + '')].map(Number);
}

export const generateTimespan = memoize(
    (min, max, zoom, showSeconds = false, showSuffix = false) => {
        const times = [];
        const minutes = zoomMinutes[zoom];
        const seconds = showSeconds ? ':00' : '';

        for (let i = min; i < max; i++) {
            const timeSuffix = showSuffix ? (i < 12 ? ' AM' : ' PM') : '';
            const minutesToUse = minutes === 30 ? halfHours : quarterHours;

            let hours = i;

            if (i > 12) {
                const splitedHours = splitToDigit(i - 2);
                if (i < 22) {
                    hours = splitedHours[1];
                } else {
                    hours = Number('1' + splitedHours[1]);
                }
            }

            for (let j = 0; j < 60 / minutes; j++) {
                times.push(hours + ':' + minutesToUse[j] + seconds + timeSuffix);
            }
        }
        return times;
    }
);

export const dateMomentToString = (date: Moment | string | null | undefined): string | null =>
    date !== null && date !== undefined
        ? typeof date === 'string'
            ? date
            : moment(date).format()
        : null;

export const displayFormatedDate = (
    date: string | undefined,
    format = DISPLAY_DATE_FORMAT
): string => moment(date, MOMENT_PARSING_FORMAT).format(format);

export const displayFormatedDateTime = (date: string | undefined): string =>
    moment.utc(date, DATETIME_LTS_FORMAT).tz(moment.tz.guess()).format(DATETIME_LTS_FORMAT);
