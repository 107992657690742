import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Search: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M.623 6.423c0-3.199 2.601-5.8 5.799-5.8 3.198 0 5.8 2.601 5.8 5.8 0 1.66-.701 3.16-1.824 4.219a5.784 5.784 0 01-3.976 1.58 5.806 5.806 0 01-5.8-5.8zm15.042 9.046l-4.607-4.607a6.398 6.398 0 001.787-4.44A6.43 6.43 0 006.422 0 6.43 6.43 0 000 6.423c0 3.54 2.88 6.422 6.422 6.422a6.39 6.39 0 004.184-1.555l4.619 4.619a.31.31 0 00.44 0 .31.31 0 000-.44z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Search;
