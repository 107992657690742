// GENERATED FILE - DO NOT MODIFY
import { CampusSurroundingDto } from 'Api/Features/CampusSurroundings/Dtos/CampusSurroundingDto';
import { CreateCampusSurroundingRequestDto } from 'Api/Features/CampusSurroundings/Dtos/CreateCampusSurroundingRequestDto';
import { GetCampusSurroundingsRequestDto } from 'Api/Features/CampusSurroundings/Dtos/GetCampusSurroundingsRequestDto';
import { GetCampusSurroundingsResponseDto } from 'Api/Features/CampusSurroundings/Dtos/GetCampusSurroundingsResponseDto';
import { UpdateCampusSurroundingRequestDto } from 'Api/Features/CampusSurroundings/Dtos/UpdateCampusSurroundingRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class CampusSurroundingProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getCampusSurrounding(campusSurroundingId: string): Promise<CampusSurroundingDto | null> {
        const uri = this.buildUri(
            "/campussurroundings/{campusSurroundingId}",
            { campusSurroundingId: campusSurroundingId },
            null
        );

        const data: CampusSurroundingDto | null = await this.httpClient.get<CampusSurroundingDto | null>(uri);
        return data;
    }

    public async getCampusSurroundings(request: GetCampusSurroundingsRequestDto | null): Promise<GetCampusSurroundingsResponseDto | null> {
        const uri = this.buildUri(
            "/campussurroundings",
            null,
            { ...request || undefined }
        );

        const data: GetCampusSurroundingsResponseDto | null = await this.httpClient.get<GetCampusSurroundingsResponseDto | null>(uri);
        return data;
    }

    public async createCampusSurrounding(request: CreateCampusSurroundingRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/campussurroundings",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateCampusSurroundingRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateCampusSurrounding(campusSurroundingId: string, request: UpdateCampusSurroundingRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/campussurroundings/{campusSurroundingId}",
            { campusSurroundingId: campusSurroundingId },
            null
        );

        await this.httpClient.patch<UpdateCampusSurroundingRequestDto | null, void>(uri, request);
    }

    public async deleteCampusSurrounding(campusSurroundingId: string): Promise<void> {
        const uri = this.buildUri(
            "/campussurroundings/{campusSurroundingId}",
            { campusSurroundingId: campusSurroundingId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}