import { FunctionComponent, useEffect } from 'react';
import { autorun } from 'mobx';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../Hooks';
import { message } from 'antd';
import './index.less';

const ToastContainer: FunctionComponent = () => {
    const { t } = useTranslation();
    const { toastStore } = useStores();
    useEffect(
        () =>
            autorun(() => {
                if (toastStore.currentToast) {
                    message[toastStore.currentToast.type](
                        toastStore.currentToast.message ||
                            t(toastStore.currentToast.messageKey || ''),
                        toastStore.currentToast.displayTime ?? 5
                    );
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [t]
    );
    return null;
};

export default ToastContainer;
