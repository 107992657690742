import { observable, action, computed } from 'mobx';
import { inject } from 'aurelia-dependency-injection';
import { BookingService } from 'Services/BookingService';
import LoadingStore from 'Stores/LoadingStore';
import AmenityStore from './AmenityStore';
import { DailyBookingSummaryAmenityDto } from 'Api/Features/Amenities/Dtos/DailyBookingSummaryAmenityDto';

@inject(BookingService, AmenityStore)
class BookingStore {
    constructor(
        private readonly bookingService: BookingService,
        private readonly loadingStore: LoadingStore,
        private readonly amenityStore: AmenityStore
    ) {}

    @observable
    private _dailyBookingSummary: Array<DailyBookingSummaryAmenityDto | null> | null = [];

    @computed
    get dailyBookingSummary(): Array<DailyBookingSummaryAmenityDto | null> | null {
        return this._dailyBookingSummary;
    }

    @action
    async setDailyBookingSummary(id: string, date: string): Promise<void> {
        const data = await this.bookingService.getDailyBookingSummary(id, date);
        if (data?.amenities !== undefined) this._dailyBookingSummary = data?.amenities;
    }
}

export default BookingStore;
