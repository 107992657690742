import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Radio,
    Row,
    Tooltip,
    Typography,
} from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { FormInstance } from 'antd/lib/form';
import { SubscriptionDetailsDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsDto';
import { GetUnitsRequestDto } from 'Api/Features/Units/Dtos/GetUnitsRequestDto';
import {
    Company as CompanyIcon,
    Location as LocationIcon,
    Plan as PlanIcon,
} from 'Components/icons';
import Icon from 'Components/icons/icon';
import NumberInput from 'Components/NumberInput/number-input';
import AddOn from 'Components/proposal/add-on';
import Discount from 'Components/proposal/discount';
import Pricing from 'Components/proposal/pricing';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { AddOn as AddOnModel } from 'Models/AddOn/AddOn';
import { CREDIT_SUGGESTION_DIVIDER, DISPLAY_MONTH_YEAR_FORMAT, MILLION } from 'Models/Constants';
import { Opportunity } from 'Models/Opportunities/Opportunity';
import { Plan } from 'Models/Plans/Plan';
import { Unit } from 'Models/Units/Unit';
import moment from 'moment';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { PlanService } from 'Services/PlanService';
import { UnitService } from 'Services/UnitService';
import { dateMomentToString } from 'Utils/TimeUtils';
import { theme } from 'variant';
import './proposal-form-section.less';

const { Title } = Typography;

const formGutter: [Gutter, Gutter] = [40, 0];

export enum ProposalDateType {
    Month = 'Month',
    Date = 'Date',
}

export enum PaymentModalities {
    Check = 'Check',
    PreAuthorized = 'PreAuthorized',
    Manual = 'Manual',
}

interface UnitMarketPrice {
    id: string;
    marketPrice: number;
}

interface ProposalFormSectionProps {
    errors: Map<string, string[]>;
    opportunity?: Opportunity;
    form: FormInstance;
    handleConvertToMtmOnExpirationChange: any;
    handleMtmChange: any;
    handleUnitChange: any;
    oldSubscription?: SubscriptionDetailsDto;
    isSubscriptionModification?: boolean;
    isSubscriptionRenewal?: boolean;
}

const ProposalFormSection: FunctionComponent<ProposalFormSectionProps> = ({
    errors,
    opportunity,
    form,
    handleConvertToMtmOnExpirationChange,
    handleMtmChange,
    handleUnitChange,
    oldSubscription,
    isSubscriptionModification,
    isSubscriptionRenewal,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const unitService = useService(UnitService);
    const planService = useService(PlanService);
    const { globalLoadingStore } = useStores();
    const pageSize = 25;

    const [paymentModalities, setPaymentModalities] = useState(PaymentModalities.PreAuthorized);
    const [mtmCheckboxState, setMtmCheckboxState] = useState(true);
    const [convertToMtmCheckboxState, setConvertToMtmCheckboxState] = useState(
        isSubscriptionModification && oldSubscription?.becomesMonthToMonthOnExpiration
    );
    const [cannotConvertOnExpiration, setCannotConvertOnExpiration] = useState(false);

    //dates
    const [startDateType, setStartDateType] = useState<ProposalDateType>(ProposalDateType.Month);
    const [startDateValue, setStartDateValue] = useState<moment.Moment>();
    const [endDateType, setEndDateType] = useState<ProposalDateType>(ProposalDateType.Month);
    const [endDateValue, setEndDateValue] = useState<moment.Moment>();

    //units
    const [unitsCurrentPage, setUnitsCurrentPage] = useState(0);
    const [unitsSearchResults, setUnitsSearchResults] = useState<Unit[]>([]);
    const [unitsSearchTerm, setUnitsSearchTerm] = useState('');
    const [unitsMaxResults, setUnitsMaxResults] = useState(false);
    const [unitsOptions, setUnitsOptions] = useState<SelectCustomOption[]>([]);
    const [selectedUnitIds, SetSelectedUnitIds] = useState<string[]>([]);
    const [selectedUnitsOptions, setSelectedUnitsOptions] = useState<SelectCustomOption[]>([]);
    const [unitDropdownIsDisabled, setUnitDropdownIsDisabled] = useState(true);

    //plans
    const [availablePlans, setAvailablePlans] = useState<Plan[]>([]);
    const [plansOptions, setPlansOptions] = useState<SelectCustomOption[]>([]);
    const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>(undefined);

    //memberships
    const [membershipsOptions, setMembershipsOptions] = useState<SelectCustomOption[]>([]);
    const [selectedMembershipId, setSelectedMembershipId] = useState<string>();

    //locations
    const [locationsOptions, setLocationsOptions] = useState<SelectCustomOption[]>([]);
    const [selectedLocationId, setSelectedLocationId] = useState<string>();

    //addOns
    const [addOnsPriceInputValues, setAddOnsPriceInputValues] = useState<number[]>([]);
    const [availableAddOns, setAvailableAddOns] = useState<(AddOnModel | null)[]>([]);

    //multiple pricing
    const [multiplePriceInputValues, setMultiplePriceInputValues] = useState<number[]>([]);
    const [suggestedPrice, setSuggestedPrice] = useState<string>('0.00');
    const [pricingStartDateValues, setPricingStartDateValues] = useState<
        (moment.Moment | undefined)[]
    >([]);
    const [oldSubscriptionPricingWasTouchedArray, setOldSubscriptionPricingWasTouchedArray] =
        useState<boolean[]>([]);

    //credits
    const [suggestedCredits, setSuggestedCredits] = useState<string>('0.00');

    const [selectLoading, setSelectLoading] = useState(false);

    //#endregion Hooks

    const resetUnitsSearch = (): void => {
        setUnitsCurrentPage(0);
        setUnitsSearchResults([]);
        setUnitsSearchTerm('');
        setUnitsMaxResults(false);
    };

    const resetUnitsSelection = useCallback((): void => {
        SetSelectedUnitIds([]);
        handleUnitChange([]);
    }, [handleUnitChange]);

    const handleUnitsKeywordsChange = useCallback((value: string): void => {
        resetUnitsSearch();
        setUnitsSearchTerm(value);
    }, []);

    const handleUnitsMenuScrollToBottom = (): void => {
        if (!unitsMaxResults) {
            setUnitsCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const searchUnits = async (
        page: number,
        searchTerm: string,
        selectedLocationId?: string,
        selectedPlanId?: string,
        startDate?: moment.Moment,
        endDate?: moment.Moment | null,
        availablePlans?: Plan[],
        oldSubscription?: SubscriptionDetailsDto
    ): Promise<Unit[]> => {
        if (
            !selectedLocationId ||
            !selectedPlanId ||
            !startDate ||
            (!mtmCheckboxState && !endDate)
        ) {
            setSelectLoading(false);
            return [];
        }
        globalLoadingStore.addLoading();

        const unitType = availablePlans?.find((x: Plan) => x.id === selectedPlanId)?.unitType;
        const request: GetUnitsRequestDto = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
            campusIds: [selectedLocationId],
            includeAvailabilities: true,
            availabilitiesStartDate: startDate
                ? dateMomentToString(startDate) || undefined
                : undefined,
            availabilitiesEndDate: endDate ? dateMomentToString(endDate) || undefined : undefined,
            types: unitType ? [unitType] : undefined,
            availabilitiesIgnoredOpportunityIds: opportunity?.id ? [opportunity.id] : null,
            availabilitiesIgnoredSubscriptionIds: oldSubscription?.id ? [oldSubscription.id] : null,
        };

        const [results] = await unitService.getUnits(request);

        if (results.length < pageSize) {
            setUnitsMaxResults(true);
        }
        setSelectLoading(false);
        globalLoadingStore.removeLoading();
        return results;
    };

    const debounceUnitsSearch = useRef(
        debounce(
            (
                page: number,
                searchTerm: string,
                selectedLocationId?: string,
                selectedPlanId?: string,
                startDate?: moment.Moment,
                endDate?: moment.Moment | null,
                availablePlans?: Plan[],
                oldSubscription?: SubscriptionDetailsDto
            ) => {
                searchUnits(
                    page,
                    searchTerm,
                    selectedLocationId,
                    selectedPlanId,
                    startDate,
                    endDate,
                    availablePlans,
                    oldSubscription
                ).then((results) => {
                    setUnitsSearchResults((prevResults) => [...prevResults, ...results]);
                });
            },
            300
        )
    );

    const fetchPlans = useCallback(
        async (campusId: string) => {
            if (campusId) {
                const [items] = await planService.getPlans({
                    campusIds: [campusId],
                });
                setAvailablePlans(items);
            }
            setSelectLoading(false);
        },
        [planService]
    );

    const handlePlanSelect = useCallback(
        (value: any): void => {
            setSelectedPlanId(value.value);
            const addOns = availablePlans?.find((x: Plan) => x.id === value.value)?.addOns;
            setAvailableAddOns(addOns ?? []);
            resetUnitsSelection();
            resetUnitsSearch();
        },
        [availablePlans, resetUnitsSelection]
    );
    //#region Event handlers

    const onMtmChange = (isChecked: boolean): void => {
        setMtmCheckboxState(isChecked);
        handleMtmChange(isChecked);
        if (isChecked) {
            setEndDateValue(undefined);
            form.setFieldsValue({ endDate: undefined });
            setConvertToMtmCheckboxState(false);
        }
    };

    const onAddOnPriceChange = (value: number, index: number): void => {
        addOnsPriceInputValues.splice(index, 1, value);
        setAddOnsPriceInputValues([...addOnsPriceInputValues]);
    };

    const onAddOnRemove = (index: number): void => {
        addOnsPriceInputValues.splice(index, 1);
        setAddOnsPriceInputValues([...addOnsPriceInputValues]);
    };

    const onMultiplePriceChange = (value: number, index: number): void => {
        multiplePriceInputValues.splice(index, 1, value);
        setMultiplePriceInputValues([...multiplePriceInputValues]);
    };

    const onMultiplePriceRemove = (index: number): void => {
        multiplePriceInputValues.splice(index, 1);
        pricingStartDateValues.splice(index, 1);

        setMultiplePriceInputValues([...multiplePriceInputValues]);
        setPricingStartDateValues([...pricingStartDateValues]);
    };

    const handleUnitSelect = useCallback(
        (values: any): void => {
            if (values === '') {
                SetSelectedUnitIds([]);
                handleUnitChange([]);
            } else {
                const options = values.map((x: any) => x as SelectCustomOption);
                setSelectedUnitsOptions(options);
                SetSelectedUnitIds(values?.map((x: any) => x.value));
                handleUnitChange(values?.map((x: any) => x.value));
            }
        },
        [handleUnitChange]
    );

    const handlePriceDateChange = (value: moment.Moment | undefined, index: number): void => {
        const oldState = [...pricingStartDateValues];
        oldState[index] = value;
        setPricingStartDateValues(oldState);
    };

    const handlePricingTouched = (index: number): void => {
        const oldState = oldSubscriptionPricingWasTouchedArray;
        oldState[index] = true;
        setOldSubscriptionPricingWasTouchedArray(oldState);
    };

    //#endregion Event handlers

    //#region Effects

    //plan search
    useEffect(() => {
        if (opportunity?.campus?.id) fetchPlans(opportunity?.campus?.id!);
    }, [opportunity, fetchPlans]);

    //plan dropdown options
    useEffect(() => {
        if (availablePlans) {
            setPlansOptions(
                availablePlans.map(
                    (plan: Plan) =>
                        ({
                            value: plan?.id,
                            label: plan?.name,
                            content: plan?.price
                                ? t('Proposal.plan_starting_at', {
                                      param1: plan?.price?.toFixed(2) ?? 0.0,
                                  })
                                : '',
                            imageUrl: plan?.imageUrl,
                            badge: undefined,
                        } as SelectCustomOption)
                )
            );
        }
    }, [availablePlans, t]);

    //membership dropdown options
    useEffect(() => {
        if (opportunity?.membership) {
            const membership = opportunity.membership;
            setMembershipsOptions([
                {
                    label: membership.name,
                    value: membership.id,
                    badge: undefined,
                    imageUrl: membership.imageUrl,
                } as SelectCustomOption,
            ]);
            setSelectedMembershipId(membership.id!);
        }
    }, [opportunity]);

    //Location dropdown options
    useEffect(() => {
        if (opportunity?.campus) {
            const campus = opportunity.campus;
            setLocationsOptions([
                {
                    label: campus.name,
                    value: campus.id,
                    badge: undefined,
                    imageUrl: campus.mainImageUrl,
                } as SelectCustomOption,
            ]);
            setSelectedLocationId(campus.id!);
        }
    }, [opportunity]);

    //Base total monthly price and Suggested credits
    useEffect(() => {
        //only first month of multiple pricing is counted for base price
        const addOns = addOnsPriceInputValues.reduce((a, b) => a + b, 0);
        const total: number =
            (multiplePriceInputValues[0] === undefined ? 0 : multiplePriceInputValues[0]) + addOns;
        form.setFieldsValue({
            baseMonthlyPrice: total.toFixed(2),
        });
        setSuggestedCredits(Math.round(total / CREDIT_SUGGESTION_DIVIDER).toFixed(2));
    }, [multiplePriceInputValues, addOnsPriceInputValues, form]);

    //unit search
    useEffect(() => {
        setSelectLoading(true);
        debounceUnitsSearch.current(
            unitsCurrentPage,
            unitsSearchTerm,
            selectedLocationId,
            selectedPlanId,
            startDateValue,
            endDateValue,
            availablePlans,
            oldSubscription
        );
    }, [
        unitsSearchTerm,
        unitsCurrentPage,
        selectedLocationId,
        selectedPlanId,
        startDateValue,
        endDateValue,
        availablePlans,
        mtmCheckboxState,
        oldSubscription,
    ]);

    //unit dropdown options, suggestedPrice and unit market prices
    useEffect(() => {
        const searchResult = unitsSearchResults?.map(
            (unit: Unit) =>
                ({
                    value: unit?.id,
                    label: unit?.name,
                    content: unit?.isAvailable
                        ? t(`Proposal.suggested_unit_price`, {
                              param1: unit.marketPrice?.toFixed(2) ?? 0.0,
                          })
                        : t('Proposal.unit_not_available'),
                    isDisabled: !unit?.isAvailable,
                } as SelectCustomOption)
        );
        const merged = mergeSelectedOptionsWithSearchResults(searchResult, selectedUnitsOptions);

        setUnitsOptions(merged);

        if (selectedUnitIds?.length > 0) {
            const markets = unitsSearchResults?.map(
                (x: Unit) =>
                    ({
                        id: x.id,
                        marketPrice: x.marketPrice?.toFixed(2) ?? 0.0,
                    } as UnitMarketPrice)
            );

            const selectedUnitMarkets: UnitMarketPrice[] = markets?.filter((x: UnitMarketPrice) =>
                selectedUnitIds.includes(x.id)
            );
            if (selectedUnitMarkets?.length > 0) {
                const total = selectedUnitMarkets
                    ?.map((x: UnitMarketPrice) => x.marketPrice)
                    ?.reduce((prev, next) => +prev + +next);
                setSuggestedPrice(total.toString());
            }
        }
    }, [unitsSearchResults, selectedUnitIds, t, selectedUnitsOptions]);

    useEffect(() => {
        if (
            !selectedPlanId ||
            !selectedLocationId ||
            !startDateValue ||
            (!mtmCheckboxState && !endDateValue)
        )
            setUnitDropdownIsDisabled(true);
        else setUnitDropdownIsDisabled(false);
    }, [selectedPlanId, selectedLocationId, startDateValue, mtmCheckboxState, endDateValue]);

    useEffect(() => {
        const endDate = form.getFieldValue('endDate');
        if (endDate) setEndDateValue(endDate);
    }, [mtmCheckboxState, form]);

    useEffect(() => {
        const formStartDate = form.getFieldValue('startDate');

        const startDate =
            formStartDate === null ||
            formStartDate === undefined ||
            !moment(formStartDate).isValid()
                ? moment(undefined)
                : moment(formStartDate);

        if (startDate) {
            if (startDateType === ProposalDateType.Month) {
                const startOfMonth = moment.utc(startDate).startOf('month');
                if (isSubscriptionModification) {
                    if (startOfMonth.isSameOrBefore(moment.utc(oldSubscription?.startDate))) {
                        setStartDateValue(undefined);
                        form.setFieldsValue({ startDate: undefined });
                    }
                } else {
                    setStartDateValue(startOfMonth);
                    form.setFieldsValue({ startDate: startOfMonth });
                }
            } else {
                setStartDateValue(startDate);
                form.setFieldsValue({ startDate: startDate });
            }
        }
    }, [startDateType, form, isSubscriptionModification, oldSubscription]);

    useEffect(() => {
        const endDate = form.getFieldValue('endDate');
        if (endDate) {
            if (endDateType === ProposalDateType.Month) {
                const endOfMonth = moment(endDate).endOf('month');
                setEndDateValue(endOfMonth);
                form.setFieldsValue({ endDate: endOfMonth });
            } else setEndDateValue(endDate);
        }
    }, [endDateType, form]);

    useEffect(() => {
        if (startDateValue && endDateValue) {
            if (startDateValue >= endDateValue) {
                setEndDateValue(undefined);
                form.setFieldsValue({ endDate: undefined });
            }
        }
    }, [startDateValue, endDateValue, form]);

    useEffect(() => {
        if (opportunity?.isSubscriptionRenewal && oldSubscription) {
            handlePlanSelect({ value: oldSubscription.planId });
            setStartDateValue(
                oldSubscription?.monthToMonthAmount ? moment() : moment(oldSubscription?.endDate)
            );
            setStartDateType(ProposalDateType.Date);
        }
    }, [opportunity, oldSubscription, handlePlanSelect]);

    useEffect(() => {
        if (isSubscriptionModification && oldSubscription) {
            handlePlanSelect({ value: oldSubscription.planId });
            setStartDateValue(moment(oldSubscription.startDate));
            setStartDateType(ProposalDateType.Date);
            setEndDateType(
                oldSubscription.endDate
                    ? moment
                          .utc(oldSubscription.endDate)
                          .isSame(moment.utc(oldSubscription.endDate).endOf('month'), 'day')
                        ? ProposalDateType.Month
                        : ProposalDateType.Date
                    : ProposalDateType.Month
            );
            setMtmCheckboxState(!oldSubscription.endDate);
            const cannotConvert =
                moment.utc(oldSubscription.endDate).diff(startDateValue, 'days') <= 30;
            setCannotConvertOnExpiration(cannotConvert);
            setConvertToMtmCheckboxState(
                !cannotConvert && (oldSubscription.becomesMonthToMonthOnExpiration ?? false)
            );
            handleUnitSelect(oldSubscription.units?.map((unit) => ({ value: unit?.id })));
            const periods = oldSubscription.periods;
            const touched: boolean[] = [];
            periods?.forEach((x) => touched.push(false));
            setOldSubscriptionPricingWasTouchedArray(touched);
        }
    }, [
        opportunity,
        oldSubscription,
        handlePlanSelect,
        handleUnitSelect,
        isSubscriptionModification,
    ]);
    //#endregion Effects

    //#region  Render helpers
    const endDateLabel = (): ReactNode => {
        return (
            <>
                {endDateType === ProposalDateType.Month ? t('last_month') : t('end_date')}
                <Checkbox
                    checked={mtmCheckboxState}
                    value={mtmCheckboxState}
                    onChange={(e): void => {
                        onMtmChange(e.target.checked);
                        resetUnitsSelection();
                        resetUnitsSearch();
                    }}
                    className="mtm-checkbox"
                >
                    {t('mtm')}
                </Checkbox>
            </>
        );
    };
    //#endregion  Render helpers

    //#region Render
    return (
        <div className="ProposalFormSection">
            <Title level={4}>{t('basic_information')}</Title>

            <Row gutter={formGutter}>
                <Col span={24}>
                    <ValidatedFormItem name="title" errors={errors} label={t('Lead.title')}>
                        <Input maxLength={200} />
                    </ValidatedFormItem>
                </Col>
            </Row>
            <Row gutter={formGutter}>
                <Col span={12}>
                    <ValidatedFormItem name="membershipId" errors={errors} label={t('company')}>
                        <SelectCustom
                            options={membershipsOptions}
                            defaultImg={<CompanyIcon fill={theme['white']} />}
                            strongLabel={true}
                            selected={selectedMembershipId ? [selectedMembershipId] : null}
                            disabled
                            isLoading={selectLoading}
                            idAttribute="membershipId"
                        />
                    </ValidatedFormItem>
                </Col>
                <Col span={12}>
                    <ValidatedFormItem name="campusId" errors={errors} label={t('location')}>
                        <SelectCustom
                            options={locationsOptions}
                            defaultImg={<LocationIcon fill={theme['white']} />}
                            strongLabel={true}
                            placeholder={t('SelectCustom.placeholder_default')}
                            hideSelectedOptions={false}
                            onChange={(value): void => {
                                setSelectedLocationId(value.value);
                                resetUnitsSelection();
                                resetUnitsSearch();
                            }}
                            selected={selectedLocationId ? [selectedLocationId] : null}
                            disabled
                            isLoading={selectLoading}
                            idAttribute="campusId"
                        />
                    </ValidatedFormItem>
                </Col>
            </Row>

            <Row gutter={formGutter}>
                <Col span={12} className="custom-date-container">
                    <Radio.Group
                        onChange={(e): void => {
                            setStartDateType(e.target.value);
                        }}
                        value={startDateType}
                        className="custom-date-actions"
                    >
                        <Radio.Button value={ProposalDateType.Month}>
                            {ProposalDateType.Month}
                        </Radio.Button>
                        <Radio.Button value={ProposalDateType.Date}>
                            {ProposalDateType.Date}
                        </Radio.Button>
                    </Radio.Group>
                    <ValidatedFormItem
                        name="startDate"
                        errors={errors}
                        label={
                            startDateType === ProposalDateType.Month
                                ? t('start_month')
                                : t('start_date')
                        }
                        required
                    >
                        {startDateType === ProposalDateType.Month ? (
                            <DatePicker
                                picker={'month'}
                                onChange={(value): void => {
                                    setStartDateValue(value?.startOf('month') ?? undefined);
                                    resetUnitsSelection();
                                    resetUnitsSearch();
                                    handlePriceDateChange(value ?? undefined, 0);
                                }}
                                disabledDate={(current): boolean =>
                                    current < moment().startOf('month') ||
                                    (isSubscriptionModification !== undefined &&
                                        isSubscriptionModification &&
                                        current.isSameOrBefore(
                                            moment.utc(oldSubscription?.startDate).endOf('month')
                                        ))
                                }
                                format={DISPLAY_MONTH_YEAR_FORMAT}
                            />
                        ) : (
                            <DatePicker
                                onChange={(value): void => {
                                    setStartDateValue(value ?? undefined);
                                    resetUnitsSelection();
                                    resetUnitsSearch();
                                }}
                                disabledDate={(current): boolean =>
                                    current < moment().startOf('month') ||
                                    (((isSubscriptionModification !== undefined &&
                                        isSubscriptionModification) ||
                                        (isSubscriptionRenewal !== undefined &&
                                            isSubscriptionRenewal)) &&
                                        current.isBefore(
                                            moment.utc(oldSubscription?.startDate).add(1, 'day')
                                        ))
                                }
                            />
                        )}
                    </ValidatedFormItem>
                </Col>

                <Col span={12} className="custom-date-container">
                    <Radio.Group
                        onChange={(e): void => {
                            setEndDateType(e.target.value);
                        }}
                        value={endDateType}
                        className="custom-date-actions end"
                        disabled={mtmCheckboxState}
                    >
                        <Radio.Button value={ProposalDateType.Month}>
                            {ProposalDateType.Month}
                        </Radio.Button>
                        <Radio.Button value={ProposalDateType.Date}>
                            {ProposalDateType.Date}
                        </Radio.Button>
                    </Radio.Group>

                    <ValidatedFormItem
                        name="endDate"
                        errors={errors}
                        label={endDateLabel()}
                        required
                        className={'proposal-dates end-date-label'}
                    >
                        {endDateType === ProposalDateType.Month ? (
                            <DatePicker
                                picker={'month'}
                                disabled={startDateValue === undefined || mtmCheckboxState}
                                onChange={(value): void => {
                                    setEndDateValue(value?.endOf('month') ?? undefined);
                                    resetUnitsSelection();
                                    resetUnitsSearch();
                                    const cannotConvert =
                                        value !== null &&
                                        value?.endOf('month').diff(startDateValue, 'days') <= 30;
                                    setCannotConvertOnExpiration(cannotConvert);
                                    if (cannotConvert) {
                                        handleConvertToMtmOnExpirationChange(false);
                                        setConvertToMtmCheckboxState(false);
                                    }
                                }}
                                disabledDate={(current): boolean =>
                                    current <
                                    ((startDateValue && moment(startDateValue).startOf('month')) ||
                                        moment().startOf('month'))
                                }
                                format={DISPLAY_MONTH_YEAR_FORMAT}
                            />
                        ) : (
                            <DatePicker
                                disabled={startDateValue === undefined || mtmCheckboxState}
                                onChange={(value): void => {
                                    setEndDateValue(value ?? undefined);
                                    resetUnitsSelection();
                                    resetUnitsSearch();
                                    const cannotConvert =
                                        value !== null && value.diff(startDateValue, 'days') <= 30;
                                    setCannotConvertOnExpiration(cannotConvert);
                                    if (cannotConvert) {
                                        handleConvertToMtmOnExpirationChange(false);
                                        setConvertToMtmCheckboxState(false);
                                    }
                                }}
                                disabledDate={(current): boolean =>
                                    current < (startDateValue || moment())
                                }
                            />
                        )}
                    </ValidatedFormItem>
                    {!mtmCheckboxState && (
                        <small className="end-date-tip">
                            {endDateType === ProposalDateType.Month
                                ? t('Proposal.end_month_tip')
                                : t('Proposal.end_date_tip')}
                        </small>
                    )}
                </Col>
            </Row>

            <Row gutter={formGutter}>
                <Col span={12}>
                    <ValidatedFormItem
                        name="referenceNumber"
                        errors={errors}
                        label={t('Proposal.reference_number')}
                    >
                        <Input maxLength={25} />
                    </ValidatedFormItem>
                </Col>

                <Col span={12} className="reminder-checkbox-container">
                    {!mtmCheckboxState && (
                        <>
                            <Tooltip
                                title={
                                    cannotConvertOnExpiration
                                        ? t('Proposal.cannot_convert_tooltip')
                                        : undefined
                                }
                            >
                                <Checkbox
                                    checked={convertToMtmCheckboxState}
                                    onChange={(e): void => {
                                        handleConvertToMtmOnExpirationChange(e.target.checked);
                                        setConvertToMtmCheckboxState(e.target.checked);
                                    }}
                                    disabled={cannotConvertOnExpiration}
                                >
                                    {t('Proposal.convert_mtm_expiration')}
                                </Checkbox>
                            </Tooltip>
                        </>
                    )}
                </Col>
            </Row>

            <Row gutter={formGutter}>
                <Col span={12}>
                    <ValidatedFormItem name="planId" errors={errors} label={t('plan')} required>
                        <SelectCustom
                            options={plansOptions}
                            defaultImg={<PlanIcon fill={theme['white']} />}
                            strongLabel={true}
                            placeholder={t('SelectCustom.placeholder_default')}
                            hideSelectedOptions={false}
                            onChange={handlePlanSelect}
                            selected={selectedPlanId ? [selectedPlanId] : null}
                            isLoading={selectLoading}
                            idAttribute={'planId'}
                        />
                    </ValidatedFormItem>
                </Col>
                <Col span={12}>
                    <ValidatedFormItem name="unitIds" errors={errors} label={t('unit')} required>
                        <SelectCustom
                            options={unitsOptions}
                            strongLabel={true}
                            placeholder={
                                unitDropdownIsDisabled
                                    ? t('Proposal.unit_not_selectable')
                                    : t('SelectCustom.placeholder_default')
                            }
                            onKeywordsChange={handleUnitsKeywordsChange}
                            onMenuScrollToBottom={handleUnitsMenuScrollToBottom}
                            hideSelectedOptions={false}
                            isMulti
                            onChange={handleUnitSelect}
                            selected={selectedUnitIds}
                            disabled={unitDropdownIsDisabled}
                            isLoading={selectLoading}
                            idAttribute="unitIds"
                        />
                    </ValidatedFormItem>
                </Col>
            </Row>

            <Title level={4}>{t('pricing')}</Title>

            {!mtmCheckboxState && (
                <>
                    <Form.List name="periods">
                        {(fields, { add, remove }): ReactNode => {
                            const fieldsNb = fields.length;
                            if (fieldsNb === 0) add();

                            return (
                                <>
                                    {fields.map((field, index, arr) => {
                                        return (
                                            <Pricing
                                                index={index}
                                                key={field.fieldKey}
                                                errors={errors}
                                                remove={remove}
                                                proposalStartDateType={startDateType}
                                                proposalStartDate={startDateValue}
                                                pricingStartDates={pricingStartDateValues}
                                                proposalEndDate={endDateValue}
                                                proposalEndDateType={endDateType}
                                                onPriceChange={(index, value): void =>
                                                    onMultiplePriceChange(index, value)
                                                }
                                                onRemove={(index): void =>
                                                    onMultiplePriceRemove(index)
                                                }
                                                form={form}
                                                suggestedPrice={suggestedPrice}
                                                onDateChange={(
                                                    value: moment.Moment | undefined,
                                                    index: number
                                                ): void => handlePriceDateChange(value, index)}
                                                oldSubscription={oldSubscription}
                                                onPricingTouched={(index): void =>
                                                    handlePricingTouched(index)
                                                }
                                                ignoreOldSubValue={
                                                    oldSubscriptionPricingWasTouchedArray[index]
                                                }
                                                isLastItem={arr.length === index + 1}
                                            />
                                        );
                                    })}

                                    {!mtmCheckboxState && (
                                        <Row gutter={formGutter}>
                                            <Col
                                                span={24}
                                                className="dynamic-list-button-container"
                                            >
                                                <Button
                                                    className={
                                                        'ant-btn-primary ant-btn-circle ant-btn-lg ant-btn-icon-only'
                                                    }
                                                    onClick={(): void => add()}
                                                    disabled={
                                                        startDateValue === undefined ||
                                                        endDateValue === undefined
                                                    }
                                                >
                                                    <Icon iconName="Add" />
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            );
                        }}
                    </Form.List>
                    <Divider />
                </>
            )}

            {/* Month-to-month pricing */}
            {(mtmCheckboxState || (!mtmCheckboxState && convertToMtmCheckboxState)) && (
                <Pricing
                    index={-1}
                    isMtmPricing={mtmCheckboxState && !convertToMtmCheckboxState}
                    isConvertMtmPricing={!mtmCheckboxState && convertToMtmCheckboxState}
                    remove={(): void => {
                        /*no remove*/
                    }}
                    proposalStartDateType={startDateType}
                    proposalEndDateType={endDateType}
                    proposalStartDate={startDateValue}
                    pricingStartDates={pricingStartDateValues}
                    proposalEndDate={endDateValue}
                    onPriceChange={(index, value): void => onMultiplePriceChange(index, value)}
                    onRemove={(index): void => onMultiplePriceRemove(index)}
                    form={form}
                    suggestedPrice={suggestedPrice}
                    onDateChange={(value: moment.Moment | undefined, index: number): void =>
                        handlePriceDateChange(value, index)
                    }
                    onPricingTouched={(): void => {
                        /*nothing to do here*/
                    }}
                />
            )}

            <div className="add-on-title">{t('add_ons')}</div>
            {availableAddOns.length > 0 && (
                <Form.List name="addOns">
                    {(fields, { add, remove }): ReactNode => (
                        <>
                            {fields.map((field, index) => (
                                <AddOn
                                    index={index}
                                    key={field.fieldKey}
                                    errors={errors}
                                    remove={remove}
                                    onPriceChange={onAddOnPriceChange}
                                    onRemove={onAddOnRemove}
                                    availableAddOns={availableAddOns}
                                    preSelectedValue={
                                        oldSubscription?.addOns
                                            ? oldSubscription.addOns[index]?.addOnId!
                                            : undefined
                                    }
                                />
                            ))}

                            <Row gutter={formGutter}>
                                <Col span={24} className="dynamic-list-button-container">
                                    <Button
                                        className={
                                            'ant-btn-primary ant-btn-circle ant-btn-lg ant-btn-icon-only'
                                        }
                                        onClick={(): void => add()}
                                    >
                                        <Icon iconName="Add" />
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form.List>
            )}
            {availableAddOns.length === 0 && (
                <div className="noAddOns">{t('Proposal.no_add_ons_available')}</div>
            )}

            <Divider />

            <Row gutter={formGutter}>
                <Col span={12}>
                    <ValidatedFormItem
                        name="baseMonthlyPrice"
                        errors={errors}
                        label={t('Proposal.base_monthly_price')}
                    >
                        <Input addonBefore={'$'} addonAfter={t('monthly')} disabled />
                    </ValidatedFormItem>
                </Col>
            </Row>

            <Title level={4}>{t('security_deposit')}</Title>
            <Row gutter={formGutter}>
                <Col span={12}>
                    <ValidatedFormItem
                        name="securityDeposit"
                        errors={errors}
                        label={t('security_deposit')}
                        required
                    >
                        <NumberFormat
                            allowNegative={false}
                            decimalScale={2}
                            fixedDecimalScale
                            customInput={(props: any) => (
                                <Input addonBefore={'$'} step={0.01} {...props} />
                            )}
                            isAllowed={(value: NumberFormatValues): boolean =>
                                value.floatValue === undefined ||
                                (value.floatValue !== undefined && value.floatValue <= MILLION)
                            }
                        />
                    </ValidatedFormItem>
                </Col>
            </Row>

            <Title level={4}>{t('credits')}</Title>
            <div className="credits-flex-container">
                <div className="credits-container">
                    <Row gutter={formGutter}>
                        <Col span={12} className="custom-price-input-container">
                            <Input
                                disabled
                                className="custom-price-suggested"
                                addonBefore={t('suggested')}
                                value={suggestedCredits}
                            />
                            <ValidatedFormItem
                                name="creditPointsPerInterval"
                                errors={errors}
                                label={t('Proposal.credits_per_interval')}
                                className="custom-price-actual"
                                required
                                rules={[{ required: true, message: t('Proposal.price_required') }]}
                            >
                                <NumberInput
                                    min={0}
                                    addonBefore={t('actual')}
                                    addonAfter={t('monthly')}
                                    type={'number'}
                                    step={0.01}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                </div>
            </div>

            <Title level={4}>{t('discounts')}</Title>
            <Row gutter={formGutter}>
                <Col span={12}>
                    <small>{t('Proposal.discount_tip')}</small>
                </Col>
            </Row>
            <Form.List name="discounts">
                {(fields, { add, remove }): ReactNode => (
                    <>
                        {fields.map((field, index) => (
                            <Discount
                                index={index}
                                key={field.fieldKey}
                                errors={errors}
                                remove={remove}
                                proposalStart={startDateValue}
                                proposalEnd={endDateValue}
                                form={form}
                            />
                        ))}
                        <Row gutter={formGutter}>
                            <Col span={24} className="dynamic-list-button-container">
                                <Button
                                    className={
                                        'ant-btn-primary ant-btn-circle ant-btn-lg ant-btn-icon-only'
                                    }
                                    onClick={(): void => add()}
                                >
                                    <Icon iconName="Add" />
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
            </Form.List>

            <Title level={4}>{t('payment_methods')}</Title>
            <Row gutter={formGutter}>
                <Col span={12}>
                    <ValidatedFormItem name="paymentModalities" errors={errors}>
                        <Radio.Group
                            onChange={(value): void => setPaymentModalities(value.target.value)}
                            value={paymentModalities}
                            defaultValue={PaymentModalities.PreAuthorized}
                        >
                            <Radio className="payment-modalities" value={PaymentModalities.Check}>
                                {t('Proposal.payment_check')}
                            </Radio>
                            <Radio
                                className="payment-modalities"
                                value={PaymentModalities.PreAuthorized}
                            >
                                {t('Proposal.payment_pre_authorized')}
                            </Radio>
                            <Radio className="payment-modalities" value={PaymentModalities.Manual}>
                                {t('Proposal.payment_manual')}
                            </Radio>
                        </Radio.Group>
                    </ValidatedFormItem>
                </Col>
                <Divider />
                <small className="agreement-tip">{t('Proposal.will_be_sent_by_email')}</small>
            </Row>
        </div>
    );

    //#endregion
};

export default React.memo(ProposalFormSection);
