import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Check: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    d="M19.5135 18.7123H0.465833C0.2085 18.7123 0 18.5038 0 18.2465V6.82667C0 6.56934 0.2085 6.36084 0.465833 6.36084H17.6757C17.933 6.36084 18.1415 6.56934 18.1415 6.82667C18.1415 7.08384 17.933 7.29234 17.6757 7.29234H0.931667V17.7807H19.0477V14.0495C19.0477 13.7923 19.2562 13.5838 19.5135 13.5838C19.7708 13.5838 19.9793 13.7923 19.9793 14.0495V18.2465C19.9793 18.5038 19.7708 18.7123 19.5135 18.7123Z"
                    fill={fill}
                />
                <path
                    d="M13.092 12.4742H9.98947C9.73213 12.4742 9.52363 12.2657 9.52363 12.0084V9.74704C9.52363 9.48987 9.73213 9.28121 9.98947 9.28121H15.1961C15.4535 9.28121 15.662 9.48987 15.662 9.74704C15.662 10.0044 15.4535 10.2129 15.1961 10.2129H10.4553V11.5425H13.092C13.3493 11.5425 13.5578 11.7512 13.5578 12.0084C13.5578 12.2657 13.3493 12.4742 13.092 12.4742Z"
                    fill={fill}
                />
                <path
                    d="M8.1804 10.2129H3.29423C3.0369 10.2129 2.8284 10.0044 2.8284 9.74705C2.8284 9.48988 3.0369 9.28121 3.29423 9.28121H8.1804C8.43773 9.28121 8.64623 9.48988 8.64623 9.74705C8.64623 10.0044 8.43773 10.2129 8.1804 10.2129Z"
                    fill={fill}
                />
                <path
                    d="M8.1804 12.4741H3.29423C3.0369 12.4741 2.8284 12.2656 2.8284 12.0083C2.8284 11.7511 3.0369 11.5425 3.29423 11.5425H8.1804C8.43773 11.5425 8.64623 11.7511 8.64623 12.0083C8.64623 12.2656 8.43773 12.4741 8.1804 12.4741Z"
                    fill={fill}
                />
                <path
                    d="M8.1804 16.0098H3.29423C3.0369 16.0098 2.8284 15.8013 2.8284 15.544C2.8284 15.2868 3.0369 15.0782 3.29423 15.0782H8.1804C8.43773 15.0782 8.64623 15.2868 8.64623 15.544C8.64623 15.8013 8.43773 16.0098 8.1804 16.0098Z"
                    fill={fill}
                />
                <path
                    d="M11.9677 16.0098H9.71751C9.46018 16.0098 9.25168 15.8013 9.25168 15.544C9.25168 15.2868 9.46018 15.0782 9.71751 15.0782H11.9677C12.225 15.0782 12.4335 15.2868 12.4335 15.544C12.4335 15.8013 12.225 16.0098 11.9677 16.0098Z"
                    fill={fill}
                />
                <path
                    d="M22.2803 8.17299L20.8986 6.79132L21.567 6.12132L22.9503 7.50465L22.2803 8.17299ZM16.7178 13.7246L15.3445 12.3529L20.2378 7.45122L21.6195 8.83289L16.7178 13.7246ZM14.3734 14.698L14.8834 13.2097L15.8599 14.1863L14.3734 14.698ZM23.6701 6.90629L22.1651 5.40129C21.9984 5.23463 21.7617 5.14963 21.5134 5.16963C21.2934 5.18796 21.0817 5.28796 20.9201 5.45129L14.2766 12.1046L12.8734 16.198L16.9717 14.7896L23.6201 8.15129C23.9767 7.79463 24.0001 7.23629 23.6701 6.90629Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Check;
