// GENERATED FILE - DO NOT MODIFY
import { AcceptedSubscriptionsReportDto } from 'Api/Features/Reports/Dtos/AcceptedSubscriptions/AcceptedSubscriptionsReportDto';
import { GetAcceptedSubscriptionsReportRequestDto } from 'Api/Features/Reports/Dtos/AcceptedSubscriptions/GetAcceptedSubscriptionsReportRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class AcceptedSubscriptionsReportProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getAcceptedSubscriptionsReport(request: GetAcceptedSubscriptionsReportRequestDto | null): Promise<AcceptedSubscriptionsReportDto | null> {
        const uri = this.buildUri(
            "/accepted-subscriptions-report",
            null,
            { ...request || undefined }
        );

        const data: AcceptedSubscriptionsReportDto | null = await this.httpClient.get<AcceptedSubscriptionsReportDto | null>(uri);
        return data;
    }

    public async exportAcceptedSubscriptionsReport(request: GetAcceptedSubscriptionsReportRequestDto | null): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/accepted-subscriptions-report/export",
            null,
            { ...request || undefined }
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }
}