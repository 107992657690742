import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Subscription: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.8491 0V2.50489L2.66699 6.75489L4.91788 9.00567L4.59921 11.1837L8.01655 16L11.4789 11.1847L11.1619 9.01789L11.1651 9.02145L13.4317 6.75456L10.2081 2.44956V0H5.8491ZM6.47177 2.28322H9.58533V0.622667H6.47177V2.28322ZM5.25155 11.0267L6.43977 2.906H9.63788L10.8262 11.0258L8.01999 14.9286L5.25155 11.0267ZM10.3878 3.72822L12.6099 6.69611L11.0502 8.25544L10.3878 3.72822ZM3.48899 6.69611L5.6851 3.76267L5.03044 8.23744L3.48899 6.69611Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Subscription;
