import { Button, Col, Form, Input, Row, Select } from 'antd';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { LeadLabelDto } from 'Api/Features/Leads/Dtos/LeadLabelDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Lead as LeadIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { toUpdateLeadRequestDto } from 'Models/Leads/DtoOutput';
import { Lead } from 'Models/Leads/Lead';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeLeadLabelSchema } from 'Schemas';
import { LeadService } from 'Services/LeadService';
import './change-label.less';

interface ChangeLabelModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    lead?: Lead;
}

const { Option } = Select;

const formGutter: [Gutter, Gutter] = [40, 0];

const ChangeLabelModal: FunctionComponent<ChangeLabelModalProps> = ({
    visible,
    onComplete,
    lead,
}) => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(ChangeLeadLabelSchema, form);
    const leadService = useService(LeadService);

    useEffect(() => {
        if (lead)
            form.setFieldsValue({
                label: lead?.label,
            });
    }, [lead, form]);

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <LeadIcon />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t('Lead.lead_confirm_positive_change_label'),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (values: Store): Promise<void> => {
        lead!.label = values.label || undefined;

        const data = toUpdateLeadRequestDto(lead!);
        data.note = values.note;

        try {
            globalLoadingStore.addLoading();

            if (!(await validateForm(data))) return;

            if (lead !== undefined) await leadService.updateLead(lead.id!, data);

            toastStore.toast({
                type: 'success',
                messageKey: `Lead.lead_label_changed_success`,
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion
    return (
        <BaseModal
            visible={visible}
            title={t('Lead.lead_change_label')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="ChangeLabel">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem name="label" errors={errors}>
                                <Select>
                                    {Object.keys(LeadLabelDto).map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`Lead.label_${x}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name="note"
                                errors={errors}
                                label={t('note')}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
};

export default ChangeLabelModal;
