// GENERATED FILE - DO NOT MODIFY
import { GetOpportunityEventsRequestDto } from 'Api/Features/OpportunityEvents/Dtos/GetOpportunityEventsRequestDto';
import { GetOpportunityEventsResponseDto } from 'Api/Features/OpportunityEvents/Dtos/GetOpportunityEventsResponseDto';
import { OpportunityEventDto } from 'Api/Features/OpportunityEvents/Dtos/OpportunityEventDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class OpportunityEventProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getOpportunityEvent(opportunityEventId: string): Promise<OpportunityEventDto | null> {
        const uri = this.buildUri(
            "/opportunity-events/{opportunityEventId}",
            { opportunityEventId: opportunityEventId },
            null
        );

        const data: OpportunityEventDto | null = await this.httpClient.get<OpportunityEventDto | null>(uri);
        return data;
    }

    public async getOpportunityEvents(opportunityId: string, request: GetOpportunityEventsRequestDto | null): Promise<GetOpportunityEventsResponseDto | null> {
        const uri = this.buildUri(
            "/opportunities/{opportunityId}/events",
            { opportunityId: opportunityId },
            { ...request || undefined }
        );

        const data: GetOpportunityEventsResponseDto | null = await this.httpClient.get<GetOpportunityEventsResponseDto | null>(uri);
        return data;
    }
}