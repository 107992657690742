import { CreatePlanRequestDto } from 'Api/Features/Plans/Dtos/CreatePlanRequestDto';
import { GetCampusPlansRequestDto } from 'Api/Features/Plans/Dtos/GetCampusPlansRequestDto';
import { GetPlanOrderResponseDto } from 'Api/Features/Plans/Dtos/GetPlanOrderResponseDto';
import { GetPlansRequestDto } from 'Api/Features/Plans/Dtos/GetPlansRequestDto';
import { GetPlansResponseDto } from 'Api/Features/Plans/Dtos/GetPlansResponseDto';
import { PlanDto } from 'Api/Features/Plans/Dtos/PlanDto';
import { UpdatePlanOrderRequestDto } from 'Api/Features/Plans/Dtos/UpdatePlanOrderRequestDto';
import { UpdatePlanRequestDto } from 'Api/Features/Plans/Dtos/UpdatePlanRequestDto';
import { PlanProxy } from 'Api/Features/Plans/PlanProxy';
import { inject } from 'aurelia-dependency-injection';
import { Plan } from 'Models/Plans/Plan';
import { ApiService } from './ApiService';

@inject(PlanProxy)
export class PlanService extends ApiService {
    constructor(private readonly planProxy: PlanProxy) {
        super();
    }

    public async getPlan(planId: string | null): Promise<PlanDto | null> {
        const data: PlanDto | null = await this.planProxy.getPlan(planId);
        return data;
    }

    public async getCampusPlans(
        campusId: string | null,
        request: GetCampusPlansRequestDto | null
    ): Promise<(PlanDto | null)[] | null> {
        const data: (PlanDto | null)[] | null = await this.planProxy.getCampusPlans(
            campusId,
            request
        );
        return data;
    }

    public async getPlans(request: GetPlansRequestDto | null): Promise<[Plan[], number]> {
        const data: GetPlansResponseDto | null = await this.planProxy.getPlans(request);

        return [data?.items?.filter((x): x is Plan => x !== null) || [], data?.totalItemCount || 0];
    }

    public async getCampusPlanOrdering(
        campusId: string | null
    ): Promise<GetPlanOrderResponseDto | null> {
        const data: GetPlanOrderResponseDto | null = await this.planProxy.getCampusPlanOrdering(
            campusId
        );
        return data;
    }

    public async updateCampusPlansOrdering(
        campusId: string | null,
        request: UpdatePlanOrderRequestDto | null
    ): Promise<void> {
        await this.planProxy.updateCampusPlansOrdering(campusId, request);
    }

    public async createPlan(request: CreatePlanRequestDto | null): Promise<string> {
        const data: string = await this.planProxy.createPlan(request);
        return data;
    }

    public async updatePlan(
        planId: string | null,
        request: UpdatePlanRequestDto | null
    ): Promise<void> {
        await this.planProxy.updatePlan(planId, request);
    }

    public async deletePlan(planId: string | null): Promise<void> {
        await this.planProxy.deletePlan(planId);
    }
}
