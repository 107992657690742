import { Table } from 'antd';
import { ColumnType, TablePaginationConfig } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import { GetBookingHistoryRequestDto } from 'Api/Features/Bookings/Dtos/GetBookingHistoryRequestDto';
import { GetBookingsSortColumnDto } from 'Api/Features/Bookings/Dtos/GetBookingsSortColumnDto';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import ContactsHeader from 'Components/contacts-header';
import Icon from 'Components/icons/icon';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { TableFilters } from 'Components/table-filters';
import { TdWithImage } from 'Components/td-with-image';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import {
    DATETIME_FORMAT,
    DISPLAY_TIME_12H_FORMAT,
    MOMENT_PARSING_FORMAT,
    TABLE_DEBOUNCE_DELAY,
} from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import moment from 'moment';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { BookingService } from 'Services/BookingService';
import { FilterStore } from 'Stores';
import { cleanVal } from 'Utils/NumberUtils';
import { ContactContext } from '..';
import './index.less';

const advancedFilters: AdvancedFilter[] = [];

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 20,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

interface ImageAndName {
    name: string;
    imageUrl?: string | null;
}

const imageCellRender = (item: ImageAndName): ReactNode => {
    return (
        <TdWithImage defaultImg={<Icon iconName="User" />} imgSrc={item?.imageUrl}>
            {item?.name}
        </TdWithImage>
    );
};

const renderAttendees = (attendeesNb: number, t: any): string => {
    const attendeeWord =
        attendeesNb > 1
            ? t('Contact.contacts_booking_history_attendees')
            : t('Contact.contacts_booking_history_attendee');

    return `${attendeesNb} ${attendeeWord}`;
};

interface Bookings {
    id: string;
    periodStart: moment.Moment;
    periodEnd: moment.Moment;
    title: string;
    creator: ReactNode;
    conferenceRoom: ReactNode;
    attendees: string;
}

const ContactBookingHistory: FunctionComponent = () => {
    const bookingService = useService(BookingService);
    const [bookings, setBookings] = useState<Bookings[]>([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const paginationRef = useRef(initialPaginationState);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { toastStore } = useStores();
    const contactContext = useContext(ContactContext);
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const history = useHistory();

    const headerDetails = {
        iconName: 'Bookmark',
        title: t('Contact.contacts_booking_history'),
        subTitle: t('Contact.contacts_booking_history_subtitle'),
        breadCrumbMemberName: `${cleanVal.string(contactContext?.firstName)}  ${cleanVal.string(
            contactContext?.lastName
        )}`,
    };

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `contacts`,
            nameKey: 'User.contacts_label',
        },
        {
            path: `${id}/dashboard`,
            nameKey: headerDetails?.breadCrumbMemberName,
        },
        {
            path: `booking-history`,
            nameKey: 'Contact.contacts_booking_history',
        },
    ];

    const columns: ColumnType<Bookings>[] = [
        {
            title: t('Contact.contacts_booking_history_booking_date'),
            render: (booking: Bookings) => booking.periodStart?.format(MOMENT_PARSING_FORMAT),
            key: GetBookingsSortColumnDto.PeriodStart,
            sorter: true,
        },
        {
            title: t('start'),
            render: (booking: Bookings) => booking.periodStart?.format(DISPLAY_TIME_12H_FORMAT),
        },
        {
            title: t('end'),
            render: (booking: Bookings) => booking.periodEnd?.format(DISPLAY_TIME_12H_FORMAT),
        },
        {
            title: t('Booking.book_a_room_title'),
            dataIndex: 'title',
            key: GetBookingsSortColumnDto.Title,
            sorter: true,
        },
        {
            title: t('Booking.book_a_room_creator'),
            dataIndex: 'creator',
            key: GetBookingsSortColumnDto.OrganizerName,
            sorter: true,
        },
        {
            title: t('Booking.conference_room'),
            dataIndex: 'conferenceRoom',
            key: GetBookingsSortColumnDto.AmenityName,
            sorter: true,
        },
        {
            title: t('Contact.contacts_booking_history_nb_attendees'),
            dataIndex: 'attendees',
            key: GetBookingsSortColumnDto.InvitedAccountCount,
            sorter: true,
        },
    ];

    const fetchBookings = useCallback(
        async (params: {
            searchTerm?: string;
            startDate: string;
            endDate: string;
            pagination: TablePaginationConfig;
            sortColumn: GetBookingsSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);

            try {
                const request: GetBookingHistoryRequestDto = {
                    searchTerm: params.searchTerm,
                    minDate: params.startDate,
                    maxDate: params.endDate,
                    pageSize: params.pagination.pageSize || 10,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                };

                const [items, totalItemCount] = await bookingService.getMemberBookings(id, request);

                const responseMapping: Bookings[] = items.map((booking) => ({
                    id: cleanVal.string(booking.id),
                    periodStart: moment(booking.periodStart, DATETIME_FORMAT),
                    periodEnd: moment(booking.periodEnd, DATETIME_FORMAT),
                    title: cleanVal.string(booking.title),
                    creator: imageCellRender({
                        imageUrl: booking.organizer?.imageUrl,
                        name:
                            cleanVal.string(booking.organizer?.firstName) +
                            ' ' +
                            cleanVal.string(booking.organizer?.lastName),
                    }),
                    conferenceRoom: imageCellRender({
                        imageUrl: booking.amenity?.imageUrl,
                        name: cleanVal.string(booking.amenity?.name),
                    }),
                    attendees: renderAttendees(cleanVal.number(booking.invitedAccountCount), t),
                }));

                setBookings(responseMapping);
                setPagination({
                    ...params.pagination,
                    total: totalItemCount,
                });
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [setLoading, bookingService, id, t]
    );

    const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any): void => {
        const { searchTerm, startDate, endDate } = filterStoreRef.current;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        fetchBookings({
            searchTerm: searchTerm,
            startDate: startDate,
            endDate: endDate,
            pagination: pagination,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
    };

    const debounceSearch = useRef(
        debounce((params: { searchTerm?: string; startDate: string; endDate: string }) => {
            fetchBookings({
                searchTerm: params.searchTerm,
                startDate: params.startDate,
                endDate: params.endDate,
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                sortColumn: null,
                sortDirection: null,
            });
        }, TABLE_DEBOUNCE_DELAY)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;

            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                startDate: filterStoreRef.current.startDate,
                endDate: filterStoreRef.current.endDate,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    return (
        <div className="ContactBookingHistory">
            <ContactsHeader routes={breadcrumbs} headerData={headerDetails} loading={loading} />

            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeDates
                    datesPrefix={{
                        start: t('Contact.contacts_booking_history_from'),
                        end: t('Contact.contacts_booking_history_until'),
                    }}
                    includeSearch
                />

                <Table
                    className="table-striped-rows"
                    bordered
                    columns={columns}
                    rowKey={(row: Bookings): string => row.id}
                    dataSource={bookings}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(row: Bookings) => ({
                        onClick: (): void => {
                            history.push(
                                `/contacts/${contactContext?.id}/dashboard/booking-history/${row.id}`
                            );
                        },
                    })}
                />
            </Content>
        </div>
    );
};

export default ContactBookingHistory;
