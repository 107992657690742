import { Button, Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { LeadSourceDto } from 'Api/Features/Leads/Dtos/LeadSourceDto';
import { OpportunityProductDto } from 'Api/Features/Opportunities/Dtos/OpportunityProductDto';
import { OpportunitySalesChannelDto } from 'Api/Features/Opportunities/Dtos/OpportunitySalesChannelDto';
import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';
import { OpportunityTermLengthDto } from 'Api/Features/Opportunities/Dtos/OpportunityTermLengthDto';
import { UpdateOpportunityRequestDto } from 'Api/Features/Opportunities/Dtos/UpdateOpportunityRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Plan, User } from 'Components/icons';
import { SelectCustom } from 'Components/select-custom';
import {
    SelectCustomGroupOption,
    SelectCustomOption,
} from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { BILLION } from 'Models/Constants';
import { LeadSource } from 'Models/Leads/LeadSource';
import { LightCampusDetailsInfo } from 'Models/Location/LightCampusInfo';
import { ManagerUser } from 'Models/ManagerUsers/ManagerUser';
import { Member } from 'Models/Members/Member';
import { Opportunity } from 'Models/Opportunities/Opportunity';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { getSchema } from 'Schemas/CreateOpportunitySchema';
import { ManagerUserService } from 'Services/ManagerUserService';
import { MemberService } from 'Services/MemberService';
import { OpportunityService } from 'Services/OpportunityService';
import { theme } from 'variant';
import './edit-opportunity.less';

const formGutter: [Gutter, Gutter] = [40, 0];
const { Option } = Select;
const { Title } = Typography;

interface OpportunityModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    opportunity?: Opportunity;
}

const EditOpportunityModal: FunctionComponent<OpportunityModalProps> = ({
    visible,
    onComplete,
    opportunity,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(
        getSchema(
            opportunity?.status === OpportunityStatusDto.New ||
                opportunity?.status === OpportunityStatusDto.TourScheduled
        ),
        form
    );
    const { globalLoadingStore, toastStore, confirmationModalStore, locationStore } = useStores();
    const opportunityService = useService(OpportunityService);
    const memberService = useService(MemberService);
    const pageSize = 25;
    const [membersCurrentPage, setMembersCurrentPage] = useState(0);
    const [membersSearchResults, setMembersSearchResults] = useState<Member[]>([]);
    const [membersSearchTerm, setMembersSearchTerm] = useState('');
    const [membersMaxResults, setMembersMaxResults] = useState(false);
    const [membersOptions, setMembersOptions] = useState<SelectCustomGroupOption[]>([]);
    const [selectedContactOptions, setSelectedContactOptions] = useState<SelectCustomOption[]>([]);
    const [selectedContactId, setSelectedContactId] = useState<string[] | undefined>([]);

    const [managersCurrentPage, setManagersCurrentPage] = useState(0);
    const [managersSearchResults, setManagersSearchResults] = useState<ManagerUser[]>([]);
    const [managersSearchTerm, setManagersSearchTerm] = useState('');
    const [managersMaxResults, setManagersMaxResults] = useState(false);
    const [managersOptions, setManagersOptions] = useState<SelectCustomOption[]>([]);
    const [selectedOwnerOptions, setSelectedOwnerOptions] = useState<SelectCustomOption[]>([]);
    const [selectedOwnerId, setSelectedOwnerId] = useState<string[] | undefined>([]);
    const managerUserService = useService(ManagerUserService);
    const [selectLoading, setSelectLoading] = useState(false);
    const [leadSourceVal, setLeadSourceVal] = useState<LeadSourceDto | undefined>(
        opportunity?.source
    );
    //#endregion
    const dismiss = (success = false, id?: string): void => {
        onComplete(success, id);
        form.resetFields();
        resetErrors();
    };

    const resetMembersSearch = (): void => {
        setMembersCurrentPage(0);
        setMembersSearchResults([]);
        setMembersSearchTerm('');
        setMembersMaxResults(false);
    };

    const resetManagersSearch = (): void => {
        setManagersCurrentPage(0);
        setManagersSearchResults([]);
        setManagersSearchTerm('');
        setManagersMaxResults(false);
    };

    //#region Event handlers
    const handleMembersKeywordsChange = useCallback((value: string): void => {
        resetMembersSearch();
        setMembersSearchTerm(value);
    }, []);

    const handleMembersMenuScrollToBottom = (): void => {
        if (!membersMaxResults) {
            setMembersCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const searchMembers = async (page: number, searchTerm: string): Promise<Member[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
            sortFirstMembershipId: opportunity?.membership?.id!,
        };

        const results = await memberService.getMembers(args);

        if (results.length < pageSize) {
            setMembersMaxResults(true);
        }
        setSelectLoading(false);
        return results[0];
    };

    const debounceMembersSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchMembers(page, searchTerm).then((results) => {
                setMembersSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    const handleManagerKeywordsChange = useCallback((value: string): void => {
        resetManagersSearch();
        setManagersSearchTerm(value);
    }, []);

    const searchManagerUsers = async (page: number, searchTerm: string): Promise<ManagerUser[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
        };
        const [results, totalItemCount] = await managerUserService.getManagerUsers(args);

        if (results.length + pageSize * page >= totalItemCount) {
            setManagersMaxResults(true);
        }
        setSelectLoading(false);
        return results;
    };

    const debounceManagerSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchManagerUsers(page, searchTerm).then((results) => {
                setManagersSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    const handleManagersMenuScrollToBottom = (): void => {
        if (!managersMaxResults) {
            setManagersCurrentPage((prevPage) => prevPage + 1);
        }
    };
    //#endregion

    //#region Effects
    useEffect(() => {
        const searchResults = membersSearchResults?.map(
            (member: Member) =>
                ({
                    value: member?.id,
                    label: member?.name,
                    imageUrl: member?.imageUrl,
                    badge: undefined,
                    content: member.contactInfo?.email,
                    isInSortFirstMembership: member.isInSortFirstMembership,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedContactOptions);

        const groupedMembersSearchResultOptions = [
            {
                label: t('company_members'),
                options: merged?.filter((member) => {
                    return member.isInSortFirstMembership;
                }),
            } as SelectCustomGroupOption,
            {
                label: t('other_contacts'),
                options: merged?.filter((member) => {
                    return !member.isInSortFirstMembership;
                }),
            } as SelectCustomGroupOption,
        ];

        setMembersOptions(groupedMembersSearchResultOptions);
    }, [membersSearchResults, t, opportunity, selectedContactOptions]);

    useEffect(() => {
        setSelectLoading(true);
        debounceMembersSearch.current(membersCurrentPage, membersSearchTerm);
    }, [membersCurrentPage, membersSearchTerm, opportunity]);

    useEffect(() => {
        const searchResults = managersSearchResults?.map(
            (manager: ManagerUser) =>
                ({
                    value: manager?.id,
                    label: manager?.name,
                    imageUrl: manager?.imageUrl,
                    badge: undefined,
                    content: manager.contactInfo?.email,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedOwnerOptions);

        setManagersOptions(merged);
    }, [managersSearchResults, t, opportunity, selectedOwnerOptions]);

    useEffect(() => {
        setSelectLoading(true);
        debounceManagerSearch.current(managersCurrentPage, managersSearchTerm);
    }, [managersCurrentPage, managersSearchTerm]);

    useEffect(() => {
        if (opportunity) {
            form.setFieldsValue({
                description: opportunity.description,
                name: opportunity.name,
                probability: opportunity.probability || undefined,
                campusId: opportunity.campus?.id,
                deskCount: opportunity.deskCount,
                product: opportunity.product,
                salesChannel: opportunity.salesChannel,
                termLength: opportunity.termLength,
                monthlyRevenue: opportunity.monthlyRevenue,
                officeNumber: opportunity.officeNumber,
                source: opportunity.source,
                sourceOther: opportunity?.sourceOther ?? undefined,
                companyConfirm: opportunity.membership?.name,
                closeDate: opportunity?.closeDate ? moment(opportunity?.closeDate) : null,
            });

            setSelectedContactId([opportunity.contact?.id!]);
            setSelectedOwnerId([opportunity.ownerAccount?.id!]);
            setSelectedOwnerOptions([
                {
                    value: opportunity.ownerAccount?.id,
                    label: `${opportunity.ownerAccount?.firstName} ${opportunity.ownerAccount?.lastName}`,
                    imageUrl: opportunity.ownerAccount?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption,
            ]);
        }
    }, [opportunity, form]);
    //#endregion

    //#region Submit / Exit
    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Plan />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText:
                    opportunity !== undefined
                        ? t('Opportunity.opportunity_confirm_positive_edit')
                        : t(`Opportunity.opportunity_confirm_positive`),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const data = {
            probability: formValues.probability === '' ? undefined : formValues.probability,
            campusId: formValues.campusId === null ? undefined : formValues.campusId,
            closeDate: formValues.closeDate ?? undefined,
            description: formValues.description,
            deskCount: formValues.deskCount,
            officeNumber: formValues.officeNumber,
            monthlyRevenue:
                formValues.monthlyRevenue === null ? undefined : formValues.monthlyRevenue,
            name: formValues.name,
            ownerAccountId: formValues.ownerAccountId?.value,
            product: formValues.product ?? undefined,
            salesChannel: formValues.salesChannel ?? undefined,
            source: formValues.source,
            sourceOther: formValues.sourceOther ?? undefined,
            termLength: formValues.termLength === null ? undefined : formValues.termLength,
            contactId: formValues.contactId?.value ?? opportunity?.contact?.id,
        } as UpdateOpportunityRequestDto;

        if (!(await validateForm(data))) return;

        try {
            globalLoadingStore.addLoading();

            await opportunityService.updateOpportunity(opportunity?.id!, data);

            toastStore.toast({
                type: 'success',
                messageKey:
                    opportunity !== undefined
                        ? 'Opportunity.opportunity_edit_success'
                        : `Opportunity.opportunity_create_success`,
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    useEffect(() => {
        if (opportunity?.ownerAccount)
            form.setFieldsValue({
                ownerAccountId: { value: opportunity.ownerAccount.id },
            });
    }, [opportunity, form]);

    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={
                opportunity !== undefined
                    ? t('Opportunity.opportunity_edit')
                    : t('Opportunity.opportunity_create')
            }
            className="FormModal"
            onCancel={exit}
        >
            <div className="EditOpportunity">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Title level={4}>{t('Opportunity.opportunity_information')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="name"
                                errors={errors}
                                label={t('Opportunity.opportunity_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="ownerAccountId"
                                errors={errors}
                                label={t('Opportunity.opportunity_owner')}
                                required
                            >
                                <SelectCustom
                                    options={managersOptions}
                                    defaultImg={<User fill={theme['white']} />}
                                    strongLabel={true}
                                    placeholder={t('SelectCustom.placeholder_default')}
                                    onKeywordsChange={handleManagerKeywordsChange}
                                    onMenuScrollToBottom={handleManagersMenuScrollToBottom}
                                    onChange={(value: any): void => {
                                        setSelectedOwnerId([value.value]);
                                        const options = [value as SelectCustomOption];
                                        setSelectedOwnerOptions(options);
                                    }}
                                    hideSelectedOptions={false}
                                    selected={selectedOwnerId}
                                    isLoading={selectLoading}
                                    idAttribute="ownerAccountId"
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="companyConfirm"
                                errors={errors}
                                label={t('company')}
                            >
                                <Input disabled />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="contactId"
                                errors={errors}
                                label={t('FormStep.step_Contact')}
                                required
                            >
                                <SelectCustom
                                    groupedOptions={membersOptions}
                                    defaultImg={<User fill={theme['white']} />}
                                    strongLabel={true}
                                    placeholder={t('SelectCustom.placeholder_default')}
                                    onKeywordsChange={handleMembersKeywordsChange}
                                    onMenuScrollToBottom={handleMembersMenuScrollToBottom}
                                    hideSelectedOptions={false}
                                    onChange={(value: any): void => {
                                        setSelectedContactId([value.value]);
                                        const options = [value as SelectCustomOption];
                                        setSelectedContactOptions(options);
                                    }}
                                    selected={selectedContactId}
                                    isLoading={selectLoading}
                                    idAttribute="contactId"
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="probability"
                                errors={errors}
                                label={t('Opportunity.probability')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="closeDate"
                                errors={errors}
                                label={t('Opportunity.close_date')}
                                required
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    defaultValue={moment(opportunity?.closeDate)}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>{t('Opportunity.deal_information')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="campusId"
                                errors={errors}
                                label={t('location')}
                                required={
                                    opportunity?.status !== OpportunityStatusDto.New &&
                                    opportunity?.status !== OpportunityStatusDto.TourScheduled &&
                                    opportunity?.status !== OpportunityStatusDto.Qualified
                                }
                            >
                                <Select
                                    disabled={
                                        opportunity?.isSubscriptionModification ||
                                        opportunity?.isSubscriptionRenewal ||
                                        (opportunity?.status !== OpportunityStatusDto.New &&
                                            opportunity?.status !==
                                                OpportunityStatusDto.TourScheduled &&
                                            opportunity?.status !== OpportunityStatusDto.Qualified)
                                    }
                                >
                                    {locationStore.locations?.map((x: LightCampusDetailsInfo) => (
                                        <Option key={x.id!} value={x.id!}>
                                            {x.name}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="deskCount"
                                errors={errors}
                                label={t('Opportunity.desk_count')}
                                required
                            >
                                <NumberFormat
                                    allowNegative={false}
                                    customInput={(props: any) => <Input {...props} />}
                                    isAllowed={(value: NumberFormatValues): boolean =>
                                        value.floatValue === undefined ||
                                        (value.floatValue !== undefined &&
                                            value.floatValue <= BILLION)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="product"
                                errors={errors}
                                label={t('Opportunity.product')}
                                required
                            >
                                <Select>
                                    {Object.keys(OpportunityProductDto).map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`Opportunity.product_${x}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="salesChannel"
                                errors={errors}
                                label={t('Opportunity.sales_channel')}
                                required
                            >
                                <Select>
                                    {Object.keys(OpportunitySalesChannelDto).map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`Opportunity.salesChannel_${x}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="termLength"
                                errors={errors}
                                label={t('Opportunity.term_length')}
                                required={
                                    opportunity?.status !== OpportunityStatusDto.New &&
                                    opportunity?.status !== OpportunityStatusDto.TourScheduled
                                }
                            >
                                <Select>
                                    {Object.keys(OpportunityTermLengthDto).map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`Opportunity.term_${x}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="monthlyRevenue"
                                errors={errors}
                                label={t('Opportunity.monthly_revenue')}
                                required={
                                    opportunity?.status !== OpportunityStatusDto.New &&
                                    opportunity?.status !== OpportunityStatusDto.TourScheduled
                                }
                            >
                                <NumberFormat
                                    allowNegative={false}
                                    customInput={(props: any) => <Input {...props} />}
                                    isAllowed={(value: NumberFormatValues): boolean =>
                                        value.floatValue === undefined ||
                                        (value.floatValue !== undefined &&
                                            value.floatValue <= BILLION)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="officeNumber"
                                errors={errors}
                                label={t('Lead.office_number')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>{t('Opportunity.additional_information')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name="description"
                                errors={errors}
                                label={t('ConferenceRoom.description_title')}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="source"
                                errors={errors}
                                label={t('Lead.source')}
                                required
                            >
                                <Select
                                    onChange={(value): void =>
                                        setLeadSourceVal(value as LeadSourceDto)
                                    }
                                >
                                    {Object.keys(LeadSource).map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`Lead.source_${x}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>

                        {leadSourceVal === LeadSourceDto.Other && (
                            <Col span={8}>
                                <ValidatedFormItem
                                    name="sourceOther"
                                    errors={errors}
                                    label={t('Lead.lead_source_other')}
                                    required
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Col>
                        )}
                    </Row>
                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>

                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(EditOpportunityModal);
