import { GetSecurityDepositsReportRequestDto } from 'Api/Features/Reports/Dtos/SecurityDeposits/GetSecurityDepositsReportRequestDto';
import { SecurityDepositsReportMembershipDto } from 'Api/Features/Reports/Dtos/SecurityDeposits/SecurityDepositsReportMembershipDto';
import { SecurityDepositsReportProxy } from 'Api/Features/Reports/SecurityDepositsReportProxy';
import { inject } from 'aurelia-dependency-injection';
import { arrayBufferToXlsx } from 'Utils/blobs';
import { cleanVal } from 'Utils/NumberUtils';
import { ApiService } from './ApiService';

interface SecurityDepositsReport {
    memberships: SecurityDepositsReportMembershipDto[];
    totalSecurityDeposit: number;
    totalHeldSecurityDeposit: number;
}

@inject(SecurityDepositsReportProxy)
export class SecurityDepositsReportService extends ApiService {
    constructor(private readonly securityDepositsReportProxy: SecurityDepositsReportProxy) {
        super();
    }

    public async getSecurityDepositsReport(
        request: GetSecurityDepositsReportRequestDto | null
    ): Promise<SecurityDepositsReport> {
        const response = await this.securityDepositsReportProxy.getSecurityDepositsReport(request);

        const nonNullMemberships = response?.memberships
            ? response.memberships.filter(
                  (membership): membership is SecurityDepositsReportMembershipDto =>
                      membership !== null
              )
            : [];

        return {
            memberships: nonNullMemberships,
            totalHeldSecurityDeposit: cleanVal.number(response?.totalHeldSecurityDeposit),
            totalSecurityDeposit: cleanVal.number(response?.totalSecurityDeposit),
        };
    }

    public async exportSecurityDepositsReport(
        request: GetSecurityDepositsReportRequestDto | null
    ): Promise<Blob> {
        const document = await this.securityDepositsReportProxy.exportSecurityDepositsReport(
            request
        );

        return arrayBufferToXlsx(document);
    }
}
