import { FlagTwoTone } from '@ant-design/icons';
import { Layout, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { CheckInCampusDto } from 'Api/Features/CheckIns/Dtos/CheckInCampusDto';
import { CheckInSourceDto } from 'Api/Features/CheckIns/Dtos/CheckInSourceDto';
import { Company, Location } from 'Components/icons';
import { ListSectionHeader } from 'Components/list-section-header';
import { TableFilters } from 'Components/table-filters';
import { TdWithImage } from 'Components/td-with-image';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { CheckIn } from 'Models/CheckIns/CheckIn';
import { DISPLAY_DATETIME_12H, TABLE_DEBOUNCE_DELAY } from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import moment from 'moment';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CheckInService } from 'Services/CheckInService';
import FilterStore from 'Stores/FilterStore';
import { showFile } from 'Utils';
import { images, theme } from 'variant';
import { GetCheckInsSortColumnDto } from 'Api/Features/CheckIns/Dtos/GetCheckInsSortColumnDto';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';

const { usersHeader } = images;
const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 10,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'sources',
        nameKey: 'Sources',
        items: [
            {
                key: 'Guest',
                displayNameKey: 'CheckIn.check_in_source_Guest',
                checked: true,
            },
            {
                key: 'GuestsWifi',
                displayNameKey: 'CheckIn.check_in_source_GuestsWifi',
                checked: true,
            },
            {
                key: 'MembersWifi',
                displayNameKey: 'CheckIn.check_in_source_MembersWifi',
                checked: true,
            },
        ],
    },
    {
        key: 'flagged',
        nameKey: 'Flagged',
        items: [
            {
                key: 'flagged',
                displayNameKey: 'CheckIn.check_in_flagged',
                checked: true,
            },
            {
                key: 'not-flagged',
                displayNameKey: 'CheckIn.check_in_not_flagged',
                checked: true,
            },
        ],
    },
];

const CheckIns: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const checkInService = useService(CheckInService);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<CheckIn[]>([]);
    const paginationRef = useRef(initialPaginationState);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const { toastStore, userPermissionsStore } = useStores();
    const { id } = useParams<{ id: string }>();

    const location = {
        isTrue: !!id,
        id: id,
    };

    const keyOrNA = (key: string | null) => key || '';
    const locationRender = (campus: CheckInCampusDto | null): ReactNode => {
        return {
            children: campus ? (
                <TdWithImage defaultImg={<Location />} imgSrc={campus.mainImageUrl}>
                    {campus.name}
                </TdWithImage>
            ) : (
                ''
            ),
            props: { colSpan: location.isTrue ? 0 : 1 },
        };
    };
    const isFlaggedRender = (isFlagged: boolean | undefined): ReactNode =>
        isFlagged ? <FlagTwoTone twoToneColor={theme['burning-red-80']} /> : null;
    const columns: ColumnType<CheckIn>[] = [
        {
            title: t('date'),
            dataIndex: 'date',
            render: (date: string): string => moment.utc(date).format(DISPLAY_DATETIME_12H),
            sorter: true,
            key: GetCheckInsSortColumnDto.Date,
        },
        {
            title: t('User.user_name'),
            dataIndex: 'name',
            sorter: true,
            key: GetCheckInsSortColumnDto.LastName,
            render: keyOrNA,
        },
        {
            title: t('email'),
            dataIndex: 'email',
            sorter: true,
            key: GetCheckInsSortColumnDto.Email,
            render: keyOrNA,
        },
        {
            title: t('company'),
            dataIndex: 'company',
            sorter: true,
            key: GetCheckInsSortColumnDto.CompanyName,
            render: keyOrNA,
        },
        {
            title: t('location'),
            dataIndex: 'campus',
            sorter: true,
            key: GetCheckInsSortColumnDto.CampusName,
            render: locationRender,
            colSpan: location.isTrue ? 0 : 1,
        },
        {
            title: t('CheckIn.check_in_flagged'),
            dataIndex: 'isFlagged',
            align: 'center',
            render: isFlaggedRender,
        },
        {
            title: t('CheckIn.check_in_source'),
            dataIndex: 'source',
            render: (source: CheckInSourceDto): string => t(`CheckIn.check_in_source_${source}`),
        },
    ];

    const getCampusId = (currentLocationId: string): string[] => {
        return currentLocationId !== 'all' ? [currentLocationId] : [];
    };

    const getAdvancedFilterSources = (): {
        checkedSources: CheckInSourceDto[];
        sourcesCount: number;
    } => {
        const filterStore = filterStoreRef.current;
        const sources = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'sources'
        );
        const checkedSources = sources?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key as CheckInSourceDto;
            });
        return {
            checkedSources: checkedSources ?? [],
            sourcesCount: sources?.items.length ?? 0,
        };
    };

    const getAdvancedFilterFlagged = (): { checkedFlagged: string[]; flaggedCount: number } => {
        const filterStore = filterStoreRef.current;
        const flagged = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'flagged'
        );
        const checkedFlagged = flagged?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key;
            });

        return {
            checkedFlagged: checkedFlagged ?? [],
            flaggedCount: flagged?.items.length ?? 0,
        };
    };

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            currentLocationId?: string;
            advancedFilters?: AdvancedFilter[];
            startDate?: string;
            endDate?: string;
            sortColumn: GetCheckInsSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            const filterStore = filterStoreRef.current;
            setLoading(true);
            try {
                const sources = getAdvancedFilterSources();
                const flagged = getAdvancedFilterFlagged();
                if (sources.checkedSources.length === 0 || flagged.checkedFlagged.length === 0) {
                    setData([]);
                } else {
                    // call api
                    const [data, totalItems] = await checkInService.getCheckIns({
                        minDateTime: filterStore.startDate,
                        maxDateTime: filterStore.endDate,
                        sources: sources.checkedSources,
                        campusIds: location.isTrue
                            ? [location.id]
                            : getCampusId(filterStore.currentLocationId),
                        isFlagged:
                            flagged.checkedFlagged.length === flagged.flaggedCount
                                ? null
                                : flagged.checkedFlagged.includes('flagged'),
                        searchTerm: filterStore.searchTerm,
                        page: (params.pagination.current || 1) - 1,
                        pageSize: params.pagination.pageSize || 10,
                        sortColumn: params.sortColumn,
                        sortDirection: params.sortDirection,
                    });
                    setData(data);
                    setPagination({
                        ...params.pagination,
                        total: totalItems,
                    });
                }
            } finally {
                setLoading(false);
            }
        },
        [checkInService, location.id, location.isTrue]
    );

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        const { currentLocationId, advancedFilters, searchTerm, startDate, endDate } =
            filterStoreRef.current;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        await fetch({
            pagination,
            currentLocationId,
            advancedFilters,
            searchTerm,
            startDate,
            endDate,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
        paginationRef.current = pagination;
    };

    const debounceSearch = useRef(
        debounce(
            (params: {
                startDate?: string;
                endDate?: string;
                searchTerm?: string;
                advancedFilters?: AdvancedFilter[];
                currentLocationId?: string;
            }) => {
                fetch({
                    pagination: {
                        ...paginationRef.current,
                        current: 1,
                    },
                    searchTerm: params.searchTerm,
                    currentLocationId: params.currentLocationId,
                    advancedFilters: params.advancedFilters,
                    startDate: params.startDate,
                    endDate: params.endDate,
                    sortColumn: null,
                    sortDirection: null,
                });
            },
            TABLE_DEBOUNCE_DELAY
        )
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                currentLocationId: filterStore.currentLocationId,
                advancedFilters: filterStore.advancedFilters,
                startDate: filterStore.startDate,
                endDate: filterStore.endDate,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    const onRowClick = async (checkIn: CheckIn): Promise<void> => {
        try {
            setLoading(true);
            const document = await checkInService.getSummaryDocument(checkIn.id);
            showFile(
                document,
                `${t(`CheckIn.check_in_source_${checkIn.source}`)} - ${
                    checkIn.name
                } - ${checkIn.dateMoment?.format('MM-DD-YYYY h-mm A')}`
            );
        } catch (e) {
            if (!e.treated) {
                toastStore.toast({
                    type: 'error',
                    messageKey: 'CheckIn.check_in_pdf_error',
                });
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <ListSectionHeader
                title={t('CheckIn.check_ins_title')}
                subTitle={t('CheckIn.check_ins_subtitle')}
                defaultImg={<Company fill={theme['primary-color']} />}
                backgroundImageUrl={usersHeader}
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeLocations={!location.isTrue}
                    includeDates
                    availableLocations={userPermissionsStore.availableLocationsForUser}
                    includeAdvancedFilters
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(record: CheckIn): string => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(row: CheckIn) => ({
                        onClick: (): void => {
                            onRowClick(row);
                        },
                    })}
                />
            </Content>
        </>
    );
});

export default CheckIns;
