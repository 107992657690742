import React, { FunctionComponent, useState, useCallback, useRef, useEffect } from 'react';
import { Row, Col, Typography, Input, Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { Gutter } from 'antd/es/grid/row';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { MembershipService } from 'Services/MembershipService';
import { OpportunityRadioButtonValues } from './create-opportunity';
import { Company } from 'Components/icons';
import { theme } from 'variant';
import { IndustryDto } from 'Api/Features/Memberships/Dtos/IndustryDto';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { BILLION } from 'Models/Constants';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import Icon from 'Components/icons/icon';
import { ImagePicker } from 'Components/image-picker';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { LightCampusDetailsInfo } from 'Models/Location/LightCampusInfo';
import { ExpertiseDto } from 'Api/Features/Expertises/Dtos/ExpertiseDto';
import { ExpertiseService } from 'Services/ExpertiseService';
import { CompanyRadioButtonValues } from '../contacts/modals/create-contact';
import { MembershipDto } from 'Api/Features/Memberships/Dtos/MembershipDto';
import './company-form-section.less';
import { UploadFile } from 'antd/lib/upload/interface';
import { MembershipDetailsPlusDto } from 'Api/Features/Memberships/Dtos/MembershipDetailsPlusDto';
import { observer } from 'mobx-react';

const { Title } = Typography;
const { Option } = Select;

const formGutter: [Gutter, Gutter] = [40, 0];

interface CompanyFormSectionProps {
    radioButtonValue: OpportunityRadioButtonValues | CompanyRadioButtonValues;
    errors: Map<string, string[]>;
    handleOnCompanyChangeLabel?: any;
    handleOnCompanyChange?: any;
    selectedMembershipId?: string;
    companyOwnerName?: string;
    setImageDetails: (images?: ImageDetails[]) => void;
    membership?: MembershipDetailsPlusDto
}

interface ExpertisesOptions {
    selected?: ExpertiseDto;
    options: ExpertiseDto[];
}

const CompanyFormSection: FunctionComponent<CompanyFormSectionProps> = observer(({
    radioButtonValue,
    errors,
    handleOnCompanyChangeLabel,
    handleOnCompanyChange,
    selectedMembershipId,
    companyOwnerName,
    setImageDetails,
    membership
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const membershipService = useService(MembershipService);
    const expertiseService = useService(ExpertiseService);
    const pageSize = 25;
    //companies
    const [companiesCurrentPage, setCompaniesCurrentPage] = useState(0);
    const [companiesSearchResults, setCompaniesSearchResults] = useState<MembershipDto[]>([]);
    const [companiesSearchTerm, setCompaniesSearchTerm] = useState('');
    const [companiesMaxResults, setCompaniesMaxResults] = useState(false);
    const [companiesOptions, setCompaniesOptions] = useState<SelectCustomOption[]>([]);
    const [selectedCompanyOptions, setSelectedCompanyOptions] = useState<SelectCustomOption[]>([]);
    const [expertisesOptions, setExpertisesOptions] = useState<ExpertisesOptions>({ options: [] });
    const [selectedLocationsIds, SetSelectedLocationsIds] = useState<string[]>(
        membership?.campuses
            ? membership.campuses.filter((x) => x?.id !== null).map((x) => x?.id!)
            : []
    );
    const [selectedExpertisesIds, SetSelectedExpertisesIds] = useState<string[]>(
        membership?.expertises
            ? membership.expertises.filter((x) => x?.id !== null).map((x) => x?.id!)
            : []
    );
    const [selectLoading, setSelectLoading] = useState(false);
    const { globalLoadingStore, toastStore, userPermissionsStore } = useStores();
    //#endregion

    //#region Event handlers
    const resetCompaniesSearch = (): void => {
        setCompaniesCurrentPage(0);
        setCompaniesSearchResults([]);
        setCompaniesSearchTerm('');
        setCompaniesMaxResults(false);
    };

    const handleCompaniesKeywordsChange = useCallback((value: string): void => {
        resetCompaniesSearch();
        setCompaniesSearchTerm(value);
    }, []);

    const handleCompaniesMenuScrollToBottom = (): void => {
        if (!companiesMaxResults) {
            setCompaniesCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handleLocationSelect = useCallback((values: any): void => {
        if (values === '') {
            SetSelectedLocationsIds([]);
        } else {
            SetSelectedLocationsIds(values?.map((x: any) => x.value));
        }
    }, []);

    const handleExprtisesSelect = useCallback((values: any): void => {
        if (values === '') {
            SetSelectedExpertisesIds([]);
        } else {
            SetSelectedExpertisesIds(values?.map((x: any) => x.value));
        }
    }, []);

    const fetchExpertises = useCallback(async () => {
        globalLoadingStore.addLoading();

        try {
            const response = await expertiseService.getExpertises({ page: 0, pageSize: 100 });
            setExpertisesOptions({ selected: response[0], options: response });
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, expertiseService, toastStore]);

    useEffect(() => {
        fetchExpertises();
    }, [fetchExpertises]);

    const searchCompanies = async (page: number, searchTerm: string): Promise<MembershipDto[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
        };

        const [results, totalItemCount] = await membershipService.getMemberships(args);

        if (results.length + pageSize * companiesCurrentPage >= totalItemCount) {
            setCompaniesMaxResults(true);
        }
        setSelectLoading(false);
        return results;
    };

    const debounceCompaniesSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchCompanies(page, searchTerm).then((results) => {
                setCompaniesSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );
    //#endregion

    //#region Effects
    useEffect(() => {
        setSelectLoading(true);
        debounceCompaniesSearch.current(companiesCurrentPage, companiesSearchTerm);
    }, [companiesSearchTerm, companiesCurrentPage, radioButtonValue]);

    useEffect(() => {
        const searchResults = companiesSearchResults?.map(
            (x: MembershipDto) =>
                ({
                    value: x?.id,
                    label: x?.name,
                    imageUrl: x?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedCompanyOptions);
        setCompaniesOptions(merged);
    }, [companiesSearchResults, selectedCompanyOptions]);
    //#endregion

    //#region Render
    return (
        <div className="CompanyFormSection">
            {radioButtonValue === OpportunityRadioButtonValues.Existing ? (
                <>
                    <Title level={4}>{t('Membership.choose_existing_company')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={10}>
                            <ValidatedFormItem name="membershipId" errors={errors} required>
                                <SelectCustom
                                    options={companiesOptions}
                                    defaultImg={<Company fill={theme['white']} />}
                                    strongLabel={true}
                                    placeholder={t('SelectCustom.placeholder_default')}
                                    onKeywordsChange={handleCompaniesKeywordsChange}
                                    onMenuScrollToBottom={handleCompaniesMenuScrollToBottom}
                                    hideSelectedOptions={false}
                                    onChange={(value): void => {
                                        handleOnCompanyChange(value);
                                        const options = [value as SelectCustomOption];
                                        setSelectedCompanyOptions(options);
                                    }}
                                    onChangeGetLabel={handleOnCompanyChangeLabel}
                                    selected={selectedMembershipId ? [selectedMembershipId] : null}
                                    isLoading={selectLoading}
                                    idAttribute='membershipId'
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Title level={4}>{t('basic_information')}</Title>
                    {companyOwnerName && (
                        <Row gutter={formGutter}>
                            <Col span={12}>
                                <ValidatedFormItem
                                    name="ownerAccountId"
                                    label={t('Membership.company_owner')}
                                    required
                                >
                                    <SelectCustom
                                        defaultImg={<Icon iconName="User" fill={theme['white']} />}
                                        strongLabel={true}
                                        hideSelectedOptions={false}
                                        options={[
                                            {
                                                value: 'tempId',
                                                label: companyOwnerName,
                                            },
                                        ]}
                                        selected={['tempId']}
                                        disabled
                                        idAttribute='ownerAccountId'
                                    />
                                </ValidatedFormItem>
                            </Col>
                        </Row>
                    )}

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                label={t('main_image')}
                                errors={errors}
                                name="membership.image"
                            >
                                <ImagePicker
                                    images={
                                        membership?.imageUrl
                                            ? [
                                                  {
                                                      url: membership?.imageUrl,
                                                      uid: 'main',
                                                  } as UploadFile,
                                              ]
                                            : undefined
                                    }
                                    setImagesDetails={(images?: ImageDetails[]) =>
                                        setImageDetails(images)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                name="membership.name"
                                errors={errors}
                                label={t('name')}
                                required
                            >
                                <Input
                                    onChange={(e): void => {
                                        if (handleOnCompanyChangeLabel)
                                            handleOnCompanyChangeLabel(e.currentTarget.value);
                                    }}
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                name="membership.billingName"
                                errors={errors}
                                label={t('billing_name')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                name="membership.billingEmail"
                                errors={errors}
                                label={t('billing_email')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                name="membership.numberOfEmployees"
                                errors={errors}
                                label={t('Lead.number_of_employees')}
                            >
                                <NumberFormat
                                    allowNegative={false}
                                    customInput={(props: any) => <Input {...props} />}
                                    isAllowed={(value: NumberFormatValues): boolean =>
                                        value.floatValue === undefined ||
                                        (value.floatValue !== undefined &&
                                            value.floatValue <= BILLION)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                name="membership.industry"
                                errors={errors}
                                label={t('Lead.industry')}
                            >
                                <Select>
                                    {Object.keys(IndustryDto).map((x) => (
                                        <Option key={x} value={x}>
                                            {t(`Lead.industry_${x}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem name="membership.locations" label={t('location')}>
                                <SelectCustom
                                    options={userPermissionsStore.availableLocationsForUser?.map(
                                        (location: LightCampusDetailsInfo) =>
                                            ({
                                                value: location.id,
                                                label: location.name,
                                            } as SelectCustomOption)
                                    )}
                                    strongLabel={true}
                                    hideSelectedOptions={false}
                                    isMulti
                                    onChange={handleLocationSelect}
                                    selected={selectedLocationsIds}
                                    idAttribute='membership.locations'
                                    disabled={membership && !userPermissionsStore.userIsAdministrator}
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={24}>
                            <ValidatedFormItem
                                name="membership.presentation"
                                errors={errors}
                                label={t('Membership.presentation')}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={24}>
                            <ValidatedFormItem name="membership.expertises" label={t('expertises')}>
                                <SelectCustom
                                    options={expertisesOptions.options.map(
                                        (expertise) =>
                                            ({
                                                value: expertise.id,
                                                label: expertise.description,
                                            } as SelectCustomOption)
                                    )}
                                    strongLabel={true}
                                    hideSelectedOptions={false}
                                    isMulti
                                    onChange={handleExprtisesSelect}
                                    selected={selectedExpertisesIds}
                                    idAttribute='membership.expertises'
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>{t('address')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name="membership.address.addressLine1"
                                errors={errors}
                                label={t('address')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="membership.address.city"
                                errors={errors}
                                label={t('city')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="membership.address.state"
                                errors={errors}
                                label={t('state')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="membership.address.country"
                                errors={errors}
                                label={t('country')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="membership.address.postalCode"
                                errors={errors}
                                label={t('zip_code')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>{t('legal_contact')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="membership.legalContact.firstName"
                                errors={errors}
                                label={t('first_name')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="membership.legalContact.lastName"
                                errors={errors}
                                label={t('last_name')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="membership.legalContact.phone"
                                errors={errors}
                                label={t('phone_number')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="membership.legalContact.email"
                                errors={errors}
                                label={t('email')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="membership.legalContact.birthDate"
                                label={t('birth_date')}
                                errors={errors}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    allowClear
                                    className="clearable"
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={24}>
                            <ValidatedFormItem
                                name="membership.legalContact.addressLine1"
                                errors={errors}
                                label={t('address')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={6}>
                            <ValidatedFormItem
                                name="membership.legalContact.city"
                                errors={errors}
                                label={t('city')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={6}>
                            <ValidatedFormItem
                                name="membership.legalContact.state"
                                errors={errors}
                                label={t('state')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={6}>
                            <ValidatedFormItem
                                name="membership.legalContact.country"
                                errors={errors}
                                label={t('country')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={6}>
                            <ValidatedFormItem
                                name="membership.legalContact.postalCode"
                                errors={errors}
                                label={t('zip_code')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>{t('contact_information')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="membership.contactInfo.phone1"
                                errors={errors}
                                label={t('phone_number')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="membership.contactInfo.email"
                                errors={errors}
                                label={t('email')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="membership.contactInfo.website"
                                errors={errors}
                                label={t('website')}
                            >
                                <Input placeholder={t('url_example')} />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="membership.contactInfo.angelListUrl"
                                errors={errors}
                                label={t('angelList_url')}
                            >
                                <Input placeholder={t('url_example')} />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="membership.contactInfo.twitterUrl"
                                errors={errors}
                                label={t('twitter_url')}
                            >
                                <Input placeholder={t('url_example')} />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="membership.contactInfo.facebookUrl"
                                errors={errors}
                                label={t('facebook_url')}
                            >
                                <Input placeholder={t('url_example')} />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="membership.contactInfo.linkedInUrl"
                                errors={errors}
                                label={t('linkedIn_url')}
                            >
                                <Input placeholder={t('url_example')} />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                name="membership.contactInfo.instagramUrl"
                                errors={errors}
                                label={t('instagram_url')}
                            >
                                <Input placeholder={t('url_example')} />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
});
//#endregion

export default React.memo(CompanyFormSection);
