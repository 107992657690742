import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DISPLAY_DATE_FORMAT } from 'Models/Constants';
import moment, { Moment } from 'moment';
import StatusBar from 'Components/status-bar/status-bar';
import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';
import './opportunity-detail-statusbar.less';
import { StatusBarNavigation } from 'Components/status-bar-navigation';
import { StatusBarNavigationProps } from 'Components/status-bar-navigation/status-bar-navigation';

interface OpportunityDetailStatusbarProps {
    opportunityStatus: OpportunityStatusDto;
    opportunityStatusActivityDate?: Moment;
    setSendAgreementModalOpen: (visible: boolean, isSigned: boolean) => void;
    setCreateProposalModalOpen: (arg0: boolean) => void;
    onProposalResponseClick: () => void;
    setScheduleATourModalOpen: (visible: boolean) => void;
    setMoveToQualifiedModalOpen: (visible: boolean) => void;
    tourScheduledDate?: string;
    setEditATourModalOpen: (visible: boolean) => void;
    opportunityIsSubscriptionRenewal?: boolean;
    opportunityIsSubscriptionModification?: boolean;
    onAgreementSentClick: () => void;
}

const OpportunityDetailStatusbar: FunctionComponent<OpportunityDetailStatusbarProps> = ({
    opportunityStatus,
    opportunityStatusActivityDate,
    setSendAgreementModalOpen,
    setCreateProposalModalOpen,
    onProposalResponseClick,
    setScheduleATourModalOpen,
    setMoveToQualifiedModalOpen,
    tourScheduledDate,
    setEditATourModalOpen,
    opportunityIsSubscriptionRenewal,
    opportunityIsSubscriptionModification,
    onAgreementSentClick
}) => {
    const { t } = useTranslation();
    let currentStep = 1;
    const stepsToDisplay = 7;

    const stepTextList = Object.keys(OpportunityStatusDto)
        .filter((value, index) => {
            currentStep = opportunityStatus === value ? index + 1 : currentStep;

            return index < stepsToDisplay && value;
        })
        .map((item) => t(`Opportunity.opportunity_status_${item}`));

    const statusBarNavigationData: StatusBarNavigationProps = {
        title: t(`Opportunity.opportunity_status_info_${opportunityStatus}`),
    };

    let statusBarCurrentStepSubTitleStringKey = '';
    
    switch (opportunityStatus) {
        case OpportunityStatusDto.New:
            statusBarNavigationData.buttonsList = [
                {
                    buttonLabel: t('Lead.schedule_a_tour'),
                    buttonOnClick: (): void => {
                        setScheduleATourModalOpen(true);
                    },
                },
                {
                    buttonLabel: t('Opportunity.move_to_qualified'),
                    buttonOnClick: (): void => {
                        setMoveToQualifiedModalOpen(true);
                    },
                },
            ];
            statusBarCurrentStepSubTitleStringKey = 'Opportunity.status_created_on';
            break;
        case OpportunityStatusDto.TourScheduled:
            statusBarNavigationData.buttonsList = [
                {
                    buttonLabel: t('Opportunity.move_to_qualified'),
                    buttonOnClick: (): void => {
                        setMoveToQualifiedModalOpen(true);
                    },
                },
            ];
            if (moment(tourScheduledDate) > moment()) {
                statusBarNavigationData.buttonsList.push({
                    buttonLabel: t('Lead.schedule_a_tour_edit'),
                    buttonOnClick: (): void => {
                        setEditATourModalOpen(true);
                    },
                });
            }
            statusBarNavigationData.title = t(
                `Opportunity.opportunity_status_info_${opportunityStatus}`,
                {
                    param1: moment(tourScheduledDate).format(DISPLAY_DATE_FORMAT),
                }
            );
            statusBarCurrentStepSubTitleStringKey = 'Opportunity.opportunity_status_since';
            break;
        case OpportunityStatusDto.Qualified:
            statusBarNavigationData.buttonsList = [
                {
                    buttonLabel: t('Opportunity.create_proposal'),
                    buttonOnClick: (): void => {
                        setCreateProposalModalOpen(true);
                    },
                },
            ];
            statusBarCurrentStepSubTitleStringKey = 'Opportunity.opportunity_status_since';
            statusBarNavigationData.title = opportunityIsSubscriptionRenewal
                ? t('Opportunity.opportunity_status_info_renewal')
                : opportunityIsSubscriptionModification
                ? t('Opportunity.opportunity_status_info_modification')
                : t(`Opportunity.opportunity_status_info_${opportunityStatus}`);
            break;
        case OpportunityStatusDto.Proposal:
            statusBarNavigationData.buttonsList = [
                {
                    buttonLabel: t('Proposal.view_proposals'),
                    buttonOnClick: (): void => {
                        onProposalResponseClick();
                    },
                },
                {
                    buttonLabel: t('Opportunity.make_new_proposal'),
                    buttonOnClick: (): void => {
                        setCreateProposalModalOpen(true);
                    },
                },
            ];
            statusBarCurrentStepSubTitleStringKey = 'Opportunity.status_sent_on';
            break;
        case OpportunityStatusDto.Agreed:
            statusBarNavigationData.buttonsList = [
                {
                    buttonLabel: t('Opportunity.agreement_terms_sent'),
                    buttonOnClick: (): void => {
                        onAgreementSentClick();
                    },
                },
            ];
            statusBarCurrentStepSubTitleStringKey = 'Opportunity.status_accepted_on';
            break;
        case OpportunityStatusDto.AgreementSent:
            statusBarNavigationData.buttonsList = [
                {
                    buttonLabel: t('Opportunity.agreement_signed'),
                    buttonOnClick: (): void => {
                        setSendAgreementModalOpen(true, true);
                    },
                },
            ];
            statusBarCurrentStepSubTitleStringKey = 'Opportunity.status_sent_on';
            break;
        case OpportunityStatusDto.AgreementSigned:
            statusBarNavigationData.title = t(
                `Opportunity.opportunity_status_info_${opportunityStatus}`,
                {
                    param1: moment(opportunityStatusActivityDate).format(DISPLAY_DATE_FORMAT),
                }
            );
            statusBarCurrentStepSubTitleStringKey = 'Opportunity.status_uploaded_on';
            break;
        case OpportunityStatusDto.Lost:
            statusBarNavigationData.title = t(
                `Opportunity.opportunity_status_info_${opportunityStatus}`,
                {
                    param1: moment(opportunityStatusActivityDate).format(DISPLAY_DATE_FORMAT),
                }
            );
            break;
    }

    return (
        <>
            {opportunityStatus !== OpportunityStatusDto.Lost && (
                <StatusBar
                    step={currentStep}
                    stepTextList={stepTextList}
                    currentStepSubTitle={t(statusBarCurrentStepSubTitleStringKey, {
                        param1: moment(opportunityStatusActivityDate).format(DISPLAY_DATE_FORMAT),
                    })}
                />
            )}

            <StatusBarNavigation
                title={statusBarNavigationData.title}
                buttonsList={statusBarNavigationData.buttonsList}
            />
        </>
    );
};

export default OpportunityDetailStatusbar;
