import React, { FunctionComponent, useEffect, useState } from 'react';
import { Modal, Form, Row, Button, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStores } from 'Hooks';
import { ConferenceRoom as AmenityIcon, Close } from 'Components/icons';
import './booking-conflicts-modal.less';
import moment from 'moment';
import { BookingOccurrenceSummaryDto } from 'Api/Features/Amenities/Dtos/BookingOccurrenceSummaryDto';
import { RecurrenceBookingExceptionRequestDto } from 'Api/Features/Amenities/Dtos/RecurrenceBookingExceptionRequestDto';
import { BookingConflictItem } from 'Components/booking/flexible-booking/booking-conflict-item';
import { ConferenceRoomDto } from 'Api/Features/Amenities/Dtos/ConferenceRooms/ConferenceRoomDto';
import { DailyBookingSummaryPeriodUnavailabilityReasonDto } from 'Api/Features/Bookings/Dtos/DailyBookingSummaryPeriodUnavailabilityReasonDto';
import EditBookingConflictModal from './edit-booking-conflict-modal';

interface BookingConflictModalProps {
    visible: boolean;
    onComplete: (
        success: boolean,
        exceptions: RecurrenceBookingExceptionRequestDto[] | null
    ) => void;
    originalAmenity: ConferenceRoomDto;
    bookingsWithRecurrenceErrors: BookingOccurrenceSummaryDto[];
    timezone: string | null;
    locationId?: string;
}

export interface CurrentConflict {
    id: string;
    bookingStart: string;
    bookingEnd: string;
    conferenceRoomId: string;
    resolved?: boolean;
}

export interface ConflictBookingPreview extends BookingOccurrenceSummaryDto {
    resolved?: boolean;
    isCancelled?: boolean;
}

const BookingConflictsModal: FunctionComponent<BookingConflictModalProps> = ({
    visible,
    onComplete,
    originalAmenity,
    bookingsWithRecurrenceErrors,
    timezone,
    locationId,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { confirmationModalStore, bookingStore } = useStores();
    const [bookings, setBookings] = useState<ConflictBookingPreview[]>([]);
    const [editConflictModalOpen, setEditConflictModalOpen] = useState(false);
    const [currentEditingConflict, setCurrentEditingConflict] = useState<CurrentConflict>();
    
    
    const onEditConflictClick = (id: string): void => {
        const conflict = bookings.find(booking => booking.id === id);
        if(conflict) {
            setCurrentEditingConflict({
                bookingStart: conflict.periodStart!,
                bookingEnd: conflict.periodEnd!,
                conferenceRoomId: conflict.amenityId!,
                id: id,
                resolved: conflict.resolved
            })
            setEditConflictModalOpen(true);
        }
    }

    const onEditConflictComplete = (resolvedConflict?: CurrentConflict): void => {
        setEditConflictModalOpen(false);

        if(resolvedConflict) {
            const oldState = bookings;
            const index = oldState.findIndex((booking) => booking.id === resolvedConflict.id);
            oldState[index] = {
                id: resolvedConflict.id,
                isAvailable: false, 
                isCancelled: false,
                periodEnd: resolvedConflict.bookingEnd,
                periodStart: resolvedConflict.bookingStart,
                unavailabilityReason: DailyBookingSummaryPeriodUnavailabilityReasonDto.Booked,
                resolved: true,
                amenityId: resolvedConflict.conferenceRoomId
            } as ConflictBookingPreview ;
            setBookings([...oldState]);
        }
    }

    useEffect(() => {
        setBookings(bookingsWithRecurrenceErrors);
    }, [bookingsWithRecurrenceErrors]);

    const dismiss = (
        success = false,
        exceptions: RecurrenceBookingExceptionRequestDto[] | null
    ): void => {
        onComplete(success, exceptions);
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <AmenityIcon />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t('Booking.leave_booking_conflicts_confirm_positive'),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss(false, null);
    };

    const cancelBooking = async (id: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <AmenityIcon />,
                title: t(`Booking.delete_conflict_confirm_title`),
                message: t(`are_you_sure_you_want_to_continue`),
                positiveText: t('yes'),
                negativeText: t('no'),
            }))
        )
            return;

        const oldState = bookings;
        oldState.find((booking) => booking.id === id)!.isCancelled = true;
        setBookings([...oldState]);
    };
    
    const createExceptions = (): RecurrenceBookingExceptionRequestDto[] => {
        const exceptions = bookings.map(
            (booking) =>
                ({
                    id: booking.id,
                    periodStart: booking.periodStart,
                    periodEnd: booking.periodEnd,
                    isCancelled: booking.isCancelled || !booking.resolved,
                    amenityId: booking.amenityId
                } as RecurrenceBookingExceptionRequestDto)
        );

        return exceptions;
    };

    const submit = (ignoreConflicts?: boolean): void => {
        if(!ignoreConflicts && bookings.some(booking => !booking.isCancelled && !booking.resolved)) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            cancelUnresolvedConflicts();
            return;
        }
        const exceptions = createExceptions();
        dismiss(true, exceptions);
    };

    const cancelUnresolvedConflicts = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <AmenityIcon />,
                title: t(`Booking.cancel_booking_conflicts`), 
                message: t(`Booking.cancel_booking_conflicts_explanation`),
                positiveText: t('yes'),
                negativeText: t(`no`),
            }))
        )
            return;
        submit(true);
    };

    return (
        <>
            <Modal
                visible={visible}
                title={t('Booking.booking_conflicts')}
                className="FormModal BookingConflictModal"
                closeIcon={<Close />}
                width={960}
                footer={null}
                onCancel={(): Promise<void> => exit()}
                maskClosable={false}
                centered
            >
                <Form scrollToFirstError layout="vertical" form={form}>
                    {bookings.some((booking) => !booking.isCancelled) ? (
                        <>
                            <Row>
                                <Col span={24} className="title-container">
                                    {t('Booking.room_and_period_not_available')}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    {bookings.map((booking, i, arr) => (
                                        <div
                                            className={
                                                booking.isCancelled || i === arr.length - 1
                                                    ? ''
                                                    : 'pb-15'
                                            }
                                            key={booking.id ?? i}
                                        >
                                            <BookingConflictItem
                                                id={booking.id!}
                                                resolved={booking.resolved}
                                                periodStart={moment(booking.periodStart)}
                                                periodEnd={moment(booking.periodEnd)}
                                                amenity={
                                                    bookingStore.dailyBookingSummary?.find(
                                                        (amenity) =>
                                                            amenity?.id === booking.amenityId
                                                    ) as ConferenceRoomDto
                                                }
                                                errorMessage={t(
                                                    `Booking.unavailability_reason_${booking.reason}`
                                                )}
                                                onDeleteClick={(id: string): Promise<void> =>
                                                    cancelBooking(id)
                                                }
                                                onEditClick={(id: string): void =>
                                                    onEditConflictClick(id)
                                                }
                                                isCanceled={booking.isCancelled}
                                                timeZone={timezone}
                                            />
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <Row>
                            <Col span={24} className="all-deleted-container">
                                {t('Booking.all_conflicts_deleted')}
                            </Col>
                        </Row>
                    )}

                    <div className="actions d-flex">
                        <Button
                            type="default"
                            className="secondary negative m-l-auto"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" onClick={(): void => submit()}>
                            {t('Booking.confirm_my_recurring_booking')}
                        </Button>
                    </div>
                </Form>
            </Modal>

            {editConflictModalOpen && (
                <EditBookingConflictModal
                    onComplete={(resolvedConflict?: CurrentConflict): void =>
                        onEditConflictComplete(resolvedConflict)
                    }
                    visible={editConflictModalOpen}
                    timezone={timezone}
                    originalConflict={currentEditingConflict}
                    locationId={locationId}
                    originalAmenityId={originalAmenity.id!}
                />
            )}
        </>
    );
};

export default React.memo(BookingConflictsModal);
