import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useService, useStores } from 'Hooks';
import { ConferenceRoom as AmenityIcon, Close } from 'Components/icons';
import FlexibleBooking, {
    FlexibleBookingViewType,
} from 'Components/booking/flexible-booking/flexible-booking';
import './edit-booking-conflict-modal.less';
import momentTimezone from 'moment-timezone';
import { BookingService } from 'Services/BookingService';
import { CurrentConflict } from './booking-conflicts-modal';
import moment from 'moment';
import {
    DAY_MONTH_DATE_YEAR_DISPLAY_FORMAT,
    MOMENT_PARSING_FORMAT,
    TIME_WITH_OFFSET_FORMAT,
} from 'Models/Constants';
import { DailyBookingSummaryPeriodUnavailabilityReasonDto } from 'Api/Features/Bookings/Dtos/DailyBookingSummaryPeriodUnavailabilityReasonDto';
import DateControls from 'Components/booking/flexible-booking/date-controls';
import { DailyBookingSummaryAmenityDto } from 'Api/Features/Amenities/Dtos/DailyBookingSummaryAmenityDto';
import { generateResourceHeader, generateResourcePeriod } from '../locations/id/booking';
import { FormStep } from 'Components/form-step';
import { StepInfo } from 'Components/form-step/form-step';

interface EditBookingConflictModalProps {
    visible: boolean;
    onComplete: (resolvedConflict?: CurrentConflict) => void;
    originalConflict?: CurrentConflict;
    timezone: string | null;
    locationId?: string;
    originalAmenityId: string;
}

enum ConflictResolutionStep {
    amenityStep = 'amenityStep',
    timeStep = 'timeStep',
}

const EditBookingConflictModal: FunctionComponent<EditBookingConflictModalProps> = ({
    visible,
    onComplete,
    originalConflict,
    timezone,
    locationId,
    originalAmenityId,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { confirmationModalStore, globalLoadingStore, toastStore } = useStores();
    const bookingService = useService(BookingService);

    const [resource, setResource] = useState<(DailyBookingSummaryAmenityDto | null)[]>([]);
    const [selectedAmenity, setSelectedAmenity] = useState<
        (DailyBookingSummaryAmenityDto | null | undefined)[]
    >([]);
    const [currentBookingIsErrored, setCurrentBookingIsErrored] = useState(
        !originalConflict?.resolved
    );

    const [currentConflict, setCurrentConflict] = useState<CurrentConflict | undefined>(
        originalConflict
    );
    const [selectedDate, setSelectedDate] = useState(moment(originalConflict?.bookingStart));
    const [currentStep, setCurrentStep] = useState<ConflictResolutionStep>(
        ConflictResolutionStep.amenityStep
    );
    const [currentBookingCreditCost, setcurrentBookingCreditCost] = useState(0);
    const modalRef = useRef<HTMLDivElement>(null);

    const fetchResource = useCallback(async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            const response = await bookingService.getDailyBookingSummary(
                locationId!,
                moment(selectedDate).format(MOMENT_PARSING_FORMAT)
            );
            if (response?.amenities) setResource(response.amenities);
        } catch (e) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, toastStore, bookingService, locationId, selectedDate]);

    useEffect(() => {
        fetchResource();
    }, [fetchResource]);

    const dismiss = (conflictResolved?: boolean): void => {
        onComplete(conflictResolved ? currentConflict : undefined);
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <AmenityIcon />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t('Booking.leave_booking_conflicts_confirm_positive'),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = (): void => {
        if (currentBookingIsErrored) return;

        dismiss(true);
    };

    const handleDateChange = (selectedDate: any) => {
        const date = moment(selectedDate);
        setSelectedDate(date);

        if (currentStep === ConflictResolutionStep.amenityStep) {
            if (moment(originalConflict?.bookingStart).isSame(date))
                setCurrentConflict(originalConflict);
            else setCurrentConflict(undefined);
        } else {
            const conflict = currentConflict;
            const startTime = moment(conflict?.bookingStart).format(TIME_WITH_OFFSET_FORMAT);
            const endTime = moment(conflict?.bookingEnd).format(TIME_WITH_OFFSET_FORMAT);
            setCurrentConflict({
                ...currentConflict,
                bookingStart: moment(
                    moment(selectedDate.format(MOMENT_PARSING_FORMAT + 'T' + startTime))
                ).format(),
                bookingEnd: moment(
                    moment(selectedDate.format(MOMENT_PARSING_FORMAT + 'T' + endTime))
                ).format(),
            } as CurrentConflict);
        }
    };

    const handleTimeCellClick = (e: any, bookingInformation: any, period: any) => {
        setCurrentBookingIsErrored(
            period.unavailabilityReason === DailyBookingSummaryPeriodUnavailabilityReasonDto.Booked
        );
        bookingInformation.id = originalConflict?.id;
        setCurrentConflict({ ...bookingInformation });
    };

    useEffect(() => {
        if (resource?.length > 0) {
            setSelectedAmenity([resource.find((resource) => resource?.id === originalAmenityId)]);
        }
    }, [originalConflict, resource]);

    useEffect(() => {
        //set if current booking is conflicting with another in the calendar
        if (currentConflict) {
            const resourcePeriods = selectedAmenity[0]?.periods;
            const bookedPeriods = resourcePeriods?.filter(
                (period: any) =>
                    period.unavailabilityReason ===
                    DailyBookingSummaryPeriodUnavailabilityReasonDto.Booked
            );

            let currentBookingIsErrored = false;
            if (bookedPeriods) {
                bookedPeriods.forEach((period) => {
                    const bookedPeriodStart = moment(period?.start);
                    const bookedPeriodEnd = moment(period?.end);
                    const currentStart = moment(currentConflict?.bookingStart);
                    const currentEnd = moment(currentConflict?.bookingEnd);
                    if (
                        currentStart.isSame(bookedPeriodStart) ||
                        currentStart.isBetween(bookedPeriodStart, bookedPeriodEnd) ||
                        (currentStart.isSameOrBefore(bookedPeriodStart) &&
                            currentEnd.isAfter(bookedPeriodStart))
                    ) {
                        currentBookingIsErrored = true;
                        return;
                    }
                });
            }
            setCurrentBookingIsErrored(currentBookingIsErrored);
        }
    }, [currentConflict, resource, selectedAmenity]);

    useEffect(() => {
        if (selectedAmenity[0]) {
            const minutes = moment
                .duration(
                    moment(currentConflict?.bookingEnd).diff(moment(currentConflict?.bookingStart))
                )
                .asMinutes();
            const creditCost = (minutes / 60) * (selectedAmenity[0].creditPointsPerBlock ?? 0);
            setcurrentBookingCreditCost(+creditCost.toFixed(2));
        }
    }, [currentConflict, selectedAmenity]);

    const handleBookingResize = ({ formatedBookingStart, formatedBookingEnd }: any) => {
        const oldConflict = currentConflict;

        const start = formatedBookingStart
            ? formatedBookingStart
            : momentTimezone(oldConflict?.bookingStart).tz(timezone!).format();
        const bookingEnd = formatedBookingEnd
            ? formatedBookingEnd
            : momentTimezone(oldConflict?.bookingEnd).tz(timezone!).format();
        if (oldConflict !== undefined) {
            oldConflict.bookingStart = start;
            oldConflict.bookingEnd = bookingEnd;
            oldConflict.id = currentConflict?.id!;
            setCurrentConflict({ ...oldConflict });
        }
    };

    const onAmenityTimeCellClick = (e: any, bookingInformation: any, period: any): void => {
        if (bookingInformation.bookingId !== undefined) return;

        setSelectedAmenity(bookingInformation.bookingAmenity);
        setCurrentConflict({
            ...bookingInformation,
            id: originalConflict?.id,
            conferenceRoomId: bookingInformation.bookingAmenity[0].id,
        });
        setCurrentStep(ConflictResolutionStep.timeStep);
        if(modalRef.current) modalRef.current.scrollIntoView();
    };

    const onConflictClick = (): void => {
        setSelectedAmenity([resource.find((resource) => resource?.id === originalAmenityId)]);
        setCurrentStep(ConflictResolutionStep.timeStep);
        if(modalRef.current) modalRef.current.scrollIntoView();
    };

    const onBackClick = (): void => {
        setCurrentStep(ConflictResolutionStep.amenityStep);
        setCurrentConflict(originalConflict);
        setSelectedDate(moment(originalConflict?.bookingStart));
        if(modalRef.current) modalRef.current.scrollIntoView();
    };

    return (
        <Modal
            visible={visible}
            title={t('Booking.edit_booking_conflicts')}
            className="FormModal EditBookingConflictModal"
            closeIcon={<Close />}
            width={1280}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
            centered
        >
            <div ref={modalRef}>
                <Form scrollToFirstError layout="vertical" form={form} onFinish={submit}>
                    <FormStep
                        Steps={Object.keys(ConflictResolutionStep).map(
                            (step: string, i: number) => {
                                return {
                                    active: step === currentStep,
                                    stepNumber: i + 1,
                                    name: step,
                                } as StepInfo;
                            }
                        )}
                    />

                    {currentStep === ConflictResolutionStep.amenityStep ? (
                        <>
                            <div className="date-controls-container">
                                <DateControls
                                    selectedDate={selectedDate}
                                    onChange={handleDateChange}
                                    showTodayButton={false}
                                    displayForConflictResolution
                                />
                            </div>
                            <FlexibleBooking
                                view={FlexibleBookingViewType.conflictAmenities}
                                isEditing={false}
                                filters={[]}
                                min={0}
                                max={24}
                                zoom={2}
                                resizable={false}
                                resources={resource}
                                resourceHeader={generateResourceHeader(t)}
                                resourcePeriod={generateResourcePeriod(t)}
                                classNames="my-20"
                                onTimeCellClick={onAmenityTimeCellClick}
                                timezone={timezone}
                                selectedDate={selectedDate}
                                currentBooking={currentConflict}
                                currentBookingIsErrored={currentBookingIsErrored}
                                onCurrentBookingClick={onConflictClick}
                            />
                        </>
                    ) : (
                        <>
                            <div className="selected-info-container">
                                <div className="date">
                                    {selectedDate.format(DAY_MONTH_DATE_YEAR_DISPLAY_FORMAT)}
                                </div>
                                {selectedAmenity[0] && (
                                    <div className="room">
                                        {(selectedAmenity[0] as DailyBookingSummaryAmenityDto).name}
                                    </div>
                                )}
                            </div>
                            <FlexibleBooking
                                view={FlexibleBookingViewType.conflicts}
                                classNames="w-100 position-relative"
                                resizable
                                resources={selectedAmenity}
                                currentBooking={currentConflict}
                                timezone={timezone}
                                onTimeCellClick={handleTimeCellClick}
                                onBookingResize={handleBookingResize}
                                min={0}
                                max={24}
                                zoom={2}
                                isEditing={false}
                                filters={[]}
                                resourcePeriod={generateResourcePeriod(t)}
                                selectedDate={currentConflict?.bookingStart}
                                currentBookingIsErrored={currentBookingIsErrored}
                            />

                            <div className="credit-cost-container">
                                {t('total_of')}
                                <span className="cost">{currentBookingCreditCost}</span>
                                {t('credits')}
                            </div>

                            <div className="actions d-flex">
                                <Button
                                    type="default"
                                    className="secondary negative m-l-auto"
                                    htmlType="button"
                                    onClick={(): void => onBackClick()}
                                >
                                    {t('back')}
                                </Button>
                                <Button type="primary" className="positive" htmlType="submit">
                                    {t('confirm')}
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </div>
        </Modal>
    );
};

export default React.memo(EditBookingConflictModal);
