import { Button, Col, Layout, Row } from 'antd';
import Icon from 'Components/icons/icon';
import { ListSectionHeader } from 'Components/list-section-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import { useService, useStores } from 'Hooks';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import EditSageIntacctIntegrationModal, {
    EditSageIntacctIntegrationData,
} from 'Routes/authenticated/administration/integration/edit-sage-intacct-integration-modal';
import { CampusService } from 'Services/CampusService';
import { cleanVal } from 'Utils/NumberUtils';
import { displayFormatedDate } from 'Utils/TimeUtils';
import { images, theme } from 'variant';
import { CampusIntegrationStatusesColumns } from '..';
import './index.less';

const { Content } = Layout;
const { usersHeader } = images;

interface IntegrationData {
    campusName: string;
    sageData: SimpleListItemProps[];
    stripeData: SimpleListItemProps[];
    stripeIsDefaultAccount: boolean;
    editSageIntacctIntegrationData?: EditSageIntacctIntegrationData;
}

const IntegrationDetails: FunctionComponent = () => {
    const campusService = useService(CampusService);
    const { globalLoadingStore, confirmationModalStore, toastStore } = useStores();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IntegrationData>();
    const [sageIntacctEditModalOpen, setSageIntacctEditModalOpen] = useState<boolean>(false);
    const location = useLocation();
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();

    const breadcrumb: BreadcrumbSegment[] = [
        {
            nameKey: 'Administration.administration_title',
            path: 'administration',
        },

        {
            nameKey: 'integrations',
            path: 'integration',
        },
        {
            path: id,
            name: data?.campusName,
        },
    ];

    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();

        try {
            if (id) {
                let campusName: string;
                if (location.state === undefined) {
                    const campusResponse = await campusService.getCampus(id);
                    campusName = cleanVal.string(campusResponse?.name);
                } else {
                    const locationState = location.state as CampusIntegrationStatusesColumns;
                    campusName = locationState.locationName;
                }

                const sageResponse = await campusService.getCampusSageAccountingSyncConfig(id);
                const stripeResponse = await campusService.getCampusStripeConfig(id);
                const sageData: SimpleListItemProps[] = [
                    {
                        title: t('sync_with_sage'),
                        description: sageResponse.accountingSyncEnabled
                            ? t('enabled')
                            : t('disabled'),
                        className: 'sync-sage',
                    },
                ];
                const stripeData: SimpleListItemProps[] = [
                    {
                        title: t('bank_account_conf'),
                        description: stripeResponse.isDefaultAccount
                            ? t('default_account')
                            : stripeResponse.stripeConnectedAccountName
                            ? stripeResponse.stripeConnectedAccountName
                            : '',
                    },
                ];

                if (sageResponse.accountingSyncEnabled) {
                    sageData.push({
                        title: t('sage_entity'),
                        description: cleanVal.string(sageResponse.sageLocationEntityName),
                    });
                    sageData.push({
                        title: t('start_date'),
                        description: displayFormatedDate(
                            cleanVal.string(sageResponse.accountingSyncStartDate)
                        ),
                    });
                }

                const editSageIntacctIntegrationData = sageResponse.sageCompanyId
                    ? {
                          campusId: id,
                          sageCompanyId: sageResponse.sageCompanyId || '',
                          accountingSyncEnabled: sageResponse.accountingSyncEnabled || false,
                      }
                    : undefined;

                setData({
                    campusName: campusName,
                    sageData: sageData,
                    stripeData: stripeData,
                    stripeIsDefaultAccount: !!stripeResponse.isDefaultAccount,
                    editSageIntacctIntegrationData: editSageIntacctIntegrationData,
                });
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [campusService, setLoading, globalLoadingStore, toastStore]);

    const handleDefaultAccountClick = useCallback(async () => {
        try {
            if (id) {
                if (
                    !(await confirmationModalStore.confirm({
                        icon: <Icon iconName="Monitor" />,
                        title: t('stripe_use_default_account'),
                        message: t('stripe_use_default_account_question'),
                        positiveText: t('stripe_use_default_account'),
                        negativeText: t(`cancel`),
                    }))
                )
                    return;

                setLoading(true);
                globalLoadingStore.addLoading();

                await campusService.removeCampusStripeConnectConfiguration(id);
                fetch();

                toastStore.toast({
                    type: 'success',
                    messageKey: 'stripe_use_default_account_confirmation',
                });
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [confirmationModalStore, setLoading, globalLoadingStore, fetch, toastStore]);

    const handleConfigureAccountClick = useCallback(async () => {
        try {
            if (id) {
                setLoading(true);
                globalLoadingStore.addLoading();

                const stripeConnectAuthorizationUrl =
                    await campusService.getCampusStripeConnectAuthorizationUrl(id);
                window.location.href = stripeConnectAuthorizationUrl;
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [setLoading, globalLoadingStore, toastStore]);

    const handleSageIntacctEditClick = () => setSageIntacctEditModalOpen(true);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return (
        <div className="IntegrationDetails">
            <ListSectionHeader
                title={cleanVal.string(data?.campusName)}
                subTitle={t('integrations_subtitle')}
                defaultImg={<Icon iconName="Monitor" fill={theme['primary-color']} />}
                backgroundImageUrl={usersHeader}
                routes={breadcrumb}
                loading={loading}
            />

            <Content>
                <Row gutter={40}>
                    <Col span={12}>
                        <SimpleList title={t('stripe')} data={data?.stripeData} className="stripe">
                            <div className="btn-wraper">
                                <Button
                                    type="default"
                                    onClick={handleConfigureAccountClick}
                                    className="configure-btn"
                                >
                                    {t('stripe_configure_account')}
                                    <Icon iconName="ExternalLink" fill={theme['primary-color']} />
                                </Button>

                                {!data?.stripeIsDefaultAccount && (
                                    <Button type="primary" onClick={handleDefaultAccountClick}>
                                        {t('stripe_use_default_account')}
                                    </Button>
                                )}
                            </div>
                        </SimpleList>
                    </Col>

                    <Col span={12} className="editable">
                        <SimpleList
                            title={t('sage_intacct')}
                            data={data?.sageData}
                            className="large-description"
                        >
                            {!!data?.editSageIntacctIntegrationData && (
                                <Button
                                    icon={<Icon iconName="PencilBorder" />}
                                    onClick={handleSageIntacctEditClick}
                                />
                            )}
                        </SimpleList>
                    </Col>
                </Row>
            </Content>

            {sageIntacctEditModalOpen && (
                <EditSageIntacctIntegrationModal
                    visible={sageIntacctEditModalOpen}
                    onComplete={(success: boolean): void => {
                        setSageIntacctEditModalOpen(false);
                        if (success) fetch();
                    }}
                    data={data?.editSageIntacctIntegrationData}
                />
            )}
        </div>
    );
};

export default IntegrationDetails;
