// GENERATED FILE - DO NOT MODIFY
import { CreateTourRequestDto } from 'Api/Features/Tours/Dtos/CreateTourRequestDto';
import { GetToursRequestDto } from 'Api/Features/Tours/Dtos/GetToursRequestDto';
import { GetToursResponseDto } from 'Api/Features/Tours/Dtos/GetToursResponseDto';
import { TourDto } from 'Api/Features/Tours/Dtos/TourDto';
import { UpdateTourRequestDto } from 'Api/Features/Tours/Dtos/UpdateTourRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class TourProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getTour(tourId: string): Promise<TourDto | null> {
        const uri = this.buildUri(
            "/tours/{tourId}",
            { tourId: tourId },
            null
        );

        const data: TourDto | null = await this.httpClient.get<TourDto | null>(uri);
        return data;
    }

    public async getTours(request: GetToursRequestDto | null): Promise<GetToursResponseDto | null> {
        const uri = this.buildUri(
            "/tours",
            null,
            { ...request || undefined }
        );

        const data: GetToursResponseDto | null = await this.httpClient.get<GetToursResponseDto | null>(uri);
        return data;
    }

    public async createTour(request: CreateTourRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/tours",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateTourRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateTour(tourId: string, request: UpdateTourRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/tours/{tourId}",
            { tourId: tourId },
            null
        );

        await this.httpClient.patch<UpdateTourRequestDto | null, void>(uri, request);
    }

    public async deleteTour(tourId: string): Promise<void> {
        const uri = this.buildUri(
            "/tours/{tourId}",
            { tourId: tourId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}