import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PencilBorder: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.8395 17.265L4.92575 19.5269C4.559 19.6971 4.229 19.3439 4.4 19.0004L6.72375 15.147L8.8395 17.265ZM15.9293 5.4165L18.582 8.034L10.152 16.4693C9.165 15.4808 7.79775 14.1135 7.518 13.8337L15.9293 5.4165ZM18.582 2.76225C18.873 2.4705 19.3448 2.4705 19.6365 2.76225L21.2168 4.34325C21.5078 4.635 21.5078 5.10675 21.2168 5.3985L19.6365 6.9795L17.0393 4.30575L18.582 2.76225ZM6.0825 13.5518L2.85675 20.0805C2.58675 20.8155 3.17475 21.474 3.90975 21.1327L10.434 17.9055C10.7153 17.8507 10.9882 17.742 11.2057 17.5245L22.797 5.925C23.379 5.343 23.379 4.39875 22.797 3.816L20.163 1.1805C19.5803 0.59775 18.6368 0.59775 18.0555 1.1805L6.46425 12.7793C6.246 12.9975 6.13725 13.2705 6.0825 13.5518ZM22.5 9V21C22.5 21.8243 21.6855 22.509 20.8628 22.509H2.9805C2.15775 22.509 1.49025 21.8422 1.49025 21.018V3.1245C1.49025 2.30025 2.1765 1.5 3 1.5H15V0H3C1.3545 0 0 1.47675 0 3.1245V21.018C0 22.665 1.33425 24 2.9805 24H20.8628C22.509 24 24 22.6478 24 21V9H22.5Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default PencilBorder;
