import { Button, Col, Form, Input, Row } from 'antd';
import { SubscriptionDetailsDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsDto';
import { GetUnitsRequestDto } from 'Api/Features/Units/Dtos/GetUnitsRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import Icon from 'Components/icons/icon';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { Unit } from 'Models/Units/Unit';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditUnitsSchema } from 'Schemas';
import { SubscriptionService } from 'Services/SubscriptionService';
import { UnitService } from 'Services/UnitService';
import { theme } from 'variant';
import './edit-units-modal.less';

interface EditUnitsModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    subscription: SubscriptionDetailsDto;
}

const EditUnitsModal: FunctionComponent<EditUnitsModalProps> = ({
    visible,
    onComplete,
    subscription,
}) => {
    const unitService = useService(UnitService);
    const subscriptionService = useService(SubscriptionService);
    const { globalLoadingStore, toastStore } = useStores();
    const [unitsOptions, setUnitsOptions] = useState<SelectCustomOption[]>([]);
    const [unitsSearchTerm, setUnitsSearchTerm] = useState('');
    const [unitsCurrentPage, setUnitsCurrentPage] = useState(0);
    const [unitsSearchResults, setUnitsSearchResults] = useState<Unit[]>([]);
    const [unitsMaxResults, setUnitsMaxResults] = useState(false);
    const [selectedUnitIds, setSelectedUnitIds] = useState<string[] | null>([]);
    const [selectedUnitsOptions, setSelectedUnitsOptions] = useState<SelectCustomOption[]>([]);
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(EditUnitsSchema, form);
    const { t } = useTranslation();
    const [selectLoading, setSelectLoading] = useState(false);

    const pageSize = 25;

    const resetUnitsSearch = (): void => {
        setUnitsCurrentPage(0);
        setUnitsSearchResults([]);
        setUnitsSearchTerm('');
        setUnitsMaxResults(false);
    };

    const handleUnitsKeywordsChange = useCallback((value: string): void => {
        resetUnitsSearch();
        setUnitsSearchTerm(value);
    }, []);

    const handleUnitsMenuScrollToBottom = (): void => {
        if (!unitsMaxResults) {
            setUnitsCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handleUnitSelect = (values: any): void => {
        if (values === '') {
            setSelectedUnitIds([]);
        } else {
            const options = values.map((x: any) => x as SelectCustomOption);
            setSelectedUnitsOptions(options);
            setSelectedUnitIds(values?.map((x: any) => x.value));
        }
    };

    const searchUnits = async (page: number, searchTerm: string): Promise<Unit[]> => {
        const request: GetUnitsRequestDto = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
            campusIds: [subscription.campusId || ''],
            includeAvailabilities: true,
            availabilitiesStartDate: subscription.startDate,
            availabilitiesEndDate: subscription.endDate,
            types: subscription.plan?.unitType ? [subscription.plan.unitType] : undefined,
            availabilitiesIgnoredSubscriptionIds: [subscription.id!]
        };

        const [results] = await unitService.getUnits(request);

        if (results.length < pageSize) {
            setUnitsMaxResults(true);
        }
        setSelectLoading(false);
        return results;
    };

    const debounceUnitsSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchUnits(page, searchTerm).then((results) => {
                setUnitsSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );
    
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = () => {
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const request = {
            unitIds: selectedUnitIds,
            reason: formValues.reason,
        };

        if (!(await validateForm(request))) return;

        try {
            globalLoadingStore.addLoading();

            await subscriptionService.updateSubscriptionUnits(`${subscription.id}`, request);

            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    useEffect(() => {
        setSelectLoading(true);
        debounceUnitsSearch.current(unitsCurrentPage, unitsSearchTerm);
    }, [unitsSearchTerm, unitsCurrentPage]);

    useEffect(() => {
        const units: string[] = [];
        subscription.units &&
            subscription.units.forEach((unit) => {
                if (unit?.id) units.push(unit.id);
            });

        setSelectedUnitIds(units);
    }, [subscription.units]);

    useEffect(() => {
        const searchResult = unitsSearchResults?.map(
            (unit: Unit) =>
                ({
                    value: unit?.id,
                    label: unit?.name,
                    content: unit?.isAvailable ? null : t('Proposal.unit_not_available'),
                    badge: undefined,
                    isDisabled: !unit?.isAvailable,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResult, selectedUnitsOptions);

        setUnitsOptions(merged);
    }, [unitsSearchResults, selectedUnitIds, t, selectedUnitsOptions]);

    return (
        <BaseModal
            className="EditUnitsModal FormModal"
            visible={visible}
            title={t('Subscription.subscription_edit_units_title')}
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <ValidatedFormItem name="unitIds" errors={errors} label={t('units')} required>
                    <SelectCustom
                        options={unitsOptions}
                        defaultImg={<Icon iconName="Units" fill={theme['white']} />}
                        strongLabel={true}
                        placeholder={t('SelectCustom.placeholder_default')}
                        onKeywordsChange={handleUnitsKeywordsChange}
                        onMenuScrollToBottom={handleUnitsMenuScrollToBottom}
                        hideSelectedOptions={false}
                        isMulti
                        onChange={handleUnitSelect}
                        selected={selectedUnitIds}
                        isLoading={selectLoading}
                        idAttribute='unitIds'
                    />
                </ValidatedFormItem>

                <Row>
                    <Col span={24}>
                        <ValidatedFormItem
                            errors={errors}
                            name="reason"
                            label={t('Subscription.subscription_edit_units_reason')}
                        >
                            <Input.TextArea />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={exit}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" className="positive" htmlType="submit">
                        {t('Subscription.subscription_units_save')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(EditUnitsModal);
