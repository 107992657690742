import React, { FunctionComponent, ReactNode, useEffect, useState, SyntheticEvent } from 'react';
import './select-custom.less';
import Select, { components, ValueType } from 'react-select';
import { Checkbox } from 'antd';
import { theme } from '../../variant';
import { Close } from 'Components/icons';

export interface SelectCustomOption {
    value: string;
    label: string;
    content?: string;
    imageUrl?: string;
    checkBox?: boolean;
    badge?: string;
    checked?: boolean;
    isDisabled?: boolean;
    isInSortFirstMembership?: boolean;
    additionalInfoObject?: any;
}

export interface SelectCustomGroupOption {
    label: string;
    options: SelectCustomOption[];
}

export interface SelectCustomProps {
    className?: string;
    options?: SelectCustomOption[];
    onChange?: (value: any) => void | undefined;
    onKeywordsChange?: (value: string) => void | undefined;
    onMenuScrollToBottom?: (event: SyntheticEvent<HTMLElement>) => void | undefined;
    placeholder?: string | null;
    defaultImg?: ReactNode;
    strongLabel?: boolean;
    isMulti?: boolean;
    isClearable?: boolean;
    closeMenuOnSelect?: boolean;
    hideSelectedOptions?: boolean;
    selected?: string[] | null;
    groupedOptions?: SelectCustomGroupOption[];
    onChangeGetLabel?: (label: string) => void | undefined;
    defaultValue?: SelectCustomOption[];
    disabled?: boolean;
    isLoading?: boolean;
    /** Id is required to enable scroll on error. use the same as ValidatedFormItem.name*/
    idAttribute: string;
}

const SelectCustom: FunctionComponent<SelectCustomProps> = ({
    className,
    options,
    onChange,
    onKeywordsChange,
    onMenuScrollToBottom,
    placeholder,
    defaultImg,
    strongLabel = false,
    isMulti = false,
    isClearable = false,
    closeMenuOnSelect = true,
    hideSelectedOptions = true,
    selected,
    groupedOptions,
    onChangeGetLabel,
    defaultValue,
    disabled,
    isLoading,
    idAttribute: id,
}) => {
    const [value, setValue] = useState<any>();
    const [keywords, setKeywords] = useState('');

    useEffect(() => {
        if (onKeywordsChange) {
            onKeywordsChange(keywords);
        }
    }, [keywords, onKeywordsChange]);

    useEffect(() => {
        if (options === undefined) return;
        if (selected) {
            setValue(
                options?.filter((x) => {
                    return selected?.includes(x.value);
                })
            );
        } else {
            setValue(null);
        }
    }, [selected, options]);

    useEffect(() => {
        if (groupedOptions === undefined) return;
        if (selected) {
            setValue(
                groupedOptions
                    ?.map((group) => {
                        return group.options.filter((option) => {
                            return selected?.includes(option.value);
                        });
                    })
                    .flat()
            );
        } else {
            setValue(null);
        }
    }, [selected, groupedOptions]);

    const styles: any = {
        option: (provided: any) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
        control: (provided: any) => ({
            ...provided,
            borderRadius: '0px',
            border: '1px solid lightgray', // default border color
            boxShadow: 'none', // no box-shadow
            '&:hover': {
                border: '1px solid lightgray', // default border color
            },
            minHeight: '50px',
        }),
        multiValue: (styles: any) => ({
            ...styles,
            backgroundColor: 'transparent',
            fontSize: '14px',
            padding: '0px',
            margin: '0px',
        }),
        multiValueRemove: (styles: any) => ({
            ...styles,
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            padding: '0 9px 0 20px',
        }),
        menu: (styles: any) => ({
            ...styles,
            width: '100%',
        }),
        placeholder: (styles: any) => ({
            ...styles,
            fontFamily: 'Raleway',
            fontWeight: 'normal',
            letterSpacing: '0.47px',
            color: 'black',
        }),
        indicatorSeparator: (styles: any) => ({
            ...styles,
            display: 'none',
        }),
        input: (styles: any) => ({
            ...styles,
            marginLeft: '-10px',
        }),
    };

    const handleOnInputChange = (value: string): void => {
        setKeywords(value.trimStart().trimEnd());
    };

    const handleOnChange = (data: ValueType<any, false>): void => {
        if (onChange) {
            onChange(data || '');
        }
        if (onChangeGetLabel) {
            onChangeGetLabel(data?.label || '');
        }
    };

    const handleMultiOnChange = (data: ValueType<any, true>): void => {
        if (onChange) {
            onChange(data || '');
        }
    };

    const optionImage = (data: SelectCustomOption, selectProps: any) => {
        return (
            <>
                {data.imageUrl ? (
                    <img
                        style={{ flex: '0 0 auto' }}
                        className="dropdown-option-img"
                        width="24px"
                        height="24px"
                        src={data.imageUrl}
                        alt=""
                    />
                ) : (
                    <span className="dropdown-option-img">{selectProps.defaultImg}</span>
                )}
            </>
        );
    };

    const optionDetails = (data: SelectCustomOption, selectProps: any) => {
        return (
            <div>
                <div className="dropdown-option-label">
                    {selectProps.strongLabel ? <strong>{data.label}</strong> : data.label}
                </div>
                {data.content && <div className="dropdown-option-content">{data.content}</div>}
            </div>
        );
    };

    const Option = (props: any) => {
        return (
            <div>
                <components.Option {...props} className="dropdown-option-container">
                    {defaultImg && optionImage(props.data, props.selectProps)}
                    {optionDetails(props.data, props.selectProps)}
                    {props.data.badge && (
                        <div
                            className="dropdown-option-badge"
                            style={{ marginLeft: 'auto', flex: '0 0 auto' }}
                        >
                            {props.data.badge}
                        </div>
                    )}
                    {props.data.checkBox && (
                        <Checkbox
                            style={{ marginLeft: 'auto', flex: '0 0 auto' }}
                            checked={props.isSelected}
                        />
                    )}
                </components.Option>
            </div>
        );
    };

    const SingleValue = ({ data, selectProps }: any): any => {
        return (
            !selectProps.menuIsOpen && (
                <div className="dropdown-value-element">
                    {defaultImg && optionImage(data, selectProps)}
                    {optionDetails(data, selectProps)}
                </div>
            )
        );
    };

    const MultiValueContainer = ({ innerProps, ...props }: any): any => {
        innerProps.className += ' dropdown-multivalue-container';
        return <components.MultiValueContainer innerProps={innerProps} {...props} />;
    };

    const MultiValueLabel = ({ data, selectProps, innerProps, ...props }: any): any => {
        innerProps.className += ' dropdown-value-element';
        data.content = data.content ? data.content : null;
        return (
            <components.MultiValueLabel innerProps={innerProps} {...props}>
                {defaultImg && optionImage(data, selectProps)}
                {optionDetails(data, selectProps)}
            </components.MultiValueLabel>
        );
    };

    const MultiValueRemove = ({ innerProps, ...props }: any): any => {
        innerProps.className += ' dropdown-value-remove';
        return (
            <components.MultiValueRemove innerProps={innerProps} {...props}>
                <Close fill={theme['black']} />
            </components.MultiValueRemove>
        );
    };

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme['primary-5'],
        padding: '10px',
    };

    const formatGroupLabel = (data: any) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
        </div>
    );

    return (
        <>
            <Select
                components={{
                    Option,
                    SingleValue,
                    MultiValueContainer,
                    MultiValueLabel,
                    MultiValueRemove,
                }}
                className={`SelectCustom ${className || ''} ${isMulti ? 'multiple' : ''} ${
                    disabled ? 'disabled' : ''
                }`}
                styles={styles}
                options={options || groupedOptions}
                onChange={isMulti ? handleMultiOnChange : handleOnChange}
                onInputChange={handleOnInputChange}
                onMenuScrollToBottom={onMenuScrollToBottom}
                isMulti={isMulti || undefined}
                isClearable={isClearable}
                closeMenuOnSelect={closeMenuOnSelect}
                hideSelectedOptions={hideSelectedOptions}
                placeholder={placeholder}
                defaultImg={defaultImg}
                strongLabel={strongLabel}
                defaultValue={defaultValue}
                value={value}
                formatGroupLabel={formatGroupLabel}
                isDisabled={disabled}
                noOptionsMessage={(): string => (isLoading ? 'Loading...' : 'No options')}
                id={id}
                //If search term change triggers an api call, display all options returned from filtered api call. Otherwise filter based on label.
                filterOption={onKeywordsChange ? () => true : undefined}
            />
        </>
    );
};

SelectCustom.displayName = 'SelectCustom';

export default SelectCustom;
