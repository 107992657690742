import { Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import { GetBookingsReportRequestDto } from 'Api/Features/Reports/Dtos/Bookings/GetBookingsReportRequestDto';
import Icon from 'Components/icons/icon';
import { ListSectionHeader } from 'Components/list-section-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import TableActionsButtons from 'Components/table-action-buttons/table-actions-buttons';
import { TableFilters } from 'Components/table-filters';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import {
    ALL_LOCATIONS,
    ALL_ROOMS,
    MOMENT_PARSING_FORMAT,
    MOMENT_PARSING_MONTH_FORMAT,
} from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AcceptedSubscriptionsReportService } from 'Services/AcceptedSubscriptionsReportService';
import { BookingReportService } from 'Services/BookingReportService';
import { FilterStore } from 'Stores';
import { showFile } from 'Utils';
import { cleanVal } from 'Utils/NumberUtils';
import { displayFormatedDateTime } from 'Utils/TimeUtils';
import { images, theme } from 'variant';
import './index.less';

const { usersHeader } = images;

interface BookingsReportRows {
    id: string;
    conferenceRoom: string;
    dateTime: string;
    location: string;
    company: string;
    title: string;
}

const advancedFilters: AdvancedFilter[] = [];

const Bookings: FunctionComponent = observer(() => {
    const bookingReportService = useService(BookingReportService);
    const [bookingsRequest, setBookingsRequest] = useState<GetBookingsReportRequestDto>();
    const [bookingsReport, setBookingsReport] = useState<BookingsReportRows[]>();
    const acceptedSubscriptionsReportService = useService(AcceptedSubscriptionsReportService);
    const { locationStore } = useStores();
    const { globalLoadingStore, toastStore, userPermissionsStore, requestStore } = useStores();
    const [isTableEmpty, setIsTableEmpty] = useState<boolean>(true);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { t } = useTranslation();

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            nameKey: 'reports',
            path: 'reports',
        },
        {
            nameKey: 'Reports.report_bookings',
            path: 'bookings',
        },
    ];

    const onExportClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            let document;
            if (bookingsRequest) {
                const campusName =
                    locationStore?.locations.find(
                        (x) => x.id === filterStoreRef.current.currentLocationId
                    )?.name || ALL_LOCATIONS;

                document = await bookingReportService.exportBookingsReportReport(bookingsRequest);

                showFile(
                    document,
                    `${t('Reports.report_bookings')} - ${moment(bookingsRequest.startDate).format(
                        MOMENT_PARSING_FORMAT
                    )} - ${moment(bookingsRequest.endDate).format(
                        MOMENT_PARSING_FORMAT
                    )} - ${campusName}`
                );
            }
        } catch (e) {
            if (!e.treated) {
                if (e.treated) {
                    toastStore.genericError();
                }
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const tableActionButtons = [
        {
            iconName: 'Download',
            toolTip: 'export_table',
            onClick: (): Promise<void> => onExportClick(),
            disabled: isTableEmpty,
        },
    ];

    const companiesColumns: ColumnType<BookingsReportRows>[] = [
        {
            title: t('Booking.conference_room'),
            dataIndex: 'conferenceRoom',
        },
        {
            title: t('Booking.time'),
            dataIndex: 'dateTime',
        },
        {
            title: t('location'),
            dataIndex: 'location',
        },
        {
            title: t('company'),
            dataIndex: 'company',
        },
        {
            title: t('Booking.book_a_room_title'),
            dataIndex: 'title',
        },
    ];

    const fetchBookingsReport = useCallback(
        async (params: {
            startDate?: string;
            endDate?: string;
            campusIds: string[];
            amenityId: string | null;
        }) => {
            globalLoadingStore.addLoading();

            try {
                const bookingsReportResponse = await bookingReportService.getBookingsReport({
                    startDate: params.startDate,
                    endDate: params.endDate,
                    campusIds: params.campusIds,
                    amenityIds: params.amenityId !== ALL_ROOMS ? [params.amenityId] : null,
                });

                const bookingsReportRows = bookingsReportResponse.dates.flatMap(
                    (date) => date.bookings
                );

                const nonNullBookingsReportRows: BookingsReportRows[] = [];
                bookingsReportRows.forEach((row) => {
                    if (row)
                        nonNullBookingsReportRows.push({
                            id: cleanVal.string(row.id),
                            conferenceRoom: cleanVal.string(row.amenity?.name),
                            dateTime: cleanVal.string(displayFormatedDateTime(row.periodStart)),
                            location: cleanVal.string(row.campus?.name),
                            company: cleanVal.string(row.membership?.name),
                            title: cleanVal.string(row.title, '-'),
                        });
                });

                setBookingsReport(nonNullBookingsReportRows);
                setIsTableEmpty(nonNullBookingsReportRows.length === 0);
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [
            acceptedSubscriptionsReportService,
            bookingReportService,
            globalLoadingStore
        ]
    );

    const getSearchInfos = (): {
        startDate: string;
        endDate: string;
        campusId: string[];
        amenityId: string;
    } => {
        const filterStore = filterStoreRef.current;
        const startDate = moment(filterStore.startDate)
            .startOf('month')
            .format(MOMENT_PARSING_FORMAT);

        const endDate = moment(filterStore.endDate).endOf('month').format(MOMENT_PARSING_FORMAT);

        const campusId =
            filterStore.currentLocationId === 'all'
                ? requestStore.getReportRequestAvailableLocationIdsForUser()
                : [filterStore.currentLocationId];

        const amenityId = filterStore.currentRoomId;

        return { startDate, endDate, campusId, amenityId };
    };

    useEffect(() => {
        const filterStore = filterStoreRef.current;

        filterStore.month = moment().format(MOMENT_PARSING_MONTH_FORMAT);
        filterStore.startDate = moment().clone().startOf('month').format(MOMENT_PARSING_FORMAT);
        filterStore.endDate = moment().clone().endOf('month').format(MOMENT_PARSING_FORMAT);
    }, []);

    useEffect(() => {
        const disposer = autorun(() => {
            const searchInfos = getSearchInfos();

            fetchBookingsReport({
                startDate: searchInfos.startDate,
                endDate: searchInfos.endDate,
                campusIds: searchInfos.campusId,
                amenityId: searchInfos.amenityId,
            });

            setBookingsRequest({
                startDate: searchInfos.startDate,
                endDate: searchInfos.endDate,
                campusIds: searchInfos.campusId,
                amenityIds: searchInfos.amenityId !== ALL_ROOMS ? [searchInfos.amenityId] : null,
            });
        });

        return (): void => {
            disposer();
        };
    }, [fetchBookingsReport]);

    return (
        <div className="Bookings">
            <ListSectionHeader
                routes={breadcrumbs}
                backgroundImageUrl={usersHeader}
                defaultImg={<Icon iconName="Bookmark" fill={theme['primary-color']} />}
                title={t('Reports.report_bookings')}
                subTitle={t('Reports.bookings_overview')}
            />

            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeDates
                    datesPrefix={{ start: t('start'), end: t('end') }}
                    includeLocations
                    availableLocations={userPermissionsStore.availableLocationsForUser}
                    includeConferenceRooms
                />

                <TableActionsButtons buttonsList={tableActionButtons} />

                <Table
                    className="expand-table table-striped-rows table-action-rows"
                    bordered
                    columns={companiesColumns}
                    dataSource={bookingsReport}
                    rowKey={(record): string => record.id}
                    pagination={false}
                />
            </Content>
        </div>
    );
});

export default Bookings;
