import { GetClientParametersResponseDto } from 'Api/Features/System/Dtos/GetClientParametersResponseDto';
import { SystemProxy } from 'Api/Features/System/SystemProxy';
import { inject } from 'aurelia-dependency-injection';
import { cleanVal } from 'Utils/NumberUtils';
import { ApiService } from './ApiService';

export interface GetSystemParametersResponse {
    creditConversionRatio: number;
    welcomeMessage: string;
}

@inject(SystemProxy)
export class SystemService extends ApiService {
    constructor(private readonly systemProxy: SystemProxy) {
        super();
    }

    public async getApiVersionInfo(): Promise<void> {
        const data = await this.systemProxy.getApiVersionInfo();

        return data;
    }

    public async getClientParameters(): Promise<GetClientParametersResponseDto | null> {
        const data = await this.systemProxy.getClientParameters();

        return data;
    }

    public async getSystemParameters(): Promise<GetSystemParametersResponse> {
        const data = await this.systemProxy.getSystemParameters();
        const nonNullData = {
            creditConversionRatio: cleanVal.number(data?.creditConversionRatio, 250),
            welcomeMessage: cleanVal.string(data?.welcomeMessage),
        };

        return nonNullData;
    }

    public async updateSystemParameters(request: GetSystemParametersResponse): Promise<void> {
        await this.systemProxy.updateSystemParameters(request);
    }
}
