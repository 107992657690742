// GENERATED FILE - DO NOT MODIFY
import { BookingsReportDto } from 'Api/Features/Reports/Dtos/Bookings/BookingsReportDto';
import { GetBookingsReportRequestDto } from 'Api/Features/Reports/Dtos/Bookings/GetBookingsReportRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class BookingsReportProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getBookingsReport(request: GetBookingsReportRequestDto | null): Promise<BookingsReportDto | null> {
        const uri = this.buildUri(
            "/bookings-report",
            null,
            { ...request || undefined }
        );

        const data: BookingsReportDto | null = await this.httpClient.get<BookingsReportDto | null>(uri);
        return data;
    }

    public async exportBookingsReportReport(request: GetBookingsReportRequestDto | null): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/bookings-report/export",
            null,
            { ...request || undefined }
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }
}