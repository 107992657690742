import * as yup from 'yup';

export const EditDayPassTypeSchema = yup.object({
    name: yup.string().required('Errors.required'),
    displayName: yup.string().required('Errors.required'),
    description: yup.string().optional().nullable().max(4000, 'Errors.must_be_under_four_thousand'),
});

export const EditPricingDayPassTypeSchema = yup.object({
    price: yup.number().typeError('Errors.number_invalid').min(0).required('Errors.required'),
    creditsPrice: yup
        .number()
        .typeError('Errors.number_invalid')
        .min(0)
        .required('Errors.required'),
});
