import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AddOn: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    d="M22.7289 4.59751H16.4885L16.715 4.20875C16.9736 3.76421 17.1054 3.29263 17.1054 2.80752C17.1054 1.25924 15.8462 0 14.2996 0C13.5001 0 12.7344 0.353264 12.1952 0.968519L12.0008 1.19163L11.8065 0.968519C11.2673 0.353264 10.5016 0 9.70209 0C8.1555 0 6.89626 1.25924 6.89626 2.80583C6.89626 3.29263 7.0281 3.76421 7.28671 4.20706L7.5132 4.59582H1.27277C0.571308 4.59751 0 5.16881 0 5.87027V7.40334C0 8.1048 0.571308 8.6761 1.27277 8.6761H1.53138V21.1942C1.53138 22.7408 2.79062 24 4.33721 24H19.6628C21.2094 24 22.4686 22.7408 22.4686 21.1942V8.6761H22.7272C23.4287 8.6761 24 8.1048 24 7.40334V5.87027C24.0017 5.16881 23.4304 4.59751 22.7289 4.59751ZM14.2996 1.01416C15.2867 1.01416 16.0913 1.81703 16.0913 2.80583C16.0913 3.79463 15.2884 4.59751 14.2996 4.59751C13.3125 4.59751 12.5079 3.79463 12.5079 2.80583C12.5079 1.81703 13.3125 1.01416 14.2996 1.01416ZM9.70209 1.01416C10.6892 1.01416 11.4938 1.81872 11.4938 2.80583C11.4938 3.79294 10.6909 4.59751 9.70209 4.59751C8.71329 4.59751 7.91041 3.79463 7.91041 2.80583C7.91041 1.81703 8.71498 1.01416 9.70209 1.01416ZM11.4938 22.9875H4.3389C3.35178 22.9875 2.54722 22.1847 2.54722 21.1959V16.3397H11.4938V22.9875ZM11.4938 15.3256H2.54722V8.6761H11.4938V15.3256ZM21.4545 21.1959C21.4545 22.183 20.6516 22.9875 19.6628 22.9875H12.5079V16.3397H21.4545V21.1959ZM21.4545 15.3256H12.5079V8.6761H21.4545V15.3256ZM21.9615 7.66195H2.04014C1.47391 7.66195 1.01416 7.2022 1.01416 6.63765C1.01416 6.0731 1.47391 5.61166 2.04014 5.61166H21.9632C22.5295 5.61166 22.9892 6.07141 22.9892 6.63765C22.9892 7.20389 22.5278 7.66195 21.9615 7.66195Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default AddOn;
