import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import './not-found.less';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../../Assets/Images/cg/cg-logo-404.svg';

const NotFoundPage: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <div className="not-found">
            <div className="notfound-container">
                <span className="error-404">{'404'}</span>

                <h1 className="not-found">{t('Errors.page_not_found')}</h1>
                <Button type="primary" className="back-to-site">
                    <Link to="/">{t('Navigation.back_to_site')}</Link>
                </Button>
            </div>
            <Logo className="logo-container"/>
        </div>
    );
};

export default NotFoundPage;
