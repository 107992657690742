import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { AddOnCategoryProxy } from 'Api/Features/AddOnCategories/AddOnCategoryProxy';
import { GetAddOnCategoriesRequestDto } from 'Api/Features/AddOnCategories/Dtos/GetAddOnCategoriesRequestDto';
import { GetAddOnCategoriesResponseDto } from 'Api/Features/AddOnCategories/Dtos/GetAddOnCategoriesResponseDto';


@inject(AddOnCategoryProxy)
export class AddOnCategoryService extends ApiService {
    constructor(private readonly addOnCategoryProxy: AddOnCategoryProxy) {
        super();
    }

    public async getAddOnCategories(request: GetAddOnCategoriesRequestDto | null): Promise<GetAddOnCategoriesResponseDto | null> {
        const data: GetAddOnCategoriesResponseDto | null = await this.addOnCategoryProxy.getAddOnCategories(request);
        return data;
    }
}
