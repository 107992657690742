import { Button } from 'antd';
import { ColumnType } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import Table, { TablePaginationConfig } from 'antd/lib/table';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetPlansSortColumnDto } from 'Api/Features/Plans/Dtos/GetPlansSortColumnDto';
import { PlanTypeDto } from 'Api/Features/Plans/Dtos/PlanTypeDto';
import { UpdatePlanOrderRequestDto } from 'Api/Features/Plans/Dtos/UpdatePlanOrderRequestDto';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { Credit } from 'Components/icons';
import { ListSectionHeader } from 'Components/list-section-header';
import LocationHeader from 'Components/location-header/location-header';
import ReorderModal, { ReorderModel } from 'Components/reorder-modal/reorder-modal';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { TableFilters } from 'Components/table-filters';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { Plan } from 'Models/Plans/Plan';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import CreateSubscriptionPlanModal from 'Routes/authenticated/locations/modals/create-subscription-plan-modal';
import { PlanService } from 'Services/PlanService';
import { FilterStore } from 'Stores';
import { images, theme } from 'variant';
import CreateSubscriptionPlanTemplateModal from './create-subscription-plan-template-modal';
import './index.less';

const { usersHeader } = images;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 20,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const advancedFilters: AdvancedFilter[] = [];

const SubscriptionPlans: FunctionComponent = () => {
    const planService = useService(PlanService);
    const { toastStore, globalLoadingStore } = useStores();
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [data, setData] = useState<Plan[]>([]);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [reorderModalOpen, setReorderModalOpen] = useState(false);
    const [orderingMethod, setOrderingMethod] = useState<{ isManual: boolean }>();
    const [loading, setLoading] = useState(false);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const paginationRef = useRef(initialPaginationState);
    const history = useHistory();
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();

    const locationManagement = {
        isTrue: !!id,
        locationId: id,
    };

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            nameKey: locationManagement.isTrue
                ? 'management'
                : 'Administration.administration_title',
            path: locationManagement.isTrue ? `management` : 'administration',
        },
        {
            nameKey: `Administration.subscription_plans_title${
                locationManagement.isTrue ? '' : '_template'
            }`,
            path: 'subscription-plans',
        },
    ];

    const onRowClick = (plan: Plan): void => {
        history.push(`subscription-plans/${plan.id}`);
    };

    const menuOptions: ActionMenuOption[] = [
        {
            key: 'create',
            title: locationManagement.isTrue
                ? t('Administration.subscription_plans_create_plan')
                : t('Administration.subscription_plans_create_plan_template'),
            action: (): void => setCreateModalOpen(true),
        },
    ];

    let actionButton;
    if (locationManagement.isTrue) {
        menuOptions.push({
            key: 'ordering',
            title: t('change_ordering'),
            action: (): void => {
                setReorderModalOpen(true);
                fetchOrdering();
            },
        });

        actionButton = <ActionMenu options={menuOptions} type="primary" trigger="click" />;
    } else {
        actionButton = (
            <Button type="primary" onClick={menuOptions[0].action}>
                {menuOptions[0].title}
            </Button>
        );
    }

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: GetPlansSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);

            try {
                const [data, totalItems] = await planService.getPlans({
                    campusIds: locationManagement.isTrue ? [locationManagement.locationId] : null,
                    types: locationManagement.isTrue
                        ? [PlanTypeDto.Campus]
                        : [PlanTypeDto.Template],
                    pageSize: params.pagination.pageSize || 10,
                    page: (params.pagination.current || 1) - 1,
                    searchTerm: params.searchTerm,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                });

                setData(data);
                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [planService, locationManagement.isTrue, locationManagement.locationId]
    );

    const fetchOrdering = useCallback(async () => {
        try {
            globalLoadingStore.addLoading();
            let order;
            if (locationManagement.isTrue) {
                order = await planService.getCampusPlanOrdering(locationManagement.locationId);
                setOrderingMethod({ isManual: order?.method === OrderingMethodDto.Manual });
            }
        } catch (e) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, toastStore, locationManagement, planService]);

    const onReorderSubmit = async (method: OrderingMethodDto, ids: string[]): Promise<void> => {
        if (locationManagement.isTrue) {
            await planService.updateCampusPlansOrdering(locationManagement.locationId, {
                ids,
                method,
            } as UpdatePlanOrderRequestDto);
        } else {
            await planService.updateCampusPlansOrdering(null, {
                ids,
                method,
            } as UpdatePlanOrderRequestDto);
        }

        setReorderModalOpen(false);

        const filterStore = filterStoreRef.current;

        fetch({
            pagination: {
                ...paginationRef.current,
                current: 1,
            },
            sortColumn: null,
            sortDirection: null,
            searchTerm: filterStore.searchTerm,
        });

        toastStore.toast({
            type: 'success',
            messageKey: t('model_reorder_success', {
                param1: t('Administration.subscription_plans_title'),
            }),
        });
    };

    const columns: ColumnType<Plan>[] = [
        {
            title: t('name'),
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: t('type'),
            dataIndex: 'unitType',
            render: (key: string): string => t(`Unit.type_${key}`),
        },
    ];

    if (locationManagement.isTrue)
        columns.unshift({
            title: t('order'),
            dataIndex: 'order',
            render: (key: string): string => key + 1 || '1',
            sorter: true,
        });

    const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any): void => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        fetch({
            pagination,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
    };

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            fetch({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                sortColumn: null,
                sortDirection: null,
                searchTerm: filterStore.searchTerm,
            });
        });
        return (): void => {
            disposer();
        };
    }, [fetch]);

    return (
        <div className="SubscriptionPlans">
            {locationManagement.isTrue ? (
                <LocationHeader
                    title={t(
                        `Administration.subscription_plans_title${
                            locationManagement.isTrue ? '' : '_template'
                        }`
                    )}
                    subTitle={t(
                        `Administration.subscription_plans_subtitle${
                            locationManagement.isTrue ? '' : '_template'
                        }`
                    )}
                    defaultImg={<Credit fill={theme['primary-color']} />}
                    action={actionButton}
                    routes={breadcrumbs}
                />
            ) : (
                <ListSectionHeader
                    title={t(
                        `Administration.subscription_plans_title${
                            locationManagement.isTrue ? '' : '_template'
                        }`
                    )}
                    subTitle={t(
                        `Administration.subscription_plans_subtitle${
                            locationManagement.isTrue ? '' : '_template'
                        }`
                    )}
                    defaultImg={<Credit fill={theme['primary-color']} />}
                    backgroundImageUrl={usersHeader}
                    action={actionButton}
                    routes={breadcrumbs}
                />
            )}

            <Content>
                <TableFilters filterStore={filterStoreRef.current} includeSearch />

                <Table
                    className="table-striped-rows"
                    bordered
                    columns={columns}
                    rowKey={(plan: Plan): string => plan.id ?? ''}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(row: Plan) => ({
                        onClick: (): void => {
                            onRowClick(row);
                        },
                    })}
                />
            </Content>

            {reorderModalOpen && orderingMethod && (
                <ReorderModal
                    visible={reorderModalOpen}
                    dataSource={data.map(
                        (plan) =>
                            ({
                                id: plan.id,
                                name: plan.name,
                                order: plan.order,
                            } as ReorderModel)
                    )}
                    onDismiss={(): void => setReorderModalOpen(false)}
                    manual={orderingMethod?.isManual}
                    onSubmit={async (
                        orderingMethod: OrderingMethodDto,
                        ids: string[]
                    ): Promise<void> => await onReorderSubmit(orderingMethod, ids)}
                    modelName={t('Administration.subscription_plans_title')}
                />
            )}

            {!locationManagement.isTrue && createModalOpen && (
                <CreateSubscriptionPlanTemplateModal
                    visible={createModalOpen}
                    onComplete={(success: boolean, id?: string): void => {
                        setCreateModalOpen(false);
                        if (success && id) history.push(`subscription-plans/${id}`);
                    }}
                />
            )}

            {locationManagement.isTrue && createModalOpen && (
                <CreateSubscriptionPlanModal
                    visible={createModalOpen}
                    onComplete={(success: boolean, id?: string): void => {
                        setCreateModalOpen(false);
                        if (success && id) history.push(`subscription-plans/${id}`);
                    }}
                    campusId={locationManagement.locationId}
                />
            )}
        </div>
    );
};

export default SubscriptionPlans;
