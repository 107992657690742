import { Button, Col, Form, Input, Row, Select } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import Title from 'antd/lib/typography/Title';
import { UploadFile } from 'antd/lib/upload/interface';
import { CampusSurroundingDto } from 'Api/Features/CampusSurroundings/Dtos/CampusSurroundingDto';
import { CampusSurroundingTypeDto } from 'Api/Features/CampusSurroundings/Dtos/CampusSurroundingTypeDto';
import { CreateCampusSurroundingRequestDto } from 'Api/Features/CampusSurroundings/Dtos/CreateCampusSurroundingRequestDto';
import { ContactInfoDto } from 'Api/Features/General/Dtos/ContactInfoDto';
import { CreateUpdateAddressRequestDto } from 'Api/Features/General/Dtos/CreateUpdateAddressRequestDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import GlobalizationSelect from 'Components/globalization-select/globalization-select';
import { AreaOfInterestPin, ForkKnife } from 'Components/icons';
import Icon from 'Components/icons/icon';
import { ImagePicker } from 'Components/image-picker';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { ValidatedFormItem } from 'Components/validated-form-item';
import GoogleMapReact, { Coords } from 'google-map-react';
import { useFormValidation, useService, useStores } from 'Hooks';
import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router-dom';
import { EditAreaOfInterestSchema } from 'Schemas/EditAreaOfInterestSchema';
import { CampusSurroundingService } from 'Services/CampusSurroundingService';
import { CampusSurroundingTypeService } from 'Services/CampusSurroundingTypeService';
import { theme } from 'variant';
import './edit-area-of-interest-modal.less';

const formGutter: [Gutter, Gutter] = [40, 0];
const { Option } = Select;

const mapCenter: Coords = { lat: 37.2756537, lng: -104.6561154 };
const defaultMapZoom = 4;
const mapZoom = 15;

const Marker = ({ lat, lng }: Coords): ReactElement => (
    <div style={{ fontSize: '40px', margin: '-20px 0 0 -20px' }}>
        <AreaOfInterestPin fill={theme['primary-color']} />
    </div>
);

interface EditAreaOfInterestModalProps {
    visible: boolean;
    onComplete: (success?: boolean, id?: string) => void;
    areaOfInterest?: CampusSurroundingDto;
}

const EditAreaOfInterestModal: FunctionComponent<EditAreaOfInterestModalProps> = ({
    visible,
    onComplete,
    areaOfInterest,
}) => {
    const campusSurroundingService = useService(CampusSurroundingService);
    const campusSurroundingTypeService = useService(CampusSurroundingTypeService);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();

    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(EditAreaOfInterestSchema, form);
    const { id } = useParams<{ id: string }>();

    const [imageDetails, setImageDetails] = useState<ImageDetails[]>();
    const [areaTypes, setAreaTypes] = useState<CampusSurroundingTypeDto[]>([]);

    const [googleMaps, setGoogleMaps] = useState<any>();
    const [geocoder, setGeocoder] = useState<any>();
    const [center, setCenter] = useState<Coords>(mapCenter);
    const [marker, setMarker] = useState<Coords | null>();
    const [zoom, setZoom] = useState<number>(defaultMapZoom);
    const [isSeachingLocation, setIsSearchingLocation] = useState<boolean>(false);
    const { t } = useTranslation();

    const dismiss = (success?: boolean, id?: string): void => {
        onComplete(success, id);
        form.resetFields();
        resetErrors();
    };

    useEffect(() => {
        if (areaOfInterest) {
            const addressLines =
                areaOfInterest.address?.addressLine1 +
                `${
                    areaOfInterest.address?.addressLine2
                        ? ', ' + areaOfInterest.address?.addressLine2
                        : ''
                }`;
            const fullAddress =
                addressLines &&
                areaOfInterest?.address?.city &&
                areaOfInterest?.address?.stateCode &&
                areaOfInterest?.address?.postalCode &&
                areaOfInterest?.address?.country
                    ? `${addressLines}, ${areaOfInterest?.address?.city}, ${areaOfInterest?.address?.stateCode}, ${areaOfInterest?.address?.postalCode}, ${areaOfInterest?.address?.country}`
                    : undefined;

            form.setFieldsValue({
                latitude: areaOfInterest.location?.latitude ?? undefined,
                longitude: areaOfInterest.location?.longitude ?? undefined,
                title: areaOfInterest.title,
                type: areaOfInterest.type?.id ?? undefined,
                introduction: areaOfInterest.introduction ?? undefined,
                addressLine1: areaOfInterest.address?.addressLine1,
                city: areaOfInterest.address?.city,
                state: areaOfInterest.address?.stateCode,
                country: areaOfInterest.address?.country,
                postalCode: areaOfInterest.address?.postalCode,
                addressSearch: fullAddress,
                website: areaOfInterest.contactInfo?.website,
                phone1: areaOfInterest.contactInfo?.phone1,
                phone2: areaOfInterest.contactInfo?.phone2,
                email: areaOfInterest.contactInfo?.email,
            });

            if (
                googleMaps &&
                areaOfInterest.location?.latitude &&
                areaOfInterest.location.longitude
            ) {
                setMarker({
                    lat: areaOfInterest.location.latitude,
                    lng: areaOfInterest.location.longitude,
                });
                setCenter({
                    lat: areaOfInterest.location.latitude,
                    lng: areaOfInterest.location.longitude,
                });
                setZoom(mapZoom);
            }
        }
    }, [areaOfInterest, form, googleMaps]);

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <ForkKnife />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t(
                    `AreasOfInterest.area_of_interest_confirm_positive${
                        areaOfInterest !== undefined ? '_edit' : ''
                    }`
                ),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const model = {
            ...formValues,
            latitude:
                formValues.latitude && formValues.latitude !== ''
                    ? parseFloat(formValues.latitude)
                    : undefined,
            longitude:
                formValues.longitude && formValues.longitude !== ''
                    ? parseFloat(formValues.longitude)
                    : undefined,
            introduction: formValues.introduction ?? undefined,
            mainImage: imageDetails
                ? ({
                      delete: imageDetails[0].isDeleted,
                      uploadBase64: imageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null,
        };

        if (!(await validateForm(model))) return;

        const request: CreateCampusSurroundingRequestDto = {
            title: model.title,
            typeId: model.type,
            introduction: model.introduction,
            mainImage: model.mainImage,
            address: {
                addressLine1: model.addressLine1,
                city: model.city,
                country: model.country,
                postalCode: model.postalCode,
                state: model.state,
            } as CreateUpdateAddressRequestDto,
            latitude: model.latitude,
            longitude: model.longitude,
            contactInfo: {
                email: model.email,
                phone1: model.phone1,
                phone2: model.phone2,
                website: model.website,
            } as ContactInfoDto,
            campusId: id,
        };

        try {
            globalLoadingStore.addLoading();

            let createdId = '';
            if (areaOfInterest) {
                await campusSurroundingService.updateCampusSurrounding(areaOfInterest.id!, request);
            } else {
                createdId = await campusSurroundingService.createCampusSurrounding(request);
            }

            toastStore.toast({
                type: 'success',
                messageKey: `AreasOfInterest.area_of_interest_${
                    areaOfInterest ? 'edited' : 'created'
                }`,
            });
            dismiss(true, createdId);
        } catch (e) {
            if (e.response?.data?.error === 'E015001') {
                toastStore.toast({
                    type: 'error',
                    messageKey: t('Errors.error_setting_image'),
                });
            } else if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const handleIntroductionChange = (value: string): void => {
        form.setFieldsValue({ introduction: value });
    };

    const handleOnGoogleApiLoaded = ({ map, maps }: any): void => {
        setGoogleMaps(maps);
        setGeocoder(new maps.Geocoder());
    };

    const handleAddressSearch = (): void => {
        setIsSearchingLocation(true);
        const address = form.getFieldValue('addressSearch');

        geocoder.geocode({ address: address }, function (results: any, status: any) {
            if (status === googleMaps.GeocoderStatus.OK) {
                const latitude = results[0].geometry.location.lat();
                const longitude = results[0].geometry.location.lng();

                form.setFieldsValue({
                    latitude: latitude,
                    longitude: longitude,
                });

                setCenter({ lat: latitude, lng: longitude });

                setMarker({
                    lat: latitude,
                    lng: longitude,
                });

                setZoom(mapZoom);
            }
            setIsSearchingLocation(false);
        });
    };

    const handleLatLongChange = (): void => {
        const values = form.getFieldsValue();
        if (values.latitude && values.longitude) {
            const lat = parseFloat(values.latitude);
            const lng = parseFloat(values.longitude);
            setMarker({
                lat,
                lng,
            });
            setCenter({ lat, lng });
            setZoom(15);
        }
    };

    const handleUseLocationAddress = (): void => {
        const locationAddressInfo = [];
        const address = form.getFieldValue('addressLine1');
        const city = form.getFieldValue('city');
        const state = form.getFieldValue('state');
        const postalCode = form.getFieldValue('postalCode');
        const country = form.getFieldValue('country');

        if (address) {
            locationAddressInfo.push(address.trim());
        }

        if (city) {
            locationAddressInfo.push(city.trim());
        }

        if (state) {
            locationAddressInfo.push(state.trim());
        }

        if (postalCode) {
            locationAddressInfo.push(postalCode.trim());
        }

        if (country) {
            locationAddressInfo.push(country.trim());
        }

        form.setFieldsValue({ addressSearch: locationAddressInfo.join(', ') });
    };

    const fetchAreaTypes = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const data = await campusSurroundingTypeService.getCampusSurroundingTypes();
            if (data) {
                setAreaTypes(data.filter((type) => type !== null).map((type) => type!));
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, campusSurroundingService, campusSurroundingTypeService]);

    useEffect(() => {
        fetchAreaTypes();
    }, [fetchAreaTypes]);

    return (
        <BaseModal
            visible={visible}
            title={t(`AreasOfInterest.${areaOfInterest ? 'edit' : 'create'}_area_of_interest`)}
            className="EditAreaOfInterestModal FormModal"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Title level={4}>{t('basic_information')}</Title>

                <Row gutter={formGutter}>
                    <Col span={12}>
                        <ValidatedFormItem
                            name="title"
                            label={t('name')}
                            required={true}
                            errors={errors}
                        >
                            <Input />
                        </ValidatedFormItem>
                    </Col>
                    <Col span={12}>
                        <ValidatedFormItem
                            name="type"
                            label={t('type')}
                            required={true}
                            errors={errors}
                        >
                            <Select>
                                {areaTypes.map((type) => (
                                    <Option key={type.id} value={type.id ?? ''}>
                                        {type.description}
                                    </Option>
                                ))}
                            </Select>
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem
                            errors={errors}
                            name="introduction"
                            label={t('introduction')}
                            className="description"
                            required
                        >
                            <Input hidden />
                        </ValidatedFormItem>
                        <ReactQuill
                            theme="snow"
                            className="description-quill"
                            onChange={handleIntroductionChange}
                            value={
                                form.getFieldValue('introduction') ||
                                areaOfInterest?.introduction ||
                                null
                            }
                        />
                    </Col>
                </Row>

                <Title level={4}>{t('main_image')}</Title>
                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem errors={errors} name="image">
                            <ImagePicker
                                images={
                                    areaOfInterest?.imageUrl
                                        ? [
                                              {
                                                  url: areaOfInterest?.imageUrl,
                                                  uid: 'main',
                                              } as UploadFile,
                                          ]
                                        : undefined
                                }
                                setImagesDetails={(images: ImageDetails[] | undefined) =>
                                    setImageDetails(images)
                                }
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Title level={4}>{t('address')}</Title>

                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem name="addressLine1" label={t('address')} errors={errors}>
                            <Input />
                        </ValidatedFormItem>
                    </Col>

                    <Col span={6}>
                        <ValidatedFormItem name="city" label={t('city')} errors={errors}>
                            <Input />
                        </ValidatedFormItem>
                    </Col>

                    <Col span={6}>
                        <GlobalizationSelect
                            selectType="state"
                            name="state"
                            label={t('state')}
                            required={true}
                            errors={errors}
                        />
                    </Col>

                    <Col span={6}>
                        <GlobalizationSelect
                            selectType="country"
                            name="country"
                            label={t('country')}
                            initialValue="US"
                            disabled
                        />
                    </Col>

                    <Col span={6}>
                        <ValidatedFormItem name="postalCode" label={t('zip_code')} errors={errors}>
                            <Input />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Title level={4}>{t('geographical_position')}</Title>

                <Row gutter={formGutter}>
                    <Col span={12}>
                        <ValidatedFormItem
                            name="latitude"
                            label={t('latitude')}
                            errors={errors}
                            required
                        >
                            <Input onChange={(): void => handleLatLongChange()} />
                        </ValidatedFormItem>
                    </Col>

                    <Col span={12}>
                        <ValidatedFormItem
                            name="longitude"
                            label={t('longitude')}
                            errors={errors}
                            required
                        >
                            <Input onChange={(): void => handleLatLongChange()} />
                        </ValidatedFormItem>
                    </Col>

                    <Col span={24} className="addressSearch-wrapper">
                        <ValidatedFormItem name="addressSearch">
                            <Input
                                placeholder={t('Location.search_to_move_pin')}
                                prefix={<Icon iconName="Search" />}
                            />
                        </ValidatedFormItem>

                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={handleAddressSearch}
                            disabled={isSeachingLocation}
                        >
                            {t('Location.map_search')}
                        </Button>

                        <Button
                            type="primary"
                            className="positive"
                            htmlType="button"
                            onClick={handleUseLocationAddress}
                        >
                            {t('Location.map_fill_with_location')}
                        </Button>
                    </Col>
                </Row>

                <Row gutter={formGutter}>
                    <Col span={24} className="GoogleMapReact-wrapper">
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: window.Environment.REACT_APP_GOOGLE_API_KEY || '',
                            }}
                            zoom={zoom}
                            center={center}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={handleOnGoogleApiLoaded}
                        >
                            {marker && <Marker lat={marker.lat} lng={marker.lng} />}
                        </GoogleMapReact>
                    </Col>
                </Row>

                <Title level={4}>{t('contact_information')}</Title>

                <Row gutter={formGutter}>
                    <Col span={12}>
                        <ValidatedFormItem name="phone1" label={t('phone_number')} errors={errors}>
                            <Input />
                        </ValidatedFormItem>
                    </Col>

                    <Col span={12}>
                        <ValidatedFormItem
                            name="phone2"
                            label={t('secondary_phone')}
                            errors={errors}
                        >
                            <Input />
                        </ValidatedFormItem>
                    </Col>

                    <Col span={12}>
                        <ValidatedFormItem name="email" label={t('email')} errors={errors}>
                            <Input />
                        </ValidatedFormItem>
                    </Col>

                    <Col span={12}>
                        <ValidatedFormItem name="website" label={t('website')} errors={errors}>
                            <Input />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" className="positive" htmlType="submit">
                        {t('submit')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(EditAreaOfInterestModal);
