import { Button, Col, DatePicker, Form, Input, Row, Select, TimePicker } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import BaseModal from 'Components/base-modal/base-modal';
import { Lead as LeadIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ScheduleTourSchema } from 'Schemas';
import { TourService } from 'Services/TourService';
import './schedule-a-tour.less';
import { DATETIME_FORMAT, MOMENT_PARSING_FORMAT, MOMENT_TIME_FORMAT, TIME_PICKER_FORMAT } from 'Models/Constants';
import moment from 'moment';
import { CreateTourRequestDto } from 'Api/Features/Tours/Dtos/CreateTourRequestDto';
import { LightCampusDetailsInfo } from 'Models/Location/LightCampusInfo';
import { Opportunity } from 'Models/Opportunities/Opportunity';
import { OpportunityService } from 'Services/OpportunityService';
import { UpdateOpportunityRequestDto } from 'Api/Features/Opportunities/Dtos/UpdateOpportunityRequestDto';
import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';

const formGutter: [Gutter, Gutter] = [40, 0];

interface ScheduleATourModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    opportunity?: Opportunity;
}

const ScheduleATourModal: FunctionComponent<ScheduleATourModalProps> = ({
    visible,
    onComplete,
    opportunity,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(ScheduleTourSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore, locationStore } = useStores();
    const tourService = useService(TourService);
    const opportuntyService = useService(OpportunityService);
    //#endregion

    useEffect(() => {
        if(opportunity?.campus?.id)
            form.setFieldsValue({campusId: opportunity.campus.id})
    }, [opportunity, form])

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <LeadIcon />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`Lead.lead_confirm_leave_tour_schedule`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const data = {
            date: formValues.date ?? undefined,
            hours: formValues.hours ?? undefined,
            note: formValues.note,
            opportunityId: opportunity?.id,
            campusId: formValues.campusId
        }
        
        if (!(await validateForm(data))) return;

        try {
            globalLoadingStore.addLoading();
            const request: CreateTourRequestDto = {
                ...data,
                startTime: moment(
                    formValues.date.format(MOMENT_PARSING_FORMAT) +
                        formValues.hours[0].format(MOMENT_TIME_FORMAT),
                    DATETIME_FORMAT
                ).format(),
                endTime: moment(
                    formValues.date.format(MOMENT_PARSING_FORMAT) +
                        formValues.hours[1].format(MOMENT_TIME_FORMAT),
                    DATETIME_FORMAT
                ).format(),
            };
            
            await tourService.createTour(request);

            //if opportunity does not have a location, set the tours location for the opportunity 
            if(opportunity?.campus === null) {
                await opportuntyService.updateOpportunity(opportunity?.id!, {
                    ...opportunity,
                    campusId: data.campusId,
                    ownerAccountId: opportunity?.ownerAccount?.id,
                    contactId: opportunity?.contact?.id,
                    status:
                        opportunity?.status === OpportunityStatusDto.New
                            ? OpportunityStatusDto.TourScheduled
                            : opportunity?.status,
                } as UpdateOpportunityRequestDto);
            }

            toastStore.toast({
                type: 'success',
                messageKey: `Opportunity.tour_scheduled_successfully`,
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Lead.schedule_a_tour')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="ScheduleATour">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="date"
                                label={t('Lead.schedule_a_tour_date')}
                                required
                            >
                                <DatePicker
                                    className="w-100"
                                    placeholder={t('Lead.meeting_scheduled_date_placeholder')}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="hours"
                                label={t('Opportunity.tour_scheduled_time')}
                                required
                            >
                                <TimePicker.RangePicker
                                    format={TIME_PICKER_FORMAT}
                                    use12Hours
                                    style={{"width": '100%'}}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="campusId"
                                errors={errors}
                                label={t('location')}
                                required
                            >
                                <Select>
                                    {locationStore.locations?.map((x: LightCampusDetailsInfo) => (
                                        <Select.Option key={x.id!} value={x.id!}>
                                            {x.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="note"
                                label={t('Lead.lead_add_a_note')}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('Lead.schedule_a_tour_schedule')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
};

export default React.memo(ScheduleATourModal);
