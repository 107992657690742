import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ForkKnife: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    d="M9.02618 0.994931V6.47455C9.02618 8.23443 7.7246 9.69433 6.03472 9.94764V24H5.10312V9.96097C3.35657 9.75766 2 8.27276 2 6.47455V0.994931H2.92994V6.47455C2.92994 7.75779 3.8732 8.82605 5.10312 9.02104V0.911603H6.03472V9.00271C7.20797 8.75939 8.09458 7.71946 8.09458 6.47455V0.994931H9.02618Z"
                    fill={fill}
                />
                <path
                    d="M17.6989 24H16.7673L16.769 0L17.5206 0.581626C19.9988 2.49983 21.7903 7.34782 22.1986 13.2374L22.2336 13.7357H17.6989V24ZM17.6989 12.8024H21.2287C20.8337 8.10777 19.4988 4.07305 17.7006 2.03153L17.6989 12.8024Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ForkKnife;
