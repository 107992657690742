// GENERATED FILE - DO NOT MODIFY
import { ExpertiseDto } from 'Api/Features/Expertises/Dtos/ExpertiseDto';
import { GetExpertisesRequestDto } from 'Api/Features/Expertises/Dtos/GetExpertisesRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class ExpertiseProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getExpertise(expertiseId: string): Promise<ExpertiseDto | null> {
        const uri = this.buildUri(
            "/expertises/{expertiseId}",
            { expertiseId: expertiseId },
            null
        );

        const data: ExpertiseDto | null = await this.httpClient.get<ExpertiseDto | null>(uri);
        return data;
    }

    public async getExpertises(request: GetExpertisesRequestDto | null): Promise<(ExpertiseDto | null)[] | null> {
        const uri = this.buildUri(
            "/expertises",
            null,
            { ...request || undefined }
        );

        const data: (ExpertiseDto | null)[] | null = await this.httpClient.get<(ExpertiseDto | null)[] | null>(uri);
        return data;
    }
}