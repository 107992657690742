// GENERATED FILE - DO NOT MODIFY

export enum MessageEntityTypeDto {
    Booking = "Booking",
    CoffeeOrder = "CoffeeOrder",
    GuestCheckIn = "GuestCheckIn",
    Invoice = "Invoice",
    Membership = "Membership",
    Recurrence = "Recurrence",
    Subscription = "Subscription",
    Opportunity = "Opportunity",
    Lead = "Lead",
}