import { Content } from 'antd/lib/layout/layout';
import { GetSubscriptionsSortColumnDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionsSortColumnDto';
import CompanyHeader from 'Components/company-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import React, { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SubscriptionTable from 'Routes/authenticated/subscriptions/subscription-table';
import { CompanyContext } from '..';

const CompanySubscriptions: FunctionComponent = () => {
    const companyContext = useContext(CompanyContext);
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `companies`,
            nameKey: 'companies',
        },
        {
            path: `${id}/dashboard`,
            nameKey: companyContext?.name ?? '',
        },
        {
            path: `subscriptions`,
            nameKey: 'subscriptions',
        },
    ];

    return (
        <div className="CompanySubscriptions">
            <CompanyHeader
                company={companyContext}
                routes={breadcrumbs}
                headerData={{ title: t('subscriptions'), iconName: "Units", subTitle: t('Membership.subscription_section_subtitle') }}
            />

            <Content>
                <SubscriptionTable membershipId={id} defaultSortcolumn={GetSubscriptionsSortColumnDto.StartDate}/>
            </Content>
        </div>
    );
};

export default CompanySubscriptions;
