import React, { FunctionComponent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './index.less';
import { observer } from 'mobx-react';

const LocationManagement: FunctionComponent = observer(() => {
    const { id } = useParams();
    const history = useHistory();
    // TODO: remove redirect for dashboard
    history.push(`/locations/${id}/booking`);

    return <>Dashboard</>;
});

export default LocationManagement;
