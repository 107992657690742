// GENERATED FILE - DO NOT MODIFY
import { CheckInDto } from 'Api/Features/CheckIns/Dtos/CheckInDto';
import { CreateCheckInRequestDto } from 'Api/Features/CheckIns/Dtos/CreateCheckInRequestDto';
import { GetCheckInsRequestDto } from 'Api/Features/CheckIns/Dtos/GetCheckInsRequestDto';
import { GetCheckInsResponseDto } from 'Api/Features/CheckIns/Dtos/GetCheckInsResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class CheckInProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getCheckIn(checkInId: string): Promise<CheckInDto | null> {
        const uri = this.buildUri(
            "/check-ins/{checkInId}",
            { checkInId: checkInId },
            null
        );

        const data: CheckInDto | null = await this.httpClient.get<CheckInDto | null>(uri);
        return data;
    }

    public async getCheckIns(request: GetCheckInsRequestDto | null): Promise<GetCheckInsResponseDto | null> {
        const uri = this.buildUri(
            "/check-ins",
            null,
            { ...request || undefined }
        );

        const data: GetCheckInsResponseDto | null = await this.httpClient.get<GetCheckInsResponseDto | null>(uri);
        return data;
    }

    public async getCheckInSummaryDocument(checkInId: string): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/check-ins/{checkInId}/summary-document",
            { checkInId: checkInId },
            null
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }

    public async createCheckIn(request: CreateCheckInRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/check-ins",
            null,
            null
        );

        await this.httpClient.post<CreateCheckInRequestDto | null, void>(uri, request);
    }
}