import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Coffee: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8056 9.90661C13.6752 9.90661 13.5482 9.8903 13.426 9.85886C13.7253 9.19272 13.8918 8.45438 13.8918 7.67761V6.82281C14.7035 6.86823 15.3487 7.54135 15.3487 8.36354C15.3487 9.21368 14.6569 9.90661 13.8056 9.90661ZM8.55571 12.3627H6.66909C4.0849 12.3627 1.98401 10.2618 1.98401 7.67761V6.251H13.2408V7.67761C13.2408 10.2618 11.1387 12.3627 8.55571 12.3627ZM13.8918 6.17181V5.60001H1.33301V7.67761C1.33301 10.6205 3.72621 13.0137 6.66909 13.0137H8.55571C10.4854 13.0137 12.1787 11.9842 13.1162 10.4458C13.3363 10.5203 13.5669 10.5576 13.8056 10.5576C15.0156 10.5576 15.9997 9.57354 15.9997 8.36354C15.9997 7.18266 15.0622 6.21607 13.8918 6.17181Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Coffee;
