import { Button, Col, DatePicker, Divider, Form, Input, Radio, Row, Typography } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { CancelSubscriptionRequestDto } from 'Api/Features/Subscriptions/Dtos/CancelSubscriptionRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Plan as PlanIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { MOMENT_PARSING_FORMAT } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CancelSubscriptionSchema } from 'Schemas/CancelSubscriptionSchema';
import { SubscriptionService } from 'Services/SubscriptionService';
import { dateMomentToString, displayFormatedDate } from 'Utils/TimeUtils';
import './cancel-subscription-modal.less';

const formGutter: [Gutter, Gutter] = [40, 0];

enum CancellationDateRadioState {
    EndOfInterval = 'EndOfInterval',
    Date = 'Date',
}

interface SubscriptionInfos {
    id: string;
    startDate: string;
    endDate: string | null;
    isUpcoming?: boolean;
}

interface CancelSubscriptionModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    subscriptionInfos: SubscriptionInfos;
}

const CancelSubscriptionModal: FunctionComponent<CancelSubscriptionModalProps> = ({
    visible,
    onComplete,
    subscriptionInfos,
}) => {
    const subscriptionService = useService(SubscriptionService);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const [cancellationDateRadioState, setCancellationDateRadioState] = useState<
        CancellationDateRadioState
    >(CancellationDateRadioState.EndOfInterval);
    const [errors, validateForm, resetErrors] = useFormValidation(CancelSubscriptionSchema, form);
    const { t } = useTranslation();

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <PlanIcon />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`Subscription.yes_leave_this_step`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            const formValues = form.getFieldsValue();

            if (
                cancellationDateRadioState === CancellationDateRadioState.Date &&
                !(await validateForm(formValues))
            )
                return;

            const data: CancelSubscriptionRequestDto = {
                cancellationDate:
                    cancellationDateRadioState === CancellationDateRadioState.EndOfInterval
                        ? null
                        : dateMomentToString(formValues.cancellationDate),
                note: formValues.note,
            };

            await subscriptionService.cancelSubscription(subscriptionInfos.id, data);

            toastStore.toast({
                type: 'success',
                messageKey: `Subscription.subscription_cancelled`,
            });

            dismiss(true);
        } catch (e) {
            if(e.response?.data.error === 'E008023') {
                toastStore.toast({
                    type: 'error',
                    displayTime: 15,
                    message: e.response.data.error_description
                })
            }
            else if(!e.treated)
                toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <BaseModal
            visible={visible}
            title={t('Subscription.subscription_cancel_subscription_title')}
            className="CancelSubscriptionModal FormModal"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Row gutter={formGutter}>
                    <Col span={24}>
                        <Typography.Title level={4}>{t('subscription')}</Typography.Title>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        <div>{t('start_date')}</div>
                        <div>
                            {displayFormatedDate(
                                subscriptionInfos.startDate,
                                MOMENT_PARSING_FORMAT
                            )}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div>{t('end_date')}</div>
                        <div>
                            {subscriptionInfos.endDate
                                ? displayFormatedDate(
                                      subscriptionInfos.endDate,
                                      MOMENT_PARSING_FORMAT
                                  )
                                : t('mtm')}
                        </div>
                    </Col>
                </Row>

                {!subscriptionInfos.isUpcoming && (
                    <>
                        <Divider />

                        <Row gutter={formGutter}>
                            <Col span={24}>
                                <Typography.Title level={4}>
                                    {t('Subscription.subscription_cancellation_date')}
                                </Typography.Title>
                            </Col>
                        </Row>

                        <Row gutter={formGutter} className="radio-buttons">
                            <Col span={24}>
                                <Radio.Group
                                    onChange={(e): void => {
                                        form.resetFields(['date']);
                                        setCancellationDateRadioState(e.target.value);
                                    }}
                                    value={cancellationDateRadioState}
                                >
                                    <Radio value={CancellationDateRadioState.EndOfInterval}>
                                        {t(`Subscription.cancel_subscription_end_of_interval`)}
                                    </Radio>
                                    <Radio value={CancellationDateRadioState.Date}>
                                        {t(`Subscription.cancel_subscription_specified_date`)}
                                    </Radio>
                                </Radio.Group>
                            </Col>
                        </Row>

                        <Row gutter={formGutter}>
                            <Col span={24}>
                                <ValidatedFormItem name="cancellationDate" errors={errors}>
                                    <DatePicker
                                        disabledDate={(current): boolean =>
                                            current.isBefore(moment.utc()) ||
                                            current.isAfter(moment.utc(subscriptionInfos?.endDate))
                                        }
                                        disabled={
                                            cancellationDateRadioState !==
                                            CancellationDateRadioState.Date
                                        }
                                    />
                                </ValidatedFormItem>
                            </Col>
                        </Row>
                    </>
                )}

                <Divider />

                <Row gutter={formGutter}>
                    <Col span={24}>{t('Lead.lead_add_a_note')}</Col>
                </Row>

                <ValidatedFormItem name="note">
                    <Input.TextArea />
                </ValidatedFormItem>

                {!subscriptionInfos.isUpcoming && (
                    <Row gutter={formGutter}>
                        <Col span={24} className="info">
                            {t('Subscription.cancel_subscription_info')}
                        </Col>
                    </Row>
                )}

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" className="positive" htmlType="submit">
                        {t('Subscription.subscription_cancel_subscription_title')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(CancelSubscriptionModal);
