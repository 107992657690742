import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Thermometer: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 8 16" fill="none" {...props}>
                <path
                    d="M3.92498 16C2.19733 16 0.799805 14.6025 0.799805 12.8748C0.799805 11.9065 1.25097 10.9931 2.01026 10.3989V1.77166C2.01026 1.68363 2.02126 1.5956 2.04327 1.51857C2.21934 0.638239 3.00063 0 3.89196 0C4.7833 0 5.56459 0.638239 5.74066 1.51857C5.75166 1.5956 5.76267 1.67263 5.77367 1.76066V10.3439C6.57697 10.9381 7.05015 11.8624 7.05015 12.8748C7.05015 14.6025 5.64162 16 3.92498 16ZM2.67051 1.79367V10.729L2.52745 10.8281C1.8562 11.3012 1.46005 12.0605 1.46005 12.8748C1.46005 14.2393 2.56046 15.3508 3.92498 15.3508C5.27848 15.3508 6.3899 14.2393 6.3899 12.8748C6.3899 12.0385 5.97174 11.2572 5.26748 10.795L5.11342 10.696V1.78267C5.11342 1.73865 5.10242 1.69464 5.09141 1.65062C4.98137 1.0784 4.47518 0.660248 3.89196 0.660248C3.30875 0.660248 2.80256 1.0784 2.69251 1.65062C2.68151 1.69464 2.67051 1.74966 2.67051 1.79367Z"
                    fill={fill}
                />
                <path
                    d="M5.46555 12.9519C5.46555 13.8102 4.77229 14.5144 3.90297 14.5144C3.04465 14.5144 2.35139 13.8102 2.35139 12.9519C2.35139 12.2696 2.79155 11.6864 3.39678 11.4773V5.45805H4.42016V11.4773C5.03639 11.6864 5.46555 12.2696 5.46555 12.9519Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Thermometer;
