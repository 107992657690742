import { Col, Row } from 'antd';
import { UnitTypeDto } from 'Api/Features/Units/Dtos/UnitTypeDto';
import { Units } from 'Components/icons';
import { useStores } from 'Hooks';
import { Unit } from 'Models/Units/Unit';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './unit-control.less';

interface UnitControlProps {
    unit?: Unit;
    onUnassignClick: () => void;
    onRemovePolygonClick: () => void;
}

const UnitControl: FunctionComponent<UnitControlProps> = ({
    unit,
    onUnassignClick,
    onRemovePolygonClick,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { confirmationModalStore } = useStores();

    const manageUnit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Units />,
                title: t(`confirm_title`),
                message: t(`FloorPlan.confirm_leave_floor_plan_message`),
                positiveText: t('confirm_positive'),
                negativeText: t('LogOut.log_out_confirm_negative'),
            }))
        )
            return;

        history.push(`/locations/${unit?.campus?.id}/management/units/${unit?.id}`);
    };

    return (
        <div className="UnitControl">
            <Row gutter={[0, 20]}>
                <Col span={12}>
                    <div className="name">
                        {unit?.name ?? (
                            <span style={{ textTransform: 'capitalize' }}>{t('unassigned')}</span>
                        )}
                    </div>
                </Col>
                {unit && (
                    <Col span={12} className="manage-unit">
                        <div className="manage-unit-btn" onClick={() => manageUnit()}>
                            {t('FloorPlan.manage_unit')}
                        </div>
                    </Col>
                )}
            </Row>

            {unit && (
                <Row gutter={[0, 20]}>
                    <Col span={12}>
                        <div>
                            <div className="type">{t('type')}</div>
                            <div className="value">{t(`Unit.type_${unit?.type}`)}</div>
                        </div>
                    </Col>

                    <Col span={12}>
                        <div>
                            <div className="capacity">{t('Unit.capacity')}</div>
                            {unit?.type === UnitTypeDto.CommunalArea && (
                                <div className="value">
                                    {unit?.capacity}{' '}
                                    {unit.capacity && unit.capacity > 1 ? 'people' : 'person'}
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            )}

            <Row>
                <Col span={7}>
                    <div className="remove-btn" onClick={onRemovePolygonClick}>
                        {t('FloorPlan.remove_shape')}
                    </div>
                </Col>
                <Col span={10} />
                <Col span={7}>
                    {unit && (
                        <div className="unassign-btn" onClick={onUnassignClick}>
                            {t('unassign')}
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default UnitControl;