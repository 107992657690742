import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { LeadStatusDto } from 'Api/Features/Leads/Dtos/LeadStatusDto';
import { DISPLAY_DATE_FORMAT } from 'Models/Constants';
import moment, { Moment } from 'moment';
import StatusBar from 'Components/status-bar/status-bar';
import { StatusBarNavigation } from 'Components/status-bar-navigation';
import { StatusBarNavigationProps } from 'Components/status-bar-navigation/status-bar-navigation';

interface LeadDetailStatusbarProps {
    leadStatus: LeadStatusDto;
    leadStatusActivityDate?: Moment;
    leadUpcomingActivityDate?: Moment;
    setTouchBaseModalOpen: (arg0: boolean) => void;
    setMoveToEngagedModalOpen: (arg0: boolean) => void;
    setConvertToOpportunityModalOpen: (arg0: boolean) => void;
}

const LeadDetailStatusbar: FunctionComponent<LeadDetailStatusbarProps> = ({
    leadStatus,
    leadStatusActivityDate,
    leadUpcomingActivityDate,
    setTouchBaseModalOpen,
    setMoveToEngagedModalOpen,
    setConvertToOpportunityModalOpen,
}) => {
    const { t } = useTranslation();
    let currentStep = 1;
    const stepsToDisplay = 3;

    const stepTextList = Object.keys(LeadStatusDto)
        .filter((value, index) => {
            currentStep = leadStatus === value ? index + 1 : currentStep;

            return index < stepsToDisplay && value;
        })
        .map((item) => t(`Lead.status_${item}`));

    const statusBarNavigationData: StatusBarNavigationProps = {
        title: t(`Lead.status_info_${leadStatus}`, {
            param1: moment(
                leadStatus === LeadStatusDto.TouchBase
                    ? leadUpcomingActivityDate
                    : leadStatusActivityDate
            ).format(DISPLAY_DATE_FORMAT),
        }),
    };

    switch (leadStatus) {
        case LeadStatusDto.New:
            statusBarNavigationData.buttonsList = [
                {
                    buttonLabel: t(`Lead.status_${LeadStatusDto.TouchBase}`),
                    buttonOnClick: (): void => {
                        setTouchBaseModalOpen(true);
                    },
                },
                {
                    buttonLabel: t(`Lead.move_to_engaged`),
                    buttonOnClick: (): void => {
                        setMoveToEngagedModalOpen(true);
                    },
                },
            ];
            break;
        case LeadStatusDto.TouchBase:
            statusBarNavigationData.buttonsList = [
                {
                    buttonLabel: t(`Lead.move_to_engaged`),
                    buttonOnClick: (): void => {
                        setMoveToEngagedModalOpen(true);
                    },
                },
            ];
            break;
        case LeadStatusDto.Engaged:
            statusBarNavigationData.buttonsList = [
                {
                    buttonLabel: t('Lead.convert_to_opportunity'),
                    buttonOnClick: (): void => {
                        setConvertToOpportunityModalOpen(true);
                    },
                },
            ];
            break;
    }

    return (
        <>
            {leadStatus !== LeadStatusDto.Lost && (
                <StatusBar
                    step={currentStep}
                    stepTextList={stepTextList}
                    currentStepSubTitle={t(
                        `${
                            leadStatus === LeadStatusDto.New
                                ? 'Lead.status_added_on'
                                : 'Lead.status_since'
                        }`,
                        {
                            param1: moment(leadStatusActivityDate).format(DISPLAY_DATE_FORMAT),
                        }
                    )}
                />
            )}

            <StatusBarNavigation
                title={statusBarNavigationData.title}
                buttonsList={statusBarNavigationData.buttonsList}
            />
        </>
    );
};

export default LeadDetailStatusbar;
