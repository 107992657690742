import { observable, action } from 'mobx';
import { ActivitySource } from 'Models/ActivityBoard/ActivitySource';
import {
    ALL_ROLES,
    ALL_LOCATIONS,
    ALL_ASSIGNED_MANAGERS,
    ALL_ROOMS,
    MY_LEADS,
    MY_OPPORTUNITIES,
} from '../Models/Constants';
import { AdvancedFilter } from '../Models/Filters/AdvancedFilter';

interface FilterStoreArgs {
    advancedFilters: AdvancedFilter[];
}

class FilterStore {
    @observable currentLocationId = ALL_LOCATIONS;
    @observable currentRoomId = ALL_ROOMS;
    @observable advancedFilters?: AdvancedFilter[];
    @observable searchTerm = '';
    @observable stateCodes = [''];
    @observable locationStatuses = [''];
    @observable month = '';
    @observable year = '';
    @observable date = '';
    @observable startDate = '';
    @observable endDate = '';
    @observable currentLeadOwnerId = MY_LEADS;
    @observable currentOpportunityOwnerId = MY_OPPORTUNITIES;
    @observable currentRoleId = ALL_ROLES;
    @observable currentManagerAssignmentId = ALL_ASSIGNED_MANAGERS;
    @observable currentActivitysource = ActivitySource.AllSources;
    @observable dateRange = '';

    constructor({ advancedFilters }: FilterStoreArgs) {
        this.advancedFilters = advancedFilters;
    }

    @action
    updateLocation(locationId: string): void {
        this.currentLocationId = locationId;
    }

    @action
    updateRoom(roomId: string): void {
        this.currentRoomId = roomId;
    }

    @action
    toggleAdvancedFilter(key: string, parentKey: string): void {
        const filters = this.advancedFilters?.slice();
        const parent = filters?.find((filter) => filter.key === parentKey);
        const filterItem = parent?.items.find((item) => item.key === key);
        if (filterItem) {
            filterItem.checked = !filterItem.checked;
        }
        this.advancedFilters = filters;
    }

    @action
    tickMultipleAdvancedFilter(
        keysList: {
            key: string[];
            parentKey: string;
        }[]
    ): void {
        const filters = this.advancedFilters?.slice();

        keysList.forEach((list) => {
            const parent = filters?.find((filter) => filter.key === list.parentKey);

            if (list.key.length > 0) {
                parent?.items.forEach((item) => {
                    item.checked = list.key.includes(item.key);
                });
            }
        });

        this.advancedFilters = filters;
    }

    @action
    toggleSection(key: string): void {
        const filters = this.advancedFilters?.slice();
        const section = filters?.find((filter) => filter.key === key);
        if (section?.items.some((item) => item.checked)) {
            section.items.forEach((item) => (item.checked = false));
        } else if (section) {
            section.items.forEach((item) => (item.checked = true));
        }
        this.advancedFilters = filters;
    }

    @action
    updateSearchTerm(term: string): void {
        this.searchTerm = term;
    }

    @action
    updateStateCodes(term: string[]): void {
        this.stateCodes = term;
    }

    @action
    updateLocationStatuses(term: string[]): void {
        this.locationStatuses = term;
    }

    @action
    updateMonth(date: string): void {
        this.month = date;
    }

    @action
    updateYear(date: string): void {
        this.year = date;
    }

    @action
    updateDate(date: string): void {
        this.date = date;
    }

    @action
    updateStartDate(date: string): void {
        this.startDate = date;
    }

    @action
    updateEndDate(date: string): void {
        this.endDate = date;
    }

    @action
    updateDateRange(range: string): void {
        this.dateRange = range;
    }

    @action
    updateLeadOwner(leadOwnerId: string): void {
        this.currentLeadOwnerId = leadOwnerId;
    }

    @action
    updateOpportunityOwner(opportunityOwnerId: string): void {
        this.currentOpportunityOwnerId = opportunityOwnerId;
    }

    @action
    updateRole(roleId: string): void {
        this.currentRoleId = roleId;
    }

    @action
    updateManagerAssignment(managerId: string): void {
        this.currentManagerAssignmentId = managerId;
    }

    @action
    updateActivitySource(source: ActivitySource): void {
        this.currentActivitysource = source;
    }

    @action
    clear(): void {
        this.searchTerm = '';
        this.stateCodes = [''];
        this.locationStatuses = [''];
        this.currentLocationId = ALL_LOCATIONS;
        this.currentRoomId = ALL_ROOMS;
        this.month = '';
        this.date = '';
        this.startDate = '';
        this.endDate = '';
        this.currentActivitysource = ActivitySource.AllSources;
        this.advancedFilters = this.advancedFilters?.map((filter) => ({
            ...filter,
            items: filter.items.map((item) => ({ ...item, checked: true })),
        }));
    }
}

export default FilterStore;
