import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const TickLined: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.9 0.800003L6.17925 11.4148L1.75834 7.34785L0 9.25923L6.5656 15.299L16.0001 2.32756L13.9 0.800003ZM14.0249 1.5924L15.2075 2.45259L6.48111 14.4505L0.801675 9.22563L1.79177 8.14948L6.26373 12.2634L14.0249 1.5924Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default TickLined;
