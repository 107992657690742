import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { CommunityEventProxy } from 'Api/Features/CommunityEvents/CommunityEventProxy';
import { CommunityEventDto } from 'Api/Features/CommunityEvents/Dtos/CommunityEventDto';
import { CreateCommunityEventRequestDto } from 'Api/Features/CommunityEvents/Dtos/CreateCommunityEventRequestDto';
import { GetCommunityEventsRequestDto } from 'Api/Features/CommunityEvents/Dtos/GetCommunityEventsRequestDto';
import { GetCommunityEventsResponseDto } from 'Api/Features/CommunityEvents/Dtos/GetCommunityEventsResponseDto';
import { UpdateCommunityEventRequestDto } from 'Api/Features/CommunityEvents/Dtos/UpdateCommunityEventRequestDto';


@inject(CommunityEventProxy)
export class CommunityEventService extends ApiService {
    constructor(private readonly communityEventProxy: CommunityEventProxy) {
        super();
    }

    public async getCommunityEvent(communityEventId: string): Promise<CommunityEventDto | null> {
        const data: CommunityEventDto | null = await this.communityEventProxy.getCommunityEvent(communityEventId);
        return data;
    }

    public async getCommunityEvents(request: GetCommunityEventsRequestDto | null): Promise<[CommunityEventDto[], number]> {
        const data: GetCommunityEventsResponseDto | null = await this.communityEventProxy.getCommunityEvents(request);
        return [
            data?.items?.filter((event) => event !== null).map((event) => event!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async createCommunityEvent(request: CreateCommunityEventRequestDto | null): Promise<string> {
        const data: string = await this.communityEventProxy.createCommunityEvent(request);
        return data;
    }

    public async updateCommunityEvent(communityEventId: string, request: UpdateCommunityEventRequestDto | null): Promise<void> {
        await this.communityEventProxy.updateCommunityEvent(communityEventId, request);
    }

    public async deleteCommunityEvent(communityEventId: string): Promise<void> {
        await this.communityEventProxy.deleteCommunityEvent(communityEventId);
    }
}
