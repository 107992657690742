import { observable, action, computed } from 'mobx';
import { inject } from 'aurelia-dependency-injection';
import { AmenityService } from 'Services/AmenityService';
import { AmenityDto } from 'Api/Features/Amenities/Dtos/AmenityDto';
import LoadingStore from 'Stores/LoadingStore';

@inject(AmenityService, LoadingStore)
class AmenityStore {
    constructor(
        private readonly amenityService: AmenityService,
        private readonly loadingStore: LoadingStore
    ) {}

    @observable
    private _amenities: AmenityDto[] = [];

    @computed
    get amenities(): AmenityDto[] {
        return this._amenities;
    }

    @action
    async setAmenitiesForLocations(id: string): Promise<void> {
        this.loadingStore.addLoading();
        try {
            const amenities = await this.amenityService.getAmenitiesforLocation(id);
            this._amenities = amenities;
        } finally {
            this.loadingStore.removeLoading();
        }
    }
}

export default AmenityStore;
