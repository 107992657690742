import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Refresh: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 14" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 6.13223L14.1845 8.05001L12.3689 6.13223H14.0301C13.6378 3.16446 11.0935 0.866679 8.02013 0.866679C5.83335 0.866679 3.80791 2.05223 2.73346 3.9589L2.15235 3.63223C3.34457 1.51557 5.59224 0.200012 8.02013 0.200012C11.4601 0.200012 14.3068 2.79557 14.7012 6.13223H16Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.7279 10.4956C12.4879 12.4767 10.3546 13.6589 8.02011 13.6589C4.75 13.6589 2.01778 11.3156 1.41456 8.22001H0L1.81567 6.30223L3.63122 8.22001H2.09567C2.68789 10.9445 5.119 12.9922 8.02011 12.9922C10.1223 12.9922 12.0456 11.9267 13.1623 10.1422L13.7279 10.4956Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Refresh;
