import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Add: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.325 0v11.329H0v1.349h11.325V24h1.35V12.678H24v-1.349H12.675V0h-1.35z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Add;
