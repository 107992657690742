import { AccountFileProxy } from 'Api/Features/Accounts/AccountFileProxy';
import { CreateAccountFileRequestDto } from 'Api/Features/Accounts/Dtos/CreateAccountFileRequestDto';
import { GetAccountFilesRequestDto } from 'Api/Features/Accounts/Dtos/GetAccountFilesRequestDto';
import { GetAccountFilesResponseDto } from 'Api/Features/Accounts/Dtos/GetAccountFilesResponseDto';
import { StoredFileDto } from 'Api/Features/General/Dtos/StoredFileDto';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(AccountFileProxy)
export class AccountFileService extends ApiService {
    constructor(private readonly accountFileProxy: AccountFileProxy) {
        super();
    }

    public async getAccountFiles(
        accountId: string | null,
        request: GetAccountFilesRequestDto | null
    ): Promise<[StoredFileDto[], number]> {
        const data: GetAccountFilesResponseDto | null = await this.accountFileProxy.getAccountFiles(
            accountId,
            request
        );
        return [
            data?.items?.filter((file) => file !== null).map((file) => file!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async createAccountFile(
        accountId: string | null,
        request: CreateAccountFileRequestDto | null
    ): Promise<string> {
        const data: string = await this.accountFileProxy.createAccountFile(accountId, request);
        return data;
    }

    public async deleteAccountFile(accountId: string | null, fileId: string): Promise<void> {
        await this.accountFileProxy.deleteAccountFile(accountId, fileId);
    }
}
