// GENERATED FILE - DO NOT MODIFY
import { CreateAccountCreditTransactionRequestDto } from 'Api/Features/Credits/Dtos/CreateAccountCreditTransactionRequestDto';
import { CreateMembershipCreditTransactionRequestDto } from 'Api/Features/Credits/Dtos/CreateMembershipCreditTransactionRequestDto';
import { CreditBalanceDetailsDto } from 'Api/Features/Credits/Dtos/CreditBalanceDetailsDto';
import { GetAccountAvailableCreditBalancesRequestDto } from 'Api/Features/Credits/Dtos/GetAccountAvailableCreditBalancesRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class CreditProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getAccountAvailableCreditBalances(accountId: string | null, request: GetAccountAvailableCreditBalancesRequestDto | null): Promise<(CreditBalanceDetailsDto | null)[] | null> {
        const uri = this.buildUri(
            "/accounts/{accountId}/credit-balances",
            { accountId: accountId },
            { ...request || undefined }
        );

        const data: (CreditBalanceDetailsDto | null)[] | null = await this.httpClient.get<(CreditBalanceDetailsDto | null)[] | null>(uri);
        return data;
    }

    public async getCurrentUserAvailableCreditBalances(campusId?: string | null, amenityId?: string | null, canDebit?: boolean, canCredit?: boolean): Promise<(CreditBalanceDetailsDto | null)[] | null> {
        const uri = this.buildUri(
            "/credits/balances",
            null,
            { campusId: campusId, amenityId: amenityId, canDebit: (canDebit === null || canDebit === undefined) ? canDebit : canDebit.toString(), canCredit: (canCredit === null || canCredit === undefined) ? canCredit : canCredit.toString() }
        );

        const data: (CreditBalanceDetailsDto | null)[] | null = await this.httpClient.get<(CreditBalanceDetailsDto | null)[] | null>(uri);
        return data;
    }

    public async getAvailableCreditBalancesObsolete(accountId: string | null, campusId?: string | null, amenityId?: string | null, canDebit?: boolean, canCredit?: boolean): Promise<(CreditBalanceDetailsDto | null)[] | null> {
        const uri = this.buildUri(
            "/accounts/{accountId}/credits/balances",
            { accountId: accountId },
            { campusId: campusId, amenityId: amenityId, canDebit: (canDebit === null || canDebit === undefined) ? canDebit : canDebit.toString(), canCredit: (canCredit === null || canCredit === undefined) ? canCredit : canCredit.toString() }
        );

        const data: (CreditBalanceDetailsDto | null)[] | null = await this.httpClient.get<(CreditBalanceDetailsDto | null)[] | null>(uri);
        return data;
    }

    public async createAccountCreditTransaction(accountId: string | null, request: CreateAccountCreditTransactionRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/accounts/{accountId}/credit-transactions",
            { accountId: accountId },
            null
        );

        await this.httpClient.post<CreateAccountCreditTransactionRequestDto | null, void>(uri, request);
    }

    public async getMembershipCreditBalance(membershipId: string | null): Promise<CreditBalanceDetailsDto | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/credit-balance",
            { membershipId: membershipId },
            null
        );

        const data: CreditBalanceDetailsDto | null = await this.httpClient.get<CreditBalanceDetailsDto | null>(uri);
        return data;
    }

    public async createMembershipCreditTransaction(membershipId: string | null, request: CreateMembershipCreditTransactionRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/credit-transactions",
            { membershipId: membershipId },
            null
        );

        await this.httpClient.post<CreateMembershipCreditTransactionRequestDto | null, void>(uri, request);
    }

    public async getMaxBookableDate(creditBalanceId: string | null, campusId: string | null): Promise<string | null> {
        const uri = this.buildUri(
            "/credits/balances/{creditBalanceId}/enddate",
            { creditBalanceId: creditBalanceId },
            { campusId: campusId }
        );

        const data: string | null = await this.httpClient.get<string | null>(uri);
        return data;
    }
}