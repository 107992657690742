import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PrivateRoom: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <circle cx={8} cy={8} r={8} fill="#fff" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 15.2A7.2 7.2 0 108 .8a7.2 7.2 0 000 14.4zm.476-7.147a1.77 1.77 0 10-.953 0l-1.021 3.402h2.996L8.476 8.053z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default PrivateRoom;
