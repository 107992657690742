import { Button, Table } from 'antd';
import { ColumnType, TablePaginationConfig } from 'antd/es/table';
import { Content } from 'antd/lib/layout/layout';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetInvoicesSortColumnDto } from 'Api/Features/Invoices/Dtos/GetInvoicesSortColumnDto';
import { GetMemberInvoicesRequestDto } from 'Api/Features/Invoices/Dtos/GetMemberInvoicesRequestDto';
import { InvoiceStatusDto } from 'Api/Features/Invoices/Dtos/InvoiceStatusDto';
import { PaymentMethodHolderTypeDto } from 'Api/Features/PaymentMethods/Dtos/PaymentMethodHolderTypeDto';
import ContactsHeader from 'Components/contacts-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { TableFilters } from 'Components/table-filters';
import { getInvoiceStatusTag } from 'Components/tag/tag';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import { MOMENT_PARSING_FORMAT, TABLE_DEBOUNCE_DELAY } from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import moment from 'moment';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { InvoiceService } from 'Services/InvoiceService';
import { FilterStore } from 'Stores';
import { RequestType } from 'Stores/RequestStore';
import { cleanVal, currencyFormatter } from 'Utils/NumberUtils';
import { displayFormatedDate } from 'Utils/TimeUtils';
import { ContactContext } from '..';
import './index.less';
import CreateManualInvoiceModal from './modals/create-manual-invoice-modal';

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 20,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const renderLastPayment = (lastPayment?: string | null): string =>
    lastPayment ? displayFormatedDate(lastPayment, MOMENT_PARSING_FORMAT) : 'N/A';

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'statuses',
        nameKey: 'Statuses',
        items: [
            {
                key: InvoiceStatusDto.Pending,
                displayNameKey: `User.contact_profile_InvoiceStatusDto_${InvoiceStatusDto.Pending}`,
                checked: true,
            },
            {
                key: InvoiceStatusDto.AwaitingPayment,
                displayNameKey: `User.contact_profile_InvoiceStatusDto_${InvoiceStatusDto.AwaitingPayment}`,
                checked: true,
            },
            {
                key: InvoiceStatusDto.Void,
                displayNameKey: `User.contact_profile_InvoiceStatusDto_${InvoiceStatusDto.Void}`,
                checked: true,
            },
            {
                key: InvoiceStatusDto.Failed,
                displayNameKey: `User.contact_profile_InvoiceStatusDto_${InvoiceStatusDto.Failed}`,
                checked: true,
            },
            {
                key: InvoiceStatusDto.Success,
                displayNameKey: `User.contact_profile_InvoiceStatusDto_${InvoiceStatusDto.Success}`,
                checked: true,
            },
            {
                key: InvoiceStatusDto.Refunded,
                displayNameKey: `User.contact_profile_InvoiceStatusDto_${InvoiceStatusDto.Refunded}`,
                checked: true,
            },
        ],
    },
];

interface Invoices {
    id: string;
    periodStart: string | null | undefined;
    periodEnd: string | null | undefined;
    invoiceNumber: string;
    description: string;
    amountDue: string;
    total: ReactNode;
    due: ReactNode;
    lastPayment: string;
}

const Invoices: FunctionComponent = () => {
    const invoiceService = useService(InvoiceService);
    const [invoices, setInvoices] = useState<Invoices[]>([]);
    const [loading, setLoading] = useState(false);
    const [createManualInvoiceModalIsOpen, setCreateManualInvoiceModalIsOpen] = useState(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const paginationRef = useRef(initialPaginationState);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const { toastStore, requestStore } = useStores();
    const contactContext = useContext(ContactContext);
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const [requestSetFromCache, setRequestSetFromCache] = useState(false);

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `contacts`,
            nameKey: 'User.contacts_label',
        },
        {
            path: `${id}/dashboard`,
            nameKey: `${cleanVal.string(contactContext?.firstName)}  ${cleanVal.string(
                contactContext?.lastName
            )}`,
        },
        {
            path: `invoices`,
            nameKey: 'Contact.contacts_invoices_label',
        },
    ];

    const actionButton = (
        <Button
            type="primary"
            onClick={(): void => {
                setCreateManualInvoiceModalIsOpen(true);
            }}
        >
            {t('Contact.contacts_invoices_create_manual_invoice')}
        </Button>
    );

    const CreateManualInvoiceModalComplete = (success: boolean): void => {
        setCreateManualInvoiceModalIsOpen(false);
        if (success) {
            const { searchTerm, startDate, endDate } = filterStoreRef.current;

            fetchInvoices({
                searchTerm: searchTerm,
                startDate: startDate,
                endDate: endDate,
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                sortColumn: null,
                sortDirection: null,
                statuses: getAdvancedFilterStatuses().checkedStatuses,
            });
        }
    };

    const onRowClick = (invoice: Invoices): void => {
        history.push(`invoices/${invoice.id}`);
    };

    const columns: ColumnType<Invoices>[] = [
        {
            key: GetInvoicesSortColumnDto.IssuedDate,
            title: t('billing_period'),
            render: (invoice: Invoices) =>
                `${moment(invoice.periodStart).format(MOMENT_PARSING_FORMAT)}${
                    invoice.periodEnd !== invoice.periodStart
                        ? ` - ${moment(invoice.periodEnd).format(MOMENT_PARSING_FORMAT)}`
                        : ''
                }`,
            sorter: true,
        },
        {
            key: GetInvoicesSortColumnDto.Number,
            title: t('Contact.contacts_invoices_invoice_number'),
            dataIndex: 'invoiceNumber',
            sorter: true,
        },
        {
            key: GetInvoicesSortColumnDto.Description,
            title: t('description'),
            dataIndex: 'description',
            sorter: true,
        },
        {
            key: GetInvoicesSortColumnDto.AmountDue,
            title: t('Contact.contacts_invoices_amount_due'),
            dataIndex: 'amountDue',
            align: 'right',
            sorter: true,
        },
        {
            key: GetInvoicesSortColumnDto.Total,
            title: t('total'),
            dataIndex: 'total',
            align: 'right',
            sorter: true,
        },
        {
            key: GetInvoicesSortColumnDto.DueDate,
            title: t('Contact.contacts_invoices_due'),
            dataIndex: 'due',
            sorter: true,
        },
        {
            key: GetInvoicesSortColumnDto.LastPaymentDate,
            title: t('Contact.contacts_invoices_last_payment'),
            dataIndex: 'lastPayment',
            sorter: true,
        },
        {
            key: GetInvoicesSortColumnDto.Status,
            title: t('status'),
            dataIndex: 'status',
            sorter: true,
        },
    ];

    const getAdvancedFilterStatuses = (): {
        checkedStatuses: InvoiceStatusDto[];
        statusesCount: number;
    } => {
        const filterStore = filterStoreRef.current;
        const statuses = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'statuses'
        );
        const checkedStatuses = statuses?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key;
            });

        return {
            checkedStatuses:
                checkedStatuses?.map((x) => InvoiceStatusDto[x as keyof typeof InvoiceStatusDto]) ??
                [],
            statusesCount: statuses?.items.length ?? 0,
        };
    };

    const headerDetails = {
        iconName: 'Bookmark',
        title: t('Contact.contacts_invoices_label'),
        subTitle: t('Contact.contacts_invoices_label_subtitle'),
        breadCrumbMemberName: `${cleanVal.string(contactContext?.firstName)}  ${cleanVal.string(
            contactContext?.lastName
        )}`,
    };

    const fetchInvoices = useCallback(
        async (params: {
            searchTerm?: string;
            startDate: string;
            endDate: string;
            pagination: TablePaginationConfig;
            sortColumn: GetInvoicesSortColumnDto | null;
            sortDirection: SortDirection | null;
            statuses?: InvoiceStatusDto[];
        }) => {
            setLoading(true);

            try {
                const noFilters =
                    !params?.statuses || (params?.statuses && params.statuses.length < 1);
                if (noFilters) {
                    setInvoices([]);
                } else {
                    const request: GetMemberInvoicesRequestDto = requestStore.setRequest({
                        request: {
                            minPeriodStart: params.startDate,
                            maxPeriodEnd: params.endDate,
                            statuses: params.statuses,
                            searchTerm: params.searchTerm,
                            sortColumn: params.sortColumn,
                            sortDirection: params.sortDirection,
                            page: (params.pagination.current || 1) - 1,
                            pageSize: params.pagination.pageSize || 10,
                        },
                        requestType: RequestType.Invoice,
                    });

                    const response = await invoiceService.getMemberInvoices(id, request);

                    const responseMapping: Invoices[] = response.items.map((invoice) => ({
                        id: cleanVal.string(invoice.id),
                        periodStart: invoice.periodStart,
                        periodEnd: invoice.periodEnd,
                        invoiceNumber: cleanVal.string(invoice.number),
                        description: cleanVal.string(invoice.description),
                        amountDue: currencyFormatter(invoice.amountDue || 0),
                        total: currencyFormatter(invoice.total || 0),
                        due: displayFormatedDate(invoice.dueDate, MOMENT_PARSING_FORMAT),
                        lastPayment: renderLastPayment(invoice.datePayment),
                        status: getInvoiceStatusTag(invoice, t),
                    }));

                    setInvoices(responseMapping);
                    setPagination({
                        ...params.pagination,
                        total: responseMapping.length,
                    });
                }
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [setLoading, invoiceService, t]
    );

    const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any): void => {
        const { searchTerm, startDate, endDate } = filterStoreRef.current;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        fetchInvoices({
            searchTerm: searchTerm,
            startDate: startDate,
            endDate: endDate,
            pagination: pagination,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
            statuses: getAdvancedFilterStatuses().checkedStatuses,
        });
    };

    const debounceSearch = useRef(
        debounce(
            (params: {
                searchTerm?: string;
                startDate: string;
                endDate: string;
                statuses?: InvoiceStatusDto[];
            }) => {
                fetchInvoices({
                    searchTerm: params.searchTerm,
                    startDate: params.startDate,
                    endDate: params.endDate,
                    pagination: {
                        ...paginationRef.current,
                        current: 1,
                    },
                    sortColumn: null,
                    sortDirection: null,
                    statuses: params.statuses,
                });
            },
            TABLE_DEBOUNCE_DELAY
        )
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;

            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                startDate: filterStoreRef.current.startDate,
                endDate: filterStoreRef.current.endDate,
                statuses: getAdvancedFilterStatuses().checkedStatuses,
            });
        });

        return (): void => {
            disposer();
        };
    }, [fetchInvoices]);

    useEffect(() => {
        if (
            requestStore?.requestInfo?.request &&
            requestStore?.requestInfo?.requestType === RequestType.Invoice &&
            !requestSetFromCache
        ) {
            setRequestSetFromCache(true);
            const filterStore = filterStoreRef.current;
            const requestFromStore: GetMemberInvoicesRequestDto = requestStore.requestInfo.request;
            const filtersList = [{ key: requestFromStore?.statuses || [], parentKey: 'statuses' }];

            if (requestStore.requestInfo.request.minPeriodStart) {
                filterStore.updateStartDate(requestStore.requestInfo.request.minPeriodStart);
            }
            if (requestStore.requestInfo.request.maxPeriodEnd) {
                filterStore.updateEndDate(requestStore.requestInfo.request.maxPeriodEnd);
            }

            filterStore.updateSearchTerm(cleanVal.string(requestFromStore.searchTerm));
            filterStore.tickMultipleAdvancedFilter(filtersList);
        }
    }, [requestStore]);

    return (
        <div className="Invoices">
            <ContactsHeader
                routes={breadcrumbs}
                headerData={headerDetails}
                loading={loading}
                action={actionButton}
            />

            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeDates
                    datesPrefix={{
                        start: t('Contact.contacts_invoices_from'),
                        end: t('Contact.contacts_invoices_to'),
                    }}
                    includeSearch
                    includeAdvancedFilters
                />

                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(row: Invoices): string => row.id}
                    dataSource={invoices}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(row: Invoices) => ({
                        onClick: (): void => {
                            onRowClick(row);
                        },
                    })}
                />
            </Content>

            {createManualInvoiceModalIsOpen && (
                <CreateManualInvoiceModal
                    visible={createManualInvoiceModalIsOpen}
                    entityId={id}
                    paymentMethodHolderType={PaymentMethodHolderTypeDto.Personal}
                    onComplete={CreateManualInvoiceModalComplete}
                />
            )}
        </div>
    );
};

export default Invoices;
