import { CampusFloorPlanFloorDto } from 'Api/Features/FloorPlans/Dtos/CampusFloorPlanFloorDto';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';

import svgPanZoom from 'svg-pan-zoom';
import { FloorPlanUnitObject, FloorPlanViewType } from './floor-plan';
import FloorPlanUnit from './floor-plan-unit';

interface FloorPlanSvgProps {
    floor?: CampusFloorPlanFloorDto | null;
    units?: FloorPlanUnitObject[];
    unitIds?: string[];
    className: string;
    membershipImage?: string;
    svgInstanceZoom?: number;
    viewType: FloorPlanViewType
}



const FloorPlanSvg: FunctionComponent<FloorPlanSvgProps> = ({
    floor,
    units,
    unitIds,
    className,
    membershipImage,
    svgInstanceZoom = 2.25,
    viewType
}) => {
    const [svgInstance, setSvgInstance] = useState<SvgPanZoom.Instance | null>(null);
    const [selectedSvgElementId, setSelectedSvgElementId] = useState<string>();
    const svgRef = useRef<any>();
    const svgRefWrap = useRef<any>();

    //#region event handlers
    const generateSVG = useCallback(
        async (base64SVG: string): Promise<void> => {
            if (svgInstance) {
                svgInstance.destroy();
            }

            const decodedSVG = atob(base64SVG);

            svgRef.current.innerHTML = decodedSVG;

            setSvgInstance(
                svgPanZoom(svgRefWrap.current, {
                    controlIconsEnabled: true,
                    mouseWheelZoomEnabled: false,
                    zoomScaleSensitivity: 0.4,
                })
            );
        },
        [svgInstance]
    );

    const handleClick = (unit: any): void => {
        setSelectedSvgElementId(unit.svgElementId);
    };
    //#endregion event handlers

    useEffect(() => {
        if (floor?.svg) {
            generateSVG(floor.svg);
            setSelectedSvgElementId(undefined);
        }
    }, [floor /*generateSVG*/]); //this dep makes the lib crash.. not sure why yet

    // if unitId is provided center to this unit
    useEffect(() => {
        if (svgInstance && unitIds && units) {
            const unit = units.find((unit: FloorPlanUnitObject) =>
                unitIds.find((id) => unit.id === id)
            );
            if (unit) {
                svgInstance.zoom(svgInstanceZoom);
                const realZoom = svgInstance.getSizes().realZoom;
                svgInstance.pan({
                    x:
                        -(unit.poleOfInaccessibility.x * realZoom) +
                        svgInstance.getSizes().width / 2,
                    y:
                        -(unit.poleOfInaccessibility.y * realZoom) +
                        svgInstance.getSizes().height / 2,
                });
            }
        }
    }, [svgInstance, unitIds, units]);

    return (
        <div className={`FloorPlanSvg floorplan-svg-container w-100 ${className}`}>
            <div className="w-100">
                <svg className="w-100 h-100" ref={svgRefWrap}>
                    <svg className="w-100 h-100 overflow-visible" ref={svgRef} />
                    {/* We need to keep this svg outside the condition/map. Without it, svg-pan-zoom crashes when recreating an instance */}
                    <svg style={{ overflow: 'visible' }}>
                        {units &&
                            units.map((unit: FloorPlanUnitObject) => {
                                const isSelected = unit.svgElementId === selectedSvgElementId;
                                const isSubscriptionUnit = unitIds?.includes(unit.id);
                                return (
                                    <FloorPlanUnit
                                        className={`${isSelected ? 'selected' : 'unselected'} ${
                                            isSubscriptionUnit ||
                                            viewType === FloorPlanViewType.Location ||
                                            viewType === FloorPlanViewType.HeatMap
                                                ? 'inSubscription'
                                                : ''
                                        }`}
                                        key={unit.svgElementId}
                                        unit={unit}
                                        onClick={handleClick}
                                        isSelected={isSelected}
                                        membershipImage={membershipImage}
                                        unitIsInProposal={isSubscriptionUnit}
                                        viewType={viewType}
                                    />
                                );
                            })}
                    </svg>
                </svg>
            </div>
        </div>
    );
};

export default FloorPlanSvg;
