// GENERATED FILE - DO NOT MODIFY

export enum MembershipRoleDto {
    Administrator = "Administrator",
    CreditManager = "CreditManager",
    FinanceManager = "FinanceManager",
    Member = "Member",
    Owner = "Owner",
    PendingMember = "PendingMember",
    JobManager = "JobManager",
    DayPassConsumer = "DayPassConsumer",
}