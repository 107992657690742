import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Layout, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { autorun } from 'mobx';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useService, useStores } from 'Hooks';
import { FilterStore } from 'Stores';
import { UnitService } from 'Services/UnitService';
import LocationHeader from 'Components/location-header/location-header';
import { Units as UnitIcon } from 'Components/icons';
import { theme } from 'variant';
import { TableFilters } from 'Components/table-filters';
import { Unit } from 'Models/Units/Unit';
import { UnitTypeDto } from 'Api/Features/Units/Dtos/UnitTypeDto';
import { cleanVal, currencyFormatter } from 'Utils/NumberUtils';
import { DEFAULT_TABLE_PAGE_SIZE, TABLE_DEBOUNCE_DELAY } from 'Models/Constants';
import { Tag } from 'Components/tag';
import { UnitActiveStatusDto } from 'Api/Features/Units/Dtos/UnitActiveStatusDto';
import { TagColors } from 'Components/tag/tag';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { ActionMenu } from 'Components/action-menu';
import CreateUnitModal from './create-unit';
import ReorderModal, { ReorderModel } from 'Components/reorder-modal/reorder-modal';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';
import ImportUnitsModal from './../../../modals/import-units-modal';
import { GetUnitsRequestDto } from 'Api/Features/Units/Dtos/GetUnitsRequestDto';
import debounce from 'lodash.debounce';
import { RequestType } from 'Stores/RequestStore';
import { ColumnType } from 'antd/es/table';
import { GetUnitsSortColumnDto } from 'Api/Features/Units/Dtos/GetUnitsSortColumnDto';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';

const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'type',
        nameKey: 'Type',
        items: [
            {
                key: UnitTypeDto.CommunalArea,
                displayNameKey: `Unit.type_${UnitTypeDto.CommunalArea}`,
                checked: true,
            },
            {
                key: UnitTypeDto.EnclosedOffice,
                displayNameKey: `Unit.type_${UnitTypeDto.EnclosedOffice}`,
                checked: true,
            },
            {
                key: UnitTypeDto.Enterprise,
                displayNameKey: `Unit.type_${UnitTypeDto.Enterprise}`,
                checked: true,
            },
            {
                key: UnitTypeDto.LiveWork,
                displayNameKey: `Unit.type_${UnitTypeDto.LiveWork}`,
                checked: true,
            },
            {
                key: UnitTypeDto.PersonalDesk,
                displayNameKey: `Unit.type_${UnitTypeDto.PersonalDesk}`,
                checked: true,
            },
        ],
    },
    {
        key: 'status',
        nameKey: 'Status',
        items: [
            {
                key: UnitActiveStatusDto.Active,
                displayNameKey: `Unit.unit_status_${UnitActiveStatusDto.Active}`,
                checked: true,
            },
            {
                key: UnitActiveStatusDto.Inactive,
                displayNameKey: `Unit.unit_status_${UnitActiveStatusDto.Inactive}`,
                checked: true,
            },
        ],
    },
];

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'management',
        nameKey: 'Location.management_label',
    },
    {
        path: 'units',
        nameKey: 'units',
    },
];

const LocationUnits: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const unitService = useService(UnitService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const paginationRef = useRef(initialPaginationState);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [data, setData] = useState<Unit[] | undefined>();
    const { requestStore, userPermissionsStore, toastStore, globalLoadingStore } = useStores();
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [reorderModalOpen, setReorderModalOpen] = useState<boolean>(false);
    const [importUnitModalOpen, setImportUnitModalOpen] = useState<boolean>(false);
    const [orderingMethod, setOrderingMethod] = useState<{ isManual: boolean }>();
    const [requestSetFromCache, setRequestSetFromCache] = useState(false);

    const actions: ActionMenuOption[] = [
        {
            key: 'create',
            title: t('Unit.create_unit'),
            action: (): void => setCreateModalOpen(true),
        },
        {
            key: 'reorder',
            title: t('reorder_model', { param1: 'units' }),
            action: (): void => {
                setReorderModalOpen(true);
                fetchOrderingMethod();
            },
        },
        {
            key: 'importUnits',
            title: t('Unit.import_units'),
            action: (): void => setImportUnitModalOpen(true),
        },
    ];

    const renderStatus = (status: UnitActiveStatusDto): ReactNode => {
        return (
            <Tag
                key={status}
                label={t(`Unit.unit_status_${status}`)}
                color={
                    status === UnitActiveStatusDto.Active ? TagColors.active : TagColors.disabled
                }
            />
        );
    };

    const columns: ColumnType<Unit>[] = [
        {
            title: t('Location.conference_room_order'),
            dataIndex: 'order',
            render: (key: string): string => key + 1 || '1',
            key: GetUnitsSortColumnDto.Order,
            sorter: true,
        },
        {
            title: t('name'),
            dataIndex: 'name',
            render: (key: string): string => key,
            key: GetUnitsSortColumnDto.Name,
            sorter: true,
        },
        {
            title: t('type'),
            dataIndex: 'type',
            render: (key: string): string => t(`Unit.type_${key}`),
            key: GetUnitsSortColumnDto.Type,
            sorter: true,
        },
        {
            title: t('capacity'),
            dataIndex: 'capacity',
            render: (key: string): string => key ?? '',
            key: GetUnitsSortColumnDto.Capacity,
            sorter: true,
        },
        {
            title: t('Unit.market_price'),
            dataIndex: 'marketPrice',
            render: (key: string): string => (key ? currencyFormatter(key) : ''),
            align: 'right',
        },
        {
            title: t('price_override'),
            dataIndex: 'priceOverride',
            render: (key: string): string => (key ? currencyFormatter(key) : ''),
            key: GetUnitsSortColumnDto.PriceOverride,
            sorter: true,
            align: 'right',
        },
        {
            title: t('Unit.data_jacks'),
            dataIndex: 'dataJacks',
            render: (key: string[]): string => key.join(', '),
            key: GetUnitsSortColumnDto.DataJacks,
            sorter: true,
        },
        {
            title: t('status'),
            dataIndex: 'status',
            render: renderStatus,
        },
    ];

    const getAdvancedFilterUnitTypes = (): {
        checkedPrivacyTypes: UnitTypeDto[];
        typeCount: number;
    } => {
        const filterStore = filterStoreRef.current;

        const unitTypes = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'type'
        );
        const checkedUnitTypes = unitTypes?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key;
            });
        return {
            checkedPrivacyTypes:
                checkedUnitTypes?.map((x) => UnitTypeDto[x as keyof typeof UnitTypeDto]) ?? [],
            typeCount: unitTypes?.items.length ?? 0,
        };
    };

    const getAdvancedFilterUnitStatuses = (): {
        checkedStatuses: UnitActiveStatusDto[];
        statusCount: number;
    } => {
        const filterStore = filterStoreRef.current;

        const unitTypes = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'status'
        );
        const checkedUnitTypes = unitTypes?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key;
            });
        return {
            checkedStatuses:
                checkedUnitTypes?.map(
                    (x) => UnitActiveStatusDto[x as keyof typeof UnitActiveStatusDto]
                ) ?? [],
            statusCount: unitTypes?.items.length ?? 0,
        };
    };

    const fetch = useCallback(
        async (params: {
            searchTerm?: string;
            pagination: TablePaginationConfig;
            advancedFilters?: AdvancedFilter[];
            sortColumn: GetUnitsSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);
            try {
                const unitTypes = getAdvancedFilterUnitTypes();
                const unitStatuses = getAdvancedFilterUnitStatuses();
                if (
                    unitTypes.checkedPrivacyTypes.length === 0 ||
                    unitStatuses.checkedStatuses.length === 0
                ) {
                    setData([]);
                } else {
                    const request: GetUnitsRequestDto = requestStore.setRequest({
                        request: {
                            campusIds: [id],
                            searchTerm: params.searchTerm,
                            types:
                                unitTypes.checkedPrivacyTypes.length === unitTypes.typeCount
                                    ? []
                                    : unitTypes.checkedPrivacyTypes,
                            status:
                                unitStatuses.checkedStatuses.length === unitStatuses.statusCount
                                    ? undefined
                                    : unitStatuses.checkedStatuses[0],
                            pageSize: paginationRef.current.pageSize,
                            page: (params.pagination.current || 1) - 1,
                            sortColumn: params.sortColumn,
                            sortDirection: params.sortDirection,
                        },
                        requestType: RequestType.Unit,
                    });
                    const [results, totalItemCount] = await unitService.getUnits(request);

                    setData(results);
                    setPagination({
                        ...params.pagination,
                        total: totalItemCount,
                    });
                }
            } finally {
                setLoading(false);
            }
        },
        [unitService, id, requestStore]
    );

    const debounceSearch = useRef(
        debounce((params: { searchTerm?: string; advancedFilters?: AdvancedFilter[] }) => {
            fetch({
                searchTerm: params.searchTerm,
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                advancedFilters: params.advancedFilters,
                sortColumn: null,
                sortDirection: null,
            });
        }, TABLE_DEBOUNCE_DELAY)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                advancedFilters: filterStore.advancedFilters,
            });
        });
        return (): void => {
            disposer();
        };
    }, [fetch]);

    useEffect(() => {
        if (
            requestStore?.requestInfo?.request &&
            requestStore?.requestInfo?.requestType === RequestType.Unit &&
            !requestSetFromCache
        ) {
            setRequestSetFromCache(true);
            const filterStore = filterStoreRef.current;
            const requestFromStore: GetUnitsRequestDto = requestStore.requestInfo.request;

            const statusKey = !requestFromStore.status
                ? [UnitActiveStatusDto.Active, UnitActiveStatusDto.Inactive]
                : [requestFromStore.status];

            const filtersList = [
                { key: statusKey, parentKey: 'status' },
                { key: requestFromStore.types || [], parentKey: 'type' },
            ];

            filterStore.updateSearchTerm(cleanVal.string(requestFromStore.searchTerm));
            filterStore.tickMultipleAdvancedFilter(filtersList);
        }
    }, [requestStore, userPermissionsStore.userInfo]);

    const fetchOrderingMethod = useCallback(async () => {
        try {
            globalLoadingStore.addLoading();
            const order = await unitService.getUnitOrdering(id);
            setOrderingMethod({ isManual: order?.method === OrderingMethodDto.Manual });
        } catch (e) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [unitService, globalLoadingStore, id, toastStore]);

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        const { advancedFilters, searchTerm } = filterStoreRef.current;
        paginationRef.current = pagination;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }
        await fetch({
            pagination,
            searchTerm,
            advancedFilters,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
    };

    const onReorderComplete = (): void => {
        const filterStore = filterStoreRef.current;
        fetch({
            pagination: { ...paginationRef.current, current: 1 },
            searchTerm: filterStore.searchTerm,
            advancedFilters: filterStore.advancedFilters,
            sortColumn: null,
            sortDirection: null,
        });
        setReorderModalOpen(false);
        setOrderingMethod(undefined);
        toastStore.toast({
            type: 'success',
            messageKey: t('model_reorder_success', { param1: 'units' }),
        });
    };

    const onReorderSubmit = async (
        orderingMethod: OrderingMethodDto,
        ids: string[]
    ): Promise<void> => {
        await unitService.updateUnitOrdering(id, { method: orderingMethod, ids });
        onReorderComplete();
    };

    return (
        <div className="Units">
            <LocationHeader
                title={t('units')}
                subTitle={t('Location.management_section_units_subtitle')}
                defaultImg={<UnitIcon fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={<ActionMenu options={actions} type="primary" trigger="click" />}
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeAdvancedFilters
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(record: Unit): string => record.id || ''}
                    dataSource={data}
                    loading={loading}
                    pagination={pagination}
                    onChange={handleTableChange}
                    onRow={(row: Unit) => ({
                        onClick: (): void => {
                            history.push(`/locations/${id}/management/units/${row.id}`);
                        },
                    })}
                />
            </Content>

            {createModalOpen && (
                <CreateUnitModal
                    visible={createModalOpen}
                    onComplete={(success: boolean): void => {
                        setCreateModalOpen(false);
                        if (success) {
                            const filterStore = filterStoreRef.current;
                            fetch({
                                pagination: { ...paginationRef.current, current: 1 },
                                searchTerm: filterStore.searchTerm,
                                advancedFilters: filterStore.advancedFilters,
                                sortColumn: null,
                                sortDirection: null,
                            });
                        }
                    }}
                    locationId={id}
                />
            )}

            {reorderModalOpen && orderingMethod && (
                <ReorderModal
                    visible={reorderModalOpen}
                    dataSource={
                        data
                            ? data.map(
                                  (amenity) =>
                                      ({
                                          id: amenity.id,
                                          name: amenity.name,
                                          order: amenity.order,
                                      } as ReorderModel)
                              )
                            : []
                    }
                    onDismiss={(): void => {
                        setReorderModalOpen(false);
                        setOrderingMethod(undefined);
                    }}
                    manual={orderingMethod.isManual}
                    onSubmit={async (
                        orderingMethod: OrderingMethodDto,
                        ids: string[]
                    ): Promise<void> => await onReorderSubmit(orderingMethod, ids)}
                    modelName={'units'}
                />
            )}

            {importUnitModalOpen && (
                <ImportUnitsModal
                    visible={importUnitModalOpen}
                    onComplete={(success: boolean): void => {
                        setImportUnitModalOpen(false);
                        if (success) {
                            const filterStore = filterStoreRef.current;
                            fetch({
                                pagination: { ...paginationRef.current, current: 1 },
                                searchTerm: filterStore.searchTerm,
                                advancedFilters: filterStore.advancedFilters,
                                sortColumn: null,
                                sortDirection: null,
                            });
                        }
                    }}
                    campusId={id}
                />
            )}
        </div>
    );
};

export default LocationUnits;
