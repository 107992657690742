import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ScreenSharing: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.9 13.155a.955.955 0 01-.956.956H1.477a.955.955 0 01-.956-.956V6.727c0-.528.428-.955.956-.955h11.467c.527 0 .955.427.955.955v6.428zM3.821 17.273h6.776v-.521H3.822v.521zM12.961 5.25H1.494C.68 5.25 0 5.895 0 6.71v6.428c0 .814.68 1.494 1.494 1.494H6.95v1.599H3.3v1.563h7.818v-1.563H7.471v-1.599h5.49c.814 0 1.46-.68 1.46-1.494V6.71c0-.815-.646-1.46-1.46-1.46z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.041 12.563h10.316V7.306H2.04v5.255zm-.434.433H12.79V6.872H1.607v6.124z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.583 1.667v3.972h.556V2.222h13.306v8.288h-5.282v.572h2.228v.557h-2.228v.556h2.782v-1.131H20V1.667H5.583z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ScreenSharing;
