import { Button, Col, Divider, Form, Input, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { MembershipRoleDto } from 'Api/Features/Memberships/Dtos/MembershipRoleDto';
import { ParseMemberImportationFileRequestDto } from 'Api/Features/Memberships/Dtos/ParseMemberImportationFileRequestDto';
import { ParseMemberImportationFileResponseDto } from 'Api/Features/Memberships/Dtos/ParseMemberImportationFileResponseDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Delete, User, Plan as PlanIcon } from 'Components/icons';
import Icon from 'Components/icons/icon';
import InputFile from 'Components/input-file/input-file';
import InviteMemberFormItem from 'Components/invite-member/invite-member-form-item';
import SelectCustom, { SelectCustomOption } from 'Components/select-custom/select-custom';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useService, useStores } from 'Hooks';
import { MemberImportationFileItem } from 'Models/Memberships/MemberImportationFileItem';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MembershipService } from 'Services/MembershipService';
import { theme } from 'variant';
import './invite-members.less';
import ImportMemberTipModal from './import-members-tip-modal';
import ImportMembersAlreadyInCompanyModal from './import-members-already-in-company';
import { ImportMembersRequestDto } from 'Api/Features/Memberships/Dtos/ImportMembersRequestDto';
import { ImportMembersRequestMemberDto } from 'Api/Features/Memberships/Dtos/ImportMembersRequestMemberDto';
import { ImportMembersPreviewItemDto } from 'Api/Features/Memberships/Dtos/ImportMembersPreviewItemDto';
import { UpdateSubscriptionOnboardingStatusRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionOnboardingStatusRequestDto';
import { GetSubscriptionOnboardingStatusResponseDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionOnboardingStatusResponseDto';
import { ONBOARDING_CATEGORY_ID_MEMBER_INVITATION } from 'Models/Constants';
import { SubscriptionService } from 'Services/SubscriptionService';

const formGutter: [Gutter, Gutter] = [40, 0];

export enum InviteMemberContext {
    Company = 'Company',
    SubscriptionOnboarding = 'SubscriptionOnboarding'
}

interface InviteMembersModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    membershipId: string;
    onboardingStatus?: GetSubscriptionOnboardingStatusResponseDto;
    subscriptionId?: string;
    inviteMemberContext: InviteMemberContext;
}

const InviteMembersModal: FunctionComponent<InviteMembersModalProps> = ({
    visible,
    onComplete,
    membershipId,
    onboardingStatus,
    subscriptionId,
    inviteMemberContext
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const membershipService = useService(MembershipService);
    const subscriptionService = useService(SubscriptionService);
    const [fileBase64, setFileBase64] = useState<string | ArrayBuffer | null>();
    const [parsedFileValue, setParsedFileValue] = useState<
        (MemberImportationFileItem | null)[] | null | undefined
    >();
    const [tipVisible, setTipVisible] = useState(false);
    const [membersAlreadyInMembershipVisible, setMembersAlreadyInMembershipVisible] = useState(
        false
    );
    const [memberAlreadyInMembershipResponse, setMemberAlreadyInMembershipResponse] = useState<
        ImportMembersPreviewItemDto[]
    >();
    const [hasSeenPreview, setHasSeenPreview] = useState(false);
    const [userRemovedLine, setUserRemovedLine] = useState(false);
    const [triggerAdditionalRender, setTriggerAdditionalRender] = useState(false);
    //#endregion

    const roleOptions = (): SelectCustomOption[] => {
        const options = Object.keys(MembershipRoleDto)
            .filter((role) => role !== MembershipRoleDto.PendingMember)
            .filter(role => role !== MembershipRoleDto.Owner)
            .map(
                (role) =>
                    ({
                        label: t(`Membership.member_role_${role}`),
                        value: role,
                    } as SelectCustomOption)
            );
        return options;
    };

    const submitImportationFile = useCallback(
        async (fileBase64: string | ArrayBuffer): Promise<void> => {
            try {
                globalLoadingStore.addLoading();

                const parsed: ParseMemberImportationFileResponseDto | null = await membershipService.parseMemberImportationFile(
                    membershipId,
                    {
                        file: { uploadBase64: fileBase64 } as UpdateFileRequestDto,
                    } as ParseMemberImportationFileRequestDto
                );
                setParsedFileValue(
                    parsed?.items?.map(
                        (item) =>
                            ({
                                ...item,
                                roles: item?.roles && item?.roles.length > 0 ? item?.roles : [MembershipRoleDto.Member],
                                isDeleted: false,
                            } as MemberImportationFileItem)
                    )
                );
            } catch (e) {
                if (e.response?.data?.error_description) {
                    toastStore.toast({
                        type: 'error',
                        messageKey: e.response?.data?.error_description,
                    });
                } else if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [globalLoadingStore, toastStore, membershipId, membershipService]
    );

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
    };

    const updateOnboardingStatus = async (wontDo: boolean): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            const updateModel: UpdateSubscriptionOnboardingStatusRequestDto = {
                categories: [...onboardingStatus?.categories!],
            };
            const memberInviteIndex = updateModel.categories?.findIndex(
                (category) =>
                    category?.onboardingCategoryId === ONBOARDING_CATEGORY_ID_MEMBER_INVITATION
            );

            updateModel.categories![memberInviteIndex!] = {
                ...updateModel.categories![memberInviteIndex!],
                wontDo: wontDo,
                isCompleted: true,
            };
            if(subscriptionId) {
                await subscriptionService.updateSubscriptionOnboardingStatus(
                    subscriptionId,
                    updateModel
                );
                toastStore.toast({
                    type: 'success',
                    messageKey: `Subscription.onboarding_step_updated`,
                });
            }
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const wontDo = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <PlanIcon />,
                title: t(`Subscription.are_you_sure_wont_do_step`),
                message: t(`action_cannot_be_undone`),
                positiveText: t(`Subscription.yes_wont_do`),
                negativeText: t(`Subscription.no_keep_step`),
            }))
        )
            return;

        await updateOnboardingStatus(true);
    };

    const fetchFileTemplate = useCallback(async () => {
        globalLoadingStore.addLoading();

        try {
            const template = await membershipService.getMemberImportationFileTemplate();

            if (template !== null) {
                const byteArray = new Uint8Array(template);
                window.open(
                    URL.createObjectURL(
                        new Blob([byteArray], {
                            type:
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        })
                    ),
                    '_blank'
                );
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [membershipService, globalLoadingStore]);

    //Set the parsed values from the file to the dom
    useEffect(() => {
        if (parsedFileValue) {
            const parsedFromFile = parsedFileValue
                ?.filter((item) => !item?.isDeleted)
                .map((item, index) => {
                    const field = {} as any;
                    field[`imported_${index}_firstName`] =
                        item?.memberAccount || item?.memberIsInvitedToMembership
                            ? item.memberAccount?.firstName
                            : item?.firstName;
                    field[`imported_${index}_lastName`] =
                        item?.memberAccount || item?.memberIsInvitedToMembership
                            ? item.memberAccount?.lastName
                            : item?.lastName;
                    field[`imported_${index}_email`] = item?.email;
                    field[`imported_${index}_role`] =
                        item?.memberAccount || item?.memberIsInvitedToMembership
                            ? item.memberAccount?.roles ?? item?.roles
                            : item?.roles;
                    return field;
                });
            if (parsedFromFile.length > 0) {
                const fieldValues = parsedFromFile.reduce((result, current) => {
                    for (const key in current) {
                        result[key] = current[key];
                    }
                    return result;
                });
                form.setFieldsValue(fieldValues);
            }
        }
    }, [parsedFileValue, form]);

    useEffect(() => {
        if (fileBase64) {
            submitImportationFile(fileBase64);
            setFileBase64(undefined);
        }
    }, [fileBase64, submitImportationFile]);

    const getBase64 = (e: any): void => {
        if (e.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(e.files[0]);
            reader.onload = (): void => {
                const str = reader.result as string;
                const result = str.substring(str.lastIndexOf(',') + 1);
                setFileBase64(result);
            };
            reader.onerror = (error): void => {
                console.log('Error: ', error);
            };
        }
    };

    const handleFileSelect = (e: any): void => {
        if (e) {
            getBase64(e);
            e.value = null;
        }
    };

    const getSubmitModelFromForm = (): ImportMembersRequestDto => {
        const formValues = form.getFieldsValue();

        //get parsed from file members
        let parsedMembers: ImportMembersRequestMemberDto[] = [];
        if (parsedFileValue) {
            //get members who have not been deleted from form. The only value that could of been changed in form is the roles.
            parsedMembers = parsedFileValue
                .filter((member) => !member?.isDeleted)
                .map((member, index) => ({
                    ...member,
                    roles:
                        formValues[`imported_${index}_role`].length === 0
                            ? [MembershipRoleDto.Member]
                            : formValues[`imported_${index}_role`],
                }));
        }

        //get form existing contacts
        let existingMembers: ImportMembersRequestMemberDto[] = [];
        existingMembers = formValues['invited-members']
            ?.filter((member: any) => member.id !== undefined)
            .map((member: any) => ({
                email: member.id.content,
                firstName: member.id.additionalInfoObject.firstName,
                lastName: member.id.additionalInfoObject.lastName,
                roles: member.role?.map((role: any) => role.value),
            }));

        //get form new contacts
        let newMembers: ImportMembersRequestMemberDto[] = [];
        newMembers = formValues['invited-members']
            ?.filter(
                (member: any) =>
                    member.id === undefined && member.role !== undefined && member.role !== ''
            )
            .map((member: any) => ({
                ...member,
                roles: member.role?.map((role: any) => role.value),
            }));

        return {
            members: [...(parsedMembers || []), ...(existingMembers || []), ...(newMembers || [])],
        } as ImportMembersRequestDto;
    };

    //#region Submit / Exit
    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <User />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`Membership.yes_leave_invitation`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const data = getSubmitModelFromForm();

        if (data.members!.length < 1) {
            toastStore.toast({
                type: 'error',
                messageKey: `Subscription.invite_members_empty_error`,
            });
            return;
        }
        try {
            globalLoadingStore.addLoading();

            const preview = await membershipService.importMembersPreview(membershipId, data);

            if (
                !hasSeenPreview &&
                preview &&
                preview.items?.some((member) => member?.memberIsInMembership)
            ) {
                setMemberAlreadyInMembershipResponse(
                    preview.items
                        ?.filter((item) => item !== null && item.memberIsInMembership)
                        .map((item) => item!)
                );
                setMembersAlreadyInMembershipVisible(true);
                setHasSeenPreview(true);
            } else {
                await membershipService.importMembers(membershipId, data);
                if(inviteMemberContext === InviteMemberContext.SubscriptionOnboarding)
                    await updateOnboardingStatus(false);
                dismiss(true);
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    const modalHeader = (): ReactNode => {
        return (
            <>
                {t('Membership.add_members')}
                <div className="buttons-wrapper">
                    <button className="download-template" onClick={fetchFileTemplate}>
                        <Icon iconName="CrossFile" />
                        Download template
                    </button>

                    <button className="download-template-info" onClick={(): void => setTipVisible(true)}>
                        <Icon iconName="Info" />
                    </button>

                    <InputFile
                        onChange={(e): void => handleFileSelect(e.target ?? null)}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                </div>
            </>
        );
    };

    useEffect(() => {
        //InviteMemberContext.Onboarding seems to have more re-renders than InviteMemberContext.Company.
        //for some reason, in the Form.List if (fields.length === 0 && !userRemovedLine) add();
        //will only work for onboarding. In the company context, the initial line is not rendered but is in the formlist, so clicking on add has two lines.
        //idk what to do so this dumb fix will do for now
        if (!triggerAdditionalRender && inviteMemberContext === InviteMemberContext.Company)
            setTriggerAdditionalRender(true);
    }, [triggerAdditionalRender])

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={modalHeader()}
            className="InviteMembersFormModal"
            onCancel={exit}
            width={1140}
        >
            <div className="InviteMembers">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    {parsedFileValue &&
                        parsedFileValue
                            .filter((item) => !item?.isDeleted)
                            .map((item, index) => (
                                <div className="invite-members-upload" key={index}>
                                    {(item?.memberIsInMembership ||
                                        item?.memberIsInvitedToMembership) && (
                                        <div className="invite-members-info">
                                            {t('Membership.this_member_already_in_company')}
                                        </div>
                                    )}

                                    <Row gutter={formGutter}>
                                        <Col span={6}>
                                            <ValidatedFormItem
                                                name={`imported_${index}_firstName`}
                                                label={t('first_name')}
                                            >
                                                <Input disabled />
                                            </ValidatedFormItem>
                                        </Col>
                                        <Col span={6}>
                                            <ValidatedFormItem
                                                name={`imported_${index}_lastName`}
                                                label={t('last_name')}
                                            >
                                                <Input disabled />
                                            </ValidatedFormItem>
                                        </Col>
                                        <Col span={6}>
                                            <ValidatedFormItem
                                                name={`imported_${index}_email`}
                                                label={t('email')}
                                            >
                                                <Input disabled />
                                            </ValidatedFormItem>
                                        </Col>
                                        <Col span={6}>
                                            <ValidatedFormItem
                                                name={`imported_${index}_role`}
                                                label={t('User.roles')}
                                                required
                                            >
                                                <SelectCustom
                                                    onChange={(values: any): void => {
                                                        const newState = parsedFileValue.slice();
                                                        const index = newState?.findIndex(
                                                            (line) => line?.email === item?.email
                                                        );
                                                        newState[index] = {
                                                            ...newState[index],
                                                            roles:
                                                                values === ''
                                                                    ? []
                                                                    : values?.map(
                                                                          (x: any) => x.value
                                                                      ),
                                                        };
                                                        setParsedFileValue(newState);
                                                    }}
                                                    selected={
                                                        item?.memberAccountExists ||
                                                        item?.memberIsInMembership ||
                                                        item?.memberIsInvitedToMembership
                                                            ? item.memberAccount?.roles
                                                            : item?.roles
                                                    }
                                                    isMulti
                                                    options={roleOptions()}
                                                    disabled={item?.memberIsInMembership}
                                                    idAttribute={`imported_${index}_role`}
                                                />
                                            </ValidatedFormItem>
                                        </Col>

                                        <span
                                            className="garbage-container"
                                            onClick={(): void => {
                                                const newState = parsedFileValue.slice();
                                                const index = newState?.findIndex(
                                                    (line) => line?.email === item?.email
                                                );
                                                newState[index] = {
                                                    ...newState[index],
                                                    isDeleted: true,
                                                };
                                                setParsedFileValue(newState);
                                            }}
                                        >
                                            <Delete fill={theme['primary-color']} />
                                        </span>
                                    </Row>
                                    <Divider />
                                </div>
                            ))}

                    <Form.List name="invited-members">
                        {(fields, { add, remove }): ReactNode => {
                            if (fields.length === 0 && !userRemovedLine) add();
                            return (
                                <>
                                    {fields.map((field, index) => (
                                        <InviteMemberFormItem
                                            index={index}
                                            key={field.fieldKey}
                                            remove={(index): void => {
                                                remove(index);
                                                setUserRemovedLine(true);
                                            }}
                                        />
                                    ))}

                                    <Row gutter={formGutter}>
                                        <Col span={24} className="dynamic-list-button-container">
                                            <Button
                                                className={
                                                    'ant-btn-primary ant-btn-circle ant-btn-lg ant-btn-icon-only'
                                                }
                                                onClick={(): void => add()}
                                            >
                                                <Icon iconName="Add" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            );
                        }}
                    </Form.List>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        
                        {inviteMemberContext === InviteMemberContext.SubscriptionOnboarding && (
                            <Button
                                type="default"
                                className="secondary wont-do"
                                htmlType="button"
                                onClick={(): Promise<void> => wontDo()}
                            >
                                {t('wont_do')}
                            </Button>
                        )}

                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>

            {tipVisible && (
                <ImportMemberTipModal
                    onComplete={(): void => setTipVisible(false)}
                    visible={tipVisible}
                />
            )}

            {membersAlreadyInMembershipVisible && (
                <ImportMembersAlreadyInCompanyModal
                    onComplete={(shouldSubmit: boolean): void => {
                        if (shouldSubmit) submit();
                        setMembersAlreadyInMembershipVisible(false);
                        setHasSeenPreview(false);
                    }}
                    visible={membersAlreadyInMembershipVisible}
                    members={memberAlreadyInMembershipResponse || []}
                />
            )}
        </BaseModal>
    );
    //#endregion
};

export default React.memo(InviteMembersModal);
