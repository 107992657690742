// GENERATED FILE - DO NOT MODIFY
import { GetClientParametersResponseDto } from 'Api/Features/System/Dtos/GetClientParametersResponseDto';
import { GetSystemParametersResponseDto } from 'Api/Features/System/Dtos/GetSystemParametersResponseDto';
import { UpdateSystemParametersRequestDto } from 'Api/Features/System/Dtos/UpdateSystemParametersRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class SystemProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getApiVersionInfo(): Promise<void> {
        const uri = this.buildUri(
            "/version",
            null,
            null
        );

        await this.httpClient.get<void>(uri);
    }

    public async getClientParameters(): Promise<GetClientParametersResponseDto | null> {
        const uri = this.buildUri(
            "/client-parameters",
            null,
            null
        );

        const data: GetClientParametersResponseDto | null = await this.httpClient.get<GetClientParametersResponseDto | null>(uri);
        return data;
    }

    public async getSystemParameters(): Promise<GetSystemParametersResponseDto | null> {
        const uri = this.buildUri(
            "/system-parameters",
            null,
            null
        );

        const data: GetSystemParametersResponseDto | null = await this.httpClient.get<GetSystemParametersResponseDto | null>(uri);
        return data;
    }

    public async updateSystemParameters(request: UpdateSystemParametersRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/system-parameters",
            null,
            null
        );

        await this.httpClient.put<UpdateSystemParametersRequestDto | null, void>(uri, request);
    }
}