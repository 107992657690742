import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Administration: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.995 3.876l-.183.104c.026.121.045.243.058.367l-.345.002.008 1.365-.154.044.032-.127c.075-.292.112-.585.112-.874 0-.28-.033-.557-.1-.825l-.012-.053-.02-.08.936-.54-.778-1.346-.929.535-.1-.098a3.4 3.4 0 00-1.472-.861l-.134-.037L5.91.37 4.355.381l.006 1.07-.137.038a3.36 3.36 0 00-1.436.823l-.1.097-.937-.545L.97 3.21l.921.538-.034.134a3.437 3.437 0 00-.013 1.694l.034.135-.934.54.778 1.348.929-.535.1.1a3.321 3.321 0 001.469.856l.133.038.004.533.003.547.43-.002.478-.004a3.897 3.897 0 00-.053.37l-.473.002-.749.005-.006-1.133v-.042a3.689 3.689 0 01-1.393-.812l-1.006.58L.44 6.117l1.015-.588a3.74 3.74 0 01.012-1.602L.466 3.342l1.15-1.98 1.017.59c.392-.35.856-.618 1.358-.778L3.985.016 6.275 0l.006 1.174c.516.166.994.445 1.396.816l1.006-.58L9.83 3.393l-.835.483z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.512 5.879l-.48-.28-.18.308a1.189 1.189 0 01-.905-1.15 1.188 1.188 0 012.373 0c0 .52-.339.964-.808 1.122zM5.136 3.2a1.556 1.556 0 100 3.109h.026a1.535 1.535 0 00.741-.203A1.554 1.554 0 005.136 3.2zM9.521 11.444a1.516 1.516 0 01-1.516-1.512c0-.835.68-1.515 1.516-1.515a1.514 1.514 0 010 3.028zm0-3.498a1.986 1.986 0 00-1.987 1.986 1.985 1.985 0 101.987-1.987z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.833 11.903l-.993 1.716-1.2-.695-.127.125c-.516.5-1.15.863-1.834 1.049l-.173.048.006 1.366-1.982.014-.008-1.38-.17-.047a4.298 4.298 0 01-1.875-1.095l-.127-.126-1.186.683-.993-1.721 1.19-.689-.042-.172a4.403 4.403 0 01.017-2.163l.043-.172-1.175-.685.958-1.65c.047 0 .093-.003.14-.01l1.096.638.127-.125a4.301 4.301 0 011.835-1.05l.019-.004.154-.044-.008-1.365.345-.002 1.638-.011.008 1.378.17.048a4.345 4.345 0 011.88 1.1l.126.125 1.186-.683.993 1.716-1.195.69.042.172c.085.34.127.694.127 1.05 0 .37-.048.745-.143 1.117l-.044.172 1.175.682zm-.517-1.97c0-.335-.035-.667-.102-.99l1.3-.75-1.465-2.53-1.283.74a4.796 4.796 0 00-1.781-1.042l-.01-1.5-1.98.015-.584.003-.36.003.009 1.48a4.814 4.814 0 00-1.732.99l-.425-.247-.39-.226-.482-.28-.179.308-.19.328-.933 1.606-.168.288.247.142.178.105.37.215.482.282a4.36 4.36 0 00-.05.263 4.606 4.606 0 00-.066.796c0 .334.034.663.101.987l-1.294.75 1.463 2.534 1.283-.74a4.779 4.779 0 001.78 1.037l.007 1.5 2.924-.021-.008-1.478a4.726 4.726 0 001.73-.993l1.303.754 1.465-2.53-1.277-.74c.078-.353.117-.71.117-1.06z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.521 11.444a1.516 1.516 0 01-1.516-1.512c0-.835.68-1.515 1.516-1.515a1.514 1.514 0 010 3.028zm0-3.498a1.986 1.986 0 00-1.987 1.986 1.985 1.985 0 101.987-1.987z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.521 11.444a1.516 1.516 0 01-1.516-1.512c0-.835.68-1.515 1.516-1.515a1.514 1.514 0 010 3.028zm0-3.498a1.986 1.986 0 00-1.987 1.986 1.985 1.985 0 101.987-1.987z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Administration;
