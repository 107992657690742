import { Button } from 'antd';
import Title from 'antd/lib/typography/Title';
import arrayMove from 'array-move';
import { EmptyPage } from 'Components/empty-page';
import Icon from 'Components/icons/icon';
import UpcomingActivitiesItem, {
    UpcomingActivitiesItemProps,
} from 'Components/upcoming-activities-item/upcoming-activities-item';
import { DISPLAY_DAY_MONTH_FORMAT } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent, ReactText, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { displayFormatedDate } from 'Utils/TimeUtils';
import './activity-board-list.less';

export interface ActivityBoardListItems {
    date: string;
    items: UpcomingActivitiesItemProps[];
}

interface ActivityBoardListProps {
    pastItems?: ActivityBoardListItems[];
    items?: ActivityBoardListItems[];
    saveOrder: (date: string, ids: string[]) => any;
    canReorder: boolean;
}

interface SortableItemInterface {
    activity: UpcomingActivitiesItemProps;
}

interface SortableContainerInterface {
    items: UpcomingActivitiesItemProps[];
    dayIndex: number;
    isPast?: boolean;
}

interface IonSortEnd {
    oldIndex: number;
    newIndex: number;
    collection: ReactText;
}

const DragHandle = SortableHandle(() => <Icon iconName="DragHandle" />);

const SortableItem = SortableElement(({ activity }: SortableItemInterface) => (
    <UpcomingActivitiesItem
        id={activity.id}
        title={activity.title}
        tagLabel={activity.tagLabel}
        label={activity.label}
        link={activity.link}
        dragHandle={<DragHandle />}
        checkbox={activity.checkbox}
    />
));

const SortableList = SortableContainer(({ items, dayIndex }: SortableContainerInterface) => {
    return (
        <div>
            {items.map((activity, index) => (
                <SortableItem
                    key={`activity-${dayIndex}-${index}`}
                    index={index}
                    activity={activity}
                    collection={dayIndex}
                />
            ))}
        </div>
    );
});

const ActivityBoardList: FunctionComponent<ActivityBoardListProps> = ({
    pastItems,
    items,
    saveOrder,
    canReorder,
}) => {
    const [activities, setActivities] = useState({ items });
    const [hidePastActivities, setHidePastActivities] = useState(true);
    const { t } = useTranslation();

    const pastActivitiesButtonLabel = t(
        `ActivityBoard.activity_board_upcoming_activities_${
            hidePastActivities ? 'see' : 'hide'
        }_past`
    );

    const onActivitiesSortEnd = ({ oldIndex, newIndex, collection }: IonSortEnd): void => {
        setActivities(({ items }) => {
            let newItems;

            if (items) {
                newItems = [...items];
                const index = collection as number;

                newItems[index].items = arrayMove(items[index].items, oldIndex, newIndex);

                saveOrder(
                    newItems[index].date,
                    newItems[index].items.map((item) => item.id)
                );
            }

            return { items: newItems };
        });
    };

    useEffect(() => {
        setActivities({ items });
    }, [items]);

    return (
        <div className="ActivityBoardList">
            <Title level={3}>{t('ActivityBoard.activity_board_upcoming_activities_title')}</Title>

            {pastItems && (
                <>
                    <Button
                        className={`expand-${hidePastActivities}`}
                        type="text"
                        onClick={(): void => setHidePastActivities(!hidePastActivities)}
                    >
                        {pastActivitiesButtonLabel}
                        <Icon iconName="AngleLeft" />
                    </Button>

                    <div className={`days-list past start-line-true  hide-${hidePastActivities}`}>
                        {pastItems.map((day, dayI) => (
                            <div key={dayI} className="day-wrapper past">
                                <Title level={4}>
                                    {displayFormatedDate(day.date, DISPLAY_DAY_MONTH_FORMAT)}
                                </Title>

                                <div>
                                    {day.items.map((activity, index) => (
                                        <UpcomingActivitiesItem
                                            key={`${index}-${activity.id}`}
                                            id={activity.id}
                                            className="past"
                                            title={activity.title}
                                            tagLabel={activity.tagLabel}
                                            label={activity.label}
                                            link={activity.link}
                                            dragHandle={<DragHandle />}
                                            checkbox={{
                                                checked: activity.checkbox?.checked
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {activities.items ? (
                <>
                    <div className={`days-list start-line-${hidePastActivities}`}>
                        {activities.items.map((day, dayI) => {
                            const isToday = moment(day.date).isSame(moment(), 'day');

                            return (
                                <div key={dayI} className="day-wrapper">
                                    <Title className={`isToday-${isToday}`} level={4}>
                                        {`${isToday ? 'Today, ' : ''}${displayFormatedDate(
                                            day.date,
                                            DISPLAY_DAY_MONTH_FORMAT
                                        )}`}
                                    </Title>

                                    {canReorder ? (
                                        <SortableList
                                            items={day.items}
                                            dayIndex={dayI}
                                            onSortEnd={onActivitiesSortEnd}
                                            lockAxis="y"
                                            useDragHandle
                                        />
                                    ) : (
                                        <div>
                                            {day.items.map((activity, index) => (
                                                <UpcomingActivitiesItem
                                                    className="reorder-disabled"
                                                    key={`${index}-${activity.id}`}
                                                    id={activity.id}
                                                    title={activity.title}
                                                    tagLabel={activity.tagLabel}
                                                    label={activity.label}
                                                    link={activity.link}
                                                    dragHandle={<DragHandle />}
                                                    checkbox={{
                                                        checked: activity.checkbox?.checked,
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : (
                <div className="EmptyPage-wrapper">
                    <EmptyPage
                        iconName={'Plan'}
                        title={t(`ActivityBoard.activity_board_upcoming_activities_empty_title`)}
                        subTitle={t(
                            `ActivityBoard.activity_board_upcoming_activities_empty_subtitle`
                        )}
                    />
                </div>
            )}
        </div>
    );
};

export default ActivityBoardList;
