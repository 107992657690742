import { AccountsReceivableReportProxy } from 'Api/Features/Reports/AccountsReceivableReportProxy';
import { AccountsReceivableReportDto } from 'Api/Features/Reports/Dtos/AccountsReceivable/AccountsReceivableReportDto';
import { GetAccountsReceivableReportRequestDto } from 'Api/Features/Reports/Dtos/AccountsReceivable/GetAccountsReceivableReportRequestDto';
import { inject } from 'aurelia-dependency-injection';
import { arrayBufferToXlsx } from 'Utils/blobs';
import { ApiService } from './ApiService';

@inject(AccountsReceivableReportProxy)
export class AccountsReceivableReportService extends ApiService {
    constructor(private readonly accountsReceivableReportProxy: AccountsReceivableReportProxy) {
        super();
    }

    public async getAccountsReceivableReport(
        request: GetAccountsReceivableReportRequestDto | null
    ): Promise<AccountsReceivableReportDto | null> {
        return await this.accountsReceivableReportProxy.getAccountsReceivableReport(request);
    }

    public async exportAccountsReceivableReport(
        request: GetAccountsReceivableReportRequestDto | null
    ): Promise<Blob> {
        const document = await this.accountsReceivableReportProxy.exportAccountsReceivableReport(
            request
        );
        return arrayBufferToXlsx(document);
    }
}
