// GENERATED FILE - DO NOT MODIFY
import { CreateMemberRequestDto } from 'Api/Features/Members/Dtos/CreateMemberRequestDto';
import { CreateMemberResponseDto } from 'Api/Features/Members/Dtos/CreateMemberResponseDto';
import { ExportMembersRequestDto } from 'Api/Features/Members/Dtos/ExportMembersRequestDto';
import { GetMemberNoteResponseDto } from 'Api/Features/Members/Dtos/GetMemberNoteResponseDto';
import { GetMemberRolesAndRightsResponseDto } from 'Api/Features/Members/Dtos/GetMemberRolesAndRightsResponseDto';
import { GetMembersRequestDto } from 'Api/Features/Members/Dtos/GetMembersRequestDto';
import { GetMembersResponseDto } from 'Api/Features/Members/Dtos/GetMembersResponseDto';
import { MemberDetailsDto } from 'Api/Features/Members/Dtos/MemberDetailsDto';
import { UpdateMemberNoteRequestDto } from 'Api/Features/Members/Dtos/UpdateMemberNoteRequestDto';
import { UpdateMemberRequestDto } from 'Api/Features/Members/Dtos/UpdateMemberRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class MemberProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getMember(memberId: string | null): Promise<MemberDetailsDto | null> {
        const uri = this.buildUri(
            "/members/{memberId}",
            { memberId: memberId },
            null
        );

        const data: MemberDetailsDto | null = await this.httpClient.get<MemberDetailsDto | null>(uri);
        return data;
    }

    public async getMembers(request: GetMembersRequestDto | null): Promise<GetMembersResponseDto | null> {
        const uri = this.buildUri(
            "/members",
            null,
            { ...request || undefined }
        );

        const data: GetMembersResponseDto | null = await this.httpClient.get<GetMembersResponseDto | null>(uri);
        return data;
    }

    public async exportMembers(request: ExportMembersRequestDto | null): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/members/export",
            null,
            { ...request || undefined }
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }

    public async getMemberRolesAndRights(memberId: string | null): Promise<GetMemberRolesAndRightsResponseDto | null> {
        const uri = this.buildUri(
            "/members/{memberId}/membershiprights",
            { memberId: memberId },
            null
        );

        const data: GetMemberRolesAndRightsResponseDto | null = await this.httpClient.get<GetMemberRolesAndRightsResponseDto | null>(uri);
        return data;
    }

    public async createMember(request: CreateMemberRequestDto | null): Promise<CreateMemberResponseDto | null> {
        const uri = this.buildUri(
            "/members",
            null,
            null
        );

        const data: CreateMemberResponseDto | null = await this.httpClient.post<CreateMemberRequestDto | null, CreateMemberResponseDto | null>(uri, request);
        return data;
    }

    public async updateMember(memberId: string | null, request: UpdateMemberRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/members/{memberId}",
            { memberId: memberId },
            null
        );

        await this.httpClient.patch<UpdateMemberRequestDto | null, void>(uri, request);
    }

    public async deleteMember(memberId: string): Promise<void> {
        const uri = this.buildUri(
            "/members/{memberId}",
            { memberId: memberId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async getMemberNote(memberId: string | null): Promise<GetMemberNoteResponseDto | null> {
        const uri = this.buildUri(
            "/members/{memberId}/notes",
            { memberId: memberId },
            null
        );

        const data: GetMemberNoteResponseDto | null = await this.httpClient.get<GetMemberNoteResponseDto | null>(uri);
        return data;
    }

    public async updateMemberNote(memberId: string | null, request: UpdateMemberNoteRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/members/{memberId}/notes",
            { memberId: memberId },
            null
        );

        await this.httpClient.put<UpdateMemberNoteRequestDto | null, void>(uri, request);
    }
}