import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Layout, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { autorun } from 'mobx';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useService, useStores } from 'Hooks';
import { FilterStore } from 'Stores';
import LocationHeader from 'Components/location-header/location-header';
import { AddOn as AddOnIcon } from 'Components/icons';
import { theme } from 'variant';
import { TableFilters } from 'Components/table-filters';
import { currencyFormatter } from 'Utils/NumberUtils';
import { DEFAULT_TABLE_PAGE_SIZE, TABLE_DEBOUNCE_DELAY } from 'Models/Constants';
import { AddOnService } from 'Services/AddOnService';
import { AddOn } from 'Models/AddOn/AddOn';
import EditAddOnModal from './edit-add-on';
import { AddOnCategoryService } from 'Services/AddOnCategoryService';
import { AdvancedFilterItem } from 'Models/Filters/AdvancedFilterItem';
import { AddOnCategoryDto } from 'Api/Features/AddOnCategories/Dtos/AddOnCategoryDto';
import { GetAddOnsRequestDto } from 'Api/Features/AddOns/Dtos/GetAddOnsRequestDto';
import debounce from 'lodash.debounce';
import { RequestType } from 'Stores/RequestStore';
import { orderObjectArrayAlphabeticallyByProperty } from 'Utils/ArrayUtils';
import { GetAddOnsSortColumnDto } from 'Api/Features/AddOns/Dtos/GetAddOnsSortColumnDto';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';

const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'management',
        nameKey: 'Location.management_label',
    },
    {
        path: 'add-ons',
        nameKey: 'add_ons',
    },
];

interface GetAddOnsRequestDtoExtended extends GetAddOnsRequestDto {
    category: string[] | null;
}

const advancedFilters: AdvancedFilter[] = [{ key: 'category', nameKey: 'Category', items: [] }];

const LocationAddOns: FunctionComponent = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const addOnService = useService(AddOnService);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const paginationRef = useRef(initialPaginationState);
    const history = useHistory();
    const { requestStore, globalLoadingStore } = useStores();
    const addOnCategoryService = useService(AddOnCategoryService);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<AddOn[] | undefined>();
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const fetchCategoriesForAdvancedFilters = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const response = await addOnCategoryService.getAddOnCategories({});
            if (response?.items) {
                const categoryAdvancedFilters = response?.items
                    .filter((category) => category !== null)
                    .map((category) => category!)
                    .map(
                        (category) =>
                            ({
                                key: category.id,
                                displayNameKey: category.name,
                                checked: true,
                            } as AdvancedFilterItem)
                    );
                const ordered = orderObjectArrayAlphabeticallyByProperty(
                    categoryAdvancedFilters,
                    'displayNameKey'
                );
                filterStoreRef.current.advancedFilters = [
                    {
                        key: 'category',
                        nameKey: 'Category',
                        items: ordered,
                    },
                ];
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, addOnCategoryService]);

    useEffect(() => {
        fetchCategoriesForAdvancedFilters();
    }, [fetchCategoriesForAdvancedFilters]);

    const columns = [
        {
            title: t('name'),
            dataIndex: 'name',
            render: (key: string): string => key,
            key: GetAddOnsSortColumnDto.Name,
            sorter: true,
        },
        {
            title: t('suggested_price_monthly'),
            dataIndex: 'suggestedPrice',
            render: (key: string): string => (key ? currencyFormatter(key) : ''),
            key: GetAddOnsSortColumnDto.SuggestedPrice,
            sorter: true,
        },
        {
            title: t('category'),
            dataIndex: 'category',
            render: (key: AddOnCategoryDto): string => key?.name || '',
            key: GetAddOnsSortColumnDto.Category,
            sorter: true,
        },
    ];

    const getAdvancedFilterCategories = (): { checkedCategoryIds: string[]; typeCount: number } => {
        const filterStore = filterStoreRef.current;
        const categories = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'category'
        );
        const checkedCategories = categories?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key;
            });
        return {
            checkedCategoryIds: checkedCategories ?? [],
            typeCount: categories?.items.length ?? 0,
        };
    };

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            advancedFilters?: AdvancedFilter[];
            sortColumn: GetAddOnsSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);
            try {
                const categories = getAdvancedFilterCategories();
                if (categories.checkedCategoryIds.length === 0) {
                    setData([]);
                } else {
                    const request: GetAddOnsRequestDtoExtended = requestStore.setRequest({
                        request: {
                            campusIds: [id],
                            searchTerm: params.searchTerm,
                            pageSize: params.pagination.pageSize || DEFAULT_TABLE_PAGE_SIZE,
                            page: (params.pagination.current || 1) - 1,
                            categoryIds:
                                categories.checkedCategoryIds.length === categories.typeCount
                                    ? []
                                    : categories.checkedCategoryIds,
                            category:
                                categories.checkedCategoryIds.length === categories.typeCount
                                    ? []
                                    : categories.checkedCategoryIds,
                            sortColumn: params.sortColumn,
                            sortDirection: params.sortDirection,
                        },
                        requestType: RequestType.AddOn,
                    });

                    const [results, totalItems] = await addOnService.getAddOns(request);

                    if (results) {
                        setData(results);
                        setPagination({
                            ...params.pagination,
                            total: totalItems,
                        });
                    }
                }
            } finally {
                setLoading(false);
            }
        },
        [addOnService, id]
    );

    const debounceSearch = useRef(
        debounce((params: { searchTerm?: string; advancedFilters?: AdvancedFilter[] }) => {
            fetch({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                searchTerm: params.searchTerm,
                advancedFilters: params.advancedFilters,
                sortColumn: null,
                sortDirection: null,
            });
        }, TABLE_DEBOUNCE_DELAY)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                advancedFilters: filterStore.advancedFilters,
            });
        });
        return (): void => {
            disposer();
        };
    }, [fetch]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): void => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }
        fetch({
            pagination,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
    };

    const onRowClick = (addOn: AddOn): void => {
        history.push(`/locations/${id}/management/add-ons/${addOn.id}`);
    };

    return (
        <div className="AddOns">
            <LocationHeader
                title={t('add_ons')}
                subTitle={t('AddOn.management_section_add_ons_subtitle')}
                defaultImg={<AddOnIcon fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            setCreateModalOpen(true);
                        }}
                    >
                        {t('AddOn.create_add_on')}
                    </Button>
                }
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeAdvancedFilters
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(record: AddOn): string => record.id || ''}
                    dataSource={data}
                    loading={loading}
                    pagination={pagination}
                    onChange={handleTableChange}
                    onRow={(row: AddOn) => ({
                        onClick: (): void => {
                            onRowClick(row);
                        },
                    })}
                />
            </Content>

            {createModalOpen && (
                <EditAddOnModal
                    visible={createModalOpen}
                    locationId={id}
                    onComplete={(success: boolean): void => {
                        setCreateModalOpen(false);
                        if (success) {
                            fetch({
                                pagination,
                                sortColumn: null,
                                sortDirection: null,
                            });
                        }
                    }}
                />
            )}
        </div>
    );
};

export default LocationAddOns;
