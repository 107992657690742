import { CreateInvoiceRequestDto } from 'Api/Features/Invoices/Dtos/CreateInvoiceRequestDto';
import { GetMemberInvoicesRequestDto } from 'Api/Features/Invoices/Dtos/GetMemberInvoicesRequestDto';
import { GetMemberInvoicesResponseDto } from 'Api/Features/Invoices/Dtos/GetMemberInvoicesResponseDto';
import { GetMembershipInvoicesRequestDto } from 'Api/Features/Invoices/Dtos/GetMembershipInvoicesRequestDto';
import { GetMembershipInvoicesResponseDto } from 'Api/Features/Invoices/Dtos/GetMembershipInvoicesResponseDto';
import { InvoiceDto } from 'Api/Features/Invoices/Dtos/InvoiceDto';
import { InvoiceExportRequestDto } from 'Api/Features/Invoices/Dtos/InvoiceExportRequestDto';
import { LightInvoiceDto } from 'Api/Features/Invoices/Dtos/LightInvoiceDto';
import { InvoiceProxy } from 'Api/Features/Invoices/InvoiceProxy';
import { inject } from 'aurelia-dependency-injection';
import { arrayBufferToPdf } from 'Utils';
import { ApiService } from './ApiService';

interface NonNullGetMemberInvoicesResponseDto {
    items: LightInvoiceDto[];
    totalItemCount: number;
}

@inject(InvoiceProxy)
export class InvoiceService extends ApiService {
    constructor(private readonly invoiceProxy: InvoiceProxy) {
        super();
    }

    public async exportInvoice(request: InvoiceExportRequestDto | null): Promise<Blob> {
        const document = await this.invoiceProxy.exportInvoice(request);

        return arrayBufferToPdf(document);
    }

    public async getInvoice(invoiceId: string | null): Promise<InvoiceDto | null> {
        const data: InvoiceDto | null = await this.invoiceProxy.getInvoice(invoiceId);
        return data;
    }

    public async getMemberInvoices(
        memberId: string | null,
        request: GetMemberInvoicesRequestDto | null
    ): Promise<NonNullGetMemberInvoicesResponseDto> {
        const data: GetMemberInvoicesResponseDto | null = await this.invoiceProxy.getMemberInvoices(
            memberId,
            request
        );
        const nonNullItems = data?.items
            ? data.items.filter((item): item is LightInvoiceDto => item !== null)
            : [];

        return { items: nonNullItems, totalItemCount: data?.totalItemCount || 0 };
    }

    public async createInvoice(request: CreateInvoiceRequestDto | null): Promise<string> {
        const data: string = await this.invoiceProxy.createInvoice(request);
        return data;
    }

    public async refundInvoice(invoiceId: string | null): Promise<void> {
        await this.invoiceProxy.refundInvoice(invoiceId);
    }

    public async resendInvoice(invoiceId: string | null): Promise<void> {
        await this.invoiceProxy.resendInvoice(invoiceId);
    }

    public async getMembershipInvoices(
        membershipId: string | null,
        request: GetMembershipInvoicesRequestDto | null
    ): Promise<[LightInvoiceDto[], number]> {
        const data: GetMembershipInvoicesResponseDto | null =
            await this.invoiceProxy.getMembershipInvoices(membershipId, request);
        return [
            data?.items?.filter((x) => x !== null).map((x) => x!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async voidInvoice(invoiceId: string | null): Promise<void> {
        await this.invoiceProxy.voidInvoice(invoiceId);
    }

    public async closeInvoice(invoiceId: string | null): Promise<void> {
        await this.invoiceProxy.closeInvoice(invoiceId);
    }

    public async tryInvoicePayment(invoiceId: string | null): Promise<InvoiceDto | null> {
        const data = await this.invoiceProxy.tryInvoicePayment(invoiceId);
        return data;
    }
}
