// GENERATED FILE - DO NOT MODIFY
import { CreateOpportunityCommentRequestDto } from 'Api/Features/Opportunities/Dtos/CreateOpportunityCommentRequestDto';
import { CreateOpportunityRequestDto } from 'Api/Features/Opportunities/Dtos/CreateOpportunityRequestDto';
import { ExportOpportunitiesRequestDto } from 'Api/Features/Opportunities/Dtos/ExportOpportunitiesRequestDto';
import { GetOpportunitiesRequestDto } from 'Api/Features/Opportunities/Dtos/GetOpportunitiesRequestDto';
import { GetOpportunitiesResponseDto } from 'Api/Features/Opportunities/Dtos/GetOpportunitiesResponseDto';
import { GetOpportunityNoteResponseDto } from 'Api/Features/Opportunities/Dtos/GetOpportunityNoteResponseDto';
import { OpportunityDto } from 'Api/Features/Opportunities/Dtos/OpportunityDto';
import { UpdateOpportunityNoteRequestDto } from 'Api/Features/Opportunities/Dtos/UpdateOpportunityNoteRequestDto';
import { UpdateOpportunityRequestDto } from 'Api/Features/Opportunities/Dtos/UpdateOpportunityRequestDto';
import { UpdateOpportunityStatusToLostRequestDto } from 'Api/Features/Opportunities/Dtos/UpdateOpportunityStatusToLostRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class OpportunityProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getOpportunity(opportunityId: string): Promise<OpportunityDto | null> {
        const uri = this.buildUri(
            "/opportunities/{opportunityId}",
            { opportunityId: opportunityId },
            null
        );

        const data: OpportunityDto | null = await this.httpClient.get<OpportunityDto | null>(uri);
        return data;
    }

    public async getOpportunities(request: GetOpportunitiesRequestDto | null): Promise<GetOpportunitiesResponseDto | null> {
        const uri = this.buildUri(
            "/opportunities",
            null,
            { ...request || undefined }
        );

        const data: GetOpportunitiesResponseDto | null = await this.httpClient.get<GetOpportunitiesResponseDto | null>(uri);
        return data;
    }

    public async exportOpportunities(request: ExportOpportunitiesRequestDto | null): Promise<ArrayBuffer> {
        const uri = this.buildUri(
            "/opportunities/export",
            null,
            { ...request || undefined }
        );

        const data: ArrayBuffer = await this.httpClient.getAsBuffer(uri);
        return data;
    }

    public async createOpportunity(request: CreateOpportunityRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/opportunities",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateOpportunityRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateOpportunity(opportunityId: string, request: UpdateOpportunityRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/opportunities/{opportunityId}",
            { opportunityId: opportunityId },
            null
        );

        await this.httpClient.patch<UpdateOpportunityRequestDto | null, void>(uri, request);
    }

    public async createOpportunityComment(opportunityId: string, request: CreateOpportunityCommentRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/opportunities/{opportunityId}/comments",
            { opportunityId: opportunityId },
            null
        );

        await this.httpClient.post<CreateOpportunityCommentRequestDto | null, void>(uri, request);
    }

    public async updateOpportunityStatusToLost(opportunityId: string, request: UpdateOpportunityStatusToLostRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/opportunities/{opportunityId}/lost",
            { opportunityId: opportunityId },
            null
        );

        await this.httpClient.post<UpdateOpportunityStatusToLostRequestDto | null, void>(uri, request);
    }

    public async deleteOpportunity(opportunityId: string): Promise<void> {
        const uri = this.buildUri(
            "/opportunities/{opportunityId}",
            { opportunityId: opportunityId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async getOpportunityNote(opportunityId: string): Promise<GetOpportunityNoteResponseDto | null> {
        const uri = this.buildUri(
            "/opportunities/{opportunityId}/notes",
            { opportunityId: opportunityId },
            null
        );

        const data: GetOpportunityNoteResponseDto | null = await this.httpClient.get<GetOpportunityNoteResponseDto | null>(uri);
        return data;
    }

    public async updateOpportunityNote(opportunityId: string, request: UpdateOpportunityNoteRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/opportunities/{opportunityId}/notes",
            { opportunityId: opportunityId },
            null
        );

        await this.httpClient.put<UpdateOpportunityNoteRequestDto | null, void>(uri, request);
    }
}