import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { TaskDto } from 'Api/Features/Tasks/Dtos/TaskDto';
import { UpdateTaskRequestDto } from 'Api/Features/Tasks/Dtos/UpdateTaskRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Tick } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import moment from 'moment';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateTaskSchema } from 'Schemas/CreateTaskSchema';
import { TaskService } from 'Services/TaskService';
import './edit-task.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface EditTaskModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    task?: TaskDto | null;
}

const EditTaskModal: FunctionComponent<EditTaskModalProps> = ({ visible, onComplete, task }) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(CreateTaskSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const taskService = useService(TaskService);
    //#endregion

    //#region Effects
    useEffect(() => {
        if (task) {
            form.setFieldsValue({
                oldDate: moment.utc(task.date),
                note: task.note,
            });
        }
    }, [task, form]);
    //#endregion

    //#region Event handlers
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Tick />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`Lead.task_confirm_positive_edit`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (values: Store): Promise<void> => {
        const updateTask: UpdateTaskRequestDto = {
            date: values.date ?? undefined,
            note: values.note,
        };

        if (!(await validateForm(updateTask))) return;
        try {
            globalLoadingStore.addLoading();

            await taskService.updateTask(task?.id!, updateTask);

            toastStore.toast({
                type: 'success',
                messageKey: `Lead.task_changed_success`,
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Lead.edit_task')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="EditTask">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="oldDate"
                                label={t('Lead.old_task_date')}
                                required
                            >
                                <DatePicker
                                    className="w-100"
                                    placeholder={t('Lead.task_scheduled_date_placeholder')}
                                    defaultValue={moment.utc(task?.date)}
                                    disabled={true}
                                    use12Hours
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="date"
                                label={t('Lead.new_task_date')}
                                required
                            >
                                <DatePicker
                                    className="w-100"
                                    placeholder={t('Lead.task_scheduled_date_placeholder')}
                                    use12Hours
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="note"
                                label={t('Lead.lead_add_a_note')}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('Lead.update_activity_task')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(EditTaskModal);
