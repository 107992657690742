import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useService, useStores } from 'Hooks';
import { Delete, ForkKnife } from 'Components/icons';
import { theme } from 'variant';
import { Col, Layout, Row } from 'antd';
import { SimpleList } from 'Components/simple-list';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import LocationHeader from 'Components/location-header/location-header';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { ActionMenu } from 'Components/action-menu';
import { CampusSurroundingDto } from 'Api/Features/CampusSurroundings/Dtos/CampusSurroundingDto';
import { CampusSurroundingService } from 'Services/CampusSurroundingService';
import Icon from 'Components/icons/icon';
import { truncate } from 'Utils/StringUtils';
import EditAreaOfInterestModal from '../edit-area-of-interest-modal';
import { Tag } from 'Components/tag';

const { Content } = Layout;

interface SimpleListItemProps {
    title: string;
    description?: string;
    avatar?: React.ReactNode;
}

const AreaOfInterestDetails: FunctionComponent = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { globalLoadingStore, confirmationModalStore, toastStore } = useStores();
    const [data, setData] = useState<CampusSurroundingDto | undefined>(undefined);
    const [introduction, setIntroduction] = useState<SimpleListItemProps[]>([]);
    const [address, setAddress] = useState<SimpleListItemProps[]>([]);
    const [contactInformation, setContactInformation] = useState<SimpleListItemProps[]>([]);
    const campusSurroundingService = useService(CampusSurroundingService);

    const [editAreaOfInterestModalOpen, setEditAreaOfInterestModalOpen] = useState(false);

    const { areaOfInterestId } = useParams<{ areaOfInterestId: string }>();

    const setSimpleListsData = useCallback((areaOfInterest: CampusSurroundingDto): void => {
        const intro: SimpleListItemProps[] = [];
        if (areaOfInterest.introduction) {
            intro.push({
                title: areaOfInterest.introduction || '',
            });
        }
        setIntroduction(intro);

        const address: SimpleListItemProps[] = [];
        const addressInfos: string[] = [];
        if (areaOfInterest.address) {
            if (areaOfInterest.address.addressLine1)
                addressInfos.push(areaOfInterest.address.addressLine1);
            if (areaOfInterest.address.addressLine2)
                addressInfos.push(areaOfInterest.address.addressLine2);
            if (areaOfInterest.address.state && areaOfInterest.address.postalCode)
                addressInfos.push(
                    `${areaOfInterest.address.state} ${areaOfInterest.address.postalCode}`
                );
            if (addressInfos.length > 0) {
                address.push({
                    title: addressInfos.join(', '),
                    avatar: <Icon fill={theme['primary-color']} iconName={'RoadSign'} />,
                });
            }
        }
        setAddress(address);

        const contactInfo: SimpleListItemProps[] = [];
        if (areaOfInterest.contactInfo) {
            if (areaOfInterest.contactInfo.phone1)
                contactInfo.push({
                    title: areaOfInterest.contactInfo.phone1,
                    avatar: <Icon fill={theme['primary-color']} iconName={'Phone'} />,
                });
            if (areaOfInterest.contactInfo.phone2)
                contactInfo.push({
                    title: areaOfInterest.contactInfo.phone2,
                    avatar: <Icon fill={theme['primary-color']} iconName={'Phone'} />,
                });
            if (areaOfInterest.contactInfo.email)
                contactInfo.push({
                    title: areaOfInterest.contactInfo.email,
                    avatar: <Icon fill={theme['primary-color']} iconName={'Email'} />,
                });
            if (areaOfInterest.contactInfo.website)
                contactInfo.push({
                    title: truncate(areaOfInterest.contactInfo.website, 80),
                    avatar: <Icon fill={theme['primary-color']} iconName={'Television'} />,
                });
        }

        setContactInformation(contactInfo);
    }, []);

    const fetch = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const data = await campusSurroundingService.getCampusSurrounding(areaOfInterestId);
            if (data) {
                setData(data);
                setSimpleListsData(data);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, setSimpleListsData, campusSurroundingService, areaOfInterestId]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const deleteArea = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                width: 850,
                icon: <Delete />,
                title: t('model_delete_confirm_title', { param1: 'area of interest' }),
                message: t(`action_cannot_be_undone`),
                positiveText: t('model_confirm_positive_delete', { param1: 'area of interest' }),
                negativeText: t('model_confirm_negative_delete', { param1: 'area of interest' }),
            }))
        )
            return;

        await campusSurroundingService.deleteCampusSurrounding(areaOfInterestId);
        toastStore.toast({
            type: 'success',
            messageKey: t('model_delete_success', { param1: 'Area of interest' }),
        });
        history.goBack();
    };

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: 'management',
            nameKey: 'Location.management_label',
        },
        {
            path: 'areas-of-interest',
            nameKey: 'AreasOfInterest.areas_of_interest',
        },
        {
            name: data?.title || '',
            path: data?.id || '',
        },
    ];

    const actions: ActionMenuOption[] = [
        {
            key: 'edit',
            title: t('AreasOfInterest.edit_area_of_interest'),
            action: (): void => setEditAreaOfInterestModalOpen(true),
        },
        {
            key: 'delete',
            title: t('AreasOfInterest.delete_area_of_interest'),
            action: (): Promise<void> => deleteArea(),
        },
    ];

    return (
        <div className="AreaOfInterestDetails">
            <LocationHeader
                title={data?.title || ''}
                subTitle={<Tag label={data?.type?.description || ''} />}
                imgSrc={data?.imageUrl}
                defaultImg={<ForkKnife fill={theme['primary-color']} />}
                routes={breadcrumbs}
                action={<ActionMenu options={actions} type="primary" trigger="click" />}
            />
            <Content>
                <Row gutter={40}>
                    <Col span={12}>
                        {introduction.length > 0 && (
                            <SimpleList title={t('introduction')} data={introduction} />
                        )}

                        {address.length > 0 && (
                            <SimpleList
                                className="editor-style"
                                title={t('address')}
                                data={address}
                            />
                        )}
                    </Col>

                    <Col span={12}>
                        {contactInformation.length > 0 && (
                            <SimpleList
                                title={t('contact_information')}
                                data={contactInformation}
                            />
                        )}
                    </Col>
                </Row>
            </Content>

            {editAreaOfInterestModalOpen && (
                <EditAreaOfInterestModal
                    visible={editAreaOfInterestModalOpen}
                    onComplete={(success?: boolean): void => {
                        setEditAreaOfInterestModalOpen(false);
                        if (success) fetch();
                    }}
                    areaOfInterest={data}
                />
            )}
        </div>
    );
};

export default AreaOfInterestDetails;
