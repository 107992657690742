import { Col, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { ExpertiseDto } from 'Api/Features/Expertises/Dtos/ExpertiseDto';
import { AddressDto } from 'Api/Features/General/Dtos/AddressDto';
import { LightContactInfoDto } from 'Api/Features/General/Dtos/LightContactInfoDto';
import { MemberDetailsDto } from 'Api/Features/Members/Dtos/MemberDetailsDto';
import { MemberDetailsMembershipDto } from 'Api/Features/Members/Dtos/MemberDetailsMembershipDto';
import Icon from 'Components/icons/icon';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import SimpleListContentWithSeeDetails from 'Components/SimpleListContentWithSeeDetails';
import { MOMENT_PARSING_FORMAT } from 'Models/Constants';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { cleanVal } from 'Utils/NumberUtils';
import { displayFormatedDate } from 'Utils/TimeUtils';
import './contact-details-body.less';

const formGutter: [Gutter, Gutter] = [40, 0];

const valOrUndefined = (val?: string | null): SimpleListItemProps[] | undefined =>
    val ? [{ title: val }] : undefined;

interface GetIconComponent {
    icon?: string | null;
    imgSrc?: string | null;
}
const getIconComponent = (params: GetIconComponent): ReactElement => (
    <ImageWithPlaceholder
        width="32"
        height="32"
        defaultImg={<Icon iconName={params.icon || 'QuestionMarkLined'} />}
        imgSrc={params.imgSrc || undefined}
    />
);

const getContactInformation = (
    contact?: LightContactInfoDto | null
): SimpleListItemProps[] | undefined => {
    const contactInfosToDisplay: (keyof LightContactInfoDto)[] = [
        'email',
        'phone1',
        'phone2',
        'angelListUrl',
        'twitterUrl',
        'facebookUrl',
        'linkedInUrl',
        'instagramUrl',
        'website',
    ];
    const contactInfosToDisplayIcons: string[] = [
        'Email',
        'Phone',
        'Phone',
        'AngelList',
        'Twitter',
        'Facebook',
        'LinkedIn',
        'Instagram',
        'Television',
    ];

    const contactInfos: { title: string; avatar: ReactElement }[] = [];

    if (contact) {
        contactInfosToDisplay.forEach((key, i) => {
            if (contact[key]) {
                contactInfos.push({
                    title: cleanVal.string(contact[key]),
                    avatar: getIconComponent({ icon: contactInfosToDisplayIcons[i] }),
                });
            }
        });
    }
    return contactInfos;
};

const getExpertisesInfo = (
    expertises?: (ExpertiseDto | null)[] | null
): SimpleListItemProps[] | undefined => {
    if (expertises && expertises.length > 0) {
        return expertises.map((expertise) => ({
            title: cleanVal.string(expertise?.description),
            avatar: getIconComponent({ imgSrc: expertise?.iconUrl }),
        }));
    } else {
        return undefined;
    }
};

const getPersonalInformation = (
    birthDate?: string | null,
    address?: AddressDto | null
): SimpleListItemProps[] | undefined => {
    const oneLineAddress = [];
    const listItem = [];

    if (address) {
        if (address.addressLine1) oneLineAddress.push(address.addressLine1);
        if (address.addressLine2) oneLineAddress.push(' ' + address.addressLine2);
        if (address.city) oneLineAddress.push(' ' + address.city);
        if (address.postalCode) oneLineAddress.push(' ' + address.postalCode);
    }

    if (oneLineAddress.length > 0)
        listItem.push({
            title: oneLineAddress.join(','),
            avatar: getIconComponent({ icon: 'RoadSign' }),
        });

    if (birthDate)
        listItem.push({
            title: displayFormatedDate(birthDate, MOMENT_PARSING_FORMAT),
            avatar: getIconComponent({ icon: 'Calendar' }),
        });

    if (listItem.length > 0) {
        return listItem;
    } else {
        return undefined;
    }
};

const getProfilPricacy = (t: any, val?: boolean | null): SimpleListItemProps[] => [
    {
        title: val
            ? t('User.contacts_profile_is_private')
            : t('User.contacts_profile_is_not_private'),
        avatar: getIconComponent({ icon: 'Padlock' }),
    },
];

interface ContactDetails {
    introduction?: SimpleListItemProps[];
    expertises?: SimpleListItemProps[];
    contactInformation?: SimpleListItemProps[];
    personalInformation?: SimpleListItemProps[];
    profilePrivacy?: SimpleListItemProps[];
    company?: ReactNode | undefined;
}

interface ContactDetailsProps {
    contact: MemberDetailsDto;
}

const ContactDetailsBody: FunctionComponent<ContactDetailsProps> = ({contact}) => {
    const {t} = useTranslation();

    const getCompanies = (
        companies?: (MemberDetailsMembershipDto | null)[] | null
    ): ReactNode | undefined => {
        return companies && companies.length > 0
            ? companies.map((company) => (
                  <SimpleListContentWithSeeDetails
                      key={company?.id}
                      title={company?.name ?? ''}
                      imageUrl={company?.imageUrl ?? ''}
                      defaultIcon={<Icon iconName="Company" />}
                      seeDetailsUrl={`/companies/${company?.id}/dashboard`}
                  />
              ))
            : undefined;
    };

    const contactDetails: ContactDetails = {
        introduction: valOrUndefined(contact?.introduction),
        contactInformation: getContactInformation(contact?.contactInfo),
        expertises: getExpertisesInfo(contact?.expertises),
        personalInformation: getPersonalInformation(
            contact?.birthDate,
            contact?.address
        ),
        profilePrivacy: getProfilPricacy(t, contact?.isPrivate),
        company: getCompanies(contact?.memberships),
    };

    return (
        <div className="ContactDetailsBody">
            <Row gutter={formGutter}>
                <Col span={12}>
                    {contactDetails?.company && (
                        <SimpleList className="companies" title={t(`companies`)}>
                            {contactDetails.company}
                        </SimpleList>
                    )}

                    {contactDetails?.introduction && (
                        <SimpleList title={t(`introduction`)} data={contactDetails.introduction} />
                    )}

                    {contactDetails?.expertises && (
                        <SimpleList
                            className="expertises"
                            title={t(`expertises`)}
                            data={contactDetails.expertises}
                        />
                    )}
                </Col>

                <Col span={12}>
                    {contactDetails?.contactInformation && (
                        <SimpleList
                            title={t(`contact_information`)}
                            data={contactDetails.contactInformation}
                        />
                    )}

                    {contactDetails?.personalInformation && (
                        <SimpleList
                            title={t(`personal_information`)}
                            data={contactDetails.personalInformation}
                        />
                    )}

                    {contactDetails?.profilePrivacy && (
                        <SimpleList
                            title={t(`profile_privacy`)}
                            data={contactDetails?.profilePrivacy}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default ContactDetailsBody;