//TODO ceci risque d'etre dangereux si un interface dto perd une cle sur laquelle on sort.
// ts ne semble pas avoir de solution simple pour enumerer les cle d'une interface pour l'instant et le temps manque en ce moment... fingers crossed.
export const orderObjectArrayAlphabeticallyByProperty = (array: Array<any>, propertyOrderedBy: string): Array<any> => {
    return array.sort((a: any, b: any) => {
        const nameA = a[propertyOrderedBy].toLowerCase();
        const nameB = b[propertyOrderedBy].toLowerCase();
        if (nameA < nameB)
            //sort string ascending
            return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
    });
};



/**
* Returns the index of the last element in the array where predicate is true, and -1
* otherwise.
* @param array The source array to search in
* @param predicate find calls predicate once for each element of the array, in descending
* order, until it finds one where predicate returns true. If such an element is found,
* findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
*/
export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
    let l = array.length;
    while (l--) {
        if (predicate(array[l], l, array))
            return l;
    }
    return -1;
}