import { InfoCircleOutlined } from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    Popover,
    Row,
    Select,
    TimePicker,
    Typography,
} from 'antd';
import { Store } from 'antd/es/form/interface';
import { Gutter } from 'antd/es/grid/row';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { UploadFile } from 'antd/lib/upload/interface';
import { AmenityTypeDto } from 'Api/Features/Amenities/Dtos/AmenityTypeDto';
import { ConferenceRoomDensitySyncSettingsDto } from 'Api/Features/Amenities/Dtos/ConferenceRooms/ConferenceRoomDensitySyncSettingsDto';
import { ConferenceRoomDto } from 'Api/Features/Amenities/Dtos/ConferenceRooms/ConferenceRoomDto';
import { ConferenceRoomGoogleCalendarSyncSettingsDto } from 'Api/Features/Amenities/Dtos/ConferenceRooms/ConferenceRoomGoogleCalendarSyncSettingsDto';
import { ConferenceRoomOffice365SyncSettingsDto } from 'Api/Features/Amenities/Dtos/ConferenceRooms/ConferenceRoomOffice365SyncSettingsDto';
import { CreateAmenityRequestDto } from 'Api/Features/Amenities/Dtos/CreateAmenityRequestDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { MembershipDto } from 'Api/Features/Memberships/Dtos/MembershipDto';
import BaseModal from 'Components/base-modal/base-modal';
import { ConferenceRoom, User } from 'Components/icons';
import { ImagePicker } from 'Components/image-picker';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import debounce from 'lodash.debounce';
import { AvailableAmenities } from 'Models/Amenities/AvailableAmenities';
import { ConferenceRoomBookingDurations } from 'Models/Amenities/ConferenceRoomBookingDurations';
import { BILLION, MINUTES_IN_A_DAY } from 'Models/Constants';
import moment, { Moment } from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AmenityService } from 'Services/AmenityService';
import { MembershipService } from 'Services/MembershipService';
import { theme } from 'variant';
import { ValidatedFormItem } from '../../../../../../Components/validated-form-item';
import { useFormValidation, useService, useStores } from '../../../../../../Hooks';
import { CreateConferenceRoomSchema } from '../../../../../../Schemas';
import './create-conference-room.less';

const { Title } = Typography;
const { Option } = Select;
const timeFormat = 'HH:mm A';

const formGutter: [Gutter, Gutter] = [40, 0];

interface ConferenceRoomModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    campusId?: string;
    conferenceRoom?: ConferenceRoomDto;
}

const ConferenceRoomModal: FunctionComponent<ConferenceRoomModalProps> = ({
    visible,
    onComplete,
    campusId,
    conferenceRoom,
}) => {
    const { t } = useTranslation();
    const pageSize = 25;
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const membershipsService = useService(MembershipService);
    const amenityService = useService(AmenityService);
    const [form] = Form.useForm();
    const [companyCurrentPage, setCompanyCurrentPage] = useState(0);
    const [companySearchTerm, setCompanySearchTerm] = useState('');
    const [companyMaxResults, setCompanyMaxResults] = useState(false);
    const [membershipSelectOptions, setMembershipSelectOptions] = useState<SelectCustomOption[]>(
        []
    );
    const [selectedMembershipOptions, setSelectedMembershipOptions] = useState<
        SelectCustomOption[]
    >([]);
    const [companySearchResults, setCompanySearchResults] = useState<MembershipDto[]>([]);
    const [privateRoom, setPrivateRoom] = useState(false || conferenceRoom?.isPrivate);
    const [selectedPrivateMembershipIds, setSelectedPrivateMembershipIds] = useState<string[]>([]);
    const [hasSameDayLimit, setSameDayLimit] = useState<boolean>(
        conferenceRoom?.sameDayMaximumTotalBookingDuration !== null &&
            conferenceRoom?.sameDayMaximumTotalBookingDuration !== undefined
    );
    const [hasContiguousLimit, setContiguousLimit] = useState<boolean>(
        conferenceRoom?.maximumContiguousBookingDuration !== null &&
            conferenceRoom?.maximumContiguousBookingDuration !== undefined
    );
    const [hasDensitySync, setHasDensitySync] = useState<boolean>(false);
    const [densityId, setDensityId] = useState<string | undefined>();
    const [hasGoogleCalendarSync, setHasGoogleCalendarSync] = useState(false);
    const [googleCalendarId, setGoogleCalendarId] = useState<string | undefined>();
    const [hasOfficeSync, setHasOfficeSync] = useState<boolean>(false);
    const [roomMailBoxId, setRoomMailBoxId] = useState<string | undefined>();
    const [appClientId, setAppClientId] = useState<string | undefined>();
    const [appTenantId, setAppTenantId] = useState<string | undefined>();
    const [appClientSecret, setAppClientSecret] = useState<string | undefined>();
    const [imageDetails, setImageDetails] = useState<ImageDetails[]>();

    const [allDay, setAllDay] = useState(false);
    const [openingTime, setOpeningTime] = useState<Moment | undefined>();
    const [closingTime, setClosingTime] = useState<Moment | undefined>();
    const [acceptsDayPass, setDayPass] = useState<boolean>(true);
    const [selectedAmenities, setSelectedAmenities] = useState<AvailableAmenities[]>([]);

    const [selectLoading, setSelectLoading] = useState(false);

    const [errors, validateForm, resetErrors] = useFormValidation(CreateConferenceRoomSchema, form);

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const parseSelectedAmenities = useCallback(() => {
        if (conferenceRoom) {
            const amenities = [];
            if (conferenceRoom.hasPhone) amenities.push(AvailableAmenities.Phone);
            if (conferenceRoom.hasScreenSharing) amenities.push(AvailableAmenities.ScreenSharing);
            if (conferenceRoom.hasWhiteboard) amenities.push(AvailableAmenities.Whiteboard);
            if (conferenceRoom.hasTv) amenities.push(AvailableAmenities.TV);

            setSelectedAmenities(amenities);
        }
    }, [conferenceRoom]);

    const determineOpeningHours = useCallback(() => {
        if (
            conferenceRoom !== undefined &&
            conferenceRoom?.openingTime == null &&
            conferenceRoom?.closingTime == null
        ) {
            setAllDay(true);
            setOpeningTime(undefined);
            setClosingTime(undefined);
        }
    }, [conferenceRoom]);

    const createPrivateMembershipOptionsFromDTO = (array: any): SelectCustomOption[] => {
        return array?.map(
            (x: any) =>
                ({
                    value: x?.id,
                    label: x?.name,
                    imageUrl: x?.imageUrl,
                    badge: undefined,
                    content: x?.name,
                } as SelectCustomOption)
        );
    };

    useEffect(() => {
        setPrivateRoom(conferenceRoom?.isPrivate);
        setSameDayLimit(conferenceRoom?.sameDayMaximumTotalBookingDuration !== null);
        setContiguousLimit(conferenceRoom?.maximumContiguousBookingDuration !== null);
        setHasDensitySync(
            conferenceRoom?.densitySyncSettings?.synchronize !== undefined &&
                conferenceRoom.densitySyncSettings.synchronize
        );
        setDensityId(conferenceRoom?.densitySyncSettings?.googleCalendarId || undefined);
        setHasGoogleCalendarSync(
            conferenceRoom?.googleCalendarSyncSettings?.synchronize !== undefined &&
                conferenceRoom.googleCalendarSyncSettings.synchronize
        );
        setGoogleCalendarId(
            conferenceRoom?.googleCalendarSyncSettings?.googleCalendarId || undefined
        );
        setHasOfficeSync(
            conferenceRoom?.office365SyncSettings?.synchronize !== undefined &&
                conferenceRoom.office365SyncSettings.synchronize
        );
        setRoomMailBoxId(conferenceRoom?.office365SyncSettings?.roomMailboxId || undefined);
        setAppClientId(conferenceRoom?.office365SyncSettings?.clientId || undefined);
        setAppTenantId(conferenceRoom?.office365SyncSettings?.tenantId || undefined);
        setAppClientSecret(conferenceRoom?.office365SyncSettings?.clientSecret || undefined);
        setOpeningTime(
            conferenceRoom?.openingTime !== undefined
                ? moment(conferenceRoom?.openingTime, timeFormat)
                : undefined
        );
        setClosingTime(
            conferenceRoom?.closingTime !== undefined
                ? moment(conferenceRoom?.closingTime, timeFormat)
                : undefined
        );
        setDayPass(
            conferenceRoom?.acceptsDayPassBookings === undefined ||
                conferenceRoom?.acceptsDayPassBookings ||
                true
        );
        if (conferenceRoom?.privateMemberships != null) {
            setSelectedPrivateMembershipIds(
                conferenceRoom.privateMemberships.map((x) => x?.id as string)
            );
        }
        parseSelectedAmenities();
        setSelectedMembershipOptions(
            createPrivateMembershipOptionsFromDTO(conferenceRoom?.privateMemberships)
        );
        determineOpeningHours();
        form.setFieldsValue({
            name: conferenceRoom?.name,
            creditPointsPerBlock: conferenceRoom?.creditPointsPerBlock,
            numberOfSeats: conferenceRoom?.numberOfSeats,
            sameDayMaxBooking: conferenceRoom?.sameDayMaximumTotalBookingDuration,
            maxContiguous: conferenceRoom?.maximumContiguousBookingDuration
        })
    }, [conferenceRoom, determineOpeningHours, parseSelectedAmenities]);

    const handleDescriptionChange = (value: string): void => {
        form.setFieldsValue({ description: value });
    };

    const searchCompanies = async (page: number, searchTerm: string): Promise<MembershipDto[]> => {
        let results = [];

        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
        };

        [results] = await membershipsService.getMemberships(args);

        if (results.length < pageSize) {
            setCompanyMaxResults(true);
        }
        setSelectLoading(false);
        return results;
    };

    const debounceCompanySearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchCompanies(page, searchTerm).then((results) => {
                setCompanySearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    const resetCompanySearch = (): void => {
        setCompanyCurrentPage(0);
        setCompanySearchResults([]);
        setCompanySearchTerm('');
        setCompanyMaxResults(false);
    };

    useEffect(() => {
        setSelectLoading(true);
        debounceCompanySearch.current(companyCurrentPage, companySearchTerm);
    }, [companySearchTerm, companyCurrentPage, visible]);

    useEffect(() => {
        const searchResult = companySearchResults?.map(
            (x) =>
                ({
                    value: x?.id,
                    label: x?.name,
                    imageUrl: x?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(
            searchResult,
            selectedMembershipOptions
        );
        setMembershipSelectOptions(merged);
    }, [companySearchResults, conferenceRoom, selectedMembershipOptions]);

    const availableAmenityOptions = (): SelectCustomOption[] => {
        return Object.keys(AvailableAmenities).map(
            (x) =>
                ({
                    value: x,
                    label: x,
                    badge: undefined,
                } as SelectCustomOption)
        );
    };

    const handlePrivacyChange = (event: CheckboxChangeEvent) => {
        if (event.target.checked)
            debounceCompanySearch.current(companyCurrentPage, companySearchTerm);
        else resetCompanySearch();
        setPrivateRoom(event.target.checked);
    };

    const handleKeywordsChange = useCallback((value: string): void => {
        resetCompanySearch();
        setCompanySearchTerm(value);
    }, []);

    const handleMenuScrollToBottom = () => {
        if (!companyMaxResults) {
            setCompanyCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handleMembershipSelect = (values: any): void => {
        if (values === '') setSelectedPrivateMembershipIds([]);
        else {
            const options = values.map((x: any) => x as SelectCustomOption);
            setSelectedMembershipOptions(options);
            setSelectedPrivateMembershipIds(values?.map((x: any) => x.value));
        }
    };

    const handleAmenitySelect = (values: any): void => {
        if (values === '') setSelectedAmenities([]);
        else setSelectedAmenities(values?.map((x: any) => x.value));
    };

    const onSameDayCheckboxChange = (event: CheckboxChangeEvent) => {
        setSameDayLimit(event.target.checked);
    };

    const onContiguousCheckboxChange = (event: CheckboxChangeEvent) => {
        setContiguousLimit(event.target.checked);
    };

    const on24hChange = (event: CheckboxChangeEvent) => {
        setAllDay(event.target.checked);
        if (event.target.checked) {
            setOpeningTime(undefined);
            setClosingTime(undefined);
        }
    };

    const onOpeningTimeChange = (value: Moment | null) => {
        if (value !== null) setOpeningTime(value);
        else setOpeningTime(undefined);
    };

    const onClosingTimeChange = (value: Moment | null) => {
        if (value !== null) setClosingTime(value);
        else setClosingTime(undefined);
    };

    const onDayPassChange = (event: CheckboxChangeEvent) => {
        setDayPass(event.target.checked);
    };

    const densityLabel = (
        <div>
            <span style={{ paddingRight: '5px' }}>
                {t('ConferenceRoom.google_calendar_id_density')}
            </span>
            <Popover content={t('ConferenceRoom.density_popover')}>
                <InfoCircleOutlined />
            </Popover>
        </div>
    );

    const googleCalendarLabel = (
        <div>
            <span style={{ paddingRight: '5px' }}>{t('ConferenceRoom.google_calendar_id')}</span>
            <Popover content={t('ConferenceRoom.google_calendar_popover')}>
                <InfoCircleOutlined />
            </Popover>
        </div>
    );

    const createConferenceRoom = async (): Promise<void> => {
        const values = form.getFieldsValue();
        const data: CreateAmenityRequestDto = {
            name: values.name,
            creditPointsPerBlock: values.creditPointsPerBlock,
            numberOfSeats: values.numberOfSeats,
            hasPhone: values.selectedAmenities
                ?.map((x: any) => x.value)
                .includes(AvailableAmenities.Phone),
            hasScreenSharing: values.selectedAmenities
                ?.map((x: any) => x.value)
                .includes(AvailableAmenities.ScreenSharing),
            hasTv: values.selectedAmenities
                ?.map((x: any) => x.value)
                .includes(AvailableAmenities.TV),
            hasWhiteboard: values.selectedAmenities
                ?.map((x: any) => x.value)
                .includes(AvailableAmenities.Whiteboard),
            description: values.description,
            privateMembershipIds: values.privateMemberships?.map((x: any) => x.value),
            minimumBookingDuration: values.minimumBookingDuration,
            maximumBookingDuration: values.maximumBookingDuration,
            sameDayMaximumTotalBookingDuration: hasSameDayLimit ? values.sameDayMaxBooking : undefined,
            maximumContiguousBookingDuration: hasContiguousLimit ? values.maxContiguous : undefined,
            openingTime: openingTime === undefined ? null : openingTime.format('HH:mm:00'),
            closingTime: closingTime === undefined ? null : closingTime.format('HH:mm:00'),
            acceptsDayPassBookings: acceptsDayPass,
            densitySyncSettings: hasDensitySync
                ? ({
                      googleCalendarId: values.densityId,
                      synchronize: true,
                  } as ConferenceRoomDensitySyncSettingsDto)
                : null,
            googleCalendarSyncSettings: hasGoogleCalendarSync
                ? ({
                      googleCalendarId: values.googleCalendarId,
                      synchronize: true,
                  } as ConferenceRoomGoogleCalendarSyncSettingsDto)
                : null,
            office365SyncSettings: hasOfficeSync
                ? ({
                      roomMailboxId: values.roomMailboxId,
                      clientId: values.appClientId,
                      tenantId: values.appTenantId,
                      clientSecret: values.appClientSecret,
                      synchronize: true,
                  } as ConferenceRoomOffice365SyncSettingsDto)
                : null,
            campusId: campusId,
            type: AmenityTypeDto.ConferenceRoom,

            image: imageDetails
                ? ({
                      delete: imageDetails[0].isDeleted,
                      uploadBase64: imageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null,
        };

        if (!(await validateForm(data))) return;

        try {
            globalLoadingStore.addLoading();
            await amenityService.createAmenity(data);
            toastStore.toast({
                type: 'success',
                messageKey: `ConferenceRoom.conference_room_created`,
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const editConferenceRoom = async (): Promise<void> => {
        const oldValues = conferenceRoom as ConferenceRoomDto;
        const values = form.getFieldsValue();

        const data: CreateAmenityRequestDto = {
            name: values.name || oldValues.name,
            creditPointsPerBlock: values.creditPointsPerBlock || oldValues.creditPointsPerBlock,
            numberOfSeats: values.numberOfSeats || oldValues.numberOfSeats,
            hasPhone: selectedAmenities.includes(AvailableAmenities.Phone),
            hasScreenSharing: selectedAmenities.includes(AvailableAmenities.ScreenSharing),
            hasTv: selectedAmenities.includes(AvailableAmenities.TV),
            hasWhiteboard: selectedAmenities.includes(AvailableAmenities.Whiteboard),
            description: values.description || oldValues.description,
            privateMembershipIds: privateRoom ? selectedPrivateMembershipIds : [],
            minimumBookingDuration:
                values.minimumBookingDuration || oldValues.minimumBookingDuration,
            maximumBookingDuration:
                values.maximumBookingDuration || oldValues.maximumBookingDuration,
            sameDayMaximumTotalBookingDuration: hasSameDayLimit ? values.sameDayMaxBooking : undefined,
            maximumContiguousBookingDuration: hasContiguousLimit ? values.maxContiguous : undefined,
            openingTime: openingTime === undefined ? null : openingTime.format('HH:mm:00'),
            closingTime: closingTime === undefined ? null : closingTime.format('HH:mm:00'),
            acceptsDayPassBookings: acceptsDayPass,
            densitySyncSettings: hasDensitySync
                ? ({
                      googleCalendarId:
                          values.densityId ?? oldValues.densitySyncSettings?.googleCalendarId,
                      synchronize: true,
                  } as ConferenceRoomDensitySyncSettingsDto)
                : null,
            googleCalendarSyncSettings: hasGoogleCalendarSync
                ? ({
                      googleCalendarId:
                          values.googleCalendarId ??
                          oldValues.googleCalendarSyncSettings?.googleCalendarId,
                      synchronize: true,
                  } as ConferenceRoomGoogleCalendarSyncSettingsDto)
                : null,
            office365SyncSettings: hasOfficeSync
                ? ({
                      roomMailboxId:
                          values.roomMailboxId ?? oldValues.office365SyncSettings?.roomMailboxId,
                      clientId: values.appClientId ?? oldValues.office365SyncSettings?.clientId,
                      tenantId: values.appTenantId ?? oldValues.office365SyncSettings?.tenantId,
                      clientSecret:
                          values.appClientSecret ?? oldValues.office365SyncSettings?.clientSecret,
                      synchronize: true,
                  } as ConferenceRoomOffice365SyncSettingsDto)
                : null,
            campusId: campusId,
            type: AmenityTypeDto.ConferenceRoom,
            image: imageDetails
                ? ({
                      delete: imageDetails[0].isDeleted,
                      uploadBase64: imageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null,
        };

        if (!(await validateForm(data))) return;

        try {
            globalLoadingStore.addLoading();
            await amenityService.updateAmenity(data, conferenceRoom!.id as string);
            toastStore.toast({
                type: 'success',
                messageKey: `ConferenceRoom.conference_room_updated`,
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const submit = async (): Promise<void> => {
        if (conferenceRoom !== undefined) {
            await editConferenceRoom();
        } else {
            await createConferenceRoom();
        }
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <ConferenceRoom />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t(
                    `ConferenceRoom.conference_room_confirm_positive${
                        conferenceRoom !== undefined ? '_edit' : ''
                    }`
                ),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    return (
        <BaseModal
            visible={visible}
            title={
                conferenceRoom !== undefined
                    ? t('ConferenceRoom.edit_conference_room')
                    : t('Location.management_section_conference_rooms_create')
            }
            className="FormModal"
            onCancel={exit}
        >
            <div className="CreateConferenceRoom">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Title level={4}>{t('basic_information')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="name"
                                label={t('ConferenceRoom.conference_room_name')}
                                required={true}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="creditPointsPerBlock"
                                label={t('Location.conference_room_credits_per_hour')}
                                required={true}
                            >
                                <NumberFormat
                                    allowNegative={false}
                                    customInput={(props: any) => <Input {...props} />}
                                    isAllowed={(value: NumberFormatValues): boolean =>
                                        value.floatValue === undefined ||
                                        (value.floatValue !== undefined &&
                                            value.floatValue <= BILLION)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="numberOfSeats"
                                label={t('Location.conference_room_number_of_seats')}
                                required={true}
                            >
                                <NumberFormat
                                    allowNegative={false}
                                    customInput={(props: any) => <Input {...props} />}
                                    isAllowed={(value: NumberFormatValues): boolean =>
                                        value.floatValue === undefined ||
                                        (value.floatValue !== undefined &&
                                            value.floatValue <= BILLION)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="selectedAmenities"
                                label={t('ConferenceRoom.conference_room_selected_amenities')}
                            >
                                <SelectCustom
                                    options={availableAmenityOptions()}
                                    defaultImg={null}
                                    strongLabel={true}
                                    placeholder={t('SelectCustom.placeholder_default')}
                                    onChange={handleAmenitySelect}
                                    onKeywordsChange={handleKeywordsChange}
                                    onMenuScrollToBottom={handleMenuScrollToBottom}
                                    hideSelectedOptions={false}
                                    isMulti
                                    selected={selectedAmenities}
                                    isLoading={selectLoading}
                                    idAttribute="selectedAmenities"
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="description"
                                label={t('ConferenceRoom.description_title')}
                                className="description"
                            >
                                <Input hidden />
                            </ValidatedFormItem>
                            <ReactQuill
                                theme="snow"
                                className="description-quill"
                                onChange={handleDescriptionChange}
                                value={
                                    form.getFieldValue('description') ||
                                    conferenceRoom?.description ||
                                    null
                                }
                            />
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem errors={errors} name="privacy">
                                <Checkbox
                                    onChange={(e) => handlePrivacyChange(e)}
                                    defaultChecked={privateRoom}
                                >
                                    {t('private')}
                                </Checkbox>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    {privateRoom && (
                        <Row gutter={formGutter}>
                            <Col span={12}>
                                <ValidatedFormItem
                                    errors={errors}
                                    name="privateMemberships"
                                    label={t('companies')}
                                >
                                    <SelectCustom
                                        options={membershipSelectOptions}
                                        defaultImg={<User fill={theme['white']} />}
                                        strongLabel={true}
                                        placeholder={t('SelectCustom.placeholder_default')}
                                        onChange={handleMembershipSelect}
                                        onKeywordsChange={handleKeywordsChange}
                                        onMenuScrollToBottom={handleMenuScrollToBottom}
                                        hideSelectedOptions={false}
                                        isMulti
                                        selected={selectedPrivateMembershipIds}
                                        isLoading={selectLoading}
                                        idAttribute="privateMemberships"
                                    />
                                </ValidatedFormItem>
                            </Col>
                        </Row>
                    )}

                    <Title level={4}>{t('ConferenceRoom.conference_room_settings')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="minimumBookingDuration"
                                label={t('ConferenceRoom.minimum_booking_duration')}
                                required={true}
                            >
                                <Select
                                    defaultValue={
                                        conferenceRoom?.minimumBookingDuration || undefined
                                    }
                                >
                                    {ConferenceRoomBookingDurations.map((x) => (
                                        <Option key={x} value={x}>
                                            {x}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="maximumBookingDuration"
                                label={t('ConferenceRoom.maximum_booking_duration')}
                                required={true}
                            >
                                <Select
                                    defaultValue={
                                        conferenceRoom?.maximumBookingDuration || undefined
                                    }
                                >
                                    {ConferenceRoomBookingDurations.map((x) => (
                                        <Option key={x} value={x}>
                                            {x}
                                        </Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="sameDayMaxBooking"
                                label={t('ConferenceRoom.same_day_maximum_total_booking_duration')}
                            >
                                <NumberFormat
                                    allowNegative={false}
                                    customInput={(props: any) => (
                                        <Input
                                            addonBefore={
                                                <Checkbox
                                                    checked={hasSameDayLimit}
                                                    onChange={onSameDayCheckboxChange}
                                                ></Checkbox>
                                            }
                                            disabled={!hasSameDayLimit}
                                            {...props}
                                        />
                                    )}
                                    isAllowed={(value: NumberFormatValues): boolean =>
                                        value.floatValue === undefined ||
                                        (value.floatValue !== undefined &&
                                            value.floatValue <= MINUTES_IN_A_DAY)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="maxContiguous"
                                label={t('ConferenceRoom.maximum_contiguous_booking_duration')}
                            >
                                <NumberFormat
                                    allowNegative={false}
                                    customInput={(props: any) => (
                                        <Input
                                            addonBefore={
                                                <Checkbox
                                                    checked={hasContiguousLimit}
                                                    onChange={onContiguousCheckboxChange}
                                                ></Checkbox>
                                            }
                                            disabled={!hasContiguousLimit}
                                            {...props}
                                        />
                                    )}
                                    isAllowed={(value: NumberFormatValues): boolean =>
                                        value.floatValue === undefined ||
                                        (value.floatValue !== undefined &&
                                            value.floatValue <= MINUTES_IN_A_DAY)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem errors={errors} name="open24h">
                                <Checkbox onChange={on24hChange} defaultChecked={allDay}>
                                    {t('ConferenceRoom.open_24h')}
                                </Checkbox>
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem errors={errors} name="acceptsDayPass">
                                <Checkbox
                                    defaultChecked={acceptsDayPass}
                                    onChange={onDayPassChange}
                                >
                                    {t('ConferenceRoom.accepts_day_pass_booking')}
                                </Checkbox>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="openingTime"
                                label={t('ConferenceRoom.conference_room_opening_time')}
                            >
                                <TimePicker
                                    disabled={allDay}
                                    className="w-100"
                                    size="large"
                                    format={timeFormat}
                                    minuteStep={15}
                                    onChange={(val) => onOpeningTimeChange(val)}
                                    defaultValue={openingTime}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="closingTime"
                                label={t('ConferenceRoom.conference_room_closing_time')}
                            >
                                <TimePicker
                                    disabled={allDay}
                                    className="w-100"
                                    size="large"
                                    format={timeFormat}
                                    minuteStep={15}
                                    onChange={(val) => onClosingTimeChange(val)}
                                    defaultValue={closingTime}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>
                        {t('ConferenceRoom.conference_room_density_integration')}
                    </Title>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem errors={errors} name="syncDensity">
                                <Checkbox
                                    defaultChecked={hasDensitySync}
                                    onChange={(e) => setHasDensitySync(e.target.checked)}
                                >
                                    {`${t('ConferenceRoom.synchronize_bookings_to')} ${t(
                                        'ConferenceRoom.google_calendar_for_density'
                                    )}`}
                                </Checkbox>
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    {hasDensitySync && (
                        <Row gutter={formGutter}>
                            <Col span={24}>
                                <ValidatedFormItem
                                    errors={errors}
                                    name="densityId"
                                    label={densityLabel}
                                >
                                    <Input value={densityId} defaultValue={densityId} />
                                </ValidatedFormItem>
                            </Col>
                        </Row>
                    )}

                    <Title level={4}>
                        {t('ConferenceRoom.conference_room_google_calendar_integration')}
                    </Title>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem errors={errors} name="syncGoogleCalendar">
                                <Checkbox
                                    onChange={(e) => setHasGoogleCalendarSync(e.target.checked)}
                                    defaultChecked={hasGoogleCalendarSync}
                                >
                                    {`${t('ConferenceRoom.synchronize_bookings_to')} ${t(
                                        'ConferenceRoom.google_calendar'
                                    )}`}
                                </Checkbox>
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    {hasGoogleCalendarSync && (
                        <Row gutter={formGutter}>
                            <Col span={24}>
                                <ValidatedFormItem
                                    errors={errors}
                                    name="googleCalendarId"
                                    label={googleCalendarLabel}
                                >
                                    <Input
                                        value={googleCalendarId}
                                        defaultValue={googleCalendarId}
                                    />
                                </ValidatedFormItem>
                            </Col>
                        </Row>
                    )}

                    <Title level={4}>
                        {t('ConferenceRoom.conference_room_office_integration')}
                    </Title>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem errors={errors} name="syncOffice365">
                                <Checkbox
                                    onChange={(e) => setHasOfficeSync(e.target.checked)}
                                    defaultChecked={hasOfficeSync}
                                >
                                    {`${t('ConferenceRoom.synchronize_bookings_to')} ${t(
                                        'ConferenceRoom.office_365'
                                    )}`}
                                </Checkbox>
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    {hasOfficeSync && (
                        <Row gutter={formGutter}>
                            <Col span={12}>
                                <ValidatedFormItem
                                    errors={errors}
                                    name="roomMailboxId"
                                    label={t('ConferenceRoom.room_mailbox_id')}
                                >
                                    <Input value={roomMailBoxId} defaultValue={roomMailBoxId} />
                                </ValidatedFormItem>
                            </Col>
                            <Col span={12}>
                                <ValidatedFormItem
                                    errors={errors}
                                    name="appClientId"
                                    label={t('ConferenceRoom.app_client_id')}
                                >
                                    <Input value={appClientId} defaultValue={appClientId} />
                                </ValidatedFormItem>
                            </Col>
                            <Col span={12} />
                            <Col span={12}>
                                <ValidatedFormItem
                                    errors={errors}
                                    name="appTenantId"
                                    label={t('ConferenceRoom.app_tenant_id')}
                                >
                                    <Input value={appTenantId} defaultValue={appTenantId} />
                                </ValidatedFormItem>
                            </Col>
                            <Col span={12} />
                            <Col span={12}>
                                <ValidatedFormItem
                                    errors={errors}
                                    name="appClientSecret"
                                    label={t('ConferenceRoom.app_client_secret')}
                                >
                                    <Input
                                        value={appClientSecret}
                                        defaultValue={appClientSecret}
                                        type={'password'}
                                    />
                                </ValidatedFormItem>
                            </Col>
                        </Row>
                    )}

                    <Title level={4}>{t('main_image')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem errors={errors} name="image">
                                <ImagePicker
                                    images={
                                        conferenceRoom?.imageUrl
                                            ? [
                                                  {
                                                      url: conferenceRoom?.imageUrl,
                                                      uid: 'main',
                                                  } as UploadFile,
                                              ]
                                            : undefined
                                    }
                                    setImagesDetails={(images: ImageDetails[] | undefined) =>
                                        setImageDetails(images)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default ConferenceRoomModal;
