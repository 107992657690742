import Icon from 'Components/icons/icon';
import React, { FunctionComponent } from 'react';
import './activity-actions.less';

interface ActivityActionsProps {
    onClickEdit: () => void;
    onDeleteClick: () => void;
}

const ActivityActions: FunctionComponent<ActivityActionsProps> = ({
    onClickEdit,
    onDeleteClick,
}) => {
    return (
        <div className="activity-actions">
            <button onClick={onClickEdit}>
                <Icon iconName="PencilBorder" />
            </button>
            <button className="delete" onClick={onDeleteClick}>
                <Icon iconName="Trash" />
            </button>
        </div>
    );
};

export default ActivityActions;
