import { AmenityProxy } from 'Api/Features/Amenities/AmenityProxy';
import { AmenityDto } from 'Api/Features/Amenities/Dtos/AmenityDto';
import { ConferenceRoomDto } from 'Api/Features/Amenities/Dtos/ConferenceRooms/ConferenceRoomDto';
import { CreateAmenityRequestDto } from 'Api/Features/Amenities/Dtos/CreateAmenityRequestDto';
import { GetAmenitiesRequestDto } from 'Api/Features/Amenities/Dtos/GetAmenitiesRequestDto';
import { GetAmenityOrderResponseDto } from 'Api/Features/Amenities/Dtos/GetAmenityOrderResponseDto';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

interface AmenitiesResponse {
    items: ConferenceRoomDto[];
    totalItemCount: number;
}

@inject(AmenityProxy)
export class AmenityService extends ApiService {
    constructor(private readonly amenityProxy: AmenityProxy) {
        super();
    }

    public async getAmenity(id: string): Promise<AmenityDto | null> {
        const response: AmenityDto | null = await this.amenityProxy.getAmenity(id);
        return response || null;
    }

    public async getAmenities(request: GetAmenitiesRequestDto | null): Promise<AmenitiesResponse> {
        const response = await this.amenityProxy.getAmenities(request);

        if (response && response.items && response.totalItemCount) {
            return {
                items: response.items.filter((item): item is ConferenceRoomDto => item !== null),
                totalItemCount: response.totalItemCount,
            };
        } else {
            return { items: [], totalItemCount: 0 };
        }
    }

    public async deleteAmenity(id: string): Promise<void> {
        await this.amenityProxy.deleteAmenity(id);
    }

    public async getAmenitiesforLocation(id: string): Promise<Array<AmenityDto>> {
        const response: Array<ConferenceRoomDto | null> | null = await this.amenityProxy.getCampusAmenities(
            id
        );

        if (response) {
            return response.filter((x): x is ConferenceRoomDto => x !== null);
        }

        return [];
    }

    public async getAmenitiesForLocationOrder(
        id: string
    ): Promise<GetAmenityOrderResponseDto | null> {
        return await this.amenityProxy.getAmenityOrdering(id);
    }

    public async setAmenitiesForLocationOrder(
        id: string,
        method: OrderingMethodDto,
        ids: string[]
    ): Promise<void> {
        await this.amenityProxy.updateAmenityOrdering(id, { method: method, ids: ids });
    }

    public async createAmenity(request: CreateAmenityRequestDto): Promise<string | null> {
        return await this.amenityProxy.createAmenity(request);
    }

    public async updateAmenity(request: CreateAmenityRequestDto, id: string): Promise<void> {
        return await this.amenityProxy.updateAmenity(id, request);
    }
}
