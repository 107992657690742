import * as yup from 'yup';

export const EditAddOnSchema = yup.object({
           name: yup.string().required('Errors.required'),
           suggestedPrice: yup
               .number()
               .positive()
               .typeError('Errors.number_invalid')
               .required('Errors.required'),
           categoryId: yup.string().required('Errors.required'),
       });
