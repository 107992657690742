import { Button, Col, Form, Row, Select } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { CampusStatusTypeDto } from 'Api/Features/Campuses/Dtos/CampusStatusTypeDto';
import BaseModal from 'Components/base-modal/base-modal';
import Icon from 'Components/icons/icon';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { Coords } from 'google-map-react';
import { useService, useStores } from 'Hooks';
import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CampusService } from 'Services/CampusService';
import './edit-location-status.less';

const formGutter: [Gutter, Gutter] = [40, 0];
interface MarkerModel extends Coords {
    img: string;
}

interface SelectOption {
    id: string;
    name: string;
}

interface EditLocationStatusModalProps {
    visible: boolean;
    campusId?: string;
    onComplete: () => void;
}

const EditLocationStatusModal: FunctionComponent<EditLocationStatusModalProps> = ({
    visible,
    campusId,
    onComplete,
}) => {
    const campusService = useService(CampusService);
    const { globalLoadingStore, toastStore } = useStores();
    const { confirmationModalStore } = useStores();
    const [LocationStatus, setLocationStatus] = useState<CampusStatusTypeDto>();
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const selectOptions = (options: SelectOption[]): ReactElement[] => {
        return options.map((option) => (
            <Select.Option key={option.id} value={option.id}>
                {option.name}
            </Select.Option>
        ));
    };

    const statusOptions: SelectOption[] = Object.keys(CampusStatusTypeDto).map((option) => ({
        id: option,
        name: t(`Location.campus_status_type_${option}`),
    }));

    const dismiss = (): void => {
        onComplete();
        form.resetFields();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="Location" />,
                title: t('Booking.book_a_room_confirm_title'),
                message: t('Booking.book_a_room_confirm_message'),
                positiveText: t('Location.edit_location_status_confirm_positive'),
                negativeText: t('Booking.book_a_room_confirm_negative'),
            }))
        )
            return;
        dismiss();
    };

    const fetchCampusStatus = useCallback(async () => {
        globalLoadingStore.addLoading();

        try {
            if (campusId) {
                const campusResponse = await campusService.getCampus(campusId);

                setLocationStatus(campusResponse?.status);
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [campusId, campusService, globalLoadingStore, toastStore]);

    const submit = async (): Promise<void> => {
        globalLoadingStore.addLoading();

        const formValues = form.getFieldsValue();
        const data = {
            status: formValues.status,
        };

        try {
            if (campusId) await campusService.updateCampusStatus(campusId, data);

            dismiss();
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    useEffect(() => {
        form.setFieldsValue({
            status: LocationStatus,
        });
    }, [form, LocationStatus]);

    useEffect(() => {
        fetchCampusStatus();
    }, [fetchCampusStatus]);

    return (
        <BaseModal
            visible={visible}
            title={t('Location.edit_location_status')}
            className="EditLocationStatusModal FormModal"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem name="status">
                            <Select>{selectOptions(statusOptions)}</Select>
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" className="positive" htmlType="submit">
                        {t('save')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(EditLocationStatusModal);
