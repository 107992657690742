import React, { FunctionComponent } from 'react';
import { Select } from 'antd';
import { DateRangeDropdownCommunityEventEnum, DateRangeDropdownEnum } from 'Models/Filters/DateRangeDropdownOption';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface DateRangeSelectProps {
    onChange: (value: string) => void;
    value: string;
    optionType: typeof DateRangeDropdownEnum | typeof DateRangeDropdownCommunityEventEnum
}

const DateRangeSelect: FunctionComponent<DateRangeSelectProps> = ({ onChange, value, optionType }) => {
    const { t } = useTranslation();
    return (
        <Select
            onChange={onChange}
            style={{ width: '100%' }}
            value={value}
        >
            {Object.keys(optionType).map((x) => (
                <Option key={x} value={x}>
                    {t(`Filter.DateRangeDropdownEnum_${x}`)}
                </Option>
            ))}
        </Select>
    );
};

export default DateRangeSelect;
