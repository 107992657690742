import React, { FunctionComponent } from 'react';
import { Content } from 'antd/lib/layout/layout';
import Icon from 'Components/icons/icon';
import { ListSectionHeader } from 'Components/list-section-header';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { theme, images } from 'variant';
import SubscriptionTable from './subscription-table';
import './index.less';

const { usersHeader } = images;

const GlobalSubscriptions: FunctionComponent = observer(() => {
    const { t } = useTranslation();

    return (
        <>
            <ListSectionHeader
                title={t('Location.subscriptions_label')}
                subTitle={t('Location.subscriptions_section_subtitle')}
                defaultImg={<Icon iconName="Plan" fill={theme['primary-color']} />}
                backgroundImageUrl={usersHeader}
            />
            <Content className="GlobalSubscriptions">
                <SubscriptionTable globalList />
            </Content>
        </>
    );
});

export default GlobalSubscriptions;
