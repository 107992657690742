import { Button, Col, Form, Input } from 'antd';
import Row, { Gutter } from 'antd/lib/grid/row';
import { CampusFloorPlanFloorDto } from 'Api/Features/FloorPlans/Dtos/CampusFloorPlanFloorDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Delete, Units } from 'Components/icons';
import Icon from 'Components/icons/icon';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useStores } from 'Hooks';
import React, { ReactNode, useRef, useState } from 'react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'variant';
import { FloorPlanManager } from '.';
import './create-edit-floor-modal.less';

const formGutter: [Gutter, Gutter] = [40, 0];

export interface CreateEditFloorSubmitValue {
    floors: CampusFloorPlanFloorDto[];
}

interface CreateEditFloorModalProps {
    visible: boolean;
    onComplete: (success: boolean, submitValue?: CreateEditFloorSubmitValue) => void;
    editFloor?: CampusFloorPlanFloorDto;
    floorPlanManager: FloorPlanManager;
}

interface PlanFile {
    fileName?: string;
    svg: string | ArrayBuffer | null | undefined;
    size?: number;
}

const CreateEditFloorModal: FunctionComponent<CreateEditFloorModalProps> = ({
    visible,
    onComplete,
    editFloor,
    floorPlanManager,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { confirmationModalStore, toastStore } = useStores();
    const fileInputRefs = useRef<any[]>([]);

    const [floorFiles, setFloorFiles] = useState<PlanFile[]>(editFloor ? [{svg: editFloor.svg}] : []);
    const [canAddFloors, setCanAddFloors] = useState(!editFloor);
    //#endregion

    const dismiss = (success = false, submitValue?: CreateEditFloorSubmitValue): void => {
        onComplete(success, submitValue);
        form.resetFields();
    };

    //#region Submit / Exit
    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Units />,
                title: t('confirm_title'),
                message: t('confirm_message'),
                positiveText: t(`model_confirm_positive_${editFloor ? 'edit' : 'create'}`, {
                    param1: 'floor',
                }),
                negativeText: t('confirm_negative'),
            }))
        )
            return;
        dismiss();
    };

    const deleteFloor = () => {
        const submit: CreateEditFloorSubmitValue = {
            floors:  [],
        };
        dismiss(true, submit);
    }

    const submit = () => {
        const formValues = form.getFieldsValue();
        const submit: CreateEditFloorSubmitValue = {
            floors: formValues.floors?.map((floor: any, i: number) => ({
                svg: floorFiles[i]?.svg,
                name: formValues.floors[i].name,
            })) ?? [],
        };
        dismiss(true, submit);
    };
    //#endregion

    const handlePlanFileChange = (e: any, index: number) => {
        // Listen for files loaded using the load SVG button.
        if (floorPlanManager) {
            const file = e?.target?.files[0];

            if (file) {
                if (file?.size > 1024 * 1024 * 3) {
                    toastStore.toast({
                        type: 'error',
                        messageKey: 'The plan SVG file must be under 3Mb',
                    });
                    return;
                }

                const reader = new FileReader();
                reader.readAsText(file, 'UTF-8');

                // Once the SVG file is finished loading, update the page with it.
                reader.onload = (readerEvent) => {
                    if (!floorPlanManager.isSvg(readerEvent?.target?.result as any)) {
                        toastStore.toast({
                            type: 'error',
                            messageKey: 'The selected file does not appear to be a valid SVG file.',
                        });
                    } else {
                        setFloorFiles((oldState) => {
                            oldState[index] = {
                                fileName: file.name,
                                svg: readerEvent.target?.result as any,
                                size: file.size,
                            };
                            return [...oldState];
                        });
                    }
                };
            }
        }
    };

    const filesize = (size: number): string => {
        if (size < 1024 * 1024) {
            return `${(size / 1024).toFixed(2)} kb`;
        } else if (size > 1024 * 1024) {
            return `${(size / (1024 * 1024)).toFixed(2)} mb`;
        } else return '';
    };


    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={editFloor ? t('FloorPlan.edit_floor') : t('FloorPlan.add_floors')}
            className="FormModal"
            onCancel={exit}
            width={1450}
        >
            <div className="CreateEditFloorModal">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Form.List
                        name="floors"
                        initialValue={editFloor ? [{ name: editFloor.name }] : [{}]}
                    >
                        {(fields, { add, remove }): ReactNode => (
                            <>
                                {fields.map((field, index) => (
                                    <div className="floor-container" key={field.fieldKey}>
                                        <div className="floor-name">
                                            <ValidatedFormItem
                                                name={[index, 'name']}
                                                label={t('FloorPlan.floor_name')}
                                                required
                                                rules={[{ required: true, message: t('required') }]}
                                            >
                                                <Input />
                                            </ValidatedFormItem>
                                        </div>
                                        <div className="file-info-container">
                                            <div>
                                                <div className="warning">
                                                    {t('FloorPlan.svg_must_have_max')}
                                                </div>
                                                <div className="file-container">
                                                    <div className="seleced-file-info">
                                                        <div className="file-name">
                                                            {floorFiles[index]?.fileName}
                                                        </div>
                                                        {floorFiles[index]?.size && (
                                                            <div className="file-size">
                                                                {filesize(floorFiles[index]?.size!)}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div
                                                        className={`file-picker ${
                                                            floorFiles[index]?.fileName
                                                                ? 'has-file'
                                                                : ''
                                                        }`}
                                                    >
                                                        <ValidatedFormItem name={[index, 'file']}>
                                                            <div className="InputFile">
                                                                <label
                                                                    htmlFor={`floors_${index}_file`}
                                                                    onClick={() => {
                                                                        if (
                                                                            fileInputRefs.current[
                                                                                index
                                                                            ]
                                                                        )
                                                                            fileInputRefs.current[
                                                                                index
                                                                            ].click();
                                                                    }}
                                                                >
                                                                    {'Select plan file (SVG)'}
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    accept={'.svg'}
                                                                    id={`floors_${index}_file`}
                                                                    ref={(e) =>
                                                                        (fileInputRefs.current[
                                                                            index
                                                                        ] = e)
                                                                    }
                                                                    onChange={(event) => {
                                                                        handlePlanFileChange(
                                                                            event,
                                                                            index
                                                                        );
                                                                    }}
                                                                ></input>
                                                            </div>
                                                        </ValidatedFormItem>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {!editFloor && (
                                            <div className="delete">
                                                <span
                                                    onClick={(): void => {
                                                        remove(index);
                                                        if (floorFiles[index]) {
                                                            setFloorFiles((oldState) => {
                                                                const state = [...oldState];
                                                                state.splice(index, 1);
                                                                return state;
                                                            });
                                                        }
                                                        if (editFloor) setCanAddFloors(true);
                                                    }}
                                                    key={index}
                                                >
                                                    <Delete
                                                        fill={theme['primary-color']}
                                                        width={19}
                                                        height={24}
                                                    />
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                {canAddFloors && (
                                    <Row gutter={formGutter}>
                                        <Col span={24} className="dynamic-list-button-container">
                                            <Button
                                                className={
                                                    'ant-btn-primary ant-btn-circle ant-btn-lg ant-btn-icon-only'
                                                }
                                                onClick={(): void => {
                                                    add();
                                                    if (editFloor) setCanAddFloors(false);
                                                }}
                                            >
                                                <Icon iconName="Add" />
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                    </Form.List>

                    <div className={`actions ${editFloor ? 'three-btn' : ''}`}>
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        {editFloor && (
                            <Button type="primary" className="delete" onClick={() => deleteFloor()}>
                                {t('delete')}
                            </Button>
                        )}
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(CreateEditFloorModal);
