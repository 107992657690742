import { Col, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { ExpertiseDto } from 'Api/Features/Expertises/Dtos/ExpertiseDto';
import { MembershipDetailsPlusDto } from 'Api/Features/Memberships/Dtos/MembershipDetailsPlusDto';
import Icon from 'Components/icons/icon';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import SubscriptionOpportunitiesListItem from 'Components/subscription-opportunities/subscription-opportunities-list-item';
import { useService, useStores } from 'Hooks';
import { MOMENT_PARSING_FORMAT } from 'Models/Constants';
import { Opportunity } from 'Models/Opportunities/Opportunity';
import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OpportunityService } from 'Services/OpportunityService';
import { cleanVal } from 'Utils/NumberUtils';
import { displayFormatedDate } from 'Utils/TimeUtils';
import FloorPlanModal from 'Routes/authenticated/locations/id/subscriptions/id/modals/floor-plan-modal';
import './company-details-body.less';
import { SubscriptionDetailsDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsDto';
import { SubscriptionUnitDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionUnitDto';
import { MembershipSubscriptionDto } from 'Api/Features/Subscriptions/Dtos/MembershipSubscriptionDto';
import { oneLineAddress } from 'Utils/AddressHelper';
import SimpleListContentWithSeeDetails from 'Components/SimpleListContentWithSeeDetails';

const formGutter: [Gutter, Gutter] = [40, 0];

interface GetIconComponent {
    icon?: string | null;
    imgSrc?: string | null;
}

const getIconComponent = (params: GetIconComponent): ReactElement => (
    <ImageWithPlaceholder
        width="32"
        height="32"
        defaultImg={<Icon iconName={params.icon || 'QuestionMarkLined'} />}
        imgSrc={params.imgSrc || undefined}
    />
);

const getExpertisesInfo = (expertises?: (ExpertiseDto | null)[] | null): SimpleListItemProps[] => {
    if (expertises && expertises.length > 0) {
        return expertises.map((expertise) => ({
            title: cleanVal.string(expertise?.description),
            avatar: getIconComponent({ imgSrc: expertise?.iconUrl }),
        }));
    } else {
        return [];
    }
};

interface CompanyData {
    basicInformation: SimpleListItemProps[];
    presentation: SimpleListItemProps[];
    expertises: SimpleListItemProps[];
    address: SimpleListItemProps[];
    contactInformation: SimpleListItemProps[];
    legalContact: SimpleListItemProps[];
    locations: SimpleListItemProps[];
}


interface CompanyDetailsProps {
    company: MembershipDetailsPlusDto;
}

const CompanyDetailsBody: FunctionComponent<CompanyDetailsProps> = ({company}) => {
    const {t} = useTranslation();
    const [opportunities, setOpportunities] = useState<Opportunity[]>();
    const {globalLoadingStore, toastStore } = useStores();
    const opportunityService = useService(OpportunityService);

    const [floorplanModalState, setFloorplanModalState] = useState<{
        visible: boolean;
        specificUnitId?: string;
        subscription?: SubscriptionDetailsDto 
    }>({ visible: false, specificUnitId: undefined, subscription: undefined });

    const companyData: CompanyData = {
        basicInformation: [
            {
                title: t('billing_name'),
                description: cleanVal.string(company?.billingName),
            },
            {
                title: t('billing_email'),
                description: cleanVal.string(company?.billingEmail),
            },
            {
                title: t('Lead.number_of_employees'),
                description: cleanVal.string(company?.numberOfEmployees?.toString()),
            },
            {
                title: t('Lead.industry'),
                description: cleanVal.string(company?.industry),
            },
        ],
        presentation: [
            {
                description: cleanVal.string(company?.presentation),
            },
        ],
        expertises: getExpertisesInfo(company?.expertises),
        address: [
            {
                avatar: getIconComponent({ icon: 'RoadSign' }),
                description: oneLineAddress(company?.address),
            },
        ],
        legalContact: [
            {
                title: t('first_name'),
                description: cleanVal.string(company?.legalContact?.firstName),
            },
            {
                title: t('last_name'),
                description: cleanVal.string(company?.legalContact?.lastName),
            },
            {
                title: t('phone_number'),
                description: cleanVal.string(company?.legalContact?.phone),
            },
            {
                title: t('email'),
                description: cleanVal.string(company?.legalContact?.email),
            },
            {
                title: t('birth_date'),
                avatar: getIconComponent({ icon: 'Calendar' }),
                description: company?.legalContact?.birthDate
                    ? displayFormatedDate(
                          company?.legalContact?.birthDate,
                          MOMENT_PARSING_FORMAT
                      )
                    : '',
            },
            {
                avatar: getIconComponent({ icon: 'RoadSign' }),
                description: oneLineAddress(company?.legalContact?.address),
            },
        ],
        contactInformation: [
            {
                description: cleanVal.string(company?.contactInfo?.email),
                avatar: getIconComponent({ icon: 'Email' }),
            },
            {
                description: cleanVal.string(company?.contactInfo?.phone1),
                avatar: getIconComponent({ icon: 'Phone' }),
            },
            {
                description: cleanVal.string(company?.contactInfo?.phone2),
                avatar: getIconComponent({ icon: 'Phone' }),
            },
            {
                description: cleanVal.string(company?.contactInfo?.angelListUrl),
                avatar: getIconComponent({ icon: 'AngelList' }),
            },
            {
                description: cleanVal.string(company?.contactInfo?.twitterUrl),
                avatar: getIconComponent({ icon: 'Twitter' }),
            },
            {
                description: cleanVal.string(company?.contactInfo?.facebookUrl),
                avatar: getIconComponent({ icon: 'Facebook' }),
            },
            {
                description: cleanVal.string(company?.contactInfo?.linkedInUrl),
                avatar: getIconComponent({ icon: 'LinkedIn' }),
            },
            {
                description: cleanVal.string(company?.contactInfo?.instagramUrl),
                avatar: getIconComponent({ icon: 'Instagram' }),
            },
            {
                description: cleanVal.string(company?.contactInfo?.website),
                avatar: getIconComponent({ icon: 'Television' }),
            },
        ],
        locations: company?.campuses
            ? company?.campuses.map((location) => ({
                  title: location?.name ?? '',
                  avatar: (
                      <ImageWithPlaceholder
                          width="32"
                          height="32"
                          imgSrc={location?.mainImageUrl}
                          defaultImg={<Icon iconName="Location" />}
                      />
                  ),
              }))
            : [],
    };

    for (const property in companyData) {
        const currentProperty = property as keyof CompanyData;
        const isNotEmpty = (element: SimpleListItemProps): boolean => element.description !== '';

        companyData[currentProperty] = companyData[currentProperty].filter(isNotEmpty);
    }

    const fetchOpportunities = useCallback(async () => {
        try {
            globalLoadingStore.addLoading();
            //call api
            const [data] = await opportunityService.getOpportunities({
                membershipIds: [company.id!],
                page: 0,
                pageSize: 20,
            });
            if (data) setOpportunities(data);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [opportunityService]);

    useEffect(() => {
        fetchOpportunities();
    }, [fetchOpportunities])

    const handleViewFloorPlanClick = (sub: MembershipSubscriptionDto, unit:SubscriptionUnitDto) => {
        setFloorplanModalState({
            visible: true,
            specificUnitId: unit?.id!,
            subscription: { ...sub, membership: { imageUrl: company.imageUrl } },
        });
    }

    return (
        <div className="CompanyDetailsBody">
            <Row gutter={formGutter}>
                <Col span={12}>
                    {companyData.basicInformation.length > 0 && (
                        <SimpleList
                            className="large-description"
                            title={t('basic_information')}
                            data={companyData.basicInformation}
                        />
                    )}

                    {companyData && (
                        <SimpleList title={t(`owner`)}>
                            <SimpleListContentWithSeeDetails
                                title={`${company?.owner?.firstName} ${company?.owner?.lastName}`}
                                imageUrl={company?.owner?.imageUrl ?? ''}
                                defaultIcon={<Icon iconName="User" />}
                                seeDetailsUrl={`/contacts/${company?.owner?.id}/dashboard`}
                            />
                        </SimpleList>
                    )}

                    {companyData.locations.length > 0 && (
                        <SimpleList title={t(`locations`)} data={companyData.locations} />
                    )}

                    {company.subscriptions && company.subscriptions?.length > 0 && (
                        <SimpleList title={t(`units`)} className="units">
                            <div className="inner-container">
                                {company.subscriptions.map((sub) => {
                                    return sub?.units
                                        ?.filter((unit) => unit !== null)
                                        .map((unit) => unit!)
                                        .map((unit) => (
                                            <div className="unit" key={unit?.id ?? ''}>
                                                <div className="name">{unit?.name}</div>
                                                <div
                                                    className="view-floorplan"
                                                    onClick={(): void =>
                                                        handleViewFloorPlanClick(sub, unit)
                                                    }
                                                >
                                                    {t('FloorPlan.view_on_floorplan')}
                                                </div>
                                            </div>
                                        ));
                                })}
                            </div>
                        </SimpleList>
                    )}

                    {companyData.presentation.length > 0 && (
                        <SimpleList
                            className="large-description"
                            title={t('Membership.presentation')}
                            data={companyData.presentation}
                        />
                    )}

                    {companyData.expertises.length > 0 && (
                        <SimpleList
                            className="expertises clear-icon-background"
                            title={t('expertises')}
                            data={companyData.expertises}
                        />
                    )}
                </Col>

                <Col span={12}>
                    {companyData.address.length > 0 && (
                        <SimpleList
                            className="large-description clear-icon-background"
                            title={t('address')}
                            data={companyData.address}
                        />
                    )}

                    {companyData.legalContact.length > 0 && (
                        <SimpleList
                            className="large-description clear-icon-background"
                            title={t('legal_contact')}
                            data={companyData.legalContact}
                        />
                    )}

                    {companyData.contactInformation.length > 0 && (
                        <SimpleList
                            className="large-description clear-icon-background"
                            title={t('contact_information')}
                            data={companyData.contactInformation}
                        />
                    )}

                    {opportunities && opportunities.length > 0 && (
                        <SimpleList
                            title={t('Subscription.subscription_opportunities')}
                            className="related-opps"
                        >
                            {opportunities.map((opp) => (
                                <SubscriptionOpportunitiesListItem key={opp.id} opportunity={opp} />
                            ))}
                        </SimpleList>
                    )}
                </Col>
            </Row>

            {floorplanModalState.visible && (
                <FloorPlanModal
                    visible={floorplanModalState.visible}
                    onComplete={(): void => {
                        setFloorplanModalState({ visible: false, specificUnitId: undefined });
                    }}
                    subscription={floorplanModalState.subscription!}
                    specificUnitId={floorplanModalState.specificUnitId}
                />
            )}
        </div>
    );
}

export default CompanyDetailsBody;