// GENERATED FILE - DO NOT MODIFY
import { GetLeadEventsRequestDto } from 'Api/Features/LeadEvents/Dtos/GetLeadEventsRequestDto';
import { GetLeadEventsResponseDto } from 'Api/Features/LeadEvents/Dtos/GetLeadEventsResponseDto';
import { LeadEventDto } from 'Api/Features/LeadEvents/Dtos/LeadEventDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class LeadEventProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getLeadEvent(leadEventId: string): Promise<LeadEventDto | null> {
        const uri = this.buildUri(
            "/lead-events/{leadEventId}",
            { leadEventId: leadEventId },
            null
        );

        const data: LeadEventDto | null = await this.httpClient.get<LeadEventDto | null>(uri);
        return data;
    }

    public async getLeadEvents(leadId: string, request: GetLeadEventsRequestDto | null): Promise<GetLeadEventsResponseDto | null> {
        const uri = this.buildUri(
            "/leads/{leadId}/events",
            { leadId: leadId },
            { ...request || undefined }
        );

        const data: GetLeadEventsResponseDto | null = await this.httpClient.get<GetLeadEventsResponseDto | null>(uri);
        return data;
    }
}