import { LeadSourceDto } from 'Api/Features/Leads/Dtos/LeadSourceDto';
import { LeadStatusDto } from 'Api/Features/Leads/Dtos/LeadStatusDto';
import { PHONE_REGEX, WEBSITE_REGEX } from 'Models/Constants';
import * as yup from 'yup';

// See: https://github.com/jquense/yup/issues/58
yup.addMethod(yup.mixed, 'optional', function optional() {
    return this.transform((value) => {
        return (typeof value == 'string' && !value) ||
            (value instanceof Array && !value.length) ||
            value === null // allow to skip "nullable"
            ? undefined
            : value;
    });
});

//See: https://github.com/jquense/yup/issues/298
function OptionalNumber(typeErrorMessage = 'Please enter a valid number') {
    return yup
        .number()
        .transform(function (value, originalValue) {
            if (this.isType(value)) return value;
            if (!originalValue || !originalValue.trim()) {
                return null;
            }
            // we return the invalid original value
            return originalValue;
        })
        .nullable(true)
        .typeError(typeErrorMessage);
}

export const CreateLeadSchema = yup.object().shape(
    {
        firstName: yup.string().required('Errors.required'),
        lastName: yup.string().required('Errors.required'),
        phoneNumber: yup.string().max(25, 'Errors.must_be_under_twenty_five').optional().matches(PHONE_REGEX, 'Errors.phone_invalid'),
        phoneNumber2: yup.string().max(25, 'Errors.must_be_under_twenty_five').optional().matches(PHONE_REGEX, 'Errors.phone_invalid'),
        email: yup.string().required('Errors.required'),
        ownerAccountId: yup.string().required('Errors.required'),
        companyName: yup.string().required('Errors.required'),
        officeNumber: OptionalNumber(),
        numberOfEmployees: OptionalNumber(),
        source: yup.string().required('Errors.required'),
        sourceOther: yup.string().when('source', {
            is: LeadSourceDto.Other,
            then: yup.string().required('Errors.required'),
        }),
        status: yup.string().required('Errors.required'),
        website: yup.string().optional().matches(WEBSITE_REGEX, 'Enter valid url'),
        campusId: yup
            .string()
            .nullable()
            .when('status', {
                is: LeadStatusDto.Engaged,
                then: yup.string().required('Errors.required'),
            }),
        address: yup.object({
            addressLine1: yup.string().max(200, 'Errors.must_be_under_two_hundred'),
            city: yup.string().max(100, 'Errors.must_be_under_one_hundred'),
            state: yup.string().max(100, 'Errors.must_be_under_one_hundred'),
            country: yup.string().max(100, 'Errors.must_be_under_one_hundred'),
            postalCode: yup.string().max(25, 'Errors.must_be_under_twenty_five'),
        }),
    },
    [['source', 'sourceOther']]
);
