import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const MessageBubble: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 40 39" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.0002 1.60591C9.79631 1.60591 1.49492 8.52535 1.49492 17.0306C1.49492 22.1265 4.51353 26.8887 9.56965 29.7698L9.87298 29.9426L10.8821 35.5337L15.2174 31.9359L15.5699 32.009C16.9985 32.3053 18.4894 32.4556 20.0002 32.4556C30.2041 32.4556 38.5055 25.5359 38.5055 17.0306C38.5055 8.52535 30.2041 1.60591 20.0002 1.60591ZM9.86555 38.319L8.525 30.8917C3.18 27.724 0 22.5662 0 17.0306C0 7.7012 8.97194 0.111206 20 0.111206C31.0281 0.111206 40 7.7012 40 17.0306C40 26.3604 31.0281 33.9504 20 33.9504C18.5119 33.9504 17.0406 33.8134 15.6214 33.5434L9.86555 38.319Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.9103 16.1387C12.3139 16.1387 11.8284 16.624 11.8284 17.2204C11.8284 17.8168 12.3139 18.302 12.9103 18.302C13.5064 18.302 13.992 17.8168 13.992 17.2204C13.992 16.624 13.5064 16.1387 12.9103 16.1387ZM12.91 19.7968C11.4894 19.7968 10.3336 18.6409 10.3336 17.2204C10.3336 15.7998 11.4894 14.644 12.91 14.644C14.3306 14.644 15.4861 15.7998 15.4861 17.2204C15.4861 18.6409 14.3306 19.7968 12.91 19.7968Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.0005 16.1387C19.4041 16.1387 18.9185 16.624 18.9185 17.2204C18.9185 17.8168 19.4041 18.302 20.0005 18.302C20.5966 18.302 21.0822 17.8168 21.0822 17.2204C21.0822 16.624 20.5966 16.1387 20.0005 16.1387ZM20.0002 19.7968C18.5796 19.7968 17.4238 18.6409 17.4238 17.2204C17.4238 15.7998 18.5796 14.644 20.0002 14.644C21.4207 14.644 22.5763 15.7998 22.5763 17.2204C22.5763 18.6409 21.4207 19.7968 20.0002 19.7968Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.0907 16.1387C26.4943 16.1387 26.0087 16.624 26.0087 17.2204C26.0087 17.8168 26.4943 18.302 27.0907 18.302C27.6868 18.302 28.1723 17.8168 28.1723 17.2204C28.1723 16.624 27.6868 16.1387 27.0907 16.1387ZM27.0903 19.7968C25.6698 19.7968 24.514 18.6409 24.514 17.2204C24.514 15.7998 25.6698 14.644 27.0903 14.644C28.5109 14.644 29.6665 15.7998 29.6665 17.2204C29.6665 18.6409 28.5109 19.7968 27.0903 19.7968Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default MessageBubble;
