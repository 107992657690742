// GENERATED FILE - DO NOT MODIFY
import { CreateMessageRequestDto } from 'Api/Features/Messages/Dtos/CreateMessageRequestDto';
import { GetAccountMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetAccountMessagesRequestDto';
import { GetMembershipMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetMembershipMessagesRequestDto';
import { GetMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetMessagesRequestDto';
import { GetMessagesResponseDto } from 'Api/Features/Messages/Dtos/GetMessagesResponseDto';
import { MessageDto } from 'Api/Features/Messages/Dtos/MessageDto';
import { SetAllMessageReadStatusesRequestDto } from 'Api/Features/Messages/Dtos/SetAllMessageReadStatusesRequestDto';
import { SetMessageReadStatusesRequestDto } from 'Api/Features/Messages/Dtos/SetMessageReadStatusesRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class MessageProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getMessage(messageId: string | null): Promise<MessageDto | null> {
        const uri = this.buildUri(
            "/messages/{messageId}",
            { messageId: messageId },
            null
        );

        const data: MessageDto | null = await this.httpClient.get<MessageDto | null>(uri);
        return data;
    }

    public async getMessages(request: GetMessagesRequestDto | null): Promise<(MessageDto | null)[] | null> {
        const uri = this.buildUri(
            "/messages",
            null,
            { ...request || undefined }
        );

        const data: (MessageDto | null)[] | null = await this.httpClient.get<(MessageDto | null)[] | null>(uri);
        return data;
    }

    public async getAccountMessages(accountId: string | null, request: GetAccountMessagesRequestDto | null): Promise<GetMessagesResponseDto | null> {
        const uri = this.buildUri(
            "/accounts/{accountId}/messages",
            { accountId: accountId },
            { ...request || undefined }
        );

        const data: GetMessagesResponseDto | null = await this.httpClient.get<GetMessagesResponseDto | null>(uri);
        return data;
    }

    public async getMembershipMessages(membershipId: string | null, request: GetMembershipMessagesRequestDto | null): Promise<GetMessagesResponseDto | null> {
        const uri = this.buildUri(
            "/memberships/{membershipId}/messages",
            { membershipId: membershipId },
            { ...request || undefined }
        );

        const data: GetMessagesResponseDto | null = await this.httpClient.get<GetMessagesResponseDto | null>(uri);
        return data;
    }

    public async hasCurrentUserUnreadMessaged(forManagers?: boolean | null): Promise<(MessageDto | null)[] | null> {
        const uri = this.buildUri(
            "/messages/hasunread",
            null,
            { forManagers: (forManagers === null || forManagers === undefined) ? forManagers : forManagers.toString() }
        );

        const data: (MessageDto | null)[] | null = await this.httpClient.get<(MessageDto | null)[] | null>(uri);
        return data;
    }

    public async createMessage(request: CreateMessageRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/messages",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateMessageRequestDto | null, string>(uri, request);
        return data;
    }

    public async setMessageStatuses(request: SetMessageReadStatusesRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/messages/status",
            null,
            null
        );

        await this.httpClient.post<SetMessageReadStatusesRequestDto | null, void>(uri, request);
    }

    public async setAllMessageStatuses(request: SetAllMessageReadStatusesRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/messages/status/all",
            null,
            null
        );

        await this.httpClient.post<SetAllMessageReadStatusesRequestDto | null, void>(uri, request);
    }
}