import { ClosedLeadsReportProxy } from 'Api/Features/Reports/ClosedLeadsReportProxy';
import { ClosedLeadsReportDto } from 'Api/Features/Reports/Dtos/ClosedLeads/ClosedLeadsReportDto';
import { GetClosedLeadsReportRequestDto } from 'Api/Features/Reports/Dtos/ClosedLeads/GetClosedLeadsReportRequestDto';
import { inject } from 'aurelia-dependency-injection';
import { arrayBufferToXlsx } from 'Utils/blobs';
import { ApiService } from './ApiService';

@inject(ClosedLeadsReportProxy)
export class ClosedLeadsReportService extends ApiService {
    constructor(private readonly closedLeadsReportProxy: ClosedLeadsReportProxy) {
        super();
    }

    public async getClosedLeadsReport(request: GetClosedLeadsReportRequestDto | null): Promise<ClosedLeadsReportDto | null> {
        const data: ClosedLeadsReportDto | null = await this.closedLeadsReportProxy.getClosedLeadsReport(request);
        return data;
    }

    public async exportClosedLeadsReport(request: GetClosedLeadsReportRequestDto | null): Promise<Blob> {
        const data: ArrayBuffer = await this.closedLeadsReportProxy.exportClosedLeadsReport(request);
        return arrayBufferToXlsx(data);
    }
}
