import { inject } from 'aurelia-dependency-injection';
import { CheckInProxy } from '../Api/Features/CheckIns/CheckInProxy';
import { ApiService } from './ApiService';
import { CheckIn } from '../Models/CheckIns/CheckIn';
import { GetCheckInsResponseDto } from '../Api/Features/CheckIns/Dtos/GetCheckInsResponseDto';
import { arrayBufferToPdf } from 'Utils';
import { CheckInSourceDto } from 'Api/Features/CheckIns/Dtos/CheckInSourceDto';
import { GetCheckInsRequestDto } from 'Api/Features/CheckIns/Dtos/GetCheckInsRequestDto';

@inject(CheckInProxy)
export class CheckInService extends ApiService {
    constructor(private readonly checkInProxy: CheckInProxy) {
        super();
    }

    public async getCheckIns(request: GetCheckInsRequestDto | null): Promise<[CheckIn[], number]> {
        const response: GetCheckInsResponseDto | null = await this.checkInProxy.getCheckIns({
            ...request,
            sources: request?.sources?.map(
                (x) => CheckInSourceDto[x as keyof typeof CheckInSourceDto]
            ),
        });

        return [
            response?.items?.map((dto) => new CheckIn(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async getSummaryDocument(id: string): Promise<Blob> {
        const document = await this.checkInProxy.getCheckInSummaryDocument(id);
        return arrayBufferToPdf(document);
    }
}
