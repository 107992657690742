import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useService, useStores } from 'Hooks';
import { Col, Layout, Row } from 'antd';
import { SimpleList } from 'Components/simple-list';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { LeadService } from 'Services/LeadService';
import { LeadDetailStatusbar } from 'Components/lead-detail-statusbar';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import {
    User as UserIcon,
    Company as CompanyIcon,
    Coffee,
    Location as LocationIcon,
    Tick,
    Trash,
    File,
} from 'Components/icons';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { Lead } from 'Models/Leads/Lead';
import CreateLeadModal from '../create-lead';
import LeadActivityLog from 'Components/lead-activity-log/lead-activity-log';
import ChangeLabelModal from './change-label';
import { LeadStatusDto } from 'Api/Features/Leads/Dtos/LeadStatusDto';
import LostModal from './mark-as-lost';
import TouchBaseModal from 'Routes/authenticated/leads/id/touch-base';
import TransferOwnershipModal from 'Routes/authenticated/leads/id/transfer-ownership';
import { LeadEventDto } from 'Api/Features/LeadEvents/Dtos/LeadEventDto';
import moment from 'moment';
import EditMeetingModal from 'Routes/authenticated/leads/id/edit-meeting';
import EditReminderModal from 'Routes/authenticated/leads/id/edit-reminder';
import EditTaskModal from 'Routes/authenticated/leads/id/edit-task';
import { MeetingService } from 'Services/MeetingService';
import MoveToEngagedModal from './move-to-engaged-modal';
import { ReminderService } from 'Services/ReminderService';
import { useHistory } from 'react-router-dom';
import { DetailHeader } from 'Components/detail-header';
import CreateOpportunityModal from 'Routes/authenticated/opportunities/create-opportunity';
import { SalesActivityDto } from 'Api/Features/SalesActivities/Dtos/SalesActivityDto';
import { SalesActivityService } from 'Services/SalesActivityService';
import { GetSalesActivitiesRequestDto } from 'Api/Features/SalesActivities/Dtos/GetSalesActivitiesRequestDto';
import { SalesActivityTypeDto } from 'Api/Features/SalesActivities/Dtos/SalesActivityTypeDto';
import { MeetingDto } from 'Api/Features/Meetings/Dtos/MeetingDto';
import { ReminderDto } from 'Api/Features/Reminders/Dtos/ReminderDto';
import { TourService } from 'Services/TourService';
import EditTourModal from 'Routes/authenticated/opportunities/id/modals/edit-tour';
import { TourDto } from 'Api/Features/Tours/Dtos/TourDto';
import { TaskService } from 'Services/TaskService';
import { TaskDto } from 'Api/Features/Tasks/Dtos/TaskDto';
import { LeadSourceDto } from 'Api/Features/Leads/Dtos/LeadSourceDto';
import { LeadEventTypeDto } from 'Api/Features/LeadEvents/Dtos/LeadEventTypeDto';
import { StoredFileDto } from 'Api/Features/General/Dtos/StoredFileDto';
import AddFileModal, { AddFileFormModel } from 'Components/add-file-modal/index';
import { LeadFileService } from 'Services/LeadFileService';
import { CreateLeadFileRequestDto } from 'Api/Features/Leads/Dtos/CreateLeadFileRequestDto';
import { GetLeadFilesRequestDto } from 'Api/Features/Leads/Dtos/GetLeadFilesRequestDto';
import debounce from 'lodash.debounce';
import ReopenLeadModal from './reopen-lead-modal';
import EditNoteModal, { NoteEntityTypeEnum } from 'Components/edit-note-modal/edit-note-modal';
import { Tag } from 'Components/tag';
import { TagColors } from 'Components/tag/tag';
import { observer } from 'mobx-react';

const { Content } = Layout;

interface SimpleListItemProps {
    title: string;
    description?: string;
    avatar?: React.ReactNode;
}

const LeadDetail: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { globalLoadingStore, confirmationModalStore, toastStore, userPermissionsStore } =
        useStores();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Lead | undefined>(undefined);
    const [activities, setActivities] = useState<LeadEventDto[] | undefined>(undefined);
    const [saleActivities, setSalesActivities] = useState<
        (SalesActivityDto | null)[] | undefined | null
    >();
    const [upcomingActivities, setUpcomingActivities] = useState<
        (SalesActivityDto | null)[] | undefined
    >(undefined);
    const [pastActivities, setPastActivities] = useState<(SalesActivityDto | null)[] | undefined>(
        undefined
    );
    const [leadNote, setLeadNote] = useState<string | null | undefined>();

    const [contact, setContact] = useState<SimpleListItemProps[] | null>(null);
    const [company, setCompany] = useState<SimpleListItemProps[] | null>(null);
    const [locationInformation, setLocationInformation] = useState<SimpleListItemProps[] | null>(
        null
    );
    const [description, setDescription] = useState<SimpleListItemProps[] | null>(null);
    const [lostData, setLostData] = useState<SimpleListItemProps[] | null>(null);
    const [contactInformation, setContactInformation] = useState<SimpleListItemProps[] | null>(
        null
    );
    const [companyInformation, setCompanyInformation] = useState<SimpleListItemProps[] | null>(
        null
    );
    const [leadInformation, setLeadInformation] = useState<SimpleListItemProps[] | null>(null);

    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

    const [addFileModalOpen, setAddFileModalOpen] = useState(false);
    const [files, setFiles] = useState<StoredFileDto[]>([]);
    const [filesCurrentPage, setFilesCurrentPage] = useState(0);
    const [filesMaxResults, setFilesMaxResults] = useState(false);
    const filesPageSize = 5;
    const [filesAreLoading, setFilesAreLoading] = useState(false);

    const leadService = useService(LeadService);
    const meetingService = useService(MeetingService);
    const reminderService = useService(ReminderService);
    const taskService = useService(TaskService);
    const salesActivitiesService = useService(SalesActivityService);
    const tourService = useService(TourService);
    const leadFileService = useService(LeadFileService);
    const [touchBaseModalOpen, setTouchBaseModalOpen] = useState(false);
    const [markLeadAsLostModalOpen, setMarkLeadAsLostModalOpen] = useState(false);
    const [editMeetingModalOpen, setEditMeetingModalOpen] = useState(false);
    const [editReminderModalOpen, setEditReminderModalOpen] = useState(false);
    const [editMeetingId, setEditMeetingId] = useState<string>();
    const [editReminderId, setEditReminderId] = useState<string>();
    const [editTaskId, setEditTaskId] = useState<string>();
    const [editTaskModalOpen, setEditTaskModalOpen] = useState(false);
    const [moveToEngagedModalOpen, setMoveToEngagedModalOpen] = useState<boolean>(false);
    const [convertToOpportunityModalOpen, setConvertToOpportunityModalOpen] =
        useState<boolean>(false);
    const [editTourId, setEditTourId] = useState<string>();
    const [editTourModalOpen, setEditTourModalOpen] = useState(false);
    const [reopenLeadModalOpen, setReopenLeadModalOpen] = useState(false);
    const [editNoteModalOpen, setEditNoteModalOpen] = useState(false);

    const history = useHistory();

    const { id } = useParams<{ id: string }>();

    const [changeLabelModalOpen, setChangeLabelModalOpen] = useState<boolean>(false);
    const [transferOwnershipModalOpen, setTransferOwnershipModalOpen] = useState<boolean>(false);

    const setSimpleListsData = useCallback(
        (lead: Lead): void => {
            setContact([
                {
                    title: `${lead.salutation ?? ''} ${lead?.firstName} ${lead?.lastName}` || '',
                    avatar: (
                        <ImageWithPlaceholder width="32" height="32" defaultImg={<UserIcon />} />
                    ),
                },
            ]);

            setCompany([
                {
                    title: lead?.companyName || '',
                    avatar: (
                        <ImageWithPlaceholder width="32" height="32" defaultImg={<CompanyIcon />} />
                    ),
                },
            ]);

            if (lead.campus !== null) {
                setLocationInformation([
                    {
                        title: lead.campus?.name ?? '',
                        avatar: (
                            <ImageWithPlaceholder
                                width="32"
                                height="32"
                                imgSrc={lead.campus?.mainImageUrl}
                                defaultImg={<LocationIcon />}
                            />
                        ),
                    },
                ]);
            }

            const contactInfo: SimpleListItemProps[] = [];
            if (lead.phoneNumber) {
                contactInfo.push({
                    title: t(`phone_number`),
                    description: lead.phoneNumber,
                });
            }
            if (lead.phoneNumber2) {
                contactInfo.push({
                    title: t(`User.mobile_number`),
                    description: lead.phoneNumber2,
                });
            }
            if (lead.email) {
                contactInfo.push({
                    title: t(`email`),
                    description: lead.email,
                });
            }
            if (lead.jobPosition) {
                contactInfo.push({
                    title: t(`Lead.job_position`),
                    description: lead.jobPosition,
                });
            }
            setContactInformation(contactInfo);

            const companyInfo: SimpleListItemProps[] = [];
            if (lead.website) {
                companyInfo.push({
                    title: t(`website`),
                    description: lead.website,
                });
            }
            if (lead.numberOfEmployees) {
                companyInfo.push({
                    title: t(`Lead.number_of_employees`),
                    description: lead.numberOfEmployees.toString(),
                });
            }
            if (lead.industry) {
                companyInfo.push({
                    title: t(`Lead.industry`),
                    description: t(`Lead.industry_${lead.industry}`),
                });
            }
            setCompanyInformation(companyInfo);

            const leadInfo: SimpleListItemProps[] = [];
            if (lead.source) {
                if (lead.source === LeadSourceDto.Other) {
                    leadInfo.push({
                        title: t(`Lead.source`),
                        description: `${t(`Lead.source_${lead.source}`)} - ${lead.sourceOther}`,
                    });
                } else {
                    leadInfo.push({
                        title: t(`Lead.source`),
                        description: t(`Lead.source_${lead.source}`),
                    });
                }
            }
            if (lead.type) {
                leadInfo.push({
                    title: t('Lead.lead_type'),
                    description: lead.type,
                });
            }
            if (lead.officeNumber) {
                leadInfo.push({
                    title: t(`Lead.office_number`),
                    description: lead.officeNumber,
                });
            }
            setLeadInformation(leadInfo);

            if (lead.description) {
                setDescription([
                    {
                        title: lead.description,
                    },
                ]);
            }

            if (lead.lostReason) {
                setLostData([
                    {
                        title: t('Lead.loss_reason'),
                        description: t(`Lead.loss_reason_${lead.lostReason}`),
                    },
                ]);
            }
        },
        [t]
    );

    const currentDate = moment().utcOffset(0, true);
    const isUpcoming = (
        activity: SalesActivityDto,
        upcomingArray: SalesActivityDto[],
        pastArray: SalesActivityDto[],
        date?: string
    ) => {
        if (!currentDate.isSameOrAfter(date)) {
            upcomingArray.push(activity);
        } else {
            pastArray.push(activity);
        }
    };

    const fetch = useCallback(async () => {
        setLoading(true);
        globalLoadingStore.addLoading();
        try {
            // call api
            const lead = await leadService.getById(id);
            const salesActivities = await salesActivitiesService.getSalesActivities({
                leadIds: [id],
            } as GetSalesActivitiesRequestDto);
            const upcomingMeetings: SalesActivityDto[] = [];
            const pastMeetings: SalesActivityDto[] = [];
            const upcomingReminders: SalesActivityDto[] = [];
            const pastReminders: SalesActivityDto[] = [];
            const upcomingTours: SalesActivityDto[] = [];
            const pastTours: SalesActivityDto[] = [];
            const upcomingTasks: SalesActivityDto[] = [];
            const pastTasks: SalesActivityDto[] = [];

            if (salesActivities) {
                salesActivities.items?.forEach((activity) => {
                    switch (activity?.type) {
                        case SalesActivityTypeDto.Meeting:
                            isUpcoming(
                                activity,
                                upcomingMeetings,
                                pastMeetings,
                                activity.meeting?.startTime
                            );
                            break;
                        case SalesActivityTypeDto.Tour:
                            isUpcoming(
                                activity,
                                upcomingTours,
                                pastTours,
                                activity.tour?.startTime
                            );
                            break;
                        case SalesActivityTypeDto.Reminder:
                            isUpcoming(
                                activity,
                                upcomingReminders,
                                pastReminders,
                                activity.reminder?.date
                            );
                            break;
                        case SalesActivityTypeDto.Task:
                            isUpcoming(activity, upcomingTasks, pastTasks, activity.task?.date);
                            break;
                    }
                });
            }

            if (lead) {
                setData(lead);
                setSimpleListsData(lead);
                setUpcomingActivities([
                    ...upcomingMeetings,
                    ...upcomingReminders,
                    ...upcomingTours,
                    ...upcomingTasks,
                ]);
                setPastActivities([...pastMeetings, ...pastReminders, ...pastTours, ...pastTasks]);
                setSalesActivities(salesActivities?.items);
            }

            const [leadEvents] = await leadService.getLeadEventsById({
                pageSize: 10,
                page: 0,
                searchTerm: undefined,
                leadId: id,
            });
            if (leadEvents) setActivities(leadEvents);
        } finally {
            setLoading(false);
            globalLoadingStore.removeLoading();
        }
    }, [id, leadService, globalLoadingStore, setSimpleListsData, salesActivitiesService]);

    useEffect(() => {
        fetch();
    }, [
        changeLabelModalOpen,
        editModalOpen,
        touchBaseModalOpen,
        markLeadAsLostModalOpen,
        changeLabelModalOpen,
        transferOwnershipModalOpen,
        editMeetingModalOpen,
        editReminderModalOpen,
        editTaskModalOpen,
        moveToEngagedModalOpen,
        touchBaseModalOpen,
        editTourModalOpen,
        fetch,
    ]);

    const fetchNote = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            //call service
            const response = await leadService.getLeadNote(id);
            setLeadNote(response?.note);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, id, leadService, toastStore]);

    useEffect(() => {
        fetchNote();
    }, [fetchNote]);

    const onEditComplete = (): void => {
        setEditModalOpen(false);
    };
    const onTouchBaseComplete = (success: boolean) => {
        setTouchBaseModalOpen(false);
    };
    const onMarkAsLostComplete = (): void => {
        setMarkLeadAsLostModalOpen(false);
    };
    const onChangeLabelComplete = (): void => {
        setChangeLabelModalOpen(false);
    };
    const onTransferOwnershipComplete = (): void => {
        setTransferOwnershipModalOpen(false);
    };
    const onAddedActivityComplete = (): void => {
        fetch();
    };
    const onMoveToEngagedComplete = (): void => {
        setMoveToEngagedModalOpen(false);
    };
    const onConvertToOpportunityComplete = (success: boolean, id?: string): void => {
        setConvertToOpportunityModalOpen(false);
        if (success && id) history.push(`/opportunities/${id}/dashboard`);
    };

    const onEditMeetingComplete = (): void => {
        setEditMeetingId(undefined);
        setEditMeetingModalOpen(false);
        fetch();
    };
    const onEditReminderComplete = (): void => {
        setEditReminderId(undefined);
        setEditReminderModalOpen(false);
        fetch();
    };
    const onEditTaskComplete = (): void => {
        setEditTaskId(undefined);
        setEditTaskModalOpen(false);
        fetch();
    };
    const onEditTourComplete = (): void => {
        setEditTourId(undefined);
        setEditTourModalOpen(false);
        fetch();
    };

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            nameKey: 'Leads',
            path: 'leads',
        },
        {
            name: `${data?.firstName} ${data?.lastName}` || '',
            path: data?.id || '',
        },
    ];

    const getCtaOptions = (): ActionMenuOption[] => {
        let options: ActionMenuOption[] = [
            {
                key: 'edit',
                title: t('Lead.edit_lead_information'),
                action: (): void => setEditModalOpen(true),
            },
        ];

        if (data !== undefined) {
            switch (data.status) {
                case LeadStatusDto.New:
                    options = [
                        ...options,
                        {
                            key: 'TouchBase',
                            title: t('Lead.status_TouchBase'),
                            action: (): void => setTouchBaseModalOpen(true),
                        },
                        {
                            key: 'engaged',
                            title: t('Lead.move_to_engaged'),
                            action: (): void => setMoveToEngagedModalOpen(true),
                        },
                    ];
                    break;
                case LeadStatusDto.TouchBase:
                    options = [
                        ...options,
                        {
                            key: 'engaged',
                            title: t('Lead.move_to_engaged'),
                            action: (): void => setMoveToEngagedModalOpen(true),
                        },
                    ];
                    break;
            }

            if (data.status === LeadStatusDto.Lost) {
                options = [
                    {
                        key: 'reopen',
                        title: t('Lead.reopen_lead'),
                        action: (): void => setReopenLeadModalOpen(true),
                    },
                ];
            } else {
                options = [
                    ...options,
                    {
                        key: 'changeLabel',
                        title: t('Lead.lead_change_label'),
                        action: (): void => setChangeLabelModalOpen(true),
                    },
                    {
                        key: 'transferLeadOwnership',
                        title: t('Lead.transfer_lead_ownership'),
                        action: (): void => setTransferOwnershipModalOpen(true),
                    },
                    {
                        key: 'lost',
                        title: t('Lead.lead_mark_as_lost'),
                        action: (): void => setMarkLeadAsLostModalOpen(true),
                    },
                    {
                        key: 'convertToOpportunity',
                        title: t('Lead.convert_to_opportunity'),
                        action: (): void => setConvertToOpportunityModalOpen(true),
                    },
                ];
            }
        }
        if (userPermissionsStore.userIsAdministrator) {
            options.push({
                key: 'delete',
                title: t('Lead.delete_lead'),
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                action: (): Promise<void> => deleteLead(),
            });
        }

        return options;
    };

    const getUpcomingMeeting = (): MeetingDto | null | undefined => {
        const upcomingMeetings = upcomingActivities
            ?.filter((x) => x?.meeting)
            .filter((x) => {
                const currentDate = moment();
                const activityDate = x?.meeting?.startTime;
                return !currentDate.isSameOrAfter(activityDate);
            });
        return upcomingMeetings && upcomingMeetings.length > 0
            ? upcomingMeetings[upcomingMeetings.length - 1]!.meeting
            : undefined;
    };

    const getUpcomingReminder = (): ReminderDto | null | undefined => {
        const upcomingReminders = upcomingActivities
            ?.filter((x) => x?.reminder)
            ?.filter((x) => {
                const currentDate = moment();
                const activityDate = x?.reminder?.date;
                return !currentDate.isSameOrAfter(activityDate);
            });
        return upcomingReminders && upcomingReminders.length > 0
            ? upcomingReminders[upcomingReminders.length - 1]!.reminder
            : undefined;
    };

    const getUpcomingTask = (): TaskDto | null | undefined => {
        const upcomingTasks = upcomingActivities
            ?.filter((x) => x?.task)
            ?.filter((x) => {
                const currentDate = moment();
                const activityDate = x?.task?.date;
                return !currentDate.isSameOrAfter(activityDate);
            });
        return upcomingTasks && upcomingTasks.length > 0
            ? upcomingTasks[upcomingTasks.length - 1]!.task
            : undefined;
    };

    const getUpcomingTour = (): TourDto | null | undefined => {
        const upcomingTours = upcomingActivities
            ?.filter((x) => x?.tour)
            ?.filter((x) => {
                const currentDate = moment();
                const activityDate = x?.tour?.startTime;
                return !currentDate.isSameOrAfter(activityDate);
            });
        return upcomingTours && upcomingTours.length > 0
            ? upcomingTours[upcomingTours.length - 1]!.tour
            : undefined;
    };

    const lastStatusChange =
        activities?.find((e) => e.newStatus === data?.status) ??
        activities?.find((event) => event.type === LeadEventTypeDto.Created);

    const activityDate =
        data?.status === LeadStatusDto.TouchBase && getUpcomingMeeting()
            ? getUpcomingMeeting()?.startTime
            : undefined;

    const getMeetingToEdit = (): MeetingDto | null | undefined => {
        if (editMeetingId)
            return (
                saleActivities
                    ?.filter((item) => item?.type === SalesActivityTypeDto.Meeting)
                    .find((x) => x?.meeting?.id === editMeetingId)?.meeting || undefined
            );

        return getUpcomingMeeting();
    };

    const getReminderToEdit = (): ReminderDto | null | undefined => {
        if (editReminderId)
            return (
                saleActivities
                    ?.filter((item) => item?.type === SalesActivityTypeDto.Reminder)
                    ?.find((x) => x?.reminder?.id === editReminderId)?.reminder || undefined
            );

        return getUpcomingReminder();
    };

    const getTaskToEdit = (): TaskDto | null | undefined => {
        if (editTaskId)
            return (
                saleActivities
                    ?.filter((item) => item?.type === SalesActivityTypeDto.Task)
                    ?.find((x) => x?.task?.id === editTaskId)?.task || undefined
            );

        return getUpcomingTask();
    };

    const getTourToEdit = (): TourDto | null | undefined => {
        if (editTourId) {
            return (
                saleActivities
                    ?.filter((item) => item?.type === SalesActivityTypeDto.Tour)
                    ?.find((x) => x?.tour?.id === editTourId)?.tour || undefined
            );
        }

        return getUpcomingTour();
    };

    const cancelMeeting = async (id: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Coffee />,
                title: t(`Lead.delete_meeting`),
                message: t(`Lead.delete_meeting_confirm_message`),
                positiveText: t(`Lead.delete_meeting`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        //call delete
        await meetingService.cancelMeeting(id);
        toastStore.toast({
            type: 'success',
            messageKey: `Lead.meeting_delete_success`,
        });
        fetch();
    };

    const cancelReminder = async (id: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Coffee />,
                title: t(`Lead.delete_reminder`),
                message: t(`Lead.delete_reminder_confirm_message`),
                positiveText: t(`Lead.delete_reminder`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        //call delete
        await reminderService.cancelReminder(id);
        toastStore.toast({
            type: 'success',
            messageKey: `Lead.meeting_delete_success`,
        });
        fetch();
    };

    const cancelTask = async (id: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Tick />,
                title: t(`Lead.delete_task`),
                message: t(`Lead.delete_task_confirm_message`),
                positiveText: t(`Lead.delete_task`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        //call delete
        await taskService.cancelTask(id);
        toastStore.toast({
            type: 'success',
            messageKey: `Lead.task_delete_success`,
        });
        fetch();
    };

    const cancelTour = async (id: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <LocationIcon />,
                title: t(`Lead.delete_tour`),
                message: t(`Lead.delete_tour_confirm_message`),
                positiveText: t(`Lead.delete_tour`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        //call delete
        await tourService.cancelTour(id);
        toastStore.toast({
            type: 'success',
            messageKey: `Lead.tour_delete_success`,
        });
        fetch();
    };

    const deleteLead = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Trash />,
                title: t(`Lead.delete_lead`),
                message: t(`Lead.delete_lead_confirm_message`),
                positiveText: t(`Lead.delete_lead`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        //call delete
        await leadService.deleteLead(id);
        toastStore.toast({
            type: 'success',
            messageKey: `Lead.delete_lead_success`,
        });
        history.push('/leads');
    };

    const resetFilesSearch = (): void => {
        setFilesAreLoading(true);
        setFilesMaxResults(false);
        setFiles([]);
        setFilesCurrentPage(0);
    };

    const handleFileUploadSubmit = async (
        isSubmiting: boolean,
        formModel?: AddFileFormModel
    ): Promise<void> => {
        if (isSubmiting && formModel) {
            try {
                globalLoadingStore.addLoading();
                await leadFileService.createLeadFile(id, formModel as CreateLeadFileRequestDto);
                toastStore.toast({
                    type: 'success',
                    messageKey: t('file_added_successfully'),
                });
                setAddFileModalOpen(false);
                resetFilesSearch();
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                debounceFetchFiles.current(0);
            } catch (e) {
                if (e.response.data.modelState['file.FileName'] !== undefined) {
                    toastStore.toast({
                        type: 'error',
                        messageKey: e.response.data.modelState['file.FileName'][0],
                    });
                } else if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        } else {
            setAddFileModalOpen(false);
        }
    };

    const handleFileClick = (url?: string): void => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    const deleteFile = async (fileId: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <File />,
                title: t(`model_delete_confirm_title`, { param1: 'file' }),
                message: t(`action_cannot_be_undone`),
                positiveText: t('model_confirm_positive_delete', { param1: 'file' }),
                negativeText: t('model_confirm_negative_delete', { param1: 'file' }),
            }))
        )
            return;
        //call delete
        await leadFileService.deleteLeadFile(id, fileId);
        toastStore.toast({
            type: 'success',
            messageKey: t('model_delete_success', { param1: 'File' }),
        });
        resetFilesSearch();
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        debounceFetchFiles.current(0);
    };

    const handleFileDeleteClick = async (id?: string): Promise<void> => {
        if (id) {
            await deleteFile(id);
        }
    };

    const fetchFiles = async (currentPage: number): Promise<StoredFileDto[]> => {
        try {
            globalLoadingStore.addLoading();

            const [results, totalItemCount] = await leadFileService.getLeadFiles(id, {
                page: currentPage,
                pageSize: filesPageSize,
            } as GetLeadFilesRequestDto);

            if (results.length + filesPageSize * currentPage >= totalItemCount) {
                setFilesMaxResults(true);
            }

            return results;
        } finally {
            globalLoadingStore.removeLoading();
            setFilesAreLoading(false);
        }
    };

    const debounceFetchFiles = useRef(
        debounce((page: number) => {
            fetchFiles(page).then((results) => {
                setFiles((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    useEffect(() => {
        debounceFetchFiles.current(filesCurrentPage);
    }, [filesCurrentPage]);

    const handleFileListScrolledToBottom = (): void => {
        if (!filesMaxResults) {
            const currentPage = filesCurrentPage + 1;
            setFilesCurrentPage(currentPage);
        }
    };

    return (
        <div>
            <DetailHeader
                title={`${data?.firstName} ${data?.lastName}` || ''}
                loading={loading}
                routes={breadcrumbs}
                menuOptions={data?.status !== LeadStatusDto.Converted ? getCtaOptions() : undefined}
                tags={[
                    <Tag
                        key={data?.status ?? ''}
                        label={t(`Lead.status_${data?.status}`)}
                        color={
                            data?.status === LeadStatusDto.Lost
                                ? TagColors.disabled
                                : TagColors.default
                        }
                    />,
                ]}
                label={data?.label || ''}
                ownerData={data?.ownerAccount}
            />

            <Content className="leads-content">
                {data?.status !== LeadStatusDto.Converted && (
                    <LeadDetailStatusbar
                        leadStatus={data?.status || LeadStatusDto.New}
                        leadStatusActivityDate={
                            lastStatusChange ? moment.parseZone(lastStatusChange!.date) : undefined
                        }
                        leadUpcomingActivityDate={moment.parseZone(activityDate)}
                        setTouchBaseModalOpen={(visible: boolean): void =>
                            setTouchBaseModalOpen(visible)
                        }
                        setMoveToEngagedModalOpen={(visible: boolean): void =>
                            setMoveToEngagedModalOpen(visible)
                        }
                        setConvertToOpportunityModalOpen={(visible: boolean): void =>
                            setConvertToOpportunityModalOpen(visible)
                        }
                    />
                )}

                <Row gutter={40}>
                    <Col span={12}>
                        {contact && contact.length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <SimpleList title={t('contact')} data={contact} />
                                </Col>
                            </Row>
                        )}

                        {company && company.length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <SimpleList title={t('company')} data={company} />
                                </Col>
                            </Row>
                        )}

                        {locationInformation && locationInformation.length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <SimpleList title={t('location')} data={locationInformation} />
                                </Col>
                            </Row>
                        )}

                        {contactInformation && contactInformation.length > 0 && (
                            <Row>
                                <Col span={24}>
                                    {contactInformation && (
                                        <SimpleList
                                            title={t('contact_information')}
                                            data={contactInformation}
                                            className={'large-description'}
                                        />
                                    )}
                                </Col>
                            </Row>
                        )}

                        {companyInformation && companyInformation.length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <SimpleList
                                        title={t('company_information')}
                                        data={companyInformation}
                                        className={'large-description'}
                                    />
                                </Col>
                            </Row>
                        )}

                        {leadInformation && leadInformation.length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <SimpleList
                                        title={t('lead_information')}
                                        data={leadInformation}
                                        className={'large-description'}
                                    />
                                </Col>
                            </Row>
                        )}

                        {description && (
                            <Row>
                                <Col span={24}>
                                    <SimpleList title={t('description')} data={description} />
                                </Col>
                            </Row>
                        )}

                        {lostData && (
                            <Row>
                                <Col span={24}>
                                    <SimpleList
                                        title={t('Lead.lead_loss')}
                                        data={lostData}
                                        className={'large-description'}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Col>

                    <Col span={12}>
                        <LeadActivityLog
                            activities={activities}
                            onAddedActivityComplete={onAddedActivityComplete}
                            upcomingActivities={upcomingActivities}
                            pastActivities={pastActivities}
                            onEditMeetingClick={(id: string): void => {
                                setEditMeetingId(id);
                                setEditMeetingModalOpen(true);
                            }}
                            onEditReminderClick={(id: string): void => {
                                setEditReminderId(id);
                                setEditReminderModalOpen(true);
                            }}
                            onEditTaskClick={(id: string): void => {
                                setEditTaskId(id);
                                setEditTaskModalOpen(true);
                            }}
                            onDeleteMeetingClick={(id: string): void => {
                                cancelMeeting(id);
                            }}
                            onDeleteReminderClick={(id: string): void => {
                                cancelReminder(id);
                            }}
                            onDeleteTaskClick={(id: string): void => {
                                cancelTask(id);
                            }}
                            onEditTourClick={(id: string): void => {
                                setEditTourId(id);
                                setEditTourModalOpen(true);
                            }}
                            onDeleteTourClick={(id: string): void => {
                                cancelTour(id);
                            }}
                            leadLocationId={data?.campus?.id ?? undefined}
                            onAddFileClick={(): void => setAddFileModalOpen(true)}
                            files={files}
                            onFileClick={handleFileClick}
                            onFileDeleteClick={handleFileDeleteClick}
                            handleFileListScrolledToBottom={handleFileListScrolledToBottom}
                            filesAreLoading={filesAreLoading}
                            onEditNoteClick={(): void => setEditNoteModalOpen(true)}
                            note={leadNote}
                        />
                    </Col>
                </Row>
            </Content>

            {editModalOpen && (
                <CreateLeadModal visible={editModalOpen} onComplete={onEditComplete} lead={data} />
            )}
            <LostModal
                visible={markLeadAsLostModalOpen}
                onComplete={onMarkAsLostComplete}
                lead={data}
            />

            <TouchBaseModal
                visible={touchBaseModalOpen}
                onComplete={onTouchBaseComplete}
                lead={data}
            />
            <ChangeLabelModal
                visible={changeLabelModalOpen}
                onComplete={onChangeLabelComplete}
                lead={data}
            />
            <TransferOwnershipModal
                visible={transferOwnershipModalOpen}
                onComplete={onTransferOwnershipComplete}
                lead={data}
            />
            <EditMeetingModal
                visible={editMeetingModalOpen}
                onComplete={onEditMeetingComplete}
                meeting={getMeetingToEdit()}
            />
            <EditReminderModal
                visible={editReminderModalOpen}
                onComplete={onEditReminderComplete}
                reminder={getReminderToEdit()}
            />
            <EditTaskModal
                visible={editTaskModalOpen}
                onComplete={onEditTaskComplete}
                task={getTaskToEdit()}
            />
            <MoveToEngagedModal
                visible={moveToEngagedModalOpen}
                onComplete={onMoveToEngagedComplete}
                lead={data}
            />
            <EditTourModal
                visible={editTourModalOpen}
                onComplete={onEditTourComplete}
                tour={getTourToEdit()}
            />
            {convertToOpportunityModalOpen && (
                <CreateOpportunityModal
                    onComplete={onConvertToOpportunityComplete}
                    visible={convertToOpportunityModalOpen}
                    lead={data}
                />
            )}

            {reopenLeadModalOpen && (
                <ReopenLeadModal
                    onComplete={(success: boolean): void => {
                        setReopenLeadModalOpen(false);
                        if (success) fetch();
                    }}
                    visible={reopenLeadModalOpen}
                    lead={data}
                />
            )}

            {addFileModalOpen && (
                <AddFileModal visible={addFileModalOpen} onComplete={handleFileUploadSubmit} />
            )}

            {editNoteModalOpen && (
                <EditNoteModal
                    visible={editNoteModalOpen}
                    onComplete={(success: boolean) => {
                        if (success) fetchNote();
                        setEditNoteModalOpen(false);
                    }}
                    entityId={data?.id!}
                    entityType={NoteEntityTypeEnum.Lead}
                    note={leadNote}
                />
            )}
        </div>
    );
});

export default LeadDetail;
