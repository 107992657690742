import { Button, Form, } from 'antd';
import BaseModal from 'Components/base-modal/base-modal';
import {  useStores } from 'Hooks';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ImpactOnInvoices, { ImpactOnInvoicesType } from 'Components/impact-on-invoices/impact-on-invoices';
import './impact-on-invoices.less';
import { SubscriptionInvoicePreviewDto } from 'Api/Features/SubscriptionProposals/Dtos/SubscriptionInvoicePreviewDto';
import { Discount } from 'Components/icons';

interface EditDiscountsModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    createdInvoices?: SubscriptionInvoicePreviewDto[];
    adjustedInvoices?: SubscriptionInvoicePreviewDto[];
}

const ImpactOnInvoiceModal: FunctionComponent<EditDiscountsModalProps> = ({
    visible,
    onComplete,
    createdInvoices,
    adjustedInvoices
}) => {
    const { confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const { t } = useTranslation();

   
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Discount />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`Subscription.yes_leave_this_step`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        dismiss(true);
    };

    return (
        <BaseModal
            className="ImpactOnInvoicesModal FormModal"
            visible={visible}
            title={t('Subscription.edit_discounts')}
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                {adjustedInvoices && adjustedInvoices.length > 0 && (
                    <div className="impacted-invoices-container">
                        <ImpactOnInvoices
                            invoices={adjustedInvoices}
                            impactType={ImpactOnInvoicesType.ExistingInvoice}
                        />
                    </div>
                )}

                {createdInvoices && createdInvoices.length > 0 && (
                    <ImpactOnInvoices
                        invoices={createdInvoices}
                        impactType={ImpactOnInvoicesType.NewInvoices}
                    />
                )}
                
                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={exit}
                    >
                        {t('cancel')}
                    </Button>

                    <Button type="primary" className="positive" htmlType="submit">
                        {t('confirm')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(ImpactOnInvoiceModal);
