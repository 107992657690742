import { Layout, Typography } from 'antd';
import { EmptyPage } from 'Components/empty-page';
import { ItemWithButtons } from 'Components/item-with-buttons';
import { ItemWithButtonsProps } from 'Components/item-with-buttons/item-with-buttons';
import { TagColors } from 'Components/tag/tag';
import { useService, useStores } from 'Hooks';
import { AgreementTerms as AgreementTermsModel } from 'Models/AgreementTerms/AgreementTerms';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AgreementTermService } from 'Services/AgreementTermsService';
import { displayFormatedDate } from 'Utils/TimeUtils';
import { OpportunityRouteProps } from '..';
import './index.less';

interface AgreementTermsProps {
    routeProps?: OpportunityRouteProps;
}

const { Content } = Layout;

const AgreementTerms: FunctionComponent<AgreementTermsProps> = ({ routeProps }) => {
    const agreementTermService = useService(AgreementTermService);
    const { globalLoadingStore, toastStore } = useStores();
    const [allAgreementTermsList, setAllAgreementTermsList] = useState<
        (AgreementTermsModel | null)[] | undefined
    >();
    const { id } = useParams<{ id: string }>();
    const { data, setBreadcrumbs } = routeProps!;
    const { t } = useTranslation();

    const agreementTermsList: (AgreementTermsModel & ItemWithButtonsProps)[] | undefined = [];
    const archivedAgreementTermsList:
        | (AgreementTermsModel & ItemWithButtonsProps)[]
        | undefined = [];

    useEffect(() => {
        setBreadcrumbs([
            {
                nameKey: 'Opportunities',
                path: 'opportunities',
            },
            {
                name: data?.name ?? data?.membership?.name ?? '',
                path: `${id}/dashboard`,
            },
            {
                name: `Service agreements`,
                path: `service-agreements`,
            },
        ]);
    }, [data, id, setBreadcrumbs])

    const fetchAgreementTermsDocument = useCallback(
        async (agreementTermsId: string | undefined) => {
            globalLoadingStore.addLoading();

            try {
                if (agreementTermsId) {
                    const agreementTermsDocument = await agreementTermService.getAgreementTermsDocument(
                        agreementTermsId
                    );
                    const documentUrl = agreementTermsDocument?.url || undefined;

                    if (agreementTermsDocument !== null) window.open(documentUrl, '_blank');
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [agreementTermService, globalLoadingStore]
    );

    const getViewInPdfButton = (id: string | undefined): any => {
        return {
            label: t(`Agreement.view_in_pdf_agreement`),
            primary: true,
            onClick: (): void => {
                fetchAgreementTermsDocument(id);
            },
        };
    };

    const allAgreementTermsListFormated = allAgreementTermsList?.map((agreementTerms) => {
        let formatedAgreementTerms: AgreementTermsModel & ItemWithButtonsProps = {
            title: `${t(`Agreement.agreement_term_not_signed_sent_on`)} ${displayFormatedDate(
                agreementTerms?.dateCreated
            )}`,
            subTitle: agreementTerms?.fileName || undefined,
            isArchived: agreementTerms?.isArchived,
            buttonsList: [getViewInPdfButton(agreementTerms?.id)],
        };

        if (agreementTerms?.isArchived) {
            formatedAgreementTerms = {
                ...formatedAgreementTerms,
                tag: {
                    label: t(`Agreement.agreement_status_archived`),
                    color: TagColors.disabled,
                },
            };
        } else {
            if (agreementTerms?.isSigned) {
                formatedAgreementTerms = {
                    ...formatedAgreementTerms,
                    title: `${t(
                        `Agreement.agreement_term_signed_uploaded_on`
                    )} ${displayFormatedDate(agreementTerms?.dateCreated)}`,
                    tag: {
                        label: t(`Agreement.agreement_status_signed`),
                        color: TagColors.default,
                    },
                };
            } else {
                formatedAgreementTerms = {
                    ...formatedAgreementTerms,
                    buttonsList: [getViewInPdfButton(agreementTerms?.id)],
                };
            }
        }

        return formatedAgreementTerms;
    });

    if (allAgreementTermsListFormated)
        for (let i = 0; i < allAgreementTermsListFormated.length; i++) {
            const item = allAgreementTermsListFormated[i];

            if (item.isArchived) {
                archivedAgreementTermsList.push(item);
            } else {
                agreementTermsList.push(item);
            }
        }

    const fetchAgreementTermsList = useCallback(async () => {
        globalLoadingStore.addLoading();

        try {
            const agreementTermsList = await agreementTermService.getAgreementTerms({
                pageSize: 10,
                page: 0,
                searchTerm: undefined,
                opportunityIds: [id],
            });

            if (agreementTermsList?.items) setAllAgreementTermsList(agreementTermsList.items);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [agreementTermService, globalLoadingStore, id, toastStore]);

    useEffect(() => {
        fetchAgreementTermsList();
    }, [fetchAgreementTermsList]);

    return (
        <div className="AgreementTerms">
            {agreementTermsList.length > 0 && (
                <Content>
                    {agreementTermsList.map((agreementTerm, i) => (
                        <ItemWithButtons
                            key={i}
                            title={agreementTerm.title}
                            subTitle={agreementTerm.subTitle}
                            tag={agreementTerm.tag}
                            buttonsList={agreementTerm.buttonsList}
                            small={!(i === 0)}
                        />
                    ))}
                </Content>
            )}

            {archivedAgreementTermsList.length > 0 && (
                <Content className="archived">
                    <Typography.Title level={2}>
                        {t(`Agreement.label_archived_agreement`)}
                    </Typography.Title>

                    {archivedAgreementTermsList.map((agreementTerm, i) => (
                        <ItemWithButtons
                            key={i}
                            title={agreementTerm.title}
                            subTitle={agreementTerm.subTitle}
                            tag={agreementTerm.tag}
                            buttonsList={agreementTerm.buttonsList}
                            lightTheme={true}
                        />
                    ))}
                </Content>
            )}

            {agreementTermsList.length < 1 && archivedAgreementTermsList.length < 1 && (
                <Content className="AgreementTerms">
                    <EmptyPage
                        iconName={'Plan'}
                        title={t(`Agreement.no_signed_agreement`)}
                    />
                </Content>
            )}
        </div>
    );
};

export default AgreementTerms;
