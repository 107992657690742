import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Plan: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.3686 14.8864H0.631725V7.06923C1.44462 8.46928 2.96046 9.41276 4.69278 9.41276H6.37204L8.00015 11.041L9.62825 9.41276H11.3075C13.0398 9.41276 14.5557 8.46928 15.3686 7.06923V14.8864ZM5.38952 4.36014H10.5686V1.07604H5.38952V4.36014ZM9.07161 9.07602L7.99988 10.1478L6.92814 9.07602L7.99988 8.00423L9.07161 9.07602ZM9.67012 8.7812L7.99991 7.11099L6.3297 8.7812H4.69254C2.54454 8.7812 0.780966 7.10478 0.640755 4.99172H15.3591C15.2189 7.10478 13.4553 8.7812 11.3073 8.7812H9.67012ZM11.2001 4.36013V0.444443H4.75779V4.36013H0V15.518H16V4.36013H11.2001Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Plan;
