import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { MeetingProxy } from 'Api/Features/Meetings/MeetingProxy';
import { CreateMeetingRequestDto } from 'Api/Features/Meetings/Dtos/CreateMeetingRequestDto';
import { UpdateMeetingRequestDto } from 'Api/Features/Meetings/Dtos/UpdateMeetingRequestDto';
import { dateMomentToString } from 'Utils/TimeUtils';

@inject(MeetingProxy)
export class MeetingService extends ApiService {
    constructor(private readonly meetingProxy: MeetingProxy) {
        super();
    }

    public async createMeeting(request: CreateMeetingRequestDto): Promise<string | null> {
        request.startTime = dateMomentToString(request.startTime);
        request.endTime = dateMomentToString(request.endTime);
        
        return await this.meetingProxy.createMeeting(request);
    }

    public async updateMeeting(id: string, request: UpdateMeetingRequestDto): Promise<void> {
        request.startTime = dateMomentToString(request.startTime);
        request.endTime = dateMomentToString(request.endTime);

        await this.meetingProxy.updateMeeting(id, request);
    }

    public async cancelMeeting(id: string): Promise<void> {
        await this.meetingProxy.deleteMeeting(id);
    }
}
