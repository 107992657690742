import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { AddOnProxy } from 'Api/Features/AddOns/AddOnProxy';
import { AddOn } from 'Models/AddOn/AddOn';
import { GetAddOnsRequestDto } from 'Api/Features/AddOns/Dtos/GetAddOnsRequestDto';
import { CreateAddOnRequestDto } from 'Api/Features/AddOns/Dtos/CreateAddOnRequestDto';
import { UpdateAddOnRequestDto } from 'Api/Features/AddOns/Dtos/UpdateAddOnRequestDto';


@inject(AddOnProxy)
export class AddOnService extends ApiService {
    constructor(private readonly addOnProxy: AddOnProxy) {
        super();
    }

    public async getAddOn(addOnId: string): Promise<AddOn | null> {
        const data: AddOn | null = await this.addOnProxy.getAddOn(addOnId);
        return data;
    }

    public async getAddOns(request: GetAddOnsRequestDto | null): Promise<[AddOn[], number]> {
        const response = await this.addOnProxy.getAddOns(request);
        return [
            response?.items?.map((dto) => new AddOn(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async createAddOn(request: CreateAddOnRequestDto | null): Promise<string> {
        const data: string = await this.addOnProxy.createAddOn(request);
        return data;
    }

    public async updateAddOn(addonId: string, request: UpdateAddOnRequestDto | null): Promise<void> {
        await this.addOnProxy.updateAddOn(addonId, request);
    }

    public async deleteAddOn(addonId: string): Promise<void> {
        await this.addOnProxy.deleteAddOn(addonId);
    }
}
