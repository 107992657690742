import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useService, useStores } from 'Hooks';
import { theme } from 'variant';
import { Col, Layout, Row } from 'antd';
import { Delete, Units as UnitIcon } from 'Components/icons';
import { SimpleList } from 'Components/simple-list';
import { Unit } from 'Models/Units/Unit';
import { currencyFormatter } from 'Utils/NumberUtils';
import { UnitService } from 'Services/UnitService';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import './index.less';
import CreateUnitModal from '../create-unit';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import { UnitActiveStatusDto } from 'Api/Features/Units/Dtos/UnitActiveStatusDto';
import Tag, { TagColors } from 'Components/tag/tag';
import { UnitTypeDto } from 'Api/Features/Units/Dtos/UnitTypeDto';
import { DISPLAY_DATE_FORMAT } from 'Models/Constants';
import moment from 'moment';
import { UnitOccupancyDto } from 'Api/Features/Units/Dtos/UnitOccupancyDto';
import UnitOccupancy from 'Components/unit-occupancy';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';

const { Content } = Layout;

const UnitDetails: FunctionComponent = () => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const unitService = useService(UnitService);
    const history = useHistory();
    const [data, setData] = useState<Unit | undefined>(undefined);
    const [unitOccupancyData, setUnitOccupancyData] = useState<UnitOccupancyDto>();
    const [pricingInformation, setPricingInformation] = useState<SimpleListItemProps[] | null>(
        null
    );
    const [activeDateInformation, setActiveDatesInformation] = useState<
        SimpleListItemProps[] | null
    >(null);
    const [dataJacksInformation, setDataJacksInformation] = useState<SimpleListItemProps[] | null>(
        null
    );
    const [note, setNote] = useState<SimpleListItemProps[] | null>(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const { id, unitId } = useParams<{ id: string; unitId: string }>();

    const setSimpleListsData = useCallback(
        (unit: Unit): void => {
            const info: SimpleListItemProps[] = [
                {
                    title: t('name'),
                    description: unit.name || '',
                },
            ];
            if (unit.squareFeet) {
                info.push({
                    title: t('square_feet'),
                    description: unit.squareFeet.toString(),
                });
            }
            if (unit.numberOfDesks) {
                info.push({
                    title: t('Unit.number_of_desks'),
                    description: unit.numberOfDesks.toString(),
                });
            }
            if (unit.note) {
                info.push({
                    title: t('note'),
                    description: unit.note,
                });
            }

            const pricing: SimpleListItemProps[] = [];
            if (unit.marketPrice)
                pricing.push({
                    title: t('Unit.market_price'),
                    description: currencyFormatter(unit.marketPrice),
                });
            if (unit.sixMonthPrice) {
                pricing.push({
                    title: t('Unit.six_month_price'),
                    description: currencyFormatter(unit.sixMonthPrice),
                });
            }
            if (unit.twelveMonthPrice) {
                pricing.push({
                    title: t('Unit.twelve_month_price'),
                    description: currencyFormatter(unit.twelveMonthPrice),
                });
            }

            setPricingInformation(pricing);

            const activeDates: SimpleListItemProps[] = [];
            if (unit.activeStartDate) {
                activeDates.push({
                    title: t('start_date'),
                    description: moment(unit.activeStartDate).format(DISPLAY_DATE_FORMAT),
                });
            }
            if (unit.activeEndDate) {
                activeDates.push({
                    title: t('end_date'),
                    description: moment(unit.activeEndDate).format(DISPLAY_DATE_FORMAT),
                });
            }

            setActiveDatesInformation(activeDates);

            const dataJacks: SimpleListItemProps[] = [];
            if (unit.dataJacks) {
                dataJacks.push(
                    ...unit.dataJacks.filter((jack) => jack).map((jack) => ({ title: jack! }))
                );
            }

            setDataJacksInformation(dataJacks);

            if (unit.note)
                setNote(
                    [
                        {
                            description: unit.note,
                        },
                    ] ?? undefined
                );
        },
        [t]
    );

    const getBreadcrumb = (): BreadcrumbSegment[] => {
        return [
            {
                path: 'management',
                nameKey: 'Location.management_label',
            },
            {
                path: 'units',
                nameKey: 'units',
            },
            {
                path: data?.id || '',
                name: data?.name || '',
            },
        ];
    };

    const fetch = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const unit = await unitService.getUnit(unitId);
            if (unit) {
                setData(unit);
                setSimpleListsData(unit);
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
            setLoading(false);
        }
    }, [globalLoadingStore, unitService, unitId, toastStore, setSimpleListsData]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const fetchUnitOccupancy = useCallback(async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const response = await unitService.getUnitOccupancy(unitId);
            if (response) {
                setUnitOccupancyData(response);
            }
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
            setLoading(false);
        }
    }, [globalLoadingStore, unitService, unitId, toastStore]);

    useEffect(() => {
        fetchUnitOccupancy();
    }, [fetchUnitOccupancy]);

    const deleteUnit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                width: 850,
                icon: <Delete />,
                title: t(`model_confirm_title_delete`, { param1: 'unit' }),
                message: t(`action_cannot_be_undone`),
                positiveText: t(`model_confirm_positive_delete`, { param1: 'unit' }),
                negativeText: t(`model_confirm_negative_delete`, { param1: 'unit' }),
            }))
        )
            return;

        try {
            globalLoadingStore.addLoading();
            await unitService.deleteUnit(unitId);
            history.goBack();
        } catch (e) {
            if (e.response?.data?.error === 'E009011') {
                toastStore.toast({
                    type: 'error',
                    messageKey: t('Errors.unit_in_usage'),
                });
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const actions: ActionMenuOption[] = [
        {
            key: 'edit',
            title: t('Unit.edit_unit'),
            action: (): void => setEditModalOpen(true),
        },
        {
            key: 'delete',
            title: t('Unit.delete_unit'),
            action: (): Promise<void> => deleteUnit(),
        },
    ];

    const getHeaderSubtitle = (): ReactNode => {
        return (
            <>
                <div>
                    <Tag
                        label={t(`Unit.unit_status_${data?.status}`)}
                        color={
                            data?.status === UnitActiveStatusDto.Active
                                ? TagColors.active
                                : TagColors.error
                        }
                    />
                </div>
                <div className="header-details">
                    <div>
                        <span className="capitalise">{t('type')}</span>
                        <div>
                            <strong>{t(`Unit.type_${data?.type}`)}</strong>
                        </div>
                    </div>
                    <div className="divider" />
                    {data?.type === UnitTypeDto.CommunalArea && (
                        <div>
                            {t('Unit.capacity')}{' '}
                            <div>
                                <strong>{data?.capacity}</strong>
                            </div>
                        </div>
                    )}
                    {data?.type !== UnitTypeDto.CommunalArea && (
                        <>
                            <div>
                                {t('square_feet')}{' '}
                                <div>
                                    <strong>{data?.squareFeet}</strong>
                                </div>
                            </div>
                            <div className="divider" />
                        </>
                    )}
                    {data?.type !== UnitTypeDto.CommunalArea && (
                        <div>
                            {t('Unit.number_of_desks')}{' '}
                            <div>
                                <strong>{data?.numberOfDesks}</strong>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    };
    return (
        <div className="UnitDetails">
            <LocationHeader
                title={data?.name || ''}
                subTitle={getHeaderSubtitle()}
                defaultImg={<UnitIcon fill={theme['primary-color']} />}
                action={<ActionMenu options={actions} type="primary" trigger="click" />}
                routes={getBreadcrumb()}
                loading={loading}
            />

            <Content>
                <Row gutter={40}>
                    <Col span={12}>
                        {note && note.length > 0 && (
                            <SimpleList
                                title={t('note')}
                                data={note}
                                className="large-description"
                            />
                        )}

                        {pricingInformation && pricingInformation.length > 0 && (
                            <SimpleList
                                title={t('pricing')}
                                data={pricingInformation}
                                className="large-description"
                            />
                        )}

                        {activeDateInformation && activeDateInformation.length > 0 && (
                            <SimpleList
                                title={t('Unit.active_dates')}
                                data={activeDateInformation}
                                className="large-description"
                            />
                        )}
                    </Col>

                    <Col span={12}>
                        {dataJacksInformation && dataJacksInformation.length > 0 && (
                            <SimpleList title={t('Unit.data_jacks')} data={dataJacksInformation} />
                        )}

                        {data?.type !== UnitTypeDto.CommunalArea && (
                            <UnitOccupancy
                                proposals={unitOccupancyData?.subscriptionProposals}
                                subscriptions={unitOccupancyData?.subscriptions}
                            />
                        )}
                    </Col>
                </Row>
            </Content>

            {editModalOpen && (
                <CreateUnitModal
                    visible={editModalOpen}
                    onComplete={(success: boolean): void => {
                        setEditModalOpen(false);
                        if (success) fetch();
                    }}
                    unit={data}
                    locationId={id}
                />
            )}
        </div>
    );
};

export default UnitDetails;
