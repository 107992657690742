import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Email: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 15" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.0983 17.915H2.895C2.53 17.915 2.17833 17.815 1.87333 17.6267L9.46667 9.74338L11.9967 11.735L14.52 9.74338L22.18 17.5884C21.8617 17.8017 21.4883 17.915 21.0983 17.915ZM0.931667 15.9517V3.69505C0.931667 3.49005 0.965 3.28672 1.02667 3.09338L8.73167 9.16338L1.215 16.9667C1.03 16.665 0.931667 16.3167 0.931667 15.9517ZM2.895 1.73172H21.0983C21.6133 1.73172 22.1 1.93338 22.4633 2.28672L11.9967 10.5484L1.52 2.29672C1.885 1.93838 2.375 1.73172 2.895 1.73172ZM23.065 3.69505V15.9517C23.065 16.29 22.9783 16.615 22.815 16.905L15.2567 9.16171L22.965 3.07838C23.03 3.27505 23.065 3.48338 23.065 3.69505ZM23.725 2.47672C23.68 2.37672 23.6283 2.28172 23.5717 2.18838C23.5517 2.15838 23.5333 2.12838 23.5133 2.09838C23.4183 1.95672 23.3133 1.82672 23.1983 1.70672C22.655 1.13172 21.9017 0.800049 21.0983 0.800049H2.895C2.08667 0.800049 1.32667 1.13672 0.785 1.71838C0.67 1.84005 0.565 1.97338 0.471667 2.11505C0.393333 2.23672 0.323333 2.36338 0.263333 2.49338C0.09 2.87005 0 3.27838 0 3.69505V15.9517C0 16.7284 0.303333 17.455 0.855 18.0017C1.40333 18.5467 2.12833 18.8467 2.895 18.8467H21.0983C21.8983 18.8467 22.645 18.525 23.1983 17.9417C23.7133 17.4034 23.9967 16.695 23.9967 15.9517V3.69505C23.9967 3.27005 23.9033 2.85672 23.725 2.47672Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Email;
