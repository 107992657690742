import { Checkbox, Col, DatePicker, Divider, Input, Row, Tooltip } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { CampusUnitPricingDto } from 'Api/Features/Campuses/Dtos/CampusUnitPricingDto';
import { UnitTypeDto } from 'Api/Features/Units/Dtos/UnitTypeDto';
import Icon from 'Components/icons/icon';
import SelectCustom, { SelectCustomOption } from 'Components/select-custom/select-custom';
import { ValidatedFormItem } from 'Components/validated-form-item';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'variant';
import './create-unit-item.less';

interface CreateUnitItemProps {
    index: number;
    remove: (index: number) => void;
    form: any;
    unitPricing: CampusUnitPricingDto | null;
}

const formGutter: [Gutter, Gutter] = [4, 0];

const CreateUnitItem: FunctionComponent<CreateUnitItemProps> = ({
    index,
    remove,
    form,
    unitPricing,
}) => {
    const { t } = useTranslation();
    const [unitType, setUnitType] = useState<UnitTypeDto>(UnitTypeDto.CommunalArea);
    const [priceOverrideCheckboxState, setPriceOverrideCheckboxState] = useState(false);

    const handleUnitTypeSelect = (values: { value: UnitTypeDto }): void => {
        setUnitType(values.value);
    };

    const getCalculatedPrice = (numberOfDesks: string, squareFeet: string): string =>
        (
            (unitPricing?.pricePerDesk || 0) * (Number(numberOfDesks) || 0) +
            (unitPricing?.pricePerSquareFoot || 0) * (Number(squareFeet) || 0)
        ).toFixed(2);

    const getMarketPrice = (
        priceOverride: string,
        priceMultiplier: string,
        calculatedPrice: string
    ): string => {
        const priceOverrideNum = Number(priceOverride);
        const priceMultiplierNum = Number(priceMultiplier || 1);
        const calculatedPriceNum = Number(calculatedPrice);

        if (priceOverrideNum && priceOverrideNum !== 0) return priceOverrideNum.toFixed(2);

        if (unitType === UnitTypeDto.CommunalArea) {
            return ((unitPricing?.communalAreaBasePrice || 0) * priceMultiplierNum).toFixed(2);
        } else {
            return ((calculatedPriceNum || 0) * (priceMultiplierNum || 1)).toFixed(2);
        }
    };

    const setFormFieldsPrices = (): void => {
        const formValues = form.getFieldsValue();
        const { unitsCreate } = formValues;
        const priceMultiplier = unitsCreate[index].priceMultiplier;
        const priceOverride = priceOverrideCheckboxState
            ? unitsCreate[index].priceOverride
            : undefined;
        const calculatedPrice = getCalculatedPrice(
            unitsCreate[index].numberOfDesks,
            unitsCreate[index].squareFeet
        );
        const marketPrice = getMarketPrice(priceOverride, priceMultiplier, calculatedPrice);

        Object.assign(unitsCreate[index], {
            type: unitType,
            capacity:
                unitType === UnitTypeDto.CommunalArea ? unitsCreate[index].capacity : undefined,
            calculatedPrice: calculatedPrice,
            marketPrice: marketPrice,
            priceMultiplier: priceMultiplier,
            sixMonthPrice: priceOverride,
            twelveMonthPrice: priceOverride,
        });

        form.setFieldsValue({ unitsCreate });
    };

    const onPriceAffectingInputChange = (): void => {
        setFormFieldsPrices();
    };

    const typeOptions = (): SelectCustomOption[] => {
        const options = Object.keys(UnitTypeDto).map(
            (type) =>
                ({
                    label: t(`Unit.type_${type}`),
                    value: type,
                } as SelectCustomOption)
        );
        return options;
    };

    useEffect(() => {
        setFormFieldsPrices();
    }, [unitType, priceOverrideCheckboxState]);

    return (
        <div className="CreateUnitItem">
            <Row className="units-upload-row">
                <Col span={23}>
                    <Row gutter={formGutter}>
                        <Col span={4}>
                            <ValidatedFormItem name={[index, 'type']} label={t('Unit.unit_type')}>
                                <SelectCustom
                                    onChange={handleUnitTypeSelect}
                                    selected={[unitType]}
                                    options={typeOptions()}
                                    idAttribute={`unitsCreate_${index}_type`}
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={2}>
                            <ValidatedFormItem
                                name={[index, 'name']}
                                label={t('name')}
                                required
                                rules={[{ required: true, message: t('Unit.required_name') }]}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        {unitType === UnitTypeDto.CommunalArea ? (
                            <Col span={2}>
                                <ValidatedFormItem
                                    name={[index, 'capacity']}
                                    label={t('Unit.capacity')}
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: t('Unit.required_capacity'),
                                        },
                                    ]}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Col>
                        ) : (
                            <>
                                <Col span={2}>
                                    <ValidatedFormItem
                                        name={[index, 'squareFeet']}
                                        label={t('square_ft')}
                                        required
                                        rules={[
                                            {
                                                required: true,
                                                message: t('Unit.required_squareFeet'),
                                            },
                                        ]}
                                    >
                                        <Input onChange={onPriceAffectingInputChange} />
                                    </ValidatedFormItem>
                                </Col>

                                <Col span={2}>
                                    <ValidatedFormItem
                                        name={[index, 'numberOfDesks']}
                                        label={t('Unit.nbr_of_desks')}
                                        required
                                        rules={[
                                            {
                                                required: true,
                                                message: t('Unit.required_numberOfDesks'),
                                            },
                                        ]}
                                    >
                                        <Input onChange={onPriceAffectingInputChange} />
                                    </ValidatedFormItem>
                                </Col>
                            </>
                        )}

                        <Col span={unitType === UnitTypeDto.CommunalArea ? 8 : 6}>
                            <ValidatedFormItem name={[index, 'note']} label={t('note')}>
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={4}>
                            <ValidatedFormItem name={[index, 'startDate']} label={t('start_date')}>
                                <DatePicker style={{ width: '100%' }} />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={4}>
                            <ValidatedFormItem name={[index, 'endDate']} label={t('end_date')}>
                                <DatePicker style={{ width: '100%' }} />
                            </ValidatedFormItem>
                        </Col>

                        {unitType === UnitTypeDto.CommunalArea ? (
                            <Col span={4}>
                                <ValidatedFormItem
                                    name={[index, 'communalAreaBasePrice']}
                                    label={t('Unit.communal_area_base_price')}
                                    initialValue={unitPricing?.communalAreaBasePrice || 0}
                                >
                                    <Input disabled />
                                </ValidatedFormItem>
                            </Col>
                        ) : (
                            <Col span={4} className="tip-wrapper">
                                <ValidatedFormItem
                                    name={[index, 'calculatedPrice']}
                                    label={
                                        <Tooltip title={t('Unit.calculated_price_tip')}>
                                            {t('calculated_price')}
                                            <Icon iconName="Info" />
                                        </Tooltip>
                                    }
                                >
                                    <Input disabled />
                                </ValidatedFormItem>
                            </Col>
                        )}

                        <Col span={2}>
                            <ValidatedFormItem
                                name={[index, 'priceMultiplier']}
                                label={t('Unit.price_multiplier')}
                            >
                                <Input onChange={onPriceAffectingInputChange} />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={2}>
                            <ValidatedFormItem
                                name={[index, 'priceOverride']}
                                label={t('price_override')}
                            >
                                <Input
                                    disabled={!priceOverrideCheckboxState}
                                    onChange={onPriceAffectingInputChange}
                                    addonAfter={
                                        <Checkbox
                                            onChange={(value): void => {
                                                setPriceOverrideCheckboxState((old) => !old);
                                                if (!value.target.checked) {
                                                    form.setFieldsValue({
                                                        priceOverride: 0,
                                                    });
                                                }
                                                onPriceAffectingInputChange();
                                            }}
                                        />
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={4}>
                            <ValidatedFormItem
                                name={[index, 'marketPrice']}
                                label={t('Unit.market_price')}
                            >
                                <Input disabled />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={4}>
                            <ValidatedFormItem
                                name={[index, 'sixMonthPrice']}
                                label={t('Unit.six_month_price')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={4}>
                            <ValidatedFormItem
                                name={[index, 'twelveMonthPrice']}
                                label={t('Unit.twelve_month_price')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={4} className="tip-wrapper">
                            <ValidatedFormItem
                                name={[index, 'dataJacks']}
                                label={
                                    <Tooltip title={t('Unit.data_jacks_info')}>
                                        {t('Unit.data_jacks')}
                                        <Icon iconName="Info" />
                                    </Tooltip>
                                }
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                </Col>

                <Col span={1} className="units-upload-row-garbage-container">
                    <span
                        className="garbage-container"
                        onClick={(): void => {
                            remove(index);
                        }}
                    >
                        <Icon iconName="Delete" fill={theme['primary-color']} />
                    </span>
                </Col>
            </Row>

            <Divider />
        </div>
    );
};

export default CreateUnitItem;
