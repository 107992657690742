import { Col } from 'antd';
import React, { FunctionComponent } from 'react';
import './status-bar.less';

interface StatusBarProps {
    step: number;
    stepTextList: string[];
    currentStepSubTitle: string;
}

const StatusBar: FunctionComponent<StatusBarProps> = ({
    step,
    stepTextList,
    currentStepSubTitle,
}) => {
    return (
        <div
            className={`status-bar`}
            style={{ gridTemplateColumns: `repeat(${stepTextList.length}, 1fr)` }}
        >
            {stepTextList.map((key, index) => {
                const currentStep = step === index + 1;

                return (
                    <Col
                        key={key}
                        className={`status-step  ${
                            currentStep ? 'active' : step > index + 1 ? 'complete' : ''
                        }`}
                    >
                        <div>{stepTextList[index]}</div>

                        {currentStep && (
                            <div className="status-step-sub-title">{currentStepSubTitle}</div>
                        )}
                    </Col>
                );
            })}
        </div>
    );
};

export default StatusBar;
