import { DayPassReservationProxy } from 'Api/Features/DayPasses/DayPassReservationProxy';
import { GetDayPassReservationsRequestDto } from 'Api/Features/DayPasses/Dtos/GetDayPassReservationsRequestDto';
import { GetDayPassReservationsResponseDto } from 'Api/Features/DayPasses/Dtos/GetDayPassReservationsResponseDto';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(DayPassReservationProxy)
export class DayPassReservationService extends ApiService {
    constructor(private readonly dayPassReservationProxy: DayPassReservationProxy) {
        super();
    }

    public async getDayPassReservations(
        request: GetDayPassReservationsRequestDto
    ): Promise<GetDayPassReservationsResponseDto | null> {
        const data: GetDayPassReservationsResponseDto | null =
            await this.dayPassReservationProxy.getDayPassReservations(request);
        return data;
    }
}
