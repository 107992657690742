// GENERATED FILE - DO NOT MODIFY
import { CreateDayPassTypeRequestDto } from 'Api/Features/DayPassTypes/Dtos/CreateDayPassTypeRequestDto';
import { DayPassTypeDto } from 'Api/Features/DayPassTypes/Dtos/DayPassTypeDto';
import { GetDayPassTypeOrderResponseDto } from 'Api/Features/DayPassTypes/Dtos/GetDayPassTypeOrderResponseDto';
import { GetDayPassTypesRequestDto } from 'Api/Features/DayPassTypes/Dtos/GetDayPassTypesRequestDto';
import { GetDayPassTypesResponseDto } from 'Api/Features/DayPassTypes/Dtos/GetDayPassTypesResponseDto';
import { UpdateDayPassTypeOrderRequestDto } from 'Api/Features/DayPassTypes/Dtos/UpdateDayPassTypeOrderRequestDto';
import { UpdateDayPassTypeRequestDto } from 'Api/Features/DayPassTypes/Dtos/UpdateDayPassTypeRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class DayPassTypeProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getDayPassType(dayPassTypeId: string): Promise<DayPassTypeDto | null> {
        const uri = this.buildUri(
            "/day-pass-types/{dayPassTypeId}",
            { dayPassTypeId: dayPassTypeId },
            null
        );

        const data: DayPassTypeDto | null = await this.httpClient.get<DayPassTypeDto | null>(uri);
        return data;
    }

    public async getDayPassTypes(request: GetDayPassTypesRequestDto | null): Promise<GetDayPassTypesResponseDto | null> {
        const uri = this.buildUri(
            "/day-pass-types",
            null,
            { ...request || undefined }
        );

        const data: GetDayPassTypesResponseDto | null = await this.httpClient.get<GetDayPassTypesResponseDto | null>(uri);
        return data;
    }

    public async createDayPassType(request: CreateDayPassTypeRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/day-pass-types",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateDayPassTypeRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateDayPassType(dayPassTypeId: string, request: UpdateDayPassTypeRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/day-pass-types/{dayPassTypeId}",
            { dayPassTypeId: dayPassTypeId },
            null
        );

        await this.httpClient.patch<UpdateDayPassTypeRequestDto | null, void>(uri, request);
    }

    public async deleteDayPassType(dayPassTypeId: string): Promise<void> {
        const uri = this.buildUri(
            "/day-pass-types/{dayPassTypeId}",
            { dayPassTypeId: dayPassTypeId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async getDayPassTypeOrdering(): Promise<GetDayPassTypeOrderResponseDto | null> {
        const uri = this.buildUri(
            "/day-pass-types/order",
            null,
            null
        );

        const data: GetDayPassTypeOrderResponseDto | null = await this.httpClient.get<GetDayPassTypeOrderResponseDto | null>(uri);
        return data;
    }

    public async updateDayPassTypeOrdering(request: UpdateDayPassTypeOrderRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/day-pass-types/order",
            null,
            null
        );

        await this.httpClient.put<UpdateDayPassTypeOrderRequestDto | null, void>(uri, request);
    }

    public async getCampusDayPassTypeOrdering(campusId: string | null): Promise<GetDayPassTypeOrderResponseDto | null> {
        const uri = this.buildUri(
            "/campus/{campusId}/day-pass-types/order",
            { campusId: campusId },
            null
        );

        const data: GetDayPassTypeOrderResponseDto | null = await this.httpClient.get<GetDayPassTypeOrderResponseDto | null>(uri);
        return data;
    }

    public async updateCampusDayPassTypeOrdering(campusId: string | null, request: UpdateDayPassTypeOrderRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/campus/{campusId}/day-pass-types/order",
            { campusId: campusId },
            null
        );

        await this.httpClient.put<UpdateDayPassTypeOrderRequestDto | null, void>(uri, request);
    }
}