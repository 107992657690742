import React, { FunctionComponent } from 'react';
import { Layout, Row, Col, Card } from 'antd';

import { images } from '../../variant';
import './anonymous-layout.less';

const { Content } = Layout;

const AnonymousLayout: FunctionComponent = ({ children }) => {
    return (
        <div className="AnonymousLayout">
            <Layout
                className="container"
                style={{ backgroundImage: `url(${images.anonymousBackground})` }}
            >
                <Content>
                    <Row align="middle" className="login-row">
                        <Col span={12} offset={6}>
                            <Card className="square" bordered={false}>
                                {children}
                            </Card>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </div>
    );
};
export default AnonymousLayout;
