import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Lead: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.44422 8.245H12.5044V7.66667H3.44422V8.245Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.33337 16H14.6346V0H1.33337V16ZM1.91158 15.4218H14.0561V0.578306H1.91158V15.4218Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.44422 3.245H7.87789V2.66667H3.44422V3.245Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.44422 5.80052H12.5044V5.22219H3.44422V5.80052Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.44422 10.6895H12.5044V10.1111H3.44422V10.6895Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.44422 13.245H12.5044V12.6667H3.44422V13.245Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.5559 3.59219H11.9259V2.22219H10.5559V3.59219ZM10 4.14778H12.4799V1.66667H10V4.14778Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Lead;
