import { Button, Col, Form, Row, Select } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { CreateAccountCreditTransactionRequestDto } from 'Api/Features/Credits/Dtos/CreateAccountCreditTransactionRequestDto';
import { CreateMembershipCreditTransactionRequestDto } from 'Api/Features/Credits/Dtos/CreateMembershipCreditTransactionRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Credit } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { TENTHOUSAND } from 'Models/Constants';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useParams } from 'react-router';
import { CreditTransactionSchema } from 'Schemas/CreditTransactionSchema';
import { CreditsService } from 'Services/CreditsService';
import './credit-transaction-modal.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface CreditTransactionModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    creditTransactionRecipientType: CreditTransactionRecipientType;
}

export enum CreditTransactionRecipientType {
    Membership = 'Membership',
    Account = 'Account',
}

export enum CreditTransactionAction {
    Give = 'Give',
    Remove = 'Remove',
}

const CreditTransactionModal: FunctionComponent<CreditTransactionModalProps> = ({
    visible,
    onComplete,
    creditTransactionRecipientType,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(CreditTransactionSchema, form);
    const { confirmationModalStore, globalLoadingStore, toastStore } = useStores();
    const [selectedAction, setSelectedAction] = useState<CreditTransactionAction>(
        CreditTransactionAction.Give
    );
    const creditService = useService(CreditsService);
    const { id } = useParams<{ id: string }>();

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Credit />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t('model_confirm_positive_edit', { param1: 'credit' }),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };
    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const request:
            | CreateAccountCreditTransactionRequestDto
            | CreateMembershipCreditTransactionRequestDto = {
            amount: formValues.amount,
        };

        if (!(await validateForm(request))) return;

        try {
            globalLoadingStore.addLoading();
            if (
                selectedAction === CreditTransactionAction.Remove &&
                request.amount !== null &&
                request.amount !== undefined
            )
                request.amount = -request.amount;

            switch (creditTransactionRecipientType) {
                case CreditTransactionRecipientType.Account:
                    await creditService.createAccountCreditTransaction(id, request);
                    break;
                case CreditTransactionRecipientType.Membership:
                    await creditService.createMembershipCreditTransaction(id, request);
                    break;
            }

            toastStore.toast({
                type: 'success',
                message: t('model_edit_success', { param1: 'Credit balance' }),
            });

            dismiss(true);
        } catch (e) {
            toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <BaseModal
            className="FormModal CreditTransactionModal"
            visible={visible}
            title={t(`Contact.make_credit_transaction`)}
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Row gutter={formGutter}>
                    <Col span={12}>
                        <ValidatedFormItem label={t('action')}>
                            <Select
                                value={selectedAction}
                                onChange={(value): void => setSelectedAction(value)}
                            >
                                {Object.keys(CreditTransactionAction).map((action) => (
                                    <Select.Option key={action} value={action}>
                                        {action}
                                    </Select.Option>
                                ))}
                            </Select>
                        </ValidatedFormItem>
                    </Col>
                    <Col span={12}>
                        <ValidatedFormItem name={'amount'} label={t('amount')} errors={errors}>
                            <NumberFormat
                                className="ant-input"
                                decimalScale={2}
                                allowNegative={false}
                                isAllowed={(value: NumberFormatValues): boolean =>
                                    value.floatValue === undefined ||
                                    (value.floatValue !== undefined &&
                                        value.floatValue <= TENTHOUSAND)
                                }
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>
                    <Button type="primary" className="positive" htmlType="submit">
                        {t('submit')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(CreditTransactionModal);
