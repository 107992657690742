import { Button, Col, Layout, Row } from 'antd';
import Icon from 'Components/icons/icon';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { ListSectionHeader } from 'Components/list-section-header';
import { SimpleList } from 'Components/simple-list';
import { SimpleListItemProps } from 'Components/simple-list/simple-list';
import { useService, useStores } from 'Hooks';
import { ManagerUser } from 'Models/ManagerUsers/ManagerUser';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ManagerUserService } from 'Services/ManagerUserService';
import { oneLineAddress } from 'Utils/AddressHelper';
import { images, theme } from 'variant';
import EditSalesPersonModal from './edit-modal';
import './index.less';

const { usersHeader } = images;
const { Content } = Layout;

const Profile: FunctionComponent = () => {
    const { t } = useTranslation();
    const { globalLoadingStore, userPermissionsStore } = useStores();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ManagerUser | undefined>(undefined);
    const [basicInformation, setBasicInformation] = useState<SimpleListItemProps[] | null>(null);
    const [address, setAddress] = useState<SimpleListItemProps[] | null>(null);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const managerUserService = useService(ManagerUserService);

    const { id } = useParams();

    const setSimpleListsData = (manager: ManagerUser): void => {
        const info: SimpleListItemProps[] = [];
        if (manager?.name)
            info.push({
                title: manager?.name || '',
                avatar: (
                    <ImageWithPlaceholder
                        width="32"
                        height="32"
                        defaultImg={<Icon iconName="Company" />}
                    />
                ),
            });
        if (manager?.contactInfo?.email)
            info.push({
                title: manager?.contactInfo?.email,
                avatar: (
                    <ImageWithPlaceholder
                        width="32"
                        height="32"
                        defaultImg={<Icon iconName="Email" />}
                    />
                ),
            });
        if (manager?.contactInfo?.phone1)
            info.push({
                title: manager?.contactInfo?.phone1,
                avatar: (
                    <ImageWithPlaceholder
                        width="32"
                        height="32"
                        defaultImg={<Icon iconName="Phone" />}
                    />
                ),
            });
        if (manager?.contactInfo?.phone2)
            info.push({
                title: manager?.contactInfo?.phone2,
                avatar: (
                    <ImageWithPlaceholder
                        width="32"
                        height="32"
                        defaultImg={<Icon iconName="Phone" />}
                    />
                ),
            });
        setBasicInformation(info);

        if (manager.address?.addressLine1) {
            setAddress([
                {
                    avatar: <Icon iconName="RoadSign" />,
                    description: oneLineAddress(manager?.address),
                },
            ]);
        }
    };

    useEffect(() => {
        async function fetch(): Promise<void> {
            setLoading(true);
            globalLoadingStore.addLoading();
            try {
                // call api
                const data = await managerUserService.getById(id);
                if (data) {
                    const user = new ManagerUser(data);
                    setData(user);
                    setSimpleListsData(user);
                }
            } finally {
                setLoading(false);
                globalLoadingStore.removeLoading();
            }
        }
        fetch();
    }, [globalLoadingStore, id, managerUserService, editModalOpen]);

    const onEditComplete = (success: boolean): void => {
        setEditModalOpen(false);
        if (success) userPermissionsStore.setUserInfoAndPermissions();
    };

    return (
        <div className="profile">
            <ListSectionHeader
                title={data?.name || ''}
                subTitle={''}
                defaultImg={<Icon iconName="User" fill={theme['primary-color']} />}
                imgSrc={data?.imageUrl}
                width="150"
                backgroundImageUrl={usersHeader}
                loading={loading}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            setEditModalOpen(true);
                        }}
                    >
                        {t('User.edit_profile')}
                    </Button>
                }
            />

            <Content>
                <Row gutter={40}>
                    {basicInformation && (
                        <Col span={12}>
                            <SimpleList title={t('information')} data={basicInformation} />
                        </Col>
                    )}
                    {address && address.length > 0 && (
                        <Col span={12}>
                            <SimpleList
                                className="large-description"
                                title={t('address')}
                                data={address}
                            />
                        </Col>
                    )}
                </Row>
            </Content>

            {editModalOpen && (
                <EditSalesPersonModal
                    onComplete={onEditComplete}
                    visible={editModalOpen}
                    user={data}
                />
            )}
        </div>
    );
};

export default Profile;
