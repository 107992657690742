// GENERATED FILE - DO NOT MODIFY
import { CreateTaskRequestDto } from 'Api/Features/Tasks/Dtos/CreateTaskRequestDto';
import { GetTasksRequestDto } from 'Api/Features/Tasks/Dtos/GetTasksRequestDto';
import { GetTasksResponseDto } from 'Api/Features/Tasks/Dtos/GetTasksResponseDto';
import { TaskDto } from 'Api/Features/Tasks/Dtos/TaskDto';
import { UpdateTaskRequestDto } from 'Api/Features/Tasks/Dtos/UpdateTaskRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class TaskProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getTask(taskId: string): Promise<TaskDto | null> {
        const uri = this.buildUri(
            "/tasks/{taskId}",
            { taskId: taskId },
            null
        );

        const data: TaskDto | null = await this.httpClient.get<TaskDto | null>(uri);
        return data;
    }

    public async getTasks(request: GetTasksRequestDto | null): Promise<GetTasksResponseDto | null> {
        const uri = this.buildUri(
            "/tasks",
            null,
            { ...request || undefined }
        );

        const data: GetTasksResponseDto | null = await this.httpClient.get<GetTasksResponseDto | null>(uri);
        return data;
    }

    public async createTask(request: CreateTaskRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/tasks",
            null,
            null
        );

        await this.httpClient.post<CreateTaskRequestDto | null, void>(uri, request);
    }

    public async updateTask(taskId: string, request: UpdateTaskRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/tasks/{taskId}",
            { taskId: taskId },
            null
        );

        await this.httpClient.patch<UpdateTaskRequestDto | null, void>(uri, request);
    }

    public async deleteTask(taskId: string): Promise<void> {
        const uri = this.buildUri(
            "/tasks/{taskId}",
            { taskId: taskId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}