import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AreaOfInterestPin: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 48 57" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M47.8853 23.5705C47.8853 36.5416 37.1752 47.0567 23.9634 47.0567C10.7516 47.0567 0.0415039 36.5416 0.0415039 23.5705C0.0415039 10.5994 10.7516 0.0843506 23.9634 0.0843506C37.1752 0.0843506 47.8853 10.5994 47.8853 23.5705Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.125 45.0586L23.677 56.2728L17.2289 45.0586H30.125Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 40C15.1636 40 8 32.8364 8 24C8 15.1636 15.1636 8 24 8C32.8367 8 40 15.1636 40 24C40 32.8364 32.8367 40 24 40Z"
                    fill={'white'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M31.0074 26.0601C30.8296 26.0601 30.6565 26.0379 30.4897 25.995C30.8978 25.0867 31.1249 24.0798 31.1249 23.0206V21.855C32.2318 21.9169 33.1116 22.8348 33.1116 23.956C33.1116 25.1153 32.1683 26.0601 31.0074 26.0601ZM23.8497 29.4094H21.277C17.7531 29.4094 14.8883 26.5445 14.8883 23.0206V21.0752H30.2385V23.0206C30.2385 26.5445 27.372 29.4094 23.8497 29.4094ZM31.1256 20.9672V20.1875H14V23.0206C14 27.0336 17.2635 30.2971 21.2765 30.2971H23.8491C26.4805 30.2971 28.7896 28.8932 30.068 26.7954C30.3681 26.897 30.6825 26.9479 31.0081 26.9479C32.6581 26.9479 34 25.606 34 23.956C34 22.3457 32.7216 21.0276 31.1256 20.9672Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default AreaOfInterestPin;
