import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const UnExpandAll: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="6" height="16" viewBox="0 0 6 16" fill="none" {...props}>
                <path fillRule="evenodd" clipRule="evenodd" d="M3 9L6 13L0 13L3 9Z" fill={fill} />
                <path d="M2 13H4V16H2V13Z" fill={fill} />
                <path fillRule="evenodd" clipRule="evenodd" d="M3 7L0 3H6L3 7Z" fill={fill} />
                <path d="M2 0H4V3H2V0Z" fill={fill} />
            </svg>
        </span>
    );
};

export default UnExpandAll;
