import { LeadDto } from 'Api/Features/Leads/Dtos/LeadDto';
import { UpdateLeadRequestDto } from 'Api/Features/Leads/Dtos/UpdateLeadRequestDto';
import { LeadCampusDto } from 'Api/Features/Leads/Dtos/LeadCampusDto';
import { LeadAccountDto } from 'Api/Features/Leads/Dtos/LeadAccountDto';

export function toUpdateLeadRequestDto(lead: LeadDto): UpdateLeadRequestDto {
    const leadRequestDto: UpdateLeadRequestDto = {
        campusId: lead!.campus?.id,
        status: lead!.status,
        salutation: lead!.salutation,
        firstName: lead!.firstName,
        middleName: lead!.middleName,
        lastName: lead!.lastName,
        phoneNumber: lead!.phoneNumber,
        phoneNumber2: lead!.phoneNumber2,
        email: lead!.email,
        companyName: lead!.companyName,
        jobPosition: lead!.jobPosition,
        website: lead!.website,
        officeNumber: lead!.officeNumber,
        numberOfEmployees: lead!.numberOfEmployees,
        industry: lead!.industry,
        ownerAccountId: lead!.ownerAccount?.id,
        source: lead!.source,
        type: lead!.type,
        label: lead!.label,
        lostReason: lead!.lostReason,
    };

    return leadRequestDto;
}

export function toLeadDto(lead: UpdateLeadRequestDto, id?: string): LeadDto {
    return {
        industry: lead.industry,
        name: lead.companyName,
        numberOfEmployees: lead.numberOfEmployees,
        officeNumber: lead.officeNumber,
        jobPosition: lead.jobPosition,
        website: lead.website,
        campus: {
            id: lead.campusId,
        } as LeadCampusDto,
        email: lead.email,
        firstName: lead.firstName,
        lastName: lead.lastName,
        middleName: lead.middleName,
        phoneNumber2: lead.phoneNumber2,
        phoneNumber: lead.phoneNumber,
        salutation: lead.salutation,
        id: id,
        label: lead.label,
        ownerAccount: {
            id: lead.ownerAccountId,
        } as LeadAccountDto,
        source: lead.source,
        status: lead.status,
        type: lead.type,
        companyName: lead.companyName
    } as LeadDto;
}
