import React, { Children, cloneElement, FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import { RouteProps, useLocation } from 'react-router';
import UnauthorizedPage from '../../Routes/error/unauthorized/unauthorized';
import { useStores } from '../../Hooks';
import { observer } from 'mobx-react';
import { NotFoundPage } from 'Routes/error/not-found';

const ValidatedRoute: FunctionComponent<RouteProps> = observer(({ children, ...routeProps }) => {
    const { authorizationStore } = useStores();
    const { userPermissionsStore } = useStores();

    const location = useLocation();
    const managerUsersEx = /^\/administration/;
    const currentPageForbidden =
        managerUsersEx.test(location.pathname) && !userPermissionsStore.userIsAdministrator;

    const updateChildrenWithProps = Children.map(children, (child: any) => {
        return cloneElement(child, {
            routeProps,
        });
    });
    
    return (
        <Route {...routeProps}>
            {authorizationStore.currentRequestForbidden || currentPageForbidden ? (
                <UnauthorizedPage />
            ) : authorizationStore.currentRequestNotFound ? (
                <NotFoundPage />
            ) : (
                updateChildrenWithProps
            )}
        </Route>
    );
});

export default ValidatedRoute;
