import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const RoadSign: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.0437 13.754H1.71969V5.35562H17.0437L19.4272 9.55397L17.0437 13.754ZM6.50818 22.9315H11.7389V14.6729H6.50818V22.9315ZM6.50818 4.43671H11.7389V0.918904H6.50818V4.43671ZM20.3544 9.32712L17.7111 4.66849C17.6289 4.52548 17.476 4.43671 17.3116 4.43671H12.6578V0.460274C12.6578 0.207123 12.4523 0 12.1991 0H6.04955C5.79475 0 5.58927 0.207123 5.58927 0.460274V4.43671H1.25941C1.00626 4.43671 0.800781 4.64219 0.800781 4.89534V14.2126C0.800781 14.4674 1.00626 14.6729 1.25941 14.6729H5.58927V23.3918C5.58927 23.6449 5.79475 23.8504 6.04955 23.8504H12.1991C12.4523 23.8504 12.6578 23.6449 12.6578 23.3918V14.6729H17.3116C17.476 14.6729 17.6289 14.5841 17.7111 14.4395L20.3544 9.78082C20.4349 9.6411 20.4349 9.46849 20.3544 9.32712Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default RoadSign;
