import { Table, TablePaginationConfig } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { ColumnType } from 'antd/lib/table';
import Title from 'antd/lib/typography/Title';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetMembershipSecurityDepositSummaryResponseDto } from 'Api/Features/SecurityDeposits/Dtos/GetMembershipSecurityDepositSummaryResponseDto';
import { GetMembershipSecurityDepositSummaryResponseSubscriptionDto } from 'Api/Features/SecurityDeposits/Dtos/GetMembershipSecurityDepositSummaryResponseSubscriptionDto';
import { GetMembershipSecurityDepositTransactionsRequestDto } from 'Api/Features/SecurityDeposits/Dtos/GetMembershipSecurityDepositTransactionsRequestDto';
import { GetSecurityDepositTransactionsSortColumnDto } from 'Api/Features/SecurityDeposits/Dtos/GetSecurityDepositTransactionsSortColumnDto';
import { SecurityDepositTransactionDto } from 'Api/Features/SecurityDeposits/Dtos/SecurityDepositTransactionDto';
import CompanyHeader from 'Components/company-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import SideSwippingCarousel from 'Components/SideSwippingCarousel';
import SecurityDepositSwipperElement from 'Components/SideSwippingCarousel/elements/security-deposit-swipper-element';
import { TableFilters } from 'Components/table-filters';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import { MOMENT_PARSING_FORMAT, TABLE_DEBOUNCE_DELAY } from 'Models/Constants';
import moment from 'moment';
import React, {
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SecurityDepositService } from 'Services/SecurityDepositService';
import { FilterStore } from 'Stores';
import { currencyFormatter } from 'Utils/NumberUtils';
import { CompanyContext } from '../..';
import './index.less';

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 20,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const SecurityDeposit: FunctionComponent = () => {
    const companyContext = useContext(CompanyContext);
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { toastStore, globalLoadingStore } = useStores();
    const securityDepositService = useService(SecurityDepositService);

    const [securityDepositTransactions, setSecurityDepositTransactions] =
        useState<SecurityDepositTransactionDto[]>();
    const [subscriptionSecurityDeposits, setSubscriptionSecurityDeposits] =
        useState<GetMembershipSecurityDepositSummaryResponseDto>();

    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const paginationRef = useRef(initialPaginationState);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters: [] }));

    const breadcrumbs: BreadcrumbSegment[] = [
        {
            path: `companies`,
            nameKey: 'companies',
        },
        {
            path: `${id}/dashboard`,
            nameKey: companyContext?.name ?? '',
        },
        {
            path: `finance`,
            nameKey: 'finance',
        },
        {
            path: `security-deposit`,
            nameKey: 'Reports.security_deposits',
        },
    ];

    const columns: ColumnType<SecurityDepositTransactionDto>[] = [
        {
            key: GetSecurityDepositTransactionsSortColumnDto.DateCreated,
            title: t('date'),
            render: (transaction: SecurityDepositTransactionDto) =>
                moment(transaction.dateCreated).format(MOMENT_PARSING_FORMAT),
            sorter: true,
        },
        {
            key: 'description',
            title: t('description'),
            render: (transaction: SecurityDepositTransactionDto) =>
                t(
                    `SecurityDeposit.MembershipSecurityDepositTransactionTypeDto_${transaction.type}`
                ),
        },
        {
            key: 'subscription',
            title: t('subscription'),
            render: (transaction: SecurityDepositTransactionDto) =>
                transaction.subscription?.referenceNumber,
        },
        {
            key: 'deduction',
            title: t('deduction'),
            render: (transaction: SecurityDepositTransactionDto) =>
                transaction.amount && transaction.amount < 0
                    ? currencyFormatter(Math.abs(transaction.amount))
                    : '',
            align: 'right',
        },
        {
            key: 'addition',
            title: t('addition'),
            render: (transaction: SecurityDepositTransactionDto) =>
                transaction.amount && transaction.amount > 0
                    ? currencyFormatter(transaction.amount)
                    : '',
            align: 'right',
        },
        {
            key: 'balance',
            title: t('SecurityDeposit.sec_dep_subscription_balance'),
            render: (transaction: SecurityDepositTransactionDto) =>
                currencyFormatter(transaction.subscriptionBalance ?? 0),
            align: 'right',
        },
        {
            key: 'balance',
            title: t('balance'),
            render: (transaction: SecurityDepositTransactionDto) =>
                currencyFormatter(transaction.balance ?? 0),
            align: 'right',
        },
    ];

    const fetchTransactions = useCallback(
        async (params: {
            searchTerm?: string;
            startDate: string;
            endDate: string;
            pagination: TablePaginationConfig;
            sortColumn: GetSecurityDepositTransactionsSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);

            try {
                const request: GetMembershipSecurityDepositTransactionsRequestDto = {
                    minDate: params.startDate,
                    maxDate: params.endDate,
                    searchTerm: params.searchTerm,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    page: (params.pagination.current || 1) - 1,
                    pageSize: params.pagination.pageSize || 10,
                };

                const [response, totalItemCount] =
                    await securityDepositService.getMembershipSecurityDepositTransactions(
                        id,
                        request
                    );

                setSecurityDepositTransactions(response);
                setPagination({
                    ...params.pagination,
                    total: totalItemCount,
                });
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [setLoading, securityDepositService, t]
    );

    const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any): void => {
        const { searchTerm, startDate, endDate } = filterStoreRef.current;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        fetchTransactions({
            searchTerm: searchTerm,
            startDate: startDate,
            endDate: endDate,
            pagination: pagination,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
    };

    const debounceSearch = useRef(
        debounce((params: { searchTerm?: string; startDate: string; endDate: string }) => {
            fetchTransactions({
                searchTerm: params.searchTerm,
                startDate: params.startDate,
                endDate: params.endDate,
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                sortColumn: null,
                sortDirection: null,
            });
        }, TABLE_DEBOUNCE_DELAY)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;

            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                startDate: filterStoreRef.current.startDate,
                endDate: filterStoreRef.current.endDate,
            });
        });

        return (): void => {
            disposer();
        };
    }, [fetchTransactions]);

    const fetchSubscriptionSecurityDeposits = useCallback(async () => {
        try {
            globalLoadingStore.addLoading();
            const response = await securityDepositService.getMembershipSecurityDepositSummary(id);
            if (response) setSubscriptionSecurityDeposits(response);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [id]);

    useEffect(() => {
        fetchSubscriptionSecurityDeposits();
    }, [fetchSubscriptionSecurityDeposits]);

    return (
        <div className="SecurityDeposits">
            <CompanyHeader
                company={companyContext}
                routes={breadcrumbs}
                headerData={{
                    title: t('Reports.security_deposits'),
                    iconName: 'Dollar',
                    subTitle: t('Company.security_deposit_section_subtitle'),
                }}
            />
            <Content>
                <Title level={2} className="title">
                    {t('SecurityDeposit.subscription_security_deposits')}
                </Title>
                <div className="sub-sec-dep-container">
                    <div className="inner-container">
                        <div className="total-container">
                            <div className="amount">
                                {currencyFormatter(subscriptionSecurityDeposits?.balance ?? 0)}
                            </div>
                            <div className="subtitle">
                                {t('SecurityDeposit.security_deposits_total')}
                            </div>
                        </div>
                        {subscriptionSecurityDeposits?.subscriptions &&
                            subscriptionSecurityDeposits.subscriptions.length > 0 && (
                                <div className="swipper-container">
                                    <SideSwippingCarousel
                                        elements={
                                            subscriptionSecurityDeposits?.subscriptions
                                                ? subscriptionSecurityDeposits?.subscriptions
                                                      ?.filter(
                                                          (item) =>
                                                              item !== null && item !== undefined
                                                      )
                                                      .map((item) => item!)
                                                      .map(
                                                          (
                                                              item: GetMembershipSecurityDepositSummaryResponseSubscriptionDto
                                                          ) => (
                                                              <SecurityDepositSwipperElement
                                                                  key={item?.id ?? ''}
                                                                  planName={item?.plan?.name ?? ''}
                                                                  amount={item?.balance ?? 0}
                                                                  units={
                                                                      item?.units?.map(
                                                                          (unit) => unit?.name ?? ''
                                                                      ) ?? []
                                                                  }
                                                              />
                                                          )
                                                      )
                                                : []
                                        }
                                    />
                                </div>
                            )}
                    </div>
                </div>

                <Title level={2} className="title">
                    {t('transactions')}
                </Title>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeDates
                    datesPrefix={{
                        start: t('date_from'),
                        end: t('date_to'),
                    }}
                    includeSearch
                />

                <Table
                    className="table-striped-rows"
                    bordered
                    columns={columns}
                    rowKey={(row: SecurityDepositTransactionDto): string =>
                        `${row.subscription?.id}${row.subscriptionBalance}${row.amount}`
                    }
                    dataSource={securityDepositTransactions}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </Content>
        </div>
    );
};

export default SecurityDeposit;
