import React, {
    FunctionComponent,
} from 'react';
import { Content } from 'antd/lib/layout/layout';
import Icon from 'Components/icons/icon';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { theme } from 'variant';
import SubscriptionTable from 'Routes/authenticated/subscriptions/subscription-table';
import './index.less';
import { useParams } from 'react-router';

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'subscriptions',
        nameKey: 'Location.subscriptions_label',
    },
];

const Subscriptions: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { id } = useParams<{id: string}>();

    return (
        <>
            <LocationHeader
                title={t('Location.subscriptions_label')}
                subTitle={t('Location.subscriptions_section_subtitle')}
                defaultImg={<Icon iconName="Plan" fill={theme['primary-color']} />}
                routes={breadcrumbs}
            />
            <Content className="Subscriptions">
                <SubscriptionTable campusId={id}/>
            </Content>
        </>
    );
});

export default Subscriptions;
