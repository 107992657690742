import React, { FunctionComponent } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import AnonymousLayout from 'Components/anonymous-layout/anonymous-layout';
import Login from './login';
import { ForgotPassword } from './forgot-password';
import * as Constants from 'Models/Constants';

export const AnonymousRoutes: FunctionComponent = () => {
    const location = useLocation();
    return (
        <AnonymousLayout>
            <Switch>
                <Route exact path="/">
                    <Login />
                </Route>
                <Route exact path="/forgot-Password">
                    <ForgotPassword />
                </Route>
                <Redirect
                    to={{
                        pathname: '/',
                        search: `?${Constants.RETURN_URL}=${encodeURIComponent(
                            location.pathname + location.search
                        )}`,
                    }}
                />
            </Switch>
        </AnonymousLayout>
    );
};

export default AnonymousRoutes;
