import { Button, Col, Form, Input, Radio, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { AcceptSubscriptionProposalRequestDto } from 'Api/Features/SubscriptionProposals/Dtos/AcceptSubscriptionProposalRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Plan as PlanIcon, User } from 'Components/icons';
import SelectCustom, { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { Member } from 'Models/Members/Member';
import { Opportunity } from 'Models/Opportunities/Opportunity';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AcceptProposalSchema } from 'Schemas';
import { MemberService } from 'Services/MemberService';
import { SubscriptionProposalService } from 'Services/SubscriptionProposalService';
import { theme } from 'variant';
import './proposal-response.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface ProposalResponseProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    opportunity?: Opportunity;
    isAccepting: boolean;
    setCreateProposalModalOpen: any;
    setLostModalOpen: any;
    proposalId?: string | null;
    fetchProposalList: () => void;
}

enum RadioValues {
    Yes = 'Yes',
    No = 'No',
}

const ProposalResponseModal: FunctionComponent<ProposalResponseProps> = ({
    visible,
    onComplete,
    opportunity,
    isAccepting,
    setCreateProposalModalOpen,
    setLostModalOpen,
    proposalId,
    fetchProposalList,
}) => {
    const { confirmationModalStore, globalLoadingStore, toastStore } = useStores();

    const [radioValue, setRadioValue] = useState<RadioValues>(
        isAccepting ? RadioValues.Yes : RadioValues.No
    );
    const memberService = useService(MemberService);
    const subscriptionProposalService = useService(SubscriptionProposalService);

    const pageSize = 25;
    const [membersCurrentPage, setMembersCurrentPage] = useState(0);
    const [membersSearchResults, setMembersSearchResults] = useState<Member[]>([]);
    const [membersSearchTerm, setMembersSearchTerm] = useState('');
    const [membersMaxResults, setMembersMaxResults] = useState(false);
    const [membersOptions, setMembersOptions] = useState<SelectCustomOption[]>([]);
    const [selectedMemberId, setSelectedMemberId] = useState<string>();
    const [selectedMembersOptions, setSelectedMembersOptions] = useState<SelectCustomOption[]>([]);
    const [selectLoading, setSelectLoading] = useState(false);

    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(AcceptProposalSchema, form);
    const { t } = useTranslation();

    const resetMembersSearch = (): void => {
        setMembersCurrentPage(0);
        setMembersSearchResults([]);
        setMembersSearchTerm('');
        setMembersMaxResults(false);
    };

    const handleMembersKeywordsChange = useCallback((value: string): void => {
        resetMembersSearch();
        setMembersSearchTerm(value);
    }, []);

    const handleMembersMenuScrollToBottom = (): void => {
        if (!membersMaxResults) {
            setMembersCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const searchMembers = async (
        page: number,
        searchTerm: string,
        membershipId: string
    ): Promise<Member[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
            sortFirstMembershipId: membershipId,
        };

        const results = await memberService.getMembers(args);

        if (results.length < pageSize) {
            setMembersMaxResults(true);
        }
        setSelectLoading(false);
        return results[0];
    };

    const debounceMembersSearch = useRef(
        debounce((page: number, searchTerm: string, membershipId: string) => {
            searchMembers(page, searchTerm, membershipId).then((results) => {
                setMembersSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    useEffect(() => {
        if (opportunity?.membership) {
            setSelectLoading(true);
            debounceMembersSearch.current(
                membersCurrentPage,
                membersSearchTerm,
                opportunity.membership.id!
            );
        }
    }, [membersCurrentPage, membersSearchTerm, opportunity]);

    useEffect(() => {
        const searchResults = membersSearchResults
            ?.filter((member: Member) => member.isInSortFirstMembership)
            .map(
                (member: Member) =>
                    ({
                        value: member?.id,
                        label: member?.name,
                        imageUrl: member?.imageUrl,
                        badge: undefined,
                        content: member.contactInfo?.email,
                        isInSortFirstMembership: member.isInSortFirstMembership,
                    } as SelectCustomOption)
            );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedMembersOptions);

        setMembersOptions(merged);
    }, [membersSearchResults, t, selectedMembersOptions]);

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <PlanIcon />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`Proposal.answer_proposal_confirm_positive`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const data: AcceptSubscriptionProposalRequestDto = {
            note: formValues['note'],
            onBehalfOfContactId: selectedMemberId,
        };

        if (!(await validateForm(data))) return;

        try {
            globalLoadingStore.addLoading();

            if (radioValue === RadioValues.Yes) {
                await subscriptionProposalService.acceptSubscriptionProposal(proposalId!, data);
            } else {
                await subscriptionProposalService.rejectSubscriptionProposal(proposalId!, data);
            }

            toastStore.toast({
                type: 'success',
                messageKey: 'Proposal.proposal_answered_success',
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const refuseAndCreateProposal = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const data: AcceptSubscriptionProposalRequestDto = {
            note: formValues['note'],
            onBehalfOfContactId: selectedMemberId,
        };
        if (!(await validateForm(data))) return;

        try {
            globalLoadingStore.addLoading();

            await subscriptionProposalService.rejectSubscriptionProposal(proposalId!, data);

            toastStore.toast({
                type: 'success',
                messageKey: 'Proposal.proposal_answered_success',
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }

        setCreateProposalModalOpen(true);
    };

    useEffect(() => {
        fetchProposalList();
    }, [fetchProposalList]);

    return (
        <BaseModal
            visible={visible}
            title={t('Proposal.proposal_answer_title')}
            className="FormModal ProposalResponse"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" form={form}>
                <Row gutter={formGutter} className="did-contact-agree">
                    <Col span={12}>
                        <ValidatedFormItem
                            errors={errors}
                            label={t('Proposal.did_contact_agree_to_price')}
                            className="label"
                        >
                            <Radio.Group
                                value={radioValue}
                                onChange={(event: RadioChangeEvent): void => {
                                    setRadioValue(event.target.value as RadioValues);
                                }}
                            >
                                <Radio value={RadioValues.Yes}>{RadioValues.Yes}</Radio>
                                <Radio value={RadioValues.No}>{RadioValues.No}</Radio>
                            </Radio.Group>
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Row gutter={formGutter}>
                    <Col span={12}>
                        <ValidatedFormItem
                            errors={errors}
                            name="onBehalfOfContactId"
                            label={
                                radioValue === RadioValues.Yes
                                    ? t('Proposal.contact_agreeing_on_behalf_of')
                                    : t('Proposal.contact_refusing_on_behalf_of')
                            }
                            required
                        >
                            <SelectCustom
                                options={membersOptions}
                                defaultImg={<User fill={theme['white']} />}
                                strongLabel={true}
                                placeholder={t('SelectCustom.placeholder_default')}
                                onKeywordsChange={handleMembersKeywordsChange}
                                onMenuScrollToBottom={handleMembersMenuScrollToBottom}
                                hideSelectedOptions={false}
                                onChange={(value): void => {
                                    setSelectedMemberId(value.value);
                                    const options = [value as SelectCustomOption];
                                    setSelectedMembersOptions(options);
                                }}
                                selected={selectedMemberId ? [selectedMemberId] : null}
                                isLoading={selectLoading}
                                idAttribute='onBehalfOfContactId'
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem
                            errors={errors}
                            name="note"
                            label={t('Lead.lead_add_a_note')}
                        >
                            <Input.TextArea />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <div className="actions">
                    {radioValue === RadioValues.No && (
                        <Button
                            className="secondary mark-lost"
                            htmlType="button"
                            onClick={(): void => setLostModalOpen(true)}
                        >
                            {t('Proposal.proposal_mark_as_lost')}
                        </Button>
                    )}

                    <Button
                        type="default"
                        className="secondary cancel"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>

                    {radioValue === RadioValues.No && (
                        <Button
                            type="primary"
                            className="cancel"
                            htmlType="button"
                            onClick={(): Promise<void> => submit()}
                        >
                            {t('save')}
                        </Button>
                    )}

                    <Button
                        type="primary"
                        htmlType="button"
                        onClick={(): Promise<void> =>
                            radioValue === RadioValues.No ? refuseAndCreateProposal() : submit()
                        }
                    >
                        {radioValue === RadioValues.No
                            ? t('Proposal.save_and_make_new_proposal')
                            : t('Proposal.agree_to_price_and_details')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(ProposalResponseModal);
