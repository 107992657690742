// GENERATED FILE - DO NOT MODIFY
import { CampusDetailsDto } from 'Api/Features/Campuses/Dtos/CampusDetailsDto';
import { CampusDto } from 'Api/Features/Campuses/Dtos/CampusDto';
import { CampusOccupancyObjectiveDto } from 'Api/Features/Campuses/Dtos/CampusOccupancyObjectiveDto';
import { CampusSageAccountingSyncConfigDto } from 'Api/Features/Campuses/Dtos/CampusSageAccountingSyncConfigDto';
import { CampusSettingsDto } from 'Api/Features/Campuses/Dtos/CampusSettingsDto';
import { CampusStripeConfigDto } from 'Api/Features/Campuses/Dtos/CampusStripeConfigDto';
import { CampusUnitPricingDto } from 'Api/Features/Campuses/Dtos/CampusUnitPricingDto';
import { ConfirmCampusStripeConnectConfigurationRequestDto } from 'Api/Features/Campuses/Dtos/ConfirmCampusStripeConnectConfigurationRequestDto';
import { CreateCampusRequestDto } from 'Api/Features/Campuses/Dtos/CreateCampusRequestDto';
import { GetCampusesRequestDto } from 'Api/Features/Campuses/Dtos/GetCampusesRequestDto';
import { GetCampusIntegrationStatusesRequestDto } from 'Api/Features/Campuses/Dtos/GetCampusIntegrationStatusesRequestDto';
import { GetCampusIntegrationStatusesResponseDto } from 'Api/Features/Campuses/Dtos/GetCampusIntegrationStatusesResponseDto';
import { GetCampusOnboardingConfigResponseDto } from 'Api/Features/Campuses/Dtos/GetCampusOnboardingConfigResponseDto';
import { GetCampusOrderResponseDto } from 'Api/Features/Campuses/Dtos/GetCampusOrderResponseDto';
import { GetCampusStripeConnectAuthorizationUrlResponseDto } from 'Api/Features/Campuses/Dtos/GetCampusStripeConnectAuthorizationUrlResponseDto';
import { GetUserHomeCampusRequestDto } from 'Api/Features/Campuses/Dtos/GetUserHomeCampusRequestDto';
import { LightCampusDetailDto } from 'Api/Features/Campuses/Dtos/LightCampusDetailDto';
import { UpdateCampusOccupancyObjectiveRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusOccupancyObjectiveRequestDto';
import { UpdateCampusOnboardingConfigRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusOnboardingConfigRequestDto';
import { UpdateCampusOrderRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusOrderRequestDto';
import { UpdateCampusRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusRequestDto';
import { UpdateCampusSageAccountingSyncConfigRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusSageAccountingSyncConfigRequestDto';
import { UpdateCampusSettingsRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusSettingsRequestDto';
import { UpdateCampusStatusRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusStatusRequestDto';
import { UpdateCampusUnitPricingRequestDto } from 'Api/Features/Campuses/Dtos/UpdateCampusUnitPricingRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class CampusProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getCampuses(request: GetCampusesRequestDto | null): Promise<(LightCampusDetailDto | null)[] | null> {
        const uri = this.buildUri(
            "/campus",
            null,
            { ...request || undefined }
        );

        const data: (LightCampusDetailDto | null)[] | null = await this.httpClient.get<(LightCampusDetailDto | null)[] | null>(uri);
        return data;
    }

    public async getCampus(campusId: string | null): Promise<CampusDetailsDto | null> {
        const uri = this.buildUri(
            "/campus/{campusId}",
            { campusId: campusId },
            null
        );

        const data: CampusDetailsDto | null = await this.httpClient.get<CampusDetailsDto | null>(uri);
        return data;
    }

    public async getCurrentUserHomeCampus(request?: GetUserHomeCampusRequestDto | null): Promise<CampusDto | null> {
        const uri = this.buildUri(
            "/homecampus",
            null,
            { ...request || undefined }
        );

        const data: CampusDto | null = await this.httpClient.get<CampusDto | null>(uri);
        return data;
    }

    public async getUserHomeCampus(accountId: string | null, request: GetUserHomeCampusRequestDto | null): Promise<CampusDto | null> {
        const uri = this.buildUri(
            "/accounts/{accountId}/home-campus",
            { accountId: accountId },
            { ...request || undefined }
        );

        const data: CampusDto | null = await this.httpClient.get<CampusDto | null>(uri);
        return data;
    }

    public async getCampusRulesAndAgreement(campusId: string | null): Promise<string | null> {
        const uri = this.buildUri(
            "/campus/{campusId}/rulesandagreements",
            { campusId: campusId },
            null
        );

        const data: string | null = await this.httpClient.get<string | null>(uri);
        return data;
    }

    public async getCampusTime(campusId: string | null): Promise<string> {
        const uri = this.buildUri(
            "/campus/{campusId}/time",
            { campusId: campusId },
            null
        );

        const data: string = await this.httpClient.get<string>(uri);
        return data;
    }

    public async getCampusesSettings(campusIds: (string | null)[] | null): Promise<{ [key: string]: CampusSettingsDto } | null> {
        const uri = this.buildUri(
            "/campussettings",
            null,
            null
        );

        const data: { [key: string]: CampusSettingsDto } | null = await this.httpClient.get<{ [key: string]: CampusSettingsDto } | null>(uri);
        return data;
    }

    public async getCampusSettings(campusId: string | null): Promise<CampusSettingsDto | null> {
        const uri = this.buildUri(
            "/campus/{campusId}/settings",
            { campusId: campusId },
            null
        );

        const data: CampusSettingsDto | null = await this.httpClient.get<CampusSettingsDto | null>(uri);
        return data;
    }

    public async createCampus(request: CreateCampusRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/campuses",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateCampusRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateCampus(campusId: string | null, request: UpdateCampusRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/campuses/{campusId}",
            { campusId: campusId },
            null
        );

        await this.httpClient.patch<UpdateCampusRequestDto | null, void>(uri, request);
    }

    public async updateCampusStatus(campusId: string | null, request: UpdateCampusStatusRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/campuses/{campusId}/status",
            { campusId: campusId },
            null
        );

        await this.httpClient.put<UpdateCampusStatusRequestDto | null, void>(uri, request);
    }

    public async getOccupancyObjective(campusId: string | null): Promise<CampusOccupancyObjectiveDto | null> {
        const uri = this.buildUri(
            "/campuses/{campusId}/occupancy-objective",
            { campusId: campusId },
            null
        );

        const data: CampusOccupancyObjectiveDto | null = await this.httpClient.get<CampusOccupancyObjectiveDto | null>(uri);
        return data;
    }

    public async updateOccupancyObjective(campusId: string | null, request: UpdateCampusOccupancyObjectiveRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/campuses/{campusId}/occupancy-objective",
            { campusId: campusId },
            null
        );

        await this.httpClient.put<UpdateCampusOccupancyObjectiveRequestDto | null, void>(uri, request);
    }

    public async getUnitPricing(campusId: string | null): Promise<CampusUnitPricingDto | null> {
        const uri = this.buildUri(
            "/campuses/{campusId}/unit-pricing",
            { campusId: campusId },
            null
        );

        const data: CampusUnitPricingDto | null = await this.httpClient.get<CampusUnitPricingDto | null>(uri);
        return data;
    }

    public async updateUnitPricing(campusId: string | null, request: UpdateCampusUnitPricingRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/campuses/{campusId}/unit-pricing",
            { campusId: campusId },
            null
        );

        await this.httpClient.put<UpdateCampusUnitPricingRequestDto | null, void>(uri, request);
    }

    public async updateCampusSettings(campusId: string | null, request: UpdateCampusSettingsRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/campus/{campusId}/settings",
            { campusId: campusId },
            null
        );

        await this.httpClient.post<UpdateCampusSettingsRequestDto | null, void>(uri, request);
    }

    public async getCampusOnboardingConfig(campusId: string | null): Promise<GetCampusOnboardingConfigResponseDto | null> {
        const uri = this.buildUri(
            "/campus/{campusId}/onboarding-config",
            { campusId: campusId },
            null
        );

        const data: GetCampusOnboardingConfigResponseDto | null = await this.httpClient.get<GetCampusOnboardingConfigResponseDto | null>(uri);
        return data;
    }

    public async updateCampusOnboardingConfig(campusId: string | null, request: UpdateCampusOnboardingConfigRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/campus/{campusId}/onboarding-config",
            { campusId: campusId },
            null
        );

        await this.httpClient.put<UpdateCampusOnboardingConfigRequestDto | null, void>(uri, request);
    }

    public async getCampusSageAccountingSyncConfig(campusId: string | null): Promise<CampusSageAccountingSyncConfigDto | null> {
        const uri = this.buildUri(
            "/campus/{campusId}/sage-accounting-sync-config",
            { campusId: campusId },
            null
        );

        const data: CampusSageAccountingSyncConfigDto | null = await this.httpClient.get<CampusSageAccountingSyncConfigDto | null>(uri);
        return data;
    }

    public async updateCampusSageAccountingSyncConfig(campusId: string | null, request: UpdateCampusSageAccountingSyncConfigRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/campus/{campusId}/sage-accounting-sync-config",
            { campusId: campusId },
            null
        );

        await this.httpClient.put<UpdateCampusSageAccountingSyncConfigRequestDto | null, void>(uri, request);
    }

    public async getCampusStripeConfig(campusId: string | null): Promise<CampusStripeConfigDto | null> {
        const uri = this.buildUri(
            "/campus/{campusId}/stripe-config",
            { campusId: campusId },
            null
        );

        const data: CampusStripeConfigDto | null = await this.httpClient.get<CampusStripeConfigDto | null>(uri);
        return data;
    }

    public async getCampusStripeConnectAuthorizationUrl(campusId: string | null): Promise<GetCampusStripeConnectAuthorizationUrlResponseDto | null> {
        const uri = this.buildUri(
            "/campus/{campusId}/stripe-connect-auth-url",
            { campusId: campusId },
            null
        );

        const data: GetCampusStripeConnectAuthorizationUrlResponseDto | null = await this.httpClient.get<GetCampusStripeConnectAuthorizationUrlResponseDto | null>(uri);
        return data;
    }

    public async confirmCampusStripeConnectConfiguration(campusId: string | null, request: ConfirmCampusStripeConnectConfigurationRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/campus/{campusId}/stripe-connect-confirm",
            { campusId: campusId },
            null
        );

        await this.httpClient.post<ConfirmCampusStripeConnectConfigurationRequestDto | null, void>(uri, request);
    }

    public async removeCampusStripeConnectConfiguration(campusId: string | null): Promise<void> {
        const uri = this.buildUri(
            "/campus/{campusId}/stripe-connect-remove",
            { campusId: campusId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async getCampusIntegrationStatuses(request: GetCampusIntegrationStatusesRequestDto | null): Promise<GetCampusIntegrationStatusesResponseDto | null> {
        const uri = this.buildUri(
            "/campus-integration-statuses",
            null,
            { ...request || undefined }
        );

        const data: GetCampusIntegrationStatusesResponseDto | null = await this.httpClient.get<GetCampusIntegrationStatusesResponseDto | null>(uri);
        return data;
    }

    public async getCampusOrdering(): Promise<GetCampusOrderResponseDto | null> {
        const uri = this.buildUri(
            "/campus/order",
            null,
            null
        );

        const data: GetCampusOrderResponseDto | null = await this.httpClient.get<GetCampusOrderResponseDto | null>(uri);
        return data;
    }

    public async updateCampusOrdering(request: UpdateCampusOrderRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/campus/order",
            null,
            null
        );

        await this.httpClient.put<UpdateCampusOrderRequestDto | null, void>(uri, request);
    }
}