import { Button, Col, Form, Radio, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { CreateDayPassRequestDto } from 'Api/Features/DayPasses/Dtos/CreateDayPassRequestDto';
import { DayPassTypeDto } from 'Api/Features/DayPassTypes/Dtos/DayPassTypeDto';
import { GetDayPassTypesRequestDto } from 'Api/Features/DayPassTypes/Dtos/GetDayPassTypesRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { DayPassTransactionRecipientType } from 'Components/give-day-pass-modal';
import { Plan } from 'Components/icons';
import Icon from 'Components/icons/icon';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { debounce } from 'lodash';
import { Member } from 'Models/Members/Member';
import { Membership } from 'Models/Memberships/Membership';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GiveLocationDayPassSchema } from 'Schemas/GiveDayPassSchema';
import { DayPassService } from 'Services/DayPassService';
import { DayPassTypeService } from 'Services/DayPassTypeService';
import { MemberService } from 'Services/MemberService';
import { MembershipService } from 'Services/MembershipService';
import { currencyFormatter } from 'Utils/NumberUtils';
import './give-location-day-pass-modal.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface GiveDayPassModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    locationId: string;
}

const GiveLocationDayPassModal: FunctionComponent<GiveDayPassModalProps> = ({
    visible,
    onComplete,
    locationId,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(GiveLocationDayPassSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const dayPassTypeService = useService(DayPassTypeService);
    const dayPassService = useService(DayPassService);
    const memberService = useService(MemberService);
    const membershipService = useService(MembershipService);

    const [selectedRecepientType, setSelectedRecepientType] =
        useState<DayPassTransactionRecipientType>(DayPassTransactionRecipientType.Membership);

    const pageSize = 25;
    //day pass type dropdown
    const [dayPassSelectLoading, setDayPassSelectLoading] = useState(false);
    const [dayPassTypeCurrentPage, setDayPassTypeCurrentPage] = useState(0);
    const [dayPassTypeSearchResults, setDayPassTypeSearchResults] = useState<DayPassTypeDto[]>([]);
    const [dayPassTypeSearchTerm, setDayPassTypeSearchTerm] = useState('');
    const [dayPassTypeMaxResults, setDayPassTypeMaxResults] = useState(false);
    const [dayPassTypeOptions, setDayPassTypeOptions] = useState<SelectCustomOption[]>([]);
    const [selectedDayPassTypeOptions, setSelectedDayPassTypeOptions] = useState<
        SelectCustomOption[]
    >([]);
    const [selecteddayPassTypeId, setSelectedDayPassTypeId] = useState<string[]>([]);

    //user dropdown
    const [memberSelectLoading, setMemberSelectLoading] = useState(false);
    const [membersCurrentPage, setMembersCurrentPage] = useState(0);
    const [membersSearchResults, setMembersSearchResults] = useState<Member[]>([]);
    const [membersSearchTerm, setMembersSearchTerm] = useState('');
    const [membersMaxResults, setMembersMaxResults] = useState(false);
    const [membersOptions, setMembersOptions] = useState<SelectCustomOption[]>([]);
    const [selectedMemberOption, setSelectedMemberOption] = useState<SelectCustomOption[]>([]);
    const [selectedMemberId, setSelectedMemberId] = useState<string>();

    //company dropdown
    const [membershipSelectLoading, setMembershipSelectLoading] = useState(false);
    const [membershipsCurrentPage, setMembershipsCurrentPage] = useState(0);
    const [membershipsSearchResults, setMembershipsSearchResults] = useState<Membership[]>([]);
    const [membershipsSearchTerm, setMembershipsSearchTerm] = useState('');
    const [membershipsMaxResults, setMembershipsMaxResults] = useState(false);
    const [membershipsOptions, setMembershipsOptions] = useState<SelectCustomOption[]>([]);
    const [selectedMembershipOption, setSelectedMembershipOption] = useState<SelectCustomOption[]>(
        []
    );
    const [selectedMembershipId, setSelectedMembershipId] = useState<string>();
    //#endregion

    //user dropdown
    const resetMembersSearch = (): void => {
        setMembersCurrentPage(0);
        setMembersSearchResults([]);
        setMembersSearchTerm('');
        setMembersMaxResults(false);
    };

    const handleMembersKeywordsChange = useCallback((value: string): void => {
        resetMembersSearch();
        setMembersSearchTerm(value);
    }, []);

    const handleMembersMenuScrollToBottom = (): void => {
        if (!membersMaxResults) {
            setMembersCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const searchMembers = async (page: number, searchTerm: string): Promise<Member[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
        };
        setMemberSelectLoading(true);
        const [results, totalItemCount] = await memberService.getMembers(args);

        if (results.length + pageSize * page >= totalItemCount) {
            setMembersMaxResults(true);
        }
        setMemberSelectLoading(false);
        return results;
    };

    const debounceMembersSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchMembers(page, searchTerm).then((results) => {
                setMembersSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    useEffect(() => {
        debounceMembersSearch.current(membersCurrentPage, membersSearchTerm);
    }, [membersCurrentPage, membersSearchTerm]);

    useEffect(() => {
        const searchResults = membersSearchResults?.map(
            (member: Member) =>
                ({
                    value: member?.id,
                    label: member?.name,
                    imageUrl: member?.imageUrl,
                    badge: undefined,
                    content: member.contactInfo?.email,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedMemberOption);

        setMembersOptions(merged);
    }, [membersSearchResults, t, selectedMemberOption]);

    //company dropdown
    const resetMembershipsSearch = (): void => {
        setMembershipsCurrentPage(0);
        setMembershipsSearchResults([]);
        setMembershipsSearchTerm('');
        setMembershipsMaxResults(false);
    };

    const handleMembershipsKeywordsChange = useCallback((value: string): void => {
        resetMembershipsSearch();
        setMembershipsSearchTerm(value);
    }, []);

    const handleMembershipsMenuScrollToBottom = (): void => {
        if (!membershipsMaxResults) {
            setMembershipsCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const searchMemberships = async (page: number, searchTerm: string): Promise<Membership[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
        };
        setMembershipSelectLoading(true);
        const [results, totalItemCount] = await membershipService.getMemberships(args);

        if (results.length + pageSize * page >= totalItemCount) {
            setMembershipsMaxResults(true);
        }
        setMembershipSelectLoading(false);
        return results;
    };

    const debounceMembershipsSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchMemberships(page, searchTerm).then((results) => {
                setMembershipsSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    useEffect(() => {
        debounceMembershipsSearch.current(membershipsCurrentPage, membershipsSearchTerm);
    }, [membershipsCurrentPage, membershipsSearchTerm]);

    useEffect(() => {
        const searchResults = membershipsSearchResults?.map(
            (membership: Membership) =>
                ({
                    value: membership?.id,
                    label: membership?.name,
                    imageUrl: membership?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(
            searchResults,
            selectedMembershipOption
        );

        setMembershipsOptions(merged);
    }, [membershipsSearchResults, t, selectedMembershipOption]);

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Plan />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`confirm_positive`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();

        const validateObject = {
            selectedMembershipId: selectedMembershipId,
            selectedMemberId: selectedMemberId,
            typeId: formValues.typeId?.value ?? undefined,
            dayPassTransactionRecipientType: selectedRecepientType,
        };

        if (!(await validateForm(validateObject))) return;

        try {
            const request: CreateDayPassRequestDto = {
                membershipId:
                    selectedRecepientType === DayPassTransactionRecipientType.Membership
                        ? selectedMembershipId
                        : undefined,
                accountId:
                    selectedRecepientType === DayPassTransactionRecipientType.Contact
                        ? selectedMemberId
                        : undefined,
                typeId: formValues.typeId?.value ?? undefined,
                noCharge: true,
            };

            globalLoadingStore.addLoading();
            await dayPassService.createDayPass(request);
            toastStore.toast({
                type: 'success',
                messageKey: 'DayPass.give_day_pass_success',
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    const searchDayPassTypes = async (
        page: number,
        searchTerm: string
    ): Promise<DayPassTypeDto[]> => {
        const request: GetDayPassTypesRequestDto = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
            campusIds: [locationId],
        };
        setDayPassSelectLoading(true);
        const [results, totalItemCount] = await dayPassTypeService.getDayPassTypes(request);

        if (results.length + pageSize * page >= totalItemCount) {
            setDayPassTypeMaxResults(true);
        }
        setDayPassSelectLoading(false);
        return results;
    };

    const debounceDayPassTypesSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchDayPassTypes(page, searchTerm).then((results) => {
                setDayPassTypeSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    useEffect(() => {
        debounceDayPassTypesSearch.current(dayPassTypeCurrentPage, dayPassTypeSearchTerm);
    }, [dayPassTypeCurrentPage, dayPassTypeSearchTerm]);

    useEffect(() => {
        const searchResults = dayPassTypeSearchResults?.map(
            (x: DayPassTypeDto) =>
                ({
                    value: x?.id,
                    label: x?.name,
                    content: currencyFormatter(x.price ?? 0),
                    badge: undefined,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(
            searchResults,
            selectedDayPassTypeOptions
        );

        setDayPassTypeOptions(merged);
    }, [dayPassTypeSearchResults, t, selectedDayPassTypeOptions]);

    const handleDayPassTypeKeywordsChange = useCallback((value: string): void => {
        resetDayPassTypeSearch();
        setDayPassTypeSearchTerm(value);
    }, []);

    const resetDayPassTypeSearch = (): void => {
        setDayPassTypeCurrentPage(0);
        setDayPassTypeSearchResults([]);
        setDayPassTypeSearchTerm('');
        setDayPassTypeMaxResults(false);
    };

    const handleDayPassTypeMenuScrollToBottom = (): void => {
        if (!dayPassTypeMaxResults) {
            setDayPassTypeCurrentPage((prevPage) => prevPage + 1);
        }
    };

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Membership.give_a_day_pass')}
            className="FormModal GiveLocationDayPassModal"
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Row gutter={formGutter} className="recipient-type">
                    <Col>
                        <div>{t('Membership.give_a_day_pass_to')}</div>

                        <Radio.Group
                            value={selectedRecepientType}
                            onChange={(value) => setSelectedRecepientType(value.target.value)}
                        >
                            <Radio value={DayPassTransactionRecipientType.Membership}>
                                {t('Membership.give_a_day_pass_to_a_company')}
                            </Radio>
                            <Radio value={DayPassTransactionRecipientType.Contact}>
                                {t('Membership.give_a_day_pass_to_a_user')}
                            </Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                <Row gutter={formGutter}>
                    <Col span={12}>
                        <div
                            className={
                                selectedRecepientType === DayPassTransactionRecipientType.Membership
                                    ? ''
                                    : 'd-none'
                            }
                        >
                            <ValidatedFormItem
                                label={t('company')}
                                name="selectedMembershipId"
                                errors={errors}
                                required
                            >
                                <SelectCustom
                                    options={membershipsOptions}
                                    defaultImg={<Icon iconName="Company" />}
                                    strongLabel={true}
                                    placeholder={t('SelectCustom.placeholder_default')}
                                    hideSelectedOptions={false}
                                    onKeywordsChange={handleMembershipsKeywordsChange}
                                    onMenuScrollToBottom={handleMembershipsMenuScrollToBottom}
                                    onChange={(value): void => {
                                        const options = [value as SelectCustomOption];
                                        setSelectedMembershipOption(options);
                                        setSelectedMembershipId(value.value);
                                    }}
                                    selected={selectedMembershipId ? [selectedMembershipId] : null}
                                    isLoading={membershipSelectLoading}
                                    idAttribute={'selectedMembershipId'}
                                />
                            </ValidatedFormItem>
                        </div>

                        <div
                            className={
                                selectedRecepientType === DayPassTransactionRecipientType.Contact
                                    ? ''
                                    : 'd-none'
                            }
                        >
                            <ValidatedFormItem
                                label={t('Membership.MembershipRoleDto_DayPassConsumer')}
                                name="selectedMemberId"
                                required
                                errors={errors}
                            >
                                <SelectCustom
                                    options={membersOptions}
                                    defaultImg={<Icon iconName="User" />}
                                    strongLabel={true}
                                    placeholder={t('SelectCustom.placeholder_default')}
                                    hideSelectedOptions={false}
                                    onKeywordsChange={handleMembersKeywordsChange}
                                    onMenuScrollToBottom={handleMembersMenuScrollToBottom}
                                    onChange={(value): void => {
                                        const options = [value as SelectCustomOption];
                                        setSelectedMemberOption(options);
                                        setSelectedMemberId(value.value);
                                    }}
                                    selected={selectedMemberId ? [selectedMemberId] : null}
                                    isLoading={memberSelectLoading}
                                    idAttribute={'selectedMemberId'}
                                />
                            </ValidatedFormItem>
                        </div>
                    </Col>

                    <Col span={12}>
                        <ValidatedFormItem name="typeId" errors={errors} label={t('type')} required>
                            <SelectCustom
                                options={dayPassTypeOptions}
                                strongLabel
                                placeholder={t('SelectCustom.placeholder_default')}
                                onKeywordsChange={handleDayPassTypeKeywordsChange}
                                onMenuScrollToBottom={handleDayPassTypeMenuScrollToBottom}
                                onChange={(value: any): void => {
                                    setSelectedDayPassTypeId([value.value]);
                                    const options = [value as SelectCustomOption];
                                    setSelectedDayPassTypeOptions(options);
                                }}
                                hideSelectedOptions={false}
                                selected={selecteddayPassTypeId}
                                isLoading={dayPassSelectLoading}
                                idAttribute="typeId"
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>
                    <Button type="primary" className="positive" htmlType="submit">
                        {t('submit')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(GiveLocationDayPassModal);
