import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { ReminderProxy } from 'Api/Features/Reminders/ReminderProxy';
import { CreateReminderRequestDto } from 'Api/Features/Reminders/Dtos/CreateReminderRequestDto';
import { UpdateReminderRequestDto } from 'Api/Features/Reminders/Dtos/UpdateReminderRequestDto';
import { dateMomentToString } from 'Utils/TimeUtils';

@inject(ReminderProxy)
export class ReminderService extends ApiService {
    constructor(private readonly reminderProxy: ReminderProxy) {
        super();
    }

    public async createReminder(request: CreateReminderRequestDto): Promise<string | null> {
        request.date = dateMomentToString(request.date);

        return await this.reminderProxy.createReminder(request);
    }

    public async updateReminder(id: string, request: UpdateReminderRequestDto): Promise<void> {
        request.date = dateMomentToString(request.date);

        await this.reminderProxy.updateReminder(id, request);
    }

    public async cancelReminder(id: string): Promise<void> {
        await this.reminderProxy.deleteReminder(id);
    }
}
