import { CampusStatusTypeDto } from 'Api/Features/Campuses/Dtos/CampusStatusTypeDto';
import { ActionMenu } from 'Components/action-menu';
import { ActionMenuOption } from 'Components/action-menu/action-menu';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './index.less';

interface LocationItemProps {
    id: string;
    name: string;
    status?: CampusStatusTypeDto;
    backgroundImgUrl?: string | null;
    editLocationModalOpen?: (campusId: string) => void;
    editLocationStatusModalOpen?: (campusId: string) => void;
}

const LocationItem: FunctionComponent<LocationItemProps> = ({
    id,
    name,
    status,
    backgroundImgUrl,
    editLocationModalOpen,
    editLocationStatusModalOpen,
}) => {
    const { t } = useTranslation();

    const menuOptions: ActionMenuOption[] = [];

    if (editLocationModalOpen) {
        menuOptions.push({
            key: 'editLocation',
            title: t('Location.edit_location'),
            action: (): void => editLocationModalOpen(id),
        });
    }

    if (editLocationStatusModalOpen) {
        menuOptions.push({
            key: 'editLocationStatus',
            title: t('Location.edit_location_status'),
            action: (): void => editLocationStatusModalOpen(id),
        });
    }

    return (
        <div
            className={`LocationItem status-${status}`}
            style={{
                backgroundImage: `url(${backgroundImgUrl})` || undefined,
            }}
        >
            <Link to={{ pathname: `locations/${id}` }}>
                <div className="gradient"></div>
                <h1 className="location-name">
                    <span>{name}</span>
                </h1>
            </Link>

            {menuOptions.length > 0 && (
                <ActionMenu options={menuOptions} type="primary" trigger="click" />
            )}
        </div>
    );
};

export default LocationItem;
