import { EventSpeakerType } from 'Routes/authenticated/locations/id/events/create-event-modal';
import * as yup from 'yup';

export const CreateCommunityEvent = yup.object({
    title: yup.string().required('Required'),
    isAllDay: yup.bool(),
    isAllMonth: yup.bool(),
    dates: yup.string().when(('isAllMonth'), {
        is: false,
        then: yup.string().required('Required')
    }),
    month: yup.string().when('isAllMonth', {
        is: true,
        then: yup.string().required('Required')
    }),
    time: yup.string().when('isAllDay', {
        is: false,
        then: yup.string().required('Required')
    }),
    speakerType: yup.string(),
    speakerMembershipId: yup.string().when('speakerType', {
        is: (speakerType) => speakerType === EventSpeakerType.InternalSpeaker,
        then: yup.string().required('Required')
    }),
    speakerAccountId: yup.string().when('speakerType', {
        is: (speakerType) => speakerType === EventSpeakerType.InternalSpeaker,
        then: yup.string().required('Required')
    }),
    description: yup.string().required('Required').max(5000)
});
