import { GetSubscriptionEventsRequestDto } from 'Api/Features/SubscriptionEvents/Dtos/GetSubscriptionEventsRequestDto';
import { GetSubscriptionEventsResponseDto } from 'Api/Features/SubscriptionEvents/Dtos/GetSubscriptionEventsResponseDto';
import { SubscriptionEventDto } from 'Api/Features/SubscriptionEvents/Dtos/SubscriptionEventDto';
import { SubscriptionEventProxy } from 'Api/Features/SubscriptionEvents/SubscriptionEventProxy';
import { CancelSubscriptionRequestDto } from 'Api/Features/Subscriptions/Dtos/CancelSubscriptionRequestDto';
import { CreateSubscriptionCommentRequestDto } from 'Api/Features/Subscriptions/Dtos/CreateSubscriptionCommentRequestDto';
import { GetSubscriptionAncestriesRequestDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionAncestriesRequestDto';
import { GetSubscriptionAncestriesResponseDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionAncestriesResponseDto';
import { GetSubscriptionNoteResponseDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionNoteResponseDto';
import { GetSubscriptionOnboardingStatusResponseDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionOnboardingStatusResponseDto';
import { GetSubscriptionsRequestDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionsRequestDto';
import { GetSubscriptionsResponseDto } from 'Api/Features/Subscriptions/Dtos/GetSubscriptionsResponseDto';
import { GetUpdateSubscriptionDiscountsPreviewResponseDto } from 'Api/Features/Subscriptions/Dtos/GetUpdateSubscriptionDiscountsPreviewResponseDto';
import { SubscriptionAncestryDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionAncestryDto';
import { SubscriptionCancellationInfoDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionCancellationInfoDto';
import { SubscriptionDetailsDto } from 'Api/Features/Subscriptions/Dtos/SubscriptionDetailsDto';
import { UpdateSubscriptionDiscountsRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionDiscountsRequestDto';
import { UpdateSubscriptionNoteRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionNoteRequestDto';
import { UpdateSubscriptionOnboardingCategoryDocumentsRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionOnboardingCategoryDocumentsRequestDto';
import { UpdateSubscriptionOnboardingStatusRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionOnboardingStatusRequestDto';
import { UpdateSubscriptionPaymentSettingsRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionPaymentSettingsRequestDto';
import { UpdateSubscriptionReferenceNumberRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionReferenceNumberRequestDto';
import { UpdateSubscriptionSettingsRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionSettingsRequestDto';
import { UpdateSubscriptionUnitsRequestDto } from 'Api/Features/Subscriptions/Dtos/UpdateSubscriptionUnitsRequestDto';
import { SubscriptionProxy } from 'Api/Features/Subscriptions/SubscriptionProxy';
import { inject } from 'aurelia-dependency-injection';
import { Subscription } from 'Models/Subscriptions/Subscription';
import { ApiService } from './ApiService';

interface SubscriptionRequest {
    subscriptionId: string | null;
}
@inject(SubscriptionProxy, SubscriptionEventProxy)
export class SubscriptionService extends ApiService {
    constructor(
        private readonly subscriptionProxy: SubscriptionProxy,
        private readonly subscriptionEventProxy: SubscriptionEventProxy
    ) {
        super();
    }

    public async getSubscriptions(
        request: GetSubscriptionsRequestDto
    ): Promise<[Subscription[], number]> {
        const response: GetSubscriptionsResponseDto | null = await this.subscriptionProxy.getSubscriptions(
            request
        );
        return [
            response?.items?.map((dto) => new Subscription(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async getSubscriptionAncestry(subscriptionAncestryId: string | null): Promise<SubscriptionAncestryDto | null> {
        const data: SubscriptionAncestryDto | null = await this.subscriptionProxy.getSubscriptionAncestry(subscriptionAncestryId);
        return data;
    }

    public async getSubscriptionAncestries(request: GetSubscriptionAncestriesRequestDto | null): Promise<GetSubscriptionAncestriesResponseDto | null> {
        const data: GetSubscriptionAncestriesResponseDto | null = await this.subscriptionProxy.getSubscriptionAncestries(request);
        return data;
    }

    public async getSubscriptionOnboardingStatus({
        subscriptionId,
    }: SubscriptionRequest): Promise<GetSubscriptionOnboardingStatusResponseDto | null> {
        return this.subscriptionProxy.getSubscriptionOnboardingStatus(subscriptionId);
    }

    public async updateSubscriptionOnboardingStatus(
        subscriptionId: string | null,
        request: UpdateSubscriptionOnboardingStatusRequestDto | null
    ): Promise<void> {
        await this.subscriptionProxy.updateSubscriptionOnboardingStatus(subscriptionId, request);
    }

    public async getSubscription(
        subscriptionId: string | null
    ): Promise<SubscriptionDetailsDto | null> {
        return await this.subscriptionProxy.getSubscription(subscriptionId);
    }

    public async createSubscriptionComment(
        subscriptionId: string | null,
        request: CreateSubscriptionCommentRequestDto | null
    ): Promise<void> {
        await this.subscriptionProxy.createSubscriptionComment(subscriptionId, request);
    }

    public async getSubscriptionEvent(
        subscriptionEventId: string
    ): Promise<SubscriptionEventDto | null> {
        const data: SubscriptionEventDto | null = await this.subscriptionEventProxy.getSubscriptionEvent(
            subscriptionEventId
        );
        return data;
    }

    public async getSubscriptionEvents(
        subscriptionId: string | null,
        request: GetSubscriptionEventsRequestDto | null
    ): Promise<GetSubscriptionEventsResponseDto | null> {
        const data: GetSubscriptionEventsResponseDto | null = await this.subscriptionEventProxy.getSubscriptionEvents(
            subscriptionId,
            request
        );
        return data;
    }

    public async updateSubscriptionOnboardingCategoryDocuments(
        subscriptionId: string | null,
        onboardingCategoryId: string,
        request: UpdateSubscriptionOnboardingCategoryDocumentsRequestDto | null
    ): Promise<void> {
        await this.subscriptionProxy.updateSubscriptionOnboardingCategoryDocuments(
            subscriptionId,
            onboardingCategoryId,
            request
        );
    }

    public async updateSubscriptionReferenceNumber(
        subscriptionId: string | null,
        request: UpdateSubscriptionReferenceNumberRequestDto | null
    ): Promise<void> {
        await this.subscriptionProxy.updateSubscriptionReferenceNumber(subscriptionId, request);
    }

    public async updateSubscriptionUnits(
        subscriptionId: string | null,
        request: UpdateSubscriptionUnitsRequestDto | null
    ): Promise<void> {
        await this.subscriptionProxy.updateSubscriptionUnits(subscriptionId, request);
    }

    public async markSubscriptionSecurityDepositAsRefunded(
        subscriptionId: string | null
    ): Promise<void> {
        await this.subscriptionProxy.markSubscriptionSecurityDepositAsRefunded(subscriptionId);
    }

    public async markSubscriptionSecurityDepositAsRetained(
        subscriptionId: string | null
    ): Promise<void> {
        await this.subscriptionProxy.markSubscriptionSecurityDepositAsRetained(subscriptionId);
    }

    public async getSubscriptionCancellationInfo(
        subscriptionId: string
    ): Promise<SubscriptionCancellationInfoDto | null> {
        const data: SubscriptionCancellationInfoDto | null = await this.subscriptionProxy.getSubscriptionCancellationInfo(
            subscriptionId
        );
        return data;
    }

    public async cancelSubscription(
        subscriptionId: string,
        request: CancelSubscriptionRequestDto | null
    ): Promise<void> {
        await this.subscriptionProxy.cancelSubscription(subscriptionId, request);
    }

    public async getSubscriptionNote(subscriptionId: string): Promise<GetSubscriptionNoteResponseDto | null> {
        const data: GetSubscriptionNoteResponseDto | null = await this.subscriptionProxy.getSubscriptionNote(subscriptionId);
        return data;
    }

    public async updateSubscriptionNote(subscriptionId: string, request: UpdateSubscriptionNoteRequestDto | null): Promise<void> {
        await this.subscriptionProxy.updateSubscriptionNote(subscriptionId, request);
    }

    public async getUpdateSubscriptionDiscountsPreview(subscriptionId: string | null, request: UpdateSubscriptionDiscountsRequestDto | null): Promise<GetUpdateSubscriptionDiscountsPreviewResponseDto | null> {
        const data: GetUpdateSubscriptionDiscountsPreviewResponseDto | null = await this.subscriptionProxy.getUpdateSubscriptionDiscountsPreview(subscriptionId, request);
        return data;
    }

    public async updateSubscriptionDiscounts(subscriptionId: string | null, request: UpdateSubscriptionDiscountsRequestDto | null): Promise<void> {
        await this.subscriptionProxy.updateSubscriptionDiscounts(subscriptionId, request);
    }

    public async updateSubscriptionSettings(subscriptionId: string | null, request: UpdateSubscriptionSettingsRequestDto | null): Promise<void> {
        await this.subscriptionProxy.updateSubscriptionSettings(subscriptionId, request);
    }

    public async updateSubscriptionPaymentSettings(subscriptionId: string | null, request: UpdateSubscriptionPaymentSettingsRequestDto | null): Promise<void> {
        await this.subscriptionProxy.updateSubscriptionPaymentSettings(subscriptionId, request);
    }
}
