import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { UploadFile } from 'antd/lib/upload/interface';
import { CreateCreditPackRequestDto } from 'Api/Features/CreditPacks/Dtos/CreateCreditPackRequestDto';
import { CreditPackDto } from 'Api/Features/CreditPacks/Dtos/CreditPackDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Credit } from 'Components/icons';
import { ImagePicker } from 'Components/image-picker';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { TENTHOUSAND } from 'Models/Constants';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { EditCreditPackSchema } from 'Schemas';
import { CreditPackService } from 'Services/CreditPackService';
import './edit-credit-pack-modal.less';

const { Title } = Typography;

const formGutter: [Gutter, Gutter] = [40, 0];

interface EditCreditPackModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    creditPack?: CreditPackDto;
}

const EditCreditPackModal: FunctionComponent<EditCreditPackModalProps> = ({
    visible,
    onComplete,
    creditPack,
}) => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const creditPackService = useService(CreditPackService);
    const [form] = Form.useForm();

    const [imageDetails, setImageDetails] = useState<ImageDetails[]>();
    const [errors, validateForm, resetErrors] = useFormValidation(EditCreditPackSchema, form);

    const dismiss = (success = false, id?: string): void => {
        onComplete(success, id);
        form.resetFields();
        resetErrors();
    };

    useEffect(() => {
        if (creditPack) {
            form.setFieldsValue({
                price: creditPack.price,
                name: creditPack.name,
                description: creditPack.description ?? undefined,
                credits: creditPack.points,
            });
        }
    }, [creditPack, form]);

    const handleDescriptionChange = (value: string): void => {
        form.setFieldsValue({ description: value });
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();

        const model: CreateCreditPackRequestDto = {
            credits: formValues.credits,
            description: formValues.description,
            name: formValues.name,
            price: formValues.price,
            mainImage: imageDetails
                ? ({
                      delete: imageDetails[0].isDeleted,
                      uploadBase64: imageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null,
        };

        if (!(await validateForm(model))) return;

        try {
            globalLoadingStore.addLoading();
            let createdId = undefined;
            if (creditPack) {
                await creditPackService.updateCreditPack(creditPack.id!, model);
            } else {
                createdId = await creditPackService.createCreditPack(model);
            }
            toastStore.toast({
                type: 'success',
                messageKey: `Administration.credit_pack_${creditPack ? 'edited' : 'created'}`,
            });
            dismiss(true, createdId);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Credit />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t(
                    `Administration.credit_pack_confirm_positive${
                        creditPack !== undefined ? '_edit' : ''
                    }`
                ),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    return (
        <BaseModal
            visible={visible}
            title={
                creditPack !== undefined
                    ? t('Administration.create_credit_pack')
                    : t('Administration.edit_credit_pack')
            }
            className="FormModal"
            onCancel={exit}
        >
            <div className="EditCreditPackModal">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Title level={4}>{t('basic_information')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="name"
                                label={t('name')}
                                required={true}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="credits"
                                label={t('credits')}
                                required={true}
                            >
                                <NumberFormat
                                    decimalScale={0}
                                    allowNegative={false}
                                    customInput={(props: any) => <Input {...props} />}
                                    isAllowed={(value: NumberFormatValues): boolean =>
                                        value.floatValue === undefined ||
                                        (value.floatValue !== undefined &&
                                            value.floatValue <= TENTHOUSAND)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="price"
                                label={t('price')}
                                required={true}
                            >
                                <NumberFormat
                                    allowNegative={false}
                                    customInput={(props: any) => (
                                        <Input addonBefore={'$'} {...props} />
                                    )}
                                    isAllowed={(value: NumberFormatValues): boolean =>
                                        value.floatValue === undefined ||
                                        (value.floatValue !== undefined &&
                                            value.floatValue <= TENTHOUSAND)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="currency"
                                label={t('currency')}
                            >
                                <Input disabled defaultValue="USD" />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="description"
                                label={t('ConferenceRoom.description_title')}
                                className="description"
                            >
                                <Input hidden />
                            </ValidatedFormItem>
                            <ReactQuill
                                theme="snow"
                                className="description-quill"
                                onChange={handleDescriptionChange}
                                value={
                                    form.getFieldValue('description') ||
                                    creditPack?.description ||
                                    null
                                }
                            />
                        </Col>
                    </Row>

                    <Title level={4}>{t('main_image')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem errors={errors} name="image">
                                <ImagePicker
                                    images={
                                        creditPack?.mainImageUrl
                                            ? [
                                                  {
                                                      url: creditPack?.mainImageUrl,
                                                      uid: 'main',
                                                  } as UploadFile,
                                              ]
                                            : undefined
                                    }
                                    setImagesDetails={(images: ImageDetails[] | undefined) =>
                                        setImageDetails(images)
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default EditCreditPackModal;
