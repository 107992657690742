import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const WhiteBoard: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 2.5v13.038h12.569v.796H.384L0 16.337v.77h20v-.773h-1.812v-.796h1.81V2.5H0zm.77.77h18.459v11.498h-1.04v-1.207h-5.62v1.207H.769V3.27zm12.568 12.645h4.08v-1.584h-4.08v1.584z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default WhiteBoard;
