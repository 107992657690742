// GENERATED FILE - DO NOT MODIFY
import { AddOnDto } from 'Api/Features/AddOns/Dtos/AddOnDto';
import { CreateAddOnRequestDto } from 'Api/Features/AddOns/Dtos/CreateAddOnRequestDto';
import { GetAddOnsRequestDto } from 'Api/Features/AddOns/Dtos/GetAddOnsRequestDto';
import { GetAddOnsResponseDto } from 'Api/Features/AddOns/Dtos/GetAddOnsResponseDto';
import { UpdateAddOnRequestDto } from 'Api/Features/AddOns/Dtos/UpdateAddOnRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class AddOnProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getAddOn(addOnId: string): Promise<AddOnDto | null> {
        const uri = this.buildUri(
            "/addons/{addOnId}",
            { addOnId: addOnId },
            null
        );

        const data: AddOnDto | null = await this.httpClient.get<AddOnDto | null>(uri);
        return data;
    }

    public async getAddOns(request: GetAddOnsRequestDto | null): Promise<GetAddOnsResponseDto | null> {
        const uri = this.buildUri(
            "/addons",
            null,
            { ...request || undefined }
        );

        const data: GetAddOnsResponseDto | null = await this.httpClient.get<GetAddOnsResponseDto | null>(uri);
        return data;
    }

    public async getCampusAddOns(campusId: string): Promise<(AddOnDto | null)[] | null> {
        const uri = this.buildUri(
            "/campus/{campusId}/addons",
            { campusId: campusId },
            null
        );

        const data: (AddOnDto | null)[] | null = await this.httpClient.get<(AddOnDto | null)[] | null>(uri);
        return data;
    }

    public async createAddOn(request: CreateAddOnRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/addons",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateAddOnRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateAddOn(addonId: string, request: UpdateAddOnRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/addons/{addonId}",
            { addonId: addonId },
            null
        );

        await this.httpClient.patch<UpdateAddOnRequestDto | null, void>(uri, request);
    }

    public async deleteAddOn(addonId: string): Promise<void> {
        const uri = this.buildUri(
            "/addons/{addonId}",
            { addonId: addonId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}