import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AdministrationNut: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.0009 8.96012C10.3567 8.96012 9.01899 10.2978 9.01899 11.9422C9.01899 13.5819 10.3567 14.9158 12.0009 14.9158C13.6407 14.9158 14.9748 13.5819 14.9748 11.9422C14.9748 10.2978 13.6407 8.96012 12.0009 8.96012ZM12.0007 15.841C9.84612 15.841 8.09363 14.092 8.09363 11.9422C8.09363 9.78768 9.84612 8.03485 12.0007 8.03485C14.1506 8.03485 15.8998 9.78768 15.8998 11.9422C15.8998 14.092 14.1506 15.841 12.0007 15.841ZM5.76319 17.7353L6.01411 17.9861C7.02691 18.9989 8.30189 19.7427 9.70084 20.1368L10.036 20.2313L10.0519 22.9443L13.9522 22.9178L13.9364 20.2293L14.2795 20.136C15.6226 19.7697 16.8696 19.0556 17.8857 18.0715L18.1366 17.8282L20.4967 19.1937L22.4506 15.818L20.1389 14.4766L20.2254 14.1376C20.4123 13.406 20.5071 12.6673 20.5071 11.9422C20.5071 11.2406 20.423 10.5449 20.2574 9.87441L20.1739 9.53675L22.524 8.18034L20.5702 4.80478L18.239 6.14812L17.9885 5.89934C16.9553 4.87397 15.6773 4.12715 14.2928 3.73918L13.9568 3.64516L13.9414 0.93151L10.0408 0.957993L10.0567 3.64467L9.71607 3.73934C8.36049 4.11672 7.11265 4.83093 6.10746 5.8045L5.85621 6.04798L3.50355 4.6823L1.5425 8.05934L3.85411 9.40831L3.76771 9.74663C3.58084 10.4774 3.48617 11.2163 3.48617 11.9422C3.48617 12.6385 3.56992 13.3312 3.7356 14.0014L3.81886 14.3386L1.47662 15.695L3.43138 19.0792L5.76319 17.7353ZM9.13227 23.8759L9.11489 20.9263C7.80996 20.5061 6.61227 19.8079 5.61702 18.8877L3.09272 20.3424L0.213379 15.3575L2.76003 13.8828C2.62794 13.247 2.56107 12.5959 2.56107 11.9422C2.56107 11.2522 2.63821 10.5529 2.79032 9.85887L0.279089 8.39305L3.16819 3.41777L5.7213 4.89997C6.70165 4.02025 7.86756 3.35288 9.12747 2.94985L9.11026 0.0392276L14.8612 0L14.8784 2.94935C16.1732 3.36579 17.3734 4.06709 18.3835 4.99713L20.9085 3.54207L23.7888 8.51868L21.2332 9.99377C21.3655 10.6303 21.4324 11.2841 21.4324 11.9422C21.4324 12.6313 21.3556 13.3301 21.2033 14.0246L23.7145 15.4813L20.8343 20.4579L18.2726 18.9757C17.2838 19.8641 16.1178 20.5322 14.8661 20.9277L14.883 23.8368L9.13227 23.8759Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default AdministrationNut;
