import { Button, Col, DatePicker, Form, Input, Row, Select, TimePicker } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { TourDto } from 'Api/Features/Tours/Dtos/TourDto';
import { UpdateTourRequestDto } from 'Api/Features/Tours/Dtos/UpdateTourRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Coffee, Plan } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { DATETIME_FORMAT, MOMENT_PARSING_FORMAT, MOMENT_TIME_FORMAT, TIME_PICKER_FORMAT } from 'Models/Constants';
import { LightCampusDetailsInfo } from 'Models/Location/LightCampusInfo';
import moment from 'moment';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ScheduleTourSchema } from 'Schemas';
import { TourService } from 'Services/TourService';
import './edit-tour.less';

const formGutter: [Gutter, Gutter] = [40, 0];

interface EditTourModalProps {
    visible: boolean;
    tour?: TourDto | null;
    onComplete: (success: boolean) => void;
}

const EditTourModal: FunctionComponent<EditTourModalProps> = ({ visible, onComplete, tour }) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(ScheduleTourSchema, form);
    const { globalLoadingStore, toastStore, confirmationModalStore, locationStore } = useStores();
    const tourService = useService(TourService);

    //#region Effects
    useEffect(() => {
        if (tour) {
            form.setFieldsValue({
                oldDate: moment.utc(tour.startTime),
                oldHours: [moment.utc(tour.startTime), moment.utc(tour.endTime)],
                oldCampusId: tour.campus?.id,
                note: tour.note,
            });
        }
    }, [tour, form]);
    //#endregion

    //#region Event handlers
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Plan />,
                title: t(`confirm_title`),
                message: t(`confirm_message`),
                positiveText: t(`Lead.lead_confirm_leave_tour_schedule`),
                negativeText: t(`confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const cancelTour = async (id: string): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Coffee />,
                title: t(`Lead.delete_tour`),
                message: t(`Lead.delete_tour_confirm_message`),
                positiveText: t(`Lead.delete_tour`),
                negativeText: t(`cancel`),
            }))
        )
            return;
        //call delete
        await tourService.cancelTour(id);
        toastStore.toast({
            type: 'success',
            messageKey: `Lead.tour_delete_success`,
        });

        dismiss(true);
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const data = {
            date: formValues.date ?? undefined,
            hours: formValues.hours ?? undefined,
            note: formValues.note,
            campusId: formValues.campusId,
        };

        if (!(await validateForm(data))) return;

        try {
            globalLoadingStore.addLoading();
            //Edit a tour
            const request: UpdateTourRequestDto = {
                ...data,
                startTime: moment(
                    formValues.date.format(MOMENT_PARSING_FORMAT) +
                        formValues.hours[0].format(MOMENT_TIME_FORMAT),
                    DATETIME_FORMAT
                ).format(),
                endTime: moment(
                    formValues.date.format(MOMENT_PARSING_FORMAT) +
                        formValues.hours[1].format(MOMENT_TIME_FORMAT),
                    DATETIME_FORMAT
                ).format(),
            };
            await tourService.updateTour(tour?.id!, request);

            toastStore.toast({
                type: 'success',
                messageKey: `Lead.tour_rescheduled_successfully`,
            });
            dismiss(true);
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion
    
    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={t('Lead.schedule_a_tour_edit')}
            className="FormModal"
            onCancel={exit}
        >
            <div className="EditTour">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="oldDate"
                                label={t('Lead.old_tour_scheduled_date')}
                                required
                            >
                                <DatePicker
                                    className="w-100"
                                    placeholder={t('Lead.meeting_scheduled_date_placeholder')}
                                    defaultValue={moment(tour?.startTime)}
                                    disabled={true}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="oldHours"
                                label={t('Opportunity.old_tour_scheduled_time')}
                                required
                            >
                                <TimePicker.RangePicker
                                    format={TIME_PICKER_FORMAT}
                                    disabled
                                    use12Hours
                                    style={{ width: '100%' }}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="oldCampusId"
                                errors={errors}
                                label={t('Opportunity.tour_old_location')}
                                required
                            >
                                <Select disabled>
                                    {locationStore.locations?.map((x: LightCampusDetailsInfo) => (
                                        <Select.Option key={x.id!} value={x.id!}>
                                            {x.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="date"
                                label={t('Lead.new_tour_scheduled_date')}
                                required
                            >
                                <DatePicker
                                    className="w-100"
                                    placeholder={t('Lead.meeting_scheduled_date_placeholder')}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="hours"
                                label={t('Opportunity.new_tour_scheduled_time')}
                                required
                            >
                                <TimePicker.RangePicker
                                    format={TIME_PICKER_FORMAT}
                                    use12Hours
                                    style={{ width: '100%' }}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                name="campusId"
                                errors={errors}
                                label={t('Opportunity.tour_new_location')}
                                required
                            >
                                <Select>
                                    {locationStore.locations?.map((x: LightCampusDetailsInfo) => (
                                        <Select.Option key={x.id!} value={x.id!}>
                                            {x.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                errors={errors}
                                name="note"
                                label={t('Lead.lead_add_a_note')}
                            >
                                <Input.TextArea />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            type="primary"
                            className="cancel-tour"
                            htmlType="button"
                            onClick={(): void => {
                                cancelTour(tour?.id!);
                            }}
                        >
                            {t('Lead.cancel_scheduled_tour')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('Lead.schedule_a_tour_update')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(EditTourModal);
