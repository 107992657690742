import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AngleTop: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => (
    <span role="img" className="anticon">
        <svg width="1em" height="1em" viewBox="0 0 17 10" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.201388 8.52513C-0.0586659 8.78785 -0.0586659 9.21327 0.201388 9.47666C0.461442 9.73872 0.888198 9.73872 1.14825 9.47666L8.00302 1.95309L14.8644 9.47666C15.1245 9.73872 15.5446 9.73872 15.8113 9.47666C16.0714 9.21327 16.0714 8.78785 15.8113 8.52513L8.51646 0.518795C8.37643 0.378766 8.18972 0.318752 8.00302 0.328088C7.82298 0.318752 7.63627 0.378766 7.49624 0.518795L0.201388 8.52513Z"
                fill={fill || 'currentColor'}
            />
        </svg>
    </span>
);

export default AngleTop;
