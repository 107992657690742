import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Facebook: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.10812 23.0679H9.54412V11.442C9.54412 11.1847 9.75278 10.976 10.0103 10.976H13.9318V8.68252H10.0181C9.76078 8.68252 9.55211 8.47385 9.55211 8.21635V6.27619C9.55211 4.81952 10.5559 3.34968 12.7981 3.34968H14.1533V0.934352H10.9038C10.8929 0.934352 10.8823 0.934018 10.8714 0.933185C10.7174 0.923352 7.06562 0.733352 6.10611 4.50519C6.10278 4.51852 6.09862 4.53168 6.09412 4.54468C6.09128 4.55285 5.73545 5.63469 5.78595 8.20735C5.78845 8.33252 5.74045 8.45335 5.65278 8.54269C5.56512 8.63219 5.44528 8.68252 5.31995 8.68252H1.73278V10.9642H5.64212C5.89945 10.9642 6.10812 11.1729 6.10812 11.4304V23.0679ZM10.0103 24H5.64212C5.38461 24 5.17595 23.7912 5.17595 23.5339V11.8964H1.26678C1.00928 11.8964 0.800781 11.6877 0.800781 11.4304V8.21635C0.800781 7.95902 1.00928 7.75035 1.26678 7.75035H4.84895C4.84562 5.48569 5.15178 4.43102 5.20678 4.25969C6.32512 -0.0966482 10.5684 -0.0111484 10.9138 0.00235161H14.6193C14.8768 0.00235161 15.0854 0.211018 15.0854 0.468352V3.81585C15.0854 4.07318 14.8768 4.28185 14.6193 4.28185H12.7981C11.0921 4.28185 10.4843 5.31202 10.4843 6.27619V7.75035H14.3979C14.6553 7.75035 14.8639 7.95902 14.8639 8.21635V11.442C14.8639 11.6995 14.6553 11.9082 14.3979 11.9082H10.4763V23.5339C10.4763 23.7912 10.2676 24 10.0103 24Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Facebook;
