import { Button, Form } from 'antd';
import { CreateUpdateAddressRequestDto } from 'Api/Features/General/Dtos/CreateUpdateAddressRequestDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { UpdateMemberRequestDto } from 'Api/Features/Members/Dtos/UpdateMemberRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import Icon from 'Components/icons/icon';
import { ImageDetails } from 'Components/image-picker/image-picker';
import { useFormValidation, useService, useStores } from 'Hooks';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactFormSection from 'Routes/authenticated/opportunities/contact-form-section';
import { CreateContactSchema } from 'Schemas/CreateContactSchema';
import { MemberService } from 'Services/MemberService';
import { dateMomentToString } from 'Utils/TimeUtils';
import { ContactContext } from '../id';
import './edit-contact.less';

interface EditContactModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
}

const EditContact: FunctionComponent<EditContactModalProps> = ({ visible, onComplete }) => {
    const memberService = useService(MemberService);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [contactImageDetails, setContactImageDetails] = useState<ImageDetails[]>();
    const [form] = Form.useForm();
    const [contactErrors, validateContactForm, resetContactErrors] = useFormValidation(
        CreateContactSchema,
        form
    );
    const contactContext = useContext(ContactContext);
    const { t } = useTranslation();

    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetContactErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="User" />,
                title: t('User.contacts_create_contact_confirm_title'),
                message: t('User.contacts_create_contact_confirm_message'),
                positiveText: t('User.contacts_edit_contact_confirm_positive'),
                negativeText: t('User.contacts_create_contact_confirm_negative'),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const contactForm: UpdateMemberRequestDto = {
            image: contactImageDetails
                ? ({
                      delete: contactImageDetails[0].isDeleted,
                      uploadBase64: contactImageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null,
            salutation: formValues['contact.salutation'],
            firstName: formValues['contact.firstName'],
            middleName: formValues['contact.middleName'],
            lastName: formValues['contact.lastName'],
            birthDate: dateMomentToString(formValues['contact.birthDate']),
            wifiPassword: formValues['contact.wifiPassword'],
            jobPosition: formValues['contact.jobPosition'],
            introduction: formValues['contact.introduction'],
            expertiseIds: formValues['contact.expertises']?.map(
                (expertise: { value: string; label: string }) => expertise.value
            ),
            contactInfo: {
                phone1: formValues['contact.contactInfo.phone1'],
                phone2: formValues['contact.contactInfo.phone2'],
                email: formValues['contact.contactInfo.email'],
                angelListUrl: formValues['contact.contactInfo.angelListUrl'],
                twitterUrl: formValues['contact.contactInfo.twitterUrl'],
                facebookUrl: formValues['contact.contactInfo.facebookUrl'],
                linkedInUrl: formValues['contact.contactInfo.linkedInUrl'],
                instagramUrl: formValues['contact.contactInfo.instagramUrl'],
            },
            address: {
                addressLine1: formValues['contact.address.addressLine1'],
                city: formValues['contact.address.city'],
                country: formValues['contact.address.country'],
                postalCode: formValues['contact.address.postalCode'],
                state: formValues['contact.address.state'],
            } as CreateUpdateAddressRequestDto,
            isPrivate: !formValues['contact.isPrivate'],
        };

        if (!(await validateContactForm({ contact: contactForm }))) return;

        try {
            globalLoadingStore.addLoading();

            if (contactContext?.id)
                await memberService.updateMember(contactContext?.id, contactForm);

            toastStore.toast({
                type: 'success',
                messageKey: t('User.contacts_edit_contact_confirmation'),
            });

            dismiss(true);
        } catch (e) {
            if (e.response.data.modelState['contactInfo.Email'] !== undefined) {
                toastStore.toast({
                    type: 'error',
                    messageKey: e.response.data.modelState['contactInfo.Email'][0],
                });
            }
            else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const actionsButtons = {
        prev: {
            label: t('cancel'),
            onClick: exit,
        },
        next: {
            label: t('submit'),
            onClick: submit,
        },
    };

    return (
        <BaseModal
            className="FormModal EditContact"
            visible={visible}
            title={t(`User.contacts_edit_contact`)}
            onCancel={exit}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <ContactFormSection
                    setImageDetails={setContactImageDetails}
                    contactData={contactContext}
                    errors={contactErrors}
                    form={form}
                />

                <div className="actions">
                    <Button
                        type="default"
                        className="secondary negative"
                        htmlType="button"
                        onClick={actionsButtons.prev.onClick}
                    >
                        {actionsButtons.prev.label}
                    </Button>

                    <Button
                        type="primary"
                        className="positive"
                        htmlType="button"
                        onClick={actionsButtons.next.onClick}
                    >
                        {actionsButtons.next.label}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(EditContact);
