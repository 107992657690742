import { Button, Layout, Table, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetOpportunitiesFlagDto } from 'Api/Features/Opportunities/Dtos/GetOpportunitiesFlagDto';
import { GetOpportunitiesSortColumnDto } from 'Api/Features/Opportunities/Dtos/GetOpportunitiesSortColumnDto';
import { OpportunityStatusDto } from 'Api/Features/Opportunities/Dtos/OpportunityStatusDto';
import { OpportunityUpcomingActivityDto } from 'Api/Features/Opportunities/Dtos/OpportunityUpcomingActivityDto';
import Icon from 'Components/icons/icon';
import { ListSectionHeader } from 'Components/list-section-header';
import { TableFilters } from 'Components/table-filters';
import Tag, { getOpportunityStatusTag, TagColors } from 'Components/tag/tag';
import { TdWithImage } from 'Components/td-with-image';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import {
    ALL_LOCATIONS,
    ALL_OPPORTUNITIES,
    ALL_OWNERS,
    DISPLAY_DATE_FORMAT,
    MOMENT_PARSING_FORMAT,
    MY_OPPORTUNITIES,
    TABLE_DEBOUNCE_DELAY,
} from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { ManagerUser } from 'Models/ManagerUsers/ManagerUser';
import { Opportunity } from 'Models/Opportunities/Opportunity';
import moment from 'moment';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ManagerUserService } from 'Services/ManagerUserService';
import { OpportunityRequest, OpportunityService } from 'Services/OpportunityService';
import FilterStore from 'Stores/FilterStore';
import { RequestType } from 'Stores/RequestStore';
import { showFile } from 'Utils';
import { cleanVal } from 'Utils/NumberUtils';
import { images } from 'variant';
import CreateOpportunityModal from './create-opportunity';
import './index.less';

const { usersHeader } = images;
const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 10,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'statuses',
        nameKey: 'Statuses',
        items: [
            {
                key: OpportunityStatusDto.New,
                displayNameKey: 'Opportunity.opportunity_status_New',
                checked: true,
            },
            {
                key: OpportunityStatusDto.TourScheduled,
                displayNameKey: 'Opportunity.opportunity_status_TourScheduled',
                checked: true,
            },
            {
                key: OpportunityStatusDto.Qualified,
                displayNameKey: 'Opportunity.opportunity_status_Qualified',
                checked: true,
            },
            {
                key: OpportunityStatusDto.Proposal,
                displayNameKey: 'Opportunity.opportunity_status_Proposal',
                checked: true,
            },
            {
                key: OpportunityStatusDto.Agreed,
                displayNameKey: 'Opportunity.opportunity_status_Agreed',
                checked: true,
            },
            {
                key: OpportunityStatusDto.AgreementSent,
                displayNameKey: 'Opportunity.opportunity_status_AgreementSent',
                checked: true,
            },
            {
                key: OpportunityStatusDto.AgreementSigned,
                displayNameKey: 'Opportunity.opportunity_status_AgreementSigned',
                checked: true,
            },
            {
                key: OpportunityStatusDto.Lost,
                displayNameKey: 'Opportunity.opportunity_status_Lost',
                checked: true,
            },
        ],
    },
    {
        key: 'types',
        nameKey: 'Types',
        items: [
            {
                key: GetOpportunitiesFlagDto.IsNewSubscription,
                displayNameKey: 'new_subscription',
                checked: true,
            },
            {
                key: GetOpportunitiesFlagDto.IsSubscriptionModification,
                displayNameKey: 'subscription_modification',
                checked: true,
            },
            {
                key: GetOpportunitiesFlagDto.IsSubscriptionRenewal,
                displayNameKey: 'subscription_renewal',
                checked: true,
            },
        ],
    },
];

const Opportunities: FunctionComponent = () => {
    const { t } = useTranslation();
    const paginationRef = useRef(initialPaginationState);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const {
        requestStore,
        userPermissionsStore,
        locationStore,
        authenticationStore,
        globalLoadingStore,
        toastStore,
    } = useStores();
    const managerUserService = useService(ManagerUserService);
    const opportunityService = useService(OpportunityService);
    const [loading, setLoading] = useState(false);
    const [salePersons, setSalesPersons] = useState<ManagerUser[]>();
    const [data, setData] = useState<Opportunity[]>();
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const history = useHistory();
    const [requestSetFromCache, setRequestSetFromCache] = useState(false);
    const [isTableEmpty, setIsTableEmpty] = useState(false);

    const companyRender = (opportunity: Opportunity): ReactNode =>
        opportunity.membership ? (
            <TdWithImage
                defaultImg={<Icon iconName="User" />}
                imgSrc={opportunity.membership.imageUrl}
            >
                {opportunity.membership.name}
            </TdWithImage>
        ) : (
            ''
        );

    const renderUpcomingActivity = (opportunity: Opportunity): ReactNode => {
        const currentDate = moment();
        let closestTimeStamp = Number.MAX_SAFE_INTEGER;
        let eventType = '';
        let eventDate = moment();
        let hasActivity = false;

        const getActivity = (activities: (OpportunityUpcomingActivityDto | null)[]): void => {
            activities
                .filter(
                    (activity) =>
                        activity !== null &&
                        activity.startTime !== undefined &&
                        !currentDate.isSameOrAfter(activity.startTime)
                )
                .map((x) => x)
                .forEach((activity) => {
                    if (activity) {
                        const diff = moment(activity.startTime).diff(currentDate);

                        if (diff !== undefined && diff < closestTimeStamp) {
                            closestTimeStamp = diff;
                            eventType = activity.type?.toString() ?? '';
                            eventDate = moment.parseZone(activity.startTime);
                            hasActivity = true;
                        }
                    }
                });
        };

        if (opportunity.upcomingActivities) {
            getActivity(opportunity.upcomingActivities);
        }
        return (
            hasActivity && (
                <>
                    <Typography.Text className="event-type-label">
                        {`${t(`Lead.lead_event_${eventType}`)} `}
                    </Typography.Text>

                    <Typography.Text>
                        {`${t('Activity.add_activity_on')}
                            ${moment(eventDate, MOMENT_PARSING_FORMAT).format(
                                DISPLAY_DATE_FORMAT
                            )}`}
                    </Typography.Text>
                </>
            )
        );
    };

    const columns: ColumnType<Opportunity>[] = [
        {
            title: t('contact'),
            render: (opportunity: Opportunity): string =>
                `${opportunity.contact?.firstName} ${opportunity.contact?.lastName}`,
        },
        {
            title: t('company'),
            render: companyRender,
            sorter: true,
            key: GetOpportunitiesSortColumnDto.MembershipName,
        },
        {
            title: t('creation'),
            render: (opportunity: Opportunity): string =>
                moment(opportunity.dateCreated).format(MOMENT_PARSING_FORMAT),
            sorter: true,
            key: GetOpportunitiesSortColumnDto.DateCreated,
        },
        {
            title: t('Opportunity.opportunity_owner'),
            render: (opportunity: Opportunity): string =>
                `${opportunity.ownerAccount?.firstName} ${opportunity.ownerAccount?.lastName}`,
            sorter: true,
            key: GetOpportunitiesSortColumnDto.OwnerAccountName,
        },
        {
            title: t('Lead.source'),
            render: (opportunity: Opportunity): string => t(`Lead.source_${opportunity.source}`),
            sorter: true,
            key: GetOpportunitiesSortColumnDto.Source,
        },
        {
            title: t('location'),
            render: (opportunity: Opportunity): string => opportunity.campus?.name ?? '',
            sorter: true,
            key: GetOpportunitiesSortColumnDto.CampusName,
        },
        {
            title: t('Opportunity.desk_count'),
            dataIndex: 'deskCount',
            sorter: true,
            key: GetOpportunitiesSortColumnDto.DeskCount,
        },
        {
            title: t('Lead.upcoming_activity'),
            render: renderUpcomingActivity,
        },
        {
            title: t('status'),
            // eslint-disable-next-line react/display-name
            render: (opportunity: Opportunity): ReactNode | null => (
                <div>
                    {getOpportunityStatusTag(opportunity, t)}
                    {opportunity.isSubscriptionModification && (
                        <Tag boldLabel color={TagColors.cold} label={t('modification')} />
                    )}
                    {opportunity.isSubscriptionRenewal && (
                        <Tag boldLabel color={TagColors.cold} label={t('renewal')} />
                    )}
                    {!opportunity.isSubscriptionRenewal &&
                        !opportunity.isSubscriptionModification && (
                            <Tag boldLabel color={TagColors.cold} label={t('new_subscription')} />
                        )}
                </div>
            ),
            sorter: true,
            key: GetOpportunitiesSortColumnDto.Status,
            align: 'center',
        },
    ];

    const getCampusId = (currentLocationId: string): string[] => {
        return currentLocationId !== ALL_LOCATIONS ? [currentLocationId] : [];
    };

    const getOpportunityOwnerId = useCallback(
        (currentOpportunityOwnerId: string): string[] => {
            if (currentOpportunityOwnerId === ALL_OPPORTUNITIES) return [];
            if (currentOpportunityOwnerId === MY_OPPORTUNITIES)
                return [authenticationStore.userId!];

            return [currentOpportunityOwnerId];
        },
        [authenticationStore.userId]
    );

    const getAdvancedFilterStatuses = (): { checkedStatuses: string[]; statusCount: number } => {
        const filterStore = filterStoreRef.current;
        const statuses = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'statuses'
        );
        const checkedStatuses = statuses?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key;
            });

        return {
            checkedStatuses: checkedStatuses ?? [],
            statusCount: statuses?.items.length ?? 0,
        };
    };

    const getAdvancedFilterTypes = (): { checkedTypes: string[]; typesCount: number } => {
        const filterStore = filterStoreRef.current;
        const types = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'types'
        );
        const checkedTypes = types?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key;
            });
        return {
            checkedTypes: checkedTypes ?? [],
            typesCount: types?.items.length ?? 0,
        };
    };

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            currentLocationId?: string;
            advancedFilters?: AdvancedFilter[];
            currentOpportunityOwnerId?: string;
            sortColumn: GetOpportunitiesSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);
            const filterStore = filterStoreRef.current;
            try {
                const statuses = getAdvancedFilterStatuses();
                const types = getAdvancedFilterTypes();

                if (statuses.checkedStatuses.length === 0) {
                    setData([]);
                } else if (types.checkedTypes.length === 0) {
                    setData([]);
                } else {
                    const request: OpportunityRequest = requestStore.setRequest({
                        request: {
                            pageSize: params.pagination.pageSize || 10,
                            page: (params.pagination.current || 1) - 1,
                            searchTerm: params.searchTerm,
                            campusIds: getCampusId(filterStore.currentLocationId),
                            ownerAccountIds: getOpportunityOwnerId(
                                filterStore.currentOpportunityOwnerId
                            ),
                            statuses:
                                statuses.checkedStatuses.length === statuses.statusCount
                                    ? []
                                    : statuses.checkedStatuses,
                            sortColumn: params.sortColumn,
                            sortDirection: params.sortDirection,
                            flags:
                                types.checkedTypes.length === types.typesCount
                                    ? []
                                    : types.checkedTypes,
                        },
                        requestType: RequestType.Opportunity,
                    });

                    //call api
                    const [data, totalItems] = await opportunityService.getOpportunities(request);
                    setData(data);
                    setPagination({
                        ...params.pagination,
                        total: totalItems,
                    });
                    setIsTableEmpty(data.length === 0);
                }
            } finally {
                setLoading(false);
            }
        },
        [opportunityService, getOpportunityOwnerId, requestStore]
    );

    const getManagerUsers = useCallback(async () => {
        setLoading(true);
        try {
            // call api
            const [data] = await managerUserService.getManagerUsers({
                page: 0,
                pageSize: 1000,
            }); //TODO probablement devoir integrer un select custom dans les filtres de table
            const salesPersonsWithoutCurrentUser = data?.filter(
                (x: ManagerUser) => x.id !== authenticationStore.userId
            );
            setSalesPersons(salesPersonsWithoutCurrentUser);
        } finally {
            setLoading(false);
        }
    }, [managerUserService, authenticationStore.userId]);

    useEffect(() => {
        getManagerUsers();
    }, [getManagerUsers]);

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        const { currentLocationId, advancedFilters, searchTerm, currentOpportunityOwnerId } =
            filterStoreRef.current;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }
        await fetch({
            pagination,
            currentLocationId,
            advancedFilters,
            searchTerm,
            currentOpportunityOwnerId: currentOpportunityOwnerId,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });
        paginationRef.current = pagination;
    };

    const onRowClick = (opportunity: Opportunity): void => {
        history.push(`opportunities/${opportunity.id}/dashboard`);
    };

    const debounceSearch = useRef(
        debounce(
            (params: {
                searchTerm?: string;
                currentLocationId?: string;
                advancedFilters?: AdvancedFilter[];
                currentOpportunityOwnerId?: string;
            }) => {
                fetch({
                    pagination: {
                        ...paginationRef.current,
                        current: 1,
                    },
                    searchTerm: params.searchTerm,
                    currentLocationId: params.currentLocationId,
                    currentOpportunityOwnerId: params.currentOpportunityOwnerId,
                    advancedFilters: params.advancedFilters,
                    sortColumn: null,
                    sortDirection: null,
                });
            },
            TABLE_DEBOUNCE_DELAY
        )
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;

            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                currentLocationId: filterStore.currentLocationId,
                advancedFilters: filterStore.advancedFilters,
                currentOpportunityOwnerId: filterStore.currentOpportunityOwnerId,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    useEffect(() => {
        if (
            requestStore?.requestInfo?.request &&
            requestStore?.requestInfo?.requestType === RequestType.Opportunity &&
            !requestSetFromCache
        ) {
            setRequestSetFromCache(true);
            const filterStore = filterStoreRef.current;
            const requestFromStore: OpportunityRequest = requestStore.requestInfo.request;
            const filtersList = [
                { key: requestFromStore?.statuses || [], parentKey: 'statuses' },
                { key: requestFromStore?.flags || [], parentKey: 'types' },
            ];

            filterStore.updateOpportunityOwner(
                requestFromStore.ownerAccountIds && requestFromStore.ownerAccountIds.length > 0
                    ? requestFromStore.ownerAccountIds[0] === userPermissionsStore.userInfo?.id
                        ? MY_OPPORTUNITIES
                        : requestFromStore.ownerAccountIds[0]
                    : ALL_OWNERS
            );
            filterStore.updateLocation(
                requestFromStore.campusIds && requestFromStore?.campusIds.length > 0
                    ? requestFromStore.campusIds[0]
                    : ALL_LOCATIONS
            );
            filterStore.updateSearchTerm(cleanVal.string(requestFromStore.searchTerm));
            filterStore.tickMultipleAdvancedFilter(filtersList);
        }
    }, [requestStore, userPermissionsStore.userInfo]);

    const onCreationComplete = (success: boolean, id?: string) => {
        setCreateModalOpen(false);
        if (success && id) history.push(`opportunities/${id}/dashboard`);
    };

    const onExportClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            const document = await opportunityService.exportOpportunities(
                requestStore.requestInfo.request
            );

            showFile(document, 'Opportunities');
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <div className="Opportunities">
            <ListSectionHeader
                title={t('Opportunity.opportunities')}
                subTitle={t('Opportunity.manage_all_opportunities')}
                imgSrc={images.usersHeader}
                backgroundImageUrl={usersHeader}
                action={
                    <Button
                        type="primary"
                        onClick={(): void => {
                            setCreateModalOpen(true);
                        }}
                    >
                        {t('Opportunity.create_new_opportunity')}
                    </Button>
                }
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeLocations
                    includeLeadOwner
                    forOpportunities={true}
                    availableLocations={locationStore.locations}
                    includeAdvancedFilters
                    availableLeadOwners={salePersons}
                    includeExport
                    onExportClick={onExportClick}
                    exportBtnDisabled={isTableEmpty}
                />

                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(record: Opportunity): string => record.id!}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(row: Opportunity) => ({
                        onClick: (): void => {
                            onRowClick(row);
                        },
                    })}
                />
            </Content>

            {createModalOpen && (
                <CreateOpportunityModal onComplete={onCreationComplete} visible={createModalOpen} />
            )}
        </div>
    );
};

export default Opportunities;
