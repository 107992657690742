import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Delete: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.024 6.094h17.621V3.566H3.024v2.528zm1.535 16.884H19.11V7.018H4.56v15.96zm4.62-20.337h5.31V1.024H9.18V2.64zm11.927 0h-5.692V.562A.462.462 0 0014.951.1H8.716a.462.462 0 00-.461.463v2.08H2.56a.462.462 0 00-.462.462v3.453c0 .255.207.462.462.462h1.073v16.42c0 .255.207.462.461.462h15.477a.463.463 0 00.463-.462V7.02h1.071a.463.463 0 00.463-.462V3.104a.463.463 0 00-.463-.463z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.156.562c0-.31.25-.562.56-.562h6.235c.311 0 .562.251.562.562v1.98h5.593c.31 0 .563.25.563.562v3.453c0 .31-.253.561-.563.561h-.972v16.321c0 .31-.253.561-.562.561H4.095a.56.56 0 01-.56-.56V7.117H2.56A.56.56 0 012 6.558V3.103c0-.31.25-.562.56-.562h5.596V.562zm.56-.364c-.2 0-.362.163-.362.364V2.74H2.561c-.2 0-.363.162-.363.364v3.453c0 .2.163.363.363.363h1.172v16.52c0 .2.162.361.362.361h15.477c.2 0 .364-.162.364-.362V6.92h1.17c.2 0 .364-.162.364-.362V3.104a.364.364 0 00-.364-.364h-5.791V.562a.363.363 0 00-.364-.364H8.716zm.364.726h5.508V2.74H9.08V.924zm.198.199v1.419h5.112v-1.42H9.278zM2.924 3.466h17.82v2.728H2.924V3.466zm.199.199v2.33h17.423v-2.33H3.123zM4.459 6.92h14.75v16.157H4.46V6.92zm.199.198v15.76H19.01V7.119H4.658z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.994 21.12a.462.462 0 01-.462-.462v-9.64a.462.462 0 11.924 0v9.64a.462.462 0 01-.462.462z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.994 10.654c-.2 0-.363.163-.363.364v9.64a.363.363 0 10.726 0v-9.64c0-.201-.163-.364-.363-.364zm-.562.364a.561.561 0 111.123 0v9.64a.561.561 0 11-1.123 0v-9.64zM11.833 21.12a.462.462 0 01-.462-.462v-9.64a.462.462 0 11.925 0v9.64a.462.462 0 01-.463.462z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.833 10.654c-.2 0-.363.163-.363.364v9.64a.363.363 0 10.726 0v-9.64c0-.201-.162-.364-.363-.364zm-.561.364a.561.561 0 111.123 0v9.64a.561.561 0 11-1.123 0v-9.64zM15.674 21.12a.462.462 0 01-.462-.462v-9.64a.462.462 0 11.924 0v9.64a.462.462 0 01-.462.462z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.674 10.654c-.2 0-.363.163-.363.364v9.64a.363.363 0 10.726 0v-9.64c0-.201-.162-.364-.363-.364zm-.561.364a.561.561 0 111.123 0v9.64a.561.561 0 11-1.123 0v-9.64z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Delete;
