import { Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib/table';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetMembersRequestDto } from 'Api/Features/Members/Dtos/GetMembersRequestDto';
import { GetMembersSortColumnDto } from 'Api/Features/Members/Dtos/GetMembersSortColumnDto';
import { MemberCampusDto } from 'Api/Features/Members/Dtos/MemberCampusDto';
import { MemberMembershipDto } from 'Api/Features/Members/Dtos/MemberMembershipDto';
import Icon from 'Components/icons/icon';
import { TableFilters } from 'Components/table-filters';
import { Tag } from 'Components/tag';
import { LabelColor, TagColors } from 'Components/tag/tag';
import { TdWithImage } from 'Components/td-with-image';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import { ALL_LOCATIONS, TABLE_DEBOUNCE_DELAY } from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { MemberService } from 'Services/MemberService';
import { FilterStore } from 'Stores';
import { RequestType } from 'Stores/RequestStore';
import { showFile } from 'Utils';
import { cleanVal } from 'Utils/NumberUtils';

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 10,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

enum ContactStatus {
    subscribed = 'subscribed',
    noSubscription = 'noSubscription',
}
interface GetMembersRequestDtoExtended extends GetMembersRequestDto {
    status: ContactStatus[] | null;
}

const advancedFilters: AdvancedFilter[] = [
    {
        key: 'status',
        nameKey: 'Status',
        items: [
            {
                key: ContactStatus.subscribed,
                displayNameKey: 'Contact.subscribed',
                checked: true,
            },
            {
                key: ContactStatus.noSubscription,
                displayNameKey: 'Contact.no_subscription',
                checked: true,
            },
        ],
    },
];

interface ImageAndName {
    name: string;
    imageUrl?: string | null;
}

interface ContactsList {
    id: string;
    contactData: ImageAndName;
    email: string;
    companiesData?: ImageAndName;
    cgPlus: string;
    locationsData?: ImageAndName;
    isSubscribed?: boolean;
}

const getCompanies = (
    companies?: (MemberMembershipDto | null)[] | null
): ImageAndName | undefined => {
    if (companies && companies !== null && companies.length > 0) {
        const companiesName = companies.map((company) => company?.name).join(', ');

        return {
            name: companiesName,
            imageUrl: companies[0]?.imageUrl,
        };
    } else {
        return undefined;
    }
};

const getLocations = (campuses?: (MemberCampusDto | null)[] | null): ImageAndName | undefined => {
    if (campuses && campuses !== null && campuses.length > 0) {
        const campusesName = campuses.map((campus) => campus?.name).join(', ');

        return {
            name: campusesName,
            imageUrl: campuses[0]?.mainImageUrl,
        };
    } else {
        return undefined;
    }
};

const imageCellRender = (
    item: ImageAndName,
    defaultImg: string,
    placeholder?: ReactNode | string
): ReactNode => {
    if (item) {
        return (
            <TdWithImage defaultImg={<Icon iconName={defaultImg} />} imgSrc={item?.imageUrl}>
                {item?.name}
            </TdWithImage>
        );
    } else {
        return placeholder;
    }
};

interface ContactsTableProps {
    globalList?: boolean;
    campusId?: string;
}

const ContactsTable: FunctionComponent<ContactsTableProps> = ({ globalList, campusId }) => {
    const memberService = useService(MemberService);
    const paginationRef = useRef(initialPaginationState);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [loading, setLoading] = useState(false);
    const [contactsList, setContactsList] = useState<ContactsList[]>();
    const { requestStore, userPermissionsStore, locationStore, toastStore, globalLoadingStore } =
        useStores();
    const history = useHistory();
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const [requestSetFromCache, setRequestSetFromCache] = useState(false);
    const [isTableEmpty, setIsTableEmpty] = useState<boolean>(true);

    const inLocation = {
        isTrue: !!campusId,
        id: campusId,
    };

    const onRowClick = (contact: ContactsList): void => {
        if (inLocation.isTrue) {
            history.push(`/locations/${id}/contacts/${contact.id}`);
        } else history.push(`contacts/${contact.id}/dashboard`);
    };

    const getAdvancedFilterCheckedStatuses = (): {
        checkedStatuses: ContactStatus[];
        statusCount: number;
    } => {
        const filterStore = filterStoreRef.current;
        const statuses = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'status'
        );
        const checkedStatuses = statuses?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key;
            });
        return {
            checkedStatuses:
                checkedStatuses?.map((x) => ContactStatus[x as keyof typeof ContactStatus]) ?? [],
            statusCount: statuses?.items.length ?? 0,
        };
    };

    const columns: ColumnType<ContactsList>[] = [
        {
            title: t('name'),
            dataIndex: 'contactData',
            render: (contactData): ReactNode => imageCellRender(contactData, 'User'),
            key: GetMembersSortColumnDto.Name,
            sorter: true,
        },
        {
            title: t('email'),
            dataIndex: 'email',
            key: GetMembersSortColumnDto.Email,
            sorter: true,
        },
        {
            title: t('companies'),
            dataIndex: 'companiesData',
            render: (companiesData): ReactNode => imageCellRender(companiesData, 'Company'),
        },
        // {
        //     title: t('cg_plus'),
        //     dataIndex: 'cgPlus',
        // },
        {
            title: t('status'),
            dataIndex: 'isSubscribed',
            render: (isSubscribed: boolean) =>
                isSubscribed ? (
                    <Tag
                        boldLabel
                        labelColor={LabelColor.white}
                        color={TagColors.default}
                        label={t('Contact.subscribed')}
                    />
                ) : (
                    <Tag
                        boldLabel
                        labelColor={LabelColor.white}
                        color={TagColors.slateBlue40}
                        label={t('Contact.no_subscription')}
                    />
                ),
        },
        {
            title: t('locations'),
            dataIndex: 'locationsData',
            render: (locationsData): ReactNode => imageCellRender(locationsData, 'Location'),
        },
    ];

    const fetchMembersList = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            currentLocationId: string;
            advancedFilters?: AdvancedFilter[];
            sortColumn: GetMembersSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            const filterStore = filterStoreRef.current;

            setLoading(true);

            try {
                const statuses = getAdvancedFilterCheckedStatuses();
                if (statuses.checkedStatuses.length === 0) {
                    setContactsList([]);
                } else {
                    const campusId = inLocation.isTrue
                        ? [inLocation.id]
                        : params.currentLocationId === ALL_LOCATIONS
                        ? []
                        : [params.currentLocationId];

                    const request: GetMembersRequestDtoExtended = requestStore.setRequest({
                        request: {
                            pageSize: params.pagination.pageSize || 0,
                            page: (params.pagination.current || 1) - 1,
                            searchTerm: filterStore.searchTerm,
                            campusIds: campusId,
                            isSubscribed:
                                statuses.checkedStatuses.length === statuses.statusCount
                                    ? null
                                    : statuses.checkedStatuses.some(
                                          (status) => status === ContactStatus.subscribed
                                      ),
                            sortColumn: params.sortColumn,
                            sortDirection: params.sortDirection,
                            status:
                                statuses.checkedStatuses.length === statuses.statusCount
                                    ? []
                                    : statuses.checkedStatuses,
                        },
                        requestType: RequestType.Contact,
                    });

                    const [items, totalItemsCount] = await memberService.getMembers(request);

                    const contactsList: ContactsList[] = items.map((item) => ({
                        id: cleanVal.string(item.id),
                        contactData: {
                            name: cleanVal.string(item.name),
                            imageUrl: cleanVal.string(item.imageUrl),
                        },
                        email: cleanVal.string(item.contactInfo?.email),
                        companiesData: getCompanies(item.memberships),
                        cgPlus: 'todo',
                        locationsData: getLocations(item.campuses),
                        isSubscribed: item.isSubscribed,
                    }));

                    setContactsList(contactsList);
                    setPagination({
                        ...params.pagination,
                        total: totalItemsCount,
                    });
                    setIsTableEmpty(items.length === 0);
                }
            } catch (e) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [requestStore, inLocation.id, inLocation.isTrue, memberService]
    );

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        const { currentLocationId, searchTerm, advancedFilters } = filterStoreRef.current;

        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        await fetchMembersList({
            pagination,
            searchTerm,
            currentLocationId,
            advancedFilters,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });

        paginationRef.current = pagination;
    };

    useEffect(() => {
        if (
            requestStore?.requestInfo?.request &&
            requestStore?.requestInfo?.requestType === RequestType.Contact &&
            !requestSetFromCache
        ) {
            setRequestSetFromCache(true);
            const filterStore = filterStoreRef.current;
            const requestFromStore: GetMembersRequestDtoExtended = requestStore.requestInfo.request;
            const filtersList = [{ key: requestFromStore?.status || [], parentKey: 'status' }];

            filterStore.updateLocation(
                requestFromStore.campusIds && requestFromStore?.campusIds.length > 0
                    ? cleanVal.string(requestFromStore.campusIds[0])
                    : ALL_LOCATIONS
            );

            filterStore.updateSearchTerm(cleanVal.string(requestFromStore.searchTerm));
            filterStore.tickMultipleAdvancedFilter(filtersList);
        }
    }, [requestStore, userPermissionsStore.userInfo]);

    const debounceSearch = useRef(
        debounce(
            (params: {
                hasActiveSubscription?: boolean | null;
                searchTerm?: string;
                currentLocationId: string;
                advancedFilters?: AdvancedFilter[];
            }) => {
                fetchMembersList({
                    pagination: {
                        ...paginationRef.current,
                        current: 1,
                    },
                    searchTerm: params.searchTerm,
                    currentLocationId: params.currentLocationId,
                    advancedFilters: params.advancedFilters,
                    sortColumn: null,
                    sortDirection: null,
                });
            },
            TABLE_DEBOUNCE_DELAY
        )
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                currentLocationId: filterStore.currentLocationId,
                advancedFilters: filterStore.advancedFilters,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    const onExportClick = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            const document = await memberService.exportMembers(requestStore.requestInfo.request);

            showFile(document, 'Contacts');
        } catch (e) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <div className="ContactsTable">
            <TableFilters
                filterStore={filterStoreRef.current}
                includeAdvancedFilters
                includeSearch
                includeLocations={globalList}
                availableLocations={locationStore.locations}
                includeExport={true}
                onExportClick={onExportClick}
                exportBtnDisabled={isTableEmpty}
            />

            <Table
                className="table-striped-rows table-action-rows"
                bordered
                dataSource={contactsList}
                columns={columns}
                loading={loading}
                rowKey={(record): string => record.id}
                pagination={pagination}
                onChange={handleTableChange}
                onRow={(row: ContactsList) => ({
                    onClick: (): void => {
                        onRowClick(row);
                    },
                })}
            />
        </div>
    );
};

export default ContactsTable;
