import { observable, action, computed } from 'mobx';
import { ManagementRoleWithContextDto } from 'Api/Features/Accounts/Dtos/ManagementRoleWithContextDto';
import { inject } from 'aurelia-dependency-injection';
import { UserPermissionsService } from 'Services/UserPermissionsService';
import { AuthenticationStore, AuthorizationStore, LocationStore } from 'Stores';
import { ManagerUserDto } from 'Api/Features/ManagerUsers/Dtos/ManagerUserDto';
import { LightCampusDetailsInfo } from 'Models/Location/LightCampusInfo';
import { ManagementRoleDto } from 'Api/Features/ManagerUsers/Dtos/ManagementRoleDto';

@inject(UserPermissionsService, AuthenticationStore, LocationStore, AuthorizationStore)
class PermissionStore {
    constructor(
        private readonly userPermissionsService: UserPermissionsService,
        private readonly authenticationStore: AuthenticationStore,
        private readonly locationStore: LocationStore,
        private readonly authorizationStore: AuthorizationStore
    ) {}

    @observable
    private _permissions: ManagementRoleWithContextDto[] | null = null;

    @observable
    public userIsAdministrator = false;

    @computed
    get permissions(): ManagementRoleWithContextDto[] | null {
        return this._permissions;
    }

    @observable
    public userInfo: ManagerUserDto | null = null;

    @observable
    public availableLocationsForUser: LightCampusDetailsInfo[] = [];

    @action
    async setUserInfoAndPermissions(): Promise<void> {
        const userId = this.authenticationStore.userId;
        try {
            const userInfo = await this.userPermissionsService.getUserInfo(userId!);
            this.userInfo = userInfo;

            if (userInfo) this._permissions = this.userPermissionsService.getPermissions(userInfo);

            this.userIsAdministrator =
                this._permissions?.some((element) => {
                    return element.name === ManagementRoleDto.Administrator;
                }) || false;

            this.setAvailableLocationsForUser();
        } catch {
            this.authenticationStore.clearSession();
            this.clearUserInfo();
            this.authorizationStore.handleUserNotManager();
            throw new UserNotManagerError();
        }
    }

    @action
    public clearUserInfo(): void {
        this.userInfo = null;
    }

    @action
    userIsManagerForAtLeastOneOfCampus(campusIds: string[]): boolean {
        let result = false;
        this.permissions?.forEach((element) => {
            if (
                element.name === ManagementRoleDto.Manager &&
                campusIds.some((campId) => campId === element.context?.campusId)
            )
                result = true;
        });
        return result;
    }

    @action
    public setAvailableLocationsForUser = (): void => {
        if (this.userIsAdministrator) {
            this.availableLocationsForUser = this.locationStore.locations;
        } else {
            const campusIds = this.permissions
                ?.filter((role) => role.name === ManagementRoleDto.Manager)
                .map((role) => role.context?.['campusId']);

            this.availableLocationsForUser = this.locationStore.locations.filter((location) =>
                campusIds?.some((id) => id === location.id)
            );
        }
    };
}


export default PermissionStore;


export class UserNotManagerError extends Error {
    constructor() {
        super();
    }
}