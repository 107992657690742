import { Button, Checkbox, Col, Form, Input, Row, Typography } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { ManagementRoleWithContextDto } from 'Api/Features/Accounts/Dtos/ManagementRoleWithContextDto';
import { CreateManagerUserRequestDto } from 'Api/Features/ManagerUsers/Dtos/CreateManagerUserRequestDto';
import { CreateManagerUserRequestRoleDto } from 'Api/Features/ManagerUsers/Dtos/CreateManagerUserRequestRoleDto';
import { ManagementRoleDto } from 'Api/Features/ManagerUsers/Dtos/ManagementRoleDto';
import { ManagerUserDto } from 'Api/Features/ManagerUsers/Dtos/ManagerUserDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Add, User } from 'Components/icons';
import { ManagementRoleSelector } from 'Components/management-role-selector';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { LocationNotificationPref } from 'Models/ManagerUsers/LocationNotificationPref';
import { Member } from 'Models/Members/Member';
import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CreateManagerUserSchema, EditManagerUserSchema } from 'Schemas';
import { ManagerUserService } from 'Services/ManagerUserService';
import { MemberService } from 'Services/MemberService';
import { v4 as uuidv4 } from 'uuid';
import { theme } from 'variant';
import './create-manager.less';

const { Title, Text } = Typography;

const formGutter: [Gutter, Gutter] = [40, 0];
const otherGutter: [Gutter, Gutter] = [40, 40];

class ManagerUserRole {
    constructor(dto?: ManagementRoleWithContextDto | null) {
        this.id = uuidv4();
        if (dto) {
            this.roleId = dto.name!;
            this.locationId = dto.context?.campusId;
        } else {
            this.roleId = '' as ManagementRoleDto;
        }
    }
    id: string;
    roleId: ManagementRoleDto;
    locationId?: string;
    touched = false;
}

interface ManagerUserModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    managerUser?: ManagerUserDto;
    previousPreferences?: LocationNotificationPref[];
}

const ManagerUserModal: FunctionComponent<ManagerUserModalProps> = ({
    visible,
    onComplete,
    managerUser,
    previousPreferences,
}) => {
    //#region Hooks
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors, setErrors] = useFormValidation(
        managerUser ? EditManagerUserSchema : CreateManagerUserSchema,
        form
    );
    const [roles, setRoles] = useState<ManagerUserRole[]>([new ManagerUserRole()]);
    const [notificationPreferences, setNotificationPreferences] = useState<
        LocationNotificationPref[]
    >([]);
    const { locationStore, globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const memberService = useService(MemberService);
    const managerUserService = useService(ManagerUserService);

    const pageSize = 25;
    const [managersCurrentPage, setMembersCurrentPage] = useState(0);
    const [membersSearchResults, setMembersSearchResults] = useState<Member[]>([]);
    const [membersSearchTerm, setMembersSearchTerm] = useState('');
    const [membersMaxResults, setMembersMaxResults] = useState(false);
    const [membersOptions, setMembersOptions] = useState<SelectCustomOption[]>([]);
    const [selectedMemberOptions, setSelectedMemberOptions] = useState<SelectCustomOption[]>([]);
    const [selectedMemberId, setSelectedMemberId] = useState<string[]>([]);
    const [selectLoading, setSelectLoading] = useState(false);
    //#endregion

    //#region Event handlers
    const onRoleChange = (itemId: string, roleId: ManagementRoleDto): void => {
        const newRoles = roles.map((role) => {
            if (role.id !== itemId) return role;

            return {
                ...role,
                roleId,
                touched: true,
            };
        });
        setRoles(newRoles);
    };

    const onLocationChange = (itemId: string, locationId: string): void => {
        const newRoles = roles.map((role) => {
            if (role.id !== itemId) return role;

            return {
                ...role,
                locationId,
                touched: true,
            };
        });
        setRoles(newRoles);
    };

    const addRole = (): void => {
        const newRoles = roles.concat([new ManagerUserRole()]);
        setRoles(newRoles);
    };

    const removeRole = (itemId: string): void => {
        if (roles.length > 1) {
            const newRoles = roles.filter((role) => role.id !== itemId);
            setRoles(newRoles);
        }
    };

    const togglePref = (locationId: string | null, checked: boolean): void => {
        const newPrefs = notificationPreferences.map((pref) => {
            if (pref.location.id !== locationId) return pref;

            return {
                ...pref,
                checked,
            };
        });
        setNotificationPreferences(newPrefs);
    };

    const resetMembersSearch = (): void => {
        setMembersCurrentPage(0);
        setMembersSearchResults([]);
        setMembersSearchTerm('');
        setMembersMaxResults(false);
    };

    const handleMemberKeywordsChange = useCallback((value: string): void => {
        resetMembersSearch();
        setMembersSearchTerm(value);
    }, []);

    const searchMembers = async (page: number, searchTerm: string): Promise<Member[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
        };
        const results = await memberService.getMembers(args);

        if (results.length < pageSize) {
            setMembersMaxResults(true);
        }
        setSelectLoading(false);
        return results[0];
    };

    const debounceMembersSearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchMembers(page, searchTerm).then((results) => {
                setMembersSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    const handleMembersMenuScrollToBottom = (): void => {
        if (!membersMaxResults) {
            setMembersCurrentPage((prevPage) => prevPage + 1);
        }
    };

    //#endregion

    //#region Effects
    const generatePrefs = useCallback(
        (roles: ManagerUserRole[]): void => {
            let prefs;
            if (roles.some((role) => role.roleId === ManagementRoleDto.Administrator)) {
                prefs = locationStore.locations.map(
                    (loc) =>
                        ({
                            location: loc,
                            checked: true,
                        } as LocationNotificationPref)
                );
            } else {
                prefs = roles
                    .filter((role) => role.roleId === ManagementRoleDto.Manager && role.locationId)
                    .map(
                        (role) =>
                            ({
                                location: locationStore.locations.find(
                                    (loc) => loc.id === role.locationId
                                ),
                                checked:
                                    role.touched ||
                                    (previousPreferences?.find(
                                        (pref) => pref.location.id === role.locationId
                                    )?.checked ??
                                        true),
                            } as LocationNotificationPref)
                    );
            }
            setNotificationPreferences(prefs);
        },
        [locationStore.locations, previousPreferences]
    );

    useEffect(() => {
        if (selectedMemberId.length > 0) {
            const chosenUser = membersSearchResults.find(
                (user: Member) => user.id === selectedMemberId[0]
            );
            if (chosenUser) {
                form.setFieldsValue({
                    existingMemberId: chosenUser?.id,
                    firstName: chosenUser?.firstName,
                    lastName: chosenUser?.lastName,
                    ['contactInfo.email']: chosenUser?.contactInfo?.email,
                });
            }
        }
    }, [selectedMemberId, form, membersSearchResults]);

    useEffect(() => {
        generatePrefs(roles);
    }, [roles, generatePrefs]);

    useEffect(() => {
        if (managerUser) {
            form.setFieldsValue({
                existingMemberId: managerUser.id,
                firstName: managerUser.firstName,
                lastName: managerUser.lastName,
                ['contactInfo.email']: managerUser.contactInfo?.email,
                'address.addressLine1': managerUser.address?.addressLine1,
                'address.city': managerUser.address?.city,
                'address.country': managerUser.address?.country,
                'address.postalCode': managerUser.address?.postalCode,
                'address.state': managerUser.address?.state,
                wifiPassword: managerUser.wifiPassword,
            });
            const managerRoles: ManagerUserRole[] = managerUser.roles?.map(
                (role) => new ManagerUserRole(role)
            ) || [new ManagerUserRole()];
            setRoles(managerRoles);
            generatePrefs(managerRoles);
        }
    }, [managerUser, form, generatePrefs]);

    useEffect(() => {
        const searchResults = membersSearchResults?.map(
            (x: Member) =>
                ({
                    value: x?.id,
                    label: x?.name,
                    imageUrl: x?.imageUrl,
                    badge: undefined,
                    content: x?.contactInfo?.email,
                } as SelectCustomOption)
        );
        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedMemberOptions);
        setMembersOptions(merged);
    }, [membersSearchResults, t, selectedMemberOptions]);

    useEffect(() => {
        setSelectLoading(true);
        debounceMembersSearch.current(managersCurrentPage, membersSearchTerm);
    }, [managersCurrentPage, membersSearchTerm]);
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        setMembersSearchResults([]);
        setSelectedMemberId([]);
        setNotificationPreferences([]);
        setRoles([new ManagerUserRole()]);
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <User />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t(
                    `ManagerUser.manager_confirm_positive${
                        managerUser !== undefined ? '_edit' : ''
                    }`
                ),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        const data: CreateManagerUserRequestDto = {
            roles: roles.map(
                (role) =>
                    ({
                        name: role.roleId,
                        context: {
                            CampusId:
                                role.roleId === ManagementRoleDto.Manager
                                    ? role.locationId
                                    : undefined,
                        },
                    } as CreateManagerUserRequestRoleDto)
            ),
            existingMemberId: selectedMemberId.length > 0 ? selectedMemberId[0] : null,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            contactInfo: { email: formValues['contactInfo.email'] },
            password: formValues.password || undefined,
            confirmPassword: formValues.confirmPassword || undefined,
            address: {
                addressLine1: formValues['address.addressLine1'],
                city: formValues['address.city'],
                country: formValues['address.country'],
                postalCode: formValues['address.postalCode'],
                state: formValues['address.state'],
            },
            wifiPassword: formValues.wifiPassword,
        };

        if (!(await validateForm(data))) return;
        try {
            globalLoadingStore.addLoading();
            if (managerUser) {
                await managerUserService.editManagerUser(
                    managerUser.id!,
                    data,
                    notificationPreferences
                );
            } else {
                await managerUserService.createManagerUser(data, notificationPreferences);
            }
            toastStore.toast({
                type: 'success',
                messageKey: `ManagerUser.manager_${managerUser ? 'edit' : 'create'}_success`,
            });
            dismiss(true);
        } catch (e) {
            if (e.response?.data?.error === 'E001003') {
                toastStore.toast({
                    type: 'error',
                    message: e.response?.data.error_description,
                });
            } else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    //#region Render
    const renderPrefCol = (pref: LocationNotificationPref): ReactNode => (
        <Col span={12} key={pref.location.id!}>
            <div>
                <Text strong className="text-lg">
                    {pref.location.name}
                </Text>
            </div>
            <Row>
                <Col flex="auto">{t('ManagerUser.notification_explanation')}</Col>
                <Col>
                    <Checkbox
                        checked={pref.checked}
                        onChange={(e): void => {
                            togglePref(pref.location.id!, e.target.checked);
                        }}
                    />
                </Col>
            </Row>
        </Col>
    );

    const renderPrefs = (): ReactNode[] => {
        const rows = [];
        for (let i = 0; i < notificationPreferences.length; i += 2) {
            const el1 = notificationPreferences[i];
            const el2 = notificationPreferences[i + 1];
            rows.push(
                <Row gutter={otherGutter} key={i}>
                    {renderPrefCol(el1)}
                    {el2 ? renderPrefCol(el2) : null}
                </Row>
            );
        }
        return rows;
    };

    return (
        <BaseModal
            visible={visible}
            title={t(`ManagerUser.manager_${managerUser ? 'edit' : 'create'}`)}
            className="FormModal"
            onCancel={exit}
        >
            <div className="CreateManager">
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Title level={4}>{t('basic_information')}</Title>
                    {!managerUser ? (
                        <Row gutter={formGutter}>
                            <Col span={24}>
                                <ValidatedFormItem
                                    errors={errors}
                                    label={t('ManagerUser.existing_user')}
                                >
                                    <SelectCustom
                                        options={membersOptions}
                                        defaultImg={<User fill={theme['white']} />}
                                        strongLabel={true}
                                        placeholder={t('SelectCustom.placeholder_managers')}
                                        onKeywordsChange={handleMemberKeywordsChange}
                                        onMenuScrollToBottom={handleMembersMenuScrollToBottom}
                                        onChange={(value): void => {
                                            setSelectedMemberId([value.value]);
                                            const options = [value as SelectCustomOption];
                                            setSelectedMemberOptions(options);
                                        }}
                                        hideSelectedOptions={false}
                                        selected={selectedMemberId}
                                        isLoading={selectLoading}
                                        idAttribute={'existing_user'}
                                    />
                                </ValidatedFormItem>
                            </Col>
                        </Row>
                    ) : null}
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="firstName"
                                label={t('first_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="lastName"
                                label={t('last_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="contactInfo.email"
                                label={t('email')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="wifiPassword"
                                label={t('wifi_password')}
                            >
                                <Input type={'password'} />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="password"
                                label={t('User.password')}
                                required={!managerUser}
                            >
                                <Input.Password />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="confirmPassword"
                                label={t('User.confirm_password')}
                                required={!managerUser}
                            >
                                <Input.Password />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>{t('address')}</Title>
                    <Row gutter={formGutter}>
                        <Col span={24}>
                            <ValidatedFormItem
                                name="address.addressLine1"
                                errors={errors}
                                label={t('address')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="address.city"
                                errors={errors}
                                label={t('city')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="address.state"
                                errors={errors}
                                label={t('state')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="address.country"
                                errors={errors}
                                label={t('country')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={6}>
                            <ValidatedFormItem
                                name="address.postalCode"
                                errors={errors}
                                label={t('zip_code')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Title level={4}>{t('ManagerUser.management_roles')}</Title>
                    {roles.map((role, i) => (
                        <ManagementRoleSelector
                            key={role.id}
                            id={role.id}
                            errors={errors}
                            errorKey={`roles[${i}]`}
                            gutter={formGutter}
                            locations={locationStore.locations}
                            selectedLocationId={role.locationId}
                            selectedRole={role.roleId}
                            onRoleChange={onRoleChange}
                            onLocationChange={onLocationChange}
                            onRemoveRole={removeRole}
                            allSelectedRoles={roles}
                        />
                    ))}
                    <div className="add-role-container">
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<Add />}
                            htmlType="button"
                            onClick={addRole}
                        />
                    </div>
                    <Title level={4}>{t('Notification.notifications')}</Title>
                    {renderPrefs()}
                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(ManagerUserModal);
