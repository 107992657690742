import React, { FunctionComponent, InputHTMLAttributes } from 'react';
import './input-file.less';

interface InputFile {
    label?: string;
    accept?: string;
}

type InputFileProps = InputFile & InputHTMLAttributes<HTMLInputElement>;

const InputFile: FunctionComponent<InputFileProps> = ({ label = 'Upload File', accept, ...rest }) => {
    return (
        <div className="InputFile">
            <label htmlFor="file-upload">{label}</label>
            <input id="file-upload" type="file" accept={accept} {...rest}></input>
        </div>
    );
};

export default InputFile;
